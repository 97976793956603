/**
 * Title: totalUsers.js
 * Description: This is a file that contains the components for the total users
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/totalUsers.js
 **/

import { useState, useEffect } from 'react'

import { listUsers } from '../../api/queries'

import { API, graphqlOperation } from 'aws-amplify'

import { Col, Row, Spin } from 'antd'

export default function TotalUsers() {
  const [nextToken, setNextToken] = useState(null)
  const [updateTrigger, setUpdateTrigger] = useState(null)
  const [loadingTable, setIsLoadingTable] = useState(true)
  const [employeeList, setEmployeeList] = useState([])
  const [inactiveList, setInactiveList] = useState([])
  const [holdAll, setHoldAll] = useState([])

  const fetchEmployeeUser = async () => {
    try {
      if (nextToken) {
        const result = await API.graphql(
          graphqlOperation(listUsers, {
            limit: 1000,
            nextToken,
          })
        )

        const holdAllForFilter = [...holdAll, ...result.data.listUsers.items]
        const sortArrayFilteredStatusInactive = holdAllForFilter.filter(
          (item) => item.status === 'Disabled'
        )

        setHoldAll([...holdAll, ...result.data.listUsers.items])

        var sortArray = []
        sortArray.push(...employeeList)
        sortArray.push(...result.data.listUsers.items)

        const sortArrayFilteredStatus = sortArray.filter(
          (item) => item.status === 'Approved'
        )

        const sortArraySorted = sortArrayFilteredStatus.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )

        setEmployeeList(sortArraySorted)
        setInactiveList(sortArrayFilteredStatusInactive)
        if (result.data.listUsers.nextToken) {
          setNextToken(result.data.listUsers.nextToken)
        } else {
          setIsLoadingTable(false)
        }
      } else {
        const result = await API.graphql(
          graphqlOperation(listUsers, {
            limit: 1000,
          })
        )

        setHoldAll(result.data.listUsers.items)

        var firstSortArray = []
        firstSortArray.push(...result.data.listUsers.items)
        const sortArrayFilteredStatus = firstSortArray.filter(
          (item) => item.status === 'Approved'
        )

        const sortArrayFilteredStatusInactive = firstSortArray.filter(
          (item) => item.status === 'Disabled'
        )

        const firstSortArraySorted = sortArrayFilteredStatus.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )

        setInactiveList(sortArrayFilteredStatusInactive)
        setEmployeeList(firstSortArraySorted)

        if (result.data.listUsers.nextToken) {
          setNextToken(result.data.listUsers.nextToken)
        } else {
          setIsLoadingTable(false)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchEmployeeUser()
  }, [nextToken, updateTrigger])

  return (
    <>
      <Col span={24}>
        <Row>
          <Col span={12}>
            <Row justify='center'>
              <Col span={24}>
                <Row justify='center'>
                  <span>NUMBER OF REGISTERED ACTIVE EMPLOYEES</span>
                </Row>
              </Col>
              <Col>
                <Row justify='center'>
                  {loadingTable ? (
                    <>
                      <Spin size='large' />
                    </>
                  ) : (
                    <>
                      <span className={'total-users'}>
                        {employeeList.length}
                      </span>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>

          <Col span={12}>
            <Row justify='center'>
              <Col span={24}>
                <Row justify='center'>
                  <span>NUMBER OF REGISTERED INACTIVE EMPLOYEES</span>
                </Row>
              </Col>
              <Col>
                <Row justify='center'>
                  {loadingTable ? (
                    <>
                      <Spin size='large' />
                    </>
                  ) : (
                    <>
                      <span className={'total-users'}>
                        {inactiveList.length}
                      </span>
                    </>
                  )}
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  )
}
