/**
 * Title: toRoman.js
 * Description: This is a file that contains the function for converting numbers to roman numerals
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/Utilities/toRoman.js
 **/

export function toRoman(num) {
  const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
  const roman = [
    'M',
    'CM',
    'D',
    'CD',
    'C',
    'XC',
    'L',
    'XL',
    'X',
    'IX',
    'V',
    'IV',
    'I',
  ]

  let result = ''

  for (let i = 0; i < decimal.length; i++) {
    while (decimal[i] <= num) {
      result += roman[i]
      num -= decimal[i]
    }
  }

  return result
}
