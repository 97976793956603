import { useState, useEffect } from 'react'

import 'antd/dist/antd.css'

import { API, graphqlOperation, Auth } from 'aws-amplify'
import {
  createTrainingAnnouncement,
  updateTrainingAnnouncement,
} from '../../api/mutations'
import {
  listTrainingRegistrations,
  listTrainingAnnouncements,
  listOtherUploads,
  listTrainingVideos,
  getHomeSlidesUploaded,
  listOnlineTrainingAnnouncements,
} from '../../api/queries'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

import LmsViewCourseModal from './LmsViewCourseModal.js'
import EvaluationMaker from './evaluationMaker.js'
import AssessmentMaker from './assessmentMaker'
import EndorsementLetter from './endorsementLetter'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import DatePicker from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'

import {
  notification,
  Button,
  Modal,
  Input,
  Select,
  Tooltip,
  TimePicker,
  DatePicker as AntdDatePicker,
  Row,
  Col,
  Radio,
} from 'antd'

import moment from 'moment'

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick
import SelectEvaluationQuestion from './selectEvaluationQuestion'
import SelectAssessmentQuestion from './selectAssessmentQuestion'
import ManageTrainingsNumberOfParticipants from './manageTrainingsNumberOfParticipants'
import UploadOnlineTrainings from './uploadOnlineTrainings'
import listOfDepartments from '../../utilities/dept'

export default function UploadTrainings({ userData }) {
  const [uploaded, setUploaded] = useState([])
  const [visibleAnnouncement, setVisibleAnnouncement] = useState(false)

  const [announceUpload, setAnnounceUpload] = useState([])
  const [announceDate, setAnnounceDate] = useState(null)
  const [announceStartTime, setAnnouceStartTime] = useState(null)
  const [announceEndTime, setAnnouceEndTime] = useState(null)
  const [tpData, setTpData] = useState(null)
  const [cdData, setCdData] = useState(null)
  const [courseTitle, setCourseTitle] = useState(null)
  const [courseCategory, setCourseCategory] = useState([])
  const [optionSubCategory, setOptionSubCategory] = useState(null)
  const [courseSubCategory, setCourseSubCategory] = useState(null)

  const [allAnnouncements, setAllAnnouncements] = useState([])

  const [courses, setCourses] = useState([])
  const [viewCourse, setViewCourse] = useState(null)
  const [openView, setOpenView] = useState(false)
  const [isOpenRegistration, setIsOpenRegistration] = useState(false)

  const [pTDateOpen, setPTDateOpen] = useState(null)
  const [pTTimeOpen, setPTTimeOpen] = useState(null)
  const [pTDateClose, setPTDateClose] = useState(null)
  const [pTTimeClose, setPTTimeClose] = useState(null)

  const [showPostAssessment, setShowPostAssessment] = useState(false)

  const [participantLimit, setParticipantLimit] = useState('')

  const [getEvalID, setGetEvalID] = useState(
    'd184c688-31d9-4c77-92a5-e0abae9c4e49'
  )

  const [isTestEvent, setIsTestEvent] = useState(false)

  const [getAssessmentID, setGetAssessmentID] = useState(null)

  const [endorsementLetterID, setEndorsementLetterID] = useState(null)

  const [registrationLimitPerDept, setRegistrationLimitPerDept] = useState({})

  const [departments, setDepartments] = useState([])

  const [listOfDepartmentsOrignal, setListOfDepartmentsOriginal] = useState([])

  const [departmentsLimitValue, setDepartmentsLimitValue] = useState({})

  const [textDisplayBeforeTrainingOpen, setTextDisplayBeforeTrainingOpen] =
    useState(null)

  const [manageParticipantsModal, setManageParticipantsModal] = useState(false)
  const [trainings, setTrainings] = useState([])
  const [isLink, setIsLink] = useState(false)
  const [selectedTraining, setSelectedTraining] = useState(null)
  const [isEndorsementLetterRequired, setIsEndorsementLetterRequired] =
    useState(null)

  const [listOfTrainings, setListOfTrainings] = useState([
    {
      value: '',
      date: '',
      label: 'Select training',
      title: 'Select training',
      disabled: true,
    },
  ])

  const [visibleAnnouncementOnline, setVisibleAnnouncementOnline] =
    useState(false)

  const [otherFiles, setOtherFiles] = useState([])
  const [uploadedTrainingVideo, setUploadedTrainingVideo] = useState([])
  const [listOfUploadedSlides, setListOfUploadedSlides] = useState([])

  const [isSeminar, setIsSeminar] = useState(false)

  const [isPassPreAssessmentRequired, setIsPassPreAssessmentRequired] =
    useState(false)
  const [preAssessmentScore, setPreAssessmentScore] = useState(0)

  useEffect(() => {
    const fetchUploadedSlides = async () => {
      const response = await API.graphql({
        ...graphqlOperation(getHomeSlidesUploaded, {
          id: 'b61cc5d7-1319-43aa-8cac-7905666bc520',
        }),
        authMode: 'API_KEY',
      })
      if (response) {
        setListOfUploadedSlides(
          JSON.parse(response.data.getHomeSlidesUploaded.slideImageFileName)
        )
      }
    }

    fetchUploadedSlides()
  }, [])

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * @description Fetches the training announcements from the database
   * @returns {Promise<void>}
   * @function fetchTrainings
   * @async
   * @param {void}
   * @returns {void}
   */
  const fetchTrainings = async () => {
    try {
      let token = null // Initialize the token variable
      let foundData = []
      do {
        const result = await API.graphql({
          query: listTrainingAnnouncements,
          authMode: 'API_KEY',
          variables: {
            limit: 1000,
            nextToken: token, // Pass the token variable as the nextToken value
          },
        })

        foundData = [
          ...foundData,
          ...result.data.listTrainingAnnouncements.items,
        ]

        token = result.data.listTrainingAnnouncements.nextToken // Update the token with the nextToken from the result
      } while (token)

      const forListTrainings = foundData.map((x) => ({
        date: x.date,
        value: x.id,
        title: x.title,
        label: (
          <span>
            <strong>{x.date}</strong> - {x.title}
          </span>
        ),
      }))
      setListOfTrainings([...listOfTrainings, ...forListTrainings])
      setTrainings(foundData)
    } catch (err) {
      console.log(err)
    }
  }

  const fetchOnlineTrainings = async () => {
    try {
      let token = null // Initialize the token variable
      let foundData = []
      do {
        const result = await API.graphql({
          query: listOnlineTrainingAnnouncements,
          authMode: 'API_KEY',
          variables: {
            limit: 1000,
            nextToken: token, // Pass the token variable as the nextToken value
          },
        })

        foundData = [
          ...foundData,
          ...result.data.listOnlineTrainingAnnouncements.items,
        ]
        console.log(foundData)

        token = result.data.listOnlineTrainingAnnouncements.nextToken // Update the token with the nextToken from the result
      } while (token)

      const forListTrainings = foundData.map((x) => ({
        date: x.date,
        value: x.id,
        title: x.title,
        label: (
          <span>
            <strong>{x.date}</strong> - {x.title}
          </span>
        ),
      }))
      setListOfTrainings([...listOfTrainings, ...forListTrainings])
      setTrainings(foundData)
    } catch (err) {
      console.log(err)
    }
  }

  if (selectedTraining) {
    const existingTrainingLinked = trainings
      .filter((x) => x.id === selectedTraining)
      .map((x) => JSON.parse(x.linkedTrainingAnnouncementIDs))[0]
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * @description Fetches the training announcements from the database
   * @returns {Promise<void>}
   * @function fetchTrainings
   * @param {Object} optionA - The first option to compare
   * @param {Object} optionB - The second option to compare
   * @returns {Number} - Returns 1 if optionA is greater than optionB, -1 if optionA is less than optionB, and 0 if they are equal
   */
  function compareOptions(optionA, optionB) {
    const dateA = new Date(optionA.date)
    const dateB = new Date(optionB.date)
    if (dateA > dateB) {
      return -1
    }
    if (dateA < dateB) {
      return 1
    }
    return 0
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * @description Fetches the training announcements from the database
   * @returns {Promise<void>}
   * @function fetchUploadedTrainingVideo
   * @param {void}
   * @returns {void}
   * @async
   */
  const fetchUploadedTrainingVideo = async () => {
    try {
      let token = null // Initialize the token variable
      let foundItems = []

      do {
        const result = await API.graphql(graphqlOperation(listTrainingVideos))

        foundItems = [foundItems, ...result.data.listTrainingVideos.items]

        token = result.data.listTrainingVideos.nextToken // Update the token with the nextToken from the result
      } while (token)

      const mappedItems = foundItems.map((e, i) => {
        return {
          ...e,
          value: e.id,
          label: `${e.key} - (Uploaded on ${moment(e.createdAt).format(
            'MMMM D, YYYY h:mm:ss A'
          )})`,
        }
      })
      setUploadedTrainingVideo(
        mappedItems.sort((a, b) => {
          return a.key.localeCompare(b.key)
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * @description Fetches the training announcements from the database
   * @returns {Promise<void>}
   * @function fetchUploadedFiles
   * @param {void}
   * @async
   */
  const fetchUploadedFiles = async () => {
    try {
      let token = null // Initialize the token variable
      let foundItems = []

      do {
        const result = await API.graphql(graphqlOperation(listOtherUploads))

        foundItems = [foundItems, ...result.data.listOtherUploads.items]

        token = result.data.listOtherUploads.nextToken // Update the token with the nextToken from the result
      } while (token)

      const mappedItems = foundItems.map((e, i) => {
        return {
          ...e,
          value: e.id,
          label: e.filename,
        }
      })
      setOtherFiles(mappedItems)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const initialLimit = {}
    departments.forEach((dept) => {
      initialLimit[dept] = 0
    })
    setRegistrationLimitPerDept(initialLimit)
    fetchTrainings()
    fetchOnlineTrainings()
    fetchUploadedFiles()
    fetchUploadedTrainingVideo()
  }, [])

  useEffect(() => {
    if (courseCategory == 'CORE COMPETENCIES') {
      setOptionSubCategory(subCategCore)
      setCourseSubCategory(null)
    } else if (courseCategory == 'ORGANIZATIONAL COMPETENCIES') {
      setOptionSubCategory(subCategOrg)
      setCourseSubCategory(null)
    } else if (courseCategory == 'LEADERSHIP COMPETENCIES') {
      setOptionSubCategory(subCategLeader)
      setCourseSubCategory(null)
    } else if (courseCategory == 'FUNCTIONAL/TECHNICAL') {
      setOptionSubCategory(subCategFunctional)
      setCourseSubCategory(null)
    } else if (courseCategory == 'Additional Training Programs') {
      setOptionSubCategory(subCategAdditional)
      setCourseSubCategory(null)
    } else {
      setOptionSubCategory([])
    }
  }, [courseCategory])

  const depts = listOfDepartments()
  /* List of departments */
  useEffect(() => {
    setDepartments(depts)
    setListOfDepartmentsOriginal(depts)
  }, [])

  const departmentOptions = [
    {
      value: '',
      label: 'Select department',
      disabled: true,
    },
    ...departments.map((dept) => ({
      value: dept,
      label: dept,
    })),
  ]

  const subCategCore = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Public Service Ethics & Accountability Orientation & Work Ethics',
      label: 'Public Service Ethics & Accountability Orientation & Work Ethics',
    },
    {
      value:
        'Administrative Discipline: Orientation of Values & Ethics in the Workplace',
      label:
        'Administrative Discipline: Orientation of Values & Ethics in the Workplace',
    },
    {
      value: 'Social Media Responsibility',
      label: 'Social Media Responsibility',
    },
    {
      value: 'Customer Service Satisfaction',
      label: 'Customer Service Satisfaction',
    },
  ]

  const subCategLeader = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Leadership & Management Program',
      label: 'Leadership & Management Program',
    },
    {
      value: 'Conflict Management',
      label: 'Conflict Management',
    },
    {
      value: 'Effective Management & Supervision',
      label: 'Effective Management & Supervision',
    },
    {
      value: 'Total Quality Management',
      label: 'Total Quality Management',
    },
    {
      value: 'Project Management',
      label: 'Project Management',
    },
    {
      value: 'Culture Building',
      label: 'Culture Building',
    },
    {
      value: 'Coaching & Mentoring',
      label: 'Coaching & Mentoring',
    },
    {
      value: 'Managing Teams & People',
      label: 'Managing Teams & People',
    },
    {
      value: 'Expanded Team Building',
      label: 'Expanded Team Building',
    },
    {
      value: 'Strategic Management Planning',
      label: 'Strategic Management Planning',
    },
    {
      value: 'Goal and Metrics Setting',
      label: 'Goal and Metrics Setting',
    },
    {
      value: 'Supervisory Development Course',
      label: 'Supervisory Development Course',
    },
    {
      value: 'Change Management',
      label: 'Change Management',
    },
  ]

  const subCategOrg = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Business/Technical Writing Skills',
      label: 'Business/Technical Writing Skills',
    },
    {
      value: 'English Proficiency & Effective Communication',
      label: 'English Proficiency & Effective Communication',
    },
    {
      value: 'Effective Presentation',
      label: 'Effective Presentation',
    },
    {
      value: 'Public Speaking',
      label: 'Public Speaking',
    },
    {
      value: 'Personality Development in Local Government',
      label: 'Personality Development in Local Government',
    },
    {
      value: 'Time Management',
      label: 'Time Management',
    },
    {
      value: 'Obtaining and Providing Positive Work Attitude',
      label: 'Obtaining and Providing Positive Work Attitude',
    },
    {
      value: 'Strategic HRM: Providing Assistance to Planning and Goal Setting',
      label: 'Strategic HRM: Providing Assistance to Planning and Goal Setting',
    },
    {
      value: 'Project Management',
      label: 'Project Management',
    },
    {
      value: 'Data Management & Analysis/Chart',
      label: 'Data Management & Analysis/Chart',
    },
    {
      value: 'Data Privacy',
      label: 'Data Privacy',
    },
    {
      value: 'Critical Thinking',
      label: 'Critical Thinking',
    },
    {
      value: 'Innovative Thinking',
      label: 'Innovative Thinking',
    },
    {
      value: 'Design Thinking',
      label: 'Design Thinking',
    },
  ]

  const subCategFunctional = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Program One',
      label: 'Program One',
    },
    {
      value: 'Program Two',
      label: 'Program Two',
    },
    {
      value: 'Program Three',
      label: 'Program Three',
    },
    {
      value: 'Program Four',
      label: 'Program Four',
    },
  ]

  const subCategAdditional = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Program One',
      label: 'Program One',
    },
    {
      value: 'Program Two',
      label: 'Program Two',
    },
    {
      value: 'Program Three',
      label: 'Program Three',
    },
    {
      value: 'Program Four',
      label: 'Program Four',
    },
  ]

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * @description Fetches the training announcements from the database
   * @returns {Promise<void>}
   * @function getAllRegistrations
   * @async
   * @param {void}
   */
  const getAllRegistrations = async () => {
    try {
      let token = null // Initialize the token variable
      let foundItems = []

      do {
        const result = await API.graphql(
          graphqlOperation(listTrainingRegistrations, {
            limit: 1000,
            nextToken: token,
          })
        )

        foundItems = [
          ...foundItems,
          ...result.data.listTrainingRegistrations.items,
        ]
        token = result.data.listTrainingRegistrations.nextToken // Update the token with the nextToken from the result
      } while (token)

      // setAllRegistrations(foundItems)
    } catch (err) {
      console.log(err)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * @description Fetches the training announcements from the database
   * @returns {Promise<void>}
   * @function getAllAnnouncements
   * @async
   * @param {void}
   */
  const getAllAnnouncements = async () => {
    try {
      let token = null // Initialize the token variable
      let foundItems = []

      do {
        const result = await API.graphql(
          graphqlOperation(listTrainingAnnouncements, {
            limit: 1000,
            nextToken: token,
          })
        )

        foundItems = [
          ...foundItems,
          ...result.data.listTrainingAnnouncements.items,
        ]

        token = result.data.listTrainingAnnouncements.nextToken // Update the token with the nextToken from the result
      } while (token)

      do {
        const result = await API.graphql(
          graphqlOperation(listOnlineTrainingAnnouncements, {
            limit: 1000,
            nextToken: token,
          })
        )

        foundItems = [
          ...foundItems,
          ...result.data.listOnlineTrainingAnnouncements.items,
        ]

        token = result.data.listOnlineTrainingAnnouncements.nextToken // Update the token with the nextToken from the result
      } while (token)

      setCourses(foundItems)
      let holdEvents = []

      foundItems.forEach((i) => {
        if (i.id == 'c94e7931-a84d-4a4a-9544-63fb138a30d7') {
          holdEvents.push({
            id: i.id,
            title: i.title ? i.title : i.date,
            start: '2022-11-10T08:00:00+08:00',
            end: '2022-11-11T17:00:00+08:00',
            color: i.sectionTitle ? 'green' : '',
          })
        } else {
          if (i.id == '2018ebe8-d2ae-414d-92dd-6481f9d75be3') {
            const letterItem = i

            ;['a', 'b', 'c', 'd', 'e'].forEach((letter) => {
              if (letter == 'a') {
                holdEvents.push({
                  id: letterItem.id,
                  title: letterItem.title ? letterItem.title : letterItem.date,
                  start: letterItem.date,
                  color: i.sectionTitle ? 'green' : '',
                })
              } else if (letter == 'b') {
                holdEvents.push({
                  id: letterItem.id,
                  title: letterItem.title ? letterItem.title : letterItem.date,
                  start: moment(letterItem.date)
                    .add(7, 'days')
                    .format('YYYY-MM-DD'),
                  color: i.sectionTitle ? 'green' : '',
                })
              } else if (letter == 'c') {
                holdEvents.push({
                  id: letterItem.id,
                  title: letterItem.title ? letterItem.title : letterItem.date,
                  start: moment(letterItem.date)
                    .add(14, 'days')
                    .format('YYYY-MM-DD'),
                  color: i.sectionTitle ? 'green' : '',
                })
              } else if (letter == 'd') {
                holdEvents.push({
                  id: letterItem.id,
                  title: letterItem.title ? letterItem.title : letterItem.date,
                  start: moment(letterItem.date)
                    .add(21, 'days')
                    .format('YYYY-MM-DD'),
                  color: i.sectionTitle ? 'green' : '',
                })
              } else {
                holdEvents.push({
                  id: letterItem.id,
                  title: letterItem.title ? letterItem.title : letterItem.date,
                  start: moment(letterItem.date)
                    .add(28, 'days')
                    .format('YYYY-MM-DD'),
                  color: i.sectionTitle ? 'green' : '',
                })
              }
            })
          } else {
            // newer training with the arrayOfDates will fall on this
            if (i.arrayOfDates?.length) {
              const parseArrayOfDates = JSON.parse(i.arrayOfDates)
              const consecutiveRanges = []

              // Function to check if two dates are consecutive
              const areConsecutive = (date1, date2) => {
                const diff = new Date(date2) - new Date(date1)
                return diff === 24 * 60 * 60 * 1000 // Difference is 1 day
              }

              // Loop through the sorted dates to identify consecutive ranges
              for (let j = 0; j < parseArrayOfDates.length; j++) {
                const currentRange = [parseArrayOfDates[j]]

                while (
                  j + 1 < parseArrayOfDates.length &&
                  areConsecutive(parseArrayOfDates[j], parseArrayOfDates[j + 1])
                ) {
                  currentRange.push(parseArrayOfDates[j + 1])
                  j++
                }

                consecutiveRanges.push(currentRange)
              }
              consecutiveRanges.forEach((range) => {
                const start = range[0]
                const endDate = new Date(range[range.length - 1])
                const end = range.length > 1 ? new Date(endDate) : undefined

                // Add 1 day to the end date conditionally
                if (range.length > 1) {
                  end.setDate(end.getDate() + 1)
                }

                const formattedEnd = end
                  ? end.toISOString().split('T')[0]
                  : undefined

                holdEvents.push({
                  id: i.id,
                  title: i.title ? i.title : start,
                  start,
                  end: formattedEnd,
                  color: i.sectionTitle ? 'green' : '',
                })
              })
              return
            }
            // Old Training without arrayOfDates will fall on this
            holdEvents.push({
              id: i.id,
              title: i.title ? i.title : i.date,
              start: i.date,
            })
          }
        }
      })

      setAllAnnouncements(holdEvents)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllAnnouncements()
  }, [])

  const handleCancel = () => {
    setVisibleAnnouncement(false)
    setAnnounceUpload([])
    setAnnounceDate(null)
    setTpData(null)
    setCdData(null)
    setCourseTitle(null)
    setShowPostAssessment(false)
    setSelectedTraining(null)
    setIsLink(false)
    setIsEndorsementLetterRequired(null)
    setIsPassPreAssessmentRequired(null)
    setListOfTrainings([
      {
        value: '',
        date: '',
        label: 'Select training',
        title: 'Select training',
        disabled: true,
      },
    ])
    setAnnouceStartTime(null)
    setAnnouceEndTime(null)
    setPTDateOpen(null)
    setPTTimeOpen(null)
    setPTDateClose(null)
    setPTTimeClose(null)
    setParticipantLimit('')
    setDepartmentsLimitValue({})
    setGetAssessmentID(null)
    setEndorsementLetterID(null)
  }

  const showUploadPubMat = () => {
    setVisibleAnnouncement(true)
  }

  const showUploadOnlineTraining = () => {
    setVisibleAnnouncementOnline(true)
  }

  const closeUploadPubMat = () => {
    setVisibleAnnouncement(false)
    setAnnounceUpload([])
    setAnnounceDate(null)
    setTpData(null)
    setCdData(null)
    setCourseTitle(null)
    setAnnouceStartTime(null)
    setAnnouceEndTime(null)
    setPTDateOpen(null)
    setPTTimeOpen(null)
    setPTDateClose(null)
    setPTTimeClose(null)
    setParticipantLimit('')
    setDepartmentsLimitValue({})
    setGetAssessmentID(null)
    setShowPostAssessment(false)
    setEndorsementLetterID(null)
  }
  const checkTimeIfValid = (a, b) => {
    const timeFormat = 'h:mm A'
    const parsedTimeA = moment(a, timeFormat)
    const parsedTimeB = moment(b, timeFormat)
    const isValidTimeA = parsedTimeA.isValid() // returns true if valid, false if not
    const isValidTimeB = parsedTimeB.isValid() // returns true if valid, false if not

    if (isValidTimeA && isValidTimeB) {
      return true
    } else {
      return false
    }
  }

  const handleSubmitTraining = async (e) => {
    e.preventDefault()
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })

      Promise.all(
        announceUpload.map((value) => {
          return new Promise(function (resolve, reject) {
            const params = {
              Bucket: 's3etnahris133956-dev',
              Key: `uploadedByAdmin/${value.name}`,
              Body: value,
            }

            const command = new PutObjectCommand(params)
            const uploadedByAdmin = s3.send(command)
            const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`
            resolve(url)
          })
        })
      )
        .then(async (res) => {
          if (courseSubCategory && courseCategory) {
            if (
              (showPostAssessment && getAssessmentID) ||
              !showPostAssessment
            ) {
              if (
                announceDate &&
                checkTimeIfValid(announceStartTime, announceEndTime)
              ) {
                if (
                  moment(`${pTDateOpen} ${pTTimeOpen}`).isValid() == true &&
                  moment(`${pTDateClose} ${pTTimeClose}`).isValid() == true
                ) {
                  if (participantLimit >= 1) {
                    if (cdData && tpData) {
                      const locations = res.map((item) => {
                        const oldLoc = item
                        return oldLoc
                      })
                      const input = {
                        date: announceDate[0],
                        arrayOfDates: JSON.stringify(announceDate),
                        trainingTimeStart: announceStartTime,
                        trainingTimeEnd: announceEndTime,
                        title: courseTitle,
                        location: JSON.stringify(locations),
                        courseDescription: cdData,
                        targetParticipants: tpData,
                        isOpenRegistration: true,
                        postTrainOpen: moment(
                          `${pTDateOpen} ${pTTimeOpen}`
                        ).format('ddd MMM D YYYY hh:mm:ss a'),
                        postTrainClose: moment(
                          `${pTDateClose} ${pTTimeClose}`
                        ).format('ddd MMM D YYYY hh:mm:ss a'),
                        courseCategory: courseCategory,
                        courseSubCategory: courseSubCategory,
                        hasAssessment: showPostAssessment,
                        participantLimit,
                        evalID: getEvalID,
                        isTestEvent,
                        assessmentID: getAssessmentID,
                        stepThreeID: endorsementLetterID,
                        limitPerDept: JSON.stringify([departmentsLimitValue]),
                        parentTrainingID: selectedTraining,
                        isEndorsementRequired: isEndorsementLetterRequired,
                        additionalTexts: textDisplayBeforeTrainingOpen,
                        isSeminar,
                        isPassPreAssessmentRequired,
                        preAssessmentScore,
                      }

                      const adminUpload = await API.graphql(
                        graphqlOperation(createTrainingAnnouncement, { input })
                      )
                      if (adminUpload) {
                        //Update the parent training if this is going to be link with other trainings
                        if (selectedTraining) {
                          //Get the training Id of the newly created training
                          const newTrainingId =
                            adminUpload.data.createTrainingAnnouncement.id
                          let existingTrainingLinked = trainings
                            .filter((x) => x.id === selectedTraining)
                            .map((x) =>
                              JSON.parse(x.linkedTrainingAnnouncementIDs)
                            )[0]

                          existingTrainingLinked =
                            existingTrainingLinked != null
                              ? existingTrainingLinked
                              : []

                          await API.graphql(
                            graphqlOperation(updateTrainingAnnouncement, {
                              input: {
                                id: selectedTraining,
                                linkedTrainingAnnouncementIDs: JSON.stringify([
                                  ...existingTrainingLinked,
                                  newTrainingId,
                                ]),
                              },
                            })
                          )
                        }

                        notification['success']({
                          message: 'Success',
                          description: 'Training announcement created.',
                        })
                        setVisibleAnnouncement(false)
                        setAnnounceUpload([])
                        setAnnounceDate(null)
                        setTpData(null)
                        setCdData(null)
                        setCourseTitle(null)
                        getAllAnnouncements()
                        fetchTrainings()
                        fetchOnlineTrainings()

                        setAnnouceStartTime(null)
                        setAnnouceEndTime(null)
                        setPTDateOpen(null)
                        setPTTimeOpen(null)
                        setPTDateClose(null)
                        setPTTimeClose(null)
                        setParticipantLimit('')
                        setDepartmentsLimitValue({})
                        setGetAssessmentID(null)
                        setEndorsementLetterID(null)
                      }
                    } else {
                      notification['error']({
                        message: 'Error',
                        description:
                          'Please enter a Course Description and Target Participants.',
                      })
                    }
                  } else {
                    notification['error']({
                      message: 'Error',
                      description: 'Participant Limit cannot be Zero.',
                    })
                  }
                } else {
                  notification['error']({
                    message: 'Error',
                    description:
                      'Please enter correctly the Post Training Opening and Closing Dates/Times.',
                  })
                }
              } else {
                notification['error']({
                  message: 'Error',
                  description:
                    'Please enter correctly the Training Date and Times.',
                })
              }
            } else {
              notification['error']({
                message: 'Error',
                description: 'Please select or create assesstment.',
              })
            }
          } else {
            notification['error']({
              message: 'Error',
              description:
                'Both Course Category and Course Sub-category are required.',
            })
          }
        })
        .catch((err) => console.log(err))
    } catch (error) {
      console.log(error)
    }
  }

  const handleDate = (selectedDates) => {
    const formattedDates = selectedDates.map((date) =>
      date.format('YYYY-MM-DD')
    )

    setAnnounceDate(formattedDates)
  }

  const handleTrainingStartTime = (time, timeString) => {
    setAnnouceStartTime(timeString)
  }

  const handleTrainingEndTime = (time, timeString) => {
    setAnnouceEndTime(timeString)
  }

  const handlePostTrainOpenDate = (date, dateString) => {
    setPTDateOpen(dateString)
  }

  const handlePostTrainOpenTime = (time, timeString) => {
    setPTTimeOpen(timeString)
  }

  const handlePostTrainCloseDate = (date, dateString) => {
    setPTDateClose(dateString)
  }

  const handlePostTrainCloseTime = (time, timeString) => {
    setPTTimeClose(timeString)
  }

  const showUploadNames = announceUpload.map((o, index) => {
    return (
      <span className={'fw-bold'} key={index}>
        {o.name}
      </span>
    )
  })

  const handleTargetParticipants = (content) => {
    setTpData(content)
  }

  const handleAdditionalText = (content) => {
    setTextDisplayBeforeTrainingOpen(content)
  }

  const handleCourseDesc = (content) => {
    setCdData(content)
  }

  const handleCourseTitle = (e) => {
    setCourseTitle(e.target.value)
  }

  const handleCourseCategory = (e) => {
    setCourseCategory(e)
  }
  const handleCourseSubCategory = (e) => {
    setCourseSubCategory(e)
  }

  const eventClick = (e) => {
    const publicId = e.event._def.publicId
    const courseFound = courses.filter((c) => {
      return c.id === publicId
    })

    setViewCourse(courseFound[0])
    setIsOpenRegistration(courseFound[0].isOpenRegistration)
    setOpenView(true)
  }

  const handleCourseModal = (e) => {
    setOpenView(e)
    setViewCourse(null)
  }

  const handleTrigger = () => {
    getAllAnnouncements()
  }

  const handleHover = (arg) => {
    try {
      tippy(arg.el, {
        content: arg.event._def.title,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleRefreshCalendar = () => {
    getAllAnnouncements()
  }

  const handleHasAssessment = (e) => {
    setShowPostAssessment(e.target.value)
    if (e.target.value === false) {
      setGetAssessmentID(null)
    }
  }

  const handleLimitParticipants = (e) => {
    setParticipantLimit(e.target.value)
  }

  const handleTestEventInput = (e) => {
    setIsTestEvent(e.target.value)
  }

  const handleIsSeminarInput = (e) => {
    setIsSeminar(e.target.value)
  }

  const handleIsPassPreAssessmentRequiredInput = (e) => {
    setIsPassPreAssessmentRequired(e.target.value)
    setShowPostAssessment(e.target.value === 'true' ? true : false)
  }

  const handleSelectTrainingToLink = (value) => {
    setSelectedTraining(value)
  }

  const handlePreAssessmentScore = (e) => {
    setPreAssessmentScore(e.target.value)
  }

  return (
    <>
      <div
        className=''
        style={{
          padding: 24,
        }}
      >
        <div className='d-flex justify-content-center'>
          <div className='d-flex justify-content-end col-md-7 col-12'>
            <div className='pb-5 pt-md-2'>
              <Tooltip
                placement='right'
                title={'Upload the PUBMAT and Create Training'}
              >
                <Button
                  className='bg-success text-white rounded'
                  onClick={showUploadPubMat}
                >
                  Upload Poster
                </Button>
              </Tooltip>
              <Modal
                maskClosable={false}
                destroyOnClose={true}
                title='Create Announcement'
                visible={visibleAnnouncement}
                footer={null}
                onCancel={handleCancel}
                width={800}
              >
                <form onSubmit={handleSubmitTraining}>
                  <div className={'border mb-3'}>
                    <Row justify='center'>
                      <span>
                        This part is for creating a test training announcements
                      </span>
                    </Row>
                    <Row justify='center'>
                      <div className='reg1-row'>
                        <span className='loginInput-label'>
                          Are you uploading a Test Event?
                        </span>
                        <Row justify='center'>
                          <em>For Testing purposes only</em>
                        </Row>
                      </div>
                    </Row>
                    <Row onChange={handleTestEventInput} justify='center'>
                      <div className={'d-flex align-items-center me-3'}>
                        <label
                          htmlFor='test-event-yes'
                          className={'loginInput-label m-0'}
                          style={{ marginBottom: 10, marginRight: 10 }}
                        >
                          YES
                        </label>
                        <input
                          value={true}
                          className={'ms-2 test-event'}
                          name='test-event'
                          id='test-event-yes'
                          type='radio'
                        />
                      </div>

                      <div className={'d-flex align-items-center ms-3'}>
                        <label
                          htmlFor='test-event-no'
                          className={'loginInput-label m-0'}
                          style={{ marginBottom: 10, marginRight: 10 }}
                        >
                          NO
                        </label>
                        <input
                          defaultChecked={true}
                          value={false}
                          className={'ms-2 test-event'}
                          name='test-event'
                          id='test-event-no'
                          type='radio'
                        />
                      </div>
                    </Row>

                    <Row justify='center'>
                      <span>
                        This part is for creating a test training announcements
                      </span>
                    </Row>
                  </div>

                  <Row className='mt-3' justify='center'>
                    <div className='reg1-row'>
                      <span className='loginInput-label'>
                        Are you uploading a Seminar?
                      </span>
                    </div>
                  </Row>

                  <Row
                    className='mb-3'
                    onChange={handleIsSeminarInput}
                    justify='center'
                  >
                    <div className={'d-flex align-items-center me-3'}>
                      <label
                        htmlFor='isSeminarYes'
                        className={'loginInput-label m-0'}
                        style={{ marginBottom: 10, marginRight: 10 }}
                      >
                        YES
                      </label>
                      <input
                        value={true}
                        className={'ms-2 test-event'}
                        name='seminar'
                        id='isSeminarYes'
                        type='radio'
                      />
                    </div>

                    <div className={'d-flex align-items-center ms-3'}>
                      <label
                        htmlFor='isSeminarNo'
                        className={'loginInput-label m-0'}
                        style={{ marginBottom: 10, marginRight: 10 }}
                      >
                        NO
                      </label>
                      <input
                        defaultChecked={true}
                        value={false}
                        className={'ms-2 test-event'}
                        name='seminar'
                        id='isSeminarNo'
                        type='radio'
                      />
                    </div>
                  </Row>
                  <div className='d-flex flex-column align-items-center justify-content-center mb-3'>
                    <span className='loginInput-label'>
                      Do you want this required the Endorsement Letter of this
                      training?
                    </span>
                    <div className='d-flex gap-2'>
                      <label
                        className={'loginInput-label m-0'}
                        htmlFor='isEndorsementLetterRequiredYes'
                      >
                        YES
                      </label>
                      <input
                        value={isEndorsementLetterRequired}
                        onChange={() => setIsEndorsementLetterRequired('yes')}
                        type='radio'
                        id='isEndorsementLetterRequiredYes'
                        name='isEndorsementLetterRequired'
                      />

                      <label
                        className='ms-3 loginInput-label'
                        htmlFor='isEndorsementLetterRequiredNo'
                      >
                        NO
                      </label>
                      <input
                        value={isEndorsementLetterRequired}
                        defaultChecked={isEndorsementLetterRequired == null}
                        onChange={() => setIsEndorsementLetterRequired(null)}
                        type='radio'
                        id='isEndorsementLetterRequiredNo'
                        name='isEndorsementLetterRequired'
                      />
                    </div>
                  </div>

                  <div className='d-flex flex-column align-items-center justify-content-center mb-3'>
                    <span className='loginInput-label'>
                      Do you want to link this training?
                    </span>
                    <div className='d-flex gap-2'>
                      <label
                        className='ms-0 loginInput-label'
                        htmlFor='linkTrainingYes'
                      >
                        YES
                      </label>
                      <input
                        value={isLink}
                        onChange={() => setIsLink(true)}
                        type='radio'
                        id='linkTrainingYes'
                        name='linkTraining'
                      />

                      <label
                        className='ms-3 loginInput-label'
                        htmlFor='linkTrainingNo'
                      >
                        NO
                      </label>
                      <input
                        value={isLink}
                        defaultChecked={isLink == false}
                        onChange={() => setIsLink(false)}
                        type='radio'
                        id='linkTrainingNo'
                        name='linkTraining'
                      />
                    </div>
                    {isLink && (
                      <div className='w-100'>
                        <label htmlFor='selectTraining'>
                          Select training to be link
                        </label>
                        <Select
                          id='selectTraining'
                          className='w-100'
                          value={selectedTraining}
                          showSearch
                          placeholder='Select a training'
                          optionFilterProp='children'
                          onChange={handleSelectTrainingToLink}
                          filterSort={compareOptions}
                          filterOption={(input, option) =>
                            (option?.label ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={listOfTrainings}
                        />
                      </div>
                    )}
                  </div>

                  <Row className='mt-3' justify='center'>
                    <div className='reg1-row'>
                      <span className='loginInput-label'>
                        Do participants need to pass the pre-assessment test
                        before registering for the training?
                      </span>
                    </div>
                  </Row>
                  <Row
                    className='mb-3'
                    onChange={handleIsPassPreAssessmentRequiredInput}
                    justify='center'
                  >
                    <div className={'d-flex align-items-center me-3'}>
                      <label
                        htmlFor='isPassPreAssessmentRequired'
                        className={'loginInput-label m-0'}
                        style={{ marginBottom: 10, marginRight: 10 }}
                      >
                        YES
                      </label>
                      <input
                        value={true}
                        className={'ms-2 test-event'}
                        name='passPreAssessment'
                        id='isPassPreAssessmentRequired'
                        type='radio'
                      />
                    </div>

                    <div className={'d-flex align-items-center ms-3'}>
                      <label
                        htmlFor='isPassPreAssessmentRequiredNo'
                        className={'loginInput-label m-0'}
                        style={{ marginBottom: 10, marginRight: 10 }}
                      >
                        NO
                      </label>
                      <input
                        defaultChecked={true}
                        value={false}
                        className={'ms-2 test-event'}
                        name='passPreAssessment'
                        id='isPassPreAssessmentRequiredNo'
                        type='radio'
                      />
                    </div>
                  </Row>

                  {isPassPreAssessmentRequired === 'true' ? (
                    <Row justify='center mb-3'>
                      <Col span={12}>
                        <Row justify='center'>
                          <label
                            htmlFor='preAssessmentScore'
                            className='loginInput-label text-center me-3 mb-2 mt-2'
                          >
                            Pre-assessment score required:
                          </label>
                          <Input
                            value={preAssessmentScore}
                            type='number'
                            min={1}
                            style={{ width: 70 }}
                            onChange={handlePreAssessmentScore}
                            id='preAssessmentScore'
                            name='preAssessmentScore'
                          />
                        </Row>
                      </Col>
                    </Row>
                  ) : null}

                  <div className='reg1-row'>
                    <span>
                      <label
                        htmlFor='date-event'
                        className='loginInput-label col-md-3 col-12'
                        style={{ marginBottom: 10, marginRight: 10 }}
                      >
                        Select Date of Event:
                      </label>

                      <DatePicker
                        onChange={handleDate}
                        id='date-event'
                        sort
                        multiple
                        format={'YYYY/MM/DD'}
                        plugins={[<DatePanel />]}
                        style={{ height: '30px', width: '400px' }}
                      />
                    </span>
                  </div>
                  <div className='reg1-row mt-3'>
                    <span>
                      <label
                        htmlFor='Train-openTime'
                        className='loginInput-label col-md-3 col-12'
                        style={{ marginBottom: 10, marginRight: 10 }}
                      >
                        Select Start Time of Event:
                      </label>

                      <TimePicker
                        id='Train-openTime'
                        name='Train-openTime'
                        use12Hours
                        format='h:mm A'
                        onChange={handleTrainingStartTime}
                      />
                    </span>
                  </div>
                  <div className='reg1-row mt-3'>
                    <span>
                      <label
                        htmlFor='Train-endTime'
                        className='loginInput-label col-md-3 col-12'
                        style={{ marginBottom: 10, marginRight: 10 }}
                      >
                        Select End Time of Event:
                      </label>

                      <TimePicker
                        id='Train-endTime'
                        name='Train-endTime'
                        use12Hours
                        format='h:mm A'
                        onChange={handleTrainingEndTime}
                      />
                    </span>
                  </div>

                  <Row>
                    <label
                      htmlFor='course-title'
                      className='loginInput-label'
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      Course Title
                    </label>
                    <Input
                      onChange={handleCourseTitle}
                      id='course-title'
                      name='course-title'
                    />
                  </Row>

                  <Row>
                    <label
                      htmlFor='course-category'
                      className='loginInput-label'
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      Course Category
                    </label>
                    <Col span={24}>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        id='course-category'
                        name='course-category'
                        showSearch
                        placeholder='Select a category'
                        optionFilterProp='children'
                        onChange={handleCourseCategory}
                        // onSearch={onSearchCategory}
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={[
                          {
                            value: '',
                            label: '',
                            disabled: true,
                          },
                          {
                            value: 'CORE COMPETENCIES',
                            label: 'CORE COMPETENCIES',
                          },
                          {
                            value: 'LEADERSHIP COMPETENCIES',
                            label: 'LEADERSHIP COMPETENCIES',
                          },
                          {
                            value: 'ORGANIZATIONAL COMPETENCIES',
                            label: 'ORGANIZATIONAL COMPETENCIES',
                          },
                          {
                            value: 'FUNCTIONAL/TECHNICAL',
                            label: 'FUNCTIONAL/TECHNICAL',
                          },
                          {
                            value: 'Additional Training Programs',
                            label: 'Additional Training Programs',
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <label
                      htmlFor='course-category'
                      className='loginInput-label'
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      Course Sub-category
                    </label>
                    <Col span={24}>
                      <Select
                        style={{
                          width: '100%',
                        }}
                        id='course-sub-category'
                        name='course-sub-category'
                        showSearch
                        placeholder='Select a sub-category'
                        optionFilterProp='children'
                        onChange={handleCourseSubCategory}
                        // onSearch={onSearchCategory}
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={optionSubCategory}
                      />
                    </Col>
                  </Row>

                  <div className='reg1-row'>
                    <span>
                      <label
                        htmlFor='pubmat'
                        className='loginInput-label'
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        PUBMAT Upload Here
                      </label>
                      <Input
                        id='pubmat'
                        name='pubmat'
                        multiple
                        type='file'
                        accept='image/jpeg,image/gif,image/png,application/pdf,video/mp4,video/x-m4v,video/*'
                        onChange={(e) => {
                          setAnnounceUpload([...e.target.files])
                        }}
                      />
                      {announceUpload.length > 0 ? (
                        <div className={'d-flex flex-column'}>
                          {showUploadNames}
                        </div>
                      ) : null}
                      <Row className='flex-column'>
                        <label
                          htmlFor='course-description'
                          className='loginInput-label'
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          Course Description
                        </label>
                        <ReactQuill
                          onChange={handleCourseDesc}
                          id='course-description'
                          name='course-description'
                          className='mb-5 mt-1'
                          style={{ height: 130 }}
                        />
                      </Row>
                      <Row className='flex-column'>
                        <label
                          htmlFor='target-participants'
                          className='loginInput-label'
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          Target Participants
                        </label>
                        <ReactQuill
                          onChange={handleTargetParticipants}
                          id='target-participants'
                          name='target-participants'
                          className='mb-5 mt-1'
                          style={{ height: 130 }}
                        />
                      </Row>
                      <Row className='flex-column'>
                        <label
                          htmlFor='additonal-text'
                          className='loginInput-label'
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          Text to display before training open
                        </label>
                        <ReactQuill
                          onChange={handleAdditionalText}
                          id='additonal-text'
                          name='additonal-text'
                          className='mb-5 mt-1'
                          style={{ height: 130 }}
                        />
                      </Row>
                      <Row className='my-3'>
                        <Col span={15}>
                          <label
                            htmlFor='limit-participants'
                            className='loginInput-label'
                            style={{ marginTop: 10, marginBottom: 10 }}
                          >
                            Number of Participants{' '}
                            <small style={{ fontSize: 10 }}>
                              <em>(limit the number of who can attend)</em>
                            </small>
                          </label>
                        </Col>
                        <Col span={9}>
                          <Input
                            // onChange={handleLimitParticipants}
                            onClick={() => setManageParticipantsModal(true)}
                            id='limit-participants'
                            name='limit-participants'
                            type='number'
                            value={participantLimit}
                            min={1}
                          />
                        </Col>
                      </Row>
                    </span>
                  </div>

                  <ManageTrainingsNumberOfParticipants
                    departmentOptions={departmentOptions}
                    departments={departments}
                    setDepartments={setDepartments}
                    listOfDepartmentsOrignal={listOfDepartmentsOrignal}
                    setDepartmentsLimitValue={setDepartmentsLimitValue}
                    departmentsLimitValue={departmentsLimitValue}
                    manageParticipantsModal={manageParticipantsModal}
                    setManageParticipantsModal={setManageParticipantsModal}
                    participantLimit={participantLimit}
                    setParticipantLimit={setParticipantLimit}
                  />

                  <div>
                    <EndorsementLetter
                      userData={userData}
                      setEndorsementLetterID={setEndorsementLetterID}
                    />
                    {endorsementLetterID && (
                      <Row className='mt-3'>
                        <span className='loginInput-label'>
                          Text ID:{' '}
                          <em className='asterisk'>{endorsementLetterID}</em>
                        </span>
                      </Row>
                    )}
                  </div>

                  <div className='reg1-row mt-3'>
                    <Row justify='center'>
                      <span className='loginInput-label'>
                        Will it have a Pre-assessment and Post-assessment?
                      </span>
                    </Row>

                    <Row className='mt-3' justify='space-around'>
                      <Radio.Group
                        style={{ width: '100%' }}
                        onChange={handleHasAssessment}
                        value={showPostAssessment}
                      >
                        <Row className='mt-3' justify='space-around'>
                          <Col>
                            <Radio className='loginInput-label' value={true}>
                              YES
                            </Radio>
                          </Col>
                          <Col>
                            <Radio className='loginInput-label' value={false}>
                              NO
                            </Radio>
                          </Col>
                        </Row>
                      </Radio.Group>
                    </Row>
                  </div>

                  {showPostAssessment === 'true' ||
                  showPostAssessment === true ? (
                    <>
                      <Row className='mt-3 w-100' justify='center'>
                        <AssessmentMaker
                          setGetAssessmentID={(e) => setGetAssessmentID(e)}
                          userData={userData}
                        />
                      </Row>
                      <Row className='mt-3 w-100' justify='center'>
                        <SelectAssessmentQuestion
                          setGetAssessmentID={setGetAssessmentID}
                        />
                      </Row>
                    </>
                  ) : null}

                  <Row className='mt-3' justify='space-around'>
                    <EvaluationMaker
                      userData={userData}
                      setGetEvalID={(e) => setGetEvalID(e)}
                    />
                    <SelectEvaluationQuestion setGetEvalID={setGetEvalID} />
                  </Row>

                  {showPostAssessment === 'true' ||
                  showPostAssessment === true ? (
                    <Row className='mt-3'>
                      <span className='loginInput-label'>
                        Assessment Questionnaire ID:{' '}
                        <em className='asterisk'>
                          {getAssessmentID ? getAssessmentID : 'none'}
                        </em>
                      </span>
                    </Row>
                  ) : null}

                  {getEvalID ? (
                    <>
                      <Row className='mt-3'>
                        <span className='loginInput-label'>
                          Evaluation Questionnaire ID:{' '}
                          <em className='asterisk'>{getEvalID}</em>
                        </span>
                      </Row>
                    </>
                  ) : (
                    <>
                      <Row className='mt-3'>
                        <span className='loginInput-label'>
                          Evaluation Questionnaire ID:{' '}
                          <em className='asterisk'>
                            Select your questionnaire or create a new one.
                          </em>
                        </span>
                      </Row>
                    </>
                  )}

                  <>
                    <div className='reg1-row mt-3'>
                      <span>
                        <label
                          className='loginInput-label'
                          style={{ marginBottom: 10, marginRight: 10 }}
                        >
                          POST TRAINING OPEN
                        </label>
                      </span>
                    </div>

                    <div className='reg1-row mt-3'>
                      <span>
                        <label
                          htmlFor='postTrain-open'
                          className='loginInput-label'
                          style={{ marginBottom: 10, marginRight: 10 }}
                        >
                          Select Date Post Training will open:
                        </label>
                        <AntdDatePicker
                          id='postTrain-open'
                          name='postTrain-open'
                          onChange={handlePostTrainOpenDate}
                        />
                      </span>
                    </div>

                    <div className='reg1-row mt-3'>
                      <span>
                        <label
                          htmlFor='postTrain-openTime'
                          className='loginInput-label'
                          style={{ marginBottom: 10, marginRight: 10 }}
                        >
                          Select Time Post Training will open:
                        </label>

                        <TimePicker
                          id='postTrain-openTime'
                          name='postTrain-openTime'
                          use12Hours
                          format='h:mm:ss A'
                          onChange={handlePostTrainOpenTime}
                        />
                      </span>
                    </div>

                    <div className='reg1-row mt-3'>
                      <span>
                        <label
                          className='loginInput-label'
                          style={{ marginBottom: 10, marginRight: 10 }}
                        >
                          POST TRAINING CLOSE
                        </label>
                      </span>
                    </div>

                    <div className='reg1-row mt-3'>
                      <span>
                        <label
                          htmlFor='postTrain-close'
                          className='loginInput-label'
                          style={{ marginBottom: 10, marginRight: 10 }}
                        >
                          Select Date Post Training will close:
                        </label>
                        <AntdDatePicker
                          id='postTrain-close'
                          name='postTrain-close'
                          onChange={handlePostTrainCloseDate}
                        />
                      </span>
                    </div>

                    <div className='reg1-row mt-3'>
                      <span>
                        <label
                          htmlFor='postTrain-closeTime'
                          className='loginInput-label'
                          style={{ marginBottom: 10, marginRight: 10 }}
                        >
                          Select Time Post Training will close:
                        </label>

                        <TimePicker
                          id='postTrain-closeTime'
                          name='postTrain-closeTime'
                          use12Hours
                          format='h:mm:ss A'
                          onChange={handlePostTrainCloseTime}
                        />
                      </span>
                    </div>
                  </>

                  <div
                    className={'d-flex justify-content-end'}
                    style={{ marginTop: 20 }}
                  >
                    <Button
                      style={{ marginRight: 20 }}
                      onClick={closeUploadPubMat}
                    >
                      Cancel
                    </Button>
                    <Button
                      key='submit'
                      type='primary'
                      htmlType='submit'
                      disabled={announceUpload.length ? false : true}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </Modal>
            </div>
            <div className='pb-5 pt-md-2'>
              <Tooltip
                placement='right'
                title={'Upload the PUBMAT and Create Training'}
              >
                <Button
                  className='bg-success text-white rounded'
                  onClick={showUploadOnlineTraining}
                >
                  Upload Online Training
                </Button>
              </Tooltip>
              <UploadOnlineTrainings
                visibleAnnouncementOnline={visibleAnnouncementOnline}
                setVisibleAnnouncementOnline={(e) =>
                  setVisibleAnnouncementOnline(e)
                }
                fetchTrainings={fetchTrainings}
                getAllAnnouncements={getAllAnnouncements}
                otherFiles={otherFiles}
                departments={departments}
                setDepartments={(e) => setDepartments(e)}
                uploadedTrainingVideo={uploadedTrainingVideo}
                listOfDepartmentsOrignal={listOfDepartmentsOrignal}
                userData={userData}
              />
            </div>
          </div>
        </div>
        <Row justify='center' className='flex-column align-items-center gap-4'>
          <div className='col-md-7 col-12 user-calendar-show'>
            <FullCalendar
              style={{ objectFit: 'contain' }}
              plugins={[dayGridPlugin, interactionPlugin]}
              initialView='dayGridMonth'
              // dateClick={e => handleDateClick(e)}
              selectable={false}
              events={allAnnouncements}
              eventClick={(e) => eventClick(e)}
              eventMouseEnter={(e) => handleHover(e)}
            />
          </div>
          <Row justify='center'>
            <div className='d-flex align-items-center flex-column gap-3 img-fluid'>
              {listOfUploadedSlides.map((filename) => (
                <img
                  key={filename}
                  src={`https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/${filename}`}
                  className={`col-md-7 col-12 https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/${filename}`}
                />
              ))}
            </div>
          </Row>
        </Row>
        <LmsViewCourseModal
          refresh={handleRefreshCalendar}
          triggerFetch={() => handleTrigger()}
          isOpenRegistration={isOpenRegistration}
          setIsOpenRegistration={(e) => setIsOpenRegistration(e)}
          viewCourse={viewCourse}
          openView={openView}
          setOpenView={(e) => handleCourseModal(e)}
          uploadedTrainingVideo={uploadedTrainingVideo}
          otherFiles={otherFiles}
          userData={userData}
        />
      </div>
    </>
  )
}
