/**
 * Title: MyTrainings.js
 * Description: This is a file that contains the components that show all the training programs
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/MyTrainings.js
 * Changes made:
 * - 10/23/2024 | Harry Lagunsad | Add prop types and fix post training message modal
 **/
import React, { useEffect, useState } from 'react'

import { Button, Col, Row } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import PropTypes from 'prop-types'

import { registrationByUserID } from '../../api/queries'
import HtmlParser from './html-parser.js'
import PostTrainingModal from './postTrainingModal.js'

export default function MyTrainings({ userData }) {
  const [upcomingPubmat, setUpcomingPubmat] = useState([])
  const [currentPageNew, setCurrentPageNew] = useState(0)
  const [currentShowsNew, setCurrentShowsNew] = useState([])

  const endIndexNew = currentPageNew * 5
  const startIndexNew = endIndexNew - 5

  useEffect(() => {
    const holdShowNew = upcomingPubmat
      .sort((a, b) => new Date(b.registeredAt) - new Date(a.registeredAt))
      .slice(startIndexNew, endIndexNew)
    setCurrentShowsNew([...currentShowsNew, ...holdShowNew])
  }, [currentPageNew])

  useEffect(() => {
    setTimeout(() => {
      handleLoadMoreNew()
    }, 2500)
  }, [])

  const getAllPubmats = async () => {
    try {
      let token = null
      let myRegisteredTrainings = []

      do {
        const result = await API.graphql(
          graphqlOperation(registrationByUserID, {
            userID: userData.id,
            nextToken: token,
          })
        )

        const { items, nextToken } = result.data.registrationByUserID
        myRegisteredTrainings = [...myRegisteredTrainings, ...items]
        token = nextToken
      } while (token)

      const pubmats = myRegisteredTrainings.map((item) => {
        return {
          ...item.training,
          registeredAt: item.createdAt,
        }
      })

      if (pubmats.length) {
        const newData = pubmats.filter((itemData) => {
          if (itemData.postTrainClose) {
            return itemData
          }
        })

        newData.sort(
          (a, b) => new Date(b.registeredAt) - new Date(a.registeredAt)
        )

        // remove test events in the Upcoming Training
        // use isTestEvent field to filter
        const removeTestEvents = newData.filter((h) => {
          let checkEvent

          if (h.isTestEvent === null) {
            checkEvent = false
          } else if (h.isTestEvent === true) {
            checkEvent = true
          } else {
            checkEvent = false
          }

          if (checkEvent === false) {
            return h
          }
        })

        setUpcomingPubmat(
          removeTestEvents.sort(
            (a, b) => new Date(b.registeredAt) - new Date(a.registeredAt)
          )
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllPubmats()
  }, [])

  useEffect(() => {
    document.getElementById('root').scrollTo(0, 0)
  }, [])

  const setNewPubmat = currentShowsNew.map((item) => {
    const sortByName = JSON.parse(item.location)

    return (
      <Col className={'next-card-margin'} key={item.id} span={24}>
        <Row justify='center'>
          <Col className='col-md-9 col-11'>
            <div className={'tPage-box-shadow p-3'}>
              <Col span={24}>
                <div className='d-md-flex'>
                  <Col className='p-0 d-md-none d-block'>
                    <Row
                      style={{ height: '100%', width: '100%' }}
                      justify='end'
                      align='center'
                    >
                      <div className={'d-flex align-items-center'}>
                        <img
                          alt=''
                          style={{ maxWidth: '100%' }}
                          src={sortByName.sort()[0]}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col className='mt-md-0 mt-3 col-md-8'>
                    <div className={'me-3'}>
                      {item.title.includes('Wave 2') ? (
                        <Row
                          id='wave-2'
                          className={'text-monts tPage-header'}
                          justify='start'
                        >
                          {item.title}
                        </Row>
                      ) : (
                        <Row
                          className={'text-monts tPage-header'}
                          justify='start'
                        >
                          {item.title}
                        </Row>
                      )}

                      <Row className={'text-monts tPage-categ'} justify='start'>
                        {'Description'}
                      </Row>

                      <Row className={'text-monts'} justify='start'>
                        <Col>
                          <HtmlParser
                            content={item?.courseDescription?.replace(
                              /\n/g,
                              '<br>'
                            )}
                          />
                        </Col>
                      </Row>

                      <Row className={'text-monts tPage-categ'} justify='start'>
                        {'Target Participants'}
                      </Row>

                      <Row className={'text-monts'} justify='start'>
                        <Col>
                          <HtmlParser
                            content={item?.targetParticipants?.replace(
                              /\n/g,
                              '<br>'
                            )}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <PostTrainingModal
                          userData={userData}
                          afterRegister={null}
                          trainingItem={item}
                          isECert={false}
                        />
                      </Row>
                    </div>
                  </Col>
                  <Col className='p-0 d-md-block d-none' span={8}>
                    <Row
                      style={{ height: '100%', width: '100%' }}
                      justify='end'
                      align='center'
                    >
                      <div className={'d-flex align-items-center'}>
                        <img
                          alt=''
                          style={{ maxWidth: '100%' }}
                          src={sortByName.sort()[0]}
                        />
                      </div>
                    </Row>
                  </Col>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Col>
    )
  })

  const handleLoadMoreNew = () => {
    setCurrentPageNew(currentPageNew + 1)
  }

  return (
    <>
      <div className='d-flex justify-content-center'>
        <h3 className='font-Mont p-4'>Trainings and Programs</h3>
      </div>

      <Row
        style={{ paddingTop: '16px', paddingBottom: '16px' }}
        justify='center'
      >
        <div style={{ width: 900 }}>
          {upcomingPubmat.length ? (
            <h4 className={'font-Mont'}>Registered Trainings</h4>
          ) : (
            <Row justify='center'>
              <span>No registered trainings</span>
            </Row>
          )}
        </div>
      </Row>
      <Row
        style={{ paddingTop: '16px', paddingBottom: '16px' }}
        justify='center'
      >
        {upcomingPubmat.length ? <>{setNewPubmat}</> : null}
      </Row>

      {currentShowsNew.length < upcomingPubmat.length &&
        currentShowsNew.length >= 5 && (
          <Row justify='center' className='mb-4'>
            <Button onClick={handleLoadMoreNew}>Load More</Button>
          </Row>
        )}
    </>
  )
}

MyTrainings.propTypes = {
  userData: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
}
