/**
 * Title: editEndorsementLetterText.js
 * Description: This is a file handles the editing endorsement letter component
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/editEndorsementLetterText.js
 **/

import { useEffect, useState } from 'react'
import { Button, Modal, Input, Popconfirm, notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import 'react-quill/dist/quill.snow.css'
import HtmlParser from './html-parser'
import { createStepThreeRegistration } from '../../api/mutations'
import ReactQuill from 'react-quill'
import { getStepThreeRegistration } from '../../api/queries'

export default function EditEndorsementLetterText({
  endorsementId,
  userData,
  setEndorsementLetterID,
}) {
  const [openEndorsementModal, setOpenEndorsementModal] = useState(false)
  const [endorsementToUpdate, setEndorsementToUpdate] = useState(null)
  const [openEditEndorsementModal, setOpenEditEndorsementModal] =
    useState(false)
  const [endorsementText, setEndorsementText] = useState('')

  const getEndorsement = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getStepThreeRegistration, {
          id: endorsementId,
        })
      )
      setEndorsementToUpdate(response.data.getStepThreeRegistration)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getEndorsement()
  }, [])

  const handleSaveEndorsement = async () => {
    try {
      if (!endorsementText) {
        return notification['info']({
          message: 'info',
          description: 'Please enter an endorsement letter',
        })
      }

      const response = await API.graphql(
        graphqlOperation(createStepThreeRegistration, {
          input: {
            textDisplay: endorsementText,
            userID: userData.id,
          },
        })
      )

      const updatedStepThree = response.data.createStepThreeRegistration
      setEndorsementLetterID(response.data.createStepThreeRegistration.id)
      setEndorsementToUpdate(updatedStepThree)
      setEndorsementText('')
      setOpenEditEndorsementModal(false)
    } catch (error) {
      console.error('Error updating StepThreeRegistration:', error)
    }
  }

  const handleEditEndorsement = () => {
    setEndorsementText(endorsementToUpdate.textDisplay)
    setOpenEditEndorsementModal(true)
  }

  const handleEndorsementLetter = () => {
    setOpenEditEndorsementModal(false)
    setEndorsementText('')
  }

  const handleEndorsementLetterChange = (newContent) => {
    setEndorsementText(newContent)
  }

  return (
    <div>
      <Button
        ghost
        type='primary'
        onClick={() => setOpenEndorsementModal(true)}
      >
        View endorsement
      </Button>

      {/* =============== Viewing of Endorsement letter modal =============== */}
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Endorsement letter'
        visible={openEndorsementModal}
        footer={null}
        onCancel={() => setOpenEndorsementModal(false)}
        width={800}
      >
        <div className='me-md-0'>
          <div className='carousel-inner'>
            <div className='col-10 offset-2'>
              <div className='d-flex justify-content-between col-md-10'>
                <h3 className='mb-5'>Endorsement Letter Preview</h3>
              </div>
              <div className='col-10'>
                <div className='text-center'>
                  <p className='text-center'>
                    <HtmlParser
                      content={endorsementToUpdate?.textDisplay?.replace(
                        /\n/g,
                        '<br>'
                      )}
                    />
                  </p>
                </div>
                <p className={'text-center'}>
                  You may edit your response to upload your endorsement letter
                  or send a hardcopy to the HRMD.
                </p>
                <label
                  htmlFor='endorsementLetter'
                  className='loginInput-label'
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  Upload Here
                </label>
                <Input disabled type='file' />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-end mt-5'>
            <Button
              onClick={() => setOpenEndorsementModal(false)}
              className='me-3'
              type='default'
            >
              Close
            </Button>
            <Button onClick={() => handleEditEndorsement()} type='primary'>
              Edit
            </Button>
          </div>
        </div>
      </Modal>

      {/* =============== Actual edit text modal =============== */}
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Create endorsement letter'
        visible={openEditEndorsementModal}
        footer={null}
        onCancel={() => setOpenEditEndorsementModal(false)}
        width={800}
      >
        <div className='d-flex flex-column gap-3'>
          <span className='loginInput-label'>
            Enter the endorsement letter:
          </span>
          <ReactQuill
            value={endorsementText}
            onChange={handleEndorsementLetterChange}
            id='target-participants'
            name='target-participants'
            style={{ height: 400 }}
          />
          <div className='ms-auto mt-5'>
            <Button
              className='me-3 bg-secondary text-white'
              onClick={handleEndorsementLetter}
              type='default'
            >
              Cancel
            </Button>
            <Button onClick={() => handleSaveEndorsement()} type='primary'>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
