/**
 * Title: OnlineTrainingNavigation.js
 * Description: This is a file that contains lms view course modal
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/OnlineTrainingNavigation.js
 * History:
 * - 09/23/2024 - John Bazil Valdez
 * - Remove resouce speaker tab in navigation for the CDP Batch 5
 **/

import React, { useEffect, useState } from 'react'
import { listOtherUploads } from '../../api/queries'
import { API } from 'aws-amplify'
import { DownloadOutlined } from '@ant-design/icons'
import Comments from './comments'
import PropTypes from 'prop-types'

/**
 * Renders the online training navigation component.
 *
 * @param {Object} training - The training object.
 * @returns {JSX.Element} The online training navigation component.
 */
export default function OnlineTrainingNavigation({
  training,
  speakerUpload,
  userData,
}) {
  const [selectedNav, setSelectedNav] = useState('overview')
  const [otherFiles, setOtherFiles] = useState([])
  const [base64Images, setBase64Images] = useState([])
  const [editImages, setEditImages] = useState([])
  /**
   * Handles the click event for the navigation items.
   * @param {Object} e - The event object.
   */
  const handleClickNav = (e) => {
    setSelectedNav(e.target.name)
  }

  /**
   * Fetches uploaded files and sets the state with the filtered files.
   * @returns {Promise<void>} A promise that resolves when the files are fetched and the state is updated.
   */
  const fetchUploadedFiles = async () => {
    try {
      const uploadedFilesData = await API.graphql({
        query: listOtherUploads,
        authMode: 'API_KEY',
      })
      const arrayItems = uploadedFilesData.data.listOtherUploads.items
      const mappedItems = arrayItems.map((e, i) => {
        return {
          ...e,
          value: e.id,
          label: e.filename,
        }
      })
      const filteredOtherFile = mappedItems.filter((item) =>
        training.additionalFiles.includes(item.id)
      )
      setOtherFiles(filteredOtherFile)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchUploadedFiles()
  }, [])

  useEffect(() => {
    if (speakerUpload) {
      // Clear the previous base64 images
      setBase64Images([])

      speakerUpload.forEach((file, index) => {
        const reader = new FileReader()

        reader.onloadend = () => {
          setBase64Images((prev) => [...prev, reader.result])
        }

        reader.readAsDataURL(file) // Convert file to Base64 string
      })
    }
  }, [speakerUpload])

  useEffect(() => {
    if (training.resourceSpeakerDetails) {
      const speakerDetails = JSON.parse(training.resourceSpeakerDetails)
      setEditImages(speakerDetails)
    }
  }, [])

  return (
    <div>
      <ul className='nav nav-tabs' id='myTab' role='tablist'>
        <li className='border-bottom'>
          <button
            onClick={handleClickNav}
            className={`nav-link ${selectedNav !== 'overview' && 'active'}`}
            name='overview'
            type='button'
            role='tab'
          >
            Overview
          </button>
        </li>
        <li>
          <button
            onClick={handleClickNav}
            className={`nav-link ${
              selectedNav !== 'additionalfiles' && 'active'
            }`}
            name='additionalfiles'
            type='button'
            role='tab'
          >
            Handout/s
          </button>
        </li>
        {training.id !== 'baec88e9-8bb6-47ed-b95c-b5dea39eb1cb' && (
          <li>
            <button
              onClick={handleClickNav}
              className={`nav-link ${
                selectedNav !== 'resourcespeaker' && 'active'
              }`}
              name='resourcespeaker'
              type='button'
              role='tab'
            >
              Resource speaker
            </button>
          </li>
        )}

        {/* <li>
          <button
            onClick={handleClickNav}
            className={`nav-link ${
              selectedNav !== 'announcement-reminder' && 'active'
            }`}
            name='announcement-reminder'
            type='button'
            role='tab'
          >
            Announcement and Reminder
          </button>
        </li> */}
        <li>
          <button
            onClick={handleClickNav}
            className={`nav-link ${selectedNav !== 'comments' && 'active'}`}
            name='comments'
            type='button'
            role='tab'
          >
            Q & A
          </button>
        </li>
      </ul>
      <div className='tab-content mt-2 d-flex flex-column px-2'>
        {selectedNav === 'overview' && (
          <>
            <div className='mt-3'>
              <h6>Description</h6>
              <p
                dangerouslySetInnerHTML={{
                  __html: training.courseDescription.replace(/\n/g, '<br>'),
                }}
              />
            </div>
            <div>
              <h6>Target pacticipants</h6>
              <p
                dangerouslySetInnerHTML={{
                  __html: training.targetParticipants.replace(/\n/g, '<br>'),
                }}
              />
            </div>
            <div>
              <h6>Opens on</h6>
              <p>
                {new Date(
                  JSON.parse(training.arrayOfDates)[0]
                ).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
              <h6>Closes on</h6>
              <p>
                {new Date(
                  JSON.parse(training.arrayOfDates)[
                    JSON.parse(training.arrayOfDates).length - 1
                  ]
                ).toLocaleDateString('en-US', {
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric',
                })}
              </p>
            </div>
            <div>
              <h6>Category</h6>
              <p>{training.courseCategory}</p>
              <h6>Sub category</h6>
              <p>
                <p>{training.courseSubCategory}</p>
              </p>
            </div>
          </>
        )}
        {selectedNav === 'additionalfiles' && (
          <div className='d-flex flex-column gap-2'>
            {otherFiles.map((item, index) => (
              <div
                key={index}
                className='d-flex justify-content-between border-bottom'
              >
                <h6>
                  {index + 1}. {item.label}
                </h6>
                <a
                  href={item.location}
                  target='_blank'
                  className='bg-success text-white py-1 px-2 rounded text-decoration-none d-flex align-items-center gap-2'
                >
                  <DownloadOutlined />
                  Download
                </a>
              </div>
            ))}
          </div>
        )}
        {selectedNav === 'resourcespeaker' && (
          <>
            {base64Images.length > 0 &&
              base64Images.map((base64, index) => (
                <img
                  className='img-fluid mt-3 h-75 w-75 mx-auto d-block'
                  key={index}
                  src={base64}
                  alt={`Uploaded File ${index}`}
                />
              ))}
            {!speakerUpload && training?.resourceSpeakerDetails ? (
              <img
                className='img-fluid mt-3 mx-auto d-block vh-100 w-auto'
                src={editImages}
                alt='Resource Speaker'
              />
            ) : null}
          </>
        )}
        {selectedNav === 'announcement-reminder' && (
          <div className='d-flex flex-column gap-2'>
            Annoucement and Reminder
          </div>
        )}
        {selectedNav === 'comments' && (
          <Comments training={training} userData={userData} />
        )}
      </div>
    </div>
  )
}

OnlineTrainingNavigation.propTypes = {
  training: PropTypes.shape({
    additionalFiles: PropTypes.arrayOf(PropTypes.string).isRequired,
    resourceSpeakerDetails: PropTypes.string,
    targetParticipants: PropTypes.string,
    courseDescription: PropTypes.string,
    arrayOfDates: PropTypes.string,
  }),
  speakerUpload: PropTypes.arrayOf(PropTypes.object),
  userData: PropTypes.object,
}
