/**
 * Title: createFormPinoy.js
 * Description: This is a file that contains the create form for the employee tagalog version
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Form/createFormPinoy.js
 **/

/*
 * Changes made:
 * - 2024.14.10 | Harry Lagunsad | fix sonarlint issues and add prop types
 */
import React from 'react'

import { Breadcrumb, notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import { Form } from '@formio/react'

import { createForm, updateUser } from '../../api/mutations'
import './form.css'

let loop = 0

export default class CreateFormPinoy extends React.Component {
  constructor() {
    super()
    this.state = { employeeId: '' }
  }

  componentDidMount() {
    loop = 0
  }

  handleSubmit = async (e) => {
    let draftOrFinish
    const user = this.props.user
    Swal.fire({
      title:
        'Ang iyong Electronic Training Needs Analysis ay record na namin online',
      text: 'Ang Final Copy ay hindi na maaring baguhin pa! At kung gusto mo pang baguhin ito pindutin ang Draft Copy! Salamat',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Final Copy',
      cancelButtonText: 'Draft Copy',
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
      .then((result) => {
        if (result.value) {
          draftOrFinish = '1'
          return API.graphql(
            graphqlOperation(createForm, {
              input: {
                dept: user.department,
                assignedDept: user.assignedDept,
                division: user.division,
                position: user.position,
                userID: user.id,
                isFinal: 'YES',
                form: JSON.stringify(e.data),
                finalSubmitDate: new Date(),
                isLanguage: '2',
              },
            })
          )
        } else {
          draftOrFinish = '0'
          return API.graphql(
            graphqlOperation(createForm, {
              input: {
                dept: user.department,
                assignedDept: user.assignedDept,
                division: user.division,
                position: user.position,
                userID: user.id,
                form: JSON.stringify(e.data),
                isLanguage: '2',
                isFinal: 'NO',
              },
            })
          )
        }
      })
      .then((nextRes) => {
        return API.graphql(
          graphqlOperation(updateUser, {
            input: {
              id: user.id,
              form: draftOrFinish,
              form2: '0',
            },
          })
        )
      })
      .then((use) => {
        function uuidv4() {
          try {
            const crypto = window.crypto || window.msCrypto
            const array = new Uint32Array(5)
            crypto.getRandomValues(array)

            return (
              array[0].toString(16).padStart(8, '0') +
              '-' +
              array[1].toString(16).padStart(4, '0') +
              '-4' +
              array[2].toString(12).padStart(3, '0') +
              '-y' +
              array[3].toString(12).padStart(3, '0') +
              '-' +
              array[4].toString(16).padStart(12, '0')
            )
          } catch (error) {
            console.log(error)
          }
        }
        this.props.refresh(uuidv4())
        notification['success']({
          message: 'Success',
          description: 'Your form has been submitted. Thank you!',
        })
        this.props.afterSubmit('viewForm')
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: "There's an error in submitting form.",
        })
        console.log(err)
      })
  }

  handleLoad = (e) => {
    try {
      const { user } = this.props

      if (loop <= 2 && user) {
        this.setState(
          (prevState) => ({
            submissionData: {
              ...prevState.submissionData,
              ...e.data,
              panelPanel2ColumnsText: user.lname,
              panelPanel2ColumnsText2: user.name,
              panelPanel2ColumnsText3: user.mname,
              panelPanel2ColumnsText4: user.suffix,
              panelPanel3Columns3Text:
                e.data?.panelPanel3Columns3Text ?? user.position,
              panelPanel3Columns2Select:
                e.data?.panelPanel3Columns2Select ?? user.assignedDept,
              panelPanel3Columns2Select2:
                e.data?.panelPanel3Columns2Select2 ?? user.division,
              panelPanel3Columns2Unit:
                e.data?.panelPanel3Columns2Unit ?? user.sectionUnit,
              panelPanel3ColumnsText: user.employeeIDNumber || '',
              panelPanel2Columns2Radio3:
                e.data?.panelColumnsGender ?? user.gender ?? 'others',
            },
          }),
          () => {
            loop += 1
          }
        )
      }
    } catch (err) {
      console.log(err)
      this.showErrorNotification()
    }
  }

  componentDidUpdate(prevProps) {
    const { updated, user } = this.props

    if (updated !== prevProps.updated && user) {
      try {
        this.setState((prevState) => ({
          submissionData: {
            ...prevState.submissionData,
            panelPanel3Columns2Select: user.assignedDept,
            panelPanel3Columns2Select2: user.division,
            panelPanel3Columns2Unit: user.sectionUnit,
            panelPanel3Columns3Text: user.position,
            panelPanel2ColumnsText: user.lname,
            panelPanel2ColumnsText2: user.name,
            panelPanel2ColumnsText3: user.mname,
            panelPanel2ColumnsText4: user.suffix,
            panelPanel3ColumnsText: user.employeeIDNumber,
            panelPanel2Columns2Radio3: user.gender ?? 'others',
          },
        }))
      } catch (err) {
        console.error('Error updating component:', err)
        this.showErrorNotification()
      }
    }
  }

  showErrorNotification() {
    notification.error({
      message: 'Error',
      description:
        'Something went wrong. Please contact System Admin for assistance.',
    })
  }

  render() {
    const user = this.props.user
    return (
      <>
        {user ? (
          <>
            <Breadcrumb>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Fill-out Form</Breadcrumb.Item>
            </Breadcrumb>
            <div className={'formio-create-page'}>
              <Form
                src='https://www.sparksoft-demo.com/formio/etnafilipinoversion'
                onChange={(e) => this.handleLoad(e)}
                onSubmit={(e) => this.handleSubmit(e)}
                submission={{ data: this.state.submissionData }}
              />
            </div>
          </>
        ) : null}
      </>
    )
  }
}

// prop types
CreateFormPinoy.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    department: PropTypes.string,
    assignedDept: PropTypes.string,
    division: PropTypes.string,
    position: PropTypes.string,
    lname: PropTypes.string,
    name: PropTypes.string,
    mname: PropTypes.string,
    suffix: PropTypes.string,
    sectionUnit: PropTypes.string,
    employeeIDNumber: PropTypes.string,
    gender: PropTypes.string,
  }),
  updated: PropTypes.bool,
  refresh: PropTypes.func,
  afterSubmit: PropTypes.func,
}
