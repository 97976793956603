/**
 * Title: convertNumToChar.js
 * Description: This is a file that contains the function for converting numbers to characters
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/Utilities/convertNumToChar.js
 **/

export function convertNumToChar(num) {
  const startingCharCode = 'a'.charCodeAt(0)
  const charCode = startingCharCode + num
  return String.fromCharCode(charCode).toUpperCase()
}
