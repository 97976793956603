/**
 * Title: uploadTrainings.js
 * Description: This is a file that contains the components for uploading training videos
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/uploadTrainingVideo.js
 **/

import { useState, useEffect } from 'react'

import { Auth } from 'aws-amplify'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

import { Button, Modal, Col, Row, notification, Input, Spin } from 'antd'
import { UploadOutlined } from '@ant-design/icons'

import { API, graphqlOperation } from 'aws-amplify'
import { createTrainingVideo } from '../../api/mutations'

import moment from 'moment'

export default function UploadTrainingVideo({ refresh }) {
  const [openView, setOpenView] = useState(false)
  const [videoFile, setVideoFile] = useState(null)

  const [disabledAfter, setDisabledAfter] = useState(false)

  const [isUploading, setIsUploading] = useState(false)

  const handleOpen = () => {
    setOpenView(true)
  }

  const handleClose = () => {
    setIsUploading(false)
    setOpenView(false)
    setVideoFile(null)
    setDisabledAfter(false)
  }

  const handleUploadVideo = async () => {
    try {
      if (videoFile) {
        const originalFile = videoFile[0]
        const modifiedName = 'input-' + originalFile.name
        const modifiedFile = new File([originalFile], modifiedName, {
          type: originalFile.type,
        })

        setIsUploading(true)
        setDisabledAfter(true)

        const credentials = await Auth.currentCredentials()
        const accessKeyId = credentials.accessKeyId
        const secretAccessKey = credentials.secretAccessKey
        const sessionToken = credentials.sessionToken

        const s3 = new S3Client({
          region: 'ap-southeast-1',
          credentials: {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            sessionToken: sessionToken,
          },
        })

        const params = {
          Bucket: 'hrmd-training-videos',
          Key: modifiedName,
          Body: modifiedFile,
          ACL: 'private',
        }

        const command = new PutObjectCommand(params)
        const uploadedByAdmin = await s3.send(command)
        const key = uploadedByAdmin.key
        const input = { key: modifiedName.replace(/^input-/, '') }

        if (uploadedByAdmin) {
          const saveVideoKey = await API.graphql(
            graphqlOperation(createTrainingVideo, { input })
          )

          if (saveVideoKey.data.createTrainingVideo) {
            notification['success']({
              message: 'Success',
              description: 'Video was uploaded successfully.',
            })

            handleClose()
            refresh(saveVideoKey.data.createTrainingVideo)
          }
        }
      } else {
        notification['error']({
          message: 'Error',
          description: 'No file to upload.',
        })
      }
    } catch (err) {
      console.log(err)

      notification['error']({
        message: 'Error',
        description:
          'Upload Video failed. Please reach out to System Admin for assistance.',
      })
    }
  }

  return (
    <>
      <Button
        size='large'
        onClick={handleOpen}
        className='mb-3 bg-primary text-white'
      >
        <UploadOutlined />
        Upload Training Video
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={'Upload Training Video'}
        centered
        visible={openView}
        onCancel={handleClose}
        width={500}
        footer={null}
      >
        {isUploading ? (
          <>
            <Row justify='center'>
              <Spin size='large' />
            </Row>
          </>
        ) : (
          <>
            <Row justify='center'>
              <Input
                id='trainingVideo'
                name='trainingVideo'
                type='file'
                accept='video/mp4,video/x-m4v,video/*'
                onChange={(e) => {
                  setVideoFile([...e.target.files])
                }}
              />
            </Row>
            <Row className={'mt-3 mb3'} justify='space-around'>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                disabled={disabledAfter}
                onClick={handleUploadVideo}
                type='primary'
              >
                Upload
              </Button>
            </Row>
          </>
        )}
      </Modal>
    </>
  )
}
