/**
 * Title: employeeHandbook.js
 * Description: This is a file that contains the Employee Handbook
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/employeeHandbook.js
 **/

/**
 * Changes Made:
 * - 2024.08.27 | Raymart Mojado | update the validation accept ' and - characters on name input
 */

import { useState, useEffect } from 'react'

import {
  Button,
  Row,
  Col,
  Card,
  Table,
  notification,
  Modal,
  Input,
  Select,
} from 'antd'

import { API, graphqlOperation } from 'aws-amplify'

import { listOtherUploads } from '../../api/queries'
import { createHandbookAcknowledgement } from '../../api/mutations'
import listOfDepartments from '../../utilities/dept'

const { Option } = Select

/**
 *
 * @returns a component that displays the department in QC
 */

/**
 *
 * @returns a function that displays the employee handbook
 */

export default function EmployeeHandbook() {
  const [name, setName] = useState(null)
  const [department, setDepartment] = useState(null)
  const [downloadLoc, setDownloadLoc] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [departmentError, setDepartmentError] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [tabError, setTabError] = useState(false)
  const [nameErrorBlank, setNameErrorBlank] = useState(false)
  const dept = listOfDepartments()

  useEffect(() => {
    fetchUploadedFiles()
  }, [])

  /**
   *
   * @returns a component that displays the Uploaded Files
   */

  const fetchUploadedFiles = async () => {
    try {
      // const uploadedFilesData = await API.graphql(
      //  graphqlOperation(listOtherUploads)
      // );

      const uploadedFilesData = await API.graphql({
        query: listOtherUploads,
        authMode: 'API_KEY',
      })

      // console.log(uploadedFilesData);

      const arrayItems = uploadedFilesData.data.listOtherUploads.items
      let newArrayItems = arrayItems.filter(
        (obj) => obj.filename === 'Employee Handbook.pdf'
      )

      setDownloadLoc(newArrayItems[0].location)
    } catch (error) {
      console.log(error)
    }
  }

  const acknowledgmentModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  /**
   * @function showErrorNotification
   * @description Displays an error notification with the provided description.
   * @param {string} description - The description of the error to be displayed in the notification.
   */
  const showErrorNotification = (description) => {
    try {
      notification['error']({
        message: 'Error',
        description: description,
      })
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function handleOk
   * @description Handles the form submission for the employee handbook acknowledgment.
   * Validates the employee name and department fields, and if valid, submits the data to the API.
   * @async
   * @returns {Promise<void>}
   */
  const handleOk = async () => {
    if (!tabError && !nameError && department && name) {
      setDisabledButton(true)
      setNameError(false)
      setDepartmentError(false)
      const input = {
        employeeName: name.trim().replace(/\s+/g, ' '),
        department,
        date: new Date(),
      }
      try {
        const acknowledgment = await API.graphql({
          query: createHandbookAcknowledgement,
          variables: { input },
          authMode: 'API_KEY',
        })

        if (acknowledgment.data.createHandbookAcknowledgement) {
          window.open(downloadLoc, '_blank')
          setIsModalVisible(false)

          setTimeout(() => {
            setName(null)
            setDepartment(null)
            setDownloadLoc(null)
            setDisabledButton(false)
          }, 2000)
        }
      } catch (err) {
        console.log(err)
        setDisabledButton(false)
      }
    } else {
      if (!name && !department) {
        setNameErrorBlank('Employee`s name cannot be blank.')
        setDepartmentError('Department name cannot be blank.')
        showErrorNotification('Please complete the fields below to continue.')
      } else if (!name) {
        showErrorNotification('Employee`s name cannot be blank.')
        setNameErrorBlank('Employee`s name cannot be blank.')
      } else if (!department) {
        showErrorNotification('Department cannot be blank.')
        setDepartmentError('Department name cannot be blank.')
      }
    }
  }

  const handleSelect = (value) => {
    try {
      setDepartment(value)
    } catch (err) {
      console.log(err)
    }
  }

  const handleChange = (e) => {
    const value = e.target.value
    const regex = /^(?:[a-zA-ZñéáíóúÑÉÁÍÓÚ.\s-']+)$/

    if (!/^(\s)/.test(value)) {
      if (!value.includes('\t') && regex.test(value) && value) {
        setName(value)
        setTabError(false)
        setNameError(false)
        setDisabledButton(false)
      }
      if (value && !regex.test(value)) {
        setNameError('The employee`s name has an invalid characters.')
        setName(value)
        setDisabledButton(true)
      }
      if (value.includes('\t')) {
        setTabError('Tab characters are not allowed.')
        setName(value)
        setDisabledButton(true)
      }
      if (value && value.length >= 256) {
        setNameError(
          'The employee`s name cannot be longer than 256 characters.'
        )
        setName(value)
        setDisabledButton(true)
      }
      if (!value) {
        setName(value)
        setDisabledButton(true)
        setTabError(false)
        setNameError(false)
      }
    }
  }
  /**
   * @function handleNameFocus
   * @description Handles the focus event for the name input field.
   * This function is triggered when the name input field gains focus.
   * It attempts to reset the name error state to false.
   * If an error occurs during this process, it logs the error to the console.
   */
  const handleNameFocus = () => {
    try {
      setNameErrorBlank(false)
    } catch (err) {
      console.log(err)
    }
  }
  /**
   * @function handleDeptFocus
   * @description Handles the focus event for the department input field.
   * This function is triggered when the department input field gains focus.
   * It attempts to reset the department error state to false.
   * If an error occurs during this process, it logs the error to the console.
   */
  const handleDeptFocus = () => {
    try {
      setDepartmentError(false)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *
   * @returns a component that displays the date
   */

  const dateDisplay = `${
    new Date().getMonth() + 1
  }-${new Date().getDate()}-${new Date().getFullYear()}`

  return (
    <div className='container'>
      <div className='mx-auto font-Mont'>
        <Row className='info' style={{ marginTop: 100 }} justify='left'>
          <Row className='heading border-bottom border-dark w-100'>
            <h1 className='fw-bolder font-Mont display-md-1 display-md-3 display-5'>
              HANDBOOK ACCESS
            </h1>
          </Row>
          <span className='mt-3 text-start p-md-0 p-4'>
            {' '}
            A government employee who is well informed about his employment’s
            terms and conditions like the rules and regulations affecting public
            administration and management including the grant of incentives and
            rewards is prepared to handle office challenges and difficulties
            thus maximize attainment of success in his career and the
            performance of his tasks excellently. This Handbook simply presents
            your work environment, duties, responsibilities and personal
            obligations as a civil servant and your rights and obligations as a
            government employee. The policies needed in every day's work are
            listed for guidance and compliance.
          </span>
        </Row>
        <Row className='button ps-md-0 pe-md-0 ps-3 pe-3' justify='left'>
          <button
            style={{ fontSize: 12, backgroundColor: '#8aa4d7' }}
            className='mb-5 mt-5 p-3 ps-5 pe-5 rounded-pill fw-bold text-white border-0 col-12 col-md-3'
            onClick={() => {
              acknowledgmentModal()
            }}
            onMouseOver={(event) => {
              event.target.style.backgroundColor = '#4d7bd6'
            }}
            onMouseLeave={(event) => {
              event.target.style.backgroundColor = '#8aa4d7'
            }}
          >
            DOWNLOAD HANDBOOK
          </button>
        </Row>
      </div>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Acknowledgment and Receipt'
        width={1000}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: disabledButton,
        }}
      >
        <p>
          I have received my copy of the Employee Handbook. The employee
          handbook describes important information about the Quezon City
          Government, and I understand that I should consult HRMD regarding any
          questions not answered in the handbook.
        </p>
        <p>
          I have received the handbook, and I understand that it is my
          responsibility to read and comply with the policies contained in this
          handbook and any revisions made to it.
        </p>

        <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
          <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
            <Input
              value={name}
              onChange={handleChange}
              onFocus={handleNameFocus}
              placeholder='Enter your name'
              className='text-center'
              maxLength={256}
            />
            {tabError && (
              <div style={{ color: 'red', fontSize: '12px' }}>{tabError}</div>
            )}
            {nameError && (
              <div style={{ color: 'red', fontSize: '12px' }}>{nameError}</div>
            )}
            {nameErrorBlank && (
              <div style={{ color: 'red', fontSize: '12px' }}>
                {nameErrorBlank}
              </div>
            )}
            <span className='text-center'>Employee's Name & Signature</span>
          </div>
          <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
            <span>{dateDisplay}</span>
            <span>Date</span>
          </div>
          <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
            <Select
              value={department}
              onChange={handleSelect}
              onFocus={handleDeptFocus}
              className='w-100 text-center'
            >
              <Option value='disabled' disabled>
                Disabled
              </Option>
              {dept.sort().map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
            {departmentError && (
              <div style={{ color: 'red', fontSize: '12px' }}>
                {departmentError}
              </div>
            )}
            <span className='text-center'>Department</span>
          </div>
        </div>
      </Modal>
    </div>
  )
}
