import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Buffer } from 'buffer'

import process from 'process'

global.Buffer = Buffer

// Polyfills
window.process = process
window.Buffer = Buffer

// Set NODE_ENV if it's not defined
if (typeof process.env.NODE_ENV === 'undefined') {
  process.env.NODE_ENV = 'production'
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
