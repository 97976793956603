/**
 * Title: this.props.data.js
 * Description: This is a file that contains the Assessment Maker Preview
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/assessmentMakerPreview.js
 **/

/*
 *Changes made:
 *2024.08.09 | Raymart Mojado | add preview of image on the multipleChoice
 *2024.08.21 | Raymart Mojado | remove the data after a successfull save
 */

import React, { useState } from 'react'
import { notification, Button, Modal, Row, Col, Input, Image } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

import { API, graphqlOperation, Storage } from 'aws-amplify'
import { createAssessmentQuestions } from '../../api/mutations'
import TextArea from 'antd/lib/input/TextArea'

export default function AssessmentMakerPreview({
  questionsArray,
  setGetAssessmentID,
  closeMotherModal,
  handleCreateQuestion,
  openAssessmentPreview,
  setOpenAssessmentPreview,
  userData,
  setQuestionsArray,
  setNewQuestion,
  setShowPostAssessment,
}) {
  /* const handleOpenModal = () => {
    handleCreateQuestion()
  } */
  const [disabledButton, setDisabledButton] = useState(false)
  const handleCancel = () => {
    setOpenAssessmentPreview(false)
  }

  const showOutput = questionsArray.map((elem, index) => {
    const imageUrl =
      elem.imageUploadQuestion && elem.imageUploadQuestion[0]?.url
    return (
      <div key={index} className='mb-4'>
        <div>
          <p className={'fw-bold'}>
            {index + 1}. {elem.question}
          </p>
        </div>
        <div className='flex-column ms-3 mt-2'>
          {imageUrl && (
            <div>
              <img
                src={imageUrl}
                alt={`imgQuestion ${index}`}
                style={{ maxWidth: '450px', maxHeight: '450px' }}
              />
            </div>
          )}
          {elem.choices[0] &&
            elem.choices[1] &&
            elem.choices[2] &&
            elem.choices[3] && (
              <>
                <div
                  className='flex align-items-center'
                  hidden={
                    elem.choices[0][`choices-1-${index + 1}`].toLowerCase() ===
                    'n/a'
                  }
                >
                  <input name='answer' className='mr' type='radio' />
                  <span className='m-0 ms-2'>
                    <strong>
                      <em>A. </em>
                    </strong>
                    {elem.choices[0][`choices-1-${index + 1}`]}
                  </span>
                </div>
                <div
                  hidden={
                    elem.choices[1][`choices-2-${index + 1}`].toLowerCase() ===
                    'n/a'
                  }
                >
                  <input name='answer' className='mr' type='radio' />
                  <span className='m-0 ms-2'>
                    <strong>
                      <em>B. </em>
                    </strong>
                    {elem.choices[1][`choices-2-${index + 1}`]}
                  </span>
                </div>
                <div
                  hidden={
                    elem.choices[2][`choices-3-${index + 1}`].toLowerCase() ===
                    'n/a'
                  }
                >
                  <input name='answer' className='mr' type='radio' />
                  <span className='m-0 ms-2'>
                    <strong>
                      <em>C. </em>
                    </strong>
                    {elem.choices[2][`choices-3-${index + 1}`]}
                  </span>
                </div>
                <div
                  hidden={
                    elem.choices[3][`choices-4-${index + 1}`].toLowerCase() ===
                    'n/a'
                  }
                >
                  <input name='answer' className='mr' type='radio' />
                  <span className='m-0 ms-2'>
                    <strong>
                      <em>D. </em>
                    </strong>
                    {elem.choices[3][`choices-4-${index + 1}`]}
                  </span>
                </div>
                <TextArea hidden={elem.correctAnswer !== 'n/a'} />
              </>
            )}
        </div>
        {/* <Row>
          <Row>
            <span className={"fw-bold"}>Multiple choice: Choose the correct asnwer</span>
          </Row>
        </Row> */}
      </div>
    )
  })

  async function uploadQuestionImage(filename, content) {
    const currentEpochTime = Math.floor(new Date().getTime() / 1000) // Get current time in Unix epoch time
    const key = `traningImages/${currentEpochTime}-${filename}`
    try {
      const result = await Storage.put(key, content)
      return result.key // Return the key of the uploaded image
    } catch (error) {
      console.log('Error uploading file:', error)
    }
  }

  const handleConfirm = async () => {
    try {
      setDisabledButton(true)
      let questionArrayData = questionsArray
      for (const question of questionArrayData) {
        if (
          question.imageUploadQuestion &&
          question.imageUploadQuestion.length > 0
        ) {
          const file = question.imageUploadQuestion[0].file
          const fileName = file.name
          const imageKey = await uploadQuestionImage(fileName, file)
          question.imageUploadQuestion = imageKey // Replace with the S3 key
        }
      }

      const createAssessment = await API.graphql(
        graphqlOperation(createAssessmentQuestions, {
          input: {
            assessmentQuestionnaire: JSON.stringify(questionArrayData),
            userID: userData.id,
          },
        })
      )

      if (createAssessment) {
        notification['success']({
          message: 'Success',
          description: 'Assessment Questionnaire was saved.',
        })

        setGetAssessmentID(createAssessment.data.createAssessmentQuestions.id)

        if (typeof setShowPostAssessment === 'function') {
          setShowPostAssessment(true)
        }

        setTimeout(() => {
          setOpenAssessmentPreview(false)
          closeMotherModal(false)
          setQuestionsArray([])
          setNewQuestion([])
          setDisabledButton(false)
        }, 1500)
      }
    } catch (err) {
      console.log(err)
      setDisabledButton(false)
      notification['error']({
        message: 'Error',
        description:
          'Assessment Questionnaire was not saved. Contact System Admin.',
      })
    }
  }

  return (
    <>
      <Modal
        maskClosable={false}
        title={
          <span>
            <em>
              <b>Please review questionnaire before saving</b>
            </em>
          </span>
        }
        visible={openAssessmentPreview}
        footer={null}
        onCancel={handleCancel}
        width={800}
        destroyOnClose={true}
      >
        <Row style={{ fontSize: 16 }} justify='center'>
          <Col>{showOutput}</Col>
        </Row>
        <Row className='mt-4' justify='end'>
          <div>
            <Button
              className='me-4'
              onClick={() => setOpenAssessmentPreview(false)}
              type='danger'
            >
              Cancel
            </Button>
            <Button
              disabled={disabledButton}
              onClick={handleConfirm}
              type='primary'
            >
              Confirm
            </Button>
          </div>
        </Row>
      </Modal>
    </>
  )
}
