/**
 * Title: surveyFeedback.js
 * Description: This is a file that contains the Survey Feedback Form
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Form/surveyFeedback.js
 **/

/*
 * Changes made:
 * - 2024.08.08 |  Harry Lagunsad  | changed the notification message
 * - 2024.08.08 |  Harry Lagunsad  | in the Formio Server, added the max length of 512 for the text area, additional comments / feedback
 */

import React, { useState } from 'react'

import { Form } from '@formio/react'

import { notification, Card } from 'antd'

import { API, graphqlOperation } from 'aws-amplify'
import { createSurvey, updateUser } from '../../api/mutations'

const SurveyFeedback = (props) => {
  const [submissionData, setSubmissionData] = useState({})

  const handleSubmit = async (e) => {
    setSubmissionData({})

    try {
      await API.graphql(
        graphqlOperation(createSurvey, {
          input: {
            dept: props.user.department,
            assignedDept: props.user.assignedDept,
            division: props.user.division,
            position: props.user.position,
            userID: props.user.id,
            surveyScore: e.data.surveyScore,
            surveyRecommendation: e.data.surveyRecommendation
              .split(' ')
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(' '),
          },
        })
      )
      const saveUpdateUser = await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: props.user.id,
            isTakeSurvey: '1',
          },
        })
      )

      props.user.isTakeSurvey = '1'

      if (saveUpdateUser) {
        setTimeout(function () {
          notification['success']({
            message: 'Success',
            description: 'Thank you for your feedback!',
          })
        }, 500)
        function uuidv4() {
          try {
            const crypto = window.crypto || window.msCrypto
            const array = new Uint32Array(5)
            crypto.getRandomValues(array)

            return (
              array[0].toString(16).padStart(8, '0') +
              '-' +
              array[1].toString(16).padStart(4, '0') +
              '-4' +
              array[2].toString(12).padStart(3, '0') +
              '-y' +
              array[3].toString(12).padStart(3, '0') +
              '-' +
              array[4].toString(16).padStart(12, '0')
            )
          } catch (error) {
            console.log(error)
          }
        }
        props.refresh(uuidv4())
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className='site-layout-background' style={{ padding: 24 }}>
        <Card>
          <Card style={{ width: '100%', display: 'flex' }}>
            <Form
              src='https://www.sparksoft-demo.com/formio/etnafeedbackform'
              onSubmit={(e) => handleSubmit(e)}
              submission={{ data: submissionData }}
            />
          </Card>
        </Card>
      </div>
    </>
  )
}

export default SurveyFeedback
