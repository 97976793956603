/**
 * Title: dashboard.js
 * Description: This is a file that contains the dashboard component
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/dashboard.js
 **/

/*
 * Changes made:
 * - 2024.20.08 | Harry Lagunsad | fixed the functions `handleisApproved` and `sendFile` error handling so that no upload of ILDP will proceed
 *   if there isn't an ILDP form submitted for the current period
 * - 2024.20.08 | Harry Lagunsad | made some changes as per sonarlint suggestions
 */

import {
  Button,
  Col,
  notification,
  Result,
  Row,
  Modal,
  Steps,
  Tabs,
  Select,
  Skeleton,
} from 'antd'

import React from 'react'

import { API, graphqlOperation, Auth } from 'aws-amplify'
import { FormByUserId, Form2ByUserId } from '../../api/queries'
import { createIDLPUpload, updateUser, updateForm2 } from '../../api/mutations'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

import GrafanaDashboard from './grafanaDashboard.js'

import moment from 'moment'

import jsPDF from 'jspdf'

const { Step } = Steps
const { TabPane } = Tabs
const { Option } = Select
const secret_name = 'hrm-2023@secrets'

export default class Dashboard extends React.Component {
  constructor() {
    super()
    this.state = {
      noUpload: true,
      isCheckingForm: true,
      current: 0,
      getFourPanels: false,
      departmentD: 'All Departments',
      isModalVisible: true,
      isModalVisibleStepFour: true,
      showCurrentPeriod: true,
      showCurrentPeriodInDemoData: true,
      steps: [
        {
          title: '',
          description: '',
          content: '',
        },
        {
          title: '',
          content: '',
        },
        {
          title: '',
          content: '',
        },
        {
          title: '',
          content: '',
        },
      ],
      isNoForm2: false,
    }
  }

  /**
   * Provides a threshold date string based on the current date.
   *
   * @returns {string} A string representing the threshold date in the format "Month Day, Year HH:MM:SS AM/PM".
   *                   If the current date is before July 1 of the current year, it returns "June 1, 2023 12:00:00 AM".
   *                   Otherwise, it returns "June 1, [current year] 12:00:00 AM".
   *
   * @throws {Error} Logs any error that occurs during execution to the console.
   *
   * @example
   * // If current date is May 15, 2024
   * const threshold = thresholdProvider();
   * console.log(threshold); // Output: "June 1, 2023 12:00:00 AM"
   *
   * // If current date is August 1, 2024
   * const threshold = thresholdProvider();
   * console.log(threshold); // Output: "June 1, 2024 12:00:00 AM"
   */
  thresholdProvider = () => {
    try {
      // Get the current date
      let currentDate = new Date()

      // Set the threshold date to July 1, current year
      let thresholdDate = new Date(currentDate.getFullYear(), 6, 1) // Note: Month is zero-indexed (0 = January, 6 = July)

      // Declare a variable to store the threshold string
      let thresholdString

      // Compare the current date with the threshold date
      if (currentDate < thresholdDate) {
        // Current date is before the threshold
        thresholdString = 'July 1, 2023 12:00:00 AM'

        return thresholdString
      } else {
        // Current date is on or after the threshold
        let currentYear = currentDate.getFullYear()
        thresholdString = 'July 1, ' + currentYear + ' 12:00:00 AM'

        return thresholdString
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Fetches forms for a user.
   *
   * @function fetchForms
   * @param {Object} user - The user object.
   * @param {string} [token=null] - The token for pagination.
   * @param {Array} [foundItems=[]] - The array to store the found items.
   * @returns {Promise<Array>} - A promise that resolves to an array of found items.
   * @description This function fetches forms for a user using the FormByUserId GraphQL query.
   * It recursively fetches forms using pagination until all forms are retrieved.
   * @async
   */
  fetchForms = async (user) => {
    let token = null
    let foundItems = []

    try {
      do {
        const myEtna = await API.graphql(
          graphqlOperation(FormByUserId, {
            userID: user.id,
            nextToken: token,
          })
        )

        const { items, nextToken } = myEtna.data.FormByUserId
        foundItems = [...foundItems, ...items]
        token = nextToken
      } while (token)

      return foundItems
    } catch (err) {
      console.log(err)
    }
  }
  /**
   * Filters items after a target date.
   *
   * @function filterItemsAfterTargetDate
   * @param {Array} items - The array of items to filter.
   * @param {string} targetDate - The target date to compare against.
   * @returns {Array} - The filtered array of items.
   * @description This function filters an array of items based on a target date.
   * It returns a new array containing only the items that have a date equal to or after the target date.
   */
  filterItemsAfterTargetDate = (items, targetDate) => {
    return items.filter((item) => {
      const dateToCheck = moment(item)
      return dateToCheck.isSameOrAfter(targetDate)
    })
  }

  /**
   * Updates the user's form and form2 values to '0'.
   *
   * @function updateUserForm
   * @param {Object} user - The user object.
   * @returns {Promise<void>} - A promise that resolves when the update is successful.
   * @description This function updates the user's form and form2 values to '0' using the updateUser mutation.
   * It also updates the user object in the local storage.
   * @async
   */
  updateUserForm = async (user) => {
    try {
      await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: user.id,
            form: '0',
            form2: '0',
          },
        })
      )
      this.props.refresh()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Checks if the ETNA form is completed for the user.
   *
   * @function checkIfEtnaIsCompleted
   * @returns {Promise<void>} - A promise that resolves when the check is completed.
   * @description This function checks if the ETNA form is completed for the user.
   * If the user is an employee and the isTakeSurvey, form2, and form values meet the required conditions,
   * it fetches the forms for the user and filters them based on a target date.
   * If no forms are found after the target date, it updates the user's form and form2 values to '0',
   * displays a notification, and reloads the page.
   * Otherwise, it sets a timeout to update the state after 3 seconds.
   * @async
   */
  checkIfEtnaIsCompleted = async () => {
    try {
      if (this.props.data && this.props.data?.role !== 'admin') {
        const { form, form2, isTakeSurvey } = this.props.data

        if (isTakeSurvey === '0' && form2 === '0' && form === '1') {
          const foundItems = await this.fetchForms(this.props.data)
          const foundItemsEtna = foundItems.map((item) => item.finalSubmitDate)

          const thresholdFound = this.thresholdProvider()

          const targetDate = moment(
            `${moment(
              thresholdFound,
              'MMMM D, YYYY h:mm:ss A'
            ).year()}-07-01 00:00:00`,
            'YYYY-MM-DD HH:mm:ss'
          )

          const filteredItems = this.filterItemsAfterTargetDate(
            foundItemsEtna,
            targetDate
          )

          if (!filteredItems.length) {
            await this.updateUserForm(this.props.data)

            notification['info']({
              message: 'Information',
              description: 'The page will reload to load your correct data.',
            })

            setTimeout(() => {
              window.location.reload(true)
            }, 5000)
          } else {
            setTimeout(() => {
              this.setState({ isCheckingForm: false })
            }, 3000)
          }
        } else {
          setTimeout(() => {
            this.setState({ isCheckingForm: false })
          }, 3000)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  handleOkStepFour = () => {
    this.setState({ isModalVisibleStepFour: false })
  }

  handleCancelStepFour = () => {
    this.setState({ isModalVisibleStepFour: false })
  }

  componentDidMount() {
    this.checkIfEtnaIsCompleted()
    this.showCurrentDash(this.props.data)
    this.getMyEtna(this.props.data)
    this.getMe()
    this.setState({
      steps: [
        {
          title: 'e-TNA Form.',
          description: '',
          content: (
            <Result
              status='warning'
              title='Training Needs Analysis Form.'
              extra={
                <Button
                  onClick={() => this.props.content('createForm')}
                  type='primary'
                  key='console'
                >
                  Fill-out
                </Button>
              }
            />
          ),
        },
        {
          title: 'ILDP Form',
          description: '',
          content: (
            <Result
              style={{ marginBottom: '0' }}
              status='warning'
              title='Individual Learning and Development Plan.'
              extra={
                <>
                  <Row gutter={24} justify='center'>
                    <Button
                      onClick={() => this.props.content('createForm2')}
                      type='primary'
                      key='console'
                    >
                      Fill-out
                    </Button>
                  </Row>
                  <Row gutter={24} justify='center'>
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Or
                    </Col>
                  </Row>
                  <Row gutter={24} justify='space-around'>
                    <Col
                      span={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <label className='custom-file-upload'>
                        <input
                          accept='image/jpeg,image/gif,image/png,application/pdf'
                          className='hide-input'
                          type='file'
                          onChange={(e) => this.getFile(e)}
                          multiple
                        />
                        Upload ILDP Form
                      </label>
                    </Col>
                  </Row>
                </>
              }
            />
          ),
        },
        {
          title: 'Take a Survey',
          content: (
            <Result
              status='warning'
              title='Take Your Survey Feedback Evaluation'
              extra={
                <Button
                  onClick={() => this.props.content('surveyFeedback')}
                  type='primary'
                  key='console'
                >
                  Fill-out
                </Button>
              }
            />
          ),
        },
        {
          title: 'Finish',
          content: (
            <Result
              status='success'
              title='Thank you for your cooperation and support'
              extra={
                <>
                  <Button
                    onClick={() => this.handleResetChange()}
                    danger
                    key='console'
                  >
                    Click Here to create a new e-TNA Survey
                  </Button>
                </>
              }
            />
          ),
        },
      ],
    })
  }

  getMe = async () => {
    if (this.props.data) {
      this.setState({ userId: this.props.data.id })
    }
  }

  /**
   * @function handleisApproved
   * Handles the approval process for a Form2 item.
   *
   * This asynchronous function performs the following steps:
   * 1. Fetches all Form2 items for the current user.
   * 2. Sorts the items by creation date (newest to oldest).
   * 3. Finds the most recent Form2 item that is on or after a threshold date.
   * 4. If a valid Form2 item is found, updates its 'isApproved' status to '1'.
   * 5. If no valid Form2 item is found, sets the 'isNoForm2' state to true.
   *
   * @async
   * @param {string} currentUserId - The ID of the current user.
   * @returns {Promise<Object|null>} A promise that resolves to:
   *   - The updated Form2 object if a valid item was found and updated successfully.
   *   - null if no valid Form2 item was found or if an error occurred.
   * @throws {Error} If there's an error during the API calls or data processing.
   *   The error is caught, logged, and a notification is shown to the user.
   */
  handleisApproved = async (currentUserId) => {
    try {
      let token = null
      let foundItems = []

      do {
        const forResponseFilter = await API.graphql(
          graphqlOperation(Form2ByUserId, {
            userID: currentUserId,
            nextToken: token,
          })
        )

        const { items, nextToken } = forResponseFilter.data.Form2ByUserId
        foundItems = [...foundItems, ...items]
        token = nextToken
      } while (token)

      let responseFilter = foundItems.sort((a, b) => {
        // sort by latest to oldest using moment.js with diff
        return moment(b.createdAt).diff(moment(a.createdAt))
      })

      // Get the threshold date
      const thresholdString = this.thresholdProvider()
      const thresholdDate = moment(thresholdString)

      // Find the most recent form2 item that is after or equal to the threshold date
      const validForm2 = responseFilter.find((item) =>
        moment(item.createdAt).isSameOrAfter(thresholdDate)
      )

      if (!validForm2) {
        this.setState({ isNoForm2: true })
        return null // Return null instead of throwing an error
      }

      const finishedJob = await API.graphql(
        graphqlOperation(updateForm2, {
          input: {
            id: validForm2.id,
            isApproved: '1',
          },
        })
      )

      return finishedJob
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description:
          'Error updating ILDP form (form2err). Please contact system admin.',
      })
      return null // Return null instead of throwing an error
    }
  }

  /**
   * @function sendFile
   * Handles the file sending process.
   *
   * This asynchronous function performs the following steps:
   * 1. Checks if file upload is allowed (based on this.state.noUpload).
   * 2. If allowed, it calls handleisApproved to check and update the approval status.
   * 3. If handleisApproved is successful, it would continue with the file sending process
   *    (the rest of the function is not provided in the given code snippet).
   * 4. If file upload is not allowed, it shows an error notification.
   *
   * @async
   * @param {Event} e - The event object (not used in the provided code snippet).
   * @returns {Promise<void>}
   * @throws {Error} If there's an error during the approval process or file upload.
   *   The error is caught, logged, and a notification is shown to the user.
   */
  sendFile = async (e) => {
    if (this.state.noUpload === false) {
      try {
        // Call handleisApproved and check for errors
        const approvalResult = await this.handleisApproved(this.props.data.id)
        if (!approvalResult) {
          // handleisApproved already showed an error notification, so we just return
          return
        }

        const credentials = await Auth.currentCredentials()
        const accessKeyId = credentials.accessKeyId
        const secretAccessKey = credentials.secretAccessKey
        const sessionToken = credentials.sessionToken

        const s3 = new S3Client({
          region: 'ap-southeast-1',
          credentials: {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            sessionToken: sessionToken,
          },
        })

        let newEFile

        if (e[0]) {
          if (e[0].type === 'application/pdf') {
            newEFile = e[0]
          }
        } else {
          newEFile = e
        }

        const params = {
          Bucket: 'dbo-application',
          Key: `oop/${moment().format('YYYY-MM-DD HH-mm-ss A')}_${
            newEFile.name
          }`,
          Body: newEFile,
        }

        const command = new PutObjectCommand(params)
        const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

        await s3.send(command)

        await API.graphql(
          graphqlOperation(createIDLPUpload, {
            input: {
              userID: this.props.data.id,
              date: moment().format('YYYY-MM-DD'),
              file: url,
              etnaId: this.state.etnaId,
              isApproved: '1',
            },
          })
        )

        await API.graphql(
          graphqlOperation(updateUser, {
            input: {
              id: this.props.data.id,
              form2: '1',
              isTakeSurvey: '0',
            },
          })
        )

        this.props.refresh()

        this.props.data.form2 = '1'

        notification['success']({
          message: 'Success',
          description: 'File uploaded.',
        })
      } catch (err) {
        console.log(err)
        notification['error']({
          message: 'Error',
          description: 'Error uploading file.',
        })
      }
    } else {
      notification['error']({
        message: 'Error',
        description:
          "You didn't submit an e-TNA form yet. Please go back to Step 1.",
      })
    }
  }

  /**
   * Checks the size of selected files and their types.
   *
   * @function fileSizeChecker
   * @param {File[]} files - The selected files to be checked.
   * @returns {boolean} - Returns true if all files pass the size and type check, otherwise false.
   * @description This function checks the size and type of each selected file.
   * It compares the file size against a maximum limit and checks if the file type is allowed.
   * If any file exceeds the maximum size or has an unsupported type, the function returns false.
   * Otherwise, it returns true indicating that all files pass the size and type check.
   */
  fileSizeChecker = (files) => {
    try {
      const maxSize = 2 * 1024 * 1024 // 1 MB
      const allowedTypes = [
        'image/avif',
        'image/webp',
        'image/png',
        'image/jpeg',
      ]
      let count = 0
      let result = true

      return new Promise((resolve, reject) => {
        // Iterate over each selected file

        for (const file of files) {
          const reader = new FileReader()

          // Read the file size and type
          reader.readAsDataURL(file)
          reader.onload = () => {
            count++
            if (file.size > maxSize || !allowedTypes.includes(file.type)) {
              // File size exceeds maximum limit or file type is not allowed
              result = false
            }

            // Check if all files have been processed
            if (count === files.length) {
              resolve(result)
            }
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Loads an image file and returns its data URL.
   *
   * @function loadImage
   * @param {File} file - The image file to be loaded.
   * @returns {Promise<string>} - A promise that resolves with the data URL of the loaded image.
   * @description This function reads the contents of the provided image file using the FileReader API.
   * It converts the image data into a data URL and resolves the promise with the data URL.
   * If an error occurs during the loading process, the promise is rejected with the error.
   */
  loadImage = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onload = function (e) {
        const imgDataUrl = e.target.result
        resolve(imgDataUrl)
      }

      reader.onerror = function (error) {
        reject(error)
      }

      reader.readAsDataURL(file)
    })
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * Adds images to a PDF document.
   *
   * @function addImagesToPdf
   * @param {File[]} files - The image files to be added to the PDF document.
   * @param {Object} pdf - The PDF document to which the images will be added.
   * @returns {Promise<void>} - A promise that resolves when the images are successfully added to the PDF document.
   * @description This function adds the selected images to the provided PDF document.
   * It iterates over each image file and loads the image using the loadImage function.
   * If the current image is not the first image, a new page is added to the PDF document.
   * The image is then added to the PDF document using the addImage method.
   * After all images have been added to the PDF document, the PDF data is generated and saved to a new File object.
   * The new File object is then sent to the sendFile function for upload to the server.
   * If an error occurs during the loading or adding process, an error message is displayed.
   * @async
   */
  addImagesToPdf = async (files, pdf) => {
    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      try {
        const imgDataUrl = await this.loadImage(file)
        if (i !== 0) {
          pdf.addPage()
        }
        pdf.addImage(
          imgDataUrl,
          'JPEG',
          0,
          0,
          pdf.internal.pageSize.getWidth(),
          pdf.internal.pageSize.getHeight()
        )
      } catch (error) {
        console.error('Error loading image:', error)
      }
    }

    const pdfData = pdf.output('arraybuffer')
    const fileName = `${this.props.data.name} ${
      this.props.data.mname ? this.props.data.mname : ''
    } ${this.props.data.lname} - ${moment(new Date()).format(
      'YYYY-MM-DD HH:mm:ss A'
    )}.pdf`
    const fileObj = new File([pdfData], fileName, {
      type: 'application/pdf',
    }) // Create a new File object
    this.sendFile(fileObj)
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * Handles the selection of a file or files for upload.
   * @function getFile
   * @param {Object} e - The event object containing the selected file or files.
   * @returns {Promise<void>} - A promise that resolves when the file or files are successfully processed.
   * @description This function handles the selection of a file or files for upload.
   * It first checks if the selected file is a single PDF file or multiple image files.
   * If the selected file is a single PDF file, it checks if the file size exceeds the maximum limit and sends the file to
   * the server for upload using the sendFile function.
   * If the selected files are multiple image files, it checks if the total file size exceeds the maximum limit and sends
   * the files to the server for upload using the addImagesToPdf function.
   * If an error occurs during the file selection or upload process, an error notification is displayed.
   * @async
   */
  getFile = async (e) => {
    const files = e.target.files

    try {
      if (files.length === 0) {
        return
      } else if (files.length === 1) {
        if (files[0].type === 'application/pdf') {
          const maxSize = 5 * 1024 * 1024 // 5 MB
          const file = files[0]

          if (file.size <= maxSize) {
            this.sendFile(files)
            return
          } else {
            notification['error']({
              message: 'Error',
              description: `Your PDF File size exceeds maximum limit of 5MB.`,
            })
          }
        } else {
          notification['error']({
            message: 'Error',
            description: `Only PDF file can be single uploaded.`,
          })
        }
      } else {
        const result = await this.fileSizeChecker(files)
        if (result === true) {
          const pdf = new jsPDF()
          this.addImagesToPdf(files, pdf)
        } else {
          notification['error']({
            message: 'Error',
            description:
              'A file or files you tried to uploaded exceeded the file size limit or its file type is not allowed. Make sure the file you are uploading is a valid image file and of allowed file size of 2MB.',
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * Fetches the user's e-TNA form.
   * @function getMyEtna
   * @param {Object} data - The user object.
   * @returns {Promise<void>} - A promise that resolves when the user's e-TNA form is successfully fetched.
   * @description This function fetches the user's e-TNA form using the FormByUserId GraphQL query.
   * It retrieves all the e-TNA forms associated with the user and sorts them in descending order based on the creation date.
   * If the user has submitted an e-TNA form, the finalSubmitDate of the latest form is set as the etnaId.
   * If the user has not submitted an e-TNA form, the noUpload state is set to true.
   * If an error occurs during the fetch process, an error message is displayed.
   * @async
   */
  getMyEtna = async (data) => {
    try {
      let token = null
      let foundItems = []

      do {
        const myEtna = await API.graphql(
          graphqlOperation(FormByUserId, { userID: data.id, nextToken: token })
        )

        const { items, nextToken } = myEtna.data.FormByUserId
        foundItems = [...foundItems, ...items]
        token = nextToken
      } while (token)

      const myEtnaArr = foundItems.filter((obj) => obj.isFinal == 'YES')
      const newMyEtnaArr = myEtnaArr.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt)
      })

      if (newMyEtnaArr) {
        if (newMyEtnaArr.length) {
          this.setState({
            etnaId: newMyEtnaArr[0].finalSubmitDate,
            noUpload: false,
          })
        } else {
          this.setState({ noUpload: true })
        }
      } else {
        this.setState({ noUpload: true })
      }
    } catch (err) {
      console.log(err)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * Resets the user's form, form2, and isTakeSurvey values to '0'.
   * @function handleResetChange
   * @returns {Promise<void>} - A promise that resolves when the user's form, form2, and isTakeSurvey values are successfully reset.
   * @description This function resets the user's form, form2, and isTakeSurvey values to '0' using the updateUser mutation.
   * It also updates the user object in the local storage and displays a success notification.
   * @async
   */
  handleResetChange = async () => {
    await API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: this.props.data.id,
          isTakeSurvey: '0',
          form: '0',
          form2: '0',
        },
      })
    )
    function uuidv4() {
      try {
        const crypto = window.crypto || window.msCrypto
        const array = new Uint32Array(5)
        crypto.getRandomValues(array)

        return (
          array[0].toString(16).padStart(8, '0') +
          '-' +
          array[1].toString(16).padStart(4, '0') +
          '-4' +
          array[2].toString(12).padStart(3, '0') +
          '-y' +
          array[3].toString(12).padStart(3, '0') +
          '-' +
          array[4].toString(16).padStart(12, '0')
        )
      } catch (error) {
        console.log(error)
      }
    }
    this.props.refresh(uuidv4())
    this.setState({
      current: 0,
    })
    notification['success']({
      message: 'Success',
      description: 'You can now take your new e-TNA Form. Thank you!',
    })
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * Shows the current dashboard based on the user's form, form2, and isTakeSurvey values.
   * @function showCurrentDash
   * @param {Object} user - The user object.
   * @returns {void}
   * @description This function shows the current dashboard based on the user's form, form2, and isTakeSurvey values.
   * If the user has not submitted any forms, the current state is set to 0.
   * If the user has submitted the e-TNA form but not the ILDP form, the current state is set to 1.
   * If the user has submitted both the e-TNA and ILDP forms but not the survey, the current state is set to 2.
   * If the user has submitted all forms, the current state is set to 3.
   */
  showCurrentDash = (user) => {
    if (user.form === '0' && user.form2 === '0' && user.isTakeSurvey === '0') {
      this.setState({ current: 0 })
    } else if (
      user.form === '1' &&
      user.form2 === '0' &&
      user.isTakeSurvey === '0'
    ) {
      this.setState({ current: 1 })
    } else if (
      user.form === '1' &&
      user.form2 === '1' &&
      user.isTakeSurvey === '0'
    ) {
      this.setState({ current: 2 })
    } else if (
      user.form === '1' &&
      user.form2 === '1' &&
      user.isTakeSurvey === '1'
    ) {
      this.setState({ current: 3 })
    } else {
      console.log('')
    }
  }

  next = () => {
    this.setState({
      current: this.state.current + 1,
    })
  }

  handleCancel = () => {
    this.setState({ isModalVisible: false })
  }

  handleOk = () => {
    this.setState({ isModalVisible: false })
  }

  handleChangeDepartment = (e) => {
    this.setState({
      departmentD: e,
    })
  }

  showDataByPeriod = (e) => {
    this.setState({ showCurrentPeriod: e })
  }

  handleShowCurrentPeriodInDemographicData = (e, choice) => {
    e.preventDefault()
    this.setState({
      showCurrentPeriodInDemoData: choice,
      departmentD: 'All Departments',
    })
  }

  render() {
    const user = this.props.data

    const getCurrentStep = (user) => {
      if (user.form === '0') {
        return 0
      } else if (user.form2 === '0') {
        return 1
      } else if (user.isTakeSurvey === '0') {
        return 2
      }
      return 3
    }
    try {
      return (
        <>
          <div className={'site-layout-background col-12 font-Mont'}>
            <div className='mainDashboard'>
              {user.role === 'admin' || user.role === 'deptadmin' ? (
                <GrafanaDashboard
                  userData={this.props.data}
                  showCurrentPeriodInDemoData={
                    this.state.showCurrentPeriodInDemoData
                  }
                  departmentD={this.state.departmentD}
                  handleChangeDepartment={(e) => this.handleChangeDepartment(e)}
                  assignedDepartment={this.props.assignedDepartment}
                  getFourPanels={this.state.getFourPanels}
                  showCurrentPeriod={this.state.showCurrentPeriod}
                  showDataByPeriod={(e) => this.showDataByPeriod(e)}
                  handleShowCurrentPeriodInDemographicData={(e, f) =>
                    this.handleShowCurrentPeriodInDemographicData(e, f)
                  }
                />
              ) : null}

              {user &&
              user.role !== 'viewer' &&
              user.role !== 'admin' &&
              user.role !== 'deptadmin' ? (
                <div className='dashInner'>
                  {this.state.isCheckingForm ? (
                    <Skeleton active />
                  ) : (
                    <>
                      <Steps current={getCurrentStep(user)}>
                        {this.state.steps.map((item, index) => (
                          <Step
                            key={item.title}
                            icon={
                              <span style={{ fontSize: '30px' }}>
                                Step {index + 1}
                              </span>
                            }
                            /*  key={item.title}  */
                          />
                        ))}
                      </Steps>
                      <div className='steps-content'>
                        {this.state.steps[this.state.current].content}
                      </div>
                      <div className='steps-action'>
                        {(this.state.current === 0 && user.form === '1') ||
                        (this.state.current === 1 && user.form2 === '1') ||
                        (this.state.current === 2 &&
                          user.isTakeSurvey === '1') ? (
                          <Button
                            type='primary'
                            onClick={() => {
                              this.next()
                            }}
                          >
                            Next
                          </Button>
                        ) : null}

                        {this.state.current === 0 ? (
                          <Modal
                            maskClosable={false}
                            destroyOnClose={true}
                            title='How to use Learning and Development System'
                            style={{ top: 20 }}
                            visible={this.state.isModalVisible}
                            onOk={() => this.handleOk()}
                            onCancel={() => this.handleCancel()}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            width={1000}
                          >
                            <Tabs defaultActiveKey='1' centered>
                              <TabPane tab='Step for Final Copy' key='1'>
                                <iframe
                                  src='https://drive.google.com/file/d/16SvThgORtKTBd-_dr9FzeDGTCmOjfIom/preview'
                                  width={950}
                                  height={480}
                                  allow='autoplay'
                                  title='PROCESS'
                                ></iframe>
                              </TabPane>
                              <TabPane tab='Step for Draft Copy' key='2'>
                                <iframe
                                  src='https://drive.google.com/file/d/1KNr4rjTf0bt0uIuK67F1yMys8nyj6TeT/preview'
                                  width={950}
                                  height={480}
                                  allow='autoplay'
                                  title='PROCESS'
                                ></iframe>
                              </TabPane>
                            </Tabs>
                          </Modal>
                        ) : null}

                        {this.state.current === 1 ? (
                          <Modal
                            maskClosable={false}
                            destroyOnClose={true}
                            title='How to use Learning and Development System'
                            visible={this.state.isModalVisible}
                            onOk={() => this.handleOk()}
                            onCancel={() => this.handleCancel()}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            width={1000}
                            style={{ top: 20 }}
                          >
                            <Tabs defaultActiveKey='1' centered>
                              <TabPane tab='Step for ILDP' key='1'>
                                <iframe
                                  src='https://drive.google.com/file/d/1-8LzEFunfFlyzemCW-QX910QmusuARsK/preview'
                                  width={950}
                                  height={480}
                                  allow='autoplay'
                                  title='PROCESS'
                                ></iframe>
                              </TabPane>
                            </Tabs>
                          </Modal>
                        ) : null}

                        {this.state.current === 3 ? (
                          <Modal
                            maskClosable={false}
                            destroyOnClose={true}
                            title='NOTICE!!!'
                            open={this.state.isModalVisibleStepFour}
                            onOk={() => this.handleOkStepFour()}
                            onCancel={() => this.handleCancelStepFour()}
                            cancelButtonProps={{ style: { display: 'none' } }}
                            width={1000}
                            style={{ top: 160 }}
                          >
                            <h3 className='text-center'>
                              Please only answer once, if you still cannot
                              register to any of the trainings, please contact
                              your respective HR representatives.
                            </h3>
                          </Modal>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              ) : null}
            </div>
          </div>
          <Modal
            maskClosable={false}
            destroyOnClose={true}
            title='Error'
            open={this.state.isNoForm2}
            onCancel={() => this.setState({ isNoForm2: false })}
            width={700}
            style={{ top: 160 }}
            footer={null}
          >
            <p className='text-center'>
              <b>
                We did not find a valid ILDP form in the database that was
                submitted for this period. Please answer the ILDP form first
                before uploading your approved ILDP form.
              </b>
            </p>
            <p className='text-center'>
              <b>Thank you.</b>
            </p>
          </Modal>
        </>
      )
    } catch (err) {
      console.log(err)
    }
  }
}
