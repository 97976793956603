/**
 * Title: postQuestionTwoAnswers.js
 * Description: This is a file that contains the components that displays the post answers two
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/postQuestionTwoAnswers.js
 **/

import { Input, Row, Col } from 'antd'

export default function PostQuestionTwoAnswers(props) {
  const {
    questionOneAfter,
    questionTwoAfter,
    questionThreeAfter,
    questionFourAfter,
    questionFiveAfter,
  } = props

  return (
    <Row>
      {questionOneAfter &&
      questionTwoAfter &&
      questionThreeAfter &&
      questionFourAfter &&
      questionFiveAfter ? (
        <>
          <Row>
            <Col span={24}>
              <Row className={'mb-4'} justify='center'>
                <Col style={{ width: 850 }}>
                  <Row className={'mb-2'}>
                    <span style={{ fontSize: 16, color: 'blue' }}>
                      <em>Note: item in bold is the correct answer.</em>
                    </span>
                  </Row>
                  <Row className={'mb-3'}>
                    <span>
                      <em>
                        <b>
                          Total score:{' '}
                          {parseInt(
                            `${questionOneAfter == 'questionOneA' ? 1 : 0}`
                          ) +
                            parseInt(
                              `${questionTwoAfter == 'questionTwoC' ? 1 : 0}`
                            ) +
                            parseInt(
                              `${
                                questionThreeAfter == 'questionThreeA' ? 1 : 0
                              }`
                            ) +
                            parseInt(
                              `${questionFourAfter == 'questionFourA' ? 1 : 0}`
                            ) +
                            parseInt(
                              `${questionFiveAfter == 'questionFiveD' ? 1 : 0}`
                            )}
                        </b>
                      </em>
                    </span>
                  </Row>
                </Col>
                <Col style={{ width: 850 }}>
                  <Row>
                    <label
                      style={{
                        fontSize: 16,
                        textAlign: 'left',
                      }}
                      className={'loginInput-label-no-bold mb-3'}
                      htmlFor='preAssessment'
                    >
                      1. An appointment issued to a person who meets all the
                      qualification requirements of the position to which he/she
                      is being appointed to, including the appropriate
                      eligibility, in accordance with the provisions of law,
                      rules and standards<em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row>
                    <span>
                      <em>
                        <b>{`${
                          questionOneAfter == 'questionOneA'
                            ? '1 point'
                            : '0 point'
                        }`}</b>
                      </em>
                    </span>
                  </Row>
                  <Row
                    align='middle'
                    style={{ height: 35, marginBottom: 12 }}
                    justify='space-between'
                  >
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionOneAfter == 'questionOneA' ? true : false
                          }
                          disabled={
                            questionOneAfter == 'questionOneA' ? false : true
                          }
                          value='questionOneA'
                          style={{ fontSize: 16 }}
                          name='questionOneSet'
                          type='radio'
                          id='plantillaOne'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='plantillaOne'
                        >
                          a. Plantilla
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionOneAfter == 'questionOneB' ? true : false
                          }
                          disabled={
                            questionOneAfter == 'questionOneB' ? false : true
                          }
                          value='questionOneB'
                          style={{ fontSize: 16 }}
                          name='questionOneSet'
                          type='radio'
                          id='contractOfServiceOne'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='contractOfServiceOne'
                        >
                          b. Contract of Service
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionOneAfter == 'questionOneC' ? true : false
                          }
                          disabled={
                            questionOneAfter == 'questionOneC' ? false : true
                          }
                          value='questionOneC'
                          style={{ fontSize: 16 }}
                          name='questionOneSet'
                          type='radio'
                          id='jobOrderOne'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='jobOrderOne'
                        >
                          c. Job Order
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionOneAfter == 'questionOneD' ? true : false
                          }
                          disabled={
                            questionOneAfter == 'questionOneD' ? false : true
                          }
                          value='questionOneD'
                          style={{ fontSize: 16 }}
                          name='questionOneSet'
                          type='radio'
                          id='consultantOne'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='consultantOne'
                        >
                          d. Consultant
                        </label>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className={'mb-4'} justify='center'>
                <Col style={{ width: 850 }}>
                  <Row>
                    <label
                      style={{
                        fontSize: 16,
                        textAlign: 'left',
                      }}
                      className={'loginInput-label-no-bold mb-3'}
                      htmlFor='preAssessment'
                    >
                      2. Refers to the hiring of a worker for piece work or
                      intermittent job of short duration not exceeding six
                      months and pay is on a daily or hourly basis. Such as
                      clearing of debris on the roads, canals, waterways, etc.
                      after natural/man-made disasters/occurrences and other
                      manual/trades and crafts services such as carpentry,
                      plumbing, electrical and the like.
                      <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row>
                    <span>
                      <em>
                        <b>{`${
                          questionTwoAfter == 'questionTwoC'
                            ? '1 point'
                            : '0 point'
                        }`}</b>
                      </em>
                    </span>
                  </Row>
                  <Row
                    align='middle'
                    style={{ height: 35, marginBottom: 12 }}
                    justify='space-between'
                  >
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionTwoAfter == 'questionTwoA' ? true : false
                          }
                          disabled={
                            questionTwoAfter == 'questionTwoA' ? false : true
                          }
                          value='questionTwoA'
                          style={{ fontSize: 16 }}
                          name='questionTwoSet'
                          type='radio'
                          id='plantillaTwo'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='plantillaTwo'
                        >
                          a. Plantilla
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionTwoAfter == 'questionTwoB' ? true : false
                          }
                          disabled={
                            questionTwoAfter == 'questionTwoB' ? false : true
                          }
                          value='questionTwoB'
                          style={{ fontSize: 16 }}
                          name='questionTwoSet'
                          type='radio'
                          id='contractOfServiceTwo'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='contractOfServiceTwo'
                        >
                          b. Contract of Service
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionTwoAfter == 'questionTwoC' ? true : false
                          }
                          disabled={
                            questionTwoAfter == 'questionTwoC' ? false : true
                          }
                          value='questionTwoC'
                          style={{ fontSize: 16 }}
                          name='questionTwoSet'
                          type='radio'
                          id='jobOrderTwo'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='jobOrderTwo'
                        >
                          c. Job Order
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionTwoAfter == 'questionTwoD' ? true : false
                          }
                          disabled={
                            questionTwoAfter == 'questionTwoD' ? false : true
                          }
                          value='questionTwoD'
                          style={{ fontSize: 16 }}
                          name='questionTwoSet'
                          type='radio'
                          id='consultantTwo'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='consultantTwo'
                        >
                          d. Consultant
                        </label>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className={'mb-4'} justify='center'>
                <Col style={{ width: 850 }}>
                  <Row>
                    <label
                      style={{
                        fontSize: 16,
                        textAlign: 'left',
                      }}
                      className={'loginInput-label-no-bold mb-3'}
                      htmlFor='preAssessment'
                    >
                      3. Which among the civilian clothing is prohibited to wear
                      by employees of Quezon City Government?{' '}
                      <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row>
                    <span>
                      <em>
                        <b>{`${
                          questionThreeAfter == 'questionThreeA'
                            ? '1 point'
                            : '0 point'
                        }`}</b>
                      </em>
                    </span>
                  </Row>
                  <Row
                    align='middle'
                    style={{ height: 35, marginBottom: 12 }}
                    justify='space-between'
                  >
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionThreeAfter == 'questionThreeA'
                              ? true
                              : false
                          }
                          disabled={
                            questionThreeAfter == 'questionThreeA'
                              ? false
                              : true
                          }
                          value='questionThreeA'
                          style={{ fontSize: 16 }}
                          name='questionThreeSet'
                          type='radio'
                          id='threeA'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='threeA'
                        >
                          a. strapless, or spaghetti-strap blouse, tank tops,
                          blouse with over-plunging necklines
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionThreeAfter == 'questionThreeB'
                              ? true
                              : false
                          }
                          disabled={
                            questionThreeAfter == 'questionThreeB'
                              ? false
                              : true
                          }
                          value='questionThreeB'
                          style={{ fontSize: 16 }}
                          name='questionThreeSet'
                          type='radio'
                          id='threeB'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='threeB'
                        >
                          b. Barong/ Filipiniana Dress
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionThreeAfter == 'questionThreeC'
                              ? true
                              : false
                          }
                          disabled={
                            questionThreeAfter == 'questionThreeC'
                              ? false
                              : true
                          }
                          value='questionThreeC'
                          style={{ fontSize: 16 }}
                          name='questionThreeSet'
                          type='radio'
                          id='threeC'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='threeC'
                        >
                          c. Collared polo shirt
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionThreeAfter == 'questionThreeD'
                              ? true
                              : false
                          }
                          disabled={
                            questionThreeAfter == 'questionThreeD'
                              ? false
                              : true
                          }
                          value='questionThreeD'
                          style={{ fontSize: 16 }}
                          name='questionThreeSet'
                          type='radio'
                          id='threeD'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='threeD'
                        >
                          d. Blazer
                        </label>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className={'mb-4'} justify='center'>
                <Col style={{ width: 850 }}>
                  <Row>
                    <label
                      style={{
                        fontSize: 16,
                        textAlign: 'left',
                      }}
                      className={
                        'loginInput-label-no-boldloginInput-label-no-bold mb-3'
                      }
                      htmlFor='preAssessment'
                    >
                      4. How many hours should a COS employee work per week?{' '}
                      <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row>
                    <span>
                      <em>
                        <b>{`${
                          questionFourAfter == 'questionFourA'
                            ? '1 point'
                            : '0 point'
                        }`}</b>
                      </em>
                    </span>
                  </Row>
                  <Row
                    align='middle'
                    style={{ height: 35, marginBottom: 12 }}
                    justify='space-between'
                  >
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionFourAfter == 'questionFourA' ? true : false
                          }
                          disabled={
                            questionFourAfter == 'questionFourA' ? false : true
                          }
                          value='questionFourA'
                          style={{ fontSize: 16 }}
                          name='questionFourSet'
                          type='radio'
                          id='fourA'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='fourA'
                        >
                          a. 40 hours
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionFourAfter == 'questionFourB' ? true : false
                          }
                          disabled={
                            questionFourAfter == 'questionFourB' ? false : true
                          }
                          value='questionFourB'
                          style={{ fontSize: 16 }}
                          name='questionFourSet'
                          type='radio'
                          id='fourB'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='fourB'
                        >
                          b. 48 hours
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionFourAfter == 'questionFourC' ? true : false
                          }
                          disabled={
                            questionFourAfter == 'questionFourC' ? false : true
                          }
                          value='questionFourC'
                          style={{ fontSize: 16 }}
                          name='questionFourSet'
                          type='radio'
                          id='fourC'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='fourC'
                        >
                          c. 24 hours
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionFourAfter == 'questionFourD' ? true : false
                          }
                          disabled={
                            questionFourAfter == 'questionFourD' ? false : true
                          }
                          value='questionFourD'
                          style={{ fontSize: 16 }}
                          name='questionFourSet'
                          type='radio'
                          id='fourD'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='fourD'
                        >
                          d. 36 hours
                        </label>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className={'mb-4'} justify='center'>
                <Col style={{ width: 850 }}>
                  <Row>
                    <label
                      style={{
                        fontSize: 16,
                        textAlign: 'left',
                      }}
                      className={'loginInput-label-no-bold mb-3'}
                      htmlFor='preAssessment'
                    >
                      5. Which among the following does not belong to RA6713?
                      <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row>
                    <span>
                      <em>
                        <b>{`${
                          questionFiveAfter == 'questionFiveD'
                            ? '1 point'
                            : '0 point'
                        }`}</b>
                      </em>
                    </span>
                  </Row>
                  <Row
                    align='middle'
                    style={{ height: 35, marginBottom: 12 }}
                    justify='space-between'
                  >
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionFiveAfter == 'questionFiveA' ? true : false
                          }
                          disabled={
                            questionFiveAfter == 'questionFiveA' ? false : true
                          }
                          value='questionFiveA'
                          style={{ fontSize: 16 }}
                          name='questionFiveSetd'
                          type='radio'
                          id='fiveA'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='fiveA'
                        >
                          a. Responsiveness
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionFiveAfter == 'questionFiveB' ? true : false
                          }
                          disabled={
                            questionFiveAfter == 'questionFiveB' ? false : true
                          }
                          value='questionFiveB'
                          style={{ fontSize: 16 }}
                          name='questionFiveSetd'
                          type='radio'
                          id='fiveB'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='fiveB'
                        >
                          b. Commitment to Democracy
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionFiveAfter == 'questionFiveC' ? true : false
                          }
                          disabled={
                            questionFiveAfter == 'questionFiveC' ? false : true
                          }
                          value='questionFiveC'
                          style={{ fontSize: 16 }}
                          name='questionFiveSetd'
                          type='radio'
                          id='fiveC'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label-no-bold'
                          htmlFor='fiveC'
                        >
                          c. Justness and Sincerity
                        </label>
                      </Row>
                    </Col>
                    <Col>
                      <Row>
                        <input
                          defaultChecked={
                            questionFiveAfter == 'questionFiveD' ? true : false
                          }
                          disabled={
                            questionFiveAfter == 'questionFiveD' ? false : true
                          }
                          value='questionFiveD'
                          style={{ fontSize: 16 }}
                          name='questionFiveSetd'
                          type='radio'
                          id='fiveD'
                        />
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='fiveD'
                        >
                          d. Loyalty
                        </label>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      ) : null}
    </Row>
  )
}
