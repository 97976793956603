/**
 * Title: usersWithPostTraining.js
 * Description: This is a file that contains the components that shows the users with post training
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/usersWithPostTraining.js
 *
 * Changes made:
 * 10/17/2024 | Harry Lagunsad | Add the employment type column in the downloaded excel or csv file
 **/
import { useState } from 'react'

import { Button, Col, Input, Modal, Row, Table, notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import xlsx from 'json-as-xlsx'
import moment from 'moment'
import PropTypes from 'prop-types'

import { DownloadOutlined } from '@ant-design/icons'

import { evaluationByTAID, registrationByTAID } from '../../api/queries'
import { convertNumToChar } from '../../utilities/convertNumToChar'
import { numToString } from '../../utilities/numToString'
import { isValidURL } from '../../utilities/utils.js'
import EditRemarks from './editRemarks.js'
import ViewSubmittedPostTraining from './viewSubmittedPostTraining.js'

const { TextArea } = Input

export default function UsersWithPostTraining({ viewCourse }) {
  const [openTableModal, setOpenTableModal] = useState(false)
  const [allRegistrants, setAllRegistrants] = useState([])
  const [allPostTrainings, setAllPostTrainings] = useState([])
  const [disableAfter, setDisableAfter] = useState(false)

  const [showEditRemarks, setShowEditRemarks] = useState(false)

  const [useNewColumns, setUseNewColumns] = useState(false)

  const getAllRegistrants = async () => {
    try {
      let nextToken = null
      let allItems = []

      do {
        const result = await API.graphql(
          graphqlOperation(registrationByTAID, {
            trainingAnnouncementID: viewCourse.id,
            limit: 1000,
            nextToken: nextToken, // Pass the nextToken to fetch the next set of items
          })
        )

        const items = result.data.registrationByTAID.items
        allItems = allItems.concat(items) // Append the items to the existing array

        nextToken = result.data.registrationByTAID.nextToken // Update the nextToken for the next iteration
      } while (nextToken) // Repeat the loop as long as nextToken is present

      if (allItems.length) {
        if (allItems[0]?.first_name) {
          setUseNewColumns(true)
        } else {
          setUseNewColumns(false)
        }

        const withCount = allItems
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .map((obj, index) => {
            return {
              ...obj,
              count: index + 1,
            }
          })

        setOpenTableModal(true)
        setTimeout(() => {
          setShowEditRemarks(true)
        }, 200)
        setAllRegistrants(
          withCount.sort(
            (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
          )
        )
      } else {
        notification['info']({
          message: 'Info',
          description: 'No registrants for this event.',
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getAllPostTrainings = async () => {
    try {
      let nextToken = null
      let allItems = []

      do {
        const result = await API.graphql(
          graphqlOperation(evaluationByTAID, {
            trainingAnnouncementID: viewCourse.id,
            limit: 1000,
            nextToken: nextToken, // Pass the nextToken to fetch the next set of items
          })
        )

        const items = result.data.evaluationByTAID.items
        allItems = allItems.concat(items) // Append the items to the existing array

        nextToken = result.data.evaluationByTAID.nextToken // Update the nextToken for the next iteration
      } while (nextToken) // Repeat the loop as long as nextToken is present

      if (allItems.length) {
        setAllPostTrainings(allItems)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const handleOpen = () => {
    getAllRegistrants()
    getAllPostTrainings()
  }

  const handleDisable = () => {
    if (allRegistrants.length) {
      return disableAfter !== false
    } else {
      return true
    }
  }

  const employmentTypeValueGetter = (e) => {
    if (e === 'permanentOrRegular') {
      return 'Permanent or Regular'
    } else if (e === 'cosOrJoOrConsultant') {
      return 'COS or JO or Consultant'
    } else {
      return ''
    }
  }

  let holdColumns = [
    { label: 'Data Privacy Consent', value: 'Data Privacy Consent' },
    { label: 'Date Answered', value: 'Date Answered' },
    { label: 'FULL NAME', value: 'FULL NAME' },
    { label: 'NICKNAME', value: 'NICKNAME' },
    { label: 'CONTACT NUMBER', value: 'CONTACT NUMBER' },
    { label: 'EMPLOYMENT TYPE', value: 'EMPLOYMENT TYPE' },
    {
      label: 'DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE',
      value: 'DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE',
    },
    { label: 'DESIGNATION/POSITION', value: 'DESIGNATION/POSITION' },
    { label: 'DIVISION/ SECTION', value: 'DIVISION/ SECTION' },
    { label: 'EMAIL ADDRESS', value: 'EMAIL ADDRESS' },
    { label: 'SEX', value: 'SEX' },
    { label: 'Endorsement Letter', value: 'Endorsement Letter' },
    { label: 'Expected Outcomes', value: 'Expected Outcomes' },
    { label: 'Comments/Feedbacks', value: 'Comments/Feedbacks' },
  ]

  const toDownloadData = () => {
    const toDownload = allRegistrants.map((obj) => {
      const {
        isAgreeDataPrivacyConsent,
        contactNumber,
        employmentType,
        createdAt,
        dept_office_unit_taskForce,
        designation_position,
        division_section,
        emailAddress,
        fullName,
        gender,
        endorsementLetter,
        expectedOutcomes,
        nickname,
        first_name,
        middle_initial,
        last_name,
      } = obj
      const q = JSON.parse(obj.questionnaire)
      const postTrainData = allPostTrainings.find(
        (data) => data.userID === obj.userID
      )

      let questionnaireCheck

      try {
        questionnaireCheck = JSON.parse(postTrainData.questionnaire)
      } catch {
        questionnaireCheck = null
      }

      if (questionnaireCheck && Object.keys(questionnaireCheck).length > 0) {
        const rawObject = JSON.parse(postTrainData.cscComments)
        const newObject = {}

        for (const key in rawObject) {
          if (key.includes('categoryQuestion')) {
            const categoryNumber = key.split('-')[1]
            const additionalCommentsKey = `additionalComments${categoryNumber}`
            newObject[rawObject[key].replace(/\n/g, '').replace(/,/g, '_')] =
              rawObject[additionalCommentsKey]
          }
        }

        const parsedData = JSON.parse(postTrainData.questionnaire)
        if (
          typeof parsedData === 'object' &&
          Object.prototype.toString.call(parsedData) === '[object Object]'
        ) {
          const answers = JSON.parse(postTrainData.questionnaire)
          let score = 0
          let dynamicQs = {}
          let dynamicEvals = new Map()

          if (viewCourse.questionsAssessment !== null) {
            let assesQuestionCorrectAns = JSON.parse(
              viewCourse.questionsAssessment.assessmentQuestionnaire
            )
            let evalItems

            if (viewCourse?.evalQuestionnaire?.eval) {
              evalItems = JSON.parse(viewCourse.evalQuestionnaire.eval)
            }

            assesQuestionCorrectAns.forEach((elem, index) => {
              const numString = numToString(index)
              const question = 'question' + numString
              if (answers[question]) {
                const itemAnswer = elem.correctAnswer
                const userAnswer =
                  answers[question].length === 1
                    ? answers[question]
                    : getOldAnswer()
                function getOldAnswer() {
                  let oldAnswers =
                    answers[question] == 'plantilla'
                      ? 'A'
                      : answers[question] == 'jobOrder'
                        ? 'C'
                        : answers[question] == 'contractOfService'
                          ? 'B'
                          : answers[question] == 'consultant'
                            ? 'D'
                            : answers[question][q[question].length - 1]
                  return oldAnswers
                }

                let isCorrect = itemAnswer == userAnswer

                dynamicQs[`Q${index + 1}-picked`] = answers[question]
                dynamicQs[`Q${index + 1}`] = isCorrect ? '1' : '0'
                if (isCorrect) {
                  score += 1
                }
              }
            })
            if (evalItems) {
              const postEvalAnswers = postTrainData.postEvalQuestions
                ? JSON.parse(postTrainData.postEvalQuestions)
                : {}

              evalItems.forEach((item, itemInd) => {
                const question = item.question.replace(/\n/g, '')

                if (item.categories.length > 0) {
                  item.categories.forEach((category, catInd) => {
                    const cat =
                      category[`category-${catInd + 1}-${itemInd + 1}`]

                    // Ensure category key exists before proceeding
                    if (!cat) {
                      console.log(
                        `Missing category key for item ${
                          itemInd + 1
                        }, category ${catInd + 1}`
                      )
                      return // Skip this iteration if category is missing
                    }

                    const arrangedArr = [
                      [
                        postTrainData.clarityOfObjectives,
                        postTrainData.relevanceOfContent,
                        postTrainData.sequenceOfContent,
                      ],
                      [
                        postTrainData.facilitationMOTS,
                        postTrainData.facilitationTimeMgt,
                        postTrainData.facilitationProfConduct,
                      ],
                      [
                        postTrainData.adminMOTS,
                        postTrainData.adminTimeMgt,
                        postTrainData.adminProfConduct,
                      ],
                    ]

                    let rating = null
                    const evalKey = `set${convertNumToChar(
                      itemInd
                    )}Q${numToString(catInd)}`

                    // Fetch the rating from the postEvalAnswers or use the arrangedArr fallback
                    if (postEvalAnswers[evalKey]) {
                      rating = postEvalAnswers[evalKey]
                    } else if (
                      arrangedArr[itemInd] &&
                      arrangedArr[itemInd][catInd] !== undefined
                    ) {
                      rating = arrangedArr[itemInd][catInd]
                    }

                    // Store the evaluation result in dynamicEvals
                    if (rating !== null) {
                      dynamicEvals[`${question} - ${cat}`] = rating
                    }
                  })
                } else {
                  // Handle cases without categories (comments/feedback)
                  const letter = convertNumToChar(itemInd)
                  const commentKey = `set${letter}Comment`
                  const comment = postEvalAnswers[commentKey] || 'N/A'

                  dynamicEvals[`${question} - (${itemInd})`] = comment
                }
              })
            }

            let excelValue = {
              id: postTrainData.id,
              ['Data Privacy Consent']:
                isAgreeDataPrivacyConsent === true ? 'YES' : 'NO',
              ['Date Answered']: moment(createdAt).format(
                'MMM. DD, YYYY h:mm:ss A'
              ),
              ['FULL NAME']: fullName,
              ['NICKNAME']: nickname,
              ['CONTACT NUMBER']: `'${contactNumber}`,
              ['EMPLOYMENT TYPE']:
                employmentTypeValueGetter(employmentType) || '',
              ['DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE']:
                dept_office_unit_taskForce,
              ['DESIGNATION/POSITION']: designation_position,
              ['DIVISION/ SECTION']: division_section,
              ['EMAIL ADDRESS']: emailAddress,
              ['SEX']: gender == 'notSpecified' ? 'Not specified' : gender,
              ['Endorsement Letter']: endorsementLetter,
              ['Expected Outcomes']: expectedOutcomes,
              ...dynamicQs,
              ['QTotal']: score,
              ...dynamicEvals,
            }

            if (useNewColumns) {
              excelValue['FIRST NAME'] = first_name
              excelValue['MIDDLE INITIAL'] = middle_initial
              excelValue['LAST NAME'] = last_name
              delete excelValue['FULL NAME']
            }

            return excelValue
          }
        } else {
          if (postTrainData) {
            let dynamicEvals = new Map()
            let evalItems = JSON.parse(viewCourse.evalQuestionnaire.eval)

            const postEvalAnswers = JSON.parse(postTrainData.postEvalQuestions)
            evalItems.forEach((item, itemInd) => {
              const question = item.question.replace(/\n/g, '')
              if (item.categories.length > 0) {
                item.categories.forEach((category, catInd) => {
                  const cat = category[`category-${catInd + 1}-${itemInd + 1}`]

                  const {
                    clarityOfObjectives,
                    relevanceOfContent,
                    sequenceOfContent,
                    facilitationMOTS,
                    facilitationTimeMgt,
                    facilitationProfConduct,
                    adminMOTS,
                    adminTimeMgt,
                    adminProfConduct,
                  } = postTrainData
                  const arrangedArr = [
                    [
                      clarityOfObjectives,
                      relevanceOfContent,
                      sequenceOfContent,
                    ],
                    [
                      facilitationMOTS,
                      facilitationTimeMgt,
                      facilitationProfConduct,
                    ],
                    [adminMOTS, adminTimeMgt, adminProfConduct],
                  ]

                  let rating
                  if (postTrainData.postEvalQuestions) {
                    if (
                      postEvalAnswers[
                        `set${convertNumToChar(itemInd)}Q${numToString(catInd)}`
                      ]
                    ) {
                      rating =
                        postEvalAnswers[
                          `set${convertNumToChar(itemInd)}Q${numToString(catInd)}`
                        ]
                    }
                  }
                  dynamicEvals[`${question} - ${cat}`] = rating
                    ? rating
                    : arrangedArr[itemInd][catInd]
                })
              } else {
                const letter = convertNumToChar(itemInd)
                const comment = postEvalAnswers['set' + letter + 'Comment']
                dynamicEvals[`${question}-(${itemInd})`] = comment
              }
            })

            if (postTrainData.cscComments) {
              let excelValue = {
                id: postTrainData.id,
                ['Data Privacy Consent']:
                  isAgreeDataPrivacyConsent === true ? 'YES' : 'NO',
                ['Date Answered']: moment(createdAt).format(
                  'MMM. DD, YYYY h:mm:ss A'
                ),
                ['FULL NAME']: fullName,
                ['NICKNAME']: nickname,
                ['CONTACT NUMBER']: `'${contactNumber}`,
                ['EMPLOYMENT TYPE']:
                  employmentTypeValueGetter(employmentType) || '',
                ['DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE']:
                  dept_office_unit_taskForce,
                ['DESIGNATION/POSITION']: designation_position,
                ['DIVISION/ SECTION']: division_section,
                ['EMAIL ADDRESS']: emailAddress,
                ['SEX']: gender == 'notSpecified' ? 'Not specified' : gender,
                ['Endorsement Letter']: endorsementLetter,
                ['Expected Outcomes']: expectedOutcomes,
                ...dynamicEvals,
                ...newObject,
              }

              if (useNewColumns) {
                excelValue['FIRST NAME'] = first_name
                excelValue['MIDDLE INITIAL'] = middle_initial
                excelValue['LAST NAME'] = last_name
                delete excelValue['FULL NAME']
                return excelValue
              } else {
                return excelValue
              }
            } else {
              let excelValue = {
                id: postTrainData.id,
                ['Data Privacy Consent']:
                  isAgreeDataPrivacyConsent === true ? 'YES' : 'NO',
                ['Date Answered']: moment(createdAt).format(
                  'MMM. DD, YYYY h:mm:ss A'
                ),
                ['FULL NAME']: fullName,
                ['NICKNAME']: nickname,
                ['CONTACT NUMBER']: `'${contactNumber}`,
                ['EMPLOYMENT TYPE']:
                  employmentTypeValueGetter(employmentType) || '',
                ['DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE']:
                  dept_office_unit_taskForce,
                ['DESIGNATION/POSITION']: designation_position,
                ['DIVISION/ SECTION']: division_section,
                ['EMAIL ADDRESS']: emailAddress,
                ['SEX']: gender == 'notSpecified' ? 'Not specified' : gender,
                ['Endorsement Letter']: endorsementLetter,
                ['Expected Outcomes']: expectedOutcomes,
                ...dynamicEvals,
              }

              if (useNewColumns) {
                excelValue['FIRST NAME'] = first_name
                excelValue['MIDDLE INITIAL'] = middle_initial
                excelValue['LAST NAME'] = last_name
                delete excelValue['FULL NAME']
                return excelValue
              } else {
                return excelValue
              }
            }
          } else {
            let excelValue = {
              id: postTrainData.id,
              ['Data Privacy Consent']:
                isAgreeDataPrivacyConsent === true ? 'YES' : 'NO',
              ['Date Answered']: moment(createdAt).format(
                'MMM. DD, YYYY h:mm:ss A'
              ),
              ['FULL NAME']: fullName,
              ['NICKNAME']: nickname,
              ['CONTACT NUMBER']: `'${contactNumber}`,
              ['EMPLOYMENT TYPE']:
                employmentTypeValueGetter(employmentType) || '',
              ['DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE']:
                dept_office_unit_taskForce,
              ['DESIGNATION/POSITION']: designation_position,
              ['DIVISION/ SECTION']: division_section,
              ['EMAIL ADDRESS']: emailAddress,
              ['SEX']: gender == 'notSpecified' ? 'Not specified' : gender,
              ['Endorsement Letter']: endorsementLetter,
              ['Expected Outcomes']: expectedOutcomes,
              ['Comments/Feedbacks']: postTrainData?.additionalComments
                ? postTrainData.additionalComments
                : '',
            }

            if (useNewColumns) {
              excelValue['FIRST NAME'] = first_name
              excelValue['MIDDLE INITIAL'] = middle_initial
              excelValue['LAST NAME'] = last_name
              delete excelValue['FULL NAME']
              return excelValue
            } else {
              return excelValue
            }
          }
        }
      } else {
        if (postTrainData) {
          let dynamicEvals = new Map()
          let evalItems = JSON.parse(viewCourse.evalQuestionnaire.eval)
          const postEvalAnswers = JSON.parse(postTrainData.postEvalQuestions)

          evalItems.forEach((item, itemInd) => {
            const question = item.question.replace(/\n/g, '')
            if (item.categories.length > 0) {
              item.categories.forEach((category, catInd) => {
                const cat = category[`category-${catInd + 1}-${itemInd + 1}`]

                const {
                  clarityOfObjectives,
                  relevanceOfContent,
                  sequenceOfContent,
                  facilitationMOTS,
                  facilitationTimeMgt,
                  facilitationProfConduct,
                  adminMOTS,
                  adminTimeMgt,
                  adminProfConduct,
                } = postTrainData
                const arrangedArr = [
                  [clarityOfObjectives, relevanceOfContent, sequenceOfContent],
                  [
                    facilitationMOTS,
                    facilitationTimeMgt,
                    facilitationProfConduct,
                  ],
                  [adminMOTS, adminTimeMgt, adminProfConduct],
                ]

                let rating
                if (postTrainData.postEvalQuestions) {
                  if (
                    postEvalAnswers[
                      `set${convertNumToChar(itemInd)}Q${numToString(catInd)}`
                    ]
                  ) {
                    rating =
                      postEvalAnswers[
                        `set${convertNumToChar(itemInd)}Q${numToString(catInd)}`
                      ]
                  }
                }
                dynamicEvals[`${question} - ${cat}`] = rating
                  ? rating
                  : arrangedArr[itemInd][catInd]
              })
            } else {
              const letter = convertNumToChar(itemInd)
              const comment = postEvalAnswers['set' + letter + 'Comment']
              dynamicEvals[`${question}-(${itemInd})`] = comment
            }
          })

          const rawObject = JSON.parse(postTrainData.cscComments)
          const newObject = {}

          for (const key in rawObject) {
            if (key.includes('categoryQuestion')) {
              const categoryNumber = key.split('-')[1]
              const additionalCommentsKey = `additionalComments${categoryNumber}`
              newObject[rawObject[key].replace(/\n/g, '').replace(/,/g, '_')] =
                rawObject[additionalCommentsKey]
            }
          }

          if (postTrainData.cscComments) {
            let excelValue = {
              id: postTrainData.id,
              ['Data Privacy Consent']:
                isAgreeDataPrivacyConsent === true ? 'YES' : 'NO',
              ['Date Answered']: moment(createdAt).format(
                'MMM. DD, YYYY h:mm:ss A'
              ),
              ['FULL NAME']: fullName,
              ['NICKNAME']: nickname,
              ['CONTACT NUMBER']: `'${contactNumber}`,
              ['EMPLOYMENT TYPE']:
                employmentTypeValueGetter(employmentType) || '',
              ['DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE']:
                dept_office_unit_taskForce,
              ['DESIGNATION/POSITION']: designation_position,
              ['DIVISION/ SECTION']: division_section,
              ['EMAIL ADDRESS']: emailAddress,
              ['SEX']: gender == 'notSpecified' ? 'Not specified' : gender,
              ['Endorsement Letter']: endorsementLetter,
              ['Expected Outcomes']: expectedOutcomes,
              ...dynamicEvals,
              ...newObject,
            }

            if (useNewColumns) {
              excelValue['FIRST NAME'] = first_name
              excelValue['MIDDLE INITIAL'] = middle_initial
              excelValue['LAST NAME'] = last_name
              delete excelValue['FULL NAME']
              return excelValue
            } else {
              return excelValue
            }
          } else {
            let excelValue = {
              id: postTrainData.id,
              ['Data Privacy Consent']:
                isAgreeDataPrivacyConsent === true ? 'YES' : 'NO',
              ['Date Answered']: moment(createdAt).format(
                'MMM. DD, YYYY h:mm:ss A'
              ),
              ['FULL NAME']: fullName,
              ['NICKNAME']: nickname,
              ['CONTACT NUMBER']: `'${contactNumber}`,
              ['EMPLOYMENT TYPE']:
                employmentTypeValueGetter(employmentType) || '',
              ['DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE']:
                dept_office_unit_taskForce,
              ['DESIGNATION/POSITION']: designation_position,
              ['DIVISION/ SECTION']: division_section,
              ['EMAIL ADDRESS']: emailAddress,
              ['SEX']: gender == 'notSpecified' ? 'Not specified' : gender,
              ['Endorsement Letter']: endorsementLetter,
              ['Expected Outcomes']: expectedOutcomes,
              ...dynamicEvals,
            }

            if (useNewColumns) {
              excelValue['FIRST NAME'] = first_name
              excelValue['MIDDLE INITIAL'] = middle_initial
              excelValue['LAST NAME'] = last_name
              delete excelValue['FULL NAME']
              return excelValue
            } else {
              return excelValue
            }
          }
        } else {
          let excelValue = {
            ['Data Privacy Consent']:
              isAgreeDataPrivacyConsent === true ? 'YES' : 'NO',
            ['Date Answered']: moment(createdAt).format(
              'MMM. DD, YYYY h:mm:ss A'
            ),
            ['FULL NAME']: fullName,
            ['NICKNAME']: nickname,
            ['CONTACT NUMBER']: `'${contactNumber}`,
            ['EMPLOYMENT TYPE']:
              employmentTypeValueGetter(employmentType) || '',
            ['DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE']:
              dept_office_unit_taskForce,
            ['DESIGNATION/POSITION']: designation_position,
            ['DIVISION/ SECTION']: division_section,
            ['EMAIL ADDRESS']: emailAddress,
            ['SEX']: gender == 'notSpecified' ? 'Not specified' : gender,
            ['Endorsement Letter']: endorsementLetter,
            ['Expected Outcomes']: expectedOutcomes,
            ['Comments/Feedbacks']: postTrainData?.additionalComments
              ? postTrainData.additionalComments
              : '',
          }

          if (useNewColumns) {
            excelValue['FIRST NAME'] = first_name
            excelValue['MIDDLE INITIAL'] = middle_initial
            excelValue['LAST NAME'] = last_name
            delete excelValue['FULL NAME']
            return excelValue
          } else {
            return excelValue
          }
        }
      }
    })

    return toDownload
  }

  const cutStringToMaxLength = (str, maxLength) => {
    if (str.length <= maxLength) {
      return str
    } else {
      let cutString = str.substring(0, maxLength).trim()
      cutString = cutString.substring(0, cutString.lastIndexOf(' '))
      return cutString
    }
  }

  let afterDownload = function () {
    notification['success']({
      message: 'Success',
      description: 'Excel file downloaded.',
    })
  }

  const handleDownload = async () => {
    setDisableAfter(true)
    try {
      let dynamicColumns = []
      if (useNewColumns) {
        dynamicColumns = holdColumns.filter((obj) => obj.label !== 'FULL NAME')
        dynamicColumns.splice(
          1,
          0,
          { label: 'FIRST NAME', value: 'FIRST NAME' },
          { label: 'MIDDLE INITIAL', value: 'MIDDLE INITIAL' },
          { label: 'LAST NAME', value: 'LAST NAME' }
        )
      } else {
        dynamicColumns = holdColumns
      }

      if (viewCourse.questionsAssessment !== null) {
        dynamicColumns.push({ label: 'Post-assessment Total', value: 'QTotal' })
        for (
          let i = 0;
          i <
          JSON.parse(viewCourse.questionsAssessment.assessmentQuestionnaire)
            .length;
          i++
        ) {
          const questionLabel = `Post-assessment Q${i + 1}`
          const questionValue = `Q${i + 1}`
          const pickedAnswer = `Q${i + 1}-picked`
          dynamicColumns.splice(dynamicColumns.length + i, 0, {
            label: `${questionLabel}`,
            value: questionValue,
          })
          dynamicColumns.push({
            label: `${questionLabel} picked answer`,
            value: pickedAnswer,
          })
        }
      }

      if (viewCourse?.evalID !== 'ok' && viewCourse?.evalQuestionnaire) {
        const evalItems = JSON.parse(viewCourse.evalQuestionnaire.eval)
        evalItems.forEach((item, itemInd) => {
          const question = item.question.replace(/\n/g, '')
          if (item.categories.length > 0) {
            item.categories.forEach((category, catInd) => {
              const cat = category[`category-${catInd + 1}-${itemInd + 1}`]
              dynamicColumns.splice(dynamicColumns.length, 0, {
                label: `${question} - ${cat}`,
                value: `${question} - ${cat}`,
              })
            })
          } else {
            dynamicColumns.splice(dynamicColumns.length, 0, {
              label: `${question}`,
              value: `${question}`,
            })
          }
        })
      }

      const toDownload = toDownloadData()
      let data = [
        {
          sheet: 'Registrants',
          columns: dynamicColumns,
          content: toDownload.sort(
            (a, b) =>
              new Date(b['Date Answered']) - new Date(a['Date Answered'])
          ),
        },
      ]

      const cutString = cutStringToMaxLength(viewCourse.title, 80)

      let settings = {
        fileName: `Submitted Post Training - ${
          viewCourse.title ? cutString : ''
        }`, // Name of the resulting spreadsheet
        extraLength: 4, // A bigger number means that columns will be wider
        // writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        // writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
        // RTL: true, // Display the columns from right-to-left (the default value is false)
      }

      function downloadCSV(csv, filename) {
        const csvBlob = new Blob([csv], { type: 'text/csv;charset=utf-8;' })
        if (navigator.msSaveBlob) {
          // IE 10+
          navigator.msSaveBlob(csvBlob, filename)
        } else {
          const link = document.createElement('a')
          if (link.download !== undefined) {
            // Feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(csvBlob)
            link.setAttribute('href', url)
            link.setAttribute('download', filename)
            link.style.visibility = 'hidden'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)

            afterDownload()
          } else {
            console.error('Unable to download CSV file on this browser.')
          }
        }
      }

      if (
        viewCourse.id === 'fbcb35cd-3702-4734-adc4-6f31f84025df' ||
        viewCourse.id === '1c885c6d-4147-41d5-aa5c-3762521d5b88' ||
        viewCourse.id === '5a871469-61c2-4050-936e-5ce25cbc50ab' ||
        viewCourse.id === '844efb56-51b0-4ef9-930c-9942e8a8c8f6'
      ) {
        const cleanedData = toDownload.map((obj) => {
          const cleanedObj = {}
          for (let key in obj) {
            cleanedObj[key.replace(/,/g, '_')] = obj[key]
          }
          return cleanedObj
        })

        const filteredData = cleanedData.filter((obj) => {
          if (obj.hasOwnProperty('PROGRAM DESIGN - Clarity of Objectives')) {
            return obj
          }
        })

        function exportToCSV(array, filename) {
          const csvHeader = Object.keys(array[0])
            .map((key) => `"${key}"`)
            .join(',')
          const csvRows = array.map((obj) =>
            Object.values(obj)
              .map((val) =>
                typeof val === 'string'
                  ? `"${val.replace(/"/g, '""').replace(/\n/g, ' ')}"`
                  : `"${val.replace(/\n/g, ' ')}"`
              )
              .join(',')
          )
          const csv = [csvHeader, ...csvRows].join('\n')

          downloadCSV(csv, filename)
        }

        exportToCSV(
          filteredData,
          `Submitted Post Training - ${viewCourse.title ? cutString : ''}`
        )
      } else {
        const createdAt = moment(new Date(viewCourse.createdAt))
        const currentPeriodDate = 'May 1, 2023 12:00:00 AM'

        if (
          !createdAt.isSameOrAfter(currentPeriodDate, 'MMM. DD, YYYY h:mm:ss A')
        ) {
          xlsx(data, settings, afterDownload)
        } else {
          const cleanedData = toDownload.map((obj) => {
            const cleanedObj = {}
            for (let key in obj) {
              cleanedObj[key.replace(/,/g, '_')] = obj[key]
            }
            return cleanedObj
          })

          const filteredData = cleanedData.filter((obj) => {
            if (obj.id) {
              return obj
            }
          })

          function exportToCSV(array, filename) {
            const originalMap = new Map(Object.entries(array[0]))
            const entriesArray = Array.from(originalMap.entries())

            // Step 2: Extract the last 3 entries
            const lastThreeEntries = entriesArray.slice(-3)

            // Step 3: Remove the last 3 entries
            entriesArray.splice(-3)

            // Step 4: Insert the extracted entries at the 3rd index
            entriesArray.splice(2, 0, ...lastThreeEntries)

            // Step 5: Create a new Map using the modified array
            const modifiedMap = new Map(entriesArray)
            const csvHeader = Array.from(modifiedMap.keys())
              .map((key) => `"${key}"`)
              .join(',')

            const modifiedArray = array.map((obj) => {
              const objMap = new Map()

              modifiedMap.forEach((value, key) => {
                if (obj.hasOwnProperty(key)) {
                  objMap.set(key, obj[key])
                }
              })

              return objMap
            })

            const csvRows = modifiedArray.map((objMap) =>
              Array.from(objMap.values())
                .map((val) => {
                  return typeof val === 'string'
                    ? `"${
                        val
                          ? val
                              .toString()
                              .replace(/"/g, '""')
                              .replace(/\n/g, ' ')
                          : ''
                      }"`
                    : `"${val ? val.toString().replace(/\n/g, ' ') : ''}"`
                })
                .join(',')
            )
            const csv = [csvHeader, ...csvRows].join('\n')

            downloadCSV(csv, filename)
          }

          if (filteredData.length) {
            exportToCSV(
              filteredData,
              `Submitted Post Training - ${viewCourse.title ? cutString : ''}`
            )
          } else {
            notification['info']({
              message: 'Info',
              description: 'No post training answers was submitted yet.',
            })
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'count',
      key: 'count',
      width: '3%',
      render: (text, record) => (
        <span>
          <b>{record.count}</b>
        </span>
      ),
    },
    {
      title: 'FULL NAME',
      dataIndex: 'fullName',
      key: 'name',
      width: '14%',
      render: (text, record) =>
        record.fullName ? (
          <span>
            <b>{record.fullName}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'EMAIL ADDRESS',
      width: '13%',
      render: (text, record) =>
        record.emailAddress ? (
          <span color='geekblue'>
            <b>{`${record.emailAddress}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE',
      width: '9%',
      render: (text, record) =>
        record.dept_office_unit_taskForce ? (
          <span>
            <b>{`${record.dept_office_unit_taskForce}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'SUBMITTED ENDORSEMENT LETTER?',
      width: '9%',
      render: (text, record) => {
        try {
          return record.userID ? (
            <span>
              {record?.endorsementLetter?.length ? (
                <>
                  {Array.isArray(JSON.parse(record.endorsementLetter)) ? (
                    <>
                      {JSON.parse(record.endorsementLetter).map((obj) => {
                        let url = obj
                        if (isValidURL(url)) {
                          encodeURI(url)
                          url = url.replace(/#/g, '%23')
                        }
                        return (
                          <Row key={url}>
                            <a
                              href={url}
                              target='_blank'
                              rel='noreferrer noopener'
                            >
                              <b>Open here</b>
                            </a>
                          </Row>
                        )
                      })}
                    </>
                  ) : (
                    (() => {
                      let url = record.endorsementLetter
                      if (isValidURL(url)) {
                        url = encodeURI(url).replace(/#/g, '%23')
                      }
                      return (
                        <a href={url} target='_blank' rel='noreferrer noopener'>
                          <b>Open here</b>
                        </a>
                      )
                    })()
                  )}
                </>
              ) : (
                <b style={{ color: 'red' }}>NO</b>
              )}
            </span>
          ) : (
            'record missing'
          )
        } catch (err) {
          let url = record.endorsementLetter
          if (isValidURL(url)) {
            encodeURI(url)
            url = url.replace(/#/g, '%23')
          }

          return (
            <a href={url} target='_blank' rel='noreferrer noopener'>
              <b>Open here</b>
            </a>
          )
        }
      },
    },
    {
      title: 'SUBMITTED POST TRAINING?',
      width: '9%',
      render: (text, record) => {
        const foundUser = allPostTrainings.filter((f) => {
          if (f.userID === record.userID) {
            return f
          }
        })

        return record.userID ? (
          <span>
            {foundUser.length >= 1 ? (
              <ViewSubmittedPostTraining f={foundUser[0]} record={record} />
            ) : (
              <b style={{ color: 'red' }}>NO</b>
            )}
          </span>
        ) : (
          'record missing'
        )
      },
    },
    {
      title: (
        <Col span={24}>
          <Row justify='center' style={{ width: '100%' }}>
            <span>REQUIREMENTS COMPLETED</span>
          </Row>
        </Col>
      ),
      width: '43%',
      render: (text, record) => {
        return (
          <>
            {showEditRemarks ? (
              <EditRemarks
                record={record}
                allPostTrainings={allPostTrainings}
              />
            ) : null}
          </>
        )
      },
    },
  ]

  let newColumns = columns.filter((obj) => obj.title !== 'FULL NAME')
  newColumns.splice(
    1,
    0,
    {
      title: 'FIRST NAME',
      dataIndex: 'first_name',
      key: 'first_name',
      width: '5%',
      render: (text, record) =>
        record.first_name ? (
          <span>
            <b>{record.first_name}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'MIDDLE INITIAL',
      dataIndex: 'middle_initial',
      key: 'middle_initial',
      width: '4%',
      render: (text, record) =>
        record.middle_initial ? (
          <span>
            <b>{record.middle_initial}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'LAST NAME',
      dataIndex: 'last_name',
      key: 'last_name',
      width: '5%',
      render: (text, record) =>
        record.last_name ? (
          <span>
            <b>{record.last_name}</b>
          </span>
        ) : (
          'record missing'
        ),
    }
  )

  return (
    <>
      <Button onClick={handleOpen}>View Submitted Post Training Forms</Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={'Registrants'}
        centered
        visible={openTableModal}
        onOk={() => {
          setAllPostTrainings([])
          setOpenTableModal(false)
        }}
        onCancel={() => {
          setOpenTableModal(false)
          setAllPostTrainings([])
          setDisableAfter(false)
          setShowEditRemarks(false)
        }}
        width={'100%'}
        footer={null}
      >
        <Row>
          <Table
            bordered
            rowKey='id'
            dataSource={allRegistrants}
            columns={useNewColumns ? newColumns : columns}
            showSizeChanger={false}
            pagination={{ pageSize: 50 }}
            className='table-responsive'
          />
        </Row>
        <Row>
          {allRegistrants.length ? (
            <Button
              disabled={handleDisable()}
              onClick={handleDownload}
              type='primary'
              icon={<DownloadOutlined />}
              size={'large'}
            >
              Download
            </Button>
          ) : null}
        </Row>
      </Modal>
    </>
  )
}

// props validation
UsersWithPostTraining.propTypes = {
  viewCourse: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    createdAt: PropTypes.string,
    questionsAssessment: PropTypes.object,
    evalID: PropTypes.string,
    evalQuestionnaire: PropTypes.object,
  }),
}
