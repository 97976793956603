/**
 * Title: changePassword.js
 * Description: This is a file that contains the components for changing password
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Login/changePassword.js
 **/

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { Input, Row, Col, notification, Modal } from 'antd'
import { MailTwoTone, LockTwoTone, CodeTwoTone } from '@ant-design/icons'

import { Auth } from 'aws-amplify'

import moment from 'moment'

export default function ChangePassword() {
  const navigate = useNavigate()

  const [username, setUsername] = useState(null)
  const [verificationCode, setVerificationCode] = useState(null)
  const [newPassword, setNewPassword] = useState(null)
  const [loading, setLoading] = useState(false)
  const [newPasswordBtn, setNewPasswordBtn] = useState(false)
  const [showDay, setShowDay] = useState(null)
  const [showDate, setShowDate] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [usernameError, setUsernameError] = useState(null)
  const [passwordError, setPasswordError] = useState([])
  useEffect(() => {
    var date = moment().format('MMMM Do YYYY')
    var day = moment().format('dddd')
    setShowDay(day)
    setShowDate(date)
  }, [])

  /**
   * @function showModalInstructions
   * @description Displays the modal with instructions by setting the modal visibility state to true.
   */
  const showModalInstructions = () => {
    try {
      setIsModalVisible(true)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function handleOk
   * @description Handles the OK button click event for the modal. Sets the modal visibility state to false.
   */
  const handleOk = () => {
    try {
      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function handleCancel
   * @description Handles the cancel button click event for the modal. Sets the modal visibility state to false.
   */
  const handleCancel = () => {
    try {
      setIsModalVisible(false)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function handleCode
   * @description Handles the form submission for the forgot password functionality.
   * Validates the username and triggers the forgot password process.
   * @param {Event} e - The form submission event.
   */
  const handleCode = (e) => {
    e.preventDefault()

    // Regular expression for valid email characters
    const validUsernameRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    if (!username || username.trim() === '') {
      setUsernameError('username cannot be blank')
      notification['error']({
        message: 'Error',
        description: 'username cannot be blank',
      })
    } else if (!validUsernameRegex.test(username)) {
      setUsernameError('Please enter a valid username.')
      notification['error']({
        message: 'Error',
        description: 'Please enter a valid username.',
      })
    } else {
      Auth.forgotPassword(username)
        .then((data) => {
          console.log(data)
          setNewPasswordBtn(true)
          if (data) {
            setIsModalVisible(true)

            notification['info']({
              message: 'Information',
              description:
                "If you didn't receive your change password email with the verification code, please click the link below for more instructions.",
              duration: 120000,
            })
          }
        })
        .catch((err) => console.log(err))
    }
  }

  /**
   * @function handleNewPassword
   * @description Handles the form submission for setting a new password.
   * Validates the new password and triggers the password reset process.
   * @param {Event} e - The form submission event.
   */
  const handleNewPassword = (e) => {
    try {
      e.preventDefault()
      let errors = []
      if (!newPassword) {
        errors.push('Password cannot be blank.')
      } else if (newPassword && newPassword.length < 8) {
        errors.push('Password should be at least 8 characters.')
      }
      if (!/\d/.test(newPassword)) {
        // Check for at least one number
        errors.push('Password should contain at least 1 number.')
      }
      if (!/[A-Z]/.test(newPassword)) {
        // Check for at least one uppercase letter
        errors.push('Password should contain at least 1 uppercase letter.')
      }
      if (!/[a-z]/.test(newPassword)) {
        // Check for at least one lowercase letter
        errors.push('Password should contain at least 1 lowercase letter.')
      }

      if (/\s{2,}/.test(newPassword)) {
        errors.push('Password should not contain consecutive spaces.')
      }
      
      if (errors.length > 0) {
        setPasswordError(errors)
        notification['error']({
          message: 'Password Error',
          description: 'Check your password requirements.',
        })
      } else {
        setLoading(true)

        Auth.forgotPasswordSubmit(username, verificationCode, newPassword)
          .then(() => {
            notification['success']({
              message: 'Success',
              description:
                'Your password was changed. You may proceed to login again.',
            })

            setTimeout(() => {
              navigate('/login')
            }, 2000)
          })
          .catch((err) => {
            const newErr = err.toString()

            console.log(err)

            if (newErr.includes('CodeMismatchException')) {
              notification['error']({
                message: 'Error',
                description: newErr.split(': ')[1],
              })
              setLoading(false)
            } else if (err.message) {
              notification['error']({
                message: 'Error',
                description: err.message,
              })
              setLoading(false)
            } else {
              notification['error']({
                message: 'Error',
                description:
                  'Please contact administrator for further instruction.',
              })
              setLoading(false)
            }
          })
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function userNameFocus
   * @description Clears the username error state when the username input field gains focus.
   */
  const userNameFocus = () => {
    try {
      setUsernameError(null)
    } catch (err) {
      console.log(err)
    }
  }
  /**
   * @function passwordFocus
   * @description Clears the password error state when the password input field gains focus.
   */
  const passwordFocus = () => {
    try {
      setPasswordError(false)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Modal
        title="Why I didn't receive my 'Change Password' verification Code in my email?"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        destroyOnClose={true}
      >
        <p>
          <b>Possible reasons: </b>
        </p>
        <ul>
          <li>
            The verification Code email is sent to your "Junk" folder or "Spam"
            folder.{' '}
          </li>
          <li>
            There could be a delay in sending the email.
            <p>
              <em>Delay could be:</em>
            </p>
            <ul>
              <li>due to internet connection, or speed</li>
              <li>your internet provider</li>
              <li>your email provider</li>
              <li>your device speed or memory</li>
            </ul>
            <p></p>
            <p>
              <em>What to do:</em>
            </p>
            <ul>
              <li>please give it at least 2-3 minutes to receive the email</li>
              <li>
                occasionally you may need to manually <em>refresh</em> your
                browser to check for new emails in your inbox.
              </li>
              <li>
                if you didn't receive it after that time, resend <em>code</em>{' '}
                again
              </li>
              <li>
                when all else fails, please contact the <em> HR team </em>for a
                change of email request.
              </li>
            </ul>
          </li>
        </ul>
        <p></p>
        <p>
          <b>
            If you've tried the steps above and still haven't received your
            verification code for Change Password email, please contact our
            Support Team.
          </b>
        </p>
      </Modal>

      <div className='mainLogin-div'>
        <div className='mainHeader-login'>
          <div className='loginHead-title'>
            {/* <img
              src="https://quezoncity.gov.ph/wp-content/themes/qcproject/images/qclogo_main.png"
              alt="QCLogo"
            />
            <span>Quezon City</span> */}
          </div>
          <div className='loginHead-date'>{`${showDay}, ${showDate}`}</div>
        </div>
        <div className='loginCard-div'>
          <div className='loginCard-right'>
            <img
              src='https://quezoncity.gov.ph/wp-content/themes/qcproject/images/qclogo_main.png'
              alt='QCLogo'
            />
            <div></div>
            <span>
              Quezon City Human Resource Management Department (HRMD) Online
              Platform
            </span>
          </div>
          <form autoComplete='none' className='loginCard-left'>
            <div className='loginTitle'>CHANGE PASSWORD</div>
            {newPasswordBtn === false ? (
              <span>
                <label className='loginInput-label' htmlFor='uname'>
                  Username*
                </label>
                <Input
                  id='uname'
                  prefix={<MailTwoTone />}
                  placeholder='Enter your username'
                  onChange={(e) => setUsername(e.target.value.toLowerCase())}
                  onFocus={() => userNameFocus()} // Clear error on focus
                  className='loginInput'
                  type='email'
                  value={username}
                  required
                  autoFocus
                  maxLength={256}
                />
                {usernameError && (
                  <div style={{ color: 'red', fontSize: '12px' }}>
                    {usernameError}
                  </div>
                )}
              </span>
            ) : (
              ''
            )}

            {newPasswordBtn === true ? (
              <>
                <span>
                  <label
                    className='loginInput-label'
                    htmlFor='verificationCode'
                  >
                    Verification Code
                  </label>
                  <Input
                    id='verificationCode'
                    autoComplete='none'
                    prefix={<CodeTwoTone />}
                    type='text'
                    placeholder=' Your verification code'
                    name='verificationCode'
                    onChange={(e) => setVerificationCode(e.target.value)}
                    required
                    className='loginInput'
                  />
                </span>

                <span>
                  <label className='loginInput-label' htmlFor='newPassword'>
                    New Password
                  </label>
                  <Input.Password
                    id='newPassword'
                    name='newPassword'
                    autoComplete='new-password'
                    prefix={<LockTwoTone />}
                    placeholder=' Your new password'
                    onChange={(e) => setNewPassword(e.target.value)}
                    required
                    className='loginInput'
                    onFocus={() => passwordFocus()} // Clear error on focus
                    maxLength={16}
                  />
                  {passwordError &&
                    passwordError.map((err, index) => (
                      <div
                        key={index}
                        style={{ color: 'red', fontSize: '12px' }}
                      >
                        {err}
                      </div>
                    ))}
                </span>
              </>
            ) : (
              ''
            )}

            {newPasswordBtn === true ? (
              <span>
                <button
                  className='btns btnLogin'
                  disabled={loading}
                  onClick={handleNewPassword}
                >
                  Submit new password
                </button>
              </span>
            ) : (
              ''
            )}

            {newPasswordBtn === false ? (
              <span>
                <button className='btns btnLogin' onClick={handleCode}>
                  Send code to email
                </button>
              </span>
            ) : (
              ''
            )}

            <div className='regFoot'>
              <span>&nbsp;</span>
              <span className='regFoot' onClick={() => navigate('/login')}>
                Login instead
              </span>
            </div>

            <div className='regFoot'>
              <span>
                <em>
                  <b>I didn't receive my Verification Code.</b>
                </em>
              </span>{' '}
              <span onClick={() => showModalInstructions()}>
                Instructions here
              </span>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
