/**
 * Title: internLanding.js
 * Description: This is a file that contains the internship landing page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/Internship/internLanding.js
 **/

import './internLanding.css'
import './fonts.css'

import { Row, Grid } from 'antd'

const { useBreakpoint } = Grid

export default function InternLanding({ goto }) {
  const handleGoto = () => {
    goto('institutionalDev')
  }

  const handleRequestIntern = () => {
    goto('internRequest')
  }

  return (
    <div className={'flex-column intern-body'}>
      <div className={'internshipfrontpage main_intern'}>
        <div className='intern__item'>
          {/*======= section1 =======*/}
          <section className='section1__section1'>
            <div className='section1__wrapper1'>
              <div className='intern__item'>
                <div className='section1__flexCol'>
                  <h1 className='section1__hero_title'>
                    STUDENT INTERNSHIP
                    <br />
                    OPPORTUNITIES
                  </h1>
                  <h5 className='section1__highlights'>
                    This is some subtext to capture the attention of the
                    interested applicant. The top of the header should be longer
                    than the bottom header ( this is a max of 100 words)
                  </h5>
                  <div className='intern__item'>
                    <button
                      className={'section1__btn intern-btn'}
                      onClick={handleRequestIntern}
                    >
                      <span className='section1__btn__text'>
                        REQUEST FOR AN INTERN
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className='spacer'></div>
              <div className='intern__item1'>
                <img
                  className='section1__image1'
                  src='/intern/assets/16ef70f94230b027a5dcd9911c813fb6.png'
                  alt='alt text'
                />
              </div>
            </div>
          </section>
          {/*-- ======= End section1 =======*/}
        </div>
        <div className='intern__item'>
          {/*======= section2 =======*/}
          <section className='section2__section2'>
            <div className='section2__wrapper'>
              <div className='section2__flexRow'>
                <div className='intern__item'>
                  {/*<div className="zindex">      */}
                  <Row
                    className={'intern__kalibrr'}
                    style={{ height: '100%' }}
                    justify='center'
                  >
                    <iframe
                      className={'section2__image'}
                      src='https://drive.google.com/file/d/1WoKzHeSogNZ7PtGFUt42qELErfTy_aEY/preview'
                      allow='autoplay'
                    ></iframe>
                  </Row>
                  {/*<Row justify="center" className="kalibrr">
                      <h5 style={{fontSize: myFont}} >Visit Quezon City Government's <a target="_blank" href="https://www.kalibrr.com/c/quezon-city-local-government/jobs">Kalibrr</a> Page</h5>
                    </Row>*/}
                  {/*</div>*/}
                </div>
                <div className='spacer'></div>
                <div className='intern__item1'>
                  <div className='section2__flexCol'>
                    <h1 className='section2__hero_title'>WHY JOIN US</h1>
                    <h5 className='section2__highlights'>
                      This is some subtext to capture the attention of the
                      interested applicant. The top of the header should be
                      longer than the bottom header ( this is a max of 250
                      words). <br />
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor in reprehenderit in voluptate velit
                      esse cillum dolore eu fugiat nulla pariatur. Excepteur
                      sint occaecat cupidatat non proident, sunt in culpa qui
                      officia deserunt mollit anim id est laborum.
                      <br />
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*======= End section2 =======*/}
        </div>
        <div className='intern__item'>
          {/*======= section3 =======*/}
          <section className='section3__section3'>
            <div className='section3__wrapper3'>
              <div className='section3__flexCol'>
                <h1 className='section3__hero_title'>
                  HOW IS YOUR INTERN DOING
                </h1>
                <div className='section3__flexCol1'>
                  <div className='section3__flexRow'>
                    <div className='intern__item'>
                      <div className='section3__content_box'>
                        <h2 className='section3__medium_title'>
                          SECURITY, PEACE, AND <br />
                          DISASTER PREDAREDNESS
                        </h2>
                      </div>
                    </div>
                    <div className='spacer'></div>
                    <div className='intern__item'>
                      <div className='section3__content_box1'>
                        <h2 className='section3__medium_title1'>
                          ECONOMIC DEVELOPMENT
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className='section3__flexRow1'>
                    <div className='intern__item'>
                      <div className='section3__content_box'>
                        <h2 className='section3__medium_title2'>
                          ENVIRONMENT
                          <br />
                          AND CLIMATE CHANGE
                        </h2>
                      </div>
                    </div>
                    <div className='spacer'></div>
                    <div className='intern__item'>
                      <div className='section3__content_box1'>
                        <h2 className='section3__medium_title3'>
                          INFRASTRUCTURE, URBAN <br />
                          PLANNING, AND DEVELOPMENT
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className='section3__flexRow1'>
                    <div className='intern__item1'>
                      <button
                        className='section3__content_box'
                        onClick={handleGoto}
                      >
                        <h2 className='section3__medium_title4'>
                          INSTITUTIONAL
                          <br />
                          DEVELOPMENT
                        </h2>
                      </button>
                    </div>
                    <div className='spacer1'></div>
                    <div className='intern__item1'>
                      <div className='section3__content_box1'>
                        <h2 className='section3__medium_title5'>
                          SPECIAL BODIES <br />
                          TASK FORCE
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className='section3__flexRow1'>
                    <div className='intern__item2'>
                      <div className='section3__content_box'>
                        <h2 className='section3__medium_title6'>
                          POVERTY REDUCTION AND
                          <br />
                          SOCIAL DEVELOPMENT
                        </h2>
                      </div>
                    </div>
                    <div className='spacer2'></div>
                    <div className='intern__item2'>
                      <div className='section3__content_box1'>
                        <h2 className='section3__medium_title7'>
                          ALL DEPARTMENTS, OFFICES,
                          <br />
                          TASK FORCES, AND UNITS
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*======= End section3 =======*/}
        </div>
        <div className='intern__item'>
          {/*======= section4 =======*/}
          <section className='section4__section4'>
            <div className='section4__flexCol'>
              <h1 className='section4__hero_title'>TESTIMONIES</h1>
              <div className='section4__flexCol1'>
                <div className='intern__item'>
                  <div className='section4__flexRow'>
                    <div className='intern__item'>
                      <div className='section4__flexCol2'>
                        <div className='intern__item'>
                          <img
                            className='section4__image3'
                            src='/intern/assets/0e7e5f2bf09fe79408673affbdc8b16f.png'
                            alt='alt text'
                          />
                        </div>
                        <h1 className='section4__title'>Agatha Christine</h1>
                        <h3 className='section4__subtitle'>
                          Quezon City University
                        </h3>
                        <div className='intern__item'>
                          <img
                            className='section4__image2'
                            src='/intern/assets/8953630d54f9c49f140022f09cf5b7ab.png'
                            alt='alt text'
                          />
                        </div>
                        <h5 className='section4__highlights'>
                          nulla at volutpat diam ut venenatis tellus in metus
                          vulputate eu scelerisque felis imperdiet proin
                          fermentum leo vel orci porta non pulvinar neque
                          laoreet suspendisse interdum consectetur libero id
                          faucibus nisl tincidunt eget nullam non nisi est sit
                          amet facilisis magna etiam tempor orci eu lobortis
                          elementum nibh tellus molestie
                          <br />
                          <br />
                        </h5>
                      </div>
                    </div>
                    <div className='spacer'></div>
                    <div className='intern__item'>
                      <div className='section4__flexCol2'>
                        <div className='intern__item1'>
                          <img
                            className='section4__image31'
                            src='/intern/assets/b3d38eb6aa56ee7f448c92ef9de89041.png'
                            alt='alt text'
                          />
                        </div>
                        <h1 className='section4__title1'>Agatha Christine</h1>
                        <h3 className='section4__subtitle1'>
                          Quezon City University
                        </h3>
                        <h5 className='section4__highlights1'>
                          nulla at volutpat diam ut venenatis tellus in metus
                          vulputate eu scelerisque felis imperdiet proin
                          fermentum leo vel orci porta non pulvinar neque
                          laoreet suspendisse interdum consectetur libero id
                          faucibus nisl tincidunt eget nullam non nisi est sit
                          amet facilisis magna etiam tempor orci eu lobortis
                          elementum nibh tellus molestie
                          <br />
                          <br />
                        </h5>
                      </div>
                    </div>
                    <div className='spacer1'></div>
                    <div className='intern__item'>
                      <div className='section4__flexCol2'>
                        <div className='intern__item2'>
                          <img
                            className='section4__image31'
                            src='/intern/assets/0e7e5f2bf09fe79408673affbdc8b16f.png'
                            alt='alt text'
                          />
                        </div>
                        <h1 className='section4__title2'>Agatha Christine</h1>
                        <h3 className='section4__subtitle2'>
                          Quezon City University
                        </h3>
                        <h5 className='section4__highlights1'>
                          nulla at volutpat diam ut venenatis tellus in metus
                          vulputate eu scelerisque felis imperdiet proin
                          fermentum leo vel orci porta non pulvinar neque
                          laoreet suspendisse interdum consectetur libero id
                          faucibus nisl tincidunt eget nullam non nisi est sit
                          amet facilisis magna etiam tempor orci eu lobortis
                          elementum nibh tellus molestie
                          <br />
                          <br />
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='intern__item'>
                  <div className='section4__flexRow1'>
                    <div className='intern__item'>
                      <img
                        className='section4__icon'
                        src='/intern/assets/4f2dfdac914e61cc2f330b967436a77c.png'
                        alt='alt text'
                      />
                    </div>
                    <div className='spacer'></div>
                    <div className='intern__item'>
                      <img
                        className='section4__icon'
                        src='/intern/assets/4f2dfdac914e61cc2f330b967436a77c.png'
                        alt='alt text'
                      />
                    </div>
                    <div className='spacer1'></div>
                    <div className='intern__item'>
                      <img
                        className='section4__icon'
                        src='/intern/assets/4f2dfdac914e61cc2f330b967436a77c.png'
                        alt='alt text'
                      />
                    </div>
                  </div>
                </div>
                <div className='intern__item1'>
                  <button
                    className={'section4__btn intern-btn'}
                    onClick={() => alert(`It is clickable`)}
                  >
                    <span className='section4__btn__text'>
                      READ MORE TESTIMONIES
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </section>
          {/*======= End section4 =======*/}
        </div>
      </div>
    </div>
  )
}
