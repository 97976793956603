/**
 * Title: evaluationMakerPreview.js
 * Description: This is a file that contains the evaluation maker preview component
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/evaluationMakerPreview.js
 **/

import React, { useState } from 'react'
import { notification, Button, Modal, Row, Col, Input } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

import { API, graphqlOperation } from 'aws-amplify'
import { createEvaluationQuestions } from '../../api/mutations'

const { TextArea } = Input

export default function EvaluationMakerPreview({
  questionsArray,
  setGetEvalID,
  closeMotherModal,
  refresh,
  regen,
  modalPreview,
  setModalPreview,
  userData,
}) {
  const handleOpenModal = () => {
    regen()
  }

  const handleCancel = () => {
    setModalPreview(false)
  }

  function toRoman(num) {
    const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
    const roman = [
      'M',
      'CM',
      'D',
      'CD',
      'C',
      'XC',
      'L',
      'XL',
      'X',
      'IX',
      'V',
      'IV',
      'I',
    ]

    let result = ''

    for (let i = 0; i < decimal.length; i++) {
      while (decimal[i] <= num) {
        result += roman[i]
        num -= decimal[i]
      }
    }

    return result
  }

  const showOutput = questionsArray.map((elem, index) => {
    return (
      <React.Fragment key={index}>
        <span className={'fw-bold'}>
          {toRoman(index + 1)}. {elem.question}
        </span>
        {elem.categories.length > 0 ? (
          <>
            {elem.description && (
              <Row>
                <span className={'text-muted py-2'}>{elem.description}</span>
              </Row>
            )}
            <Row className={'mb-3'}>
              <Col style={{ width: 550 }}>
                <Row>
                  <Col span={9}></Col>
                  <Col span={15} className='d-flex justify-content-around'>
                    <span className='fw-bold'>1</span>
                    <span className='fw-bold'>2</span>
                    <span className='fw-bold'>3</span>
                    <span className='fw-bold'>4</span>
                    <span className='fw-bold'>5</span>
                  </Col>
                  <Col span={24}>
                    {/* <Row style={{ height: "22px" }}></Row> */}
                    {elem.categories.map((category, ci) => {
                      return (
                        <div className='d-flex align-items-center mt-1'>
                          <Col span={9} key={ci}>
                            {category[`category-${ci + 1}-${index + 1}`]}{' '}
                            <em className='asterisk'>**</em>
                          </Col>
                          <Col
                            span={15}
                            className='d-flex justify-content-around'
                          >
                            <Row style={{ height: '22px' }}>
                              <input
                                value='1'
                                name='setAQOne'
                                className={'postAssesmentQ'}
                                type='radio'
                              />
                            </Row>
                            <Row style={{ height: '22px' }}>
                              <input
                                value='2'
                                name='setAQOne'
                                className={'postAssesmentQ'}
                                type='radio'
                              />
                            </Row>
                            <Row style={{ height: '22px' }}>
                              <input
                                value='2'
                                name='setAQOne'
                                className={'postAssesmentQ'}
                                type='radio'
                              />
                            </Row>
                            <Row style={{ height: '22px' }}>
                              <input
                                value='2'
                                name='setAQOne'
                                className={'postAssesmentQ'}
                                type='radio'
                              />
                            </Row>
                            <Row style={{ height: '22px' }}>
                              <input
                                value='2'
                                name='setAQOne'
                                className={'postAssesmentQ'}
                                type='radio'
                              />
                            </Row>
                          </Col>
                        </div>
                      )
                    })}
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        ) : (
          <div style={{ width: '550px' }}>
            <Row className={'mb-3 mt-3'}>
              <Col span={24}>
                <TextArea
                  /* onChange={(e) =>
                    handleTextareaChange(e, elem.question, index)
                  } */
                  id={`additionalComments${index}`}
                  style={{ fontSize: 16 }}
                  rows={5}
                />
              </Col>
            </Row>
          </div>
        )}
      </React.Fragment>
    )
  })

  const handleConfirm = async () => {
    try {
      const createEvaluation = await API.graphql(
        graphqlOperation(createEvaluationQuestions, {
          input: {
            eval: JSON.stringify(questionsArray),           
          },
        })
      )

      if (createEvaluation) {
        notification['success']({
          message: 'Success',
          description: 'Evaluation Questionnaire was saved.',
        })

        setGetEvalID(createEvaluation.data.createEvaluationQuestions.id)

        setTimeout(() => {
          setModalPreview(false)
          closeMotherModal(false)
        }, 1500)
      }
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description:
          'Evaluation Questionnaire was not saved. Contact System Admin.',
      })
    }
  }

  const handleCancelSave = () => {
    setModalPreview(false)
    refresh()
  }

  return (
    <>
      <Button ghost type='primary' onClick={handleOpenModal}>
        Save
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={
          <span>
            <em>
              <b>Please review questionnaire before saving</b>
            </em>
          </span>
        }
        visible={modalPreview}
        footer={null}
        onCancel={handleCancel}
        width={800}
      >
        <Row style={{ fontSize: 16 }} className='justify-content-center'>
          <Col>{showOutput}</Col>
        </Row>
        <Row className='mt-4' justify='end'>
          <div>
            <Button className='me-4' onClick={handleCancelSave} type='danger'>
              Cancel
            </Button>
            <Button onClick={handleConfirm} type='primary'>
              Confirm
            </Button>
          </div>
        </Row>
      </Modal>
    </>
  )
}
