/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getForm = /* GraphQL */ `
  query GetForm($id: ID!) {
    getForm(id: $id) {
      id
      form
      dept
      assignedDept
      division
      position
      isLanguage
      userID
      isFinal
      finalSubmitDate
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listForms = /* GraphQL */ `
  query ListForms(
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        form
        dept
        assignedDept
        division
        position
        isLanguage
        userID
        isFinal
        finalSubmitDate
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getForm2 = /* GraphQL */ `
  query GetForm2($id: ID!) {
    getForm2(id: $id) {
      id
      form
      dept
      assignedDept
      division
      position
      userID
      etnaId
      filipino
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listForm2s = /* GraphQL */ `
  query ListForm2s(
    $filter: ModelForm2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    listForm2s(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        form
        dept
        assignedDept
        division
        position
        userID
        etnaId
        filipino
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getIDLPUpload = /* GraphQL */ `
  query GetIDLPUpload($id: ID!) {
    getIDLPUpload(id: $id) {
      id
      file
      date
      userID
      etnaId
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listIDLPUploads = /* GraphQL */ `
  query ListIDLPUploads(
    $filter: ModelIDLPUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIDLPUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        file
        date
        userID
        etnaId
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getCscVideos = /* GraphQL */ `
  query GetCscVideos($id: ID!) {
    getCscVideos(id: $id) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listCscVideos = /* GraphQL */ `
  query ListCscVideos(
    $filter: ModelcscVideosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCscVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employeeName
        date
        department
        videoName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getCscMaterials = /* GraphQL */ `
  query GetCscMaterials($id: ID!) {
    getCscMaterials(id: $id) {
      id
      employeeName
      date
      department
      materials
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listCscMaterials = /* GraphQL */ `
  query ListCscMaterials(
    $filter: ModelcscMaterialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCscMaterials(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employeeName
        date
        department
        materials
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getVariousMaterials = /* GraphQL */ `
  query GetVariousMaterials($id: ID!) {
    getVariousMaterials(id: $id) {
      id
      employeeName
      date
      department
      materialName
      additionalDetails
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listVariousMaterials = /* GraphQL */ `
  query ListVariousMaterials(
    $filter: ModelvariousMaterialsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVariousMaterials(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeName
        date
        department
        materialName
        additionalDetails
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      userID
      surveyScore
      surveyRecommendation
      dept
      assignedDept
      division
      position
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        surveyScore
        surveyRecommendation
        dept
        assignedDept
        division
        position
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSpmsOPCRPartOne = /* GraphQL */ `
  query GetSpmsOPCRPartOne($id: ID!) {
    getSpmsOPCRPartOne(id: $id) {
      id
      data
      userID
      isFinal
      department
      isApprovedByApprover
      approverID
      commentsOfApprover
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listSpmsOPCRPartOnes = /* GraphQL */ `
  query ListSpmsOPCRPartOnes(
    $filter: ModelspmsOPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpmsOPCRPartOnes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        isApprovedByApprover
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSpmsOPCREvaluation = /* GraphQL */ `
  query GetSpmsOPCREvaluation($id: ID!) {
    getSpmsOPCREvaluation(id: $id) {
      id
      data
      employeeID
      department
      approverID
      formOPCRID
      isForApproverReview
      isFinal
      formulationOPCR {
        id
        data
        userID
        isFinal
        department
        isApprovedByApprover
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listSpmsOPCREvaluations = /* GraphQL */ `
  query ListSpmsOPCREvaluations(
    $filter: ModelspmsOPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpmsOPCREvaluations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        employeeID
        department
        approverID
        formOPCRID
        isForApproverReview
        isFinal
        formulationOPCR {
          id
          data
          userID
          isFinal
          department
          isApprovedByApprover
          approverID
          commentsOfApprover
          createdAt
          updatedAt
          approver {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSpmsDPCREvaluation = /* GraphQL */ `
  query GetSpmsDPCREvaluation($id: ID!) {
    getSpmsDPCREvaluation(id: $id) {
      id
      data
      employeeID
      department
      division
      approverID
      formDPCRID
      isForApproverReview
      isFinal
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      formulationDPCR {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const listSpmsDPCREvaluations = /* GraphQL */ `
  query ListSpmsDPCREvaluations(
    $filter: ModelspmsDPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpmsDPCREvaluations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        employeeID
        department
        division
        approverID
        formDPCRID
        isForApproverReview
        isFinal
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        formulationDPCR {
          id
          data
          userID
          isFinal
          department
          division
          isApprovedBySupervisor
          approverID
          commentsOfApprover
          createdAt
          updatedAt
          approver {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSpmsIPCREvaluation = /* GraphQL */ `
  query GetSpmsIPCREvaluation($id: ID!) {
    getSpmsIPCREvaluation(id: $id) {
      id
      data
      isForSupervisorReview
      isFinal
      employeeID
      department
      division
      supervisorID
      formIPCRID
      createdAt
      updatedAt
      supervisor {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      formulationIPCR {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        supervisorID
        commentsOfSupervisor
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const listSpmsIPCREvaluations = /* GraphQL */ `
  query ListSpmsIPCREvaluations(
    $filter: ModelspmsIPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpmsIPCREvaluations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        isForSupervisorReview
        isFinal
        employeeID
        department
        division
        supervisorID
        formIPCRID
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        formulationIPCR {
          id
          data
          userID
          isFinal
          department
          division
          isApprovedBySupervisor
          supervisorID
          commentsOfSupervisor
          createdAt
          updatedAt
          supervisor {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getTrainingVideo = /* GraphQL */ `
  query GetTrainingVideo($id: ID!) {
    getTrainingVideo(id: $id) {
      id
      key
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listTrainingVideos = /* GraphQL */ `
  query ListTrainingVideos(
    $filter: ModeltrainingVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainingVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        key
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getIPCRUpload = /* GraphQL */ `
  query GetIPCRUpload($id: ID!) {
    getIPCRUpload(id: $id) {
      id
      file
      department
      division
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listIPCRUploads = /* GraphQL */ `
  query ListIPCRUploads(
    $filter: ModelIPCRUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listIPCRUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        file
        department
        division
        date
        userID
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getDPCRUpload = /* GraphQL */ `
  query GetDPCRUpload($id: ID!) {
    getDPCRUpload(id: $id) {
      id
      file
      department
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listDPCRUploads = /* GraphQL */ `
  query ListDPCRUploads(
    $filter: ModelDPCRUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDPCRUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        file
        department
        date
        userID
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSecretKeys = /* GraphQL */ `
  query GetSecretKeys($id: ID!) {
    getSecretKeys(id: $id) {
      id
      secret
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listSecretKeys = /* GraphQL */ `
  query ListSecretKeys(
    $filter: ModelsecretKeysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSecretKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        secret
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getOPCRUpload = /* GraphQL */ `
  query GetOPCRUpload($id: ID!) {
    getOPCRUpload(id: $id) {
      id
      file
      department
      date
      userID
      isApproved
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listOPCRUploads = /* GraphQL */ `
  query ListOPCRUploads(
    $filter: ModelOPCRUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOPCRUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        file
        department
        date
        userID
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getActivityOneAnswer = /* GraphQL */ `
  query GetActivityOneAnswer($id: ID!) {
    getActivityOneAnswer(id: $id) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const listActivityOneAnswers = /* GraphQL */ `
  query ListActivityOneAnswers(
    $filter: ModelactivityOneAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityOneAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        answer
        trainingAnnouncementID
        createdAt
        timeSubmitted
        isSubmittedByUser
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getActivityTwoAnswer = /* GraphQL */ `
  query GetActivityTwoAnswer($id: ID!) {
    getActivityTwoAnswer(id: $id) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const listActivityTwoAnswers = /* GraphQL */ `
  query ListActivityTwoAnswers(
    $filter: ModelactivityTwoAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityTwoAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        answer
        trainingAnnouncementID
        createdAt
        timeSubmitted
        isSubmittedByUser
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getActivityThreeAnswer = /* GraphQL */ `
  query GetActivityThreeAnswer($id: ID!) {
    getActivityThreeAnswer(id: $id) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const listActivityThreeAnswers = /* GraphQL */ `
  query ListActivityThreeAnswers(
    $filter: ModelactivityThreeAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityThreeAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        answer
        trainingAnnouncementID
        createdAt
        timeSubmitted
        isSubmittedByUser
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getActivityFourAnswer = /* GraphQL */ `
  query GetActivityFourAnswer($id: ID!) {
    getActivityFourAnswer(id: $id) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const listActivityFourAnswers = /* GraphQL */ `
  query ListActivityFourAnswers(
    $filter: ModelactivityFourAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityFourAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        answer
        trainingAnnouncementID
        createdAt
        timeSubmitted
        isSubmittedByUser
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getActivityFiveAnswer = /* GraphQL */ `
  query GetActivityFiveAnswer($id: ID!) {
    getActivityFiveAnswer(id: $id) {
      id
      userID
      date
      answer
      trainingAnnouncementID
      createdAt
      timeSubmitted
      isSubmittedByUser
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const listActivityFiveAnswers = /* GraphQL */ `
  query ListActivityFiveAnswers(
    $filter: ModelactivityFiveAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActivityFiveAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        answer
        trainingAnnouncementID
        createdAt
        timeSubmitted
        isSubmittedByUser
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBSubmission = /* GraphQL */ `
  query GetPSBSubmission($id: ID!) {
    getPSBSubmission(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      isPlantilla
      psbDeliberationID
      status
      screeningRemarks
      expectedDeliberationDate
      actualDeliberationDate
      dateDeptApproved
      dateHRApproved
      positionAppliedFor
      psbWorkExperienceID
      eligibility
      performance
      education
      training
      experience
      hrRemarks
      psbTrainingExperienceID
      psbTranscriptOrDiplomaID
      psbCertificationID
      psbIndividualPerformanceID
      psbDrugTestID
      psbClearanceID
      psbAffidavitID
      psbJustificationLetterID
      psbLoneApplicantID
      psbAppraisalReportID
      psbMedicalCertificateID
      psbMarriageCertificateID
      psbBirthCertificateID
      psbEvaluationReportID
      isForSubmission
      assignedItemNumber
      createdAt
      updatedAt
      owner
      psbWorkExperience {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbTrainingExperience {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbTranscriptOrDiploma {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbCertification {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbIndividualPerformance {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbDrugTest {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbClearance {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbAffidavit {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbJustificationLetter {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbLoneApplicant {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbAppraisalReport {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbMedicalCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbMarriageCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbBirthCertificate {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      psbEvaluationReport {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const listPSBSubmissions = /* GraphQL */ `
  query ListPSBSubmissions(
    $filter: ModelPSBSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBSubmissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        isPlantilla
        psbDeliberationID
        status
        screeningRemarks
        expectedDeliberationDate
        actualDeliberationDate
        dateDeptApproved
        dateHRApproved
        positionAppliedFor
        psbWorkExperienceID
        eligibility
        performance
        education
        training
        experience
        hrRemarks
        psbTrainingExperienceID
        psbTranscriptOrDiplomaID
        psbCertificationID
        psbIndividualPerformanceID
        psbDrugTestID
        psbClearanceID
        psbAffidavitID
        psbJustificationLetterID
        psbLoneApplicantID
        psbAppraisalReportID
        psbMedicalCertificateID
        psbMarriageCertificateID
        psbBirthCertificateID
        psbEvaluationReportID
        isForSubmission
        assignedItemNumber
        createdAt
        updatedAt
        owner
        psbWorkExperience {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbTrainingExperience {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbTranscriptOrDiploma {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbCertification {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbIndividualPerformance {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbDrugTest {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbClearance {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbAffidavit {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbJustificationLetter {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbLoneApplicant {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbAppraisalReport {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbMedicalCertificate {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbMarriageCertificate {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbBirthCertificate {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbEvaluationReport {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBDeliberationActivity = /* GraphQL */ `
  query GetPSBDeliberationActivity($id: ID!) {
    getPSBDeliberationActivity(id: $id) {
      id
      userID
      sequenceNumber
      date
      startDate
      cutoffDate
      isCompleted
      createdAt
      notes
      updatedAt
      user {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBDeliberationActivities = /* GraphQL */ `
  query ListPSBDeliberationActivities(
    $filter: ModelPSBDeliberationActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBDeliberationActivities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        sequenceNumber
        date
        startDate
        cutoffDate
        isCompleted
        createdAt
        notes
        updatedAt
        user {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getAttendanceRecord = /* GraphQL */ `
  query GetAttendanceRecord($id: ID!) {
    getAttendanceRecord(id: $id) {
      id
      dateTimeEntry
      tags
      username
      employeeUserID
      firstName
      middleName
      lastName
      createdAt
      isApproved
      isAccepted
      isOTApproved
      location
      updatedAt
      employee {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listAttendanceRecords = /* GraphQL */ `
  query ListAttendanceRecords(
    $filter: ModelAttendanceRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendanceRecords(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateTimeEntry
        tags
        username
        employeeUserID
        firstName
        middleName
        lastName
        createdAt
        isApproved
        isAccepted
        isOTApproved
        location
        updatedAt
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getAttendanceRecordAuditTrail = /* GraphQL */ `
  query GetAttendanceRecordAuditTrail($id: ID!) {
    getAttendanceRecordAuditTrail(id: $id) {
      id
      attendanceID
      attendance {
        id
        dateTimeEntry
        tags
        username
        employeeUserID
        firstName
        middleName
        lastName
        createdAt
        isApproved
        isAccepted
        isOTApproved
        location
        updatedAt
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      attendanceFrom
      editedBy
      editedByID
      createdBy
      createdByID
      dateTimeEdite
      createdAt
      editDetails
      updatedAt
      editedByUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      createdByUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listAttendanceRecordAuditTrails = /* GraphQL */ `
  query ListAttendanceRecordAuditTrails(
    $filter: ModelAttendanceRecordAuditTrailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAttendanceRecordAuditTrails(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attendanceID
        attendance {
          id
          dateTimeEntry
          tags
          username
          employeeUserID
          firstName
          middleName
          lastName
          createdAt
          isApproved
          isAccepted
          isOTApproved
          location
          updatedAt
          employee {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        attendanceFrom
        editedBy
        editedByID
        createdBy
        createdByID
        dateTimeEdite
        createdAt
        editDetails
        updatedAt
        editedByUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        createdByUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPMISEmployeeOld = /* GraphQL */ `
  query GetPMISEmployeeOld($id: ID!) {
    getPMISEmployeeOld(id: $id) {
      id
      employeeNo
      surname
      givenName
      middleName
      firstDayInAgency
      firstDayInGov
      workDate
      employmentStatus
      statusParticular
      effectiveDateEmployeeStatus
      lastDayInAgency
      lastUpdateByCOEmploymentStatus
      nickname
      sex
      civilStatus
      effectiveDatePersonnelInfo
      maidenName
      spouseName
      spouseOccupation
      dateOfBirth
      birthplace
      bloodType
      nationality
      religion
      height
      weight
      fatherName
      fatherBirthplace
      motherName
      motherBirthplace
      employeeHomeAddress
      employeeDependent
      employeeEducationalAttainment
      employeeEligibility
      gsisPolicyNo
      gsisBranch
      pagibigNo
      pagibigBranch
      tinNo
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listPMISEmployeeOlds = /* GraphQL */ `
  query ListPMISEmployeeOlds(
    $filter: ModelPMISEmployeeOldFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPMISEmployeeOlds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeNo
        surname
        givenName
        middleName
        firstDayInAgency
        firstDayInGov
        workDate
        employmentStatus
        statusParticular
        effectiveDateEmployeeStatus
        lastDayInAgency
        lastUpdateByCOEmploymentStatus
        nickname
        sex
        civilStatus
        effectiveDatePersonnelInfo
        maidenName
        spouseName
        spouseOccupation
        dateOfBirth
        birthplace
        bloodType
        nationality
        religion
        height
        weight
        fatherName
        fatherBirthplace
        motherName
        motherBirthplace
        employeeHomeAddress
        employeeDependent
        employeeEducationalAttainment
        employeeEligibility
        gsisPolicyNo
        gsisBranch
        pagibigNo
        pagibigBranch
        tinNo
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPMISEmployeeOldAddress = /* GraphQL */ `
  query GetPMISEmployeeOldAddress($id: ID!) {
    getPMISEmployeeOldAddress(id: $id) {
      id
      createdAt
      homeAddressNo
      homeAddressStreet
      homeAddressBarangay
      homeAddressMunicipalCity
      homeAddressProvince
      telephoneNumber
      status
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const listPMISEmployeeOldAddresses = /* GraphQL */ `
  query ListPMISEmployeeOldAddresses(
    $filter: ModelPMISEmployeeOldAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPMISEmployeeOldAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        homeAddressNo
        homeAddressStreet
        homeAddressBarangay
        homeAddressMunicipalCity
        homeAddressProvince
        telephoneNumber
        status
        lastUpdateByCO
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPMISEmployeeOldDependent = /* GraphQL */ `
  query GetPMISEmployeeOldDependent($id: ID!) {
    getPMISEmployeeOldDependent(id: $id) {
      id
      createdAt
      firstname
      middlename
      lastname
      dateOfBirth
      relation
      isIncapacitated
      isQualifiedForTaxExemption
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const listPMISEmployeeOldDependents = /* GraphQL */ `
  query ListPMISEmployeeOldDependents(
    $filter: ModelPMISEmployeeOldDependentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPMISEmployeeOldDependents(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        firstname
        middlename
        lastname
        dateOfBirth
        relation
        isIncapacitated
        isQualifiedForTaxExemption
        lastUpdateByCO
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPMISEmployeeOldEducationalAttainment = /* GraphQL */ `
  query GetPMISEmployeeOldEducationalAttainment($id: ID!) {
    getPMISEmployeeOldEducationalAttainment(id: $id) {
      id
      createdAt
      educationLevel
      degreeCourse
      isGraduate
      lastAttendedYear
      unitsEarned
      gradeOrYearLevel
      honor
      nameOfSchool
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const listPMISEmployeeOldEducationalAttainments = /* GraphQL */ `
  query ListPMISEmployeeOldEducationalAttainments(
    $filter: ModelPMISEmployeeOldEducationalAttainmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPMISEmployeeOldEducationalAttainments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        educationLevel
        degreeCourse
        isGraduate
        lastAttendedYear
        unitsEarned
        gradeOrYearLevel
        honor
        nameOfSchool
        lastUpdateByCO
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPMISEmployeeOldEligibility = /* GraphQL */ `
  query GetPMISEmployeeOldEligibility($id: ID!) {
    getPMISEmployeeOldEligibility(id: $id) {
      id
      createdAt
      barBoardGovtExam
      dateOfExam
      rating
      rank
      placeOfExam
      licenseNumber
      lastUpdateByCO
      updatedAt
      owner
      __typename
    }
  }
`
export const listPMISEmployeeOldEligibilities = /* GraphQL */ `
  query ListPMISEmployeeOldEligibilities(
    $filter: ModelPMISEmployeeOldEligibilityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPMISEmployeeOldEligibilities(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        barBoardGovtExam
        dateOfExam
        rating
        rank
        placeOfExam
        licenseNumber
        lastUpdateByCO
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getDepartment = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      name
      image
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listDepartments = /* GraphQL */ `
  query ListDepartments(
    $filter: ModelDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDepartments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getDivision = /* GraphQL */ `
  query GetDivision($id: ID!) {
    getDivision(id: $id) {
      id
      name
      departmentID
      department {
        id
        name
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listDivisions = /* GraphQL */ `
  query ListDivisions(
    $filter: ModelDivisionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDivisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        departmentID
        department {
          id
          name
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSectionUnit = /* GraphQL */ `
  query GetSectionUnit($id: ID!) {
    getSectionUnit(id: $id) {
      id
      name
      divisionID
      division {
        id
        name
        departmentID
        department {
          id
          name
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listSectionUnits = /* GraphQL */ `
  query ListSectionUnits(
    $filter: ModelSectionUnitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSectionUnits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        divisionID
        division {
          id
          name
          departmentID
          department {
            id
            name
            image
            createdAt
            updatedAt
            owner
            __typename
          }
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPositionList = /* GraphQL */ `
  query GetPositionList($id: ID!) {
    getPositionList(id: $id) {
      id
      poscode
      positionTitle
      posacro
      posgrade
      itemCode
      educationReqs
      trainingReqs
      experienceReqs
      eligibilityReqs
      salaryGrade
      monthlySalary
      createdAt
      additionalDetails
      updatedAt
      owner
      __typename
    }
  }
`
export const listPositionLists = /* GraphQL */ `
  query ListPositionLists(
    $filter: ModelpositionListFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositionLists(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        poscode
        positionTitle
        posacro
        posgrade
        itemCode
        educationReqs
        trainingReqs
        experienceReqs
        eligibilityReqs
        salaryGrade
        monthlySalary
        createdAt
        additionalDetails
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getGradeStep = /* GraphQL */ `
  query GetGradeStep($id: ID!) {
    getGradeStep(id: $id) {
      id
      grade
      step
      salary
      yearVersion
      documentUpload
      isEnabled
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listGradeSteps = /* GraphQL */ `
  query ListGradeSteps(
    $filter: ModelGradeStepFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGradeSteps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        grade
        step
        salary
        yearVersion
        documentUpload
        isEnabled
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPositionDepartment = /* GraphQL */ `
  query GetPositionDepartment($id: ID!) {
    getPositionDepartment(id: $id) {
      id
      departmentID
      department {
        id
        name
        image
        createdAt
        updatedAt
        owner
        __typename
      }
      positions
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listPositionDepartments = /* GraphQL */ `
  query ListPositionDepartments(
    $filter: ModelPositionDepartmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPositionDepartments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        departmentID
        department {
          id
          name
          image
          createdAt
          updatedAt
          owner
          __typename
        }
        positions
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getShift = /* GraphQL */ `
  query GetShift($id: ID!) {
    getShift(id: $id) {
      id
      shiftType
      startTime
      endTime
      breakDuration
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listShifts = /* GraphQL */ `
  query ListShifts(
    $filter: ModelShiftFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShifts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        shiftType
        startTime
        endTime
        breakDuration
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSchedule = /* GraphQL */ `
  query GetSchedule($id: ID!) {
    getSchedule(id: $id) {
      id
      employeeID
      shiftID
      scheduleDates
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listSchedules = /* GraphQL */ `
  query ListSchedules(
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSchedules(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employeeID
        shiftID
        scheduleDates
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getHoliday = /* GraphQL */ `
  query GetHoliday($id: ID!) {
    getHoliday(id: $id) {
      id
      label
      date
      category
      isWorkDay
      hasWork
      type
      subType
      state
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listHolidays = /* GraphQL */ `
  query ListHolidays(
    $filter: ModelHolidayFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHolidays(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        label
        date
        category
        isWorkDay
        hasWork
        type
        subType
        state
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const FormByUserId = /* GraphQL */ `
  query FormByUserId(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    FormByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        form
        dept
        assignedDept
        division
        position
        isLanguage
        userID
        isFinal
        finalSubmitDate
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const FormByUserDept = /* GraphQL */ `
  query FormByUserDept(
    $assignedDept: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFormFilterInput
    $limit: Int
    $nextToken: String
  ) {
    FormByUserDept(
      assignedDept: $assignedDept
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        form
        dept
        assignedDept
        division
        position
        isLanguage
        userID
        isFinal
        finalSubmitDate
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const Form2ByUserId = /* GraphQL */ `
  query Form2ByUserId(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelForm2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    Form2ByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        form
        dept
        assignedDept
        division
        position
        userID
        etnaId
        filipino
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const Form2ByUserDept = /* GraphQL */ `
  query Form2ByUserDept(
    $assignedDept: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelForm2FilterInput
    $limit: Int
    $nextToken: String
  ) {
    Form2ByUserDept(
      assignedDept: $assignedDept
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        form
        dept
        assignedDept
        division
        position
        userID
        etnaId
        filipino
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const IDLPUploadByUserId = /* GraphQL */ `
  query IDLPUploadByUserId(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIDLPUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    IDLPUploadByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        file
        date
        userID
        etnaId
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const surveysByUserId = /* GraphQL */ `
  query SurveysByUserId(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveysByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        surveyScore
        surveyRecommendation
        dept
        assignedDept
        division
        position
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsOPCRPartOneByUserID = /* GraphQL */ `
  query SpmsOPCRPartOneByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsOPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsOPCRPartOneByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        isApprovedByApprover
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsOPCRPartOneByDeptName = /* GraphQL */ `
  query SpmsOPCRPartOneByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsOPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsOPCRPartOneByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        isApprovedByApprover
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsOPCRPartOneByApproverID = /* GraphQL */ `
  query SpmsOPCRPartOneByApproverID(
    $approverID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsOPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsOPCRPartOneByApproverID(
      approverID: $approverID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        isApprovedByApprover
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsOPCREvaluationByEmployeeID = /* GraphQL */ `
  query SpmsOPCREvaluationByEmployeeID(
    $employeeID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsOPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsOPCREvaluationByEmployeeID(
      employeeID: $employeeID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        employeeID
        department
        approverID
        formOPCRID
        isForApproverReview
        isFinal
        formulationOPCR {
          id
          data
          userID
          isFinal
          department
          isApprovedByApprover
          approverID
          commentsOfApprover
          createdAt
          updatedAt
          approver {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsOPCREvaluationByDeptName = /* GraphQL */ `
  query SpmsOPCREvaluationByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsOPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsOPCREvaluationByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        employeeID
        department
        approverID
        formOPCRID
        isForApproverReview
        isFinal
        formulationOPCR {
          id
          data
          userID
          isFinal
          department
          isApprovedByApprover
          approverID
          commentsOfApprover
          createdAt
          updatedAt
          approver {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsOPCREvaluationByApproverID = /* GraphQL */ `
  query SpmsOPCREvaluationByApproverID(
    $approverID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsOPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsOPCREvaluationByApproverID(
      approverID: $approverID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        employeeID
        department
        approverID
        formOPCRID
        isForApproverReview
        isFinal
        formulationOPCR {
          id
          data
          userID
          isFinal
          department
          isApprovedByApprover
          approverID
          commentsOfApprover
          createdAt
          updatedAt
          approver {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsDPCREvaluationByEmployeeID = /* GraphQL */ `
  query SpmsDPCREvaluationByEmployeeID(
    $employeeID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsDPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsDPCREvaluationByEmployeeID(
      employeeID: $employeeID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        employeeID
        department
        division
        approverID
        formDPCRID
        isForApproverReview
        isFinal
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        formulationDPCR {
          id
          data
          userID
          isFinal
          department
          division
          isApprovedBySupervisor
          approverID
          commentsOfApprover
          createdAt
          updatedAt
          approver {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsDPCREvaluationByDeptName = /* GraphQL */ `
  query SpmsDPCREvaluationByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsDPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsDPCREvaluationByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        employeeID
        department
        division
        approverID
        formDPCRID
        isForApproverReview
        isFinal
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        formulationDPCR {
          id
          data
          userID
          isFinal
          department
          division
          isApprovedBySupervisor
          approverID
          commentsOfApprover
          createdAt
          updatedAt
          approver {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsDPCREvaluationByApproverID = /* GraphQL */ `
  query SpmsDPCREvaluationByApproverID(
    $approverID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsDPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsDPCREvaluationByApproverID(
      approverID: $approverID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        employeeID
        department
        division
        approverID
        formDPCRID
        isForApproverReview
        isFinal
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        formulationDPCR {
          id
          data
          userID
          isFinal
          department
          division
          isApprovedBySupervisor
          approverID
          commentsOfApprover
          createdAt
          updatedAt
          approver {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsIPCREvaluationByEmployeeID = /* GraphQL */ `
  query SpmsIPCREvaluationByEmployeeID(
    $employeeID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsIPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsIPCREvaluationByEmployeeID(
      employeeID: $employeeID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        isForSupervisorReview
        isFinal
        employeeID
        department
        division
        supervisorID
        formIPCRID
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        formulationIPCR {
          id
          data
          userID
          isFinal
          department
          division
          isApprovedBySupervisor
          supervisorID
          commentsOfSupervisor
          createdAt
          updatedAt
          supervisor {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsIPCREvaluationByDeptName = /* GraphQL */ `
  query SpmsIPCREvaluationByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsIPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsIPCREvaluationByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        isForSupervisorReview
        isFinal
        employeeID
        department
        division
        supervisorID
        formIPCRID
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        formulationIPCR {
          id
          data
          userID
          isFinal
          department
          division
          isApprovedBySupervisor
          supervisorID
          commentsOfSupervisor
          createdAt
          updatedAt
          supervisor {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsIPCREvaluationBySupervisorID = /* GraphQL */ `
  query SpmsIPCREvaluationBySupervisorID(
    $supervisorID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsIPCREvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsIPCREvaluationBySupervisorID(
      supervisorID: $supervisorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        isForSupervisorReview
        isFinal
        employeeID
        department
        division
        supervisorID
        formIPCRID
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        formulationIPCR {
          id
          data
          userID
          isFinal
          department
          division
          isApprovedBySupervisor
          supervisorID
          commentsOfSupervisor
          createdAt
          updatedAt
          supervisor {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const IPCRUploadByUserId = /* GraphQL */ `
  query IPCRUploadByUserId(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIPCRUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    IPCRUploadByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        file
        department
        division
        date
        userID
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const IPCRUploadByDeptName = /* GraphQL */ `
  query IPCRUploadByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIPCRUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    IPCRUploadByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        file
        department
        division
        date
        userID
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const IPCRUploadByDivisionName = /* GraphQL */ `
  query IPCRUploadByDivisionName(
    $division: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIPCRUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    IPCRUploadByDivisionName(
      division: $division
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        file
        department
        division
        date
        userID
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const DPCRUploadByUserId = /* GraphQL */ `
  query DPCRUploadByUserId(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDPCRUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DPCRUploadByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        file
        department
        date
        userID
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const DPCRUploadByDeptName = /* GraphQL */ `
  query DPCRUploadByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDPCRUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    DPCRUploadByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        file
        department
        date
        userID
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const OPCRUploadByUserId = /* GraphQL */ `
  query OPCRUploadByUserId(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOPCRUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OPCRUploadByUserId(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        file
        department
        date
        userID
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const OPCRUploadByDeptName = /* GraphQL */ `
  query OPCRUploadByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOPCRUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OPCRUploadByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        file
        department
        date
        userID
        isApproved
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const ActivityOneAnswerByUserID = /* GraphQL */ `
  query ActivityOneAnswerByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelactivityOneAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ActivityOneAnswerByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        answer
        trainingAnnouncementID
        createdAt
        timeSubmitted
        isSubmittedByUser
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const ActivityTwoAnswerByUserID = /* GraphQL */ `
  query ActivityTwoAnswerByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelactivityTwoAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ActivityTwoAnswerByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        answer
        trainingAnnouncementID
        createdAt
        timeSubmitted
        isSubmittedByUser
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const ActivityThreeAnswerByUserID = /* GraphQL */ `
  query ActivityThreeAnswerByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelactivityThreeAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ActivityThreeAnswerByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        answer
        trainingAnnouncementID
        createdAt
        timeSubmitted
        isSubmittedByUser
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const ActivityFourAnswerByUserID = /* GraphQL */ `
  query ActivityFourAnswerByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelactivityFourAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ActivityFourAnswerByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        answer
        trainingAnnouncementID
        createdAt
        timeSubmitted
        isSubmittedByUser
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const ActivityFiveAnswerByUserID = /* GraphQL */ `
  query ActivityFiveAnswerByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelactivityFiveAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ActivityFiveAnswerByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        date
        answer
        trainingAnnouncementID
        createdAt
        timeSubmitted
        isSubmittedByUser
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBSubmissionByUserID = /* GraphQL */ `
  query PSBSubmissionByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBSubmissionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBSubmissionByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        isPlantilla
        psbDeliberationID
        status
        screeningRemarks
        expectedDeliberationDate
        actualDeliberationDate
        dateDeptApproved
        dateHRApproved
        positionAppliedFor
        psbWorkExperienceID
        eligibility
        performance
        education
        training
        experience
        hrRemarks
        psbTrainingExperienceID
        psbTranscriptOrDiplomaID
        psbCertificationID
        psbIndividualPerformanceID
        psbDrugTestID
        psbClearanceID
        psbAffidavitID
        psbJustificationLetterID
        psbLoneApplicantID
        psbAppraisalReportID
        psbMedicalCertificateID
        psbMarriageCertificateID
        psbBirthCertificateID
        psbEvaluationReportID
        isForSubmission
        assignedItemNumber
        createdAt
        updatedAt
        owner
        psbWorkExperience {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbTrainingExperience {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbTranscriptOrDiploma {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbCertification {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbIndividualPerformance {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbDrugTest {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbClearance {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbAffidavit {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbJustificationLetter {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbLoneApplicant {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbAppraisalReport {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbMedicalCertificate {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbMarriageCertificate {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbBirthCertificate {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        psbEvaluationReport {
          id
          userID
          department
          division
          sequenceNumber
          sequenceDate
          createdAt
          isPlantilla
          uploads
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBDeliberationActivityBySequenceNumber = /* GraphQL */ `
  query PSBDeliberationActivityBySequenceNumber(
    $sequenceNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelPSBDeliberationActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBDeliberationActivityBySequenceNumber(
      sequenceNumber: $sequenceNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        sequenceNumber
        date
        startDate
        cutoffDate
        isCompleted
        createdAt
        notes
        updatedAt
        user {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const AttendanceRecordByEmployeeUsername = /* GraphQL */ `
  query AttendanceRecordByEmployeeUsername(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceRecordByEmployeeUsername(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dateTimeEntry
        tags
        username
        employeeUserID
        firstName
        middleName
        lastName
        createdAt
        isApproved
        isAccepted
        isOTApproved
        location
        updatedAt
        employee {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const AttendanceRecordAuditTrailByCreatorUsername = /* GraphQL */ `
  query AttendanceRecordAuditTrailByCreatorUsername(
    $createdBy: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceRecordAuditTrailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceRecordAuditTrailByCreatorUsername(
      createdBy: $createdBy
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attendanceID
        attendance {
          id
          dateTimeEntry
          tags
          username
          employeeUserID
          firstName
          middleName
          lastName
          createdAt
          isApproved
          isAccepted
          isOTApproved
          location
          updatedAt
          employee {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        attendanceFrom
        editedBy
        editedByID
        createdBy
        createdByID
        dateTimeEdite
        createdAt
        editDetails
        updatedAt
        editedByUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        createdByUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const AttendanceRecordAuditTrailByEditorUsername = /* GraphQL */ `
  query AttendanceRecordAuditTrailByEditorUsername(
    $editedBy: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAttendanceRecordAuditTrailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    AttendanceRecordAuditTrailByEditorUsername(
      editedBy: $editedBy
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        attendanceID
        attendance {
          id
          dateTimeEntry
          tags
          username
          employeeUserID
          firstName
          middleName
          lastName
          createdAt
          isApproved
          isAccepted
          isOTApproved
          location
          updatedAt
          employee {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        attendanceFrom
        editedBy
        editedByID
        createdBy
        createdByID
        dateTimeEdite
        createdAt
        editDetails
        updatedAt
        editedByUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        createdByUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const ScheduleByUserID = /* GraphQL */ `
  query ScheduleByUserID(
    $employeeID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ScheduleByUserID(
      employeeID: $employeeID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeID
        shiftID
        scheduleDates
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      mname
      lname
      suffix
      contact
      role
      position
      department
      assignedDept
      division
      sectionUnit
      dataPrivacy
      form
      form2
      isTakeSurvey
      date
      gender
      status
      createdAt
      username
      profilePicture
      ipcrRole
      dpcrRole
      opcrRole
      employeeIDNumber
      pdsRole
      psbRole
      appointmentStatus
      updatedAt
      owner
      __typename
    }
  }
`
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const UserbyUsername = /* GraphQL */ `
  query UserbyUsername(
    $username: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserbyUsername(
      username: $username
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const UserbyName = /* GraphQL */ `
  query UserbyName(
    $name: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserbyName(
      name: $name
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const UserbyLastname = /* GraphQL */ `
  query UserbyLastname(
    $lname: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserbyLastname(
      lname: $lname
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const UserbyDepartment = /* GraphQL */ `
  query UserbyDepartment(
    $assignedDept: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserbyDepartment(
      assignedDept: $assignedDept
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const UserbyHomeDepartment = /* GraphQL */ `
  query UserbyHomeDepartment(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    UserbyHomeDepartment(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getHandbookAcknowledgement = /* GraphQL */ `
  query GetHandbookAcknowledgement($id: ID!) {
    getHandbookAcknowledgement(id: $id) {
      id
      employeeName
      date
      department
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listHandbookAcknowledgements = /* GraphQL */ `
  query ListHandbookAcknowledgements(
    $filter: ModelhandbookAcknowledgementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHandbookAcknowledgements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeName
        date
        department
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getLdRefresher = /* GraphQL */ `
  query GetLdRefresher($id: ID!) {
    getLdRefresher(id: $id) {
      id
      employeeName
      date
      department
      mess
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listLdRefreshers = /* GraphQL */ `
  query ListLdRefreshers(
    $filter: ModelldRefresherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLdRefreshers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        employeeName
        date
        department
        mess
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getLdRefresherVideos = /* GraphQL */ `
  query GetLdRefresherVideos($id: ID!) {
    getLdRefresherVideos(id: $id) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listLdRefresherVideos = /* GraphQL */ `
  query ListLdRefresherVideos(
    $filter: ModelldRefresherVideosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLdRefresherVideos(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeName
        date
        department
        videoName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getOtherUpload = /* GraphQL */ `
  query GetOtherUpload($id: ID!) {
    getOtherUpload(id: $id) {
      id
      filename
      location
      description
      date
      userID
      createdAt
      updatedAt
      uploadedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      __typename
    }
  }
`
export const listOtherUploads = /* GraphQL */ `
  query ListOtherUploads(
    $filter: ModelotherUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOtherUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        filename
        location
        description
        date
        userID
        createdAt
        updatedAt
        uploadedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getTrainingAnnouncement = /* GraphQL */ `
  query GetTrainingAnnouncement($id: ID!) {
    getTrainingAnnouncement(id: $id) {
      id
      title
      date
      arrayOfDates
      trainingTimeStart
      trainingTimeEnd
      location
      courseDescription
      targetParticipants
      isOpenRegistration
      postTrainOpen
      postTrainClose
      courseCategory
      courseSubCategory
      hasAssessment
      participantLimit
      evalID
      assessmentID
      isTestEvent
      stepThreeID
      limitPerDept
      linkedTrainingAnnouncementIDs
      parentTrainingID
      isEndorsementRequired
      additionalTexts
      isSeminar
      isPassPreAssessmentRequired
      preAssessmentScore
      createdAt
      updatedAt
      owner
      evalQuestionnaire {
        id
        eval
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      questionsAssessment {
        id
        assessmentQuestionnaire
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      stepThreeText {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const listTrainingAnnouncements = /* GraphQL */ `
  query ListTrainingAnnouncements(
    $filter: ModeltrainingAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainingAnnouncements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getTrainingRegistration = /* GraphQL */ `
  query GetTrainingRegistration($id: ID!) {
    getTrainingRegistration(id: $id) {
      id
      userID
      trainingAnnouncementID
      isAgreeDataPrivacyConsent
      fullName
      first_name
      last_name
      middle_initial
      emailAddress
      gender
      contactNumber
      dept_office_unit_taskForce
      division_section
      designation_position
      endorsementLetter
      nickname
      employmentType
      questionnaire
      expectedOutcomes
      createdAt
      isRegistered
      isSubmitEndorsementLetter
      isAttended
      isPostEvalDone
      isCourseOutputDone
      additionalRemarks
      cscProof
      isAdminRegistration
      isPreAssessmentPassed
      updatedAt
      registeredUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      onlineTraining {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        sectionTitle
        additionalFiles
        sectionVideos
        sectionQuestions
        additionalTexts
        videoTranscripts
        isSeminar
        resourceSpeakerDetails
        announcementAndReminders
        questionAndAnswerObject
        isPassPreAssessmentRequired
        preAssessmentScore
        isPassPostAssessmentRequired
        postAssessmentScore
        createdAt
        updatedAt
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      __typename
    }
  }
`
export const listTrainingRegistrations = /* GraphQL */ `
  query ListTrainingRegistrations(
    $filter: ModeltrainingRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainingRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        trainingAnnouncementID
        isAgreeDataPrivacyConsent
        fullName
        first_name
        last_name
        middle_initial
        emailAddress
        gender
        contactNumber
        dept_office_unit_taskForce
        division_section
        designation_position
        endorsementLetter
        nickname
        employmentType
        questionnaire
        expectedOutcomes
        createdAt
        isRegistered
        isSubmitEndorsementLetter
        isAttended
        isPostEvalDone
        isCourseOutputDone
        additionalRemarks
        cscProof
        isAdminRegistration
        isPreAssessmentPassed
        updatedAt
        registeredUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        onlineTraining {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          sectionTitle
          additionalFiles
          sectionVideos
          sectionQuestions
          additionalTexts
          videoTranscripts
          isSeminar
          resourceSpeakerDetails
          announcementAndReminders
          questionAndAnswerObject
          isPassPreAssessmentRequired
          preAssessmentScore
          isPassPostAssessmentRequired
          postAssessmentScore
          createdAt
          updatedAt
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const registrationByTAID = /* GraphQL */ `
  query RegistrationByTAID(
    $trainingAnnouncementID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeltrainingRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationByTAID(
      trainingAnnouncementID: $trainingAnnouncementID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        trainingAnnouncementID
        isAgreeDataPrivacyConsent
        fullName
        first_name
        last_name
        middle_initial
        emailAddress
        gender
        contactNumber
        dept_office_unit_taskForce
        division_section
        designation_position
        endorsementLetter
        nickname
        employmentType
        questionnaire
        expectedOutcomes
        createdAt
        isRegistered
        isSubmitEndorsementLetter
        isAttended
        isPostEvalDone
        isCourseOutputDone
        additionalRemarks
        cscProof
        isAdminRegistration
        isPreAssessmentPassed
        updatedAt
        registeredUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        onlineTraining {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          sectionTitle
          additionalFiles
          sectionVideos
          sectionQuestions
          additionalTexts
          videoTranscripts
          isSeminar
          resourceSpeakerDetails
          announcementAndReminders
          questionAndAnswerObject
          isPassPreAssessmentRequired
          preAssessmentScore
          isPassPostAssessmentRequired
          postAssessmentScore
          createdAt
          updatedAt
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const registrationByUserID = /* GraphQL */ `
  query RegistrationByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeltrainingRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    registrationByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        trainingAnnouncementID
        isAgreeDataPrivacyConsent
        fullName
        first_name
        last_name
        middle_initial
        emailAddress
        gender
        contactNumber
        dept_office_unit_taskForce
        division_section
        designation_position
        endorsementLetter
        nickname
        employmentType
        questionnaire
        expectedOutcomes
        createdAt
        isRegistered
        isSubmitEndorsementLetter
        isAttended
        isPostEvalDone
        isCourseOutputDone
        additionalRemarks
        cscProof
        isAdminRegistration
        isPreAssessmentPassed
        updatedAt
        registeredUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        onlineTraining {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          sectionTitle
          additionalFiles
          sectionVideos
          sectionQuestions
          additionalTexts
          videoTranscripts
          isSeminar
          resourceSpeakerDetails
          announcementAndReminders
          questionAndAnswerObject
          isPassPreAssessmentRequired
          preAssessmentScore
          isPassPostAssessmentRequired
          postAssessmentScore
          createdAt
          updatedAt
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getHomeCardAnnouncement = /* GraphQL */ `
  query GetHomeCardAnnouncement($id: ID!) {
    getHomeCardAnnouncement(id: $id) {
      id
      upper
      lower
      lowerLocation
      hideLower
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listHomeCardAnnouncements = /* GraphQL */ `
  query ListHomeCardAnnouncements(
    $filter: ModelhomeCardAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomeCardAnnouncements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        upper
        lower
        lowerLocation
        hideLower
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getHomeCardRegistration = /* GraphQL */ `
  query GetHomeCardRegistration($id: ID!) {
    getHomeCardRegistration(id: $id) {
      id
      upper
      lower
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listHomeCardRegistrations = /* GraphQL */ `
  query ListHomeCardRegistrations(
    $filter: ModelhomeCardRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomeCardRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        upper
        lower
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getHomeCardUpcomingTrainingCard = /* GraphQL */ `
  query GetHomeCardUpcomingTrainingCard($id: ID!) {
    getHomeCardUpcomingTrainingCard(id: $id) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listHomeCardUpcomingTrainingCards = /* GraphQL */ `
  query ListHomeCardUpcomingTrainingCards(
    $filter: ModelhomeCardUpcomingTrainingCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomeCardUpcomingTrainingCards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getHomeUpcomingTrainingCard = /* GraphQL */ `
  query GetHomeUpcomingTrainingCard($id: ID!) {
    getHomeUpcomingTrainingCard(id: $id) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listHomeUpcomingTrainingCards = /* GraphQL */ `
  query ListHomeUpcomingTrainingCards(
    $filter: ModelhomeUpcomingTrainingCardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomeUpcomingTrainingCards(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getTrainingPostEvaluation = /* GraphQL */ `
  query GetTrainingPostEvaluation($id: ID!) {
    getTrainingPostEvaluation(id: $id) {
      id
      userID
      trainingAnnouncementID
      clarityOfObjectives
      relevanceOfContent
      sequenceOfContent
      facilitationMOTS
      facilitationTimeMgt
      facilitationProfConduct
      adminMOTS
      adminTimeMgt
      adminProfConduct
      postEvalQuestions
      questionnaire
      additionalComments
      cscComments
      createdAt
      updatedAt
      registeredUser {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      __typename
    }
  }
`
export const listTrainingPostEvaluations = /* GraphQL */ `
  query ListTrainingPostEvaluations(
    $filter: ModeltrainingPostEvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTrainingPostEvaluations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        trainingAnnouncementID
        clarityOfObjectives
        relevanceOfContent
        sequenceOfContent
        facilitationMOTS
        facilitationTimeMgt
        facilitationProfConduct
        adminMOTS
        adminTimeMgt
        adminProfConduct
        postEvalQuestions
        questionnaire
        additionalComments
        cscComments
        createdAt
        updatedAt
        registeredUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const evaluationByTAID = /* GraphQL */ `
  query EvaluationByTAID(
    $trainingAnnouncementID: ID
    $userID: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeltrainingPostEvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    evaluationByTAID(
      trainingAnnouncementID: $trainingAnnouncementID
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        trainingAnnouncementID
        clarityOfObjectives
        relevanceOfContent
        sequenceOfContent
        facilitationMOTS
        facilitationTimeMgt
        facilitationProfConduct
        adminMOTS
        adminTimeMgt
        adminProfConduct
        postEvalQuestions
        questionnaire
        additionalComments
        cscComments
        createdAt
        updatedAt
        registeredUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const evaluationByUserID = /* GraphQL */ `
  query EvaluationByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModeltrainingPostEvaluationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    evaluationByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        trainingAnnouncementID
        clarityOfObjectives
        relevanceOfContent
        sequenceOfContent
        facilitationMOTS
        facilitationTimeMgt
        facilitationProfConduct
        adminMOTS
        adminTimeMgt
        adminProfConduct
        postEvalQuestions
        questionnaire
        additionalComments
        cscComments
        createdAt
        updatedAt
        registeredUser {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getEvaluationQuestions = /* GraphQL */ `
  query GetEvaluationQuestions($id: ID!) {
    getEvaluationQuestions(id: $id) {
      id
      eval
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listEvaluationQuestions = /* GraphQL */ `
  query ListEvaluationQuestions(
    $filter: ModelevaluationQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvaluationQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        eval
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getOnlineActivityQuestions = /* GraphQL */ `
  query GetOnlineActivityQuestions($id: ID!) {
    getOnlineActivityQuestions(id: $id) {
      id
      questions
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listOnlineActivityQuestions = /* GraphQL */ `
  query ListOnlineActivityQuestions(
    $filter: ModelonlineActivityQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnlineActivityQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        questions
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getOnlineActivityAnswers = /* GraphQL */ `
  query GetOnlineActivityAnswers($id: ID!) {
    getOnlineActivityAnswers(id: $id) {
      id
      answers
      userID
      onlineTrainingAnnouncementID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listOnlineActivityAnswers = /* GraphQL */ `
  query ListOnlineActivityAnswers(
    $filter: ModelonlineActivityAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnlineActivityAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answers
        userID
        onlineTrainingAnnouncementID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const OnlineActivitiesAnswerByTrainingID = /* GraphQL */ `
  query OnlineActivitiesAnswerByTrainingID(
    $onlineTrainingAnnouncementID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelonlineActivityAnswersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    OnlineActivitiesAnswerByTrainingID(
      onlineTrainingAnnouncementID: $onlineTrainingAnnouncementID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        answers
        userID
        onlineTrainingAnnouncementID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getAssessmentQuestions = /* GraphQL */ `
  query GetAssessmentQuestions($id: ID!) {
    getAssessmentQuestions(id: $id) {
      id
      assessmentQuestionnaire
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listAssessmentQuestions = /* GraphQL */ `
  query ListAssessmentQuestions(
    $filter: ModelassessmentQuestionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessmentQuestionnaire
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSpmsIPCRPartOne = /* GraphQL */ `
  query GetSpmsIPCRPartOne($id: ID!) {
    getSpmsIPCRPartOne(id: $id) {
      id
      data
      userID
      isFinal
      department
      division
      isApprovedBySupervisor
      supervisorID
      commentsOfSupervisor
      createdAt
      updatedAt
      supervisor {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listSpmsIPCRPartOnes = /* GraphQL */ `
  query ListSpmsIPCRPartOnes(
    $filter: ModelspmsIPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpmsIPCRPartOnes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        supervisorID
        commentsOfSupervisor
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsIPCRPartOneByUserID = /* GraphQL */ `
  query SpmsIPCRPartOneByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsIPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsIPCRPartOneByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        supervisorID
        commentsOfSupervisor
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsIPCRPartOneByDeptName = /* GraphQL */ `
  query SpmsIPCRPartOneByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsIPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsIPCRPartOneByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        supervisorID
        commentsOfSupervisor
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsIPCRPartOneByDivisionName = /* GraphQL */ `
  query SpmsIPCRPartOneByDivisionName(
    $division: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsIPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsIPCRPartOneByDivisionName(
      division: $division
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        supervisorID
        commentsOfSupervisor
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsIPCRPartOneBySupervisorID = /* GraphQL */ `
  query SpmsIPCRPartOneBySupervisorID(
    $supervisorID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsIPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsIPCRPartOneBySupervisorID(
      supervisorID: $supervisorID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        supervisorID
        commentsOfSupervisor
        createdAt
        updatedAt
        supervisor {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSpmsDPCRPartOne = /* GraphQL */ `
  query GetSpmsDPCRPartOne($id: ID!) {
    getSpmsDPCRPartOne(id: $id) {
      id
      data
      userID
      isFinal
      department
      division
      isApprovedBySupervisor
      approverID
      commentsOfApprover
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listSpmsDPCRPartOnes = /* GraphQL */ `
  query ListSpmsDPCRPartOnes(
    $filter: ModelspmsDPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpmsDPCRPartOnes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsDPCRPartOneByUserID = /* GraphQL */ `
  query SpmsDPCRPartOneByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsDPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsDPCRPartOneByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsDPCRPartOneByDeptName = /* GraphQL */ `
  query SpmsDPCRPartOneByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsDPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsDPCRPartOneByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsDPCRPartOneByDivisionName = /* GraphQL */ `
  query SpmsDPCRPartOneByDivisionName(
    $division: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsDPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsDPCRPartOneByDivisionName(
      division: $division
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const spmsDPCRPartOneByApproverID = /* GraphQL */ `
  query SpmsDPCRPartOneByApproverID(
    $approverID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelspmsDPCRPartOneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    spmsDPCRPartOneByApproverID(
      approverID: $approverID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApprovedBySupervisor
        approverID
        commentsOfApprover
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getShowVideoByTraining = /* GraphQL */ `
  query GetShowVideoByTraining($id: ID!) {
    getShowVideoByTraining(id: $id) {
      id
      trainingVideoID
      trainingAnnouncementID
      createdAt
      updatedAt
      training {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        additionalTexts
        isSeminar
        isPassPreAssessmentRequired
        preAssessmentScore
        createdAt
        updatedAt
        owner
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        __typename
      }
      owner
      __typename
    }
  }
`
export const listShowVideoByTrainings = /* GraphQL */ `
  query ListShowVideoByTrainings(
    $filter: ModelshowVideoByTrainingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShowVideoByTrainings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        trainingVideoID
        trainingAnnouncementID
        createdAt
        updatedAt
        training {
          id
          title
          date
          arrayOfDates
          trainingTimeStart
          trainingTimeEnd
          location
          courseDescription
          targetParticipants
          isOpenRegistration
          postTrainOpen
          postTrainClose
          courseCategory
          courseSubCategory
          hasAssessment
          participantLimit
          evalID
          assessmentID
          isTestEvent
          stepThreeID
          limitPerDept
          linkedTrainingAnnouncementIDs
          parentTrainingID
          isEndorsementRequired
          additionalTexts
          isSeminar
          isPassPreAssessmentRequired
          preAssessmentScore
          createdAt
          updatedAt
          owner
          evalQuestionnaire {
            id
            eval
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          questionsAssessment {
            id
            assessmentQuestionnaire
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          stepThreeText {
            id
            textDisplay
            userID
            createdAt
            updatedAt
            ownedBy {
              id
              name
              mname
              lname
              suffix
              contact
              role
              position
              department
              assignedDept
              division
              sectionUnit
              dataPrivacy
              form
              form2
              isTakeSurvey
              date
              gender
              status
              createdAt
              username
              profilePicture
              ipcrRole
              dpcrRole
              opcrRole
              employeeIDNumber
              pdsRole
              psbRole
              appointmentStatus
              updatedAt
              owner
              __typename
            }
            owner
            __typename
          }
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getStepThreeRegistration = /* GraphQL */ `
  query GetStepThreeRegistration($id: ID!) {
    getStepThreeRegistration(id: $id) {
      id
      textDisplay
      userID
      createdAt
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listStepThreeRegistrations = /* GraphQL */ `
  query ListStepThreeRegistrations(
    $filter: ModelstepThreeRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStepThreeRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getFaqTable = /* GraphQL */ `
  query GetFaqTable($id: ID!) {
    getFaqTable(id: $id) {
      id
      category
      questionText
      answerText
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listFaqTables = /* GraphQL */ `
  query ListFaqTables(
    $filter: ModelfaqTableFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFaqTables(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        category
        questionText
        answerText
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getSpmsVideoAcknowledgement = /* GraphQL */ `
  query GetSpmsVideoAcknowledgement($id: ID!) {
    getSpmsVideoAcknowledgement(id: $id) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listSpmsVideoAcknowledgements = /* GraphQL */ `
  query ListSpmsVideoAcknowledgements(
    $filter: ModelspmsVideoAcknowledgementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSpmsVideoAcknowledgements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeName
        date
        department
        videoName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getOnlineTrainingAnnouncement = /* GraphQL */ `
  query GetOnlineTrainingAnnouncement($id: ID!) {
    getOnlineTrainingAnnouncement(id: $id) {
      id
      title
      date
      arrayOfDates
      trainingTimeStart
      trainingTimeEnd
      location
      courseDescription
      targetParticipants
      isOpenRegistration
      postTrainOpen
      postTrainClose
      courseCategory
      courseSubCategory
      hasAssessment
      participantLimit
      evalID
      assessmentID
      isTestEvent
      stepThreeID
      limitPerDept
      linkedTrainingAnnouncementIDs
      parentTrainingID
      isEndorsementRequired
      sectionTitle
      additionalFiles
      sectionVideos
      sectionQuestions
      additionalTexts
      videoTranscripts
      isSeminar
      resourceSpeakerDetails
      announcementAndReminders
      questionAndAnswerObject
      isPassPreAssessmentRequired
      preAssessmentScore
      isPassPostAssessmentRequired
      postAssessmentScore
      createdAt
      updatedAt
      evalQuestionnaire {
        id
        eval
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      questionsAssessment {
        id
        assessmentQuestionnaire
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      stepThreeText {
        id
        textDisplay
        userID
        createdAt
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listOnlineTrainingAnnouncements = /* GraphQL */ `
  query ListOnlineTrainingAnnouncements(
    $filter: ModelonlineTrainingAnnouncementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnlineTrainingAnnouncements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        date
        arrayOfDates
        trainingTimeStart
        trainingTimeEnd
        location
        courseDescription
        targetParticipants
        isOpenRegistration
        postTrainOpen
        postTrainClose
        courseCategory
        courseSubCategory
        hasAssessment
        participantLimit
        evalID
        assessmentID
        isTestEvent
        stepThreeID
        limitPerDept
        linkedTrainingAnnouncementIDs
        parentTrainingID
        isEndorsementRequired
        sectionTitle
        additionalFiles
        sectionVideos
        sectionQuestions
        additionalTexts
        videoTranscripts
        isSeminar
        resourceSpeakerDetails
        announcementAndReminders
        questionAndAnswerObject
        isPassPreAssessmentRequired
        preAssessmentScore
        isPassPostAssessmentRequired
        postAssessmentScore
        createdAt
        updatedAt
        evalQuestionnaire {
          id
          eval
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        questionsAssessment {
          id
          assessmentQuestionnaire
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        stepThreeText {
          id
          textDisplay
          userID
          createdAt
          updatedAt
          ownedBy {
            id
            name
            mname
            lname
            suffix
            contact
            role
            position
            department
            assignedDept
            division
            sectionUnit
            dataPrivacy
            form
            form2
            isTakeSurvey
            date
            gender
            status
            createdAt
            username
            profilePicture
            ipcrRole
            dpcrRole
            opcrRole
            employeeIDNumber
            pdsRole
            psbRole
            appointmentStatus
            updatedAt
            owner
            __typename
          }
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getOrientationVideoAcknowledgement = /* GraphQL */ `
  query GetOrientationVideoAcknowledgement($id: ID!) {
    getOrientationVideoAcknowledgement(id: $id) {
      id
      employeeName
      date
      department
      videoName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listOrientationVideoAcknowledgements = /* GraphQL */ `
  query ListOrientationVideoAcknowledgements(
    $filter: ModelorientationVideoAcknowledgementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrientationVideoAcknowledgements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        employeeName
        date
        department
        videoName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getHomeSlidesUploaded = /* GraphQL */ `
  query GetHomeSlidesUploaded($id: ID!) {
    getHomeSlidesUploaded(id: $id) {
      id
      slideImageFileName
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listHomeSlidesUploadeds = /* GraphQL */ `
  query ListHomeSlidesUploadeds(
    $filter: ModelhomeSlidesUploadedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHomeSlidesUploadeds(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slideImageFileName
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPublicKeys = /* GraphQL */ `
  query GetPublicKeys($id: ID!) {
    getPublicKeys(id: $id) {
      id
      publicKey
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listPublicKeys = /* GraphQL */ `
  query ListPublicKeys(
    $filter: ModelpublicKeysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicKeys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        publicKey
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPersonalDataSheet = /* GraphQL */ `
  query GetPersonalDataSheet($id: ID!) {
    getPersonalDataSheet(id: $id) {
      id
      data
      userID
      isFinal
      department
      division
      isApproved
      approverID
      commentsOfApprover
      isHRApproved
      hrApproverID
      commentsOfHRApprover
      createdAt
      isAgreeDataPrivacyPDS
      eSignatureLoc
      thumbmarkSignLoc
      passportPhotoLoc
      encodedBy
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      hrApprover {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      encoder {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPersonalDataSheets = /* GraphQL */ `
  query ListPersonalDataSheets(
    $filter: ModelpersonalDataSheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonalDataSheets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApproved
        approverID
        commentsOfApprover
        isHRApproved
        hrApproverID
        commentsOfHRApprover
        createdAt
        isAgreeDataPrivacyPDS
        eSignatureLoc
        thumbmarkSignLoc
        passportPhotoLoc
        encodedBy
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        encoder {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const personalDataSheetByUserID = /* GraphQL */ `
  query PersonalDataSheetByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpersonalDataSheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalDataSheetByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApproved
        approverID
        commentsOfApprover
        isHRApproved
        hrApproverID
        commentsOfHRApprover
        createdAt
        isAgreeDataPrivacyPDS
        eSignatureLoc
        thumbmarkSignLoc
        passportPhotoLoc
        encodedBy
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        encoder {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const personalDataSheetByDeptName = /* GraphQL */ `
  query PersonalDataSheetByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpersonalDataSheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalDataSheetByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApproved
        approverID
        commentsOfApprover
        isHRApproved
        hrApproverID
        commentsOfHRApprover
        createdAt
        isAgreeDataPrivacyPDS
        eSignatureLoc
        thumbmarkSignLoc
        passportPhotoLoc
        encodedBy
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        encoder {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const personalDataSheetByDivisionName = /* GraphQL */ `
  query PersonalDataSheetByDivisionName(
    $division: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpersonalDataSheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalDataSheetByDivisionName(
      division: $division
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApproved
        approverID
        commentsOfApprover
        isHRApproved
        hrApproverID
        commentsOfHRApprover
        createdAt
        isAgreeDataPrivacyPDS
        eSignatureLoc
        thumbmarkSignLoc
        passportPhotoLoc
        encodedBy
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        encoder {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const personalDataSheetByApproverID = /* GraphQL */ `
  query PersonalDataSheetByApproverID(
    $approverID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpersonalDataSheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalDataSheetByApproverID(
      approverID: $approverID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApproved
        approverID
        commentsOfApprover
        isHRApproved
        hrApproverID
        commentsOfHRApprover
        createdAt
        isAgreeDataPrivacyPDS
        eSignatureLoc
        thumbmarkSignLoc
        passportPhotoLoc
        encodedBy
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        encoder {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const personalDataSheetByEncodedByID = /* GraphQL */ `
  query PersonalDataSheetByEncodedByID(
    $encodedBy: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelpersonalDataSheetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    personalDataSheetByEncodedByID(
      encodedBy: $encodedBy
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        isFinal
        department
        division
        isApproved
        approverID
        commentsOfApprover
        isHRApproved
        hrApproverID
        commentsOfHRApprover
        createdAt
        isAgreeDataPrivacyPDS
        eSignatureLoc
        thumbmarkSignLoc
        passportPhotoLoc
        encodedBy
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        encoder {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getErrorLogs = /* GraphQL */ `
  query GetErrorLogs($id: ID!) {
    getErrorLogs(id: $id) {
      id
      errorLog
      date
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`
export const listErrorLogs = /* GraphQL */ `
  query ListErrorLogs(
    $filter: ModelerrorLogsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listErrorLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        errorLog
        date
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBUpload = /* GraphQL */ `
  query GetPSBUpload($id: ID!) {
    getPSBUpload(id: $id) {
      id
      data
      userID
      department
      division
      isApproved
      dateApproved
      approverID
      commentsOfApprover
      isHRApproved
      dateHRApproved
      hrApproverID
      commentsOfHRApprover
      sequenceNumber
      sequenceDate
      isPlantilla
      createdAt
      updatedAt
      approver {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      hrApprover {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBUploads = /* GraphQL */ `
  query ListPSBUploads(
    $filter: ModelPSBUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBUploads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data
        userID
        department
        division
        isApproved
        dateApproved
        approverID
        commentsOfApprover
        isHRApproved
        dateHRApproved
        hrApproverID
        commentsOfHRApprover
        sequenceNumber
        sequenceDate
        isPlantilla
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBUploadsByUserID = /* GraphQL */ `
  query PSBUploadsByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBUploadsByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        department
        division
        isApproved
        dateApproved
        approverID
        commentsOfApprover
        isHRApproved
        dateHRApproved
        hrApproverID
        commentsOfHRApprover
        sequenceNumber
        sequenceDate
        isPlantilla
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBUploadsByApproverID = /* GraphQL */ `
  query PSBUploadsByApproverID(
    $approverID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBUploadsByApproverID(
      approverID: $approverID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        department
        division
        isApproved
        dateApproved
        approverID
        commentsOfApprover
        isHRApproved
        dateHRApproved
        hrApproverID
        commentsOfHRApprover
        sequenceNumber
        sequenceDate
        isPlantilla
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBUploadsByHRApproverID = /* GraphQL */ `
  query PSBUploadsByHRApproverID(
    $hrApproverID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBUploadsByHRApproverID(
      hrApproverID: $hrApproverID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        department
        division
        isApproved
        dateApproved
        approverID
        commentsOfApprover
        isHRApproved
        dateHRApproved
        hrApproverID
        commentsOfHRApprover
        sequenceNumber
        sequenceDate
        isPlantilla
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBUploadBySequenceNumber = /* GraphQL */ `
  query PSBUploadBySequenceNumber(
    $sequenceNumber: String
    $sortDirection: ModelSortDirection
    $filter: ModelPSBUploadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBUploadBySequenceNumber(
      sequenceNumber: $sequenceNumber
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        data
        userID
        department
        division
        isApproved
        dateApproved
        approverID
        commentsOfApprover
        isHRApproved
        dateHRApproved
        hrApproverID
        commentsOfHRApprover
        sequenceNumber
        sequenceDate
        isPlantilla
        createdAt
        updatedAt
        approver {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        hrApprover {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBWorkExperience = /* GraphQL */ `
  query GetPSBWorkExperience($id: ID!) {
    getPSBWorkExperience(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBWorkExperiences = /* GraphQL */ `
  query ListPSBWorkExperiences(
    $filter: ModelPSBWorkExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBWorkExperiences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBWorkExperienceByUserID = /* GraphQL */ `
  query PSBWorkExperienceByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBWorkExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBWorkExperienceByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBWorkExperienceByDeptName = /* GraphQL */ `
  query PSBWorkExperienceByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBWorkExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBWorkExperienceByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBWorkExperienceBySequenceNumber = /* GraphQL */ `
  query PSBWorkExperienceBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBWorkExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBWorkExperienceBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBTrainingExperience = /* GraphQL */ `
  query GetPSBTrainingExperience($id: ID!) {
    getPSBTrainingExperience(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBTrainingExperiences = /* GraphQL */ `
  query ListPSBTrainingExperiences(
    $filter: ModelPSBTrainingExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBTrainingExperiences(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBTrainingExperienceByUserID = /* GraphQL */ `
  query PSBTrainingExperienceByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBTrainingExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBTrainingExperienceByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBTrainingExperienceByDeptName = /* GraphQL */ `
  query PSBTrainingExperienceByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBTrainingExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBTrainingExperienceByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBTrainingExperienceBySequenceNumber = /* GraphQL */ `
  query PSBTrainingExperienceBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBTrainingExperienceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBTrainingExperienceBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBTranscriptOrDiploma = /* GraphQL */ `
  query GetPSBTranscriptOrDiploma($id: ID!) {
    getPSBTranscriptOrDiploma(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBTranscriptOrDiplomas = /* GraphQL */ `
  query ListPSBTranscriptOrDiplomas(
    $filter: ModelPSBTranscriptOrDiplomaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBTranscriptOrDiplomas(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBTranscriptOrDiplomaByUserID = /* GraphQL */ `
  query PSBTranscriptOrDiplomaByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBTranscriptOrDiplomaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBTranscriptOrDiplomaByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBTranscriptOrDiplomaByDeptName = /* GraphQL */ `
  query PSBTranscriptOrDiplomaByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBTranscriptOrDiplomaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBTranscriptOrDiplomaByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBTranscriptOrDiplomaBySequenceNumber = /* GraphQL */ `
  query PSBTranscriptOrDiplomaBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBTranscriptOrDiplomaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBTranscriptOrDiplomaBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBCertification = /* GraphQL */ `
  query GetPSBCertification($id: ID!) {
    getPSBCertification(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBCertifications = /* GraphQL */ `
  query ListPSBCertifications(
    $filter: ModelPSBCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBCertifications(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBCertificationByUserID = /* GraphQL */ `
  query PSBCertificationByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBCertificationByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBCertificationByDeptName = /* GraphQL */ `
  query PSBCertificationByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBCertificationByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBCertificationBySequenceNumber = /* GraphQL */ `
  query PSBCertificationBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBCertificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBCertificationBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBIndividualPerformance = /* GraphQL */ `
  query GetPSBIndividualPerformance($id: ID!) {
    getPSBIndividualPerformance(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBIndividualPerformances = /* GraphQL */ `
  query ListPSBIndividualPerformances(
    $filter: ModelPSBIndividualPerformanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBIndividualPerformances(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBIndividualPerformanceByUserID = /* GraphQL */ `
  query PSBIndividualPerformanceByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBIndividualPerformanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBIndividualPerformanceByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBIndividualPerformanceByDeptName = /* GraphQL */ `
  query PSBIndividualPerformanceByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBIndividualPerformanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBIndividualPerformanceByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBIndividualPerformanceBySequenceNumber = /* GraphQL */ `
  query PSBIndividualPerformanceBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBIndividualPerformanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBIndividualPerformanceBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBDrugTest = /* GraphQL */ `
  query GetPSBDrugTest($id: ID!) {
    getPSBDrugTest(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBDrugTests = /* GraphQL */ `
  query ListPSBDrugTests(
    $filter: ModelPSBDrugTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBDrugTests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBDrugTestByUserID = /* GraphQL */ `
  query PSBDrugTestByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBDrugTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBDrugTestByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBDrugTestByDeptName = /* GraphQL */ `
  query PSBDrugTestByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBDrugTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBDrugTestByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBDrugTestBySequenceNumber = /* GraphQL */ `
  query PSBDrugTestBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBDrugTestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBDrugTestBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBClearance = /* GraphQL */ `
  query GetPSBClearance($id: ID!) {
    getPSBClearance(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBClearances = /* GraphQL */ `
  query ListPSBClearances(
    $filter: ModelPSBClearanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBClearances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBClearanceByUserID = /* GraphQL */ `
  query PSBClearanceByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBClearanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBClearanceByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBClearanceByDeptName = /* GraphQL */ `
  query PSBClearanceByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBClearanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBClearanceByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBClearanceBySequenceNumber = /* GraphQL */ `
  query PSBClearanceBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBClearanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBClearanceBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBAffidavit = /* GraphQL */ `
  query GetPSBAffidavit($id: ID!) {
    getPSBAffidavit(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBAffidavits = /* GraphQL */ `
  query ListPSBAffidavits(
    $filter: ModelPSBAffidavitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBAffidavits(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBAffidavitByUserID = /* GraphQL */ `
  query PSBAffidavitByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBAffidavitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBAffidavitByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBAffidavitByDeptName = /* GraphQL */ `
  query PSBAffidavitByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBAffidavitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBAffidavitByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBAffidavitBySequenceNumber = /* GraphQL */ `
  query PSBAffidavitBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBAffidavitFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBAffidavitBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBJustificationLetter = /* GraphQL */ `
  query GetPSBJustificationLetter($id: ID!) {
    getPSBJustificationLetter(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBJustificationLetters = /* GraphQL */ `
  query ListPSBJustificationLetters(
    $filter: ModelPSBJustificationLetterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBJustificationLetters(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBJustificationLetterByUserID = /* GraphQL */ `
  query PSBJustificationLetterByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBJustificationLetterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBJustificationLetterByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBJustificationLetterByDeptName = /* GraphQL */ `
  query PSBJustificationLetterByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBJustificationLetterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBJustificationLetterByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBJustificationLetterBySequenceNumber = /* GraphQL */ `
  query PSBJustificationLetterBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBJustificationLetterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBJustificationLetterBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBLoneApplicant = /* GraphQL */ `
  query GetPSBLoneApplicant($id: ID!) {
    getPSBLoneApplicant(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBLoneApplicants = /* GraphQL */ `
  query ListPSBLoneApplicants(
    $filter: ModelPSBLoneApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBLoneApplicants(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBLoneApplicantByUserID = /* GraphQL */ `
  query PSBLoneApplicantByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBLoneApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBLoneApplicantByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBLoneApplicantByDeptName = /* GraphQL */ `
  query PSBLoneApplicantByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBLoneApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBLoneApplicantByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBLoneApplicantBySequenceNumber = /* GraphQL */ `
  query PSBLoneApplicantBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBLoneApplicantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBLoneApplicantBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBAppraisalReport = /* GraphQL */ `
  query GetPSBAppraisalReport($id: ID!) {
    getPSBAppraisalReport(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBAppraisalReports = /* GraphQL */ `
  query ListPSBAppraisalReports(
    $filter: ModelPSBAppraisalReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBAppraisalReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBAppraisalReportByUserID = /* GraphQL */ `
  query PSBAppraisalReportByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBAppraisalReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBAppraisalReportByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBAppraisalReportByDeptName = /* GraphQL */ `
  query PSBAppraisalReportByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBAppraisalReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBAppraisalReportByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBAppraisalReportBySequenceNumber = /* GraphQL */ `
  query PSBAppraisalReportBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBAppraisalReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBAppraisalReportBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBMedicalCertificate = /* GraphQL */ `
  query GetPSBMedicalCertificate($id: ID!) {
    getPSBMedicalCertificate(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBMedicalCertificates = /* GraphQL */ `
  query ListPSBMedicalCertificates(
    $filter: ModelPSBMedicalCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBMedicalCertificates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBMedicalCertificateByUserID = /* GraphQL */ `
  query PSBMedicalCertificateByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBMedicalCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBMedicalCertificateByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBMedicalCertificateByDeptName = /* GraphQL */ `
  query PSBMedicalCertificateByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBMedicalCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBMedicalCertificateByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBMedicalCertificateBySequenceNumber = /* GraphQL */ `
  query PSBMedicalCertificateBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBMedicalCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBMedicalCertificateBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBMarriageCertificate = /* GraphQL */ `
  query GetPSBMarriageCertificate($id: ID!) {
    getPSBMarriageCertificate(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBMarriageCertificates = /* GraphQL */ `
  query ListPSBMarriageCertificates(
    $filter: ModelPSBMarriageCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBMarriageCertificates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBMarriageCertificateByUserID = /* GraphQL */ `
  query PSBMarriageCertificateByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBMarriageCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBMarriageCertificateByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBMarriageCertificateByDeptName = /* GraphQL */ `
  query PSBMarriageCertificateByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBMarriageCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBMarriageCertificateByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBMarriageCertificateBySequenceNumber = /* GraphQL */ `
  query PSBMarriageCertificateBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBMarriageCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBMarriageCertificateBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBBirthCertificate = /* GraphQL */ `
  query GetPSBBirthCertificate($id: ID!) {
    getPSBBirthCertificate(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBBirthCertificates = /* GraphQL */ `
  query ListPSBBirthCertificates(
    $filter: ModelPSBBirthCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBBirthCertificates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBBirthCertificateByUserID = /* GraphQL */ `
  query PSBBirthCertificateByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBBirthCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBBirthCertificateByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBBirthCertificateByDeptName = /* GraphQL */ `
  query PSBBirthCertificateByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBBirthCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBBirthCertificateByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBBirthCertificateBySequenceNumber = /* GraphQL */ `
  query PSBBirthCertificateBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBBirthCertificateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBBirthCertificateBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const getPSBEvaluationReport = /* GraphQL */ `
  query GetPSBEvaluationReport($id: ID!) {
    getPSBEvaluationReport(id: $id) {
      id
      userID
      department
      division
      sequenceNumber
      sequenceDate
      createdAt
      isPlantilla
      uploads
      updatedAt
      ownedBy {
        id
        name
        mname
        lname
        suffix
        contact
        role
        position
        department
        assignedDept
        division
        sectionUnit
        dataPrivacy
        form
        form2
        isTakeSurvey
        date
        gender
        status
        createdAt
        username
        profilePicture
        ipcrRole
        dpcrRole
        opcrRole
        employeeIDNumber
        pdsRole
        psbRole
        appointmentStatus
        updatedAt
        owner
        __typename
      }
      owner
      __typename
    }
  }
`
export const listPSBEvaluationReports = /* GraphQL */ `
  query ListPSBEvaluationReports(
    $filter: ModelPSBEvaluationReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPSBEvaluationReports(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBEvaluationReportByUserID = /* GraphQL */ `
  query PSBEvaluationReportByUserID(
    $userID: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBEvaluationReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBEvaluationReportByUserID(
      userID: $userID
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBEvaluationReportByDeptName = /* GraphQL */ `
  query PSBEvaluationReportByDeptName(
    $department: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBEvaluationReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBEvaluationReportByDeptName(
      department: $department
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
export const PSBEvaluationReportBySequenceNumber = /* GraphQL */ `
  query PSBEvaluationReportBySequenceNumber(
    $sequenceNumber: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPSBEvaluationReportFilterInput
    $limit: Int
    $nextToken: String
  ) {
    PSBEvaluationReportBySequenceNumber(
      sequenceNumber: $sequenceNumber
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        department
        division
        sequenceNumber
        sequenceDate
        createdAt
        isPlantilla
        uploads
        updatedAt
        ownedBy {
          id
          name
          mname
          lname
          suffix
          contact
          role
          position
          department
          assignedDept
          division
          sectionUnit
          dataPrivacy
          form
          form2
          isTakeSurvey
          date
          gender
          status
          createdAt
          username
          profilePicture
          ipcrRole
          dpcrRole
          opcrRole
          employeeIDNumber
          pdsRole
          psbRole
          appointmentStatus
          updatedAt
          owner
          __typename
        }
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`
