/**
 * Title: internRequest.js
 * Description: This is a file that contains the Intern Request Page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/Internship/internRequest.js
 **/

import { useEffect } from 'react'
import { Row, Col } from 'antd'

import { Form } from '@formio/react'

import './internRequest.css'

export default function InternRequest() {
  const handleShowUpload = () => {
    try {
      const uploadLabel = document.createElement('label')
      uploadLabel.htmlFor = 'uploadInput'
      uploadLabel.innerHTML = 'UPLOAD ENDORSEMENT LETTER'

      const uploadSpan = document.createElement('span')
      uploadSpan.id = 'uploadSpan'
      uploadSpan.innerHTML = 'Uploaded File: '

      const uploadDiv = document.createElement('div')
      uploadDiv.id = 'uploadDiv'

      const uploadInput = document.createElement('input')
      uploadInput.className = 'uploadInput'
      uploadInput.id = 'uploadInput'
      uploadInput.type = 'file'

      document
        .getElementsByClassName('uploadendorsementinternrequest')[0]
        .appendChild(uploadLabel)
      document
        .getElementsByClassName('uploadendorsementinternrequest')[0]
        .appendChild(uploadDiv)
      uploadDiv.appendChild(uploadSpan)
      uploadDiv.appendChild(uploadInput)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className='main_itr'>
      <div className='main_itr__inside'>
        <div className='section2__highlights1__itr'>
          <span className='section2__highlights1_span0__itr'>
            REQUEST FOR AN INTERN{' '}
          </span>
        </div>
        <hr className='section2__line__itr' size='1' />
        <div className='section2__highlights__itr'>
          <span>
            The Quezon City Internship Program was officially launched last May
            of 2022. Since then, 37 departments/offices/units have provided
            internship opportunities wherein 148 interns were deployed through
            this program. Moving forward, the Strategic Human Resource Unit is
            actively collaborating with various departments to bridge and create
            opportunities for incoming apprentices to join the government
            workforce.{' '}
            <b>
              Interested DOUs may find the attached memorandum request to
              identify internship opportunities for the year 2023 here:{' '}
            </b>
          </span>
        </div>

        <Row
          style={{ paddingTop: '16px', paddingBottom: '16px' }}
          justify='center'
        >
          <img
            style={{ maxWidth: '800px' }}
            src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/internscorner/image+7.jpg'
          />
        </Row>

        <div className='section2__highlights2__itr'>
          <span className='section2__highlights1_span0__itr'>
            OPTION 1: <span className='blue__span'>MANUAL REQUEST</span>
          </span>
        </div>
        <hr className='section2__line__itr' />
        <div className='section2__highlights__itr'>
          <span>
            For the first option, the DOU must write a formal endorsement letter
            addressed to the action officer of the Strategic Human Resource
            Unit, <b>to be duly signed by your respective DOU heads/chiefs.</b>{' '}
            Kindly refer to the memorandum request. The form must be sent to the
            SHRU email address:{' '}
            <a href='mailto:SHRU@quezoncity.gov.ph'>SHRU@quezoncity.gov.ph</a>.
            For any concerns, you may call{' '}
            <a href='tel:+63289884242'>(02)-89884242</a>.
          </span>
        </div>

        <div className='section2__highlights3__itr'>
          <span className='section2__highlights3_span0__itr'>
            OPTION 2: <span className='blue__span'>ONLINE REQUEST</span>
          </span>{' '}
        </div>

        <hr className='section2__line__itr' />
        <div className='section2__highlights__itr'>
          <span>
            For the second option, the DOU must fill out an online request form
            with the information requested in the fields below. One may create
            multiple instances for the fields enclosed in the box for requesting
            interns who have different tasks. Multiple forms submitted by the
            same DOU requesting different kinds of interns per form are not
            guaranteed to be processed at the same time.{' '}
            <b>
              The DOU must write a formal endorsement letter addressed to the
              action officer of the Strategic Human Resource Unit, to be duly
              signed by your respective DOU heads/chiefs.
            </b>{' '}
            This endorsement letter must be uploaded through the online request
            platform before the request can be processed.
          </span>
        </div>

        <Row
          style={{ paddingTop: '76px', paddingBottom: '16px' }}
          justify='center'
        >
          <Col className='internrequestForm' span={24}>
            <Form
              onRender={handleShowUpload}
              src='https://www.sparksoft-demo.com/formio/onlinerequesthrmd'
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}
