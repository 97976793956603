/**
 * Title: trainingRegistrationModal.js
 * Description: This is a file that contains the components for the training registration modal
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/trainingRegistrationModal.js
 * History:
 * - 08/07/2024 | John Bazil Valdez | Implement input validation to prevent the use of special characters and numbers in name fields,
 *   and enforce character limits to prevent excessive input.
 * - 09/10/2024 | Harry Lagunsad | Add certain texts below the Training registration forms and
 *   Post Training Evaluation/Assessment question forms
 * - 09/11/2024 | Harry Lagunsad | Add props validation
 * - 09/11/2024 | Harry Lagunsad | Fix sonarlint issues
 * - 09/26/2024 | Raymart Mojado | use the new data format on the  department participant limit
 * - 10/15/2024 | Harry Lagunsad | increased max length of middle initial from 1 to 3
 * - 10/15/2024 | Harry Lagunsad | Fix sonarlint issues
 * - 10/16/2024 | Harry Lagunsad | Added the employment type field
 **/
import React, { useEffect, useState } from 'react'

import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Steps,
  notification,
} from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

import { createTrainingRegistration } from '../../api/mutations'
import {
  Form2ByUserId,
  FormByUserId,
  IDLPUploadByUserId,
  getTrainingAnnouncement,
  listTrainingAnnouncements,
  registrationByTAID,
} from '../../api/queries'
import { numToString } from '../../utilities/numToString'
import { sendEmail } from '../../utilities/sendEmail.js'
import HtmlParser from './html-parser.js'
import PreAssessmentCustom from './preAssessmentCustom'

const { Step } = Steps
const { TextArea } = Input

/**
 *@function TrainingRegistrationModal
 *@description This component renders the training registration modal. It contains the form for
 * registering for a training item. It also contains the steps for the registration process.
 *@param {Object} trainingItem The training item to be registered for.
 *@param {Function} setAfterRegister A function that sets the after register state.
 *@param {Function} gotoForms A function that navigates to the forms page.
 *@param {Function} setRefresh A function that sets the refresh state.
 *@param {Boolean} refresh The refresh state.
 *@param {Function} gotoSecWeek A function that navigates to the second week page.
 *@param {Function} gotoThirdWeek A function that navigates to the third week page.
 *@param {Function} gotoSept8 A function that navigates to the September 8 page.
 *@param {Function} gotosept15 A function that navigates to the September 15 page.
 *@param {Function} gotoNov20 A function that navigates to the November 20 page.
 *@returns {JSX.Element} The training registration modal JSX Element.
 */

const TrainingRegistrationModal = ({
  trainingItem,
  setAfterRegister,
  gotoForms,
  setRefresh,
  refresh,
  gotoSecWeek,
  gotoThirdWeek,
  gotoSept8,
  gotosept15,
  gotoNov20,
  gotoJan21,
  userData,
  gotoSept8Batch2,
}) => {
  const currentUser = userData

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [current, setCurrent] = useState(0)

  const [isAgree, setIsAgree] = useState('no')

  const [first_name, setFirst_name] = useState(null)
  const [middle_initial, setMiddle_initial] = useState(null)
  const [last_name, setLast_name] = useState(null)
  const [full_name, setFull_name] = useState(null)
  const [email_address, setEmail_address] = useState(null)
  const [user_sex, setUser_sex] = useState(null)
  const [contact_number, setContact_number] = useState(null)
  const [user_dept, setUser_dept] = useState(null)
  const [div_section, setDiv_section] = useState(null)
  const [user_designation, setUser_designation] = useState(null)
  const [nickname, setNickname] = useState('')

  const [letterUpload, setLetterUpload] = useState(null)

  const [expected_outcome, setExpected_outcome] = useState('')

  const [disableRegister, setDisableRegister] = useState(false)

  const [limitReached, setLimitReached] = useState(false)

  const [isPastToday, setIsPastToday] = useState(false)

  const [assessQuestionsSet, setAssessQuestionsSet] = useState([])

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [hasNewPeriodForms, setHasNewPeriodForms] = useState(false)

  const [submittedEndorseLetter, setSubmittedEndorseLetter] = useState(false)
  const [reachLimitText, setReachLimitText] = useState('')

  const [childrenTrains, setChildrenTrains] = useState([])
  const [customRegistration, setCustomRegistration] = useState([])
  const [assessQuestionsSetTwo, setAssessQuestionsSetTwo] = useState([])
  const [assessQuestionsSetThree, setAssessQuestionsSetThree] = useState([])
  const [assessQuestionsSetFour, setAssessQuestionsSetFour] = useState([])

  const [expected_outcomeTwo, setExpected_outcomeTwo] = useState('')
  const [expected_outcomeThree, setExpected_outcomeThree] = useState('')
  const [expected_outcomeFour, setExpected_outcomeFour] = useState('')

  const [parentTrainingName, setParentTrainingName] = useState(null)

  const [isFinishedChecking, setIsFinishedChecking] = useState(false)

  const [isAddOpen, setIsAddOpen] = useState(false)

  const [isPreAssessmentPassed, setIsPreAssessmentPassed] = useState(true)

  const [validationError, setValidationError] = useState({})

  const [employmentType, setEmploymentType] = useState('')

  const todaysDate = new Date() // Current date

  const trainingItemDate = new Date(trainingItem.date)

  const navigate = useNavigate()

  const errorNotif = () => {
    notification['error']({
      message: 'Error',
      description: 'Something went wrong. Please contact system administrator.',
    })
  }

  let assessmentQuestions
  if (trainingItem.questionsAssessment !== null) {
    assessmentQuestions = JSON.parse(
      trainingItem.questionsAssessment.assessmentQuestionnaire
    )
  }

  /**
   * @function fetchParentTraining
   * @description This function fetches the parent training of the current training item.
   * It queries the API for the parent training item and sets the parent training name state
   * to the title of the parent training.
   * @returns {Promise<void>} A promise that resolves when the parent training is fetched.
   * @async
   */
  const fetchParentTraining = async () => {
    try {
      const getParentName = await API.graphql(
        graphqlOperation(getTrainingAnnouncement, {
          id: trainingItem.parentTrainingID,
        })
      )

      setParentTrainingName(getParentName.data.getTrainingAnnouncement.title)
    } catch (err) {
      console.log(err)
      errorNotif()
    }
  }

  const fetcher = async () => {
    try {
      let token = null
      let foundItems = []

      do {
        const result = await API.graphql({
          query: listTrainingAnnouncements,
          authMode: 'API_KEY',
          variables: {
            limit: 1000,
            nextToken: token,
          },
        })

        const { items, nextToken } = result.data.listTrainingAnnouncements
        foundItems = [...foundItems, ...items]
        token = nextToken
      } while (token)

      return foundItems
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function fetchTrainings
   * @description This function fetches all the trainings in the system. It queries the API
   * for all trainings and sets the list of trainings state to the result of the query. It also
   * sets the children trainings state to the result of the query filtered by the linkedTrainingAnnouncementIDs
   * property of the current training item.
   * @returns {Promise<void>} A promise that resolves when the trainings are fetched.
   * @async
   */
  const fetchTrainings = async () => {
    try {
      const foundItems = await fetcher()

      if (trainingItem.linkedTrainingAnnouncementIDs) {
        const childrenIDs = JSON.parse(
          trainingItem.linkedTrainingAnnouncementIDs
        )
        const filteredArray = foundItems.filter((num) =>
          childrenIDs.includes(num.id)
        )
        setChildrenTrains(filteredArray)
      }
    } catch (err) {
      console.log(err)
      errorNotif()
    }
  }

  useEffect(() => {
    fetchTrainings()

    if (trainingItem.parentTrainingID) {
      fetchParentTraining()
    }
  }, [])

  useEffect(() => {
    if (refresh) {
      checkIfRegistered()
    }
  }, [refresh])

  /**
   * @function fetcherByTAIDWithUser
   * @description This function fetches registration items for a specific training announcement.
   * It queries the API for all registration items associated with the given training announcement ID.
   * The function uses pagination to fetch all items, making multiple API calls if necessary.
   * After fetching all items, it filters the results to include only the registrations for the current user.
   * If an error occurs during the API calls, the function logs the error and returns an empty array.
   * @param {string} trainingItem.id - The ID of the training announcement to fetch registrations for.
   * @returns {Promise<Array>} A promise that resolves to an array of filtered registration items for the current user.
   * @async
   */
  /*DO NOT EDIT*/
  const fetcherByTAIDWithUser = async (currentUser) => {
    try {
      let token = null
      let allItems = []

      const queryVariables = {
        trainingAnnouncementID: trainingItem.id,
        nextToken: token,
      }

      do {
        const result = await API.graphql(
          graphqlOperation(registrationByTAID, queryVariables)
        )

        const { items, nextToken } = result.data.registrationByTAID
        allItems = [...allItems, ...items]
        token = nextToken
        queryVariables.nextToken = token
      } while (token)

      let filteredAllItems = []

      if (allItems.length) {
        filteredAllItems = allItems.filter(
          (item) => item.userID === currentUser.id
        )
        return filteredAllItems
      } else {
        return []
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function fetcherByTAID
   * @description This function fetches registration items for a specific training announcement.
   * It queries the API for all registration items associated with the given training announcement ID.
   * The function uses pagination to fetch all items, making multiple API calls if necessary.
   * After fetching all items, it filters the results to include only the registrations for the current user.
   * If an error occurs during the API calls, the function logs the error and returns an empty array.
   * @param {string} trainingItem.id - The ID of the training announcement to fetch registrations for.
   * @returns {Promise<Array>} A promise that resolves to an array of filtered registration items for the current user.
   * @async
   */
  /*DO NOT EDIT*/
  const fetcherByTAID = async (currentUser) => {
    try {
      let token = null
      let allItems = []

      const queryVariables = {
        trainingAnnouncementID: trainingItem.id,
        nextToken: token,
      }

      do {
        const result = await API.graphql(
          graphqlOperation(registrationByTAID, queryVariables)
        )

        const { items, nextToken } = result.data.registrationByTAID
        allItems = [...allItems, ...items]
        token = nextToken
        queryVariables.nextToken = token
      } while (token)

      return allItems
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function checkIfRegistered
   * @description This function checks if the current user is already registered for the current training item.
   * It queries the API for the current user's registration for the current training item. If the user is already
   * registered, it sets the disable register state to true and sets the isFinishedChecking state to true.
   * If the user is not registered, it queries the API for all registrations for the current training item. It then
   * checks if the number of registrations is equal to or greater than the participant limit for the training item.
   * If the limit has been reached, it sets the limit reached state to true and sets the reach limit text state to
   * the appropriate message. If the limit has not been reached, it sets the disable register state to false and sets
   * the limit reached state to false. If an error occurs while querying the API, the function logs the error to the
   * console.
   * @returns
   * @async
   */
  const checkIfRegistered = async () => {
    try {
      if (currentUser) {
        const foundItems = await fetcherByTAIDWithUser(currentUser)

        if (foundItems.length) {
          setDisableRegister(true)
          setIsFinishedChecking(true)

          if (foundItems[0].isPreAssessmentPassed === false) {
            setIsPreAssessmentPassed(false)
          } else {
            setIsPreAssessmentPassed(true)
          }

          if (foundItems[0].endorsementLetter) {
            setSubmittedEndorseLetter(true)
          }
        } else {
          const participantLimit = trainingItem?.participantLimit || 40

          const foundItems = await fetcherByTAID()

          if (trainingItem.limitPerDept !== null) {
            const userDept = currentUser.assignedDept
            const usersRegistered = foundItems.filter((item) => {
              if (item.isPreAssessmentPassed !== false) {
                return item
              }
            })
            const registeredUsersInDept = usersRegistered.filter(
              (user) => user.dept_office_unit_taskForce == userDept
            )
            const registeredCount = registeredUsersInDept.length

            const parsedLimitPerDept = JSON.parse(trainingItem.limitPerDept)
            // Check if allDepartments is present

            let checkLimit
            if (
              Array.isArray(parsedLimitPerDept) &&
              parsedLimitPerDept.length > 0
            ) {
              const firstItem = parsedLimitPerDept[0]
              if (firstItem.allDepartments) {
                const departmentsLimitValue = firstItem.allDepartments
                checkLimit = departmentsLimitValue[userDept]
              } else if (Array.isArray(firstItem) && firstItem.length > 0) {
                const secondDataStructure = firstItem[0]
                checkLimit = secondDataStructure[userDept]
              } else {
                checkLimit = firstItem[userDept]
              }
            } else {
              console.error('Unexpected format in limitPerDept')
              // Handle default case or throw an error
            }

            setIsFinishedChecking(true)

            if (registeredCount >= checkLimit) {
              setLimitReached(true)
              setReachLimitText(
                'Training event already reached max limit of your department.'
              )
            } else if (usersRegistered.length >= participantLimit) {
              setLimitReached(true)
              setReachLimitText(
                'Training event already reached max limit of attendees.'
              )
            } else {
              setDisableRegister(false)
              setLimitReached(false)
            }
          } else {
            setIsFinishedChecking(true)

            if (foundItems.length >= participantLimit) {
              setLimitReached(true)
            } else {
              setDisableRegister(false)
              setLimitReached(false)
            }
          }
        }
      }
    } catch (error) {
      console.log(error)
      errorNotif()
    }
  }

  useEffect(() => {
    // This will initialize the assessment dynamic variables where we gonna contain the asnwer on the assessment
    // questions depends on the length of the assestmentQuestion.
    let assessQuestions = {}
    if (assessmentQuestions) {
      for (let i = 0; i < assessmentQuestions.length; i++) {
        let numString = numToString(i)

        let question = 'question' + numString
        assessQuestions[question] = null
      }
      setAssessQuestionsSet(assessQuestions)
    }
  }, [])

  /**
   * Provides a threshold date string based on the current date.
   *
   * @returns {string} A string representing the threshold date in the format "Month Day, Year HH:MM:SS AM/PM".
   *                   If the current date is before July 1 of the current year, it returns "June 1, 2023 12:00:00 AM".
   *                   Otherwise, it returns "June 1, [current year] 12:00:00 AM".
   *
   * @throws {Error} Logs any error that occurs during execution to the console.
   *
   * @example
   * // If current date is May 15, 2024
   * const threshold = thresholdProvider();
   * console.log(threshold); // Output: "June 1, 2023 12:00:00 AM"
   *
   * // If current date is August 1, 2024
   * const threshold = thresholdProvider();
   * console.log(threshold); // Output: "June 1, 2024 12:00:00 AM"
   */
  const thresholdProvider = () => {
    try {
      // Get the current date
      let currentDate = new Date()

      // Set the threshold date to July 1, current year
      let thresholdDate = new Date(currentDate.getFullYear(), 6, 1) // Note: Month is zero-indexed (0 = January, 6 = July)

      // Declare a variable to store the threshold string
      let thresholdString

      // Compare the current date with the threshold date
      if (currentDate < thresholdDate) {
        // Current date is before the threshold
        thresholdString = 'July 1, 2023 12:00:00 AM'

        return thresholdString
      } else {
        // Current date is on or after the threshold
        let currentYear = currentDate.getFullYear()
        thresholdString = 'July 1, ' + currentYear + ' 12:00:00 AM'

        return thresholdString
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function checkIfILDPUploadIsNewPeriod
   * @description This function checks if the current user has any ILDP uploads in the current period.
   * It queries the API for all ILDP uploads by the current user. It then filters the uploads based on
   * specific criteria and determines if there are any new period uploads. If there are new period uploads,
   * it sets the has new period forms state to true and calls the checkIfRegistered function. If there are
   * no uploads or no new period uploads, it sets the has new period forms state to false and sets the
   * is finished checking state to true. If an error occurs while querying the API, the function logs the
   * error to the console and shows an error notification.
   * @returns {Promise<void>} A promise that resolves when the check is complete.
   * @async
   */
  const checkIfILDPUploadIsNewPeriod = async () => {
    try {
      let token = null
      let foundItems = []

      do {
        const result = await API.graphql(
          graphqlOperation(IDLPUploadByUserId, {
            userID: currentUser.id,
            limit: 1000,
            nextToken: token,
          })
        )

        const { items, nextToken } = result.data.IDLPUploadByUserId
        foundItems = [...foundItems, ...items]
        token = nextToken
      } while (token)

      if (foundItems.length) {
        const foundIDLP = foundItems.filter((item) => {
          const finalSubmitDate = moment(new Date(item.updatedAt))
          const finalSubmitDateCheckAgain = moment(new Date(item.etnaId))
          const currentPeriodDate = thresholdProvider()

          if (
            finalSubmitDate.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            ) ||
            finalSubmitDateCheckAgain.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            )
          ) {
            if (item.isApproved == 1) {
              return item
            }
          }
        })

        if (foundIDLP.length) {
          checkIfRegistered()
          setHasNewPeriodForms(true)
        } else if (trainingItem.id === '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04') {
          const foundItems = await fetcherByTAIDWithUser(currentUser)

          if (foundItems.length) {
            checkIfRegistered()
            setHasNewPeriodForms(true)
          } else {
            setHasNewPeriodForms(false)
            setIsFinishedChecking(true)
          }
        } else {
          setHasNewPeriodForms(false)
          setIsFinishedChecking(true)
        }
      } else {
        setHasNewPeriodForms(false)
        setIsFinishedChecking(true)
      }
    } catch (err) {
      console.log(err)

      if (currentUser) {
        notification['error']({
          message: 'Error',
          duration: 500000,
          description:
            'Failed to get your list of uploaded ILDP forms. You may need to logout and login back to the website to try again. If this error remains, please report to system administrator.',
        })
      }
    }
  }

  /**
   * @function checkIfILDPFormIsNewPeriod
   * @description This function checks if the current user has any ILDP forms in the current period.
   * It queries the API for all ILDP forms by the current user. It then filters the forms based on
   * specific criteria and determines if there are any new period forms. If there are new period forms,
   * it calls the checkIfILDPUploadIsNewPeriod function. If there are no forms or no new period forms,
   * it sets the has new period forms state to false and sets the is finished checking state to true.
   * If an error occurs while querying the API, the function logs the error to the console and shows
   * an error notification.
   * @returns {Promise<void>} A promise that resolves when the check is complete.
   * @async
   */
  const checkIfILDPFormIsNewPeriod = async () => {
    try {
      let token = null
      let foundItems = []

      do {
        const result = await API.graphql(
          graphqlOperation(Form2ByUserId, {
            userID: currentUser.id,
            limit: 1000,
            nextToken: token,
          })
        )

        const { items, nextToken } = result.data.Form2ByUserId
        foundItems = [...foundItems, ...items]
        token = nextToken
      } while (token)

      if (foundItems.length) {
        const foundForm2 = foundItems.filter((item) => {
          const finalSubmitDate = moment(new Date(item.updatedAt))
          const finalSubmitDateCheckAgain = moment(new Date(item.etnaId))
          const currentPeriodDate = thresholdProvider()

          if (
            finalSubmitDate.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            ) ||
            finalSubmitDateCheckAgain.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            )
          ) {
            if (item.isApproved == 1 || item.isApproved == '1') {
              return item
            }
          }
        })

        if (foundForm2.length) {
          checkIfILDPUploadIsNewPeriod()
        } else if (trainingItem.id === '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04') {
          const foundItems = await fetcherByTAIDWithUser(currentUser)

          if (foundItems.length) {
            checkIfRegistered()
            setHasNewPeriodForms(true)
          } else {
            setHasNewPeriodForms(false)
            setIsFinishedChecking(true)
          }
        } else {
          setHasNewPeriodForms(false)
          setIsFinishedChecking(true)
        }
      } else {
        setHasNewPeriodForms(false)
        setIsFinishedChecking(true)
      }
    } catch (err) {
      console.log(err)

      if (currentUser) {
        notification['error']({
          message: 'Error',
          duration: 500000,
          description:
            'Failed to get your list of ILDP forms answered. You may need to logout and login back to the website to try again. If this error remains, please report to system administrator.',
        })
      }
    }
  }

  const fetchForms = async () => {
    try {
      let token = null
      let foundItems = []

      do {
        const result = await API.graphql(
          graphqlOperation(FormByUserId, {
            userID: currentUser.id,
            limit: 1000,
            nextToken: token,
          })
        )

        const { items, nextToken } = result.data.FormByUserId
        foundItems = [...foundItems, ...items]
        token = nextToken
      } while (token)

      return foundItems // Return the array of items
    } catch (err) {
      console.log(err)
      return [] // Return an empty array in case of an error
    }
  }

  /**
   *@function checkIfEtnaIsNewPeriod
   *@description This function checks if the current user has any eTNA forms in the current period.
   * It queries the API for all eTNA forms by the current user. It then filters the forms based on
   * specific criteria and determines if there are any new period forms. If there are new period forms,
   * it calls the checkIfILDPFormIsNewPeriod function. If there are no forms or no new period forms,
   * it sets the has new period forms state to false and sets the is finished checking state to true.
   * If an error occurs while querying the API, the function logs the error to the console and shows
   * an error notification.
   *@async
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   **/
  const checkIfEtnaIsNewPeriod = async () => {
    try {
      let foundItems = await fetchForms()

      if (foundItems.length) {
        const foundForm1 = foundItems.filter((item) => {
          const finalSubmitDate = moment(new Date(item.finalSubmitDate))
          const finalSubmitDateCheckAgain = moment(new Date(item.updatedAt))
          const currentPeriodDate = thresholdProvider()

          if (
            finalSubmitDate.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            ) ||
            finalSubmitDateCheckAgain.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            )
          ) {
            if (item.isFinal === 'YES') {
              return item
            }
          }
        })

        if (foundForm1.length) {
          checkIfILDPFormIsNewPeriod()
        } else if (trainingItem.id === '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04') {
          const searchedItems = await fetcherByTAIDWithUser(currentUser)

          if (searchedItems.length) {
            checkIfRegistered()
            setHasNewPeriodForms(true)
          } else {
            setHasNewPeriodForms(false)
            setIsFinishedChecking(true)
          }
        } else {
          setHasNewPeriodForms(false)
          setIsFinishedChecking(true)
        }
      } else if (trainingItem.id === '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04') {
        const foundItems = await fetcherByTAIDWithUser(currentUser)

        if (foundItems.length) {
          checkIfRegistered()
          setHasNewPeriodForms(true)
        } else {
          setHasNewPeriodForms(false)
          setIsFinishedChecking(true)
        }
      } else {
        setHasNewPeriodForms(false)
        setIsFinishedChecking(true)
      }
    } catch (err) {
      console.log(err)

      if (currentUser) {
        // this could show multiple times as many as active trainings
        notification['error']({
          message: 'Error',
          duration: 500000,
          description:
            'Failed to get your list of eTNA. You may need to logout and login back to the website to try again. If this error remains, please report to system administrator.',
        })
      }
    }
  }

  /**
   * Checks if the current system date is within a specified date range and performs actions accordingly.
   *
   * @function handleCheckIfTrainingIsBeforePeriod
   * @async
   *
   * @description
   * This function checks if the current system date falls within the range of July 1, 2024 to July 31, 2024.
   * If it does, it sets a state variable and checks if the user is registered.
   * If not, it checks if ETNA is in a new period.
   *
   * @throws {Error} Logs any error to the console and triggers an error notification.
   *
   *
   * @requires moment
   * @requires setHasNewPeriodForms
   * @requires checkIfRegistered
   * @requires checkIfEtnaIsNewPeriod
   * @requires errorNotif
   */
  const handleCheckIfTrainingIsBeforePeriod = () => {
    try {
      const customDateStart = 'July 1, 2024 12:00:00 AM'
      const customDateEnd = 'July 31, 2024 11:59:59 PM'

      const currentSystemDate = moment().format('MMMM D, YYYY h:mm:ss A')

      const isWithinRange = moment(currentSystemDate).isBetween(
        customDateStart,
        customDateEnd
      )

      if (isWithinRange) {
        setHasNewPeriodForms(true)
        checkIfRegistered()
      } else {
        checkIfEtnaIsNewPeriod()
      }
    } catch (err) {
      console.log(err)

      errorNotif()
    }
  }

  useEffect(() => {
    if (currentUser) {
      handleCheckIfTrainingIsBeforePeriod()
    } else {
      setIsFinishedChecking(true)
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      setFull_name(
        `${currentUser.name} ${currentUser.mname ? currentUser.mname : ''} ${
          currentUser.lname
        }`
      )
      setFirst_name(currentUser.name)
      /*  setMiddle_initial(currentUser.name.charAt(0) + '.') */
      setLast_name(currentUser.lname)
      setEmail_address(currentUser.username)
      setUser_dept(currentUser.assignedDept)
      setDiv_section(currentUser.division)
      setContact_number(currentUser.contact)
      setUser_designation(currentUser.position)
      setUser_sex(currentUser.gender)
    }
  })

  const handleRadio = (e) => {
    setIsAgree(e.target.value)
  }

  const handleMiddleInitial = (e) => {
    // Regex pattern for middle initial, accepts letters only
    const regex = /^(N\/A|[a-zA-ZñéáíóúÑÉÁÍÓÚ.]+)$/

    const value = e.target.value
    const maxLength = e.target.maxLength

    //Input a validation for middle initial using the namePattern

    if (!regex.test(value) && value !== '') {
      // Invalid input detected
      setMiddle_initial(value)
      setValidationError((prev) => ({
        ...prev,
        middleInitial: 'Enter a valid middle initial or N/A',
      }))
    } else if (value.length > maxLength) {
      // Input exceeds max length
      setMiddle_initial(value)
      setValidationError((prev) => ({
        ...prev,
        middleInitial: `Maximum ${maxLength} characters allowed`,
      }))
    } else {
      // Input is valid, update state
      setMiddle_initial(value)
      setValidationError((prev) => ({
        ...prev,
        middleInitial: '', // Clear validation error if valid
      }))
    }
  }

  const handleEmploymentType = (e) => {
    setEmploymentType(e)
  }

  const handleNickname = (e) => {
    // Regex pattern for nickname, accepts letters, spaces, hyphens, and apostrophes
    const regex = /^(?:[a-zA-ZñéáíóúÑÉÁÍÓÚ.\s-']+)$/

    const value = e.target.value
    const maxLength = e.target.maxLength

    if (!regex.test(value) && value !== '') {
      // Invalid input detected
      setNickname(value)
      setValidationError((prev) => ({
        ...prev,
        nickname: 'Enter a valid nickname',
      }))
    } else if (value.length > maxLength) {
      // Input exceeds max length
      setNickname(value)
      setValidationError((prev) => ({
        ...prev,
        nickname: `Maximum ${maxLength} characters allowed`,
      }))
    } else {
      // Input is valid, update state
      setNickname(value)
      setValidationError((prev) => ({
        ...prev,
        nickname: '', // Clear validation error if valid
      }))
    }
  }

  const handleEmailAddress = (e) => {
    setEmail_address(e.target.value)
  }

  const handleSexRadio = (e) => {
    setUser_sex(e.target.value)
  }

  const handleContactNumber = (e) => {
    setContact_number(e.target.value)
  }

  const handleDepartment = (e) => {
    setUser_dept(e.target.value)
  }

  const handleDivisionSection = (e) => {
    setDiv_section(e.target.value)
  }

  const handleDesignation = (e) => {
    setUser_designation(e.target.value)
  }

  const handleExpectedOutcome = (e) => {
    setExpected_outcome(e.target.value)
  }

  const handleExpectedOutcomeTwo = (e) => {
    setExpected_outcomeTwo(e.target.value)
  }

  const handleExpectedOutcomeThree = (e) => {
    setExpected_outcomeThree(e.target.value)
  }

  const handleExpectedOutcomeFour = (e) => {
    setExpected_outcomeFour(e.target.value)
  }

  /**
   *@function handleNextButton
   *@description This function checks if the current step is valid. If the current step is valid,
   * it increments the current state by 1. If the current step is not valid, it returns false.
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   */
  const handleNextButton = () => {
    switch (current) {
      case 0:
        return isAgree == 'no'

      case 1:
        return !(
          middle_initial &&
          email_address &&
          user_sex &&
          contact_number &&
          user_dept &&
          div_section &&
          user_designation &&
          nickname &&
          employmentType?.length &&
          !validationError.middleInitial &&
          !validationError.nickname
        )

      case 2:
        if (
          trainingItem.isEndorsementRequired == 'yes' ||
          trainingItem.id === '54c26b0c-babb-4b3b-b525-e1beba28d23b'
        ) {
          return !letterUpload
        } else {
          return current != 2
        }
    }
  }

  /**
   *@function handleNextButtonWithAssessment
   *@description This function checks if the current step is valid. If the current step is valid,
   * it increments the current state by 1. If the current step is not valid, it returns false.
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   */
  const handleNextButtonWithAssessment = () => {
    switch (current) {
      case 0:
        return isAgree === 'no'

      case 1:
        return !(
          middle_initial &&
          email_address &&
          user_sex &&
          contact_number &&
          user_dept &&
          div_section &&
          user_designation &&
          nickname &&
          employmentType?.length &&
          !validationError.middleInitial &&
          !validationError.nickname
        )

      case 2:
        if (
          trainingItem.isEndorsementRequired == 'yes' ||
          trainingItem.id === '54c26b0c-babb-4b3b-b525-e1beba28d23b'
        ) {
          return !letterUpload
        } else {
          return current != 2
        }

      case 3:
        if (assessQuestionsSet) {
          let holdAnswers = []
          for (let key in assessQuestionsSet) {
            if (assessQuestionsSet[key] === null) {
              holdAnswers.push('')
            }
          }
          return holdAnswers.length >= 1
        }

        break
      case 4:
        if (trainingItem.linkedTrainingAnnouncementIDs) {
          let holdAnswers = []
          if (assessQuestionsSetTwo) {
            for (let key in assessQuestionsSetTwo) {
              if (assessQuestionsSetTwo[key] === null) {
                holdAnswers.push('')
              }
            }
          }

          return holdAnswers.length >= 1
        }

        break
      case 5:
        if (trainingItem.linkedTrainingAnnouncementIDs) {
          let holdAnswers = []
          if (assessQuestionsSetThree) {
            for (let key in assessQuestionsSetThree) {
              if (assessQuestionsSetThree[key] === null) {
                holdAnswers.push('')
              }
            }
          }

          return holdAnswers.length >= 1
        }

        break
      case 6:
        if (trainingItem.linkedTrainingAnnouncementIDs) {
          let holdAnswers = []
          if (assessQuestionsSetFour) {
            for (let key in assessQuestionsSetFour) {
              if (assessQuestionsSetFour[key] === null) {
                holdAnswers.push('')
              }
            }
          }

          return holdAnswers.length >= 1
        }

        break
    }
  }

  /**
   *@function checkIfLimitReached
   *@description This function checks if the current training item has reached its participant limit.
   * It queries the API for all registrations for the current training item. It then checks if the number
   * of registrations is equal to or greater than the participant limit for the training item. If the limit
   * has been reached, it sets the limit reached state to true and sets the reach limit text state to the
   * appropriate message. If the limit has not been reached, it sets the disable register state to false and
   * sets the limit reached state to false. If an error occurs while querying the API, the function logs the
   * error to the console and shows an error notification.
   *@async
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   **/
  const checkIfLimitReached = async (a) => {
    setIsSubmitted(true)
    try {
      const foundItems = await fetcherByTAID()

      // Determine the participant limit for the training event
      const participantLimit = trainingItem.participantLimit || 40

      if (trainingItem.limitPerDept !== null) {
        const userDept = currentUser.assignedDept
        const usersRegistered = foundItems.filter((item) => {
          if (item.isPreAssessmentPassed !== false) {
            return item
          }
        })
        const registeredUsersInDept = usersRegistered.filter(
          (user) => user.dept_office_unit_taskForce == userDept
        )
        const registeredCount = registeredUsersInDept.length
        const departmentLimit = JSON.parse(trainingItem.limitPerDept)[0][
          userDept
        ]

        setIsFinishedChecking(true)

        if (registeredCount >= departmentLimit) {
          setLimitReached(true)
          setReachLimitText(
            'Training event already reached max limit of your department.'
          )

          notification['info']({
            message: 'Message',
            description:
              'Sorry, the training event you are attempting to register for has already reached its maximum participant limit for your department.',
          })

          setIsModalOpen(false)
          handleCheckIfTrainingIsBeforePeriod()
        } else if (usersRegistered.length >= participantLimit) {
          setLimitReached(true)
          setReachLimitText(
            'Training event already reached max limit of attendees.'
          )

          notification['info']({
            message: 'Message',
            description:
              'Sorry, the training event you are trying to register for has already reached its maximum number of participants',
          })

          setIsModalOpen(false)
          handleCheckIfTrainingIsBeforePeriod()
        } else {
          setDisableRegister(false)
          setLimitReached(false)

          if (a === 'without') {
            handleRegistration()
          }
        }
      }
    } catch (err) {
      // If an error occurs while querying the API, log the error to the console and return `false`
      console.log(err)
      notification['error']({
        message: 'Error',
        description:
          'An unexpected error occurred. Please contact your administrator for assistance.',
      })
    }
  }

  /**
   *@function handleRegistration
   *@description This function handles the registration of the current user for the current training item
   * and if there's a letterUpload (Endorsement Letter), it will upload the letter to S3 and store the URL to the database.
   *@async
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   **/
  const handleRegistration = async () => {
    try {
      const linkedTrainings = JSON.parse(
        trainingItem.linkedTrainingAnnouncementIDs
      )

      if (expected_outcome.length >= 1) {
        if (trainingItem.isOpenRegistration === true) {
          try {
            const credentials = await Auth.currentCredentials()
            const accessKeyId = credentials.accessKeyId
            const secretAccessKey = credentials.secretAccessKey
            const sessionToken = credentials.sessionToken

            const s3 = new S3Client({
              region: 'ap-southeast-1',
              credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey,
                sessionToken: sessionToken,
              },
            })

            const questionnaireValue = assessQuestionsSet

            if (letterUpload) {
              if (letterUpload.length >= 2) {
                async function uploadFiles(files) {
                  const promises = files.map(async (file) => {
                    const params = {
                      Bucket: 's3etnahris133956-dev',
                      Key: `${currentUser.id}/${moment().valueOf()}-${
                        file.name
                      }`,
                      Body: file,
                      ACL: 'public-read',
                    }

                    const command = new PutObjectCommand(params)
                    await s3.send(command)
                    const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`
                    return url
                  })

                  return Promise.all(promises)
                }
                const uploadsDone = await uploadFiles(letterUpload)
                if (uploadsDone) {
                  const adminUpload = await API.graphql(
                    graphqlOperation(createTrainingRegistration, {
                      input: {
                        userID: currentUser.id,
                        trainingAnnouncementID: trainingItem.id,
                        isAgreeDataPrivacyConsent:
                          isAgree == 'yes' || isAgree === true,
                        fullName: full_name,
                        first_name: first_name,
                        middle_initial: middle_initial,
                        last_name: last_name,
                        emailAddress: email_address,
                        gender: user_sex,
                        contactNumber: contact_number,
                        dept_office_unit_taskForce: user_dept,
                        division_section: div_section,
                        designation_position: user_designation,
                        endorsementLetter: JSON.stringify(uploadsDone),
                        questionnaire: JSON.stringify(questionnaireValue),
                        expectedOutcomes: expected_outcome,
                        isRegistered: true,
                        isSubmitEndorsementLetter: false,
                        isAttended: false,
                        isPostEvalDone: false,
                        isCourseOutputDone: false,
                        additionalRemarks: '',
                        nickname,
                        employmentType,
                      },
                    })
                  )
                  if (adminUpload) {
                    if (linkedTrainings?.length > 0) {
                      const assessQuestionsSets = [
                        assessQuestionsSetTwo,
                        assessQuestionsSetThree,
                        assessQuestionsSetFour,
                      ]
                      const expectedOutcomesList = [
                        expected_outcomeTwo,
                        expected_outcomeThree,
                        expected_outcomeFour,
                      ]
                      const promisesChildTrains = linkedTrainings.map(
                        async (id, count) => {
                          const adminUploadNext = API.graphql(
                            graphqlOperation(createTrainingRegistration, {
                              input: {
                                userID: currentUser.id,
                                trainingAnnouncementID: id,
                                isAgreeDataPrivacyConsent:
                                  isAgree == 'yes' || isAgree === true,
                                fullName: full_name,
                                first_name: first_name,
                                middle_initial: middle_initial,
                                last_name: last_name,
                                emailAddress: email_address,
                                gender: user_sex,
                                contactNumber: contact_number,
                                dept_office_unit_taskForce: user_dept,
                                division_section: div_section,
                                designation_position: user_designation,
                                endorsementLetter: JSON.stringify(uploadsDone),
                                questionnaire: JSON.stringify(
                                  assessQuestionsSets[count]
                                ),
                                expectedOutcomes: expectedOutcomesList[count],
                                isRegistered: true,
                                isSubmitEndorsementLetter: false,
                                isAttended: false,
                                isPostEvalDone: false,
                                isCourseOutputDone: false,
                                additionalRemarks: '',
                                nickname,
                                employmentType,
                              },
                            })
                          )
                          return adminUploadNext
                        }
                      )

                      const finishedChildTrainsRegistrations =
                        await Promise.all(promisesChildTrains)

                      if (finishedChildTrainsRegistrations) {
                        notification['success']({
                          message: 'Success',
                          description:
                            "You're registered now to the Training/Event. You're also registered with other training's that is linked in this training ",
                        })

                        setAfterRegister(finishedChildTrainsRegistrations)
                        setRefresh(finishedChildTrainsRegistrations)
                        setIsAgree('no')
                        setFull_name(null)
                        setFirst_name(null)
                        setMiddle_initial(null)
                        setLast_name(null)
                        setEmail_address(null)
                        setUser_sex(null)
                        setContact_number(null)
                        setUser_dept(null)
                        setDiv_section(null)
                        setUser_designation(null)
                        setLetterUpload(null)

                        setExpected_outcome('')
                        setCurrent(0)
                        setNickname('')
                        setEmploymentType('')
                        setDisableRegister(true)
                        handleCheckIfTrainingIsBeforePeriod()
                        setTimeout(() => {
                          setIsModalOpen(false)
                        }, 3000)
                      }
                    } else {
                      notification['success']({
                        message: 'Success',
                        description:
                          "You're registered now to the Training/Event.",
                      })

                      setAfterRegister(adminUpload)

                      setIsAgree('no')
                      setFull_name(null)
                      setFirst_name(null)
                      setMiddle_initial(null)
                      setLast_name(null)
                      setEmail_address(null)
                      setUser_sex(null)
                      setContact_number(null)
                      setUser_dept(null)
                      setDiv_section(null)
                      setUser_designation(null)
                      setLetterUpload(null)

                      setExpected_outcome('')
                      setCurrent(0)
                      setNickname('')
                      setEmploymentType('')
                      setDisableRegister(true)
                      handleCheckIfTrainingIsBeforePeriod()

                      setTimeout(() => {
                        setIsModalOpen(false)
                      }, 3000)
                    }
                  }
                }
              } else {
                const params = {
                  Bucket: 's3etnahris133956-dev',
                  Key: `${currentUser.id}/${moment().valueOf()}-${
                    letterUpload?.name
                      ? letterUpload.name
                      : letterUpload[0].name
                  }`,
                  Body: letterUpload,
                  ACL: 'public-read',
                }
                const command = new PutObjectCommand(params)
                await s3.send(command)
                const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

                const adminUpload = await API.graphql(
                  graphqlOperation(createTrainingRegistration, {
                    input: {
                      userID: currentUser.id,
                      trainingAnnouncementID: trainingItem.id,
                      isAgreeDataPrivacyConsent:
                        isAgree == 'yes' || isAgree === true,
                      fullName: full_name,
                      first_name: first_name,
                      middle_initial: middle_initial,
                      last_name: last_name,
                      emailAddress: email_address,
                      gender: user_sex,
                      contactNumber: contact_number,
                      dept_office_unit_taskForce: user_dept,
                      division_section: div_section,
                      designation_position: user_designation,
                      endorsementLetter: url,
                      questionnaire: JSON.stringify(questionnaireValue),
                      expectedOutcomes: expected_outcome,
                      isRegistered: true,
                      isSubmitEndorsementLetter: false,
                      isAttended: false,
                      isPostEvalDone: false,
                      isCourseOutputDone: false,
                      additionalRemarks: '',
                      nickname,
                      employmentType,
                    },
                  })
                )
                if (adminUpload) {
                  if (
                    trainingItem.id === '2eeae940-ce21-4219-983f-a616f6751245'
                  ) {
                    const adminUploadNext = await API.graphql(
                      graphqlOperation(createTrainingRegistration, {
                        input: {
                          userID: currentUser.id,
                          trainingAnnouncementID:
                            'e817dbf4-9dda-4c94-b851-1a99cdea9e4a',
                          isAgreeDataPrivacyConsent:
                            isAgree == 'yes' || isAgree === true,
                          fullName: full_name,
                          first_name: first_name,
                          middle_initial: middle_initial,
                          last_name: last_name,
                          emailAddress: email_address,
                          gender: user_sex,
                          contactNumber: contact_number,
                          dept_office_unit_taskForce: user_dept,
                          division_section: div_section,
                          designation_position: user_designation,
                          endorsementLetter: url,
                          questionnaire: JSON.stringify(questionnaireValue),
                          expectedOutcomes: expected_outcome,
                          isRegistered: true,
                          isSubmitEndorsementLetter: false,
                          isAttended: false,
                          isPostEvalDone: false,
                          isCourseOutputDone: false,
                          additionalRemarks: '',
                          nickname,
                          employmentType,
                        },
                      })
                    )

                    if (adminUploadNext) {
                      notification['success']({
                        message: 'Success',
                        description:
                          "You're registered now to the Training/Event. You're also registered now to training: 'Filipino Sign Language Training'",
                      })

                      setAfterRegister(adminUploadNext)
                      setRefresh(adminUploadNext)
                      setIsAgree('no')
                      setFull_name(null)
                      setFirst_name(null)
                      setMiddle_initial(null)
                      setLast_name(null)
                      setEmail_address(null)
                      setUser_sex(null)
                      setContact_number(null)
                      setUser_dept(null)
                      setDiv_section(null)
                      setUser_designation(null)
                      setLetterUpload(null)

                      setExpected_outcome('')
                      setCurrent(0)
                      setNickname('')
                      setEmploymentType('')
                      setDisableRegister(true)
                      handleCheckIfTrainingIsBeforePeriod()
                      setTimeout(() => {
                        setIsModalOpen(false)
                      }, 3000)
                    }
                  } else if (linkedTrainings?.length > 0) {
                    const assessQuestionsSets = [
                      assessQuestionsSetTwo,
                      assessQuestionsSetThree,
                      assessQuestionsSetFour,
                    ]
                    const expectedOutcomesList = [
                      expected_outcomeTwo,
                      expected_outcomeThree,
                      expected_outcomeFour,
                    ]
                    const promisesChildTrains = linkedTrainings.map(
                      async (id, count) => {
                        const adminUploadNext = API.graphql(
                          graphqlOperation(createTrainingRegistration, {
                            input: {
                              userID: currentUser.id,
                              trainingAnnouncementID: id,
                              isAgreeDataPrivacyConsent:
                                isAgree == 'yes' || isAgree === true,
                              fullName: full_name,
                              first_name: first_name,
                              middle_initial: middle_initial,
                              last_name: last_name,
                              emailAddress: email_address,
                              gender: user_sex,
                              contactNumber: contact_number,
                              dept_office_unit_taskForce: user_dept,
                              division_section: div_section,
                              designation_position: user_designation,
                              endorsementLetter: url,
                              questionnaire: JSON.stringify(
                                assessQuestionsSets[count]
                              ),
                              expectedOutcomes: expectedOutcomesList[count],
                              isRegistered: true,
                              isSubmitEndorsementLetter: false,
                              isAttended: false,
                              isPostEvalDone: false,
                              isCourseOutputDone: false,
                              additionalRemarks: '',
                              nickname,
                              employmentType,
                            },
                          })
                        )
                        return adminUploadNext
                      }
                    )

                    const finishedChildTrainsRegistrations =
                      await Promise.all(promisesChildTrains)

                    if (finishedChildTrainsRegistrations) {
                      notification['success']({
                        message: 'Success',
                        description:
                          "You're registered now to the Training/Event. You're also registered with other training's that is linked in this training ",
                      })

                      setAfterRegister(finishedChildTrainsRegistrations)
                      setRefresh(finishedChildTrainsRegistrations)
                      setIsAgree('no')
                      setFull_name(null)
                      setFirst_name(null)
                      setMiddle_initial(null)
                      setLast_name(null)
                      setEmail_address(null)
                      setUser_sex(null)
                      setContact_number(null)
                      setUser_dept(null)
                      setDiv_section(null)
                      setUser_designation(null)
                      setLetterUpload(null)

                      setExpected_outcome('')
                      setCurrent(0)
                      setNickname('')
                      setEmploymentType('')
                      setDisableRegister(true)
                      handleCheckIfTrainingIsBeforePeriod()
                      setTimeout(() => {
                        setIsModalOpen(false)
                      }, 3000)
                    }
                  } else {
                    notification['success']({
                      message: 'Success',
                      description:
                        "You're registered now to the Training/Event.",
                    })

                    setAfterRegister(adminUpload)

                    setIsAgree('no')
                    setFull_name(null)
                    setFirst_name(null)
                    setMiddle_initial(null)
                    setLast_name(null)
                    setEmail_address(null)
                    setUser_sex(null)
                    setContact_number(null)
                    setUser_dept(null)
                    setDiv_section(null)
                    setUser_designation(null)
                    setLetterUpload(null)

                    setExpected_outcome('')
                    setCurrent(0)
                    setNickname('')
                    setEmploymentType('')
                    setDisableRegister(true)
                    handleCheckIfTrainingIsBeforePeriod()

                    setTimeout(() => {
                      setIsModalOpen(false)
                    }, 3000)
                  }
                }
              }

              //For sending email to notifify the admin that user are registered with uploaded endorsement letter
              const emailSubject = `Uploaded Endorsement Letter for the ${trainingItem.title} training`
              const emailBody = `Hi SHRU,

Below are the details of an Endorsement Letter uploaded today.
Name of Registrant: ${currentUser.name} ${currentUser.lname}
Training Title: ${trainingItem.title} with an uploaded endorsement letter. 
Training date: ${trainingItem.date} ${
                JSON.parse(trainingItem.arrayOfDates).length > 1
                  ? `- ${
                      JSON.parse(trainingItem.arrayOfDates)[
                        JSON.parse(trainingItem.arrayOfDates).length - 1
                      ]
                    }`
                  : ''
              }
            
Regards, 
HRM Platform Administrator`
              sendEmail(emailSubject, emailBody)
            } else {
              const adminUpload = await API.graphql(
                graphqlOperation(createTrainingRegistration, {
                  input: {
                    userID: currentUser.id,
                    trainingAnnouncementID: trainingItem.id,
                    isAgreeDataPrivacyConsent:
                      isAgree == 'yes' || isAgree === true,
                    fullName: full_name,
                    first_name: first_name,
                    middle_initial: middle_initial,
                    last_name: last_name,
                    emailAddress: email_address,
                    gender: user_sex,
                    contactNumber: contact_number,
                    dept_office_unit_taskForce: user_dept,
                    division_section: div_section,
                    designation_position: user_designation,
                    endorsementLetter: '',
                    questionnaire: JSON.stringify(questionnaireValue),
                    expectedOutcomes: expected_outcome,
                    isRegistered: true,
                    isSubmitEndorsementLetter: false,
                    isAttended: false,
                    isPostEvalDone: false,
                    isCourseOutputDone: false,
                    additionalRemarks: '',
                    nickname,
                    employmentType,
                  },
                })
              )
              if (adminUpload) {
                if (
                  trainingItem.id === '2eeae940-ce21-4219-983f-a616f6751245'
                ) {
                  const adminUploadNext = await API.graphql(
                    graphqlOperation(createTrainingRegistration, {
                      input: {
                        userID: currentUser.id,
                        trainingAnnouncementID:
                          'e817dbf4-9dda-4c94-b851-1a99cdea9e4a',
                        isAgreeDataPrivacyConsent:
                          isAgree == 'yes' || isAgree === true,
                        fullName: full_name,
                        first_name: first_name,
                        middle_initial: middle_initial,
                        last_name: last_name,
                        emailAddress: email_address,
                        gender: user_sex,
                        contactNumber: contact_number,
                        dept_office_unit_taskForce: user_dept,
                        division_section: div_section,
                        designation_position: user_designation,
                        endorsementLetter: '',
                        questionnaire: JSON.stringify(questionnaireValue),
                        expectedOutcomes: expected_outcome,
                        isRegistered: true,
                        isSubmitEndorsementLetter: false,
                        isAttended: false,
                        isPostEvalDone: false,
                        isCourseOutputDone: false,
                        additionalRemarks: '',
                        nickname,
                        employmentType,
                      },
                    })
                  )

                  if (adminUploadNext) {
                    notification['success']({
                      message: 'Success',
                      description:
                        "You're registered now to the Training/Event. You're also registered now to training: 'Filipino Sign Language Training'",
                    })

                    setAfterRegister(adminUpload)
                    setRefresh(adminUploadNext)
                    setIsAgree('no')
                    setFull_name(null)
                    setFirst_name(null)
                    setMiddle_initial(null)
                    setLast_name(null)
                    setEmail_address(null)
                    setUser_sex(null)
                    setContact_number(null)
                    setUser_dept(null)
                    setDiv_section(null)
                    setUser_designation(null)
                    setLetterUpload(null)

                    setExpected_outcome('')
                    setCurrent(0)
                    setNickname('')
                    setEmploymentType('')
                    setDisableRegister(true)
                    handleCheckIfTrainingIsBeforePeriod()
                    setTimeout(() => {
                      setIsModalOpen(false)
                    }, 3000)
                  }
                } else if (linkedTrainings?.length > 0) {
                  const assessQuestionsSets = [
                    assessQuestionsSetTwo,
                    assessQuestionsSetThree,
                    assessQuestionsSetFour,
                  ]
                  const expectedOutcomesList = [
                    expected_outcomeTwo,
                    expected_outcomeThree,
                    expected_outcomeFour,
                  ]
                  const promisesChildTrains = linkedTrainings.map(
                    async (id, count) => {
                      const adminUploadNext = API.graphql(
                        graphqlOperation(createTrainingRegistration, {
                          input: {
                            userID: currentUser.id,
                            trainingAnnouncementID: id,
                            isAgreeDataPrivacyConsent:
                              isAgree == 'yes' || isAgree === true,
                            fullName: full_name,
                            first_name: first_name,
                            middle_initial: middle_initial,
                            last_name: last_name,
                            emailAddress: email_address,
                            gender: user_sex,
                            contactNumber: contact_number,
                            dept_office_unit_taskForce: user_dept,
                            division_section: div_section,
                            designation_position: user_designation,
                            endorsementLetter: '',
                            // questionnaire:
                            //   count === 0
                            //     ? JSON.stringify(assessQuestionsSetTwo)
                            //     : count == 1
                            //       ? JSON.stringify(assessQuestionsSetThree)
                            //       : JSON.stringify(assessQuestionsSetFour),
                            // expectedOutcomes:
                            //   count == 0
                            //     ? expected_outcomeTwo
                            //     : count == 1
                            //       ? expected_outcomeThree
                            //       : expected_outcomeFour,
                            questionnaire: JSON.stringify(
                              assessQuestionsSets[count]
                            ),
                            expectedOutcomes: expectedOutcomesList[count],
                            isRegistered: true,
                            isSubmitEndorsementLetter: false,
                            isAttended: false,
                            isPostEvalDone: false,
                            isCourseOutputDone: false,
                            additionalRemarks: '',
                            nickname,
                            employmentType,
                          },
                        })
                      )
                      return adminUploadNext
                    }
                  )
                  try {
                    const finishedChildTrainsRegistrations =
                      await Promise.all(promisesChildTrains)
                    if (finishedChildTrainsRegistrations) {
                      notification['success']({
                        message: 'Success',
                        description:
                          "You're registered now to the Training/Event. You're also registered with other training's that is linked in this training ",
                      })

                      setAfterRegister(finishedChildTrainsRegistrations)
                      setRefresh(finishedChildTrainsRegistrations)
                      setIsAgree('no')
                      setFull_name(null)
                      setFirst_name(null)
                      setMiddle_initial(null)
                      setLast_name(null)
                      setEmail_address(null)
                      setUser_sex(null)
                      setContact_number(null)
                      setUser_dept(null)
                      setDiv_section(null)
                      setUser_designation(null)
                      setLetterUpload(null)

                      setExpected_outcome('')
                      setCurrent(0)
                      setNickname('')
                      setEmploymentType('')
                      setDisableRegister(true)
                      handleCheckIfTrainingIsBeforePeriod()
                      setTimeout(() => {
                        setIsModalOpen(false)
                      }, 3000)
                    }
                  } catch (err) {
                    console.log(err)
                  }
                } else {
                  notification['success']({
                    message: 'Success',
                    description: "You're registered now to the Training/Event.",
                  })

                  setAfterRegister(adminUpload)

                  setIsAgree('no')
                  setFull_name(null)
                  setFirst_name(null)
                  setMiddle_initial(null)
                  setLast_name(null)
                  setEmail_address(null)
                  setUser_sex(null)
                  setContact_number(null)
                  setUser_dept(null)
                  setDiv_section(null)
                  setUser_designation(null)
                  setLetterUpload(null)

                  setExpected_outcome('')
                  setCurrent(0)
                  setNickname('')
                  setEmploymentType('')
                  setDisableRegister(true)
                  handleCheckIfTrainingIsBeforePeriod()

                  setTimeout(() => {
                    setIsModalOpen(false)
                  }, 3000)
                }
              }
            }
          } catch (error) {
            console.log(error)
            notification['error']({
              message: 'Error',
              description: 'Please try again later to register.',
            })
          }
        } else {
          notification['error']({
            message: 'Error',
            description:
              'Registration to this event is already closed at this time.',
          })
        }
      } else {
        setIsSubmitted(false)
        notification['error']({
          message: 'Error',
          description:
            'Please answer this question: What are your expected outcome/s and/or learnings after the training?',
        })
      }
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description:
          'An unexpected error occurred. Please contact your administrator for assistance.',
      })
    }
  }

  /**
   * @constant stepsWithAssessment
   * @description This constant is used to store the steps of the registration modal with required assessment form.
   */
  const stepsWithAssessment = [
    {
      title: 'Data Privacy Consent Form',
      content: (
        <>
          <Row justify='center' style={{ fontSize: 16 }}>
            <Col className='d-flex flex-column justify-content-center align-items-center'>
              <div className='d-flex justify-content-center'>
                <Row className='col-md-5 col-12'>
                  <span>
                    <strong>
                      I hereby authorize and give the Strategic Human Resource
                      Unit a consent that all personal information indicated
                      herein will be kept confidential for training purposes
                      only. I further understand that this form is being
                      protected by the Republic Act 10173 or the "Data Privacy
                      Act".
                    </strong>
                  </span>
                </Row>
              </div>
              <Row
                style={{ marginTop: 24 }}
                justify='space-evenly'
                onChange={handleRadio}
                className='col-md-7 col-12'
              >
                <Col>
                  <input
                    style={{ marginRight: 8 }}
                    defaultChecked={isAgree == 'no'}
                    value='no'
                    name='dpcf'
                    id='dpcfNo'
                    type='radio'
                  />
                  <label htmlFor='dpcfNo'>Disagree</label>
                </Col>
                <Col>
                  <input
                    style={{ marginRight: 8 }}
                    defaultChecked={isAgree == 'yes'}
                    value='yes'
                    name='dpcf'
                    id='dpcfYes'
                    type='radio'
                  />
                  <label htmlFor='dpcfYes'>Agree</label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.02</b>
            </span>
          </Row>
        </>
      ),
    },
    {
      title: 'Demographic Profile of Participants',
      content: (
        <>
          <Row className={'mb-3'} justify='center'>
            <Col span={24}>
              <span style={{ fontSize: '20px' }}>
                <b>
                  Before proceeding to the next part, please review your details
                  in the Demographic Profile of Participants Form.
                </b>
              </span>
            </Col>
          </Row>
          <Row justify='center'>
            <Col>
              <Row justify='center'>
                <Col style={{ width: 830 }}>
                  <Row gutter={6}>
                    <Col span={8} style={{ height: '100px' }}>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='firstName'
                        >
                          First Name <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Input
                        disabled
                        defaultValue={first_name}
                        className={'text-center'}
                        type='text'
                        id='firstName'
                      />
                    </Col>

                    <Col span={8}>
                      <div>
                        <Row>
                          <label
                            style={{ fontSize: 16 }}
                            className='loginInput-label'
                            htmlFor='middleInitial'
                          >
                            Middle Initial <em className='asterisk'>**</em>
                          </label>
                        </Row>

                        <Input
                          value={middle_initial}
                          onChange={handleMiddleInitial}
                          className={`text-center ${
                            validationError.middleInitial && 'border-danger'
                          }`}
                          type='text'
                          id='middleInitial'
                          maxLength={3}
                        />
                      </div>
                      {validationError.middleInitial && (
                        <span className='text-danger'>
                          {validationError.middleInitial}
                        </span>
                      )}
                    </Col>

                    <Col span={8} style={{ height: '100px' }}>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='lastName'
                        >
                          Last Name <em className='asterisk'>**</em>
                        </label>
                      </Row>

                      <Input
                        disabled
                        defaultValue={last_name}
                        className={'text-center'}
                        type='text'
                        id='lastName'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <label
                      style={{ fontSize: 16 }}
                      className='loginInput-label'
                      htmlFor='nickname'
                    >
                      Nickname <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row style={{ marginBottom: 12, fontSize: 16 }}>
                    <Input
                      value={nickname}
                      onChange={handleNickname}
                      placeholder={'enter your nickname here'}
                      className={`text-center ${
                        validationError.nickname && 'border-danger'
                      }`}
                      type='text'
                      id='nickname'
                      maxLength={50}
                    />
                    {validationError.nickname && (
                      <span className='text-danger'>
                        {validationError.nickname}
                      </span>
                    )}
                  </Row>
                  <Row>
                    <label
                      className='loginInput-label'
                      style={{ fontSize: 16 }}
                      htmlFor='employmentType'
                    >
                      Type of Employment <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row>
                    <Select
                      value={employmentType}
                      placeholder={'select your employment type'}
                      onChange={handleEmploymentType}
                      style={{
                        width: '50%',
                      }}
                      options={[
                        {
                          value: 'permanentOrRegular',
                          label: 'Permanent/Regular',
                        },
                        {
                          value: 'cosOrJoOrConsultant',
                          label: 'COS/JO/Consultant',
                        },
                      ]}
                      id='employmentType'
                    />
                  </Row>
                  <Row gutter={6}>
                    <Col className='col-md-6 col-12' style={{ fontSize: 16 }}>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='emailAddress'
                        >
                          EMAIL ADDRESS <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={email_address}
                          onChange={handleEmailAddress}
                          style={{ fontSize: 16 }}
                          type='email'
                          id='emailAddress'
                        />
                      </Row>
                      <Row>
                        <span
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                        >
                          SEX <em className='asterisk'>**</em>
                        </span>
                      </Row>

                      <Row
                        className='ms-md-0 ms-4'
                        onChange={handleSexRadio}
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col className='col-md-auto col-12'>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'male'}
                              value='male'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexMale'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexMale'
                            >
                              Male
                            </label>
                          </Row>
                        </Col>
                        <Col className='col-md-auto col-12'>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'female'}
                              value='female'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexFemale'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexFemale'
                            >
                              Female
                            </label>
                          </Row>
                        </Col>
                        <Col className='col-md-auto col-12'>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'notSpecified'}
                              value='notSpecified'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexNotMentioned'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexNotMentioned'
                            >
                              Prefer not to mention
                            </label>
                          </Row>
                        </Col>
                      </Row>

                      <Row className='mt-md-0 mt-5'>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='contactNumber'
                        >
                          CONTACT NUMBER <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={contact_number}
                          onChange={handleContactNumber}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='contactNumber'
                        />
                      </Row>
                    </Col>
                    <Col className='col-md-6 col-12'>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='department'
                        >
                          DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={user_dept}
                          onChange={handleDepartment}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='department'
                        />
                      </Row>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='divisionSection'
                        >
                          DIVISION / SECTION{' '}
                          <small>(If not applicable, type N/A)</small>{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={div_section}
                          onChange={handleDivisionSection}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='divisionSection'
                        />
                      </Row>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='designation'
                        >
                          DESIGNATION/POSITION <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={user_designation}
                          onChange={handleDesignation}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='designation'
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className='d-md-flex justify-content-center mt-md-3'>
            <div className='col-md-5'>
              <Row className='d-md-flex justify-content-start'>
                <em className='requiredFields'>** All fields are required</em>
              </Row>
            </div>
          </div>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.04</b>
            </span>
          </Row>
        </>
      ),
    },
    {
      title: 'Endorsement Letter',
      content: (
        <Row justify='center' style={{ fontSize: 16 }}>
          <Col style={{ width: 800 }}>
            <Row justify='center'>
              <Col>
                {trainingItem?.stepThreeText?.textDisplay ? (
                  <>
                    <p
                      className='text-center'
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      <HtmlParser
                        content={trainingItem?.stepThreeText?.textDisplay?.replace(
                          /\n/g,
                          '<br>'
                        )}
                      />
                    </p>
                    {trainingItem.isEndorsementRequired === 'yes' ? null : (
                      <p className={'text-center'}>
                        You may edit your response to upload your endorsement
                        letter or send a hardcopy to the HRMD.
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    <p className={'text-center'}>
                      Kindly submit the formal letter duly signed by your
                      Department/Office Head allowing your attendance in this
                      training on official time.{' '}
                      {/*<em>
                                  This letter shall merit the issuance of Certificate of
                                  Completion.
                                </em>*/}
                      <em>
                        Please be advised that the <b>Coaching and Mentoring</b>{' '}
                        will credit <b>8 hours of leadership training</b> and a
                        Certificate of Completion will be issued upon
                        accomplishment of all the requirements of the program.
                      </em>
                    </p>
                    {trainingItem.isEndorsementRequired === 'yes' ? null : (
                      <p className={'text-center'}>
                        You may edit your response to upload your endorsement
                        letter or send a hardcopy to the HRMD.
                      </p>
                    )}
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <label
                htmlFor='endorsementLetter'
                className='loginInput-label'
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Upload Word Document PDF or JPEG/PNG{' '}
                {trainingItem.isEndorsementRequired === 'yes' ||
                trainingItem.id === '54c26b0c-babb-4b3b-b525-e1beba28d23b' ? (
                  <span className='asterisk'>**</span>
                ) : null}
              </label>
              <Input
                id='endorsementLetter'
                name='endorsementLetter'
                type='file'
                accept='application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/png,image/jpeg'
                onChange={(e) => {
                  validateFile(e.target.files[0])
                }}
              />
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: `${childrenTrains.length ? 'Pre-Assessment 1' : 'Pre-Assessment'}`,
      content: (
        <>
          <div className='col-md-8 offset-md-2 p-md-0 p-2'>
            {childrenTrains.length ? (
              <Row justify='center'>
                <h3>
                  {trainingItem.title} <em>(pre-assessment)</em>
                </h3>
              </Row>
            ) : null}
            <PreAssessmentCustom
              identifier={0}
              trainingItem={trainingItem}
              assessmentQuestions={assessmentQuestions}
              assessmentQuestionsTwo={null}
              assessmentQuestionsThree={null}
              assessmentQuestionsFour={null}
              setAssessQuestionsSet={setAssessQuestionsSet}
              setAssessQuestionsSetTwo={null}
              setAssessQuestionsSetThree={null}
              setAssessQuestionsSetFour={null}
            />
          </div>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.10</b>
            </span>
          </Row>
        </>
      ),
    },
    {
      title: 'Expected Outcomes',
      content: (
        <>
          <Row justify='center'>
            <Col style={{ width: 800 }}>
              <Row>
                <label
                  style={{ fontSize: 16 }}
                  className='loginInput-label'
                  htmlFor='expectedOutcome'
                >
                  What are your expected outcome/s and/or learnings after the
                  training? <span className='asterisk'>**</span>
                </label>
              </Row>
              <Row>
                <TextArea
                  onChange={handleExpectedOutcome}
                  id='expectedOutcome'
                  style={{ fontSize: 16 }}
                  rows={4}
                  maxLength={512}
                />
              </Row>
            </Col>
          </Row>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.06</b>
            </span>
          </Row>
        </>
      ),
    },
  ]

  useEffect(() => {
    if (trainingItem.linkedTrainingAnnouncementIDs) {
      const newObject = childrenTrains.map((obj, index) => {
        if (obj.questionsAssessment !== null) {
          let customAssessmentQuestions = JSON.parse(
            obj.questionsAssessment.assessmentQuestionnaire
          )

          return {
            title: `Pre-Assessment ${index + 2}`,
            content: (
              <>
                {current > 3 ? (
                  <div className='col-md-8 offset-md-2 p-md-0 p-2'>
                    <Row justify='center'>
                      <h3>
                        {obj.title} <em>(pre-assessment)</em>
                      </h3>
                    </Row>
                    <PreAssessmentCustom
                      identifier={index + 1}
                      trainingItem={obj}
                      assessmentQuestions={null}
                      assessmentQuestionsTwo={
                        index === 0 ? customAssessmentQuestions : null
                      }
                      assessmentQuestionsThree={
                        index === 1 ? customAssessmentQuestions : null
                      }
                      assessmentQuestionsFour={
                        index === 2 ? customAssessmentQuestions : null
                      }
                      setAssessQuestionsSet={setAssessQuestionsSet}
                      setAssessQuestionsSetTwo={
                        index === 0 ? setAssessQuestionsSetTwo : null
                      }
                      setAssessQuestionsSetThree={
                        index === 1 ? setAssessQuestionsSetThree : null
                      }
                      setAssessQuestionsSetFour={
                        index === 2 ? setAssessQuestionsSetFour : null
                      }
                    />
                  </div>
                ) : null}
              </>
            ),
          }
        }
      })

      let objToUpdate = stepsWithAssessment.find(
        (obj) => obj.title === 'Expected Outcomes'
      )
      if (objToUpdate) {
        objToUpdate.content = (
          <>
            {childrenTrains.length ? (
              <>
                <Row justify='center' className='mb-4'>
                  <Col style={{ width: 800 }}>
                    <Row>
                      <label
                        style={{ fontSize: 16 }}
                        className='loginInput-label'
                        htmlFor='expectedOutcome'
                      >
                        What are your expected outcome/s and/or learnings after
                        the training <em>{`(${trainingItem.title})?`} </em>
                        <span className='asterisk'>**</span>
                      </label>
                    </Row>
                    <Row>
                      <TextArea
                        onChange={handleExpectedOutcome}
                        id='expectedOutcome'
                        style={{ fontSize: 16 }}
                        rows={4}
                        maxLength={512}
                      />
                    </Row>
                  </Col>
                </Row>
                {childrenTrains.map((obj, count) => {
                  return (
                    <Row key={obj.id} justify='center' className='mb-4'>
                      <Col style={{ width: 800 }}>
                        <Row>
                          <label
                            style={{ fontSize: 16 }}
                            className='loginInput-label'
                            htmlFor={`expectedOutcome${
                              count == 0 ? 'Two' : count == 1 ? 'Three' : 'Four'
                            }`}
                          >
                            What are your expected outcome/s and/or learnings
                            after the training <em>{`(${obj.title})?`} </em>
                            <span className='asterisk'>**</span>
                          </label>
                        </Row>
                        <Row>
                          <TextArea
                            onChange={
                              count == 0
                                ? handleExpectedOutcomeTwo
                                : count == 1
                                  ? handleExpectedOutcomeThree
                                  : handleExpectedOutcomeFour
                            }
                            id={`expectedOutcome${
                              count == 0 ? 'Two' : count == 1 ? 'Three' : 'Four'
                            }`}
                            style={{ fontSize: 16 }}
                            rows={4}
                            maxLength={512}
                          />
                        </Row>
                      </Col>
                    </Row>
                  )
                })}
              </>
            ) : (
              <Row justify='center'>
                <Col style={{ width: 800 }}>
                  <Row>
                    <label
                      style={{ fontSize: 16 }}
                      className='loginInput-label'
                      htmlFor='expectedOutcome'
                    >
                      What are your expected outcome/s and/or learnings after
                      the training? <span className='asterisk'>**</span>
                    </label>
                  </Row>
                  <Row>
                    <TextArea
                      onChange={handleExpectedOutcome}
                      id='expectedOutcome'
                      style={{ fontSize: 16 }}
                      rows={4}
                      maxLength={512}
                    />
                  </Row>
                </Col>
              </Row>
            )}
          </>
        )
      }

      const index = stepsWithAssessment.findIndex(
        (obj) => obj.title === 'Expected Outcomes'
      )

      let newStepsWithAssessments = stepsWithAssessment
      if (index !== -1) {
        newStepsWithAssessments.splice(index, 0, ...newObject)
        setCustomRegistration([...newStepsWithAssessments])
      }
    }
  }, [childrenTrains, current])

  const stepsWithoutAssessment = [
    {
      title: 'Data Privacy Consent Form',
      content: (
        <>
          <Row justify='center' style={{ fontSize: 16 }}>
            <Col>
              <Row style={{ width: 800 }}>
                <span>
                  <strong>
                    I hereby authorize and give the Strategic Human Resource
                    Unit a consent that all personal information indicated
                    herein will be kept confidential for training purposes only.
                    I further understand that this form is being protected by
                    the Republic Act 10173 or the "Data Privacy Act".
                  </strong>
                </span>
              </Row>
              <Row
                style={{ marginTop: 24 }}
                justify='space-evenly'
                onChange={handleRadio}
              >
                <Col span={12}>
                  <input
                    style={{ marginRight: 8 }}
                    defaultChecked={isAgree == 'no'}
                    value='no'
                    name='dpcf'
                    id='dpcfNo'
                    type='radio'
                  />
                  <label htmlFor='dpcfNo'>Disagree</label>
                </Col>
                <Col span={12}>
                  <input
                    style={{ marginRight: 8 }}
                    defaultChecked={isAgree == 'yes'}
                    value='yes'
                    name='dpcf'
                    id='dpcfYes'
                    type='radio'
                  />
                  <label htmlFor='dpcfYes'>Agree</label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.02</b>
            </span>
          </Row>
        </>
      ),
    },
    {
      title: 'Demographic Profile of Participants',
      content: (
        <>
          <Row className={'mb-3'} justify='center'>
            <Col span={24}>
              <span style={{ fontSize: '20px' }}>
                <b>
                  Before proceeding to the next part, please review your details
                  in the Demographic Profile of Participants Form.
                </b>
              </span>
            </Col>
          </Row>
          <Row justify='center'>
            <Col>
              <Row justify='center'>
                <Col style={{ width: 830 }}>
                  <div className='d-flex justify-content-between gap-2'>
                    <Row
                      className='w-100'
                      style={{
                        marginBottom: 12,
                        fontSize: 16,
                        height: '50px',
                      }}
                    >
                      <label
                        style={{ fontSize: 16 }}
                        className='loginInput-label'
                        htmlFor='firstName'
                      >
                        First name <em className='asterisk'>**</em>
                      </label>
                      <Input
                        disabled
                        defaultValue={first_name}
                        className={'text-center w-100'}
                        type='text'
                        id='firstName'
                      />
                    </Row>
                    <Row
                      className='w-50'
                      style={{ marginBottom: 12, fontSize: 16 }}
                    >
                      <div>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='middleInitial'
                        >
                          Middle initial
                        </label>
                        <Input
                          value={middle_initial}
                          onChange={handleMiddleInitial}
                          className={`text-center ${
                            validationError.middleInitial && 'border-danger'
                          }`}
                          type='text'
                          id='middleInitial'
                          maxLength={3}
                        />
                      </div>
                      {validationError.middleInitial && (
                        <span className='text-danger'>
                          {validationError.middleInitial}
                        </span>
                      )}
                    </Row>
                    <Row
                      className='w-100'
                      style={{ marginBottom: 12, fontSize: 16, height: '50px' }}
                    >
                      <label
                        style={{ fontSize: 16 }}
                        className='loginInput-label'
                        htmlFor='lastName'
                      >
                        Last name <em className='asterisk'>**</em>
                      </label>
                      <Input
                        disabled
                        defaultValue={last_name}
                        className={'text-center w-100'}
                        type='text'
                        id='lastName'
                      />
                    </Row>
                  </div>

                  <Row>
                    <label
                      style={{ fontSize: 16 }}
                      className='loginInput-label'
                      htmlFor='nickname'
                    >
                      Nickname <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row style={{ marginBottom: 12, fontSize: 16 }}>
                    <Input
                      value={nickname}
                      onChange={handleNickname}
                      placeholder={'enter your nickname here'}
                      className={`text-center ${
                        validationError.nickname && 'border-danger'
                      }`}
                      type='text'
                      id='nickname'
                      maxLength={50}
                    />
                    {validationError.nickname && (
                      <span className='text-danger'>
                        {validationError.nickname}
                      </span>
                    )}
                  </Row>
                  <Row>
                    <label
                      className='loginInput-label'
                      style={{ fontSize: 16 }}
                      htmlFor='employmentType'
                    >
                      Type of Employment <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row>
                    <Select
                      placeholder={'select your employment type'}
                      onChange={handleEmploymentType}
                      style={{
                        width: '50%',
                      }}
                      options={[
                        {
                          value: 'permanentOrRegular',
                          label: 'Permanent/Regular',
                        },
                        {
                          value: 'cosOrJoOrConsultant',
                          label: 'COS/JO/Consultant',
                        },
                      ]}
                      id='employmentType'
                    />
                  </Row>
                  <Row gutter={6}>
                    <Col style={{ fontSize: 16 }} span={12}>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='emailAddress'
                        >
                          EMAIL ADDRESS <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={email_address}
                          onChange={handleEmailAddress}
                          style={{ fontSize: 16 }}
                          type='email'
                          id='emailAddress'
                        />
                      </Row>
                      <Row>
                        <span
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                        >
                          SEX <em className='asterisk'>**</em>
                        </span>
                      </Row>

                      <Row
                        onChange={handleSexRadio}
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'male'}
                              value='male'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexMale'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexMale'
                            >
                              Male
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'female'}
                              value='female'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexFemale'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexFemale'
                            >
                              Female
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'notSpecified'}
                              value='notSpecified'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexNotMentioned'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexNotMentioned'
                            >
                              Prefer not to mention
                            </label>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='contactNumber'
                        >
                          CONTACT NUMBER <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={contact_number}
                          onChange={handleContactNumber}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='contactNumber'
                        />
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='department'
                        >
                          DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={user_dept}
                          onChange={handleDepartment}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='department'
                        />
                      </Row>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='divisionSection'
                        >
                          DIVISION / SECTION{' '}
                          <small>(If not applicable, type N/A)</small>{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={div_section}
                          onChange={handleDivisionSection}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='divisionSection'
                        />
                      </Row>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='designation'
                        >
                          DESIGNATION/POSITION <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={user_designation}
                          onChange={handleDesignation}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='designation'
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <em className='requiredFields'>** All fields are required</em>
          </Row>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.04</b>
            </span>
          </Row>
        </>
      ),
    },
    {
      title: 'Endorsement Letter',
      content: (
        <Row justify='center' style={{ fontSize: 16 }}>
          <Col style={{ width: 800 }}>
            <Row justify='center'>
              <Col>
                {trainingItem.id == '31818235-2ac0-49a1-8658-74affcd5b0c9' ? (
                  <>
                    <p className={'text-center'}>
                      Kindly submit the formal letter duly signed by your
                      Department/Office Head allowing your attendance in this
                      training on official time.{' '}
                      {/*<em>
                            This letter shall merit the issuance of Certificate of
                            Completion.
                          </em>*/}
                      <em>
                        Please be advised that the{' '}
                        <b>
                          The Value of Values: Improving Ethics in Public
                          Service via Zoom
                        </b>{' '}
                        will credit <b>16 hours of technical training</b> and a
                        Certificate of Completion will be issued upon
                        accomplishment of all the requirements of the program.
                      </em>
                    </p>
                    {trainingItem.isEndorsementRequired === 'yes' ? null : (
                      <p className={'text-center'}>
                        You may edit your response to upload your endorsement
                        letter or send a hardcopy to the HRMD..
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    {trainingItem.id ==
                    '4c86b36a-1d59-4304-b272-9dd1f5519a6e' ? (
                      <>
                        <p className={'text-center'}>
                          Kindly submit the formal letter duly signed by your
                          Department/Office Head allowing your attendance in
                          this training on official time.{' '}
                          {/*<em>
                                  This letter shall merit the issuance of Certificate of
                                  Completion.
                                </em>*/}
                          <em>
                            Please be advised that the{' '}
                            <b>
                              Building the QC Government Culture through
                              Positive Engagement
                            </b>{' '}
                            will credit <b>8 hours of leadership training</b>{' '}
                            and a Certificate of Completion will be issued upon
                            accomplishment of all the requirements of the
                            program.
                          </em>
                        </p>
                        {trainingItem.isEndorsementRequired === 'yes' ? null : (
                          <p className={'text-center'}>
                            You may edit your response to upload your
                            endorsement letter or send a hardcopy to the HRMD.
                          </p>
                        )}
                      </>
                    ) : trainingItem?.stepThreeText?.textDisplay ? (
                      <>
                        <p
                          className='text-center'
                          style={{ whiteSpace: 'pre-wrap' }}
                        >
                          <HtmlParser
                            content={trainingItem?.stepThreeText?.textDisplay?.replace(
                              /\n/g,
                              '<br>'
                            )}
                          />
                        </p>
                        {trainingItem.isEndorsementRequired === 'yes' ? null : (
                          <p className={'text-center'}>
                            You may edit your response to upload your
                            endorsement letter or send a hardcopy to the HRMD.
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        <p className={'text-center'}>
                          Kindly submit the formal letter duly signed by your
                          Department/Office Head allowing your attendance in
                          this training on official time.{' '}
                          {/*<em>
                                  This letter shall merit the issuance of Certificate of
                                  Completion.
                                </em>*/}
                          <em>
                            Please be advised that the{' '}
                            <b>Coaching and Mentoring</b> will credit{' '}
                            <b>8 hours of leadership training</b> and a
                            Certificate of Completion will be issued upon
                            accomplishment of all the requirements of the
                            program.
                          </em>
                        </p>
                        {trainingItem.isEndorsementRequired === 'yes' ? null : (
                          <p className={'text-center'}>
                            You may edit your response to upload your
                            endorsement letter or send a hardcopy to the HRMD.
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <label
                htmlFor='endorsementLetter'
                className='loginInput-label'
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Upload Word Document PDF or JPEG/PNG{' '}
                {
                  <>
                    {trainingItem.isEndorsementRequired === 'yes' ||
                    trainingItem.id ===
                      '54c26b0c-babb-4b3b-b525-e1beba28d23b' ? (
                      <span className='asterisk'>**</span>
                    ) : null}
                  </>
                }
              </label>
              <Input
                id='endorsementLetter'
                name='endorsementLetter'
                type='file'
                accept='application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,image/png,image/jpeg'
                onChange={(e) => {
                  validateFile(e.target.files[0])
                }}
              />
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Expected Outcomes',
      content: (
        <>
          <Row justify='center'>
            <Col style={{ width: 800 }}>
              <Row>
                <label
                  style={{ fontSize: 16 }}
                  className='loginInput-label'
                  htmlFor='expectedOutcome'
                >
                  What are your expected outcome/s and/or learnings after the
                  training? <span className='asterisk'>**</span>
                </label>
              </Row>
              <Row>
                <TextArea
                  onChange={handleExpectedOutcome}
                  id='expectedOutcome'
                  style={{ fontSize: 16 }}
                  rows={4}
                  maxLength={512}
                />
              </Row>
            </Col>
          </Row>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.06</b>
            </span>
          </Row>
        </>
      ),
    },
  ]

  /**
   *@function showModal
   *@description Opens the modal for the user to register for the training.
   * It checks if the user is logged in or not. If the user is logged in, it opens the modal,
   * otherwise, it shows a notification that the user must login to register.
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   */
  const showModal = () => {
    if (userData) {
      setIsModalOpen(true)
    } else {
      notification['info']({
        message: 'Info',
        description: 'You must login to register.',
      })
    }
  }

  /**
   *@function handleCancel
   *@description Cancels the modal and resets the state variables.
   */
  const handleCancel = () => {
    setIsModalOpen(false)
    setIsAgree('no')
    setFull_name(null)
    setFirst_name(null)
    setMiddle_initial(null)
    setLast_name(null)
    setEmail_address(null)
    setUser_sex(null)
    setContact_number(null)
    setUser_dept(null)
    setDiv_section(null)
    setUser_designation(null)
    setLetterUpload(null)
    setExpected_outcome('')
    setCurrent(0)
    setNickname('')
    setEmploymentType('')
  }
  const next = () => {
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  /**
   *@function checkIfPastToday
   *@description Checks if the training is past today's date.
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   */
  const checkIfPastToday = () => {
    // seems redundant but will consult with HRM if this is the case or any date will do
    try {
      if (trainingItem?.arrayOfDates) {
        const arrayOfDates = JSON.parse(trainingItem.arrayOfDates)
        const sortedItems = arrayOfDates.sort(function (a, b) {
          return moment(a.date).diff(moment(b.date))
        })
        const initialDate = sortedItems[0]
        const dateChecker = moment(
          `${initialDate} ${trainingItem.trainingTimeStart}`,
          'YYYY-MM-DD hh:mm A'
        ).isAfter(new Date(), 'day')
        const isDateToday = moment(
          `${initialDate} ${trainingItem.trainingTimeStart}`,
          'YYYY-MM-DD hh:mm A'
        ).isSame(new Date(), 'day')
        if (dateChecker === true) {
          setIsPastToday(true)
        } else {
          setIsPastToday(isDateToday)
        }
      } else {
        const dateChecker = moment(
          `${trainingItem.date} ${trainingItem.trainingTimeStart}`,
          'YYYY-MM-DD hh:mm A'
        ).isAfter(new Date(), 'day')
        const isDateToday = moment(
          `${trainingItem.date} ${trainingItem.trainingTimeStart}`,
          'YYYY-MM-DD hh:mm A'
        ).isSame(new Date(), 'day')

        if (dateChecker === true) {
          setIsPastToday(true)
        } else {
          setIsPastToday(isDateToday)
        }
      }
    } catch (err) {
      console.log(err)
      errorNotif()
    }
  }

  useEffect(() => {
    checkIfPastToday()
  }, [])

  /**
   *@function handleGoToForms
   *@description Checks if the user is logged in or not. If the user is logged in, it goes to the forms page,
   * otherwise, it shows a notification that the user must login to register.
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   */
  const handleGoToForms = (e) => {
    e.preventDefault()
    if (currentUser) {
      gotoForms()
    } else {
      notification['info']({
        message: 'Info',
        description: 'You must sign-in to register.',
      })
    }
  }

  const handleGoToLogin = (e) => {
    e.preventDefault()
    navigate('/login')
  }

  /**
   *@function validateFile
   *@description Validates the file if it is a valid file type and if it is not too large.
   *@param {File} file The file to be validated.
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   */
  const validateFile = (file) => {
    try {
      const validTypes = [
        'image/jpeg',
        'image/gif',
        'image/png',
        'application/pdf',
      ]
      const maxSize = 5 * 1024 * 1024 // 5MB in bytes

      if (!validTypes.includes(file.type)) {
        notification['error']({
          message: 'Error',
          description:
            'Invalid file type. The file must be a JPEG, GIF, PNG, or PDF.',
        })

        setLetterUpload(null)
        return
      }

      if (file.size > maxSize) {
        notification['error']({
          message: 'Error',
          description: 'File is too large. The maximum file size is 5MB.',
        })

        setLetterUpload(null)
        return
      }

      setLetterUpload(file)
    } catch (err) {
      console.log(err)
      errorNotif()
    }
  }

  const checkAnswerPreAssess = async () => {
    try {
      const assessmentQuestionnaire = JSON.parse(
        trainingItem.questionsAssessment.assessmentQuestionnaire
      )
      const getCorrectAnswers = JSON.parse(
        trainingItem.questionsAssessment.assessmentQuestionnaire
      ).map((item) => item.correctAnswer)
      const answers = []

      let questionOrder = []

      for (let i = 0; i < assessmentQuestionnaire.length; i++) {
        questionOrder.push(`question${numToString(i)}`)
      }

      for (const key of questionOrder) {
        if (key in assessQuestionsSet) {
          answers.push(assessQuestionsSet[key])
        }
      }

      const foundResult = checkTestResult(getCorrectAnswers, answers)

      if (foundResult === 'Failed') {
        await createFailedRegistration()
        showFailureNotification()

        handleCheckIfTrainingIsBeforePeriod()

        setTimeout(() => {
          setIsModalOpen(false)
        }, 3000)
      } else if (foundResult === 'Passed') {
        next()
      } else {
        throw new Error(
          'Cannot validate result. Please contact system administrator.'
        )
      }
    } catch (err) {
      console.log(err)

      notification['error']({
        message: 'Error',
        description:
          'Cannot validate result. Please contact system administrator.',
      })
    }
  }

  function checkTestResult(correctAnswers, myAnswers) {
    if (correctAnswers.length !== myAnswers.length) {
      throw new Error('Arrays must have the same length.')
    }

    let correctCount = 0
    for (let i = 0; i < correctAnswers.length; i++) {
      if (correctAnswers[i] === myAnswers[i]) {
        correctCount++
      }
    }

    if (correctCount > correctAnswers.length) {
      throw new Error(
        'Correct count cannot be greater than the length of the correct answers.'
      )
    }

    if (correctCount >= parseInt(trainingItem.preAssessmentScore)) {
      return 'Passed'
    } else {
      return 'Failed'
    }
  }

  const createFailedRegistration = async () => {
    try {
      await API.graphql(
        graphqlOperation(createTrainingRegistration, {
          input: {
            userID: currentUser.id,
            trainingAnnouncementID: trainingItem.id,
            isAgreeDataPrivacyConsent: isAgree == 'yes' || isAgree === true,
            fullName: full_name,
            first_name: first_name,
            middle_initial: middle_initial,
            last_name: last_name,
            emailAddress: email_address,
            gender: user_sex,
            contactNumber: contact_number,
            dept_office_unit_taskForce: user_dept,
            division_section: div_section,
            designation_position: user_designation,
            endorsementLetter: '',
            questionnaire: JSON.stringify(assessQuestionsSet),
            expectedOutcomes: expected_outcome,
            isRegistered: true,
            isSubmitEndorsementLetter: false,
            isAttended: false,
            isPostEvalDone: false,
            isCourseOutputDone: false,
            additionalRemarks: '',
            nickname,
            employmentType,
            isPreAssessmentPassed: false,
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const showFailureNotification = () => {
    notification['error']({
      message: (
        <span>
          <b>Error</b>
        </span>
      ),
      style: {
        width: 450,
      },
      description: (
        <span>
          <b>
            Unfortunately, you DID NOT qualify for this training based on your
            pre-test results. We recommend starting with our basic Excel course.
          </b>{' '}
        </span>
      ),
    })
  }

  return (
    <>
      {isFinishedChecking ? (
        <>
          {hasNewPeriodForms || currentUser?.role === 'admin' ? (
            <>
              {limitReached === false ? (
                <>
                  {disableRegister === false ? (
                    <>
                      {trainingItem.isOpenRegistration === true ? (
                        <>
                          {
                            // if training day is past today, it will show close otherwise it shows the register button
                            isPastToday ? (
                              <>
                                {!trainingItem.parentTrainingID ? (
                                  <button
                                    className='m-container'
                                    onClick={() => showModal()}
                                  >
                                    Register Here
                                  </button>
                                ) : (
                                  <Row className='mt-2'>
                                    <div
                                      className='alert alert-info ms-2'
                                      role='alert'
                                    >
                                      Register to {`${parentTrainingName}`}
                                    </div>
                                  </Row>
                                )}
                              </>
                            ) : (
                              <Row justify='start' className={'mt-3 w-100'}>
                                {todaysDate < trainingItemDate ? (
                                  <span
                                    className={
                                      'p-2 pb-0 alert alert-info w-100'
                                    }
                                  >
                                    <HtmlParser
                                      content={
                                        trainingItem?.additionalTexts?.replace(
                                          /\n/g,
                                          '<br>'
                                        ) || (
                                          <span className={'p-2'}>
                                            <i>
                                              <b>CLOSED</b>
                                            </i>
                                          </span>
                                        )
                                      }
                                    />
                                  </span>
                                ) : (
                                  <span className={'p-2'}>
                                    <i>
                                      <b>CLOSED</b>
                                    </i>
                                  </span>
                                )}
                              </Row>
                            )
                          }
                        </>
                      ) : (
                        <Row justify='start' className={'mt-3 w-100'}>
                          {todaysDate < trainingItemDate ? (
                            <span className={'p-2 pb-0 alert alert-info w-100'}>
                              <HtmlParser
                                content={
                                  trainingItem?.additionalTexts?.replace(
                                    /\n/g,
                                    '<br>'
                                  ) || (
                                    <span className={'p-2'}>
                                      <i>
                                        <b>CLOSED</b>
                                      </i>
                                    </span>
                                  )
                                }
                              />
                            </span>
                          ) : (
                            <span className={'p-2'}>
                              <i>
                                <b>CLOSED</b>
                              </i>
                            </span>
                          )}
                        </Row>
                      )}

                      <Modal
                        maskClosable={false}
                        destroyOnClose={true}
                        footer={null}
                        width={'100vw'}
                        title='REGISTRATION FORM'
                        open={isModalOpen}
                        onCancel={handleCancel}
                      >
                        {trainingItem.hasAssessment == 'true' ? (
                          <>
                            {trainingItem.id ==
                            '2018ebe8-d2ae-414d-92dd-6481f9d75be3' ? null : (
                              <>
                                {customRegistration.length ? (
                                  <>
                                    <Steps current={current}>
                                      {customRegistration.map((item) => (
                                        <Step
                                          key={item.title}
                                          title={item.title}
                                        />
                                      ))}
                                    </Steps>
                                    <div className='steps-content'>
                                      {customRegistration[current].content}
                                    </div>
                                    <div
                                      className={
                                        'steps-action d-flex justify-content-end'
                                      }
                                    >
                                      {current > 0 && (
                                        <Button
                                          style={{
                                            margin: '0 8px',
                                          }}
                                          onClick={() => prev()}
                                        >
                                          Previous
                                        </Button>
                                      )}
                                      {current ===
                                        customRegistration.length - 1 && (
                                        <Button
                                          disabled={isSubmitted}
                                          type='primary'
                                          onClick={() =>
                                            checkIfLimitReached('without')
                                          }
                                        >
                                          Done
                                        </Button>
                                      )}
                                      {current <
                                        customRegistration.length - 1 && (
                                        <Button
                                          disabled={handleNextButtonWithAssessment()}
                                          type='primary'
                                          onClick={() => next()}
                                        >
                                          Next
                                        </Button>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <Steps current={current}>
                                      {stepsWithAssessment.map((item) => (
                                        <Step
                                          key={item.title}
                                          title={item.title}
                                        />
                                      ))}
                                    </Steps>
                                    <div className='steps-content'>
                                      {stepsWithAssessment[current].content}
                                    </div>
                                    <div
                                      className={
                                        'steps-action d-flex justify-content-end'
                                      }
                                    >
                                      {current > 0 && (
                                        <Button
                                          style={{
                                            margin: '0 8px',
                                          }}
                                          onClick={() => prev()}
                                        >
                                          Previous
                                        </Button>
                                      )}
                                      {current ===
                                        stepsWithAssessment.length - 1 && (
                                        <Button
                                          disabled={isSubmitted}
                                          type='primary'
                                          onClick={() =>
                                            checkIfLimitReached('without')
                                          }
                                        >
                                          Done
                                        </Button>
                                      )}
                                      {current <
                                        stepsWithAssessment.length - 1 && (
                                        <Button
                                          disabled={handleNextButtonWithAssessment()}
                                          type='primary'
                                          onClick={() => {
                                            if (
                                              trainingItem.isPassPreAssessmentRequired &&
                                              current === 3
                                            ) {
                                              checkAnswerPreAssess()
                                            } else {
                                              next()
                                            }
                                          }}
                                        >
                                          {trainingItem.isPassPreAssessmentRequired &&
                                          current === 3
                                            ? 'Submit Answers'
                                            : 'Next'}
                                        </Button>
                                      )}
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {trainingItem.id ==
                            '2018ebe8-d2ae-414d-92dd-6481f9d75be3' ? null : (
                              <>
                                <Steps current={current}>
                                  {stepsWithoutAssessment.map((item) => (
                                    <Step key={item.title} title={item.title} />
                                  ))}
                                </Steps>
                                <div className='steps-content'>
                                  {stepsWithoutAssessment[current].content}
                                </div>
                                <div
                                  className={
                                    'steps-action d-flex justify-content-end'
                                  }
                                >
                                  {current > 0 && (
                                    <Button
                                      style={{
                                        margin: '0 8px',
                                      }}
                                      onClick={() => prev()}
                                    >
                                      Previous
                                    </Button>
                                  )}
                                  {current ===
                                    stepsWithoutAssessment.length - 1 && (
                                    <Button
                                      disabled={isSubmitted}
                                      type='primary'
                                      onClick={() =>
                                        checkIfLimitReached('without')
                                      }
                                    >
                                      Done
                                    </Button>
                                  )}
                                  {current <
                                    stepsWithoutAssessment.length - 1 && (
                                    <Button
                                      disabled={handleNextButton()}
                                      type='primary'
                                      onClick={() => next()}
                                    >
                                      Next
                                    </Button>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </Modal>
                    </>
                  ) : (
                    <span className={'text-monts mt-3'}>
                      {trainingItem.isPassPreAssessmentRequired &&
                      isPreAssessmentPassed === false ? (
                        <b>
                          <em>
                            Unfortunately, you DID NOT qualify for this training
                            based on your pre-test results. We recommend
                            starting with our basic Excel course.
                          </em>
                        </b>
                      ) : (
                        <b>
                          {submittedEndorseLetter ? (
                            <em>You are already registered.</em>
                          ) : (
                            <em>
                              You are already registered. Please check your
                              Calendar found under the "Learning and Development
                              Tab" to be able to submit your duly signed
                              Endorsement letter.
                            </em>
                          )}
                        </b>
                      )}
                    </span>
                  )}
                </>
              ) : (
                <span className={'text-monts mt-3'}>
                  <b>
                    <em>{reachLimitText}</em>
                  </b>
                </span>
              )}
            </>
          ) : (
            <>
              <Row>
                <span className={'text-monts mt-3'}>
                  <b>
                    <em>
                      Please accomplish the e-Training Needs Analysis and
                      Individual Learning Development Plan forms for this
                      current period (2023) to be able to register to a
                      Training.
                    </em>
                  </b>
                </span>
              </Row>
              {currentUser ? (
                <Row>
                  <a
                    onClick={handleGoToForms}
                    className={'text-monts mt-3'}
                    href='#'
                  >
                    Answer e-TNA or ILDP here
                  </a>
                </Row>
              ) : (
                <Row>
                  <a
                    onClick={handleGoToLogin}
                    className={'text-monts mt-3'}
                    href='#'
                  >
                    Sign in to register
                  </a>
                </Row>
              )}
            </>
          )}
          <Modal
            maskClosable={false}
            destroyOnClose={true}
            footer={null}
            width={'50vw'}
            title='Additional Files'
            open={isAddOpen}
            onCancel={() => setIsAddOpen(false)}
          >
            <Col>
              <Row className='mb-3' justify='center'>
                <a
                  target='_blank'
                  href='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/BW - Handout.pdf'
                >
                  <h3>BW Handouts.pdf</h3>
                </a>
              </Row>
            </Col>
          </Modal>
        </>
      ) : (
        <Skeleton active />
      )}
    </>
  )
}

TrainingRegistrationModal.propTypes = {
  trainingItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    questionsAssessment: PropTypes.object,
    parentTrainingID: PropTypes.string,
    limitPerDept: PropTypes.string,
    participantLimit: PropTypes.number,
    isEndorsementRequired: PropTypes.string,
    isOpenRegistration: PropTypes.bool,
    title: PropTypes.string.isRequired,
    arrayOfDates: PropTypes.string,
    stepThreeText: PropTypes.object,
    linkedTrainingAnnouncementIDs: PropTypes.arrayOf(PropTypes.string),
    trainingTimeStart: PropTypes.string,
    isPassPreAssessmentRequired: PropTypes.bool,
    additionalTexts: PropTypes.string,
    hasAssessment: PropTypes.string,
    preAssessmentScore: PropTypes.number,
  }).isRequired,
  setAfterRegister: PropTypes.func,
  gotoForms: PropTypes.func,
  setRefresh: PropTypes.func,
  refresh: PropTypes.object,
  gotoSecWeek: PropTypes.func,
  gotoThirdWeek: PropTypes.func,
  gotoSept8: PropTypes.func,
  gotosept15: PropTypes.func,
  gotoNov20: PropTypes.func,
  gotoJan21: PropTypes.func,
  userData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    email: PropTypes.string,
    department: PropTypes.string,
    designation: PropTypes.string,
    contactNumber: PropTypes.string,
  }).isRequired,
  gotoSept8Batch2: PropTypes.func,
}

export default TrainingRegistrationModal
