/**
 * Title: manageILDP.js
 * Description: This is a file that contains the components for managing the ILDP (Individual Learning and Development Plan) for a user.
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Users/manageILDP.js
 **/

/*
 * Changes made:
 * - 2024.21.08 | Harry Lagunsad | added a toggle switch function for the change view of view all ILDP or filter to view only uploaded ILDP
 * - 2024.21.08 | Harry Lagunsad | applied fixes as per sonarlint suggestions
 * - 2024.21.08 | Harry Lagunsad | added a JSDOC comment to the new function
 */

import React, { useState } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  Form2ByUserId,
  IDLPUploadByUserId,
  UserbyUsername,
  UserbyName,
  UserbyDepartment,
  Form2ByUserDept,
} from '../../api/queries'
import { deleteIDLPUpload, updateUser, updateForm2 } from '../../api/mutations'

import {
  Tag,
  Table,
  Input,
  Select,
  Card,
  Button,
  Modal,
  Col,
  Row,
  Tooltip,
  notification,
  DatePicker,
  Popconfirm,
  Switch,
} from 'antd'
import {
  FundViewOutlined,
  FilePdfTwoTone,
  DeleteTwoTone,
} from '@ant-design/icons'

import { Form } from '@formio/react'
import './form.css'

import moment from 'moment'
import listOfDepartments from '../../utilities/dept'

const { Search } = Input

const { Option } = Select

const limit = 1000

const { RangePicker } = DatePicker

/**
 * @async
 * @function ManageILDP
 * @description Manages the ILDP (Individual Learning and Development Plan) for a user.
 * @param {Object} assignedDepartment - The assigned department for the ILDP.
 * @returns {void}
 * @throws {Error} If there is an error during the ILDP management process.
 */
const ManageILDP = ({ assignedDepartment }) => {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [employeeWithIldp, setEmployeeWithIldp] = useState([])
  const [submissionDataIldp, setSubmissionDataIldp] = useState()
  const [isLang, setIsLang] = useState()

  const [fName, setFName] = useState('')
  const [lName, setLName] = useState('')
  const [emailSearch, setEmailSearch] = useState('')
  const [isLoadingTable, setIsLoadingTable] = useState(true)

  const [isSwitchView, setIsSwitchView] = useState(false)
  const [employeeWithIldpFiltered, setEmployeeWithIldpFiltered] = useState([])

  const handleChangeFName = (e) => {
    setFName(e.target.value)
  }
  const handleChangeLName = (e) => {
    setLName(e.target.value)
  }

  /**
   * @async
   * @function
   * @description Handles the search for a username or email address and retrieves the associated user forms and IDLP uploads.
   * @throws {Error} If an error occurs during the search process.
   * @param {string} e - The username or email address to search for.
   * @returns {Promise<void>} A promise that resolves when the search is complete.
   */
  const handleSearchUsername = async (e) => {
    try {
      setIsLoadingTable(true)
      setEmployeeWithIldp([])
      const emailUsername = e.toLowerCase()
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

      function isValidEmail(email) {
        return emailRegex.test(email)
      }

      if (isValidEmail(emailUsername)) {
        const searchUsers = await API.graphql(
          graphqlOperation(UserbyUsername, { username: e })
        )
        const foundUsers = searchUsers.data.UserbyUsername.items

        let items = []
        for (const foundUser of foundUsers) {
          const searchUserForms = await API.graphql(
            graphqlOperation(Form2ByUserId, {
              userID: foundUser.id,
              limit: 1000,
            })
          )

          items.push(...searchUserForms.data.Form2ByUserId.items)
        }

        for (const foundUser of foundUsers) {
          const searchUserForms = await API.graphql(
            graphqlOperation(IDLPUploadByUserId, {
              userID: foundUser.id,
              limit: 1000,
            })
          )

          items.push(...searchUserForms.data.IDLPUploadByUserId.items)
        }

        setEmployeeWithIldp(
          items.sort((a, b) => {
            return moment(new Date(b.etnaId)).diff(moment(new Date(a.etnaId)))
          })
        )
        setIsLoadingTable(false)
      } else {
        console.log('invalid email address')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleSearchDepartment = async (e) => {
    try {
      setIsLoadingTable(true)
      setEmployeeWithIldp([])

      let itemsUsers = []
      let tokenUsers = null

      do {
        const searchUsers = await API.graphql(
          graphqlOperation(UserbyDepartment, {
            assignedDept: e,
            nextToken: tokenUsers,
          })
        )
        const foundUsers = searchUsers.data.UserbyDepartment.items
        itemsUsers.push(...foundUsers)
        tokenUsers = searchUsers.data.UserbyDepartment.nextToken
      } while (tokenUsers)

      let itemsILDP = []
      for (const foundUser of itemsUsers) {
        const searchUserForms = await API.graphql(
          graphqlOperation(IDLPUploadByUserId, {
            userID: foundUser.id,
          })
        )

        itemsILDP.push(...searchUserForms.data.IDLPUploadByUserId.items)
      }

      let itemsForm2 = []
      let tokenForm2 = null

      do {
        const searchUsers = await API.graphql(
          graphqlOperation(Form2ByUserDept, {
            assignedDept: e,
            nextToken: tokenForm2,
          })
        )
        const foundUsers = searchUsers.data.Form2ByUserDept.items
        itemsForm2.push(...foundUsers)
        tokenForm2 = searchUsers.data.Form2ByUserDept.nextToken
      } while (tokenForm2)

      setEmployeeWithIldp(
        [...itemsILDP, ...itemsForm2].sort((a, b) => {
          return moment(new Date(b.etnaId)).diff(moment(new Date(a.etnaId)))
        })
      )

      setIsLoadingTable(false)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @async
   * @function handleSearchNames
   * @description Searches for users and their associated forms based on provided first name and last name variations.
   * @throws {Error} If an error occurs during the search process.
   * @returns {Promise<void>} A promise that resolves once the search is complete.
   */
  const handleSearchNames = async () => {
    try {
      setEmployeeWithIldp([])
      setIsLoadingTable(true)
      let itemsFound = []

      const firstLetterUppercaseLname =
        lName.charAt(0).toUpperCase() + lName.slice(1).toLowerCase()

      const lnameVariations = [
        firstLetterUppercaseLname,
        lName.toLowerCase(),
        lName.toUpperCase(),
      ]

      const fNameParts = fName.trim().split(' ')
      const nameVariations =
        fNameParts.length === 1
          ? [
              fNameParts[0].charAt(0).toUpperCase() +
                fNameParts[0].slice(1).toLowerCase(),
              fNameParts[0].toLowerCase(),
              fNameParts[0].toUpperCase(),
            ]
          : [
              `${
                fNameParts[0].charAt(0).toUpperCase() +
                fNameParts[0].slice(1).toLowerCase()
              } ${
                fNameParts[1].charAt(0).toUpperCase() +
                fNameParts[1].slice(1).toLowerCase()
              }`,
              `${fNameParts[0]} ${fNameParts[1]}`.toLowerCase(),
              `${fNameParts[0]} ${fNameParts[1]}`.toUpperCase(),
              `${
                fNameParts[0].charAt(0).toUpperCase() +
                fNameParts[0].slice(1).toLowerCase()
              } ${fNameParts[1].toLowerCase()}`,
            ]

      for (const nameVariation of nameVariations) {
        for (const lnameVariation of lnameVariations) {
          let nextToken = null

          do {
            const result = await API.graphql(
              graphqlOperation(UserbyName, {
                name: nameVariation,
                filter: {
                  lname: {
                    contains: lnameVariation,
                  },
                },
                limit: 1000,
                nextToken,
              })
            )

            itemsFound = [...itemsFound, ...result.data.UserbyName.items]
            nextToken = result.data.UserbyName.nextToken
          } while (nextToken)
        }
      }

      const uniqueArray = Array.from(
        new Set(itemsFound.map((obj) => obj.id))
      ).map((id) => {
        return itemsFound.find((obj) => obj.id === id)
      })

      let formItems = []
      for (const foundUser of uniqueArray) {
        const searchUserForms = await API.graphql(
          graphqlOperation(Form2ByUserId, { userID: foundUser.id, limit: 1000 })
        )

        formItems.push(...searchUserForms.data.Form2ByUserId.items)
      }

      for (const foundUser of uniqueArray) {
        const searchUserForms = await API.graphql(
          graphqlOperation(IDLPUploadByUserId, {
            userID: foundUser.id,
            limit: 1000,
          })
        )

        formItems.push(...searchUserForms.data.IDLPUploadByUserId.items)
      }

      setEmployeeWithIldp(
        formItems.sort((a, b) => {
          return moment(new Date(b.etnaId)).diff(moment(new Date(a.etnaId)))
        })
      )
      setIsLoadingTable(false)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @async
   * @function handleDeleteUploaded
   * @description Deletes an uploaded ILDP form and updates related data.
   * @throws {Error} If an error occurs during the deletion process.
   * @param {Object} rec - The record containing information about the ILDP form to be deleted.
   * @returns {Promise<void>} A promise that resolves once the deletion and updates are complete.
   */
  const handleDeleteUploaded = async (rec) => {
    try {
      const getUserILDPForm = await API.graphql(
        graphqlOperation(Form2ByUserId, { userID: rec.userID })
      )
      const filterForms = getUserILDPForm.data.Form2ByUserId.items.filter(
        (item) => item.etnaId === rec.etnaId
      )
      const wasDeleted = await API.graphql(
        graphqlOperation(deleteIDLPUpload, { input: { id: rec.id } })
      )

      if (wasDeleted) {
        const promises = filterForms.map(async (item) => {
          const approveILDPForm = await API.graphql(
            graphqlOperation(updateForm2, {
              input: { id: item.id, isApproved: '0' },
            })
          )
          return approveILDPForm
        })

        await Promise.all(promises)

        notification['info']({
          message: 'Information',
          description: 'You have deleted an Uploaded ILDP form.',
          duration: 5,
        })

        const input = {
          id: rec.userID,
          form2: 0,
        }

        const updateUserStep2 = await API.graphql(
          graphqlOperation(updateUser, { input })
        )

        if (updateUserStep2) {
          const arrayPromise = []

          if (employeeWithIldp.length) {
            const indexToDelete = employeeWithIldp.findIndex(
              (item) => item.id === rec.id
            )
            let newArray
            if (indexToDelete !== -1) {
              newArray = employeeWithIldp.splice(indexToDelete, 1)
              arrayPromise.push(setEmployeeWithIldp(newArray))
            }
          }

          const finishedPromises = Promise.all(arrayPromise)

          if (finishedPromises) {
            filterForms.forEach((form) => {
              if (employeeWithIldp.length) {
                const newArray = employeeWithIldp.map((item) => {
                  if (item.id === form.id) {
                    return { ...item, isApproved: '0' }
                  }
                  return item
                })
                setEmployeeWithIldp(newArray)
              }
            })

            const userN = updateUserStep2.data.updateUser
            notification['info']({
              message: 'Information',
              description: `Employee (${userN.name} ${
                userN.mname ? userN.mname : ''
              } ${
                userN.lname
              }) is back to Step 2. Please remind employee to re-login to see the update.`,
              duration: 6,
            })

            if (emailSearch.length) {
              handleSearchUsernameApproval()
            } else {
              handleSearchNames()
            }
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @async
   * @function handleSearchUsernameApproval
   * @description Handles the search for users by username or email address and retrieves their associated forms and IDLP uploads.
   * @throws {Error} If an error occurs during the search or retrieval process.
   * @param {string} emailSearch - The email address or username to search for.
   * @returns {Promise<void>} A promise that resolves when the search and retrieval process is complete.
   */
  const handleSearchUsernameApproval = async () => {
    try {
      setIsLoadingTable(true)
      setEmployeeWithIldp([])
      const emailUsername = emailSearch.toLowerCase()
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

      function isValidEmail(email) {
        return emailRegex.test(email)
      }

      if (isValidEmail(emailUsername)) {
        const searchUsers = await API.graphql(
          graphqlOperation(UserbyUsername, { username: emailUsername })
        )
        const foundUsers = searchUsers.data.UserbyUsername.items
        let items = []
        for (const foundUser of foundUsers) {
          const searchUserForms = await API.graphql(
            graphqlOperation(Form2ByUserId, {
              userID: foundUser.id,
              limit: 1000,
            })
          )

          items.push(...searchUserForms.data.Form2ByUserId.items)
        }

        for (const foundUser of foundUsers) {
          const searchUserForms = await API.graphql(
            graphqlOperation(IDLPUploadByUserId, {
              userID: foundUser.id,
              limit: 1000,
            })
          )

          items.push(...searchUserForms.data.IDLPUploadByUserId.items)
        }

        setEmployeeWithIldp(
          items.sort((a, b) => {
            return moment(new Date(b.etnaId)).diff(moment(new Date(a.etnaId)))
          })
        )
        setIsLoadingTable(false)
      } else {
        console.log('invalid email address')
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @async
   * @function handleApproval
   * @description Handles the approval process for a record.
   * @param {Object} record - The record to be approved.
   * @throws {Error} If an error occurs during the approval process.
   * @returns {Promise<void>} A promise that resolves when the approval process is complete.
   */
  const handleApproval = async (record) => {
    try {
      const getUserILDPForm = await API.graphql(
        graphqlOperation(Form2ByUserId, { userID: record.userID })
      )
      const filterForms = getUserILDPForm.data.Form2ByUserId.items.filter(
        (item) => item.etnaId === record.etnaId
      )

      const promises = filterForms.map(async (item) => {
        const approveILDPForm = await API.graphql(
          graphqlOperation(updateForm2, {
            input: { id: item.id, isApproved: '1' },
          })
        )
        return approveILDPForm
      })
      const results = await Promise.all(promises)

      if (results) {
        notification['success']({
          message: 'Success',
          description: 'You have the employee ILDP. They may proceed to Step 3',
          duration: 5,
        })
        record.isApproved = '1'

        if (emailSearch.length) {
          handleSearchUsernameApproval()
        } else {
          handleSearchNames()
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleOkIldp = () => {
    setIsModalVisible(false)
  }

  const handleCancelIldp = () => {
    setIsModalVisible(false)
  }

  /**
   * @async
   * @function
   * @description Handles the show ILDP event.
   * @throws {Error} If an error occurs while removing buttons.
   * @param {Event} e - The event object.
   * @returns {void}
   */
  const handleShowIldp = (e) => {
    const b = document.getElementsByTagName('button')
    try {
      setTimeout(() => {
        if (b['data[submit]']) {
          b['data[submit]'].remove()
        }

        if (b['data[print]']) {
          b['data[print]'].remove()
        }
      }, 1000)
    } catch (err) {
      console.log(err)
    }

    setIsModalVisible(true)
    setSubmissionDataIldp(JSON.parse(e.form))
    setIsLang(e.filipino)
  }

  /**
   *
   * @function listOfDepartments
   * @description This function retrieves the list of departments from the file.
   * @throws {Error} If there is an error retrieving the list of departments.
   * @returns {Array} The list of departments.
   */
  const dept = listOfDepartments()

  /**
   * @description Represents the columns configuration for a table in the manageILDP component.
   * @returns {Array} An array of column objects.
   */
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '25%',
      render: (text, record) =>
        record.ownedBy ? (
          <span>
            <b>{`${record.ownedBy.name} ${
              record.ownedBy.mname !== null ? record.ownedBy.mname : ''
            } ${record.ownedBy.lname}`}</b>
          </span>
        ) : (
          'record missing'
        ),
      sorter: (a, b) => a.ownedBy?.name?.localeCompare(b.ownedBy?.name),
    },
    {
      title: 'Assigned Department',
      width: '20%',
      filters: dept.sort().map((obj) => ({
        text: obj,
        value: obj,
      })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record) {
          if ('ownedBy' in record) {
            if (record.ownedBy) {
              if ('assignedDept' in record.ownedBy) {
                return record.ownedBy.assignedDept.indexOf(value) === 0
              }
            }
          }
        }
      },
      render: (text, record) =>
        record.ownedBy ? (
          <span color='geekblue'>
            <b>{`${record.ownedBy.assignedDept}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'Division',
      dataIndex: 'division',
      key: 'division',
      width: '15%',
      render: (text, record) =>
        record.ownedBy ? (
          <span color='magenta'>
            <b>{`${record.ownedBy.division}`}</b>
          </span>
        ) : (
          'record missing'
        ),
      sorter: (a, b) => a.ownedBy?.division?.localeCompare(b.ownedBy?.division),
    },
    {
      title: 'Date ILDP Submitted',
      dataIndex: 'date',
      key: 'createdAt',
      width: '10%',
      render: (text, record) => (
        <Tag color='green'>
          {moment(record.updatedAt).format('MMMM D, YYYY h:mm:ss A')}
        </Tag>
      ),
      sorter: (a, b) => a.updatedAt?.localeCompare(b.updatedAt),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'isApproved',
      width: '10%',
      render: (text, record) => {
        return record.isApproved === '1' ? (
          <>
            <Tag color='blue'>Final</Tag>
          </>
        ) : (
          <>
            <Tag color='red'>for Supervisor Approval</Tag>
          </>
        )
      },
      sorter: (a, b) => a.isApproved?.localeCompare(b.isApproved),
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      render: (text, record) =>
        record?.file ? (
          <Col>
            <Row justify='center'>
              <Tooltip placement='top' title={'Open File'}>
                <a rel='noopener noreferrer' href={record.file} target='_blank'>
                  <Button
                    size='medium'
                    style={{ marginTop: '3px', marginBottom: '6px' }}
                  >
                    <FilePdfTwoTone />
                  </Button>
                </a>
              </Tooltip>
            </Row>
            <Row justify='center'>
              <Tooltip placement='left' title={'Delete File'}>
                <Button onClick={() => handleDeleteUploaded(record)}>
                  <DeleteTwoTone twoToneColor='red' />
                </Button>
              </Tooltip>
            </Row>
            {record.isApproved === '1' ? null : (
              <>
                <Row justify='center'>
                  <Popconfirm
                    title={
                      <span>
                        This will approve their ILDP forms and uploaded file/s.
                        Are you sure to continue?
                      </span>
                    }
                    onConfirm={() => handleApproval(record)}
                    placement='top'
                    okText='Yes'
                    cancelText='No'
                  >
                    <Tooltip placement='top' title={'Approve ILDP'}>
                      <Button
                        size='medium'
                        type='primary'
                        style={{ marginTop: '3px', marginBottom: '6px' }}
                      >
                        Approve
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Row>
              </>
            )}
          </Col>
        ) : (
          <>
            <Row justify='center'>
              <div className='p-2'>
                <a
                  rel='noopener noreferrer'
                  href={record.location}
                  target='_blank'
                >
                  <Button
                    size='medium'
                    style={{ marginLeft: '0px', marginTop: '3px' }}
                    onClick={() => handleShowIldp(record)}
                  >
                    <FundViewOutlined />
                  </Button>
                </a>
              </div>
            </Row>
            {record.isApproved === '1' ? null : (
              <>
                <Row justify='center'>
                  <Popconfirm
                    title={
                      <span>
                        This will approve their ILDP forms and uploaded file/s.
                        Are you sure to continue?
                      </span>
                    }
                    onConfirm={() => handleApproval(record)}
                    placement='top'
                    okText='Yes'
                    cancelText='No'
                  >
                    <Tooltip placement='top' title={'Approve ILDP'}>
                      <Button
                        size='medium'
                        type='primary'
                        style={{ marginTop: '3px', marginBottom: '6px' }}
                      >
                        Approve
                      </Button>
                    </Tooltip>
                  </Popconfirm>
                </Row>
              </>
            )}
          </>
        ),
    },
  ]

  /**
   * Handles switching the view by filtering the employeeWithIldp array.
   *
   * @param {boolean} e - A boolean value indicating whether to filter the array or not.
   * @throws {Error} Logs any error that occurs during the execution to the console.
   *
   * @description
   * If the input parameter is truthy, this function filters the employeeWithIldp array
   * to include only items with a __typename of 'IDLPUpload'. The filtered result is then
   * set using setEmployeeWithIldpFiltered. If the input is falsy, it sets an empty array.
   * Any errors during this process are caught and logged to the console.
   */
  const handleSwitchView = (e) => {
    try {
      setIsSwitchView(e)
      if (e) {
        const filteredArray = employeeWithIldp.filter((item) => {
          if (item.__typename === 'IDLPUpload') {
            return item
          }
        })
        setEmployeeWithIldpFiltered(filteredArray)
      } else {
        setEmployeeWithIldpFiltered([])
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <div className='site-layout-background' style={{ padding: 24 }}>
        <Card title='List of Employee Submitted ILDP Forms'>
          {fName.length || lName.length ? null : (
            <Row className={'mb-5'} justify='center'>
              <div className='userHeader'>
                <Search
                  icon='search'
                  size='large'
                  style={{ width: 400 }}
                  placeholder='Enter valid email address / username'
                  onSearch={(e) => handleSearchUsername(e)}
                  onChange={(e) => {
                    setEmailSearch(e.target.value)
                    if (!e.target.value.length) {
                      setEmployeeWithIldp([])
                    }
                  }}
                  allowClear
                />
              </div>
            </Row>
          )}

          {fName.length || lName.length || emailSearch.length ? null : (
            <Row className={'mb-5'} justify='center'>
              <div className='userHeader'>
                <Select
                  showSearch
                  optionFilterProp='children'
                  placeholder='Select department.'
                  onChange={(e) => {
                    if (e == '') {
                      setEmployeeWithIldp([])
                      setIsLoadingTable(true)
                    } else {
                      handleSearchDepartment(e)
                    }
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  id='dept'
                  className='department-search'
                  required
                >
                  <Option value=''>Select department</Option>
                  {dept.sort().map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </Row>
          )}

          {emailSearch.length ? null : (
            <Row className={'mb-5'} justify='center'>
              <Col className='mx-3'>
                <div className='userHeader'>
                  <Input
                    size='large'
                    style={{ width: 400 }}
                    placeholder='Enter first name of Employee'
                    allowClear
                    onChange={(e) => {
                      handleChangeFName(e)
                      if (!e.target.value.length && !lName.length) {
                        setEmployeeWithIldp([])
                        setIsLoadingTable(true)
                      }
                    }}
                  />
                </div>
              </Col>

              <Col className='mx-3'>
                <div className='userHeader'>
                  <Input
                    size='large'
                    style={{ width: 400 }}
                    placeholder='Enter last name of Employee'
                    allowClear
                    onChange={(e) => {
                      handleChangeLName(e)
                      if (!e.target.value.length && !fName.length) {
                        setEmployeeWithIldp([])
                        setIsLoadingTable(true)
                      }
                    }}
                  />
                </div>
              </Col>
              <Col className='mx-3'>
                <Button
                  disabled={!(fName.length && lName.length)}
                  style={{ height: '100%' }}
                  type='default'
                  onClick={handleSearchNames}
                >
                  Search Employee
                </Button>
              </Col>
            </Row>
          )}

          <Row justify='end' className='my-4'>
            <Switch
              onChange={handleSwitchView}
              checkedChildren='view Uploaded ILDP Only'
              unCheckedChildren='view All'
            />
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={isLoadingTable}
                bordered
                rowKey='id'
                columns={columns}
                dataSource={
                  isSwitchView ? employeeWithIldpFiltered : employeeWithIldp
                }
                pagination={{ pageSize: 50 }}
              />
            </Col>
          </Row>
          <Modal
            maskClosable={false}
            destroyOnClose={true}
            title='ILDP Submitted'
            visible={isModalVisible}
            onOk={() => handleOkIldp()}
            onCancel={() => handleCancelIldp()}
            width={1000}
            style={{ top: 20 }}
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            {submissionDataIldp ? (
              isLang === 'YES' ? (
                <>
                  <Form
                    src='https://www.sparksoft-demo.com/formio/ildp-fili'
                    submission={{ data: submissionDataIldp }}
                  />
                </>
              ) : (
                <>
                  <Form
                    src='https://www.sparksoft-demo.com/formio/ildp'
                    submission={{ data: submissionDataIldp }}
                  />
                </>
              )
            ) : null}
          </Modal>
        </Card>
      </div>
    </>
  )
}

export default ManageILDP
