/**
 * Title: activityQuestionModalThreeNov20.js
 * Description: This is a file that contains activity question modal three for nov 20 training
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/activityQuestionModalThreeNov20.js
 **/

import { useState, useEffect, useRef } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  ActivityThreeAnswerByUserID,
  listTrainingVideos,
} from '../../api/queries'
import {
  createActivityThreeAnswer,
  deleteTrainingVideo,
  updateActivityThreeAnswer,
} from '../../api/mutations'

import moment from 'moment'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  PlayCircleFilled,
} from '@ant-design/icons'
import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  Row,
  Col,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'

export default function ActivityQuestionModalThreeNov20({
  setProgressActivity,
  setIsModalActivityOpen,
  setIsUserWatchedCurrentVid,
  userData,
  /*  viewUserAnswer, */
}) {
  const user = userData

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activityOneAnswers, setActivityOneAnswers] = useState({})
  const [activityOneID, setActivityOneID] = useState(null)
  const [isShowActivity, setIsShowActivity] = useState(false)
  const [countCheck, setCountCheck] = useState(0)

  const [isModalVisibleConfirm, setIsModalVisibleConfirm] = useState(false)

  /*  useEffect(() => {
    if (viewUserAnswer.length > 0) {
      setActivityOneAnswers(JSON.parse(viewUserAnswer[0].answer))
    }
  }, [viewUserAnswer]) */

  const submitAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityThreeAnswer, {
          input: {
            id: activityOneID,
            answer: JSON.stringify(activityOneAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: true,
          },
        })
      )

      notification['success']({
        message: 'Success',
        description: 'Your answer is saved as final answer.',
      })
      setIsModalActivityOpen(false)
      setProgressActivity('4')
      setTimeout(() => {
        setIsShowActivity(false)
        setIsModalVisible(false)
      }, 3000)
    } catch (err) {
      console.log(err)
    }
  }

  const updateAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityThreeAnswer, {
          input: {
            id: activityOneID,
            answer: JSON.stringify(activityOneAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const createAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(createActivityThreeAnswer, {
          input: {
            userID: user.id,
            trainingAnnouncementID: '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04',
            answer: JSON.stringify(activityOneAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: false,
          },
        })
      )

      setActivityOneID(submitTimeOnly.data.createActivityThreeAnswer.id)
      setIsShowActivity(true)
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityOneAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityThreeAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityThreeAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setIsShowActivity(false)
        } else {
          setIsShowActivity(true)

          setActivityOneAnswers(JSON.parse(itemsFetched[0].answer))
          if (itemsFetched[0].id !== activityOneID) {
            setActivityOneID(itemsFetched[0].id)
          }
        }
      } else {
        createAnswerItem()
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    /* if (!viewUserAnswer.length) { */
    if (isModalVisible) {
      if (user) {
        getActivityOneAnswerByUserID()
      }
    }
    /*    } */
  }, [isModalVisible])

  useEffect(() => {
    /*   if (!viewUserAnswer.length) { */
    if (countCheck > 0) {
      updateAnswerItem()
    }
    /*     } */
  }, [countCheck])

  useEffect(() => {
    /*  if (!viewUserAnswer.length) { */
    let timeoutId

    const startTimer = () => {
      timeoutId = setTimeout(() => {
        if (isShowActivity && activityOneID) {
          setCountCheck((prevCount) => prevCount + 10000)
        }

        startTimer() // Recursively call startTimer after 10 seconds
      }, 10000)
    }

    startTimer() // Start the initial timer

    return () => {
      clearTimeout(timeoutId) // Cleanup the timer on component unmount
    }
    /*    } */
  }, [isShowActivity, activityOneID])

  useEffect(() => {
    /*   if (!viewUserAnswer.length) { */
    if (isModalVisible) {
      const initialAnswers = {}

      for (let i = 1; i <= questions.length; i++) {
        initialAnswers[`Question${i}`] = ''
      }

      setActivityOneAnswers(initialAnswers)
    }
    /*    } */
  }, [isModalVisible])

  function handleChoiceChange(questionName, choiceValue) {
    // Copy the existing answers object
    const newAnswers = { ...activityOneAnswers }
    // Update the answer for the specific question
    newAnswers[questionName] = choiceValue
    // Set the updated answers object in state

    setActivityOneAnswers(newAnswers)
  }

  const handleSubmitActivityAnswer = async () => {
    try {
      function hasNoLengthInObject(obj) {
        for (const value of Object.values(obj)) {
          if (value === null || value === undefined || value === '') {
            return true
          }
        }
        return false
      }
      const checkObject = hasNoLengthInObject(activityOneAnswers)
      if (checkObject) {
        setIsModalVisibleConfirm(true)
      } else {
        submitAnswerItem()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmitActivityAnswerConfirm = async () => {
    submitAnswerItem()
  }

  const questions = [
    {
      question: 'Please confirm if you`re finished watching Video #1?',
      choices: [{ name: '1', value: 'A', label: 'A.', text: 'Yes' }],
    },
  ]

  return (
    <>
      <Button
        className='bg-success text-white'
        onClick={() => setIsModalVisible(true)}
      >
        {/* {!viewUserAnswer ? 'Go to activity' : 'View'} */}
        Go to activity
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Activity'
        width={1000}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,

          <Button
            /*  hidden={viewUserAnswer.length > 0} */
            key='submit'
            type='primary'
            disabled={isShowActivity ? false : true}
            // loading={loading}
            onClick={handleSubmitActivityAnswer}
          >
            Submit
          </Button>,
        ]}
      >
        <div className='font-Mont'>
          <Row className='mb-3' justify='center'>
            <h3 className='text-center'>CONFIRMATION</h3>
          </Row>
          {/* <Row className='mb-3' justify='left'>
            <h3>Instructions: Let’s refresh! Choose the correct answer.</h3>
          </Row> */}
        </div>
        <div className='font-Mont'>
          {questions.map((questionObj, index) => {
            const questionName = `Question${index + 1}`

            return (
              <div key={index}>
                {activityOneAnswers ? (
                  <div className='mt-3'>
                    <p className='p-0 m-0'>{questionObj.question}</p>
                    {questionObj.choices.length === 0 ? (
                      <TextArea
                        value={activityOneAnswers.Question5}
                        className='w-100 mt-2'
                        style={{ height: '100px' }}
                        onChange={(e) =>
                          handleChoiceChange(questionName, e.target.value)
                        }
                      />
                    ) : null}
                    {questionObj.choices.map((choice, index) => (
                      <div className='d-flex p-1' key={index}>
                        <input
                          onChange={() =>
                            handleChoiceChange(questionName, choice.value)
                          }
                          checked={
                            activityOneAnswers[questionName] === choice.value
                          }
                          value={choice.value}
                          name={questionName}
                          className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                          type='radio'
                          id={`question${questionName}-choice-${choice.value}`}
                        />
                        <label
                          className='m-0 ms-3'
                          htmlFor={`question${questionName}-choice-${choice.value}`}
                        >
                          <strong>
                            <em>{choice.label} </em>
                          </strong>
                          {choice.text}
                        </label>
                      </div>
                    ))}
                  </div>
                ) : null}
              </div>
            )
          })}
        </div>
      </Modal>

      <Modal
        maskClosable={false}
        destroyOnClose={true}
        width={800}
        visible={isModalVisibleConfirm}
        onCancel={() => setIsModalVisibleConfirm(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisibleConfirm(false)}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            /*  disabled={isShowActivity || viewUserAnswer.length ? false : true} */
            // loading={loading}
            onClick={handleSubmitActivityAnswerConfirm}
          >
            Submit
          </Button>,
        ]}
      >
        <Row className='mt-3' justify='center'>
          <h4>You will be submitting an activity with incomplete answer?</h4>
        </Row>
        <Row justify='center'>
          <h4>Click Submit to proceed</h4>
        </Row>
      </Modal>
    </>
  )
}
