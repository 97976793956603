/**
 * Title: userCalendar.js
 * Description: This is a file that contains the components for the user calendar
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/userCalendar.js
 **/

import { useState, useEffect } from 'react'

import { API, graphqlOperation } from 'aws-amplify'

import { getHomeSlidesUploaded, registrationByUserID } from '../../api/queries'

import { Row } from 'antd'

import moment from 'moment'

import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import interactionPlugin from '@fullcalendar/interaction' // needed for dayClick

import ViewMyEventModal from './viewMyEventModal.js'

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

export default function UserCalendar({ userData }) {
  const currentUser = userData

  const [viewCourse, setViewCourse] = useState(null)
  const [openView, setOpenView] = useState(false)
  const [courses, setCourses] = useState([])
  const [allAnnouncements, setAllAnnouncements] = useState([])
  const [toEdit, setToEdit] = useState(null)
  const [listOfUploadedSlides, setListOfUploadedSlides] = useState([])

  const fetchUploadedSlides = async () => {
    const response = await API.graphql({
      ...graphqlOperation(getHomeSlidesUploaded, {
        id: 'b61cc5d7-1319-43aa-8cac-7905666bc520',
      }),
      authMode: 'API_KEY',
    })
    if (response) {
      setListOfUploadedSlides(
        JSON.parse(response.data.getHomeSlidesUploaded.slideImageFileName)
      )
    }
  }

  useEffect(() => {
    fetchUploadedSlides()
  }, [])

  const getMyevents = async () => {
    /* try { */
    const result = await API.graphql(
      graphqlOperation(registrationByUserID, {
        userID: currentUser.id,
      })
    )

    if (result) {
      let holdEvents = []

      result.data.registrationByUserID.items
        .filter((item) => {
          if (item?.isPreAssessmentPassed !== false) {
            return item
          }
        })
        .forEach((i) => {
          const letterItem = i.training || i.onlineTraining

          if (i.id == '2018ebe8-d2ae-414d-92dd-6481f9d75be3') {
            const neededArr = ['a', 'b', 'c', 'd', 'e']

            neededArr.forEach((letter) => {
              if (letter == 'a') {
                holdEvents.push({
                  id: i.id,
                  title: letterItem.title ? letterItem.title : letterItem.date,
                  start: letterItem.date,
                })
              } else if (letter == 'b') {
                holdEvents.push({
                  id: i.id,
                  title: letterItem.title ? letterItem.title : letterItem.date,
                  start: moment(letterItem.date)
                    .add(7, 'days')
                    .format('YYYY-MM-DD'),
                })
              } else if (letter == 'c') {
                holdEvents.push({
                  id: i.id,
                  title: letterItem.title ? letterItem.title : letterItem.date,
                  start: moment(letterItem.date)
                    .add(14, 'days')
                    .format('YYYY-MM-DD'),
                })
              } else if (letter == 'd') {
                holdEvents.push({
                  id: i.id,
                  title: letterItem.title ? letterItem.title : letterItem.date,
                  start: moment(letterItem.date)
                    .add(21, 'days')
                    .format('YYYY-MM-DD'),
                })
              } else if (letterItem && letterItem.title) {
                holdEvents.push({
                  id: i.id,
                  title: letterItem.title,
                  start: moment(letterItem.date)
                    .add(28, 'days')
                    .format('YYYY-MM-DD'),
                })
              } else {
                holdEvents.push({
                  id: i.id,
                  title: letterItem.date,
                  start: moment(letterItem.date)
                    .add(28, 'days')
                    .format('YYYY-MM-DD'),
                })
              }
            })
          } else if (letterItem?.id == 'e817dbf4-9dda-4c94-b851-1a99cdea9e4a') {
            const neededArr = [0, 1, 2, 3, 4, 5, 6, 7]
            neededArr.forEach((n) => {
              if (n === 0) {
                holdEvents.push({
                  id: i.id,
                  title: letterItem.title,
                  start: letterItem.date,
                })
              } else if (n !== 4 && n !== 5) {
                holdEvents.push({
                  id: i.id,
                  title: letterItem.title,
                  start: moment(letterItem.date)
                    .add(n, 'day')
                    .format('YYYY-MM-DD'),
                })
              }
            })
          } else if (letterItem?.arrayOfDates?.length) {
            const parseArrayOfDates = JSON.parse(letterItem.arrayOfDates)
            const consecutiveRanges = []

            // Function to check if two dates are consecutive
            const areConsecutive = (date1, date2) => {
              const diff = new Date(date2) - new Date(date1)
              return diff === 24 * 60 * 60 * 1000 // Difference is 1 day
            }

            // Loop through the sorted dates to identify consecutive ranges
            for (let j = 0; j < parseArrayOfDates.length; j++) {
              const currentRange = [parseArrayOfDates[j]]

              while (
                j + 1 < parseArrayOfDates.length &&
                areConsecutive(parseArrayOfDates[j], parseArrayOfDates[j + 1])
              ) {
                currentRange.push(parseArrayOfDates[j + 1])
                j++
              }

              consecutiveRanges.push(currentRange)
            }
            consecutiveRanges.forEach((range) => {
              const start = range[0]
              const endDate = new Date(range[range.length - 1])
              const end = range.length > 1 ? new Date(endDate) : undefined

              // Add 1 day to the end date conditionally
              if (range.length > 1) {
                end.setDate(end.getDate() + 1)
              }

              const formattedEnd = end
                ? end.toISOString().split('T')[0]
                : undefined

              holdEvents.push({
                id: i.id,
                title: letterItem.title ? letterItem.title : start,
                start,
                end: formattedEnd,
              })
            })
          } else {
            holdEvents.push({
              id: i.id,
              title: letterItem?.title ? letterItem?.title : letterItem?.date,
              start: letterItem?.date,
            })
          }
        })

      setCourses(result.data.registrationByUserID.items)
      setAllAnnouncements(holdEvents)
    }
    /* } catch (err) {
      console.log(err)
    } */
  }

  useEffect(() => {
    getMyevents()
  }, [])

  const eventClick = (e) => {
    const publicId = e.event._def.publicId
    const courseFound = courses.filter((c) => {
      return c.id === publicId
    })

    setToEdit(courseFound[0])
    setViewCourse(courseFound[0].training || courseFound[0].onlineTraining)
    setOpenView(true)
  }

  const handleCourseModal = (e) => {
    setOpenView(e)
    setViewCourse(null)
  }

  const handleHover = (arg) => {
    try {
      tippy(arg.el, {
        content: arg.event._def.title,
      })
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div
      style={{
        padding: 24,
      }}
    >
      <Row justify='center' className='flex-column align-items-center gap-4'>
        <div className='col-md-7 col-12 user-calendar-show'>
          <FullCalendar
            style={{ objectFit: 'contain' }}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView='dayGridMonth'
            // dateClick={e => handleDateClick(e)}
            selectable={false}
            events={allAnnouncements}
            eventClick={(e) => eventClick(e)}
            eventMouseEnter={(e) => handleHover(e)}
          />
        </div>
      </Row>
      <ViewMyEventModal
        userData={userData}
        toEdit={toEdit}
        viewCourse={viewCourse}
        openView={openView}
        setSetOpenView={setOpenView}
        getMyevents={getMyevents}
        setOpenView={(e) => handleCourseModal(e)}
      />
      <Row justify='center' style={{ marginTop: '20px' }}>
        <div className='d-flex align-items-center flex-column gap-3 img-fluid'>
          {/* <img
            src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/slide1'
            className='col-md-7 col-12'
          />
          <img
            src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/slide2'
            className='col-md-7 col-12'
          /> */}
          {listOfUploadedSlides.map((filename) => (
            <img
              key={filename}
              src={`https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/${filename}`}
              className={`col-md-7 col-12 https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/${filename}`}
            />
          ))}
        </div>
      </Row>
    </div>
  )
}
