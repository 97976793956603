/**
 * Title: strategicPerformanceManagementSystem.js
 * Description: This is a file that contains the components for the strategic performance management system
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/strategicPerformanceManagementSystem.js
 **/

/**
 * Changes Made:
 * - 2024.08.27 | Raymart Mojado | add validation on input names show error if there is a non acceptable character
 */

import { useState, useEffect, useRef } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import { listTrainingVideos } from '../../api/queries'
import { deleteTrainingVideo } from '../../api/mutations'

import moment from 'moment'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  PlayCircleFilled,
} from '@ant-design/icons'
import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  Row,
  Col,
} from 'antd'

import VideoPlayer from './videoPlayer'

import './strategicPerformanceManagementSystem.css'
import listOfDepartments from '../../utilities/dept'

const { Option } = Select

export default function StrategicPerformanceManagementSystem({
  refresh,
  userData,
}) {
  const userInfo = userData
  const userFullName =
    userInfo?.name + ' ' + userInfo?.mname + ' ' + userInfo?.lname
  const [name, setName] = useState(userFullName)
  const [department, setDepartment] = useState(userInfo?.assignedDept)
  const [uploadedTrainingVideo, setUploadedTrainingVideo] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [videoKey, setVideoKey] = useState('')
  const [isAgree, setIsAgree] = useState(true)
  const [tabError, setTabError] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const dept = listOfDepartments()
  useEffect(() => {
    fetchUploadedTrainingVideo()
  }, [refresh])

  const fetchUploadedTrainingVideo = async () => {
    try {
      const uploadedTrainingVid = await API.graphql(
        graphqlOperation(listTrainingVideos)
      )
      const uploadedTrainingVidList =
        uploadedTrainingVid.data.listTrainingVideos.items
      setUploadedTrainingVideo(
        uploadedTrainingVidList.sort((a, b) => {
          return a.key.localeCompare(b.key)
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  const dateDisplay = `${
    new Date().getMonth() + 1
  }-${new Date().getDate()}-${new Date().getFullYear()}`

  const handleSelect = (value) => {
    setDepartment(value)
  }

  const handlePlayButton = (key) => {
    setIsModalVisible(true)
    setVideoKey(key)
  }

  const handleChange = (e) => {
    const value = e.target.value
    const regex = /^(?:[a-zA-ZñéáíóúÑÉÁÍÓÚ.\s-']+)$/

    if (!/^(\s)/.test(value)) {
      if (!value.includes('\t') && regex.test(value) && value) {
        setName(value)
        setTabError(false)
        setNameError(false)
        setDisabledButton(false)
      }
      if (value && !regex.test(value)) {
        setNameError('The employee`s name has an invalid characters.')
        setName(value)
        setDisabledButton(true)
      }
      if (value.includes('\t')) {
        setTabError('Tab characters are not allowed.')
        setName(value)
        setDisabledButton(true)
      }
      if (value && value.length >= 256) {
        setNameError(
          'The employee`s name cannot be longer than 256 characters.'
        )
        setName(value)
        setDisabledButton(true)
      }
      if (!value) {
        setName(value)
        setDisabledButton(true)
        setTabError(false)
        setNameError(false)
      }
    }
  }

  return (
    <div className='pb-5'>
      <div className='d-flex flex-wrap gap-5 justify-content-center container'>
        <h1 className='p-5 pb-3 font-Mont d-none d-md-block'>
          Strategic Performance Management System
        </h1>
        <h3 className='p-5 pb-3 font-Mont d-block d-md-none'>
          Strategic Performance Management System
        </h3>
        {uploadedTrainingVideo
          .filter((video) => video.key.toLowerCase().includes('spms part'))
          .map((video) => {
            const videoName = video.key.slice(0, -4) // remove the last 4 characters (i.e. ".mp4") from the file name
            return (
              <>
                <div
                  className='card shadow mt-3 col-md-5 col-12'
                  key={videoName}
                >
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/spms2-thumbnail.png`}
                      alt={video.key}
                      className='w-100'
                    />
                    <div
                      className='playbutton btn cursor-pointer mb-5'
                      onClick={() => handlePlayButton(video.key)}
                      style={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80px',
                        height: '80px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        top: '40%',
                      }}
                    >
                      <PlayCircleFilled
                        style={{ fontSize: '43px', color: '#fff' }}
                      />
                    </div>
                  </div>
                  <div className='card-body'>
                    <h4 className='card-title'>{videoName}</h4>
                    {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                  </div>
                </div>
              </>
            )
          })}
      </div>
      {/* Show the acknowledgement that you will watched the video when play button is clicked */}
      <Modal
        maskClosable={false}
        title='Acknowledgment and Receipt'
        width={1000}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <VideoPlayer
            videoKey={videoKey}
            spms={true}
            name={name}
            department={department}
            setIsModalVisible={setIsModalVisible}
            setName={setName}
            setDepartment={setDepartment}
            isAgree={isAgree}
            disabledButton={disabledButton}
          />,
        ]}
      >
        <div>
          <p>STATEMENT OF CLAIMING VIEW</p>
          <p className='text-justify'>
            I hereby declare and affirm my commitment to the terms and
            conditions set forth in this statement regarding the training video
            material provided to me in this platform (www.qc-hrmd.org), and its
            entirety, by the Human Resource Management Department (HRMD) of the
            Quezon City Government. I acknowledge that the training video
            material is the exclusive property of HRMD, and as such, I fully
            understand and agree to abide by the following terms:
          </p>
          <div className='d-flex'>
            <p className='ms-2 ms-md-5 me-1'>1.</p>
            <p>
              {' '}
              <strong>Ownership:</strong> I recognize that the training video
              material is protected by intellectual property laws and is the
              sole and exclusive property of HRMD. I acknowledge that I have no
              rights or ownership claims over the content contained within the
              training video material.
            </p>
          </div>
          <div className='d-flex'>
            <p className='ms-2 ms-md-5 me-1'>2.</p>
            <p>
              {' '}
              <strong>Non-Duplication:</strong> I solemnly affirm that I will
              not duplicate or reproduce, in whole or in part, the training
              video material provided to me by HRMD. I understand that
              duplication includes but is not limited to copying, recording,
              downloading, or distributing the material through any means or in
              any format without explicit written consent from HRMD.
            </p>
          </div>
          <div className='d-flex'>
            <p className='ms-2 ms-md-5 me-1'>3.</p>
            <p>
              {' '}
              <strong>Confidentiality:</strong> I acknowledge and respect that
              the training video material may contain confidential and
              proprietary information belonging to HRMD. I commit to maintaining
              the strictest confidentiality regarding the content, concepts,
              techniques, and methodologies presented in the training video
              material.
            </p>
          </div>
          <div className='d-flex'>
            <p className='ms-2 ms-md-5 me-1'>4.</p>
            <p>
              {' '}
              <strong>Personal Use:</strong> I understand that the training
              video material is solely intended for my personal use, learning,
              and development. I agree not to share, lend, or otherwise make the
              training video material available to any third party, whether for
              commercial or non-commercial purposes, without the express written
              consent of HRMD.
            </p>
          </div>
          <div className='d-flex'>
            <p className='ms-2 ms-md-5 me-1'>5.</p>
            <p>
              {' '}
              <strong>Legal Consequences:</strong> I acknowledge that any
              unauthorized duplication or reproduction of the training video
              material may result in legal consequences, including but not
              limited to civil lawsuits and monetary damages. I accept full
              responsibility for any such consequences resulting from my
              violation of this agreement.
            </p>
          </div>
          <div className='d-flex'>
            <p className='ms-2 ms-md-5 me-1'>6.</p>
            <p>
              {' '}
              <strong>Compliance:</strong> I commit to cooperating with HRMD and
              its representatives in any investigation of suspected violation of
              this agreement. I understand that HRMD reserves the right to take
              appropriate legal action to enforce these terms and protect its
              rights.
            </p>
          </div>
          <p>
            By inputting my full name and department/office in this statement, I
            acknowledge that I have read and understood the terms outlined above
            and that I will comply with them strictly. I further acknowledge
            that any breach of this agreement may result in severe legal
            consequences.
          </p>
          <div className='d-flex'>
            <label className='d-flex items-align-center gap-2'>
              <input
                type='checkbox'
                checked={!isAgree}
                onChange={(e) => setIsAgree(!isAgree)}
              />
              I agree
            </label>
          </div>
        </div>

        <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
          <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
            <Input
              value={name}
              onChange={handleChange}
              placeholder='Enter your name'
              className='text-center'
              maxLength={256}
            />
            {tabError && (
              <div style={{ color: 'red', fontSize: '12px' }}>{tabError}</div>
            )}
            {nameError && (
              <div style={{ color: 'red', fontSize: '12px' }}>{nameError}</div>
            )}
            <span className='text-center'>Employee's Name & Signature</span>
          </div>
          <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
            <span>{dateDisplay}</span>
            <span>Date</span>
          </div>
          <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
            <Select
              value={department}
              onChange={handleSelect}
              className='w-100 text-center'
            >
              <Option value='disabled' disabled>
                Disabled
              </Option>
              {dept.sort().map((item) => (
                <Option key={item} value={item}>
                  {item}
                </Option>
              ))}
            </Select>
            <span className='text-center'>Department</span>
          </div>
        </div>
      </Modal>
    </div>
  )
}
