import React from 'react'
import { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateOnlineTrainingAnnouncement } from '../../api/mutations'
import { notification } from 'antd'
import { dateFormatter } from '../../utilities/dateFormatter'

export default function Comments({ training, userData }) {
  const [comments, setComments] = useState(
    training.questionAndAnswerObject
      ? JSON.parse(training.questionAndAnswerObject)
      : []
  )
  const [newComment, setNewComment] = useState('')
  const [replyContents, setReplyContents] = useState({})

  const refetchComments = async () => {
    try {
      const updatedTraining = await API.graphql(
        graphqlOperation(updateOnlineTrainingAnnouncement, {
          input: { id: training.id },
        })
      )
      setComments(
        JSON.parse(
          updatedTraining.data.updateOnlineTrainingAnnouncement
            .questionAndAnswerObject
        )
      )
    } catch (error) {
      console.error('Error fetching comments:', error)
    }
  }

  useEffect(() => {
    //refetch every 15 seconds
    const interval = setInterval(() => {
      refetchComments()
    }, 15000)

    return () => clearInterval(interval)
  }, [])

  const handleAddComment = async () => {
    const firstNameInitial = userData ? userData.name.charAt(0) : ''
    const lastNameInitial = userData ? userData.lname.charAt(0) : ''
    const avatarInitials = firstNameInitial + lastNameInitial

    const newCommentObj = {
      id: Date.now(), // You can generate unique IDs using timestamps
      author: {
        name: userData ? userData.name + ' ' + userData.lname : 'Anonymous', // Get name from userData or set default
        avatar: avatarInitials.toUpperCase(), // Use initials as avatar
        timestamp: new Date().toISOString(), // Timestamp of the comment
      },
      content: newComment,
      replies: [], // Nested comments/replies
    }

    const updatedComments = [...comments, newCommentObj]

    const input = {
      id: training.id,
      questionAndAnswerObject: JSON.stringify(updatedComments),
    }

    try {
      await API.graphql(
        graphqlOperation(updateOnlineTrainingAnnouncement, { input })
      )
      /*   notification['success']({
        message: 'Success',
        description: 'Your comment has been added successfully',
      }) */
      setComments(updatedComments)
      setNewComment('') // Clear the input field after posting comment
    } catch (error) {
      console.error('Error adding comment:', error)
      notification['error']({
        message: 'Error',
        description: 'Failed to add your comment. Please try again later.',
      })
    }
  }

  const handleReply = async (commentId) => {
    const newReply = {
      id: Date.now(),
      author: {
        name: 'SHRU Moderator',
        avatar: 'A',
        timestamp: new Date().toISOString(),
      },
      content: replyContents[commentId] || '',
    }

    const updatedComments = comments.map((comment) => {
      if (comment.id === commentId) {
        return {
          ...comment,
          replies: [...comment.replies, newReply],
        }
      }
      return comment
    })

    const input = {
      id: training.id,
      questionAndAnswerObject: JSON.stringify(updatedComments),
    }

    try {
      await API.graphql(
        graphqlOperation(updateOnlineTrainingAnnouncement, { input })
      )
      /*  notification['success']({
        message: 'Success',
        description: 'Your reply has been added successfully',
      }) */
      setComments(updatedComments)
      setReplyContents({
        ...replyContents,
        [commentId]: '', // Clear the specific reply input
      })
    } catch (error) {
      console.error('Error adding reply:', error)
      notification['error']({
        message: 'Error',
        description: 'Failed to add your reply. Please try again later.',
      })
    }
  }

  const handleCommentChange = (e) => {
    setNewComment(e.target.value)
  }

  const handleReplyChange = (commentId, e) => {
    setReplyContents({
      ...replyContents,
      [commentId]: e.target.value,
    })
  }

  return (
    <section className=''>
      <div className='container py-3'>
        <div className='row justify-content-center'>
          <div className=''>
            <div className='card'>
              <div className='card-body'>
                <div className='d-flex flex-start align-items-center'>
                  <img
                    className='rounded-circle shadow-1-strong me-3'
                    src='./Quezon_City.svg'
                    alt='avatar'
                    width='60'
                    height='60'
                  />
                  <div>
                    <h6 className='fw-bold text-primary mb-1'>HRMD Admin</h6>
                    <p className='text-muted small mb-0'>
                      {dateFormatter(training.createdAt)}
                    </p>
                  </div>
                </div>

                <p className='mt-3 mb-4 pb-2'>
                  You can ask questions or share your thoughts here.
                </p>
              </div>

              <div className='card-footer py-3 border-0'>
                <div className='d-flex flex-start w-100'>
                  <div
                    className='rounded-circle mt-4 shadow-1-strong me-3 bg-primary text-white d-flex justify-content-center align-items-center'
                    style={{ width: '50px', height: '45px' }}
                  >
                    {userData
                      ? (
                          userData.name.charAt(0) + userData.lname.charAt(0)
                        ).toUpperCase()
                      : ''}
                  </div>
                  <textarea
                    onChange={handleCommentChange}
                    value={newComment}
                    className='form-control w-100'
                    id='textAreaExample'
                    rows='4'
                    placeholder='Message'
                  ></textarea>
                </div>
                <div className='float-end d-flex mt-2 pt-1'>
                  <button
                    disabled={!newComment}
                    onClick={handleAddComment}
                    className='btn btn-primary btn-sm me-1 px-4 text-nowrap'
                  >
                    Post comment
                  </button>
                </div>
              </div>
              {/* Comment display */}
              <div className='card-body'>
                {comments &&
                  comments
                    .slice()
                    .reverse()
                    .map((comment) => (
                      <div key={comment.id} className='mb-4'>
                        <div className='d-flex flex-start align-items-center'>
                          <div
                            className='rounded-circle shadow-1-strong me-3 bg-primary text-white d-flex justify-content-center align-items-center'
                            style={{ width: '40px', height: '40px' }}
                          >
                            {comment.author.avatar}
                          </div>
                          <div>
                            <h6 className='fw-bold text-primary mb-1'>
                              {comment.author.name}
                            </h6>
                            <p className='text-muted small mb-0'>
                              {dateFormatter(comment.author.timestamp)}
                            </p>
                          </div>
                        </div>
                        <p className='mt-3 mb-4 pb-2'>{comment.content}</p>

                        {comment.replies &&
                          comment.replies.map((reply) => (
                            <div key={reply.id} className='ms-5'>
                              <div className='d-flex flex-start align-items-center'>
                                <img
                                  className='qclogo img-fluid rounded-circle shadow-1-strong me-3 text-white d-flex justify-content-center align-items-center'
                                  style={{ width: '30px', height: '30px' }}
                                  src='./Quezon_City.svg'
                                  alt='QC–LGU Seal'
                                />
                                <div>
                                  <h6 className='fw-bold text-primary mb-1'>
                                    {reply.author.name}
                                  </h6>
                                  <p className='text-muted small mb-0'>
                                    {dateFormatter(reply.author.timestamp)}
                                  </p>
                                </div>
                              </div>
                              <p className='mt-3 mb-2 pb-1'>{reply.content}</p>
                            </div>
                          ))}

                        {/* Add comment field */}
                        {userData?.role === 'admin' && (
                          <div className='mb-3'>
                            <textarea
                              onChange={(e) => handleReplyChange(comment.id, e)}
                              value={replyContents[comment.id] || ''}
                              className='form-control'
                              rows='2'
                              placeholder='Add a reply...'
                            ></textarea>
                            <div className='d-flex mt-2 pt-1 justify-content-end'>
                              <button
                                disabled={!replyContents[comment.id]}
                                onClick={() => handleReply(comment.id)}
                                className='btn btn-primary btn-sm me-1 px-4 text-nowrap'
                                style={{ width: '130px' }}
                              >
                                Reply
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
