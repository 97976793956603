/**
 * Title: activityQuestionModalThreeSept15.js
 * Description: This is a file that contains activity question modal three for sept 15 training
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/activityQuestionModalThreeSept15.js
 **/

import { useState, useEffect, useRef } from 'react'

import { Modal, Row, Button, Col, notification, Input } from 'antd'

import { ActivityThreeAnswerByUserID } from '../../api/queries'
import {
  createActivityThreeAnswer,
  updateActivityThreeAnswer,
} from '../../api/mutations'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import moment from 'moment'

export default function ActivityQuestionModalThreeSept15({
  setProgressActivity,
  setIsModalActivityOpen,
  userData,
}) {
  const user = userData

  const quillRef = useRef(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activityAnswer, setActivityAnswer] = useState(null)
  const [activityAnswerTwo, setActivityAnswerTwo] = useState(null)
  const [activityThreeID, setActivityThreeID] = useState(null)
  const [isShowActivity, setIsShowActivity] = useState(false)
  const [countCheck, setCountCheck] = useState(0)

  const [caseScenario, setCaseScenario] = useState(null)
  const [uploadedFile, setUploadedFile] = useState(null)

  const submitAnswerItem = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })

      const params = {
        Bucket: 's3etnahris133956-dev',
        Key: `${user.id}/${moment().valueOf()}-${uploadedFile.name}`,
        Body: uploadedFile,
        ACL: 'public-read',
      }

      const command = new PutObjectCommand(params)
      const result = await s3.send(command)
      const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

      if (result) {
        const submitTimeOnly = await API.graphql(
          graphqlOperation(updateActivityThreeAnswer, {
            input: {
              id: activityThreeID,
              answer: JSON.stringify({
                caseScenario: caseScenario,
                location: url,
              }),
              timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
              isSubmittedByUser: true,
            },
          })
        )

        notification['success']({
          message: 'Success',
          description: 'Your answer is saved as final answer.',
        })
        setIsModalActivityOpen(false)
        setProgressActivity('alldone')
        setTimeout(() => {
          setIsShowActivity(false)
          setIsModalVisible(false)
        }, 3000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const updateAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityThreeAnswer, {
          input: {
            id: activityThreeID,
            answer: JSON.stringify({
              caseScenario: caseScenario,
              location: null,
            }),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const createAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(createActivityThreeAnswer, {
          input: {
            userID: user.id,
            trainingAnnouncementID: 'acb7d867-7114-49a5-81f0-8ee28eb2828d',
            answer: JSON.stringify({
              caseScenario: caseScenario,
              location: null,
            }),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: false,
          },
        })
      )

      setActivityThreeID(submitTimeOnly.data.createActivityThreeAnswer.id)
      setIsShowActivity(true)
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityThreeAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityThreeAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityThreeAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            'acb7d867-7114-49a5-81f0-8ee28eb2828d'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setIsShowActivity(false)
        } else {
          setIsShowActivity(true)
          setActivityAnswer(JSON.parse(itemsFetched[0].answer).answerOne)
          setActivityAnswerTwo(JSON.parse(itemsFetched[0].answer).answerTwo)
          if (itemsFetched[0].id !== activityThreeID) {
            setActivityThreeID(itemsFetched[0].id)
          }
        }
      } else {
        createAnswerItem()
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (isModalVisible) {
      if (user) {
        getActivityThreeAnswerByUserID()
      }
    }
  }, [isModalVisible])

  useEffect(() => {
    if (countCheck > 0) {
      updateAnswerItem()
    }
  }, [countCheck])

  useEffect(() => {
    let timeoutId

    const startTimer = () => {
      timeoutId = setTimeout(() => {
        if (isShowActivity && activityThreeID) {
          setCountCheck((prevCount) => prevCount + 10000)
        }

        startTimer() // Recursively call startTimer after 10 seconds
      }, 10000)
    }

    startTimer() // Start the initial timer

    return () => {
      clearTimeout(timeoutId) // Cleanup the timer on component unmount
    }
  }, [isShowActivity, activityThreeID])

  const handleActivityAnswer = (content) => {
    setActivityAnswer(content)
  }

  const handleActivityAnswerTwo = (content) => {
    setActivityAnswerTwo(content)
  }

  const handleSubmitActivityAnswer = async () => {
    try {
      if (uploadedFile) {
        if (caseScenario) {
          submitAnswerItem()
        } else {
          notification['error']({
            message: 'Error',
            description: 'Must select a case scenario.',
          })
        }
      } else {
        notification['error']({
          message: 'Error',
          description: 'Must have a file to proceed.',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleRadio = (e) => {
    setCaseScenario(e.target.id)
  }

  return (
    <div>
      <Button
        className='bg-success text-white w-100'
        onClick={() => setIsModalVisible(true)}
      >
        Go to activity
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Activity'
        width={1000}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={isShowActivity ? false : true}
            // loading={loading}
            onClick={handleSubmitActivityAnswer}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify='center' className='font-Mont'>
          <Col span={24}>
            <Row className='mb-3' justify='center'>
              <h3 className='text-center'>ACTIVITY 3 for MODULE 3</h3>
            </Row>

            {isShowActivity ? (
              <>
                <Row className='mb-3' justify='left'>
                  <h3>Instructions: </h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <p className='mb-2 activity-font'>
                    The following instructions outline four distinct case
                    scenarios. Within the context of your department, office, or
                    unit (DOU), please select one of these scenarios and compose
                    a Complete Staff Work (CSW) in a Word document. Submit your
                    CSW document via the provided upload feature.
                  </p>
                </Row>
                <Row className='mb-3' justify='left'>
                  <h3>Case Scenario #1: Training Endorsement</h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <p className='mb-2 activity-font'>
                    In your capacity as the HR representative or administrative
                    personnel for your DOU, craft an endorsement letter using a
                    CSW. Enumerate the names of the five employees within your
                    DOU who express interest in attending the "The Value of
                    Values: Improving Ethics in Public Service via Zoom"
                    training, scheduled for September 19-20, 2023.
                  </p>
                </Row>
                <Row className='mb-3' justify='left'>
                  <h3>Case Scenario #2: Study Leave Application</h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <p className='mb-2 activity-font'>
                    In this scenario, you express a desire to pursue a
                    three-month study leave with the purpose of completing your
                    chosen course's dissertation. Having served diligently as a
                    plantilla employee for a tenure of two years and one month,
                    your record reflects consistent excellence, underscored by
                    two consecutive months of achieving a "Very Satisfactory"
                    rating. It is important to note that in the event of
                    approval, you would be subject to a service obligation of
                    two (2) years, in accordance with the guidelines established
                    by the Commission of Civil Service. Consequently, your task
                    entails the preparation of a CSW aimed at formally
                    submitting your study leave application.
                  </p>
                </Row>
                <Row className='mb-3' justify='left'>
                  <h3>Case Scenario #3: Travel Authority Request</h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <p className='mb-2 activity-font'>
                    For personal reasons, you have scheduled a trip to Bali,
                    Indonesia, from November 14-18, 2023, whether for a family
                    vacation or to attend a convention. Draft a CSW in which you
                    request a travel authority for this forthcoming journey.
                  </p>
                </Row>
                <Row className='mb-3' justify='left'>
                  <h3>Case Scenario #4: Proposal for Departmental Training</h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <p className='mb-2 activity-font'>
                    You have the opportunity to propose a training program that
                    involves a training budget for 25 employees to be conducted
                    within your department. Please create a CSW outlining the
                    significance of this training and the rationale for its
                    implementation.
                  </p>
                </Row>

                <Row>
                  <span style={{ color: 'red' }}>**</span>
                  <span>Select a case scenario before uploading:</span>
                </Row>

                <Row className='mb-5'>
                  <div className='d-flex' onChange={handleRadio}>
                    <input
                      id='caseScenario-1'
                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                      type='radio'
                      name='caseScenario'
                    />
                    <label className='me-4' htmlFor='caseScenario-1'>
                      <b>Case # 1</b>
                    </label>
                    <input
                      id='caseScenario-2'
                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                      type='radio'
                      name='caseScenario'
                    />
                    <label className='me-4' htmlFor='caseScenario-2'>
                      <b>Case # 2</b>
                    </label>
                    <input
                      id='caseScenario-3'
                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                      type='radio'
                      name='caseScenario'
                    />
                    <label className='me-4' htmlFor='caseScenario-3'>
                      <b>Case # 3</b>
                    </label>
                    <input
                      id='caseScenario-4'
                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                      type='radio'
                      name='caseScenario'
                    />
                    <label className='me-4' htmlFor='caseScenario-4'>
                      <b>Case # 4</b>
                    </label>
                  </div>
                </Row>

                <Row>
                  <input
                    id='activityThreeUpload'
                    name='activityThreeUpload'
                    type='file'
                    accept='.pdf,.doc,.docx'
                    /*  accept='application/pdf,application/doc,application/docx' */
                    onChange={(e) => {
                      if (e.target.files[0].size <= 5 * 1024 * 1024) {
                        setUploadedFile(e.target.files[0])
                        console.log(e.target.files[0])
                      } else {
                        notification['error']({
                          message: 'Error',
                          description:
                            'File size limit of 5MB is exceeded. Upload a different file.',
                        })
                      }
                    }}
                  />
                </Row>
              </>
            ) : (
              <>
                <Row className='mb-3' justify='left'>
                  <h3>Activity answer was already submitted as final.</h3>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
