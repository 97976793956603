/**
 * Title: homeContent.js
 * Description: This is a file that contains the home content page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/Homepage/homeContent.js
 **/
import { useEffect, useRef, useState } from 'react'

import {
  Button,
  Card,
  Carousel,
  Col,
  Input,
  Modal,
  Row,
  Switch,
  Upload,
  notification,
} from 'antd'
import { API, Auth, Storage, graphqlOperation } from 'aws-amplify'
import PropTypes from 'prop-types'

import {
  LeftCircleOutlined,
  RightCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

import {
  updateHomeCardAnnouncement,
  updateHomeSlidesUploaded,
} from './../../../api/mutations'
import {
  getHomeCardAnnouncement,
  getHomeCardRegistration,
  getHomeSlidesUploaded,
  getHomeUpcomingTrainingCard,
} from './../../../api/queries'
import RegistrationCardEdit from './../registrationCardEdit.js'
import UpcomingTrainingCardEdit from './../upcomingTrainingCardEdit.js'

const contentStyle = {
  height: '500px',
  width: '500px',
  color: '#fff',
  textAlign: 'center',
  background: '#364d79',
}

const { TextArea } = Input

export default function HomeContent({ afterSubmit, goto, userData }) {
  const currentUser = userData

  const slider = useRef()

  const [showSlide, setShowSlide] = useState(false)
  const [showAnnouncement, setShowAnnouncement] = useState(false)

  const [myHomeSlides, setMyHomeSlides] = useState([])
  const [myDots, setMyDots] = useState([])

  const [openUpper, setOpenUpper] = useState(false)
  const [upperCardAnnc, setUpperCardAnnc] = useState(null)
  const [tempUpperAnnc, setTempUpperAnnc] = useState(null)
  const [lowerCardAnnc, setLowerCardAnnc] = useState(null)
  const [lowerCardLoc, setLowerCardLoc] = useState(null)
  const [tempLowerAnnc, setTempLowerAnnc] = useState(null)
  const [anncFileUpload, setAnncFileUpload] = useState(null)
  const [hideLower, setHideLower] = useState(false)

  const [registrationEditOpen, setRegistrationEditOpen] = useState(false)
  const [uTEditOpen, setUTEditOpen] = useState(false)

  const [regCardText, setRegCardText] = useState(null)
  const [regCardEdited, setRegCardEdited] = useState(null)

  const [uTCardText, setUTCardText] = useState(null)
  const [uTCardEdited, setUTCardEdited] = useState(null)

  const [listOfUploadedSlides, setListOfUploadedSlides] = useState([])
  const [imageSlides, setImageSlides] = useState([])
  const [dotSlides, setDotSlides] = useState([])
  const [editSlidesModal, setEditSlidesModal] = useState(false)
  const [imagesToShowInModal, setImagesToShowInModal] = useState([])

  const getRegistrationCardText = async () => {
    try {
      const result = await API.graphql({
        query: getHomeCardRegistration,
        authMode: 'API_KEY',
        variables: {
          id: 'a3a5525e-c96d-4818-9085-0ecc3d98683e',
        },
      })

      setRegCardText(result.data.getHomeCardRegistration.upper)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getRegistrationCardText()
  }, [regCardEdited])

  const getUTCardText = async () => {
    try {
      const result = await API.graphql({
        query: getHomeUpcomingTrainingCard,
        authMode: 'API_KEY',
        variables: {
          id: '30cb7bc3-55de-4386-ae72-69f6cfe0501a',
        },
      })

      setUTCardText(result.data.getHomeUpcomingTrainingCard.textDisplay)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUTCardText()
  }, [uTCardEdited])

  const handleShowSlide = (a) => {
    if (a == 'register') {
      goto('trainingPrograms')
    }
  }

  const handleCloseSlide = () => {
    setTimeout(() => {
      setShowSlide(false)
    }, 0)
  }

  const getHomeCardAnnc = async () => {
    try {
      const result = await API.graphql({
        query: getHomeCardAnnouncement,
        authMode: 'API_KEY',
        variables: {
          id: '4f5c6e17-56b3-47eb-8727-b34de808825d',
        },
      })
      const dataAnnc = result.data.getHomeCardAnnouncement
      setUpperCardAnnc(dataAnnc.upper)
      setLowerCardAnnc(dataAnnc.lower)
      setLowerCardLoc(dataAnnc.lowerLocation)
      setHideLower(dataAnnc.hideLower)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getHomeCardAnnc()

    let slides = document.getElementsByClassName('mySlides')
    setMyHomeSlides(slides)
    let dots = document.getElementsByClassName('dot')
    setMyDots(dots)
  }, [])

  useEffect(() => {
    if (myHomeSlides.length && myDots.length) {
      showSlides()
    }
  }, [myHomeSlides, myDots, listOfUploadedSlides, imageSlides, dotSlides])

  /* window.onscroll = function () {
    scrollFunction()
  }

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      //document.getElementById('header-box').style.top = '10'
    } else {
      //document.getElementById('header-box').style.top = '-200px'
    }
  } */

  let slideIndex = 0
  let slideIndexCurrent = 0

  /*** CURRENT SLIDE on click ***/
  function currentSlide(n) {
    slideIndexCurrent = n
    showSlidesCurrent(slideIndexCurrent)
  }

  function showSlidesCurrent(n) {
    //let slideIndex = 1;
    let i
    if (n > myHomeSlides.length) {
      slideIndexCurrent = 1
    }

    if (n < 0) {
      slideIndexCurrent = myHomeSlides.length
    }
    for (i = 0; i < myHomeSlides.length; i++) {
      myHomeSlides[i].style.display = 'none'
    }
    slideIndex++
    for (i = 0; i < myDots.length; i++) {
      myDots[i].className = myDots[i].className.replace(' active', '')
    }
    myHomeSlides[slideIndexCurrent - 1].style.display = 'block'
    myDots[slideIndexCurrent - 1].className += ' active'
    slideIndexCurrent++
  }

  /*** SHOW SLIDE with timer ***/
  function showSlides() {
    let i
    if (myHomeSlides.length && myDots.length) {
      for (i = 0; i < myHomeSlides.length; i++) {
        myHomeSlides[i].style.display = 'none'
      }
      slideIndex++
      if (slideIndex > myHomeSlides.length) {
        slideIndex = 1
      }
      for (i = 0; i < myDots.length; i++) {
        myDots[i].className = myDots[i].className.replace(' active', '')
      }
      myHomeSlides[slideIndex - 1].style.display = 'block'
      myDots[slideIndex - 1].className += ' active'

      setTimeout(showSlides, 3000) // Change image every x seconds
    }
  }

  const editUpper = () => {
    setOpenUpper(true)
  }

  const saveUpper = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })

      if (anncFileUpload) {
        setUpperCardAnnc(tempUpperAnnc)
        setTempUpperAnnc(null)

        setLowerCardAnnc(tempLowerAnnc)
        setTempLowerAnnc(null)

        setOpenUpper(false)

        const params = {
          Bucket: 's3etnahris133956-dev',
          Key: `uploadedByAdmin/${anncFileUpload.name}`,
          Body: anncFileUpload,
          ACL: 'public-read',
        }

        const command = new PutObjectCommand(params)
        await s3.send(command)
        const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

        setLowerCardLoc(url)

        const adminUpload = await API.graphql(
          graphqlOperation(updateHomeCardAnnouncement, {
            input: {
              id: '4f5c6e17-56b3-47eb-8727-b34de808825d',
              upper: tempUpperAnnc,
              lower: tempLowerAnnc,
              lowerLocation: url,
              hideLower: hideLower,
              userID: currentUser.id,
            },
          })
        )

        if (adminUpload) {
          notification['success']({
            message: 'Success',
            description: 'Announcement was edited.',
          })
        }
        return
      }

      if (hideLower === true) {
        if (tempUpperAnnc == null) {
          notification['error']({
            message: 'Error',
            description: 'Nothing to save.',
          })
          return
        }

        if (tempUpperAnnc.length) {
          setUpperCardAnnc(tempUpperAnnc)
          setTempUpperAnnc(null)

          setLowerCardAnnc(tempLowerAnnc)
          setTempLowerAnnc(null)

          setOpenUpper(false)

          setLowerCardLoc('')

          const adminUpload = await API.graphql(
            graphqlOperation(updateHomeCardAnnouncement, {
              input: {
                id: '4f5c6e17-56b3-47eb-8727-b34de808825d',
                upper: tempUpperAnnc,
                lower: tempLowerAnnc,
                lowerLocation: '',
                hideLower: hideLower,
                userID: currentUser.id,
              },
            })
          )

          if (adminUpload) {
            notification['success']({
              message: 'Success',
              description: 'Announcement was edited.',
            })
          }
        } else {
          notification['error']({
            message: 'Error',
            description: 'Nothing to save.',
          })
        }
      } else {
        notification['error']({
          message: 'Error',
          description: "You haven't chose a file yet.",
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleTempUpperAnnc = (e) => {
    setTempUpperAnnc(e.target.value)
  }

  const handleTempLowerAnnc = (e) => {
    setTempLowerAnnc(e.target.value)
  }

  const handleModalAnnc = () => {
    setTimeout(() => {
      setShowAnnouncement(false)
    }, 100)
  }

  const editOrProceed = () => {
    if (currentUser) {
      if (currentUser.role == 'admin') {
        setRegistrationEditOpen(true)
      } else {
        handleShowSlide('register')
      }
    } else {
      handleShowSlide('register')
    }
  }

  const editOrProceedUT = () => {
    if (currentUser) {
      if (currentUser.role == 'admin') {
        setUTEditOpen(true)
      } else {
        handleShowSlide('register')
      }
    } else {
      handleShowSlide('register')
    }
  }

  const highestNumber = findHighestNumber()
  const availableNumber = findAvailableNumber(highestNumber)

  const fetchUploadedSlides = async () => {
    const response = await API.graphql({
      ...graphqlOperation(getHomeSlidesUploaded, {
        id: 'b61cc5d7-1319-43aa-8cac-7905666bc520',
      }),
      authMode: 'API_KEY',
    })
    if (response) {
      setListOfUploadedSlides(
        JSON.parse(response.data.getHomeSlidesUploaded.slideImageFileName)
      )
    }
  }

  useEffect(() => {
    fetchUploadedSlides()
  }, [])

  const uploadPictureSlides = async (file) => {
    if (!file) {
      return console.log('please choose one photo')
    }

    await Storage.put(`homePictureSlides/slide${availableNumber}`, file)

    const response = await API.graphql(
      graphqlOperation(updateHomeSlidesUploaded, {
        input: {
          id: 'b61cc5d7-1319-43aa-8cac-7905666bc520',
          slideImageFileName: JSON.stringify([
            ...listOfUploadedSlides,
            `slide${availableNumber}`,
          ]),
        },
      })
    )
    if (response) {
      fetchUploadedSlides()
      notification['success']({
        message: 'Success',
        description: 'Successfuly Uploaded!',
      })
    }
  }

  const mapEachPicture = () => {
    const slides = []
    listOfUploadedSlides.forEach((fileName) => {
      const slideUrl = `https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/${fileName}`
      slides.push(
        <div className={'mySlides fade'} key={fileName}>
          <div className='image-container'>
            <img
              id={slideUrl}
              className={`image-slide ${slideUrl}`}
              src={slideUrl}
              alt=''
            />
          </div>
        </div>
      )
    })
    setImageSlides(slides)
  }

  const mapEachPictureInModal = () => {
    const slides = []
    listOfUploadedSlides.forEach((fileName) => {
      const slideUrl = `https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/${fileName}`
      slides.push(
        <div key={fileName}>
          <div className='image-container'>
            <img className={`image-slide  img-fluid`} src={slideUrl} alt='' />
            {currentUser?.role?.toLowerCase() === 'admin' && (
              <div className='middle d-flex flex-column align-items-center justify-content-center gap-2'>
                <Upload
                  accept='.jpeg,.jpg,.png'
                  maxCount={1}
                  showUploadList={false}
                  onChange={(info) =>
                    updateHomeSlide(info.fileList[0], fileName, slideUrl)
                  }
                >
                  <Button className='upload-button d-flex align-items-center bg-success text-white'>
                    Change Image
                  </Button>
                </Upload>
                {/* {fileName !== "slide1" || fileName !== "slide2" || fileName !== "slide5" && */}
                <Button
                  onClick={() => deleteHomeSlide(fileName)}
                  className='d-flex align-items-center bg-danger text-white'
                >
                  Delete Image
                </Button>
                {/* } */}
              </div>
            )}
          </div>
        </div>
      )
    })
    setImagesToShowInModal(slides)
  }

  const mapDots = () => {
    const dots = []
    for (let i = 1; i < listOfUploadedSlides.length + 1; i++) {
      dots.push(
        <button
          className={`border-0 dot${i === 1 ? ' active' : ''}`}
          onClick={() => currentSlide(i + 1)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              currentSlide(i + 1)
            }
          }}
          key={i}
          tabIndex='0'
        ></button>
      )
    }
    setDotSlides(dots)
  }

  useEffect(() => {
    mapEachPicture()
    mapEachPictureInModal()
    mapDots()
  }, [listOfUploadedSlides])

  const updateHomeSlide = async (file, fileName, slideUrl) => {
    if (!file) {
      console.log('Please choose a photo.')
      return
    }

    try {
      const uploadedFile = await Storage.put(
        `homePictureSlides/${fileName}`,
        file.originFileObj
      )
      const imageUrl = await Storage.get(uploadedFile.key)
      const imageElements = document.querySelectorAll(`[src="${slideUrl}"]`)

      for (const imageElement of imageElements) {
        imageElement.src = imageUrl
      }

      mapEachPicture()
      mapEachPictureInModal()
      fetchUploadedSlides()
      notification['success']({
        message: 'Success',
        description: 'Image was updated!',
      })
    } catch (error) {
      console.log('Error uploading the file:', error)
    }
  }

  const deleteHomeSlide = async (fileName) => {
    const filtered = listOfUploadedSlides.filter((item) => item !== fileName)

    await Storage.remove(`homePictureSlides/${fileName}`)

    await API.graphql(
      graphqlOperation(updateHomeSlidesUploaded, {
        input: {
          id: 'b61cc5d7-1319-43aa-8cac-7905666bc520',
          slideImageFileName: JSON.stringify(filtered),
        },
      })
    )
    fetchUploadedSlides()
    notification['success']({
      message: 'Success',
      description: 'Image was deleted!',
    })
  }

  // Example array of file names
  // const listOfUploadedSlides = ['slide1', 'slide2', 'slide3', 'slide5', 'slide7'];

  function findHighestNumber() {
    let highestNumber = 0

    // Iterate over the file names
    for (const fileName of listOfUploadedSlides) {
      // Extract the number from the file name
      const number = parseInt(fileName.slice(5))

      // Update the highest number if necessary
      if (number > highestNumber) {
        highestNumber = number
      }
    }

    return highestNumber
  }

  function findAvailableNumber(highestNumber) {
    let availableNumber = listOfUploadedSlides.length

    // Iterate over the range of numbers up to the highest number
    for (let i = 1; i <= highestNumber; i++) {
      // Check if the number is available
      if (!listOfUploadedSlides.includes(`slide${i}`)) {
        availableNumber = i
        break // Found the first available number, exit the loop
      }
    }

    // If no gap found, use the next number after the highest number
    if (availableNumber === highestNumber) {
      availableNumber = highestNumber + 1
    }

    return availableNumber
  }

  return (
    <>
      <Modal
        maskClosable={false}
        title=''
        centered
        open={registrationEditOpen}
        onCancel={() => setRegistrationEditOpen(false)}
        width={400}
        footer={null}
      >
        <Row
          align='bottom'
          className={'d-flex justify-content-between'}
          style={{ height: 100 }}
        >
          <RegistrationCardEdit
            userData={userData}
            setRegistrationEditOpen={(e) => setRegistrationEditOpen(e)}
            setRegCardEdited={(e) => setRegCardEdited(e)}
          />
          <Button type='primary' onClick={() => handleShowSlide('register')}>
            Proceed to next page
          </Button>
        </Row>
      </Modal>
      <Modal
        maskClosable={false}
        title=''
        centered
        open={uTEditOpen}
        onCancel={() => setUTEditOpen(false)}
        width={430}
        footer={null}
      >
        <Row
          align='bottom'
          className={'d-flex justify-content-between'}
          style={{ height: 100 }}
        >
          <UpcomingTrainingCardEdit
            userData={userData}
            setUTEditOpen={(e) => setUTEditOpen(e)}
            setUTCardEdited={(e) => setUTCardEdited(e)}
          />
          <Button type='primary' onClick={() => handleShowSlide('register')}>
            Proceed to next page
          </Button>
        </Row>
      </Modal>

      {/*<Row className="welcome" justify="center">
        <p>Coming Soon</p>
      </Row>*/}
      {/*<Row className="welcome" justify="center">
        <p>Welcome to Quezon City Local Government</p>
      </Row>
      <Row className="welcome-two" justify="center">
        <p>Human Resource</p>
      </Row>
      <Row className="welcome-next" justify="center">
        <p>Onboarding Video</p>
      </Row>*/}
      <div style={{ height: '79vh' }}>
        <video
          style={{ width: '100%' }}
          className='overflow-hidden'
          muted
          autoPlay
          loop
        >
          <source src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/QCG+HR+Website_Welcome_V2.mp4' />
        </video>
      </div>

      <Row justify='center'>
        <div className='container site-layoutOne'>
          <div className='row'>
            <div className='col-md-4'>
              <h1>Core Values</h1>
              <p>
                Competence
                <br />
                Commitment
                <br />
                Caring
                <br />
                Creativity
                <br />
                Integrity
                <br />
                Political Will
                <br />
                Cooperation
                <br />
              </p>
            </div>

            <div className='col-md-4 mt-md-0 mt-4'>
              <h1>Vision</h1>
              <p>
                To sustain Quezon City as quality Community- a progressive and
                peacefu, clean and orderly place, conducive and hospitable to
                living, employment, and business.
              </p>
            </div>

            <div className='col-md-4 mt-md-0 mt-4 mb-md-0 mb-5'>
              <h1>Mission</h1>
              <p>
                To provide quality service, making Quezon City an ideal
                community where people live, work and do business in a
                hospitable, progressive, and peaceful environment.
              </p>
            </div>
          </div>
        </div>
      </Row>
      <Row justify='center mt-md-0 mt-4 mb-0'>
        <div className='home-contentTitle'>Featured Courses</div>
      </Row>
      <Row justify='center' className='feature-courses'>
        <section className='hero-section'>
          <div className='home-cardGrid d-flex justify-content-center'>
            <div
              className={'card-grid d-flex flex-wrap justify-content-center'}
            >
              <div className='d-md-flex'>
                <a
                  className={'a__card mx-3'}
                  type='button'
                  onClick={() => setShowAnnouncement(true)}
                >
                  <div
                    className={'home-contentCards cardOdd card__background_1'}
                  >
                    <Row justify='start'>
                      <p className='card__category'>Announcements</p>
                    </Row>
                    <Row justify='center' className={'px-2'}>
                      <span className='card__heading'>
                        <b>
                          Be up to date with the latest announcements from the
                          HRMD.
                        </b>
                      </span>
                    </Row>
                    {/*<Row justify="center">
                  <span className="card__comment">Coming Soon!</span>
                </Row>*/}
                    <Modal
                      maskClosable={false}
                      title='ANNOUNCEMENT'
                      centered
                      mask={false}
                      open={showAnnouncement}
                      onOk={() => setShowAnnouncement(false)}
                      onCancel={handleModalAnnc}
                      width={700}
                      footer={null}
                    >
                      <Row className={'d-flex justify-content-between mb-2'}>
                        {currentUser?.role == 'admin' ? (
                          <Button onClick={editUpper}>Edit</Button>
                        ) : null}

                        {openUpper ? (
                          <div>
                            <Button
                              className={'me-2'}
                              type='primary'
                              onClick={saveUpper}
                            >
                              Save
                            </Button>
                            <Button
                              danger
                              type='primary'
                              onClick={() => setOpenUpper(false)}
                            >
                              Cancel
                            </Button>
                          </div>
                        ) : null}
                      </Row>
                      {openUpper ? (
                        <>
                          <Row className={'mb-2'} justify='center'>
                            <TextArea onChange={handleTempUpperAnnc} rows={4} />
                          </Row>
                          <Row>
                            <Switch
                              onChange={() => setHideLower(!hideLower)}
                              defaultChecked={!hideLower}
                              checkedChildren='show'
                              unCheckedChildren='hide'
                            />
                          </Row>
                        </>
                      ) : (
                        <Row justify='center'>
                          <span
                            style={{
                              fontSize: 24,
                              textAlign: 'center',
                              width: '100%',
                            }}
                          >
                            <b>{upperCardAnnc}</b>
                          </span>
                        </Row>
                      )}

                      {hideLower == false ? (
                        <>
                          <Row justify='center'>
                            <span
                              style={{
                                fontSize: 24,
                                textAlign: 'center',
                                width: '100%',
                              }}
                            >
                              <b>For your reference and guidance</b>
                            </span>
                          </Row>
                          <Row style={{ marginTop: 20 }} justify='center'>
                            {openUpper ? (
                              <Col>
                                <Row className={'mb-2'}>
                                  <Input
                                    onChange={handleTempLowerAnnc}
                                    placeholder='Reference title'
                                  />
                                </Row>
                                <Row className={'mb-2'}>
                                  <Input
                                    id='anncFile'
                                    name='anncFile'
                                    type='file'
                                    accept='image/jpeg,image/gif,image/png,application/pdf'
                                    onChange={(e) => {
                                      setAnncFileUpload(e.target.files[0])
                                    }}
                                  />
                                </Row>
                              </Col>
                            ) : (
                              <a
                                style={{ fontSize: 20 }}
                                rel='noopener noreferrer'
                                target='_blank'
                                href={lowerCardLoc}
                              >
                                <b>{lowerCardAnnc}</b>
                              </a>
                            )}
                          </Row>
                        </>
                      ) : null}
                    </Modal>
                  </div>
                </a>

                <a
                  className={'a__card mx-3 mt-md-0 mt-2'}
                  type='button'
                  onClick={() => editOrProceed()}
                >
                  <div
                    className={'home-contentCards cardEven card__background_2'}
                  >
                    <Row justify='start'>
                      <p className='card__category'>Registration</p>
                    </Row>
                    <Row justify='center' className={'px-2'}>
                      <span className='card__heading'>
                        <b>{regCardText}</b>
                      </span>
                    </Row>
                    <Row justify='center'>
                      <span className='card__comment'>Register here.</span>
                    </Row>
                    {/*<Row justify="center">
                  <span className="card__comment">Register Here</span>
                </Row>*/}
                  </div>
                </a>
              </div>
              <div className='d-md-flex'>
                <a
                  className={'a__card mx-3'}
                  type='button'
                  onClick={() => editOrProceedUT()}
                >
                  <div
                    className={'home-contentCards cardOdd card__background_3'}
                  >
                    <Row justify='start'>
                      <p className='card__category'>Upcoming Trainings</p>
                    </Row>
                    <Row justify='center' className={'px-2'}>
                      <span className='card__heading'>
                        <b>{uTCardText}</b>
                      </span>
                    </Row>
                  </div>
                </a>
                <a
                  className={'a__card mx-3 mt-md-0 mt-2'}
                  type='button'
                  onClick={() => setShowSlide(true)}
                >
                  <div
                    className={'home-contentCards cardEven card__background_4'}
                  >
                    <Row justify='start'>
                      <p className='card__category'>Previous Trainings</p>
                    </Row>
                    <Row justify='center' className={'px-2'}>
                      <span className='card__heading'>
                        <b>
                          Browse through the previously conducted trainings
                          here.
                        </b>
                      </span>
                    </Row>

                    <Modal
                      maskClosable={false}
                      title={'Previous Events'}
                      centered
                      open={showSlide}
                      onCancel={handleCloseSlide}
                      width={850}
                      footer={null}
                    >
                      <Carousel
                        dots='true'
                        autoplaySpeed={3000}
                        autoplay
                        ref={(ref) => {
                          slider.current = ref
                        }}
                      >
                        <div
                          className={'d-flex justify-content-center'}
                          style={contentStyle}
                        >
                          <Card
                            size='small'
                            title='December'
                            style={{
                              width: 450,
                            }}
                          >
                            <Row
                              style={{
                                paddingTop: '16px',
                                paddingBottom: '16px',
                              }}
                              justify='center'
                            >
                              <img
                                style={{ maxWidth: '300px' }}
                                src='https://s3etnahris133956-dev.s3.amazonaws.com/uploadedByAdmin/WAVE%202%20-%20DEC%206.png'
                                alt='Human Resource On-boarding and Orientation - Wave 2'
                              />
                            </Row>
                          </Card>
                        </div>
                        <div
                          className={'d-flex justify-content-center'}
                          style={contentStyle}
                        >
                          <Card
                            size='small'
                            title='November'
                            style={{
                              width: 450,
                            }}
                          >
                            <Row
                              style={{
                                paddingTop: '16px',
                                paddingBottom: '16px',
                              }}
                              justify='center'
                            >
                              <img
                                style={{ maxWidth: '300px' }}
                                src='https://s3etnahris133956-dev.s3.amazonaws.com/uploadedByAdmin/COS-Orientation-Wave1.jpg'
                                alt='Human Resource On-boarding and Orientation 2022'
                              />
                            </Row>
                          </Card>
                        </div>
                        <div
                          className={'d-flex justify-content-center'}
                          style={contentStyle}
                        >
                          <Card
                            size='small'
                            title='November'
                            style={{
                              width: 450,
                            }}
                          >
                            <Row
                              style={{
                                paddingTop: '16px',
                                paddingBottom: '16px',
                              }}
                              justify='center'
                            >
                              <img
                                style={{ maxWidth: '300px' }}
                                src='https://s3etnahris133956-dev.s3.amazonaws.com/uploadedByAdmin/viber_image_2022-11-11_16-07-39-874.jpg'
                                alt='Coaching and Mentoring Second Batch'
                              />
                            </Row>
                          </Card>
                        </div>

                        <div
                          className={'d-flex justify-content-center'}
                          style={contentStyle}
                        >
                          <Card
                            size='small'
                            title='November'
                            style={{
                              width: 450,
                            }}
                          >
                            <Row
                              style={{
                                paddingTop: '16px',
                                paddingBottom: '16px',
                              }}
                              justify='center'
                            >
                              <img
                                style={{ maxWidth: '300px' }}
                                src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/CSC+Director+Hans+POSTERS_1667900056.png'
                                alt='Updates on Leave Laws And Other Relevant Issuances'
                              />
                            </Row>
                          </Card>
                        </div>
                        <div
                          className={'d-flex justify-content-center'}
                          style={contentStyle}
                        >
                          <Card
                            size='small'
                            title='October'
                            style={{
                              width: 450,
                            }}
                          >
                            <Row
                              style={{
                                paddingTop: '16px',
                                paddingBottom: '16px',
                              }}
                              justify='center'
                            >
                              <img
                                style={{ maxWidth: '300px' }}
                                src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/Pubmat+1+-+Policies+%26+Procedures+in+Recruitment+%26+Selection+Course+Description.jpg'
                                alt='Policies and Procedures in Recruitment and Selection Course'
                              />
                            </Row>
                          </Card>
                        </div>
                        <div
                          className={'d-flex justify-content-center'}
                          style={contentStyle}
                        >
                          <Card
                            size='small'
                            title='October'
                            style={{
                              width: 450,
                            }}
                          >
                            <Row
                              style={{
                                paddingTop: '16px',
                                paddingBottom: '16px',
                              }}
                              justify='center'
                            >
                              <img
                                style={{ maxWidth: '300px' }}
                                src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/Pubmat%20v1-Coaching%20and%20Mentoring.png'
                                alt='Coaching and Mentoring'
                              />
                            </Row>
                          </Card>
                        </div>
                        <div
                          className={'d-flex justify-content-center'}
                          style={contentStyle}
                        >
                          <Card
                            size='small'
                            title='October'
                            style={{
                              width: 600,
                            }}
                          >
                            <Row
                              style={{
                                paddingTop: '16px',
                                paddingBottom: '16px',
                              }}
                              justify='center'
                            >
                              <Col span={12}>
                                <Row justify='center'>
                                  <img
                                    style={{ maxWidth: 300 }}
                                    src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/Brief%20Course%20Description.png'
                                    alt='Admin Brief Course Description'
                                  />
                                </Row>
                              </Col>
                              <Col span={12}>
                                <Row justify='center'>
                                  <img
                                    style={{ maxWidth: 300 }}
                                    src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/Resource%20Speaker%20Profile.png'
                                    alt='Resource Speaker Profile'
                                  />
                                </Row>
                              </Col>
                            </Row>
                          </Card>
                        </div>
                        <div
                          className={'d-flex justify-content-center'}
                          style={contentStyle}
                        >
                          <Card
                            size='small'
                            title='August'
                            style={{
                              width: 450,
                            }}
                          >
                            <Row
                              style={{
                                paddingTop: '16px',
                                paddingBottom: '16px',
                              }}
                              justify='center'
                            >
                              <img
                                style={{ maxWidth: '300px' }}
                                src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/viber_image_2022-10-03_14-25-22-799.jpg'
                                alt='High Impact Call Handling Training'
                              />
                            </Row>
                          </Card>
                        </div>
                      </Carousel>
                      <Row justify='start'>
                        <Button
                          className={'border border-0'}
                          style={{
                            position: 'absolute',
                            top: 312,
                            background: 'rgb(0 0 0 / 0%)',
                          }}
                          onClick={(e) => slider.current.prev()}
                          icon={<LeftCircleOutlined />}
                          size='large'
                        />
                      </Row>
                      <Row justify='end'>
                        <Button
                          className={'border border-0'}
                          style={{
                            position: 'absolute',
                            top: 312,
                            background: 'rgb(0 0 0 / 0%)',
                          }}
                          onClick={(e) => slider.current.next()}
                          icon={<RightCircleOutlined />}
                          size='large'
                        />
                      </Row>
                    </Modal>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>
      </Row>
      <Row justify='center mt-3'>
        <div className={'slider__news'}>NEWS</div>
      </Row>
      {currentUser?.role?.toLowerCase() === 'admin' && (
        <div className='d-flex flex-column justify-content-center align-items-center'>
          <label htmlFor='file-upload' className='file-upload-label mb-2'>
            <em>To add new image slide</em>
          </label>
          <Upload
            accept='.jpeg,.jpg,.png'
            maxCount={1}
            showUploadList={false}
            beforeUpload={(file) => {
              uploadPictureSlides(file)
              return false // Prevent default upload behavior
            }}
          >
            <Button
              className='d-flex align-items-center bg-primary text-white'
              icon={<UploadOutlined />}
            >
              Click to Upload
            </Button>
          </Upload>
          <Button
            className='mt-2 bg-primary text-white'
            onClick={() => setEditSlidesModal(true)}
          >
            Edit/Delete Slides
          </Button>
          <Modal
            maskClosable={false}
            title={
              <span>
                <em>
                  <b>Edit/Delete Home slide images</b>
                </em>
              </span>
            }
            open={editSlidesModal}
            footer={null}
            onCancel={() => setEditSlidesModal(false)}
            width={1000}
            destroyOnClose={true}
          >
            <div className='slideshow-container gap-3'>
              <div className='image-container'>
                <img
                  className={`image-slide img-fluid`}
                  src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/firstSlide'
                  alt='First Slide'
                />
                {currentUser?.role?.toLowerCase() === 'admin' && (
                  <div className='middle d-flex flex-column align-items-center justify-content-center gap-2'>
                    <Upload
                      accept='.jpeg,.jpg,.png'
                      maxCount={1}
                      showUploadList={false}
                      onChange={(info) =>
                        updateHomeSlide(
                          info.fileList[0],
                          'firstSlide',
                          'https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/firstSlide'
                        )
                      }
                    >
                      <Button className='upload-button d-flex align-items-center bg-success text-white'>
                        Change Image
                      </Button>
                    </Upload>
                  </div>
                )}
              </div>
              {imagesToShowInModal}
              <div className='image-container'>
                <img
                  className={`image-slide img-fluid`}
                  src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/lastSlide'
                  alt='Last Slide'
                />
                {currentUser?.role?.toLowerCase() === 'admin' && (
                  <div className='middle d-flex flex-column align-items-center justify-content-center gap-2'>
                    <Upload
                      accept='.jpeg,.jpg,.png'
                      maxCount={1}
                      showUploadList={false}
                      onChange={(info) =>
                        updateHomeSlide(
                          info.fileList[0],
                          'lastSlide',
                          'https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/lastSlide'
                        )
                      }
                    >
                      <Button className='upload-button d-flex align-items-center bg-success text-white'>
                        Change Image
                      </Button>
                    </Upload>
                  </div>
                )}
              </div>
            </div>
          </Modal>
          {/* <div className='d-flex justify-content-center align-items-center gap-3'>
            <label htmlFor="file-upload" className="file-upload-label">
              Click to Upload new picture slide
            </label>
            <input
              // className='mt-3 col-12'
              id="file-upload"
              type="file"
              accept=".jpeg,.jpg,.png"
              max="1"
              onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  uploadPictureSlides(file);
                }
              }}
            />
          </div> */}
        </div>
      )}
      {listOfUploadedSlides.length ? (
        <>
          <Row
            style={{ paddingBottom: '16px' }}
            justify='center'
            className='p-4'
          >
            <div className='slideshow-container'>
              <div className={'mySlides fade'}>
                <img
                  className='image-slide https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/firstSlide'
                  src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/firstSlide'
                  alt='First Slide'
                />
              </div>
              {imageSlides}
              <div className={'mySlides fade'}>
                <img
                  alt='Last Slide'
                  className='image-slide https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/lastSlide'
                  src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/public/homePictureSlides/lastSlide'
                />
              </div>
            </div>
          </Row>
          <Row style={{ paddingBottom: '16px' }} justify='center'>
            <div style={{ textAlign: 'center' }}>
              <button
                className={'dot border-0'}
                onClick={() => currentSlide(1)}
                tabIndex='0'
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    currentSlide(1)
                  }
                }}
              ></button>
              {dotSlides}
              <button
                className={'dot border-0'}
                onClick={() => currentSlide(listOfUploadedSlides.length + 2)}
                tabIndex='0'
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    currentSlide(listOfUploadedSlides.length + 2)
                  }
                }}
              ></button>
            </div>
          </Row>
        </>
      ) : (
        <h4 className='mt-5'>
          No photo to display
          <br />
          Please upload one
        </h4>
      )}

      <Row style={{ height: '100px' }}></Row>
      {/*<Row justify="center">
        <h3>Events</h3>
      </Row>

      <Row style={{ paddingBottom: "16px" }} justify="center">
        <div style={{ width: 450 }}>
          <div>
            <Row justify="center">
              <Button
                type="text"
                style={{ color: "blue", fontSize: 14, padding: 0 }}
                onClick={(e) => afterSubmit("trainingPrograms")}
              >
                Register here for the upcoming event
              </Button>
            </Row>

            <Card
              size="small"
              title="November"
              style={{
                width: 450,
              }}
            >
              <Row
                style={{ paddingTop: "16px", paddingBottom: "16px" }}
                justify="center"
              >
                <img
                  style={{ maxWidth: "300px" }}
                  src="https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/CSC+Director+Hans+POSTERS_1667900056.png"
                  alt="Update on Leave Laws and Other relevant Issuances Course"
                />
              </Row>
            </Card>
          </div>
          <Carousel
            dots="true"
            autoplaySpeed={3000}
            autoplay
            style={{ paddingTop: "16px", paddingBottom: "16px" }}
            ref={(ref) => {
              slider.current = ref;
            }}
          >
            <div style={contentStyle}>
              <Card
                size="small"
                title="October"
                style={{
                  width: 450,
                }}
              >
                <Row
                  style={{ paddingTop: "16px", paddingBottom: "16px" }}
                  justify="center"
                >
                  <img
                    style={{ maxWidth: "300px" }}
                    src="https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/Pubmat+1+-+Policies+%26+Procedures+in+Recruitment+%26+Selection+Course+Description.jpg"
                    alt="Policies and Procedures in Recruitment and Selection Course"
                  />
                </Row>
              </Card>
            </div>
            <div style={contentStyle}>
              <Card
                size="small"
                title="October"
                style={{
                  width: 450,
                }}
              >
                <Row
                  style={{ paddingTop: "16px", paddingBottom: "16px" }}
                  justify="center"
                >
                  <img
                    style={{ maxWidth: "300px" }}
                    src="https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/Pubmat%20v1-Coaching%20and%20Mentoring.png"
                    alt="Coaching and Mentoring"
                  />
                </Row>
              </Card>
            </div>
            <div style={contentStyle}>
              <Card
                size="small"
                title="October"
                style={{
                  width: 450,
                }}
              >
                <Row
                  style={{ paddingTop: "16px", paddingBottom: "16px" }}
                  justify="center"
                >
                  <Col span={12}>
                    <Row justify="center">
                      <img
                        style={{ maxWidth: 200 }}
                        src="https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/Brief%20Course%20Description.png"
                        alt="Admin Brief Course Description"
                      />
                    </Row>
                  </Col>
                  <Col span={12}>
                    <Row justify="center">
                      <img
                        style={{ maxWidth: 200 }}
                        src="https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/Resource%20Speaker%20Profile.png"
                        alt="Resource Speaker Profile"
                      />
                    </Row>
                  </Col>
                </Row>
              </Card>
            </div>
            <div style={contentStyle}>
              <Card
                size="small"
                title="August"
                style={{
                  width: 450,
                }}
              >
                <Row
                  style={{ paddingTop: "16px", paddingBottom: "16px" }}
                  justify="center"
                >
                  <img
                    style={{ maxWidth: "300px" }}
                    src="https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/viber_image_2022-10-03_14-25-22-799.jpg"
                    alt="High Impact Call Handling Training"
                  />
                </Row>
              </Card>
            </div>
          </Carousel>
          <Row justify="space-between">
            <Button onClick={(e) => slider.current.prev()}>Previous</Button>
            <Button onClick={(e) => slider.current.next()}>Next</Button>
          </Row>
        </div>
      </Row>*/}
      <Row
        style={{ paddingTop: '16px', paddingBottom: '16px' }}
        justify='center'
      >
        <div style={{ width: 450 }}></div>
      </Row>

      {/*<span>- Announcements</span>
      <span>- Video Introduction</span>
      <span>- Latest Updates/News/Memorandum</span>*/}
    </>
  )
}
HomeContent.propTypes = {
  afterSubmit: PropTypes.func.isRequired,
  goto: PropTypes.func.isRequired,
  userData: PropTypes.object,
}
