import { StyleSheet } from '@react-pdf/renderer'

// Create styles
export const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#FFF',
    padding: 12,
    margin: 0,
  },
  section: {
    flexGrow: 1,
  },
  backgroundCol: {
    backgroundColor: '#EAEAEA',
  },
  bordered: {
    border: '1px solid black',
    borderTop: 'none',
    borderLeft: 'none',
  },
  borderedX: {
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
  },
  borderedY: {
    borderTop: '1px solid black',
    borderBottom: '1px solid black',
  },
  div: {
    border: '2px solid black',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 4,
    paddingLeft: 8,
    position: 'relative',
  },
  div1: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  div1a: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'middle',
  },
  div1b: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
  },
  div1c: {
    border: '2px solid black',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    paddingTop: 4,
    paddingLeft: 0,
    position: 'relative',
  },
  div2: {
    display: 'flex',
    flexDirection: 'column',
  },
  div2a: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  div3: {
    display: 'flex',
    flexDirection: 'row',
  },
  div3a: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  div3b: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  div4: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  div5: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingRight: 18,
  },
  divTitle: {
    textAlign: 'center',
    position: 'relative',
    marginTop: 0,
  },
  textItalic: {
    fontFamily: 'PTSansNarrow',
    fontStyle: 'italic',
  },
  textItalicBold: {
    fontFamily: 'PTSansNarrow',
    fontStyle: 'italic-bold',
  },
  textArchivoBoldItalic: {
    fontFamily: 'OpenSansBoldItalic',
  },
  textArchivoBoldNarrow: {
    fontFamily: 'PTSansNarrow',
    fontStyle: 'bold',
  },
  textBold: {
    // fontFamily: 'PTSansNarrow',
    textTransform: 'uppercase',
  },
  textSmall: {
    fontSize: 6.6,
    fontFamily: 'PTSansNarrow',
  },
  textXSmall: {
    fontSize: 6,
    fontFamily: 'PTSansNarrow',
  },
  textSmallBold: {
    fontSize: 8,
    fontFamily: 'PTSansNarrow',
    // fontStyle: 'bold',
  },
  textMedium: {
    fontSize: 6.6,
    fontFamily: 'PTSansNarrow',
  },
  textCivilLabel: {
    fontSize: 6.9,
    fontFamily: 'PTSansNarrow',
  },
  textCivilData: {
    fontSize: 6.9,
    fontFamily: 'PTSansNarrow',
  },
  textMediumData: {
    fontSize: 8.2,
    fontFamily: 'PTSansNarrow',
  },
  textMediumDataBold: {
    fontSize: 8.2,
    fontFamily: 'PTSansNarrow',
    fontWeight: 'bold',
  },
  textMediumB: {
    fontSize: 9,
    fontFamily: 'PTSansNarrow',
  },
  textMediumAB: {
    fontSize: 7,
    fontFamily: 'PTSansNarrow',
  },
  textMedium2: {
    fontSize: 9,
    fontFamily: 'PTSansNarrow',
  },
  title: {
    fontFamily: 'OpenSans-ExtraBold',
    fontSize: 18,
    // fontWeight: 900,
  },
  margin1: {
    margin: 8,
  },
  marginTop1: {
    marginTop: 4,
  },
  margin2y: {
    marginTop: 8,
    marginBottom: 8,
  },
  margin2: {
    margin: 12,
  },
  marginL1: {
    marginLeft: 8,
  },
  marginL2: {
    marginLeft: 12,
  },
  marginL3: {
    marginLeft: 24,
  },
  marginL32: {
    marginLeft: 30,
  },
  marginL4: {
    marginLeft: 20,
  },
  margin1Top: {
    marginTop: 8,
  },
  margin1TopA: {
    marginTop: 12,
  },
  margin1TopB: {
    marginTop: 32,
  },
  margin1TopC: {
    marginTop: 16,
  },
  margin1TopD: {
    marginTop: 13,
  },
  margin1Bottom: {
    marginBottom: 8,
  },
  margin1aBottom: {
    marginBottom: 1,
  },
  padding1X: {
    marginLeft: 10,
    marginRight: 10,
  },
  textUnderline: {
    textDecoration: 'underline',
  },
  textOverline: {
    textDecoration: 'overline',
  },
  width1: {
    width: 250,
  },
  width1b3: {
    width: '50px',
  },
  width1a: {
    maxWidth: 230,
    maxHeight: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  width1b: {
    width: 310,
  },
  width1b2: {
    width: 200,
    height: 24,
    border: '1px solid black',
  },
  width1b4: {
    width: 210,
  },
  width1c: {
    maxWidth: '3.5cm',
    maxHeight: '4.5cm',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  width1d: {
    width: '4.5cm',
    alignItems: 'center',
  },
  width1e: {
    width: 250,
  },
  width2: {
    width: 180,
  },
  width3: {
    width: 350,
  },
  width4: {
    width: 74,
  },
  width4a: {
    width: 75,
  },
  width4b: {
    width: 65,
  },
  width4bc: {
    width: 51,
  },
  width4c: {
    width: 30,
  },
  width5: {
    width: 161,
  },
  width5e: {
    width: 150,
  },
  width6: {
    width: 110,
  },
  width6b: {
    width: 100,
  },
  width6bd: {
    width: 133,
  },
  width6cd: {
    width: 100,
  },
  width6c: {
    width: 129,
  },
  width6ca: {
    width: 142,
  },
  width6d: {
    width: 68,
  },
  width7: {
    width: 100,
  },
  width8: {
    width: 447,
  },
  width9: {
    width: 70,
    textAlign: 'center',
    border: '1px solid black',
    borderTop: 'none',
    borderLeft: ' none',
  },
  width9a: {
    width: 155,
    textAlign: 'center',
    border: '1px solid black',
    borderTop: 'none',
    borderLeft: ' none',
  },
  width9b: {
    width: 85,
    textAlign: 'center',
    border: '1px solid black',
    borderTop: 'none',
    borderLeft: ' none',
  },
  width9c: {
    width: 54,
  },
  width9c2: {
    width: 55,
  },
  width9d: {
    width: 120,
    border: '1px solid black',
    borderTop: 'none',
    borderLeft: ' none',
  },
  width9d2: {
    width: 120,
  },
  signBelow: {
    height: 20,
    width: 585,
    position: 'absolute',
    top: '98.5%',
  },
  signBelow1b: {
    height: 20,
    width: 585,
    position: 'absolute',
    top: '98.2%',
  },

  signBelow1: {
    width: 140,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  signBelow2: {
    width: 205,
  },
  signBelow3: {
    width: 90,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  signBelow4: {
    width: 150,
  },
  signBelow5: {
    height: 20,
    width: 585,
    position: 'absolute',
    bottom: -2,
  },
  textCenter: {
    textAlign: 'center',
  },
  textLeft: {
    textAlign: 'left',
  },
  borderStyle: {
    borderWidth: 1,
    borderColor: 'black',
    borderStyle: 'solid',
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: '#000', // You can change the color as needed
    paddingBottom: 2, // Adjust as necessary for spacing
  },
  verticalLine: {
    width: 2, // Adjust the width of the line as needed
    backgroundColor: '#000', // Set the color of the line
    height: '100%', // Set the height to fill the parent container
  },
  informationContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleBorder: {
    backgroundColor: 'gray',
    borderTop: '1.5px solid black',
    borderBottom: '1.5px solid black',
    height: '15px',
    paddingLeft: 1,
  },
  titleFontColor: {
    color: 'white',
  },
  fullWidth: {
    width: '100%',
  },
  nobordertpbtm: {
    width: '20%',
    borderBottom: 'none',
    borderTop: 'none',
  },
  suffixCol: { width: '30%', borderLeft: '1px solid black', height: '15px' },
  suffixTextSize: { fontSize: 5.8 },
  width80: { width: '80%' },
  width70: { width: '70%' },
  width50: { width: '50%' },
  width100: { width: '100%' },
  textColorRed: { color: 'red' },
  formPageCountFont: {
    textAlign: 'right',
    position: 'relative',
    top: '20px',
  },
  datePadding: {
    padding: '3',
  },
  headerTitle: {
    position: 'relative',
    bottom: '10px',
  },
  titleBorderLeftRightNone: {
    borderLeft: 'none',
    borderRight: 'none',
  },
  titleBorderLeftNone: {
    borderLeft: 'none',
  },
  textAlignStart: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingLeft: 1,
  },
  paddingTitle: {
    paddingLeft: 4,
  },
  paddingL3: {
    paddingLeft: 3,
  },
  paddingL1: {
    paddingLeft: 1,
  },
  paddingL5: {
    paddingLeft: 5,
  },
  borderRightNone: {
    borderRight: 'none',
  },
  familyBackgroundHeightCell: {
    height: 19.1,
  },
  familyBackgroundHeightCellSH: {
    height: 17.1,
  },
  headerFont: {
    fontFamily: 'Roboto',
  },
  marginLeft2: {
    marginLeft: 2,
  },
  tablePadding: {
    paddingLeft: 1,
    paddingRight: 1,
  },
})
