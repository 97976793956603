import moment from 'moment'

import DOMPurify from 'dompurify'

export const getEmailBodyReturnedByHR = (record) => {
  try {
    return `Dear Department Admin,

This is to inform you that a Personal Data Sheet has been returned by the HR Admin to the employee with the following details:

First Name: ${DOMPurify.sanitize(record.ownedBy.name)}
Last Name: ${DOMPurify.sanitize(record.ownedBy.lname)}
Assigned Department: ${DOMPurify.sanitize(record.ownedBy.assignedDept)}
Date and Time of Submission: ${moment().format('MMMM D, YYYY h:mm A')}

They were already notified of it so they can do the needed changes, which is explained on the 'Remarks'.

Thank you for your attention to this matter.

Best regards,
System Administrator
HRIS`
  } catch (err) {
    console.log(err)
  }
}

export const getEmailBodyApprovedByHR = (record) => {
  try {
    return `Dear HR Admin,

This is to inform you that a Personal Data Sheet has been approved by a HR Admin.

The following is the details of the PDS submission.

First Name: ${DOMPurify.sanitize(record.ownedBy.name)}
Last Name: ${DOMPurify.sanitize(record.ownedBy.lname)}
Assigned Department: ${DOMPurify.sanitize(record.ownedBy.assignedDept)}
Date and Time of Submission: ${moment().format('MMMM D, YYYY h:mm A')}

Please follow the link to open the PDS document submitted: 

https://pds.d3n8dycsr461s8.amplifyapp.com

Thank you for your attention to this matter.

Best regards,
System Administrator
HRIS`
  } catch (err) {
    console.log(err)
  }
}

export const getEmailBodyReturnedByHREmployeeNotif = (record) => {
  try {
    return `Dear ${DOMPurify.sanitize(
      record.ownedBy.name
    )} ${DOMPurify.sanitize(record.ownedBy.lname)},

This is to inform you that your submitted Personal Data Sheet was returned by the HR Admin. Kindly log in to your account to review their remarks on it.

Once you made the necessary changes, you can resubmit it for approval.

If you have any questions or require further assistance, don't hesitate to reach the HR department.

Thank you for your attention to this matter.

Best regards,
System Administrator
HRIS`
  } catch (err) {
    console.log(err)
  }
}

export const getEmailBodyApprovedByHREmployeeNotif = (record) => {
  try {
    return `Dear ${DOMPurify.sanitize(
      record.ownedBy.name
    )} ${DOMPurify.sanitize(record.ownedBy.lname)},

This is to inform you that your submitted Personal Data Sheet was approved by the HR Admin.

If you have any questions or require further assistance, don't hesitate to reach the HR department.

Best regards,
System Administrator
HRIS`
  } catch (err) {
    console.log(err)
  }
}

export const getEmailBodySubmittedByEmployeeNotifDeptApprover = (userFound) => {
  try {
    return `Dear Department Admin,

This is to inform you that a Personal Data Sheet has been submitted. Kindly log in to your account to review the submission.

The following is the details of the PDS submission.

First Name: ${DOMPurify.sanitize(userFound.name)}
Last Name: ${DOMPurify.sanitize(userFound.lname)}
Home Department: ${DOMPurify.sanitize(userFound.department)}
Date and Time of Submission: ${moment().format('MMMM D, YYYY h:mm A')}

Please follow the link to open the PDS document submitted:

https://pds.d3n8dycsr461s8.amplifyapp.com

If you have any questions or require further assistance, don't hesitate to reach the HR department.

Thank you for your attention to this matter.

Best regards,
System Administrator
HRIS`
  } catch (err) {
    console.log(err)
  }
}

export const getEmailBodyApprovedByDeptApproverEmployeeNotif = (record) => {
  try {
    return `Dear ${DOMPurify.sanitize(
      record.ownedBy.name
    )} ${DOMPurify.sanitize(record.ownedBy.lname)},

This is to inform you that your submitted Personal Data Sheet was approved by the Department Admin and was sent to HR for its final review.

Once they're done, you will receive another email to inform you of their decision.

If you have any questions or require further assistance, don't hesitate to reach the HR department.

Thank you for your attention to this matter.

Best regards,
System Administrator
HRIS`
  } catch (err) {
    console.log(err)
  }
}

export const getEmailBodyReturnedByDeptApproverEmployeeNotif = (record) => {
  try {
    return `Dear ${DOMPurify.sanitize(
      record.ownedBy.name
    )} ${DOMPurify.sanitize(record.ownedBy.lname)},

This is to inform you that your submitted Personal Data Sheet was returned by the Department Admin. Kindly log in to your account to review their remarks on it.

Once you made the necessary changes, you can resubmit it for approval.

If you have any questions or require further assistance, don't hesitate to reach the HR department.

Thank you for your attention to this matter.

Best regards,
System Administrator
HRIS`
  } catch (err) {
    console.log(err)
  }
}

export const getEmailBodySubmittedByDeptApproverNotifHR = (record) => {
  try {
    return `Dear HR Admin,

This is to inform you that a Personal Data Sheet has been approved by a Department Admin and submitted for your final review.

The following is the details of the PDS submission.

First Name: ${DOMPurify.sanitize(record.ownedBy.name)}
Last Name: ${DOMPurify.sanitize(record.ownedBy.lname)}
Assigned Department: ${DOMPurify.sanitize(record.ownedBy.assignedDept)}
Date and Time of Submission: ${moment().format('MMMM D, YYYY h:mm A')}

Please follow the link to open the PDS document submitted: 

https://pds.d3n8dycsr461s8.amplifyapp.com

Thank you for your attention to this matter.

Best regards,
System Administrator
HRIS`
  } catch (err) {
    console.log(err)
  }
}
