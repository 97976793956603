/**
 * Title: manageEtna.js
 * Description: This is a file that contains the components for managing e-TNA forms of employees.
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Users/manageEtna.js
 **/

import React, { useState } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  UserbyUsername,
  FormByUserId,
  UserbyName,
  FormByUserDept,
} from '../../api/queries'

import 'antd/dist/antd.css'
import {
  Tag,
  Table,
  Input,
  Select,
  Button,
  Modal,
  Card,
  DatePicker,
  Col,
  Row,
} from 'antd'
import { FundViewOutlined } from '@ant-design/icons'

import { Form } from '@formio/react'

import moment from 'moment'

import listOfDepartments from '../../utilities/dept'

const { Search } = Input

const { Option } = Select

const { RangePicker } = DatePicker

const secret_name = 'hrm-2023@secrets'

/**
 * @async
 * @function ManageEtna
 * @description Component for managing e-TNA forms of employees.
 * @param {Object} assignedDepartment - The assigned department of the employee.
 * @throws {Error} If there is an error during the API calls.
 * @returns {JSX.Element} The JSX element representing the ManageEtna component.
 */
const ManageEtna = ({ assignedDepartment }) => {
  const [employeeWithEtna, setEmployeeWithEtna] = useState([])

  const [isModalVisible, setIsModalVisible] = useState(false)

  const [submissionData, setSubmissionData] = useState(null)
  const [formSource, setFormSource] = useState('')

  const [loadingTable, setIsLoadingTable] = useState(true)

  const [fName, setFName] = useState('')
  const [lName, setLName] = useState('')
  const [emailSearch, setEmailSearch] = useState('')
  const [deptSearch, setDeptSearch] = useState('')

  /**
   * @async
   * @function handleSearchUsername
   * @description Handles the search for a user by username or email address.
   * @throws {Error} If an error occurs during the search process.
   * @param {string} e - The username or email address to search for.
   * @returns {Promise<void>} A promise that resolves when the search is complete.
   */
  const handleSearchUsername = async (e) => {
    try {
      setIsLoadingTable(true)
      setEmployeeWithEtna([])
      const emailUsername = e.toLowerCase()
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

      function isValidEmail(email) {
        return emailRegex.test(email)
      }

      if (isValidEmail(emailUsername)) {
        const searchUsers = await API.graphql(
          graphqlOperation(UserbyUsername, { username: e })
        )
        const foundUsers = searchUsers.data.UserbyUsername.items
        let items = []
        for (const foundUser of foundUsers) {
          const searchUserForms = await API.graphql(
            graphqlOperation(FormByUserId, { userID: foundUser.id })
          )

          items.push(...searchUserForms.data.FormByUserId.items)
        }
        setEmployeeWithEtna(items)
        setIsLoadingTable(false)
      } else {
        console.log('invalid email address')
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleSearchDepartment = async (e) => {
    console.log(e)
    try {
      setIsLoadingTable(true)
      setEmployeeWithEtna([])

      let items = []
      let token = null

      do {
        const searchUsers = await API.graphql(
          graphqlOperation(FormByUserDept, {
            assignedDept: e,
            nextToken: token,
          })
        )
        const foundUsers = searchUsers.data.FormByUserDept.items
        items.push(...foundUsers)
        token = searchUsers.data.FormByUserDept.nextToken
      } while (token)

      setEmployeeWithEtna(items)
      setIsLoadingTable(false)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @async
   * @function handleSearchNames
   * @description Searches for users based on their first name and last name variations, and retrieves their associated forms.
   * @throws {Error} If an error occurs during the search process.
   * @returns {Promise<void>} A promise that resolves once the search is complete.
   */
  const handleSearchNames = async () => {
    try {
      setEmployeeWithEtna([])
      setIsLoadingTable(true)
      let itemsFound = []

      const firstLetterUppercaseLname =
        lName.charAt(0).toUpperCase() + lName.slice(1).toLowerCase()

      const lnameVariations = [
        firstLetterUppercaseLname,
        lName.toLowerCase(),
        lName.toUpperCase(),
      ]

      const fNameParts = fName.trim().split(' ')
      const nameVariations =
        fNameParts.length === 1
          ? [
              fNameParts[0].charAt(0).toUpperCase() +
                fNameParts[0].slice(1).toLowerCase(),
              fNameParts[0].toLowerCase(),
              fNameParts[0].toUpperCase(),
            ]
          : [
              `${
                fNameParts[0].charAt(0).toUpperCase() +
                fNameParts[0].slice(1).toLowerCase()
              } ${
                fNameParts[1].charAt(0).toUpperCase() +
                fNameParts[1].slice(1).toLowerCase()
              }`,
              `${fNameParts[0]} ${fNameParts[1]}`.toLowerCase(),
              `${fNameParts[0]} ${fNameParts[1]}`.toUpperCase(),
              `${
                fNameParts[0].charAt(0).toUpperCase() +
                fNameParts[0].slice(1).toLowerCase()
              } ${fNameParts[1].toLowerCase()}`,
            ]

      for (const nameVariation of nameVariations) {
        for (const lnameVariation of lnameVariations) {
          let nextToken = null

          do {
            const result = await API.graphql(
              graphqlOperation(UserbyName, {
                name: nameVariation,
                filter: {
                  lname: {
                    contains: lnameVariation,
                  },
                },
                limit: 1000,
                nextToken,
              })
            )

            itemsFound = [...itemsFound, ...result.data.UserbyName.items]
            nextToken = result.data.UserbyName.nextToken
          } while (nextToken)
        }
      }

      const uniqueArray = Array.from(
        new Set(itemsFound.map((obj) => obj.id))
      ).map((id) => {
        return itemsFound.find((obj) => obj.id === id)
      })

      let formItems = []
      for (const foundUser of uniqueArray) {
        const searchUserForms = await API.graphql(
          graphqlOperation(FormByUserId, { userID: foundUser.id, limit: 1000 })
        )

        formItems.push(...searchUserForms.data.FormByUserId.items)
      }
      setEmployeeWithEtna(formItems)
      setIsLoadingTable(false)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @async
   * @function handleOk
   * @description Handles the "Ok" button click event.
   * @throws {Error} If there is an error while handling the event.
   * @returns {void}
   */
  const handleOk = () => {
    setIsModalVisible(false)
  }

  /**
   * @async
   * @function handleCancel
   * @description Handles the cancel action by closing the modal, resetting submission data, and clearing the form source.
   * @throws {Error} If an error occurs during the cancellation process.
   * @returns {void}
   */
  const handleCancel = () => {
    setIsModalVisible(false)
    setSubmissionData(null)
    setFormSource('')
  }

  /**
   * @async
   * @function handleShowSurveybyId
   * @description Handles the action of showing a survey by its ID.
   * @throws {Error} If there is an error parsing the filter form data.
   * @param {Object} e - The event object.
   * @returns {void}
   */
  const handleShowSurveybyId = (e) => {
    try {
      const filter = e
      setIsModalVisible(true)
      setSubmissionData(JSON.parse(filter.form))
      setFormSource(
        filter.isLanguage === '1' ? 'samplesurvey' : 'etnafilipinoversion'
      )
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @async
   * @function
   * @description Handles the form submission and removes specific buttons after a delay.
   * @throws {Error} If an error occurs during the button removal process.
   * @param {Event} e - The event object representing the form submission.
   * @returns {void}
   */
  const handleForm = (e) => {
    const b = document.getElementsByTagName('button')
    try {
      setTimeout(() => {
        if (b['data[submit]']) {
          b['data[submit]'].remove()
        }

        if (b['data[print]']) {
          b['data[print]'].remove()
        }
      }, 500)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Retrieves the list of departments asynchronously.
   *
   * @function listOfDepartments
   * @description This function retrieves the list of departments from the file.
   * @throws {Error} If there is an error retrieving the list of departments.
   * @returns {Array} An array of department objects.
   */
  const dept = listOfDepartments()

  const handleChangeFName = (e) => {
    setFName(e.target.value)
  }
  const handleChangeLName = (e) => {
    setLName(e.target.value)
  }

  /**
   * @description Represents the columns configuration for a table.
   * @returns {Array} An array of column objects.
   */
  const columns = [
    {
      title: 'Employee Name',
      width: '25%',
      render: (text, record) => {
        if (record) {
          if (record.ownedBy) {
            return (
              <span>
                <b>{`${record.ownedBy.name} ${
                  record.ownedBy.mname ? record.ownedBy.mname : ''
                } ${record.ownedBy.lname}`}</b>
              </span>
            )
          }
        }
      },
      sorter: (a, b) => a.ownedBy?.name?.localeCompare(b.ownedBy?.name),
    },
    // {
    //   title: "Department",
    //   render: (text, record) => (
    //       <span>
    //          <Tag color="geekblue">{`${record.dept}`}</Tag>
    //       </span>
    //   ),
    // },
    {
      title: 'Assigned Department',
      width: '20%',
      textWrap: 'word-break',
      filters: dept.sort().map((obj) => ({
        text: obj,
        value: obj,
      })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record) {
          if ('ownedBy' in record) {
            if (record.ownedBy) {
              if ('assignedDept' in record.ownedBy) {
                return record.ownedBy.assignedDept.indexOf(value) === 0
              }
            }
          }
        }
      },
      render: (text, record) => {
        if (record) {
          if (record.ownedBy) {
            return (
              <span color='geekblue'>
                <b>
                  {`${
                    record.ownedBy.assignedDept === null
                      ? ''
                      : record.ownedBy.assignedDept
                  }`}
                </b>
              </span>
            )
          }
        }
      },
    },
    {
      title: 'Division',
      dataIndex: 'division',
      key: 'division',
      render: (text, record) => (
        <span>
          <b>{`${record.division}`}</b>
        </span>
      ),
      sorter: (a, b) => a.division?.localeCompare(b.division),
    },
    // {
    //   title: "Section/Unit",
    //   dataIndex: "sectionunit",
    //   key: "sectionunit",
    //   width: "15%",
    //   render: (text, record) => {
    //     if(record){
    //       if(record.ownedBy){
    //         (
    //           <span color="geekblue">
    //             <b>
    //               {`${record.ownedBy.sectionUnit === null ? "" : record.ownedBy.sectionUnit}`}
    //             </b>
    //           </span>
    //         )
    //       }
    //     }
    //   },
    // },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <>
          {record.isFinal === 'YES' ? (
            <Tag color='green'>
              {'Final Copy'} {'as of '}{' '}
              {moment(record.finalSubmitDate).format('MMMM D, YYYY h:mm:ss A')}
            </Tag>
          ) : (
            <Tag color='magenta'>
              {'Draft Copy'} {'as of '}{' '}
              {moment(record.finalSubmitDate).format('MMMM D, YYYY h:mm:ss A')}
            </Tag>
          )}
        </>
      ),
      sorter: (a, b) => a.finalSubmitDate?.localeCompare(b.finalSubmitDate),
    },
    {
      title: 'Date e-TNA Created',
      dataIndex: 'date',
      key: 'locale',
      render: (text, record) => (
        <Tag color='geekblue'>
          {moment(record.createdAt).format('MMMM D, YYYY h:mm:ss A')}
        </Tag>
      ),
      sorter: (a, b) => a.createdAt?.localeCompare(b.createdAt),
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      render: (text, record) => (
        <>
          <div className='p-2'>
            <a rel='noopener noreferrer' href={record.location} target='_blank'>
              <Button
                size='medium'
                style={{ marginLeft: '0px', marginTop: '3px' }}
                onClick={() => handleShowSurveybyId(record)}
              >
                <FundViewOutlined />
              </Button>
            </a>
          </div>
        </>
      ),
    },
  ]

  return (
    <>
      <div className='site-layout-background' style={{ padding: 24 }}>
        <Card title='List of Employee Submitted e-TNA Forms'>
          {fName.length || lName.length || deptSearch ? null : (
            <Row className={'mb-5'} justify='center'>
              <div className='userHeader'>
                <Search
                  icon='search'
                  size='large'
                  style={{ width: 400 }}
                  placeholder='Enter valid email address / username'
                  onSearch={(e) => handleSearchUsername(e)}
                  onChange={(e) => {
                    setEmailSearch(e.target.value)
                    if (!e.target.value.length) {
                      setEmployeeWithEtna([])
                    }
                  }}
                  allowClear
                />
              </div>
            </Row>
          )}

          {fName.length || lName.length || emailSearch.length ? null : (
            <Row className={'mb-5'} justify='center'>
              <div className='userHeader'>
                <Select
                  showSearch
                  optionFilterProp='children'
                  placeholder='Select department.'
                  onChange={(e) => {
                    if (e == '') {
                      setEmployeeWithEtna([])
                      setIsLoadingTable(true)
                      setDeptSearch('')
                    } else {
                      handleSearchDepartment(e)
                      setDeptSearch(e)
                    }
                  }}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  id='dept'
                  className='department-search'
                  required
                >
                  <Option value=''>Select department</Option>
                  {dept.sort().map((item, index) => (
                    <Option key={index} value={item}>
                      {item}
                    </Option>
                  ))}
                </Select>
              </div>
            </Row>
          )}

          {emailSearch.length || deptSearch ? null : (
            <Row className={'mb-5'} justify='center'>
              <Col className='mx-3'>
                <div className='userHeader'>
                  <Input
                    size='large'
                    style={{ width: 400 }}
                    placeholder='Enter first name of Employee'
                    allowClear
                    onChange={(e) => {
                      handleChangeFName(e)
                      if (!e.target.value.length && !lName.length) {
                        setEmployeeWithEtna([])
                        setIsLoadingTable(true)
                      }
                    }}
                  />
                </div>
              </Col>

              <Col className='mx-3'>
                <div className='userHeader'>
                  <Input
                    size='large'
                    style={{ width: 400 }}
                    placeholder='Enter last name of Employee'
                    allowClear
                    onChange={(e) => {
                      handleChangeLName(e)
                      if (!e.target.value.length && !fName.length) {
                        setEmployeeWithEtna([])
                        setIsLoadingTable(true)
                      }
                    }}
                  />
                </div>
              </Col>
              <Col className='mx-3'>
                <Button
                  disabled={!(fName.length && lName.length)}
                  style={{ height: '100%' }}
                  type='default'
                  onClick={handleSearchNames}
                >
                  Search Employee
                </Button>
              </Col>
            </Row>
          )}

          <Col span={24}>
            <Row>
              <Col span={24}>
                <Table
                  className={'font-Mont'}
                  bordered
                  dataSource={employeeWithEtna}
                  columns={columns}
                  pagination={{ pageSize: 50 }}
                  loading={loadingTable}
                  rowKey='id'
                />
              </Col>
            </Row>
          </Col>

          <Modal
            maskClosable={false}
            destroyOnClose={true}
            title='SURVEY ANSWERED'
            visible={isModalVisible}
            onOk={() => handleOk()}
            onCancel={() => handleCancel()}
            width={1000}
            cancelButtonProps={{ style: { display: 'none' } }}
          >
            {submissionData ? (
              <Form
                src={`https://www.sparksoft-demo.com/formio/${formSource}`}
                submission={{ data: submissionData }}
                onFormLoad={(e) => handleForm(e)}
              />
            ) : (
              'Form loading...'
            )}
          </Modal>
        </Card>
      </div>
    </>
  )
}

export default ManageEtna
