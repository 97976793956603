/**
 * Title: SubmittedPDSFormsDeptFilterComp.js
 * Description: This is a file that contains the component for the filter function of the department PDS table.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/SubmittedPDSFormsDeptFilterComp.js
 **/

import PropTypes from 'prop-types'

import { Button, Input } from 'antd'

export default function SubmittedPDSFormsDeptFilterComp({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
}) {
  return (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search 'Submitted by'`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Button
        type='primary'
        onClick={() => confirm()}
        size='small'
        style={{ width: 90, marginRight: 8 }}
      >
        Search
      </Button>
      <Button onClick={() => clearFilters()} size='small' style={{ width: 90 }}>
        Reset
      </Button>
    </div>
  )
}

// add props validation
SubmittedPDSFormsDeptFilterComp.propTypes = {
  setSelectedKeys: PropTypes.func,
  selectedKeys: PropTypes.array,
  confirm: PropTypes.func,
  clearFilters: PropTypes.func,
}
