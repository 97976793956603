/**
 * Title: PDSapprovalHR.js
 * Description: This is a file that contains the components for the approve PDS feature for HR roles.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/PDSapprovalHR.js
 **/

/*
 *Changes made:
 * 2024.10.12  | Harry Lagunsad | implement a form-like component for when returning the PDS from HR to employee
 */
import { useState } from 'react'

import { Button, Col, Modal, Row, notification } from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import DOMpurify from 'dompurify'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import {
  GetSecretValueCommand,
  SecretsManagerClient,
} from '@aws-sdk/client-secrets-manager'
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses'

import { updatePersonalDataSheet } from '../../api/mutations'
import { UserbyHomeDepartment } from '../../api/queries'
import {
  getEmailBodyApprovedByHR,
  getEmailBodyApprovedByHREmployeeNotif,
} from './PDSEmailTemplates'

const PDSapprovalHR = ({
  record,
  userFound,
  setIsOpenPDF,
  fetchUserData,
  setShowRemarksForm,
}) => {
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)

  const emailSchema = Yup.string().email()

  /**
   * @function getDeptAdminDetailsArrayFunction
   * @description Gets the department admin details.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<Array>} Returns an array of department admin details.
   * @throws {Error} Throws an error if an error occurs.
   */
  const getDeptAdminDetailsArrayFunction = async () => {
    try {
      let getDeptAdminDetailsArray = []
      let token = null

      do {
        const getDeptAdminDetails = await API.graphql(
          graphqlOperation(UserbyHomeDepartment, {
            department: record.department,
            filter: { pdsRole: { eq: 'deptApprover' } },
            limit: 1000,
            nextToken: token,
          })
        )

        getDeptAdminDetailsArray.push(
          ...getDeptAdminDetails.data.UserbyHomeDepartment.items
        )
        token = getDeptAdminDetails.data.UserbyHomeDepartment.nextToken
      } while (token)

      return getDeptAdminDetailsArray
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while fetching the department admin. Please contact the system admin for help.',
      })
      return []
    }
  }

  /**
   * @function paramsGenerator
   * @description Generates the parameters needed for sending an email.
   * @param {String} username The username of the employee.
   * @param {String} emailBody The email body.
   * @param {String} emailTitle The email title.
   * @param {String} sourceEmail The source email.
   * @returns {Object} Returns an object containing the email parameters.
   * @throws {Error} Throws an error if an error occurs.
   */
  const paramsGenerator = (username, emailBody, emailTitle, sourceEmail) => {
    try {
      return {
        Destination: {
          ToAddresses: [DOMpurify.sanitize(username)],
        },
        Message: {
          Body: {
            Text: {
              Data: DOMpurify.sanitize(emailBody),
              Charset: 'UTF-8',
            },
          },
          Subject: {
            Data: DOMpurify.sanitize(emailTitle), // replace with your email subject
            Charset: 'UTF-8',
          },
        },
        Source: DOMpurify.sanitize(sourceEmail),
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while generating the email parameters. Please contact the system admin for help.',
      })
      throw err
    }
  }

  /**
   * @function getSesClientToken
   * @description Gets the SES client token.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<Object>} Returns an SES client object.
   * @throws {Error} Throws an error if an error occurs.
   */
  const getSesClientToken = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const sesClient = new SESClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      return sesClient
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while checking validity of the session. Please contact the system admin for help.',
      })
      throw err
    }
  }

  /**
   * @function getSourceEmail
   * @description Gets the source email.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<String>} Returns the source email.
   * @throws {Error} Throws an error if an error occurs.
   */
  const getSourceEmail = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const secret_name = 'hrm-2023@secrets'
      const secretsClient = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await secretsClient.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)
      const sourceEmail = foundSecret.REACT_APP_SOURCE_EMAIL

      return sourceEmail
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while fetching the source email. Please contact the system admin for help.',
      })
      return ''
    }
  }

  /**
   * @function sendEmail
   * @description Sends an email to the employee.
   * @param {Object} sesClient The SES client object.
   * @param {String} sourceEmail The source email.
   * @async
   * @returns {Promise<void>} Returns nothing.
   * @throws {Error} Throws an error if an error occurs.
   */
  async function sendEmail(sesClient, sourceEmail) {
    try {
      const emailBody = getEmailBodyApprovedByHREmployeeNotif(record)

      const params = paramsGenerator(
        record.ownedBy.username,
        emailBody,
        'Approval of your Personal Data Sheet (HR Admin)',
        sourceEmail
      )

      if (window.location.href !== 'http://localhost:3000/') {
        await sesClient.send(new SendEmailCommand(params))
        notification.success({
          message: 'Email Sent',
          description: 'The email has been successfully sent to the employee.',
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while sending the email to the employee. Please contact the system admin for help.',
      })
    }
  }

  /**
   * @function sendEmailToDeptAdminApprove
   * @description Sends an email to the department admin.
   * @param {Object} sesClient The SES client object.
   * @param {String} sourceEmail The source email.
   * @param {Array} getDeptAdminDetailsArray The department admin details.
   * @async
   * @returns {Promise<void>} Returns nothing.
   * @throws {Error} Throws an error if an error occurs.
   */
  async function sendEmailToDeptAdminApprove(
    sesClient,
    sourceEmail,
    getDeptAdminDetailsArray
  ) {
    try {
      const emailBodyHR = getEmailBodyApprovedByHR(record)
      const deptApproverUsername = getDeptAdminDetailsArray[0].username

      if (await emailSchema.isValid(deptApproverUsername)) {
        const paramsHR = paramsGenerator(
          deptApproverUsername,
          emailBodyHR,
          'Personal Data Sheet for HR Review (Approved)',
          sourceEmail
        )

        if (window.location.href !== 'http://localhost:3000/') {
          await sesClient.send(new SendEmailCommand(paramsHR))
          notification.success({
            message: 'Email Sent',
            description:
              'The email has been successfully sent to the department admin.',
          })
        }
      }else {
        throw new Error('Invalid email address')
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while sending the email to the department admin. Please contact the system admin for help.',
      })
    }
  }

  /**
   * @function handleApproveSubmit
   * @description Handles the submission of the approval of the Personal Data Sheet.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<Boolean>} Returns a boolean value.
   * @throws {Error} Throws an error if an error occurs.
   */
  const handleApproveSubmit = async () => {
    try {
      await API.graphql(
        graphqlOperation(updatePersonalDataSheet, {
          input: {
            id: record.id,
            hrApproverID: userFound.id,
            isHRApproved: 'Approved',
          },
        })
      )

      notification.success({
        message: 'Submission Approved',
        description: 'The Personal Data Sheet has been successfully approved.',
        duration: 4.5,
      })

      const sesClient = await getSesClientToken()
      const sourceEmail = (await getSourceEmail()) || ''

      await sendEmail(sesClient, sourceEmail)

      const getDeptAdminDetailsArray = await getDeptAdminDetailsArrayFunction()

      await sendEmailToDeptAdminApprove(
        sesClient,
        sourceEmail,
        getDeptAdminDetailsArray
      )

      fetchUserData()
      setIsOpenPDF(false)
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while approving the Personal Data Sheet. Please contact the system admin for help.',
      })
    }
  }

  return (
    <>
      <Row justify='center' style={{ width: '50%', margin: 'auto' }}>
        <Col span={12}>
          <Row justify='space-around'>
            <Col>
              <Button onClick={() => setIsApproveModalOpen(true)}>
                Approve Personal Data Sheet
              </Button>
            </Col>
            <Col>
              <Button onClick={() => setShowRemarksForm(true)}>
                Add Remarks
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>

      <Modal
        maskClosable={false}
        title='Approve Employee Personal Data Sheet'
        open={isApproveModalOpen}
        onCancel={() => setIsApproveModalOpen(false)}
        footer={[]}
        destroyOnClose={true}
      >
        <Row justify='space-around'>
          <Button type='primary' onClick={handleApproveSubmit}>
            Approve
          </Button>
          <Button onClick={() => setIsApproveModalOpen(false)}>Cancel</Button>
        </Row>
      </Modal>
    </>
  )
}

export default PDSapprovalHR

// prop types
PDSapprovalHR.propTypes = {
  record: PropTypes.object.isRequired,
  userFound: PropTypes.object.isRequired,
  setIsOpenPDF: PropTypes.func.isRequired,
  fetchUserData: PropTypes.func.isRequired,
  setShowRemarksForm: PropTypes.func.isRequired,
}
