/**
 * Title: LDRefresher.js
 * Description: This is a file that contains the LDRefresher component
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/Homepage/LDRefresher.js
 **/

/*
 *Changes Made:
 *- 2024.08.09  |  Raymart Mojado  | Add Validation on the input before downloading
 * - 2024.08.27 | Raymart Mojado | update the validation accept ' and - characters on name input
 */
import { useEffect, useState } from 'react'

import { Button, Col, Input, Modal, Row, Select, notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'

import { PlayCircleFilled } from '@ant-design/icons'

import {
  createLdRefresher,
  createLdRefresherVideos,
} from '../../../api/mutations'
import { listOtherUploads, listTrainingVideos } from '../../../api/queries'
import VideoPlayer from './../videoPlayer'

const { Option } = Select

/**
 *@function LDRefresher
 *@description React component that displays the refresher PDF and refresher videos for Learning and Development (L&D).
 *The component fetches uploaded files and refresher videos from an API using AWS Amplify,
 * allowing users to view and interact with the content.
 *It provides a modal for acknowledging the refresher PDF, allows users to select and watch refresher videos,
 * and captures user information
 *when acknowledging and submitting video completion. The component also handles error notifications and has
 * various event handlers for
 *interacting with the UI elements.
 *@throws {Error} If there is an error while fetching uploaded files or refresher videos from the API.
 *@returns {Function} A function that renders the LDRefresher component.
 */

export default function LDRefresher({ userData }) {
  const user = userData

  const [name, setName] = useState(null)
  const [department, setDepartment] = useState(null)
  const [downloadLoc, setDownloadLoc] = useState(null)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isModalVisibleVideo, setIsModalVisibleVideo] = useState(false)

  const [videoKey, setVideoKey] = useState(null)
  const [departmentVideo, setDepartmentVideo] = useState(null)
  const [nameVideo, setNameVideo] = useState(null)

  const [foundVideos, setFoundVideos] = useState([])

  const [isAgreeVideo, setIsAgreeVideo] = useState(false)
  const [isAgreeVideoSubmit, setIsAgreeVideoSubmit] = useState(false)

  const [watch, setWatch] = useState(null)
  const [watchClosed, setWatchClosed] = useState(false)

  const [submitDisabled, setSubmitDisabled] = useState(true)

  const [nameError, setNameError] = useState(false)
  const [departmentError, setDepartmentError] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [tabError, setTabError] = useState(false)
  const [nameErrorBlank, setNameErrorBlank] = useState(false)

  useEffect(() => {
    if (isAgreeVideo && departmentVideo && nameVideo) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }, [isAgreeVideo, departmentVideo, nameVideo])

  const dept = [
    'Office of the City Mayor',
    'National Bureau of Investigation - Quezon City Hall',
    'Office of the City Councilors',
    'Office of the Vice Mayor',
    'Office of the City Administrator',
    'Office of the Secretary to the Mayor',
    'Office of the Secretary to the Sangguniang Panlungsod',
    'City Treasurer’s Office',
    'Barangay and Community Relations Department',
    'Board of Assessment Appeals',
    'Business Affairs Coordinating Office',
    'Business Permits and Licensing Department',
    'City Accounting Department',
    'City Architect Department',
    'City Assessor’s Office',
    'City Budget Department',
    'City Civil Registry Department',
    'City General Services Department',
    'Quezon City Health Department',
    'City Legal Department',
    'City Planning and Development Department',
    'City Veterinary Department',
    'Department of Public Order and Safety',
    'Department of the Building Official',
    'Quezon City Engineering Department',
    'Climate Change and Environmental Sustainability Department',
    'Human Resource Management Department',
    'Investment Affairs Office',
    'Liquor Licensing and Regulatory Board',
    'Market Development and Administration Department',
    'Novaliches District Center',
    'Novaliches District Hospital',
    'Office for the Senior Citizen’s Affairs',
    'Parks Development and Administration Department',
    'People’s Law Enforcement Board',
    'Persons with Disability Affairs Office',
    'Procurement Department',
    'Public Affairs and Information Services Department',
    'Public Employment Service Office',
    'Quezon City Disaster and Risk Reduction Management Office',
    'Quezon City Information Technology Development Department',
    'Quezon City Tourism Department',
    'Quezon City Fire District',
    'Quezon City General Hospital',
    'Quezon City Police District',
    'Quezon City Public Library',
    'Quezon City University',
    'Radio Communications Service',
    'Rosario Maclang Bautista General Hospital',
    'Quezon City Youth Development Office',
    'Small Business and Cooperatives Development and Promotions Office',
    'Social Services Development Department',
    'Schools Division Office Quezon City',
    'Department of Sanitation and Cleanup Works of Quezon City',
    'Youth Development Office',
    'Local Economic Investment Promotions Office',
  ]

  useEffect(() => {
    fetchUploadedFiles()
    fetchRefresherVideos()
  }, [])

  /**
   *@async
   *@function fetchUploadedFiles
   *@description Fetches uploaded files related to the refresher PDF for Learning and Development (L&D) from the API.
   *The function sends a GraphQL query using AWS Amplify to retrieve the uploaded files data.
   * It filters the files based on a specific search term.
   *Once the matching file is found, it extracts the download location and updates the state
   * variable 'downloadLoc' to store the location for further use.
   *@throws {Error} If there is an error while fetching or processing the uploaded files data.
   */
  const fetchUploadedFiles = async () => {
    try {
      const uploadedFilesData = await API.graphql({
        query: listOtherUploads,
        authMode: 'API_KEY',
      })
      const arrayItems = uploadedFilesData.data.listOtherUploads.items
      const searchTerm = 'MC NO. 05_Policy Guidelines for L&D Plan'
      const newArrayItems = arrayItems.filter((item) =>
        item.filename.includes(searchTerm)
      )
      setDownloadLoc(newArrayItems[0].location)
    } catch (error) {
      console.log(error)
    }
  }

  /**
   *@async
   *@function fetchRefresherVideos
   *@description Fetches refresher videos related to Learning and Development (L&D) from the API.
   *The function sends a GraphQL query using AWS Amplify to retrieve the refresher videos data.
   * It filters the videos based on specific keys.
   *Only the videos with keys matching the provided list of allowed keys are selected and
   * stored in the 'foundVideos' state variable for further use.
   *@throws {Error} If there is an error while fetching or processing the refresher videos data.
   */
  const fetchRefresherVideos = async () => {
    try {
      const refresherVideos = await API.graphql(
        graphqlOperation(listTrainingVideos)
      )
      const getCorrectVidoes =
        refresherVideos.data.listTrainingVideos.items.filter((item) => {
          if (
            item.key ===
              'L&D Interventions Appropriate to Levels of Position.mp4' ||
            item.key === 'E-Training Needs Analysis (ETNA).mp4' ||
            item.key ===
              'Individual Learning and Development Plan (ILDP).mp4' ||
            item.key === 'Mission Critical Competencies.mp4'
          ) {
            return item
          }
        })
      setFoundVideos(getCorrectVidoes)
    } catch (error) {
      console.log(error)
    }
  }

  const acknowledgmentModal = () => {
    setIsModalVisible(true)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  const showErrorNotification = (description) => {
    try {
      notification['error']({
        message: 'Error',
        description: description,
      })
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@async
   *@function handleOk
   *@description Handles the acknowledgment and submission of the refresher PDF in the LDRefresher component.
   *If the required fields (name and department) are not filled or the department is set to 'disabled',
   * it displays an error notification.
   *Otherwise, it creates a new refresher acknowledgment by sending a GraphQL mutation using AWS Amplify.
   *The acknowledgment data is retrieved from the response, and if the acknowledgment is
   * successfully created, it opens the refresher PDF in a new window,
   *closes the modal, and resets the relevant state variables after a delay of 2000 milliseconds.
   *@throws {Error} If there is an error while creating the refresher acknowledgment or
   * processing the response data.
   */
  const handleOk = async () => {
    if (
      (!tabError && !nameError && department && name) ||
      department === 'disabled'
    ) {
      const input = {
        employeeName: name,
        department,
        date: new Date(),
      }

      try {
        const acknowledgment = await API.graphql({
          query: createLdRefresher,
          variables: { input },
          authMode: 'API_KEY',
        })

        if (acknowledgment.data.createLdRefresher) {
          window.open(downloadLoc, '_blank')
          setIsModalVisible(false)

          setTimeout(() => {
            setName(null)
            setDepartment(null)
            setDownloadLoc(null)
          }, 2000)
        }
      } catch (err) {
        console.log(err)
      }
    } else {
      if (!name && !department) {
        setNameErrorBlank('Employee`s name cannot be blank.')
        setDepartmentError('Department name cannot be blank.')
        showErrorNotification('Please complete the fields below to continue.')
      } else if (!name) {
        showErrorNotification('Employee`s name cannot be blank.')
        setNameErrorBlank('Employee`s name cannot be blank.')
      } else if (!department) {
        showErrorNotification('Department cannot be blank.')
        setDepartmentError('Department name cannot be blank.')
      }
    }
  }

  const handleSelect = (value) => {
    setDepartment(value)
  }

  const handleSelectVideo = (value) => {
    setDepartmentVideo(value)
  }

  const dateDisplay = `${
    new Date().getMonth() + 1
  }-${new Date().getDate()}-${new Date().getFullYear()}`

  const handlePlayButton = (key) => {
    setIsModalVisibleVideo(true)
    setVideoKey(key)
  }

  /**
   *@async
   *@function handleSubmitVideo
   *@description Handles the submission of the refresher video acknowledgment in the LDRefresher component.
   *It creates a new refresher video acknowledgment by sending a GraphQL mutation using AWS Amplify.
   *The input for the mutation includes the employee name, department, date, and video name.
   *After the mutation is executed, the response data is stored in the 'saveRefresherVideoAcknowlegment' variable.
   *If the refresher video acknowledgment is successfully created, it sets
   * the 'isAgreeVideoSubmit' state variable to true,
   *closes the video modal, and updates the state variable 'watch' with the response data.
   *@throws {Error} If there is an error while creating the refresher video acknowledgment or
   * processing the response data.
   */
  const handleSubmitVideo = async () => {
    try {
      const input = {
        employeeName: nameVideo,
        department: departmentVideo,
        date: new Date(),
        videoName: videoKey,
      }
      const saveRefresherVideoAcknowlegment = await API.graphql(
        graphqlOperation(createLdRefresherVideos, { input })
      )
      setWatch(saveRefresherVideoAcknowlegment)
      if (saveRefresherVideoAcknowlegment.data.createLdRefresherVideos) {
        setIsAgreeVideoSubmit(true)
        setIsModalVisibleVideo(false)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    setIsAgreeVideo(false)
    setIsAgreeVideoSubmit(false)
    if (user) {
      setDepartmentVideo(user.assignedDept)
      setNameVideo(
        `${user.name}${user.mname ? ' ' + user.mname : ''} ${user.lname}`
      )
      setDepartment(user.assignedDept)
      setName(`${user.name}${user.mname ? ' ' + user.mname : ''} ${user.lname}`)
    } else {
      setDepartmentVideo(null)
      setNameVideo(null)
      setDepartment(null)
      setName(null)
    }
  }, [isModalVisible, isModalVisibleVideo])

  const handleSetWatchClose = (e) => {
    setWatchClosed(e)
    setVideoKey(null)
  }

  const handleChange = (e) => {
    const value = e.target.value
    const regex = /^(?:[a-zA-ZñéáíóúÑÉÁÍÓÚ.\s-']+)$/

    if (!/^(\s)/.test(value)) {
      if (!value.includes('\t') && regex.test(value) && value) {
        setName(value)
        setTabError(false)
        setNameError(false)
        setDisabledButton(false)
      }
      if (value && !regex.test(value)) {
        setNameError('The employee`s name has an invalid characters.')
        setName(value)
        setDisabledButton(true)
      }
      if (value.includes('\t')) {
        setTabError('Tab characters are not allowed.')
        setName(value)
        setDisabledButton(true)
      }
      if (value && value.length >= 256) {
        setNameError(
          'The employee`s name cannot be longer than 256 characters.'
        )
        setName(value)
        setDisabledButton(true)
      }
      if (!value) {
        setName(value)
        setDisabledButton(true)
        setTabError(false)
        setNameError(false)
      }
    }
  }

  const handleNameFocus = () => {
    try {
      setNameErrorBlank(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleDeptFocus = () => {
    try {
      setDepartmentError(false)
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <>
      <div className='container'>
        <div className='mx-auto font-Mont'>
          <Row className='info' style={{ marginTop: 100 }} justify='left'>
            <Row className='heading border-bottom border-dark w-100'>
              <h1 className='fw-bolder font-Mont display-md-1 display-md-3 display-5'>
                L&D Refresher
              </h1>
            </Row>
            <span className='mt-3 text-start p-md-0 p-4'>
              {' '}
              The policy serves as guide in adopting Learning & Development
              interventions to address competency gaps, achieve and sustain
              improvements in individual employees and organizational
              performance.
            </span>
          </Row>
          <Row className='button ps-md-0 pe-md-0 ps-3 pe-3' justify='left'>
            <button
              style={{ fontSize: 12, backgroundColor: '#8aa4d7' }}
              className='mb-5 mt-5 p-3 ps-5 pe-5 rounded-pill fw-bold text-white border-0 col-12 col-md-3'
              onClick={() => {
                acknowledgmentModal()
              }}
              onMouseOver={(event) => {
                event.target.style.backgroundColor = '#4d7bd6'
              }}
              onMouseLeave={(event) => {
                event.target.style.backgroundColor = '#8aa4d7'
              }}
            >
              Download Policy Guidelines (PDF)
            </button>
          </Row>
        </div>

        <Row className='info mt-3' justify='left'>
          <Row className='heading border-bottom border-dark w-100'>
            <h1 className='fw-bolder font-Mont display-md-1 display-md-3 display-5'>
              L&D Refresher Videos
            </h1>
          </Row>
          <Col className='mb-3' span={24}>
            <Row gutter={12} justify='space-between'>
              {foundVideos.map((item, index) => {
                return (
                  <Col span={6} key={index}>
                    <Row justify='center'>
                      <div className='card shadow mt-3'>
                        <div>
                          <img
                            src={`https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/ldicon.png`}
                            alt={item.key}
                            className='w-100'
                          />
                          <div
                            className='playbutton btn cursor-pointer mb-5'
                            onClick={() => handlePlayButton(item.key)}
                            style={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '80px',
                              height: '80px',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              top: '40%',
                            }}
                          >
                            <PlayCircleFilled
                              style={{ fontSize: '43px', color: '#fff' }}
                            />
                          </div>
                        </div>
                        <div className='card-body'>
                          <h4 className='card-title'>
                            {item.key.slice(0, -4)}
                          </h4>
                        </div>
                      </div>
                    </Row>
                  </Col>
                )
              })}
            </Row>
          </Col>
        </Row>

        <Modal
          maskClosable={false}
          title='Acknowledgment and Receipt'
          width={1000}
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            disabled: disabledButton,
          }}
        >
          <p>
            I have received the L&D Refresher Policy Guidelines (PDF), and I
            understand that it is my responsibility to read and comply with the
            policies contained in this guidelines and any revisions made to it.
          </p>

          <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
              <Input
                value={name}
                onChange={handleChange}
                onFocus={handleNameFocus}
                maxLength={256}
                placeholder='Enter your name'
                className='text-center'
              />
              {tabError && (
                <div style={{ color: 'red', fontSize: '12px' }}>{tabError}</div>
              )}
              {nameError && (
                <div style={{ color: 'red', fontSize: '12px' }}>
                  {nameError}
                </div>
              )}
              {nameErrorBlank && (
                <div style={{ color: 'red', fontSize: '12px' }}>
                  {nameErrorBlank}
                </div>
              )}
              <span className='text-center'>Employee's Name & Signature</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
              <span>{dateDisplay}</span>
              <span>Date</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
              <Select
                value={department}
                onChange={handleSelect}
                onFocus={handleDeptFocus}
                className='w-100 text-center'
              >
                <Option value='disabled' disabled>
                  Disabled
                </Option>
                {dept.sort().map((item, qindex) => (
                  <Option key={qindex} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              {departmentError && (
                <div style={{ color: 'red', fontSize: '12px' }}>
                  {departmentError}
                </div>
              )}
              <span className='text-center'>Department</span>
            </div>
          </div>
        </Modal>

        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='Acknowledgment and Receipt'
          width={1000}
          open={isModalVisibleVideo}
          onCancel={() => setIsModalVisibleVideo(false)}
          footer={[
            <Button onClick={() => setIsModalVisibleVideo(false)}>
              Cancel
            </Button>,
            <Button
              type='primary'
              onClick={handleSubmitVideo}
              disabled={submitDisabled}
            >
              Submit
            </Button>,
          ]}
        >
          <div>
            <p>STATEMENT OF CLAIMING VIEW</p>
            <p className='text-justify'>
              I hereby declare and affirm my commitment to the terms and
              conditions set forth in this statement regarding the training
              video material provided to me in this platform (www.qc-hrmd.org),
              and its entirety, by the Human Resource Management Department
              (HRMD) of the Quezon City Government. I acknowledge that the
              training video material is the exclusive property of HRMD, and as
              such, I fully understand and agree to abide by the following
              terms:
            </p>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>1.</p>
              <p>
                {' '}
                <strong>Ownership:</strong> I recognize that the training video
                material is protected by intellectual property laws and is the
                sole and exclusive property of HRMD. I acknowledge that I have
                no rights or ownership claims over the content contained within
                the training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>2.</p>
              <p>
                {' '}
                <strong>Non-Duplication:</strong> I solemnly affirm that I will
                not duplicate or reproduce, in whole or in part, the training
                video material provided to me by HRMD. I understand that
                duplication includes but is not limited to copying, recording,
                downloading, or distributing the material through any means or
                in any format without explicit written consent from HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>3.</p>
              <p>
                {' '}
                <strong>Confidentiality:</strong> I acknowledge and respect that
                the training video material may contain confidential and
                proprietary information belonging to HRMD. I commit to
                maintaining the strictest confidentiality regarding the content,
                concepts, techniques, and methodologies presented in the
                training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>4.</p>
              <p>
                {' '}
                <strong>Personal Use:</strong> I understand that the training
                video material is solely intended for my personal use, learning,
                and development. I agree not to share, lend, or otherwise make
                the training video material available to any third party,
                whether for commercial or non-commercial purposes, without the
                express written consent of HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>5.</p>
              <p>
                {' '}
                <strong>Legal Consequences:</strong> I acknowledge that any
                unauthorized duplication or reproduction of the training video
                material may result in legal consequences, including but not
                limited to civil lawsuits and monetary damages. I accept full
                responsibility for any such consequences resulting from my
                violation of this agreement.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>6.</p>
              <p>
                {' '}
                <strong>Compliance:</strong> I commit to cooperating with HRMD
                and its representatives in any investigation of suspected
                violation of this agreement. I understand that HRMD reserves the
                right to take appropriate legal action to enforce these terms
                and protect its rights.
              </p>
            </div>
            <p>
              By inputting my full name and department/office in this statement,
              I acknowledge that I have read and understood the terms outlined
              above and that I will comply with them strictly. I further
              acknowledge that any breach of this agreement may result in severe
              legal consequences.
            </p>
            <div className='d-flex'>
              <label className='d-flex items-align-center gap-2'>
                <input
                  type='checkbox'
                  checked={isAgreeVideo}
                  onChange={(e) => setIsAgreeVideo(!isAgreeVideo)}
                />
                I agree
              </label>
            </div>
          </div>

          <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
              <Input
                value={nameVideo}
                onChange={(e) => setNameVideo(e.target.value)}
                placeholder='Enter your name'
                className='text-center'
              />
              <span className='text-center'>Employee's Name & Signature</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
              <span>{dateDisplay}</span>
              <span>Date</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
              <Select
                value={departmentVideo}
                onChange={handleSelectVideo}
                className='w-100 text-center'
              >
                <Option value='disabled' disabled>
                  Disabled
                </Option>
                {dept.sort().map((item, pindex) => (
                  <Option key={pindex + 1000} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <span className='text-center'>Department</span>
            </div>
          </div>
        </Modal>

        <VideoPlayer
          videoKey={videoKey}
          setDepartmentVideo={(e) => setDepartmentVideo(e)}
          setIsModalVisible={(e) => setIsModalVisibleVideo(e)}
          isAgreeVideoSubmit={isAgreeVideoSubmit}
          setIsAgreeVideoSubmit={(e) => setIsAgreeVideoSubmit(e)}
          watch={watch}
          setWatchClosed={(e) => handleSetWatchClose(e)}
        />
      </div>
    </>
  )
}
