/**
 * Title: homeName.js
 * Description: This is a file that contains the home name title
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/Homepage/homeName.js
 **/

import { Grid } from 'antd'

const { useBreakpoint } = Grid

export default function HomeName() {
  const { md, lg, xl } = useBreakpoint() // lg is one of the elements returned if screenwidth exceeds 991
  const myFontSize = xl ? '29px' : lg ? '24px' : md ? '16px' : '14px'
  const pageName = (
    <span style={{ fontSize: myFontSize }}>
      Human Resource @ Quezon City Government
    </span>
  )
  return <>{pageName}</>
}
