/**
 * Title: dept.js
 * Description: This is a file that contains the list of departments in Quezon City Government
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/Utilities/dept.js
 **/

const listOfDepartments = () => {
  const dept = [
    'Office of the City Mayor',
    'National Bureau of Investigation - Quezon City Hall',
    'Office of the City Councilors',
    'Office of the Vice Mayor',
    'Office of the City Administrator',
    'Office of the Secretary to the Mayor',
    'Office of the Secretary to the Sangguniang Panlungsod',
    'Office of the Secretary to the Sanggunian Panlungsod (City Secretary)',
    'City Treasurer’s Office',
    'Barangay and Community Relations Department',
    'Board of Assessment Appeals',
    'Business Affairs Coordinating Office',
    'Business Permits and Licensing Department',
    'City Accounting Department',
    'City Architect Department',
    'City Budget Department',
    'City Civil Registry Department',
    'City General Services Department',
    'Quezon City Health Department',
    'City Legal Department',
    'City Planning and Development Department',
    'City Veterinary Department',
    'Department of Public Order and Safety',
    'Department of the Building Official',
    'Quezon City Engineering Department',
    'Climate Change and Environmental Sustainability Department',
    'Housing Community Development and Resettlement Department',
    'Human Resource Management Department',
    'Liquor Licensing and Regulatory Board',
    'Market Development and Administration Department',
    'Novaliches District Center',
    'Novaliches District Hospital',
    'Office for the Senior Citizen’s Affairs',
    'Parks Development and Administration Department',
    'People’s Law Enforcement Board',
    'Persons with Disability Affairs Office',
    'Procurement Department',
    'Public Affairs and Information Services Department',
    'Public Employment Service Office (PESO)',
    'Quezon City Center for Children with Disabilities',
    'Quezon City Disaster and Risk Reduction Management Office',
    'Information Technology Development Department',
    'Quezon City Tourism Department',
    'Quezon City Fire District',
    'Quezon City General Hospital',
    'Quezon City Police District',
    'Quezon City Protection Center for Victims/Survivors of Gender Based Violence And Abuse',
    'Quezon City Public Library',
    'Quezon City University',
    'Radio Communications Service',
    'Rosario Maclang Bautista General Hospital',
    'Quezon City Youth Development Office',
    'Small Business and Cooperatives Development and Promotions Office',
    'Social Services Development Department',
    'Schools Division Office Quezon City',
    'Task Force for Transport and Traffic Management (TF-TTM)',
    'Department of Sanitation and Cleanup Works of Quezon City',
    'Local Economic Investment Promotions Office',
    'House Resources Management Department',
    'Investment Affairs Office',
    'Office of the Sectorial Representative (Liga Ng Mga Barangay)',
    'Office of the Sectorial Representative (Sangguniang Kabataan)',
    'Sports Development (SDO)',
    'Tricycle Franchising (TFB)',
    'Chief of Staff (COS)',
    'Mayor’s Proper',
    'Quezon City Competency Assessment Center (QC-CAC)',
    'Quezon City Anti-Drug Council (QCADAC)',
    'Quezon City Drug Treatment and Rehabilitation Center (TAHANAN)',
    'Office of the City Assessor',
    'Traffic and Transport Management Department (TTMD)',
    'Quezon City Citizen Services Department (QCCSD)',
    'Special Operating Units',
    'Amoranto Sports Complex',
    'Zoning Administrative Unit',
    'QC Bangsamoro Affairs Office',
    'Task Force Street lighting',
    'QC Epidemiology & Surveillance Unit',
    'Educational Affairs Unit',
    'Task Force Disiplina',
    'Task Force COPRESS',
    'Quezon City Memorial Cicle Administration Office',
    'Internal Audit Service',
    'Strategic Human Resource Unit',
    'Bureau of Fire Protection (BFP)',
    'Bureau of Jail Management and Penology (BJMP)',
    'Department of Justice Board of Pardons and Parole',
    'Commission on Audit (COA)',
    'Deparment of the Internal and Local Government (DILG)',
    'Metropolitan Trial Court',
    'Regional Trial Court',
    'Office of the City Prosecutor',
    'Division of City Schools',
    'Others',
    'District Action Office 1',
    'District Action Office 2',
    'District Action Office 3',
    'District Action Office 4',
    'District Action Office 5',
    'District Action Office 6',
    'Gender and Development Council Office',
  ]

  dept.sort()

  return dept
}

export default listOfDepartments
