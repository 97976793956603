/**
 * Title: PDSVideoLoader.js
 * Description: This is a file that contains the components for the Personal Data Sheet form.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/PDSVideoLoader.js
 **/

import React, { useState, useEffect, useRef } from 'react'

import { API, Auth, graphqlOperation } from 'aws-amplify'
import {
  CloudFrontClient,
  GetDistributionCommand,
} from '@aws-sdk/client-cloudfront'
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from '@aws-sdk/client-secrets-manager'
import { getSignedUrl } from '@aws-sdk/cloudfront-signer'

import { Button, Modal, Row, Col, notification } from 'antd'

import './../Home/videoPlayer.css'
import ReactPlayer from 'react-player'

export default function PDSVideoLoader() {
  const [videoUrl, setVideoUrl] = useState(null)

  const playerRef = useRef(null)

  useEffect(() => {
    getVideoUrl()
  }, [])

  /**
   * Retrieves a signed URL for a video stored in CloudFront CDN.
   *
   * This asynchronous function performs the following steps:
   * 1. Authenticates and retrieves AWS credentials.
   * 2. Fetches a secret from AWS Secrets Manager.
   * 3. Retrieves CloudFront distribution information.
   * 4. Generates a signed URL for the video using CloudFront's private key.
   *
   * @async
   * @function getVideoUrl
   * @throws {Error} If there's an issue retrieving the video URL.
   * @returns {Promise<void>} Does not return a value, but sets the video URL using a setter function.
   *
   * @example
   * try {
   *   await getVideoUrl();
   *   // Video URL is now set and can be used
   * } catch (error) {
   *   console.error('Failed to get video URL:', error);
   * }
   */
  async function getVideoUrl() {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken
      const secret_name = 'hrmCloudfront'
      const client = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await client.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)
      const objectKey = 'PDS video tutorial ver 2.mp4'
      const cdnClient = new CloudFrontClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const getDistributionParams = {
        Id: foundSecret.hrmDistributionID,
      }
      const commandCDN = new GetDistributionCommand(getDistributionParams)
      const response = await cdnClient.send(commandCDN)
      const newURL = `https://${response.Distribution.DomainName}/output/${objectKey}`
      const privateKey = foundSecret.hrmCloudfrontKey.replace(/\s*&&\s*/g, '\n')
      const keyPairId = foundSecret.hrmCloudfrontID
      const dateLessThan = new Date(Date.now() + 3600 * 1000)

      const signedUrlCDN = getSignedUrl({
        url: newURL,
        keyPairId,
        dateLessThan,
        privateKey,
      })

      setVideoUrl(signedUrlCDN)
    } catch (error) {
      console.log('Error getting video URL:', error)
    }
  }

  const file = {
    attributes: {
      controlsList: 'nodownload',
      onContextMenu: (e) => e.preventDefault(),
    },
  }

  return (
    <Row>
      <Col span={24} className='d-md-flex'>
        {videoUrl ? (
          <>
            <ReactPlayer
              className='video-player w-100'
              ref={playerRef}
              playing={true}
              url={videoUrl}
              controls
              config={{ file }}
              onEnded={() => console.log('err')}
            />
          </>
        ) : null}
      </Col>
    </Row>
  )
}
