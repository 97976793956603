/**
 * Title: trainingPrograms.js
 * Description: This is a file that contains the components that show all the training programs
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/trainingPrograms.js
 *
 *Changes made:
 * - 2024.11.09  | Harry Lagunsad | add Props Validation
 * - 2024.11.09  | Harry Lagunsad | fix sonarlint issues
 * - 2024.11.09  | Harry Lagunsad | fix sonarlint issues and fix post training modal message
 **/
import React, { useEffect, useState } from 'react'

import { Button, Card, Col, Row } from 'antd'
import { API } from 'aws-amplify'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  listOnlineTrainingAnnouncements,
  listTrainingAnnouncements,
} from '../../api/queries'
import HtmlParser from './html-parser.js'
import OnlineTrainingRegistrationModal from './onlineTrainingRegistrationModal.js'
import PostTrainingModal from './postTrainingModal.js'
import TrainingRegistrationModal from './trainingRegistrationModal.js'

export default function TrainingPrograms({
  triggerScroll,
  gotoForms,
  gotoCSCVideos,
  gotoCSCMaterials,
  gotoSecWeek,
  gotoThirdWeek,
  gotoSept8,
  gotosept15,
  gotoNov20,
  gotoJan21,
  userData,
  gotoSept8Batch2,
}) {
  const [upcomingPubmat, setUpcomingPubmat] = useState([])
  const [oldPubmat, setOldPubmat] = useState([])

  const [afterRegister, setAfterRegister] = useState(null)

  const [refresh, setRefresh] = useState(null)

  const [currentPage, setCurrentPage] = useState(0)
  const [currentShows, setCurrentShows] = useState([])
  const endIndex = currentPage * 5
  const startIndex = endIndex - 5

  const [currentPageNew, setCurrentPageNew] = useState(0)
  const [currentShowsNew, setCurrentShowsNew] = useState([])
  const endIndexNew = currentPageNew * 5
  const startIndexNew = endIndexNew - 5

  useEffect(() => {
    const holdShow = oldPubmat
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(startIndex, endIndex)
    setCurrentShows([...currentShows, ...holdShow])
  }, [currentPage])

  useEffect(() => {
    const holdShowNew = upcomingPubmat
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(startIndexNew, endIndexNew)
    setCurrentShowsNew([...currentShowsNew, ...holdShowNew])
  }, [currentPageNew])

  useEffect(() => {
    setTimeout(() => {
      handleLoadMoreNew()
    }, 2500)
  }, [])

  const getAllPubmats = async () => {
    try {
      let token = null
      let token2 = null
      let foundItems = []

      do {
        const result2 = await API.graphql({
          query: listOnlineTrainingAnnouncements,
          authMode: 'API_KEY',
          variables: {
            limit: 1000,
            nextToken: token2,
          },
        })

        const { items, nextToken } =
          result2.data.listOnlineTrainingAnnouncements
        foundItems = [
          ...foundItems,
          ...items.map((item) => ({
            ...item,
            isOnlineTraining: true,
          })),
        ]
        token2 = nextToken
      } while (token2)

      do {
        const result = await API.graphql({
          query: listTrainingAnnouncements,
          authMode: 'API_KEY',
          variables: {
            limit: 1000,
            nextToken: token,
          },
        })

        const { items, nextToken } = result.data.listTrainingAnnouncements
        foundItems = [
          ...foundItems,
          ...items.map((item) => ({
            ...item,
            isOnlineTraining: false,
          })),
        ]
        token = nextToken
      } while (token)

      if (foundItems.length) {
        let holdOld = []
        let holdNew = []

        // filter out training announcements that has no post training dates then push to holdOld
        // they are considered as old trainings as post training function came later
        foundItems.forEach((itemData) => {
          if (!itemData.postTrainClose) {
            holdOld.push(itemData)
          }
        })

        // sort by date then filter out trainings that has its post training past today date
        // items post training date is past today are pushed to Past Trainings
        const newData = foundItems.filter((itemData) => {
          if (itemData.postTrainClose) {
            return itemData
          }
        })

        newData
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .forEach((item) => {
            if (moment(item.postTrainClose).isSameOrAfter(new Date(), 'day')) {
              holdNew.push(item)
            } else {
              holdOld.push(item)
            }
          })

        // remove test events in the Upcoming Training
        // use isTestEvent field to filter
        const removeTestEvents = holdNew.filter((h) => {
          let checkEvent = h.isTestEvent === true

          if (checkEvent === false) {
            return h
          }
        })

        setUpcomingPubmat(
          removeTestEvents.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          )
        )
        setOldPubmat(
          holdOld.sort((a, b) => new Date(b.date) - new Date(a.date))
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllPubmats()
  }, [])

  useEffect(() => {
    document.getElementById('root').scrollTo(0, 0)
  }, [])

  const setNewPubmat = currentShowsNew.map((item) => {
    const sortByName = JSON.parse(item.location)

    return (
      <Col className={'next-card-margin'} key={item.id} span={24}>
        <Row justify='center'>
          <Col className='col-md-9 col-11'>
            <div className={'tPage-box-shadow p-3'}>
              <Col span={24}>
                <div className='d-md-flex'>
                  <Col className='p-0 d-md-none d-block'>
                    <Row
                      style={{ height: '100%', width: '100%' }}
                      justify='end'
                      align='center'
                    >
                      <div className={'d-flex align-items-center'}>
                        <img
                          alt=''
                          style={{ maxWidth: '100%' }}
                          src={sortByName.sort()[0]}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col className='mt-md-0 mt-3 col-md-8'>
                    <div className={'me-3'}>
                      <Row
                        id={
                          item.title.includes('Wave 2') ? 'wave-2' : undefined
                        }
                        className={'text-monts tPage-header'}
                        justify='start'
                      >
                        {item.title}
                      </Row>

                      <Row className={'text-monts tPage-categ'} justify='start'>
                        {'Description'}
                      </Row>

                      <Row className={'text-monts'} justify='start'>
                        <Col>
                          <HtmlParser
                            content={item?.courseDescription?.replace(
                              /\n/g,
                              '<br>'
                            )}
                          />
                        </Col>
                      </Row>

                      <Row className={'text-monts tPage-categ'} justify='start'>
                        {'Target Participants'}
                      </Row>

                      <Row className={'text-monts'} justify='start'>
                        <Col>
                          <HtmlParser
                            content={item?.targetParticipants?.replace(
                              /\n/g,
                              '<br>'
                            )}
                          />
                        </Col>
                      </Row>
                      {item.sectionTitle ? (
                        <Row justify='start'>
                          <OnlineTrainingRegistrationModal
                            userData={userData}
                            refresh={refresh}
                            setRefresh={(e) => setRefresh(e)}
                            gotoForms={() => gotoForms()}
                            setAfterRegister={(e) => setAfterRegister(e)}
                            trainingItem={item}
                            afterRegister={afterRegister}
                          />
                        </Row>
                      ) : (
                        <Row justify='start'>
                          <TrainingRegistrationModal
                            userData={userData}
                            refresh={refresh}
                            setRefresh={(e) => setRefresh(e)}
                            gotoForms={() => gotoForms()}
                            gotoCSCVideos={() => gotoCSCVideos()}
                            gotoSecWeek={() => gotoSecWeek()}
                            gotoThirdWeek={() => gotoThirdWeek()}
                            gotoSept8={() => gotoSept8()}
                            gotoSept8Batch2={() => gotoSept8Batch2()}
                            gotosept15={() => gotosept15()}
                            gotoNov20={() => gotoNov20()}
                            gotoJan21={() => gotoJan21()}
                            gotoCSCMaterials={(e) => gotoCSCMaterials(e)}
                            setAfterRegister={(e) => setAfterRegister(e)}
                            trainingItem={item}
                          />
                        </Row>
                      )}

                      <Row>
                        <PostTrainingModal
                          userData={userData}
                          afterRegister={afterRegister}
                          trainingItem={item}
                          isECert={item.isOnlineTraining}
                        />
                      </Row>
                    </div>
                  </Col>
                  <Col className='p-0 d-md-block d-none' span={8}>
                    <Row
                      style={{ height: '100%', width: '100%' }}
                      justify='end'
                      align='center'
                    >
                      <div className={'d-flex align-items-center'}>
                        <img
                          alt=''
                          style={{ maxWidth: '100%' }}
                          src={sortByName.sort()[0]}
                        />
                      </div>
                    </Row>
                  </Col>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Col>
    )
  })

  const setOldPubmatDiv = currentShows.map((item) => {
    const sortByName = JSON.parse(item.location)
    return (
      <Col className={'next-card-margin'} key={item.id} span={24}>
        <Row justify='center'>
          <Col className='col-md-9 col-11'>
            <div className={'tPage-box-shadow p-3'}>
              <Col span={24}>
                <div className='d-md-flex'>
                  <Col className='d-block d-md-none'>
                    <Row
                      style={{ height: '100%', width: '100%' }}
                      justify='end'
                      align='center'
                    >
                      <div className={'d-flex align-items-center'}>
                        <img
                          alt=''
                          style={{ maxWidth: '100%' }}
                          src={sortByName.sort()[0]}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col className='mt-md-0 mt-3'>
                    <div className={'me-3'}>
                      <Row
                        className={'text-monts tPage-header'}
                        justify='start'
                      >
                        {item.title}
                      </Row>

                      <Row className={'text-monts tPage-categ'} justify='start'>
                        {'Description'}
                      </Row>

                      <Row className={'text-monts'} justify='start'>
                        <Col>
                          <HtmlParser
                            content={item?.courseDescription?.replace(
                              /\n/g,
                              '<br>'
                            )}
                          />
                        </Col>
                      </Row>

                      {item.targetParticipants ? (
                        <>
                          <Row
                            className={'text-monts tPage-categ'}
                            justify='start'
                          >
                            {'Target Participants'}
                          </Row>

                          <Row className={'text-monts'} justify='start'>
                            <Col>
                              <HtmlParser
                                content={item?.targetParticipants?.replace(
                                  /\n/g,
                                  '<br>'
                                )}
                              />
                            </Col>
                          </Row>
                        </>
                      ) : null}

                      <Row justify='start' className={'mt-3'}>
                        <span className={'p-2'}>
                          <i>
                            <b>CLOSED</b>
                          </i>
                        </span>
                      </Row>
                    </div>
                  </Col>
                  <Col className='p-0 d-md-block d-none' span={8}>
                    <Row
                      style={{ height: '100%', width: '100%' }}
                      justify='end'
                      align='center'
                    >
                      <div className={'d-flex align-items-center'}>
                        <img
                          alt=''
                          style={{ maxWidth: '100%' }}
                          src={sortByName.sort()[0]}
                        />
                      </div>
                    </Row>
                  </Col>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Col>
    )
  })

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  const handleLoadMoreNew = () => {
    setCurrentPageNew(currentPageNew + 1)
  }

  return (
    <>
      <div className='d-flex justify-content-center'>
        <h3 className='font-Mont p-4'>Trainings and Programs</h3>
      </div>

      <Row
        style={{ paddingTop: '16px', paddingBottom: '16px' }}
        justify='center'
      >
        <div style={{ width: 900 }}>
          <h4 className={'font-Mont'}>Upcoming Trainings</h4>
        </div>
      </Row>
      <Row
        style={{ paddingTop: '16px', paddingBottom: '16px' }}
        justify='center'
      >
        {upcomingPubmat.length ? (
          <>{setNewPubmat}</>
        ) : (
          <Card
            size='small'
            title='TBA'
            style={{
              width: 900,
            }}
          ></Card>
        )}
      </Row>

      {currentShowsNew.length < upcomingPubmat.length &&
        currentShowsNew.length >= 5 && (
          <Row justify='center' className='mb-4'>
            <Button onClick={handleLoadMoreNew}>Load More</Button>
          </Row>
        )}

      <Row
        style={{ paddingTop: '40px', paddingBottom: '16px' }}
        justify='center'
      >
        <div style={{ width: 900 }}>
          <h4 className={'font-Mont'}>Past Trainings</h4>
        </div>
      </Row>
      <Row
        style={{ paddingTop: '16px', paddingBottom: '16px' }}
        justify='center'
      >
        {oldPubmat.length ? <>{setOldPubmatDiv}</> : null}
      </Row>

      {upcomingPubmat.length ? (
        <>
          {currentShowsNew.length > 0 &&
            currentShows.length < oldPubmat.length && (
              <Row justify='center' className='mb-4'>
                <Button onClick={handleLoadMore}>Load More</Button>
              </Row>
            )}
        </>
      ) : (
        <>
          {currentShows.length < oldPubmat.length && (
            <Row justify='center' className='mb-4'>
              <Button onClick={handleLoadMore}>Load More</Button>
            </Row>
          )}
        </>
      )}
    </>
  )
}

TrainingPrograms.propTypes = {
  triggerScroll: PropTypes.func,
  gotoForms: PropTypes.func,
  gotoCSCVideos: PropTypes.func,
  gotoCSCMaterials: PropTypes.func,
  gotoSecWeek: PropTypes.func,
  gotoThirdWeek: PropTypes.func,
  gotoSept8: PropTypes.func,
  gotosept15: PropTypes.func,
  gotoNov20: PropTypes.func,
  gotoJan21: PropTypes.func,
  userData: PropTypes.object,
  gotoSept8Batch2: PropTypes.func,
}
