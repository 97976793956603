import { useState } from 'react'

import { Button, Col, Modal, Row, notification } from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import DOMpurify from 'dompurify'
import PropTypes from 'prop-types'

import {
  GetSecretValueCommand,
  SecretsManagerClient,
} from '@aws-sdk/client-secrets-manager'
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses'

import { updatePersonalDataSheet } from '../../api/mutations'
import {
  getEmailBodyApprovedByDeptApproverEmployeeNotif,
  getEmailBodySubmittedByDeptApproverNotifHR,
} from './PDSEmailTemplates'

const PDSapprovalDept = ({
  record,
  userFound,
  setIsOpenPDF,
  fetchUserData,
  setShowRemarksFormDeptAppr,
}) => {
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)

  /**
   * @function paramsGenerator
   * @description Generates the parameters needed for sending an email.
   * @param {String} username The username of the employee.
   * @param {String} emailBody The email body.
   * @param {String} emailTitle The email title.
   * @param {String} sourceEmail The source email.
   * @returns {Object} Returns an object containing the email parameters.
   * @throws {Error} Throws an error if an error occurs.
   */
  const paramsGenerator = (username, emailBody, emailTitle, sourceEmail) => {
    try {
      return {
        Destination: {
          ToAddresses: [DOMpurify.sanitize(username)],
        },
        Message: {
          Body: {
            Text: {
              Data: DOMpurify.sanitize(emailBody),
              Charset: 'UTF-8',
            },
          },
          Subject: {
            Data: DOMpurify.sanitize(emailTitle), // replace with your email subject
            Charset: 'UTF-8',
          },
        },
        Source: DOMpurify.sanitize(sourceEmail),
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while generating the email parameters. Please contact the system admin for help.',
      })
      throw err
    }
  }

  /**
   * @function getSesClientToken
   * @description Gets the SES client token.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<Object>} Returns an SES client object.
   * @throws {Error} Throws an error if an error occurs.
   */
  const getSesClientToken = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const sesClient = new SESClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      return sesClient
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while checking validity of the session. Please contact the system admin for help.',
      })
      throw err
    }
  }

  const getSourceEmail = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const secret_name = 'hrm-2023@secrets'
      const secretsClient = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await secretsClient.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)
      const sourceEmail = foundSecret.REACT_APP_SOURCE_EMAIL

      return sourceEmail
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while fetching the source email. Please contact the system admin for help.',
      })
      return ''
    }
  }

  /**
   * @function sendEmailApprove
   * @description Sends an email to the employee and HR Approver about the approval of the Personal Data Sheet.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<Boolean>} Returns a boolean value.
   * @throws {Error} Throws an error if an error occurs.
   */
  async function sendEmailApprove(sesClient, sourceEmail) {
    try {
      const emailBody = getEmailBodyApprovedByDeptApproverEmployeeNotif(record)

      const params = paramsGenerator(
        DOMpurify.sanitize(record.ownedBy.username),
        emailBody,
        'Approval of your Personal Data Sheet (Department Admin)',
        sourceEmail
      )

      if (window.location.href !== 'http://localhost:3000/') {
        await sesClient.send(new SendEmailCommand(params))
        notification.success({
          message: 'Email Sent',
          description: 'The email has been successfully sent to the employee.',
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while sending the email to the employee. Please contact the system admin for help.',
      })
    }
  }

  /**
   * @function sendEmailToHRApprove
   * @description Sends an email to  HR Approver about the approval of the Personal Data Sheet.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<Boolean>} Returns a boolean value.
   * @throws {Error} Throws an error if an error occurs.
   */
  async function sendEmailToHRApprove(sesClient, sourceEmail) {
    try {
      const emailBodyHR = getEmailBodySubmittedByDeptApproverNotifHR(record)

      const paramsHR = paramsGenerator(
        'pad.hrmd@quezoncity.gov.ph',
        emailBodyHR,
        'Personal Data Sheet for HR Review',
        sourceEmail
      )

      if (window.location.href !== 'http://localhost:3000/') {
        await sesClient.send(new SendEmailCommand(paramsHR))
        notification.success({
          message: 'Email Sent',
          description: 'The email has been successfully sent to the HR Admin.',
        })
      }
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while sending the email to the HR Approver. Please contact the system admin for help.',
      })
    }
  }

  /**
   * @function handleApproveSubmit
   * @description Handles the approving of the Personal Data Sheet.
   * It also sends an email to the employee and HR Admin about the approval of the PDS.
   * @param {Object} None no parameters needed.
   * @async
   * @returns {Promise<Boolean>} Returns a boolean value.
   * @throws {Error} Throws an error if an error occurs.
   */
  const handleApproveSubmit = async () => {
    try {
      await API.graphql(
        graphqlOperation(updatePersonalDataSheet, {
          input: {
            id: record.id,
            approverID: userFound.id,
            isApproved: 'Approved',
          },
        })
      )

      notification.success({
        message: 'Submission Approved',
        description: 'The Personal Data Sheet has been successfully approved.',
        duration: 4.5,
      })

      const sesClient = await getSesClientToken()
      const sourceEmail = (await getSourceEmail()) || ''

      await sendEmailApprove(sesClient, sourceEmail)
      await sendEmailToHRApprove(sesClient, sourceEmail)

      fetchUserData()
      setIsApproveModalOpen(false)
      setIsOpenPDF(false)
    } catch (err) {
      notification.error({
        message: 'Error',
        description:
          'An error occurred while saving (Approve) the Personal Data Sheet. Please contact the system admin for help.',
      })
    }
  }

  return (
    <>
      <Row justify='center' style={{ width: '50%', margin: 'auto' }}>
        <Col span={12}>
          <Row justify='space-around'>
            <Col>
              <Button onClick={() => setIsApproveModalOpen(true)}>
                Approve Personal Data Sheet
              </Button>
            </Col>
            <Col>
              <Button onClick={() => setShowRemarksFormDeptAppr(true)}>
                Add Remarks
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal
        maskClosable={false}
        title='Approve Employee Personal Data Sheet'
        open={isApproveModalOpen}
        onCancel={() => setIsApproveModalOpen(false)}
        footer={[]}
        destroyOnClose={true}
      >
        <Row justify='space-around'>
          <Button type='primary' onClick={handleApproveSubmit}>
            Approve
          </Button>
          <Button onClick={() => setIsApproveModalOpen(false)}>Cancel</Button>
        </Row>
      </Modal>
    </>
  )
}

export default PDSapprovalDept

PDSapprovalDept.propTypes = {
  record: PropTypes.object,
  userFound: PropTypes.object,
  setIsOpenPDF: PropTypes.func,
  fetchUserData: PropTypes.func,
  setShowRemarksFormDeptAppr: PropTypes.func,
}
