/**
 * Title: activityFourDownload.js
 * Description: This is a file that contains activity four download
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/activityFourDownload.js
 **/

import { useState, useEffect, useRef } from 'react'

import { Modal, Row, Button, Col, notification } from 'antd'

import { ActivityFourAnswerByUserID } from '../../../api/queries'
import {
  createActivityFourAnswer,
  updateActivityFourAnswer,
} from '../../../api/mutations'
import { API, graphqlOperation } from 'aws-amplify'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import moment from 'moment'

export default function ActivityQuestionModalFourBatch2({
  setProgressActivity,
  setIsModalActivityOpen,
  viewUserAnswer,
  userData,
}) {
  const user = userData
  const quillRef = useRef(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activityAnswer, setActivityAnswer] = useState(null)
  const [activityAnswerTwo, setActivityAnswerTwo] = useState(null)
  const [activityFourID, setActivityFourID] = useState(null)
  const [isShowActivity, setIsShowActivity] = useState(true)
  const [countCheck, setCountCheck] = useState(0)

  /* useEffect(() => {
    if (viewUserAnswer.length > 0) {
      setActivityAnswer(viewUserAnswer[0].answerOne)
      setActivityAnswerTwo(viewUserAnswer[0].answerTwo)
      setIsShowActivity(true)
    }
  }, [viewUserAnswer]) */

  const submitAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityFourAnswer, {
          input: {
            id: activityFourID,
            answer: JSON.stringify({
              answerOne: activityAnswer,
              answerTwo: activityAnswerTwo,
            }),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: true,
          },
        })
      )

      notification['success']({
        message: 'Success',
        description: 'Your answer is saved as final answer.',
      })
      setIsModalActivityOpen(false)
      setProgressActivity('4')
      setTimeout(() => {
        setIsShowActivity(false)
        setIsModalVisible(false)
      }, 3000)
    } catch (err) {
      console.log(err)
    }
  }

  const updateAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityFourAnswer, {
          input: {
            id: activityFourID,
            answer: JSON.stringify({
              answerOne: activityAnswer,
              answerTwo: activityAnswerTwo,
            }),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const createAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(createActivityFourAnswer, {
          input: {
            userID: user.id,
            trainingAnnouncementID: 'cdb10950-01b5-428a-9959-0fc47237b528',
            answer: JSON.stringify({
              answerOne: activityAnswer,
              answerTwo: activityAnswerTwo,
            }),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: false,
          },
        })
      )

      setActivityFourID(submitTimeOnly.data.createActivityFourAnswer.id)
      setIsShowActivity(true)
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityFourAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityFourAnswerByUserID, { userID: user.id })
      )

      const itemsFetched = dataFetched.data.ActivityFourAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            'cdb10950-01b5-428a-9959-0fc47237b528'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setIsShowActivity(false)
        } else {
          setIsShowActivity(true)
          setActivityAnswer(JSON.parse(itemsFetched[0].answer).answerOne)
          setActivityAnswerTwo(JSON.parse(itemsFetched[0].answer).answerTwo)
          if (itemsFetched[0].id !== activityFourID) {
            setActivityFourID(itemsFetched[0].id)
          }
        }
      } else {
        createAnswerItem()
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    /*  if (!viewUserAnswer.length) { */
    if (isModalVisible) {
      if (user) {
        getActivityFourAnswerByUserID()
      }
    }
    /*  } */
  }, [isModalVisible])

  useEffect(() => {
    /*  if (!viewUserAnswer.length) { */
    if (countCheck > 0) {
      updateAnswerItem()
    }
    /* } */
  }, [countCheck])

  useEffect(() => {
    /*  if (!viewUserAnswer.length) { */
    let timeoutId

    const startTimer = () => {
      timeoutId = setTimeout(() => {
        console.log('Logging message every 10 seconds')

        if (isShowActivity && activityFourID) {
          setCountCheck((prevCount) => prevCount + 10000)
        }

        startTimer() // Recursively call startTimer after 10 seconds
      }, 10000)
    }

    startTimer() // Start the initial timer

    return () => {
      clearTimeout(timeoutId) // Cleanup the timer on component unmount
    }
    /* } */
  }, [isShowActivity, activityFourID])

  const handleActivityAnswer = (content) => {
    setActivityAnswer(content)
  }

  const handleActivityAnswerTwo = (content) => {
    setActivityAnswerTwo(content)
  }

  const handleSubmitActivityAnswer = async () => {
    try {
      submitAnswerItem()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div>
      <Button
        className='bg-success text-white w-100'
        onClick={() => setIsModalVisible(true)}
      >
        {/* {!viewUserAnswer ? 'Go to activity' : 'View'} */}
        Go to activity
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Activity'
        width={1000}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            /*  hidden={viewUserAnswer.length > 0} */
            key='submit'
            type='primary'
            /*  disabled={isShowActivity || viewUserAnswer.length ? false : true} */
            disabled={isShowActivity ? false : true}
            // loading={loading}
            onClick={handleSubmitActivityAnswer}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify='center' className='font-Mont'>
          <Col span={24}>
            <Row className='mb-3' justify='left'>
              <h3>Exercise No. 3</h3>
            </Row>

            {isShowActivity ? (
              <>
                <Row className='mb-3' justify='left'>
                  <h3>
                    Write two official memos. Choose two among the topics
                    listed:{' '}
                  </h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <ul>
                    <li className='mb-2 activity-font'>
                      Memo addressed to all employees informing everyone that
                      effective September 15, 2023, everyone will be required to
                      wear office uniforms from Monday to Wednesday. Thursday
                      will be for wash day while Friday will be non-uniform day.
                    </li>
                    <li className='mb-2 activity-font'>
                      Memo informing the entire Quezon City LGU that your office
                      will be closed from September 21-22. This is due to the
                      fact that your department will be going out-of-town for a
                      training and team building.
                    </li>
                    <li className='mb-2 activity-font'>
                      Memo addressed to all line managers and supervisors
                      informing them to already begin the individual performance
                      appraisal for each member of their team. Line managers and
                      supervisors should use the new template provided by the
                      Human Resources Team. Deadline for the submission of the
                      performance appraisal will be on September 25.
                    </li>
                    <li className='mb-2 activity-font'>
                      Memo informing everyone to strictly follow Kaizen or 5S in
                      their work stations. The Human Resources team will be
                      going around to monitor the work stations of people. The
                      department that has the most organized and the cleanest
                      work stations will get a prize under the “Kaizen Program”
                      being implemented by HR.
                    </li>
                  </ul>
                </Row>
                <Row justify='center'>
                  <Col span={24}>
                    <ReactQuill
                      /*  readOnly={true} */
                      value={activityAnswer}
                      onChange={handleActivityAnswer}
                      id='activity-answer'
                      name='activity-answer'
                      className='mb-5 mt-1'
                      style={{ height: 400 }}
                    />
                  </Col>
                </Row>

                <Row justify='center'>
                  <Col span={24}>
                    <ReactQuill
                      /*  readOnly={true} */
                      value={activityAnswerTwo}
                      onChange={handleActivityAnswerTwo}
                      id='activity-answer-two'
                      name='activity-answer-two'
                      className='mb-5 mt-1'
                      style={{ height: 400 }}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row className='mb-3' justify='left'>
                  <h3>Activity answer was already submitted as final.</h3>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
