/**
 * Title: ildpFormModalPrintEnglish.js
 * Description: This is a file that contains the modal to print the ILDP form in English
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Form/ildpFormModalPrintEnglish.js
 **/

import { useEffect, useState } from 'react'

import { Card, List, Col, Row, Input, Checkbox } from 'antd'

const { TextArea } = Input

export default function IldpFormModalPrintEnglish({
  submissionDataIldp,
  disable,
  changeFname,
}) {
  const currentTimeInSGT = (e) => {
    if (e) {
      return new Date(e).toLocaleString('en-SG', {
        timeZone: 'Asia/Singapore',
        hour12: false,
      })
    } else {
      return ''
    }
  }
  return (
    <div>
      <div id='specific'>
        <>
          <div id='specific1'>
            <Row>
              <Col span={24}>
                <List
                  bordered
                  size='large'
                  header={
                    <b style={{ fontSize: '18px' }}>
                      Instructions for Individuals and Supervisors in filling up
                      the Individual Learning and Development Plan (ILDP):
                    </b>
                  }
                  style={{ marginBottom: '20px' }}
                >
                  <List.Item key='1'>
                    1.) Individuals fill-up the ILDP form and base answers from
                    the Self-Assessment Tool in the e-TNA Form given.
                  </List.Item>
                  <List.Item key='2'>
                    2.) After the completion of ILDP, print the draft copy of
                    the ILDP and individual shall present it to their Supervisor
                    for further evaluation and feedbacking.
                  </List.Item>
                  <List.Item key='3'>
                    3.) Take note of the <b>Competencies</b> that need to be
                    addressed (based on the results of the e-TNA/Self-assessment
                    tool).
                  </List.Item>
                  <List.Item key='4'>
                    4.) Once approved by the supervisor, print a final copy to
                    be signed by both the individual and the supervisor.
                  </List.Item>
                  <List.Item key='5'>
                    5.) Individual uploads the final copy with signature on the
                    Step 2 of Learning & Development System website.
                  </List.Item>
                  <List.Item key='6'>
                    6.) Please update ILDP on a regular basis after completion
                    of each program taken. Please keep copies of submitted
                    online certificates. The ILDP will cover a period of three
                    years (2022-2025).
                  </List.Item>
                </List>
              </Col>
            </Row>
          </div>
          <Card>
            <div id='specific2'>
              <Row>
                <Col span={24}>
                  <h3
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    INDIVIDUAL LEARNING & DEVELOPMENT PLAN (ILDP)
                  </h3>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                  ></p>
                </Col>
                <Col span={16}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                    }}
                  >
                    PERIOD COVERED (Inclusive dates of 3 years to complete
                    programs)
                  </p>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Name
                    </label>
                    <Input value={submissionDataIldp.panelColumns2Name} />
                  </span>
                </Col>
                <Col span={6}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Date from:
                    </label>
                    <Input
                      value={currentTimeInSGT(
                        submissionDataIldp.panelColumns2ColumnsDatefrom
                      )}
                    />
                  </span>
                </Col>

                <Col span={6}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Date To
                    </label>
                    <Input
                      value={currentTimeInSGT(
                        submissionDataIldp.panelColumns2ColumnsDateTo
                      )}
                    />
                  </span>
                </Col>
              </Row>

              <Row gutter={[8, 8]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Current position
                    </label>
                    <Input
                      value={submissionDataIldp.panelColumns2Currentposition}
                    />
                  </span>
                </Col>

                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Division
                    </label>
                    <Input value={submissionDataIldp.panelColumns2Division} />
                  </span>
                </Col>
              </Row>

              <Row gutter={[8, 8]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Salary grade
                    </label>
                    <Input
                      value={submissionDataIldp.panelColumns2Salarygrade}
                    />
                  </span>
                </Col>

                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Office
                    </label>
                    <Input value={submissionDataIldp.panelColumns2Office} />
                  </span>
                </Col>
              </Row>

              <Row gutter={[8, 8]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Years in position
                    </label>
                    <Input
                      value={submissionDataIldp.panelColumns2Yearsinposition}
                    />
                  </span>
                </Col>

                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Supervisor's name
                    </label>
                    <Input
                      disabled={disable}
                      id='fname'
                      placeholder='Enter your first name'
                      onChange={(e) => changeFname(e)}
                      className='loginInput'
                      required
                    />
                  </span>
                </Col>
              </Row>

              <Row gutter={[8, 8]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Years in QCG
                    </label>
                    <Input value={submissionDataIldp.panelColumns2YearsinQcg} />
                  </span>
                </Col>

                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Date taken
                    </label>
                    <Input
                      value={currentTimeInSGT(
                        submissionDataIldp.panelColumns3Datetaken
                      )}
                    />
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <h3
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                  >
                    Purpose
                  </h3>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: '0' }}>
                {submissionDataIldp !== undefined && (
                  <Col span={16}>
                    <Checkbox
                      style={{ marginLeft: '8px' }}
                      checked={
                        submissionDataIldp.panelColumns3Tomeetthecompetenciesofcurrentposition
                      }
                    >
                      To meet the competencies of current position.
                    </Checkbox>
                    <Checkbox
                      checked={
                        submissionDataIldp.panelColumns3Toincreasethelevelofcompetenciesofcurrentposition
                      }
                    >
                      To increase the level of competencies of current position.
                    </Checkbox>
                    <Checkbox
                      checked={
                        submissionDataIldp.panelColumns3Tomeetthecompetenciesofthenexthigherposition
                      }
                    >
                      To meet the competencies of the next higher position.
                    </Checkbox>
                    <Checkbox
                      checked={
                        submissionDataIldp.panelColumns3Toacquirenewcompetenciesacrossdifferentfunctionsposition
                      }
                    >
                      To acquire new competencies across different
                      functions/position.
                    </Checkbox>
                    <Checkbox
                      checked={
                        submissionDataIldp.panelColumns3Otherspleasespecify
                      }
                    >
                      Others, please specify.
                    </Checkbox>
                  </Col>
                )}
              </Row>

              <Row gutter={24} style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <span>
                    <TextArea
                      value={submissionDataIldp.panelColumns3Text}
                      rows={4}
                    />
                  </span>
                </Col>
              </Row>
            </div>

            <div id='specific3'>
              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <h3
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    Result of Self-Evaluation Tool
                  </h3>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                  >
                    Identify areas in which you need FURTHER DEVELOPMENT (lower
                    scores of 3.5 & below)
                  </p>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    CORE COMPETENCIES (TRAINING PROGRAMS)
                  </p>
                </Col>
              </Row>

              <Row style={{ marginTop: '0' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    1.) Exemplifying Ethics & Integrity:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Public Service Ethics & Accountability (PSEA) □
                  Administrative Discipline: Orientation of Values & Ethics in
                  the Workplace
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score14) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score14}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    2.) Decision Making and Problem Solving:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Grievance Handling □ Conciliation and Mediation
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score12) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score12}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    3.) Customer Focus/ Delivering Service Excellence:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Customer Service Satisfaction □ Sevice Management
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score13) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score13}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    LEADERSHIP COMPETENCIES (TRAINING PROGRAMS)
                  </p>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    1.) Managing Performance:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Leadership & Management Program □ Conflict Management □
                  Collective Negotiation □ Effective Management & Supervision □
                  Total Quality Management □ Performance Management
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    2.) Building Commitment:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>□ Culture Building</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score2) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score2}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    3.) Developing People:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Coaching & Mentoring □ Managing Teams/People
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score3) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score3}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    4.) Partnering & Networking:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>□ Expanded Team Building</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score4) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score4}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    5.) Strategic Thinking:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Strategic Management Planning □ Goal & Metrics Setting
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score5) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score5}
                  </span>
                </Col>
              </Row>
            </div>

            <div id='specific4'>
              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    6.) Leading Change/ Transformative Leadership:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Supervisory Development Course □ Change Management
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score6) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score6}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    ORGANIZATIONAL COMPETENCIES (TRAINING PROGRAMS)
                  </p>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    1.) Communication:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Business/ Technical Writing Skills □ English Proficiency &
                  Effective Communication □ Effective Presentation □ Public
                  Speaking
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score7) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score7}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    2.) Personal Effectiveness:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Personality Development in Local Government □ Time
                  Management □ Obtaining and Providing Positive Work Attitude □
                  Stress Management
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score8) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score8}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    3.) Planning & Delivering:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Strategic HRM: Providing Assistance to Planning & Goal
                  Setting □ Project Management □ AGILE
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score9) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score9}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    4.) Management Information:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Data Management & Analysis/Charts □ Data Privacy
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score10) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score10}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    5.) Creative and Innovative Thinking:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Critical Thinking Innovation / Innovative Thinking □ Design
                  Thinking
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score11) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score11}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Comments/Feedback from Supervisor (One-on-one session)
                    </label>
                    <TextArea rows={2} />
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  Note: Please indicate specific behavioral Indicators and
                  skills NOT consistently demonstrated
                </Col>
              </Row>
            </div>
          </Card>

          <div id='specific5'>
            <Card
              title='COMPETENCIES'
              style={{
                marginBottom: '20px',
                marginTop: '20px',
              }}
            >
              <p>
                The following competencies are essentials in addressing the
                learning and development gaps.
              </p>
              <Row>
                <Col span={12}>
                  <Card
                    title='Category of Position'
                    style={{ height: '753px' }}
                  >
                    <p>
                      Executive/Managerial, Division Chief/ Asst. Div. Chief and
                      equivalent positions
                    </p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title='Recommended MCC'>
                    <p>
                      <b>Core</b>
                    </p>
                    <p>* Exemplifying Ethics and Integrity</p>
                    <p>* Delivering Service Excellence</p>
                    <p>* Decision Making and Problem Solving</p>

                    <p>
                      <b>Leadership:</b>
                    </p>
                    <p>* Managing Performance</p>
                    <p>* Building Commitment</p>
                    <p>* Developing People</p>
                    <p>* Partnering & Networking</p>
                    <p>* Strategic Thinking</p>
                    <p>* Leading Change/Transformative Leadership</p>

                    <p>
                      <b>Organizational:</b>
                    </p>
                    <p>* Communication</p>
                    <p>* Personal Effectiveness</p>
                    <p>* Planning & Delivering</p>
                    <p>* Management Information</p>
                    <p>* Creative and Innovative Thinking</p>
                  </Card>
                </Col>
              </Row>
            </Card>
          </div>
          <div id='specific6'>
            <Card>
              <Row>
                <Col span={12}>
                  <Card style={{ height: '360px' }}>
                    <p>Professional/Technical (SG 11 - 22)</p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{ height: '360px' }}>
                    <p>
                      <b>Core</b>
                    </p>
                    <p>* Exemplifying Ethics and Integrity</p>
                    <p>* Delivering Service Excellence</p>
                    <p>* Decision Making and Problem Solving</p>
                    <p>
                      <b>Organizational:</b>
                    </p>
                    <p>* Communication</p>
                    <p>* Personal Effectiveness</p>
                    <p>* Planning & Delivering</p>
                    <p>
                      * Management Information Creative and Innovative Thinking
                    </p>
                  </Card>
                </Col>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col span={12}>
                  <Card style={{ height: '373px' }}>
                    <p>Clerical/Technical/Admin Support (SG 4-9)</p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{ height: '373px' }}>
                    <p>
                      <b>Core</b>
                    </p>
                    <p>* Exemplifying Ethics and Integrity</p>
                    <p>* Delivering Service Excellence</p>
                    <p>* Decision Making and Problem Solving</p>

                    <p>
                      <b>Organizational:</b>
                    </p>
                    <p>* Communication</p>
                    <p>* Personal Effectiveness</p>
                    <p>* Planning & Delivering</p>
                    <p>
                      * Management Information Creative and Innovative Thinking
                    </p>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Card style={{ height: '180px' }}>
                    <p>Trades/Crafts (SG 1-3)</p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{ height: '180px' }}>
                    <p>
                      <b>Core</b>
                    </p>
                    <p>* Exemplifying Ethics and Integrity</p>
                    <p>* Delivering Service Excellence</p>
                    <p>* Decision Making and Problem Solving</p>
                  </Card>
                </Col>
              </Row>
              <section>
                <p style={{ marginTop: '12px' }}>
                  Note: It is important to note that competencies varies from
                  one position to another, however, the Core competencies are
                  constant to all positions. It is also assumed that as the
                  individual employees move up to supervisory positions and
                  above, they already possess the organizational competencies.
                </p>
              </section>
            </Card>
          </div>

          <div id='specific7'>
            <Card
              style={{ marginTop: '16px' }}
              title='LEARNING AND DEVELOPMENT PROGRAMS'
            >
              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <b>CORE COMPETENCIES (Required)</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>
                  <b>Name of Program</b>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Date Taken</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  Public Service Ethics & Accountability Orientation & Work
                  Ethics
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks1 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks1 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks1.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>
                  Administrative Discipline: Orientation of Values & Ethics in
                  the Workplace
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks2 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks2 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks2.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Social Media Responsibility</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime2
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks3 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks3 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks3.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Customer Service Satisfaction</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime3
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks4 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks4 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks4.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <b>LEADERSHIP COMPETENCIES</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>
                  <b>Name of Program</b>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Date Taken</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>Leadership & Management Program</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime4
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks5 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks5 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks5.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Conflict Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime26
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks6 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks6 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks6.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Effective Management & Supervision</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime25
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks7 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks7 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks7.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Total Quality Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime20
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks8 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks8 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks8.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Project Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime19
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks9 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks9 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks9.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Culture Building</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime18
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks10 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks10 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks10
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Coaching & Mentoring</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime30
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks11 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks11 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks11
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Managing Teams & People</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime29
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks12 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks12 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks12
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Expanded Team Building</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime28
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks13 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks13 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks13
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Strategic Management Planning</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime27
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks14 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks14 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks14
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Goal and Metrics Setting</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime33
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks15 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks15 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks15
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Supervisory Development Course</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime32
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks16 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks16 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks16
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '16px' }}>
                <Col span={12}>Change Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime31
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks17 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks17 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks17
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Card>
          </div>

          <div id='specific8'>
            <Card>
              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <b>ORGANIZATIONAL COMPETENCIES</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <b>Name of Program</b>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Date Taken</b>
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Communication</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>Business/Technical Writing Skills</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime2
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks18 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks18 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks18
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  English Proficiency & Effective Communication
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime3
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks19 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks19 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks19
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Effective Presentation</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime5
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks20 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks20 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks20
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Public Speaking</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime16
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks21 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks21 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks21
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Personal Effectiveness</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>Personality Development in Local Government</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime6
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks22 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks22 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks22
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Time Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime4
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks23 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks23 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks23
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  Obtaining and Providing Positive Work Attitude
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime13
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks24 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks24 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks24
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Planning and Delivering</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  Strategic HRM: Providing Assistance to Planning and Goal
                  Setting
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime9
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks25 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks25 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks25
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Project Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime17
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks26 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks26 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks26
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Management Information</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>Data Management & Analysis/Chart</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime5
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks27 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks27 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks27
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Data Privacy</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime34
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks28 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks28 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks28
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Creative and Innovative Thinking</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>Critical Thinking</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime14
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks29 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks29 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks29
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Innovative Thinking</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime18
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks30 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks30 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks30
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Design Thinking</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime15
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks31 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks31 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks31
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Card>
          </div>

          <div id='specific9'>
            <Card style={{ marginTop: '20px' }}>
              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>FUNCTIONAL/TECHNICAL (Specific to Division/Department)</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <b>Name of Program</b>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Date Taken</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel3Columns25Text4}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime19
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel3Columns25Text10}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime22
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel3Columns25Text8}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime21
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel3Columns25Text6}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns3DateTime20
                    )}
                  ></Input>
                </Col>
              </Row>
            </Card>
            <Card style={{ marginTop: '20px' }}>
              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <b>
                    Additional Training Programs (Not in the List) Taken / Date
                    Taken
                  </b>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Date Taken</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel3Columns25Text3}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime21
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel3Columns25Text9}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime24
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel3Columns25Text7}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime23
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel3Columns25Text5}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel3Columns4DateTime22
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <span>
                    <Input
                      name='signature1'
                      value={
                        submissionDataIldp.panel3Columns31EmployeeNameandSignature3
                      }
                    />
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    <Input
                      name='signature2'
                      value={
                        submissionDataIldp.panel3Columns31SupervisorNameandSignature3
                      }
                    />
                  </span>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Employee Name and Signature
                    </label>
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Supervisor Name and Signature
                    </label>
                  </span>
                </Col>
              </Row>
            </Card>
          </div>
        </>
      </div>
    </div>
  )
}
