/**
 * Title: createForm.js
 * Description: This is a file that contains the create form for the employee
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Form/createForm.js
 *
 *Changes made:
 * - 2024.14.10  | Harry Lagunsad | add Props Validation
 * - 2024.14.10  | Harry Lagunsad | fix sonarlint issues
 **/
import React from 'react'

import { Breadcrumb, Card, Tabs, notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import PropTypes from 'prop-types'
import Swal from 'sweetalert2'

import { Form } from '@formio/react'

import { createForm, updateUser } from '../../api/mutations'
import CreateFormPinoy from './createFormPinoy'
import './form.css'

const { TabPane } = Tabs

let loop = 0

export default class CreateForm extends React.Component {
  constructor() {
    super()
    this.state = { employeeId: '' }
  }

  componentDidUpdate(prevProps) {
    const { updated, user } = this.props

    try {
      if (updated !== prevProps.updated) {
        this.setState((prevState) => ({
          submissionData: {
            ...prevState.submissionData,
            panelColumns2DepartmentDivision: user?.assignedDept,
            panelColumns2Division: user?.division,
            panelColumns2Position: user?.position,
            panelColumns2Unit: user?.sectionUnit,
            panelName: user?.lname,
            panelName2: user?.name,
            panelName3: user?.mname,
            panelName4: user?.suffix,
            panelColumns4EmployeeId: user?.employeeIDNumber,
            panelColumnsGender: user?.gender?.toLowerCase() || 'others',
          },
        }))
      }
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description: 'Something broke, please contact System Admin for help.',
      })
    }
  }

  componentDidMount() {
    loop = 0
  }

  /**
   * @async
   * @function
   * @description Handles the form submission process.
   * @param {Event} e - The event object.
   * @throws {Error} - If there is an error in submitting the form.
   * @returns {Promise<void>} - A promise that resolves when the form submission is complete.
   */
  handleSubmit = async (e) => {
    let draftOrFinish

    const user = this.props.user
    Swal.fire({
      title: 'Let us save your Electronic Training Needs Analysis ',
      text: 'Final Copy will no longer be editable! Please save as Draft Copy if you want to edit it later. Thank You',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Final Copy',
      cancelButtonText: 'Draft Copy',
      allowOutsideClick: false,
      allowEscapeKey: false,
    })
      .then((result) => {
        if (result.value) {
          draftOrFinish = '1'
          return API.graphql(
            graphqlOperation(createForm, {
              input: {
                dept: user.department,
                assignedDept: user.assignedDept,
                division: user.division,
                position: user.position,
                userID: user.id,
                isFinal: 'YES',
                form: JSON.stringify(e.data),
                finalSubmitDate: new Date(),
                isLanguage: '1',
              },
            })
          )
        } else {
          draftOrFinish = '0'
          return API.graphql(
            graphqlOperation(createForm, {
              input: {
                dept: user.department,
                assignedDept: user.assignedDept,
                division: user.division,
                position: user.position,
                userID: user.id,
                form: JSON.stringify(e.data),
                isLanguage: '1',
                isFinal: 'NO',
              },
            })
          )
        }
      })
      .then((nextRes) => {
        return API.graphql(
          graphqlOperation(updateUser, {
            input: {
              id: user.id,
              form: draftOrFinish,
              form2: '0',
              isTakeSurvey: '0',
            },
          })
        )
      })
      .then((use) => {
        function uuidv4() {
          try {
            const crypto = window.crypto || window.msCrypto
            const array = new Uint32Array(5)
            crypto.getRandomValues(array)

            return (
              array[0].toString(16).padStart(8, '0') +
              '-' +
              array[1].toString(16).padStart(4, '0') +
              '-4' +
              array[2].toString(12).padStart(3, '0') +
              '-y' +
              array[3].toString(12).padStart(3, '0') +
              '-' +
              array[4].toString(16).padStart(12, '0')
            )
          } catch (error) {
            console.log(error)
          }
        }
        this.props.refresh(uuidv4())
        notification['success']({
          message: 'Success',
          description: 'Your form has been submitted. Thank you!',
        })

        this.props.afterSubmit('viewForm')
      })
      .catch((err) => {
        notification['error']({
          message: 'Error',
          description: "There's an error in submitting form.",
        })
        console.log(err)
      })
  }

  /**
   * @async
   * @function
   * @description Handles the load event.
   * @throws {Error} If there is an error during the handling of the load event.
   * @param {Event} e - The load event object.
   * @returns {Promise<void>} A promise that resolves when the load event is handled.
   */
  handleLoad = async (e) => {
    const { user } = this.props

    try {
      if (loop <= 2 && user) {
        const submissionData = {
          ...e.data,
          panelColumns2DepartmentDivision:
            e.data?.panelColumns2DepartmentDivision ?? user.assignedDept,
          panelColumns2Division: e.data?.panelColumns2Division ?? user.division,
          panelColumns2Position: e.data?.panelColumns2Position ?? user.position,
          panelColumns2Unit: e.data?.panelColumns2Unit ?? user.sectionUnit,
          panelName: user.lname,
          panelName2: user.name,
          panelName3: user.mname,
          panelName4: user.suffix,
          panelColumns4EmployeeId: user.employeeIDNumber || '',
          panelColumnsGender:
            e.data?.panelColumnsGender ??
            {
              male: 'male',
              female: 'female',
            }[user.gender] ??
            'others',
        }

        this.setState({ submissionData })

        loop += 1
      }
    } catch (err) {
      console.error('Error in handleLoad:', err)
      notification.error({
        message: 'Error',
        description:
          'An error occurred while loading data. Please contact System Admin for assistance.',
      })
    }
  }

  render() {
    const user = this.props.user
    return (
      <div className={'site-layout-background createForm-padding'}>
        <Card>
          <Tabs defaultActiveKey='1' centered>
            <TabPane tab={<b>e-TNA Form (English Version)</b>} key='1'>
              {user ? (
                <>
                  <Breadcrumb>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Fill-out Form</Breadcrumb.Item>
                  </Breadcrumb>
                  <div className={'formio-create-page'}>
                    <Form
                      src='https://www.sparksoft-demo.com/formio/samplesurvey'
                      onChange={(e) => this.handleLoad(e)}
                      onSubmit={(e) => this.handleSubmit(e)}
                      submission={{ data: this.state.submissionData }}
                    />
                  </div>
                </>
              ) : null}
            </TabPane>
            <TabPane tab={<b>e-TNA Form (Filipino Version)</b>} key='2'>
              <CreateFormPinoy
                afterSubmit={(el) => this.props.afterSubmit(el)}
                user={this.props.user}
                refresh={(e) => {
                  this.props.refresh(e)
                  this.setState({ content: '' })
                }}
                updated={this.props.updated}
              />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    )
  }
}

CreateForm.propTypes = {
  afterSubmit: PropTypes.func,
  refresh: PropTypes.func,
  updated: PropTypes.bool,
  user: PropTypes.object,
}
