/**
 * Title: uploadOnlineTrainingAccordionPreview.js
 * Description: This is a file that contains the components that displays the post training modal.
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/uploadOnlineTrainingAccordionPreview.js
 * Changes made:
 * - 09/23/2024 - John Bazil Valdez
 * - Fix the bug when the online training has no activity it gives error when saving the online training
 **/

import React, { useState } from 'react'

/**
 * Renders an accordion preview for uploading online training.
 *
 * @param {Object} props - The component props.
 * @param {string} props.selectedView - The currently selected view.
 * @param {function} props.setSelectedView - The function to set the selected view.
 * @param {function} props.setSelectedVideoKey - The function to set the selected video key.
 * @param {Object} props.training - The training data.
 * @param {function} props.setSelectedActivity - The function to set the selected activity.
 * @returns {JSX.Element} The accordion preview component.
 */
export default function UploadOnlineTrainingAccordionPreview({
  selectedView,
  setSelectedView,
  setSelectedVideoKey,
  training,
  setSelectedActivity,
  setSelectedTranscript,
}) {
  const [selectedAccordion, setSelectedAccordion] = useState(0)

  /**
   * Handles the click event on the accordion.
   * @param {number} index - The index of the accordion.
   */
  const handleAccordionClick = (index) => {
    setSelectedAccordion(index === selectedAccordion ? -1 : index)
  }

  return (
    <>
      <div className='accordion' id='accordionExample'>
        <div className='accordion-item'>
          <h2 className='accordion-header'>
            <button disabled className={`accordion-button py-4 collapsed`}>
              <input
                className='form-check-input fs-5 me-3'
                type='checkbox'
                disabled
                checked
              />
              Pre-Test
            </button>
          </h2>
        </div>
      </div>
      <div className='accordion' id='accordionExample'>
        <div className='accordion-item'>
          <h2 className='accordion-header'>
            <button disabled className={`accordion-button py-4 collapsed`}>
              <input
                className='form-check-input fs-5 me-3'
                type='checkbox'
                disabled
                checked
              />
              Endorsement letter
            </button>
          </h2>
        </div>
      </div>
      {training?.sectionTitle &&
        training?.sectionTitle?.map((title, index) => (
          <div className='accordion' id='accordionExample' key={index}>
            <div className='accordion-item'>
              <h2 className='accordion-header'>
                <button
                  onClick={() => {
                    handleAccordionClick(index)

                    setSelectedVideoKey(
                      training.sectionVideos[`trainingVideosID${index + 1}`]
                    )
                    if (training.sectionQuestions) {
                      setSelectedActivity(
                        JSON.parse(
                          training?.sectionQuestions[
                            `activityQuestion${index + 1}`
                          ] || null
                        )
                      )
                    }
                  }}
                  className={`accordion-button py-4 ${
                    selectedAccordion === index ? '' : 'collapsed'
                  }`}
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target={`#collapse${index + 1}`}
                  aria-expanded={`${
                    selectedAccordion === index ? 'true' : 'false'
                  }`}
                >
                  <input
                    className='form-check-input fs-5 me-3'
                    type='checkbox'
                  />
                  {index + 1}. {title}
                </button>
              </h2>

              <div
                id={`collapse${index + 1}`}
                className={`accordion-collapse collapse ${
                  selectedAccordion === index ? 'show' : ''
                }`}
                aria-labelledby={`heading${index + 1}`}
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body bg-light p-3 rounded'>
                  <button
                    onClick={() => setSelectedView('video')}
                    className={`btn btn-outline-secondary w-100 mb-2 ${
                      selectedView === 'video' && 'active'
                    }`}
                  >
                    Video
                  </button>

                  <button
                    hidden={
                      (training.videoTranscript &&
                        !training?.videoTranscript[
                          `videoTranscriptID${index + 1}`
                        ]) ||
                      false
                    }
                    onClick={() =>
                      setSelectedTranscript(
                        training?.videoTranscript[
                          `videoTranscriptID${index + 1}`
                        ]
                      )
                    }
                    className={`btn btn-outline-secondary w-100 mb-2 `}
                  >
                    Transcript
                  </button>

                  {training.sectionQuestions && (
                    <button
                      hidden={
                        (training.sectionQuestions &&
                          !training?.sectionQuestions[
                            `activityQuestion${index + 1}`
                          ]) ||
                        false
                      }
                      onClick={() => setSelectedView('activity')}
                      className={`btn btn-outline-secondary w-100 ${
                        selectedView !== 'video' && 'active'
                      }`}
                    >
                      Activity
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className='accordion' id='accordionExample'>
        <div className='accordion-item'>
          <h2 className='accordion-header'>
            <button
              onClick={() => {
                handleAccordionClick(training?.sectionTitle.length + 1)
              }}
              className={`accordion-button py-4 ${
                selectedAccordion === training?.sectionTitle.length + 1
                  ? ''
                  : 'collapsed'
              }`}
              type='button'
              data-bs-toggle='collapse'
              data-bs-target={`#collapse${training?.sectionTitle.length + 1}`}
              aria-expanded={`${
                selectedAccordion === training?.sectionTitle.length + 1
                  ? 'true'
                  : 'false'
              }`}
            >
              <input className='form-check-input fs-5 me-3' type='checkbox' />
              {training?.sectionTitle.length + 1}. Post Training
            </button>
          </h2>

          <div
            id={`collapse${training?.sectionTitle.length + 1}`}
            className={`accordion-collapse collapse ${
              selectedAccordion === training?.sectionTitle.length ? 'show' : ''
            }`}
            aria-labelledby={`heading${training?.sectionTitle.length + 1}`}
            data-bs-parent='#accordionExample'
          >
            {/* <div className='accordion-body bg-light p-3 rounded'>
              <PostTrainingModal afterRegister={null} trainingItem={training} />
            </div> */}
          </div>
        </div>
      </div>
    </>
  )
}
