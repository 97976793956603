import { Button, Modal, Row, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import OnlineTrainingNavigation from './OnlineTrainingNavigation'
import { API, Auth } from 'aws-amplify'
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from '@aws-sdk/client-secrets-manager'
import {
  CloudFrontClient,
  GetDistributionCommand,
} from '@aws-sdk/client-cloudfront'
import { getSignedUrl } from '@aws-sdk/cloudfront-signer'
import TextArea from 'antd/lib/input/TextArea'
import UploadOnlineTrainingAccordionPreview from './uploadOnlineTrainingAccordionPreview'
import { numToString } from '../../utilities/numToString'

const file = {
  attributes: {
    controlsList: 'nodownload',
    onContextMenu: (e) => e.preventDefault(),
  },
}

/**
 * Component for uploading and previewing online trainings.
 * @param {Object} props - The component props.
 * @param {Function} props.announceUpload - Function to announce upload.
 * @param {Function} props.handleSubmitTraining - Function to handle training submission.
 * @param {Object} props.training - The training object.
 * @returns {JSX.Element} - The rendered component.
 */
export default function UploadOnlineeTrainingsPreview({
  speakerUpload,
  handleSubmitTraining,
  training,
  sectionLength,
  isEditTraining,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedVideoKey, setSelectedVideoKey] = useState(null)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [videoUrl, setVideoUrl] = useState(null)
  const [selectedView, setSelectedView] = useState('video')
  const [selectedTranscript, setSelectedTranscript] = useState(null)
  const [isFormValid, setIsFormValid] = useState(false)

  const playerRef = useRef(null)

  useEffect(() => {
    const validateForm = () => {
      if (!training) return false      

      if (
        training.title &&
        training.arrayOfDates.length > 0 &&
        training.trainingTimeStart &&
        training.trainingTimeEnd &&
        training.courseDescription !== '<p><br></p>' &&
        training.targetParticipants !== '<p><br></p>' &&
        training.postTrainOpen &&
        training.postTrainOpen != 'Invalid date' &&
        training.postTrainClose &&
        training.postTrainClose != 'Invalid date' &&
        training.courseCategory &&
        training.courseSubCategory &&
        training.evalID &&
        (training.announceUpload?.length || isEditTraining) &&
        (speakerUpload?.length || isEditTraining)
      ) {
        setIsFormValid(true)
      } else {
        setIsFormValid(false)
      }
    }

    validateForm()
  }, [training, speakerUpload])

  useEffect(() => {
    getVideoUrl(selectedVideoKey)
  }, [selectedVideoKey])

  /**
   * Retrieves the video URL for a given key.
   * @param {string} key - The key of the video object.
   * @returns {Promise<void>} - A promise that resolves when the video URL is retrieved.
   * @throws {Error} - If there is an error getting the video URL.
   */
  async function getVideoUrl(key) {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken
      const secret_name = 'hrmCloudfront'
      const client = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await client.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)
      const objectKey = key
      const cdnClient = new CloudFrontClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const getDistributionParams = {
        Id: foundSecret.hrmDistributionID,
      }
      const commandCDN = new GetDistributionCommand(getDistributionParams)
      const response = await cdnClient.send(commandCDN)
      const resourcePath = `output/${objectKey}`
      const newURL = `https://${response.Distribution.DomainName}/output/${objectKey}`
      const privateKey = foundSecret.hrmCloudfrontKey.replace(/\s*&&\s*/g, '\n')
      const keyPairId = foundSecret.hrmCloudfrontID
      const dateLessThan = new Date(Date.now() + 3600 * 1000)

      const signedUrlCDN = getSignedUrl({
        url: newURL,
        keyPairId,
        dateLessThan,
        privateKey,
      })

      setVideoUrl(signedUrlCDN)
    } catch (error) {
      console.log('Error getting video URL:', error)
    }
  }

  /**
   * Handles the cancel action.
   */
  const handleCancel = () => {
    setIsModalOpen(false)

    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pause()
    }
  }

  /**
   * Handles the opening of the preview modal.
   */
  const handleOpenPreview = () => {
    let isError = false

    sectionLength.some((element, index) => {
      const question = `activityQuestion${index + 1}`
      if (element && !training.sectionQuestions[question]) {
        notification['error']({
          message: 'Error',
          description: 'There are missing questions for the section.',
        })
        isError = true
        return true // Break out of the loop after showing the first notification
      }
      return false
    })

    if (!isError) {
      if (training?.sectionVideos?.trainingVideosID1)
        setSelectedVideoKey(
          training?.sectionVideos['trainingVideosID1'] || null
        )

      if (training?.sectionQuestions?.activityQuestion1)
        setSelectedActivity(
          JSON.parse(training?.sectionQuestions['activityQuestion1'] || null)
        )

      setIsModalOpen(true)
    }
  }

  return (
    <section>
      <Button
        onClick={handleOpenPreview}
        type='primary'
        disabled={!isFormValid}
      >
        Preview
      </Button>
      {isModalOpen && (
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title={`${training?.title} Preview`}
          open={isModalOpen}
          footer={
            <>
              <Button onClick={handleCancel}>Close</Button>
              <Button type='primary' onClick={handleSubmitTraining}>
                Submit
              </Button>
            </>
          }
          onCancel={handleCancel}
          width={1800}
        >
          <div className='col-12 d-md-flex'>
            <div className={`col-md-8 col-12 order-2 order-md-1`}>
              <div>
                {selectedView === 'video' && (
                  <ReactPlayer
                    className='video-player w-100'
                    ref={playerRef}
                    playing={false}
                    url={videoUrl}
                    controls
                    config={{ file }}
                    /* onEnded={handleEnded} */
                  />
                )}
                {selectedView === 'activity' &&
                  selectedActivity &&
                  selectedActivity.map((elem, index) => {
                    const numString = numToString(index)
                    return (
                      <div key={index} className='mb-4'>
                        {elem.questionType !== 'caseStudy' && (
                          <>
                            <Row className='mt-4'>
                              <span
                                style={{
                                  fontSize: 16,
                                  textAlign: 'left',
                                }}
                              >
                                <strong>
                                  {index + 1}. {elem.question}
                                </strong>
                              </span>
                            </Row>
                            <Row
                              align='center'
                              justify='center'
                              className='flex-column ms-3 mt-2'
                            >
                              {elem.choices[0] &&
                                elem.choices[1] &&
                                elem.choices[2] &&
                                elem.choices[3] && (
                                  <>
                                    {elem.choices[0][
                                      `choices-1-${index + 1}`
                                    ].toLowerCase() !== 'n/a' && (
                                      <div
                                        className='d-flex p-1'
                                        hidden={
                                          elem.choices[0][
                                            `choices-1-${index + 1}`
                                          ].toLowerCase() === 'n/a'
                                        }
                                      >
                                        <input
                                          value='A'
                                          name={'question' + numString}
                                          className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                          type='radio'
                                          id={`question${numString}-choice-A`}
                                        />
                                        <label
                                          className='m-0 ms-3 text-start'
                                          htmlFor={`question${numString}-choice-A`}
                                        >
                                          <strong>
                                            <em>A. </em>
                                          </strong>
                                          {
                                            elem.choices[0][
                                              `choices-1-${index + 1}`
                                            ]
                                          }
                                        </label>
                                      </div>
                                    )}
                                    {elem.choices[1][
                                      `choices-2-${index + 1}`
                                    ].toLowerCase() !== 'n/a' && (
                                      <div className='d-flex p-1'>
                                        <input
                                          value='B'
                                          name={'question' + numString}
                                          className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                          type='radio'
                                          id={`question${numString}-choice-B`}
                                        />
                                        <label
                                          className='m-0 ms-3 text-start'
                                          htmlFor={`question${numString}-choice-B`}
                                        >
                                          <strong>
                                            <em>B. </em>
                                          </strong>
                                          {
                                            elem.choices[1][
                                              `choices-2-${index + 1}`
                                            ]
                                          }
                                        </label>
                                      </div>
                                    )}
                                    {elem.choices[2][
                                      `choices-3-${index + 1}`
                                    ].toLowerCase() !== 'n/a' && (
                                      <div className='d-flex p-1'>
                                        <input
                                          value='C'
                                          name={'question' + numString}
                                          className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                          type='radio'
                                          id={`question${numString}-choice-C`}
                                        />
                                        <label
                                          className='m-0 ms-3 text-start'
                                          htmlFor={`question${numString}-choice-C`}
                                        >
                                          <strong>
                                            <em>C. </em>
                                          </strong>
                                          {
                                            elem.choices[2][
                                              `choices-3-${index + 1}`
                                            ]
                                          }
                                        </label>
                                      </div>
                                    )}
                                    {elem.choices[3][
                                      `choices-4-${index + 1}`
                                    ].toLowerCase() !== 'n/a' && (
                                      <div className='d-flex p-1'>
                                        <input
                                          value='D'
                                          name={'question' + numString}
                                          className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                          type='radio'
                                          id={`question${numString}-choice-D`}
                                        />
                                        <label
                                          className='m-0 ms-3 text-start'
                                          htmlFor={`question${numString}-choice-D`}
                                        >
                                          <strong>
                                            <em>D. </em>
                                          </strong>
                                          {
                                            elem.choices[3][
                                              `choices-4-${index + 1}`
                                            ]
                                          }
                                        </label>
                                      </div>
                                    )}
                                    <TextArea
                                      hidden={elem.correctAnswer !== 'n/a'}
                                      name={'question' + numString}
                                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                    />
                                  </>
                                )}
                            </Row>
                          </>
                        )}
                        {elem.questionType === 'caseStudy' && (
                          <>
                            <Row
                              className={
                                'mb-3 d-flex justify-content-between align-items-center'
                              }
                            >
                              <label htmlFor={`question-${index + 1}`}>
                                <strong>Instructions:</strong>
                              </label>
                            </Row>
                            <Row className={'mb-3'}>
                              <p>{elem?.question}</p>
                            </Row>

                            {elem?.scenario.map((scenario, scenarioIndex) => (
                              <div key={scenarioIndex} className='my-4'>
                                <div>
                                  <h4>
                                    Case Scenario #{scenarioIndex + 1}:{' '}
                                    {scenario.title}{' '}
                                  </h4>
                                  <p>{scenario.description}</p>
                                </div>
                              </div>
                            ))}
                            <div>
                              <p>Select a case scenario before uploading:</p>
                              <div className='d-flex gap-3'>
                                {elem?.scenario.map(
                                  (scenario, scenarioIndex) => (
                                    <div
                                      key={scenarioIndex}
                                      className='fw-bold'
                                    >
                                      <input
                                        type='radio'
                                        name='caseScenario'
                                        id={`caseScenario-${scenarioIndex}`}
                                      />{' '}
                                      Case # {scenarioIndex + 1}
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                            <input className='mt-4' type='file' />
                          </>
                        )}
                      </div>
                    )
                  })}
              </div>
              <OnlineTrainingNavigation
                training={training}
                speakerUpload={speakerUpload}
              />
            </div>
            <section className='col-md-4 col-12 order-1 order-md-2'>
              {!selectedTranscript && (
                <React.Fragment>
                  <div className='border-bottom'>
                    <div className='d-flex justify-content-between px-3 pt-3'>
                      <h4>Course Content</h4>
                      {/* <button
                        className='bg-white border-0'
                        onClick={handleCancel}
                      >
                        <h4>x</h4>
                      </button> */}
                    </div>
                  </div>
                  <div className='px-3'>
                    <div className='d-flex flex-column'>
                      <UploadOnlineTrainingAccordionPreview
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        training={training}
                        setSelectedVideoKey={setSelectedVideoKey}
                        selectedVideoKey={selectedVideoKey}
                        setSelectedActivity={setSelectedActivity}
                        setSelectedTranscript={setSelectedTranscript}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {selectedTranscript && (
                <div className='flex'>
                  <div className='d-flex justify-content-between px-3 pt-3'>
                    <h4>Transcript</h4>
                    <button
                      className='bg-white border-0'
                      onClick={() => setSelectedTranscript(null)}
                    >
                      <h4>x</h4>
                    </button>
                  </div>
                  <p
                    className='px-3 mt-2'
                    style={{ maxHeight: '580px', overflow: 'auto' }}
                  >
                    {selectedTranscript}
                  </p>
                </div>
              )}
            </section>
          </div>
        </Modal>
      )}
    </section>
  )
}
