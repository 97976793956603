/**
 * Title: postAnswersTwo.js
 * Description: This is a file that contains the components that displays the post answers two
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/postAnswersTwo.js
 **/

import { useState, useEffect } from 'react'

import { Row, Col } from 'antd'

export default function PostAnswersOne({ data, record }) {
  const [setAQOne, setSetAQOne] = useState(null)
  const [setAQTwo, setSetAQTwo] = useState(null)
  const [setAQThree, setSetAQThree] = useState(null)

  const [setBQOne, setSetBQOne] = useState(null)
  const [setBQTwo, setSetBQTwo] = useState(null)
  const [setBQThree, setSetBQThree] = useState(null)

  const [setCQOne, setSetCQOne] = useState(null)
  const [setCQTwo, setSetCQTwo] = useState(null)
  const [setCQThree, setSetCQThree] = useState(null)

  const [setDQOne, setSetDQOne] = useState(null)
  const [setDQTwo, setSetDQTwo] = useState(null)
  const [setDQThree, setSetDQThree] = useState(null)

  const [setEQOne, setSetEQOne] = useState(null)
  const [setEQTwo, setSetEQTwo] = useState(null)
  const [setEQThree, setSetEQThree] = useState(null)

  const [questionOneAfter, setQuestionOneAfter] = useState(null)
  const [questionTwoAfter, setQuestionTwoAfter] = useState(null)
  const [questionThreeAfter, setQuestionThreeAfter] = useState(null)
  const [questionFourAfter, setQuestionFourAfter] = useState(null)
  const [questionFiveAfter, setQuestionFiveAfter] = useState(null)

  const [show, setShow] = useState(false)

  useEffect(() => {
    const d = JSON.parse(data.postEvalQuestions)
    const q = JSON.parse(data.questionnaire)

    setSetAQOne(d.setAQOne)
    setSetAQTwo(d.setAQTwo)
    setSetAQThree(d.setAQThree)
    setSetBQOne(d.setBQOne)
    setSetBQTwo(d.setBQTwo)
    setSetBQThree(d.setBQThree)
    setSetCQOne(d.setCQOne)
    setSetCQTwo(d.setCQTwo)
    setSetCQThree(d.setCQThree)
    setSetDQOne(d.setDQOne)
    setSetDQTwo(d.setDQTwo)
    setSetDQThree(d.setDQThree)
    setSetEQOne(d.setEQOne)
    setSetEQTwo(d.setEQTwo)
    setSetEQThree(d.setEQThree)

    setQuestionOneAfter(q.questionOne)
    setQuestionTwoAfter(q.questionTwo)
    setQuestionThreeAfter(q.questionThree)
    setQuestionFourAfter(q.questionFour)
    setQuestionFiveAfter(q.questionFive)

    setTimeout(() => {
      setShow(true)
    }, 100)
  }, [])

  return (
    <>
      {show == true ? (
        <>
          <Row className={'mb-3 mt-3'}>
            <h4>I. Post Evaluation</h4>
          </Row>

          <Row style={{ fontSize: 16 }} justify='center'>
            <Col>
              <Row>
                <span className={'fw-bold'}>I. PROGRAM DESIGN</span>
              </Row>
              <Row>
                <span className={'fw-bold'}>1 = Poor, 5 = Excellent</span>
              </Row>
              <Row className={'mb-3'}>
                <Col style={{ width: 550 }}>
                  <Row>
                    <Col span={9}>
                      <Row style={{ height: '22px' }}></Row>
                      <Row>
                        Clarity of Objectives <em className='asterisk'>**</em>
                      </Row>
                      <Row>
                        Relevance of Contents <em className='asterisk'>**</em>
                      </Row>
                      <Row>
                        Sequence of Contents <em className='asterisk'>**</em>
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>1</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQOne == '1' ? true : false}
                          value='1'
                          name='setAQOne'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQTwo == '1' ? true : false}
                          value='1'
                          name='setAQTwo'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQThree == '1' ? true : false}
                          value='1'
                          name='setAQThree'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>2</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQOne == '2' ? true : false}
                          value='2'
                          name='setAQOne'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQTwo == '2' ? true : false}
                          value='2'
                          name='setAQTwo'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQThree == '2' ? true : false}
                          value='2'
                          name='setAQThree'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>3</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQOne == '3' ? true : false}
                          value='3'
                          name='setAQOne'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQTwo == '3' ? true : false}
                          value='3'
                          name='setAQTwo'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQThree == '3' ? true : false}
                          value='3'
                          name='setAQThree'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>4</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQOne == '4' ? true : false}
                          value='4'
                          name='setAQOne'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQTwo == '4' ? true : false}
                          value='4'
                          name='setAQTwo'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQThree == '4' ? true : false}
                          value='4'
                          name='setAQThree'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>5</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQOne == '5' ? true : false}
                          value='5'
                          name='setAQOne'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQTwo == '5' ? true : false}
                          value='5'
                          name='setAQTwo'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          defaultChecked={setAQThree == '5' ? true : false}
                          value='5'
                          name='setAQThree'
                          className={'postAssesmentQ'}
                          type='radio'
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <span className={'fw-bold'}>
                  II. FACILITATION by Mr. Dale Patrick Lasala{' '}
                  <em className='asterisk'>**</em>
                </span>
              </Row>
              <Row>
                <span className={'fw-bold'}>1 = Poor, 5 = Excellent</span>
              </Row>
              <Row className={'mb-3'}>
                <Col style={{ width: 550 }}>
                  <Row>
                    <Col span={9}>
                      <Row style={{ height: '22px' }}></Row>
                      <Row>
                        Mastery of the Subject <em className='asterisk'>**</em>
                      </Row>
                      <Row>
                        Time Management <em className='asterisk'>**</em>
                      </Row>
                      <Row>
                        Professional Conduct <em className='asterisk'>**</em>
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>1</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQOne == '1' ? true : false}
                          value='1'
                          name='setBQOne'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQTwo == '1' ? true : false}
                          value='1'
                          name='setBQTwo'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQThree == '1' ? true : false}
                          value='1'
                          name='setBQThree'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>2</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQOne == '2' ? true : false}
                          value='2'
                          name='setBQOne'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQTwo == '2' ? true : false}
                          value='2'
                          name='setBQTwo'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQThree == '2' ? true : false}
                          value='2'
                          name='setBQThree'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>3</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQOne == '3' ? true : false}
                          value='3'
                          name='setBQOne'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQTwo == '3' ? true : false}
                          value='3'
                          name='setBQTwo'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQThree == '3' ? true : false}
                          value='3'
                          name='setBQThree'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>4</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQOne == '4' ? true : false}
                          value='4'
                          name='setBQOne'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQTwo == '4' ? true : false}
                          value='4'
                          name='setBQTwo'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQThree == '4' ? true : false}
                          value='4'
                          name='setBQThree'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>5</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQOne == '5' ? true : false}
                          value='5'
                          name='setBQOne'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQTwo == '5' ? true : false}
                          value='5'
                          name='setBQTwo'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setBQThree == '5' ? true : false}
                          value='5'
                          name='setBQThree'
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/*
                <>
                  <Row>
                    <span className={"fw-bold"}>
                      III. FACILITATION - Benefits & Incentives by ROMMEL ROMERO{" "}
                      <em className="asterisk">**</em>
                    </span>
                  </Row>
                  <Row>
                    <span className={"fw-bold"}>1 = Poor, 5 = Excellent</span>
                  </Row>
                  <Row className={"mb-3"}>
                    <Col style={{ width: 550 }}>
                      <Row>
                        <Col span={9}>
                          <Row style={{ height: "22px" }}></Row>
                          <Row>
                            Mastery of the Subject <em className="asterisk">**</em>
                          </Row>
                          <Row>
                            Time Management <em className="asterisk">**</em>
                          </Row>
                          <Row>
                            Professional Conduct <em className="asterisk">**</em>
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={"fw-bold"}>1</span>
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQOne == "1" ? true : false}
                              value="1"
                              name="setCQOne"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQTwo == "1" ? true : false}
                              value="1"
                              name="setCQTwo"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQThree == "1" ? true : false}
                              value="1"
                              name="setCQThree"
                              type="radio"
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={"fw-bold"}>2</span>
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQOne == "2" ? true : false}
                              value="2"
                              name="setCQOne"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQTwo == "2" ? true : false}
                              value="2"
                              name="setCQTwo"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQThree == "2" ? true : false}
                              value="2"
                              name="setCQThree"
                              type="radio"
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={"fw-bold"}>3</span>
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQOne == "3" ? true : false}
                              value="3"
                              name="setCQOne"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQTwo == "3" ? true : false}
                              value="3"
                              name="setCQTwo"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQThree == "3" ? true : false}
                              value="3"
                              name="setCQThree"
                              type="radio"
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={"fw-bold"}>4</span>
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQOne == "4" ? true : false}
                              value="4"
                              name="setCQOne"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQTwo == "4" ? true : false}
                              value="4"
                              name="setCQTwo"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQThree == "4" ? true : false}
                              value="4"
                              name="setCQThree"
                              type="radio"
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={"fw-bold"}>5</span>
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQOne == "5" ? true : false}
                              value="5"
                              name="setCQOne"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQTwo == "5" ? true : false}
                              value="5"
                              name="setCQTwo"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setCQThree == "5" ? true : false}
                              value="5"
                              name="setCQThree"
                              type="radio"
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <span className={"fw-bold"}>
                      IV. FACILITATION - Introduction to Gender and Development by
                      KAREN CHRISTINE SANTOS <em className="asterisk">**</em>
                    </span>
                  </Row>
                  <Row>
                    <span className={"fw-bold"}>1 = Poor, 5 = Excellent</span>
                  </Row>
                  <Row className={"mb-3"}>
                    <Col style={{ width: 550 }}>
                      <Row>
                        <Col span={9}>
                          <Row style={{ height: "22px" }}></Row>
                          <Row>
                            Mastery of the Subject <em className="asterisk">**</em>
                          </Row>
                          <Row>
                            Time Management <em className="asterisk">**</em>
                          </Row>
                          <Row>
                            Professional Conduct <em className="asterisk">**</em>
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={"fw-bold"}>1</span>
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQOne == "1" ? true : false}
                              value="1"
                              name="setDQOne"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQTwo == "1" ? true : false}
                              value="1"
                              name="setDQTwo"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQThree == "1" ? true : false}
                              value="1"
                              name="setDQThree"
                              type="radio"
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={"fw-bold"}>2</span>
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQOne == "2" ? true : false}
                              value="2"
                              name="setDQOne"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQTwo == "2" ? true : false}
                              value="2"
                              name="setDQTwo"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQThree == "2" ? true : false}
                              value="2"
                              name="setDQThree"
                              type="radio"
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={"fw-bold"}>3</span>
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQOne == "3" ? true : false}
                              value="3"
                              name="setDQOne"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQTwo == "3" ? true : false}
                              value="3"
                              name="setDQTwo"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQThree == "3" ? true : false}
                              value="3"
                              name="setDQThree"
                              type="radio"
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={"fw-bold"}>4</span>
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQOne == "4" ? true : false}
                              value="4"
                              name="setDQOne"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQTwo == "4" ? true : false}
                              value="4"
                              name="setDQTwo"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQThree == "4" ? true : false}
                              value="4"
                              name="setDQThree"
                              type="radio"
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={"fw-bold"}>5</span>
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQOne == "5" ? true : false}
                              value="5"
                              name="setDQOne"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQTwo == "5" ? true : false}
                              value="5"
                              name="setDQTwo"
                              type="radio"
                            />
                          </Row>
                          <Row style={{ height: "22px" }}>
                            <input
                              type="radio"
                              defaultChecked={setDQThree == "5" ? true : false}
                              value="5"
                              name="setDQThree"
                              type="radio"
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </>
              */}

              <Row>
                {/**
                  <span className={"fw-bold"}>V. ADMINISTRATION</span>
                **/}
                <span className={'fw-bold'}>III. ADMINISTRATION</span>
              </Row>
              <Row>
                <span className={'fw-bold'}>1 = Poor, 5 = Excellent</span>
              </Row>
              <Row className={'mb-3'}>
                <Col style={{ width: 550 }}>
                  <Row>
                    <Col span={9}>
                      <Row style={{ height: '22px' }}></Row>
                      <Row>
                        Mastery of the Subject1 <em className='asterisk'>**</em>
                      </Row>
                      <Row>
                        Time Management <em className='asterisk'>**</em>
                      </Row>
                      <Row>
                        Professional Conduct <em className='asterisk'>**</em>
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>1</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQOne == '1' ? true : false}
                          value='1'
                          name='setEQOne'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQTwo == '1' ? true : false}
                          value='1'
                          name='setEQTwo'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQThree == '1' ? true : false}
                          value='1'
                          name='setEQThree'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>2</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQOne == '2' ? true : false}
                          value='2'
                          name='setEQOne'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQTwo == '2' ? true : false}
                          value='2'
                          name='setEQTwo'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQThree == '2' ? true : false}
                          value='2'
                          name='setEQThree'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>3</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQOne == '3' ? true : false}
                          value='3'
                          name='setEQOne'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQTwo == '3' ? true : false}
                          value='3'
                          name='setEQTwo'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQThree == '3' ? true : false}
                          value='3'
                          name='setEQThree'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>4</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQOne == '4' ? true : false}
                          value='4'
                          name='setEQOne'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQTwo == '4' ? true : false}
                          value='4'
                          name='setEQTwo'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQThree == '4' ? true : false}
                          value='4'
                          name='setEQThree'
                        />
                      </Row>
                    </Col>
                    <Col span={3}>
                      <Row>
                        <span className={'fw-bold'}>5</span>
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQOne == '5' ? true : false}
                          value='5'
                          name='setEQOne'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQTwo == '5' ? true : false}
                          value='5'
                          name='setEQTwo'
                        />
                      </Row>
                      <Row style={{ height: '22px' }}>
                        <input
                          type='radio'
                          defaultChecked={setEQThree == '5' ? true : false}
                          value='5'
                          name='setEQThree'
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          {record.hasAssessment == 'true' ? (
            <>
              <Row className={'mt-5 mb-3'}>
                <h4>III. Post Assessment</h4>
              </Row>
            </>
          ) : null}

          {record.hasAssessment == 'true' ? (
            <>
              <Row>
                <Col span={24}>
                  <Row className={'mb-4'} justify='center'>
                    <Col style={{ width: 850 }}>
                      <Row className={'mb-2'}>
                        <span style={{ fontSize: 16, color: 'blue' }}>
                          <em>Note: item in bold is the correct answer.</em>
                        </span>
                      </Row>
                      <Row className={'mb-3'}>
                        <span>
                          <em>
                            <b>
                              Total score:{' '}
                              {parseInt(
                                `${questionOneAfter == 'questionOneA' ? 1 : 0}`
                              ) +
                                parseInt(
                                  `${
                                    questionTwoAfter == 'questionTwoC' ? 1 : 0
                                  }`
                                ) +
                                parseInt(
                                  `${
                                    questionThreeAfter == 'questionThreeA'
                                      ? 1
                                      : 0
                                  }`
                                ) +
                                parseInt(
                                  `${
                                    questionFourAfter == 'questionFourA' ? 1 : 0
                                  }`
                                ) +
                                parseInt(
                                  `${
                                    questionFiveAfter == 'questionFiveD' ? 1 : 0
                                  }`
                                )}
                            </b>
                          </em>
                        </span>
                      </Row>
                    </Col>
                    <Col style={{ width: 850 }}>
                      <Row>
                        <label
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                          }}
                          className={'loginInput-label-no-bold mb-3'}
                          htmlFor='preAssessment'
                        >
                          1. An appointment issued to a person who meets all the
                          qualification requirements of the position to which
                          he/she is being appointed to, including the
                          appropriate eligibility, in accordance with the
                          provisions of law, rules and standards
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row>
                        <span>
                          <em>
                            <b>{`${
                              questionOneAfter == 'questionOneA'
                                ? '1 point'
                                : '0 point'
                            }`}</b>
                          </em>
                        </span>
                      </Row>
                      <Row
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionOneAfter == 'questionOneA'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionOneAfter == 'questionOneA'
                                  ? false
                                  : true
                              }
                              value='questionOneA'
                              style={{ fontSize: 16 }}
                              name='questionOneSet'
                              type='radio'
                              id='plantillaOne'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='plantillaOne'
                            >
                              a. Plantilla
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionOneAfter == 'questionOneB'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionOneAfter == 'questionOneB'
                                  ? false
                                  : true
                              }
                              value='questionOneB'
                              style={{ fontSize: 16 }}
                              name='questionOneSet'
                              type='radio'
                              id='contractOfServiceOne'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='contractOfServiceOne'
                            >
                              b. Contract of Service
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionOneAfter == 'questionOneC'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionOneAfter == 'questionOneC'
                                  ? false
                                  : true
                              }
                              value='questionOneC'
                              style={{ fontSize: 16 }}
                              name='questionOneSet'
                              type='radio'
                              id='jobOrderOne'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='jobOrderOne'
                            >
                              c. Job Order
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionOneAfter == 'questionOneD'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionOneAfter == 'questionOneD'
                                  ? false
                                  : true
                              }
                              value='questionOneD'
                              style={{ fontSize: 16 }}
                              name='questionOneSet'
                              type='radio'
                              id='consultantOne'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='consultantOne'
                            >
                              d. Consultant
                            </label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className={'mb-4'} justify='center'>
                    <Col style={{ width: 850 }}>
                      <Row>
                        <label
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                          }}
                          className={'loginInput-label-no-bold mb-3'}
                          htmlFor='preAssessment'
                        >
                          2. Refers to the hiring of a worker for piece work or
                          intermittent job of short duration not exceeding six
                          months and pay is on a daily or hourly basis. Such as
                          clearing of debris on the roads, canals, waterways,
                          etc. after natural/man-made disasters/occurrences and
                          other manual/trades and crafts services such as
                          carpentry, plumbing, electrical and the like.
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row>
                        <span>
                          <em>
                            <b>{`${
                              questionTwoAfter == 'questionTwoC'
                                ? '1 point'
                                : '0 point'
                            }`}</b>
                          </em>
                        </span>
                      </Row>
                      <Row
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionTwoAfter == 'questionTwoA'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionTwoAfter == 'questionTwoA'
                                  ? false
                                  : true
                              }
                              value='questionTwoA'
                              style={{ fontSize: 16 }}
                              name='questionTwoSet'
                              type='radio'
                              id='plantillaTwo'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='plantillaTwo'
                            >
                              a. Plantilla
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionTwoAfter == 'questionTwoB'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionTwoAfter == 'questionTwoB'
                                  ? false
                                  : true
                              }
                              value='questionTwoB'
                              style={{ fontSize: 16 }}
                              name='questionTwoSet'
                              type='radio'
                              id='contractOfServiceTwo'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='contractOfServiceTwo'
                            >
                              b. Contract of Service
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionTwoAfter == 'questionTwoC'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionTwoAfter == 'questionTwoC'
                                  ? false
                                  : true
                              }
                              value='questionTwoC'
                              style={{ fontSize: 16 }}
                              name='questionTwoSet'
                              type='radio'
                              id='jobOrderTwo'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='jobOrderTwo'
                            >
                              c. Job Order
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionTwoAfter == 'questionTwoD'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionTwoAfter == 'questionTwoD'
                                  ? false
                                  : true
                              }
                              value='questionTwoD'
                              style={{ fontSize: 16 }}
                              name='questionTwoSet'
                              type='radio'
                              id='consultantTwo'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='consultantTwo'
                            >
                              d. Consultant
                            </label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className={'mb-4'} justify='center'>
                    <Col style={{ width: 850 }}>
                      <Row>
                        <label
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                          }}
                          className={'loginInput-label-no-bold mb-3'}
                          htmlFor='preAssessment'
                        >
                          3. Which among the civilian clothing is prohibited to
                          wear by employees of Quezon City Government?{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row>
                        <span>
                          <em>
                            <b>{`${
                              questionThreeAfter == 'questionThreeA'
                                ? '1 point'
                                : '0 point'
                            }`}</b>
                          </em>
                        </span>
                      </Row>
                      <Row
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionThreeAfter == 'questionThreeA'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionThreeAfter == 'questionThreeA'
                                  ? false
                                  : true
                              }
                              value='questionThreeA'
                              style={{ fontSize: 16 }}
                              name='questionThreeSet'
                              type='radio'
                              id='threeA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='threeA'
                            >
                              a. strapless, or spaghetti-strap blouse, tank
                              tops, blouse with over-plunging necklines
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionThreeAfter == 'questionThreeB'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionThreeAfter == 'questionThreeB'
                                  ? false
                                  : true
                              }
                              value='questionThreeB'
                              style={{ fontSize: 16 }}
                              name='questionThreeSet'
                              type='radio'
                              id='threeB'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='threeB'
                            >
                              b. Barong/ Filipiniana Dress
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionThreeAfter == 'questionThreeC'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionThreeAfter == 'questionThreeC'
                                  ? false
                                  : true
                              }
                              value='questionThreeC'
                              style={{ fontSize: 16 }}
                              name='questionThreeSet'
                              type='radio'
                              id='threeC'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='threeC'
                            >
                              c. Collared polo shirt
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionThreeAfter == 'questionThreeD'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionThreeAfter == 'questionThreeD'
                                  ? false
                                  : true
                              }
                              value='questionThreeD'
                              style={{ fontSize: 16 }}
                              name='questionThreeSet'
                              type='radio'
                              id='threeD'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='threeD'
                            >
                              d. Blazer
                            </label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className={'mb-4'} justify='center'>
                    <Col style={{ width: 850 }}>
                      <Row>
                        <label
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                          }}
                          className={
                            'loginInput-label-no-boldloginInput-label-no-bold mb-3'
                          }
                          htmlFor='preAssessment'
                        >
                          4. How many hours should a COS employee work per week?{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row>
                        <span>
                          <em>
                            <b>{`${
                              questionFourAfter == 'questionFourA'
                                ? '1 point'
                                : '0 point'
                            }`}</b>
                          </em>
                        </span>
                      </Row>
                      <Row
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFourAfter == 'questionFourA'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionFourAfter == 'questionFourA'
                                  ? false
                                  : true
                              }
                              value='questionFourA'
                              style={{ fontSize: 16 }}
                              name='questionFourSet'
                              type='radio'
                              id='fourA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='fourA'
                            >
                              a. 40 hours
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFourAfter == 'questionFourB'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionFourAfter == 'questionFourB'
                                  ? false
                                  : true
                              }
                              value='questionFourB'
                              style={{ fontSize: 16 }}
                              name='questionFourSet'
                              type='radio'
                              id='fourB'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='fourB'
                            >
                              b. 48 hours
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFourAfter == 'questionFourC'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionFourAfter == 'questionFourC'
                                  ? false
                                  : true
                              }
                              value='questionFourC'
                              style={{ fontSize: 16 }}
                              name='questionFourSet'
                              type='radio'
                              id='fourC'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='fourC'
                            >
                              c. 24 hours
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFourAfter == 'questionFourD'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionFourAfter == 'questionFourD'
                                  ? false
                                  : true
                              }
                              value='questionFourD'
                              style={{ fontSize: 16 }}
                              name='questionFourSet'
                              type='radio'
                              id='fourD'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='fourD'
                            >
                              d. 36 hours
                            </label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row className={'mb-4'} justify='center'>
                    <Col style={{ width: 850 }}>
                      <Row>
                        <label
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                          }}
                          className={'loginInput-label-no-bold mb-3'}
                          htmlFor='preAssessment'
                        >
                          5. Which among the following does not belong to
                          RA6713?
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row>
                        <span>
                          <em>
                            <b>{`${
                              questionFiveAfter == 'questionFiveD'
                                ? '1 point'
                                : '0 point'
                            }`}</b>
                          </em>
                        </span>
                      </Row>
                      <Row
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFiveAfter == 'questionFiveA'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionFiveAfter == 'questionFiveA'
                                  ? false
                                  : true
                              }
                              value='questionFiveA'
                              style={{ fontSize: 16 }}
                              name='questionFiveSetd'
                              type='radio'
                              id='fiveA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='fiveA'
                            >
                              a. Responsiveness
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFiveAfter == 'questionFiveB'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionFiveAfter == 'questionFiveB'
                                  ? false
                                  : true
                              }
                              value='questionFiveB'
                              style={{ fontSize: 16 }}
                              name='questionFiveSetd'
                              type='radio'
                              id='fiveB'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='fiveB'
                            >
                              b. Commitment to Democracy
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFiveAfter == 'questionFiveC'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionFiveAfter == 'questionFiveC'
                                  ? false
                                  : true
                              }
                              value='questionFiveC'
                              style={{ fontSize: 16 }}
                              name='questionFiveSetd'
                              type='radio'
                              id='fiveC'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label-no-bold'
                              htmlFor='fiveC'
                            >
                              c. Justness and Sincerity
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFiveAfter == 'questionFiveD'
                                  ? true
                                  : false
                              }
                              disabled={
                                questionFiveAfter == 'questionFiveD'
                                  ? false
                                  : true
                              }
                              value='questionFiveD'
                              style={{ fontSize: 16 }}
                              name='questionFiveSetd'
                              type='radio'
                              id='fiveD'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='fiveD'
                            >
                              d. Loyalty
                            </label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : null}
        </>
      ) : null}
    </>
  )
}
