import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses'
import { Auth } from 'aws-amplify'

export async function sendEmail(emailSubject, emailBody) {
  const credentials = await Auth.currentCredentials()
  const accessKeyId = credentials.accessKeyId
  const secretAccessKey = credentials.secretAccessKey
  const sessionToken = credentials.sessionToken

  const sesClient = new SESClient({
    region: 'ap-southeast-1',
    credentials: {
      accessKeyId: accessKeyId,
      secretAccessKey: secretAccessKey,
      sessionToken: sessionToken,
    },
  })

  const params = {
    Destination: {
      ToAddresses: ['hrmd@quezoncity.gov.ph', 'shru@quezoncity.gov.ph'],
    },
    Message: {
      Body: {
        Text: {
          Data: emailBody,
          Charset: 'UTF-8',
        },
      },
      Subject: {
        Data: emailSubject, // replace with your email subject
        Charset: 'UTF-8',
      },
    },
    Source: 'hris.qc.gov@gmail.com',
  }

  await sesClient.send(new SendEmailCommand(params))
}
