/**
 * Title: recruitment.js
 * Description: This is a file that contains the components for the recruitment page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/recruitment.js
 **/

import { useState, useEffect } from 'react'
import { Row, Grid } from 'antd'

const pattern = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.[A-Za-z0-9-_.+/=]*$/g

const { useBreakpoint } = Grid
export default function Recruitment({ userData }) {
  const { md, lg, xl } = useBreakpoint()

  let myMargin = 0
  if (window.screen.width <= 768) {
    myMargin = 0
  } else if (window.screen.width > 768 && window.screen.width <= 1200) {
    myMargin = '8rem'
  } else {
    myMargin = '14rem'
  }
  let myFont = '1rem'
  if (xl) {
    myFont = '1.75rem'
  } else if (lg) {
    myFont = '1.25rem'
  } else if (md) {
    myFont = '1rem'
  }

  let myWidth = '640px'
  if (window.screen.width <= 768) {
    myWidth = '240px'
  } else if (window.screen.width <= 1300) {
    myWidth = '432px'
  }
  let myHeight = '360px'
  if (window.screen.width <= 768) {
    myHeight = '135px'
  } else if (window.screen.width <= 1300) {
    myHeight = '243px'
  }

  const [role, setRole] = useState('employee')

  useEffect(() => {
    userData?.role ? setRole(userData.role) : setRole('employee')
  }, [role])

  return (
    <>
      <Row className='showcase' justify='center'>
        <video className='video-container' muted autoPlay loop>
          <source src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/Kalibrr-Website+Background.mp4' />
        </video>

        <div className='zindex' style={{ marginTop: myMargin }}>
          <Row className='recruitVideoRow' justify='center'>
            <video
              controls
              controlsList='nodownload'
              preload='auto'
              style={{ minWidth: myWidth, height: myHeight }}
              autoPlay
            >
              <source src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/KALIBRR-QCHRMD+page.mp4' />
            </video>
          </Row>
          <Row justify='center' className='kalibrr'>
            <h5 style={{ fontSize: myFont }}>
              Visit Quezon City Government's{' '}
              <a
                target='_blank'
                href='https://www.kalibrr.com/c/quezon-city-local-government/jobs'
              >
                Kalibrr
              </a>{' '}
              Page
            </h5>
          </Row>
        </div>
      </Row>
    </>
  )
}
