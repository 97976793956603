/**
 * Title: ildpFormModalPrintEnglish.js
 * Description: This is a file that contains the modal to print the ILDP form in Tagalog version
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Form/ildpFormModalPrintEnglish.js
 **/

import { Card, List, Col, Row, Input, Checkbox } from 'antd'

const { TextArea } = Input

export default function IldpFormModalPrintPinoy({
  changeFname,
  disable,
  submissionDataIldp,
}) {
  const currentTimeInSGT = (e) => {
    if (e) {
      return new Date(e).toLocaleString('en-SG', {
        timeZone: 'Asia/Singapore',
        hour12: false,
      })
    } else {
      return ''
    }
  }

  return (
    <div>
      <div id='specificPinoy'>
        <>
          <div id='specific1a'>
            <Row>
              <Col span={24}>
                <List
                  bordered
                  size='large'
                  header={
                    <b style={{ fontSize: '18px' }}>
                      Mga Tagubilin para sa mga Indibidwal at Superbisor sa
                      pagpuno ng Individual Learning and Development Plan
                      (ILDP):
                    </b>
                  }
                  style={{ marginBottom: '20px' }}
                >
                  <List.Item key='1'>
                    1.) Punan ng bawat indibidwal ang form ng ILDP na base sa
                    ibinigay na sagot sa Self-Assessment Tool ng e-TNA Form.
                  </List.Item>
                  <List.Item key='2'>
                    2.) Pagkatapos sagutan ang ILDP, i-print ang nasagutang
                    kopya at ipasa sa kanilang superbisor para sa karagdagang
                    pagsusuri at pagpuna.
                  </List.Item>
                  <List.Item key='3'>
                    3.) Tandaan ang iyong <b>Competencies</b> na kailangan pang
                    palaguin (base sa resulta ng iyong e-TNA/Self-Assessment
                    Tool).
                  </List.Item>
                  <List.Item key='4'>
                    4.) Kapag naaprubahan ng superbisor, mag-print ng pinal na
                    kopya upang malagdaan ng parehong indibidwal at superbisor.
                  </List.Item>
                  <List.Item key='5'>
                    5.) Ang indibidwal ay mag-uupload ng pinal na kopya na may
                    pirma sa ikalawang hakbang ng Learning and Development
                    System.
                  </List.Item>
                  <List.Item key='6'>
                    6.) Mangyaring i-update ng regular ang ILDP pagkatapos
                    makumpleto ang bawat programa na kinuha. At mangyaring
                    ingatan ang mga kopya ng mga sertipiko na isinumite online.
                    Ang ILDP ay sasaklaw ng tatlong taon (2022-2025).
                  </List.Item>
                </List>
              </Col>
            </Row>
          </div>

          <Card>
            <div id='specific2b'>
              <Row>
                <Col span={24}>
                  <h3
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    INDIVIDUAL LEARNING & DEVELOPMENT PLAN (ILDP)
                  </h3>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={6}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontSize: '12px',
                    }}
                  ></p>
                </Col>
                <Col span={18}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontSize: '12px',
                    }}
                  >
                    SAKOP NG PANAHON (Kasama ang mga petsa ng buong 3 taon upang
                    makumpleto ang mga programa.)
                  </p>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Pangalan:
                    </label>
                    <Input value={submissionDataIldp.panel2Columns2Pangalan} />
                  </span>
                </Col>
                <Col span={6}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Date from:
                    </label>
                    <Input
                      value={currentTimeInSGT(
                        submissionDataIldp.panel2Columns2ColumnsPetsamula
                      )}
                    />
                  </span>
                </Col>

                <Col span={6}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Date To
                    </label>
                    <Input
                      value={currentTimeInSGT(
                        submissionDataIldp.panel2Columns2ColumnsPetsahanggang
                      )}
                    />
                  </span>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      KASAKULUKUYANG POSISYON:
                    </label>
                    <Input value={submissionDataIldp.panel2Columns2Pangalan5} />
                  </span>
                </Col>

                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Division
                    </label>
                    <Input value={submissionDataIldp.panel2Columns2Division} />
                  </span>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      GRADO NG SUWELDO:
                    </label>
                    <Input value={submissionDataIldp.panel2Columns2Pangalan4} />
                  </span>
                </Col>

                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      OPISINA:
                    </label>
                    <Input value={submissionDataIldp.panel2Columns2Division4} />
                  </span>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      TAON SA POSISYON:
                    </label>
                    <Input value={submissionDataIldp.panel2Columns2Pangalan3} />
                  </span>
                </Col>

                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      PANGALAN NG SUPERBISOR:
                    </label>
                    <Input
                      disabled={disable}
                      id='fname'
                      placeholder='Enter your first name'
                      onChange={(e) => changeFname(e)}
                      className='loginInput'
                      required
                    />
                  </span>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      TAON SA QCG:
                    </label>
                    <Input value={submissionDataIldp.panel2Columns2Pangalan2} />
                  </span>
                </Col>

                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Petsa ng pagkakuha:
                    </label>
                    <Input
                      value={currentTimeInSGT(
                        submissionDataIldp.panel2Columns3Petsangpagkakuha
                      )}
                    />
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <h3
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                  >
                    LAYUNIN:
                  </h3>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: '0' }}>
                {submissionDataIldp !== undefined && (
                  <Col span={16}>
                    <Checkbox
                      style={{ marginLeft: '8px' }}
                      checked={
                        submissionDataIldp.panel2Columns3Upangmatugunanangmgakakayahanngkasalukuyangposisyon
                      }
                    >
                      Upang matugunan ang mga kakayahan ng kasalukuyang
                      posisyon..
                    </Checkbox>
                    <Checkbox
                      checked={
                        submissionDataIldp.panel2Columns3Upangmapataasangantasngmgakakayahanngkasalukuyangposisyon
                      }
                    >
                      Upang mapataas ang antas ng mga kakayahan ng kasalukuyang
                      posisyon.
                    </Checkbox>
                    <Checkbox
                      checked={
                        submissionDataIldp.panel2Columns3Upangmatugunanangmgakakayahanngkasalukuyangposisyon
                      }
                    >
                      Upang matugunan ang mga kakayahan ng susunod na mas mataas
                      na posisyon.
                    </Checkbox>
                    <Checkbox
                      checked={
                        submissionDataIldp.panel2Columns3Upangmakakuhangmgabagongkakayahansaibatibangtungkulinposisyon
                      }
                    >
                      Upang makakuha ng mga bagong kakayahan sa iba't ibang
                      tungkulin/posisyon.
                    </Checkbox>
                    <Checkbox
                      checked={submissionDataIldp.panel2Columns3Ibapakitukoy}
                    >
                      Iba, pakitukoy:
                    </Checkbox>
                  </Col>
                )}
              </Row>

              <Row gutter={24} style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <span>
                    <TextArea
                      value={
                        submissionDataIldp.panel2MgaKomentoPunasaSuperbisorOneOnOnesession
                      }
                      rows={4}
                    />
                  </span>
                </Col>
              </Row>
            </div>
            <div id='specific3c'>
              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <h3
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                    }}
                  >
                    RESULTA NG SELF-EVALUATION TOOL
                  </h3>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                    }}
                  >
                    Tukuyin ang kakayahan kung saan kailangan mo ng KARAGDAGANG
                    PAG-UNLAD (mga marka na 3.5 pababa)
                  </p>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    CORE COMPETENCIES (TRAINING PROGRAMS)
                  </p>
                </Col>
              </Row>

              <Row style={{ marginTop: '0' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    1.) Exemplifying Ethics & Integrity:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Public Service Ethics & Accountability (PSEA) □
                  Administrative Discipline: Orientation of Values & Ethics in
                  the Workplace
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score14) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score14}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    2.) Decision Making and Problem Solving:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Grievance Handling □ Conciliation and Mediation
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score12) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score12}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    3.) Customer Focus/ Delivering Service Excellence:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Customer Service Satisfaction □ Sevice Management
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score13) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score13}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    LEADERSHIP COMPETENCIES (TRAINING PROGRAMS)
                  </p>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    1.) Managing Performance:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Leadership & Management Program □ Conflict Management □
                  Collective Negotiation □ Effective Management & Supervision □
                  Total Quality Management □ Performance Management
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    2.) Building Commitment:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>□ Culture Building</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score2) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score2}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    3.) Developing People:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Coaching & Mentoring □ Managing Teams/People
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score3) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score3}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    4.) Partnering & Networking:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>□ Expanded Team Building</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score4) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score4}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    5.) Strategic Thinking:
                  </p>
                </Col>
              </Row>
            </div>
            <div id='specific4d'>
              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Strategic Management Planning □ Goal & Metrics Setting
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score5) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score5}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    6.) Leading Change/ Transformative Leadership:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Supervisory Development Course □ Change Management
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score6) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score6}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    ORGANIZATIONAL COMPETENCIES (TRAINING PROGRAMS)
                  </p>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    1.) Communication:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Business/ Technical Writing Skills □ English Proficiency &
                  Effective Communication □ Effective Presentation □ Public
                  Speaking
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score7) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score7}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    2.) Personal Effectiveness:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Personality Development in Local Government □ Time
                  Management □ Obtaining and Providing Positive Work Attitude □
                  Stress Management
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score8) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score8}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    3.) Planning & Delivering:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Strategic HRM: Providing Assistance to Planning & Goal
                  Setting □ Project Management □ AGILE
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score9) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score9}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    4.) Management Information:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Data Management & Analysis/Charts □ Data Privacy
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score10) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score10}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <p
                    style={{
                      display: 'flex',
                      justifyContent: 'start',
                      fontWeight: 'bold',
                    }}
                  >
                    5.) Creative and Innovative Thinking:
                  </p>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  □ Critical Thinking Innovation / Innovative Thinking □ Design
                  Thinking
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    className={
                      parseFloat(submissionDataIldp.panelColumns13Score11) < 3.5
                        ? 'redScore'
                        : 'blackScore'
                    }
                  >
                    Score: {submissionDataIldp.panelColumns13Score11}
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Mga Komento/Puna sa Superbisor (One-on-one session)
                    </label>
                    <TextArea
                      value={
                        submissionDataIldp.panel2MgaKomentoPunasaSuperbisorOneOnOnesession
                      }
                      rows={2}
                    />
                  </span>
                </Col>
              </Row>

              <Row style={{ marginTop: '20px' }}>
                <Col span={24}>
                  Tandaan: Mangyaring tukuyin ang mga partikular na
                  tagapagpahiwatig ng pag-uugali at kasanayan na HINDI patuloy
                  na ipinapakita
                </Col>
              </Row>
            </div>
          </Card>

          <div id='specific5e'>
            <Card
              title='COMPETENCIES'
              style={{
                marginBottom: '20px',
                marginTop: '20px',
              }}
            >
              <p>
                Ang mga sumusunod na Competencies ay kailangan upang matugunan
                ang kakulangan sa Learning and Development.
              </p>
              <Row>
                <Col span={12}>
                  <Card
                    title='Category of Position'
                    style={{ height: '753px' }}
                  >
                    <p>
                      Executive/Managerial, Division Chief/ Asst. Div. Chief and
                      equivalent positions
                    </p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card title='Recommended MCC'>
                    <p>
                      <b>Core</b>
                    </p>
                    <p>* Exemplifying Ethics and Integrity</p>
                    <p>* Delivering Service Excellence</p>
                    <p>* Decision Making and Problem Solving</p>

                    <p>
                      <b>Leadership:</b>
                    </p>
                    <p>* Managing Performance</p>
                    <p>* Building Commitment</p>
                    <p>* Developing People</p>
                    <p>* Partnering & Networking</p>
                    <p>* Strategic Thinking</p>
                    <p>* Leading Change/Transformative Leadership</p>

                    <p>
                      <b>Organizational:</b>
                    </p>
                    <p>* Communication</p>
                    <p>* Personal Effectiveness</p>
                    <p>* Planning & Delivering</p>
                    <p>* Management Information</p>
                    <p>* Creative and Innovative Thinking</p>
                  </Card>
                </Col>
              </Row>
            </Card>
          </div>
          <div id='specific6f'>
            <Card>
              <Row>
                <Col span={12}>
                  <Card style={{ height: '360px' }}>
                    <p>Professional/Technical (SG 11 - 22)</p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{ height: '360px' }}>
                    <p>
                      <b>Core</b>
                    </p>
                    <p>* Exemplifying Ethics and Integrity</p>
                    <p>* Delivering Service Excellence</p>
                    <p>* Decision Making and Problem Solving</p>

                    <p>
                      <b>Organizational:</b>
                    </p>
                    <p>* Communication</p>
                    <p>* Personal Effectiveness</p>
                    <p>* Planning & Delivering</p>
                    <p>
                      * Management Information Creative and Innovative Thinking
                    </p>
                  </Card>
                </Col>
              </Row>
            </Card>
            <Card>
              <Row>
                <Col span={12}>
                  <Card style={{ height: '373px' }}>
                    <p>Clerical/Technical/Admin Support (SG 4-9)</p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{ height: '373px' }}>
                    <p>
                      <b>Core</b>
                    </p>
                    <p>* Exemplifying Ethics and Integrity</p>
                    <p>* Delivering Service Excellence</p>
                    <p>* Decision Making and Problem Solving</p>

                    <p>
                      <b>Organizational:</b>
                    </p>
                    <p>* Communication</p>
                    <p>* Personal Effectiveness</p>
                    <p>* Planning & Delivering</p>
                    <p>
                      * Management Information Creative and Innovative Thinking
                    </p>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Card style={{ height: '180px' }}>
                    <p>Trades/Crafts (SG 1-3)</p>
                  </Card>
                </Col>
                <Col span={12}>
                  <Card style={{ height: '180px' }}>
                    <p>
                      <b>Core</b>
                    </p>
                    <p>* Exemplifying Ethics and Integrity</p>
                    <p>* Delivering Service Excellence</p>
                    <p>* Decision Making and Problem Solving</p>
                  </Card>
                </Col>
              </Row>
              <section>
                <p style={{ marginTop: '36px' }}>
                  Tandaan: Mahalagang tandaan na ang Competencies ay nag-iiba
                  mula sa isang posisyon patungo sa isa pa, gayunpaman, ang mga
                  Core Competencies ay pare-pareho sa lahat ng mga posisyon.
                  Ipinapalagay din na habang ang mga indibidwal na empleyado ay
                  umakyat sa mga posisyon sa pangangasiwa at mas mataas, mayroon
                  na silang mga kakayahan sa organisasyon.
                </p>
              </section>
            </Card>
          </div>
          <div id='specific7g'>
            <Card
              style={{ marginTop: '20px' }}
              title='LEARNING AND DEVELOPMENT PROGRAMS'
            >
              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <b>CORE COMPETENCIES (Required)</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <b>Name of Program</b>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Date Taken</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  Public Service Ethics & Accountability Orientation & Work
                  Ethics
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns5DateTime
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks1 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  {submissionDataIldp.remarks1 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks1.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  Administrative Discipline: Orientation of Values & Ethics in
                  the Workplace
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns5DateTime4
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks2 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks2 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks2.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Social Media Responsibility</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns5DateTime3
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks3 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks3 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks3.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Customer Service Satisfaction</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns5DateTime2
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks4 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks4 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks4.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <b>LEADERSHIP COMPETENCIES</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <b>Name of Program</b>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Date Taken</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>Leadership & Management Program</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks5 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks5 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks5.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Conflict Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime2
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks6 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks6 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks6.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Effective Management & Supervision</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime3
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks7 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks7 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks7.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Total Quality Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime4
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks8 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks8 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks8.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Project Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime5
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks9 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks9 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks9.split(/\r?\n/).map((l) => {
                          return (
                            <Col span={24}>
                              <span>{l}</span>
                            </Col>
                          )
                        })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Culture Building</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime6
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks10 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks10 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks10
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Coaching & Mentoring</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime7
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks11 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks11 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks11
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Managing Teams & People</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime8
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks12 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks12 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks12
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Expanded Team Building</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime9
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks13 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks13 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks13
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Strategic Management Planning</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime12
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks14 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks14 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks14
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Goal and Metrics Setting</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime11
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks15 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks15 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks15
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Supervisory Development Course</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime13
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks16 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks16 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks16
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Change Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns18DateTime10
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks17 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks17 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks17
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Card>
          </div>
          <div id='specific8h'>
            <Card>
              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <b>ORGANIZATIONAL COMPETENCIES</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <b>Name of Program</b>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Date Taken</b>
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Communication</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>Business/Technical Writing Skills</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks18 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks18 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks18
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  English Proficiency & Effective Communication
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime4
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks19 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks19 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks19
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Effective Presentation</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime3
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks20 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks20 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks20
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Public Speaking</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime2
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks21 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks21 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks21
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Personal Effectiveness</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>Personality Development in Local Government</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime5
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks22 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks22 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks22
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Time Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime6
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks23 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks23 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks23
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  Obtaining and Providing Positive Work Attitude
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime7
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks24 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks24 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks24
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Planning and Delivering</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  Strategic HRM: Providing Assistance to Planning and Goal
                  Setting
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime8
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks25 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks25 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks25
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Project Management</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime9
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks26 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks26 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks26
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Management Information</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>Data Management & Analysis/Chart</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime10
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks27 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks27 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks27
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Data Privacy</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime11
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks28 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks28 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks28
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>

              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Creative and Innovative Thinking</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>Critical Thinking</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime12
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks29 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks29 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks29
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Innovative Thinking</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime13
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks30 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}
                  {submissionDataIldp.remarks30 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks30
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>Design Thinking</Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime8
                    )}
                  ></Input>
                </Col>
                <Col span={24}>
                  {submissionDataIldp.remarks31 ? (
                    <>
                      <Row>
                        <Col
                          span={24}
                          style={{
                            display: 'flex',
                            justifyContent: 'start',
                          }}
                        >
                          <em>Remarks from HR</em>
                        </Col>
                      </Row>
                    </>
                  ) : null}

                  {submissionDataIldp.remarks31 ? (
                    <>
                      <Row className={'border p-3'}>
                        {submissionDataIldp.remarks31
                          .split(/\r?\n/)
                          .map((l) => {
                            return (
                              <Col span={24}>
                                <span>{l}</span>
                              </Col>
                            )
                          })}
                      </Row>
                    </>
                  ) : null}
                </Col>
              </Row>
            </Card>
          </div>
          <div id='specific9i'>
            <Card style={{ marginTop: '20px' }}>
              <Row>
                <Col
                  span={24}
                  style={{
                    marginTop: '5px',
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>FUNCTIONAL/TECHNICAL (Specific to Division/Department)</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <b>Name of Program</b>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Date Taken</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  <Input value={submissionDataIldp.panel4Columns44Text}></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime15
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel4Columns44Text2}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime16
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel4Columns44Text3}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime17
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel4Columns44Text4}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime18
                    )}
                  ></Input>
                </Col>
              </Row>
            </Card>
            <Card style={{ marginTop: '20px' }}>
              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <b>
                    Additional Training Programs (Not in the List) Taken / Date
                    Taken
                  </b>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'start',
                  }}
                >
                  <b>Date Taken</b>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel4Columns44Text5}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime19
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel4Columns44Text6}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime20
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel4Columns44Text9}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime22
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[8, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <Input
                    value={submissionDataIldp.panel4Columns44Text8}
                  ></Input>
                </Col>
                <Col
                  span={12}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Input
                    value={currentTimeInSGT(
                      submissionDataIldp.panel4Columns28DateTime21
                    )}
                  ></Input>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                <Col span={12}>
                  <span>
                    <Input
                      name='signature1'
                      value={
                        submissionDataIldp.panel5Columns3PangalanatPirmangEmpleyado
                      }
                    />
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    <Input
                      name='signature2'
                      value={
                        submissionDataIldp.panel5Columns3PangalanatPirmangEmpleyado2
                      }
                    />
                  </span>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: '0' }}>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Pangalan at Pirma ng Empleyado
                    </label>
                  </span>
                </Col>
                <Col span={12}>
                  <span>
                    <label className='loginInput-label' htmlFor='fname'>
                      Pangalan at Pirma ng Superbisor
                    </label>
                  </span>
                </Col>
              </Row>
            </Card>
          </div>
        </>
      </div>
    </div>
  )
}
