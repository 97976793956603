/**
 * Title: CSCOnlineTrainingNov20.js
 * Description: This is a file that contains the CSC Online Training for November 20, 2023
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/CSCOnlineTrainingNov20.js
 **/

import { useState, useEffect, useRef } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  ActivityFiveAnswerByUserID,
  ActivityFourAnswerByUserID,
  ActivityOneAnswerByUserID,
  ActivityThreeAnswerByUserID,
  ActivityTwoAnswerByUserID,
  listActivityFourAnswers,
  listActivityOneAnswers,
  listActivityThreeAnswers,
  listActivityTwoAnswers,
  listTrainingVideos,
} from '../../api/queries'
import { deleteTrainingVideo } from '../../api/mutations'

import moment from 'moment'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  PlayCircleFilled,
} from '@ant-design/icons'
import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  Row,
  Col,
} from 'antd'

import VideoPlayer from './videoPlayer'

import './strategicPerformanceManagementSystem.css'

import ActivityQuestionModalOneSept15 from './activityQuestionModalOneSept15'
import ActivityQuestionModalTwoSept15 from './activityQuestionModalTwoSept15'
import ActivityQuestionModalThreeSept15 from './activityQuestionModalThreeSept15.js'
import listOfDepartments from '../../utilities/dept'
import ActivityQuestionModalOneNov20 from './ActivityQuestionModalOneNov20.js'
import ActivityQuestionModalTwoNov20 from './ActivityQuestionModalTwoNov20.js'
import ActivityQuestionModalThreeNov20 from './ActivityQuestionModalThreeNov20.js'
import ActivityQuestionModalFour from './activityQuestionModalFour.js'
import ActivityQuestionModalFiveNov20 from './ActivityQuestionModalFiveNov20.js'
import ActivityQuestionModalFourNov20 from './ActivityQuestionModalFourNov20.js'

const { Option } = Select

export default function CSCOnlineTrainingNov20({ userData }) {
  const user = userData
  const userInfo = userData
  const userFullName =
    userInfo?.name + ' ' + userInfo?.mname + ' ' + userInfo?.lname

  const [name, setName] = useState(userFullName)
  const [department, setDepartment] = useState(userInfo?.assignedDept)
  const [uploadedTrainingVideo, setUploadedTrainingVideo] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [videoKey, setVideoKey] = useState('')
  const [isAgree, setIsAgree] = useState(true)
  const [pageState, setPageState] = useState(null)
  const [openModal, setOpenModal] = useState(true)
  const [videoFinishWatching, setvideoFinishWatching] = useState([])
  const [isModalActivityOpen, setIsModalActivityOpen] = useState(false)
  const [progressActivity, setProgressActivity] = useState('1')
  const [isWatchedVideoOne, setIsWatchedVideoOne] = useState(false)
  const [isWatchedVideoTwo, setIsWatchedVideoTwo] = useState(false)
  const [isWatchedVideoThree, setIsWatchedVideoThree] = useState(false)
  const [isWatchedVideoFour, setIsWatchedVideoFour] = useState(false)
  const [transcriptActivity, setTranscriptActivity] = useState(
    'transcriptActivity1'
  )
  const [isInstructionModalVisible, setIsInstructionModalVisible] =
    useState(true)
  const [isCompleteMsgModalVisible, setIsCompleteMsgModalVisible] =
    useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getActivityOneAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityOneAnswerByUserID, { userID: user.id })
      )

      const itemsFetched = dataFetched.data.ActivityOneAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('2')
        } else {
          setIsWatchedVideoOne(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityTwoAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityTwoAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityTwoAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('3')
        } else {
          setIsWatchedVideoTwo(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityThreeAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityThreeAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityThreeAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('4')
        } else {
          setIsWatchedVideoThree(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityFourAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityFourAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityFourAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('5')
        } else {
          setIsWatchedVideoThree(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityFiveAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityFiveAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityFiveAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('alldone')
        } else {
          setIsWatchedVideoThree(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (progressActivity == 1) {
      setTranscriptActivity(transcriptActivity1)
    }
    if (progressActivity == 2) {
      setTranscriptActivity(transcriptActivity2)
    }
    if (progressActivity == 3) {
      setTranscriptActivity(transcriptActivity3)
    }
    if (progressActivity == 4) {
      setTranscriptActivity(transcriptActivity4)
    }
    if (progressActivity == 4) {
      setTranscriptActivity(transcriptActivity5)
    }
  }, [progressActivity])

  const fetchAllUserActivityProgress = async () => {
    setIsLoading(true)
    await getActivityOneAnswerByUserID()
    await getActivityTwoAnswerByUserID()
    await getActivityThreeAnswerByUserID()
    await getActivityFourAnswerByUserID()
    await getActivityFiveAnswerByUserID()
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAllUserActivityProgress()
  }, [])

  const englishVideos = [
    'Part 1_PLF Final.mp4',
    'Part 2_PLF Final.mp4',
    'Part 3_PLF Final.mp4',
    'Part 4_PLF Final.mp4',
    'Part 5_4 Kinds of GC_PLF Final.mp4',
  ]

  const dept = listOfDepartments()

  const transcriptActivity1 = () => {
    return (
      <>
        <p>
          We will now discuss the overview of the Philippine Legal Framework.
          So, for purposes of our discussion, we will be primarily tackling the
          following laws, but we will not be limited in our discussions. But
          please take note that these are the main branches of our discussion.
          Number one, Constitutional Law, which is the 1987 Philippine
          Constitution. Statutory Law, which is the Local Government Code of
          1991, which is also known as Republic Act No. 7160. And the Revised
          Administrative Code, or EO 292, series of 1987. By way of overview, we
          will be discussing these key concepts and their relation to each
          other. Constitutional Law, Local Autonomy, and Administrative Law. But
          first, we must define the terms that we will be discussing. So we will
          define what is a law. And by laws, we will be discussing each of these
          body of laws. Number one is the 1987 Philippine Constitution. The laws
          and jurisprudence, meaning those that come from the two houses of
          Congress and the decisions being handed down and promulgated by the
          Supreme Court. Administrative issuances from national government
          agencies. And of course, local ordinances applicable within the city.
          When we say hierarchy of laws, we refer to the application. And of
          course, when we say that the Philippine Constitution is the highest
          law of the land, it means all the laws are subordinated to it. Such
          that laws passed by Congress, if not complying within the limits
          prescribed by the 1987 Constitution, may be declared by the Supreme
          Court as unconstitutional and therefore struck down as being of no
          force in effect. Likewise, administrative issuances, those that are
          issued by the national government agencies, are merely interpretative
          or they are only issued and implemented pursuant to existing laws
          passed by the two houses of Congress. So administrative issuances are
          likewise limited in scope within the mandates of each national
          government agency. For example, the Department of the Interior and
          Local Government may issue such rules and regulations that govern the
          supervision and control of certain key functions of each local
          government unit from the province down to the barangay. Of course,
          when we talk about ordinances, they are limited in scope and
          application within each local government unit. And there are four
          major local government units. The province, the city, which may be a
          chartered city or a component city of a province, a municipality, and
          the last one is the barangay. So each one of these local government
          units have the power through their Sangguniang Panlalawigan provincial
          board Sangguniang Panglungsod city council, Sangguniang Pangbayan
          municipal council, and of course the barangay legislative board which
          are composed of the barangay kagawads. So these ordinances are limited
          in scope to the territorial jurisdiction of each LGU. So when we say
          these laws, these laws are created by its author. We refer to the
          Philippine Constitution as being a product of the expression of the
          will of the sovereign Filipino people. When we talk about Congress, we
          talk about duly elected officials, duly elected representatives of the
          people. They are voted either nationally, we have our senators, and
          each legislative district also has one district representative. Of
          course, nationally we also vote for a party-less representative, which
          may represent the marginalized sector enumerated in the 1987
          Constitution. Of course, our Supreme Court justices are appointed by
          the President. However, they are also confirmed by the Commission on
          Appointment. So there is also checks and balance in place.
          Administrative issuances, as previously adverted to, are issued by the
          national government agencies pursuant to their specific mandates of
          environment, local government, finance, social welfare, etc. And when
          we talk about ordinances, each local legislature has their own
          specific limits as defined under the local government code of the
          Philippines. So in terms of effectivity, the Philippine Constitution
          has a national application, national scope, national mandate. The same
          is true for the laws passed by the House of Representatives and the
          Senate, and the jurisprudence or the final decisions handed down by
          our Philippine Supreme Court. They are all national in scope. National
          administrative issuances are also national in scope. However, they are
          also limited by the specific mandate of the agency or the department.
          And of course, the local legislatures, for as long as they are within
          the General Welfare Clause, Article 17 of the local government code,
          and of course, they are limited within their application, and
          effectivity are limited within each territorial jurisdiction of the
          LGU. So general principles of Philippine constitutional law, these
          general principles will somehow find its way down the line when we
          talk about the author, when we talk about the effectivity, we talk
          about all of these general principles permeating each level of the
          hierarchy of laws. These are not, by the way, these principles of
          constitutional law are not a comprehensive one. These are selected
          because these will have a direct and practical effect on the daily
          duties that we do in the local government. So we enumerate them first
          and foremost. Our form of government is democratic and republican. Our
          structure of government is characterized by separation of powers. The
          powers of the national government are limited by the list of rights,
          the Bill of Rights, the constitutional rights of the citizen. And of
          course, oversight, the power of oversight is being exercised at each
          level of government and even between and among the separate but equal
          powers of each branch. So what do we mean when we say the Philippines
          is a democratic and republican state? Democratic describes how we
          elect our officials. We hold democratic elections. We vote for the
          people who we want to govern us. So how do we put them in power? We
          hold periodic national and local elections. What does it mean when we
          say that the Philippines is a republican state? Republican is a mode
          of governance where the citizens do not govern themselves directly,
          but they do it through a representative. So when we vote for people in
          power, we vote for a president, a vice president, 12 senators each
          time, a party-list representative, a district representative, mayor,
          vice mayor, city councillors. So we have a barangay chair, barangay
          councillors or barangay kagawad, SK chair and SK kagawad. All of these
          are being voted by the people directly in order to govern themselves.
          So we do not directly govern. We govern through representatives. We
          govern through the republican state. And therefore, when we say
          sovereignty resides in the people and all government authority
          emanates from them, before any president can exercise any of the
          powers vested by law and the constitution, they must enjoy the vote
          and confidence of the people. And therefore, while we exercise this
          sovereign right of suffrage, the right to vote, every six years for
          national government officials, every three years for senators and
          congressmen and local government officials, we are exercising our
          right pursuant to the sovereign authority as citizens. Separation of
          powers. What do we mean when we say separation of powers? There is a
          foremost constitutional law expert, Father Bernas, which describes our
          structure of government, the democratic and republican structure of
          government, as a system of trust and distrust. This means that while
          the sovereign power of the people put their representatives in key
          positions of power, they are doing so only temporarily. This is a
          temporary expression of trust. And therefore, when we do elect people
          in the executive, the president and the vice president, practically,
          we are giving them the mandate for six years, we are lending our
          trust, but we are not fully, we are not placing our trust wholesale,
          because we will also need to elect members of the House of Senate and
          House of Representatives who will now do their constitutional mandate
          of being a check on both the executive and the judiciary. The same
          thing with the judiciary. They are appointed by the president, but
          once appointed, they act as a check both to the legislative and
          executive departments, such that if the executive commits an act, over
          or beyond its constitutionally granted power, the judiciary, the
          courts, can issue temporary restraining orders, or can invalidate
          certain executive acts if they are issued, if they are done, if they
          are implemented in such a way that contravenes the constitution. For
          example, if the executive somehow issues an executive order which does
          not have a basis in law, then the Supreme Court can declare that
          unconstitutional, and by declaring it unconstitutional, it will lose
          the force and effect of law. The same thing with acts of the
          legislature. When a particular law is declared unconstitutional by the
          judiciary, they, at least from the time that the decision of the
          Supreme Court becomes final and executory, then it loses its force and
          effect as a national law. However, for example, if there are certain
          declarations or decisions made by the judiciary, and the legislature
          does not agree with it, they can always amend the law, or they can
          always pass a new law, which again will have a prospective effect. So
          the same thing with the executive. They can still continue to exercise
          their powers and dispense with their responsibilities, and unless and
          until the judiciary or a citizen goes to court to question that, it
          will enjoy the presumption of regularity. What are the Bill of Rights?
          This is a list of constitutional rights in Article 3 of the 1987
          Constitution, which collectively limit the power of government, and by
          government, we mean both the executive, legislative, and the
          judiciary. It also grants inviolable rights to its citizens. When we
          say inviolable, it means that these rights cannot be violated by any
          branch of government and by any local government unit. The Bill of
          Rights is a domain where government powers stop. For example, the
          right to the inviolability of a home. For example, when you are not
          committing any crime, you are well within your rights, you are well
          within your power as citizens to prevent any law enforcement agent,
          any agent of the government, from the executive to the legislative, or
          even the judiciary, you can prevent them from entering your home.
          Because our Constitution and our laws impose criminal, civil, and
          administrative liabilities on state agents who violate the fundamental
          rights of our citizens. So they will be charged, for example, with
          trespass to dwelling, they will be charged with other administrative
          sanctions which can forfeit their authority to occupy their government
          offices. So when we say rights, it's always complementary to speak of
          government duty. Because you can have a right, but if the government
          does not recognize, does not respect, then your rights will be
          rendered nugatory or without effect. But that violation will
          necessarily mean a liability on the part of the government or state
          agent that fails to respect, or fails to recognize, the citizens'
          rights. In constitutional law, we also have this oversight function.
          When we say oversight function, it can come in the form of specific
          constitutional bodies with specific mandates that have the power to
          check the rights of the citizens. For example, when we talk about the
          Civil Service Commission, the Civil Service Commission is like the HR
          of the entire government bureaucracy. When we talk about appointments,
          when we talk about transfers, when we talk about prerequisites or
          preconditions to assumptions of office, or maintaining good standing
          in any government office, we go to the Civil Service Commission, and
          their guidance, through implementing memorandum circulars, so that
          each and every act, each and every human resource action will be in
          accordance with the Constitution, and if ever, any government body,
          and when we say government instrumentality, this covers all, from the
          national government agencies to the government-owned and controlled
          corporations, to any other regulatory bodies, including local
          government units from the province down to the barangay. Anything that
          has to do with human resource action, we defer to the Civil Service
          Commission. For example, if the Civil Service Commission disapproves
          certain appointments, or it invalidates certain designations, then,
          they are well within their oversight function to implement these
          prohibitions or these allowances, as the case may be, within their
          constitutional authority of exercising a check on the human resource
          action of all conceivable government offices. We now turn to the
          Commission on Audit. In terms of allocation, expenditure, government
          accounting and auditing rules, we go to the Commission on Audit for
          guidance on how to budget, on how to allocate, on how to document
          certain expenditures. And the Commission on Audit has the authority to
          define its own scope of audit and the manner by which it conducts
          audit. Primarily, the Commission on Audit exercises its financial
          audit, but in recent years, it has also delved into or evaluated acts
          of government agencies and instrumentalities through the lens of a
          performance audit. So, the effectiveness, the necessary consequences
          of certain wasteful acts or wasteful means of allocating and using
          government resources. And by government resources, we're not just
          talking about public funds. We're talking about public property. We're
          also talking about the use of government personnel. While this may
          have a common ground with the Civil Service Commission, the Commission
          on Audit looks at the deployment or the use of government executive or
          government employee time for certain activities that are either within
          the mandate of that government agency or beyond it. We also have a
          constitutional oversight body known as the Office of the Ombudsman.
          The Office of the Ombudsman, again, is a constitutional body which
          deals with graft and corruption. So, there are several laws. The
          Charter of the Office of the Ombudsman, we have RA 6713, we have
          Republic Act 3019 that all govern the conduct or the ideal conduct of
          government officials and employees. And once there are erring
          government officials, especially on the aspect of graft and
          corruption, graft and corruption, in a nutshell, is using government
          authority for private gain. When these are done by government
          officials, then they run the risk of being run over and run after by
          the Office of the Ombudsman. Commission on Elections is another
          oversight body as defined under the 1987 Constitution, primarily
          administering laws on elections. This Constitution recognizes that all
          government power must come from the conduct of clean, honest, and
          orderly elections. And therefore, the role of the Commission on
          Elections cannot be trifled with. In fact, during a national or a
          local election, there is a shift in the hierarchy of authority.
          Whereas in non-election time, the law enforcement bodies, like the
          National Bureau of Investigation, the Philippine National Police, may
          conduct their own regular operations during election period. And the
          election periods are prescribed by law. The Commission on Elections
          may turn its mandate on, and all of these national government agencies
          are now being subordinated to the authority of the Commission on
          Elections. The Commission on Elections may deputize any of the law
          enforcement agencies to be able to secure, for example, an area of
          concern, or areas where lawless violence or election-related violence
          are rampant and therefore put several safeguards and put several
          measures to ensure that the people’s will, will be heard on the ballot
          and not be suppressed by terrorism or other acts of lawless violence
        </p>
      </>
    )
  }

  const transcriptActivity2 = () => {
    return (
      <>
        <p>
          Executive oversight. So, when we talk about constitutional oversight,
          we generally mean these four, the Civil Service Commission, Commission
          on Audit, Office of the Ombudsman, and Commission on Elections. But
          within the executive department, there are also several oversight
          bodies, primarily the Office of the President, the Department of
          Budget and Management, the Department of the Interior and Local
          Government, and the Department of Finance. Are these the only national
          government agencies? No, but these executive departments are a big
          brother making sure that all the younger brothers or all the offices
          under its supervision are following the law because that is a primary
          function of the executive, to make sure that everyone do their
          responsibilities within the bounds of law and therefore, the oversight
          power of the President cannot extend beyond what is provided for by
          the law. And therefore, when we talk about the power of the DBM in
          terms of budgeting and managing of local government budgets, we go to
          the General Appropriations Act. When we talk about the oversight
          function of the Department of the DILG, we are talking about primarily
          the four corners of the local government code. And of course, with the
          Department of Finance, we talk about the limits of budgetary proposals
          Congress and the limits of moving specific line-item budgets within
          each department, within each government agency or instrumentality and
          not crossing over certain executive lines or mandates. We now go to
          the legislative oversight. We know that Congress, and when we talk
          about Congress we talk about the House of the Senate and House of
          Representatives, they are not only authorized to make laws or
          promulgate Republic Acts, they also conduct what we call
          investigations in aid of legislation. So, what are covered by
          investigations in aid of legislation? Essentially everything. So, when
          we talk about the power to make laws, this is coextensive with the
          power to investigate any and all matters that affect public interest.
          So, this is your, the equivalent of the general welfare clause of the
          local government code. We're now talking of the national mandate of
          the Senate and the House of Representatives. So, anything and
          everything that can promote the good, the common good, Congress can
          make anything and everything that can prevent harm to the people that
          is well within the law-making power of Congress, also the
          investigation powers of Congress. The Constitution gives the power of
          the purse, or power of funding, to the two houses of Congress. In
          fact, the worst-case scenario for any government agency is that, based
          on the exercise of this legislative power of oversight in terms of
          funding, Congress may defund, meaning withdraw, all funding from a
          specific government agency, which they think are not performing their
          given mandate. And when we say mandate, these are mandates pursuant to
          laws passed by Congress. Also, this also comes hand in hand with the
          lawmaking authority. For example, Congress may reorganize certain
          government agencies, bureaus, or even government-owned and controlled
          corporations, or even create new ones, dissolving previous government
          instrumentalities pursuant to their lawmaking authority. Another power
          is outright called congressional oversight. For example, there are
          certain laws that may from time to time need adjustment or may need
          further amendments in order to respond to the exigencies of the
          situation on the ground. And therefore, certain laws you will notice
          will contain congressional oversight. These provisions contained in
          the law provides, for example, in terms of mining laws, there is a
          congressional oversight on the mining industry or certain
          congressional oversight committees on certain commercial or business
          activities, when the Congress, when the lawmakers feel that there are
          certain activities or industries that may need closer monitoring or
          closer closer or periodic checks. And we're talking about, for
          example, joint congressional oversight, so where members of the House
          of Representatives and the Senate may, from time to time, convene,
          sometimes annually, sometimes every three years, for example, maybe to
          adjust penalties for violation of laws or may adjust specific
          provisions of laws to cover certain activities that were not covered
          before. For example, certain commercial activities that were not
          covered under old laws, for example, online commercial activities,
          they can either amend existing laws to cover those online activities
          or create new laws or create new governmental bodies to address
          certain gaps or certain emergencies. Technically, judicial oversight
          is a generic term for the exercise of the Supreme Court's check, the
          power to check and balance the other two co-equal branches of
          government, the executive and the legislative. For example, if there
          are violations of penal laws, the court, from the lowest courts up
          until the Supreme Court, they exercise criminal jurisdiction over
          these types of cases. We mentioned before that for acts of the
          legislature or acts of the executive, the court may, for example,
          issue injunctions or restraining orders to prevent the damage to
          property, damage to liberty, or limiting the freedom of certain
          people, for example, if they are being incarcerated or imprisoned. So,
          these are, the court can always be a refuge for what the citizens may
          perceive as abuse of government authority by the executive or the
          legislative. Declaratory relief primarily pertains to the power of the
          court to declare certain laws or certain provision of laws, if not in
          its entirety, declaring them unconstitutional or not in accordance
          with the constitution and therefore being struck down as without force
          and effect of law. And of course, when we talk about the last
          oversight authority, which is the authority designed for
          controversies. We're not just talking of controversies involving
          government instrumentalities, but we're also talking about
          controversies between and among the citizens. And these cases may
          include criminal cases, may include special proceedings, may include
          civil cases or those, for example, involving land, involving property,
          involving debt collection, et cetera. So here is in a nutshell all the
          constitutional principles that we discussed. Democratic and
          Republican, the principle of separation of powers, the concept of
          checks and balance, the Bill of Rights, and constitutional oversight
          functions. So, these are the 18 articles of the Constitution. You may
          want to review them cursorily, in particular Article 10, which
          pertains to local government, and Article 11, accountability of public
          officers and employees. After this, we will now go to the principles
          of local autonomy as it is discussed or embodied in our local
          government code. When we talk about principles of local autonomy, we
          refer to basic constitutional principles embodied in the 1987
          Constitution as well as the enfleshment of these principles in RA
          7160, otherwise known as the Local Government Code. So, we look at
          this provision in Article 10 on the subject of local government code
          in section 3, it provides for the congress passing of the local
          government code which they did in the 1990s, but primarily defining
          and putting the structures for the system of decentralization and by
          that we mean it is the allocation of powers, responsibilities and
          resources. While our laws, the constitution, and our laws provide for
          the qualification, the election and appointment and removal of certain
          government officials, there are certain matters that are given more
          specific guidance and limitations under our local government code. And
          for example, the general welfare clause, Section 16, this is a very
          long provision, but essentially it tells us that each local government
          unit in the province, city, municipality, and barangay, within its
          territorial jurisdiction can number one pass laws, and by laws, we
          mean ordinances that promote the common good. Number two, prevent any
          harm to the people and this is embodied in these provisions. To
          preserve and enrich the culture, health and safety, balanced ecology,
          development of scientific, and technological capabilities. It has also
          the mandate to improve public morals, enhance economic prosperity and
          social justice, promote full employment among organized and
          unorganized labor, maintaining peace and order, including traffic, and
          preserve the comfort and convenience of the inhabitants within each
          territorial jurisdiction. In Article 10, Section 2, the territorial
          and political subdivisions shall enjoy local autonomy and by this, we
          mean two forms of decentralization. Decentralization of power and
          decentralization of administration. Essentially the constitution
          itself provides for the abdication of political power from the
          national government down to the autonomous local government units. So,
          it's abdication of political power and delegation of specific
          administrative powers to the LGUs in accordance with law, and
          primarily when we say in accordance with law we talk about the local
          government code. Another key concept is that the office of the
          president, the executive branch primarily exercises general
          supervision over local government units and that is what we mean by
          local autonomy. The president’s power is limited to keeping them in
          line and within the limits of their mandates primarily the local
          government code. If and when they exceed their mandates or worse
          violate the provisions of the local government code, then it is
          incumbent upon the office of the president to hold them accountable
          through established means of making them accountable whether
          criminally, civilly or administratively. We discussed the general
          supervision of the provinces with respect to its component cities and
          municipalities and of course the general supervision of cities and
          municipalities with respect to their component barangays. And of
          course, all of these provisions pertaining to general supervision
          primarily means that that all the component units under its general
          jurisdiction should follow the scope of the law and of course the
          scope and mandate provided by the Constitution. So we must distinguish
          between the power of control and the power of supervision. The
          Constitution said, or provides, that the President exercises only the
          power of supervision over all local government units. The Office of
          the President does not have, or does not exercise the power of control
          over LGUs. The Office of the President, however, has the power of
          control over all national government agencies and we can see this in
          our government structure each and every department from the Department
          of foreign affairs, Social Welfare, trade and industry, interior and
          local Government all of these are appointed by the president and
          therefore they exercise functions pursuant to the mandate of the
          office of the President. And therefore, each Cabinet Secretary is by
          law the alter ego of the President. Any act of each Secretary is
          deemed an act of the President unless and until the President counter
          or withdraws or by exercising its power of control, supersedes or
          replaces the decision of the Secretary with the decision of the office
          of the President. And that is essentially the power of control, the
          power of the superior officer to substitute its judgment, its decision
          for that of its subordinate officer, the president in relation to the
          secretary, and down the line. The power of supervision is essentially
          keeping the subordinate officers in line or towing the lines, or the
          limitations provided for by law. So, the President in relation to the
          local government units can only make these local chief executives
          accountable for not following the law but it cannot substitute its
          decision or action, the President cannot substitute the decision or
          action of each local chief executive.
        </p>
      </>
    )
  }

  const transcriptActivity3 = () => {
    return (
      <>
        <p>
          We're still in local government, but we're now talking about
          administrative law. So, we will talk about general principles and
          their application. Again, we refer to the power of the president to
          exercise general supervision over local government. And somewhere
          earlier in the Constitution, there are provisions which provide for
          administrative control of the president overall national government
          agencies, over all government-owned and controlled corporations.
          General principles, these are principles of penalties, where we talk
          about holding public officers and employees accountable. But these
          general principles also apply to ordinary citizens. There is a
          principle in law, ”nullum crimen, nullum poena sine lege,” meaning
          there is no crime when there is no law. And when we say that, it means
          that the penal law must define the crime, its elements, and must
          impose a penalty for violation. So when there is a law which defines a
          crime but does not impose a penalty, then that means it is not a crime
          at all. So, each and every penal law must have, number one, a
          definition of crime, number two, an imposition or provision for its
          penalty. When the laws provide for penalties, these are generally
          considered criminal penalties, meaning it can only be imposed by a
          court, and it is only imposed by the court after a criminal trial. In
          our criminal law jurisdiction, we have certain steps or procedures
          that we must follow before a criminal penalty can be imposed.
          Normally, we go through the process of reporting to law enforcement
          agents like the Philippine National Police, National Bureau of
          Investigation, who conduct essentially a fact-finding investigation.
          After the fact-finding investigation, or criminal investigation by the
          police, then all the evidence says, or pieces of evidence are
          forwarded to the Office of the Public Prosecutor, where a preliminary
          investigation may then be undertaken. And there, whether the accused
          or the respondent is a government employee or not, they will be given
          an opportunity to be heard, whether by themselves or by counsel, but
          preferably, through their lawyers, because they now face the
          possibility of having their freedom limited or taken away after a
          determination of probable cause in the preliminary investigation and a
          determination of proof beyond reasonable doubt in the criminal trial.
          Penalties under administrative issuances can only be imposed if they
          are in accordance with the law. So certain laws must be checked if
          they provide for, number one, power to each administrative agency to
          define the penalties or even to define certain violations. For
          example, violations of the Clean Air Act, violation of the Ecological
          Solid Waste Management Law, and other laws providing for civil,
          criminal, and administrative penalties. Another limitation for
          administrative penalties is that they must be contained in an
          issuance, and not just contained in an issuance. These issuances,
          these, for example, administrative orders, department orders,
          executive orders, must be published in newspapers of general
          circulation and two copies must be deposited, hard copies deposited in
          the Office of the National Administrative Register, or ONAR, in the UP
          College of Law. Why UP College of Law? Because the law provides that
          it is the official repository of issuances, especially those
          administrative issuances that impose penalties or the law provides
          that these administrative issuances must be published and deposited in
          the owner for them to affect the rights of third persons, meaning the
          general public. Administrative disciplinary jurisdiction, we've talked
          about the oversight for example of the Office of the Ombudsman, Office
          of the Civil Service Commission, or even the Commission on Audit, and
          of course, the Office of the President where all of these, in
          particular the Civil Service Commission, Office of the Ombudsman, and
          Office of the President, may acquire or exercise jurisdiction in
          administrative disciplinary cases, holding officials and employees in
          the government responsible for their illegal actions or those that
          violate the rights of citizens and other officials. For example, if I
          am employed, I'm an employee or officer in the local government unit,
          any private citizen may file a case against me in the Civil Service
          Commission, Office of the Ombudsman, or even the Office of the
          President. But the limitation there, whoever acquires jurisdiction
          first must act on it to the exclusion of the other agencies like the
          CSC or the Ombudsman having concurrent administrative disciplinary
          jurisdiction. And for example, within the LGU of Quezon City, cases
          may also be filed within Quezon City and the department must conduct a
          fact-finding. investigation. And after that, if probable cause is
          found, and by probable cause we only mean that there is a likelihood
          or most probably an administrative violation has been committed and
          that most probably the person being held accountable for or the person
          complained against is the perpetrator of that administrative
          violation. After that it will be submitted to the city legal for the
          conduct of a formal investigation but in each and every stage any
          respondent is by law and by constitutional mandate be given notice of
          the charges against him or her and an opportunity to be heard either
          through hearings, face-to-face or through pleadings, written
          opportunities to be heard. The last concept we will discuss is the
          concept of administrative, civil and criminal liabilities. Normally
          when a public official or a public employee violates any law, it is
          automatic that he incurs three separate liabilities and we're talking
          about government officials, government employees. They may be
          subjected to administrative, civil and criminal liabilities and when
          we talk about double jeopardy, meaning the prohibition from being
          prosecuted for the same act, twice in a court of law that only applies
          to criminal liabilities. So, any official, any employee in the
          government cannot invoke double jeopardy. For example, if a criminal
          case has already been filed against them and simultaneously
          administrative and civil cases are filed, they cannot invoke double
          jeopardy to move for the dismissal of the administrative and civil
          case because these are separate cases. And it is possible to hold
          public officials and employees liable for administrative penalties and
          civil damages resulting from the same acts that they are being
          criminally prosecuted for. And there is no prohibition for holding
          them responsible for all three liabilities. So, when we talk about
          cases being filed against any government official or employee, we talk
          about evidence. And in administrative disciplinary proceedings, we
          only talk about substantial evidence. Substantial evidence. In law,
          it's defined as from a certain set of allegations, you must be able to
          point to specific facts or circumstances or documents to support that
          allegation. If you allege, for example, that a certain official
          pocketed or received kickbacks from suppliers, you must be able to
          supply the evidence to support or the evidence to prove that
          allegation, either the physical act of delivering money or, for
          example, if the money was transferred through digital means or through
          bank transfers, you must be able to support that allegation. Or even,
          for example, in cases of... sexual harassment, the testimonial
          evidence of the complainant will be enough to support the allegations
          of abuse of authority or even acts of sexual, physical, verbal abuse.
          So, when we say the truth in administrative disciplinary proceedings,
          must be based on substantial evidence, we must be able to point to a
          specific statement in the complaint affidavit or incident report that
          will support a finding of conviction in administrative and
          disciplinary proceedings. In any administrative and disciplinary
          proceeding, it must always start with an Incident Report. However, in
          certain cases, there are not incident reports, but complaints, whether
          filed through the office of the city mayor, city administrator, even
          through the presidential hotline 8888 and being forwarded by the
          office of the president for our appropriate action here at the local
          government unit. So, either a complaint or report and such reports
          must contain specific details for the evaluating official to be able
          to say, oh, assuming these allegations are true, there is a probable
          violation of existing laws, existing civil service prohibited acts,
          and therefore, there is a probable cause to proceed with a formal
          investigation. So essentially, from the complaint and the incident
          report, the public official tasked to conduct the fact-finding
          investigation must validate the veracity, the truthfulness of such
          allegations or such complaints. So, any of the means available,
          whether these acts are based or proven by documents, or these acts can
          be substantiated by statements or disclosures of complainants and
          other witnesses, they must be validated. Of course, we must not forget
          that even at the fact-finding level, we can get the side of the
          respondent. There are certain instances where the report, the incident
          report, or the complaint does not, even if assumed to be true, does
          not constitute any administrative offense. And therefore, the official
          must document that through a fact-finding report saying that the
          allegations in the complaint or the incident report, even if we assume
          that they are true, do not constitute a violation of civil service
          laws or any other laws for that matter. And therefore, that official
          can, based on the report, validation report or fact-finding report,
          can dismiss the case outright. However, if, okay, upon, reading of the
          complaint or the report, there may be a need for the respondent either
          to controvert what is contained in that report, then you must give
          that opportunity to the respondent. And by law, in practical
          application, when the investigating, the fact-finding investigator,
          the fact-finding evaluator, what they must do is assume that the
          allegations on the complaint and the report are true and assume that
          the explanation of the respondent, all the pieces of information there
          are also true. Weighing them now, does that give you the impression?
          And the legal standard here is always the mind of a reasonable person.
          Taking both these sets of allegations, denials, does it leave a doubt,
          or does it tilt, for example, in favor of the complainant or in favor
          of the respondent? And if it does tilt in favor of the complaint, then
          the officer, the evaluator, must recommend in this fact-finding report
          the conduct of a formal investigation. This does not mean that the
          respondent is already a judge guilty. It just means that the validator
          or the officer has found probable cause. Again, not yet a final, only
          a probability, a high probability that the respondent may be held
          responsible. Because the next stage, the formal investigation will
          still given opportunity to the respondent to either further refute the
          allegations or even adduce or offer additional evidence to support his
          or her defense. Here we are talking about, for both the complaint and
          the defense of substantial evidence, in substantial evidence in law is
          defined as such amount of relevant evidence as a reasonable mind will
          accept as adequate to support a conclusion or allegation. And
          therefore, whether we talk about complaints, whether we talk about
          defenses in administrative disciplinary procedure, we know that
          documentation is key. Whether you are defending yourself in an
          administrative investigation or just doing your day-to-day duty,
          again, we cannot overemphasize that documentation is key. In every
          memo, in every report, we must include timely information, relevant
          information, factual information, and therefore, we cannot leave
          anything out. We must disclose every piece of information that comes
          to our office that falls on our desk. And the reason is we have to
          dispense with the responsibility that is being given to us as officers
          of our government as State agents, whether we are agents of the local
          government unit or state agents of the national government, or even
          government-owned and controlled corporations, we have these basic
          four. These are not the only duties of civil servants, but these, in
          my mind, are the most important.
        </p>
      </>
    )
  }

  const transcriptActivity4 = () => {
    return (
      <>
        <p>
          When we talk about the duty of fidelity, it is not just being faithful
          to the law, it is also being faithful to our office. And the law
          provides that we must avoid any situation of conflict of interest.
          Remember, we define graft and corruption as using our public office
          for private gain. Again, this is similar or almost the same thing.
          When our personal interests are put before our public duties, then we
          have a conflict of interest. And when we talk about conflict of
          interest, the law covers not just actual situations of conflict of
          interest, but also future or possible Conflicts of interest. Case in
          point, for example, nepotism under civil service rules are limited by
          certain degrees of consanguinity and affinity. However, while you may
          not be prosecuted or held responsible for violating the civil service
          rules on nepotism, pleasing a relative, however distant that relative
          may be in the same office where you work, may put you in a
          conflict-of-interest position. For example, if you have a relative in
          your office, and you are the head of the office or not head of a
          section or head of a division, you are, by law, authorized or mandated
          to set the conditions of work, authorize overtime, deploy, or make
          assignments to their posts. And therefore, if you favor, or even if
          you are perceived to even favoring your distant relative in terms of
          the conditions of work, assignments, then you are exposing yourself to
          a possible conflict of interest situation. Number two duty is the duty
          of confidentiality. Even prior to the passage of the Data Privacy Act,
          government officials have, under civil service rules, under the
          Constitution, mandated to avoid undue disclosure of personal
          information. And with the passage of the Data Privacy Act, we are
          given another prohibition to avoid undue disclosure of sensitive
          personal information. What are sensitive personal information? Health
          records, birth records, marriage, other information that would
          normally be required, for example, in opening a bank account. Because
          such information, like, for example, mother's maiden name, complete
          maiden name, father's middle name, and other information that could
          reasonably, the disclosure of which could reasonably result in the
          privation or identity theft of that person, and therefore, may cause
          such pecuniary or even reputational damage to the person or the
          citizen who has a right to privacy and who has a right to protect his
          or her personal information or, more importantly, sensitive personal
          information. When we talk about the duty of professionalism, that
          means we must dispense our mandate without regard to, number one,
          personal relations. So, we are always called upon as civil servants to
          dispense the duties of our offices fairly and without any other
          consideration other than the merits of each and every person and the
          merits of each and every case, each and every dispute, each and every
          question or matter that comes to our attention and to our mandate. So,
          R.A 6713 provides for the ethical and professional standards of all
          government officials and employee, it may do you go to again review
          that. And, of course, take it to heart. Because ignorance of the law
          excuses no one. Even if you don't know each and every duty, you will
          still be held to the same higher standard than any private citizen.
          Because as civil servants, as officers of the law, as officers of the
          local government unit, you will be held accountable for a higher
          standards. Those provided for by the Constitution, the laws, and the
          civil service rules and regulations. Of course, it goes without saying
          the duty of honesty. And, I think honesty may encompass all the other
          duties. Fidelity, confidentiality, and professionalism. Honesty also
          goes into the prohibition of using our public mandate or public office
          for private gain. Good faith dictates that we exercise our duties
          fairly regardless of whoever is it that are asking us to act on
          certain disputes or certain matters. Okay? Now we discuss the nature
          of legal liabilities. In terms of criminal, there is only one type.
          Personal. You can be held personally liable for criminal acts if you
          did it voluntarily or willfully. You can also be held criminally
          liable for negligent acts or those acts which require you to have
          exercised a certain degree of prudence, certain degree of care, but
          you have failed to do so, then those are considered negligent acts and
          some negligent acts are considered illegal acts or felonious acts and
          may subject you to criminal liabilities and penalties like
          imprisonment or criminal fines. The nature of legal liabilities,
          another type is administrative, but administrative and civil
          liabilities can extend not just the personal, but can extend beyond
          the personal to the institutional. Okay? What do we mean? For example,
          if you are an employee of the local government of Quezon City, okay,
          administratively, you violated certain civil service rules through any
          willful or negligent act, your willful and negligent act will extend
          to your immediate superiors and sometimes, it will extend to the head
          of your agency. In our case, in the local government, it may extend to
          as high up as the mayor and the vice mayor. And therefore, you must
          always consider this because we represent our local government unit.
          Each and every one of us represent our mayor and vice mayor to the
          extent that if we willfully do something illegal or if we fail to
          exercise due diligence and due care in the performance of our duties
          and responsibilities in accordance with law, then our entire city may
          be held accountable. The same thing, okay? In civil liability, while
          you, by doing the willful and negligent or negligent illegal acts, you
          may be personally held liable to pay damages, the city may also be
          held liable for damages for your acts. And therefore, the necessary
          consequence of that, okay, if your superiors are administratively held
          liable, and in fact, the institution, the city, is held liable, then
          you will also face administrative and disciplinary proceedings within
          the LGU. And therefore, it may also result in the city filing criminal
          charges against you so that it can dissociate or meaning it can save
          itself. Meaning, illegal acts are never sanctioned by law or even by
          higher officials. And therefore, if they can prove that they had no
          hand in that, then it will stay in the personal. Or even, if you did
          violate an existing law, then that violation will result in criminal
          prosecution or penalties and it will not go up to the superior
          officers or even the city. Okay? So, these are just an illustration of
          the possible consequences or legal liabilities. Okay? And of course,
          in terms of holding people responsible, the possibility of these legal
          liabilities, for example, for administrative liability, you may be
          held responsible for payment of fines or suspension or revocation of
          your license to exercise your profession. In criminal liabilities, you
          can be imprisoned. You can be a judge to be, you can be ordered to pay
          fines. And you can be imposed the accessory penalties of either
          temporary or perpetual disqualification from holding public office.
          So, that's like a death sentence from holding public office, whether
          appointive or elective. Okay? And of course, when we talk about civil
          liability, we're talking about just financial responsibility.
          Primarily, or most of the time, being paid to persons who have
          experienced injury or suffered damages as a result of your willful or
          negligent act. So, the quantum of evidence for each type of liability
          is also different. For administrative liability, specifically in
          administrative and disciplinary proceedings, we only need to offer and
          submit substantial evidence to prove our complaint or to prove our
          defense. In criminal prosecutions, okay, the courts, the judge, must
          make sure that it has received substantial, oh sorry, proof beyond a
          reasonable doubt, okay, before any judgment of conviction can be
          handed down against any criminal accused. Okay? In civil liability,
          the quantum of evidence required is preponderance of evidence. Here,
          preponderance of evidence means that between the defendant and the
          complainant, okay, the judge will make an evaluation and decision
          which party has submitted more relevant evidence, more competent
          evidence, and more believable evidence. This is not a question of one
          party having more witnesses than the other. Even if a party only
          presents a single witness, and sometimes the witness is the
          complainant himself or herself, if it outweighs the allegations or
          even the evidence of the defendant, then that party can prevail. It's
          all about the quality of the evidence, and it goes to the probative
          value, believability, the credibility of the person making the
          statements, or even the credibility of official records or other
          documents, whether commercial or governmental records. So, when we
          talk about procedural due process, we talk about it being embedded in
          each of these types of jurisdiction, administrative, criminal, and
          civil jurisdiction. Let's start with administrative liability. For
          administrative disciplinary proceedings, there are essentially two
          stages, the fact-finding and the formal investigation. Fact-finding
          essentially is number one, receiving the report of the complaint,
          number two, studying and evaluating it, and if the complaint or the
          report is sufficient in form and substance, maybe then it can be
          referred to the respondent, the person being complained to, for his or
          her explanation. And after that, the fourth step is to conclude with a
          fact-finding investigation report. And that report will either contain
          a finding that there is probable cause to proceed, or if there is no
          probable cause to proceed, then it will be dismissed outright for lack
          of basis in law or in fact. But if the evaluator, the investigating
          official, the fact-finding investigator finds that there is probable
          cause to proceed, with a formal investigation, then it will recommend.
          In our case, in the city, in Quezon City, it will be forwarded to the
          Office of the City Legal, which will then conduct a formal
          investigation. But in both the fact-finding and the formal
          investigation, the respondent will be given an opportunity to explain
          his or her side, produce or offer documentary evidence, or produce or
          offer testimonial evidence from his or her own witnesses to support
          his or her defense. If we go to the criminal liability, the
          fact-finding part is being done by law enforcement agents, like the
          Philippine National Police, the National Bureau of Investigation, or
          sometimes there is also a fact-finding by administrative bodies, for
          example, oversight functions, finding a violation, and then it will be
          forwarded to the preliminary investigation stage or the public
          prosecutor, the Office of the City Fiscal, where they will be
          conducting the process of a preliminary investigation. The complaint
          will be given to the respondent, will be asked to submit a
          counter-affidavit. After that, the complainant can be asked to submit
          a reply and then the respondent will be asked to submit a rejoinder.
          After that, the public prosecutor may or may not schedule a what we
          call a clarificatory hearing where the investigating prosecutor can
          ask questions to the complainant and to the respondent based on their
          submissions or any other matter that is related to their submission,
          whether these are documents or testimonies of their witnesses. And
          after that, it will be submitted for resolution and then the fiscal
          will now write a report in the form of a resolution. The resolution
          will either say that there is probable cause to proceed to the next
          level, which is the criminal trial, or it can outright dismiss the
          complaint. But if it does find a probable cause to proceed with the
          criminal prosecution, then it will recommend part of the determination
          is the recommendation for bail. So, either the prosecutor may
          recommend that amount of bail be posted for the provisional liberty of
          the accused. And of course, in the criminal trial, then the judge will
          hear both sides. Normally, the prosecution will present its evidence
          first, then the defense, and then the court will decide. If the
          prosecutor has convincingly proved all the elements of the crime or
          the quantum of evidence required, which is proved beyond reasonable
          doubt, has been satisfied by the prosecution. Of course, in civil
          liability, the two stages will be number one, demand, normally, in a
          written demand that is received by the defendant. And of course, if
          the complainant is not satisfied with the reply or with the
          performance of the defendant, then they will go through the process of
          a civil trial to prove the damages or to dispute the damages being
          claimed by the complainant. For our purposes in our city government,
          there we need to focus on the administrative due process. So, what do
          we mean by administrative due process? There are four essential
          requisites, okay, but essentially contained in just two concepts,
          notice and opportunity to be heard. So, number one, in administrative
          due process, we know that there are two stages, fact-finding
          investigation, formal investigation. In a fact-finding investigation,
          you must be notified that there is a complaint against you. If the
          incident report is not available or if the complaint is available, it
          will be a copy, should be given to you that there is in fact a
          complaint being lodged against you, okay. So, the administrative due
          process standards and requirements must be satisfied and meaning any
          respondent in any administrative complaint must be given a real
          opportunity to be heard. Keeping in mind, the worst case scenario in
          administrative proceedings is dismissal from the service. So, the law
          in the constitution provides that each respondent, even as early as
          the fact-finding investigation, may be assisted by counsel or a
          lawyer. And also given the opportunity to present witnesses or pieces
          of evidence in one's favor or to be able to present a credible or
          formidable defense. Number three, who should hear the case? Okay, and
          now we're moving to, from the fact-finding investigation, we only have
          a person presumably and ideally an impartial evaluator of the
          complaint or incident report. But, when we transcend that part,
          meaning the evaluator has determined that there is probable cause to
          proceed with the formal investigation, then in the case of the city,
          it will be forwarded to the office of the city legal. But, okay, let
          us keep in mind that the law provides or the constitution requires an
          impartial person or impartial tribunal vested with competent
          jurisdiction to evaluate the respondent's evidence. Meaning, okay, in
          the formal investigation, we either constitute a panel or we
          constitute a person, a hearing officer for the formal investigation.
          And the law says that that person must be an impartial person or that
          panel must be an impartial set of persons. Normally, there are
          attorneys or legal officers within the city legal that conduct these
          formal investigations. Okay? But if you know of any personal or
          professional relations between the investigating officer and the
          complainant or even the respondent, that may affect the impartial
          adjudication of this matter, you can raise that in either your
          pleadings or in the minutes of the meeting. So, the fourth requirement
          for administrative due process is that the final act of such impartial
          panel or person must be reduced in writing, in a form of a written
          decision. And such written decision must contain the facts in the law
          relied upon by the panel or by the investigating official in either a
          finding of no fault or dismissing the case against the respondent or
          finding substantial evidence to support a guilty verdict. Guilty
          meaning the person complained of is actually guilty of willful acts or
          negligent acts in relation to his or her own functions. And therefore,
          the requirement of the law that this decision be reduced into writing
          is still part of the due process rights of respondents because if and
          when the person complained of is not satisfied with the findings of
          this tribunal then such person can go to the higher authority
          sometimes it is the courts and sometimes in certain cases the office
          of the president or you must check where the next administrative
          remedy must come from because part of the duty of any person who is
          not satisfied with any administrative or executive determination is
          that person must exhaust all administrative remedies prior to
          resorting or going to court to question such an action or decision. So
          if you fail to do that then the courts will dismiss your case for
          failure to abide by the conditions preceding for your case to proceed
          and therefore you will be left with a dismissal of your appeal or your
          even your motion for reconsideration and therefore when we talk about
          administrative due process we talk about all these four elements but
          essentially as I've said, essentially these requirements these four
          requirements can be reduced into two. Due process means number one,
          notice of the facts and circumstances being alleged against you and
          notice of the law that you are supposed to have violated. So that's
          the notice part. Opportunity to be heard whether by yourself or
          through council whether through the presentation of your own testimony
          or the testimony of your witnesses or the presentation of your
          documents and whether it is oral defense or written defense through
          pleadings that is covered by administrative due process and of course
          not to forget even in administrative proceedings you will have or you
          can invoke your right to a council you can bring your own council the
          lawyer of your choice for you to be able to present your defense and
          you can be guided by a person who is well versed in the field of law,
          especially in administrative and disciplinary proceedings and I think
          we can end with that if you have questions, you can send them through
          our HRMD Facebook page or through our email addresses
          (LD.HRMD@quezoncity.gov.ph) so, thank you and good day!
        </p>
      </>
    )
  }

  const transcriptActivity5 = () => {
    return (
      <p>
        Memorandum Circulars A Memorandum Circular (MC) is a type of official
        document used for communication or conveying information, instructions,
        or decisions to officials and employees and the issuance of policies,
        guidelines, or directives within a government bureaucracy. Memorandum
        Circulars are typically issued by the head of a government agency, such
        as a department secretary or agency director, and are circulated
        internally within the organization. They may also be disseminated to
        other relevant government agencies or stakeholders, depending on the
        nature of the information or directives contained in the circular. The
        content of a Memorandum Circular can vary widely, covering a range of
        topics such as organizational changes, procedural updates, policy
        announcements, or specific instructions on how to implement government
        programs or initiatives. The issuance of Memorandum Circulars helps
        ensure that important information and instructions are communicated
        efficiently and consistently throughout the government structure. The
        format and content of Memorandum Circulars may vary depending on the
        organization or government entity, but they generally include details
        such as the issuing authority, the date of issuance, a subject or title,
        and the specific instructions or information being conveyed. Memorandum
        Circulars are important for maintaining consistency and ensuring that
        all relevant offices or individuals are informed about and adhere to
        specific policies or directives. Executive Orders An Executive Order
        (EO) is a directive issued by the President of the Philippines to manage
        and govern the operations of the executive branch of the government.
        Similar to the concept in other countries, executive orders in the
        Philippines are a form of presidential directive providing rules of a
        general or permanent character in implementation or execution of
        constitutional or statutory powers, or has the force of law and is used
        to implement laws, manage government agencies, and address various
        administrative matters. The authority for issuing executive orders in
        the Philippines is derived from the Philippine Constitution, which
        grants the President the power to issue executive orders for the
        faithful execution of laws. EOs cover a wide range of issues, including
        the organization and functions of government agencies, the
        implementation of policies, and the coordination of executive branch
        activities. While executive orders derive their authority from the
        President's constitutional powers, they must still be consistent with
        existing laws and must not go beyond the powers granted to the President
        by the Constitution or by statutes. The legality and constitutionality
        of executive orders in the Philippines can be subject to judicial
        review. If an executive order is found to be inconsistent with the
        Constitution or existing laws, it may be declared invalid by the courts.
        In certain situations, such as during a state of emergency, the
        President may issue executive orders to address urgent and critical
        matters. However, the use of emergency powers is subject to
        constitutional limitations. Executive Orders issued by the President are
        typically published in the Official Gazette or other official
        publications to ensure transparency and provide public notice. The
        format includes the title, preamble which provides context and
        justification for the issuance of the EO. It may explain the reasons,
        legal basis and considerations that led to the executive action. Next is
        the body which contains the main content, including specific directives,
        orders, or provisions. It is organized into numbered sections or
        articles for clarity. Then it should be signed by the President with
        full name, title and the date of issuance. Memorandum A memorandum is a
        written document used for communication within an organization or
        government agency. It serves as a formal means of conveying information,
        instructions, or decisions to officials and employees within the
        government bureaucracy. Memoranda are commonly used for internal
        communication, facilitating the exchange of information, and documenting
        important decisions or policies. This provides a written record of
        decisions, policies, and communications, which may contain specific
        directives that officials and employees are expected to follow and can
        be referred to later if needed. Memoranda are frequently used for
        routine matters such as policy updates, procedural changes,
        announcements, or reminders. They help ensure that all relevant
        individuals are informed of important developments. This document
        facilitates coordination and information sharing among different units
        or sections within the same government agency and may convey details
        about inter-departmental activities, collaborations, or updates. This
        also serves as a written guide for officials and employees on how to
        carry out tasks or respond to certain situations. Depending on the
        nature of the information, memoranda may be marked as confidential or
        restricted to certain individuals within the organization. The format of
        a memorandum typically includes a heading with details such as the date,
        to whom it is addressed, from whom it is sent, and a subject line. The
        body of the memorandum contains the main content, which may include
        instructions, announcements, or other relevant information. It is also
        important this would be consistent with higher-level laws, rules, and
        regulations governing the civil service and government operations in the
        Philippines. Office Orders Office Order refers to official directives
        issued by the head or authorized personnel within a specific government
        office or agency. These orders are internal documents intended for the
        guidance and implementation of policies or procedures within that
        particular office. The purpose of office orders includes its utilization
        in the internal communication within a government office or agency. This
        conveys information, instructions, or announcements to the officials and
        employees of that particular office. This may be issued to implement
        specific policies or procedures within the office that could relate to
        administrative matters, work protocols, office hours or other internal
        regulations. If there are changes in the organizational structure,
        assignments, or responsibilities within a government office, an office
        order may be issued by the head of the agency to communicate the
        changes. Any administrative matters, such as changes in office hours,
        holidays, or leave policies specific to that office, may be communicated
        through an Office Order. To add, If an office is organizing events,
        workshops, training sessions, or other activities, the details and
        guidelines for participation may be communicated through an Office
        Order. Office orders may further be used to facilitate coordination and
        collaboration among different units or sections within the same office.
        They can outline procedures for inter-office communication or joint
        projects. These are commonly used for sending reminders, announcements,
        or important information to the office's staff and helps maintain a
        well-informed and organized working environment. The format and content
        can also vary between government offices but they typically include a
        heading, a clear statement of the directive or information, the date of
        issuance, and the signature of the issuing authority. Office Orders are
        not published in official gazettes and are generally for internal use,
        ensuring that they are accessible to the office's employees.
      </p>
    )
  }

  const fetchUploadedTrainingVideo = async () => {
    try {
      const uploadedTrainingVid = await API.graphql(
        graphqlOperation(listTrainingVideos)
      )
      const uploadedTrainingVidList =
        uploadedTrainingVid.data.listTrainingVideos.items
      setUploadedTrainingVideo(
        uploadedTrainingVidList.sort((a, b) => {
          return a.key.localeCompare(b.key)
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchUploadedTrainingVideo()
  }, [])

  const handlePlayButton = (key) => {
    setIsModalVisible(true)
    setVideoKey(key)
  }

  const handleSelect = (value) => {
    setDepartment(value)
  }

  const dateDisplay = `${
    new Date().getMonth() + 1
  }-${new Date().getDate()}-${new Date().getFullYear()}`

  /*  const handleButtonClick = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSdee0qZgz6rnrMW41J-LSV5_iH7CyRLau4MBrhIKHN50JWMMA/viewform',
      '_blank'
    )
  } */

  return (
    <>
      {/* <Modal
        title='NOTICE!!!'
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        width={800}
        footer={[
          <Button key='back' onClick={() => setOpenModal(false)}>
            Close
          </Button>,
        ]}
      >
        <h6>Please answer the PRE-TEST before you proceed watching!</h6>
        <Button
          type='primary'
          onClick={handleButtonClick}
          target='_blank'
          rel='noopener noreferrer'
        >
          Take Pre-Test
        </Button>
      </Modal> */}
      <div className='pb-5'>
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='NOTICE!'
          width={1000}
          visible={isInstructionModalVisible}
          onCancel={() => setIsInstructionModalVisible(false)}
          footer={[
            <Button
              key='back'
              onClick={() => setIsInstructionModalVisible(false)}
            >
              Ok
            </Button>,
          ]}
        >
          <p>
            Welcome to{' '}
            <strong>Overview of the Philippine Legal Framework-Batch 3!</strong>
          </p>

          <p>
            Few instructions before you proceed:
            <ul>
              <li>
                The training session has a workshop which will only appear after
                completely viewing all the videos.
              </li>
              <li>
                There is no backtracking to the previous video/s so please
                note-take. You may make use of the transcript available.
              </li>
            </ul>
          </p>
          <p>
            If you encounter any technical issues, please email your concern/s
            to <strong>LD.HRMD@quezoncity.gov.ph.</strong> Thank you!
          </p>
        </Modal>
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='Congratulations!'
          width={1000}
          visible={isCompleteMsgModalVisible}
          onCancel={() => setIsCompleteMsgModalVisible(false)}
          footer={[
            <Button
              key='back'
              onClick={() => setIsCompleteMsgModalVisible(false)}
            >
              Ok
            </Button>,
          ]}
        >
          <p>You have reached the end of our training modules.</p>
          <p>
            To complete this training, please go back to{' '}
            <strong>
              <i>Training Programs</i> tab
            </strong>{' '}
            and answer <strong>the Post-Training.</strong>
          </p>
          <p>Thank you, and we hope you had a wonderful learning experience!</p>
        </Modal>
        <>
          <img
            className='col-12'
            src={`${process.env.PUBLIC_URL}/Nov_20_HEADER.png`}
          />
          <div className='d-flex gap-2 justify-content-center'>
            <img
              className='col-3'
              src={`${process.env.PUBLIC_URL}/Nov_20_LEFT(Resource Speaker).png`}
            />
            {englishVideos.map((title, index) => {
              return uploadedTrainingVideo
                .filter((video) =>
                  video.key.toLowerCase().includes(title.toLowerCase())
                )
                .map((video) => {
                  const videoName = video.key.slice(0, -4) // remove the last 4 characters (i.e. ".mp4") from the file name
                  let activityComponent
                  let inProgressVid
                  if (index === 0) {
                    activityComponent = (
                      <ActivityQuestionModalOneNov20
                        userData={userData}
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                      />
                    )
                    inProgressVid = isWatchedVideoOne
                  } else if (index === 1) {
                    activityComponent = (
                      <ActivityQuestionModalTwoNov20
                        userData={userData}
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                      />
                    )
                    inProgressVid = isWatchedVideoTwo
                  } else if (index === 2) {
                    activityComponent = (
                      <ActivityQuestionModalThreeNov20
                        userData={userData}
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                      />
                    )
                    inProgressVid = isWatchedVideoFour
                  } else if (index === 3) {
                    activityComponent = (
                      <ActivityQuestionModalFourNov20
                        userData={userData}
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                      />
                    )
                    inProgressVid = isWatchedVideoFour
                  } else if (index === 4) {
                    activityComponent = (
                      <ActivityQuestionModalFiveNov20
                        userData={userData}
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                      />
                    )
                    inProgressVid = isWatchedVideoFour
                  }
                  return (
                    <>
                      {progressActivity == index + 1 && !isLoading && (
                        <div
                          className='card shadow mt-3 col-md-6 col-12'
                          key={video.key}
                        >
                          <Modal
                            maskClosable={false}
                            footer={null}
                            width={1050}
                            title={`NOTICE!!!`}
                            visible={isModalActivityOpen}
                            onCancel={() => setIsModalActivityOpen(false)}
                            destroyOnClose={true}
                          >
                            <div className='d-flex justify-content-center flex-column align-items-center text-center'>
                              <h6>
                                PLEASE{' '}
                                <span className='text-danger'>
                                  CLICK THE BUTTON BELOW
                                </span>{' '}
                                TO PROCEED TO THIS MODULE'S ACTIVITY
                                <p className='mt-3'>
                                  Clicking <strong>"Submit"</strong> will record
                                  your answers as final.
                                </p>
                              </h6>
                              {activityComponent}
                            </div>
                          </Modal>
                          <div className='h-100 d-flex align-items-center '>
                            <img
                              src={`${process.env.PUBLIC_URL}/MODULE ${
                                index + 1
                              }.png`}
                              alt={video.key}
                              className='w-100'
                            />
                            <div
                              className='playbutton btn cursor-pointer mb-5'
                              onClick={() => handlePlayButton(video.key)}
                              style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '80px',
                                height: '80px',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                top: '40%',
                              }}
                            >
                              <PlayCircleFilled
                                style={{ fontSize: '43px', color: '#fff' }}
                              />
                            </div>
                          </div>
                          {videoFinishWatching.includes(video.key) ||
                          inProgressVid
                            ? activityComponent
                            : null}
                        </div>
                      )}
                    </>
                  )
                })
            })}
            {progressActivity == 'alldone' && (
              <div className='col-md-6 col-12 flex-grow-1 d-flex flex-column align-items-center justify-content-center mt-5 bg-secondary text-white'>
                <p>
                  <h3>You have reached the end of our training modules.</h3>
                </p>
                <p>
                  <h3>
                    To complete this training, please go back to{' '}
                    <strong>Training Programs</strong> tab and{' '}
                    <strong>answer the Post-Training.</strong>
                  </h3>
                </p>
                <p>
                  <h3>
                    Thank you, and we hope you had a wonderful learning
                    experience!"
                  </h3>
                </p>
              </div>
            )}
            <img
              className='col-3'
              src={`${process.env.PUBLIC_URL}/Nov_20_RIGHT.png`}
            />
          </div>
        </>
        <Modal
          maskClosable={false}
          /*  destroyOnClose={true} */
          title='Acknowledgment and Receipt'
          width={1000}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button key='back' onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>,
            <VideoPlayer
              videoKey={videoKey}
              CSCOnlineVids={true}
              name={name}
              department={department}
              setIsModalVisible={setIsModalVisible}
              isAgree={isAgree}
              setvideoFinishWatching={setvideoFinishWatching}
              videoFinishWatching={videoFinishWatching}
              setIsModalActivityOpen={setIsModalActivityOpen}
              transcriptActivity={transcriptActivity}
            />,
          ]}
        >
          <div>
            <p>STATEMENT OF CLAIMING VIEW</p>
            <p className='text-justify'>
              I hereby declare and affirm my commitment to the terms and
              conditions set forth in this statement regarding the review video
              material provided to me in this platform (www.qc-hrmd.org), and
              its entirety, by the Human Resource Management Department (HRMD)
              of the Quezon City Government. I acknowledge that the review video
              material is the exclusive property of HRMD, and as such, I fully
              understand and agree to abide by the following terms:
            </p>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>1.</p>
              <p>
                {' '}
                <strong>Ownership:</strong> I recognize that the training video
                material is protected by intellectual property laws and is the
                sole and exclusive property of HRMD. I acknowledge that I have
                no rights or ownership claims over the content contained within
                the training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>2.</p>
              <p>
                {' '}
                <strong>Non-Duplication:</strong> I solemnly affirm that I will
                not duplicate or reproduce, in whole or in part, the training
                video material provided to me by HRMD. I understand that
                duplication includes but is not limited to copying, recording,
                downloading, or distributing the material through any means or
                in any format without explicit written consent from HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>3.</p>
              <p>
                {' '}
                <strong>Confidentiality:</strong> I acknowledge and respect that
                the training video material may contain confidential and
                proprietary information belonging to HRMD. I commit to
                maintaining the strictest confidentiality regarding the content,
                concepts, techniques, and methodologies presented in the
                training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>4.</p>
              <p>
                {' '}
                <strong>Personal Use:</strong> I understand that the training
                video material is solely intended for my personal use, learning,
                and development. I agree not to share, lend, or otherwise make
                the training video material available to any third party,
                whether for commercial or non-commercial purposes, without the
                express written consent of HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>5.</p>
              <p>
                {' '}
                <strong>Legal Consequences:</strong> I acknowledge that any
                unauthorized duplication or reproduction of the training video
                material may result in legal consequences, including but not
                limited to civil lawsuits and monetary damages. I accept full
                responsibility for any such consequences resulting from my
                violation of this agreement.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>6.</p>
              <p>
                {' '}
                <strong>Compliance:</strong> I commit to cooperating with HRMD
                and its representatives in any investigation of suspected
                violation of this agreement. I understand that HRMD reserves the
                right to take appropriate legal action to enforce these terms
                and protect its rights.
              </p>
            </div>
            <p>
              By inputting my full name and department/office in this statement,
              I acknowledge that I have read and understood the terms outlined
              above and that I will comply with them strictly. I further
              acknowledge that any breach of this agreement may result in severe
              legal consequences.
            </p>
            <div className='d-flex'>
              <label className='d-flex items-align-center gap-2'>
                <input
                  type='checkbox'
                  checked={!isAgree}
                  onChange={(e) => setIsAgree(!isAgree)}
                />
                I agree
              </label>
            </div>
          </div>

          <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Enter your name'
                className='text-center'
              />
              <span className='text-center'>Employee's Name & Signature</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
              <span>{dateDisplay}</span>
              <span>Date</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
              <Select
                value={department}
                onChange={handleSelect}
                className='w-100 text-center'
              >
                <Option value='disabled' disabled>
                  Disabled
                </Option>
                {dept.sort().map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <span className='text-center'>Department</span>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
