/**
 * Title: editRemarks.js
 * Description: This is a file that contains edit remarks component
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/editRemarks.js
 **/

/*
 * Changes made:
 * - 2024.09.08 | Harry Lagunsad | added an input max length of 512 for the additional remarks text area
 */

import { useEffect, useState } from 'react'

import { Button, Modal, Col, Row, notification, Input } from 'antd'

import { API, graphqlOperation } from 'aws-amplify'
import { updateTrainingRegistration } from '../../api/mutations'

const { TextArea } = Input

export default function EditRemarks({ record, allPostTrainings }) {
  const [isAttendedNew, setIsAttendedNew] = useState(null)
  const [isSubmitEndorsementLetterNew, setIsSubmitEndorsementLetterNew] =
    useState(null)
  const [isCourseOutputDoneNew, setIsCourseOutputDoneNew] = useState(null)
  const [isPostEvalDoneNew, setIsPostEvalDoneNew] = useState(null)

  const [openConfirm, setOpenConfirm] = useState(false)

  const [adRemarks, setAdRemarks] = useState(null)

  const {
    isAttended,
    isSubmitEndorsementLetter,
    isCourseOutputDone,
    isPostEvalDone,
    additionalRemarks,
  } = record

  useEffect(() => {
    setIsAttendedNew(isAttended)
    setIsSubmitEndorsementLetterNew(isSubmitEndorsementLetter)
    setIsCourseOutputDoneNew(isCourseOutputDone)
    setIsPostEvalDoneNew(isPostEvalDone)
    setAdRemarks(record.adRemarks)
  }, [])

  const inputOne = (e) => {
    setIsAttendedNew(e.target.checked)
  }

  const inputTwo = (e) => {
    setIsSubmitEndorsementLetterNew(e.target.checked)
  }

  const inputThree = (e) => {
    setIsCourseOutputDoneNew(e.target.checked)
  }

  const inputFour = (e) => {
    setIsPostEvalDoneNew(e.target.checked)
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * @function handleSubmitRemarks
   * @description This function submits the edited remarks of the registrant.
   * @param {void} - no parameter
   * @returns {void} - no return value
   * @async
   */
  const handleSubmitRemarks = async () => {
    try {
      setOpenConfirm(false)

      const input = {
        id: record.id,
        isAttended: isAttendedNew,
        isSubmitEndorsementLetter: isSubmitEndorsementLetterNew,
        isCourseOutputDone: isCourseOutputDoneNew,
        isPostEvalDone: isPostEvalDoneNew,
        additionalRemarks: adRemarks,
      }
      const editRemarksInTrainingRegistration = await API.graphql(
        graphqlOperation(updateTrainingRegistration, { input })
      )

      if (editRemarksInTrainingRegistration) {
        notification['success']({
          message: 'Success',
          description: 'Remarks was edited!',
        })
      }
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description:
          'Encountered error in editing the remarks. Please contact system administrator.',
      })
    }
  }

  const handleAddRems = (e) => {
    setAdRemarks(e.target.value)
  }

  return (
    <>
      <Row>
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title={null}
          centered
          visible={openConfirm}
          onOk={() => setOpenConfirm(false)}
          onCancel={() => setOpenConfirm(false)}
          width={400}
          footer={null}
          closable={false}
        >
          <Row justify='center'>
            <span>{`Proceed to edit requirements of ${record.fullName}?`}</span>
          </Row>
          <Row className={'mt-3'}>
            <Col span={12}>
              <Row justify='center'>
                <Button type='danger' onClick={() => setOpenConfirm(false)}>
                  Cancel
                </Button>
              </Row>
            </Col>
            <Col span={12}>
              <Row justify='center'>
                <Button type='primary' onClick={handleSubmitRemarks}>
                  Proceed
                </Button>
              </Row>
            </Col>
          </Row>
        </Modal>
        <Col className={'border ps-2'} span={10}>
          <Row className={'mb-3 mt-2'}>
            <input
              onChange={inputOne}
              defaultChecked={isAttended}
              id={`checkAttendance-${record.count}`}
              name={`checkAttendance-${record.count}`}
              type='checkbox'
            />
            <label
              className={'ms-1'}
              htmlFor={`checkAttendance-${record.count}`}
            >
              Attendance
            </label>
          </Row>
          <Row className={'mb-3 mt-2'}>
            <input
              onChange={inputTwo}
              defaultChecked={isSubmitEndorsementLetter}
              id={`checkLetter-${record.count}`}
              name={`checkLetter-${record.count}`}
              type='checkbox'
            />
            <label className={'ms-1'} htmlFor={`checkLetter-${record.count}`}>
              Endorsement Letter
            </label>
          </Row>
          <Row className={'mb-3 mt-2'}>
            <input
              onChange={inputThree}
              defaultChecked={isCourseOutputDone}
              id={`checkAssessment-${record.count}`}
              name={`checkAssessment-${record.count}`}
              type='checkbox'
            />
            <label
              className={'ms-1'}
              htmlFor={`checkAssessment-${record.count}`}
            >
              Post Assessment
            </label>
          </Row>
          <Row className={'mb-3 mt-2'}>
            <input
              onChange={inputFour}
              defaultChecked={isPostEvalDone}
              id={`checkEval-${record.count}`}
              name={`checkEval-${record.count}`}
              type='checkbox'
            />
            <label className={'ms-1'} htmlFor={`checkEval-${record.count}`}>
              Post Evaluation
            </label>
          </Row>
        </Col>
        <Col className={'border'} span={14}>
          <Row justify='center'>
            <span>ADDITIONAL REMARKS</span>
          </Row>
          <Row className={'px-2 pb-1'} style={{ height: 138 }} justify='center'>
            <TextArea
              defaultValue={additionalRemarks}
              onChange={handleAddRems}
              row={6}
              maxLength={512}
            ></TextArea>
          </Row>
        </Col>
      </Row>
      <Row className={'mt-4'} justify='end'>
        <Button onClick={() => setOpenConfirm(true)} type='primary'>
          Submit
        </Button>
      </Row>
    </>
  )
}
