import { API, graphqlOperation } from 'aws-amplify'
import React, { useEffect, useRef, useState } from 'react'
import { listOnlineActivityQuestions } from '../../api/queries'
import { deleteAssessmentQuestions } from '../../api/mutations'

import { notification, Button, Modal, Row, Col, Input, Popconfirm } from 'antd'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  CaretLeftFilled,
  CaretRightFilled,
} from '@ant-design/icons'
import TextArea from 'antd/lib/input/TextArea'

const SelectActivityQuestions = ({ setActivityQuestions, index }) => {
  /* Declaring variables */
  const [openModal, setOpenModal] = useState(false)
  const [selectedAssessQuestion, setSelectedAssessQuestion] = useState('')
  const [AssessQuestionsList, setAssessQuestionsList] = useState([])
  const [qIndex, setqIndex] = useState(0)
  // const selectRef = useRef(null);

  /* Get the list of Eval Questions */
  const fetchAssessQuestionList = async () => {
    const AssessQuestionList = await API.graphql({
      query: listOnlineActivityQuestions,
    })

    // Get the list of questions
    const questionList =
      AssessQuestionList.data.listOnlineActivityQuestions.items

    // Sort the questions by createdAt field
    questionList.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))

    // Get the initial selected question based on qIndex
    const initialSelectedQuestion = questionList[qIndex]

    // Update the state with the sorted list
    setAssessQuestionsList(questionList)
    setSelectedAssessQuestion(initialSelectedQuestion)
  }

  /* Change the question preview to next question */
  const handleNextQuestion = () => {
    const nextIndex = qIndex === AssessQuestionsList.length - 1 ? 0 : qIndex + 1
    setqIndex(nextIndex)
  }

  /* Change the question preview to previous question */
  const handlePrevQuestion = () => {
    const prevIndex = qIndex === 0 ? AssessQuestionsList.length - 1 : qIndex - 1
    setqIndex(prevIndex)
  }

  // //Handle Selected Question
  // const handleSelectedQuestion = (e) => {
  //   const questionId = e.target.value;
  //   if (questionId) {
  //     const selectedQuestion = AssessQuestionsList.filter(question => {
  //       return question.id == questionId;
  //     })
  //     console.log(JSON.parse(selectedQuestion[0].assessmentQuestionnaire))
  //     setSelectedAssessQuestion(JSON.parse(selectedQuestion[0].assessmentQuestionnaire));
  //   }

  //   if (!questionId) {
  //     setSelectedAssessQuestion("")
  //   }
  // }

  /* Saving to bind the selected assessment question to the training that will be created */
  const handleSave = () => {
    setActivityQuestions((prev) => {
      const newKey = `activityQuestion${index + 1}`
      return {
        ...prev,
        [newKey]: AssessQuestionsList[qIndex].questions,
      }
    })

    setTimeout(() => {
      setqIndex(0)
      setSelectedAssessQuestion(AssessQuestionsList[qIndex])
      setOpenModal(false)
    }, 1000)
  }

  /* Closing the select question modal */
  const handleCloseModal = () => {
    setOpenModal(false)
    setqIndex(0)
    setSelectedAssessQuestion(AssessQuestionsList[qIndex])
  }

  /* Deleting the assessment question from database */
  const handleConfirmDelete = async (e, questionId) => {
    e.preventDefault()
    try {
      const status = await API.graphql(
        graphqlOperation(deleteAssessmentQuestions, {
          input: {
            id: questionId,
          },
        })
      )
      if (status) {
        notification['success']({
          message: 'Success',
          description: 'Successfully deleted.',
        })
      }
      fetchAssessQuestionList()
      // selectRef.current.value = "";
    } catch (error) {
      console.log(error)
    }
  }

  /* Initializing the default value of selected question */
  useEffect(() => {
    if (AssessQuestionsList && AssessQuestionsList[qIndex]) {
      setSelectedAssessQuestion(AssessQuestionsList[qIndex])
    }
  }, [qIndex])

  /* Fetch assessment question list */
  useEffect(() => {
    fetchAssessQuestionList()
  }, [])

  /* Mapping selectedAssessQuestion for Questions Preview  */
  const showOutput =
    selectedAssessQuestion &&
    JSON.parse(selectedAssessQuestion.questions).map((elem, index) => {
      return (
        <div key={index} className='mb-4'>
          {elem.questionType !== 'caseStudy' && (
            <>
              <div>
                <p className={'fw-bold'}>
                  {index + 1}. {elem.question}
                </p>
              </div>
              <div className='flex-column ms-3 mt-2'>
                {elem.choices[0] &&
                  elem.choices[1] &&
                  elem.choices[2] &&
                  elem.choices[3] && (
                    <>
                      <div
                        className='flex align-items-center'
                        hidden={
                          elem.choices[0][
                            `choices-1-${index + 1}`
                          ].toLowerCase() === 'n/a'
                        }
                      >
                        <input name='answer' className='mr' type='radio' />
                        <span className='m-0 ms-2'>
                          <strong>
                            <em>A. </em>
                          </strong>
                          {elem.choices[0][`choices-1-${index + 1}`]}
                        </span>
                      </div>
                      <div
                        hidden={
                          elem.choices[1][
                            `choices-2-${index + 1}`
                          ].toLowerCase() === 'n/a'
                        }
                      >
                        <input name='answer' className='mr' type='radio' />
                        <span className='m-0 ms-2'>
                          <strong>
                            <em>B. </em>
                          </strong>
                          {elem.choices[1][`choices-2-${index + 1}`]}
                        </span>
                      </div>
                      <div
                        hidden={
                          elem.choices[2][
                            `choices-3-${index + 1}`
                          ].toLowerCase() === 'n/a'
                        }
                      >
                        <input name='answer' className='mr' type='radio' />
                        <span className='m-0 ms-2'>
                          <strong>
                            <em>C. </em>
                          </strong>
                          {elem.choices[2][`choices-3-${index + 1}`]}
                        </span>
                      </div>
                      <div
                        hidden={
                          elem.choices[3][
                            `choices-4-${index + 1}`
                          ].toLowerCase() === 'n/a'
                        }
                      >
                        <input name='answer' className='mr' type='radio' />
                        <span className='m-0 ms-2'>
                          <strong>
                            <em>D. </em>
                          </strong>
                          {elem.choices[3][`choices-4-${index + 1}`]}
                        </span>
                      </div>
                      <TextArea hidden={elem.correctAnswer !== 'n/a'} />
                    </>
                  )}
              </div>
            </>
          )}
          {/* <Row>
            <Row>
              <span className={"fw-bold"}>Multiple choice: Choose the correct asnwer</span>
            </Row>
          </Row> */}
          {elem.questionType === 'caseStudy' && (
            <>
              <Row
                className={
                  'mb-3 d-flex justify-content-between align-items-center'
                }
              >
                <label htmlFor={`question-${index + 1}`}>
                  <strong>Instructions:</strong>
                </label>
              </Row>
              <Row className={'mb-3'}>
                <p>
                  {JSON.parse(selectedAssessQuestion.questions)[index].question}
                </p>
              </Row>

              {JSON.parse(selectedAssessQuestion.questions)[index].scenario.map(
                (scenario, scenarioIndex) => (
                  <div key={scenarioIndex} className='my-4'>
                    <div>
                      <h4>
                        Case Scenario #{scenarioIndex + 1}: {scenario.title}{' '}
                      </h4>
                      <p>{scenario.description}</p>
                    </div>
                  </div>
                )
              )}
              <div>
                <p>Select a case scenario before uploading:</p>
                <div className='d-flex gap-3'>
                  {JSON.parse(selectedAssessQuestion.questions)[
                    index
                  ].scenario.map((scenario, scenarioIndex) => (
                    <div key={scenarioIndex} className='fw-bold'>
                      <input type='radio' name='caseScenario' /> Case #{' '}
                      {scenarioIndex + 1}
                    </div>
                  ))}
                </div>
              </div>
              <input className='mt-4' type='file' disabled />
            </>
          )}
        </div>
      )
    })

  return (
    <>
      <Button ghost type='primary' onClick={() => setOpenModal(true)}>
        Select Activity questionnaire
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Select Assessment Questionnaire'
        visible={openModal}
        footer={null}
        onCancel={handleCloseModal}
        width={800}
      >
        {/* <div className="p-5 ps-0 pe-0 d-flex flex-md-row flex-column justify-content-center align-items-md-center">
          <span className="fw-bold">Select Evaluation Question: </span>
          <select ref={selectRef} onChange={(e) => handleSelectedQuestion(e)} className="p-1 ms-md-2">
            <option value="">Select Question</option>
            {AssessQuestionsList.map((question) => {
              return (
                <option key={question.id} value={`${question.id}`}>
                  {question.id}
                </option>
              )
            })}
          </select>
        </div> */}
        <div className='me-md-0'>
          <div className='carousel-inner'>
            <div className='col-10 offset-2'>
              {showOutput && (
                <>
                  <div className='d-flex justify-content-between col-md-10'>
                    <h3 className='mb-5'>Activity Question Preview</h3>
                    <Popconfirm
                      placement='top'
                      title={'Are you sure to delete this Question?'}
                      onConfirm={(e) =>
                        handleConfirmDelete(
                          e,
                          JSON.parse(selectedAssessQuestion.questions).id
                        )
                      }
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button className='bg-danger text-white d-flex align-items-center me-5 me-md-0'>
                        <DeleteFilled />
                        <span className='d-md-block d-none'>
                          Delete This Question
                        </span>
                      </Button>
                    </Popconfirm>
                  </div>
                  <div className='col-10'>{showOutput}</div>
                </>
              )}
            </div>
            <button
              onClick={handlePrevQuestion}
              className='carousel-control-prev text-black display-1 d-flex justify-content-start'
            >
              <CaretLeftFilled />
            </button>
            <button
              onClick={handleNextQuestion}
              className='carousel-control-next text-black display-1 d-flex justify-content-end'
            >
              <CaretRightFilled />
            </button>
          </div>
          <div className='d-flex justify-content-end gap-3 mt-5'>
            <Button onClick={handleCloseModal} className='bg-danger text-white'>
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              className='align-self-end bg-primary text-white ps-4 pe-4'
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SelectActivityQuestions
