/**
 * Title: postQuestionTwo.js
 * Description: This is a file that contains the components that displays the post question two
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/postQuestionTwo.js
 **/

import { useState, useEffect } from 'react'

import { Button, Modal, Steps, Input, Row, Col, notification } from 'antd'

import { API, graphqlOperation } from 'aws-amplify'

import { createTrainingPostEvaluation } from '../../api/mutations'
import { registrationByTAID, evaluationByTAID } from '../../api/queries'

export default function PostQuestionTwo({
  trainingItem,
  setQuestionOneAfter,
  setQuestionTwoAfter,
  setQuestionThreeAfter,
  setQuestionFourAfter,
  setQuestionFiveAfter,
  setIsModalOpenAfter,
  setShowAnswer,
  handleCloseModal,
  userData,
}) {
  const currentUser = userData

  const [setAQOne, setSetAQOne] = useState(null)
  const [setAQTwo, setSetAQTwo] = useState(null)
  const [setAQThree, setSetAQThree] = useState(null)

  const [setBQOne, setSetBQOne] = useState(null)
  const [setBQTwo, setSetBQTwo] = useState(null)
  const [setBQThree, setSetBQThree] = useState(null)

  const [setCQOne, setSetCQOne] = useState(null)
  const [setCQTwo, setSetCQTwo] = useState(null)
  const [setCQThree, setSetCQThree] = useState(null)

  const [setDQOne, setSetDQOne] = useState(null)
  const [setDQTwo, setSetDQTwo] = useState(null)
  const [setDQThree, setSetDQThree] = useState(null)

  const [setEQOne, setSetEQOne] = useState(null)
  const [setEQTwo, setSetEQTwo] = useState(null)
  const [setEQThree, setSetEQThree] = useState(null)

  const [questionOne, setQuestionOne] = useState(null)
  const [questionTwo, setQuestionTwo] = useState(null)
  const [questionThree, setQuestionThree] = useState(null)
  const [questionFour, setQuestionFour] = useState(null)
  const [questionFive, setQuestionFive] = useState(null)

  const [isAgree, setIsAgree] = useState(false)

  const handleSetAQOne = (e) => {
    setSetAQOne(e.target.value)
  }

  const handleSetAQTwo = (e) => {
    setSetAQTwo(e.target.value)
  }

  const handleSetAQThree = (e) => {
    setSetAQThree(e.target.value)
  }

  const handleSetBQOne = (e) => {
    setSetBQOne(e.target.value)
  }

  const handleSetBQTwo = (e) => {
    setSetBQTwo(e.target.value)
  }

  const handleSetBQThree = (e) => {
    setSetBQThree(e.target.value)
  }

  const handleSetCQOne = (e) => {
    setSetCQOne(e.target.value)
  }

  const handleSetCQTwo = (e) => {
    setSetCQTwo(e.target.value)
  }

  const handleSetCQThree = (e) => {
    setSetCQThree(e.target.value)
  }

  const handleSetDQOne = (e) => {
    setSetDQOne(e.target.value)
  }

  const handleSetDQTwo = (e) => {
    setSetDQTwo(e.target.value)
  }

  const handleSetDQThree = (e) => {
    setSetDQThree(e.target.value)
  }
  const handleSetEQOne = (e) => {
    setSetEQOne(e.target.value)
  }

  const handleSetEQTwo = (e) => {
    setSetEQTwo(e.target.value)
  }

  const handleSetEQThree = (e) => {
    setSetEQThree(e.target.value)
  }

  const handleQuestionOne = (e) => {
    setQuestionOne(e.target.value)
  }

  const handleQuestionTwo = (e) => {
    setQuestionTwo(e.target.value)
  }

  const handleQuestionThree = (e) => {
    setQuestionThree(e.target.value)
  }

  const handleQuestionFour = (e) => {
    setQuestionFour(e.target.value)
  }

  const handleQuestionfive = (e) => {
    setQuestionFive(e.target.value)
  }

  const handleDisable = () => {
    if (trainingItem.hasAssessment == 'false') {
      if (
        setAQOne &&
        setAQTwo &&
        setAQThree &&
        setBQOne &&
        setBQTwo &&
        setBQThree &&
        // setCQOne &&
        // setCQTwo &&
        // setCQThree &&
        // setDQOne &&
        // setDQTwo &&
        // setDQThree &&
        setEQOne &&
        setEQTwo &&
        setEQThree
      ) {
        return false
      } else {
        return true
      }
    } else {
      if (
        setAQOne &&
        setAQTwo &&
        setAQThree &&
        setBQOne &&
        setBQTwo &&
        setBQThree &&
        setCQOne &&
        setCQTwo &&
        setCQThree &&
        setDQOne &&
        setDQTwo &&
        setDQThree &&
        setEQOne &&
        setEQTwo &&
        setEQThree &&
        questionOne &&
        questionTwo &&
        questionThree &&
        questionFour &&
        questionFive
      ) {
        return false
      } else {
        return true
      }
    }
  }

  const handleSubmit = async () => {
    try {
      if (
        setAQOne &&
        setAQTwo &&
        setAQThree &&
        setBQOne &&
        setBQTwo &&
        setBQThree &&
        // setCQOne &&
        // setCQTwo &&
        // setCQThree &&
        // setDQOne &&
        // setDQTwo &&
        // setDQThree &&
        setEQOne &&
        setEQTwo &&
        setEQThree
      ) {
        const questionnaireValue = {
          questionOne: questionOne,
          questionTwo: questionTwo,
          questionThree: questionThree,
          questionFour: questionFour,
          questionFive: questionFive,
        }

        const postEvalQuestionsValue = {
          setAQOne,
          setAQTwo,
          setAQThree,
          setBQOne,
          setBQTwo,
          setBQThree,
          setCQOne,
          setCQTwo,
          setCQThree,
          setDQOne,
          setDQTwo,
          setDQThree,
          setEQOne,
          setEQTwo,
          setEQThree,
        }

        const adminUpload = await API.graphql(
          graphqlOperation(createTrainingPostEvaluation, {
            input: {
              userID: currentUser.id,
              trainingAnnouncementID: trainingItem.id,
              postEvalQuestions: JSON.stringify(postEvalQuestionsValue),
              questionnaire: JSON.stringify(questionnaireValue),
            },
          })
        )
        if (adminUpload) {
          const answer = JSON.parse(
            adminUpload.data.createTrainingPostEvaluation.questionnaire
          )

          notification['success']({
            message: 'Success',
            description:
              'Thank you for answering the Post-Training Evaluation. Your response has been recorded.',
          })
          // setIsModalOpen(false);
          setIsModalOpenAfter(true)
          setTimeout(() => {
            if (trainingItem.hasAssessment == 'false') {
              setShowAnswer(false)
              handleCloseModal()
            } else {
              setShowAnswer(true)
            }
          }, 250)

          setQuestionOne(null)
          setQuestionTwo(null)
          setQuestionThree(null)
          setQuestionFour(null)
          setQuestionFive(null)

          setSetAQOne(null)
          setSetAQTwo(null)
          setSetAQThree(null)
          setSetBQOne(null)
          setSetBQTwo(null)
          setSetBQThree(null)
          setSetCQOne(null)
          setSetCQTwo(null)
          setSetCQThree(null)

          setQuestionOneAfter(answer.questionOne)
          setQuestionTwoAfter(answer.questionTwo)
          setQuestionThreeAfter(answer.questionThree)
          setQuestionFourAfter(answer.questionFour)
          setQuestionFiveAfter(answer.questionFive)
        }
      } else {
        notification['error']({
          message: 'Error',
          description: 'Please answer all questions.',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handlePrivacy = (e) => {
    setIsAgree(true)
  }

  return (
    <>
      <Row className={'font-Mont mb-3'}>
        <Col>
          <Row className={'mt-2'}>
            <Col span={24}>
              <Row>
                <span>
                  Your experience and feedback in regards to the training
                  program will help us improve our logistics and content, thus
                  we would highly appreciate your comments and thoughts by
                  filling out this quick survey.
                </span>
                <br />
              </Row>

              <Row>
                <span>Thank you!</span>
                <br />
                <br />
              </Row>
              <Row>
                <span>1 = Poor</span>
              </Row>
              <Row>
                <span>2 = Fair</span>
              </Row>
              <Row>
                <span>3 = Average</span>
              </Row>
              <Row>
                <span>4 = Good</span>
              </Row>
              <Row>
                <span>5 = Excellent</span>
                <br />
                <br />
              </Row>
            </Col>
          </Row>

          <Row className={'mb-3'}>
            <h4>I. Data Privacy Consent Form</h4>
          </Row>
          <Row>
            <span>Republic Act 10173 or the "Data Privacy Act"</span>
            <br />
            <br />
          </Row>
          <Row>
            <span>
              <strong>
                I hereby authorize and give the Human Resource Management
                Department a consent that all personal information indicated
                herein will be kept confidential for training purposes only. I
                further understand that this form is being protected by the
                Republic Act 10173 or the "Data Privacy Act".
              </strong>
            </span>
            <br />
          </Row>
          <Row onChange={(e) => handlePrivacy(e)}>
            <input value={true} id='dpa22' name='dpa22' type='radio' />
            <label className={'ms-2'} htmlFor='dpa22'>
              <b>I agree *</b>
            </label>
          </Row>

          {isAgree == true ? (
            <>
              <Row className={'mb-3 mt-3'}>
                <h4>II. Post Evaluation</h4>
              </Row>
              <Row>
                <span style={{ fontSize: 16 }}>
                  Your experience and feedback in regards to the training
                  program will help us improve our logistics and content, thus
                  we would highly appreciate your comments and thoughts by
                  filling out this quick survey.
                </span>
                <br />
                <br />
              </Row>
              <Row>
                <span style={{ fontSize: 16 }}>Thank you!</span>
                <br />
                <br />
              </Row>
              <Row>
                <span style={{ fontSize: 16 }}>1 = Poor</span>
              </Row>
              <Row>
                <span style={{ fontSize: 16 }}>2 = Fair</span>
              </Row>
              <Row>
                <span style={{ fontSize: 16 }}>3 = Average</span>
              </Row>
              <Row>
                <span style={{ fontSize: 16 }}>4 = Good</span>
              </Row>
              <Row>
                <span style={{ fontSize: 16 }}>5 = Excellent</span>
              </Row>
              <Row className={'mb-4'}></Row>
              <Row style={{ fontSize: 16 }} justify='center'>
                <Col>
                  <Row>
                    <span className={'fw-bold'}>I. PROGRAM DESIGN</span>
                  </Row>
                  <Row>
                    <span className={'fw-bold'}>1 = Poor, 5 = Excellent</span>
                  </Row>
                  <Row className={'mb-3'}>
                    <Col style={{ width: 550 }}>
                      <Row>
                        <Col span={9}>
                          <Row style={{ height: '22px' }}></Row>
                          <Row>
                            Clarity of Objectives{' '}
                            <em className='asterisk'>**</em>
                          </Row>
                          <Row>
                            Relevance of Contents{' '}
                            <em className='asterisk'>**</em>
                          </Row>
                          <Row>
                            Sequence of Contents{' '}
                            <em className='asterisk'>**</em>
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>1</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQOne(e)}
                              defaultChecked={setAQOne == '1' ? true : false}
                              value='1'
                              name='setAQOne'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQTwo(e)}
                              defaultChecked={setAQTwo == '1' ? true : false}
                              value='1'
                              name='setAQTwo'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQThree(e)}
                              defaultChecked={setAQThree == '1' ? true : false}
                              value='1'
                              name='setAQThree'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>2</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQOne(e)}
                              defaultChecked={setAQOne == '2' ? true : false}
                              value='2'
                              name='setAQOne'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQTwo(e)}
                              defaultChecked={setAQTwo == '2' ? true : false}
                              value='2'
                              name='setAQTwo'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQThree(e)}
                              defaultChecked={setAQThree == '2' ? true : false}
                              value='2'
                              name='setAQThree'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>3</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQOne(e)}
                              defaultChecked={setAQOne == '3' ? true : false}
                              value='3'
                              name='setAQOne'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQTwo(e)}
                              defaultChecked={setAQTwo == '3' ? true : false}
                              value='3'
                              name='setAQTwo'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQThree(e)}
                              defaultChecked={setAQThree == '3' ? true : false}
                              value='3'
                              name='setAQThree'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>4</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQOne(e)}
                              defaultChecked={setAQOne == '4' ? true : false}
                              value='4'
                              name='setAQOne'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQTwo(e)}
                              defaultChecked={setAQTwo == '4' ? true : false}
                              value='4'
                              name='setAQTwo'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQThree(e)}
                              defaultChecked={setAQThree == '4' ? true : false}
                              value='4'
                              name='setAQThree'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>5</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQOne(e)}
                              defaultChecked={setAQOne == '5' ? true : false}
                              value='5'
                              name='setAQOne'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQTwo(e)}
                              defaultChecked={setAQTwo == '5' ? true : false}
                              value='5'
                              name='setAQTwo'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetAQThree(e)}
                              defaultChecked={setAQThree == '5' ? true : false}
                              value='5'
                              name='setAQThree'
                              className={'postAssesmentQ'}
                              type='radio'
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <span className={'fw-bold'}>
                      II. FACILITATION <em className='asterisk'>**</em>
                    </span>
                  </Row>
                  <Row>
                    <span className={'fw-bold'}>1 = Poor, 5 = Excellent</span>
                  </Row>
                  <Row className={'mb-3'}>
                    <Col style={{ width: 550 }}>
                      <Row>
                        <Col span={9}>
                          <Row style={{ height: '22px' }}></Row>
                          <Row>
                            Mastery of the Subject{' '}
                            <em className='asterisk'>**</em>
                          </Row>
                          <Row>
                            Time Management <em className='asterisk'>**</em>
                          </Row>
                          <Row>
                            Professional Conduct{' '}
                            <em className='asterisk'>**</em>
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>1</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQOne(e)}
                              type='radio'
                              defaultChecked={setBQOne == '1' ? true : false}
                              value='1'
                              name='setBQOne'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQTwo(e)}
                              type='radio'
                              defaultChecked={setBQTwo == '1' ? true : false}
                              value='1'
                              name='setBQTwo'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQThree(e)}
                              type='radio'
                              defaultChecked={setBQThree == '1' ? true : false}
                              value='1'
                              name='setBQThree'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>2</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQOne(e)}
                              type='radio'
                              defaultChecked={setBQOne == '2' ? true : false}
                              value='2'
                              name='setBQOne'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQTwo(e)}
                              type='radio'
                              defaultChecked={setBQTwo == '2' ? true : false}
                              value='2'
                              name='setBQTwo'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQThree(e)}
                              type='radio'
                              defaultChecked={setBQThree == '2' ? true : false}
                              value='2'
                              name='setBQThree'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>3</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQOne(e)}
                              type='radio'
                              defaultChecked={setBQOne == '3' ? true : false}
                              value='3'
                              name='setBQOne'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQTwo(e)}
                              type='radio'
                              defaultChecked={setBQTwo == '3' ? true : false}
                              value='3'
                              name='setBQTwo'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQThree(e)}
                              type='radio'
                              defaultChecked={setBQThree == '3' ? true : false}
                              value='3'
                              name='setBQThree'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>4</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQOne(e)}
                              type='radio'
                              defaultChecked={setBQOne == '4' ? true : false}
                              value='4'
                              name='setBQOne'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQTwo(e)}
                              type='radio'
                              defaultChecked={setBQTwo == '4' ? true : false}
                              value='4'
                              name='setBQTwo'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQThree(e)}
                              type='radio'
                              defaultChecked={setBQThree == '4' ? true : false}
                              value='4'
                              name='setBQThree'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>5</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQOne(e)}
                              type='radio'
                              defaultChecked={setBQOne == '5' ? true : false}
                              value='5'
                              name='setBQOne'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQTwo(e)}
                              type='radio'
                              defaultChecked={setBQTwo == '5' ? true : false}
                              value='5'
                              name='setBQTwo'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetBQThree(e)}
                              type='radio'
                              defaultChecked={setBQThree == '5' ? true : false}
                              value='5'
                              name='setBQThree'
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/**<>
                    <Row>
                      <span className={"fw-bold"}>
                        III. FACILITATION - Benefits & Incentives by ROMMEL ROMERO{" "}
                        <em className="asterisk">**</em>
                      </span>
                    </Row>
                    <Row>
                      <span className={"fw-bold"}>1 = Poor, 5 = Excellent</span>
                    </Row>
                    <Row className={"mb-3"}>
                      <Col style={{ width: 550 }}>
                        <Row>
                          <Col span={9}>
                            <Row style={{ height: "22px" }}></Row>
                            <Row>
                              Mastery of the Subject{" "}
                              <em className="asterisk">**</em>
                            </Row>
                            <Row>
                              Time Management <em className="asterisk">**</em>
                            </Row>
                            <Row>
                              Professional Conduct{" "}
                              <em className="asterisk">**</em>
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <span className={"fw-bold"}>1</span>
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQOne(e)}
                                type="radio"
                                defaultChecked={setCQOne == "1" ? true : false}
                                value="1"
                                name="setCQOne"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQTwo(e)}
                                type="radio"
                                defaultChecked={setCQTwo == "1" ? true : false}
                                value="1"
                                name="setCQTwo"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQThree(e)}
                                type="radio"
                                defaultChecked={setCQThree == "1" ? true : false}
                                value="1"
                                name="setCQThree"
                                type="radio"
                              />
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <span className={"fw-bold"}>2</span>
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQOne(e)}
                                type="radio"
                                defaultChecked={setCQOne == "2" ? true : false}
                                value="2"
                                name="setCQOne"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQTwo(e)}
                                type="radio"
                                defaultChecked={setCQTwo == "2" ? true : false}
                                value="2"
                                name="setCQTwo"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQThree(e)}
                                type="radio"
                                defaultChecked={setCQThree == "2" ? true : false}
                                value="2"
                                name="setCQThree"
                                type="radio"
                              />
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <span className={"fw-bold"}>3</span>
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQOne(e)}
                                type="radio"
                                defaultChecked={setCQOne == "3" ? true : false}
                                value="3"
                                name="setCQOne"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQTwo(e)}
                                type="radio"
                                defaultChecked={setCQTwo == "3" ? true : false}
                                value="3"
                                name="setCQTwo"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQThree(e)}
                                type="radio"
                                defaultChecked={setCQThree == "3" ? true : false}
                                value="3"
                                name="setCQThree"
                                type="radio"
                              />
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <span className={"fw-bold"}>4</span>
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQOne(e)}
                                type="radio"
                                defaultChecked={setCQOne == "4" ? true : false}
                                value="4"
                                name="setCQOne"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQTwo(e)}
                                type="radio"
                                defaultChecked={setCQTwo == "4" ? true : false}
                                value="4"
                                name="setCQTwo"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQThree(e)}
                                type="radio"
                                defaultChecked={setCQThree == "4" ? true : false}
                                value="4"
                                name="setCQThree"
                                type="radio"
                              />
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <span className={"fw-bold"}>5</span>
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQOne(e)}
                                type="radio"
                                defaultChecked={setCQOne == "5" ? true : false}
                                value="5"
                                name="setCQOne"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQTwo(e)}
                                type="radio"
                                defaultChecked={setCQTwo == "5" ? true : false}
                                value="5"
                                name="setCQTwo"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetCQThree(e)}
                                type="radio"
                                defaultChecked={setCQThree == "5" ? true : false}
                                value="5"
                                name="setCQThree"
                                type="radio"
                              />
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>

                    <Row>
                      <span className={"fw-bold"}>
                        IV. FACILITATION - Introduction to Gender and Development
                        by KAREN CHRISTINE SANTOS <em className="asterisk">**</em>
                      </span>
                    </Row>
                    <Row>
                      <span className={"fw-bold"}>1 = Poor, 5 = Excellent</span>
                    </Row>
                    <Row className={"mb-3"}>
                      <Col style={{ width: 550 }}>
                        <Row>
                          <Col span={9}>
                            <Row style={{ height: "22px" }}></Row>
                            <Row>
                              Mastery of the Subject{" "}
                              <em className="asterisk">**</em>
                            </Row>
                            <Row>
                              Time Management <em className="asterisk">**</em>
                            </Row>
                            <Row>
                              Professional Conduct{" "}
                              <em className="asterisk">**</em>
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <span className={"fw-bold"}>1</span>
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQOne(e)}
                                type="radio"
                                defaultChecked={setDQOne == "1" ? true : false}
                                value="1"
                                name="setDQOne"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQTwo(e)}
                                type="radio"
                                defaultChecked={setDQTwo == "1" ? true : false}
                                value="1"
                                name="setDQTwo"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQThree(e)}
                                type="radio"
                                defaultChecked={setDQThree == "1" ? true : false}
                                value="1"
                                name="setDQThree"
                                type="radio"
                              />
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <span className={"fw-bold"}>2</span>
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQOne(e)}
                                type="radio"
                                defaultChecked={setDQOne == "2" ? true : false}
                                value="2"
                                name="setDQOne"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQTwo(e)}
                                type="radio"
                                defaultChecked={setDQTwo == "2" ? true : false}
                                value="2"
                                name="setDQTwo"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQThree(e)}
                                type="radio"
                                defaultChecked={setDQThree == "2" ? true : false}
                                value="2"
                                name="setDQThree"
                                type="radio"
                              />
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <span className={"fw-bold"}>3</span>
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQOne(e)}
                                type="radio"
                                defaultChecked={setDQOne == "3" ? true : false}
                                value="3"
                                name="setDQOne"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQTwo(e)}
                                type="radio"
                                defaultChecked={setDQTwo == "3" ? true : false}
                                value="3"
                                name="setDQTwo"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQThree(e)}
                                type="radio"
                                defaultChecked={setDQThree == "3" ? true : false}
                                value="3"
                                name="setDQThree"
                                type="radio"
                              />
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <span className={"fw-bold"}>4</span>
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQOne(e)}
                                type="radio"
                                defaultChecked={setDQOne == "4" ? true : false}
                                value="4"
                                name="setDQOne"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQTwo(e)}
                                type="radio"
                                defaultChecked={setDQTwo == "4" ? true : false}
                                value="4"
                                name="setDQTwo"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQThree(e)}
                                type="radio"
                                defaultChecked={setDQThree == "4" ? true : false}
                                value="4"
                                name="setDQThree"
                                type="radio"
                              />
                            </Row>
                          </Col>
                          <Col span={3}>
                            <Row>
                              <span className={"fw-bold"}>5</span>
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQOne(e)}
                                type="radio"
                                defaultChecked={setDQOne == "5" ? true : false}
                                value="5"
                                name="setDQOne"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQTwo(e)}
                                type="radio"
                                defaultChecked={setDQTwo == "5" ? true : false}
                                value="5"
                                name="setDQTwo"
                                type="radio"
                              />
                            </Row>
                            <Row style={{ height: "22px" }}>
                              <input
                                onChange={(e) => handleSetDQThree(e)}
                                type="radio"
                                defaultChecked={setDQThree == "5" ? true : false}
                                value="5"
                                name="setDQThree"
                                type="radio"
                              />
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>**/}

                  <Row>
                    {/*
                      <span className={"fw-bold"}>V. ADMINISTRATION</span>
                    */}
                    <span className={'fw-bold'}>III. ADMINISTRATION</span>
                  </Row>
                  <Row>
                    <span className={'fw-bold'}>1 = Poor, 5 = Excellent</span>
                  </Row>
                  <Row className={'mb-3'}>
                    <Col style={{ width: 550 }}>
                      <Row>
                        <Col span={9}>
                          <Row style={{ height: '22px' }}></Row>
                          <Row>
                            Mastery of the Subject{' '}
                            <em className='asterisk'>**</em>
                          </Row>
                          <Row>
                            Time Management <em className='asterisk'>**</em>
                          </Row>
                          <Row>
                            Professional Conduct{' '}
                            <em className='asterisk'>**</em>
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>1</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQOne(e)}
                              type='radio'
                              defaultChecked={setEQOne == '1' ? true : false}
                              value='1'
                              name='setEQOne'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQTwo(e)}
                              type='radio'
                              defaultChecked={setEQTwo == '1' ? true : false}
                              value='1'
                              name='setEQTwo'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQThree(e)}
                              type='radio'
                              defaultChecked={setEQThree == '1' ? true : false}
                              value='1'
                              name='setEQThree'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>2</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQOne(e)}
                              type='radio'
                              defaultChecked={setEQOne == '2' ? true : false}
                              value='2'
                              name='setEQOne'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQTwo(e)}
                              type='radio'
                              defaultChecked={setEQTwo == '2' ? true : false}
                              value='2'
                              name='setEQTwo'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQThree(e)}
                              type='radio'
                              defaultChecked={setEQThree == '2' ? true : false}
                              value='2'
                              name='setEQThree'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>3</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQOne(e)}
                              type='radio'
                              defaultChecked={setEQOne == '3' ? true : false}
                              value='3'
                              name='setEQOne'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQTwo(e)}
                              type='radio'
                              defaultChecked={setEQTwo == '3' ? true : false}
                              value='3'
                              name='setEQTwo'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQThree(e)}
                              type='radio'
                              defaultChecked={setEQThree == '3' ? true : false}
                              value='3'
                              name='setEQThree'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>4</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQOne(e)}
                              type='radio'
                              defaultChecked={setEQOne == '4' ? true : false}
                              value='4'
                              name='setEQOne'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQTwo(e)}
                              type='radio'
                              defaultChecked={setEQTwo == '4' ? true : false}
                              value='4'
                              name='setEQTwo'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQThree(e)}
                              type='radio'
                              defaultChecked={setEQThree == '4' ? true : false}
                              value='4'
                              name='setEQThree'
                            />
                          </Row>
                        </Col>
                        <Col span={3}>
                          <Row>
                            <span className={'fw-bold'}>5</span>
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQOne(e)}
                              type='radio'
                              defaultChecked={setEQOne == '5' ? true : false}
                              value='5'
                              name='setEQOne'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQTwo(e)}
                              type='radio'
                              defaultChecked={setEQTwo == '5' ? true : false}
                              value='5'
                              name='setEQTwo'
                            />
                          </Row>
                          <Row style={{ height: '22px' }}>
                            <input
                              onChange={(e) => handleSetEQThree(e)}
                              type='radio'
                              defaultChecked={setEQThree == '5' ? true : false}
                              value='5'
                              name='setEQThree'
                            />
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {trainingItem.hasAssessment == 'true' ? (
                <>
                  <Row className={'mt-5 mb-3'}>
                    <h4>III. Post Assessment</h4>
                  </Row>
                  <Row>
                    <span style={{ fontSize: 16, textAlign: 'left' }}>
                      <b>Instructions: </b>Please answer all the questions that
                      follow to have a complete picture of your updated
                      knowledge after attending the Onboarding and Orientation
                      Program. Please read carefully and select the best answer.
                    </span>
                    <br />
                    <br />
                  </Row>
                  <Row className={'mb-4'} justify='center'>
                    <Col style={{ width: 800 }}>
                      <Row>
                        <label
                          style={{ fontSize: 16, textAlign: 'left' }}
                          className={'loginInput-label mb-3'}
                          htmlFor='preAssessment'
                        >
                          1. An appointment issued to a person who meets all the
                          qualification requirements of the position to which
                          he/she is being appointed to, including the
                          appropriate eligibility, in accordance with the
                          provisions of law, rules and standards.{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row
                        onChange={handleQuestionOne}
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionOne == 'questionOneA' ? true : false
                              }
                              value='questionOneA'
                              style={{ fontSize: 16 }}
                              name='questionOneSet'
                              type='radio'
                              id='plantillaOneA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='plantillaOneA'
                            >
                              a. Plantilla
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionOne == 'questionOneB' ? true : false
                              }
                              value='questionOneB'
                              style={{ fontSize: 16 }}
                              name='questionOneSet'
                              type='radio'
                              id='contractOfServiceOneA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='contractOfServiceOneA'
                            >
                              b. Contract of Service
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionOne == 'questionOneC' ? true : false
                              }
                              value='questionOneC'
                              style={{ fontSize: 16 }}
                              name='questionOneSet'
                              type='radio'
                              id='jobOrderOneA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='jobOrderOneA'
                            >
                              c. Job Order
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionOne == 'questionOneD' ? true : false
                              }
                              value='questionOneD'
                              style={{ fontSize: 16 }}
                              name='questionOneSet'
                              type='radio'
                              id='consultantOneA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='consultantOneA'
                            >
                              d. Consultant
                            </label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={'mb-4'} justify='center'>
                    <Col style={{ width: 800 }}>
                      <Row>
                        <label
                          style={{ fontSize: 16, textAlign: 'left' }}
                          className={'loginInput-label mb-3'}
                          htmlFor='preAssessment'
                        >
                          2. Refers to the hiring of a worker for piece work or
                          intermittent job of short duration not exceeding six
                          months and pay is on a daily or hourly basis. Such as
                          clearing of debris on the roads, canals, waterways,
                          etc. after natural/man-made disasters/occurrences and
                          other manual/trades and crafts services such as
                          carpentry, plumbing, electrical and the like.{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row
                        onChange={handleQuestionTwo}
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionTwo == 'questionTwoA' ? true : false
                              }
                              value='questionTwoA'
                              style={{ fontSize: 16 }}
                              name='questionTwoSet'
                              type='radio'
                              id='plantillaTwoA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='plantillaTwoA'
                            >
                              a. Plantilla
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionTwo == 'questionTwoB' ? true : false
                              }
                              value='questionTwoB'
                              style={{ fontSize: 16 }}
                              name='questionTwoSet'
                              type='radio'
                              id='contractOfServiceTwoA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='contractOfServiceTwoA'
                            >
                              b. Contract of Service
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionTwo == 'questionTwoC' ? true : false
                              }
                              value='questionTwoC'
                              style={{ fontSize: 16 }}
                              name='questionTwoSet'
                              type='radio'
                              id='jobOrderTwoA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='jobOrderTwoA'
                            >
                              c. Job Order
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionTwo == 'questionTwoD' ? true : false
                              }
                              value='questionTwoD'
                              style={{ fontSize: 16 }}
                              name='questionTwoSet'
                              type='radio'
                              id='consultantTwoA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='consultantTwoA'
                            >
                              d. Consultant
                            </label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={'mb-4'} justify='center'>
                    <Col style={{ width: 800 }}>
                      <Row>
                        <label
                          style={{ fontSize: 16, textAlign: 'left' }}
                          className={'loginInput-label mb-3 mt-3'}
                          htmlFor='preAssessment'
                        >
                          3. Which among the civilian clothing is prohibited to
                          wear by employees of Quezon City Government?{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row
                        onChange={handleQuestionThree}
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionThree == 'questionThreeA' ? true : false
                              }
                              value='questionThreeA'
                              style={{ fontSize: 16 }}
                              name='questionThreeSet'
                              type='radio'
                              id='threeAA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='threeAA'
                            >
                              a. strapless, or spaghetti-strap blouse, tank
                              tops, blouse with over-plunging necklines
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionThree == 'questionThreeB' ? true : false
                              }
                              value='questionThreeB'
                              style={{ fontSize: 16 }}
                              name='questionThreeSet'
                              type='radio'
                              id='threeBA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='threeBA'
                            >
                              b. Barong/ Filipiniana Dress
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionThree == 'questionThreeC' ? true : false
                              }
                              value='questionThreeC'
                              style={{ fontSize: 16 }}
                              name='questionThreeSet'
                              type='radio'
                              id='threeCA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='threeCA'
                            >
                              c. Collared polo shirt
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionThree == 'questionThreeD' ? true : false
                              }
                              value='questionThreeD'
                              style={{ fontSize: 16 }}
                              name='questionThreeSet'
                              type='radio'
                              id='threeDA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='threeDA'
                            >
                              d. Blazer
                            </label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={'mb-4'} justify='center'>
                    <Col style={{ width: 800 }}>
                      <Row>
                        <label
                          style={{ fontSize: 16, textAlign: 'left' }}
                          className={'loginInput-label mb-3 mt-3'}
                          htmlFor='preAssessment'
                        >
                          4. How many hours should a COS employee work per week?{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row
                        onChange={handleQuestionFour}
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFour == 'questionFourA' ? true : false
                              }
                              value='questionFourA'
                              style={{ fontSize: 16 }}
                              name='questionFourSet'
                              type='radio'
                              id='fourAA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='fourAA'
                            >
                              a. 40 hours
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFour == 'questionFourB' ? true : false
                              }
                              value='questionFourB'
                              style={{ fontSize: 16 }}
                              name='questionFourSet'
                              type='radio'
                              id='fourBA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='fourBA'
                            >
                              b. 48 hours
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFour == 'questionFourC' ? true : false
                              }
                              value='questionFourC'
                              style={{ fontSize: 16 }}
                              name='questionFourSet'
                              type='radio'
                              id='fourCA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='fourCA'
                            >
                              c. 24 hours
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFour == 'questionFourD' ? true : false
                              }
                              value='questionFourD'
                              style={{ fontSize: 16 }}
                              name='questionFourSet'
                              type='radio'
                              id='fourD'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='fourDA'
                            >
                              d. 36 hours
                            </label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className={'mb-4'} justify='center'>
                    <Col style={{ width: 800 }}>
                      <Row>
                        <label
                          style={{ fontSize: 16, textAlign: 'left' }}
                          className={'loginInput-label mb-3 mt-3'}
                          htmlFor='preAssessment'
                        >
                          5. Which among the following does not belong to
                          RA6713? <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row
                        onChange={handleQuestionfive}
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFive == 'questionFiveA' ? true : false
                              }
                              value='questionFiveA'
                              style={{ fontSize: 16 }}
                              name='questionFiveSet'
                              type='radio'
                              id='fiveAA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='fiveAA'
                            >
                              a. Responsiveness
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFive == 'questionFiveB' ? true : false
                              }
                              value='questionFiveB'
                              style={{ fontSize: 16 }}
                              name='questionFiveSet'
                              type='radio'
                              id='fiveBA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='fiveBA'
                            >
                              b. Commitment to Democracy
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFive == 'questionFiveC' ? true : false
                              }
                              value='questionFiveC'
                              style={{ fontSize: 16 }}
                              name='questionFiveSet'
                              type='radio'
                              id='fiveCA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='fiveC'
                            >
                              c. Justness and Sincerity
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              defaultChecked={
                                questionFive == 'questionFiveD' ? true : false
                              }
                              value='questionFiveD'
                              style={{ fontSize: 16 }}
                              name='questionFiveSet'
                              type='radio'
                              id='fiveDA'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='fiveDA'
                            >
                              d. Loyalty
                            </label>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <em className='requiredFields'>
                      ** All fields are required
                    </em>
                  </Row>
                </>
              ) : null}
            </>
          ) : null}
        </Col>
      </Row>

      <Row justify='end'>
        <Button
          disabled={handleDisable() == false ? false : true}
          onClick={handleSubmit}
          type='primary'
        >
          Submit
        </Button>
      </Row>
    </>
  )
}
