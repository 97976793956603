/**
 * Title: pendingHome.js
 * Description: This is a file that contains the components that displays the pending home page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/pendingHome.js
 **/

import React, { useState, useEffect } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  getAssessmentQuestions,
  getEvaluationQuestions,
} from '../../api/queries'

import { toRoman } from '../../utilities/toRoman'
import { numToString } from '../../utilities/numToString'
import { convertNumToChar } from '../../utilities/convertNumToChar'

import { Row, Col, Input } from 'antd'

const { TextArea } = Input

export default function PostAnswersCustom({ data, record }) {
  const newAnswers = JSON.parse(data.postEvalQuestions)
  const oldAnswers = {
    setAQOne: data.clarityOfObjectives,
    setAQTwo: data.relevanceOfContent,
    setAQThree: data.sequenceOfContent,
    setBQOne: data.facilitationMOTS,
    setBQTwo: data.facilitationTimeMgt,
    setBQThree: data.facilitationProfConduct,
    setCQOne: data.adminMOTS,
    setCQTwo: data.adminTimeMgt,
    setCQThree: data.adminProfConduct,
  }

  const evalUserAnswer = newAnswers ? newAnswers : oldAnswers
  const assessmentUserAnswer = JSON.parse(data.questionnaire)
  const assessID =
    record.training?.assessmentID || record.onlineTraining?.assessmentID
  const evalID = record.training?.evalID || record.onlineTraining?.evalID

  const [assessQuestions, setAssessQuestions] = useState(null)
  const [evalQuestions, setEvalQuestions] = useState(null)
  const [score, setScore] = useState(0)

  useEffect(() => {
    const fetchQuestions = async () => {
      //to get the questions of assessment
      if (assessID) {
        const assessQ = await API.graphql(
          graphqlOperation(getAssessmentQuestions, { id: assessID })
        )
        const assessQuestionnaire =
          assessQ.data.getAssessmentQuestions.assessmentQuestionnaire
        const parsedAssessQuestions = JSON.parse(assessQuestionnaire)
        setAssessQuestions(parsedAssessQuestions)

        // To get the score of the user
        let tempScore = 0
        parsedAssessQuestions.forEach((elem, index) => {
          const numString = numToString(index)
          const question = 'question' + numString
          let oldAnswers =
            assessmentUserAnswer[question] == 'plantilla'
              ? 'A'
              : assessmentUserAnswer[question] == 'jobOrder'
              ? 'C'
              : assessmentUserAnswer[question] == 'contractOfService'
              ? 'B'
              : assessmentUserAnswer[question] == 'consultant'
              ? 'D'
              : assessmentUserAnswer[question][
                  assessmentUserAnswer[question].length - 1
                ]
          const userAnswer =
            assessmentUserAnswer[question].length > 1
              ? oldAnswers
              : assessmentUserAnswer[question]

          if (parsedAssessQuestions[index].correctAnswer == userAnswer) {
            tempScore++
          }
        })
        setScore(tempScore)
      }

      //to get the questions of evaluation
      if (evalID) {
        const evalQ = await API.graphql(
          graphqlOperation(getEvaluationQuestions, { id: evalID })
        )
        const evalData = evalQ.data.getEvaluationQuestions.eval
        const parsedEvalQuestions = JSON.parse(evalData)
        setEvalQuestions(parsedEvalQuestions)
      }
      // let tempScore = 0;
      // if (assessQuestions) {
      //   assessQuestions.forEach((elem, index) => {
      //     const numString = numToString(index);
      //     const question = "question" + numString
      //     if (assessQuestions[index].correctAnswer == assessmentUserAnswer[question]) {
      //       tempScore++;
      //     }
      //   });
      // }
      // setScore(tempScore);
    }
    fetchQuestions()
  }, [assessID, evalID])

  let showEvaluationQuestions
  // Mapping the evaluations questions
  if (evalQuestions) {
    showEvaluationQuestions = evalQuestions.map((elem, index) => {
      const numberStrings = ['One', 'Two', 'Three', 'Four', 'Five', 'Six']
      const letter = convertNumToChar(index)
      return (
        <React.Fragment key={index}>
          <Row>
            <span className={'fw-bold'}>
              {toRoman(index + 1)}. {elem.question}
            </span>
          </Row>
          {elem.categories.length > 0 ? (
            <>
              {elem.description && (
                <Row>
                  <span className={'text-muted py-2'}>{elem.description}</span>
                </Row>
              )}
              <Row className={'mb-3'}>
                <Col style={{ width: 730 }}>
                  <Row>
                    <Col span={13}></Col>
                    <Col
                      span={11}
                      className='d-flex justify-content-around mt-3'
                    >
                      <span className='fw-bold'>1</span>
                      <span className='fw-bold'>2</span>
                      <span className='fw-bold'>3</span>
                      <span className='fw-bold'>4</span>
                      <span className='fw-bold'>5</span>
                      <span className='fw-bold'>n/a</span>
                    </Col>
                    <Col span={24}>
                      <Row style={{ height: '10px' }}></Row>
                      {elem.categories.map((category, ci) => {
                        return (
                          <div
                            key={ci}
                            className='d-flex align-items-center mt-3
                      '
                          >
                            <Col span={13} key={ci} className='text-end'>
                              {category[`category-${ci + 1}-${index + 1}`]}{' '}
                              <em className='asterisk'>**</em>
                            </Col>
                            <Col
                              span={11}
                              className='d-flex justify-content-around'
                            >
                              <Row style={{ height: '22px' }}>
                                <input
                                  defaultChecked={
                                    evalUserAnswer[
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    ] == '1'
                                      ? true
                                      : false
                                  }
                                  name={
                                    'set' + letter + 'Q' + numberStrings[ci]
                                  }
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  defaultChecked={
                                    evalUserAnswer[
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    ] == '2'
                                      ? true
                                      : false
                                  }
                                  name={
                                    'set' + letter + 'Q' + numberStrings[ci]
                                  }
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  defaultChecked={
                                    evalUserAnswer[
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    ] == '3'
                                      ? true
                                      : false
                                  }
                                  name={
                                    'set' + letter + 'Q' + numberStrings[ci]
                                  }
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  defaultChecked={
                                    evalUserAnswer[
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    ] == '4'
                                      ? true
                                      : false
                                  }
                                  name={
                                    'set' + letter + 'Q' + numberStrings[ci]
                                  }
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  defaultChecked={
                                    evalUserAnswer[
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    ] == '5'
                                      ? true
                                      : false
                                  }
                                  name={
                                    'set' + letter + 'Q' + numberStrings[ci]
                                  }
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  defaultChecked={
                                    evalUserAnswer[
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    ] == 'n/a'
                                      ? true
                                      : false
                                  }
                                  name={
                                    'set' + letter + 'Q' + numberStrings[ci]
                                  }
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                            </Col>
                          </div>
                        )
                      })}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <Row className={'mb-3 mt-3'}>
              <Col span={24} style={{ width: '550px' }}>
                <TextArea
                  /* onChange={(e) =>
                    handleTextareaChange(e, elem.question, index)
                  } */
                  value={evalUserAnswer['set' + letter + 'Comment']}
                  id={`additionalComments${index}`}
                  style={{ fontSize: 16 }}
                  rows={5}
                />
              </Col>
            </Row>
          )}
        </React.Fragment>
      )
    })
  }

  // Mapping the assessment questions
  let showAssessmentQuestions
  if (assessQuestions) {
    showAssessmentQuestions = assessQuestions.map((elem, index) => {
      const numString = numToString(index)
      const question = 'question' + numString
      let oldAnswers =
        assessmentUserAnswer[question] == 'plantilla'
          ? 'A'
          : assessmentUserAnswer[question] == 'jobOrder'
          ? 'C'
          : assessmentUserAnswer[question] == 'contractOfService'
          ? 'B'
          : assessmentUserAnswer[question] == 'consultant'
          ? 'D'
          : assessmentUserAnswer[question][
              assessmentUserAnswer[question].length - 1
            ]
      const userAnswer =
        assessmentUserAnswer[question].length > 1
          ? oldAnswers
          : assessmentUserAnswer[question]

      return (
        <div key={index} className='col-10 offset-1'>
          <Row className='mt-4'>
            <span style={{ fontSize: 16, textAlign: 'left' }}>
              <strong>
                {toRoman(index + 1)}. {elem.question}
              </strong>
            </span>
          </Row>
          <Row
            align='center'
            justify='center'
            className='flex-column ms-3 mt-2'
          >
            {elem.choices[0] &&
              elem.choices[1] &&
              elem.choices[2] &&
              elem.choices[3] && (
                <>
                  {elem.choices[0][`choices-1-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <Row className='p-1'>
                      <input
                        // onChange={(e) => handleSetAnsAssessQuestion(e)}
                        defaultChecked={userAnswer == 'A' ? true : false}
                        value='A'
                        name={'question' + numString}
                        type='radio'
                      />
                      <span
                        className={
                          elem.correctAnswer == 'A'
                            ? 'm-0 ms-3'
                            : 'fw-normal m-0 ms-3'
                        }
                      >
                        <em>A. </em>
                        {elem.choices[0][`choices-1-${index + 1}`]}
                      </span>
                    </Row>
                  )}
                  {elem.choices[1][`choices-2-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <Row className='p-1'>
                      <input
                        // onChange={(e) => handleSetAnsAssessQuestion(e)}
                        defaultChecked={userAnswer == 'B' ? true : false}
                        value='B'
                        name={'question' + numString}
                        type='radio'
                      />
                      <span
                        className={
                          elem.correctAnswer == 'B'
                            ? 'fw-bold m-0 ms-3'
                            : 'fw-normal m-0 ms-3'
                        }
                      >
                        <em>B. </em>
                        {elem.choices[1][`choices-2-${index + 1}`]}
                      </span>
                    </Row>
                  )}
                  {elem.choices[2][`choices-3-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <Row className='p-1'>
                      <input
                        // onChange={(e) => handleSetAnsAssessQuestion(e)}
                        defaultChecked={userAnswer == 'C' ? true : false}
                        value='C'
                        name={'question' + numString}
                        type='radio'
                      />
                      <span
                        className={
                          elem.correctAnswer == 'C'
                            ? 'fw-bold m-0 ms-3'
                            : ' fw-normal m-0 ms-3'
                        }
                      >
                        <em>C. </em>
                        {elem.choices[2][`choices-3-${index + 1}`]}
                      </span>
                    </Row>
                  )}
                  {elem.choices[3][`choices-4-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <Row className='p-1 font-weight-bold'>
                      <input
                        // onChange={(e) => handleSetAnsAssessQuestion(e)}
                        defaultChecked={userAnswer == 'D' ? true : false}
                        value='D'
                        name={'question' + numString}
                        type='radio'
                      />
                      <span
                        className={
                          elem.correctAnswer == 'D'
                            ? 'fw-bold m-0 ms-3'
                            : 'fw-normal m-0 ms-3'
                        }
                      >
                        <em>D. </em>
                        {elem.choices[3][`choices-4-${index + 1}`]}
                      </span>
                    </Row>
                  )}
                  <TextArea
                    hidden={elem.correctAnswer !== 'n/a'}
                    name={'question' + numString}
                    className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                    value={assessmentUserAnswer[question]}
                  />
                </>
              )}
          </Row>
        </div>
      )
    })
  }

  return (
    <>
      {evalQuestions && (
        <>
          <Row className={'mb-3 mt-3'}>
            <h4>I. Post Evaluation</h4>
          </Row>
          <Row>
            <span className={'fw-bold mb-3'}>I. PROGRAM DESIGN</span>
          </Row>
          <Row style={{ fontSize: 16 }} justify='center'>
            <Col>{showEvaluationQuestions}</Col>
          </Row>
        </>
      )}

      {data && data.additionalComments ? (
        <div className='ms-md-5 me-md-5'>
          <Row justify='center' className='mt-3'>
            <Col className='col-md-10 col-12'>
              <Row className='mb-2'>
                <label
                  style={{ fontSize: 16 }}
                  className='loginInput-label ms-0 ps-0'
                  htmlFor='additionalComments'
                >
                  Additional Comments/Feedback{' '}
                  <span className='asterisk'>**</span>
                </label>
              </Row>
              <TextArea
                defaultValue={data.additionalComments}
                id='additionalComments'
                style={{ fontSize: 16 }}
                rows={5}
              />
            </Col>
          </Row>
        </div>
      ) : null}

      {assessQuestions && (
        <>
          <h4 className='mb-3 mt-5'>III. Post Assessment</h4>
          <h5
            style={{ fontSize: 16, color: 'blue' }}
            className='mb-3 d-flex flex-column gap-3'
          >
            <em>Note: item in bold is the correct answer.</em>
            <em>Note: Essay question is not included in the correct answer </em>
          </h5>
          <span className='fw-bold'>
            <h4>
              <em>Total score: {score}</em>
            </h4>
            <div className='ms-5'>{showAssessmentQuestions}</div>
          </span>
        </>
      )}
    </>
  )
}
