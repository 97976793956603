import React from 'react'

import { Button, Form, Input } from 'antd'
import PropTypes from 'prop-types'

/**
 * SearchForm component renders a form to search for an employee by their username.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.onSearch - The function to call when the form is submitted.
 *
 * @returns {JSX.Element} The rendered SearchForm component.
 */
const SearchForm = ({ onSearch }) => {
  const [form] = Form.useForm()

  const handleSubmit = (values) => {
    onSearch(values.email)
  }

  return (
    <Form form={form} onFinish={handleSubmit} layout='vertical'>
      <Form.Item
        name='email'
        rules={[
          { required: true, message: `Please enter the employee's username.` },
          { type: 'email', message: 'Please enter a valid username' },
        ]}
      >
        <Input placeholder={`Enter employee's username.`} />
      </Form.Item>
      <Form.Item>
        <Button type='primary' htmlType='submit'>
          Search
        </Button>
      </Form.Item>
    </Form>
  )
}

export default SearchForm

SearchForm.propTypes = {
  onSearch: PropTypes.func.isRequired,
}
