/**
 * Title: dataPrivacy.js
 * Description: This is a file that contains the components for the data privacy page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Register/dataPrivacy.js
 **/

import React from 'react'
// import { Checkbox, Input, notification, Select } from "antd";
import moment from 'moment'

// const { Option } = Select;

export default class DataPrivacy extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    var date = moment().format('MMMM Do YYYY')
    var day = moment().format('dddd')
    this.setState({ date: date, day: day })
  }

  render() {
    return (
      <>
        <div className='mainLogin-div'>
          <div className='mainHeader-login'>
            <div className='loginHead-title'>
              <img
                src='https://quezoncity.gov.ph/wp-content/themes/qcproject/images/qclogo_main.png'
                alt='QCLogo'
              />
              <span>Quezon City</span>
            </div>
            <div className='loginHead-date'>
              {`${this.state.day}, ${this.state.date}`}
            </div>
          </div>
          <div
            className='loginCard-div'
            style={{ height: '540px', texAlign: 'center' }}
          >
            <iframe
              title='DATA PRIVACY (QC-ETNA)'
              style={{ width: '100%', height: '100%' }}
              src='https://docs.google.com/document/d/e/2PACX-1vQMLvkormhJitBDwBA9kBKp8BZoMWwPzO0tz5RPfRW-zNp888X9MRO2DfuN_QfyGxD0l0OBz05oSNGw/pub?embedded=true'
            ></iframe>
          </div>
        </div>
      </>
    )
  }
}
