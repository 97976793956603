/**
 * Title: viewForm.js
 * Description: This is a file that contains to view the form
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Form/viewForm.js
 **/

import React, { Component } from 'react'

import {
  Breadcrumb,
  Table,
  Tag,
  Tabs,
  Button,
  Modal,
  Input,
  Select,
  notification,
  Card,
  Spin,
  Alert,
  Col,
  Row,
  Tooltip,
} from 'antd'
import {
  FundViewOutlined,
  FilePdfTwoTone,
  DeleteTwoTone,
} from '@ant-design/icons'

import { Form } from '@formio/react'

import {
  getForm,
  FormByUserId,
  IDLPUploadByUserId,
  Form2ByUserId,
} from '../../api/queries'
import {
  updateForm,
  updateForm2,
  updateUser,
  deleteIDLPUpload,
} from '../../api/mutations'
import { API, graphqlOperation } from 'aws-amplify'

import Swal from 'sweetalert2'

import html2canvas from 'html2canvas'

import pdfMake from 'pdfmake/build/pdfmake'

import './createForm2.css'

import IldpFormModalPrintPinoy from './ildpFormModalPrintPinoy'
import IldpFormModalPrintEnglish from './ildpFormModalPrintEnglish'

import PDFMerger from 'pdf-merger-js/browser'

import moment from 'moment'

import PropTypes from 'prop-types'

const { TabPane } = Tabs
const { Option } = Select
const { TextArea } = Input
const secret_name = 'hrm-2023@secrets'

class ViewForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: null,
      isModalVisible: false,
      options: [],
      isStatus: '',
      formID: '',
      formSource: '',
      isLanguage: '',
      isLang: null,
      comp: null,
      loading: false,
      checkBoxArray: [],
      submissionDataIldp: {},
      setIsDeleted: null,
      ildpButton: false,
      showSpinner: false,
      showILDPArchived: false,
      showETNAArchived: false,
    }
  }

  uuidv4() {
    try {
      const crypto = window.crypto || window.msCrypto
      const array = new Uint32Array(5)
      crypto.getRandomValues(array)

      return (
        array[0].toString(16).padStart(8, '0') +
        '-' +
        array[1].toString(16).padStart(4, '0') +
        '-4' +
        array[2].toString(12).padStart(3, '0') +
        '-y' +
        array[3].toString(12).padStart(3, '0') +
        '-' +
        array[4].toString(16).padStart(12, '0')
      )
    } catch (error) {
      console.log(error)
    }
  }

  // this will put finalSubmitDate to etna with null finalSubmitDates
  checkIfHasNoFinalSubmitDate = async () => {
    try {
      const user = this.props.user
      const result = await API.graphql(
        graphqlOperation(FormByUserId, { userID: user.id })
      )

      const foundItems = result.data.FormByUserId.items.filter((obj) => {
        if (obj.finalSubmitDate === null) {
          return obj
        }
      })

      if (foundItems.length) {
        const promises = foundItems.map((item) => {
          return API.graphql(
            graphqlOperation(updateForm, {
              input: {
                id: item.id,
                finalSubmitDate: item.createdAt,
              },
            })
          )
        })

        const afterUpdates = await Promise.all(promises)

        if (afterUpdates) {
          setTimeout(() => {
            window.location.reload()
          }, 3000)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }
  componentDidMount() {
    this.checkIfHasNoFinalSubmitDate()
    this.listILDPUploads()
    this.listForms()
  }

  handleDeleteUploaded = async (rec) => {
    try {
      const getUserForm2s = await API.graphql(
        graphqlOperation(Form2ByUserId, { userID: rec.userID })
      )
      const form2s = getUserForm2s.data.Form2ByUserId.items.filter(
        (item) => item.etnaId === rec.etnaId
      )
      const promises = form2s.map(async (obj) => {
        const result = await API.graphql(
          graphqlOperation(updateForm2, {
            input: { id: obj.id, isApproved: 0 },
          })
        )
        return result
      })
      const form2sResults = await Promise.all(promises)

      if (form2sResults) {
        const wasDeleted = await API.graphql(
          graphqlOperation(deleteIDLPUpload, { input: { id: rec.id } })
        )

        this.setState({ setIsDeleted: wasDeleted })

        if (wasDeleted) {
          const input = {
            id: rec.userID,
            form: 1,
            form2: 0,
            isTakeSurvey: 0,
          }

          const updateUserStep2 = await API.graphql(
            graphqlOperation(updateUser, { input })
          )

          if (updateUserStep2) {
            notification['info']({
              message: 'Information',
              description: `You have deleted an Uploaded ILDP form and you're now back to Step 2.`,
              duration: 6,
            })

            this.props.refresh(this.uuidv4())
            setTimeout(() => {
              this.props.afterSubmit('employeeForms')
            }, 3000)
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Provides a threshold date string based on the current date.
   *
   * @returns {string} A string representing the threshold date in the format "Month Day, Year HH:MM:SS AM/PM".
   *                   If the current date is before July 1 of the current year, it returns "July 1, 2023 12:00:00 AM".
   *                   Otherwise, it returns "July 1, [current year] 12:00:00 AM".
   *
   * @throws {Error} Logs any error that occurs during execution to the console.
   *
   * @example
   * // If current date is May 15, 2024
   * const threshold = thresholdProvider();
   * console.log(threshold); // Output: "July 1, 2023 12:00:00 AM"
   *
   * // If current date is August 1, 2024
   * const threshold = thresholdProvider();
   * console.log(threshold); // Output: "July 1, 2024 12:00:00 AM"
   */
  thresholdProvider = () => {
    try {
      // Get the current date
      let currentDate = new Date()

      // Set the threshold date to July 1, current year
      let thresholdDate = new Date(currentDate.getFullYear(), 6, 1) // Note: Month is zero-indexed (0 = January, 6 = July)

      // Declare a variable to store the threshold string
      let thresholdString

      // Compare the current date with the threshold date
      if (currentDate < thresholdDate) {
        // Current date is before the threshold
        thresholdString = 'July 1, 2023 12:00:00 AM'

        return thresholdString
      } else {
        // Current date is on or after the threshold
        let currentYear = currentDate.getFullYear()
        thresholdString = 'July 1, ' + currentYear + ' 12:00:00 AM'

        return thresholdString
      }
    } catch (err) {
      console.log(err)
    }
  }

  listForms = async (e) => {
    const thresholdFound = this.thresholdProvider()

    let arr = []
    let a = []
    let x = []
    let c = ''
    let d = ''

    const user = this.props.user

    try {
      const result = await API.graphql(
        graphqlOperation(FormByUserId, { userID: user.id })
      )
      let filter = result.data.FormByUserId.items
      this.setState({ data1: filter })
      if (filter.length) {
        filter.forEach((item, i) => {
          arr.push({ ...item, key: i + 1 })
          c = filter[0].isFinal
          d = filter[0].id
          a.push({
            level: new Date(item.createdAt).getFullYear(),
            value: new Date(item.createdAt).getFullYear(),
          })
          x.push({
            level: [...new Set(a.map((data) => data.level))],
            value: [...new Set(a.map((data) => data.level))],
          })
        })
      }
      const i = []
      i.push({ level: x[0].level[0], value: x[0].value[0] })

      const filteredSubmissions = arr.filter((submission) => {
        const date = moment(
          new Date(submission.finalSubmitDate),
          'MMMM D, YYYY'
        )
        const threshold = moment(thresholdFound, 'MMMM D, YYYY h:mm:ss A')

        return date.isSameOrAfter(threshold, 'day')
      })

      const archivedSubmissions = arr.filter((submission) => {
        const date = moment(
          new Date(submission.finalSubmitDate),
          'MMMM D, YYYY'
        )
        const threshold = moment(thresholdFound, 'MMMM D, YYYY h:mm:ss A')
        return date.isBefore(threshold, 'day')
      })

      this.setState({
        data: archivedSubmissions.sort(
          (a, b) => new Date(b.finalSubmitDate) - new Date(a.finalSubmitDate)
        ),
        currentPeriodData: filteredSubmissions.sort(
          (a, b) => new Date(b.finalSubmitDate) - new Date(a.finalSubmitDate)
        ),
        allData: archivedSubmissions,
        options: i,
        isStatus: c,
        formID: d,
      })
    } catch (e) {
      console.log(e)
    }
  }

  handleShowSurveybyId = async (e) => {
    try {
      console.log(this.state.isStatus)
      const result = await API.graphql(graphqlOperation(getForm, { id: e }))
      const filter = result.data.getForm

      this.setState({
        submissionData: JSON.parse(filter.form),
        formID: filter.id,
        isStatus: result.data.getForm.isFinal,
        formSource:
          filter.isLanguage === '1' ? 'samplesurvey' : 'etnafilipinoversion',
        language: filter.isLanguage,
        isModalVisible: true,
        comp: null,
      })
    } catch (e) {
      console.log(e)
    }
  }

  handleForm = (e) => {
    try {
      if (this.state.isStatus === 'NO') {
        if (e !== undefined) {
          let arr = []
          e.components.forEach((item) => {
            if (this.props.viewer === 'self') {
              if (item.key === 'submit' && this.state.isStatus === null) {
                arr.push({ ...item, disabled: false, label: 'Update' })
              } else if (
                item.key === 'submit' &&
                this.state.isStatus === 'YES'
              ) {
                arr.push({ ...item, disabled: true })
              } else {
                arr.push(item)
              }
            }
          })

          this.setState({ comp: arr, dataCom: e })
        }
      } else {
        const submitDiv = document.getElementsByClassName(
          'formio-component-submit'
        )

        submitDiv[0].style.display = 'none'
        setTimeout(() => {
          document
            .querySelectorAll('.submitted-etna-page-modal button')
            .forEach((i) => {
              i.parentNode.remove()
            })
          document
            .querySelectorAll(
              '.submitted-etna-page-modal input, .submitted-etna-page-modal textarea'
            )
            .forEach((i) => {
              i.disabled = true
            })
        }, 1000)

        setTimeout(() => {
          document
            .querySelectorAll('.submitted-etna-page-modal select')
            .forEach((i) => {
              let selectedValue = i.options[0].value
              let inputElement = document.createElement('input')
              inputElement.value =
                selectedValue.includes('Less') &&
                !selectedValue.includes('LessThan')
                  ? selectedValue.replace('Less', ' - less than ')
                  : selectedValue.includes('LessThan')
                  ? selectedValue.replace('LessThan', ' - less than ')
                  : selectedValue.includes('OrMoreYears')
                  ? selectedValue.replace('OrMoreYears', 'or more years ')
                  : selectedValue
              inputElement.setAttribute('aria-required', 'false')
              inputElement.setAttribute('spellcheck', 'true')
              inputElement.setAttribute('lang', 'en')
              inputElement.setAttribute('class', 'form-control')
              inputElement.setAttribute('type', 'text')
              inputElement.setAttribute('ref', 'input')
              inputElement.setAttribute('aria-invalid', 'false')
              inputElement.setAttribute('disabled', '')
              i.parentNode.insertAdjacentElement('afterend', inputElement)
              i.parentNode.remove()
            })
        }, 1000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  userUpdate = async (user, b) => {
    try {
      if (b == '1') {
        const result = await API.graphql(
          graphqlOperation(updateUser, {
            input: {
              id: user.id,
              form: b,
              form2: '0',
            },
          })
        )
        if (result) {
          this.props.refresh(this.uuidv4())
          this.props.afterSubmit('employeeForms')
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  handleSubmit = (e) => {
    const user = this.props.user
    let draftOrFinish
    if (this.state.language === '1') {
      Swal.fire({
        title: 'Let us save your Electronic Training Needs Analysis ',
        text: 'Final Copy will no longer be editable! Please save as Draft Copy if you want to edit it later. Thank You',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Final Copy',
        cancelButtonText: 'Draft Copy',
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
        .then((result) => {
          if (result.value) {
            draftOrFinish = '1'
            this.setState({ formSource: '' })
            return API.graphql(
              graphqlOperation(updateForm, {
                input: {
                  dept: user.department,
                  assignedDept: user.assignedDept,
                  division: user.division,
                  position: user.position,
                  userID: user.id,
                  form: JSON.stringify(e.data),
                  id: this.state.formID,
                  isFinal: 'YES',
                  finalSubmitDate: new Date(),
                },
              })
            )
          } else {
            draftOrFinish = '0'
            return API.graphql(
              graphqlOperation(updateForm, {
                input: {
                  dept: user.department,
                  assignedDept: user.assignedDept,
                  division: user.division,
                  position: user.position,
                  userID: user.id,
                  form: JSON.stringify(e.data),
                  id: this.state.formID,
                  isFinal: 'NO',
                },
              })
            )
          }
        })
        .then((nextRes) => {
          this.userUpdate(user, draftOrFinish)
          this.setState({ isModalVisible: false })
          this.listForms()
          notification['success']({
            message: 'Success',
            description: 'Your form has been updated. Thank you!',
          })
        })
        .catch((err) => {
          console.log(err)
          notification['error']({
            message: 'Error',
            description: "There's an error in updating your form.",
          })
        })
    } else {
      Swal.fire({
        title:
          'Ang iyong Electronic Training Needs Analysis ay record na namin online',
        text: 'Ang Final Copy ay hindi na maaring baguhin pa! At kung gusto mo pang baguhin ito pindutin ang Draft Copy! Salamat',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Final Copy',
        cancelButtonText: 'Draft Copy',
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
        .then((result) => {
          if (result.value) {
            draftOrFinish = '1'
            this.setState({ formSource: '' })
            return API.graphql(
              graphqlOperation(updateForm, {
                input: {
                  dept: user.department,
                  assignedDept: user.assignedDept,
                  division: user.division,
                  position: user.position,
                  userID: user.id,
                  form: JSON.stringify(e.data),
                  id: this.state.formID,
                  isFinal: 'YES',
                  finalSubmitDate: new Date(),
                },
              })
            )
          } else {
            draftOrFinish = '0'
            return API.graphql(
              graphqlOperation(updateForm, {
                input: {
                  dept: user.department,
                  assignedDept: user.assignedDept,
                  division: user.division,
                  position: user.position,
                  userID: user.id,
                  form: JSON.stringify(e.data),
                  id: this.state.formID,
                  isFinal: 'NO',
                },
              })
            )
          }
        })
        .then((nextRes) => {
          this.userUpdate(user, draftOrFinish)
          this.listForms()
          this.setState({ isModalVisible: false })
          notification['success']({
            message: 'Success',
            description: 'Ang yung e-TNA ay nabago na. Salamat',
          })
        })
        .catch((err) => console.log(err))
    }
  }

  handleOk = () => {
    this.setState({ isModalVisible: false })
  }

  listILDPforms = async () => {
    const thresholdFound = this.thresholdProvider()
    const user = this.props.user
    try {
      const listedIldpForms = await API.graphql(
        graphqlOperation(Form2ByUserId, { userID: user.id })
      )
      this.setState(
        { ildpForms: listedIldpForms.data.Form2ByUserId.items },
        () => {
          const ildpArr = this.state.ildpForms.map((row) => {
            return { ...row, key: row.id }
          })
          this.setState({ ildpArr }, () => {
            let sortIldp = [...this.state.ildpArr, ...this.state.newData].sort(
              (a, b) => {
                return new Date(b.updatedAt) - new Date(a.updatedAt)
              }
            )

            const filteredSubmissions = sortIldp.filter((submission) => {
              const date = moment(
                new Date(submission.updatedAt),
                'MMMM D, YYYY'
              )
              const threshold = moment(thresholdFound, 'MMMM D, YYYY h:mm:ss A')
              return date.isSameOrAfter(threshold, 'day')
            })

            const archivedSubmissions = sortIldp.filter((submission) => {
              const date = moment(
                new Date(submission.updatedAt),
                'MMMM D, YYYY'
              )
              const threshold = moment(thresholdFound, 'MMMM D, YYYY h:mm:ss A')
              return date.isBefore(threshold, 'day')
            })

            this.setState({
              allIldpsCurrentPeriod: filteredSubmissions,
              allIldpsArchived: archivedSubmissions,
            })
          })
        }
      )
    } catch (err) {
      console.log(err)
    }
  }

  listILDPUploads = async () => {
    const user = this.props.user
    try {
      const listedUploads = await API.graphql(
        graphqlOperation(IDLPUploadByUserId, { userID: user.id })
      )
      this.setState(
        { ildpUploads: listedUploads.data.IDLPUploadByUserId.items },
        () => {
          const newData = this.state.ildpUploads.map((row) => {
            return { ...row, key: row.id }
          })
          this.setState({ newData }, () => this.listILDPforms())
        }
      )
    } catch (err) {
      console.log(err)
    }
  }

  handleShowIldp = async (e) => {
    const b = document.getElementsByTagName('button')
    try {
      this.setState({
        submissionDataIldp: JSON.parse(e.form),
        isLang: e.filipino,
        isModalVisible: false,
        isModalVisibleIldp: true,
        comp: null,
      })

      setTimeout(() => {
        if (b['data[submit]']) {
          b['data[submit]'].remove()
        }
      }, 100)
    } catch (err) {
      console.log(err)
    }
  }

  handleOkIldp = () => {
    this.setState({ isModalVisibleIldp: false })
  }

  handleCancel = () => {
    this.setState({ isModalVisible: false })
  }

  handleCancelIldp = () => {
    this.setState({ isModalVisibleIldp: false, submissionDataIldp: {} })
  }

  handleDownload = async () => {
    try {
      const userName = this.props.user
      let pdfArr = []

      notification['info']({
        message: 'PDF Download',
        description: 'Your form is being generated. Please wait for a moment.',
        placement: 'bottomLeft',
        duration: 15,
      })

      this.setState({ ildpButton: true, showSpinner: true })

      const canvas1 = await html2canvas(document.getElementById('specific1'), {
        scale: 1.3,
      })
      const data1 = canvas1.toDataURL()
      const pdfExportSetting1 = {
        content: [
          {
            image: data1,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator1 = pdfMake.createPdf(pdfExportSetting1)
      pdfDocGenerator1.getBlob((blob1) => pdfArr.push(blob1))

      const canvas2 = await html2canvas(document.getElementById('specific2'), {
        scale: 1.3,
      })
      const data2 = canvas2.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting2 = {
        content: [
          {
            image: data2,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator2 = pdfMake.createPdf(pdfExportSetting2)
      pdfDocGenerator2.getBlob((blob2) => pdfArr.push(blob2))

      const canvas3 = await html2canvas(document.getElementById('specific3'), {
        scale: 1.3,
      })
      const data3 = canvas3.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting3 = {
        content: [
          {
            image: data3,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator3 = pdfMake.createPdf(pdfExportSetting3)
      pdfDocGenerator3.getBlob((blob3) => pdfArr.push(blob3))

      const canvas4 = await html2canvas(document.getElementById('specific4'), {
        scale: 1.3,
      })
      const data4 = canvas4.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting4 = {
        content: [
          {
            image: data4,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator4 = pdfMake.createPdf(pdfExportSetting4)
      pdfDocGenerator4.getBlob((blob4) => pdfArr.push(blob4))

      const canvas5 = await html2canvas(document.getElementById('specific5'), {
        scale: 1.3,
      })
      const data5 = canvas5.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting5 = {
        content: [
          {
            image: data5,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator5 = pdfMake.createPdf(pdfExportSetting5)
      pdfDocGenerator5.getBlob((blob5) => pdfArr.push(blob5))

      const canvas6 = await html2canvas(document.getElementById('specific6'), {
        scale: 1.3,
      })
      const data6 = canvas6.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting6 = {
        content: [
          {
            image: data6,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator6 = pdfMake.createPdf(pdfExportSetting6)
      pdfDocGenerator6.getBlob((blob6) => pdfArr.push(blob6))

      const canvas7 = await html2canvas(document.getElementById('specific7'), {
        scale: 1.3,
      })
      const data7 = canvas7.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting7 = {
        content: [
          {
            image: data7,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator7 = pdfMake.createPdf(pdfExportSetting7)
      pdfDocGenerator7.getBlob((blob7) => pdfArr.push(blob7))

      const canvas7a = await html2canvas(document.getElementById('specific7'), {
        scale: 1.3,
        y: 1000,
      })
      const data7a = canvas7a.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting7a = {
        content: [
          {
            image: data7a,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator7a = pdfMake.createPdf(pdfExportSetting7a)
      pdfDocGenerator7a.getBlob((blob7a) => pdfArr.push(blob7a))

      const canvas8 = await html2canvas(document.getElementById('specific8'), {
        scale: 1.3,
      })
      const data8 = canvas8.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting8 = {
        content: [
          {
            image: data8,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator8 = pdfMake.createPdf(pdfExportSetting8)
      pdfDocGenerator8.getBlob((blob8) => pdfArr.push(blob8))

      const canvas9 = await html2canvas(document.getElementById('specific9'), {
        scale: 1.3,
      })
      const data9 = canvas9.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting9 = {
        content: [
          {
            image: data9,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator9 = pdfMake.createPdf(pdfExportSetting9)
      pdfDocGenerator9.getBlob((blob9) => {
        pdfArr.push(blob9)

        this.setState({ showSpinner: false, isModalVisibleIldp: false })
        notification['success']({
          message: 'Success',
          description: 'Your form has been converted to pdf!',
        })

        const render = async () => {
          const merger = new PDFMerger()
          for (const file of pdfArr) {
            await merger.add(file)
          }
          await merger.save(
            `ILDP Form - ${userName.name}_${
              userName.mname ? userName.mname : ''
            }_${userName.lname}_${moment().format('MMMM D YYYY h:mm A')}`
          )
        }

        render()
      })
    } catch (err) {
      console.log(err)
    }
  }

  handleDownloadPinoy = async () => {
    try {
      const userName = this.props.user
      let pdfArr = []

      notification['info']({
        message: 'PDF Download',
        description:
          'Your form is currently being generated. Please wait for a moment.',
        placement: 'bottomLeft',
        duration: 15,
      })

      this.setState({ ildpButton: true, showSpinner: true })

      const canvas1 = await html2canvas(document.getElementById('specific1a'), {
        scale: 1.3,
      })
      const data1 = canvas1.toDataURL()
      const pdfExportSetting1 = {
        content: [
          {
            image: data1,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator1 = pdfMake.createPdf(pdfExportSetting1)

      pdfDocGenerator1.getBlob((blob1) => pdfArr.push(blob1))

      const canvas2 = await html2canvas(document.getElementById('specific2b'), {
        scale: 1.3,
      })
      const data2 = canvas2.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting2 = {
        content: [
          {
            image: data2,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator2 = pdfMake.createPdf(pdfExportSetting2)

      pdfDocGenerator2.getBlob((blob2) => pdfArr.push(blob2))

      const canvas3 = await html2canvas(document.getElementById('specific3c'), {
        scale: 1.3,
      })
      const data3 = canvas3.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting3 = {
        content: [
          {
            image: data3,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator3 = pdfMake.createPdf(pdfExportSetting3)

      pdfDocGenerator3.getBlob((blob3) => pdfArr.push(blob3))

      const canvas4 = await html2canvas(document.getElementById('specific4d'), {
        scale: 1.3,
      })
      const data4 = canvas4.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting4 = {
        content: [
          {
            image: data4,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator4 = pdfMake.createPdf(pdfExportSetting4)

      pdfDocGenerator4.getBlob((blob4) => pdfArr.push(blob4))

      const canvas5 = await html2canvas(document.getElementById('specific5e'), {
        scale: 1.3,
      })
      const data5 = canvas5.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting5 = {
        content: [
          {
            image: data5,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator5 = pdfMake.createPdf(pdfExportSetting5)

      pdfDocGenerator5.getBlob((blob5) => pdfArr.push(blob5))

      const canvas6 = await html2canvas(document.getElementById('specific6f'), {
        scale: 1.3,
      })
      const data6 = canvas6.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting6 = {
        content: [
          {
            image: data6,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator6 = pdfMake.createPdf(pdfExportSetting6)

      pdfDocGenerator6.getBlob((blob6) => pdfArr.push(blob6))

      const canvas7 = await html2canvas(document.getElementById('specific7g'), {
        scale: 1.3,
      })
      const data7 = canvas7.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting7 = {
        content: [
          {
            image: data7,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator7 = pdfMake.createPdf(pdfExportSetting7)
      pdfDocGenerator7.getBlob((blob7) => pdfArr.push(blob7))

      const canvas7a = await html2canvas(
        document.getElementById('specific7g'),
        {
          scale: 1.3,
          y: 1000,
        }
      )
      const data7a = canvas7a.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting7a = {
        content: [
          {
            image: data7a,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator7a = pdfMake.createPdf(pdfExportSetting7a)
      pdfDocGenerator7a.getBlob((blob7a) => pdfArr.push(blob7a))

      const canvas8 = await html2canvas(document.getElementById('specific8h'), {
        scale: 1.3,
      })
      const data8 = canvas8.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting8 = {
        content: [
          {
            image: data8,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator8 = pdfMake.createPdf(pdfExportSetting8)

      pdfDocGenerator8.getBlob((blob8) => pdfArr.push(blob8))

      const canvas9 = await html2canvas(document.getElementById('specific9i'), {
        scale: 1.3,
      })
      const data9 = canvas9.toDataURL('image/jpeg', 1.0)
      const pdfExportSetting9 = {
        content: [
          {
            image: data9,
            width: 500,
          },
        ],
      }
      const pdfDocGenerator9 = pdfMake.createPdf(pdfExportSetting9)
      pdfDocGenerator9.getBlob((blob9) => {
        pdfArr.push(blob9)

        this.setState({ showSpinner: false, isModalVisibleIldp: false })
        notification['success']({
          message: 'Success',
          description: 'Your form has been converted to pdf!',
        })

        const render = async () => {
          const merger = new PDFMerger()
          for (const file of pdfArr) {
            await merger.add(file)
          }
          await merger.save(
            `ILDP Form - ${userName.name}_${
              userName.mname ? userName.mname : ''
            }_${userName.lname}_${moment().format('MMMM D YYYY h:mm A')}`
          )
        }

        render()
      })
    } catch (err) {
      console.log(err)
    }
  }

  handleCloseSpin = () => {
    this.setState({ showSpinner: false })
  }

  handleViewILDPArchived = (e) => {
    e.preventDefault()
    try {
      this.setState({ showILDPArchived: true })
    } catch (err) {
      console.log(err)
    }
  }

  handleHideILDPArchived = (e) => {
    e.preventDefault()
    try {
      this.setState({ showILDPArchived: false })
    } catch (err) {
      console.log(err)
    }
  }

  handleViewETNAArchived = (e) => {
    e.preventDefault()
    try {
      this.setState({ showETNAArchived: true })
    } catch (err) {
      console.log(err)
    }
  }

  handleHideETNAArchived = (e) => {
    e.preventDefault()
    try {
      this.setState({ showETNAArchived: false })
    } catch (err) {
      console.log(err)
    }
  }

  render() {
    const columns = [
      {
        title: 'Date e-TNA Created',
        dataIndex: 'date',
        key: 'locale',
        render: (text, record) => (
          <Tag color='geekblue'>
            {moment(record.createdAt).format('MMMM D, YYYY h:mm A')}
          </Tag>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
          <Tag color={record.isFinal === 'YES' ? 'green' : 'red'}>
            {record.isFinal === 'YES' ? 'Final Copy' : 'Draft Copy'} {'as of '}{' '}
            {`${moment(record.finalSubmitDate).format('MMMM D, YYYY h:mm A')}`}
          </Tag>
        ),
      },
      {
        title: 'Version',
        dataIndex: 'status',
        key: 'isLanguage',
        render: (text, record) => (
          <Tag color='geekblue'>
            {record.isLanguage === '1' ? 'English Version' : 'Filipino Version'}
          </Tag>
        ),
      },
      {
        title: '',
        key: 'action',
        width: 90,
        render: (text, record) => (
          <span key={record.id}>
            <Button
              size='medium'
              type='primary'
              style={{ marginLeft: '5px', marginTop: '3px' }}
              onClick={() => this.handleShowSurveybyId(record.id)}
            >
              <FundViewOutlined /> View e-TNA
            </Button>
          </span>
        ),
      },
    ]
    const ildpColumns = [
      // possibly year only
      {
        title: 'Date ILDP Submitted',
        dataIndex: 'date',
        key: 'locale',
        render: (text, record) => (
          <Tag color='geekblue'>
            {moment(record.createdAt).format('MMMM D, YYYY h:mm A')}
          </Tag>
        ),
      },
      {
        title: 'e-TNA Submission Date',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => (
          <Tag color='green'>
            {`e-TNA Final Copy as of ${moment(record.etnaId).format(
              'MMMM D, YYYY h:mm A'
            )}`}
          </Tag>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'isApproved',
        render: (text, record) => {
          return record.isApproved === '1' ? (
            <Tag color='blue'>Final</Tag>
          ) : (
            <Tag color='red'>for Supervisor Approval</Tag>
          )
        },
      },

      {
        title: '',
        key: 'action',
        width: 100,
        render: (text, record) =>
          record?.file ? (
            <Col>
              <Row justify='center'>
                <a rel='noopener noreferrer' href={record.file} target='_blank'>
                  <Button
                    size='medium'
                    style={{
                      marginLeft: '10px',
                      marginTop: '3px',
                      width: '85%',
                    }}
                  >
                    <FilePdfTwoTone /> View Upload
                  </Button>
                </a>
              </Row>
              <Row justify='center'>
                <Tooltip placement='left' title={'Delete File'}>
                  <Button onClick={() => this.handleDeleteUploaded(record)}>
                    <DeleteTwoTone twoToneColor='red' /> Delete Upload
                  </Button>
                </Tooltip>
              </Row>
            </Col>
          ) : (
            <span>
              <div className='p-2'>
                <a
                  rel='noopener noreferrer'
                  href={record.location}
                  target='_blank'
                >
                  <Button
                    type='primary'
                    size='medium'
                    style={{
                      marginLeft: '0px',
                      marginTop: '3px',
                      width: '100%',
                    }}
                    onClick={() => this.handleShowIldp(record)}
                  >
                    <FundViewOutlined /> show ILDP
                  </Button>
                </a>
              </div>
            </span>
          ),
      },
    ]

    return (
      <div
        className='site-layout-background'
        style={{
          padding: 50,
        }}
      >
        <Card>
          <Breadcrumb className={'font-Mont'} style={{ marginBottom: 20 }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>View Submitted Forms</Breadcrumb.Item>
          </Breadcrumb>
          <Tabs className={'font-Mont'} defaultActiveKey='1' centered>
            <TabPane tab={<b>e-TNA Form</b>} key='1'>
              <Row className={'mb-4'}>
                <Col span={24}>
                  <Row justify='start'>
                    <span className={'font-Mont lds-span-bold'}>
                      Current period:{' '}
                      {moment(this.thresholdProvider()).format('YYYY')}
                    </span>
                  </Row>
                  <div className='compTable' style={{ marginTop: '30px' }}>
                    <Table
                      className={'font-Mont'}
                      rowKey='id'
                      bordered
                      dataSource={this.state.currentPeriodData}
                      columns={columns}
                    />
                  </div>
                </Col>
              </Row>

              {this.state.showETNAArchived === false ? (
                <Row justify='end' className={'mb-3'}>
                  <a
                    onClick={this.handleViewETNAArchived}
                    className={'font-Mont'}
                    href='#'
                  >
                    <i>View Archived e-TNA</i>
                  </a>
                </Row>
              ) : null}

              {this.state.showETNAArchived === true ? (
                <Row justify='end' className={'mb-3'}>
                  <a
                    onClick={this.handleHideETNAArchived}
                    className={'font-Mont'}
                    href='#'
                  >
                    <i>Hide Archived e-TNA</i>
                  </a>
                </Row>
              ) : null}

              {this.state.showETNAArchived === true ? (
                <Row>
                  <Col span={24}>
                    <Row justify='start'>
                      <span className={'font-Mont lds-span-bold'}>
                        Archived eTNA forms
                      </span>
                    </Row>
                    <div className='compTable' style={{ marginTop: '30px' }}>
                      <Table
                        className={'font-Mont'}
                        rowKey='id'
                        bordered
                        dataSource={this.state.data}
                        columns={columns}
                      />
                    </div>
                  </Col>
                </Row>
              ) : null}
              <Modal
                destroyOnClose={true}
                maskClosable={false}
                title='SURVEY ANSWERED'
                open={this.state.isModalVisible}
                onOk={() => this.handleOk()}
                footer={null}
                onCancel={() => this.handleCancel()}
                width={'70vw'}
                cancelButtonProps={{ style: { display: 'none' } }}
              >
                <div className='submitted-etna-page-modal'>
                  {this.state.submissionData ? (
                    this.state.comp ? (
                      <Form
                        src={{ components: this.state.comp }}
                        onSubmit={(e) => this.handleSubmit(e)}
                        submission={{
                          data: this.state.submissionData,
                        }}
                      />
                    ) : (
                      <Form
                        src={`https://www.sparksoft-demo.com/formio/${this.state.formSource}`}
                        onSubmit={(e) => this.handleSubmit(e)}
                        submission={{
                          data: this.state.submissionData,
                        }}
                        onFormLoad={(e) => this.handleForm(e)}
                      />
                    )
                  ) : null}
                </div>
              </Modal>
            </TabPane>

            <TabPane tab={<b>ILDP Form</b>} key='2'>
              <Row className={'mb-4'}>
                <Col span={24}>
                  <Row justify='start'>
                    <span className={'font-Mont lds-span-bold'}>
                      Current period:{' '}
                      {moment(this.thresholdProvider()).format('YYYY')}
                    </span>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <Table
                        className={'font-Mont'}
                        rowKey='id'
                        bordered
                        dataSource={this.state.allIldpsCurrentPeriod}
                        columns={ildpColumns}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
              {this.state.showILDPArchived === false ? (
                <Row className='mb-3' justify='end'>
                  <a
                    onClick={this.handleViewILDPArchived}
                    className={'font-Mont'}
                    href='#'
                  >
                    <i>View Archived ILDP forms</i>
                  </a>
                </Row>
              ) : null}

              {this.state.showILDPArchived === true ? (
                <Row className='mb-3' justify='end'>
                  <a
                    onClick={this.handleHideILDPArchived}
                    className={'font-Mont'}
                    href='#'
                  >
                    <i>Hide Archived ILDP forms</i>
                  </a>
                </Row>
              ) : null}

              {this.state.showILDPArchived === true ? (
                <Row>
                  <Col span={24}>
                    <Row justify='start'>
                      <span className={'font-Mont lds-span-bold'}>
                        Archived ILDP forms
                      </span>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <Table
                          className={'font-Mont'}
                          rowKey='id'
                          bordered
                          dataSource={this.state.allIldpsArchived}
                          columns={ildpColumns}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}

              <div className='editModal'>
                <Modal
                  destroyOnClose={true}
                  maskClosable={false}
                  open={this.state.showSpinner}
                  footer={null}
                  onCancel={() => this.handleCloseSpin()}
                  width={87}
                  closable={false}
                  centered={true}
                  mask={false}
                >
                  <div
                    className={
                      'd-flex justify-content-center align-items-center'
                    }
                  >
                    <Spin size='large' />{' '}
                  </div>
                </Modal>
              </div>
              <Modal
                destroyOnClose={true}
                maskClosable={false}
                closable={false}
                title='ILDP Submitted'
                open={this.state.isModalVisibleIldp}
                onOk={() => this.handleOkIldp()}
                onCancel={() => this.handleCancelIldp()}
                width={1000}
                style={{ top: 20 }}
                cancelButtonProps={{ style: { display: 'none' } }}
                footer={[
                  this.state.isLang === 'YES' ? (
                    <span key='footer-download-pinoy'>
                      <Button
                        disabled={this.state.ildpButton}
                        block
                        type='primary'
                        onClick={() => this.handleDownloadPinoy()}
                      >
                        Download
                      </Button>
                    </span>
                  ) : (
                    <span key='footer-download'>
                      <Button
                        disabled={this.state.ildpButton}
                        block
                        type='primary'
                        onClick={() => this.handleDownload()}
                      >
                        Download
                      </Button>
                    </span>
                  ),
                ]}
              >
                {this.state.isLang === 'YES' ? (
                  <IldpFormModalPrintPinoy
                    submissionDataIldp={this.state.submissionDataIldp}
                    disable={this.state.disabled}
                    changeFname={(e) =>
                      this.setState({ fname: e.target.value })
                    }
                  />
                ) : (
                  <IldpFormModalPrintEnglish
                    submissionDataIldp={this.state.submissionDataIldp}
                    disable={this.state.disabled}
                    changeFname={(e) =>
                      this.setState({ fname: e.target.value })
                    }
                  />
                )}
              </Modal>

              <Modal
                destroyOnClose={true}
                maskClosable={false}
                open={this.state.downloadedStatus}
                centered
                width={480}
                bodyStyle={{ height: 200 }}
                closable={false}
                footer={null}
              >
                <Alert
                  message='Success Tips'
                  description='Please wait - your file is being downloaded.'
                  type='success'
                  showIcon
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '35px',
                  }}
                />
              </Modal>
            </TabPane>
          </Tabs>
        </Card>
      </div>
    )
  }
}

export default ViewForm

// add props validation
ViewForm.propTypes = {
  user: PropTypes.object,
  refresh: PropTypes.func,
  afterSubmit: PropTypes.func,
  viewer: PropTypes.object,
}
