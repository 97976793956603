import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import PropTypes from 'prop-types'

/**
 * Modal component for delete confirmation
 * @param {boolean} open - Controls modal visibility
 * @param {Function} onCancel - Handler for cancel action
 * @param {Function} onConfirm - Handler for confirm delete
 * @param {string} [title='Delete Confirmation'] - Modal title
 * @param {string} [itemName='this item'] - Name of item being deleted
 */
export default function DeleteConfirmationModal({
  open,
  onCancel,
  onConfirm,
  title = 'Delete Confirmation',
  itemName = 'this item',
}) {
  return (
    <Modal
      title={
        <div className='pds-remarks-delete-row-div'>
          <ExclamationCircleOutlined style={{ color: '#faad14' }} />
          {title}
        </div>
      }
      open={open}
      onCancel={onCancel}
      okText='Delete row'
      cancelText='Cancel'
      okButtonProps={{
        danger: true,
        style: {
          backgroundColor: '#ff4d4f',
          borderColor: '#ff4d4f',
        },
      }}
      onOk={onConfirm}
    >
      <p style={{ fontSize: '16px', marginBottom: '16px' }}>
        Are you sure you want to delete {itemName}? This action cannot be
        undone.
      </p>
    </Modal>
  )
}

DeleteConfirmationModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  itemName: PropTypes.string,
}
