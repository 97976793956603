/**
 * Title: CSCOnlineMaterialsPartTwo.js
 * Description: This is a file that contains the CSC Online Materials part two component
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/CSCOnlineMaterialsPartTwo.js
 **/

import { useState, useEffect } from 'react'

import { Button, Modal, Input, Select, Row, Col, notification } from 'antd'
import { FilePdfTwoTone, PlayCircleFilled } from '@ant-design/icons'

import { API, graphqlOperation } from 'aws-amplify'
import { listOtherUploads, listTrainingVideos } from './../../api/queries'
import { createCscMaterials } from './../../api/mutations'

import listOfDepartments from './../../utilities/dept'

import VideoPlayer from './videoPlayer'

const { Option } = Select

const dept = listOfDepartments()

export default function CSCOnlineMaterialsPartTwo({
  gotoCSCVideos,
  cscTrainID,
  userData,
}) {
  const userInfo = userData

  const [downloadLoc, setDownloadLoc] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [name, setName] = useState(null)
  const [department, setDepartment] = useState(null)

  const [urlLink, setUrlLink] = useState(null)
  const [materials, setMaterials] = useState(null)

  const [videosLoc, setVideosLoc] = useState([])

  const [nameVideo, setNameVideo] = useState(null)
  const [departmentVideo, setDepartmentVideo] = useState(null)
  const [isModalVisibleVideo, setIsModalVisibleVideo] = useState(false)
  const [isAgreeVideo, setIsAgreeVideo] = useState(true)
  const [videoKey, setVideoKey] = useState(null)

  /**
   *@async
   *@function fetchUploadedFiles
   *@description Fetches uploaded files related to a CSC Training material from the API.
   *The function sends a GraphQL query using AWS Amplify to retrieve the uploaded files data.
   * It filters the files based on a specific search term.
   *Once the matching file is found, it extracts the download location and updates the state
   * variable 'downloadLoc' to store the location for further use.
   *@throws {Error} If there is an error while fetching or processing the uploaded files data.
   */
  const fetchUploadedFiles = async () => {
    try {
      const uploadedFilesData = await API.graphql({
        query: listOtherUploads,
        authMode: 'API_KEY',
      })
      const arrayItems = uploadedFilesData.data.listOtherUploads.items
      const searchTerms = ['PRACTICE TEST - Clerical Operations.pdf']

      const newArrayItems = arrayItems.filter((item) =>
        searchTerms.some((term) => item.filename.includes(term))
      )
      setDownloadLoc(
        newArrayItems.sort(
          (a, b) =>
            searchTerms.indexOf(a.filename) - searchTerms.indexOf(b.filename)
        )
      )
    } catch (error) {
      console.log(error)
    }
  }

  const fetchUploadedVideos = async () => {
    try {
      const uploadedFilesData = await API.graphql(
        graphqlOperation(listTrainingVideos)
      )
      const arrayItems = uploadedFilesData.data.listTrainingVideos.items
      let searchTerms = [
        'Word Problems Part 1.mp4',
        'Word Problems Part 2.mp4',
        'Word Problems Part 3.mp4',
        'Word Problem Part 4.mp4',
      ]

      const newArrayItems = arrayItems.filter((item) =>
        searchTerms.some((term) => item.key.includes(term))
      )

      setVideosLoc(
        newArrayItems.sort(
          (a, b) => searchTerms.indexOf(a.key) - searchTerms.indexOf(b.key)
        )
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    setIsModalVisible(false)
    setName(null)
    setDepartment(null)
    setUrlLink(null)
  }

  const dateDisplay = `${
    new Date().getMonth() + 1
  }-${new Date().getDate()}-${new Date().getFullYear()}`

  const handleSelect = (value) => {
    setDepartment(value)
  }

  const handleSelectVideo = (value) => {
    setDepartmentVideo(value)
  }

  /**
   *@async
   *@function handleOk
   *@description Handles the acknowledgment and submission of the refresher PDF in the LDRefresher component.
   *If the required fields (name and department) are not filled or the department is set to 'disabled',
   * it displays an error notification.
   *Otherwise, it creates a new refresher acknowledgment by sending a GraphQL mutation using AWS Amplify.
   *The acknowledgment data is retrieved from the response, and if the acknowledgment is
   * successfully created, it opens the refresher PDF in a new window,
   *closes the modal, and resets the relevant state variables after a delay of 2000 milliseconds.
   *@throws {Error} If there is an error while creating the refresher acknowledgment or
   * processing the response data.
   */
  const handleOk = async () => {
    if (!name || !department || department === 'disabled') {
      showErrorNotification()
    } else {
      const input = {
        employeeName: name,
        department,
        date: new Date(),
        materials,
      }

      try {
        const acknowledgment = await API.graphql(
          graphqlOperation(createCscMaterials, { input })
        )
        if (acknowledgment.data.createCscMaterials) {
          window.open(urlLink, '_blank')
          setIsModalVisible(false)

          setTimeout(() => {
            setName(null)
            setDepartment(null)
            setUrlLink(null)
          }, 2000)
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  const showErrorNotification = () => {
    notification['error']({
      message: 'Error',
      description: 'Please complete the fields below to continue.',
    })
  }

  useEffect(() => {
    if (userInfo) {
      fetchUploadedFiles()
      fetchUploadedVideos()
    }
  }, [])

  useEffect(() => {
    if (userInfo) {
      setDepartment(userInfo.assignedDept)
      setDepartmentVideo(userInfo.assignedDept)
      setName(
        `${userInfo.name}${userInfo.mname ? ' ' + userInfo.mname : ''} ${
          userInfo.lname
        }`
      )
      setNameVideo(
        `${userInfo.name}${userInfo.mname ? ' ' + userInfo.mname : ''} ${
          userInfo.lname
        }`
      )
    }
  }, [isModalVisible])

  const handleModal = (e, url, fileName) => {
    e.preventDefault()
    setUrlLink(url)
    setIsModalVisible(true)
    setMaterials(fileName)
  }

  const handleGotoCSCVideos = (e) => {
    e.preventDefault()
    gotoCSCVideos()
  }

  const handlePlayButton = (key) => {
    setIsModalVisibleVideo(true)
    setVideoKey(key)
  }

  return (
    <Col span={24} className='font-Mont mb-5'>
      <Row className='mt-3' justify='center'>
        <h1>Additional Materials for Training</h1>
      </Row>
      <Row className='mt-2' justify='center'>
        <h3>QCG Employee Assistance Program: ONLINE Review Sessions</h3>
      </Row>
      {downloadLoc.length ? (
        <Row>
          {downloadLoc.map((item) => {
            return (
              <Col key={item.id} span={12}>
                <Row className='mt-5'>
                  <div className='mx-5'>
                    <a
                      onClick={(e) =>
                        handleModal(e, item.location, item.filename)
                      }
                      href='#'
                      target='_blank'
                    >
                      <Col>
                        <Row>
                          <FilePdfTwoTone style={{ fontSize: 100 }} />
                        </Row>
                        <Row>
                          <span>
                            <b>{item.filename}</b>
                          </span>
                        </Row>
                      </Col>
                    </a>
                  </div>
                </Row>
              </Col>
            )
          })}
        </Row>
      ) : null}
      <Row className='mt-5'>
        {videosLoc.map((video) => {
          const videoName = video.key.slice(0, -4) // remove the last 4 characters (i.e. ".mp4") from the file name
          return (
            <Col key={video.id} span={12}>
              <Row justify='center'>
                <div className='card shadow mt-3 col-md-7 col-12'>
                  <div>
                    <img
                      src={`${process.env.PUBLIC_URL}/Csc-Week2.png`}
                      alt={video.key}
                      className='w-100'
                    />
                    <div
                      className='playbutton btn cursor-pointer mb-5'
                      onClick={() => handlePlayButton(video.key)}
                      style={{
                        position: 'absolute',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80px',
                        height: '80px',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        top: '40%',
                      }}
                    >
                      <PlayCircleFilled
                        style={{ fontSize: '43px', color: '#fff' }}
                      />
                    </div>
                  </div>
                  <div className='card-body'>
                    <h4 className='card-title'>{videoName}</h4>
                    {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                  </div>
                </div>
              </Row>
            </Col>
          )
        })}
      </Row>
      {/*<Row justify='end' className='mx-3 mt-5 mb-5'>
        <a type='button' onClick={handleGotoCSCVideos} href='#'>
          <span>
            <i>go to Training Videos ></i>
          </span>
        </a>
      </Row>*/}
      <div>
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='Acknowledgment and Receipt'
          width={1000}
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText='Agree'
        >
          <div>
            <p>
              <b>STATEMENT OF CLAIMING VIEW</b>
            </p>
            <p className='text-justify'>
              I hereby declare and affirm my commitment to the terms and
              conditions set forth in this statement regarding the review PDF
              material provided to me in this platform (www.qc-hrmd.org), and
              its entirety, by the Human Resource Management Department (HRMD)
              of the Quezon City Government. I acknowledge that the review PDF
              material is the exclusive property of HRMD, and as such, I fully
              understand and agree to abide by the following terms:
            </p>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>1.</p>
              <p>
                {' '}
                <strong>Ownership:</strong> I recognize that the review PDF
                material is protected by intellectual property laws and is the
                sole and exclusive property of HRMD. I acknowledge that I have
                no rights or ownership claims over the content contained within
                the review video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>2.</p>
              <p>
                {' '}
                <strong>Non-Duplication:</strong> I solemnly affirm that I will
                not duplicate or reproduce, in whole or in part, the review PDF
                material provided to me by HRMD. I understand that duplication
                includes but is not limited to copying, recording, downloading,
                or distributing the material through any means or in any format
                without explicit written consent from HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>3.</p>
              <p>
                {' '}
                <strong>Confidentiality:</strong> I acknowledge and respect that
                the review PDF material may contain confidential and proprietary
                information belonging to HRMD. I commit to maintaining the
                strictest confidentiality regarding the content, concepts,
                techniques, and methodologies presented in the review PDF
                material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>4.</p>
              <p>
                {' '}
                <strong>Personal Use:</strong> I understand that the review PDF
                material is solely intended for my personal use, learning, and
                development. I agree not to share, lend, or otherwise make the
                review PDF material available to any third party, whether for
                commercial or non-commercial purposes, without the express
                written consent of HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>5.</p>
              <p>
                {' '}
                <strong>Legal Consequences:</strong> I acknowledge that any
                unauthorized duplication or reproduction of the review PDF
                material may result in legal consequences, including but not
                limited to civil lawsuits and monetary damages. I accept full
                responsibility for any such consequences resulting from my
                violation of this agreement.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>6.</p>
              <p>
                {' '}
                <strong>Compliance:</strong> I commit to cooperating with HRMD
                and its representatives in any investigation of suspected
                violations of this agreement. I understand that HRMD reserves
                the right to take appropriate legal action to enforce these
                terms and protect its rights.
              </p>
            </div>
            <p>
              By inputting my full name and department/office in this statement,
              I acknowledge that I have read and understood the terms outlined
              above and that I will comply with them strictly. I further
              acknowledge that any breach of this agreement may result in severe
              legal consequences.
            </p>
          </div>

          <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Enter your name'
                className='text-center'
              />
              <span className='text-center'>Employee's Name & Signature</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
              <span>{dateDisplay}</span>
              <span>Date</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
              <Select
                value={department}
                onChange={handleSelect}
                className='w-100 text-center'
              >
                <Option value='disabled' disabled>
                  Disabled
                </Option>
                {dept.sort().map((item, qindex) => (
                  <Option key={qindex} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <span className='text-center'>Department</span>
            </div>
          </div>
        </Modal>

        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='Acknowledgment and Receipt'
          width={1000}
          visible={isModalVisibleVideo}
          onCancel={() => setIsModalVisibleVideo(false)}
          footer={[
            <Button key='back' onClick={() => setIsModalVisibleVideo(false)}>
              Cancel
            </Button>,
            <VideoPlayer
              videoKey={videoKey}
              CSCOnlineVids={true}
              name={nameVideo}
              department={departmentVideo}
              setIsModalVisible={setIsModalVisibleVideo}
              isAgree={isAgreeVideo}
            />,
          ]}
        >
          <div>
            <p>STATEMENT OF CLAIMING VIEW</p>
            <p className='text-justify'>
              I hereby declare and affirm my commitment to the terms and
              conditions set forth in this statement regarding the review video
              material provided to me in this platform (www.qc-hrmd.org), and
              its entirety, by the Human Resource Management Department (HRMD)
              of the Quezon City Government. I acknowledge that the review video
              material is the exclusive property of HRMD, and as such, I fully
              understand and agree to abide by the following terms:
            </p>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>1.</p>
              <p>
                {' '}
                <strong>Ownership:</strong> I recognize that the training video
                material is protected by intellectual property laws and is the
                sole and exclusive property of HRMD. I acknowledge that I have
                no rights or ownership claims over the content contained within
                the training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>2.</p>
              <p>
                {' '}
                <strong>Non-Duplication:</strong> I solemnly affirm that I will
                not duplicate or reproduce, in whole or in part, the training
                video material provided to me by HRMD. I understand that
                duplication includes but is not limited to copying, recording,
                downloading, or distributing the material through any means or
                in any format without explicit written consent from HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>3.</p>
              <p>
                {' '}
                <strong>Confidentiality:</strong> I acknowledge and respect that
                the training video material may contain confidential and
                proprietary information belonging to HRMD. I commit to
                maintaining the strictest confidentiality regarding the content,
                concepts, techniques, and methodologies presented in the
                training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>4.</p>
              <p>
                {' '}
                <strong>Personal Use:</strong> I understand that the training
                video material is solely intended for my personal use, learning,
                and development. I agree not to share, lend, or otherwise make
                the training video material available to any third party,
                whether for commercial or non-commercial purposes, without the
                express written consent of HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>5.</p>
              <p>
                {' '}
                <strong>Legal Consequences:</strong> I acknowledge that any
                unauthorized duplication or reproduction of the training video
                material may result in legal consequences, including but not
                limited to civil lawsuits and monetary damages. I accept full
                responsibility for any such consequences resulting from my
                violation of this agreement.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>6.</p>
              <p>
                {' '}
                <strong>Compliance:</strong> I commit to cooperating with HRMD
                and its representatives in any investigation of suspected
                violation of this agreement. I understand that HRMD reserves the
                right to take appropriate legal action to enforce these terms
                and protect its rights.
              </p>
            </div>
            <p>
              By inputting my full name and department/office in this statement,
              I acknowledge that I have read and understood the terms outlined
              above and that I will comply with them strictly. I further
              acknowledge that any breach of this agreement may result in severe
              legal consequences.
            </p>
            <div className='d-flex'>
              <label className='d-flex items-align-center gap-2'>
                <input
                  type='checkbox'
                  checked={!isAgreeVideo}
                  onChange={(e) => setIsAgreeVideo(!isAgreeVideo)}
                />
                I agree
              </label>
            </div>
          </div>

          <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
              <Input
                value={nameVideo}
                onChange={(e) => setNameVideo(e.target.value)}
                placeholder='Enter your name'
                className='text-center'
              />
              <span className='text-center'>Employee's Name & Signature</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
              <span>{dateDisplay}</span>
              <span>Date</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
              <Select
                value={departmentVideo}
                onChange={handleSelectVideo}
                className='w-100 text-center'
              >
                <Option value='disabled' disabled>
                  Disabled
                </Option>
                {dept.sort().map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <span className='text-center'>Department</span>
            </div>
          </div>
        </Modal>
      </div>
    </Col>
  )
}
