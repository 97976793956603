/**
 * Title: register.js
 * Description: This is a file that contains the components for the registration page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Justine Villanueva [justinevillanueva090794@gmail.com] [@Github: @Justine077]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Register/register.js
 **/

/**
 * Changes Made:
 * - 2024.08.02 | Justine Villanueva | Added input validation in the registration form.
 * - 2024.08.02 | Justine Villanueva | Fixed a bug in the position input.
 * - 2024.08.06 | Justine Villanueva | Used regex patterns for names, usernames, and passwords.
 * - 2024.08.06 | Justine Villanueva | Added a regex pattern that accepts only certain special characters in the employee ID number.
 * - 2024.08.14 | Justine Villanueva | Validation Function Allows Roman Numerals and Incorrect Spacing for Position field.
 * - 2024.08.15 | Justine Villanueva | Fix the validation issue for suffix field.Don't allow blank spaces on the dropdown.
 * - 2024.08.15 | Justine Villanueva | A SonarQube issue in register.js has been resolved by grouping parts of the regex
 *   together to make the intended operator precedence explicit.
 * - 2024.08.16 | Justine Villanueva | Change the layout of the profile page to make the input data on the registration page more readable.
 *   Some inputs have long names or strings that are currently being cut off.
 * - 2024.07.11 | Harry Lagunsad | Fix most sonarlint issues
 * - 2024.07.11 | Harry Lagunsad | Add appointmentStatus field in registration and set it as required
 * - 2024.07.11 | Harry Lagunsad | change employeeID max length to 100
 */
import React from 'react'

import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Input,
  List,
  Modal,
  Row,
  Select,
  notification,
} from 'antd'
import { Auth } from 'aws-amplify'
import moment from 'moment'
import PropTypes from 'prop-types'

import { SmileOutlined } from '@ant-design/icons'

import listOfDepartments from '../../utilities/dept.js'
import RecaptchaRegister from './recaptchaRegister.js'

const { Option } = Select

export default class Register extends React.Component {
  static propTypes = {
    signUp: PropTypes.func.isRequired,
  }
  constructor() {
    super()
    this.state = {
      gender: '',
      appoinmentStatus: '',
      DepartmentX: [],
      DivisionX: [],
      SectionUnit: [],
      SectionUnitX: '',
      assignedDept: 'Select Assigned Department',
      dept: 'Select Home Department',
      divisionN: '',
      disabledSection: false,
      role: 'employee',
      setOpenModal: false,
      status: 0,
      isMaintenance: true,
      captcha: null,
      showButton: false,

      showPage: false,
      validationErrors: {
        fname: '',
        mname: '',
        lname: '',
        suffix: '',
      },
      genderValidation: '',
      appoinmentStatusValidation: '',
      divisionValidation: '',
      assignedDeptValidation: '',
      sectionUnitValidation: '',
      deptValidation: '',
      passwordValidation: '',
      confirmPasswordValidation: '',
      employeeIDValidation: '',
      contactValidation: '',
      positionValidation: '',
      usernameValidation: '',
    }
    this.changeDivision = this.changeDivision.bind(this)
  }

  /**
   * @function checkIfLoggedin
   * @description Checks if a user is currently logged in by retrieving the current session. If a session is found, it redirects the user to the homepage. If an error occurs (e.g., no session found), it clears local storage and sets the state to show the page.
   *
   * @returns {Promise<void>} A promise that resolves when the check is complete.
   *
   * @throws {Error} Logs any caught errors to the console and clears local storage.
   */
  checkIfLoggedin = async () => {
    try {
      const session = await Auth.currentSession()
      if (session) {
        window.location.pathname = '/'
      }
    } catch (err) {
      console.log(err)
      localStorage.clear()
      this.setState({ showPage: true })
    }
  }

  /**
   * @function changeDivision
   * @description This function is used to change the division based on the department selected
   * @param {string} value - The value of the department selected
   * @returns {void}
   * @throws {null}
   */
  componentDidMount() {
    this.checkIfLoggedin()

    let date = moment().format('MMMM Do YYYY')
    let date2 = moment().format('YYYY-DD-MM')
    let day = moment().format('dddd')
    this.setState({ date: date, day: day, date2: date2 })

    this.setState({
      DepartmentX: [
        {
          department: 'Office of the City Mayor',
          division: [
            {
              divisionName: 'Administrative',
              SectionUnit: [
                'Correspondence/Clerical Section',
                'General Services Section',
                'Records Management Section',
                'Finance, Property and Supply Section',
                'Others',
              ],
            },
            {
              divisionName: 'Quezon City Persons With Disability Affairs',
              SectionUnit: [],
            },
            {
              divisionName:
                'Quezon City Disaster Risk Reduction and Management Office',
              SectionUnit: [
                'Administration and Training Section',
                'Research and Planning Section',
                'Operations and Warning Section',
                'Others',
              ],
            },
            {
              divisionName: 'City Real Estate Management and Control Office',
              SectionUnit: [
                'Management Section',
                'Operations Section',
                'Others',
              ],
            },
            {
              divisionName: 'Solo Parents Affairs Office',
              SectionUnit: ['Others'],
            },
            {
              divisionName: 'Others',
              SectionUnit: [
                'Intern Audit Service',
                'Gender and Development Council Office',
                'Task Force for Transport and Traffice Management',
                'Task Force Disiplina',
                'Quezon City Sustainable Development Affairs Unit',
                'Quezon City Sustainable Development Affairs Unit',
                'Task Force on Control, Prevention, and Removal of illegal Structures and squatting',
                'Task Force on Food Security',
                'Quezon City Bangsamoro Affairs Service',
                'Education Affairs Unit',
                'Others',
              ],
            },
          ],
        },
        {
          department: 'National Bureau of Investigation - Quezon City Hall',
          division: [
            {
              divisionName: 'Biometric Operation',
              SectionUnit: [],
            },
            {
              divisionName: 'Technical Support',
              SectionUnit: [],
            },
            {
              divisionName: 'Liason',
              SectionUnit: [],
            },
            {
              divisionName: 'Releasing',
              SectionUnit: [],
            },
          ],
        },
        {
          department: 'Office of the City Councilors',
          division: [
            {
              divisionName: 'District I',
              SectionUnit: [],
            },
            {
              divisionName: 'District II',
              SectionUnit: [],
            },
            {
              divisionName: 'District III',
              SectionUnit: [],
            },
            {
              divisionName: 'District IV',
              SectionUnit: [],
            },
            {
              divisionName: 'District V',
              SectionUnit: [],
            },
            {
              divisionName: 'District VI',
              SectionUnit: [],
            },
            {
              divisionName: 'District VII',
              SectionUnit: [],
            },
            {
              divisionName: 'Sectoral Representatives',
              SectionUnit: [
                'Liga Ng Mga Barangay',
                'Sangguniang Kabataan',
                'Office of the Sangguniang Panlungsod Member III',
              ],
            },
          ],
        },
        {
          department: 'Office of the Vice Mayor',
          division: [
            {
              divisionName: 'Others',
              SectionUnit: ['Technical', 'Youth and Sports', 'Others'],
            },
          ],
        },
        {
          department: 'Office of the City Administrator',
          division: [
            {
              divisionName: 'Quezon City ID',
              SectionUnit: ['Others'],
            },
            {
              divisionName: 'Administrative Division',
              SectionUnit: ['Others'],
            },
            {
              divisionName: 'Fiscal Management Division',
              SectionUnit: ['Others'],
            },
            {
              divisionName: 'Management and Organization Division',
              SectionUnit: ['Others'],
            },
            {
              divisionName:
                'Operations, Coordinations, and Monitoring Division',
              SectionUnit: ['Others'],
            },
            { divisionName: 'Technical Division', SectionUnit: ['Others'] },
            { divisionName: 'Others', SectionUnit: ['Others'] },
          ],
        },
        {
          department: 'Office of the Secretary to the Sangguniang Panlungsod',
          division: [
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'General Services Section',
                'Personnel Section',
                'Finance and Accounting Section',
                'Finance and Accounting Section',
                'Others',
              ],
            },
            {
              divisionName: 'Legislative Services Division',
              SectionUnit: [
                'Legislative Research Section',
                'Ordinance and Resolution Section',
                'Agenda and Briefing Section',
                'Reference and Archives Section',
                'Others',
              ],
            },
            {
              divisionName: 'Journal and Minutes Division',
              SectionUnit: [
                'Journal and Transcript Section',
                'Minutes Section',
                'Committee Affairs Section',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'City Planning and Development Department',
          division: [
            {
              divisionName: 'Social Development Planning Division',
              SectionUnit: [
                'Demography and Health',
                'Education and Sports',
                'Social Welfare and Protective Services',
                'Others',
              ],
            },
            {
              divisionName: 'Economic Development Planning Division',
              SectionUnit: [
                'Trade and Industry',
                'Labor and Employment',
                'Others',
              ],
            },
            { divisionName: 'Special Projects Division', SectionUnit: [] },
            {
              divisionName:
                'Disaster Risk Reduction Management/Climate Change Adaption',
              SectionUnit: [
                'Environmental Quality ',
                'Database Management',
                'Others',
              ],
            },
            {
              divisionName: 'Land Use and Infrastructure Division',
              SectionUnit: ['Land Use', 'Infrastructure'],
            },
            {
              divisionName: 'Institutional Development Planning Division',
              SectionUnit: [
                'Organization and Management',
                "People's Participation",
                'Others',
              ],
            },
            {
              divisionName: 'Zoning and Urban Design Division',
              SectionUnit: [],
            },
            {
              divisionName:
                'Subdivision Administration and Regulation Division',
              SectionUnit: ['Subdivision', 'Others'],
            },
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Personnel and Budget',
                'Property, Supplies and Records',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'City Legal Department',
          division: [
            {
              divisionName: 'Trial and Investigation Division',
              SectionUnit: [],
            },
            { divisionName: 'Opinion and Research Division', SectionUnit: [] },
            {
              divisionName: 'Records and Information Management Division',
              SectionUnit: [],
            },
            { divisionName: 'Administrative Division', SectionUnit: [] },
            {
              divisionName: 'Legal Aide and Enforcement Division',
              SectionUnit: [],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'City Civil Registry Department',
          division: [
            { divisionName: 'Administrative Division', SectionUnit: [] },
            {
              divisionName: 'Records Management and Archiving Division',
              SectionUnit: [
                'Information and Data Management Section',
                'Others',
              ],
            },
            { divisionName: 'Birth Registration Division', SectionUnit: [] },
            { divisionName: 'Marriage Registration Division', SectionUnit: [] },
            { divisionName: 'Death Registration Division ', SectionUnit: [] },
            {
              divisionName: 'Public Cemetery Management Division',
              SectionUnit: [
                'Baesa Public Cemetery',
                'Novaliches Public Cemetery',
                'Bagbag Public Cemetery',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'City General Services Department',
          division: [
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Human Resource Management Section',
                'Fiscal Management Section',
                'General Administrative Section',
                'Others',
              ],
            },
            {
              divisionName: 'Moveable Property Management and Control Division',
              SectionUnit: [
                'Programming and Utilization Section',
                'Inventory',
                'Disposal Section',
                'Technical Inspection Section',
                'Others',
              ],
            },
            {
              divisionName: 'Central Warehouse Management Division',
              SectionUnit: [
                'Supplies Receiving and Programming Section',
                'Storage and Processing Section',
                'Others',
              ],
            },
            {
              divisionName: 'Building and Grounds Management Division',
              SectionUnit: [
                'Building and Facilities Maintenance Section',
                'Security and Housekeeping Services Section',
                'Equipment Maintenance Section',
                'Others',
              ],
            },
            {
              divisionName: 'Records Management and Control Division',
              SectionUnit: [
                'Current Records and Mail Section',
                'Records Storage Section',
                'Records Archival Section',
                'Others',
              ],
            },
            {
              divisionName: 'Motorpool Division',
              SectionUnit: [
                'Motor Vehicles Maintenance Section',
                'Dispatching Section',
              ],
            },
            {
              divisionName: 'Fixed Assets Management and Control Division',
              SectionUnit: [
                'Land Inventory Section',
                'Building and Improvement Inventory Section',
                'Research and Disposition Section',
                'Others',
              ],
            },
            {
              divisionName: 'Streetlights Management & Services Division',
              SectionUnit: [
                'Operation and Maintenance Section',
                'Planning and Monitoring Section',
                'Streetlight Installation and Repair Section',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'City Budget Department',
          division: [
            { divisionName: 'Administrative Division', SectionUnit: [] },
            { divisionName: 'Budget Development Division', SectionUnit: [] },
            {
              divisionName: 'Budget Operations and Control Division',
              SectionUnit: [],
            },
            {
              divisionName:
                'Budget Management and Information Service Division',
              SectionUnit: [],
            },
            {
              divisionName: 'Barangay Operation Affairs Division',
              SectionUnit: [],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'City Accounting Department',
          division: [
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Records Management Section',
                'EDP Database Section',
                'Others',
              ],
            },
            {
              divisionName: 'Fiscal Management and Control Division',
              SectionUnit: [
                'Pre-Audit Section',
                'Fund Control Section',
                'Technical Inspection Section',
                'Others',
              ],
            },
            {
              divisionName: 'Financial Accounts and Analysis Division',
              SectionUnit: [
                'Financial Accounts Section',
                'Financial Analysis Section',
                'Miscellaneous Accounts Section',
                'Others',
              ],
            },
            {
              divisionName: 'Payrolls, Bills and Remittance Division',
              SectionUnit: [
                'Payroll Section',
                'Bill and Remittance Section',
                'Others',
              ],
            },
            {
              divisionName: 'Revenue Abstract and Statistics Division',
              SectionUnit: [
                'Revenue Abstract Section',
                'Revenue Statistics and Analysis Section',
                'Others',
              ],
            },
            {
              divisionName: 'Barangay Accounting Division',
              SectionUnit: [
                'Bookkeeping Section',
                'Processing Section',
                'Others',
              ],
            },
            {
              divisionName: 'Internal Audit Division',
              SectionUnit: [
                'Financial Audit Section',
                'System Audit Section',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Department of Public Order and Safety',
          division: [
            { divisionName: 'Administrative Division', SectionUnit: [] },
            {
              divisionName: 'Traffic Operations Division',
              SectionUnit: [
                'Traffic Planning Section',
                'Traffic Enforcement Section',
                'Others',
              ],
            },
            {
              divisionName: 'Security Division',
              SectionUnit: [
                'Personnel and Document Security Section',
                'Physical Security Section',
                'Others',
              ],
            },
            {
              divisionName: 'Disaster Control Division',
              SectionUnit: [
                'Disaster Preparedness Section',
                'Rescue and Relief Section',
                'Others',
              ],
            },
            {
              divisionName:
                'Anti-Squatting Enforcement and Relocation Division',
              SectionUnit: [
                'Squatter Demolition Section',
                'Squatter Relocation Section',
                'Others',
              ],
            },
            {
              divisionName: 'Tricycle Regulation Division',
              SectionUnit: [
                'Processing and Research Section',
                'Inspection and Monitoring Section',
                'Administration, Education and Data Management Section',
                'Research, Planning and Development Section',
                'Bicycle and Active Transport Section',
                'Others',
              ],
            },
            {
              divisionName: 'Inspection and Monitoring Division (IMD)',
              SectionUnit: [],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Social Services Development Department',
          division: [
            { divisionName: 'Administrative Division', SectionUnit: [] },
            {
              divisionName: 'Community Outreach Division',
              SectionUnit: [
                'Family Development Section',
                'Youth Development Section',
                'Child Development Section',
                'Others',
              ],
            },
            {
              divisionName: 'Vocational Development Division',
              SectionUnit: [
                'Skills Training Section',
                'Employment and Placement Section',
                'Others',
              ],
            },
            {
              divisionName: 'Residential and Rehabilitation Division',
              SectionUnit: ['Molave Youth Home', 'Others'],
            },
            {
              divisionName: 'Reception and Action Center for Mendicants ',
              SectionUnit: ['Home for the Aged and Hospice Section', 'Others'],
            },
            {
              divisionName: 'Welfare and Relief Division',
              SectionUnit: [
                'Public Assistance Section',
                'Volunteer and Auxiliary Section',
                'Others',
              ],
            },
            { divisionName: 'Public Assistance Division', SectionUnit: [] },
            { divisionName: 'Special Project Division', SectionUnit: [] },
            {
              divisionName: 'Child Welfare and Protection Office',
              SectionUnit: [],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department:
            'Department of Sanitation and Cleanup Works of Quezon City',
          division: [
            {
              divisionName: 'Waste Management Operations Division',
              SectionUnit: [
                'Waste Management Operations Division',
                'Disaster Relief and Cleanup Section',
                'Others',
              ],
            },
            {
              divisionName: 'Compliance and Permits Division',
              SectionUnit: [
                'Permits and Evaluation Section',
                'Compliance and Enforcement Section',
                'Others',
              ],
            },
            {
              divisionName:
                'Administrative Affairs and Property Management Division',
              SectionUnit: [
                'Administrative Affairs Section',
                'Property Management Section',
                'Others',
              ],
            },
            {
              divisionName: 'Public Affairs and Special Division',
              SectionUnit: ['Special Concerns Section', 'Others'],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department:
            'Climate Change and Environmental Sustainability Department',
          division: [
            {
              divisionName: 'Climate change Mitigation Divisions',
              SectionUnit: [
                'Low Carbon Development Section',
                'Environmental Quality Section',
                'Others',
              ],
            },
            {
              divisionName: 'Climate Change Adaptation Division',
              SectionUnit: [
                'Biodiversity Management and Food Security Section',
                'Resource Efficiency Section',
                'Others',
              ],
            },
            {
              divisionName: 'Climate Change and Environment Education Division',
              SectionUnit: [
                'Institutional Capacity Development Section',
                'Community and Stakeholder Engagement Section',
                'Others',
              ],
            },
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Human Resource Management Section',
                'Budget and Finance Section',
                'Records Management and General Services Section',
                'Others',
              ],
            },
            {
              divisionName: 'Others',
              SectionUnit: [
                'Management Information System Unit',
                'Performance Monitoring Unit',
                'Others',
              ],
            },
          ],
        },
        {
          department: 'Parks Development and Administration Department',
          division: [
            { divisionName: 'Administrative Division', SectionUnit: [] },
            {
              divisionName: 'Construction and Maintenance Division',
              SectionUnit: [],
            },
            { divisionName: 'Landscape Development Division', SectionUnit: [] },
            { divisionName: 'Nursery and Arboretum Division', SectionUnit: [] },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Market Development and Administration Department',
          division: [
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Personnel Section',
                'Fiscal and Statistical Section',
                'Market Inspection Section',
                'Others',
              ],
            },
            { divisionName: 'Public Market Division', SectionUnit: [] },
            { divisionName: 'Private Market Division', SectionUnit: [] },
            { divisionName: 'Technical Services Division', SectionUnit: [] },
            {
              divisionName: 'Hwaker’s Division',
              SectionUnit: [
                'Hawker’s Permit Section',
                'Hawker’s Inspection Section',
                'Others',
              ],
            },
            {
              divisionName: 'Others',
              SectionUnit: [
                'Management Information system Unit',
                'Security Unit',
                'Maintenance Unit',
                'Others',
              ],
            },
          ],
        },
        {
          department: 'Office of the City Assessor',
          division: [
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Personnel Section',
                'Records Section',
                'Fiscal Management Section',
                'Supply Section',
                'Others',
              ],
            },
            {
              divisionName: 'Assessment Records Management Division',
              SectionUnit: [
                'Assessment Processing Section',
                'Property Declaration Section',
                'Records Control Section',
                'Others',
              ],
            },
            {
              divisionName: 'Electronic Data Processing Division',
              SectionUnit: [
                'Data Preparation Section',
                'Computer Operation Section',
                'System Analysis and Support Section',
                'Others',
              ],
            },
            {
              divisionName: 'Tax/Mapping Operations Division',
              SectionUnit: [
                'Mapping section',
                'Tax Mapping Maintenance Section',
                'Property Identification Section',
                'Others',
              ],
            },
            {
              divisionName: 'Appraisal Division',
              SectionUnit: [
                'Building Appraisal Section',
                'Land Appraisal Section',
                'Machinery Appraisal Section',
                'Others',
              ],
            },
            {
              divisionName: 'Property Valuation Standard Division',
              SectionUnit: [
                'Market Valuation Section',
                'Building Plan Record Section',
                'Assessment Valuation Control Section',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Office of the City Treasure',
          division: [
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Records Section',
                'Personnel',
                'Fiscal Examination Section',
                'General Section',
                'Others',
              ],
            },
            {
              divisionName: 'Inspection and Examination Division',
              SectionUnit: ['Inspection', 'Examination', 'Others'],
            },
            { divisionName: 'EDP Division', SectionUnit: [] },
            { divisionName: 'Cash Division', SectionUnit: [] },
            {
              divisionName: 'Taxes and Fees Division',
              SectionUnit: [
                'Assesment',
                'Collection',
                'Market Collection',
                'Revenue Taxes and Fees Verification',
                'Miscellaneous Taxes',
                'Residence Tax',
                'Tax Delinquency and Verification',
                'Others',
              ],
            },
            {
              divisionName: 'Real Estate Division',
              SectionUnit: [
                'Collection',
                'Revenue Assessment',
                'Real Property Taxes and Verification',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Quezon City Engineering Department',
          division: [
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Personnel Section',
                'Records Section',
                'Accounting Section',
                'Property and Supply Section',
                'General Services Section',
                'Electronic Data Section',
                'Others',
              ],
            },
            {
              divisionName: 'Vertical Projects Supervision Division',
              SectionUnit: [],
            },
            {
              divisionName: 'Horizontal Projects Supervision Division',
              SectionUnit: ['Utilities Section', 'Others'],
            },
            {
              divisionName: 'Road, Drainage and Bridges Maintenance Division',
              SectionUnit: [],
            },
            {
              divisionName: 'Building Maintenance Division',
              SectionUnit: [],
            },
            {
              divisionName: 'Equipment Repair and Maintenance Division',
              SectionUnit: [],
            },
            { divisionName: 'Planning and Design Division', SectionUnit: [] },
            { divisionName: 'Technical Management Division', SectionUnit: [] },
            { divisionName: "City Engineer's Staff", SectionUnit: [] },
            {
              divisionName: 'Others',
              SectionUnit: ['Project Monitoring Unit', 'Others'],
            },
          ],
        },
        {
          department: 'Department of the Building Official',
          division: [
            {
              divisionName: 'Permit Processing for Simple Application Division',
              SectionUnit: [
                'Construction Permit Processing',
                'Final Permit Processing',
                'Compliance',
                'Others',
              ],
            },
            {
              divisionName: 'Construction Permit Division',
              SectionUnit: ['Green Building Section', 'Regular Section'],
            },
            {
              divisionName: 'Final Permit Division',
              SectionUnit: ['Regular Processing', 'Green Building', 'Others'],
            },
            {
              divisionName: 'Signboard & Utilities Division',
              SectionUnit: [
                'Sign Board Processing Section',
                'Utilities Excavation/DeepWell',
                'Others',
              ],
            },
            {
              divisionName: 'Investigation and Adjucation',
              SectionUnit: ['Adjucation', 'Inspection', 'Others'],
            },
            {
              divisionName:
                'Policy, Programming & System Administration Division',
              SectionUnit: [
                'Policy, Programming and Technical Compliance',
                'System Administration',
                'Administration & Operation Monitoring',
                'Others',
              ],
            },
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Receiving & Validation',
                'Assessment and Billing',
                'Releasing',
                'Administrative Support',
                'Others',
              ],
            },
            {
              divisionName: 'Annual Building Inspection Division',
              SectionUnit: [
                'Administrative',
                'Annual Inspection',
                'On-going Construction',
                'Others',
              ],
            },
            {
              divisionName: 'Others',
              SectionUnit: [
                'Personnel and Budget Unit',
                'Supply and Procurement Unit',
                'Administrative Relations Unit',
                'Records Unit',
                'Stand-Alone-Mechanical-and-Electrical-Unit-(SAMEU) ',
                'Others',
              ],
            },
          ],
        },
        {
          department: 'Quezon City Health Department',
          division: [
            {
              divisionName: 'Office of the City Health Officer',
              SectionUnit: [],
            },
            {
              divisionName: 'Office of the Assistant City Health Officer',
              SectionUnit: [],
            },
            {
              divisionName: 'Administrative Division',
              SectionUnit: ['PERSONNEL', 'BUDGET', 'PROPERTY', 'PHARMACY'],
            },
            {
              divisionName: 'Field Operation Division',
              SectionUnit: ['ALL FIELD'],
            },
            {
              divisionName:
                'Planning, Evaluation, Research and Training Division',
              SectionUnit: ['PLANNING', 'TRANING', 'CESU', 'HEPO'],
            },
            {
              divisionName: 'Environmental Sanitation Division',
              SectionUnit: [],
            },
            { divisionName: 'Nursing Division', SectionUnit: [] },
            { divisionName: 'Dental Division', SectionUnit: [] },
            { divisionName: 'Nutrition Section', SectionUnit: [] },
            { divisionName: 'Special Services Division', SectionUnit: [] },
            { divisionName: 'Midwifery Section', SectionUnit: [] },
            {
              divisionName: 'Health Information Management Section',
              SectionUnit: [],
            },
            { divisionName: 'Laboratory Section', SectionUnit: [] },
          ],
        },
        {
          department: 'Quezon City General Hospital',
          division: [
            {
              divisionName:
                'Planning Development Education and Research Department',
              SectionUnit: [
                'Management Information System Section',
                'Medical Services',
                'Others',
              ],
            },
            { divisionName: 'ER-OPD Emergency Department', SectionUnit: [] },
            { divisionName: 'Medical Department', SectionUnit: [] },
            { divisionName: 'Psychiatry Department', SectionUnit: [] },
            {
              divisionName: 'Surgery Department',
              SectionUnit: ['Surgery Department', 'Others'],
            },
            {
              divisionName: 'Ear, Nose and Throat Department',
              SectionUnit: [],
            },
            { divisionName: 'Ophthalmology Department', SectionUnit: [] },
            { divisionName: 'Orthopedic Department', SectionUnit: [] },
            {
              divisionName: 'Obstetrics and Gynecology Department',
              SectionUnit: [],
            },
            {
              divisionName: 'Paediatrics Department ',
              SectionUnit: ['Human Milk Bank Section', 'Others'],
            },
            { divisionName: 'Ancillary Medical Services', SectionUnit: [] },
            { divisionName: 'Anaesthesiology Department ', SectionUnit: [] },
            { divisionName: 'Laboratory Department', SectionUnit: [] },
            { divisionName: 'Radiology Department', SectionUnit: [] },
            {
              divisionName: 'Community Ambulatory Health Care Department ',
              SectionUnit: [],
            },
            { divisionName: 'Dental Department', SectionUnit: [] },
            { divisionName: 'Pharmacy Department', SectionUnit: [] },
            { divisionName: 'Medical Records Department', SectionUnit: [] },
            {
              divisionName: 'Medical Social Services Department',
              SectionUnit: [],
            },
            { divisionName: 'Nursing Department', SectionUnit: [] },
            {
              divisionName: 'Operating Room and Recovery Department',
              SectionUnit: [],
            },
            { divisionName: 'Ward Department', SectionUnit: [] },
            {
              divisionName: 'Delivery Room and Nursery Department',
              SectionUnit: [],
            },
            { divisionName: 'Dietetic Department', SectionUnit: [] },
            {
              divisionName: 'Administrative Division',
              SectionUnit: ['Records', 'Cash', 'Property and Supply', 'Others'],
            },
            { divisionName: 'Personnel Division', SectionUnit: [] },
            { divisionName: 'Accounting Division', SectionUnit: [] },
            { divisionName: 'Engineering Services', SectionUnit: [] },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Business Affairs Coordinating Office',
          division: [
            {
              divisionName: 'Others',
              SectionUnit: [],
            },
          ],
        },
        {
          department: 'Board of Assessment Appeals',
          division: [
            {
              divisionName: 'Others',
              SectionUnit: [],
            },
          ],
        },
        {
          department: 'Business Permits and Licensing Department',
          division: [
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Personnel and General Services',
                'Finance and Budget',
                'Others',
              ],
            },
            {
              divisionName:
                'Management Information System, Records and Archiving Division',
              SectionUnit: [
                'Management Information System',
                ' Records and Archiving',
                'Others',
              ],
            },
            {
              divisionName: 'Business Permit Division',
              SectionUnit: ['Business Permit', 'Occupational Permit', 'Others'],
            },
            {
              divisionName: 'Liquor Licensing Division',
              SectionUnit: ['Licensing', 'Regulatory', 'Others'],
            },
            {
              divisionName: 'Inspection and Verification Division',
              SectionUnit: ['Inspection', 'Verification', 'Others'],
            },
            {
              divisionName: 'Enforcement and Adjudication Division',
              SectionUnit: ['Enforcement', 'Adjudication', 'Others'],
            },
            { divisionName: 'Novaliches District Center', SectionUnit: [] },
          ],
        },
        {
          department:
            'Quezon City Small Business and Cooperatives Developement and Promotions Office',
          division: [
            {
              divisionName:
                'Planning , Program Development and Monitoring Division',
              SectionUnit: [
                'Policy and Planning Section',
                'Capacity Building and Monitoring Section',
                'Financing and Marketing Section',
                'Others',
              ],
            },
            {
              divisionName: 'Information Management Division',
              SectionUnit: [
                'Information Capture Structure and Sharing Section',
                'Database Management Section',
                'Others',
              ],
            },
            {
              divisionName:
                'Cooperatives Development and Business Resources Division',
              SectionUnit: [
                'Cooperatives Development Section',
                'Business Resources Section',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Quezon City Public Library',
          division: [
            {
              divisionName: 'Reader’s Services Division',
              SectionUnit: [
                'Reference Section',
                'Filipiniana, Local History and Archives Section',
                'Law Research Section',
                'Periodicals Section',
                'Management Information System Section',
                'Others',
              ],
            },
            {
              divisionName: 'Technical Services Division',
              SectionUnit: [
                'Collective Development Section',
                'Cataloging Section',
                'Binding and Preservation Section',
                'Others',
              ],
            },
            {
              divisionName: 'Library Extension Division',
              SectionUnit: [
                'Recreational, Educational and Social Activity Section',
                'Recreational, Educational and Social Activity Section',
                'Others',
              ],
            },
            { divisionName: 'District Libraries Division', SectionUnit: [] },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Barangay and Community Relations Department',
          division: [
            { divisionName: 'Administrative Division', SectionUnit: [] },
            {
              divisionName: 'Operations and Monitoring Division',
              SectionUnit: [],
            },
            {
              divisionName: 'Planning and Programming Division',
              SectionUnit: [],
            },
            {
              divisionName: 'Technical and Research Division',
              SectionUnit: [],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department:
            'Housing Community Development and Resettlement Department',
          division: [
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Central Records and Human Resource Section',
                'Budget and Supply Section',
                'Others',
              ],
            },
            {
              divisionName: 'Housing and Resettlement Division',
              SectionUnit: [
                'Community Mortgage and Program Section',
                'Community Development Section',
                'Direct Sales Section',
                'Others',
              ],
            },
            {
              divisionName: 'Census and Planning Division',
              SectionUnit: [
                'Census and Survey Section',
                'Technical Section',
                'Others',
              ],
            },
            {
              divisionName: 'Support Service Division',
              SectionUnit: [
                'Accounts Monitoring and Management Section',
                'Basic Utilities Section',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Information Technology Development Department',
          division: [
            {
              divisionName: 'Network and Technical Maintenance Division',
              SectionUnit: [],
            },
            { divisionName: 'Database Management Division', SectionUnit: [] },
            {
              divisionName: 'Systems Development Management Division',
              SectionUnit: [],
            },
            {
              divisionName:
                'Project Research and Project Research and Development Division',
              SectionUnit: [],
            },
            {
              divisionName: 'Administrative Division',
              SectionUnit: ['Others'],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Procurement Department',
          division: [
            {
              divisionName: 'Documentation and Administrative Support Division',
              SectionUnit: [],
            },
            { divisionName: 'Technical Services Division', SectionUnit: [] },
            {
              divisionName: 'Procurement Database and Management Division',
              SectionUnit: [],
            },
            {
              divisionName: 'Price Standardization and Monitoring Division',
              SectionUnit: [],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Quezon City University',
          division: [
            { divisionName: 'Administration and Finance', SectionUnit: [] },
            { divisionName: 'Academic Affairs', SectionUnit: [] },
            {
              divisionName: 'Research, Extension, Planning and Linkages',
              SectionUnit: [],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Public Employment Service Office (PESO)',
          division: [
            { divisionName: 'Employment Services Division', SectionUnit: [] },
            {
              divisionName: 'Labor Relations and Standard Division',
              SectionUnit: [],
            },
            { divisionName: 'Special Projects Division', SectionUnit: [] },
            {
              divisionName: 'Labor Market Information System Division',
              SectionUnit: [],
            },
            { divisionName: 'Migration Services Division', SectionUnit: [] },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Public Affairs and Information Services Department',
          division: [
            { divisionName: 'Editorial Services Division', SectionUnit: [] },
            { divisionName: 'Media Relations Division', SectionUnit: [] },
            { divisionName: 'Digital Media Division', SectionUnit: [] },
            {
              divisionName: 'Visual Production Services Division',
              SectionUnit: [],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Radio Communications Service',
          division: [{ divisionName: 'Others', SectionUnit: [] }],
        },
        {
          department: 'Quezon City Tourism Department',
          division: [
            { divisionName: 'Tourism Services Division', SectionUnit: [] },
            { divisionName: 'Tourism Coordination Division', SectionUnit: [] },
            { divisionName: 'Tourism Promotions Division', SectionUnit: [] },
            { divisionName: 'Tourism Planning Division', SectionUnit: [] },
            { divisionName: 'Quezon City Symphonic Band', SectionUnit: [] },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'City Architect Department',
          division: [
            { divisionName: 'Administrative Division', SectionUnit: [] },
            {
              divisionName: 'Planning and Programming Division',
              SectionUnit: [],
            },
            { divisionName: 'Architectural Design Division ', SectionUnit: [] },
            {
              divisionName: 'Supervision and Monitoring Division',
              SectionUnit: [],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'City Veterinary Department',
          division: [
            {
              divisionName: 'Food Hygiene and Regulatory Division',
              SectionUnit: [],
            },
            { divisionName: 'City Pound Division', SectionUnit: [] },
            {
              divisionName: 'Animal Care & Disease Control Division',
              SectionUnit: [],
            },
            { divisionName: 'Administrative Division', SectionUnit: [] },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Quezon City Anti-Drug Council (QCADAC)',
          division: [
            {
              divisionName: 'Others',
              SectionUnit: [
                'Technical Research and Legal Services Section',
                'In-take, Referral and Evaluation Section',
                'Intelligence, Surveillance and Operation Section',
                'Administrative Section',
                'Others',
              ],
            },
          ],
        },
        {
          department:
            'Quezon City Drug Treatment and Rehabilitation Center (TAHANAN)',
          division: [
            {
              divisionName: 'Others',
              SectionUnit: [
                'Technical Research and Legal Services Section',
                'Rehabilitation and Residential Section',
                'dministrative Services Section',
                'Others',
              ],
            },
          ],
        },
        {
          department: 'Quezon City Center for Children with Disabilities',
          division: [
            {
              divisionName: 'Others',
              SectionUnit: [
                'Administrative Staff Support Unit',
                'Health Unit',
                'Education Unit',
                'Livelihood Unit',
                'Social and Empowerment Unit',
                'Others',
              ],
            },
          ],
        },

        {
          department: 'Quezon City Youth Development Office',
          division: [
            {
              divisionName: 'Policy Research, Program Development and Planning',
              SectionUnit: [
                'Policy Research and Program Development',
                'Planning',
                'Others',
              ],
            },
            {
              divisionName: 'Youth Welfare Programs and Operations',
              SectionUnit: [
                'Youth Welfare Programs',
                'Youth Welfare Operations',
                'Others',
              ],
            },
            {
              divisionName: 'Management Information Systems ',
              SectionUnit: [],
            },
            {
              divisionName:
                'Operations, Coordinations, and Monitoring Division',
              SectionUnit: [],
            },
            {
              divisionName: 'Operation and Administrative Staff',
              SectionUnit: [],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department:
            'Quezon City Protection Center for Victims/Survivors of Gender Based Violence And Abuse',
          division: [
            {
              divisionName: 'Others',
              SectionUnit: [
                'Administrative Unit',
                'Counselling and Case Intake Unit',
                'Medical Support Unit',
                'Legal and Security Unit',
                'Advocacy and Network Unit',
                'Others',
              ],
            },
          ],
        },
        {
          department: "Quezon City People's Law Enforcement Center Board",
          division: [{ divisionName: 'Others', SectionUnit: [] }],
        },
        {
          department: 'Office of the City Prosecutor',
          division: [
            { divisionName: 'Administrative Division', SectionUnit: [] },
            { divisionName: 'Records and Docket Division', SectionUnit: [] },
            { divisionName: 'Secretarial Division', SectionUnit: [] },
            { divisionName: 'General Services Division', SectionUnit: [] },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Division of City Schools',
          division: [
            {
              divisionName: 'Administrative Division',
              SectionUnit: [
                'Records Section',
                'Personnel Section',
                'Accounting Section',
                'Infrastructure Development Section',
                'Property and Supply Section',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Local Economic Investment Promotions Office',
          division: [
            { divisionName: 'Promotions and Marketing', SectionUnit: [] },
            { divisionName: 'Operations', SectionUnit: [] },
            { divisionName: 'Technical Support ', SectionUnit: [] },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Human Resource Management Department',
          division: [
            {
              divisionName:
                'Human Resource Development And Administrative Support',
              SectionUnit: ['Human Resource Development', 'Others'],
            },
            {
              divisionName: 'Personnel Actions',
              SectionUnit: [
                'Appointment and Personnel Movement',
                'Personnel Records',
                'Others',
              ],
            },
            {
              divisionName: 'Compensation and Benefits',
              SectionUnit: [
                'Employee Compensation',
                'Employee Benefits',
                'Others',
              ],
            },
            {
              divisionName: 'Recruitment and Career Management',
              SectionUnit: ['Recruitment', 'Career Management ', 'Others'],
            },
            {
              divisionName:
                'Human Resource Planning and Organizational Development',
              SectionUnit: [
                'Human Resource Planning',
                'Organizational Development',
                'Others',
              ],
            },
            { divisionName: 'Others', SectionUnit: [] },
          ],
        },
        {
          department: 'Others',
          division: [{ divisionName: '', SectionUnit: [''] }],
        },
        {
          department: 'Persons with Disability Affairs Office',
          division: [
            {
              divisionName: 'Others',
              SectionUnit: [
                'Monitoring and Data Management Unit',
                'Learning and Capacity Development Unit',
                'Operations Unit',
                'Others',
              ],
            },
          ],
        },
        {
          department: 'Rosario Maclang Bautista General Hospital',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Novaliches District Hospital',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Quezon City Fire District',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department:
            'Task Force for Transport and Traffic Management (TF-TTM)',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Investment Affairs Office',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Office of the Secretary to the Mayor',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'City Treasurer’s Office',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'People’s Law Enforcement Board',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department:
            'Quezon City Disaster and Risk Reduction Management Office',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Quezon City Police District',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department:
            'Small Business and Cooperatives Development and Promotions Office',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'House Resources Management Department',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Tricycle Franchising',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Liquor Licensing and Regulatory Board',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Sports Development',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Tricycle Franchising',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department:
            'Office of the Sectorial Representative (Liga Ng Mga Barangay)',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Chief of Staff (COS)',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Quezon City Competency Assessment Center (QC-CAC)',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Traffic and Transport Management Department (TTMD)',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Quezon City Citizen Services Department (QCCSD)',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Special Operating Units',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Amoranto Sports Complex',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Office for the Senior Citizen’s Affairs',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Zoning Administrative Unit',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'QC Bangsamoro Affairs Office',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Task Force Street lighting',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'QC Epidemiology & Surveillance Unit',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Educational Affairs Unit',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Task Force Disiplina',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Task Force COPRESS',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Quezon City Memorial Cicle Administration Office',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Internal Audit Service',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Strategic Human Resource Unit',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Bureau of Fire Protection (BFP)',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Bureau of Jail Management and Penology (BJMP)',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Department of Justice Board of Pardons and Parole',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Commission on Audit (COA)',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Deparment of the Internal and Local Government (DILG)',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Metropolitan Trial Court',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Regional Trial Court',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'District Action Office 1',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'District Action Office 2',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'District Action Office 3',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'District Action Office 4',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'District Action Office 5',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'District Action Office 6',
          division: [{ divisionName: 'Others', SectionUnit: ['Others'] }],
        },
        {
          department: 'Gender and Development Council Office',
          division: [
            {
              divisionName: 'Regular and Special Projects Division',
              SectionUnit: ['Others'],
            },
            {
              divisionName: 'Administrative Support Services Division',
              SectionUnit: ['Others'],
            },
            {
              divisionName:
                'Research, Profiling, Monitoring and Evaluation Division',
              SectionUnit: ['Others'],
            },
            {
              divisionName: 'Capacity Building Division',
              SectionUnit: ['Others'],
            },
            {
              divisionName: 'CODI Secretariat Division',
              SectionUnit: ['Others'],
            },
            {
              divisionName: 'GAD Council Secretariat Division',
              SectionUnit: ['Others'],
            },
            {
              divisionName: 'Pride Council Secretariat Division',
              SectionUnit: ['Others'],
            },
          ],
        },
      ],
    })
  }

  /**
   * @function changeDepartment
   * @description Updates the state with the selected department. If the selected department is not 'Others', it sets the related division and resets the section/unit values. If the selected department is 'Others', it sets the division and section/unit to 'Others'.
   *
   * @param {Event} event - The selected department value.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  changeDepartment = (event) => {
    this.setState({ assignedDept: event })
    if (event !== 'Others') {
      this.setState((prevState) => {
        const foundDepartment = prevState.DepartmentX.find(
          (d) => d.department === event
        )
        return {
          DivisionX: foundDepartment ? foundDepartment.division : '',
          divisionN: 'Select Division',
          SectionUnitX: '',
          SectionUnit: [],
        }
      })
    } else {
      this.setState({
        divisionN: 'Others',
        SectionUnitX: 'Others',
      })
    }
  }

  /**
   * @function handleFocusOrChange
   * @description Handles changes or focus events for various input fields by resetting specific validation states. This function is used to clear validation messages when the user interacts with the fields.
   *
   * @param {Event} e - The event object from the focus or change event.
   * @param {string} inputName - The name of the input field to determine which validation state to reset.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  handleFocusOrChange = (e, inputName) => {
    try {
      if (inputName === 'employeeID') {
        this.setState({ employeeIDValidation: false })
      }
      if (inputName === 'homeDepartment') {
        this.setState({ deptValidation: false })
      }
      if (inputName === 'assignedDepartment') {
        this.setState({ assignedDeptValidation: false })
      }
      if (inputName === 'gender') {
        this.setState({ genderValidation: false })
      }
      if (inputName === 'contact') {
        this.setState({ contactValidation: false })
      }
      if (inputName === 'division') {
        this.setState({ divisionValidation: false })
      }
      if (inputName === 'section') {
        this.setState({ sectionUnitValidation: false })
      }
      if (inputName === 'position') {
        this.setState({ positionValidation: false })
      }
      if (inputName === 'username') {
        this.setState({ usernameValidation: false })
      }
      if (inputName === 'password') {
        this.setState({ passwordValidation: false })
      }
      if (inputName === 'confirmPassword') {
        this.setState({ confirmPasswordValidation: false })
      }
      if (inputName === 'appointmentStatus') {
        this.setState({ appointmentStatusValidation: false })
      }

      if (inputName === 'suffix') {
        this.setState((prevState) => ({
          validationErrors: {
            ...prevState.validationErrors,
            [inputName]: false,
          },
        }))
      }
      if (inputName === 'consentGiven') {
        this.setState({ consentValidation: false })
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function changeDivision
   * @description Updates the state with the selected division and corresponding section/unit values. It also manages the disabled state of the section/unit dropdown based on the availability of section/unit options.
   *
   * @param {Event} event - The selected division value.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  changeDivision = (event) => {
    const division = this.state.DepartmentX.find(
      (d) => d.department === this.state.assignedDept
    ).division
    const sectionUnit = division.find(
      (stat) => stat.divisionName === event
    ).SectionUnit

    this.setState({
      divisionN: event,
      SectionUnit: sectionUnit.length > 0 ? sectionUnit : [],
      SectionUnitX: sectionUnit.length > 0 ? 'Select Section/Unit' : 'Others',
      disabledSection: sectionUnit.length === 0,
    })
  }

  /**
   * @function handleSubmit
   * @description Handles form submission by validating all fields and managing validation errors. If no errors are found, it updates the state to enable form submission. If errors are present, it displays them.
   *
   * @param {Event} e - The form submit event.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const {
        fname,
        mname,
        lname,
        suffix,
        gender,
        appointmentStatus,
        cpassword,
        password,
        assignedDept,
        SectionUnitX,
        divisionN,
        dept,
        status,
        employeeIDNumber,
        contact,
        position,
        username,
      } = this.state

      // Reset all validation errors
      this.resetValidationErrors()

      let errors = []

      // Validate fields
      this.validateName(fname, 'First name', 'fname', errors)
      this.validateName(mname, 'Middle name', 'mname', errors, true)
      this.validateName(lname, 'Last name', 'lname', errors)
      this.validateContact(contact, errors)
      this.validateSuffix(suffix, errors)
      this.validateField(gender, 'gender', 'Please select a gender.', errors)
      this.validateField(
        appointmentStatus,
        'appointmentStatus',
        'Please select an appointment status.',
        errors
      )
      this.validateEmployeeID(employeeIDNumber, errors)
      this.validateDropdown(
        dept,
        'Select Home Department',
        'dept',
        'Please select a home department.',
        errors
      )
      this.validateDropdown(
        assignedDept,
        'Select Assigned Department',
        'assignedDept',
        'Please select an assigned department.',
        errors
      )
      this.validateDropdown(
        divisionN,
        'Select Division',
        'division',
        'Please select a division.',
        errors
      )
      this.validateDropdown(
        SectionUnitX,
        'Select Section/Unit',
        'sectionUnit',
        'Please select a section/unit.',
        errors
      )
      this.validateField(
        position,
        'position',
        'Please enter a position.',
        errors
      )
      this.validateUsername(username, errors) // Added username validation
      this.validatePasswords(password, cpassword, errors)

      // Handle errors
      if (errors.length > 0) {
        this.displayErrors(errors)
      } else if (status === 0) {
        this.setState({ disabled: false, setOpenModal: true })
      } else {
        // Additional logic if needed
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function resetValidationErrors
   * @description Resets all validation errors and disables the form. This function is called before performing new validations to ensure that previous errors are cleared.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  resetValidationErrors = () => {
    try {
      this.setState({
        disabled: true,
        contactValidation: '',
        employeeIDValidation: '',
        genderValidation: '',
        deptValidation: '',
        assignedDeptValidation: '',
        divisionValidation: '',
        sectionUnitValidation: '',
        passwordValidation: '',
        confirmPasswordValidation: '',
        positionValidation: '',
        usernameValidation: '',
        appointmentStatusValidation: '',
        validationErrors: {
          fname: '',
          mname: '',
          lname: '',
          suffix: '',
        },
      })
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * @function validateName
   * @description Validates a name field based on required criteria, including optional status, regex pattern, and length constraints. Updates the state with validation errors as necessary.
   *
   * @param {string} name - The name entered by the user.
   * @param {string} displayName - The display name for the field, used in error messages.
   * @param {string} field - The name of the field being validated, used for setting state.
   * @param {Array} errors - An array to collect validation error messages.
   * @param {boolean} [optional=false] - Indicates if the field is optional (defaults to false).
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  validateName = (name, displayName, field, errors, optional = false) => {
    try {
      if (!optional && (!name || name.trim() === '')) {
        errors.push(`${displayName} is required.`)
        this.setState((prevState) => ({
          validationErrors: {
            ...prevState.validationErrors,
            [field]: `${displayName} is required.`,
          },
        }))
      } else if (name && !this.namePattern.test(name)) {
        errors.push(`Invalid ${displayName}.`)
        this.setState((prevState) => ({
          validationErrors: {
            ...prevState.validationErrors,
            [field]: `Invalid ${displayName}.`,
          },
        }))
      } else if (name && name.length >= 256) {
        errors.push('Maximum 256 characters allowed.')
        this.setState((prevState) => ({
          validationErrors: {
            ...prevState.validationErrors,
            [field]: 'Maximum 256 characters allowed',
          },
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function validateSuffix
   * @description Validates a suffix field based on criteria, including regex pattern and length constraints. Updates the state with validation errors as necessary.
   *
   * @param {string} suffix - The suffix entered by the user.
   * @param {Array} errors - An array to collect validation error messages.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  validateSuffix = (suffix, errors) => {
    try {
      if (suffix === undefined || suffix === null || suffix === '') {
        // Treat undefined, null, or empty string as missing value
        errors.push(`Suffix is required.`)
        this.setState((prevState) => ({
          validationErrors: {
            ...prevState.validationErrors,
            suffix: `Suffix is required.`,
          },
        }))
      } else if (suffix === 'N/A') {
        // Valid case when suffix is 'N/A'
        this.setState((prevState) => ({
          validationErrors: {
            ...prevState.validationErrors,
            suffix: '',
          },
        }))
      } else if (suffix && !this.namePattern.test(suffix)) {
        errors.push(`Invalid suffix.`)
        this.setState((prevState) => ({
          validationErrors: {
            ...prevState.validationErrors,
            suffix: `Invalid suffix.`,
          },
        }))
      } else if (suffix && suffix.length >= 256) {
        errors.push('Maximum 256 characters allowed.')
        this.setState((prevState) => ({
          validationErrors: {
            ...prevState.validationErrors,
            suffix: 'Maximum 256 characters allowed',
          },
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function validateContact
   * @description Validates a contact number to ensure it starts with 9 and is 10 digits long. Updates the state with validation errors if the contact number is invalid.
   *
   * @param {string} contact - The contact number entered by the user.
   * @param {Array} errors - An array to collect validation error messages.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  validateContact = (contact, errors) => {
    try {
      // Pattern for Philippine mobile numbers: +63 9XX XXX XXXX
      const contactPattern = /^9\d{9}$/

      if (!contactPattern.test(contact)) {
        errors.push(
          'Please enter a valid Philippine mobile number (10 digits starting with 9, e.g., 9123456789).'
        )
        this.setState({
          contactValidation:
            'Please enter a valid Philippine mobile number (10 digits starting with 9, e.g., 9123456789).',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function validateField
   * @description Validates a field to check for its presence and length constraints. Updates the state with validation errors as necessary.
   *
   * @param {string} field - The value of the field being validated.
   * @param {string} fieldName - The name of the field being validated, used for setting state.
   * @param {string} errorMessage - The error message to display if the field is invalid.
   * @param {Array} errors - An array to collect validation error messages.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */

  validateField = (field, fieldName, errorMessage, errors) => {
    try {
      const romanNumeralsPattern = /^(I{1,3}|IV|V|VI{0,3})$/ // Allows Roman numerals from I to III, IV, V, VI to VIII
      const consecutiveSpacesPattern = /\s{2,}/ // Detects consecutive spaces
      const allowedCharactersPattern = /^[a-zA-Z\s]+$/ // Allows letters and spaces
      const numberPattern = /\d/ // Detects any number

      if (!field) {
        errors.push(errorMessage)
        this.setState({ [`${fieldName}Validation`]: errorMessage })
      } else {
        // Check if the field contains a Tab character
        if (field.includes('\t')) {
          errors.push('Tab character is not allowed.')
          this.setState({
            [`${fieldName}Validation`]: 'Tab character is not allowed.',
          })
        }

        // Check for consecutive spaces
        if (consecutiveSpacesPattern.test(field)) {
          errors.push('Consecutive spaces are not allowed.')
          this.setState({
            [`${fieldName}Validation`]: 'Consecutive spaces are not allowed.',
          })
        }
        // Check if the field contains numbers that are not Roman numerals
        else if (
          numberPattern.test(field) &&
          !romanNumeralsPattern.test(field)
        ) {
          errors.push('Numbers are not allowed.')
          this.setState({
            [`${fieldName}Validation`]: 'Numbers are not allowed.',
          })
        }
        // Check if the field contains special characters
        else if (!allowedCharactersPattern.test(field) && field !== 'N/A') {
          errors.push('Special characters are not allowed.')
          this.setState({
            [`${fieldName}Validation`]: 'Special characters are not allowed.',
          })
        }
        // Check the length of the field
        else if (field.length > 256) {
          errors.push('Maximum 256 characters allowed.')
          this.setState({
            [`${fieldName}Validation`]: 'Maximum 256 characters allowed.',
          })
        }
        // Clear the validation error if valid
        else {
          this.setState({
            [`${fieldName}Validation`]: '',
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function validateDropdown
   * @description Validates the selected value in a dropdown field. Checks if the field value matches an invalid value and updates the state with a validation error if necessary.
   *
   * @param {string} field - The current value of the dropdown field.
   * @param {string} invalidValue - The value that is considered invalid.
   * @param {string} fieldName - The name of the field being validated, used for setting state.
   * @param {string} errorMessage - The error message to display if the field value is invalid.
   * @param {Array} errors - An array to collect validation error messages.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  validateDropdown = (field, invalidValue, fieldName, errorMessage, errors) => {
    try {
      if (field === invalidValue) {
        errors.push(errorMessage)
        this.setState({ [`${fieldName}Validation`]: errorMessage })
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function validateEmployeeID
   * @description Validates the provided employee ID. Checks for required fields, length constraints, and specific character patterns. Updates the state with validation errors as necessary.
   *
   * @param {string} employeeID - The employee ID entered by the user.
   * @param {Array} errors - An array to collect validation error messages.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  validateEmployeeID = (employeeID, errors) => {
    try {
      const maxLength = 100 // Maximum length is 100 characters

      // Pattern allows only letters (A-Z, a-z), numbers (0-9), dashes (-), and forward slashes (/)
      // Examples of valid IDs: ABC123, 123-456, A1/B2/C3
      const employeeIDPattern = /^[A-Za-z0-9/-]+$/

      if (!employeeID) {
        errors.push('Please enter an employee ID number.')
        this.setState({
          employeeIDValidation: 'Please enter an employee ID number.',
        })
      } else if (employeeID.length > maxLength) {
        errors.push(`Employee ID should be up to ${maxLength} characters.`)
        this.setState({
          employeeIDValidation: `Employee ID should be up to ${maxLength} characters.`,
        })
      } else if (!employeeIDPattern.test(employeeID)) {
        errors.push(
          'Employee ID should only include letters, numbers, dashes, and slashes.'
        )
        this.setState({
          employeeIDValidation:
            'Employee ID should only include letters, numbers, dashes, and slashes.',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Regular expression pattern for validating email addresses
  validEmailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  /**
   * @function validateUsername
   * @description Validates the provided username, checking for required fields and email format. Updates the state with validation errors as necessary.
   *
   * @param {string} username - The username entered by the user, expected to be in email format.
   * @param {Array} errors - An array to collect validation error messages.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  validateUsername = (username, errors) => {
    try {
      if (!username || username.trim() === '') {
        errors.push('Username is required.')
        this.setState({ usernameValidation: 'Username is required.' })
      } else if (!this.validEmailRegex.test(username)) {
        errors.push('Invalid email format for username.')
        this.setState({
          usernameValidation: 'Invalid email format for username.',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function validatePasswords
   * @description Validates the provided password and confirm password fields. It checks for required fields, matching passwords, and specific password requirements (such as length and character types). Updates the state with validation errors as necessary.
   *
   * @param {string} password - The password entered by the user.
   * @param {string} cpassword - The confirm password entered by the user.
   * @param {Array} errors - An array to collect validation error messages.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  validatePasswords = (password, cpassword, errors) => {
    try {
      // Reset validation state
      this.setState({
        passwordValidation: '',
        confirmPasswordValidation: '',
      })

      // Check if password and confirm password are provided
      if (!password || password.trim() === '') {
        errors.push('Password is required.')
        this.setState({
          passwordValidation: 'Password is required.',
        })
      }

      if (!cpassword || cpassword.trim() === '') {
        errors.push('Confirm password is required.')
        this.setState({
          confirmPasswordValidation: 'Confirm password is required.',
        })
      }

      if (password !== cpassword) {
        errors.push('Confirm password and password do not match.')
        this.setState({
          confirmPasswordValidation:
            'Confirm password and password do not match.',
        })
      }

      // Password requirements
      const passwordRequirements = [
        { regex: /\d/, message: 'Password should contain at least 1 number.' },
        {
          regex: /[A-Z]/,
          message: 'Password should contain at least 1 uppercase letter.',
        },
        {
          regex: /[a-z]/,
          message: 'Password should contain at least 1 lowercase letter.',
        },
        // Optional special character requirement
        {
          regex: /[!@#$%^&*(),.?":{}|<>]/,
          message:
            'Password should contain at least 1 special character (optional).',
        },
      ]

      // Check for consecutive spaces
      if (/\s{2,}/.test(password)) {
        errors.push('Password should not contain consecutive spaces.')
        this.setState({
          passwordValidation: 'Password should not contain consecutive spaces.',
        })
      }

      if (password.length < 8) {
        errors.push('Password should be at least 8 characters.')
        this.setState({
          passwordValidation: 'Password should be at least 8 characters.',
        })
      }

      // Check password length (maximum 16 characters)
      if (password.length > 16) {
        errors.push('Password should be no more than 16 characters.')
        this.setState({
          passwordValidation: 'Password should be no more than 16 characters.',
        })
      }

      // Check if the password meets all the required requirements
      passwordRequirements.forEach((requirement) => {
        // Skip optional special character requirement if it's not specified
        if (
          requirement.regex.source === '[!@#$%^&*(),.?":{}|<>]' &&
          !requirement.regex.test(password)
        ) {
          // Special character requirement not met but it's optional, so skip
          return
        }

        if (!requirement.regex.test(password)) {
          errors.push(requirement.message)
          this.setState((prevState) => ({
            passwordValidation: prevState.passwordValidation
              ? `${prevState.passwordValidation} ${requirement.message}`
              : requirement.message,
          }))
        }
      })
    } catch (error) {
      console.error('Password validation error:', error)
    }
  }

  /**
   * @function displayErrors
   * @description Displays a notification with validation errors and updates the state to enable certain actions.
   *
   * @param {Object} errors - The object containing validation errors.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  displayErrors = (errors) => {
    try {
      notification.open({
        message: 'Validation Errors',
        duration: 5,
        description: 'Some input fields are invalid or missing values.',
        icon: <SmileOutlined style={{ color: '#108ee9' }} />,
      })
      this.setState({ disabled: false })
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function componentDidUpdate
   * @description React lifecycle method that is called after the component updates. If the props have changed, it updates the state to enable certain actions.
   *
   * @param {Object} prevProps - The previous props before the update.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  componentDidUpdate(prevProps) {
    try {
      if (this.props !== prevProps) {
        this.setState({ disabled: false })
        this.setState({ setOpenModal: false })
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * @function handleCaptcha
   * @description Handles the CAPTCHA verification process. If the CAPTCHA is successfully completed, it calls the sign-up function with the current state and hides the sign-up button. If not, it displays an error notification.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */
  handleCaptcha = () => {
    try {
      if (this.state.captcha) {
        this.props.signUp(this.state)
        this.setState({ showButton: false })
      } else {
        notification['error']({
          message: 'Error',
          description: 'You must answer captcha to register.',
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Regular expression pattern for validating names
  // Allows letters (including accented characters), spaces, hyphens, periods, and apostrophes
  namePattern = /^(?:[a-zA-ZñéáíóúÑÉÁÍÓÚ.\s-']+)$/
  // Maximum length for input fields
  maxLength = 256

  /**
   * @function handleInputChange
   * @description Handles input change events and validates the input against a regex pattern and a maximum length.
   * Updates the state with the input value and any validation errors.
   *
   * @param {Object} e - The event object from the input change event.
   * @param {string} state - The state property name that corresponds to the input field being validated.
   *
   * @returns {void}
   *
   * @throws {Error} Logs any caught errors to the console.
   */

  handleInputChange = (e, state) => {
    try {
      const value = e.target.value

      // Check for tab character
      if (value.includes('\t')) {
        this.setState((prevState) => ({
          [state]: value,
          validationErrors: {
            ...prevState.validationErrors,
            [state]: (
              <span style={textValidation}>Tab character is not allowed</span>
            ),
          },
        }))
        return
      }

      // Validate against regex pattern and max length
      if (!this.namePattern.test(value) && value !== '') {
        // Invalid input detected
        this.setState((prevState) => ({
          [state]: value,
          validationErrors: {
            ...prevState.validationErrors,
            [state]: <span style={textValidation}>Invalid input fields</span>,
          },
        }))
      } else if (value.length > this.maxLength) {
        // Input exceeds max length
        this.setState((prevState) => ({
          [state]: value,
          validationErrors: {
            ...prevState.validationErrors,
            [state]: `Maximum ${this.maxLength} characters allowed`,
          },
        }))
      } else {
        // Input is valid, update state
        this.setState((prevState) => ({
          [state]: value,
          validationErrors: {
            ...prevState.validationErrors,
            [state]: '', // Clear validation error if valid
          },
        }))
      }
    } catch (error) {
      console.log(error)
    }
  }

  render() {
    const dept = listOfDepartments()
    const showPage = this.state.showPage

    // Gender title based on selected
    let genderTitle
    const genderLowercase = this.state.gender.toLowerCase()

    if (genderLowercase === 'female') {
      genderTitle = 'Female'
    } else if (genderLowercase === 'male') {
      genderTitle = 'Male'
    } else {
      genderTitle = 'Prefer not to say'
    }
    return (
      <>
        {showPage === true ? (
          <>
            {/*<Modal
                title=""
                closable={false}
                visible={this.state.isMaintenance}
                style={{ top: 20 }}
                width={1000}
                footer={[
                  <Button key="submit" onClick={this.handleIsMaintenance}>
                    OK
                  </Button>,
                ]}
              >
                <div id="maintenace-alert">
                  <Alert
                    style={{ marginBottom: "10px" }}
                    message=""
                    description="***This site will have a server maintenance between 10:00 PM to 11:00 PM***"
                    type="warning"
                    banner={true}
                  />
                </div>
              </Modal>*/}
            <div className='mainLogin-div'>
              <div className='mainHeader-login'>
                <div className='loginHead-title'>
                  {/* <img
                      src="https://quezoncity.gov.ph/wp-content/themes/qcproject/images/qclogo_main.png"
                      alt="QCLogo"
                    />
                    <span>Quezon City</span> */}
                </div>
                <div className='loginHead-date'>{`${this.state.day}, ${this.state.date}`}</div>
              </div>
              <div className='loginCard-div register-page-height'>
                <form
                  onSubmit={this.handleSubmit}
                  className='loginCard-left'
                  style={{ width: '100%', padding: '30px 0' }}
                >
                  <div className='loginTitle'>REGISTER</div>
                  <div className='reg2-row'>
                    <span>
                      <label className='loginInput-label' htmlFor='fname'>
                        Firstname<span style={textRequired}>*</span>
                      </label>
                      <Input
                        disabled={this.state.disabled}
                        id='fname'
                        placeholder='Enter your first name'
                        onChange={(e) => this.handleInputChange(e, 'fname')}
                        maxLength={257}
                        required
                        className='loginInput'
                        style={
                          this.state.validationErrors.fname ? inputError : {}
                        }
                        value={this.state.fname}
                      />{' '}
                      {this.state.validationErrors.fname && (
                        <p style={textValidation} className='error-message'>
                          {this.state.validationErrors.fname}
                        </p>
                      )}
                    </span>
                    <span>
                      <label className='loginInput-label' htmlFor='mname'>
                        Middlename
                      </label>
                      <Input
                        disabled={this.state.disabled}
                        id='mname'
                        maxLength={257}
                        placeholder='Enter your middle name'
                        onChange={(e) => this.handleInputChange(e, 'mname')}
                        className='loginInput'
                        style={
                          this.state.validationErrors.mname ? inputError : {}
                        }
                        value={this.state.mname}
                        // type="email"
                      />
                      {this.state.validationErrors.mname && (
                        <p style={textValidation} className='error-message'>
                          {this.state.validationErrors.mname}
                        </p>
                      )}
                    </span>
                  </div>
                  <div className='reg2-row'>
                    <span>
                      <label className='loginInput-label' htmlFor='lname'>
                        Lastname<span style={textRequired}>*</span>
                      </label>
                      <Input
                        disabled={this.state.disabled}
                        id='lname'
                        placeholder='Enter your last name'
                        onChange={(e) => this.handleInputChange(e, 'lname')}
                        required
                        className='loginInput'
                        style={
                          this.state.validationErrors.lname ? inputError : {}
                        }
                        // type="email"

                        value={this.state.lname}
                      />
                      {this.state.validationErrors.lname && (
                        <p style={textValidation} className='error-message'>
                          {this.state.validationErrors.lname}
                        </p>
                      )}
                    </span>

                    <span>
                      <label className='loginInput-label' htmlFor='suffix'>
                        Suffix<span style={textRequired}>*</span>
                      </label>
                      <Select
                        disabled={this.state.disabled}
                        id='suffix'
                        placeholder='Select your suffix'
                        value={this.state.suffix}
                        onChange={(value) => this.setState({ suffix: value })}
                        onFocus={(e) => this.handleFocusOrChange(e, 'suffix')}
                        className='loginInput'
                        style={
                          this.state.validationErrors.suffix ? inputError : {}
                        }
                      >
                        <option value='' disabled>
                          Select your suffix
                        </option>
                        <option value='N/A'>N/A</option>
                        <option value='JR.'>JR.</option>
                        <option value='SR.'>SR.</option>
                        <option value='I'>I</option>
                        <option value='II'>II</option>
                        <option value='III'>III</option>
                        <option value='IV'>IV</option>
                        <option value='V'>V</option>
                        <option value='VI'>VI</option>
                        <option value='VII'>VII</option>
                        <option value='VIII'>VIII</option>
                        <option value='IX'>IX</option>
                        <option value='X'>X</option>
                      </Select>
                      {this.state.validationErrors.suffix && (
                        <p style={textValidation} className='error-message'>
                          {this.state.validationErrors.suffix}
                        </p>
                      )}
                    </span>
                  </div>
                  <div className='reg2-row'>
                    <span>
                      <label className='loginInput-label' htmlFor='con'>
                        Contact<span style={textRequired}>*</span>
                      </label>
                      <Input
                        disabled={this.state.disabled}
                        // prefix={}
                        onChange={(e) =>
                          this.setState({ contact: e.target.value })
                        }
                        onFocus={(e) => this.handleFocusOrChange(e, 'contact')}
                        id='con'
                        addonBefore='+63'
                        maxLength={10}
                        required
                        // type="tel"
                        aria-label='Contact number'
                        aria-describedby='contact-help'
                        placeholder='Enter your contact number'
                        className='loginInput'
                        style={this.state.contactValidation ? inputError : {}}
                        value={this.state.contact}
                      />
                      {this.state.contactValidation && (
                        <p style={textValidation}>
                          {this.state.contactValidation}
                        </p>
                      )}
                    </span>

                    <span>
                      <label className='loginInput-label' htmlFor='gender'>
                        Gender<span style={textRequired}>*</span>
                      </label>
                      <Select
                        placeholder='Select gender'
                        onChange={(value) => this.setState({ gender: value })}
                        onFocus={(e) => this.handleFocusOrChange(e, 'gender')}
                        id='gender'
                        className='loginInput'
                        style={this.state.genderValidation ? inputError : {}}
                        required
                      >
                        <Option value='N/A'>Prefer not to say</Option>
                        <Option value='male'>Male</Option>
                        <Option value='female'>Female</Option>
                      </Select>
                      {this.state.genderValidation && (
                        <p style={textValidation}>
                          {this.state.genderValidation}
                        </p>
                      )}
                    </span>
                  </div>
                  <div style={{ width: '80%', marginBottom: 10 }}>
                    <span>
                      <label
                        className='loginInput-label'
                        htmlFor='employeeIDNumber'
                      >
                        Employee ID Number<span style={textRequired}>*</span>
                      </label>
                      <Input
                        disabled={this.state.disabled}
                        id='employeeIDNumber'
                        placeholder='Enter your employee ID number'
                        onChange={(e) =>
                          this.setState({ employeeIDNumber: e.target.value })
                        }
                        onFocus={(e) =>
                          this.handleFocusOrChange(e, 'employeeID')
                        }
                        className='loginInput'
                        required
                        style={
                          this.state.employeeIDValidation ? inputError : {}
                        }
                        value={this.state.employeeIDNumber}
                        maxLength={100}
                        showCount
                      />
                    </span>
                    {this.state.employeeIDValidation && (
                      <p style={textValidation}>
                        {this.state.employeeIDValidation}
                      </p>
                    )}
                  </div>
                  <div style={{ width: '80%', marginBottom: 10 }}>
                    <span>
                      <label className='loginInput-label' htmlFor='dept'>
                        Home Department<span style={textRequired}>*</span>
                      </label>
                      <Select
                        showSearch
                        optionFilterProp='children'
                        placeholder='Select your home dept.'
                        value={this.state.dept}
                        onFocus={(e) =>
                          this.handleFocusOrChange(e, 'homeDepartment')
                        }
                        onChange={(e) => this.setState({ dept: e })}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        id='dept'
                        className='loginInput'
                        style={this.state.deptValidation ? inputError : {}}
                        required
                      >
                        {/* <Option value="N/A">Prefer not to say</Option> */}
                        {dept.sort().map((item, index) => (
                          <Option key={`${item}-${index}`} value={item}>
                            {item}
                          </Option>
                        ))}
                      </Select>
                      {this.state.deptValidation && (
                        <p style={textValidation}>
                          {this.state.deptValidation}
                        </p>
                      )}
                    </span>
                  </div>
                  <div style={{ width: '80%', marginBottom: 10 }}>
                    <span>
                      <label
                        className='loginInput-label'
                        htmlFor='assignedDept'
                      >
                        Assigned Department
                        <span style={textRequired}>*</span>
                      </label>
                      <Select
                        showSearch
                        optionFilterProp='children'
                        placeholder='Select your assigned dept.'
                        value={this.state.assignedDept}
                        onChange={this.changeDepartment}
                        onFocus={(e) =>
                          this.handleFocusOrChange(e, 'assignedDepartment')
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        id='assignedDept'
                        className='loginInput'
                        style={
                          this.state.assignedDeptValidation ? inputError : {}
                        }
                        required
                      >
                        {this.state.DepartmentX.sort((a, b) => {
                          let fa = a.department.toLowerCase(),
                            fb = b.department.toLowerCase()
                          if (fa < fb) {
                            return -1
                          }
                          if (fa > fb) {
                            return 1
                          }
                          return 0
                        }).map((e, key) => {
                          return (
                            <Option
                              key={`${e.department}-${key}`}
                              value={e.department}
                            >
                              {e.department}
                            </Option>
                          )
                        })}
                      </Select>
                      {this.state.assignedDeptValidation && (
                        <p style={textValidation}>
                          {this.state.assignedDeptValidation}
                        </p>
                      )}
                    </span>
                  </div>
                  <div style={{ width: '80%', marginBottom: 10 }}>
                    <span>
                      <label className='loginInput-label' htmlFor='div'>
                        Division
                        <span style={textRequired}>*</span>
                      </label>

                      <Select
                        showSearch
                        value={this.state.divisionN}
                        onChange={this.changeDivision}
                        onFocus={(e) => this.handleFocusOrChange(e, 'division')}
                        className='loginInput'
                        style={this.state.divisionValidation ? inputError : {}}
                        placeholder='Select Division'
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        required
                      >
                        {this.state.DivisionX.map((e, key) => {
                          return (
                            <Option
                              key={`${e.divisionName}-${key}`}
                              value={e.divisionName}
                            >
                              {e.divisionName}
                            </Option>
                          )
                        })}
                      </Select>
                      {this.state.divisionValidation && (
                        <p style={textValidation}>
                          {this.state.divisionValidation}
                        </p>
                      )}
                    </span>
                  </div>
                  <div style={{ width: '80%' }}>
                    <span>
                      <label className='loginInput-label' htmlFor='div'>
                        Section/Unit
                        <span style={textRequired}>*</span>
                      </label>
                      <Select
                        showSearch
                        value={this.state.SectionUnitX}
                        onChange={(e) => this.setState({ SectionUnitX: e })}
                        onFocus={(e) => this.handleFocusOrChange(e, 'section')}
                        className='loginInput'
                        style={
                          this.state.sectionUnitValidation ? inputError : {}
                        }
                        placeholder='Select Section/Unit'
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        required
                        disabled={this.state.disabledSection}
                      >
                        {this.state.SectionUnit.map((e, key) => {
                          return (
                            <Option key={`${e}-${key}`} value={e}>
                              {e}
                            </Option>
                          )
                        })}
                      </Select>
                      <p style={textValidation}>
                        {' '}
                        {this.state.sectionUnitValidation && (
                          <p style={textValidation}>
                            {this.state.sectionUnitValidation}
                          </p>
                        )}
                      </p>
                    </span>
                  </div>
                  <div style={{ width: '80%', marginBottom: 10 }}>
                    <span>
                      <label
                        className='loginInput-label'
                        htmlFor='appointmentStatus'
                      >
                        Appointment Status
                        <span style={textRequired}>*</span>
                      </label>
                      <Select
                        disabled={this.state.disabled}
                        id='appointmentStatus'
                        placeholder='Select your Appointment Status'
                        value={this.state.appointmentStatus}
                        onChange={(value) =>
                          this.setState({ appointmentStatus: value })
                        }
                        onFocus={(e) =>
                          this.handleFocusOrChange(e, 'appointmentStatus')
                        }
                        className='loginInput'
                        style={
                          this.state.appointmentStatusValidation
                            ? inputError
                            : {}
                        }
                      >
                        <option value='' disabled>
                          Select your appointment status
                        </option>
                        <option value='PERMANENT'>Permanent</option>
                        <option value='TEMPORARY'>Temporary</option>
                        <option value='SUBSTITUTE'>Substitute</option>
                        <option value='COTERMINOUS'>Coterminous</option>
                        <option value='FIXED_TERM'>Fixed Term</option>
                        <option value='CONTRACTUAL'>Contractual</option>
                        <option value='CASUAL'>Casual</option>
                      </Select>
                      {this.state.appointmentStatusValidation && (
                        <p style={textValidation}>
                          {this.state.appointmentStatusValidation}
                        </p>
                      )}
                    </span>
                  </div>
                  <div className='reg2-row'>
                    {' '}
                    <span>
                      <label className='loginInput-label' htmlFor='role'>
                        Role<span style={textRequired}>*</span>
                      </label>
                      <Select
                        id='role'
                        placeholder='Select role'
                        onChange={(e) => this.setState({ role: e })}
                        defaultValue='employee'
                        className='loginInput'
                        required
                        disabled
                      >
                        {/* <Option value="">Prefer not to say</Option> */}
                        <Option value='employee'>Employee</Option>
                        <Option value='supervisor'>Supervisor</Option>
                      </Select>
                    </span>
                    <span>
                      <label className='loginInput-label' htmlFor='pos'>
                        Position<span style={textRequired}>*</span>
                      </label>
                      <Input
                        disabled={this.state.disabled}
                        // prefix={}
                        maxLength={50}
                        // onChange={(e) =>
                        //   this.setState({ position: e.target.value })
                        // }
                        onChange={(e) => {
                          const value = e.target.value
                          this.setState({ position: value })
                          const errors = []
                          this.validateField(
                            value,
                            'position',
                            'Please enter a position.',
                            errors
                          )
                        }}
                        onFocus={(e) => this.handleFocusOrChange(e, 'position')}
                        id='pos'
                        placeholder='Enter your position'
                        className='loginInput'
                        style={this.state.positionValidation ? inputError : {}}
                        value={this.state.position}
                      />
                      {this.state.positionValidation && (
                        <p style={textValidation}>
                          {this.state.positionValidation}
                        </p>
                      )}
                    </span>
                  </div>
                  <div style={{ width: '80%', marginBottom: 10 }}>
                    {' '}
                    <span>
                      <label className='loginInput-label' htmlFor='uname'>
                        Username (Email Address)
                        <span style={textRequired}>*</span>
                      </label>
                      <Input
                        disabled={this.state.disabled}
                        // prefix={}
                        onChange={(e) =>
                          this.setState({
                            username: e.target.value.toLowerCase(),
                          })
                        }
                        onFocus={(e) => this.handleFocusOrChange(e, 'username')}
                        id='uname'
                        type='email'
                        placeholder='Enter your username'
                        className='loginInput'
                        style={this.state.usernameValidation ? inputError : {}}
                        value={this.state.username}
                      />
                      {this.state.usernameValidation && (
                        <p style={textValidation}>
                          {this.state.usernameValidation}
                        </p>
                      )}
                    </span>
                  </div>

                  <div className='reg2-row'>
                    <span>
                      <label className='loginInput-label' htmlFor='pwd'>
                        Password<span style={textRequired}>*</span>
                      </label>
                      <Input.Password
                        disabled={this.state.disabled}
                        onChange={(e) =>
                          this.setState({ password: e.target.value })
                        }
                        onFocus={(e) => this.handleFocusOrChange(e, 'password')}
                        minLength='6'
                        id='pwd'
                        type='password'
                        required
                        placeholder='Enter your password'
                        className='loginInput'
                        style={this.state.passwordValidation ? inputError : {}}
                      />
                      {this.state.passwordValidation && (
                        <p style={textValidation}>
                          {this.state.passwordValidation}
                        </p>
                      )}
                    </span>
                    <span>
                      <label className='loginInput-label' htmlFor='cpwd'>
                        Confirm Password<span style={textRequired}>*</span>
                      </label>
                      <Input.Password
                        disabled={this.state.disabled}
                        onChange={(e) =>
                          this.setState({ cpassword: e.target.value })
                        }
                        onFocus={(e) =>
                          this.handleFocusOrChange(e, 'confirmPassword')
                        }
                        minLength='6'
                        id='cpwd'
                        type='password'
                        required
                        placeholder='Confirm your password'
                        style={
                          this.state.confirmPasswordValidation ? inputError : {}
                        }
                        className='loginInput'
                      />
                      {this.state.confirmPasswordValidation && (
                        <p style={textValidation}>
                          {this.state.confirmPasswordValidation}
                        </p>
                      )}
                    </span>
                  </div>

                  <span>
                    <div>
                      <Checkbox required>I give my consent to the </Checkbox>
                      <a
                        href={`${window.location.origin}/dataPrivacy`}
                        target='_blank'
                        rel='noopener noreferrer'
                        className='dataPrivacy'
                      >
                        Data Privacy Agreement
                      </a>
                    </div>

                    <button
                      disabled={this.state.disabled}
                      className='btns btnLogin'
                    >
                      Create Account
                    </button>
                  </span>

                  {/* <span className="forgotPwd">
                      <span>Forgot password?</span>
                    </span> */}
                  <div className='regFoot'>
                    <span>Already have an account?</span>{' '}
                    <a
                      href={`${window.location.origin}/login`}
                      rel='noopener noreferrer'
                      className='regFoot'
                    >
                      Login here
                    </a>
                  </div>

                  <div className={'regFoot d-flex justify-content-center'}>
                    <span>
                      Please do not create a second account if you have
                      forgotten your password, or if you did not receive a
                      confirmation email; doing so will result in the account
                      being deleted and any progress will be lost. Please use
                      the forgot password link here or send a message to QC L&D
                      Team at <b>LD.HRMD@quezoncity.gov.ph</b> or you may
                      contact us at <b>8988 4242 loc. 8504</b>.
                    </span>{' '}
                  </div>
                  {/* <Register
                      register={(e) => this.props.register(e)}
                      regState={this.props.regState}
                      confirm={(e) => this.props.confirm(e)}
                      resend={(e) => this.props.resend(e)}
                    /> */}
                </form>
              </div>
            </div>
            <Modal
              title={
                <Alert
                  message='Kindly check your details before creating an account'
                  type='info'
                  showIcon
                  className={'mx-3'}
                  style={{ textAlign: 'center' }}
                />
              }
              keyboard={false}
              open={this.state.setOpenModal}
              maskClosable={true}
              closable={false}
              destroyOnClose={true}
              onCancel={() => this.setState({ setOpenModal: false })}
              footer={[
                <div
                  key='div'
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <span>
                    <Button
                      danger
                      onClick={() => this.setState({ setOpenModal: false })}
                    >
                      Cancel
                    </Button>
                  </span>
                  <span>
                    <Button
                      disabled={
                        this.state.showButton === false || !this.state.captcha
                      }
                      onClick={this.handleCaptcha}
                    >
                      OK
                    </Button>
                  </span>
                </div>,
              ]}
            >
              <Card>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta
                      title={`${this.state.fname} ${
                        this.state.mname ? this.state.mname : ''
                      } ${this.state.lname} ${
                        this.state.suffix && this.state.suffix !== 'N/A'
                          ? this.state.suffix
                          : ''
                      }`}
                      description='FULL NAME'
                    />
                  </List.Item>
                </List>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta
                      title={this.state.contact}
                      description='Contact Number'
                    />
                  </List.Item>
                </List>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta title={genderTitle} description='Gender' />
                  </List.Item>
                </List>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta
                      title={this.state.suffix}
                      description='Suffix'
                    />
                  </List.Item>
                </List>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta
                      title={this.state.employeeIDNumber}
                      description='Employee ID Number'
                    />
                  </List.Item>
                </List>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta
                      title={this.state.dept}
                      description='Home Department'
                    />
                  </List.Item>
                </List>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta
                      title={this.state.assignedDept}
                      description='Assigned Department'
                    />
                  </List.Item>
                </List>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta
                      title={this.state.divisionN}
                      description='Division'
                    />
                  </List.Item>
                </List>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta
                      title={this.state.SectionUnitX}
                      description='Section/Unit'
                    />
                  </List.Item>
                </List>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta
                      title={this.state.appointmentStatus}
                      description='Appointment Status'
                    />
                  </List.Item>
                </List>
                <List itemLayout='horizontal'>
                  <List.Item>
                    <List.Item.Meta
                      title={this.state.position}
                      description='Position'
                    />
                  </List.Item>
                </List>
                <Row className='contact-us-row'>
                  <Col className='contact-us-col' span={24}>
                    {this.state.setOpenModal === true ? (
                      <RecaptchaRegister
                        showButton={(e) => this.setState({ showButton: e })}
                        setCaptcha={(e) => this.setState({ captcha: e })}
                      />
                    ) : null}
                  </Col>
                </Row>
              </Card>
            </Modal>
          </>
        ) : null}
      </>
    )
  }
}

const textValidation = {
  textAlign: 'start',
  lineHeight: 1.7,
  color: 'red',
  fontSize: '13px',
}

const textRequired = {
  textAlign: 'start',
  color: 'red',
  lineHeight: 1.7,
  fontSize: '16px',
}

const inputError = {
  border: '1px solid red',
}
