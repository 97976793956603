/**
 * Title: selectAssessmentQuestion.js
 * Description: This is a file that contains the components for the select assessment question
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/selectAssessmentQuestion.js
 **/

/*
 *Changes made:
 *2024.08.09 | Raymart Mojado | show image on the multipleChoice if have image
 */

import { API, graphqlOperation, Storage } from 'aws-amplify'
import React, { useEffect, useRef, useState } from 'react'
import { listAssessmentQuestions } from '../../api/queries'
import { deleteAssessmentQuestions } from '../../api/mutations'

import { notification, Button, Modal, Row, Col, Input, Popconfirm } from 'antd'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  CaretLeftFilled,
  CaretRightFilled,
} from '@ant-design/icons'

const SelectAssessmentQuestion = ({
  setGetAssessmentID,
  setShowPostAssessment,
}) => {
  /* Declaring variables */
  const [openModal, setOpenModal] = useState(false)
  const [selectedAssessQuestion, setSelectedAssessQuestion] = useState('')
  const [AssessQuestionsList, setAssessQuestionsList] = useState([])
  const [imageUrls, setImageUrls] = useState({})
  const [qIndex, setqIndex] = useState(0)
  // const selectRef = useRef(null);

  /**
   * Fetches the list of assessment questions and updates the state.
   *
   * This function uses a do...while loop to handle pagination and fetches all the assessment questions
   * until there are no more tokens. It updates the state with the fetched questions and sets the initial
   * selected question.
   *
   * @async
   * @function fetchAssessQuestionList
   * @returns {Promise<void>} A promise that resolves when all assessment questions have been fetched and the state has been updated.
   */
  const fetchAssessQuestionList = async () => {
    let questionList = []
    let nextToken = null

    do {
      const AssessQuestionList = await API.graphql({
        query: listAssessmentQuestions,
        variables: { nextToken },
      })

      const fetchedQuestions =
        AssessQuestionList.data.listAssessmentQuestions.items
      questionList = [...questionList, ...fetchedQuestions]
      nextToken = AssessQuestionList.data.listAssessmentQuestions.nextToken
    } while (nextToken)

    const initialSelectedQuestion = questionList[qIndex]
    setAssessQuestionsList(questionList)
    setSelectedAssessQuestion(initialSelectedQuestion)
  }

  /* Change the question preview to next question */
  const handleNextQuestion = () => {
    const nextIndex = qIndex === AssessQuestionsList.length - 1 ? 0 : qIndex + 1
    setqIndex(nextIndex)
  }

  /* Change the question preview to previous question */
  const handlePrevQuestion = () => {
    const prevIndex = qIndex === 0 ? AssessQuestionsList.length - 1 : qIndex - 1
    setqIndex(prevIndex)
  }

  // //Handle Selected Question
  // const handleSelectedQuestion = (e) => {
  //   const questionId = e.target.value;
  //   if (questionId) {
  //     const selectedQuestion = AssessQuestionsList.filter(question => {
  //       return question.id == questionId;
  //     })
  //     console.log(JSON.parse(selectedQuestion[0].assessmentQuestionnaire))
  //     setSelectedAssessQuestion(JSON.parse(selectedQuestion[0].assessmentQuestionnaire));
  //   }

  //   if (!questionId) {
  //     setSelectedAssessQuestion("")
  //   }
  // }

  /* Saving to bind the selected assessment question to the training that will be created */
  const handleSave = () => {
    setGetAssessmentID(AssessQuestionsList[qIndex].id)

    if (typeof setShowPostAssessment === 'function') {
      setShowPostAssessment(true)
    }

    setTimeout(() => {
      setqIndex(0)
      setSelectedAssessQuestion(AssessQuestionsList[qIndex])
      setOpenModal(false)
    }, 1000)
  }

  /* Closing the select question modal */
  const handleCloseModal = () => {
    setOpenModal(false)
    setqIndex(0)
    setSelectedAssessQuestion(AssessQuestionsList[qIndex])
  }

  /* Deleting the assessment question from database */
  const handleConfirmDelete = async (e, questionId) => {
    e.preventDefault()
    try {
      const status = await API.graphql(
        graphqlOperation(deleteAssessmentQuestions, {
          input: {
            id: questionId,
          },
        })
      )
      if (status) {
        notification['success']({
          message: 'Success',
          description: 'Successfully deleted.',
        })
      }
      fetchAssessQuestionList()
      // selectRef.current.value = "";
    } catch (error) {
      console.log(error)
    }
  }

  /* Initializing the default value of selected question */
  useEffect(() => {
    if (AssessQuestionsList && AssessQuestionsList[qIndex]) {
      setSelectedAssessQuestion(AssessQuestionsList[qIndex])
    }
  }, [qIndex])

  /* Fetch assessment question list */
  useEffect(() => {
    fetchAssessQuestionList()
  }, [])

  useEffect(() => {
    if (selectedAssessQuestion) {
      const questions = JSON.parse(
        selectedAssessQuestion.assessmentQuestionnaire
      )

      /**
       * Fetches image URLs for questions that have an imageUploadQuestion property.
       *
       * This function iterates over the questions array and checks if the imageUploadQuestion
       * property is either a non-empty string or a non-empty array. If so, it fetches the image URL
       * using the fetchImageUrl function and stores it in the urls object. Finally, it updates the
       * state with the fetched URLs.
       *
       * @async
       * @function fetchImageUrls
       * @returns {Promise<void>} A promise that resolves when all image URLs have been fetched and the state has been updated.
       */
      const fetchImageUrls = async () => {
        const urls = {}
        for (const question of questions) {
          if (
            (Array.isArray(question.imageUploadQuestion) &&
              question.imageUploadQuestion.length > 0) ||
            (typeof question.imageUploadQuestion === 'string' &&
              question.imageUploadQuestion.trim() !== '')
          ) {
            const url = await fetchImageUrl(question.imageUploadQuestion)
            urls[question.imageUploadQuestion] = url
          }
        }
        setImageUrls(urls)
      }
      fetchImageUrls()
    }
  }, [selectedAssessQuestion])
  const fetchImageUrl = async (key) => {
    try {
      const url = await Storage.get(key)
      return url
    } catch (error) {
      console.log(error)
      return null
    }
  }
  /* Mapping selectedAssessQuestion for Questions Preview  */
  const showOutput =
    selectedAssessQuestion &&
    JSON.parse(selectedAssessQuestion.assessmentQuestionnaire).map(
      (elem, index) => {
        return (
          <React.Fragment key={index}>
            <div className='mt-3'>
              <p className={'fw-bold'}>
                {index + 1}. {elem.question}
              </p>
            </div>
            {elem.imageUploadQuestion &&
              imageUrls[elem.imageUploadQuestion] && (
                <div className='mt-3'>
                  <img
                    src={imageUrls[elem.imageUploadQuestion]}
                    alt={imageUrls[elem.imageUploadQuestion]}
                    style={{ maxWidth: '450px', maxHeight: '450px' }}
                  />
                </div>
              )}
            <div className='flex-column ms-3 mt-2 gap-1'>
              {elem.choices[0] &&
                elem.choices[1] &&
                elem.choices[2] &&
                elem.choices[3] && (
                  <>
                    <div className='flex align-items-center'>
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>A. </em>
                        </strong>
                        {elem.choices[0][`choices-1-${index + 1}`]}
                      </span>
                    </div>
                    <div>
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>B. </em>
                        </strong>
                        {elem.choices[1][`choices-2-${index + 1}`]}
                      </span>
                    </div>
                    <div>
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>C. </em>
                        </strong>
                        {elem.choices[2][`choices-3-${index + 1}`]}
                      </span>
                    </div>
                    <div>
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>D. </em>
                        </strong>
                        {elem.choices[3][`choices-4-${index + 1}`]}
                      </span>
                    </div>
                  </>
                )}
            </div>
            {/* <Row>
          <Row>
            <span className={"fw-bold"}>Multiple choice: Choose the correct asnwer</span>
          </Row>
        </Row> */}
          </React.Fragment>
        )
      }
    )

  return (
    <>
      <Button ghost type='primary' onClick={() => setOpenModal(true)}>
        Select Pre-asssessment & Post-assessment questionnaire
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Select Assessment Questionnaire'
        visible={openModal}
        footer={null}
        onCancel={handleCloseModal}
        width={800}
      >
        {/* <div className="p-5 ps-0 pe-0 d-flex flex-md-row flex-column justify-content-center align-items-md-center">
          <span className="fw-bold">Select Evaluation Question: </span>
          <select ref={selectRef} onChange={(e) => handleSelectedQuestion(e)} className="p-1 ms-md-2">
            <option value="">Select Question</option>
            {AssessQuestionsList.map((question) => {
              return (
                <option key={question.id} value={`${question.id}`}>
                  {question.id}
                </option>
              )
            })}
          </select>
        </div> */}
        <div className='me-md-0'>
          <div className='carousel-inner'>
            <div className='col-10 offset-2'>
              {showOutput && (
                <>
                  <div className='d-flex justify-content-between col-md-10'>
                    <h3 className='mb-5'>Assessment Question Preview</h3>
                    <Popconfirm
                      placement='top'
                      title={'Are you sure to delete this Question?'}
                      onConfirm={(e) =>
                        handleConfirmDelete(e, selectedAssessQuestion.id)
                      }
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button className='bg-danger text-white d-flex align-items-center me-5 me-md-0'>
                        <DeleteFilled />
                        <span className='d-md-block d-none'>
                          Delete This Question
                        </span>
                      </Button>
                    </Popconfirm>
                  </div>
                  <div className='col-10'>{showOutput}</div>
                </>
              )}
            </div>
            <button
              onClick={handlePrevQuestion}
              className='carousel-control-prev text-black display-1 d-flex justify-content-start'
            >
              <CaretLeftFilled />
            </button>
            <button
              onClick={handleNextQuestion}
              className='carousel-control-next text-black display-1 d-flex justify-content-end'
            >
              <CaretRightFilled />
            </button>
          </div>
          <div className='d-flex justify-content-end gap-3 mt-5'>
            <Button onClick={handleCloseModal} className='bg-danger text-white'>
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              className='align-self-end bg-primary text-white ps-4 pe-4'
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SelectAssessmentQuestion
