import { Row, Col } from 'antd'

export default function Error404() {
  return (
    <Row style={{ height: '100vh' }} justify='center' align='middle'>
      <Col>
        <Row justify='center'>
          <h1>404</h1>
        </Row>
        <Row justify='center'>
          <p>Page not found</p>
        </Row>
      </Col>
    </Row>
  )
}
