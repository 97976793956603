/**
 * Title: App.js
 * Description: This is a file that contains the main component of the application
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/App.js
 *
 * Changes made:
 * - 2024.01.10  | Harry Lagunsad | Fixed sonarqube high issues like refactoring functions
 * - 2024.10.10  | Harry Lagunsad | Add function that will read the URL parameters and set the content to be displayed
 * - 2024.07.11  | Harry Lagunsad | Added appointmentStatus data in registration process
 **/
import { useEffect, useState } from 'react'

import { notification } from 'antd'
import 'antd/dist/antd.css'
import { API, Amplify, Auth, graphqlOperation } from 'aws-amplify'
import 'bootstrap/dist/css/bootstrap.min.css'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import './App.css'
import { AuthProvider } from './ContextProvider/ContextProvider'
import { createUser } from './api/mutations'
import { UserbyUsername } from './api/queries'
import awsconfig from './aws-exports'
import Error404 from './components/Error404'
import Home from './components/Home/Homepage/home'
import ChangePassword from './components/Login/changePassword'
import Login from './components/Login/login'
import PersonalDataSheet from './components/PDS/PersonalDataSheet'
import DataPrivacy from './components/Register/dataPrivacy'
import Register from './components/Register/register'
import UserExistModal from './components/Register/userExistModal'
import Verify from './components/Verify/verify'

Amplify.configure(awsconfig)

function App() {
  const [regState, setRegState] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [content, setContent] = useState('home')

  /**
   * Handles user sign-up process
   * @async
   * @param {Object} e - The sign-up form data
   * @param {string} e.username - User's email address (used as username)
   * @param {string} e.password - User's password
   * @param {string} e.date2 - User's locale
   * @param {string} e.fname - User's first name
   * @param {string} e.mname - User's middle name
   * @param {string} e.lname - User's last name
   * @param {string} e.suffix - User's name suffix
   * @param {string} e.contact - User's contact number
   * @param {string} e.divisionN - User's division
   * @param {string} e.assignedDept - User's assigned department
   * @param {string} e.dept - User's department
   * @param {string} e.role - User's role
   * @param {string} e.gender - User's gender
   * @param {string} e.position - User's position
   * @param {string} e.SectionUnitX - User's section/unit
   * @param {string} e.captcha - Captcha response
   * @param {string} e.employeeIDNumber - User's employee ID number
   * @param {string} e.appointmentStatus - User's appointment status
   * @throws {Error} If sign-up fails
   */
  async function signUp(e) {
    const username = e.username.trim().replace(/\s+/g, ' ')
    const password = e.password
    const locale = e.date2
    const name = e.fname.trim().replace(/\s+/g, ' ')
    const mname = e.mname ? e.mname.trim().replace(/\s+/g, ' ') : e.mname
    const lname = e.lname.trim().replace(/\s+/g, ' ')
    const suffix = e.suffix
    const contact = e.contact
    const div = e.divisionN
    const assignedDept = e.assignedDept
    const dept = e.dept
    const role = e.role
    const gender = e.gender
    const appointmentStatus = e.appointmentStatus
    const position = e.position.trim().replace(/\s+/g, ' ')
    const sectionUnit = e.SectionUnitX
    const captcha = e.captcha
    const employeeIDNumber = e.employeeIDNumber.trim().replace(/\s+/g, ' ')

    try {
      if (e.captcha) {
        const { user } = await Auth.signUp({
          username,
          password,
          attributes: {
            given_name: name,
            middle_name: mname,
            family_name: lname,
            locale,
            email: username,
            phone_number: `+63${contact}`,
            gender,
            'custom:role': role,
            'custom:department': dept,
            'custom:assignedDept': assignedDept,
            'custom:division': div,
            'custom:position': position,
            'custom:sectionUnit': sectionUnit,
            'custom:suffix': suffix,
            'custom:captcha': captcha,
            'custom:isFromAdmin': '0',
            'custom:employeeIDNumber': employeeIDNumber,
            'custom:appointmentStatus': appointmentStatus,
          },
        })

        if (user) {
          localStorage.setItem('email', username)
          notification['success']({
            message: 'Success',
            description: 'Verification code has been sent to your email.',
            // duration: 5,
          })

          setTimeout(() => {
            window.location.pathname = '/verify'
          }, 3000)
        }
      }
    } catch (error) {
      setRegState(error.message)

      console.log(error)

      if (error.message === 'User already exists') {
        setIsModalOpen(true)
      }

      notification['error']({
        message: 'Error',
        description: error.message,
      })
    }
  }

  const closeModal = () => {
    try {
      setIsModalOpen(false)
    } catch (error) {
      console.log(error)
    }
  }

  const createUserFunc = async (d) => {
    try {
      const createUserData = await API.graphql(
        graphqlOperation(createUser, {
          input: {
            name: d.given_name,
            mname: d.middle_name,
            lname: d.family_name,
            suffix: d['custom:suffix'],
            contact: d.phone_number,
            role: d['custom:role'],
            department: d['custom:department'],
            assignedDept: d['custom:assignedDept'],
            division: d['custom:division'],
            sectionUnit: d['custom:sectionUnit'],
            position: d['custom:position'],
            dataPrivacy: true,
            date: d.locale,
            gender: d.gender,
            appointmentStatus: d['custom:appointmentStatus'],
            status: 'Approved',
            username: d.email,
            form: '0',
            form2: '0',
            employeeIDNumber: d['custom:employeeIDNumber'],
          },
        })
      )

      notification['success']({
        message: 'Success',
        description: `Credentials accepted. Welcome ${createUserData.data.createUser.name}`,
        duration: 5,
      })

      setTimeout(() => {
        window.location.pathname = '/'
      }, 4000)
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description:
          'An error occurred while creating the user. Please contact system admin for assistance.',
      })
    }
  }

  /**
   * Handles user sign-in process
   * @async
   * @param {Object} e - The sign-in form data
   * @param {string} e.username - User's email address (used as username)
   * @param {string} e.password - User's password
   * @throws {Error} If sign-in fails
   */
  async function signIn(e) {
    const { username, password } = e
    try {
      const user = await Auth.signIn(username, password)

      if (user) {
        const issued_time = new Date().toISOString() // get the current time in ISO format
        localStorage.setItem('refresh_token_issued_time', issued_time)

        const email = user.attributes.email
        const d = user.attributes

        const foundUserData = await API.graphql(
          graphqlOperation(UserbyUsername, { username: email })
        )

        const emailFind = foundUserData.data.UserbyUsername.items

        if (emailFind.length) {
          if (emailFind[0].status === 'Pending') {
            notification['success']({
              message: 'Success',
              description:
                'Credentials accepted. Please wait for the admin approval',
            })

            setTimeout(() => {
              window.location.pathname = '/'
            }, 3000)
          } else if (emailFind[0].status === 'Disabled') {
            notification['error']({
              message: 'Error',
              description: 'Your account has been disabled.',
            })

            signOut()
          } else {
            notification['success']({
              message: 'Success',
              description: `Credentials accepted. Welcome ${emailFind[0].name}`,
            })

            setTimeout(() => {
              window.location.pathname = '/'
            }, 3000)
          }
        } else {
          createUserFunc(d)
        }
      }
    } catch (error) {
      notification['error']({
        message: 'Error',
        description: error.message,
      })
      const array = new Uint32Array(1)
      window.crypto.getRandomValues(array)
      setRegState(error.message + array[0])
    }
  }

  /**
   * Confirms user sign-up with verification code
   * @async
   * @param {Object} e - The confirmation form data
   * @param {string} e.email - User's email address
   * @param {string} e.code - Verification code
   * @throws {Error} If confirmation fails
   */
  async function confirmNow(e) {
    const username = e.email
    const code = e.code

    try {
      await Auth.confirmSignUp(username, code)
      notification['success']({
        message: 'Success',
        description:
          'We have been verified your account. Please login to continue.',
      })
      setTimeout(() => {
        window.location.pathname = '/login'
        setRegState('successConfirm')
      }, 3000)
    } catch (error) {
      setRegState(error.message)
      notification['error']({
        message: 'Error',
        description: error.message,
      })
    }
  }

  /**
   * Signs out the current user
   * @async
   * @throws {Error} If sign-out fails
   */
  async function signOut() {
    try {
      if (Auth?._storage?.length) {
        await Auth.signOut({ global: true })
        localStorage.clear()
        window.location.reload()
      } else {
        localStorage.clear()
        window.location.reload()
      }
    } catch (error) {
      console.log(error)

      localStorage.clear()
      window.location.reload()
    }
  }

  /**
   * Function that will read the URL parameters and set the content to be displayed
   * @function getUrlParams
   * @returns {void}
   * @throws {error} any error that will occur
   */
  function getUrlParams() {
    try {
      const params = new URLSearchParams(window.location.search)
      const foundContent = params.get('show')

      const validContents = ['SubmittedPDSForms', 'PDSForm']

      if (foundContent) {
        if (validContents.includes(foundContent)) {
          localStorage.setItem('content', foundContent)
        }
        window.location.href = '/'
      }
    } catch (err) {
      console.log(err)
    }
  }

  const checkRefreshTokenExpiration = () => {
    try {
      const issuedTime = localStorage.getItem('refresh_token_issued_time')
      if (!issuedTime) return

      getUrlParams()
      checkAuthStatus()

      const now = new Date()
      const timeElapsed = (now - new Date(issuedTime)) / 1000
      const expireHour = 24 * 60 * 60 // 24 hours in seconds
      const remainingTime = expireHour - timeElapsed

      const notificationTimes = [
        { time: 10 * 60, type: 'info', message: 'Info' },
        { time: 5 * 60, type: 'info', message: 'Info' },
        { time: 3 * 60, type: 'error', message: 'Danger' },
      ]

      for (const { time, type, message } of notificationTimes) {
        if (remainingTime <= time) {
          notification[type]({
            message,
            duration: null,
            description: `Your session will expire in ${Math.floor(
              remainingTime / 60
            )} minutes. ${
              type === 'error'
                ? 'The page will reload immediately after that time. '
                : ''
            }Please finish your work or save it.`,
          })

          if (time <= 5 * 60) {
            let count = 0
            const intervalId = setInterval(() => {
              checkAuthStatus()
              if (++count === 10) clearInterval(intervalId)
            }, 30000)
          }

          break
        }
      }

      if (remainingTime < 1) checkAuthStatus()
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Checks the current authentication status of the user
   *
   * This function:
   * 1. Attempts to retrieve the current authenticated session
   * 2. Handles various authentication error scenarios:
   *    - No current user
   *    - NotAuthorizedException (expired session)
   *    - Refresh token related errors
   * 3. Initiates logout process if the session is invalid or expired:
   *    - Displays an error notification to the user
   *    - Clears local storage
   *    - Reloads the page after a short delay
   *
   * @async
   * @throws {Error} If there's an authentication error or issue with the current session
   */
  const checkAuthStatus = async () => {
    try {
      // this will check current session, throws an error if no valid session
      // this will update the access token previously saved after login the first time in the local storage
      // const user = await Auth.currentAuthenticatedUser();
      await Auth.currentSession()

      // setIsAuthenticated(true)
    } catch (err) {
      console.log(err)
      // setIsAuthenticated(false)

      if (err == 'No current user') {
        // notify that user will be logged out
        notification['error']({
          message: 'Error',
          description:
            'Your session has expired. You will be logged out shortly.',
        })

        localStorage.clear()

        setTimeout(() => {
          window.location.reload()
        }, 4000)
      }

      if (err.code) {
        if (err.code === 'NotAuthorizedException') {
          console.log('User session has expired. Redirecting to sign-in page.')

          // notify that user will be logged out
          notification['error']({
            message: 'Error',
            description:
              'Your session has expired. You will be logged out shortly.',
          })

          localStorage.clear()

          setTimeout(() => {
            window.location.reload()
          }, 4000)
        }
      } else if (err.message) {
        if (err.message.toLowerCase().includes('refresh token')) {
          console.log('User session has expired. Redirecting to sign-in page.')

          // notify that user will be logged out
          notification['error']({
            message: 'Error',
            description:
              'Your session has expired. You will be logged out shortly.',
          })

          localStorage.clear()

          setTimeout(() => {
            window.location.reload()
          }, 4000)
        }
      }
    }
  }

  useEffect(() => {
    checkRefreshTokenExpiration()

    const issued_time = localStorage.getItem('refresh_token_issued_time')

    if (issued_time) {
      const now = new Date()
      const time_elapsed = now.getTime() - new Date(issued_time).getTime()
      const toCheck = 300000 - (time_elapsed % 300000)
      const timeoutId = setTimeout(() => {
        checkRefreshTokenExpiration()
        const interval = setInterval(checkRefreshTokenExpiration, 300000)
        return () => clearInterval(interval)
      }, toCheck)

      return () => clearTimeout(timeoutId)
    }

    const interval = setInterval(checkRefreshTokenExpiration, 300000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Router scrollRestoration='manual'>
        <Routes>
          <Route
            exact
            path='/'
            element={
              <AuthProvider>
                <Home logout={signOut} />
              </AuthProvider>
            }
          />
          <Route
            path='/login'
            element={<Login signIn={signIn} regState={regState} />}
          />
          <Route
            path='/register'
            element={<Register signUp={signUp} regState={regState} />}
          />
          <Route
            path='/changePassword'
            element={<ChangePassword signUp={signUp} regState={regState} />}
          />
          <Route
            path='/verify'
            element={<Verify verify={confirmNow} regState={regState} />}
          />
          <Route path='/dataPrivacy' element={<DataPrivacy />} />
          <Route path='*' element={<Error404 />} />
        </Routes>
      </Router>
      <UserExistModal openModal={isModalOpen} closeModal={closeModal} />
    </>
  )
}

export default App
