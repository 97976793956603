/**
 * Title: activityQuestionModalFiveNov20.js
 * Description: This is a file that contains activity question modal five for nov 20 training
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/activityQuestionModalFiveNov20.js
 **/

import { useState, useEffect, useRef } from 'react'

import { Modal, Row, Button, Col, notification, Input } from 'antd'

import { ActivityFiveAnswerByUserID } from '../../api/queries'
import {
  createActivityFiveAnswer,
  updateActivityFiveAnswer,
} from '../../api/mutations'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

import moment from 'moment'

export default function ActivityQuestionModalFiveNov20({
  setProgressActivity,
  setIsModalActivityOpen,
  userData,
}) {
  const user = userData

  const quillRef = useRef(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activityAnswer, setActivityAnswer] = useState(null)
  const [activityAnswerTwo, setActivityAnswerTwo] = useState(null)
  const [activityFiveID, setActivityFiveID] = useState(null)
  const [isShowActivity, setIsShowActivity] = useState(false)
  const [countCheck, setCountCheck] = useState(0)

  const [caseScenario, setCaseScenario] = useState(null)
  const [uploadedFile, setUploadedFile] = useState(null)

  const submitAnswerItem = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })

      const params = {
        Bucket: 's3etnahris133956-dev',
        Key: `nov20training/${uploadedFile.name}`,
        Body: uploadedFile,
        ACL: 'public-read',
      }

      const command = new PutObjectCommand(params)
      const result = await s3.send(command)
      const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

      if (result) {
        const submitTimeOnly = await API.graphql(
          graphqlOperation(updateActivityFiveAnswer, {
            input: {
              id: activityFiveID,
              answer: JSON.stringify({
                caseScenario: caseScenario,
                location: url,
              }),
              timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
              isSubmittedByUser: true,
            },
          })
        )

        notification['success']({
          message: 'Success',
          description: 'Your answer is saved as final answer.',
        })
        setIsModalActivityOpen(false)
        setProgressActivity('alldone')
        setTimeout(() => {
          setIsShowActivity(false)
          setIsModalVisible(false)
        }, 3000)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const updateAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityFiveAnswer, {
          input: {
            id: activityFiveID,
            answer: JSON.stringify({
              caseScenario: caseScenario,
              location: null,
            }),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const createAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(createActivityFiveAnswer, {
          input: {
            userID: user.id,
            trainingAnnouncementID: '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04',
            answer: JSON.stringify({
              caseScenario: caseScenario,
              location: null,
            }),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: false,
          },
        })
      )

      setActivityFiveID(submitTimeOnly.data.createActivityFiveAnswer.id)
      setIsShowActivity(true)
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityFiveAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityFiveAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityFiveAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setIsShowActivity(false)
        } else {
          setIsShowActivity(true)
          setActivityAnswer(JSON.parse(itemsFetched[0].answer).answerOne)
          setActivityAnswerTwo(JSON.parse(itemsFetched[0].answer).answerTwo)
          if (itemsFetched[0].id !== activityFiveID) {
            setActivityFiveID(itemsFetched[0].id)
          }
        }
      } else {
        createAnswerItem()
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (isModalVisible) {
      if (user) {
        getActivityFiveAnswerByUserID()
      }
    }
  }, [isModalVisible])

  useEffect(() => {
    if (countCheck > 0) {
      updateAnswerItem()
    }
  }, [countCheck])

  useEffect(() => {
    let timeoutId

    const startTimer = () => {
      timeoutId = setTimeout(() => {
        if (isShowActivity && activityFiveID) {
          setCountCheck((prevCount) => prevCount + 10000)
        }

        startTimer() // Recursively call startTimer after 10 seconds
      }, 10000)
    }

    startTimer() // Start the initial timer

    return () => {
      clearTimeout(timeoutId) // Cleanup the timer on component unmount
    }
  }, [isShowActivity, activityFiveID])

  const handleActivityAnswer = (content) => {
    setActivityAnswer(content)
  }

  const handleActivityAnswerTwo = (content) => {
    setActivityAnswerTwo(content)
  }

  const handleSubmitActivityAnswer = async () => {
    try {
      if (uploadedFile) {
        if (caseScenario) {
          submitAnswerItem()
        } else {
          notification['error']({
            message: 'Error',
            description: 'Must select a case scenario.',
          })
        }
      } else {
        notification['error']({
          message: 'Error',
          description: 'Must have a file to proceed.',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleRadio = (e) => {
    setCaseScenario(e.target.id)
  }

  return (
    <div>
      <Button
        className='bg-success text-white w-100'
        onClick={() => setIsModalVisible(true)}
      >
        Go to activity
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Activity'
        width={1000}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={isShowActivity ? false : true}
            // loading={loading}
            onClick={handleSubmitActivityAnswer}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify='center' className='font-Mont'>
          <Col span={24}>
            {/* <Row className='mb-3' justify='center'>
              <h3 className='text-center'>ACTIVITY 3 for MODULE 3</h3>
            </Row> */}

            {isShowActivity ? (
              <>
                <Row className='mb-3' justify='left'>
                  <h3>Instructions: </h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <p className='mb-2 activity-font'>
                    The following instructions outline four distinct case
                    scenarios. Within the context of your department, office, or
                    unit (DOU), please select one of these scenarios that best
                    fit your preference and compose an OFFICE ORDER in a Word
                    document. Submit your output via the provided upload
                    feature.
                  </p>
                </Row>
                <Row className='mb-3' justify='left'>
                  <h3>Case Scenario #1: Public Health Measures</h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <p className='mb-2 activity-font'>
                    As the HR representative or administrative personnel for
                    your DOU, craft an office order outlining the preventive
                    measures, quarantine protocols, vaccination campaigns, and
                    guidelines for the Quezon City Government workforce.
                  </p>
                </Row>
                <Row className='mb-3' justify='left'>
                  <h3>Case Scenario #2: Personnel Reassignment</h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <p className='mb-2 activity-font'>
                    In this scenario, one of the employees in your department is
                    being reassigned to another department. Now, as the
                    personnel in charge, you are asked to generate an office
                    order pertaining to the fact that she or he will be
                    transferred or reassigned to another department in the
                    Quezon City government.
                  </p>
                </Row>
                <Row className='mb-3' justify='left'>
                  <h3>Case Scenario #3: Designated Officer-in-Charge</h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <p className='mb-2 activity-font'>
                    For official business purposes, the head of your department
                    has a scheduled trip to Seoul, South Korea, from November 20
                    to 24, 2023. Draft an office order for the personnel next in
                    rank as the designated officer-in-Charge for the
                    aforementioned date.
                  </p>
                </Row>
                <Row className='mb-3' justify='left'>
                  <h3>Case Scenario #4: Focal point person</h3>
                </Row>
                <Row className='mb-5' justify='left'>
                  <p className='mb-2 activity-font'>
                    In light of the service exigency, there is a requirement
                    within your department to designate a mental health focal
                    point person for a specific task that needs implementation.
                    This task includes, but is not limited to, monitoring
                    employees' workplace wellness. In this instance, you are
                    assigned to draft an office order.
                  </p>
                </Row>

                <Row>
                  <span style={{ color: 'red' }}>**</span>
                  <span>Select a case scenario before uploading:</span>
                </Row>

                <Row className='mb-5'>
                  <div className='d-flex' onChange={handleRadio}>
                    <input
                      id='caseScenario-1'
                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                      type='radio'
                      name='caseScenario'
                    />
                    <label className='me-4' htmlFor='caseScenario-1'>
                      <b>Case # 1</b>
                    </label>
                    <input
                      id='caseScenario-2'
                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                      type='radio'
                      name='caseScenario'
                    />
                    <label className='me-4' htmlFor='caseScenario-2'>
                      <b>Case # 2</b>
                    </label>
                    <input
                      id='caseScenario-3'
                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                      type='radio'
                      name='caseScenario'
                    />
                    <label className='me-4' htmlFor='caseScenario-3'>
                      <b>Case # 3</b>
                    </label>
                    <input
                      id='caseScenario-4'
                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                      type='radio'
                      name='caseScenario'
                    />
                    <label className='me-4' htmlFor='caseScenario-4'>
                      <b>Case # 4</b>
                    </label>
                  </div>
                </Row>

                <Row>
                  <input
                    id='activityFiveUpload'
                    name='activityFiveUpload'
                    type='file'
                    accept='.pdf,.doc,.docx'
                    /*  accept='application/pdf,application/doc,application/docx' */
                    onChange={(e) => {
                      if (e.target.files[0].size <= 5 * 1024 * 1024) {
                        setUploadedFile(e.target.files[0])
                        console.log(e.target.files[0])
                      } else {
                        notification['error']({
                          message: 'Error',
                          description:
                            'File size limit of 5MB is exceeded. Upload a different file.',
                        })
                      }
                    }}
                  />
                </Row>
              </>
            ) : (
              <>
                <Row className='mb-3' justify='left'>
                  <h3>Activity answer was already submitted as final.</h3>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
