/**
 * Title: activity1.js
 * Description: This is a file that contains activity 1
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/activity1.js
 **/

import { useState, useEffect, useRef } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  ActivityOneAnswerByUserID,
  listTrainingVideos,
} from '../../api/queries'
import {
  createActivityOneAnswer,
  deleteTrainingVideo,
  updateActivityOneAnswer,
} from '../../api/mutations'

import moment from 'moment'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  PlayCircleFilled,
} from '@ant-design/icons'
import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  Row,
  Col,
} from 'antd'

export default function Activity1({
  setProgressActivity,
  setIsModalActivityOpen,
  setIsUserWatchedCurrentVid,
  viewUserAnswer,
  userData,
}) {
  const user = userData

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activityOneAnswers, setActivityOneAnswers] = useState({})
  const [activityOneID, setActivityOneID] = useState(null)
  const [isShowActivity, setIsShowActivity] = useState(false)
  const [countCheck, setCountCheck] = useState(0)

  useEffect(() => {
    if (viewUserAnswer.length > 0) {
      setActivityOneAnswers(JSON.parse(viewUserAnswer[0].answer))
      setIsShowActivity(true)
    }
  }, [viewUserAnswer])

  const submitAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityOneAnswer, {
          input: {
            id: activityOneID,
            answer: JSON.stringify(activityOneAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: true,
          },
        })
      )

      notification['success']({
        message: 'Success',
        description: 'Your answer is saved as final answer.',
      })
      setIsModalActivityOpen(false)
      setProgressActivity('2')
      setTimeout(() => {
        setIsShowActivity(false)
        setIsModalVisible(false)
      }, 3000)
    } catch (err) {
      console.log(err)
    }
  }

  const updateAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityOneAnswer, {
          input: {
            id: activityOneID,
            answer: JSON.stringify(activityOneAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const createAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(createActivityOneAnswer, {
          input: {
            userID: user.id,
            trainingAnnouncementID: 'cdb10950-01b5-428a-9959-0fc47237b528',
            answer: JSON.stringify(activityOneAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: false,
          },
        })
      )

      setActivityOneID(submitTimeOnly.data.createActivityOneAnswer.id)
      setIsShowActivity(true)
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityOneAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityOneAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityOneAnswerByUserID.items

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setIsShowActivity(false)
        } else {
          setIsShowActivity(true)
          setActivityOneAnswers(JSON.parse(itemsFetched[0].answer))
          if (itemsFetched[0].id !== activityOneID) {
            setActivityOneID(itemsFetched[0].id)
          }
        }
      } else {
        createAnswerItem()
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!viewUserAnswer.length) {
      if (isModalVisible) {
        if (user) {
          getActivityOneAnswerByUserID()
        }
      }
    }
  }, [isModalVisible])

  useEffect(() => {
    if (!viewUserAnswer.length) {
      if (countCheck > 0) {
        updateAnswerItem()
      }
    }
  }, [countCheck])

  useEffect(() => {
    if (!viewUserAnswer.length) {
      let timeoutId

      const startTimer = () => {
        timeoutId = setTimeout(() => {
          if (isShowActivity && activityOneID) {
            setCountCheck((prevCount) => prevCount + 10000)
          }

          startTimer() // Recursively call startTimer after 10 seconds
        }, 10000)
      }

      startTimer() // Start the initial timer

      return () => {
        clearTimeout(timeoutId) // Cleanup the timer on component unmount
      }
    }
  }, [isShowActivity, activityOneID])

  useEffect(() => {
    if (!viewUserAnswer.length) {
      if (isModalVisible) {
        const initialAnswers = {}

        for (let i = 1; i <= 15; i++) {
          initialAnswers[`Question${i}`] = ''
        }

        setActivityOneAnswers(initialAnswers)
      }
    }
  }, [isModalVisible])

  function handleChoiceChange(questionName, choiceValue) {
    // Copy the existing answers object
    const newAnswers = { ...activityOneAnswers }
    // Update the answer for the specific question
    newAnswers[questionName] = choiceValue
    // Set the updated answers object in state

    setActivityOneAnswers(newAnswers)
  }

  const handleSubmitActivityAnswer = async () => {
    try {
      submitAnswerItem()
    } catch (err) {
      console.log(err)
    }
  }

  function MultipleChoiceQuestion({ question, choices, questionName }) {
    return (
      <div className='mt-3'>
        <p className='p-0 m-0'>{question}</p>
        {choices.map((choice, index) => (
          <div className='d-flex p-1' key={index}>
            <input
              /* disabled={viewUserAnswer.length > 0} */
              onChange={() => handleChoiceChange(questionName, choice.value)}
              checked={activityOneAnswers[questionName] === choice.value}
              value={choice.value}
              name={questionName}
              className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
              type='radio'
              id={`question${questionName}-choice-${choice.value}`}
            />
            <label
              className='m-0 ms-3'
              htmlFor={`question${questionName}-choice-${choice.value}`}
            >
              <strong>
                <em>{choice.label} </em>
              </strong>
              {choice.text}
            </label>
          </div>
        ))}
      </div>
    )
  }

  const questions = [
    {
      question:
        '1. The City Engineer ______________ all projects within the confines of the Quezon City Hall.',
      choices: [
        { name: '1', value: 'A', label: 'A.', text: 'oversee' },
        { name: '1', value: 'B', label: 'B.', text: 'oversees' },
        { name: '1', value: 'C', label: 'C.', text: 'overseen' },
        { name: '1', value: 'D', label: 'D.', text: 'do oversees' },
      ],
    },
    {
      question: '2. What did Mariah and Jim ___________?',
      choices: [
        { name: '2', value: 'A', label: 'A.', text: 'carried' },
        { name: '2', value: 'B', label: 'B.', text: 'carries' },
        { name: '2', value: 'C', label: 'C.', text: 'carry' },
        { name: '2', value: 'D', label: 'D.', text: 'did carry' },
      ],
    },
    {
      question:
        '3. Either Mikee or Rona ____________ the national anthem really well.',
      choices: [
        { name: '3', value: 'A', label: 'A.', text: 'sing' },
        { name: '3', value: 'B', label: 'B.', text: 'sings' },
        { name: '3', value: 'C', label: 'C.', text: 'singing' },
        { name: '3', value: 'D', label: 'D.', text: 'do sing' },
      ],
    },
    {
      question:
        '4. The Mayor, with two members of her staff, ____ attending the ribbon cutting ceremony for the new admin building.',
      choices: [
        { name: '4', value: 'A', label: 'A.', text: 'is' },
        { name: '4', value: 'B', label: 'B.', text: 'are' },
        { name: '4', value: 'C', label: 'C.', text: 'did' },
        { name: '4', value: 'D', label: 'D.', text: 'does' },
      ],
    },
    {
      question:
        '5. When the nights _____ colder, the rhythms get you falling behind.',
      choices: [
        { name: '5', value: 'A', label: 'A.', text: 'gets' },
        { name: '5', value: 'B', label: 'B.', text: 'get' },
        { name: '5', value: 'C', label: 'C.', text: 'getting' },
        { name: '5', value: 'D', label: 'D.', text: 'does get' },
      ],
    },
    {
      question: '6. There __ two questions that keep flooding my mind.',
      choices: [
        { name: '6', value: 'A', label: 'A.', text: 'is' },
        { name: '6', value: 'B', label: 'B.', text: 'are' },
        { name: '6', value: 'C', label: 'C.', text: 'has' },
        { name: '6', value: 'D', label: 'D.', text: 'have' },
      ],
    },
    {
      question: '7. Measles ___ a fatal illness when not treated properly',
      choices: [
        { name: '7', value: 'A', label: 'A.', text: 'is' },
        { name: '7', value: 'B', label: 'B.', text: 'are' },
        { name: '7', value: 'C', label: 'C.', text: 'has' },
        { name: '7', value: 'D', label: 'D.', text: 'have' },
      ],
    },
    {
      question: '8. A liter of cats ______ found playing at the quadrangle.',
      choices: [
        { name: '8', value: 'A', label: 'A.', text: 'was' },
        { name: '8', value: 'B', label: 'B.', text: 'were' },
        { name: '8', value: 'C', label: 'C.', text: 'has' },
        { name: '8', value: 'D', label: 'D.', text: 'have' },
      ],
    },
    {
      question: '9. One of my best friends ____ getting married this weekend.',
      choices: [
        { name: '9', value: 'A', label: 'A.', text: 'is' },
        { name: '9', value: 'B', label: 'B.', text: 'are' },
        { name: '9', value: 'C', label: 'C.', text: 'has' },
        { name: '9', value: 'D', label: 'D.', text: 'have' },
      ],
    },
    {
      question:
        '10. The mother, along with her children, ______ the recipient of the livelihood assistance from the Vice Mayor’s office.',
      choices: [
        { name: '10', value: 'A', label: 'A.', text: 'is' },
        { name: '10', value: 'B', label: 'B.', text: 'are' },
        { name: '10', value: 'C', label: 'C.', text: 'has' },
        { name: '10', value: 'D', label: 'D.', text: 'have' },
      ],
    },
    {
      question:
        '11. For the uninitiated, Economics __________ rather difficult to understand.',
      choices: [
        { name: '11', value: 'A', label: 'A.', text: 'seems' },
        { name: '11', value: 'B', label: 'B.', text: 'seem' },
        { name: '11', value: 'C', label: 'C.', text: 'seemingly' },
        { name: '11', value: 'D', label: 'D.', text: 'do seems' },
      ],
    },
    {
      question:
        '12. Mac and cheese __________ to be my favorite rainy day snack.',
      choices: [
        { name: '12', value: 'A', label: 'A.', text: 'happens' },
        { name: '12', value: 'B', label: 'B.', text: 'happen' },
        { name: '12', value: 'C', label: 'C.', text: 'do happen' },
        { name: '12', value: 'D', label: 'D.', text: 'do happens' },
      ],
    },
    {
      question:
        '13. Basketball and volleyball ____ two of the most popular sports in the Philippines.',
      choices: [
        { name: '13', value: 'A', label: 'A.', text: 'is' },
        { name: '13', value: 'B', label: 'B.', text: 'are' },
        { name: '13', value: 'C', label: 'C.', text: 'has' },
        { name: '13', value: 'D', label: 'D.', text: 'have' },
      ],
    },
    {
      question:
        '14. Fifty years _________ a long time coming for the alumni honorees.',
      choices: [
        { name: '14', value: 'A', label: 'A.', text: 'is' },
        { name: '14', value: 'B', label: 'B.', text: 'are' },
        { name: '14', value: 'C', label: 'C.', text: 'has' },
        { name: '14', value: 'D', label: 'D.', text: 'have' },
      ],
    },
    {
      question:
        '15. Everyone who works for the Quezon City local government ___ expected to know by heart Mayor Joy’s 14-point agenda.',
      choices: [
        { name: '15', value: 'A', label: 'A.', text: 'is' },
        { name: '15', value: 'B', label: 'B.', text: 'are' },
        { name: '15', value: 'C', label: 'C.', text: 'has' },
        { name: '15', value: 'D', label: 'D.', text: 'have' },
      ],
    },
  ]

  return (
    <>
      <Button
        className='bg-success text-white'
        onClick={() => setIsModalVisible(true)}
      >
        {!viewUserAnswer ? 'Go to activity' : 'View'}
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Activity'
        width={1000}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            hidden={viewUserAnswer.length > 0}
            key='submit'
            type='primary'
            disabled={isShowActivity || viewUserAnswer.length ? false : true}
            // loading={loading}
            onClick={handleSubmitActivityAnswer}
          >
            Submit
          </Button>,
        ]}
      >
        <div>
          <h4>Exercise No.1 (10minutes)</h4>
          {isShowActivity && (
            <h4>
              Find the correct subject or verb in the following sentences.
              Choose the letter of the correct answer.
            </h4>
          )}
        </div>
        {isShowActivity ? (
          <div>
            {questions.map((questionObj, index) => (
              <div key={index}>
                {activityOneAnswers ? (
                  <MultipleChoiceQuestion
                    question={questionObj.question}
                    choices={questionObj.choices}
                    questionName={`Question${index + 1}`}
                  />
                ) : null}
              </div>
            ))}
          </div>
        ) : (
          <>
            <Row className='mb-3 mt-5' justify='left'>
              <h3>Activity answer was already submitted as final.</h3>
            </Row>
          </>
        )}
      </Modal>
    </>
  )
}
