import { Button, Modal } from 'antd'
import React, { useState } from 'react'
import Comments from './comments'

export default function AdminCommentModalView({ userData, training }) {
  const [openView, setOpenView] = useState(false)

  return (
    <div>
      <Button className='mt-3' onClick={() => setOpenView(true)}>
        View comments
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={`${training.title} comments`}
        centered
        visible={openView}
        onOk={() => setOpenView(false)}
        onCancel={() => setOpenView(false)}
        width={850}
        footer={null}
      >
        <Comments training={training} userData={userData} />
      </Modal>
    </div>
  )
}
