export default function placeDataArray() {
  const data = [
    {
      location: 'Manila',
      municipality: 'Binondo',
      post_code: 1006,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Intramuros',
      post_code: 1002,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Malate',
      post_code: 1004,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Manila CPO - Ermita',
      post_code: 1000,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Paco',
      post_code: 1007,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Pandacan',
      post_code: 1011,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Port Area (South)',
      post_code: 1018,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Quiapo',
      post_code: 1001,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Sampaloc East',
      post_code: 1008,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Sampaloc West',
      post_code: 1015,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'San Andres Bukid',
      post_code: 1017,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'San Miguel',
      post_code: 1005,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'San Nicolas',
      post_code: 1010,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Sta. Ana',
      post_code: 1009,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Sta Cruz North',
      post_code: 1014,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Sta Cruz South',
      post_code: 1003,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Santa Mesa',
      post_code: 1016,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Tondo North',
      post_code: 1013,
      region: 'NCR',
    },
    {
      location: 'Manila',
      municipality: 'Tondo South',
      post_code: 1012,
      region: 'NCR',
    },
    {
      location: 'Caloocan City (North)',
      region: 'NCR',
      municipality: 'Amparo Subdivision',
      post_code: 1425,
    },
    {
      location: 'Caloocan area (North)',
      region: 'NCR',
      municipality: 'Bagong Silang',
      post_code: 1428,
    },
    {
      location: 'Caloocan City (North)',
      region: 'NCR',
      municipality: 'Bagumbong/Pag-asa',
      post_code: 1421,
    },
    {
      location: 'Caloocan City (North)',
      region: 'NCR',
      municipality: 'Bankers Village',
      post_code: 1426,
    },
    {
      location: 'Caloocan City (North)',
      region: 'NCR',
      municipality: 'Capitol Parkland Subdivision',
      post_code: 1424,
    },
    {
      location: 'Caloocan City (North)',
      region: 'NCR',
      municipality: 'Kaybiga/Deparo',
      post_code: 1420,
    },
    {
      location: 'Caloocan City (North)',
      region: 'NCR',
      municipality: 'Lilles Ville Subdivision',
      post_code: 1423,
    },
    {
      location: 'Caloocan City (North)',
      region: 'NCR',
      municipality: 'Novaliches North',
      post_code: 1422,
    },
    {
      location: 'Caloocan City (North)',
      region: 'NCR',
      municipality: 'Tala Leprosarium',
      post_code: 1427,
    },
    {
      location: 'Caloocan City (North)',
      region: 'NCR',
      municipality: 'Victory Heights',
      post_code: 1427,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: '1st Avenue to 7th Avenue - West',
      post_code: 1405,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Baesa',
      post_code: 1401,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Fish Market',
      post_code: 1411,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Grace Park East',
      post_code: 1403,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Grace Park West',
      post_code: 1406,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Isla de Cocomo',
      post_code: 1412,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Caloocan City CPO',
      post_code: 1400,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Kapitbahayan East',
      post_code: 1413,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Kaunlaran Village',
      post_code: 1409,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Maypajo',
      post_code: 1410,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'San Jose',
      post_code: 1404,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Sangandaan',
      post_code: 1408,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'Sta. Quiteria',
      post_code: 1402,
    },
    {
      location: 'Caloocan City (South)',
      region: 'NCR',
      municipality: 'University Hills',
      post_code: 1407,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Almanza',
      post_code: 1750,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Angela Village',
      post_code: 1749,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Cut-cut',
      post_code: 1743,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Gatchalian Subdivision',
      post_code: 1745,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Las Piñas CPO',
      post_code: 1740,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Manila Doctors Village',
      post_code: 1748,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Manuyo',
      post_code: 1744,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Pulang Lupa',
      post_code: 1742,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Remarville Subdivision',
      post_code: 1741,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Soldiers Hills Subdivision',
      post_code: 1752,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'T. S. Cruz Subdivision',
      post_code: 1751,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Talon, Moonwalk',
      post_code: 1747,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Verdant Acres Subdivision',
      post_code: 1746,
    },
    {
      location: 'Las Piñas City',
      region: 'NCR',
      municipality: 'Zapote',
      post_code: 1742,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Ayala-Paseo De Roxas',
      post_code: 1226,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Bangkal',
      post_code: 1233,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Bel-air',
      post_code: 1209,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Cembo',
      post_code: 1214,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Comembo',
      post_code: 1217,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Dasmarinas Village North',
      post_code: 1221,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Dasmarinas Village South',
      post_code: 1222,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Forbes Park North',
      post_code: 1219,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Forbes Park South',
      post_code: 1220,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Fort Bonifacio Naval Station',
      post_code: 1202,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Greenbelt',
      post_code: 1228,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Guadalupe Nuevo (inc. Visayan)',
      post_code: 1212,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Guadalupe Viejo (inc. Palm Village)',
      post_code: 1211,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Kasilawan',
      post_code: 1206,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'La Paz-Singkamas-Tejeros',
      post_code: 1204,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Legaspi Village',
      post_code: 1229,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Magallanes Village',
      post_code: 1232,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Commercial Center',
      post_code: 1224,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Makati CPO (Inc. Buendia Avenue)',
      post_code: 1200,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Olympia and Carmona',
      post_code: 1207,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Palanan',
      post_code: 1235,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Pasong Tamo 2000 Up, Ecology Village',
      post_code: 1231,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Pembo',
      post_code: 1218,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Pinagkaisahan-pitogo',
      post_code: 1213,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Pio Del Pilar',
      post_code: 1230,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Poblacion',
      post_code: 1210,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Rembo (East) and Malapad Na Bato',
      post_code: 1216,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Rembo (West)',
      post_code: 1215,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Salcedo Village',
      post_code: 1227,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'San Antonio Village (inc. Malu)',
      post_code: 1203,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'San Isidro',
      post_code: 1234,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'San Lorenzo Village',
      post_code: 1223,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Sta. Cruz',
      post_code: 1205,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Urdaneta Village',
      post_code: 1225,
    },
    {
      location: 'Makati City',
      region: 'NCR',
      municipality: 'Valenzuela (inc. Santiago, San Miguel and Rizal Village)',
      post_code: 1208,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Acacia',
      post_code: 1474,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Araneta Subdivision',
      post_code: 1476,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Dampalit',
      post_code: 1480,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Flores',
      post_code: 1471,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Kaunlaran Village',
      post_code: 1409,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Longos',
      post_code: 1472,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Malabon',
      post_code: 1470,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Maysilo',
      post_code: 1477,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Muzon',
      post_code: 1479,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Potrero',
      post_code: 1475,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Santolan',
      post_code: 1478,
    },
    {
      location: 'Malabon City',
      region: 'NCR',
      municipality: 'Tonsuya',
      post_code: 1473,
    },
    {
      location: 'Mandaluyong City',
      region: 'NCR',
      municipality: 'East Edsa',
      post_code: 1554,
    },
    {
      location: 'Mandaluyong City',
      region: 'NCR',
      municipality: 'Greenhills South',
      post_code: 1556,
    },
    {
      location: 'Mandaluyong City',
      region: 'NCR',
      municipality: 'Mandaluyong CPO',
      post_code: 1550,
    },
    {
      location: 'Mandaluyong City',
      region: 'NCR',
      municipality: 'Philippine National Mental Hospital',
      post_code: 1553,
    },
    {
      location: 'Mandaluyong City',
      region: 'NCR',
      municipality: 'Shaw Boulevard',
      post_code: 1552,
    },
    {
      location: 'Mandaluyong City',
      region: 'NCR',
      municipality: 'Vergara',
      post_code: 1551,
    },
    {
      location: 'Mandaluyong City',
      region: 'NCR',
      municipality: 'Wack Wack Golf',
      post_code: 1555,
    },
    {
      location: 'Marikina City',
      municipality: 'Barangka',
      post_code: 1803,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Concepcion 1',
      post_code: 1807,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Concepcion 2',
      post_code: 1811,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Fortune',
      post_code: 1812,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Industrial Valley',
      post_code: 1802,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'J. De La Peña',
      post_code: 1804,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Malanday',
      post_code: 1805,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Marikina CPO - Sta. Elena - Sto. Niño',
      post_code: 1800,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Marikina Heights',
      post_code: 1810,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Nangka',
      post_code: 1808,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Tumana',
      post_code: 1806,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Parang',
      post_code: 1809,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'San Roque - Calumpang',
      post_code: 1801,
      region: 'NCR',
    },
    {
      location: 'Marikina City',
      municipality: 'Tañong',
      post_code: 1803,
      region: 'NCR',
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Ayala Alabang P.O. Boxes',
      post_code: 1799,
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Ayala Alabang Subdivision',
      post_code: 1780,
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Bayanan/Putatan',
      post_code: 1772,
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Bule/Cupang',
      post_code: 1771,
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Filinvest Corp. City',
      post_code: 1781,
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Muntinlupa CPO',
      post_code: 1770,
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Pearl Heights',
      post_code: 1775,
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Pleasant Village',
      post_code: 1777,
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Poblacion',
      post_code: 1776,
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Susana Heights',
      post_code: 1774,
    },
    {
      location: 'Muntinlupa City',
      region: 'NCR',
      municipality: 'Tunasan',
      post_code: 1773,
    },
    {
      location: 'Navotas City',
      region: 'NCR',
      municipality: 'Fish Market',
      post_code: 1411,
    },
    {
      location: 'Navotas City',
      region: 'NCR',
      municipality: 'Isla De Cocomo',
      post_code: 1412,
    },
    {
      location: 'Navotas City',
      region: 'NCR',
      municipality: 'Kapitbahayan East',
      post_code: 1413,
    },
    {
      location: 'Navotas City',
      region: 'NCR',
      municipality: 'Kaunlaran Village',
      post_code: 1409,
    },
    {
      location: 'Navotas City',
      region: 'NCR',
      municipality: 'Navotas',
      post_code: 1485,
    },
    {
      location: 'Navotas City',
      region: 'NCR',
      municipality: 'Tangos',
      post_code: 1489,
    },
    {
      location: 'Navotas City',
      region: 'NCR',
      municipality: 'Tanza',
      post_code: 1490,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Aeropark Subdivision',
      post_code: 1714,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Baclaran',
      post_code: 1702,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Better Living Subdivision',
      post_code: 1711,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Bf Homes 1',
      post_code: 1720,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Bf Homes 2',
      post_code: 1718,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Executive Heights Subdivision',
      post_code: 1710,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Ireneville II',
      post_code: 1714,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Ireneville Subdivision I & II',
      post_code: 1719,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Manila Memorial Park',
      post_code: 1717,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Marina Subdivision (Reclamation)',
      post_code: 1703,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Maywood I',
      post_code: 1719,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Maywood Village II',
      post_code: 1716,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Merville Park',
      post_code: 1709,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Miramar Subdivision',
      post_code: 1712,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Moonwalk',
      post_code: 1709,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Multinational Village',
      post_code: 1708,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Ninoy Aquino International Airport (NAIA)',
      post_code: 1705,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Parañaque CPO',
      post_code: 1700,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Pulo',
      post_code: 1706,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'San Antonio Valley 11 & 12',
      post_code: 1707,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'San Antonio Valley I',
      post_code: 1715,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'South Admiral Village',
      post_code: 1709,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Sto. Niño',
      post_code: 1704,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'Tambo',
      post_code: 1701,
    },
    {
      location: 'Parañaque City',
      region: 'NCR',
      municipality: 'United Subdivision',
      post_code: 1713,
    },
    {
      location: 'Pasay City',
      region: 'NCR',
      municipality: 'Domestic Airport PO',
      post_code: 1301,
    },
    {
      location: 'Pasay City',
      region: 'NCR',
      municipality: 'Manila Bay (Reclamation)',
      post_code: 1308,
    },
    {
      location: 'Pasay City',
      region: 'NCR',
      municipality: 'Pasay City CPO Malibay',
      post_code: 1300,
    },
    {
      location: 'Pasay City',
      region: 'NCR',
      municipality: 'PICC (Reclamation Area)',
      post_code: 1307,
    },
    {
      location: 'Pasay City',
      region: 'NCR',
      municipality: 'San Isidro',
      post_code: 1306,
    },
    {
      location: 'Pasay City',
      region: 'NCR',
      municipality: 'San Jose',
      post_code: 1305,
    },
    {
      location: 'Pasay City',
      region: 'NCR',
      municipality: 'San Rafael',
      post_code: 1302,
    },
    {
      location: 'Pasay City',
      region: 'NCR',
      municipality: 'San Roque',
      post_code: 1303,
    },
    {
      location: 'Pasay City',
      region: 'NCR',
      municipality: 'Santa Clara',
      post_code: 1304,
    },
    {
      location: 'Pasay City',
      region: 'NCR',
      municipality: 'Villamor Airbase',
      post_code: 1309,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Caniogan',
      post_code: 1606,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Green Park',
      post_code: 1612,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Kapitolyo',
      post_code: 1603,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Manggahan',
      post_code: 1611,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Maybunga',
      post_code: 1607,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Ortigas PO',
      post_code: 1605,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Bagong Ilog',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Bagong Katipunan',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Bambang',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Buting',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Dela Paz',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Kalawaan',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Kapasigan',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Malinao',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Oranbo',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Palatiw',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Pineda',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Sagad',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'San Antonio',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'San Jose',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'San Miguel',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'San Nicolas',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Santa Cruz',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Santa Rosa',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Santo Tomas',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Sumilang',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Pasig CPO',
      post_code: 1600,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Pinagbuhatan',
      post_code: 1602,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Rosario',
      post_code: 1609,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'San Joaquin',
      post_code: 1601,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Santolan',
      post_code: 1610,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Sta. Lucia',
      post_code: 1608,
    },
    {
      location: 'Pasig City',
      region: 'NCR',
      municipality: 'Ugong',
      post_code: 1604,
    },
    {
      location: 'Pateros',
      region: 'NCR',
      municipality: 'Aguho',
      post_code: 1620,
    },
    {
      location: 'Pateros',
      region: 'NCR',
      municipality: 'Sta. Ana',
      post_code: 1621,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Alicia',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Amihan',
      post_code: 1102,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Apolonio Samson',
      post_code: 1106,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Baesa',
      post_code: 1106,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Bagbag',
      post_code: 1116,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Bagong Bayan',
      post_code: 1110,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Bagong Buhay',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Bagong Lipunan',
      post_code: 1111,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Bagong Pag-asa',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Bagong Silangan',
      post_code: 1119,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Bahay Toro',
      post_code: 1106,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Balingasa',
      post_code: 1115,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Balumbato',
      post_code: 1106,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Batasan Hills',
      post_code: 1126,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Bayanihan',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'BF Homes',
      post_code: 1120,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Blue Ridge',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Botocan',
      post_code: 1101,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Bungad',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Camp Aguinaldo',
      post_code: 1110,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Capitol Hills/Park',
      post_code: 1126,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Capri',
      post_code: 1117,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Central',
      post_code: 1100,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Claro',
      post_code: 1102,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Commonwealth',
      post_code: 1121,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Crame',
      post_code: 1111,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Cubao',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Culiat',
      post_code: 1128,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Damar',
      post_code: 1115,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Damayan',
      post_code: 1104,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Damayan Lagi',
      post_code: 1112,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Damong Maliit',
      post_code: 1123,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Del Monte',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Diliman',
      post_code: 1101,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Dioquino Zobel',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Doña Imelda',
      post_code: 1113,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Don Manuel',
      post_code: 1113,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Doña Aurora',
      post_code: 1113,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Doña Faustina Subd',
      post_code: 1125,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Doña Josefa',
      post_code: 1113,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Duyan-duyan',
      post_code: 1102,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'E. Rodriguez',
      post_code: 1102,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Escopa',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Fairview',
      post_code: 1118,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Fairview North',
      post_code: 1121,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Fairview South',
      post_code: 1122,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Gantol',
      post_code: 1113,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Gintong Silahis',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Gulod',
      post_code: 1117,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Holy Spirit',
      post_code: 1127,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Horseshoe',
      post_code: 1112,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Immaculate Concepcion',
      post_code: 1111,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Kaligayahan',
      post_code: 1124,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Kalusugan',
      post_code: 1112,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Kamias',
      post_code: 1102,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Kamuning',
      post_code: 1103,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Katipunan',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Kaunlaran',
      post_code: 1111,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Kristong Hari',
      post_code: 1112,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Krus Na Ligas',
      post_code: 1101,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'La Loma',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Langing Handa',
      post_code: 1103,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Libis',
      post_code: 1110,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Lourdes',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Loyola Heights',
      post_code: 1108,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Maharlika',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Malaya',
      post_code: 1101,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Mangga',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Manresa',
      post_code: 1115,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Mariana',
      post_code: 1112,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Mariblo',
      post_code: 1104,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Marilag',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Masagana',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Masambong',
      post_code: 1115,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Matalahib',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Matandang Balara',
      post_code: 1119,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Milagrosa',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Nagkaisang Nayon',
      post_code: 1125,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Nayon Kaunlaran',
      post_code: 1104,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'New Era',
      post_code: 1107,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Novaliches Proper',
      post_code: 1121,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Obrero',
      post_code: 1103,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Old Capitol Site',
      post_code: 1101,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Paang Bundok',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Pag-ibig Sa Nayon',
      post_code: 1115,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Paligsahan',
      post_code: 1103,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Paltok',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Pansol',
      post_code: 1108,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Paraiso',
      post_code: 1104,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Pasong Putik',
      post_code: 1118,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Pasong Tamo',
      post_code: 1107,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Payatas',
      post_code: 1119,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Phil-am',
      post_code: 1104,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Pinagkaisahan',
      post_code: 1111,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Piñahan',
      post_code: 1100,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Project 4',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Project 6',
      post_code: 1100,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Project 7',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Project 8',
      post_code: 1106,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Quezon City CPO',
      post_code: 1100,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Quirino District (Project 2 & 3)',
      post_code: 1102,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'R. Magsaysay',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Roxas District',
      post_code: 1103,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Sacred Heart',
      post_code: 1103,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Salumbato',
      post_code: 1106,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Salvacion',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'San Agustin',
      post_code: 1117,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'San Antonio',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'San Bartolome',
      post_code: 1116,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'San Isidro',
      post_code: 1113,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'San Isidro Labrador',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'San Jose',
      post_code: 1115,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'San Roque',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'San Vicente',
      post_code: 1101,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Sangandaan',
      post_code: 1116,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Santa Cruz',
      post_code: 1104,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Santa Lucia',
      post_code: 1117,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Santa Monica',
      post_code: 1117,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Santa Teresita',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Sauyo',
      post_code: 1116,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Sienna',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Sikatuna Village',
      post_code: 1101,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Silangan',
      post_code: 1102,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Socorro',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'South Triangle',
      post_code: 1103,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'St. Ignatius',
      post_code: 1110,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'St. Martin De Porres',
      post_code: 1111,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'St. Peter',
      post_code: 1114,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Sto. Cristo',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Sto. Nino',
      post_code: 1113,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Tagumpay',
      post_code: 1109,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Talampas',
      post_code: 1110,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Talayan',
      post_code: 1104,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Talipapa',
      post_code: 1116,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Tandang Sora',
      post_code: 1116,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Tatalon',
      post_code: 1113,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Teachers Village',
      post_code: 1101,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Ugong Norte',
      post_code: 1110,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Unang Sigaw',
      post_code: 1106,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'University of the Philippines PO',
      post_code: 1101,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'UP Village',
      post_code: 1101,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Valencia',
      post_code: 1112,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Vasra',
      post_code: 1128,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Veterans Village',
      post_code: 1105,
    },
    {
      location: 'Quezon City',
      region: 'NCR',
      municipality: 'Villa Maria Clara',
      post_code: 1109,
    },
    {
      location: 'San Juan City',
      municipality: 'Eisenhower-Crame',
      post_code: 1504,
      region: 'NCR',
    },
    {
      location: 'San Juan City',
      municipality: 'Greenhills North',
      post_code: 1503,
      region: 'NCR',
    },
    {
      location: 'San Juan City',
      municipality: 'Greenhills PO',
      post_code: 1502,
      region: 'NCR',
    },
    {
      location: 'San Juan City',
      municipality: 'San Juan CPO',
      post_code: 1500,
      region: 'NCR',
    },
    {
      location: 'Taguig City',
      region: 'NCR',
      municipality: 'Bay Breeze Executive Village',
      post_code: 1636,
    },
    {
      location: 'Taguig City',
      region: 'NCR',
      municipality: 'Bicutan',
      post_code: 1631,
    },
    {
      location: 'Taguig City',
      region: 'NCR',
      municipality: 'Bicutan Lower',
      post_code: 1632,
    },
    {
      location: 'Taguig City',
      region: 'NCR',
      municipality: 'Bicutan Upper',
      post_code: 1633,
    },
    {
      location: 'Taguig City',
      region: 'NCR',
      municipality: 'Bicutan Western',
      post_code: 1630,
    },
    {
      location: 'Taguig City',
      region: 'NCR',
      municipality: 'Bonifacio Global City',
      post_code: 1635,
    },
    {
      location: 'Taguig City',
      region: 'NCR',
      municipality: 'Ligid',
      post_code: 1638,
    },
    {
      location: 'Taguig City',
      region: 'NCR',
      municipality: 'Nichols - Mckinley',
      post_code: 1634,
    },
    {
      location: 'Taguig City',
      region: 'NCR',
      municipality: 'Tuktukan',
      post_code: 1637,
    },
    {
      location: 'Taguig City',
      region: 'NCR',
      municipality: 'Ususan',
      post_code: 1639,
    },
    {
      location: 'Valenzuela City',
      region: 'NCR',
      municipality: 'Arkong Bato-Rincon-Pasolo-Malanday-Mabolo-Polo',
      post_code: 1444,
    },
    {
      location: 'Valenzuela City',
      region: 'NCR',
      municipality: 'Balangkas-Caloong',
      post_code: 1445,
    },
    {
      location: 'Valenzuela City',
      region: 'NCR',
      municipality: 'Dalandan - West Canumay',
      post_code: 1443,
    },
    {
      location: 'Valenzuela City',
      region: 'NCR',
      municipality: 'East Canumay - Lawang Bato Pun',
      post_code: 1447,
    },
    {
      location: 'Valenzuela City',
      region: 'NCR',
      municipality: 'Fortune Village - Paso De Blas - Gen. T. De Leon',
      post_code: 1442,
    },
    {
      location: 'Valenzuela City',
      region: 'NCR',
      municipality: 'Karuhatan',
      post_code: 1441,
    },
    {
      location: 'Valenzuela City',
      region: 'NCR',
      municipality: 'Lingunan',
      post_code: 1446,
    },
    {
      location: 'Valenzuela City',
      region: 'NCR',
      municipality: 'Mapulang Lupa',
      post_code: 1448,
    },
    {
      location: 'Valenzuela City',
      region: 'NCR',
      municipality: 'Valenzuela CPO - Malinta',
      post_code: 1440,
    },
    {
      location: 'Valenzuela City',
      region: 'NCR',
      municipality: 'Valenzuela P.O. Boxes',
      post_code: 1469,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'ACCRA Law Offices',
      post_code: 770,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Adventist University of the Philippines',
      post_code: 901,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'American Express',
      post_code: 710,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Araneta Center',
      post_code: 810,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Asian Development Bank - Manila',
      post_code: 980,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Asian Development Bank - San Juan City',
      post_code: 401,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Ateneo de Manila University',
      post_code: 917,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Atlantic, Gulf and Pacific',
      post_code: 790,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'A-Z Direct Marketing',
      post_code: 709,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Bank of the Philippine Islands',
      post_code: 720,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'BCD Direct Marketing',
      post_code: 731,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Bible School on the Air',
      post_code: 420,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'BPI Family Savings Bank',
      post_code: 713,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Bureau of Internal Revenue',
      post_code: 820,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Bureau of Plant Industry',
      post_code: 912,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Caltex Philippines',
      post_code: 926,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Camp Aguinaldo',
      post_code: 802,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Camp Crame',
      post_code: 801,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Canadian Embassy',
      post_code: 707,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Chinabank',
      post_code: 925,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Citibank',
      post_code: 702,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Colgate Palmolive Philippines',
      post_code: 701,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Commission on Audit',
      post_code: 880,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'De La Salle University',
      post_code: 922,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Department of Justice',
      post_code: 970,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Department of Labor and Employment',
      post_code: 910,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Diners Club',
      post_code: 717,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Directories Philippines Corporation',
      post_code: 788,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Don Stewart Evangelistic Association',
      post_code: 923,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Equitable PCI Bank',
      post_code: 726,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Export and Industry Bank',
      post_code: 732,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Faith Embassy',
      post_code: 706,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Far East Broadcasting Company - Manila',
      post_code: 902,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Far East Broadcasting Corporation - Valenzuela City',
      post_code: 560,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Febias College of Bible',
      post_code: 550,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Fil-Japan Shipping Company',
      post_code: 918,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Insular Life Assurance Company',
      post_code: 725,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'International Center for Aquatic Resources',
      post_code: 718,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'International Correspondence Institute',
      post_code: 915,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'International Correspondence School',
      post_code: 400,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'International Rice Research Institute',
      post_code: 960,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Japanese Embassy',
      post_code: 723,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Land Bank of the Philippines',
      post_code: 711,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Manila Bulletin',
      post_code: 900,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Manila Hotel',
      post_code: 913,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Manila Medical Center',
      post_code: 906,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Manila Pavilion Hotel',
      post_code: 903,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Manila Police District',
      post_code: 919,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Marcopper Mining Corporation',
      post_code: 715,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Mead Johnson Philippines',
      post_code: 780,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Midland Plaza Manila Hotel',
      post_code: 904,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'National Irrigation Administration',
      post_code: 830,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Pan-Filipino Shipping',
      post_code: 908,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine Airlines',
      post_code: 750,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine American Life and General Insurance Company',
      post_code: 990,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine Campus Crusade for Christ',
      post_code: 928,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine Heart Center',
      post_code: 850,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine Long Distance Telephone Company',
      post_code: 721,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine Manila Mission',
      post_code: 714,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine National Bank',
      post_code: 940,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine National Oil Company',
      post_code: 708,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine Refining Company and Unilever Philippines',
      post_code: 914,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine Statistics Authority',
      post_code: 911,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Philippine Veterans Affairs Office',
      post_code: 927,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Pilipinas Shell',
      post_code: 760,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Plain Truth Ministries',
      post_code: 700,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Producers Bank',
      post_code: 704,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Prudential Bank',
      post_code: 712,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Quezon City Hall',
      post_code: 860,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Radio Bible Class',
      post_code: 410,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Radio Veritas',
      post_code: 916,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Rizal Commercial Banking Corporation',
      post_code: 727,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'San Miguel Corporation',
      post_code: 907,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Sarmiento Enterprises',
      post_code: 703,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Security Bank',
      post_code: 719,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Social Security System',
      post_code: 800,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Sofitel Philippine Plaza',
      post_code: 921,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Summer Institute of Linguistics',
      post_code: 909,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Sun Life Financial',
      post_code: 724,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Sunbursts Publications',
      post_code: 920,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Sycip, Gorres, Velayo and Co.',
      post_code: 740,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'The Philippine Star',
      post_code: 905,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Union Ajinomoto',
      post_code: 705,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'United Coconut Planters Bank',
      post_code: 728,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'United States Embassy',
      post_code: 930,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'V. Luna Hospital',
      post_code: 840,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Veterans Hospital',
      post_code: 870,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'World Executive Digest',
      post_code: 722,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'World Map',
      post_code: 924,
    },
    {
      location: 'Companies/Institutions',
      region: 'NCR',
      municipality: 'Zuellig Pharma',
      post_code: 730,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Bangued',
      post_code: 2800,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Boliney',
      post_code: 2815,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Bucloc',
      post_code: 2817,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Bucay',
      post_code: 2805,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Daguioman',
      post_code: 2816,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Danglas',
      post_code: 2825,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Dolores',
      post_code: 2801,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'La Paz',
      post_code: 2826,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Lacub',
      post_code: 2821,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Lagayan',
      post_code: 2824,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Lagangilang',
      post_code: 2802,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Langiden',
      post_code: 2807,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Licuan-Baay',
      post_code: 2819,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Luba',
      post_code: 2813,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Malibcong',
      post_code: 2820,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Manabo',
      post_code: 2810,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Peñarrubia',
      post_code: 2804,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Pidigan',
      post_code: 2806,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Pilar',
      post_code: 2812,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Sal-Lapadan',
      post_code: 2818,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'San Isidro',
      post_code: 2809,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'San Juan',
      post_code: 2823,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'San Quintin',
      post_code: 2808,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Tayum',
      post_code: 2803,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Tineg',
      post_code: 2822,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Tubo',
      post_code: 2814,
    },
    {
      location: 'Abra',
      region: 'CAR',
      municipality: 'Villaviciosa',
      post_code: 2811,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Buenavista',
      post_code: 8601,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Butuan City',
      post_code: 8600,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Cabadbaran',
      post_code: 8605,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Carmen',
      post_code: 8603,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Jabonga',
      post_code: 8607,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Kitcharao',
      post_code: 8609,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Las Nieves',
      post_code: 8610,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Magallanes',
      post_code: 8604,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Nasipit',
      post_code: 8602,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Remedios T. Romualdez',
      post_code: 8611,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Santiago',
      post_code: 8608,
    },
    {
      location: 'Agusan del Norte',
      region: 'XIII',
      municipality: 'Tubay',
      post_code: 8606,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Bayugan',
      post_code: 8502,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Bunawan',
      post_code: 8506,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Esperanza',
      post_code: 8513,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'La Paz',
      post_code: 8508,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Loreto',
      post_code: 8507,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Prosperidad',
      post_code: 8500,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Rosario',
      post_code: 8504,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'San Francisco',
      post_code: 8501,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'San Luis',
      post_code: 8511,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Sibagat',
      post_code: 8503,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Sta. Josefa',
      post_code: 8512,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Talacogon',
      post_code: 8510,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Trento',
      post_code: 8505,
    },
    {
      location: 'Agusan del Sur',
      region: 'XIII',
      municipality: 'Veruela',
      post_code: 8509,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Altavas',
      post_code: 5616,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Balete',
      post_code: 5614,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Banga',
      post_code: 5601,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Batan',
      post_code: 5615,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Buruanga',
      post_code: 5609,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Ibajay',
      post_code: 5613,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Kalibo',
      post_code: 5600,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Lezo',
      post_code: 5605,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Libacao',
      post_code: 5602,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Madalag',
      post_code: 5603,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Makato',
      post_code: 5611,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Malay',
      post_code: 5608,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Malinao',
      post_code: 5606,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Nabas',
      post_code: 5607,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'New Washington',
      post_code: 5610,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Numancia',
      post_code: 5604,
    },
    {
      location: 'Aklan',
      region: 'VI',
      municipality: 'Tangalan',
      post_code: 5612,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Bacacay',
      post_code: 4509,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Camalig',
      post_code: 4502,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Daraga (Locsin)',
      post_code: 4501,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Guinobatan',
      post_code: 4503,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Jovellar',
      post_code: 4515,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Legazpi City',
      post_code: 4500,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Libon',
      post_code: 4507,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Ligao',
      post_code: 4504,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Malilipot',
      post_code: 4510,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Malinao',
      post_code: 4512,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Manito',
      post_code: 4514,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Oas',
      post_code: 4505,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Pio Duran (Malacbalac)',
      post_code: 4516,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Polangui',
      post_code: 4506,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Rapu-Rapu',
      post_code: 4517,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Sto. Domingo',
      post_code: 4508,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Tabaco',
      post_code: 4511,
    },
    {
      location: 'Albay',
      region: 'V',
      municipality: 'Tiwi',
      post_code: 4513,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Anini-y',
      post_code: 5717,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Barbaza',
      post_code: 5706,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Belison',
      post_code: 5701,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Bugasong',
      post_code: 5704,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Caluya',
      post_code: 5711,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Culasi',
      post_code: 5708,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Hamtic',
      post_code: 5715,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Laua-an',
      post_code: 5705,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Libertad',
      post_code: 5710,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Pandan',
      post_code: 5712,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Patnongon',
      post_code: 5702,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'San Jose',
      post_code: 5700,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'San Remegio',
      post_code: 5714,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Sebaste',
      post_code: 5709,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Sibalom',
      post_code: 5713,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Tibiao',
      post_code: 5707,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Tobias Fornier (Dao)',
      post_code: 5716,
    },
    {
      location: 'Antique',
      region: 'VI',
      municipality: 'Valderrama',
      post_code: 5703,
    },
    {
      location: 'Apayao',
      region: 'CAR',
      municipality: 'Calanasan',
      post_code: 3814,
    },
    {
      location: 'Apayao',
      region: 'CAR',
      municipality: 'Conner',
      post_code: 3807,
    },
    {
      location: 'Apayao',
      region: 'CAR',
      municipality: 'Flora',
      post_code: 3810,
    },
    {
      location: 'Apayao',
      region: 'CAR',
      municipality: 'Kabugao',
      post_code: 3809,
    },
    {
      location: 'Apayao',
      region: 'CAR',
      municipality: 'Luna',
      post_code: 3813,
    },
    {
      location: 'Apayao',
      region: 'CAR',
      municipality: 'Pudtol',
      post_code: 3812,
    },
    {
      location: 'Apayao',
      region: 'CAR',
      municipality: 'Sta. Marcela',
      post_code: 3811,
    },
    {
      location: 'Aurora',
      region: 'CAR',
      municipality: 'Baler',
      post_code: 3200,
    },
    {
      location: 'Aurora',
      region: 'CAR',
      municipality: 'Casiguran',
      post_code: 3204,
    },
    {
      location: 'Aurora',
      region: 'CAR',
      municipality: 'Dilasag',
      post_code: 3205,
    },
    {
      location: 'Aurora',
      region: 'CAR',
      municipality: 'Dinalungan',
      post_code: 3206,
    },
    {
      location: 'Aurora',
      region: 'CAR',
      municipality: 'Dingalan',
      post_code: 3207,
    },
    {
      location: 'Aurora',
      region: 'CAR',
      municipality: 'Dipaculao',
      post_code: 3203,
    },
    {
      location: 'Aurora',
      region: 'CAR',
      municipality: 'Maria Aurora',
      post_code: 3202,
    },
    {
      location: 'Aurora',
      region: 'CAR',
      municipality: 'San Luis',
      post_code: 3201,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Akbar',
      post_code: 7307,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Al-Barka',
      post_code: 7308,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Hadji Mohammad Ajul',
      post_code: 7309,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Hadji Muhtamad',
      post_code: 7310,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Isabela de Basilan',
      post_code: 7300,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Lamitan',
      post_code: 7302,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Lantawan',
      post_code: 7301,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Maluso',
      post_code: 7303,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Sumisip',
      post_code: 7305,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Tabuan-Lasa',
      post_code: 7312,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Tipo-Tipo',
      post_code: 7304,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Tuburan',
      post_code: 7306,
    },
    {
      location: 'Basilan',
      region: 'BARMM',
      municipality: 'Ungkaya Pukan',
      post_code: 7311,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Abucay',
      post_code: 2114,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Cabcaben',
      post_code: 2109,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Bagac',
      post_code: 2107,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Balanga',
      post_code: 2100,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Bataan Export Processing Zone',
      post_code: 2106,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Dinalupihan',
      post_code: 2110,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Hermosa',
      post_code: 2111,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Lamao',
      post_code: 2104,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Limay',
      post_code: 2103,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Mariveles',
      post_code: 2105,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Morong',
      post_code: 2108,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Orani',
      post_code: 2112,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Orion',
      post_code: 2102,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Pilar',
      post_code: 2101,
    },
    {
      location: 'Bataan',
      region: 'III',
      municipality: 'Samal',
      post_code: 2113,
    },
    {
      location: 'Batanes',
      region: 'II',
      municipality: 'Basco',
      post_code: 3900,
    },
    {
      location: 'Batanes',
      region: 'II',
      municipality: 'Itbayat',
      post_code: 3905,
    },
    {
      location: 'Batanes',
      region: 'II',
      municipality: 'Ivana',
      post_code: 3902,
    },
    {
      location: 'Batanes',
      region: 'II',
      municipality: 'Mahatao',
      post_code: 3901,
    },
    {
      location: 'Batanes',
      region: 'II',
      municipality: 'Sabtang',
      post_code: 3904,
    },
    {
      location: 'Batanes',
      region: 'II',
      municipality: 'Uyugan',
      post_code: 3903,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Agoncillo',
      post_code: 4211,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Alitagtag',
      post_code: 4205,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Balayan',
      post_code: 4213,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Balete',
      post_code: 4219,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Batangas City',
      post_code: 4200,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Bauan',
      post_code: 4201,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Calaca',
      post_code: 4212,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Calatagan',
      post_code: 4215,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Cuenca',
      post_code: 4222,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Fernando Airbase',
      post_code: 4218,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Ibaan',
      post_code: 4230,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Laurel',
      post_code: 4221,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Lemery',
      post_code: 4209,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Lian',
      post_code: 4216,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Lipa City',
      post_code: 4217,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Lobo',
      post_code: 4229,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Mabini',
      post_code: 4202,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Malvar',
      post_code: 4233,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Mataas na Kahoy',
      post_code: 4223,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Nasugbu',
      post_code: 4231,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Padre Garcia',
      post_code: 4224,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Rosario',
      post_code: 4225,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'San Jose',
      post_code: 4227,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'San Juan',
      post_code: 4226,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'San Luis',
      post_code: 4210,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'San Nicolas',
      post_code: 4207,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'San Pascual',
      post_code: 4204,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Sta. Teresita',
      post_code: 4206,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Sto. Tomas',
      post_code: 4234,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Taal',
      post_code: 4208,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Talisay',
      post_code: 4220,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Tanauan',
      post_code: 4232,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Taysan',
      post_code: 4228,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Tingloy',
      post_code: 4203,
    },
    {
      location: 'Batangas',
      region: 'IV-A',
      municipality: 'Tuy',
      post_code: 4214,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'CAR ',
      post_code: 2612,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Baguio City',
      post_code: 2600,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Bakun',
      post_code: 2610,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Bokod',
      post_code: 2605,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Bugias',
      post_code: 2607,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Itogon',
      post_code: 2604,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Kabayan',
      post_code: 2606,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Kapangan',
      post_code: 2613,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Kibungan',
      post_code: 2611,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'La Trinidad',
      post_code: 2601,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Lepanto',
      post_code: 2609,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Mankayan',
      post_code: 2608,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Philippine Military Academy (PMA)',
      post_code: 2602,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Sablan',
      post_code: 2614,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Tuba',
      post_code: 2603,
    },
    {
      location: 'Benguet',
      region: 'IV-A',
      municipality: 'Tublay',
      post_code: 2615,
    },
    {
      location: 'Biliran',
      region: 'VIII',
      municipality: 'Almeria',
      post_code: 6544,
    },
    {
      location: 'Biliran',
      region: 'VIII',
      municipality: 'Biliran',
      post_code: 6549,
    },
    {
      location: 'Biliran',
      region: 'VIII',
      municipality: 'Cabucgayan',
      post_code: 6550,
    },
    {
      location: 'Biliran',
      region: 'VIII',
      municipality: 'Caibiran',
      post_code: 6548,
    },
    {
      location: 'Biliran',
      region: 'VIII',
      municipality: 'Culaba',
      post_code: 6547,
    },
    {
      location: 'Biliran',
      region: 'VIII',
      municipality: 'Kawayan',
      post_code: 6545,
    },
    {
      location: 'Biliran',
      region: 'VIII',
      municipality: 'Maripipi',
      post_code: 6546,
    },
    {
      location: 'Biliran',
      region: 'VIII',
      municipality: 'Naval',
      post_code: 6543,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Alburquerque',
      post_code: 6302,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Alicia',
      post_code: 6314,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Anda',
      post_code: 6311,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Antequera',
      post_code: 6335,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Baclayon',
      post_code: 6301,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Balilihan',
      post_code: 6342,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Batuan',
      post_code: 6318,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Bien Unido',
      post_code: 6326,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Bilar',
      post_code: 6317,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Buenavista',
      post_code: 6333,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Calape',
      post_code: 6328,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Candijay',
      post_code: 6312,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Carmen',
      post_code: 6319,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Catigbian',
      post_code: 6343,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Clarin',
      post_code: 6330,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Corella',
      post_code: 6337,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Cortes',
      post_code: 6341,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Dagohoy',
      post_code: 6322,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Danao',
      post_code: 6344,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Dauis',
      post_code: 6339,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Dimiao',
      post_code: 6305,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Duero',
      post_code: 6309,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Garcia Hernandez',
      post_code: 6307,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Guindulman',
      post_code: 6310,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Inabanga',
      post_code: 6332,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Jagna',
      post_code: 6308,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Jetafe',
      post_code: 6334,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Lila',
      post_code: 6304,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Loay',
      post_code: 6303,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Loboc',
      post_code: 6316,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Loon',
      post_code: 6327,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Mabini',
      post_code: 6313,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Maribojoc',
      post_code: 6336,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Panglao',
      post_code: 6340,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Pilar',
      post_code: 6321,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'President Carlos P. Garcia',
      post_code: 6346,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Sagbayan',
      post_code: 6331,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'San Isidro',
      post_code: 6345,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'San Miguel',
      post_code: 6323,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Sevilla',
      post_code: 6347,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Sierra Bullones',
      post_code: 6320,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Sikatuna',
      post_code: 6338,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Tagbilaran City',
      post_code: 6300,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Talibon',
      post_code: 6325,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Trinidad',
      post_code: 6324,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Tubigon',
      post_code: 6329,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Ubay',
      post_code: 6315,
    },
    {
      location: 'Bohol',
      region: 'VII',
      municipality: 'Valencia',
      post_code: 6306,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Baungon',
      post_code: 8707,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Cabanglasan',
      post_code: 8723,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Damulog',
      post_code: 8721,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Dangcagan',
      post_code: 8719,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Don Carlos',
      post_code: 8712,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Impasugong',
      post_code: 8702,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Kadingilan',
      post_code: 8713,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Kalilangan',
      post_code: 8718,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Kibawe',
      post_code: 8720,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Kitaotao',
      post_code: 8716,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Lantapan',
      post_code: 8722,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Libona',
      post_code: 8706,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Malaybalay',
      post_code: 8700,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Malitbog',
      post_code: 8704,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Manolo Fortich',
      post_code: 8703,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Maramag',
      post_code: 8714,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Musuan',
      post_code: 8710,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Pangantucan',
      post_code: 8717,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Phillips',
      post_code: 8705,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Quezon',
      post_code: 8715,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'San Fernando',
      post_code: 8711,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Sumilao',
      post_code: 8701,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Talakag',
      post_code: 8708,
    },
    {
      location: 'Bukidnon',
      region: 'X',
      municipality: 'Valencia',
      post_code: 8709,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Angat',
      post_code: 3012,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Balagtas',
      post_code: 3016,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Baliuag',
      post_code: 3006,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Bocaue',
      post_code: 3018,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Bulacan',
      post_code: 3017,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Bustos',
      post_code: 3007,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Calumpit',
      post_code: 3003,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Cruz na Daan',
      post_code: 3025,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Doña Remedios Trinidad',
      post_code: 3009,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Guiguinto',
      post_code: 3015,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Hagonoy',
      post_code: 3002,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Malolos',
      post_code: 3000,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Marilao',
      post_code: 3019,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Meycauayan',
      post_code: 3020,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Norzagaray',
      post_code: 3013,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Obando',
      post_code: 3021,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Pandi',
      post_code: 3014,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Paombong',
      post_code: 3001,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Plaridel',
      post_code: 3004,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Pulilan',
      post_code: 3005,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'San Ildefonso',
      post_code: 3010,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'San Jose Del Monte',
      post_code: 3023,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'San Miguel',
      post_code: 3011,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'San Rafael',
      post_code: 3008,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Sapang Palay',
      post_code: 3024,
    },
    {
      location: 'Bulacan',
      region: 'III',
      municipality: 'Sta. Maria',
      post_code: 3022,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Abulug',
      post_code: 3517,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Alcala',
      post_code: 3507,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Allacapan',
      post_code: 3523,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Amulung',
      post_code: 3505,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Aparri',
      post_code: 3515,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Baggao',
      post_code: 3506,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Ballesteros',
      post_code: 3516,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Buguey',
      post_code: 3511,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Calayan',
      post_code: 3520,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Camalaniugan',
      post_code: 3510,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Claveria',
      post_code: 3519,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Enrile',
      post_code: 3501,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Gattaran',
      post_code: 3508,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Gonzaga',
      post_code: 3513,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Iguig',
      post_code: 3504,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Lal-lo',
      post_code: 3509,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Lasam',
      post_code: 3524,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Pamplona',
      post_code: 3522,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Peñablanca',
      post_code: 3502,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Piat',
      post_code: 3527,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Rizal',
      post_code: 3526,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Sanchez Mira',
      post_code: 3518,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Solana',
      post_code: 3503,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Sta. Ana',
      post_code: 3514,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Sta. Praxedes',
      post_code: 3521,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Sta. Teresita',
      post_code: 3512,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Sto. Niño',
      post_code: 3525,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Tuao',
      post_code: 3528,
    },
    {
      location: 'Cagayan',
      region: 'II',
      municipality: 'Tuguegarao City',
      post_code: 3500,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Basud',
      post_code: 4608,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Capalonga',
      post_code: 4607,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Daet',
      post_code: 4600,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'San Lorenzo Ruiz (formerly Imelda)',
      post_code: 4610,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Jose Panganiban',
      post_code: 4606,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Labo',
      post_code: 4604,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Mercedes',
      post_code: 4601,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Paracale',
      post_code: 4605,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'San Vicente',
      post_code: 4609,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Sta. Elena',
      post_code: 4611,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Talisay',
      post_code: 4602,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Tulay na Lupa',
      post_code: 4612,
    },
    {
      location: 'Camarines Norte',
      region: 'V',
      municipality: 'Vinzons',
      post_code: 4603,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Baao',
      post_code: 4432,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Balatan',
      post_code: 4436,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Bato',
      post_code: 4435,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Bombon',
      post_code: 4404,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Buhi',
      post_code: 4433,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Bula',
      post_code: 4430,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Cabusao',
      post_code: 4406,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Calabanga',
      post_code: 4405,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Camaligan',
      post_code: 4401,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Canaman',
      post_code: 4402,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Caramoan',
      post_code: 4429,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Del Gallego',
      post_code: 4411,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Gainza',
      post_code: 4412,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Garchitorena',
      post_code: 4428,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Goa',
      post_code: 4422,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Iriga City',
      post_code: 4431,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Lagonoy',
      post_code: 4425,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Libmanan',
      post_code: 4407,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Lupi',
      post_code: 4409,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Magarao',
      post_code: 4403,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Milaor',
      post_code: 4413,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Minalabac',
      post_code: 4414,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Nabua',
      post_code: 4434,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Naga City',
      post_code: 4400,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Ocampo',
      post_code: 4419,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Pamplona',
      post_code: 4416,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Pasacao',
      post_code: 4417,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Pili',
      post_code: 4418,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Presentacion',
      post_code: 4424,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Ragay',
      post_code: 4410,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Sagnay',
      post_code: 4421,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'San Fernando',
      post_code: 4415,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'San Jose',
      post_code: 4423,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Sipocot',
      post_code: 4408,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Siruma',
      post_code: 4427,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Tigaon',
      post_code: 4420,
    },
    {
      location: 'Camarines Sur',
      region: 'V',
      municipality: 'Tinambac',
      post_code: 4426,
    },
    {
      location: 'Camiguin',
      region: 'X',
      municipality: 'Catarman',
      post_code: 9104,
    },
    {
      location: 'Camiguin',
      region: 'X',
      municipality: 'Guinsiliban',
      post_code: 9102,
    },
    {
      location: 'Camiguin',
      region: 'X',
      municipality: 'Mahinog',
      post_code: 9101,
    },
    {
      location: 'Camiguin',
      region: 'X',
      municipality: 'Mambajao',
      post_code: 9100,
    },
    {
      location: 'Camiguin',
      region: 'X',
      municipality: 'Sagay',
      post_code: 9103,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Cuartero',
      post_code: 5811,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Dao',
      post_code: 5810,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Dumalag',
      post_code: 5813,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Dumarao',
      post_code: 5812,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Ivisan',
      post_code: 5805,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Jamindan',
      post_code: 5808,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Ma-ayon',
      post_code: 5809,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Mambusao',
      post_code: 5807,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Panay',
      post_code: 5801,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Panitan',
      post_code: 5815,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Pilar',
      post_code: 5804,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Pres. Roxas',
      post_code: 5803,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Pontevedra',
      post_code: 5802,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Roxas City',
      post_code: 5800,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Sapian',
      post_code: 5806,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Sigma',
      post_code: 5816,
    },
    {
      location: 'Capiz',
      region: 'VI',
      municipality: 'Tapaz',
      post_code: 5814,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'Bagamanoc',
      post_code: 4807,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'Baras',
      post_code: 4803,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'Bato',
      post_code: 4801,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'Caramoran',
      post_code: 4808,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'Gigmoto',
      post_code: 4804,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'Pandan',
      post_code: 4809,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'Panganiban',
      post_code: 4806,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'San Andres',
      post_code: 4810,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'San Miguel',
      post_code: 4802,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'Viga',
      post_code: 4805,
    },
    {
      location: 'Catanduanes',
      region: 'V',
      municipality: 'Virac',
      post_code: 4800,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: '1st Cavite Industrial Estate',
      post_code: 4126,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Alfonso',
      post_code: 4123,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Amadeo',
      post_code: 4119,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Bacoor',
      post_code: 4102,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Carmona',
      post_code: 4116,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Cavite City',
      post_code: 4100,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Cavite Naval Base',
      post_code: 4101,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Corregidor',
      post_code: 4125,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Dasmariñas',
      post_code: 4114,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Dasmariñas Resettlement Area',
      post_code: 4115,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Gen. Aguinaldo (Bailen)',
      post_code: 4124,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Gen. Mariano Alvarez',
      post_code: 4117,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Gen. Trias',
      post_code: 4107,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Imus',
      post_code: 4103,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Indang',
      post_code: 4122,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Kawit',
      post_code: 4104,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Magallanes',
      post_code: 4113,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Maragondon',
      post_code: 4112,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Mendez',
      post_code: 4121,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Molino',
      post_code: 4135,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Naic',
      post_code: 4110,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Noveleta',
      post_code: 4105,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Rosario',
      post_code: 4106,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Silang',
      post_code: 4118,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Tagaytay',
      post_code: 4120,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Tanza',
      post_code: 4108,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Ternate',
      post_code: 4111,
    },
    {
      location: 'Cavite',
      region: 'IV-A',
      municipality: 'Trece Martirez City',
      post_code: 4109,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Alcantara',
      post_code: 6033,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Alcoy',
      post_code: 6023,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Alegria',
      post_code: 6030,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Aloguinsan',
      post_code: 6040,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Argao',
      post_code: 6021,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Asturias',
      post_code: 6042,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Badian',
      post_code: 6031,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Balamban',
      post_code: 6041,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Bantayan',
      post_code: 6052,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Barili',
      post_code: 6036,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Bogo',
      post_code: 6010,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Boljoon',
      post_code: 6024,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Borbon',
      post_code: 6008,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Carcar',
      post_code: 6019,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Carmen',
      post_code: 6005,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Catmon',
      post_code: 6006,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Cebu City',
      post_code: 6000,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Compostela',
      post_code: 6003,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Consolacion',
      post_code: 6001,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Cordova',
      post_code: 6017,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Daang-Bantayan',
      post_code: 6013,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Dalaguete',
      post_code: 6022,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Danao City',
      post_code: 6004,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Dumanjug',
      post_code: 6035,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Ginatilan',
      post_code: 6028,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Lapu-Lapu City',
      post_code: 6015,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Liloan',
      post_code: 6002,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Mactan Airport',
      post_code: 6016,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Madridejos',
      post_code: 6053,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Malabuyoc',
      post_code: 6029,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Mandaue City',
      post_code: 6014,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Medellin',
      post_code: 6012,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Minglanilla',
      post_code: 6046,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Moalboal',
      post_code: 6032,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Naga',
      post_code: 6037,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Oslob',
      post_code: 6025,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Pilar',
      post_code: 6048,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Pinamungajan',
      post_code: 6039,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Poro',
      post_code: 6049,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Ronda',
      post_code: 6034,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Samboan',
      post_code: 6027,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'San Fernando',
      post_code: 6018,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'San Francisco',
      post_code: 6050,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'San Remegio',
      post_code: 6011,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Santa Fe',
      post_code: 6047,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Santander',
      post_code: 6026,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Sibonga',
      post_code: 6020,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Sogod',
      post_code: 6007,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Tabogon',
      post_code: 6009,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Tabuelan',
      post_code: 6044,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Talisay',
      post_code: 6045,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Toledo City',
      post_code: 6038,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Tuburan',
      post_code: 6043,
    },
    {
      location: 'Cebu',
      region: 'VII',
      municipality: 'Tudela',
      post_code: 6051,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'Compostela',
      post_code: 8803,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'Laak (formerly San Vicente)',
      post_code: 8810,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'Mabini',
      post_code: 8807,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'Maco',
      post_code: 8806,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'Maragusan (formerly San Mariano)',
      post_code: 8808,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'Mawab',
      post_code: 8802,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'Monkayo',
      post_code: 8805,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'Montevista',
      post_code: 8801,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'Nabunturan',
      post_code: 8800,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'New Bataan',
      post_code: 8804,
    },
    {
      location: 'Davao de Oro (Compostela Valley)',
      region: 'XI',
      municipality: 'Pantukan',
      post_code: 8809,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Asuncion (formerly Saug)',
      post_code: 8102,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Babak',
      post_code: 8118,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Braulio E. Dujali',
      post_code: 8106,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Carmen',
      post_code: 8101,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Kapalong',
      post_code: 8113,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Kaputian',
      post_code: 8120,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'New Corella',
      post_code: 8104,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Panabo',
      post_code: 8105,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Samal',
      post_code: 8119,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'San Isidro',
      post_code: 8121,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Santo Tomas',
      post_code: 8112,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Tagum',
      post_code: 8100,
    },
    {
      location: 'Davao del Norte',
      region: 'XI',
      municipality: 'Talaingod',
      post_code: 8107,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Ateneo',
      post_code: 8016,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Bansalan',
      post_code: 8005,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Bunawan',
      post_code: 8017,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Calinan',
      post_code: 8018,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Davao City',
      post_code: 8000,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Davao International Airport',
      post_code: 8019,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Digos',
      post_code: 8002,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Hagonoy',
      post_code: 8006,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Kiblawan',
      post_code: 8008,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Magsaysay',
      post_code: 8004,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Malalag',
      post_code: 8010,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Mandug',
      post_code: 8020,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Matanao',
      post_code: 8003,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Matina',
      post_code: 8021,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Mintal',
      post_code: 8022,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Padada',
      post_code: 8007,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Santa Cruz',
      post_code: 8001,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Sulop',
      post_code: 8009,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Talomo',
      post_code: 8023,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Tibungco',
      post_code: 8024,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'Toril',
      post_code: 8025,
    },
    {
      location: 'Davao del Sur',
      region: 'XI',
      municipality: 'University of Mindanao',
      post_code: 8026,
    },
    {
      location: 'Davao Occidental',
      region: 'XI',
      municipality: 'Don Marcelino',
      post_code: 8013,
    },
    {
      location: 'Davao Occidental',
      region: 'XI',
      municipality: 'Jose Abad Santos',
      post_code: 8014,
    },
    {
      location: 'Davao Occidental',
      region: 'XI',
      municipality: 'Malita',
      post_code: 8012,
    },
    {
      location: 'Davao Occidental',
      region: 'XI',
      municipality: 'Santa Maria',
      post_code: 8011,
    },
    {
      location: 'Davao Occidental',
      region: 'XI',
      municipality: 'Sarangani',
      post_code: 8015,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'Baganga',
      post_code: 8204,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'Banaybanay',
      post_code: 8208,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'Boston',
      post_code: 8206,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'Caraga',
      post_code: 8203,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'Cateel',
      post_code: 8205,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'Gov. Generoso',
      post_code: 8210,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'Lupon',
      post_code: 8207,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'Manay',
      post_code: 8202,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'Mati',
      post_code: 8200,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'San Isidro',
      post_code: 8209,
    },
    {
      location: 'Davao Oriental',
      region: 'XI',
      municipality: 'Tarragona',
      post_code: 8201,
    },
    {
      location: 'Dinagat Islands',
      region: 'XIII',
      municipality: 'Basilisa (formerly Rizal)',
      post_code: 8413,
    },
    {
      location: 'Dinagat Islands',
      region: 'XIII',
      municipality: 'Cagdianao',
      post_code: 8411,
    },
    {
      location: 'Dinagat Islands',
      region: 'XIII',
      municipality: 'Dinagat',
      post_code: 8412,
    },
    {
      location: 'Dinagat Islands',
      region: 'XIII',
      municipality: 'Libjo (formerly Albor)',
      post_code: 8414,
    },
    {
      location: 'Dinagat Islands',
      region: 'XIII',
      municipality: 'Loreto',
      post_code: 8415,
    },
    {
      location: 'Dinagat Islands',
      region: 'XIII',
      municipality: 'San Jose',
      post_code: 8427,
    },
    {
      location: 'Dinagat Islands',
      region: 'XIII',
      municipality: 'Tubajon',
      post_code: 8426,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Arteche',
      post_code: 6822,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Balangiga',
      post_code: 6812,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Balangkayan',
      post_code: 6801,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Borongan',
      post_code: 6800,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Can-Avid',
      post_code: 6806,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Dolores',
      post_code: 6817,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Gen. MacArthur',
      post_code: 6805,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Giporlos',
      post_code: 6811,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Guiuan',
      post_code: 6809,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Hernani',
      post_code: 6804,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Jipapad',
      post_code: 6819,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Lawaan',
      post_code: 6813,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Llorente',
      post_code: 6803,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Maslog',
      post_code: 6820,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Maydolong',
      post_code: 6802,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Mercedes',
      post_code: 6808,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Oras',
      post_code: 6818,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Quinapondan',
      post_code: 6810,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Salcedo',
      post_code: 6807,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'San Julian',
      post_code: 6814,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'San Policarpio',
      post_code: 6821,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Sulat',
      post_code: 6815,
    },
    {
      location: 'Eastern Samar',
      region: 'VIII',
      municipality: 'Taft',
      post_code: 6816,
    },
    {
      location: 'Guimaras',
      region: 'VI',
      municipality: 'Buenavista',
      post_code: 5044,
    },
    {
      location: 'Guimaras',
      region: 'VI',
      municipality: 'Jordan',
      post_code: 5045,
    },
    {
      location: 'Guimaras',
      region: 'VI',
      municipality: 'Nueva Valencia',
      post_code: 5046,
    },
    {
      location: 'Guimaras',
      region: 'VI',
      municipality: 'San Lorenzo',
      post_code: 5047,
    },
    {
      location: 'Guimaras',
      region: 'VI',
      municipality: 'Sibunag',
      post_code: 5048,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Aguinaldo',
      post_code: 3606,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Asipulo',
      post_code: 3610,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Banaue',
      post_code: 3601,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Hingyon',
      post_code: 3607,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Hungduan',
      post_code: 3603,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Kiangan',
      post_code: 3604,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Lagawe',
      post_code: 3600,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Lamut',
      post_code: 3605,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Mayoyao',
      post_code: 3602,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Potia',
      post_code: 3608,
    },
    {
      location: 'Ifugao',
      region: 'CAR',
      municipality: 'Tinoc',
      post_code: 3609,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Adams',
      post_code: 2922,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Bacarra',
      post_code: 2916,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Badoc',
      post_code: 2904,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Bangui',
      post_code: 2920,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Batac',
      post_code: 2906,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Burgos',
      post_code: 2918,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Carasi',
      post_code: 2911,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Currimao',
      post_code: 2903,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Dingras',
      post_code: 2913,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Dumalneg',
      post_code: 2921,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Espiritu',
      post_code: 2908,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Laoag City',
      post_code: 2900,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Marcos',
      post_code: 2907,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Nueva Era',
      post_code: 2909,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Pagudpud',
      post_code: 2919,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Paoay',
      post_code: 2902,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Pasuquin',
      post_code: 2917,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Piddig',
      post_code: 2912,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Pinili',
      post_code: 2905,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'San Nicolas',
      post_code: 2901,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Sarrat',
      post_code: 2914,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Solsona',
      post_code: 2910,
    },
    {
      location: 'Ilocos Norte',
      region: 'I',
      municipality: 'Vintar',
      post_code: 2915,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Alilem',
      post_code: 2716,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Banayoyo',
      post_code: 2708,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Bantay',
      post_code: 2727,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Burgos',
      post_code: 2724,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Cabugao',
      post_code: 2732,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Candon',
      post_code: 2710,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Caoayan',
      post_code: 2702,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Cervantes',
      post_code: 2718,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Galimuyod',
      post_code: 2709,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Gregorio del Pilar',
      post_code: 2720,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Lidlida',
      post_code: 2723,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Magsingal',
      post_code: 2730,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Nagbukel',
      post_code: 2725,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Narvacan',
      post_code: 2704,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Quirino',
      post_code: 2721,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Salcedo',
      post_code: 2711,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'San Emilio',
      post_code: 2722,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'San Esteban',
      post_code: 2706,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'San Ildefonso',
      post_code: 2728,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'San Juan',
      post_code: 2731,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'San Vicente',
      post_code: 2726,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Santa',
      post_code: 2703,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Santa Catalina',
      post_code: 2701,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Santa Cruz',
      post_code: 2713,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Santa Lucia',
      post_code: 2712,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Santa Maria',
      post_code: 2705,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Santiago',
      post_code: 2707,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Santo Domingo',
      post_code: 2729,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Sigay',
      post_code: 2719,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Sinait',
      post_code: 2733,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Sugpon',
      post_code: 2717,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Suyo',
      post_code: 2715,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Tagudin',
      post_code: 2714,
    },
    {
      location: 'Ilocos Sur',
      region: 'I',
      municipality: 'Vigan',
      post_code: 2700,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Ajuy',
      post_code: 5012,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Alimodian',
      post_code: 5028,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Anilao',
      post_code: 5009,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Badiangan',
      post_code: 5033,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Balasan',
      post_code: 5018,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Banate',
      post_code: 5010,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Barotac Nuevo',
      post_code: 5007,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Barotac Viejo',
      post_code: 5011,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Batad',
      post_code: 5016,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Bingawan',
      post_code: 5041,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Cabatuan',
      post_code: 5031,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Calinog',
      post_code: 5040,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Carles',
      post_code: 5019,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Concepcion',
      post_code: 5013,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Dingle',
      post_code: 5035,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Dueñas',
      post_code: 5038,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Dumangas',
      post_code: 5006,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Estancia',
      post_code: 5017,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Guimbal',
      post_code: 5022,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Igbaras',
      post_code: 5029,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Iloilo City',
      post_code: 5000,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Janiuay',
      post_code: 5034,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Lambunao',
      post_code: 5042,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Leganes',
      post_code: 5003,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Lemery',
      post_code: 5043,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Leon',
      post_code: 5026,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Maasin',
      post_code: 5030,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Miagao',
      post_code: 5023,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Mina',
      post_code: 5032,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'New Lucena',
      post_code: 5005,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Oton',
      post_code: 5020,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Passi',
      post_code: 5037,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Pavia',
      post_code: 5001,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Pototan',
      post_code: 5008,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'San Dionisio',
      post_code: 5015,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'San Enrique',
      post_code: 5036,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'San Joaquin',
      post_code: 5024,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'San Miguel',
      post_code: 5025,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'San Rafael',
      post_code: 5039,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Santa Barbara',
      post_code: 5002,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Sara',
      post_code: 5014,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Tigbauan',
      post_code: 5021,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Tubungan',
      post_code: 5027,
    },
    {
      location: 'Iloilo',
      region: 'VI',
      municipality: 'Zarraga',
      post_code: 5004,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Alicia',
      post_code: 3306,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Angadanan',
      post_code: 3307,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Aurora',
      post_code: 3316,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Benito Soliven',
      post_code: 3331,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Burgos',
      post_code: 3322,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Cabagan',
      post_code: 3328,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Cabatuan',
      post_code: 3315,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'City of Cauayan',
      post_code: 3305,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'City of Ilagan',
      post_code: 3300,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Cordon',
      post_code: 3312,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Delfin Albano',
      post_code: 3326,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Dinapigue',
      post_code: 3336,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Divilacan',
      post_code: 3335,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Echague',
      post_code: 3309,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Gamu',
      post_code: 3301,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Jones',
      post_code: 3313,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Luna',
      post_code: 3304,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Maconacon',
      post_code: 3333,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Mallig',
      post_code: 3323,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Naguillan',
      post_code: 3302,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Palanan',
      post_code: 3334,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Quezon',
      post_code: 3324,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Quirino',
      post_code: 3321,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Ramon',
      post_code: 3319,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Reina Mercedes',
      post_code: 3303,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Roxas',
      post_code: 3320,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'San Agustin',
      post_code: 3314,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'San Guillermo',
      post_code: 3308,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'San Isidro',
      post_code: 3310,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'San Manuel',
      post_code: 3317,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'San Mariano',
      post_code: 3332,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'San Mateo',
      post_code: 3318,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'San Pablo',
      post_code: 3329,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Santiago CIty',
      post_code: 3311,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Sta. Maria',
      post_code: 3330,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Sto. Tomas',
      post_code: 3327,
    },
    {
      location: 'Isabela',
      region: 'II',
      municipality: 'Tumauini',
      post_code: 3325,
    },
    {
      location: 'Kalinga',
      region: 'II',
      municipality: 'Balbalan',
      post_code: 3801,
    },
    {
      location: 'Kalinga',
      region: 'II',
      municipality: 'Lubuagan',
      post_code: 3802,
    },
    {
      location: 'Kalinga',
      region: 'II',
      municipality: 'Pasil',
      post_code: 3803,
    },
    {
      location: 'Kalinga',
      region: 'II',
      municipality: 'Pinukpok',
      post_code: 3806,
    },
    {
      location: 'Kalinga',
      region: 'II',
      municipality: 'Rizal',
      post_code: 3808,
    },
    {
      location: 'Kalinga',
      region: 'II',
      municipality: 'Tabuk City',
      post_code: 3800,
    },
    {
      location: 'Kalinga',
      region: 'II',
      municipality: 'Tanudan',
      post_code: 3805,
    },
    {
      location: 'Kalinga',
      region: 'II',
      municipality: 'Tinglayan',
      post_code: 3804,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Agoo',
      post_code: 2504,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Aringay',
      post_code: 2503,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Bacnotan',
      post_code: 2515,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Bagulin',
      post_code: 2512,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Balaoan',
      post_code: 2517,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Bangar',
      post_code: 2519,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Bauang',
      post_code: 2501,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Burgos',
      post_code: 2510,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Caba',
      post_code: 2502,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Damortis',
      post_code: 2507,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Luna',
      post_code: 2518,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Naguilian',
      post_code: 2511,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Pugo',
      post_code: 2508,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Rosario',
      post_code: 2506,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'San Fernando',
      post_code: 2500,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'San Gabriel',
      post_code: 2513,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'San Juan',
      post_code: 2514,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Santol',
      post_code: 2516,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Sto. Tomas',
      post_code: 2505,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Sudipen',
      post_code: 2520,
    },
    {
      location: 'La Union',
      region: 'I',
      municipality: 'Tubao',
      post_code: 2509,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Alaminos',
      post_code: 4001,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Bay',
      post_code: 4033,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Biñan',
      post_code: 4024,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Botocan',
      post_code: 4006,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Cabuyao',
      post_code: 4025,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Calamba',
      post_code: 4027,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Caluan',
      post_code: 4012,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Camp Vicente Lim',
      post_code: 4029,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Canlubang',
      post_code: 4028,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Cavinti',
      post_code: 4013,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'College Los Baños',
      post_code: 4031,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Famy',
      post_code: 4021,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Kalayaan',
      post_code: 4015,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Laguna Technopark',
      post_code: 4034,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Liliw',
      post_code: 4004,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Los Baños',
      post_code: 4030,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Luisiana',
      post_code: 4032,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Lumban',
      post_code: 4014,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Mabitac',
      post_code: 4020,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Magdalena',
      post_code: 4007,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Majayjay',
      post_code: 4005,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Nagcarlan',
      post_code: 4002,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Paete',
      post_code: 4016,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Pagsanjan',
      post_code: 4008,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Pakil',
      post_code: 4017,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Pangil',
      post_code: 4018,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Pila',
      post_code: 4010,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Rizal',
      post_code: 4003,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'San Pablo City',
      post_code: 4000,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'San Pedro',
      post_code: 4023,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Siniloan',
      post_code: 4019,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Sta. Cruz',
      post_code: 4009,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Sta. Maria',
      post_code: 4022,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Sta. Rosa',
      post_code: 4026,
    },
    {
      location: 'Laguna',
      region: 'IV-A',
      municipality: 'Victoria',
      post_code: 4011,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Bacolod',
      post_code: 9205,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Baloi',
      post_code: 9217,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Baroy',
      post_code: 9210,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Iligan City',
      post_code: 9200,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Kapatagan',
      post_code: 9214,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Kauswagan',
      post_code: 9202,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Kolambugan',
      post_code: 9207,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Lala',
      post_code: 9211,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Linamon',
      post_code: 9201,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Magsaysay',
      post_code: 9221,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Maigo',
      post_code: 9206,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Matungao',
      post_code: 9203,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Munai',
      post_code: 9219,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Nunungan',
      post_code: 9216,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Pantao Ragat',
      post_code: 9208,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Pantar',
      post_code: 9218,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Poona Piagapo',
      post_code: 9204,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Salvador',
      post_code: 9212,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Sapad',
      post_code: 9213,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Sultan Naga Dimaporo (formerly Karomatan)',
      post_code: 9215,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Tagoloan',
      post_code: 9222,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Tangcal',
      post_code: 9220,
    },
    {
      location: 'Lanao del Norte',
      region: 'X',
      municipality: 'Tubod',
      post_code: 9209,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Bacolod Grande',
      post_code: 9316,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Balabagan',
      post_code: 9302,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Bayang',
      post_code: 9309,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Balindong',
      post_code: 9318,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Binidayan',
      post_code: 9310,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Buadiposo Buntong',
      post_code: 9714,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Bubong',
      post_code: 9708,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Bumbaran',
      post_code: 9320,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Butig',
      post_code: 9305,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Calanogas',
      post_code: 9319,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Ganassi',
      post_code: 9311,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Kapai',
      post_code: 9709,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Kapatagan',
      post_code: 9322,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Lumba Bayabao',
      post_code: 9703,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Lumbaca Unayan',
      post_code: 9323,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Lumbatan',
      post_code: 9307,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Lumbayanague',
      post_code: 9306,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Macador Andong',
      post_code: 9308,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Madalum',
      post_code: 9315,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Madamba',
      post_code: 9314,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Maguing',
      post_code: 9715,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Malabang',
      post_code: 9300,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Marantao',
      post_code: 9711,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Marawi City',
      post_code: 9700,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Marogong',
      post_code: 9303,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Masiu',
      post_code: 9706,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Mulondo',
      post_code: 9702,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Pagayawan',
      post_code: 9312,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Piagapo',
      post_code: 9710,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Picong (formerly Sultan Gumander)',
      post_code: 9301,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Poona Bayabao',
      post_code: 9705,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Pualas',
      post_code: 9313,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Ramain-Ditsaan',
      post_code: 9713,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Saguiaran',
      post_code: 9701,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Sultan Domalondong',
      post_code: 9324,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Sultan Gumander',
      post_code: 9301,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Tagoloan - II',
      post_code: 9321,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Tamparan',
      post_code: 9704,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Taraka',
      post_code: 9712,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Tubaran',
      post_code: 9304,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Tugaya',
      post_code: 9317,
    },
    {
      location: 'Lanao del Sur',
      region: 'XII',
      municipality: 'Wao',
      post_code: 9716,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Abuyog',
      post_code: 6510,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Alangalang',
      post_code: 6517,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Albuera',
      post_code: 6542,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Babatngon',
      post_code: 6520,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Burauen',
      post_code: 6516,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Barugo',
      post_code: 6519,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Bato',
      post_code: 6525,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Baybay',
      post_code: 6521,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Calubian',
      post_code: 6534,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Capoocan',
      post_code: 6530,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Carigara',
      post_code: 6529,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Dagami',
      post_code: 6515,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Dulag',
      post_code: 6505,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Hilongos',
      post_code: 6524,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Hindang',
      post_code: 6523,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Inopacan',
      post_code: 6522,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Isabel',
      post_code: 6539,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Jaro',
      post_code: 6527,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Javier',
      post_code: 6511,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Julita',
      post_code: 6506,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Kananga',
      post_code: 6531,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'La Paz',
      post_code: 6508,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Leyte',
      post_code: 6533,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Macarthur',
      post_code: 6509,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Mahaplag',
      post_code: 6512,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Matag-ob',
      post_code: 6532,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Matalom',
      post_code: 6526,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Mayorga',
      post_code: 6507,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Merida',
      post_code: 6540,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Ormoc City',
      post_code: 6541,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Palo',
      post_code: 6501,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Palompon',
      post_code: 6538,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Pastrana',
      post_code: 6514,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'San Isidro',
      post_code: 6535,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'San Miguel',
      post_code: 6518,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Sta. Fe',
      post_code: 6513,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Tabango',
      post_code: 6536,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Tabontabon',
      post_code: 6504,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Tacloban City',
      post_code: 6500,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Tanauan',
      post_code: 6502,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Tolosa',
      post_code: 6503,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Tunga',
      post_code: 6528,
    },
    {
      location: 'Leyte',
      region: 'VIII',
      municipality: 'Villaba',
      post_code: 6537,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Ampatuan',
      post_code: 9609,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Barira',
      post_code: 9614,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Buldon',
      post_code: 9615,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Buluan',
      post_code: 9616,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Cotabato City',
      post_code: 9600,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Odin Sinsuat (Dinaig)',
      post_code: 9601,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Paglas',
      post_code: 9617,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Piang',
      post_code: 9607,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Abdullah Sangki',
      post_code: 9621,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Anggal Midtimbang',
      post_code: 9622,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Blah T. Sinsuat',
      post_code: 9623,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Hoffer Ampatuan',
      post_code: 9624,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Montawal (formerly Pagagawan)',
      post_code: 9631,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Salibo',
      post_code: 9625,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Saudi-Ampatuan',
      post_code: 9626,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Datu Unsay',
      post_code: 9627,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'General Salipada K. Pendatun',
      post_code: 9618,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Guindulungan',
      post_code: 9628,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Kabuntulan',
      post_code: 9606,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Shariff Aguak (formerly Maganoy)',
      post_code: 9608,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Mamasapano',
      post_code: 9629,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Mangudadatu',
      post_code: 9620,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Matanog',
      post_code: 9613,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Northern Kabuntalan',
      post_code: 9630,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Paglat',
      post_code: 9632,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Pandag',
      post_code: 9633,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Pagalungan',
      post_code: 9610,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Parang',
      post_code: 9604,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Rajah Buayan',
      post_code: 9634,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Shariff Saydona Mustapha',
      post_code: 9635,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Sultan Mastura',
      post_code: 9636,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Sultan Sumagka (formerly Talitay)',
      post_code: 9637,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'South Upi',
      post_code: 9603,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Sultan Kudarat',
      post_code: 9605,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Sultan Sa Barongis',
      post_code: 9611,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Talayan',
      post_code: 9612,
    },
    {
      location: 'Maguindanao',
      region: 'XII',
      municipality: 'Upi',
      post_code: 9602,
    },
    {
      location: 'Marinduque',
      region: 'IV-B',
      municipality: 'Boac',
      post_code: 4900,
    },
    {
      location: 'Marinduque',
      region: 'IV-B',
      municipality: 'Buenavista',
      post_code: 4904,
    },
    {
      location: 'Marinduque',
      region: 'IV-B',
      municipality: 'Gasan',
      post_code: 4905,
    },
    {
      location: 'Marinduque',
      region: 'IV-B',
      municipality: 'Mogpog',
      post_code: 4901,
    },
    {
      location: 'Marinduque',
      region: 'IV-B',
      municipality: 'Sta. Cruz',
      post_code: 4902,
    },
    {
      location: 'Marinduque',
      region: 'IV-B',
      municipality: 'Torrijos',
      post_code: 4903,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Aroroy',
      post_code: 5414,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Baleno',
      post_code: 5413,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Balud',
      post_code: 5412,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Batuan',
      post_code: 5415,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Buenavista',
      post_code: 5421,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Cataingan',
      post_code: 5405,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Cawayan',
      post_code: 5409,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Claveria',
      post_code: 5419,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Dimasalang',
      post_code: 5403,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Esperanza',
      post_code: 5407,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Mandaon',
      post_code: 5411,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Masbate',
      post_code: 5400,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Milagros',
      post_code: 5410,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Mobo',
      post_code: 5401,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Monreal',
      post_code: 5418,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Palanas',
      post_code: 5404,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Pio V. Corpuz',
      post_code: 5406,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Placer',
      post_code: 5408,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'San Fernando',
      post_code: 5416,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'San Jacinto',
      post_code: 5417,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'San Pascual',
      post_code: 5420,
    },
    {
      location: 'Masbate',
      region: 'V',
      municipality: 'Uson',
      post_code: 5402,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Aloran',
      post_code: 7206,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Baliangao',
      post_code: 7211,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Bonifacio',
      post_code: 7215,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Calamba',
      post_code: 7210,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Clarin',
      post_code: 7201,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Concepcion',
      post_code: 7213,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Don Victoriano Chiongbian',
      post_code: 7216,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Jimenez',
      post_code: 7204,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Lopez Jaena',
      post_code: 7208,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Oroquieta City',
      post_code: 7207,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Ozamis City',
      post_code: 7200,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Panaon',
      post_code: 7205,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Plaridel',
      post_code: 7209,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Sapang dalaga',
      post_code: 7212,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Sinacaban',
      post_code: 7203,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Tangub City',
      post_code: 7214,
    },
    {
      location: 'Misamis Occidental',
      region: 'X',
      municipality: 'Tudela',
      post_code: 7202,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Alubijid',
      post_code: 9018,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Balingasag',
      post_code: 9005,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Balingoan',
      post_code: 9011,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Binuangan',
      post_code: 9008,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Cagayan de Oro City',
      post_code: 9000,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Claveria',
      post_code: 9004,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'El Salvador',
      post_code: 9017,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Gingoog City',
      post_code: 9014,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Gitagum',
      post_code: 9020,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Initao',
      post_code: 9022,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Jasaan',
      post_code: 9003,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Kinoguitan',
      post_code: 9010,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Laguindingan',
      post_code: 9019,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Lagonglong',
      post_code: 9006,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Libertad',
      post_code: 9021,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Lugait',
      post_code: 9025,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Magsaysay',
      post_code: 9015,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Manticao',
      post_code: 9024,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Medina',
      post_code: 9013,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Naawan',
      post_code: 9023,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Opol',
      post_code: 9016,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Salay',
      post_code: 9007,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Sugbongcogon',
      post_code: 9009,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Tagoloan',
      post_code: 9001,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Talisayan',
      post_code: 9012,
    },
    {
      location: 'Misamis Oriental',
      region: 'X',
      municipality: 'Villanueva',
      post_code: 9002,
    },
    {
      location: 'Mountain Province',
      region: 'CAR',
      municipality: 'Barlig',
      post_code: 2623,
    },
    {
      location: 'Mountain Province',
      region: 'CAR',
      municipality: 'Baukod',
      post_code: 2621,
    },
    {
      location: 'Mountain Province',
      region: 'CAR',
      municipality: 'Besao',
      post_code: 2618,
    },
    {
      location: 'Mountain Province',
      region: 'CAR',
      municipality: 'Bontoc',
      post_code: 2616,
    },
    {
      location: 'Mountain Province',
      region: 'CAR',
      municipality: 'Natonin',
      post_code: 2624,
    },
    {
      location: 'Mountain Province',
      region: 'CAR',
      municipality: 'Paracelis',
      post_code: 2625,
    },
    {
      location: 'Mountain Province',
      region: 'CAR',
      municipality: 'Sabangan',
      post_code: 2622,
    },
    {
      location: 'Mountain Province',
      region: 'CAR',
      municipality: 'Sadanga',
      post_code: 2617,
    },
    {
      location: 'Mountain Province',
      region: 'CAR',
      municipality: 'Sagada',
      post_code: 2619,
    },
    {
      location: 'Mountain Province',
      region: 'CAR',
      municipality: 'Tadian',
      post_code: 2620,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Bacolod City',
      post_code: 6100,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Bago City',
      post_code: 6101,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Binalbagan',
      post_code: 6107,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Cadiz City',
      post_code: 6121,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Calatrava',
      post_code: 6126,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Candoni',
      post_code: 6110,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Cauayan',
      post_code: 6112,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Don Salvador Benedicto',
      post_code: 6133,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Enrique Magalona',
      post_code: 6118,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Escalante',
      post_code: 6124,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Himamaylan',
      post_code: 6108,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Hinigaran',
      post_code: 6106,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Hinoba-an',
      post_code: 6114,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Ilog',
      post_code: 6109,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Isabela',
      post_code: 6128,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Kabankalan',
      post_code: 6111,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'La Carlota City',
      post_code: 6130,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'La Castellana',
      post_code: 6131,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Manapla',
      post_code: 6120,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Moises Padilla',
      post_code: 6132,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Murcia',
      post_code: 6129,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Paraiso (Fabrica)',
      post_code: 6123,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Pontevedra',
      post_code: 6105,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Pulupandan',
      post_code: 6102,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Sagay',
      post_code: 6122,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'San Carlos City',
      post_code: 6127,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'San Enrique',
      post_code: 6104,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Silay City',
      post_code: 6116,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Silay Hawaiian Central',
      post_code: 6117,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Sipalay',
      post_code: 6113,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Talisay',
      post_code: 6115,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Toboso',
      post_code: 6125,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Valladolid',
      post_code: 6103,
    },
    {
      location: 'Negros Occidental',
      region: 'VI',
      municipality: 'Victorias',
      post_code: 6119,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Amlan',
      post_code: 6203,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Ayungon',
      post_code: 6210,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Bacung',
      post_code: 6216,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Bais City',
      post_code: 6206,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Basay',
      post_code: 6222,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Bayawan',
      post_code: 6221,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Bindoy',
      post_code: 6209,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Canlaon City',
      post_code: 6223,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Dauin',
      post_code: 6217,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Dumaguete City',
      post_code: 6200,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Guihulngan',
      post_code: 6214,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Jimalalud',
      post_code: 6212,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'La Libertad',
      post_code: 6213,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Mabinay',
      post_code: 6207,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Manjuyod',
      post_code: 6208,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Pamplona',
      post_code: 6205,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'San Jose',
      post_code: 6202,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Siaton',
      post_code: 6219,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Sibulan',
      post_code: 6201,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Sta. Catalina',
      post_code: 6220,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Tanjay',
      post_code: 6204,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Tayasan',
      post_code: 6211,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Valencia',
      post_code: 6215,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Valle hermoso',
      post_code: 6224,
    },
    {
      location: 'Negros Oriental',
      region: 'VII',
      municipality: 'Zamboanguita',
      post_code: 6218,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Alamada',
      post_code: 9413,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Aleosan',
      post_code: 9415,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Antipas',
      post_code: 9414,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Arakan',
      post_code: 9417,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Banisilan',
      post_code: 9416,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Carmen',
      post_code: 9408,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Kabacan',
      post_code: 9407,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Kidapawan',
      post_code: 9400,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Libungan',
      post_code: 9411,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Magpet',
      post_code: 9404,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Makilala',
      post_code: 9401,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Matalam',
      post_code: 9406,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Midsayap',
      post_code: 9410,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: "M'lang",
      post_code: 9402,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Pigkawayan',
      post_code: 9412,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Pikit',
      post_code: 9409,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Pre. Roxas',
      post_code: 9405,
    },
    {
      location: 'North Cotabato',
      region: 'XII',
      municipality: 'Tulunan',
      post_code: 9403,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Allen',
      post_code: 6405,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Biri',
      post_code: 6410,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Bobon',
      post_code: 6401,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Capul',
      post_code: 6408,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Catarman',
      post_code: 6400,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Catubig',
      post_code: 6418,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Gamay',
      post_code: 6422,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Las Navas',
      post_code: 6420,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Laoang',
      post_code: 6411,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Lapineg',
      post_code: 6423,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Lavezares',
      post_code: 6404,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Lope de Vega',
      post_code: 6403,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Mapanas',
      post_code: 6412,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Mondragon',
      post_code: 6417,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Palapag',
      post_code: 6421,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Pambujan',
      post_code: 6413,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Rosario',
      post_code: 6416,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'San Antonio',
      post_code: 6407,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'San Isidro',
      post_code: 6409,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'San Jose',
      post_code: 6402,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'San Roque',
      post_code: 6415,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'San Vicente',
      post_code: 6419,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Silvino Lobos',
      post_code: 6414,
    },
    {
      location: 'Northern Samar',
      region: 'VIII',
      municipality: 'Victoria',
      post_code: 6406,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Aliaga',
      post_code: 3111,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Bongabon',
      post_code: 3128,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Cabanatuan City',
      post_code: 3100,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Cabiao',
      post_code: 3107,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Carrangalan',
      post_code: 3123,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Central Luzon State University',
      post_code: 3120,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Cuyapo',
      post_code: 3117,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Fort Magsaysay',
      post_code: 3130,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Gabaldon',
      post_code: 3131,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Gapan City',
      post_code: 3105,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Gen. M. Natividad',
      post_code: 3125,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Gen. Tinio',
      post_code: 3104,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Guimba',
      post_code: 3115,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Jaen',
      post_code: 3109,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Laur',
      post_code: 3129,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Licab',
      post_code: 3112,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Llanera',
      post_code: 3126,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Lupao',
      post_code: 3122,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Muñoz',
      post_code: 3119,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Nampicuan',
      post_code: 3116,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Palayan City',
      post_code: 3132,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Pantabangan',
      post_code: 3124,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Peñaranda',
      post_code: 3103,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Quezon',
      post_code: 3113,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Rizal',
      post_code: 3127,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'San Antonio',
      post_code: 3108,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'San Isidro',
      post_code: 3106,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'San Jose City',
      post_code: 3121,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'San Leonardo',
      post_code: 3102,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Sta. Rosa',
      post_code: 3101,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Sto. Domingo',
      post_code: 3133,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Talavera',
      post_code: 3114,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Talugtog',
      post_code: 3118,
    },
    {
      location: 'Nueva Ecija',
      region: 'III',
      municipality: 'Zaragoza',
      post_code: 3110,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Alfonso Castañeda',
      post_code: 3714,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Ambaguio',
      post_code: 3701,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Aritao',
      post_code: 3704,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Bagabag',
      post_code: 3711,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Bambang',
      post_code: 3702,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Bayombong',
      post_code: 3700,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Diadi',
      post_code: 3712,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Dupax del Norte',
      post_code: 3706,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Dupax del Sur',
      post_code: 3707,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Kasibu',
      post_code: 3703,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Kayapa',
      post_code: 3708,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Quezon',
      post_code: 3713,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Solano',
      post_code: 3709,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Sta. Fe',
      post_code: 3705,
    },
    {
      location: 'Nueva Vizcaya',
      region: 'II',
      municipality: 'Villaverde',
      post_code: 3710,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Abra de Ilog',
      post_code: 5108,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Calintaan',
      post_code: 5102,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Looc',
      post_code: 5111,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Lubang',
      post_code: 5109,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Magsaysay',
      post_code: 5101,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Mamburao',
      post_code: 5106,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Palauan',
      post_code: 5107,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Rizal',
      post_code: 5103,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Sablayan',
      post_code: 5104,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'San Jose',
      post_code: 5100,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Sta. Cruz',
      post_code: 5105,
    },
    {
      location: 'Occidental Mindoro',
      region: 'IV-B',
      municipality: 'Tilik',
      post_code: 5110,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Baco',
      post_code: 5201,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Bansud',
      post_code: 5210,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Bongabong',
      post_code: 5211,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Bulalacao',
      post_code: 5214,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Calapan',
      post_code: 5200,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Gloria',
      post_code: 5209,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Mansalay',
      post_code: 5213,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Naujan',
      post_code: 5204,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Pinamalayan',
      post_code: 5208,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Pola',
      post_code: 5206,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Puerto Galera',
      post_code: 5203,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Roxas',
      post_code: 5212,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'San Teodoro',
      post_code: 5202,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Socorro',
      post_code: 5207,
    },
    {
      location: 'Oriental Mindoro',
      region: 'IV-B',
      municipality: 'Victoria',
      post_code: 5205,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Aborlan',
      post_code: 5302,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Agutaya',
      post_code: 5320,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Araceli',
      post_code: 5311,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Balabac',
      post_code: 5307,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Batazara',
      post_code: 5306,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: "Brooke's Point",
      post_code: 5305,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Busuanga',
      post_code: 5317,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Cagayancillo',
      post_code: 5321,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Coron',
      post_code: 5316,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Culion',
      post_code: 5315,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Cuyo',
      post_code: 5318,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Dumaran',
      post_code: 5310,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'El Nido (Baquit)',
      post_code: 5313,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Iwahig Penal Colony',
      post_code: 5301,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Kalayaan',
      post_code: 5322,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Linapacan',
      post_code: 5314,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Magsaysay',
      post_code: 5319,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Narra (Panacan)',
      post_code: 5303,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Puerto Princesa City',
      post_code: 5300,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Quezon',
      post_code: 5304,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Roxas',
      post_code: 5308,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Rizal (Marcos)',
      post_code: 5323,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'San Vicente',
      post_code: 5309,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Sofronio Española',
      post_code: 5324,
    },
    {
      location: 'Palawan',
      region: 'IV-B',
      municipality: 'Taytay',
      post_code: 5312,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Angeles City',
      post_code: 2009,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Apalit',
      post_code: 2016,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Arayat',
      post_code: 2012,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Bacolor',
      post_code: 2001,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Balibago',
      post_code: 2024,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Basa airbase',
      post_code: 2007,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Candaba',
      post_code: 2013,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'CSEZ, Clark',
      post_code: 2023,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Dau, Mabalacat',
      post_code: 2026,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Floridablanca',
      post_code: 2006,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Guagua',
      post_code: 2003,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Lubao',
      post_code: 2005,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Mabalacat',
      post_code: 2010,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Macabebe',
      post_code: 2018,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Magalang',
      post_code: 2011,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Masantol',
      post_code: 2017,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Mexico',
      post_code: 2021,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Minalin',
      post_code: 2019,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Porac',
      post_code: 2008,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'San Fernando',
      post_code: 2000,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'San Luis',
      post_code: 2014,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'San simon',
      post_code: 2015,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Sexmoan (Sasmuan)',
      post_code: 2004,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Sta. Ana',
      post_code: 2022,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Sta. Cruz, Lubao',
      post_code: 2025,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Sta. Rita',
      post_code: 2002,
    },
    {
      location: 'Pampanga',
      region: 'III',
      municipality: 'Sto. Tomas',
      post_code: 2020,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Agno',
      post_code: 2408,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Aguilar',
      post_code: 2415,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Alaminos',
      post_code: 2404,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Alcala',
      post_code: 2425,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Anda',
      post_code: 2405,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Asingan',
      post_code: 2439,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Balungao',
      post_code: 2442,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Bani',
      post_code: 2407,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Basista',
      post_code: 2422,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Bautista',
      post_code: 2424,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Bayambang',
      post_code: 2423,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Binalonan',
      post_code: 2436,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Binmaley',
      post_code: 2417,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Bolinao',
      post_code: 2406,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Bugallon',
      post_code: 2416,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Burgos',
      post_code: 2410,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Calasiao',
      post_code: 2418,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Dagupan City',
      post_code: 2400,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Dasol',
      post_code: 2411,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Infanta',
      post_code: 2412,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Labrador',
      post_code: 2402,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Laoac',
      post_code: 2437,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Lingayen',
      post_code: 2401,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Mabini',
      post_code: 2409,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Malasiqui',
      post_code: 2421,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Manaoag',
      post_code: 2430,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Mangaldan',
      post_code: 2432,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Mangatarem',
      post_code: 2413,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Mapandan',
      post_code: 2429,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Natividad',
      post_code: 2446,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Pozorrubio',
      post_code: 2435,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Rosales',
      post_code: 2441,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'San Carlos City',
      post_code: 2420,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'San Fabian',
      post_code: 2433,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'San Jacinto',
      post_code: 2431,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'San Manuel',
      post_code: 2438,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'San Nicolas',
      post_code: 2447,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'San Quintin',
      post_code: 2444,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Sison',
      post_code: 2434,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Sta. Barbara',
      post_code: 2419,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Sta. Maria',
      post_code: 2440,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Sto. Tomas',
      post_code: 2426,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Sual',
      post_code: 2403,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Tayug',
      post_code: 2445,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Umingan',
      post_code: 2443,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Urbiztondo',
      post_code: 2414,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Urdaneta',
      post_code: 2428,
    },
    {
      location: 'Pangasinan',
      region: 'I',
      municipality: 'Villasis',
      post_code: 2427,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Agdangan',
      post_code: 4304,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Alabat',
      post_code: 4333,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Atimonan',
      post_code: 4331,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Buenavista',
      post_code: 4320,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Burdeos',
      post_code: 4340,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Calauag',
      post_code: 4318,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Candelaria',
      post_code: 4323,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Catanauan',
      post_code: 4311,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Dolores',
      post_code: 4326,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'General Luna',
      post_code: 4310,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'General Nakar',
      post_code: 4338,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Guinayangan',
      post_code: 4319,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Gumaca',
      post_code: 4307,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Hondagua',
      post_code: 4317,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Infanta',
      post_code: 4336,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Jomalig',
      post_code: 4342,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Lopez',
      post_code: 4316,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Lucban',
      post_code: 4328,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Lucena City',
      post_code: 4301,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Macalelon',
      post_code: 4309,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Mauban',
      post_code: 4330,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Mulanay',
      post_code: 4312,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Padre Burgos',
      post_code: 4303,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Pagbilao',
      post_code: 4302,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Panukulan',
      post_code: 4337,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Patnanungan',
      post_code: 4341,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Perez',
      post_code: 4334,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Pitogo',
      post_code: 4308,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Plaridel',
      post_code: 4306,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Polilio',
      post_code: 4339,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Quezon',
      post_code: 4332,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Quezon Capitol',
      post_code: 4300,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Real',
      post_code: 4335,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Sampaloc',
      post_code: 4329,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'San Andres',
      post_code: 4314,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'San Antonio',
      post_code: 4324,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'San Francisco',
      post_code: 4315,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'San Narciso',
      post_code: 4313,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Sariaya',
      post_code: 4322,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Tagkawayan',
      post_code: 4321,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Tayabas',
      post_code: 4327,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Tiaong',
      post_code: 4325,
    },
    {
      location: 'Quezon Province',
      region: 'IV-A',
      municipality: 'Unisan',
      post_code: 4305,
    },
    {
      location: 'Quirino',
      region: 'II',
      municipality: 'Aglipay',
      post_code: 3403,
    },
    {
      location: 'Quirino',
      region: 'II',
      municipality: 'Cabarroguis',
      post_code: 3400,
    },
    {
      location: 'Quirino',
      region: 'II',
      municipality: 'Diffun',
      post_code: 3401,
    },
    {
      location: 'Quirino',
      region: 'II',
      municipality: 'Maddela',
      post_code: 3404,
    },
    {
      location: 'Quirino',
      region: 'II',
      municipality: 'Nagtipunan',
      post_code: 3405,
    },
    {
      location: 'Quirino',
      region: 'II',
      municipality: 'Saguday',
      post_code: 3402,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Angono',
      post_code: 1930,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Antipolo',
      post_code: 1870,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Bagong Nayon (Cogeo)',
      post_code: 1872,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Baras',
      post_code: 1970,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Binangonan',
      post_code: 1940,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Cainta',
      post_code: 1900,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Cardona',
      post_code: 1950,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Cupang',
      post_code: 1873,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Jala-Jala',
      post_code: 1990,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Langhaya',
      post_code: 1874,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Mambagat',
      post_code: 1875,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Mayamot',
      post_code: 1871,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Montalban (Rodriguez)',
      post_code: 1860,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Morong',
      post_code: 1960,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Pilillia',
      post_code: 1910,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'San Mateo',
      post_code: 1850,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Tanay',
      post_code: 1980,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Taytay',
      post_code: 1920,
    },
    {
      location: 'Rizal Province',
      region: 'IV-A',
      municipality: 'Teresa',
      post_code: 1880,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Alcantara',
      post_code: 5509,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Banton (Jones)',
      post_code: 5515,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Cajidiocan',
      post_code: 5512,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Calatrava',
      post_code: 5503,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Concepcion',
      post_code: 5516,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Corcuera',
      post_code: 5514,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Ferrol',
      post_code: 5506,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Sta. Maria (formerly Imelda)',
      post_code: 5502,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Looc',
      post_code: 5507,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Magdiwang',
      post_code: 5511,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Odiongan',
      post_code: 5505,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Romblon',
      post_code: 5500,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'San Agustin',
      post_code: 5501,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'San Andres',
      post_code: 5504,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'San fernando',
      post_code: 5513,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'San Jose',
      post_code: 5510,
    },
    {
      location: 'Romblon',
      region: 'IV-B',
      municipality: 'Sta. Fe',
      post_code: 5508,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Almagro',
      post_code: 6724,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Basey',
      post_code: 6720,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Calbayog City',
      post_code: 6710,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Calbiga',
      post_code: 6715,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Catbalogan',
      post_code: 6700,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Daram',
      post_code: 6722,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Gandara',
      post_code: 6706,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Hinabangan',
      post_code: 6713,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Jiabong',
      post_code: 6701,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Marabut',
      post_code: 6721,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Matuguinao',
      post_code: 6708,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Motiong',
      post_code: 6702,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Pagsanghan',
      post_code: 6705,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Pinabacdao',
      post_code: 6716,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'San Jorge',
      post_code: 6707,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'San Jose de Buan',
      post_code: 6723,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'San Sebastian',
      post_code: 6714,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Sta. Margarita',
      post_code: 6709,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Sta. Rita',
      post_code: 6718,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Sto. Niño',
      post_code: 6711,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Tagapul-an',
      post_code: 6712,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Talalora',
      post_code: 6719,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Tarangnan',
      post_code: 6704,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Villareal',
      post_code: 6717,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Wright',
      post_code: 6703,
    },
    {
      location: 'Samar',
      region: 'VIII',
      municipality: 'Zumarraga',
      post_code: 6725,
    },
    {
      location: 'Sarangani',
      region: 'XII',
      municipality: 'Alabel',
      post_code: 9501,
    },
    {
      location: 'Sarangani',
      region: 'XII',
      municipality: 'Glan',
      post_code: 9517,
    },
    {
      location: 'Sarangani',
      region: 'XII',
      municipality: 'Kiamba',
      post_code: 9514,
    },
    {
      location: 'Sarangani',
      region: 'XII',
      municipality: 'Maasim',
      post_code: 9502,
    },
    {
      location: 'Sarangani',
      region: 'XII',
      municipality: 'Maitum',
      post_code: 9515,
    },
    {
      location: 'Sarangani',
      region: 'XII',
      municipality: 'Malapatan',
      post_code: 9516,
    },
    {
      location: 'Sarangani',
      region: 'XII',
      municipality: 'Malungon',
      post_code: 9503,
    },
    {
      location: 'Siquijor',
      region: 'VII',
      municipality: 'Enrique Villanueva',
      post_code: 6230,
    },
    {
      location: 'Siquijor',
      region: 'VII',
      municipality: 'Larena',
      post_code: 6226,
    },
    {
      location: 'Siquijor',
      region: 'VII',
      municipality: 'Lazi',
      post_code: 6228,
    },
    {
      location: 'Siquijor',
      region: 'VII',
      municipality: 'Maria',
      post_code: 6229,
    },
    {
      location: 'Siquijor',
      region: 'VII',
      municipality: 'San Juan',
      post_code: 6227,
    },
    {
      location: 'Siquijor',
      region: 'VII',
      municipality: 'Siquijor',
      post_code: 6225,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Bacon',
      post_code: 4701,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Barcelona',
      post_code: 4712,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Bulan',
      post_code: 4706,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Bulusan',
      post_code: 4704,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Casiguran',
      post_code: 4702,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Castilla',
      post_code: 4713,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Donsol',
      post_code: 4715,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Gubat',
      post_code: 4710,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Irosin',
      post_code: 4707,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Juban',
      post_code: 4703,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Magallanes',
      post_code: 4705,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Matnog',
      post_code: 4708,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Pilar',
      post_code: 4714,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Prieto Diaz',
      post_code: 4711,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Sorsogon',
      post_code: 4700,
    },
    {
      location: 'Sorsogon',
      region: 'V',
      municipality: 'Sta. Magdalena',
      post_code: 4709,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'Banga',
      post_code: 9511,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'General Santos City',
      post_code: 9500,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'Koronadal',
      post_code: 9506,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'Lake Sebu',
      post_code: 9518,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'Norala',
      post_code: 9508,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'Polomolok',
      post_code: 9504,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'Sto. Niño',
      post_code: 9509,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'Surallah',
      post_code: 9512,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'Tampakan',
      post_code: 9507,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'Tantangan',
      post_code: 9510,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: "T'boli",
      post_code: 9513,
    },
    {
      location: 'South Cotabato',
      region: 'XII',
      municipality: 'Tupi',
      post_code: 9505,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Anahawan',
      post_code: 6610,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Bontoc',
      post_code: 6604,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Hinunangan',
      post_code: 6608,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Hinundayan',
      post_code: 6609,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Libagon',
      post_code: 6615,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Liloan',
      post_code: 6612,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Limasawa',
      post_code: 6618,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Maasin',
      post_code: 6600,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Macrohon',
      post_code: 6601,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Malitbog',
      post_code: 6603,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Padre Burgos',
      post_code: 6602,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Pintuyan',
      post_code: 6614,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'San Francisco',
      post_code: 6613,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'San Juan (Cabalian)',
      post_code: 6611,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'San Ricardo',
      post_code: 6617,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Silago',
      post_code: 6607,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Sogod',
      post_code: 6606,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'St. Bernard',
      post_code: 6616,
    },
    {
      location: 'Southern Leyte',
      region: 'VIII',
      municipality: 'Tomas Oppus',
      post_code: 6605,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Bagumbayan',
      post_code: 9810,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Columbio',
      post_code: 9801,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Esperanza (Ampatuan)',
      post_code: 9806,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Isulan',
      post_code: 9805,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Kalamansig',
      post_code: 9808,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Lebak (Salaman)',
      post_code: 9807,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Lutayan',
      post_code: 9803,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Mariano Marcos',
      post_code: 9802,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Palimbang',
      post_code: 9809,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Pres. Quirino',
      post_code: 9804,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Sen. Ninoy Aquino',
      post_code: 9811,
    },
    {
      location: 'Sultan Kudarat',
      region: 'XII',
      municipality: 'Tacurong',
      post_code: 9800,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Banguingui (formerly Tongkil)',
      post_code: 7406,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Hadji Panglima Tahil (formerly Marungas)',
      post_code: 7413,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Indanan',
      post_code: 7407,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Jolo',
      post_code: 7400,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Kalingalan Kalauang',
      post_code: 7416,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Lugus',
      post_code: 7411,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Luuk',
      post_code: 7404,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Maimbung',
      post_code: 7409,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Omar',
      post_code: 7417,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Panamao',
      post_code: 7402,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Pandami',
      post_code: 7418,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Panglima Estino',
      post_code: 7415,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Panguntaran',
      post_code: 7414,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Parang',
      post_code: 7408,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Pata',
      post_code: 7405,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Patikul',
      post_code: 7401,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Siasi',
      post_code: 7412,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Talipao',
      post_code: 7403,
    },
    {
      location: 'Sulu',
      region: 'BARMM',
      municipality: 'Tapul',
      post_code: 7410,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Alegria',
      post_code: 8425,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Bacuag',
      post_code: 8408,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Burgos',
      post_code: 8424,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Claver',
      post_code: 8410,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Dapa',
      post_code: 8417,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Del Carmen',
      post_code: 8418,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Gen. Luna',
      post_code: 8419,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Gigaquit',
      post_code: 8409,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Mainit',
      post_code: 8407,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Malimono',
      post_code: 8402,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Pilar',
      post_code: 8420,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Placer',
      post_code: 8405,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'San Benito',
      post_code: 8423,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'San Francisco',
      post_code: 8401,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'San Isidro',
      post_code: 8421,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Sison',
      post_code: 8404,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Socorro',
      post_code: 8416,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Sta. Monica',
      post_code: 8422,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Surigao City',
      post_code: 8400,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Tagana-an',
      post_code: 8403,
    },
    {
      location: 'Surigao del Norte',
      region: 'XIII',
      municipality: 'Tubod',
      post_code: 8406,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Barobo',
      post_code: 8309,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Bayabas',
      post_code: 8303,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Bislig',
      post_code: 8311,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Cagwait',
      post_code: 8304,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Cantilan',
      post_code: 8317,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Carmen',
      post_code: 8315,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Carrascal',
      post_code: 8318,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Cortez',
      post_code: 8313,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Hinatuan',
      post_code: 8310,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Lanuza',
      post_code: 8314,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Lianga',
      post_code: 8307,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Lingig',
      post_code: 8312,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Madrid',
      post_code: 8316,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Malixi',
      post_code: 8319,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Marihatag',
      post_code: 8306,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'San Agustin',
      post_code: 8305,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'San Miguel',
      post_code: 8301,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Tagbina',
      post_code: 8308,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Tago',
      post_code: 8302,
    },
    {
      location: 'Surigao del Sur',
      region: 'XIII',
      municipality: 'Tandag',
      post_code: 8300,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Anao',
      post_code: 2310,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Bamban',
      post_code: 2317,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Camiling',
      post_code: 2306,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Capas',
      post_code: 2315,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Concepcion',
      post_code: 2316,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Gerona',
      post_code: 2302,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'La Paz',
      post_code: 2314,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Mayantoc',
      post_code: 2304,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Moncada',
      post_code: 2308,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Paniqui',
      post_code: 2307,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Pura',
      post_code: 2312,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Ramos',
      post_code: 2311,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'San Clemente',
      post_code: 2305,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'San Jose',
      post_code: 2318,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'San Manuel',
      post_code: 2309,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'San Miguel',
      post_code: 2301,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Sta. Ignacia',
      post_code: 2303,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Tarlac',
      post_code: 2300,
    },
    {
      location: 'Tarlac',
      region: 'III',
      municipality: 'Victoria',
      post_code: 2313,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'Bongao',
      post_code: 7500,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'Languyan',
      post_code: 7509,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'Mapun (formerly Cagayan de Sulu)',
      post_code: 7508,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'Panglima Sugala (formerly Balimbing)',
      post_code: 7501,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'Sapa-Sapa',
      post_code: 7503,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'Sibutu',
      post_code: 7510,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'Simunul',
      post_code: 7505,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'Sitangkai',
      post_code: 7506,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'South Ubian',
      post_code: 7504,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'Turtle Island (Taganak)',
      post_code: 7507,
    },
    {
      location: 'Tawi-Tawi',
      region: 'BARMM',
      municipality: 'Tandu Bas',
      post_code: 7502,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'Botolan',
      post_code: 2202,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'Cabangan',
      post_code: 2203,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'Candelaria',
      post_code: 2212,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'Castillejos',
      post_code: 2208,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'Iba',
      post_code: 2201,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'Masinloc',
      post_code: 2211,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'Olongapo City',
      post_code: 2200,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'Palauig',
      post_code: 2210,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'San Antonio',
      post_code: 2206,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'San Felipe',
      post_code: 2204,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'San Marcelino',
      post_code: 2207,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'San Narciso',
      post_code: 2205,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'Sta. Cruz',
      post_code: 2213,
    },
    {
      location: 'Zambales',
      region: 'III',
      municipality: 'Subic',
      post_code: 2209,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Leon B. Postigo (Bacungan)',
      post_code: 7125,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Liloy',
      post_code: 7115,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Manukan',
      post_code: 7110,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Mutia',
      post_code: 7107,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Pinan',
      post_code: 7105,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Polanco',
      post_code: 7106,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Rizal',
      post_code: 7104,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Roxas',
      post_code: 7102,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Salug',
      post_code: 7114,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Sergio Osmeña',
      post_code: 7108,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Siayan',
      post_code: 7113,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Sibuco',
      post_code: 7122,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Sibutad',
      post_code: 7103,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Sindangan',
      post_code: 7112,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Siocon',
      post_code: 7120,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Siraway',
      post_code: 7121,
    },
    {
      location: 'Zamboanga del Norte',
      region: 'IX',
      municipality: 'Tampilisan',
      post_code: 7116,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Aurora',
      post_code: 7020,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Bayog',
      post_code: 7011,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Dimataling',
      post_code: 7032,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Dinas',
      post_code: 7030,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Don Mariano Marcos',
      post_code: 7022,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Dumalinao',
      post_code: 7015,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Dumingag',
      post_code: 7028,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Guipos',
      post_code: 7042,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Josefina',
      post_code: 7027,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Kumalarang',
      post_code: 7013,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Labangan',
      post_code: 7017,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Lakewood',
      post_code: 7014,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Lapuyan',
      post_code: 7037,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Mahayag',
      post_code: 7026,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Margo sa Tubig',
      post_code: 7035,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Midsalip',
      post_code: 7021,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Molave',
      post_code: 7023,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Pagadian City',
      post_code: 7016,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Pitogo',
      post_code: 7033,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Ramon Magsaysay',
      post_code: 7024,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'San Miguel',
      post_code: 7029,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'San Pablo',
      post_code: 7031,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Tabina',
      post_code: 7034,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Tambulig',
      post_code: 7025,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Tigbao',
      post_code: 7043,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Tukuran',
      post_code: 7019,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Vicencio Sagun',
      post_code: 7036,
    },
    {
      location: 'Zamboanga del Sur',
      region: 'IX',
      municipality: 'Zamboanga City',
      post_code: 7000,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Alicia',
      post_code: 7040,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Buug',
      post_code: 7009,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Diplahan',
      post_code: 7039,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Imelda',
      post_code: 7007,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Ipil',
      post_code: 7001,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Kabasalan',
      post_code: 7005,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Mabuhay',
      post_code: 7010,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Malangas',
      post_code: 7038,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Naga',
      post_code: 7004,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Olutanga',
      post_code: 7041,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Payao',
      post_code: 7008,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Roseller Lim',
      post_code: 7002,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Siay',
      post_code: 7006,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Talusan',
      post_code: 7012,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Titay',
      post_code: 7003,
    },
    {
      location: 'Zamboanga Sibugay',
      region: 'IX',
      municipality: 'Tungawan',
      post_code: 7018,
    },
  ]
  return data
}
