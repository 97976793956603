/**
 * Title: orientVideosAcknowledgement.js
 * Description: This is a file that contains the components that displays the list of Orientation Videos Acknowledgement
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/orientVideosAcknowledgement.js
 **/

import React, { useEffect, useState, useRef } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import { listOrientationVideoAcknowledgements } from '../../api/queries'

import { Card, Input, Space, Table, Tag, Button } from 'antd'

import { SearchOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'

import Highlighter from 'react-highlight-words'

import moment from 'moment'

const OrientVideosAcknowledgement = () => {
  const [userAccessHandbook, setUserAccessHandbook] = useState([])
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const [tableIsLoading, setTableIsLoading] = useState(true)

  const fetch = async () => {
    try {
      let items = []
      let nextToken = null

      do {
        const result = await API.graphql(
          graphqlOperation(listOrientationVideoAcknowledgements, {
            nextToken,
          })
        )
        nextToken = result.data.listOrientationVideoAcknowledgements.nextToken
        items.push(...result.data.listOrientationVideoAcknowledgements.items)
      } while (nextToken)

      setUserAccessHandbook(items)
      setTableIsLoading(false)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    fetch()
  }, [])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  // Reset name filter
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            className='d-flex align-items-center justify-content-center'
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      key: 'employeeName',
      className: 'col-3 text-capitalize',
      sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
      ...getColumnSearchProps('employeeName'),
      width: '40%',
      render: (text) => (
        <div
          style={{
            width: '100%',
            whiteSpace: 'normal',
            wordBreak: 'break-word',
            hyphens: 'auto',
          }}
          lang='en' // Add the lang attribute
        >
          {text}
        </div>
      ),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      className: 'col-5',
      sorter: (a, b) => a.department.localeCompare(b.department),
      width: '30%',
    },
    {
      title: 'Date of Handbook Access',
      dataIndex: 'date',
      key: 'locale',
      className: 'col-3',
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      width: '30%',
      render: (text, record) => (
        <Tag color='geekblue'>
          {moment(record.createdAt).format('MMMM DD YYYY h:mm A')}
        </Tag>
      ),
    },
  ]

  return (
    <>
      <div className='site-layout-background' style={{ padding: 24 }}>
        <Card title='List of Orientation Videos Handbook'>
          <Table
            loading={tableIsLoading}
            rowKey={'id'}
            bordered
            dataSource={userAccessHandbook}
            columns={columns}
            pagination={{ pageSize: 50 }}
          />
        </Card>
      </div>
    </>
  )
}

export default OrientVideosAcknowledgement
