import React, { useState } from 'react'
import { notification, Button, Modal, Row, Col, Input } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

import { API, graphqlOperation } from 'aws-amplify'
import {
  createAssessmentQuestions,
  createOnlineActivityQuestions,
} from '../../api/mutations'
import TextArea from 'antd/lib/input/TextArea'

export default function AcitivityQuestionMakerPreview({
  questionsArray,
  setActivityQuestions,
  closeMotherModal,
  handleCreateQuestion,
  openAssessmentPreview,
  setOpenAssessmentPreview,
  index,
}) {
  const handleCancel = () => {
    setOpenAssessmentPreview(false)
  }

  const showOutput = questionsArray.map((elem, index) => {
    return (
      <div key={index} className='mb-4'>
        {elem.questionType !== 'caseStudy' && (
          <>
            <div>
              <p className={'fw-bold'}>
                {index + 1}. {elem.question}
              </p>
            </div>
            <div className='flex-column ms-3 mt-2'>
              {elem.choices[0] &&
                elem.choices[1] &&
                elem.choices[2] &&
                elem.choices[3] && (
                  <>
                    <div
                      className='flex align-items-center'
                      hidden={
                        elem.choices[0][
                          `choices-1-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>A. </em>
                        </strong>
                        {elem.choices[0][`choices-1-${index + 1}`]}
                      </span>
                    </div>
                    <div
                      hidden={
                        elem.choices[1][
                          `choices-2-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>B. </em>
                        </strong>
                        {elem.choices[1][`choices-2-${index + 1}`]}
                      </span>
                    </div>
                    <div
                      hidden={
                        elem.choices[2][
                          `choices-3-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>C. </em>
                        </strong>
                        {elem.choices[2][`choices-3-${index + 1}`]}
                      </span>
                    </div>
                    <div
                      hidden={
                        elem.choices[3][
                          `choices-4-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>D. </em>
                        </strong>
                        {elem.choices[3][`choices-4-${index + 1}`]}
                      </span>
                    </div>
                    <TextArea hidden={elem.correctAnswer !== 'n/a'} />
                  </>
                )}
            </div>
          </>
        )}
        {/* <Row>
          <Row>
            <span className={"fw-bold"}>Multiple choice: Choose the correct asnwer</span>
          </Row>
        </Row> */}
        {elem.questionType === 'caseStudy' && (
          <>
            <Row
              className={
                'mb-3 d-flex justify-content-between align-items-center'
              }
            >
              <label htmlFor={`question-${index + 1}`}>
                <strong>Instructions:</strong>
              </label>
            </Row>
            <Row className={'mb-3'}>
              <p>{questionsArray[index].question}</p>
            </Row>

            {questionsArray[index].scenario.map((scenario, scenarioIndex) => (
              <div key={scenarioIndex} className='my-4'>
                <div>
                  <h4>
                    Case Scenario #{scenarioIndex + 1}: {scenario.title}{' '}
                  </h4>
                  <p>{scenario.description}</p>
                </div>
              </div>
            ))}
            <div>
              <p>Select a case scenario before uploading:</p>
              <div className='d-flex gap-3'>
                {questionsArray[index].scenario.map(
                  (scenario, scenarioIndex) => (
                    <div key={scenarioIndex} className='fw-bold'>
                      <input type='radio' name='caseScenario' /> Case #{' '}
                      {scenarioIndex + 1}
                    </div>
                  )
                )}
              </div>
            </div>
            <input className='mt-4' type='file' disabled />
          </>
        )}
      </div>
    )
  })

  const handleConfirm = async () => {
    try {
      const createAssessment = await API.graphql(
        graphqlOperation(createOnlineActivityQuestions, {
          input: {
            questions: JSON.stringify(questionsArray),
          },
        })
      )

      if (createAssessment) {
        notification['success']({
          message: 'Success',
          description: 'Assessment Questionnaire was saved.',
        })

        setActivityQuestions((prev) => {
          const newKey = `activityQuestion${index + 1}`

          return {
            ...prev,
            [newKey]:
              createAssessment.data.createOnlineActivityQuestions.questions,
          }
        })

        setTimeout(() => {
          setOpenAssessmentPreview(false)
          closeMotherModal(false)
        }, 1500)
      }
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description:
          'Assessment Questionnaire was not saved. Contact System Admin.',
      })
    }
  }

  return (
    <>
      <Modal
        maskClosable={false}
        title={
          <span>
            <em>
              <b>Please review questionnaire before saving</b>
            </em>
          </span>
        }
        visible={openAssessmentPreview}
        footer={null}
        onCancel={handleCancel}
        width={800}
        destroyOnClose={true}
      >
        <Row style={{ fontSize: 16 }} justify='center'>
          <Col>{showOutput}</Col>
        </Row>
        <Row className='mt-4' justify='end'>
          <div>
            <Button
              className='me-4'
              onClick={() => setOpenAssessmentPreview(false)}
              type='danger'
            >
              Cancel
            </Button>
            <Button onClick={handleConfirm} type='primary'>
              Confirm
            </Button>
          </div>
        </Row>
      </Modal>
    </>
  )
}
