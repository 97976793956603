/**
 * Title: onlineTrainingProgramsView.js
 * Description: This is a file that contains lms view course modal
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/onlineTrainingProgramsView.js
 * History:
 * - 09/23/2024 - John Bazil Valdez
 * - Change the display text for the message showing after video module and when module has no activity
 **/

import { Button, Modal, Row, notification } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import OnlineTrainingNavigation from './OnlineTrainingNavigation'
import { API, Auth, graphqlOperation } from 'aws-amplify'

import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from '@aws-sdk/client-secrets-manager'
import {
  CloudFrontClient,
  GetDistributionCommand,
} from '@aws-sdk/client-cloudfront'
import { getSignedUrl } from '@aws-sdk/cloudfront-signer'
import { numToString } from '../../utilities/numToString'
import TextArea from 'antd/lib/input/TextArea'
import OnlineTrainingAccordionView from './onlineTrainingAccordionView'
import {
  listOnlineActivityAnswers,
  listOnlineActivityQuestions,
  listTrainingAnnouncements,
} from '../../api/queries'
import {
  createOnlineActivityAnswers,
  createTrainingPostEvaluation,
  updateOnlineActivityAnswers,
} from '../../api/mutations'
import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import moment from 'moment'
import OnlineTrainingInstructionPrompt from './online-training-instruction-promtmodal'

const file = {
  attributes: {
    controlsList: 'nodownload',
    onContextMenu: (e) => e.preventDefault(),
  },
}

/**
 * Renders the OnlineTrainingProgramsView component.
 *
 * @param {Object} training - The training object.
 * @returns {JSX.Element} The rendered component.
 */
export default function OnlineTrainingProgramsView({
  training,
  afterRegister,
  userData,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedVideoKey, setSelectedVideoKey] = useState(null)
  const [selectedActivity, setSelectedActivity] = useState(null)
  const [videoUrl, setVideoUrl] = useState(null)
  const [selectedView, setSelectedView] = useState('video')
  const [assessQuestionsSet, setAssessQuestionsSet] = useState([])
  const [selectedActivityIndex, setSelectedActivityIndex] = useState(0)
  const [numberOfAnsweredActivity, setNumberOfAsnweredActivity] = useState(0)
  const [selectedAccordion, setSelectedAccordion] = useState(
    numberOfAnsweredActivity
  )
  const [isVideoWatched, setIsVideoWatched] = useState(false)
  const [selectedTranscript, setSelectedTranscript] = useState(null)
  const [correctAnswerModal, setCorrectAnswerModal] = useState(false)
  const [totalScore, setTotalScore] = useState(0)
  const [caseScenario, setCaseScenario] = useState(null)
  const [uploadedFile, setUploadedFile] = useState(null)
  const [playerHeight, setPlayerHeight] = useState(0)
  const [isInstructionModalVisible, setIsInstructionModalVisible] =
    useState(false)
  const playerRef = useRef(null)

  const handleClickDone = () => {
    const activityNumber = `activity${selectedActivityIndex + 1}`
    setAssessQuestionsSet((prevState) => ({
      ...prevState, // Shallow copy of previous state
      [activityNumber]: {
        ...prevState[activityNumber], // Shallow copy of nested object
        activity: 'N/A', // Update specific property
      },
    }))
  }

  useEffect(() => {
    const handleResize = () => {
      if (playerRef.current) {
        setPlayerHeight(playerRef.current.getInternalPlayer().clientHeight)
      }
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleReady = () => {
    if (playerRef.current) {
      setPlayerHeight(playerRef.current.getInternalPlayer().clientHeight)
    }
  }

  /**
   * Handles the submission of an activity.
   * @returns {Promise<void>} - A promise that resolves when the activity is submitted.
   */
  const handleSubmitActivity = async () => {
    let newAssessQuestionsSet = assessQuestionsSet

    if (selectedActivity && selectedActivity[0]?.questionType === 'caseStudy') {
      newAssessQuestionsSet = await handleCaseScenario()
      setAssessQuestionsSet(newAssessQuestionsSet)
    }

    const fetchAllData = async () => {
      let allData = []
      let nextToken = null

      do {
        const result = await API.graphql({
          query: listOnlineActivityAnswers,
          variables: {
            nextToken,
            limit: 10000,
          },
        })

        const data = result.data.listOnlineActivityAnswers.items
        allData = allData.concat(data)

        nextToken = result.data.listOnlineActivityAnswers.nextToken
      } while (nextToken)

      return allData
    }

    const userAnswer = await fetchAllData()

    const filteredUserAnswer = userAnswer.filter((item) => {
      return (
        item.userID == userData.id &&
        item.onlineTrainingAnnouncementID == training.id
      )
    })

    if (!filteredUserAnswer.length) {
      const adminUpload = await API.graphql(
        graphqlOperation(createOnlineActivityAnswers, {
          input: {
            userID: userData.id,
            onlineTrainingAnnouncementID: training.id,
            answers: JSON.stringify(assessQuestionsSet),
            /*   timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'), */
          },
        })
      )

      if (adminUpload.data.createOnlineActivityAnswers) {
        if (JSON.parse(training?.sectionTitle).length === 1) {
          setSelectedView('done')
        }

        notification['success']({
          message: 'Success',
          description: 'Activity submitted!',
        })

        setSelectedVideoKey(
          JSON.parse(training.sectionVideos)[`trainingVideosID2`]
        )
        /*  setSelectedActivity(
          JSON.parse(
            JSON.parse(training?.sectionQuestions)[`activityQuestion2`] || null
          ) || null
        ) */
        setSelectedAccordion(1)
        setNumberOfAsnweredActivity(1)
        setSelectedActivityIndex(1)
        setIsVideoWatched(false)
        setSelectedView('video')
      }

      //Show correct answer modal if the question type is not case study
      if (
        selectedActivity &&
        selectedActivity[0].questionType !== 'caseStudy'
      ) {
        setCorrectAnswerModal(true)
        const totalScore = selectedActivity.reduce((total, elem, index) => {
          const score =
            elem.correctAnswer ===
            assessQuestionsSet?.[`activity${selectedActivityIndex + 1}`]?.[
              `question${numToString(index)}`
            ]
              ? 1
              : 0
          return total + score
        }, 0)
        setTotalScore(totalScore)
      }
    } else {
      const adminUpload = await API.graphql(
        graphqlOperation(updateOnlineActivityAnswers, {
          input: {
            id: filteredUserAnswer[0].id,
            answers: JSON.stringify(newAssessQuestionsSet),
          },
        })
      )

      if (adminUpload.data.updateOnlineActivityAnswers) {
        notification['success']({
          message: 'Success',
          description: 'Activity submitted!',
        })

        setSelectedAccordion(numberOfAnsweredActivity + 1)
        setNumberOfAsnweredActivity(numberOfAnsweredActivity + 1)
        setSelectedActivityIndex(numberOfAnsweredActivity + 1)

        setSelectedVideoKey(
          JSON.parse(training.sectionVideos)[
            `trainingVideosID${numberOfAnsweredActivity + 2}`
          ]
        )

        setIsVideoWatched(false)
        if (
          JSON.parse(training?.sectionTitle).length ===
          numberOfAnsweredActivity + 1
        ) {
          setSelectedView('done')
        } else {
          setSelectedView('video')
        }
      }
    }
  }

  useEffect(() => {
    getVideoUrl(selectedVideoKey)
  }, [selectedVideoKey])

  /**
   * Retrieves the video URL for a given key.
   * @param {string} key - The key of the video.
   * @returns {Promise<void>} - A promise that resolves when the video URL is retrieved.
   * @throws {Error} - If there is an error getting the video URL.
   */
  async function getVideoUrl(key) {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken
      const secret_name = 'hrmCloudfront'
      const client = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await client.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)
      const objectKey = key
      const cdnClient = new CloudFrontClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const getDistributionParams = {
        Id: foundSecret.hrmDistributionID,
      }
      const commandCDN = new GetDistributionCommand(getDistributionParams)
      const response = await cdnClient.send(commandCDN)
      const resourcePath = `output/${objectKey}`
      const newURL = `https://${response.Distribution.DomainName}/output/${objectKey}`
      const privateKey = foundSecret.hrmCloudfrontKey.replace(/\s*&&\s*/g, '\n')
      const keyPairId = foundSecret.hrmCloudfrontID
      const dateLessThan = new Date(Date.now() + 3600 * 1000)

      const signedUrlCDN = getSignedUrl({
        url: newURL,
        keyPairId,
        dateLessThan,
        privateKey,
      })

      setVideoUrl(signedUrlCDN)
    } catch (error) {
      console.log('Error getting video URL:', error)
    }
  }

  /**
   * Handles the cancel action.
   */
  const handleCancel = () => {
    setIsModalOpen(false)
    setSelectedView('video')
    setSelectedTranscript(null)
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pause()
    }
  }

  /**
   * Handles the opening of a preview for online training programs.
   * Retrieves user answers and filters them based on the current user and training ID.
   * Sets the assessment questions set, number of answered activities, selected accordion, selected activity index, and selected activity.
   * Opens the modal.
   */
  const handleOpenPreview = async () => {
    const fetchAllData = async () => {
      let allData = []
      let nextToken = null

      do {
        const result = await API.graphql({
          query: listOnlineActivityAnswers,
          variables: {
            nextToken,
            limit: 10000,
          },
        })

        const data = result.data.listOnlineActivityAnswers.items
        allData = allData.concat(data)

        nextToken = result.data.listOnlineActivityAnswers.nextToken
      } while (nextToken)

      return allData
    }

    const userAnswer = await fetchAllData()

    const filteredUserAnswer = userAnswer.filter((item) => {
      return (
        item.userID === userData.id &&
        item.onlineTrainingAnnouncementID === training.id
      )
    })

    if (filteredUserAnswer.length) {
      setAssessQuestionsSet(JSON.parse(filteredUserAnswer[0].answers))
    }

    const numberOfAnswers = filteredUserAnswer[0]?.answers
      ? Object.keys(JSON.parse(filteredUserAnswer[0].answers)).length
      : 0

    setNumberOfAsnweredActivity(numberOfAnswers)
    setSelectedAccordion(numberOfAnswers)
    setSelectedActivityIndex(numberOfAnswers)

    if (JSON.parse(training?.sectionTitle).length === numberOfAnswers) {
      setSelectedView('done')
    } else {
      setSelectedVideoKey(
        JSON.parse(training.sectionVideos)[
          `trainingVideosID${numberOfAnswers + 1 || 0}`
        ] || null
      )

      setSelectedActivity(
        JSON.parse(
          JSON.parse(training?.sectionQuestions)?.[
            `activityQuestion${numberOfAnswers + 1 || 0}`
          ] || null
        ) || null
      )
    }

    // To open the training modal
    setIsModalOpen(true)
    // To open the instruction modal
    setIsInstructionModalVisible(true)
  }

  /**
   * Handles the event when an answer is set for an assessment question.
   * @param {Event} e - The event object.
   */
  const handleSetAnsAssessQuestion = (e) => {
    const activityNumber = `activity${selectedActivityIndex + 1}`
    setAssessQuestionsSet((prevState) => ({
      ...prevState, // Shallow copy of previous state
      [activityNumber]: {
        ...prevState[activityNumber], // Shallow copy of nested object
        [e.target.name]: e.target.value, // Update specific property
      },
    }))
  }

  const handleEnded = () => {
    setSelectedActivity(
      JSON.parse(
        JSON.parse(training?.sectionQuestions)?.[
          `activityQuestion${numberOfAnsweredActivity + 1}`
        ] || null
      ) || null
    )
    setIsVideoWatched(true)
    setSelectedTranscript(null)
    setSelectedView('activity')
  }

  const handleRadio = (e) => {
    setCaseScenario(e.target.id)
  }

  const handleCaseScenario = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const { accessKeyId, secretAccessKey, sessionToken } = credentials

      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId,
          secretAccessKey,
          sessionToken,
        },
      })

      const params = {
        Bucket: 's3etnahris133956-dev',
        Key: `${userData.id}/${moment().valueOf()}-${uploadedFile.name}`,
        Body: uploadedFile,
        ACL: 'public-read',
      }

      const command = new PutObjectCommand(params)
      const result = await s3.send(command)
      const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

      if (result) {
        const activityNumber = `activity${selectedActivityIndex + 1}`
        const newAssessQuestionsSet = {
          ...assessQuestionsSet,
          [activityNumber]: {
            ...assessQuestionsSet[activityNumber],
            caseScenario: caseScenario,
            location: url,
          },
        }
        return newAssessQuestionsSet
      }
    } catch (err) {
      console.log(err)
      return assessQuestionsSet
    }
  }

  return (
    <section>
      {/* Initial Prompt Modal Instruction upon clicking the training proper button*/}
      <OnlineTrainingInstructionPrompt
        isInstructionModalVisible={isInstructionModalVisible}
        setIsInstructionModalVisible={setIsInstructionModalVisible}
        title={training?.title}
      />
      <Row>
        <button onClick={handleOpenPreview} className={'m-container ms-0 mb-0'}>
          Training Proper
        </button>
      </Row>
      {isModalOpen && (
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title={`${training?.title} Preview`}
          open={isModalOpen}
          footer={
            <>
              <Button onClick={handleCancel}>Close</Button>
            </>
          }
          onCancel={handleCancel}
          width={1800}
        >
          <div className='col-md-12 d-md-flex'>
            <div className={`col-md-8 col-12`}>
              {selectedView === 'done' ? (
                <div
                  style={{ height: 500 }}
                  className='p-5 text-center d-flex justify-content-center align-items-center bg-light'
                >
                  <h4>
                    You have finished the video modules.
                    <br /> To complete this training, answer the{' '}
                    <strong>Post Training</strong> (Post Evaluation and Post
                    Test)
                  </h4>
                </div>
              ) : (
                <div>
                  {selectedView === 'video' && (
                    <ReactPlayer
                      className='video-player w-100'
                      ref={playerRef}
                      playing={false}
                      url={videoUrl}
                      controls
                      config={{ file }}
                      onReady={handleReady}
                      onEnded={handleEnded}
                    />
                  )}
                  {selectedView === 'activity' && (
                    <div className='mb-4 card p-4'>
                      {selectedActivity &&
                        selectedActivity.map((elem, index) => {
                          const numString = numToString(index)
                          return (
                            <div key={index} className='mb-4'>
                              {elem.questionType !== 'caseStudy' && (
                                <>
                                  <Row className='mt-4'>
                                    <span
                                      style={{
                                        fontSize: 16,
                                        textAlign: 'left',
                                      }}
                                    >
                                      <strong>
                                        {index + 1}. {elem.question}
                                      </strong>
                                    </span>
                                  </Row>
                                  <Row
                                    align='center'
                                    justify='center'
                                    className='flex-column ms-3 mt-2'
                                  >
                                    {elem.choices[0] &&
                                      elem.choices[1] &&
                                      elem.choices[2] &&
                                      elem.choices[3] && (
                                        <>
                                          {elem.choices[0][
                                            `choices-1-${index + 1}`
                                          ].toLowerCase() !== 'n/a' && (
                                            <div
                                              className='d-flex p-1'
                                              hidden={
                                                elem.choices[0][
                                                  `choices-1-${index + 1}`
                                                ].toLowerCase() === 'n/a'
                                              }
                                            >
                                              <input
                                                onChange={(e) =>
                                                  handleSetAnsAssessQuestion(e)
                                                }
                                                value='A'
                                                name={'question' + numString}
                                                className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                                type='radio'
                                                id={`question${numString}-choice-A`}
                                              />
                                              <label
                                                className='m-0 ms-3 text-start'
                                                htmlFor={`question${numString}-choice-A`}
                                              >
                                                <strong>
                                                  <em>A. </em>
                                                </strong>
                                                {
                                                  elem.choices[0][
                                                    `choices-1-${index + 1}`
                                                  ]
                                                }
                                              </label>
                                            </div>
                                          )}
                                          {elem.choices[1][
                                            `choices-2-${index + 1}`
                                          ].toLowerCase() !== 'n/a' && (
                                            <div className='d-flex p-1'>
                                              <input
                                                onChange={(e) =>
                                                  handleSetAnsAssessQuestion(e)
                                                }
                                                value='B'
                                                name={'question' + numString}
                                                className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                                type='radio'
                                                id={`question${numString}-choice-B`}
                                              />
                                              <label
                                                className='m-0 ms-3 text-start'
                                                htmlFor={`question${numString}-choice-B`}
                                              >
                                                <strong>
                                                  <em>B. </em>
                                                </strong>
                                                {
                                                  elem.choices[1][
                                                    `choices-2-${index + 1}`
                                                  ]
                                                }
                                              </label>
                                            </div>
                                          )}
                                          {elem.choices[2][
                                            `choices-3-${index + 1}`
                                          ].toLowerCase() !== 'n/a' && (
                                            <div className='d-flex p-1'>
                                              <input
                                                onChange={(e) =>
                                                  handleSetAnsAssessQuestion(e)
                                                }
                                                value='C'
                                                name={'question' + numString}
                                                className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                                type='radio'
                                                id={`question${numString}-choice-C`}
                                              />
                                              <label
                                                className='m-0 ms-3 text-start'
                                                htmlFor={`question${numString}-choice-C`}
                                              >
                                                <strong>
                                                  <em>C. </em>
                                                </strong>
                                                {
                                                  elem.choices[2][
                                                    `choices-3-${index + 1}`
                                                  ]
                                                }
                                              </label>
                                            </div>
                                          )}
                                          {elem.choices[3][
                                            `choices-4-${index + 1}`
                                          ].toLowerCase() !== 'n/a' && (
                                            <div className='d-flex p-1'>
                                              <input
                                                onChange={(e) =>
                                                  handleSetAnsAssessQuestion(e)
                                                }
                                                value='D'
                                                name={'question' + numString}
                                                className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                                type='radio'
                                                id={`question${numString}-choice-D`}
                                              />
                                              <label
                                                className='m-0 ms-3 text-start'
                                                htmlFor={`question${numString}-choice-D`}
                                              >
                                                <strong>
                                                  <em>D. </em>
                                                </strong>
                                                {
                                                  elem.choices[3][
                                                    `choices-4-${index + 1}`
                                                  ]
                                                }
                                              </label>
                                            </div>
                                          )}
                                          <TextArea
                                            hidden={
                                              elem.correctAnswer !== 'n/a'
                                            }
                                            onChange={(e) =>
                                              handleSetAnsAssessQuestion(e)
                                            }
                                            name={'question' + numString}
                                            className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                          />
                                        </>
                                      )}
                                  </Row>
                                </>
                              )}
                              {elem.questionType === 'caseStudy' && (
                                <>
                                  <Row
                                    className={
                                      'mb-3 d-flex justify-content-between align-items-center'
                                    }
                                  >
                                    <label htmlFor={`question-${index + 1}`}>
                                      <strong>Instructions:</strong>
                                    </label>
                                  </Row>
                                  <Row className={'mb-3'}>
                                    <p>{elem?.question}</p>
                                  </Row>

                                  {elem?.scenario.map(
                                    (scenario, scenarioIndex) => (
                                      <div key={scenarioIndex} className='my-4'>
                                        <div>
                                          <h4>
                                            Case Scenario #{scenarioIndex + 1}:{' '}
                                            {scenario.title}{' '}
                                          </h4>
                                          <p>{scenario.description}</p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <div>
                                    <p>
                                      Select a case scenario before uploading:
                                    </p>
                                    <div className='d-flex gap-3'>
                                      {elem?.scenario.map(
                                        (scenario, scenarioIndex) => (
                                          <div
                                            key={scenarioIndex}
                                            className='fw-bold'
                                          >
                                            <input
                                              defaultChecked={
                                                caseScenario ===
                                                `caseScenario-${scenarioIndex}`
                                              }
                                              type='radio'
                                              name='caseScenario'
                                              onChange={(e) => handleRadio(e)}
                                              id={`caseScenario-${
                                                scenarioIndex + 1
                                              }`}
                                            />{' '}
                                            Case # {scenarioIndex + 1}
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                  <input
                                    className='mt-4'
                                    type='file'
                                    onChange={(e) => {
                                      if (!e.target.files[0])
                                        return setUploadedFile(null)

                                      if (
                                        e.target.files[0].size <=
                                        5 * 1024 * 1024
                                      ) {
                                        setUploadedFile(e.target.files[0])
                                      } else {
                                        notification['error']({
                                          message: 'Error',
                                          description:
                                            'File size limit of 5MB is exceeded. Upload a different file.',
                                        })
                                      }
                                    }}
                                    disabled={!caseScenario}
                                  />
                                </>
                              )}
                            </div>
                          )
                        })}
                      <div className='d-flex justify-content-center'>
                        <Button
                          disabled={
                            selectedActivity &&
                            selectedActivity[0]?.questionType === 'caseStudy' &&
                            !uploadedFile
                          }
                          hidden={!selectedActivity}
                          onClick={handleSubmitActivity}
                          size='large'
                          className={`text-white rounded ${
                            selectedActivity &&
                            selectedActivity[0]?.questionType === 'caseStudy' &&
                            !uploadedFile
                              ? 'bg-secondary'
                              : 'bg-success'
                          }`}
                        >
                          Submit
                        </Button>
                      </div>
                      {!selectedActivity && (
                        <div
                          style={{ height: 500 }}
                          className='p-5 text-center d-flex justify-content-center align-items-center bg-light'
                        >
                          <h4>
                            This module does not have an activity. To proceed,
                            click
                            <strong className=''>"Next"</strong> button.
                          </h4>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}
              <OnlineTrainingNavigation
                training={training}
                userData={userData}
              />
            </div>
            <section className='col-12 col-md-4'>
              {!selectedTranscript && (
                <React.Fragment>
                  <div className=''>
                    <div className='d-flex justify-content-between px-3 pt-3'>
                      <h4>Course Content</h4>
                      {/*    <button className='bg-white border-0' onClick={handleCancel}>
                    <h4>x</h4>
                  </button> */}
                    </div>
                  </div>
                  <div className='px-3'>
                    <div className='d-flex flex-column'>
                      <OnlineTrainingAccordionView
                        selectedView={selectedView}
                        setSelectedView={setSelectedView}
                        training={training}
                        setSelectedVideoKey={setSelectedVideoKey}
                        selectedVideoKey={selectedVideoKey}
                        setSelectedActivity={setSelectedActivity}
                        setSelectedActivityIndex={setSelectedActivityIndex}
                        numberOfAnsweredActivity={numberOfAnsweredActivity}
                        selectedAccordion={selectedAccordion}
                        setSelectedAccordion={setSelectedAccordion}
                        handleSubmitActivity={handleSubmitActivity}
                        isVideoWatched={isVideoWatched}
                        setIsVideoWatched={setIsVideoWatched}
                        handleClickDone={handleClickDone}
                        setSelectedTranscript={setSelectedTranscript}
                        afterRegister={afterRegister}
                        userData={userData}
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
              {selectedTranscript && (
                <div className='flex'>
                  <div className='d-flex justify-content-between px-3'>
                    <h4>Transcript</h4>
                    <button
                      className='bg-white border-0'
                      onClick={() => setSelectedTranscript(null)}
                    >
                      <h4>x</h4>
                    </button>
                  </div>
                  <p
                    className='px-3 mt-2'
                    style={{
                      maxHeight: playerHeight - 50 + 'px',
                      overflow: 'auto',
                      fontSize: '20px',
                    }}
                  >
                    {selectedTranscript}
                  </p>
                </div>
              )}
              {/*  <button
                onClick={() => {
                  setCorrectAnswerModal(true)
                  const totalScore = selectedActivity.reduce(
                    (total, elem, index) => {
                      const score =
                        elem.correctAnswer ===
                        assessQuestionsSet?.[
                          `activity${selectedActivityIndex + 1}`
                        ]?.[`question${numToString(index)}`]
                          ? 1
                          : 0
                      return total + score
                    },
                    0
                  )
                  setTotalScore(totalScore)
                }}
              >
                view correct answer
              </button> */}
            </section>
          </div>
          <Modal
            maskClosable={false}
            destroyOnClose={true}
            title={`Activity ${selectedActivityIndex} Correct Answwer Preview`}
            open={correctAnswerModal}
            footer={
              <>
                <Button onClick={() => setCorrectAnswerModal(false)}>
                  Close
                </Button>
              </>
            }
            onCancel={() => setCorrectAnswerModal(false)}
            width={1800}
            key={'modal2'}
          >
            <div className='mb-4 gap-3'>
              <div className='d-md-flex align-items-center'>
                <h3 className='text-success'>Total Score: {totalScore} </h3>
                <h6 className='text-muted'>
                  (Activity "Essay" is not included in the total score)
                </h6>
              </div>

              {selectedActivity &&
                selectedActivity.map((elem, index) => {
                  const numString = numToString(index)
                  return (
                    <>
                      <div key={index} className='mb-4'>
                        <Row className='mt-4'>
                          <span style={{ fontSize: 16, textAlign: 'left' }}>
                            <strong>
                              {index + 1}. {elem.question}
                            </strong>
                          </span>
                        </Row>
                        <Row
                          align='center'
                          justify='center'
                          className='flex-column ms-3 mt-2'
                        >
                          {elem.choices[0] &&
                            elem.choices[1] &&
                            elem.choices[2] &&
                            elem.choices[3] && (
                              <>
                                {elem.choices[0][
                                  `choices-1-${index + 1}`
                                ].toLowerCase() !== 'n/a' && (
                                  <div
                                    className='d-flex p-1'
                                    hidden={
                                      elem.choices[0][
                                        `choices-1-${index + 1}`
                                      ].toLowerCase() === 'n/a'
                                    }
                                  >
                                    <input
                                      disabled
                                      checked={
                                        assessQuestionsSet?.[
                                          `activity${selectedActivityIndex}`
                                        ]?.[`question${numString}`] === 'A'
                                      }
                                      value='A'
                                      name={'question' + numString}
                                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                      type='radio'
                                      id={`question${numString}-choice-A`}
                                    />
                                    <label
                                      className='m-0 ms-3 text-start'
                                      htmlFor={`question${numString}-choice-A`}
                                    >
                                      <strong>
                                        <em>A. </em>
                                      </strong>
                                      {
                                        elem.choices[0][
                                          `choices-1-${index + 1}`
                                        ]
                                      }
                                      <span
                                        hidden={elem.correctAnswer !== 'A'}
                                        className='text-secondary'
                                      >
                                        {' '}
                                        (Correct answer)
                                      </span>
                                    </label>
                                  </div>
                                )}
                                {elem.choices[1][
                                  `choices-2-${index + 1}`
                                ].toLowerCase() !== 'n/a' && (
                                  <div className='d-flex p-1'>
                                    <input
                                      disabled
                                      checked={
                                        assessQuestionsSet?.[
                                          `activity${selectedActivityIndex}`
                                        ]?.[`question${numString}`] === 'B'
                                      }
                                      value='B'
                                      name={'question' + numString}
                                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                      type='radio'
                                      id={`question${numString}-choice-B`}
                                    />
                                    <label
                                      className='m-0 ms-3 text-start'
                                      htmlFor={`question${numString}-choice-B`}
                                    >
                                      <strong>
                                        <em>B. </em>
                                      </strong>
                                      {
                                        elem.choices[1][
                                          `choices-2-${index + 1}`
                                        ]
                                      }
                                      <span
                                        hidden={elem.correctAnswer !== 'B'}
                                        className='text-secondary'
                                      >
                                        {' '}
                                        (Correct answer)
                                      </span>
                                    </label>
                                  </div>
                                )}
                                {elem.choices[2][
                                  `choices-3-${index + 1}`
                                ].toLowerCase() !== 'n/a' && (
                                  <div className='d-flex p-1'>
                                    <input
                                      disabled
                                      checked={
                                        assessQuestionsSet?.[
                                          `activity${selectedActivityIndex}`
                                        ]?.[`question${numString}`] === 'C'
                                      }
                                      value='C'
                                      name={'question' + numString}
                                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                      type='radio'
                                      id={`question${numString}-choice-C`}
                                    />
                                    <label
                                      className='m-0 ms-3 text-start'
                                      htmlFor={`question${numString}-choice-C`}
                                    >
                                      <strong>
                                        <em>C. </em>
                                      </strong>
                                      {
                                        elem.choices[2][
                                          `choices-3-${index + 1}`
                                        ]
                                      }
                                      <span
                                        hidden={elem.correctAnswer !== 'C'}
                                        className='text-secondary'
                                      >
                                        {' '}
                                        (Correct answer)
                                      </span>
                                    </label>
                                  </div>
                                )}
                                {elem.choices[3][
                                  `choices-4-${index + 1}`
                                ].toLowerCase() !== 'n/a' && (
                                  <div className='d-flex p-1'>
                                    <input
                                      disabled
                                      checked={
                                        assessQuestionsSet?.[
                                          `activity${selectedActivityIndex}`
                                        ]?.[`question${numString}`] === 'D'
                                      }
                                      value='D'
                                      name={'question' + numString}
                                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                      type='radio'
                                      id={`question${numString}-choice-D`}
                                    />
                                    <label
                                      className='m-0 ms-3 text-start'
                                      htmlFor={`question${numString}-choice-D`}
                                    >
                                      <strong>
                                        <em>D. </em>
                                      </strong>
                                      {
                                        elem.choices[3][
                                          `choices-4-${index + 1}`
                                        ]
                                      }
                                      <span
                                        hidden={elem.correctAnswer !== 'A'}
                                        className='text-secondary'
                                      >
                                        {' '}
                                        (Correct answer)
                                      </span>
                                    </label>
                                  </div>
                                )}
                                <TextArea
                                  hidden={elem.correctAnswer !== 'n/a'}
                                  onChange={(e) =>
                                    handleSetAnsAssessQuestion(e)
                                  }
                                  value={
                                    assessQuestionsSet?.[
                                      `activity${selectedActivityIndex}`
                                    ]?.[`question${numString}`]
                                  }
                                  disabled
                                  name={'question' + numString}
                                  className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                />
                              </>
                            )}
                        </Row>
                      </div>
                    </>
                  )
                })}
            </div>
          </Modal>
        </Modal>
      )}
    </section>
  )
}
