/**
 * Title: formEmployee.js
 * Description: This is a file that contains the employee Table
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Employee/formEmployee.js
 **/

import { Button, Table, Modal, Tag, Breadcrumb } from 'antd'
import React from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import { listForms } from '../../api/queries'
import moment from 'moment'
import ViewForm from '../Form/viewForm'
export default class FormEmployee extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      open: false,
      selected: [],
      name: '',
      disabled: false,
      docs: [],
      openEdit: false,
    }
  }

  componentDidMount() {
    this.listUser()

    this.setState({ size: window.innerWidth })

    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    this.setState({ size: window.innerWidth })
  }

  listUser = async () => {
    const user = this.props.userData
    let arr = []
    API.graphql(
      graphqlOperation(listForms, {
        filter: {
          division: { contains: user.division },
          and: {
            dept: { contains: user.department },
            and: {
              userID: { notContains: user.id },
              and: {
                assignedDept: { contains: user.assignedDept },
              },
            },
          },
        },
      })
    )
      .then((res) => {
        let filter = res.data.listForms.items

        if (filter.length) {
          filter.forEach((item, i) => {
            arr.push({ ...item, key: i + 1 })
          })
        }
      })
      .then((res) => {
        this.setState({ data: arr })
      })
  }

  render() {
    const columns = [
      {
        title: 'No.',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <span>
            <b>{`${record.ownedBy.name} ${record.ownedBy.mname} ${record.ownedBy.lname}`}</b>
          </span>
        ),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'username',
        render: (text, record) => <span>{record.ownedBy.username}</span>,
      },
      {
        title: 'Home Department',
        dataIndex: 'department',
        key: 'department',
        render: (text, record) => <Tag color='geekblue'>{record.dept}</Tag>,
      },
      {
        title: 'Assigned Department',
        dataIndex: 'assignedDept',
        key: 'assignedDept',
        render: (text, record) => (
          <Tag color='geekblue'>{record.assignedDept}</Tag>
        ),
      },
      {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        render: (text, record) => <Tag color='geekblue'>{record.division}</Tag>,
      },

      {
        title: 'Date submitted',
        dataIndex: 'date',
        key: 'locale',
        render: (text, record) => (
          <span>{moment(record.createdAt).format('MMMM Do YYYY')}</span>
        ),
      },

      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button
              onClick={() =>
                this.setState({ open: true, selected: record.ownedBy })
              }
              size='small'
              type='primary'
            >
              Check Form
            </Button>
            {/* <ViewUser data={record} /> */}
          </span>
        ),
      },
    ]

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>My Employee's Forms</Breadcrumb.Item>
        </Breadcrumb>
        <div className='compTable' style={{ marginTop: '30px' }}>
          <Table
            columns={columns}
            dataSource={this.state.data}
            pagination={false}
            loading={this.state.data === null}
            size={this.state.size <= 768 ? 'small' : 'middle'}
          />
          {/* </div> */}
        </div>

        <Modal
          maskClosable={false}
          title='Submitted Form'
          open={this.state.open}
          width={1000}
          style={{ top: 20 }}
          onCancel={() => this.setState({ open: false })}
          footer={[
            <Button
              key={'close'}
              onClick={() => this.setState({ open: false })}
              type='danger'
              ghost
            >
              Close
            </Button>,
          ]}
        >
          <ViewForm user={this.state.selected} viewer='others' />
        </Modal>
      </>
    )
  }
}
