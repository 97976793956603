/**
 * Title: PageLoading.js
 * Description: This is a file that contains the components for the Page Loading of the Personal Data Sheet.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/PageLoading.js
 **/
export default function PageLoading({ switchMsg, message }) {
  return (
    <>
      <span>{switchMsg ? `${message}` : 'Loading...'}</span>
    </>
  )
}
