/**
 * Title: employee.js
 * Description: This is a file that contains the employee Table
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Employee/employee.js
 **/

import { Table, Tag, Breadcrumb } from 'antd'
import React from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import { listUsers } from '../../api/queries'
import ViewUser from '../Users/viewUser'

export default class Employee extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      open: false,
      selected: [],
      name: '',
      disabled: false,
      docs: [],
      openEdit: false,
    }
  }

  componentDidMount() {
    this.listUser()

    this.setState({ size: window.innerWidth })

    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    this.setState({ size: window.innerWidth })
  }

  listUser = async () => {
    const user = this.props.userData
    let arr = []
    API.graphql(
      graphqlOperation(listUsers, {
        filter: {
          status: { contains: 'Approved' },
          and: {
            role: { notContains: 'admin' },
            and: {
              role: { notContains: 'supervisor' },
              and: {
                department: { contains: user ? user.department : '' },
                and: {
                  division: { contains: user ? user.division : '' },
                  and: {
                    assignedDept: { contains: user ? user.assignedDept : '' },
                  },
                },
              },
            },
          },
        },
      })
    )
      .then((res) => {
        let filter = res.data.listUsers.items

        if (filter.length) {
          filter.forEach((item, i) => {
            arr.push({ ...item, key: i + 1 })
          })
        }
      })

      .then(() => {
        this.setState({ data: arr })
      })
  }

  render() {
    const columns = [
      {
        title: 'No.',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <span>
            <b>{`${record.name} ${record.mname} ${record.lname}`}</b>
          </span>
        ),
      },
      {
        title: 'Home Department',
        dataIndex: 'department',
        key: 'department',
        render: (text, record) => (
          <Tag color='geekblue'>{record.department}</Tag>
        ),
      },
      {
        title: 'Assigned Department',
        dataIndex: 'assignedDept',
        key: 'assignedDept',
        render: (text, record) => (
          <Tag color='geekblue'>{record.assignedDept}</Tag>
        ),
      },
      {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        render: (text, record) => <Tag color='geekblue'>{record.division}</Tag>,
      },
      {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        render: (text, record) => (
          <span>
            <b
              style={{ textTransform: 'capitalize' }}
            >{`${record.position}`}</b>
          </span>
        ),
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (text, record) => (
          <span>
            <b style={{ textTransform: 'capitalize' }}>{`${record.role}`}</b>
          </span>
        ),
      },

      {
        title: 'Date created',
        dataIndex: 'date',
        key: 'locale',
      },

      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <ViewUser data={record} />
          </span>
        ),
      },
    ]

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>My Employee</Breadcrumb.Item>
        </Breadcrumb>
        <div className='compTable' style={{ marginTop: '30px' }}>
          <Table
            columns={columns}
            dataSource={this.state.data}
            pagination={false}
            loading={this.state.data === null}
            size={this.state.size <= 768 ? 'small' : 'middle'}
          />
          {/* </div> */}
        </div>
      </>
    )
  }
}
