import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { DynamoDBClient, ScanCommand } from '@aws-sdk/client-dynamodb'
import { Row, Col, Card, Button, Spin, DatePicker, notification } from 'antd'

// generate JSDOC comment or documentation for this function with @description tag
/**
 * FormCounter component
 * @description FormCounter component is a component that displays the total number of submitted e-TNA and uploaded ILDP
 * @returns {JSX.Element} JSX.Element
 */
const FormCounter = () => {
  const [totalAllTime, setTotalAllTime] = useState(null)
  const [totalCurrentPeriod, setTotalCurrentPeriod] = useState(null)
  const [totalLastPeriod, setTotalLastPeriod] = useState(null)
  const [startDateETNA, setStartDateETNA] = useState(null)
  const [endDateETNA, setEndDateETNA] = useState(null)
  const [loadingETNA, setLoadingETNA] = useState(true)
  const [loadingCurrentETNA, setLoadingCurrentETNA] = useState(false)

  const [totalAllTimeILDP, setTotalAllTimeILDP] = useState(null)
  const [totalCurrentPeriodILDP, setTotalCurrentPeriodILDP] = useState(null)
  const [totalLastPeriodILDP, setTotalLastPeriodILDP] = useState(null)
  const [startDateILDP, setStartDateILDP] = useState(null)
  const [endDateILDP, setEndDateILDP] = useState(null)
  const [loadingILDP, setLoadingILDP] = useState(true)
  const [loadingCurrentILDP, setLoadingCurrentILDP] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchTotalItemCount(),
        fetchTotalItemCountILDP(),
        fetchTotalItemCountFromLastPeriod(),
        fetchTotalItemCountFromLastPeriodILDP(),
      ])
      setLoadingETNA(false)
      setLoadingILDP(false)
    }
    fetchData()
  }, [])

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * fetchTotalItemCount function
   * @description function that retrieves the total number of submitted e-TNA and uploaded ILDP
   * @returns {Promise<void>} Promise<void>
   * @async
   * @function fetchTotalItemCount
   * @param {void} - no parameter
   * @throws {Error} Logs any error that occurs during execution to the console.

   */
  const fetchTotalItemCount = async () => {
    try {
      setLoadingETNA(true)
      const credentials = await Auth.currentCredentials()
      const client = new DynamoDBClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })

      const tableName = 'Form-wl3wmchpibbwrdqtw6mcjsbnmm-dev'
      const scanParams = {
        TableName: tableName,
        Select: 'COUNT',
      }

      let totalCount = 0
      let token = true
      do {
        const scanCommand = new ScanCommand(scanParams)
        const response = await client.send(scanCommand)
        totalCount += parseInt(response.Count)

        if (response.LastEvaluatedKey) {
          scanParams.ExclusiveStartKey = response.LastEvaluatedKey
        } else {
          token = false
        }
      } while (token)

      setTotalAllTime(totalCount)
      setLoadingETNA(false)
    } catch (error) {
      console.error('Error fetching total item count', error)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * fetchTotalItemCountILDP function
   * @description function that retrieves the total number of uploaded ILDP
   * @returns {Promise<void>} Promise<void>
   * @async
   * @function fetchTotalItemCountILDP
   * @param {void} - no parameter
   * @throws {Error} Logs any error that occurs during execution to the console.
   */
  const fetchTotalItemCountILDP = async () => {
    try {
      setLoadingILDP(true)
      const credentials = await Auth.currentCredentials()
      const client = new DynamoDBClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })

      const tableName = 'IDLPUpload-wl3wmchpibbwrdqtw6mcjsbnmm-dev'
      const scanParams = {
        TableName: tableName,
        Select: 'COUNT',
      }

      let totalCount = 0
      let token = true
      do {
        const scanCommand = new ScanCommand(scanParams)
        const response = await client.send(scanCommand)
        totalCount += parseInt(response.Count)

        if (response.LastEvaluatedKey) {
          scanParams.ExclusiveStartKey = response.LastEvaluatedKey
        } else {
          token = false
        }
      } while (token)

      setTotalAllTimeILDP(totalCount)
      setLoadingILDP(false)
    } catch (error) {
      console.error('Error fetching total item count ILDP', error)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * fetchTotalItemCountFromLastPeriod function
   * @description function that retrieves the total number of submitted e-TNA from the last period
   * @returns {Promise<void>} Promise<void>
   * @async
   * @function fetchTotalItemCountFromLastPeriod
   * @param {void} - no parameter
   * @throws {Error} Logs any error that occurs during execution to the console.
   */
  const fetchTotalItemCountFromLastPeriod = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const client = new DynamoDBClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })

      const tableName = 'Form-wl3wmchpibbwrdqtw6mcjsbnmm-dev'
      const currentYear = new Date().getFullYear()
      const startDate = new Date(currentYear - 1, 6, 1)
      const endDate = new Date(currentYear, 5, 30, 23, 59, 59, 999)

      const scanParams = {
        TableName: tableName,
        Select: 'COUNT',
        FilterExpression: 'createdAt BETWEEN :startDate AND :endDate',
        ExpressionAttributeValues: {
          ':startDate': { S: startDate.toISOString() },
          ':endDate': { S: endDate.toISOString() },
        },
      }

      let totalCount = 0
      let token = true
      do {
        const scanCommand = new ScanCommand(scanParams)
        const response = await client.send(scanCommand)
        totalCount += parseInt(response.Count)

        if (response.LastEvaluatedKey) {
          scanParams.ExclusiveStartKey = response.LastEvaluatedKey
        } else {
          token = false
        }
      } while (token)

      setTotalLastPeriod(totalCount)
    } catch (error) {
      console.error('Error fetching total item count from last period', error)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * fetchTotalItemCountFromLastPeriodILDP function
   * @description function that retrieves the total number of uploaded ILDP from the last period
   * @returns {Promise<void>} Promise<void>
   * @async
   * @function fetchTotalItemCountFromLastPeriodILDP
   * @param {void} - no parameter
   * @throws {Error} Logs any error that occurs during execution to the console.
   */
  const fetchTotalItemCountFromLastPeriodILDP = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const client = new DynamoDBClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })

      const tableName = 'IDLPUpload-wl3wmchpibbwrdqtw6mcjsbnmm-dev'
      const currentYear = new Date().getFullYear()
      const startDate = new Date(currentYear - 1, 6, 1)
      const endDate = new Date(currentYear, 5, 30, 23, 59, 59, 999)

      const scanParams = {
        TableName: tableName,
        Select: 'COUNT',
        FilterExpression: 'createdAt BETWEEN :startDate AND :endDate',
        ExpressionAttributeValues: {
          ':startDate': { S: startDate.toISOString() },
          ':endDate': { S: endDate.toISOString() },
        },
      }

      let totalCount = 0
      let token = true
      do {
        const scanCommand = new ScanCommand(scanParams)
        const response = await client.send(scanCommand)
        totalCount += parseInt(response.Count)

        if (response.LastEvaluatedKey) {
          scanParams.ExclusiveStartKey = response.LastEvaluatedKey
        } else {
          token = false
        }
      } while (token)

      setTotalLastPeriodILDP(totalCount)
    } catch (error) {
      console.error(
        'Error fetching total item count from last period ILDP',
        error
      )
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * fetchTotalItemCountFromCurrentPeriodETNA function
   * @description function that retrieves the total number of submitted e-TNA from the current period
   * @returns {Promise<void>} Promise<void>
   * @async
   * @function fetchTotalItemCountFromCurrentPeriodETNA
   * @param {Date} startDate - start date
   * @throws {Error} Logs any error that occurs during execution to the console.
   */
  const fetchTotalItemCountFromCurrentPeriodETNA = async (
    startDate,
    endDate
  ) => {
    try {
      const credentials = await Auth.currentCredentials()
      const client = new DynamoDBClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })

      const tableName = 'Form-wl3wmchpibbwrdqtw6mcjsbnmm-dev'
      const scanParams = {
        TableName: tableName,
        Select: 'COUNT',
        FilterExpression: 'createdAt BETWEEN :startDate AND :endDate',
        ExpressionAttributeValues: {
          ':startDate': { S: startDate.toISOString() },
          ':endDate': { S: endDate.toISOString() },
        },
      }

      let totalCount = 0
      let token = true
      do {
        const scanCommand = new ScanCommand(scanParams)
        const response = await client.send(scanCommand)
        totalCount += parseInt(response.Count)

        if (response.LastEvaluatedKey) {
          scanParams.ExclusiveStartKey = response.LastEvaluatedKey
        } else {
          token = false
        }
      } while (token)

      setTotalCurrentPeriod(totalCount)
    } catch (error) {
      console.error(
        'Error fetching total item count from current period',
        error
      )
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * fetchTotalItemCountFromCurrentPeriodILDP function
   * @description function that retrieves the total number of uploaded ILDP from the current period
   * @returns {Promise<void>} Promise<void>
   * @async
   * @function fetchTotalItemCountFromCurrentPeriodILDP
   * @param {Date} startDate - start date
   * @throws {Error} Logs any error that occurs during execution to the console.
   */
  const fetchTotalItemCountFromCurrentPeriodILDP = async (
    startDate,
    endDate
  ) => {
    try {
      const credentials = await Auth.currentCredentials()
      const client = new DynamoDBClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: credentials.accessKeyId,
          secretAccessKey: credentials.secretAccessKey,
          sessionToken: credentials.sessionToken,
        },
      })

      const tableName = 'IDLPUpload-wl3wmchpibbwrdqtw6mcjsbnmm-dev'
      const scanParams = {
        TableName: tableName,
        Select: 'COUNT',
        FilterExpression: 'createdAt BETWEEN :startDate AND :endDate',
        ExpressionAttributeValues: {
          ':startDate': { S: startDate.toISOString() },
          ':endDate': { S: endDate.toISOString() },
        },
      }

      let totalCount = 0
      let token = true
      do {
        const scanCommand = new ScanCommand(scanParams)
        const response = await client.send(scanCommand)
        totalCount += parseInt(response.Count)

        if (response.LastEvaluatedKey) {
          scanParams.ExclusiveStartKey = response.LastEvaluatedKey
        } else {
          token = false
        }
      } while (token)

      setTotalCurrentPeriodILDP(totalCount)
    } catch (error) {
      console.error(
        'Error fetching total item count from current period ILDP',
        error
      )
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * handleFilterClickETNA function
   * @description function that handles the filter click event for e-TNA forms
   * @returns {void} - no return value
   * @function handleFilterClickETNA
   * @async
   * @param {void} - no parameter
   * @throws {Error} Logs any error that occurs during execution to the console.
   */
  const handleFilterClickETNA = async () => {
    try {
      if (!startDateETNA || !endDateETNA) {
        notification.error({
          message: 'Invalid Dates',
          description: 'Please provide valid start and end dates.',
          duration: 3,
        })
        return
      }

      // Check if the start date is the same as the end date (single day filter)
      const isSameDay = startDateETNA.isSame(endDateETNA, 'day')

      setLoadingCurrentETNA(true)
      if (isSameDay) {
        // Filter for a specific day
        const selectedDate = startDateETNA.startOf('day').toDate()
        const endOfDay = startDateETNA.endOf('day').toDate()
        await fetchTotalItemCountFromCurrentPeriodETNA(selectedDate, endOfDay)
      } else {
        // Filter for a date range
        await fetchTotalItemCountFromCurrentPeriodETNA(
          startDateETNA.toDate(),
          endDateETNA.toDate()
        )
      }
      setLoadingCurrentETNA(false)
    } catch (error) {
      console.error('Error filtering e-TNA forms', error)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * handleFilterClickILDP function
   * @description function that handles the filter click event for ILDP forms
   * @returns {void} - no return value
   * @function handleFilterClickILDP
   * @async
   * @param {void} - no parameter
   * @throws {Error} Logs any error that occurs during execution to the console.
   */
  const handleFilterClickILDP = async () => {
    try {
      if (!startDateILDP || !endDateILDP) {
        notification.error({
          message: 'Invalid Dates',
          description: 'Please provide valid start and end dates.',
          duration: 3,
        })
        return
      }

      // Check if the start date is the same as the end date (single day filter)
      const isSameDay = startDateILDP.isSame(endDateILDP, 'day')

      setLoadingCurrentILDP(true)
      if (isSameDay) {
        // Filter for a specific day
        const selectedDate = startDateILDP.startOf('day').toDate()
        const endOfDay = startDateILDP.endOf('day').toDate()
        await fetchTotalItemCountFromCurrentPeriodILDP(selectedDate, endOfDay)
      } else {
        // Filter for a date range
        await fetchTotalItemCountFromCurrentPeriodILDP(
          startDateILDP.toDate(),
          endDateILDP.toDate()
        )
      }
      setLoadingCurrentILDP(false)
    } catch (error) {
      console.error('Error filtering ILDP forms', error)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * clearFilterETNA function
   * @description function that clears the filter for e-TNA forms
   * @returns {void} - no return value
   * @function clearFilterETNA
   * @async
   * @param {void} - no parameter
   * @throws {Error} Logs any error that occurs during execution to the console.
   */
  const clearFilterETNA = () => {
    try {
      setStartDateETNA(null)
      setEndDateETNA(null)
      setTotalCurrentPeriod(null)
    } catch (error) {
      console.error('Error clearing filter for e-TNA forms', error)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * clearFilterILDP function
   * @description function that clears the filter for ILDP forms
   * @returns {void} - no return value
   * @function clearFilterILDP
   * @async
   * @param {void} - no parameter
   */
  const clearFilterILDP = () => {
    try {
      setStartDateILDP(null)
      setEndDateILDP(null)
      setTotalCurrentPeriodILDP(null)
    } catch (error) {
      console.error('Error clearing filter for ILDP forms', error)
    }
  }

  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Card
            style={cardStyle}
            title={
              <h3 className='text-center text-white fw-bold'>
                Submitted e-TNA Forms
              </h3>
            }
            className='custom-card'
            headStyle={{ backgroundColor: '#002165', color: '#ffffff' }}
            bodyStyle={{ textAlign: 'center', minHeight: '200px' }}
          >
            {loadingETNA ? (
              <Spin />
            ) : (
              <>
                <div className='d-flex justify-content-between mb-4'>
                  <p>
                    All-Time Total:{' '}
                    <span className='fw-bold'>{totalAllTime}</span>
                  </p>

                  <p>
                    Last Period Total:{' '}
                    <span className='fw-bold'>{totalLastPeriod}</span>
                  </p>
                </div>
                <Row className='d-flex justify-content-between'>
                  <Col span={11}>
                    <DatePicker
                      style={{ width: '100%', marginBottom: '10px' }}
                      placeholder='Start Date'
                      value={startDateETNA}
                      onChange={(date) => setStartDateETNA(date)}
                    />
                  </Col>
                  <Col span={11}>
                    {' '}
                    <DatePicker
                      style={{ width: '100%', marginBottom: '10px' }}
                      placeholder='End Date'
                      value={endDateETNA}
                      onChange={(date) => setEndDateETNA(date)}
                    />
                  </Col>
                </Row>
                <Button
                  type='primary'
                  onClick={handleFilterClickETNA}
                  style={{ width: '100%' }}
                >
                  Enter
                </Button>
                <Button
                  onClick={clearFilterETNA}
                  style={{ marginTop: '10px', width: '100%' }}
                >
                  Clear Filter
                </Button>
                <Card
                  style={{
                    marginTop: '10px',
                    padding: '10px',
                    textAlign: 'center',
                  }}
                >
                  <p>Current Period Total:</p>
                  {loadingCurrentETNA ? (
                    <Spin />
                  ) : (
                    <h3 className='fw-bold'>{totalCurrentPeriod}</h3>
                  )}
                </Card>
              </>
            )}
          </Card>
        </Col>
        <Col span={12}>
          <Card
            style={cardStyle}
            title={
              <h3 className='text-center text-white fw-bold'>
                Uploaded ILDP Forms
              </h3>
            }
            className='custom-card'
            headStyle={{ backgroundColor: '#002165', color: '#ffffff' }}
            bodyStyle={{ textAlign: 'center', minHeight: '200px' }}
          >
            {loadingILDP ? (
              <Spin />
            ) : (
              <>
                <div className='d-flex justify-content-between mb-4'>
                  <p>
                    All-Time Total:{' '}
                    <span className='fw-bold'>{totalAllTimeILDP}</span>
                  </p>

                  <p>
                    Last Period Total:{' '}
                    <span className='fw-bold'>{totalLastPeriodILDP}</span>
                  </p>
                </div>
                <Row className='d-flex justify-content-between'>
                  <Col span={11}>
                    <DatePicker
                      style={{ width: '100%', marginBottom: '10px' }}
                      placeholder='Start Date'
                      value={startDateILDP}
                      onChange={(date) => setStartDateILDP(date)}
                    />
                  </Col>
                  <Col span={11}>
                    {' '}
                    <DatePicker
                      style={{ width: '100%', marginBottom: '10px' }}
                      placeholder='End Date'
                      value={endDateILDP}
                      onChange={(date) => setEndDateILDP(date)}
                    />
                  </Col>
                </Row>
                <Button
                  type='primary'
                  onClick={handleFilterClickILDP}
                  style={{ width: '100%' }}
                >
                  Enter
                </Button>
                <Button
                  onClick={clearFilterILDP}
                  style={{ marginTop: '10px', width: '100%' }}
                >
                  Clear Filter
                </Button>

                <Card
                  style={{
                    marginTop: '10px',
                    padding: '10px',
                    textAlign: 'center',
                  }}
                >
                  <p>Current Period Total:</p>
                  {loadingCurrentILDP ? (
                    <Spin />
                  ) : (
                    <h3 className='fw-bold'>{totalCurrentPeriodILDP}</h3>
                  )}
                </Card>
              </>
            )}
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default FormCounter

const cardStyle = {
  backgroundColor: '#002165',
  color: '#ffffff',
  textAlign: 'center',
  minHeight: '200px',
}
