/**
 * Title: activityOneDownload.js
 * Description: This is a file that contains activity one download
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/activityOneDownload.js
 **/

import React, { useState, useEffect, Suspense, useRef } from 'react'

import {
  Button,
  Modal,
  Col,
  Row,
  Table,
  notification,
  Tooltip,
  Space,
  Input,
} from 'antd'
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons'

import Highlighter from 'react-highlight-words'

import { API, graphqlOperation } from 'aws-amplify'
import { registrationByTAID, listActivityOneAnswers } from '../../api/queries'

import { numToString } from '../../utilities/numToString'

import moment from 'moment'
import Activity1 from './activity1'

export default function ActivityOneDownload({ viewCourse, userData }) {
  const [openTableModal, setOpenTableModal] = useState(false)
  const [disableAfter, setDisableAfter] = useState(false)
  const [allRegistrants, setAllRegistrants] = useState([])
  const [allActivityAnswers, setAllActivityAnswers] = useState([])
  const [dataDownload, setDataDownload] = useState([])

  const [isTableLoading, setIsTableLoading] = useState(true)

  function formatDate(dateString) {
    const date = new Date(dateString)

    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hour = date.getHours().toString().padStart(2, '0')
    const minute = date.getMinutes().toString().padStart(2, '0')
    const second = date.getSeconds().toString().padStart(2, '0')
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM'

    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second} ${ampm}`

    return formattedDate
  }

  const filterWithAnswers = (a, b) => {
    const newB = b.map((item) => {
      let totalCorrectItem = 0
      if (item.answerOne == 'B') {
        totalCorrectItem += 1
      }
      if (item.answerTwo == 'C') {
        totalCorrectItem += 1
      }
      if (item.answerThree == 'B') {
        totalCorrectItem += 1
      }
      if (item.answerFour == 'A') {
        totalCorrectItem += 1
      }
      if (item.answerFive == 'B') {
        totalCorrectItem += 1
      }
      if (item.answerSix == 'B') {
        totalCorrectItem += 1
      }
      if (item.answerSeven == 'A') {
        totalCorrectItem += 1
      }
      if (item.answerEight == 'A') {
        totalCorrectItem += 1
      }
      if (item.answerNine == 'A') {
        totalCorrectItem += 1
      }
      if (item.answerTen == 'A') {
        totalCorrectItem += 1
      }
      if (item.answerEleven == 'A') {
        totalCorrectItem += 1
      }
      if (item.answerTwelve == 'A') {
        totalCorrectItem += 1
      }
      if (item.answerThirteen == 'B') {
        totalCorrectItem += 1
      }
      if (item.answerFourteen == 'A') {
        totalCorrectItem += 1
      }
      if (item.answerFifteen == 'A') {
        totalCorrectItem += 1
      }
      return {
        answerOne: item.answerOne,
        answerTwo: item.answerTwo,
        answerThree: item.answerThree,
        answerFour: item.answerFour,
        answerFive: item.answerFive,
        answerSix: item.answerSix,
        answerSeven: item.answerSeven,
        answerEight: item.answerEight,
        answerNine: item.answerNine,
        answerTen: item.answerTen,
        answerEleven: item.answerEleven,
        answerTwelve: item.answerTwelve,
        answerThirteen: item.answerThirteen,
        answerFourteen: item.answerFourteen,
        answerFifteen: item.answerFifteen,
        userID: item.userID,
        totalScore: totalCorrectItem,
        timeStart: formatDate(item.createdAt),
        timeSubmitted: item.timeSubmitted,
      }
    })
    setIsTableLoading(false)

    const filterIds = newB.map((item) => item.userID)
    const combinedArray = a
      .filter((item) => filterIds.includes(item.registeredUser.id))
      .map((item) => {
        const filterItem = newB.find(
          (filter) => filter.userID === item.registeredUser.id
        )
        return { ...item, ...filterItem }
      })
    setDataDownload(combinedArray)
  }

  const getAllActivityOneAnswers = async (a) => {
    try {
      let items = []
      let nextToken = null
      const limit = 1000

      do {
        const variables = { limit, nextToken }
        const result = await API.graphql(
          graphqlOperation(listActivityOneAnswers, variables)
        )
        items.push(...result.data.listActivityOneAnswers.items)
        nextToken = result.data.listActivityOneAnswers.nextToken
      } while (nextToken)

      const filterByTrainingAnnouncementID = items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
              'c822d184-b65a-4a25-8079-89e895685546' ||
            item.trainingAnnouncementID ==
              'cdb10950-01b5-428a-9959-0fc47237b528'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted)
          const timeSubmittedB = moment(b.timeSubmitted)

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      const filterFinalAnswers = filterByTrainingAnnouncementID
        .filter((item) => {
          if (item.isSubmittedByUser == true) {
            return {
              ...item,
            }
          }
        })
        .map((item) => {
          return {
            ...item,
            answerOne: JSON.parse(item.answer).Question1,
            answerTwo: JSON.parse(item.answer).Question2,
            answerThree: JSON.parse(item.answer).Question3,
            answerFour: JSON.parse(item.answer).Question4,
            answerFive: JSON.parse(item.answer).Question5,
            answerSix: JSON.parse(item.answer).Question6,
            answerSeven: JSON.parse(item.answer).Question7,
            answerEight: JSON.parse(item.answer).Question8,
            answerNine: JSON.parse(item.answer).Question9,
            answerTen: JSON.parse(item.answer).Question10,
            answerEleven: JSON.parse(item.answer).Question11,
            answerTwelve: JSON.parse(item.answer).Question12,
            answerThirteen: JSON.parse(item.answer).Question13,
            answerFourteen: JSON.parse(item.answer).Question14,
            answerFifteen: JSON.parse(item.answer).Question15,
            timeStart: item.createdAt,
            timeSubmitted: item.timeSubmitted,
          }
        })
      filterWithAnswers(a, filterFinalAnswers)
      setAllActivityAnswers(filterFinalAnswers)
    } catch (err) {
      console.log(err)
    }
  }

  console.log(allActivityAnswers)

  const getAllRegistrants = async () => {
    try {
      const checkLength = await API.graphql(
        graphqlOperation(registrationByTAID, {
          trainingAnnouncementID: viewCourse.id,
          limit: 1000,
        })
      )
      if (checkLength.data.registrationByTAID.items.length) {
        const withCount = checkLength.data.registrationByTAID.items
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .map((obj, index) => {
            return {
              ...obj,
              count: index + 1,
            }
          })

        if (withCount.length) {
          setAllRegistrants(
            withCount.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          )
          getAllActivityOneAnswers(
            withCount.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          )
        } else {
          setOpenTableModal(false)
        }
      } else {
        notification['info']({
          message: 'Info',
          description: 'No registrants for this event.',
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'count',
      key: 'count',
      width: '5%',
      render: (text, record) => (
        <span>
          <b>{record.count}</b>
        </span>
      ),
    },
    {
      title: 'FULL NAME',
      dataIndex: 'fullName',
      key: 'name',
      width: '20%',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      render: (text, record) =>
        record.fullName ? (
          <span>
            <b className='text-capitalize'>{record.fullName.toLowerCase()}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'NICKNAME',
      dataIndex: 'nickname',
      key: 'nickname',
      width: '10%',
      render: (text, record) =>
        record.nickname ? (
          <span>
            <b className='text-capitalize'>{record.nickname.toLowerCase()}</b>
          </span>
        ) : (
          'no nickname'
        ),
    },
    {
      title: 'EMAIL ADDRESS',
      width: '15%',
      render: (text, record) =>
        record.emailAddress ? (
          <span color='geekblue'>
            <b>{`${record.emailAddress}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE',
      width: '15%',
      render: (text, record) =>
        record.dept_office_unit_taskForce ? (
          <span>
            <b>{`${record.dept_office_unit_taskForce}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'DIVISION / SECTION',
      width: '10%',
      render: (text, record) =>
        record.division_section ? (
          <span>
            <b>{`${record.division_section}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'DESIGNATION/POSITION',
      width: '10%',
      render: (text, record) =>
        record.designation_position ? (
          <span>
            <b>{`${record.designation_position}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'Activity 1 Answer',
      width: '10%',
      render: (text, record) => {
        const foundAcct = allActivityAnswers.filter((iden) => {
          if (record.registeredUser.id === iden.userID) {
            return JSON.parse(iden.answer)
          }
        })

        return (
          <>
            {foundAcct.length ? (
              <span>
                <Activity1 userData={userData} viewUserAnswer={foundAcct} />
              </span>
            ) : (
              'no answer'
            )}
          </>
        )
      },
    },
  ]

  const handleOpen = () => {
    getAllRegistrants()
    setOpenTableModal(true)
  }

  const handleDisable = () => {
    if (allRegistrants.length) {
      if (disableAfter == false) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  const handleDownload = async () => {
    try {
      if (dataDownload.length) {
        const newData = dataDownload.map((item) => {
          const {
            id,
            isAgreeDataPrivacyConsent,
            fullName,
            emailAddress,
            gender,
            contactNumber,
            dept_office_unit_taskForce,
            division_section,
            designation_position,
            endorsementLetter,
            nickname,
            expectedOutcomes,
            answerOne,
            answerTwo,
            answerThree,
            answerFour,
            answerFive,
            answerSix,
            answerSeven,
            answerEight,
            answerNine,
            answerTen,
            answerEleven,
            answerTwelve,
            answerThirteen,
            answerFourteen,
            answerFifteen,
            totalScore,
            timeStart,
            timeSubmitted,
          } = item

          return {
            'Data Privacy Consent': isAgreeDataPrivacyConsent,
            'FULL NAME': fullName,
            NICKNAME: nickname,
            'CONTACT NUMBER': contactNumber,
            'DEPARTMENT / OFFICE/ UNIT/ TASK FORCE': dept_office_unit_taskForce,
            'DESIGNATION / POSITION': designation_position,
            'DIVISION / SECTION': division_section,
            'EMAIL ADDRESS': emailAddress,
            SEX: gender,
            'Endorsement Letter': endorsementLetter,
            'Answer no. 1': answerOne,
            'Answer no. 2': answerTwo,
            'Answer no. 3': answerThree,
            'Answer no. 4': answerFour,
            'Answer no. 5': answerFive,
            'Answer no. 6': answerSix,
            'Answer no. 7': answerSeven,
            'Answer no. 8': answerEight,
            'Answer no. 9': answerNine,
            'Answer no. 10': answerTen,
            'Answer no. 11': answerEleven,
            'Answer no. 12': answerTwelve,
            'Answer no. 13': answerThirteen,
            'Answer no. 14': answerFourteen,
            'Answer no. 15': answerFifteen,
            'Total Score': totalScore,
            'Time Started': timeStart,
            'Time Submitted': timeSubmitted,
          }
        })

        const csvHeader = Object.keys(newData[0])
        const csvValue = newData.map((item) => Object.values(item))
        const csvRows = csvValue.map((row) =>
          row
            .map((value) => `"${String(value).replace(/\n/g, '\r')}"`)
            .join(';')
        )
        const csvData = csvHeader.join(';') + '\n' + csvRows.join('\n')
        const afterDownload = function () {
          notification['success']({
            message: 'Success',
            description: 'Excel file downloaded.',
          })

          setTimeout(() => {
            setOpenTableModal(false)
            setDisableAfter(false)
            setAllRegistrants([])
            setAllActivityAnswers([])
            setDataDownload([])
            setIsTableLoading(true)
          }, 3000)
        }

        const downloadCSV = (csvData, fileName) => {
          const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
            csvData
          )}`
          const link = document.createElement('a')
          link.setAttribute('href', csvContent)
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          afterDownload()
        }

        downloadCSV(
          csvData,
          `${viewCourse.title ? viewCourse.title : ''} - Activity 1 Answers`
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleCancelModal = () => {
    setOpenTableModal(false)
    setDisableAfter(false)
    setIsTableLoading(true)
    setAllRegistrants([])
    setAllActivityAnswers([])
    setDataDownload([])
  }

  return (
    <>
      <Button onClick={handleOpen}>Download activity 1 answers</Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={'Registrants'}
        centered
        open={openTableModal}
        onOk={() => setOpenTableModal(false)}
        onCancel={handleCancelModal}
        width={'100%'}
        footer={null}
      >
        <Row>
          <Table
            loading={isTableLoading}
            bordered
            rowKey='id'
            // dataSource={selectArray()}
            dataSource={allRegistrants}
            columns={columns}
            showSizeChanger={false}
            pagination={{ pageSize: 50 }}
            className='w-100 table-responsive'
          />
        </Row>
        <Row>
          {allRegistrants.length ? (
            <>
              <Button
                disabled={handleDisable()}
                onClick={handleDownload}
                type='primary'
                icon={<DownloadOutlined />}
                size={'large'}
              >
                Download
              </Button>
            </>
          ) : null}
        </Row>
      </Modal>
    </>
  )
}
