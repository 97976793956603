/**
 * Title: CSCOnlineTrainingSept18.js
 * Description: This is a file that contains the CSC Online Training for September 18, 2023
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/CSCOnlineTrainingSept18.js
 **/

import { useState, useEffect, useRef } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  ActivityFourAnswerByUserID,
  ActivityOneAnswerByUserID,
  ActivityThreeAnswerByUserID,
  ActivityTwoAnswerByUserID,
  listActivityFourAnswers,
  listActivityOneAnswers,
  listActivityThreeAnswers,
  listActivityTwoAnswers,
  listTrainingVideos,
} from '../../api/queries'
import { deleteTrainingVideo } from '../../api/mutations'

import moment from 'moment'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  PlayCircleFilled,
} from '@ant-design/icons'
import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  Row,
  Col,
} from 'antd'

import VideoPlayer from './videoPlayer'

import './strategicPerformanceManagementSystem.css'
import Activity1 from './activity1'
import Activity2 from './activity2'
import ActivityQuestionModalThree from './activityQuestionModalThree'
import ActivityQuestionModalFour from './activityQuestionModalFour.js'
import listOfDepartments from '../../utilities/dept'

const { Option } = Select

export default function CSCOnlineTrainingSept8({ userData }) {
  const userInfo = userData
  const userFullName =
    userInfo?.name + ' ' + userInfo?.mname + ' ' + userInfo?.lname

  const user = userData

  const [name, setName] = useState(userFullName)
  const [department, setDepartment] = useState(userInfo?.assignedDept)
  const [uploadedTrainingVideo, setUploadedTrainingVideo] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [videoKey, setVideoKey] = useState('')
  const [isAgree, setIsAgree] = useState(true)
  const [pageState, setPageState] = useState(null)
  const [openModal, setOpenModal] = useState(true)
  const [videoFinishWatching, setvideoFinishWatching] = useState([])
  const [isModalActivityOpen, setIsModalActivityOpen] = useState(false)
  const [progressActivity, setProgressActivity] = useState('1')
  const [isWatchedVideoOne, setIsWatchedVideoOne] = useState(false)
  const [isWatchedVideoTwo, setIsWatchedVideoTwo] = useState(false)
  const [isWatchedVideoThree, setIsWatchedVideoThree] = useState(false)
  const [isWatchedVideoFour, setIsWatchedVideoFour] = useState(false)
  const [transcriptActivity, setTranscriptActivity] = useState(
    'transcriptActivity1'
  )
  const [isInstructionModalVisible, setIsInstructionModalVisible] =
    useState(true)
  const [isCompleteMsgModalVisible, setIsCompleteMsgModalVisible] =
    useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const getActivityOneAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityOneAnswerByUserID, { userID: user.id })
      )

      const itemsFetched = dataFetched.data.ActivityOneAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            'cdb10950-01b5-428a-9959-0fc47237b528'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('2')
        } else {
          setIsWatchedVideoOne(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityTwoAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityTwoAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityTwoAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            'cdb10950-01b5-428a-9959-0fc47237b528'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('3')
        } else {
          setIsWatchedVideoTwo(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityThreeAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityThreeAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityThreeAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            'cdb10950-01b5-428a-9959-0fc47237b528'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('alldone')
        } else {
          setIsWatchedVideoThree(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityFourAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityFourAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityFourAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            'cdb10950-01b5-428a-9959-0fc47237b528'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('4')
        } else {
          setIsWatchedVideoFour(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (progressActivity == 1) {
      setTranscriptActivity(transcriptActivity1)
    }
    if (progressActivity == 2) {
      setTranscriptActivity(transcriptActivity2)
    }
    if (progressActivity == 3) {
      setTranscriptActivity(transcriptActivity3)
    }
    if (progressActivity == 4) {
      setTranscriptActivity(transcriptActivity4)
    }
  }, [progressActivity])

  const fetchAllUserActivityProgress = async () => {
    setIsLoading(true)
    await getActivityOneAnswerByUserID()
    await getActivityTwoAnswerByUserID()
    await getActivityFourAnswerByUserID()
    await getActivityThreeAnswerByUserID()
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAllUserActivityProgress()
  }, [])

  const englishVideos = ['BW-Part 1', 'BW-Part 2', 'BW-Part 3', 'BW-Part 4']

  const dept = listOfDepartments()

  const transcriptActivity1 = () => {
    return (
      <>
        <p>
          When writing a memo, an email, or an essay, the most basic element of
          grammar that needs to be considered is the subject-verb agreement.
        </p>
        <p>
          The odd thing is that despite it being one of the fundamentals of
          grammar, it is also the area where most errors happen. And if I may
          add, when it does happen, people often are unaware of the errors.
        </p>
        <p>
          So what really is subject-verb agreement? Do we even understand its
          rules, let alone what it truly means?
        </p>
        <p>
          To explain subject-verb agreement in its simplest terms, it means that
          when the subject of the sentence is singular, then the verb must also
          be singular. When the subject is plural, then it necessarily follows
          that the verb should be plural.
        </p>
        <p>
          But before we go on discussing what the singular and plural forms of
          subjects and verbs are, let us first define some key terms.
        </p>
        <p>
          When we say subject, we pertain to who or what the sentence speaks
          about. The subject could be either a noun or pronoun. The subject is
          the one executing or doing the verb in the sentence.
        </p>
        <p>
          Now, when we talk about the verb, we are referring to the action that
          the subject is doing or what the sentence is saying about the subject.
          The verb is the word that expresses an action or a state of being.{' '}
        </p>
        <p>An example of state of being is the word “is”.</p>
        <p>
          So in the sentence “ The man is unhappy”, the subject is the word
          “man” because that is what the sentence is talking about, while the
          verb which expresses a state of being will be the word “is”.
        </p>
        <p>
          There are a number of easy to remember rules that I want everyone to
          know by heart.
        </p>
        <p>
          A noun, when it is plural, often has an “S” or “ES” at the end.
          Although this is not an absolute rule, in most cases, this is true.
        </p>
        <p>
          So to get the plural form of DOG, you just add S and the word becomes
          DOGS.
        </p>
        <p>
          To get the plural form of TOMATO, you just add ES and the word becomes
          TOMATOES.
        </p>
        <p>
          On the other hand, when a verb is singular, that is when you add S.
          When the verb is plural, there should be no S at the end of the word.
        </p>
        <p>
          So the verb BARK is plural. When you add an S to it, the word becomes
          BARKS which is now in its singular form.
        </p>
        <p>
          Another example is the plural verb LOOK. When an S is added at the
          end, it becomes LOOKS, which makes it singular.
        </p>
        <p>
          Let us now combine the subjects and the verbs we discussed in our
          examples and see how they go together when used in sentences.
        </p>
        <p>The dog barks fiercely</p>
        <p>
          In the example, the subject is DOG and it is singular. It does not
          have an S. The verb that tells what the subject is doing is BARKS. It
          is also singular. It has an S at the end.’
        </p>
        <p>
          So if we make the subject and verb plural, the sentence will be this:
        </p>
        <p>The dogs bark fiercely. </p>
        <p>
          The plural subject DOGS has an S while the plural verb BARK does not
          have an S.
        </p>
        <p>Let us continue with the other examples.</p>
        <p>These tomatoes look really fresh</p>
        <p>
          The subject is plural with ES at the end. The verb, LOOK, is plural
          but it does not have S.{' '}
        </p>
        <p>
          When we change the subject to singular, the sentence will be this:
        </p>
        <p>This tomato looks really fresh.</p>
        <p>Singular subject without S, plural verb with S.</p>
        <p>
          Let us now test how well you understand subject-verb agreement. In the
          next five minutes, please answer the short test that will be flashed
          on the screen. Underline the correct answer:
        </p>
      </>
    )
  }

  const transcriptActivity2 = () => {
    return (
      <>
        <p>
          What I have noticed in all forms of written office communication,
          whether thru memo or email, is that oftentimes, people use incomplete
          sentences in their paragraphs. I have even encountered phrases being
          used like these were complete sentences.
        </p>
        <p>So let us discuss this time what makes up a sentence.</p>
        <p>
          Let us start with a clause. This will be familiar to you as this
          mirrors our first topic on the subject-verb agreement.
        </p>
        <p>
          A clause is a group of words that contains a subject and a verb that
          have a relationship. It also expresses a complete thought or idea.
        </p>
        <p>
          Therein lies the difference between a clause and a phrase. Though both
          are groups of words, the phrase may not have a subject or a verb or
          may not express a complete thought.
        </p>
        <p>
          The presence of and relationship between subject and verb is of most
          importance.
        </p>
        <p>
          A clause gives information about what that subject is or is doing,
          dissimilar to a phrase which is just a random group of words.
        </p>
        <p>
          Given that a clause expresses an action or a state of being, a clause
          can often—but not always—function as an independent sentence.
        </p>
        <p>
          A sentence can have just one clause or it can have multiple clauses.
          The only defining characteristic of a clause is that it must contain a
          related subject and verb.{' '}
        </p>
        <p>
          As previously mentioned, a clause can function as its own sentence. A
          phrase will never be able to function as a complete sentence.
        </p>
        <p>
          To demonstrate the difference between a phrase and a clause, consider
          the examples below:
          <ul>
            <li>Phrase: barks fiercely</li>
            <li>Clause: The dog barks fiercely.</li>
          </ul>
        </p>
        <p>
          The first example, the phrase, has a verb, but it does not have a
          subject and it does not express a complete thought. The second
          examples ticks off all the requirements of a clause or sentence. It
          has a subject and a verb and it expresses a complete thought.
        </p>
        <p>
          You can also combine two clauses to form a complex sentence. For
          example:
          <ul>
            <li>
              The dog barks fiercely m when she sees strangers coming into the
              house.
            </li>
          </ul>
        </p>
        <p>
          In this case, there are two clauses - the dog barks fiercely, she sees
          strangers coming into the house. Connected by the word WHEN, the two
          clauses combined to form a longer sentence.
        </p>
        <p>
          Next, let us talk about the{' '}
          <strong>
            types of clauses, the independent clause and the dependent clause.
          </strong>
        </p>
        <p>
          <h6>Independent clauses</h6>{' '}
          <p>
            An independent clause is a clause that can stand on its own as a
            distinct sentence. Simply put, it does not have to be combined with
            another clause to form a complete sentence.
          </p>
        </p>
        <p>
          Check out the following examples:
          <ul>
            <li>I love Lechon.</li>
            <li>My Dad plays tennis regularly.</li>
            <li>The kids ate lunch at the canteen.</li>
            <li>His new van is green.</li>
          </ul>{' '}
          As you can see, independent clauses aren’t long or complex sentences.
          They can certainly be part of complex sentences, but by definition,
          they are known as simple sentences. There are other types of sentences
          such as <strong>complex sentences, compound sentences</strong>, and
          <strong>
            compound-complex sentences but in the interest of time, we will be
            unable to discuss them.
          </strong>
        </p>
        <p>
          <h6>Dependent clauses</h6>In contrast, a dependent clause is not a
          complete sentence. Dependent clauses are sometimes known as
          subordinate clauses. These clauses depend on independent clauses to
          clearly express ideas. Here are a few examples of dependent clauses:
          <ul>
            <li>When I finish studying</li>
            <li>Although he practiced hard for the competition</li>
            <li>
              The actress who played the title character in the musical play To
              turn these dependent clauses into full sentences, you need to
              combine them with independent clauses:
            </li>
            <li>
              <strong>When I finish studying</strong>, I want to be able to help
              out in the communities
            </li>
            <li>
              <strong>
                Although he practiced hard for the competition, he still was not
                able to make the semifinals.{' '}
              </strong>
            </li>
            <li>
              The actress who played the title character in the musical play is
              the same one who portrayed the villain in another play I saw.
            </li>
          </ul>
        </p>
        <h5>How to combine clauses</h5>
        <p>
          When you want to write a sentence that’s more than one independent
          clause, you need to combine clauses. Every sentence you write includes
          at least one independent clause. The other clauses in your sentence
          determine how you can combine them with your independent clause. A
          sentence that includes two or more independent clauses is known as a
          compound sentence. In a compound sentence, the clauses are joined by a
          comma paired with a coordinating conjunction, a semicolon, or a colon.
          This kind of combination is known as coordination. Take a look at
          these examples of compound sentences:
          <ul>
            <li>
              I love eating chocolate, but I know too much of is bad for my
              teeth.
            </li>
            <li>
              Maria loved hosting dinner parties; her friends enjoyed her
              cooking.
            </li>
            <li>
              We danced until the band their last set: their playlist lasted
              until midnight. When all of your clauses are independent clauses,
              you can combine them using a coordinating conjunction. In English,
              the coordinating conjunctions are:{' '}
            </li>
            <li>for</li>
            <li>and </li>
            <li>nor</li>
            <li>but</li>
            <li>or</li>
            <li>yet</li>
            <li>so</li>
          </ul>
        </p>
        <p>
          I hope you will now be aware of the usage of clauses. Remember, when
          writing an office memo or an email, make sure you write complete
          sentences. These can be in the form of simple sentences or complete
          sentences.
        </p>
      </>
    )
  }

  const transcriptActivity3 = () => {
    return (
      <>
        <p>
          We are done discussing basic grammar rules such as the subject-verb
          agreement and the clauses, we can now put all these things in the
          context that is most relevant to you.
        </p>
        <p>
          We will discuss something you probably do or read on a regular basis.
          I am talking about the office memo.
        </p>
        <p>
          Different sources will give you different formats of business or
          office memo. If you make your own research, you will also most likely
          encounter sites with different versions of the parts of a business or
          office memo.
        </p>
        <p>
          For our purposes, we will make this very simple and stick to the three
          main parts of the memo. These are the following:
        </p>
        <ol>
          <li>The heading</li>
          <li>The subject and date</li>
          <li>The content</li>
        </ol>
        <p>Let us break this down further.</p>
        <p>
          1. <strong>The Heading typically</strong> contains the name of the
          organization and ‘To’ and ‘From’ sections.
        </p>
        <p>
          In the section ‘To’, a courtesy title – such as Honorable, Atty,, Dr.,
          Ms. - is often included.
        </p>
        <p>in the section ‘From’, you do not have to use a courtesy title.</p>
        <p>
          Make sure you address the reader by this or her correct name and job
          title.
        </p>
        <p>
          2. <strong>The subject</strong> is a brief statement indicating, in
          specific terms, what the memo is about.{' '}
        </p>
        <p>
          The subject line should not be too lengthy, therefore, it should not
          be a complete sentence. Rather, it must be a concise phrase which
          indicates some specific information. The date is also indicated
        </p>
        <p>
          3. <strong>The content</strong> is the Message in the main body of a
          memo.{' '}
        </p>
        <p>
          The message can further be broken down into the following components:
        </p>
        <p>
          (a) Introduction: The message presents the main idea in the first
          paragraph. it provides a snapshot or a preview of what the memo wants
          to convey to the recipients.
        </p>
        <p>
          (b) Details: In the second paragraph you should write the details that
          will provide the meat of the memo. It should be clear and pertinent.
          It provides a context to the main idea being discussed in the memo.
          The details should be able to answer the basic questions of 5 Ws and 1
          H: WHO, WHAT, WHEN WHERE, WHY, HOW.
        </p>
        <p>
          (c) Response: It is called the action statement. In this paragraph,
          you should state the action you want the reader to take. Will it be
          wearing of the new ID? A new template for a report that everyone is
          expected to use? Is it a revision of the current liquidation process?
          Whatever to is, at this point in the memo, the reader must already
          feel compelled to follow the direction being details in the memo.
        </p>
        <p>
          <div class='container'>
            <table class='table table-bordered'>
              <thead>
                <tr>
                  <th>Basis for Comparison</th>
                  <th>Memo</th>
                  <th>Letter</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Meaning</td>
                  <td>Short message for interoffice use</td>
                  <td>Verbal communication for external parties</td>
                </tr>
                <tr>
                  <td>Nature</td>
                  <td>Informal and concise</td>
                  <td>Formal and informative</td>
                </tr>
                <tr>
                  <td>Exchanged Between</td>
                  <td>
                    Departments, units, or superiors within the organization
                  </td>
                  <td>Two business houses or between company and client</td>
                </tr>
                <tr>
                  <td>Length</td>
                  <td>Short</td>
                  <td>Comparatively long</td>
                </tr>
                <tr>
                  <td>Signature</td>
                  <td>Not required</td>
                  <td>Duly signed by the sender</td>
                </tr>
                <tr>
                  <td>Communication</td>
                  <td>One to many</td>
                  <td>One to one</td>
                </tr>
                <tr>
                  <td>Content</td>
                  <td>Allows technical jargon and personal pronouns</td>
                  <td>Uses simple words and written in the third person</td>
                </tr>
              </tbody>
            </table>
          </div>
          Let us first try to distinguish the difference between a Memo and a
          Letter.
        </p>
        <p>
          A memo is an important part of written communication and it also has a
          large number of users for a different purposes. Different types of
          memo are used for different purposes so everyone should know about
          types of memo.
        </p>
        <p>
          Types of Memo or, Movement of Memo As an internal communication tool,
          the memo can be moved in three (3) ways within an organization and can
          be divided into as follows:
          <ol>
            <li>Downward</li>
            <li>Upward</li>
            <li>Horizontal</li>
          </ol>
        </p>
        <p>
          Downward
          <br />
          Here memo moves from superiors to subordinates. Such memos, in most
          cases, announce or explain policy or procedures. It also announces new
          staff appointments, product and service changes, and special events
          within the organization. Moreover, the superior may also request any
          information, through such a memo.
        </p>
        <p>
          Upward
          <br /> Here memos are written by subordinates to their respective
          superiors. These memos provide responses to requests made by superiors
          and convey informal reports to superiors. Upward memos may also make
          requests for any specific reasonable matter. The tone of such a memo
          should be formal and the facts to be contained here should be backed
          by proper evidence. This memo is one way to present yourself to your
          superior and maybe your best support for promotion within the
          organization.
        </p>
        <p>
          Horizontal
          <br /> This memo is communicated between co-workers and employees at
          the same level or position. The respective positions of the sender and
          the receiver determine the tone of the memo and generally, the tone is
          informal. The carefully written memo may enhance your image among your
          co-workers and therefore an opportunity for your advancement.
        </p>
      </>
    )
  }

  const transcriptActivity4 = () => {
    return (
      <>
        <p>
          An email is one of the most widely used forms of written
          communication—both in and out of the workplace. A well-thought of and
          eloquently composed professional email provides the recipient with a
          friendly, clear, concise and actionable message. Learning how to
          professionally write an email that meets all of these criteria will
          entail lots and lots of practice.
        </p>
        <p>
          We shall discuss in this module the importance of knowing how to write
          a professional email, how to write one, review example emails and
          answer some frequently asked questions about email communication.
        </p>
        <p>
          <h6>
            Why is it important to know how to write a professional email?
          </h6>
          In this day and age where speed and efficiency are valued in the
          workplace, you will likely use email in some capacity no matter your
          role or the industry you work in, whether you work in government or in
          the private sector.
        </p>
        <p>
          Because email and other forms of digital communication do not give us
          visibility on natural communication cues like body language, it is
          therefore imperative that we learn how to write professional emails
          which are clear and accurate. Knowing how to write a professional
          email can:
        </p>
        <p>
          <ul>
            <li>Save time </li>
            <li>Provide clarity</li>
            <li>Show competency </li>
            <li>
              Establish a reputation for a person, an office, or a department
            </li>
          </ul>
        </p>
        <p>
          <h6>1. Use a professional email address</h6> A professional email
          address is one you use for work, oftentimes an email address you only
          use for work-related purposes or formal communications. As much as
          possible, do not use your personal email for work-related
          communication, like sending out a memo.
        </p>
        <p className='border border-2'>
          <strong>Tip:</strong> A professional email typically consists of five
          elements: subject line, salutation, body, closing and signature.
        </p>
        <p>
          <strong>2. Add a concise, informative subject line</strong> This is a
          short phrase that clearly summarizes the reason for your email message
          or the goal of your communication. It’s important you include a
          subject line when sending a professional email so your audience knows
          exactly what to expect and is able to locate the message again easily,
          if needed. Here are some subject line tips to keep in mind for your
          next professional email:
          <ul>
            <li>Use less than 60 characters.</li>
            <li>Clearly state the email's goal in one sentence.</li>
            <li>Consider starting with the recipient's name.</li>
          </ul>
          For example:
          <br /> <strong>Subject:</strong> Follow up: Product presentation
        </p>
        <p>
          Remember that a person’s inbox typically contains hundreds of emails.
          Having a concise, informative subject will ensure your email will not
          be forgotten or overlooked.
        </p>
        <p>
          <h6>3. Greet the recipient with a proper salutation</h6>
          This is the first line of your email and generally acts as the
          greeting. You’ll want this email greeting to match the tone of the
          email you’re sending—in this case, a professional tone. Your greeting
          can change depending on your relationship with the recipient.
        </p>
        <p>
          <ul>
            <li>
              If you’re writing to someone you’ve never met or a person you have
              a formal professional relationship with, such as someone from
              another department or a stakeholder who is not part of the Quezon
              City LGU, you can start with “Dear.”
            </li>
            <li>
              If you’re addressing someone you know or someone you have a
              familiar professional relationship with, such as a colleague or
              manager, you can start with “Hello” or “Hi.”
            </li>
          </ul>
        </p>
        <p>
          Here are a few examples of greetings:
          <ul>
            <li>Hi Ms. Gaspar,</li>
            <li>Hello Maria,</li>
            <li>Dear Dr. Gonzales,</li>
            <li>Dear colleagues,</li>
            <li>Dear Ma’am Irma Daldal,</li>
          </ul>
          <span className='border border-2'>
            <strong>Tip:</strong> When sending a formal email, it’s good
            practice to use the “Mr.” or “Ms.” honorific and the recipient’s
            last name, if you know their preferred gender pronouns. If you’re
            not sure of the recipient’s gender pronouns, use their full name.
          </span>
        </p>
        <p>
          <h6>4. Write the body of your email</h6>
          Just like the body of a letter you might write, this is where you’ll
          share your full message. No matter the length of your email, which is
          oftentimes concise and to the point, the message should be actionable
          and must include a closing remark. For example: Thank you for
          attending the cascade on the new liquidation procedures this
          afternoon. I’ve attached a video file of the full recording so you can
          share it with your team. Please let me know if you have any questions.
          <span className='border border-2'>
            <strong>Tip:</strong> When you compose an email message, make sure
            your tone matches your audience.
          </span>
        </p>
        <p>
          5. End emails with a sign-off and signature The signature is where you
          identify yourself by name, title and any other information relevant to
          your communications. Most email programs allow you to set a fixed
          signature that’s automatically added to the end of every email you
          send. Typical closing words include:
          <ul>
            <li>Regards,</li>
            <li>Best,</li>
            <li>Sincerely,</li>
            <li>Thanks,</li>
            <li>Kind regards,</li>
          </ul>
          Here’s an example of how you could end an email:
        </p>
        <p>Sincerely,</p>
        <p>
          Alberto Guidaben
          <br />
          Head
          <br />
          Finance and Accounting
        </p>
        <p>
          <h6>6. Proofread your email</h6>
          An error-free email demonstrates diligence and professionalism. Before
          you send an email, take a moment to check for any spelling, grammar or
          syntax errors. Double-check to ensure you’ve included any files or
          attachments you may have referenced in your message as well.
        </p>
        <p>
          When creating a professional email, keep it simple, to the point and
          void of any grammatical or spelling errors.
        </p>
        <p>
          Once you’re done proofing your email, read it over once more as if you
          were the recipient. Doing so may help you catch anything you may have
          missed during an earlier step, such as when composing or editing.
          <br />
          <span className='border border-2'>
            <strong>Tip:</strong> If it’s an important email to critical
            stakeholders, you may ask your direct supervisor or a trusted
            colleague to read over it before you send it.
          </span>
        </p>
        <p>
          7. Check your recipient fields Before sending your email, check your
          recipient fields to make sure you’re sending it to the right person,
          people or group. You also want to make sure the email addresses are in
          the correct field. Remember:
          <table class='table table-bordered'>
            <tbody>
              <tr>
                <td>To:</td>
                <td>
                  The email address of the person or group to whom your email
                  will be sent.
                </td>
              </tr>
              <tr>
                <td>CC:</td>
                <td>
                  Stands for "carbon copy." Any address in this field will
                  receive the same email, but not be directly addressed in the
                  email.
                </td>
              </tr>
              <tr>
                <td>BCC:</td>
                <td>
                  This field is similar to "CC," only the email address(es) is
                  hidden.
                </td>
              </tr>
            </tbody>
          </table>
        </p>
        <p>
          <h6>8. Schedule your email and send</h6>
          Sometimes you may compose an email ahead of time or during off hours.
          Scheduling an email can help you manage your time and ensure you’re
          also sending your email at a time that’s appropriate for the
          recipient. All email clients have a scheduling feature.
        </p>
        <p>
          <h6>9. Remember to follow up</h6>
          Finally, most people receive several emails per day, so they might
          miss or forget to respond to your message. If the recipient hasn’t
          replied within two working days, consider reaching back out with a
          friendly follow-up email.
        </p>
        <p>
          <h6>Writing an email to a group</h6>
        </p>
        <p>
          <emp>
            <strong>Subject line:</strong> [Subject] meeting canceled
            <br />
            Hi team,
            <br />
            The [meeting name] meeting scheduled for this [time frame] has been
            canceled. <br />I apologize for the late notice, but I know everyone
            will welcome the extra time back in their day. We will reconvene at
            our regularly scheduled time on [date]. Thanks,
            <br />
            [Your name]
            <br />
            [Your title]
          </emp>
        </p>
        <p>
          <h6>Writing a follow-up email</h6> <strong>Subject line:</strong> Re:
          Availability for introductory meeting
          <br /> Hi [Name] I just wanted to check back in regarding the date for
          your meeting with [Name]. Just let me know whether [date] or [date]
          works better for your schedule.
          <br />
          Thank you!
          <p>
            [Your name]
            <br />
            [Your title]
            <br />
            [Your company]
          </p>
        </p>
        <p>
          Since we all use emails on a daily basis, it can be critical that we
          all have a mature understanding of email etiquette. There are a number
          of generally acceptable social behaviors that when followed will allow
          us to communicate more effectively and convey our professionalism.
          Knowing more about email etiquette can help ensure that we both show
          and receive respect through this form of written communication.{' '}
        </p>
        <p>
          <h6>What is email etiquette?</h6> Email etiquette refers to how a
          person should behave when writing, answering and sending emails. Email
          etiquette can be different depending on the nature of the email being
          sent. For example, email etiquette for messages sent to friends and
          family usually differ from the etiquette expected of you in the
          workplace. Knowing basic email etiquette can ensure that you're
          perceived as professional and have confidence when writing emails in
          various settings. Email etiquette is important for a number of
          reasons, including that it:
          <ul>
            <li>Enables you to convey professionalism in a business setting</li>
            <li>
              Ensures your emails are straightforward and easily understood
            </li>
            <li>Minimizes the risk of sending inappropriate emails</li>
          </ul>
        </p>
        <p>
          <h6>Basic email etiquette rules</h6> The following are 11 email
          etiquette rules that you can follow when composing or responding to
          emails in a professional capacity:{' '}
          <h6>1. Use a professional email address</h6>
          If you're responding to an email within your organization, use the
          email address provided to you by your company. If you're responding to
          professional emails outside of work, create a professional email that
          includes your name so that recipients can easily see who is emailing.
          Be sure to pick a reputable email provider that can secure your
          messages from potential cyber threats.
        </p>
        <p>
          <h6>2. Be mindful of tone</h6>
          Keeping a professional tone in your emails is another important
          component of good email etiquette. Even if you know the person well,
          strive to remain as professional as possible when corresponding about
          business topics. You might find it helpful to read your message aloud
          to gauge how professional your tone is prior to sending the email.
        </p>
        <p>
          <strong>3. Pick a concise subject line</strong> When writing a
          professional email, make sure that your subject line is clear and
          easily understood. This can help quickly convey to the recipient what
          your email is about and ensure that your email actually gets opened by
          the recipient. If your email requires a prompt reply, you can add a
          word like "urgent" or "important" to the subject line, but be cautious
          about using these phrases too often.
        </p>
        <p>
          <h6>4. Check the spelling of your recipient's name</h6>
          Ensuring that you have spelled your recipient's name correctly is
          important for good email etiquette. Spelling the recipient's name
          correctly demonstrates your respect and attention to detail. Before
          hitting "send," double-check that you have spelled the recipient's
          first and last name accurately, along with other details like their
          job title or company name.
        </p>
        <p>
          <h6>5. Create email segment lists</h6> Make segment lists of your
          email contacts, meaning different lists based on categories. For
          example, you might create unique segments for your team members,
          vendors and clients. Creating these segment lists can make it more
          efficient for you to contact groups of people simultaneously.
        </p>
        <p>
          <h6>6. Minimize the use of emojis</h6>
          Avoid including emojis or other animations in your professional
          emails, as they can sometimes appear too casual. If you want to make
          your email seem more friendly, try instead adding a polite phrase,
          such as "I hope you're well" or "Wishing you all the best." Only use
          emojis or animations when communicating with colleagues in a more
          informal manner, such as if the email chain pertains to a work party
          or already includes fun images.
        </p>
        <p>
          <h6>7. Only include attachments if necessary</h6>
          Before you include an attachment in your email, ensure that it's
          necessary for the particular email you're sending. For example, if a
          document has only one or two sentences, consider including it in the
          email body rather than sending an attachment. Also, be sure to mention
          the attachment in your email so that the recipient knows that you sent
          it on purpose.
        </p>
        <p>
          <h6>8. Reply as promptly as possible</h6>
          While you're likely not expected to get to every email you receive
          immediately, it's good email etiquette to respond to emails in a
          timely fashion. Try to prioritize your emails as they come in so that
          you can determine which emails require the fastest responses. You
          might consider developing internal folders within your email provider
          to help you keep your emails organized based on which ones require
          more prompt replies.
        </p>
        <p>
          <h6>9. Check the recipient field</h6>
          Make sure that you're emailing the right person. When using an
          internal company email system, it can become easy to accidentally send
          a message to the wrong person, especially if multiple people within
          the company have similar or identical names. Be sure to double-check
          that you have the correct recipient's email address before hitting
          send.
        </p>
        <p>
          <h6>10. Proofread your email</h6>
          Before sending any professional email, proofread the email at least
          once to make sure it's correct and includes all the necessary
          information. Ensure that each sentence is clear and professional. You
          might also find it helpful to read your message aloud or ask someone
          else to review it so that you can view the email more objectively.
        </p>
        <p>
          <h5>15 more email etiquette rules</h5>
          Here are 15 additional tips that can help you maintain proper email
          etiquette:
          <ol>
            <li>
              Use reply all sparingly: Only use the "reply all" feature when the
              information in your message can potentially affect all the
              included recipients.
            </li>
            <li>
              Pick an appropriate greeting: If you're writing to someone you
              know well, like a colleague, use a friendly but more casual
              greeting, such as "Good morning" or "Hello." If you're writing to
              someone you interact with less frequently or in a more
              professional capacity, pick a formal greeting like "Dear" followed
              by the person's professional title.{' '}
            </li>
            <li>
              Include a signature: Add a signature to your professional emails
              that contains basic information about you, such as your name,
              title, the company you work for and your phone number.
            </li>
            <li>
              Be concise: Craft your message to be as concise as possible. While
              it can be courteous to add pleasantries such as wishing the other
              person well, keep the bulk of your message focused on the
              essential information.
            </li>
            <li>
              Process your emotions before responding: If an email makes you
              feel anxious or upset, wait until you feel more emotionally
              centered before responding to that message. Taking the time to
              process your emotions can help ensure that you later create an
              email that's as objective as possible.
            </li>
            <li>
              Use humor carefully: If you feel that a joke's appropriate to add
              to your email based on factors like your relationship with the
              intended recipient, add a little humor to your email. When using
              humor in an email, be mindful of not including any jokes with
              potentially sensitive material.
            </li>
            <li>
              Minimize a dramatic tone: Use exclamation points sparingly and
              only when conveying a positive statement. It's also important to
              only use all-capital letters when writing about acronyms or
              abbreviations.{' '}
            </li>
            <li>
              Give the recipient time to respond: Give your email recipient at
              least 24 business hours to reply to your message. If they take
              longer than 24 hours to respond, you can write a polite follow-up
              message to make sure they got your first email and to reiterate
              the key information or question.
            </li>
            <li>
              Type the recipient's address last: To minimize the risk of
              accidentally sending your email too soon, add the recipient's
              address after you compose your message.
            </li>
            <li>
              Use a spell checker: Run your email through a spell-checking tool
              before sending it. You can also use an online tool designed to
              detect and fix grammatical or syntactical issues.
            </li>
            <li>
              Understand cultural conventions: If you're writing an email to
              someone from a cultural background that differs from yours, make
              sure that you understand what they consider professional or
              courteous before sending your email. Certain idioms, for example,
              may not translate well into other languages or cultures.
            </li>
            <li>
              Minimize confidential information: Try to limit how much sensitive
              information you share over email to bolster your cybersecurity
              efforts. If you need to share confidential details with the
              recipient, consider sending through a more secure method, like a
              file share site or a password-protected attachment.
            </li>
            <li>
              Send emails during business hours: Strive to only send emails
              during the work hours of the company you work for or during
              traditional business hours. If you end up composing an email
              outside of these hours, see if your email provider allows you to
              schedule a send time for the next business day.
            </li>
            <li>
              Use auto-responders as needed: If you plan to be away from your
              job for a whole business day, set up an auto-reply message that
              offers a brief explanation and says when you return to work. If
              you plan to be gone for more than a few days, consider including
              the contact information of someone else that the person emailing
              you might want to email instead.{' '}
            </li>
            <li>
              Pick an easy-to-read font: Use a professional font that's simple
              to read, such as Times New Roman, Arial or Garamond. Write in an
              11- or 12-point size font.
            </li>
          </ol>
        </p>
      </>
    )
  }

  const fetchUploadedTrainingVideo = async () => {
    try {
      const uploadedTrainingVid = await API.graphql(
        graphqlOperation(listTrainingVideos)
      )
      const uploadedTrainingVidList =
        uploadedTrainingVid.data.listTrainingVideos.items
      setUploadedTrainingVideo(
        uploadedTrainingVidList.sort((a, b) => {
          return a.key.localeCompare(b.key)
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchUploadedTrainingVideo()
  }, [])

  const handlePlayButton = (key) => {
    setIsModalVisible(true)
    setVideoKey(key)
  }

  const handleSelect = (value) => {
    setDepartment(value)
  }

  const dateDisplay = `${
    new Date().getMonth() + 1
  }-${new Date().getDate()}-${new Date().getFullYear()}`

  /*  const handleButtonClick = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSdee0qZgz6rnrMW41J-LSV5_iH7CyRLau4MBrhIKHN50JWMMA/viewform',
      '_blank'
    )
  } */

  return (
    <>
      {/* <Modal
        title='NOTICE!!!'
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        width={800}
        footer={[
          <Button key='back' onClick={() => setOpenModal(false)}>
            Close
          </Button>,
        ]}
      >
        <h6>Please answer the PRE-TEST before you proceed watching!</h6>
        <Button
          type='primary'
          onClick={handleButtonClick}
          target='_blank'
          rel='noopener noreferrer'
        >
          Take Pre-Test
        </Button>
      </Modal> */}
      <div className='pb-5'>
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='NOTICE!'
          width={1000}
          visible={isInstructionModalVisible}
          onCancel={() => setIsInstructionModalVisible(false)}
          footer={[
            <Button
              key='back'
              onClick={() => setIsInstructionModalVisible(false)}
            >
              Ok
            </Button>,
          ]}
        >
          <p>
            Welcome to{' '}
            <strong>
              Business Writing: The Art of Effective Office Written
              Communication!
            </strong>
          </p>
          <p>
            This training has four (4) parts: Subject-Verb Agreement, Grammar:
            Clauses, The Office Memo, and Email Writing.
          </p>
          <p>
            Few instructions before you proceed:
            <ul>
              <li>
                Each video module has an activity which will only appear after
                completely viewing a video module.
              </li>
              <li>
                Per activity, your progress will be saved if you have not
                clicked the submit button yet.
              </li>
              <li>
                Submitting your activity entry will redirect you to the next
                video module. There is no backtracking to the previous video/s
                so please note-take.
              </li>
              <li>
                You may make use of the transcript available per video module.
              </li>
            </ul>
          </p>
          <p>
            If you encounter any technical issues, please email your concern/s
            to <strong>LD.HRMD@quezoncity.gov.ph.</strong> Thank you!
          </p>
        </Modal>
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='Congratulations!'
          width={1000}
          visible={isCompleteMsgModalVisible}
          onCancel={() => setIsCompleteMsgModalVisible(false)}
          footer={[
            <Button
              key='back'
              onClick={() => setIsCompleteMsgModalVisible(false)}
            >
              Ok
            </Button>,
          ]}
        >
          <p>You have reached the end of our training modules.</p>
          <p>
            To complete this training, please go back to{' '}
            <strong>Training Programs</strong> tab and{' '}
            <strong>answer the Post-Training.</strong>
          </p>
          <p>Thank you, and we hope you had a wonderful learning experience!</p>
        </Modal>
        <>
          <img
            className='col-12'
            src={`${process.env.PUBLIC_URL}/Title Heading v2.png`}
          />
          <div className='d-flex gap-2 justify-content-center'>
            <img
              className='col-3'
              src={`${process.env.PUBLIC_URL}/Side Attachment - About the Resource Person.png`}
            />
            {englishVideos.map((title, index) => {
              return uploadedTrainingVideo
                .filter((video) =>
                  video.key.toLowerCase().includes(title.toLowerCase())
                )
                .map((video) => {
                  const videoName = video.key.slice(0, -4) // remove the last 4 characters (i.e. ".mp4") from the file name
                  let activityComponent
                  let inProgressVid
                  if (index === 0) {
                    activityComponent = (
                      <Activity1
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                        viewUserAnswer={[]}
                      />
                    )
                    inProgressVid = isWatchedVideoOne
                  } else if (index === 1) {
                    activityComponent = (
                      <Activity2
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                      />
                    )
                    inProgressVid = isWatchedVideoTwo
                  } else if (index === 2) {
                    activityComponent = (
                      <ActivityQuestionModalFour
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                      />
                    )
                    inProgressVid = isWatchedVideoFour
                  } else {
                    activityComponent = (
                      <ActivityQuestionModalThree
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                        setIsCompleteMsgModalVisible={
                          setIsCompleteMsgModalVisible
                        }
                      />
                    )
                    inProgressVid = isWatchedVideoThree
                  }
                  return (
                    <>
                      {progressActivity == index + 1 && !isLoading && (
                        <div
                          className='card shadow mt-3 col-md-6 col-12'
                          key={video.key}
                        >
                          <Modal
                            maskClosable={false}
                            destroyOnClose={true}
                            footer={null}
                            width={1050}
                            title={`NOTICE!!!`}
                            visible={isModalActivityOpen}
                            onCancel={() => setIsModalActivityOpen(false)}
                          >
                            <div className='d-flex justify-content-center flex-column align-items-center text-center'>
                              <h6>
                                PLEASE{' '}
                                <span className='text-danger'>
                                  CLICK THE BUTTON BELOW
                                </span>{' '}
                                TO PROCEED TO THIS MODULE'S ACTIVITY
                                <p className='mt-3'>
                                  Clicking <strong>"Submit"</strong> will record
                                  your answers as final.
                                </p>
                              </h6>
                              {activityComponent}
                            </div>
                          </Modal>
                          <div className='h-100 d-flex align-items-center '>
                            <img
                              src={`${
                                process.env.PUBLIC_URL
                              }/For sir Mhel - Video ${index + 1}.png`}
                              alt={video.key}
                              className='w-100'
                            />
                            <div
                              className='playbutton btn cursor-pointer mb-5'
                              onClick={() => handlePlayButton(video.key)}
                              style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '80px',
                                height: '80px',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                top: '40%',
                              }}
                            >
                              <PlayCircleFilled
                                style={{ fontSize: '43px', color: '#fff' }}
                              />
                            </div>
                          </div>
                          {videoFinishWatching.includes(video.key) ||
                          inProgressVid
                            ? activityComponent
                            : null}
                        </div>
                      )}
                    </>
                  )
                })
            })}
            {progressActivity == 'alldone' && (
              <div className='col-md-6 col-12 flex-grow-1 d-flex flex-column align-items-center justify-content-center mt-5 bg-secondary text-white'>
                <p>
                  <h3>You have reached the end of our training modules.</h3>
                </p>
                <p>
                  <h3>
                    To complete this training, please go back to{' '}
                    <strong>Training Programs</strong> tab and{' '}
                    <strong>answer the Post-Training.</strong>
                  </h3>
                </p>
                <p>
                  <h3>
                    Thank you, and we hope you had a wonderful learning
                    experience!"
                  </h3>
                </p>
              </div>
            )}
            <img
              className='col-3'
              src={`${process.env.PUBLIC_URL}/Side Attachment - Certificate of Completion.png`}
            />
          </div>
        </>
        <Modal
          title='Acknowledgment and Receipt'
          width={1000}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button key='back' onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>,
            <VideoPlayer
              videoKey={videoKey}
              CSCOnlineVids={true}
              name={name}
              department={department}
              setIsModalVisible={setIsModalVisible}
              isAgree={isAgree}
              setvideoFinishWatching={setvideoFinishWatching}
              videoFinishWatching={videoFinishWatching}
              setIsModalActivityOpen={setIsModalActivityOpen}
              transcriptActivity={transcriptActivity}
            />,
          ]}
        >
          <div>
            <p>STATEMENT OF CLAIMING VIEW</p>
            <p className='text-justify'>
              I hereby declare and affirm my commitment to the terms and
              conditions set forth in this statement regarding the review video
              material provided to me in this platform (www.qc-hrmd.org), and
              its entirety, by the Human Resource Management Department (HRMD)
              of the Quezon City Government. I acknowledge that the review video
              material is the exclusive property of HRMD, and as such, I fully
              understand and agree to abide by the following terms:
            </p>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>1.</p>
              <p>
                {' '}
                <strong>Ownership:</strong> I recognize that the training video
                material is protected by intellectual property laws and is the
                sole and exclusive property of HRMD. I acknowledge that I have
                no rights or ownership claims over the content contained within
                the training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>2.</p>
              <p>
                {' '}
                <strong>Non-Duplication:</strong> I solemnly affirm that I will
                not duplicate or reproduce, in whole or in part, the training
                video material provided to me by HRMD. I understand that
                duplication includes but is not limited to copying, recording,
                downloading, or distributing the material through any means or
                in any format without explicit written consent from HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>3.</p>
              <p>
                {' '}
                <strong>Confidentiality:</strong> I acknowledge and respect that
                the training video material may contain confidential and
                proprietary information belonging to HRMD. I commit to
                maintaining the strictest confidentiality regarding the content,
                concepts, techniques, and methodologies presented in the
                training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>4.</p>
              <p>
                {' '}
                <strong>Personal Use:</strong> I understand that the training
                video material is solely intended for my personal use, learning,
                and development. I agree not to share, lend, or otherwise make
                the training video material available to any third party,
                whether for commercial or non-commercial purposes, without the
                express written consent of HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>5.</p>
              <p>
                {' '}
                <strong>Legal Consequences:</strong> I acknowledge that any
                unauthorized duplication or reproduction of the training video
                material may result in legal consequences, including but not
                limited to civil lawsuits and monetary damages. I accept full
                responsibility for any such consequences resulting from my
                violation of this agreement.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>6.</p>
              <p>
                {' '}
                <strong>Compliance:</strong> I commit to cooperating with HRMD
                and its representatives in any investigation of suspected
                violation of this agreement. I understand that HRMD reserves the
                right to take appropriate legal action to enforce these terms
                and protect its rights.
              </p>
            </div>
            <p>
              By inputting my full name and department/office in this statement,
              I acknowledge that I have read and understood the terms outlined
              above and that I will comply with them strictly. I further
              acknowledge that any breach of this agreement may result in severe
              legal consequences.
            </p>
            <div className='d-flex'>
              <label className='d-flex items-align-center gap-2'>
                <input
                  type='checkbox'
                  checked={!isAgree}
                  onChange={(e) => setIsAgree(!isAgree)}
                />
                I agree
              </label>
            </div>
          </div>

          <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Enter your name'
                className='text-center'
              />
              <span className='text-center'>Employee's Name & Signature</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
              <span>{dateDisplay}</span>
              <span>Date</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
              <Select
                value={department}
                onChange={handleSelect}
                className='w-100 text-center'
              >
                <Option value='disabled' disabled>
                  Disabled
                </Option>
                {dept.sort().map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <span className='text-center'>Department</span>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
