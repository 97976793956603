/**
 * Title: AddFAQ.js
 * Description: This is a file that contains the form for adding FAQs
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/AddFAQ.js
 **/

import { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { createFaqTable } from '../../api/mutations'
import {
  notification,
  Button,
  Modal,
  Row,
  Col,
  Input,
  Select,
  Breadcrumb,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const { Option } = Select

export default function AddFAQ({ fetchFAQs, groupedFAQs }) {
  const [openModal, setOpenModal] = useState(false)
  const [openPreviewModal, setOpenPreviewModal] = useState(false)
  const [hasError, setHasError] = useState([])
  const [category, setCategory] = useState('')
  const [question, setQuestion] = useState('')
  const [answer, setAnswer] = useState('')
  const [newCategory, setNewCategory] = useState('')

  const categoryOptions = Object.keys(groupedFAQs)

  const handleConfirm = async () => {
    const adminUpload = await API.graphql(
      graphqlOperation(createFaqTable, {
        input: {
          category: category.toLowerCase(),
          questionText: question,
          answerText: answer,
        },
      })
    )
    if (adminUpload) {
      notification['success']({
        message: 'Success',
        description: 'New FAQ is added',
      })
      setOpenPreviewModal(false)
      setOpenModal(false)
      fetchFAQs()
      setCategory('')
      setQuestion('')
      setAnswer('')
    }
  }

  const handleSave = () => {
    const errors = []
    if (!category || !question || !answer) {
      notification['error']({
        message: 'Error',
        description: 'Fill all the fields',
      })
      if (!category) {
        errors.push('category')
      }
      if (!question) {
        errors.push('question')
      }
      if (!answer) {
        errors.push('answer')
      }

      setHasError(errors)
      return
    }
    setHasError([])
    setOpenPreviewModal(true)
    setNewCategory('')
  }

  const handleCancel = () => {
    setHasError([])
    setOpenModal(false)
    setCategory('')
    setQuestion('')
    setAnswer('')
    setNewCategory('')
  }

  const handleAnswerChange = (newContent) => {
    setAnswer(newContent)
  }

  const handleCategoryChange = (value) => {
    if (value === '__new__') {
      setNewCategory('__new__')
    } else {
      setCategory(value)
    }
  }

  const handleNewCategoryChange = (e) => {
    setCategory(e.target.value)
  }

  return (
    <>
      <Button
        className='bg-success text-white'
        onClick={() => setOpenModal(true)}
      >
        Add New FAQ
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Create new FAQ'
        visible={openModal}
        footer={null}
        onCancel={handleCancel}
        width={800}
      >
        <div className='d-flex flex-column gap-4'>
          <div className='d-flex align-items-center'>
            <label htmlFor='category' className='col-md-2 col-3'>
              Category:
            </label>
            {newCategory !== '__new__' && (
              <Select
                id='category'
                className={
                  hasError.includes('category')
                    ? 'border border-danger w-100'
                    : 'w-100'
                }
                value={category}
                onChange={handleCategoryChange}
              >
                <Option value=''>Select a category</Option>
                {categoryOptions.map((option) => (
                  <Option key={option} value={option}>
                    {option}
                  </Option>
                ))}
                <Option value='__new__'>Enter a new category</Option>
              </Select>
            )}
            {newCategory === '__new__' && (
              <Input
                type='text'
                className={
                  hasError.includes('category')
                    ? 'border border-danger form-control'
                    : 'form-control'
                }
                onChange={handleNewCategoryChange}
              />
            )}
          </div>
          <div className='d-flex flex-column gap-3'>
            <label htmlFor='question' className='col-md-2'>
              Question
            </label>
            <TextArea
              onPressEnter={(e) => setQuestion(e.target.value + '\n')}
              className={
                hasError.includes('question') ? 'border border-danger' : ''
              }
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              id='question'
              style={{ height: 80 }}
            />
          </div>
          <div className='d-flex flex-column gap-3'>
            <label htmlFor='answer' className='col-md-2'>
              Answer
            </label>
            {/* <TextArea onPressEnter={(e) => setAnswer(e.target.value + '\n')} className={hasError.includes("answer") ? "border border-danger" : ""} value={answer} onChange={(e) => setAnswer(e.target.value)} id="answer" style={{ height: 300 }} /> */}
            <ReactQuill
              value={answer}
              onChange={handleAnswerChange}
              className={
                hasError.includes('answer') ? 'border border-danger' : ''
              }
              id='answer'
              style={{ height: 400 }}
            />
          </div>
          <div className='d-flex justify-content-end gap-3 mt-5'>
            <Button
              onClick={handleCancel}
              className='bg-secondary text-white'
              style={{ width: 80 }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              className='bg-primary text-white'
              style={{ width: 80 }}
            >
              Save
            </Button>
            <Modal
              maskClosable={false}
              destroyOnClose={true}
              title='Preview new FAQ'
              visible={openPreviewModal}
              footer={null}
              onCancel={() => setOpenPreviewModal(false)}
              width={800}
            >
              <div className='d-flex flex-column gap-3'>
                <div className='display-6 fw-bold'>{question}</div>
                <Breadcrumb separator='>'>
                  <Breadcrumb.Item>
                    <a className='text-decoration-none font-italic' href=''>
                      <em>FAQs</em>
                    </a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <a className='text-decoration-none font-italic' href=''>
                      <em>{category}</em>
                    </a>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item className='font-italic'>
                    <em>{question}</em>
                  </Breadcrumb.Item>
                </Breadcrumb>
                <p
                  dangerouslySetInnerHTML={{
                    __html: answer.replace(/\n/g, '<br>'),
                  }}
                ></p>
                <div className='d-flex justify-content-end gap-3'>
                  <Button
                    onClick={() => setOpenPreviewModal(false)}
                    className='bg-secondary text-white'
                    style={{ width: 80 }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={handleConfirm}
                    className='bg-primary text-white'
                    style={{ width: 80 }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </Modal>
    </>
  )
}
