/**
 * Title: videoPlayer.js
 * Description: This is a file that contains the components for the video player
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/videoPlayer.js
 **/

/**
 * Changes Made:
 * - 2024.08.27 | Raymart Mojado | disable the button submit if there is an error from validation
 */

import React, { useState, useEffect, useRef } from 'react'

import { API, Auth } from 'aws-amplify'
import { createSpmsVideoAcknowledgement } from '../../api/mutations'
import {
  CloudFrontClient,
  GetDistributionCommand,
} from '@aws-sdk/client-cloudfront'
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from '@aws-sdk/client-secrets-manager'
import { getSignedUrl } from '@aws-sdk/cloudfront-signer'

import { Button, Modal, Row, Col, notification } from 'antd'

import './videoPlayer.css'
import ReactPlayer from 'react-player'

export default function VideoPlayer({
  videoKey,
  spms,
  uploadedVid,
  name,
  department,
  setIsModalVisible,
  isAgree,
  watch,
  setWatchClosed,
  CSCOnlineVids,
  setvideoFinishWatching,
  videoFinishWatching,
  setIsModalActivityOpen,
  transcriptActivity,
  disabledButton,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [videoUrl, setVideoUrl] = useState(null)
  const playerRef = useRef(null)

  useEffect(() => {
    if (watch) {
      setIsModalOpen(true)
      getVideoUrl(videoKey)
    } else {
      setIsModalOpen(false)
    }
  }, [watch])

  async function getVideoUrl(key) {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken
      const secret_name = 'hrmCloudfront'
      const client = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await client.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)
      const objectKey = key
      const cdnClient = new CloudFrontClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const getDistributionParams = {
        Id: foundSecret.hrmDistributionID,
      }
      const commandCDN = new GetDistributionCommand(getDistributionParams)
      const response = await cdnClient.send(commandCDN)
      const newURL = `https://${response.Distribution.DomainName}/output/${objectKey}`
      const privateKey = foundSecret.hrmCloudfrontKey.replace(/\s*&&\s*/g, '\n')
      const keyPairId = foundSecret.hrmCloudfrontID
      const dateLessThan = new Date(Date.now() + 3600 * 1000)

      const signedUrlCDN = getSignedUrl({
        url: newURL,
        keyPairId,
        dateLessThan,
        privateKey,
      })

      setVideoUrl(signedUrlCDN)
    } catch (error) {
      console.log('Error getting video URL:', error)
    }
  }

  const handleCancel = () => {
    if (setWatchClosed) {
      setWatchClosed(true)
    }

    setIsModalOpen(false)
    setVideoUrl(null)
    if (playerRef.current) {
      playerRef.current.getInternalPlayer().pause()
    }
  }

  const handleOpen = async () => {
    try {
      // need to fetch the IAM identity first
      setIsModalOpen(true)
      getVideoUrl(videoKey)
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmit = async () => {
    try {
      if (!name || !department || department === 'disabled') {
        return notification['error']({
          message: 'Error',
          description: 'Please complete the fields below to continue.',
        })
      }

      // Save the aknoweldgement of the watcher of video to the database
      const input = {
        employeeName: name,
        department,
        date: new Date(),
        videoName: videoKey,
      }

      if (spms) {
        const acknowledgment = await API.graphql({
          query: createSpmsVideoAcknowledgement,
          variables: { input },
          authMode: 'API_KEY',
        })

        if (acknowledgment.data.createSpmsVideoAcknowledgement) {
          setTimeout(() => {
            // need to fetch the IAM identity first
            setIsModalOpen(true)
            getVideoUrl(videoKey)
          }, 1000)

          setTimeout(() => {
            setIsModalVisible(false)
          }, 2000)
        }
      }

      setIsModalOpen(true)
      getVideoUrl(videoKey)
      setIsModalVisible(false)

      setIsModalOpen(true)
    } catch (err) {
      console.log(err)
    }
  }

  const file = {
    attributes: {
      controlsList: 'nodownload',
      onContextMenu: (e) => e.preventDefault(),
    },
  }

  const handleEnded = () => {
    setvideoFinishWatching((prev) => [...prev, videoKey])
    setIsModalOpen(false)
    setIsModalActivityOpen(true)
  }

  try {
    return (
      <>
        {uploadedVid && (
          <Button onClick={handleOpen}>Watch Video Training</Button>
        )}
        {spms && (
          <Button
            key='submit'
            type='primary'
            onClick={handleSubmit}
            disabled={isAgree || disabledButton}
          >
            Submit
          </Button>
        )}
        {CSCOnlineVids && (
          <Button
            key='submit'
            type='primary'
            onClick={handleSubmit}
            disabled={isAgree || disabledButton}
          >
            Submit
          </Button>
        )}

        <Modal
          maskClosable={false}
          destroyOnClose={true}
          footer={null}
          width={CSCOnlineVids ? 1600 : 1050}
          title={`${videoKey}`}
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <Row>
            <Col span={24} className='d-md-flex'>
              {videoUrl ? (
                <>
                  <ReactPlayer
                    className='video-player w-100'
                    ref={playerRef}
                    playing={true}
                    url={videoUrl}
                    controls
                    config={{ file }}
                    onEnded={handleEnded}
                  />
                  {transcriptActivity && (
                    <div className='transcript col-md-4 px-3 py-0 mt-md-5 pt-md-4'>
                      <p>{transcriptActivity}</p>
                    </div>
                  )}
                </>
              ) : null}
            </Col>
          </Row>
        </Modal>
      </>
    )
  } catch (elemErr) {
    console.log(elemErr)
  }
}
