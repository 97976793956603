/**
 * Title: manageEtnaFeedback.js
 * Description: This is a file that contains the components for managing the Etna feedback
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Users/manageEtnaFeedback.js
 **/

import { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { listForms } from '../../api/queries'
import { Card, Avatar, Comment, Pagination, Spin } from 'antd'
import { UserOutlined } from '@ant-design/icons'

export default function ManageEtnaFeedBack() {
  const [data, setData] = useState([])
  const [nextToken, setNextToken] = useState(null)
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(10)
  const [isLoading, setIsloading] = useState(true)

  const fetchEmployeeSubmittedEtna = async () => {
    try {
      if (nextToken) {
        const result = await API.graphql(
          graphqlOperation(listForms, {
            limit: 1000,
            nextToken,
          })
        )

        var sortArray = []
        var newSortArray = []
        sortArray.push(...data)
        sortArray.push(...result.data.listForms.items)
        let oneArray = sortArray.filter((obj) => {
          if (JSON.parse(obj.form).panelPanel8TextArea) {
            if (JSON.parse(obj.form).panelPanel8TextArea.length > 1) {
              newSortArray.push(obj)
            }
          }
        })
        let twoArray = sortArray.filter((obj) => {
          if (
            JSON.parse(obj.form)
              .well66SuggestionsRecommendationstoImproveTrainingProgram
          ) {
            if (
              JSON.parse(obj.form)
                .well66SuggestionsRecommendationstoImproveTrainingProgram
                .length > 1
            ) {
              newSortArray.push(obj)
            }
          }
        })

        const finalArray = newSortArray.sort(
          (a, b) => new Date(b.finalSubmitDate) - new Date(a.finalSubmitDate)
        )

        setData(finalArray)
        if (result.data.listForms.nextToken) {
          setNextToken(result.data.listForms.nextToken)
        } else {
          setIsloading(false)
        }
      } else {
        const result = await API.graphql(
          graphqlOperation(listForms, {
            limit: 1000,
          })
        )

        var firstSortArray = []
        var newFirstSortArray = []
        firstSortArray.push(...result.data.listForms.items)
        let oneArray = firstSortArray.forEach((obj) => {
          if (JSON.parse(obj.form).panelPanel8TextArea) {
            if (JSON.parse(obj.form).panelPanel8TextArea.length > 1) {
              newFirstSortArray.push(obj)
            }
          }
        })
        let twoArray = firstSortArray.forEach((obj) => {
          if (
            JSON.parse(obj.form)
              .well66SuggestionsRecommendationstoImproveTrainingProgram
          ) {
            if (
              JSON.parse(obj.form)
                .well66SuggestionsRecommendationstoImproveTrainingProgram
                .length > 1
            ) {
              newFirstSortArray.push(obj)
            }
          }
        })

        const finalFirstSortArray = newFirstSortArray.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
        setData(finalFirstSortArray)

        if (result.data.listForms.nextToken) {
          setNextToken(result.data.listForms.nextToken)
        } else {
          setIsloading(false)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchEmployeeSubmittedEtna()
  }, [nextToken])

  const handleChange = (value) => {
    if (value <= 1) {
      setMinValue(0)
      setMaxValue(10)
    } else {
      setMinValue(value * 10 - 10)
      setMaxValue(value * 10)
    }
  }

  return (
    <>
      <div
        className='site-layout-background'
        style={{
          padding: 24,
        }}
      >
        {isLoading ? (
          <>
            <Card
              type='inner'
              title='List of Employees Suggestion/Recommendation to Improve Training Program'
              style={{ marginTop: '10px' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin size='large' />
              </div>
            </Card>
          </>
        ) : (
          <>
            <Card
              type='inner'
              title='List of Employees Suggestion/Recommendation to Improve Training Program'
              style={{ marginTop: '10px' }}
            >
              {data &&
                data.length > 0 &&
                data.slice(minValue, maxValue).map((item, i) => (
                  <Card key={i}>
                    <Comment
                      author={
                        <a>
                          {`${item.ownedBy.name} ${
                            item.ownedBy.mname ? item.ownedBy.mname : ''
                          } ${item.ownedBy.lname}`}
                        </a>
                      }
                      avatar={<Avatar size='large' icon={<UserOutlined />} />}
                      content={
                        <p>
                          {JSON.parse(item.form).panelPanel8TextArea ||
                            JSON.parse(item.form)
                              .well66SuggestionsRecommendationstoImproveTrainingProgram}
                        </p>
                      }
                    />
                  </Card>
                ))}
              <div className='d-flex flex-row-reverse'>
                <div className='p-2'></div>
                <div className='p-2'>
                  {data.length > 0 ? (
                    <Pagination
                      defaultCurrent={1}
                      pageSize={10}
                      onChange={(e) => handleChange(e)}
                      total={data.length}
                    />
                  ) : null}
                </div>
                <div className='p-2'></div>
              </div>
            </Card>
          </>
        )}
      </div>
    </>
  )
}
