/**
 * Title: uploadAdmin.js
 * Description: This is a file that contains the components for the upload admin
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/uploadAdmin.js
 **/

import { useState, useEffect, useRef } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  createOtherUpload,
  deleteOtherUpload,
  createTrainingAnnouncement,
} from '../../api/mutations'
import { listOtherUploads } from '../../api/queries'
import { Auth } from 'aws-amplify'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

import { CloseOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'

import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  Row,
  Col,
} from 'antd'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
} from '@ant-design/icons'

import moment from 'moment'
import UploadTrainingVideo from './uploadTrainingVideo'
import UploadedTrainingVideo from './uploadedTrainingVideo'

const { Option } = Select
const { TextArea } = Input

export default function UploadAdmin({ userData }) {
  const currentUser = userData

  const [uploaded, setUploaded] = useState([])
  const [visible, setVisible] = useState(false)
  const [mainModal, setMainModal] = useState(false)
  const [uploadTrainingModal, setUploadTrainingModal] = useState(false)
  const [visibleAnnouncement, setVisibleAnnouncement] = useState(false)
  const [sdescription, setDescription] = useState('')
  const [sadminUpload, setAdminUpload] = useState('')
  const [uploadName, setUploadName] = useState('')
  const [loading, setloading] = useState(false)
  const [announceUpload, setAnnounceUpload] = useState([])
  const [announceDate, setAnnounceDate] = useState(null)
  const [tpData, setTpData] = useState(null)
  const [cdData, setCdData] = useState(null)

  const [triggerRefresh, setTriggerRefresh] = useState(null)

  useEffect(() => {
    fetchUploadedFiles()
  }, [])

  function handleChange(value) {
    setDescription(value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (sdescription === 'disabled' || sdescription === '') {
      notification['error']({
        message: 'Error',
        description: 'Please choose a page or section to upload the file.',
      })
    } else {
      try {
        setloading(true)

        const credentials = await Auth.currentCredentials()
        const accessKeyId = credentials.accessKeyId
        const secretAccessKey = credentials.secretAccessKey
        const sessionToken = credentials.sessionToken

        const s3 = new S3Client({
          region: 'ap-southeast-1',
          credentials: {
            accessKeyId: accessKeyId,
            secretAccessKey: secretAccessKey,
            sessionToken: sessionToken,
          },
        })

        const params = {
          Bucket: 's3etnahris133956-dev',
          Key: `uploadedByAdmin/${sadminUpload.name}`,
          Body: sadminUpload,
        }

        const command = new PutObjectCommand(params)
        const uploadedByAdmin = await s3.send(command)
        const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

        const adminUpload = await API.graphql(
          graphqlOperation(createOtherUpload, {
            input: {
              userID: currentUser.id,
              date: moment().format('YYYY-MM-DD'),
              filename: uploadName,
              location: url,
              description: sdescription,
            },
          })
        )
        if (adminUpload) {
          notification['success']({
            message: 'Success',
            description: 'File uploaded.',
          })
          setVisible(false)
          setloading(false)
          setDescription('')
          setAdminUpload(null)
          fetchUploadedFiles()
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const showModal = () => setVisible(true)

  const handleCancel = () => {
    setDescription('')
    setAdminUpload('')
    setVisible(false)
  }

  const handleConfirmDelete = async (e, rId) => {
    e.preventDefault()
    try {
      const status = await API.graphql(
        graphqlOperation(deleteOtherUpload, {
          input: {
            id: rId,
          },
        })
      )
      if (status) {
        notification['success']({
          message: 'Success',
          description: 'Your File is successfully deleted.',
        })
      }
      fetchUploadedFiles()
    } catch (error) {
      console.log(error)
    }
  }

  const fetchUploadedFiles = async () => {
    try {
      const uploadedFilesData = await API.graphql(
        graphqlOperation(listOtherUploads)
      )
      const uploadedFilesList = uploadedFilesData.data.listOtherUploads.items
      setUploaded(
        uploadedFilesList.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    {
      title: 'Date Uploaded',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'File Name',
      dataIndex: 'filename',
      key: 'filename',
    },
    {
      title: 'Page or Section',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      render: (text, record) => (
        <span>
          <div className='d-flex flex-row-reverse'>
            <div className='p-2'>
              <Popconfirm
                placement='top'
                title={'Are you sure to delete this task?'}
                onConfirm={(e) => handleConfirmDelete(e, record.id)}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  size='medium'
                  style={{ marginLeft: '0px', marginTop: '3px' }}
                >
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </div>
            <div className='p-2'>
              <a
                rel='noopener noreferrer'
                href={record.location}
                target='_blank'
              >
                <Button
                  size='medium'
                  style={{ marginLeft: '0px', marginTop: '3px' }}
                >
                  <FundViewOutlined />
                </Button>
              </a>
            </div>
          </div>
        </span>
      ),
    },
  ]

  const showUploadPubMat = () => {
    setVisibleAnnouncement(true)
  }

  const closeUploadPubMat = () => {
    setVisibleAnnouncement(false)
    setAnnounceUpload([])
    setAnnounceDate(null)
  }

  const handleDate = (date, dateString) => {
    setAnnounceDate(dateString)
  }

  const showUploadNames = announceUpload.map((o, index) => {
    return (
      <span className={'fw-bold'} key={index}>
        {o.name}
      </span>
    )
  })

  const handleTargetParticipants = (e) => {
    setTpData(e.target.value)
  }

  const handleCourseDesc = (e) => {
    setCdData(e.target.value)
  }

  return (
    <>
      <div className='d-flex flex-column align-items-center gap-3'>
        <Button
          size='large'
          onClick={() => setMainModal(true)}
          className='mt-5 bg-success text-light'
        >
          View Others
        </Button>
        <Button
          size='large'
          onClick={() => setUploadTrainingModal(true)}
          className='bg-primary text-light'
        >
          View Training Video
        </Button>
      </div>

      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Uploaded Training Videos'
        visible={uploadTrainingModal}
        closable={false}
        footer={[]}
        width={900}
      >
        <div className='d-flex justify-content-center'>
          <UploadTrainingVideo refresh={(e) => setTriggerRefresh(e)} />
        </div>
        <div>
          <UploadedTrainingVideo refresh={triggerRefresh} />
        </div>

        <div className='d-flex justify-content-end'>
          <Button
            className='mt-3 text-end bg-danger text-light'
            onClick={() => setUploadTrainingModal(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>

      {/* Others Modal */}
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Others'
        visible={mainModal}
        closable={false}
        footer={[]}
        width={900}
      >
        <div
          className='site-layout-background mb-0 m-0 p-0'
          style={{
            padding: 24,
          }}
        >
          <div>
            <div className='p-2 d-flex justify-content-center'>
              {/* <label className="custom-file-upload">
                  <input
                    accept="image/jpeg,image/gif,image/png,application/pdf,video/mp4,video/x-m4v,video/*"
                    className="hide-input"
                    type="file"
                    onChange={(e) => getFile(e)}
                  />
                  <UploadOutlined />
                  Upload Here
            </label> */}

              <Button onClick={showModal} className='bg-primary text-white'>
                <UploadOutlined />
                Upload Here
              </Button>
              <Modal
                maskClosable={false}
                destroyOnClose={true}
                title='Add Files'
                visible={visible}
                closable={false}
                footer={[]}
              >
                <form onSubmit={handleSubmit}>
                  <div className='reg1-row'>
                    <span>
                      <label
                        className='loginInput-label'
                        style={{ marginBottom: 10 }}
                      >
                        Page or Section:
                      </label>
                      <Select
                        defaultValue='disabled'
                        style={{ width: 475 }}
                        onChange={handleChange}
                      >
                        <Option value='Recruitment'>Recruitment</Option>
                        <Option value='Employee Handbook'>
                          Employee Handbook
                        </Option>
                        <Option value='Orientation Videos'>
                          Orientation Videos
                        </Option>
                        <Option value='disabled' disabled>
                          None
                        </Option>
                      </Select>
                      {/*<TextArea
                                   onChange={e => setDescription(e.target.value)}  
                                   value={sdescription} 
                                   required
                                />*/}
                    </span>
                  </div>

                  <div className='reg1-row'>
                    <span>
                      <label
                        className='loginInput-label'
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        File Upload Here
                      </label>
                      <Input
                        type='file'
                        accept='image/jpeg,image/gif,image/png,application/pdf,video/mp4,video/x-m4v,video/*'
                        onChange={(e) => {
                          setAdminUpload(e.target.files[0])
                          setUploadName(e.target.files[0].name)
                        }}
                      />
                    </span>
                  </div>

                  <div
                    className='reg1-row d-flex justify-content-end'
                    style={{ marginTop: 20 }}
                  >
                    <Button style={{ marginRight: 20 }} onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button
                      key='submit'
                      type='primary'
                      htmlType='submit'
                      loading={loading}
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </Modal>
            </div>
          </div>
          <Card
            title='List of Uploaded Training Videos and Materials'
            className='p-4'
          >
            <Table
              bordered
              columns={columns}
              dataSource={uploaded}
              rowKey='id'
              className='table-responsive'
              pagination={{
                position: ['bottomCenter'],
                style: { textAlign: 'center' },
              }}
            />
          </Card>
          <div className='reg1-row d-flex justify-content-end mt-4 '>
            <Button
              onClick={() => setMainModal(false)}
              className='bg-danger text-light'
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}
