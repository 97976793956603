/**
 * Title: activityQuestionModalThree.js
 * Description: This is a file that contains activity question modal three
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/activityQuestionModalThree.js
 **/

import { useState, useEffect, useRef } from 'react'

import { Modal, Row, Button, Col, notification } from 'antd'

import { ActivityThreeAnswerByUserID } from '../../../api/queries'
import {
  createActivityThreeAnswer,
  updateActivityThreeAnswer,
} from '../../../api/mutations'
import { API, graphqlOperation } from 'aws-amplify'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import moment from 'moment'

export default function ActivityQuestionModalThreeBatch3({
  setProgressActivity,
  setIsCompleteMsgModalVisible,
  viewUserAnswer,
  userData,
}) {
  const user = userData

  const quillRef = useRef(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activityAnswer, setActivityAnswer] =
    useState(`<span>Date: September 7, 2023</span>
      <br />
                <span>From: Head of Finance</span>
                <br />
                <span>To: All Department Heads</span>
                <br />
                <br />
                <br />
                <span>Subject: Submission of Financial Statements</span>
                <br />
                <br />
                <br />
                <span>Greetings?</span>
                <br />
                <br />
                <br />
                <span>Please be remind that the local government s books of accounts
                  willl be closed on September 15, 2023. As such all financial
                  statemeents must be prepared and submitting on or before
                  September 12, 2023.
                </span>
                <br />
                <br />
                <br />
                <span>Department heads is expected to ensure that all iiquidations,
                  expense reports, and purchase order receipts were in order and
                  ready for submission on, or before the set deadline.
                </span>
                <br />
                <br />
                <span>In case of delays in preparing the financial statemeents due
                  to fortuitous events, please contact us so we can acted upon
                  it accordingly
                </span>
                <br />
                <br />
                <br />
                <span>Thank you.</span>
                <br />
                <br />
                <br />
                <span>Sincerely,</span>
                <br />
                <span>Ms.Celeste Santos</span>
                <br />
                <p>Head of Finance</span>`)
  const [isShowActivity, setIsShowActivity] = useState(false)
  const [activityThreeID, setActivityThreeID] = useState(null)
  const [countCheck, setCountCheck] = useState(0)

  /* useEffect(() => {
    if (viewUserAnswer.length > 0) {
      setActivityAnswer(viewUserAnswer[0].answerOne)
      setIsShowActivity(true)
    }
  }, [viewUserAnswer]) */

  const submitAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityThreeAnswer, {
          input: {
            id: activityThreeID,
            answer: JSON.stringify({
              answerOne: activityAnswer,
            }),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: true,
          },
        })
      )

      notification['success']({
        message: 'Success',
        description: 'Your answer is saved as final answer.',
      })
      setProgressActivity('alldone')
      setIsCompleteMsgModalVisible(true)
      setTimeout(() => {
        setIsShowActivity(false)
        setIsModalVisible(false)
      }, 3000)
    } catch (err) {
      console.log(err)
    }
  }

  const updateAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityThreeAnswer, {
          input: {
            id: activityThreeID,
            answer: JSON.stringify({
              answerOne: activityAnswer,
            }),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const createAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(createActivityThreeAnswer, {
          input: {
            userID: user.id,
            trainingAnnouncementID: 'cdb10950-01b5-428a-9959-0fc47237b528',
            answer: JSON.stringify({
              answerOne: activityAnswer,
            }),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: false,
          },
        })
      )

      setActivityThreeID(submitTimeOnly.data.createActivityThreeAnswer.id)
      setIsShowActivity(true)
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityThreeAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityThreeAnswerByUserID, { userID: user.id })
      )

      const itemsFetched = dataFetched.data.ActivityThreeAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            'cdb10950-01b5-428a-9959-0fc47237b528'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setIsShowActivity(false)
        } else {
          setIsShowActivity(true)
          setActivityAnswer(JSON.parse(itemsFetched[0].answer).answerOne)
          if (itemsFetched[0].id !== activityThreeID) {
            setActivityThreeID(itemsFetched[0].id)
          }
        }
      } else {
        createAnswerItem()
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    /* if (!viewUserAnswer.length) { */
    if (isModalVisible) {
      if (user) {
        getActivityThreeAnswerByUserID()
      }
    }
    /* } */
  }, [isModalVisible])

  useEffect(() => {
    /*   if (!viewUserAnswer.length) { */
    if (countCheck > 0) {
      updateAnswerItem()
    }
    /*   } */
  }, [countCheck])

  useEffect(() => {
    /*  if (!viewUserAnswer.length) { */
    let timeoutId

    const startTimer = () => {
      timeoutId = setTimeout(() => {
        console.log('Logging message every 10 seconds')

        if (isShowActivity && activityThreeID) {
          setCountCheck((prevCount) => prevCount + 10000)
        }

        startTimer() // Recursively call startTimer after 10 seconds
      }, 10000)
    }

    startTimer() // Start the initial timer

    return () => {
      clearTimeout(timeoutId) // Cleanup the timer on component unmount
    }
    /*  } */
  }, [isShowActivity, activityThreeID])

  useEffect(() => {
    if (quillRef.current) {
      const quillInstance = quillRef.current.getEditor()
      if (quillInstance) {
        const editorDiv = quillInstance.root
        editorDiv.setAttribute('spellcheck', 'false')
      }
    }
  })

  const handleActivityAnswer = (content) => {
    setActivityAnswer(content)
  }

  const handleSubmitActivityAnswer = async () => {
    try {
      submitAnswerItem()
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div>
      <Button
        className='bg-success text-white w-100'
        onClick={() => setIsModalVisible(true)}
      >
        {/*  {!viewUserAnswer ? 'Go to activity' : 'View'} */}
        Go to activity
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Activity'
        width={1000}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            /*    hidden={viewUserAnswer.length > 0} */
            key='submit'
            type='primary'
            /* disabled={isShowActivity || viewUserAnswer.length ? false : true} */
            disabled={isShowActivity ? false : true}
            // loading={loading}
            onClick={handleSubmitActivityAnswer}
          >
            Submit
          </Button>,
        ]}
      >
        <Row justify='center' className='font-Mont'>
          <Col span={24}>
            <Row className='mb-3' justify='left'>
              <h3>Exercise No. 4</h3>
            </Row>
            {isShowActivity ? (
              <>
                <Row className='mb-3' justify='left'>
                  <h3>
                    Instructions: Spot ten (10) errors in the memo below.
                    Correct the errors in grammar and punctuation. You may also
                    add punctuations when necessary.
                  </h3>
                </Row>
                <Row justify='center'>
                  <Col span={24}>
                    <ReactQuill
                      ref={quillRef}
                      spellCheck={false}
                      value={activityAnswer}
                      onChange={handleActivityAnswer}
                      id='activity-answer'
                      name='activity-answer'
                      className='mb-5 mt-1'
                      style={{ height: 400 }}
                      /*  readOnly={true} */
                    />
                  </Col>
                </Row>
                <Row></Row>
              </>
            ) : (
              <>
                <>
                  <Row className='mb-3' justify='left'>
                    <h3>Activity answer was already submitted as final.</h3>
                  </Row>
                </>
              </>
            )}
          </Col>
        </Row>
      </Modal>
    </div>
  )
}
