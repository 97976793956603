/**
 * Title: viewMyEventModal.js
 * Description: This is a file that contains the components for viewing the user's event modal
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/viewMyEventModal.js
 **/

import React, { useState, useEffect } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { deleteTrainingRegistration } from '../../api/mutations.js'
import {
  listTrainingAnnouncements,
  registrationByTAID,
} from '../../api/queries'
import { Button, Modal, Col, Row, notification, Popconfirm } from 'antd'

import UploadLetter from './uploadLetter.js'
import VideoPlayer from './videoPlayer.js'

import moment from 'moment'
import HtmlParser from './html-parser.js'

export default function ViewMyEvent({
  viewCourse,
  openView,
  setOpenView,
  toEdit,
  getMyevents,
  setSetOpenView,
  userData,
}) {
  const user = userData

  const [openViewLetter, setOpenViewLetter] = useState(false)
  const [trainingToUnenroll, setTrainingToUnenroll] = useState(null)
  const [listOfTrainings, setListOfTrainings] = useState([])
  const [
    idListOfChildTrainingToUnregister,
    setIdListOfChildTrainingToUnregister,
  ] = useState([])

  const fetchTrainings = async () => {
    const result = await API.graphql({
      query: listTrainingAnnouncements,
      authMode: 'API_KEY',
      variables: {
        limit: 1000,
      },
    })
    setListOfTrainings(result.data.listTrainingAnnouncements.items)
  }

  useEffect(() => {
    fetchTrainings()
  }, [])

  const diffTime = (x, y) => {
    var a = moment(x)
    var b = moment(y)
    var result = b.diff(a, 'days')
    if (result > 1) {
      return `${result} days`
    } else {
      return `${result} day`
    }
  }

  const targetParticipants = viewCourse?.targetParticipants
    ? viewCourse.targetParticipants.split(/\r?\n|\r|\n/g).map((line, ind) => {
        return (
          <React.Fragment key={ind}>
            <span>{`${line ? line : ''}`}</span>
            <br />
          </React.Fragment>
        )
      })
    : null

  const courseDescription = viewCourse?.courseDescription
    ? viewCourse.courseDescription.split(/\r?\n|\r|\n/g).map((line, ind) => {
        return (
          <React.Fragment key={ind}>
            <span>{`${line ? line : ''}`}</span>
            <br />
          </React.Fragment>
        )
      })
    : null

  const fetchTrainingRegistered = async () => {
    if (viewCourse && viewCourse.linkedTrainingAnnouncementIDs) {
      const childTraining = JSON.parse(viewCourse.linkedTrainingAnnouncementIDs)
      if (childTraining != null) {
        setIdListOfChildTrainingToUnregister([])
        const registrationIdOfChildToUnenroll = []
        const childTrainingIds = childTraining.filter((trainingId) =>
          listOfTrainings.some((x) => x.id === trainingId)
        )

        childTrainingIds.forEach(async (trainingId) => {
          const allUserRegisteredInSelectedTraining = await API.graphql(
            graphqlOperation(registrationByTAID, {
              trainingAnnouncementID: trainingId,
              limit: 1000,
            })
          )

          if (allUserRegisteredInSelectedTraining) {
            const trainings =
              allUserRegisteredInSelectedTraining.data.registrationByTAID.items
            const trainingToUnenroll = trainings.find(
              (item) => item.userID === user.id
            )
            registrationIdOfChildToUnenroll.push(trainingToUnenroll.id)
          }
        })

        setIdListOfChildTrainingToUnregister(registrationIdOfChildToUnenroll)
      }
    }

    const allUserRegisteredInSelectedTraining = await API.graphql(
      graphqlOperation(registrationByTAID, {
        trainingAnnouncementID: viewCourse.id,
        limit: 1000,
      })
    )

    if (allUserRegisteredInSelectedTraining) {
      const trainings =
        allUserRegisteredInSelectedTraining.data.registrationByTAID.items
      const trainingToUnenroll = trainings.find(
        (item) => item.userID === user.id
      )
      setTrainingToUnenroll(trainingToUnenroll)
    }
  }

  useEffect(() => {
    if (viewCourse) {
      fetchTrainingRegistered()
    }
  }, [viewCourse])

  const handleUnenroll = async () => {
    try {
      if (idListOfChildTrainingToUnregister.length > 0) {
        idListOfChildTrainingToUnregister.forEach(async (trainingRegId) => {
          await API.graphql(
            graphqlOperation(deleteTrainingRegistration, {
              input: {
                id: trainingRegId,
              },
            })
          )
        })
      }

      const result = await API.graphql(
        graphqlOperation(deleteTrainingRegistration, {
          input: {
            id: trainingToUnenroll.id,
          },
        })
      )

      if (result.data.deleteTrainingRegistration) {
        notification['success']({
          message: 'Success',
          description: 'Successfuly unerolled from the Training Event.',
        })
        getMyevents()

        setTimeout(() => {
          setSetOpenView(false)
        }, 1000)
      }
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description: 'Error. Please report to System Admin.',
      })
    }
  }

  return (
    <>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={'Course / Training'}
        centered
        visible={openView}
        onOk={() => setOpenView(false)}
        onCancel={() => setOpenView(false)}
        width={850}
        footer={null}
      >
        {viewCourse ? (
          <>
            <Row>
              <Col className='col-md-6 col-12 order-2 order-md-1 mt-md-0 mt-5'>
                {viewCourse?.title ? (
                  <Row>
                    <span>
                      <b>Course Title: </b>
                      <br />
                      <p>{`${viewCourse?.title}`}</p>
                    </span>
                  </Row>
                ) : null}

                <Row>
                  <span>
                    <b>Course Description: </b>
                    <br />
                    {viewCourse?.courseDescription ? (
                      <p>
                        <HtmlParser
                          content={viewCourse?.courseDescription?.replace(
                            /\n/g,
                            '<br>'
                          )}
                        />
                      </p>
                    ) : null}
                  </span>
                </Row>
                <Row>
                  <span>
                    <b>Target Participants: </b>
                    <br />
                    {viewCourse?.targetParticipants ? (
                      <p>
                        <HtmlParser
                          content={viewCourse?.targetParticipants?.replace(
                            /\n/g,
                            '<br>'
                          )}
                        />
                      </p>
                    ) : null}
                  </span>
                </Row>
                <Row>
                  <span>
                    <b>Course / Training Date: </b>
                    <br />
                    {`${moment(viewCourse.date).format('MMMM DD, YYYY')}`}
                  </span>
                </Row>
              </Col>
              <Col className='col-md-6 col-12 order-1 order-md-2'>
                <Row justify='center'>
                  <img
                    style={{ maxWidth: '250px' }}
                    src={JSON.parse(viewCourse.location)[0]}
                    alt='Course image.'
                  />
                </Row>
                <Row style={{ marginTop: 12 }} justify='center'>
                  <Button
                    onClick={() => setOpenViewLetter(true)}
                    type='primary'
                  >
                    Submit your Endorsement Letter
                  </Button>
                  <UploadLetter
                    viewCourse={viewCourse}
                    toEdit={toEdit}
                    openViewLetter={openViewLetter}
                    setOpenViewLetter={setOpenViewLetter}
                    userData={userData}
                  />
                </Row>
                {viewCourse.parentTrainingID ? (
                  <Row className='mt-2 justify-content-center'>
                    <div className='alert alert-info ms-2' role='alert'>
                      Unenroll to the parent training
                    </div>
                  </Row>
                ) : (
                  <Popconfirm
                    placement='top'
                    title={'Are you sure to unenroll this training?'}
                    onConfirm={handleUnenroll}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Row style={{ marginTop: 12 }} justify='center'>
                      <Button className='bg-danger text-white'>
                        Unenroll training
                      </Button>
                    </Row>
                  </Popconfirm>
                )}
                <Row style={{ marginTop: 12 }} justify='center'>
                  <VideoPlayer />
                </Row>
              </Col>
            </Row>
          </>
        ) : null}
      </Modal>
    </>
  )
}
