/**
 * Title: CSCOnlineVideosAccessList.js
 * Description: This is a file that contains the CSC online videos access list
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/CSCOnlineVideosAccessList.js
 **/

import React, { useEffect, useState, useRef } from 'react'

import 'antd/dist/antd.css'
import { Card, Input, Space, Table, Tag, Button, Row, Col, Tabs } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import { API, graphqlOperation } from 'aws-amplify'
import { listCscMaterials } from './../../api/queries'

import Highlighter from 'react-highlight-words'

import moment from 'moment'

const { TabPane } = Tabs

/**
 *@function LDRefresherViewLists
 *@description React component that displays the lists of employees' access to the refresher PDF
 * and refresher videos for Learning and Development (L&D).
 *The component fetches the data for the lists from the API using AWS Amplify and renders the data in tables.
 *It provides search and filter functionality for the tables, allowing users to search for
 * specific employee names and filter the data based on different criteria.
 *The component uses Ant Design's Tabs component to switch between the lists of PDF and video access.
 *@returns {Function} A function that renders the LDRefresherViewLists component.
 *@throws {Error} If there is an error while fetching or processing the data for the lists of PDF and video access.
 */
export default function CSCOnlineVideosAccessList() {
  const [nextNextToken, setNextNextToken] = useState()
  const [previousTokens, setPreviousTokens] = useState([])

  const [lDRefresherPDFAccess, setLDRefresherPDFAccess] = useState([])
  const [tabKey, setTabKey] = useState('1')

  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  /**
   *@async
   *@function getLdRefresherListAccess
   *@description Retrieves the list of employee access to the refresher PDF for Learning and Development (L&D)
   * from the API.
   *The function uses AWS Amplify to send a GraphQL query with pagination to fetch the data in batches.
   *It iteratively retrieves the items from each batch and appends them to the 'items' array.
   *The pagination is controlled using the 'nextToken' value, which is updated with each iteration until
   * all items are fetched.
   *Once all the items are fetched, the 'items' array is stored in the 'lDRefresherPDFAccess' state variable for further use.
   *@throws {Error} If there is an error while fetching or processing the data from the API.
   */
  const getLdRefresherListAccess = async () => {
    try {
      let items = []
      let nextToken = null
      const limit = 1000

      do {
        const variables = { limit, nextToken }
        const result = await API.graphql(
          graphqlOperation(listCscMaterials, variables)
        )
        items.push(...result.data.listCscMaterials.items)
        nextToken = result.data.listCscMaterials.nextToken
      } while (nextToken)

      setLDRefresherPDFAccess(items)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getLdRefresherListAccess()
  }, [])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  // Reset name filter
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  /**
   *@function getColumnSearchProps
   *@description Returns an object containing properties for implementing column search and
   * filter functionality in an Ant Design Table component.
   *@param {string} dataIndex - The key of the column data for which the search
   * and filter functionality is applied.
   *@returns {object} An object with properties for search and filter functionality.
   */
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            className='d-flex align-items-center justify-content-center'
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      key: 'employeeName',
      className: 'col-3 text-capitalize',
      sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
      ...getColumnSearchProps('employeeName'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      className: 'col-4',
      sorter: (a, b) => a.department.localeCompare(b.department),
    },
    {
      title: 'Date of CSC Training Additional Materials Access',
      dataIndex: 'date',
      key: 'locale',
      className: 'col-3',
      sorter: (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
      render: (text, record) => (
        <Tag color='geekblue'>
          {moment(new Date(record.createdAt)).format('MMMM D YYYY h:mm A')}
        </Tag>
      ),
    },
  ]

  const newColumn = {
    title: 'PDF Name',
    dataIndex: 'materials',
    key: 'materials',
    className: 'col-2',
    sorter: (a, b) => {
      if (a.materials && b.materials) {
        return a.materials.localeCompare(b.materials)
      }
      return 0
    },
    render: (text, record) => {
      const filename = record.materials
      if (filename) {
        const nameWithoutExtension = filename.slice(
          0,
          filename.lastIndexOf('.')
        )
        return <span>{nameWithoutExtension}</span>
      }
      return null
    },
  }

  const columnMaterials = [
    ...columns.slice(0, 2),
    newColumn,
    ...columns.slice(2),
  ]

  const handleTabChange = (e) => {
    setTabKey(e)
  }

  return (
    <>
      <div className='site-layout-background' style={{ padding: 24 }}>
        <Tabs type='card' defaultActiveKey='1'>
          <TabPane
            tab='List of Employees Access of CSC Training Additional Materials'
            key='1'
          >
            <Row>
              <Col span={24}>
                <Card title='List of Employees Access of CSC Training Additional Materials'>
                  <Table
                    bordered
                    dataSource={lDRefresherPDFAccess.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )}
                    columns={columnMaterials}
                    className={'font-Mont'}
                    pagination={{ pageSize: 50 }}
                    rowKey='id'
                  />
                </Card>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </>
  )
}
