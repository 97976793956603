/**
 * Title: assessmentMaker.js
 * Description: This is a file that contains the Assessment Maker
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/assessmentMaker.js
 **/

/*
 *Changes made:
 *2024.08.09  | Raymart Mojado | add image on the multipleChoice if have image
 *2024.08.09  | Raymart Mojado | use before upload to convert the blob to base64
 *2024.08.21  | Raymart Mojado | add a condition on the questions and  answers if the value is empty
 */

import React, { useState } from 'react'

import EvaluationMakerPreview from './evaluationMakerPreview.js'
import AssessmentMakerPreview from './assessmentMakerPreview'

import {
  notification,
  Button,
  Modal,
  Row,
  Col,
  Input,
  Select,
  Radio,
  Upload,
  Image,
  message,
} from 'antd'

import { PlusOutlined, UploadOutlined } from '@ant-design/icons'

const { TextArea } = Input

const props = {
  beforeUpload: (file) => {
    const imageList = [
      'image/png',
      'image/jpeg',
      'image/jpg',
      'image/gif',
      'image/bmp',
      'image/webp',
      'image/avif',
    ]
    const isImage = imageList.includes(file.type)
    const maxSize = 3 * 1024 * 1024 // 3MB

    if (!isImage) {
      message.error(`${file.name} is not an image file!`)
      return Upload.LIST_IGNORE
    }

    if (file.size > maxSize) {
      message.error(
        `File size exceeds the limit. Maximum file size allowed is 3MB.`
      )
      return Upload.LIST_IGNORE
    }
  },
}

export default function AssessmentMaker({
  setGetAssessmentID,
  userData,
  setShowPostAssessment,
}) {
  const [restart, setRestart] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [newQuestion, setNewQuestion] = useState([])

  const [questionsArray, setQuestionsArray] = useState([])
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState('')
  const [openAssessmentPreview, setOpenAssessmentPreview] = useState(false)

  const handleOpenModal = () => {
    try {
      setOpenModal(true)
      setTimeout(() => {
        setRestart(true)
      }, 500)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    try {
      setOpenModal(false)
      setRestart(false)
      setNewQuestion([])
      setQuestionsArray([])
    } catch (error) {
      console.log(error)
    }
  }

  const removeQuestion = (index) => {
    try {
      const updatedQuestion2 = [...questionsArray]
      const updatedQuestion = [...newQuestion]
      updatedQuestion.splice(index, 1)
      updatedQuestion2.splice(index, 1)
      setNewQuestion(updatedQuestion)
      setQuestionsArray(updatedQuestion2)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCorrectAnswerChange = (e, index) => {
    try {
      const updatedQuestionArray = [...questionsArray]
      const questionObjectToUpdate = { ...updatedQuestionArray[index] }
      questionObjectToUpdate.correctAnswer = e.target.value
      updatedQuestionArray[index] = questionObjectToUpdate
      setQuestionsArray(updatedQuestionArray)
    } catch (error) {
      console.log(error)
    }
  }

  const handleQuestionChange = (e, index) => {
    try {
      const updatedQuestionArray = [...questionsArray]
      const questionObjectToUpdate = { ...updatedQuestionArray[index] }
      questionObjectToUpdate.question = e.target.value
      updatedQuestionArray[index] = questionObjectToUpdate
      setQuestionsArray(updatedQuestionArray)
    } catch (error) {
      console.log(error)
    }
  }

  const handleAnswerChoicesChange = (e, index, choicesNumber) => {
    try {
      const choice = `choices-${choicesNumber}-${index + 1}`

      const updatedQuestionArray = [...questionsArray]
      const questionObjectToUpdate = { ...updatedQuestionArray[index] }
      questionObjectToUpdate.choices[choicesNumber - 1][choice] = e.target.value
      updatedQuestionArray[index] = questionObjectToUpdate
      setQuestionsArray(updatedQuestionArray)
    } catch (error) {
      console.log(error)
    }
  }
  /**
   * Handles the preview of an image file.
   *
   * This function sets the preview image URL and opens the preview modal.
   * It uses the URL of the file or its thumbnail URL if the URL is not available.
   *
   * @function handlePreview
   * @param {Object} file - The file object containing the image data.
   * @param {string} file.url - The URL of the image file.
   * @param {string} file.thumbUrl - The thumbnail URL of the image file.
   */
  const handlePreview = (file) => {
    setPreviewImage(file.url || file.thumbUrl)
    setPreviewOpen(true)
  }
  /**
   * Handles the change event for image uploads in the questions array.
   *
   * This function updates the `imageUploadQuestion` property of the question at the specified index
   * with the new file list from the event. It creates a new array of questions to avoid mutating the
   * existing state directly and then updates the state with the new array.
   *
   * @function handleChangeImage
   * @param {number} index - The index of the question in the questions array.
   * @param {Object} e - The event object containing the file list.
   * @param {Array} e.fileList - The list of files uploaded.
   */
  const handleChangeImage = (index, base64, file) => {
    const newQuestionsArray = [...questionsArray]

    // Ensure imageUploadQuestion is an array
    if (!Array.isArray(newQuestionsArray[index].imageUploadQuestion)) {
      newQuestionsArray[index].imageUploadQuestion = []
    }

    // Add the new image to the imageUploadQuestion array
    newQuestionsArray[index].imageUploadQuestion.push({
      uid: file.uid,
      name: file.name,
      status: 'done',
      url: base64,
      file: file,
    })

    setQuestionsArray(newQuestionsArray)
  }
  /**
   * Handles the removal of an uploaded image from a question's imageUploadQuestion array.
   *
   * This function updates the `imageUploadQuestion` property of the question at the specified index
   * by filtering out the file with the matching `uid`. It creates a new array of questions to avoid
   * mutating the existing state directly and then updates the state with the new array.
   *
   * @function handleRemove
   * @param {Object} file - The file object to be removed.
   * @param {string} file.uid - The unique identifier of the file.
   * @param {number} index - The index of the question in the questions array.
   */
  const handleRemove = (file, index) => {
    const newQuestionsArray = [...questionsArray]
    newQuestionsArray[index].imageUploadQuestion = newQuestionsArray[
      index
    ].imageUploadQuestion.filter((item) => item.uid !== file.uid)
    setQuestionsArray(newQuestionsArray)
  }

  const handleCustomRequestSuccess = ({ file, onSuccess }) => {
    onSuccess('ok')
  }
  const addRow = newQuestion.map((questionType, index) => {
    try {
      const handleRadioChange = async (e, index) => {
        try {
          const { value } = e.target
          const updatedQuestions = [...newQuestion]

          if (value == 'trueOrFalse') {
            updatedQuestions[index] = 'trueOrFalse'
            setNewQuestion(updatedQuestions)
            const updatedQuestionArray = [...questionsArray]
            const questionObjectToUpdate = { ...updatedQuestionArray[index] }
            questionObjectToUpdate.choices[0][`choices-1-${index + 1}`] = ''
            questionObjectToUpdate.choices[1][`choices-2-${index + 1}`] = ''
            questionObjectToUpdate.choices[2][`choices-3-${index + 1}`] = 'n/a'
            questionObjectToUpdate.choices[3][`choices-4-${index + 1}`] = 'n/a'
            updatedQuestionArray[index] = questionObjectToUpdate
            questionObjectToUpdate.questionType = 'trueOrFalse'

            setQuestionsArray(updatedQuestionArray)
          }

          if (value == 'enumeration') {
            updatedQuestions[index] = 'enumeration'
            setNewQuestion(updatedQuestions)
            const updatedQuestionArray = [...questionsArray]
            const questionObjectToUpdate = { ...updatedQuestionArray[index] }
            questionObjectToUpdate.choices[0][`choices-1-${index + 1}`] = 'n/a'
            questionObjectToUpdate.choices[1][`choices-2-${index + 1}`] = 'n/a'
            questionObjectToUpdate.choices[2][`choices-3-${index + 1}`] = 'n/a'
            questionObjectToUpdate.choices[3][`choices-4-${index + 1}`] = 'n/a'
            updatedQuestionArray[index] = questionObjectToUpdate
            questionObjectToUpdate.correctAnswer = 'n/a'
            questionObjectToUpdate.questionType = 'enumeration'
            setQuestionsArray(updatedQuestionArray)
          }

          if (value === 'multipleChoice') {
            updatedQuestions[index] = 'multipleChoice'
            const updatedQuestionArray = [...questionsArray]
            const questionObjectToUpdate = { ...updatedQuestionArray[index] }
            questionObjectToUpdate.choices[0][`choices-1-${index + 1}`] = ''
            questionObjectToUpdate.choices[1][`choices-2-${index + 1}`] = ''
            questionObjectToUpdate.choices[2][`choices-3-${index + 1}`] = ''
            questionObjectToUpdate.choices[3][`choices-4-${index + 1}`] = ''
            questionObjectToUpdate.correctAnswer = 'empty'
            questionObjectToUpdate.questionType = 'multipleChoice'
            questionObjectToUpdate.imageUploadQuestion = []
            updatedQuestionArray[index] = questionObjectToUpdate
            setQuestionsArray(updatedQuestionArray)
            setNewQuestion(updatedQuestions)
          }
        } catch (error) {
          console.log(error)
        }
      }

      return (
        <React.Fragment key={index}>
          <hr hidden={index === 0} />
          <Row className={'mb-4'}>
            <Col span={24}>
              <p className='text-center'>
                <em>
                  <strong>Question Type</strong>
                </em>
              </p>
              <Radio.Group
                onChange={(e) => handleRadioChange(e, index)}
                defaultValue='multipleChoice'
                className='d-flex justify-content-center'
              >
                <Radio value='multipleChoice'>Multiple Choice</Radio>
                <Radio value='trueOrFalse'>True or False</Radio>
                <Radio value='enumeration'>Essay</Radio>
              </Radio.Group>
              <Row
                className={
                  'mb-3 d-flex justify-content-between align-items-center'
                }
              >
                <label htmlFor={`question-${index + 1}`}>
                  <strong>Question #{index + 1}:</strong>
                </label>
                <Button
                  onClick={() => removeQuestion(index)}
                  className='text-white bg-danger'
                >
                  X
                </Button>
              </Row>
              <Row className={'mb-3'}>
                <TextArea
                  id={`question-${index + 1}`}
                  style={{ width: '100%' }}
                  rows={4}
                  value={
                    questionsArray[index] ? questionsArray[index].question : ''
                  }
                  onChange={(e) => handleQuestionChange(e, index)}
                  maxLength={512}
                  // onChange={(e) => handleInputChange(e, index)}
                />
              </Row>
              <Row
                className={'mb-3'}
                hidden={
                  questionType === 'enumeration' ||
                  questionType === 'trueOrFalse'
                }
              >
                <Upload
                  // {...props}
                  listType='picture-card'
                  fileList={
                    Array.isArray(questionsArray) &&
                    questionsArray[index] &&
                    Array.isArray(questionsArray[index].imageUploadQuestion)
                      ? questionsArray[index].imageUploadQuestion
                      : []
                  }
                  accept='image/*'
                  onPreview={handlePreview}
                  beforeUpload={(file) => {
                    const imageList = [
                      'image/png',
                      'image/jpeg',
                      'image/jpg',
                      'image/gif',
                      'image/bmp',
                      'image/webp',
                      'image/avif',
                    ]
                    const isImage = imageList.includes(file.type)
                    const maxSize = 3 * 1024 * 1024 // 3MB

                    if (!isImage) {
                      message.error(`${file.name} is not an image file!`)
                      return Upload.LIST_IGNORE
                    }

                    if (file.size > maxSize) {
                      message.error(
                        `File size exceeds the limit. Maximum file size allowed is 3MB.`
                      )
                      return Upload.LIST_IGNORE
                    }
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = () => {
                      const base64 = reader.result
                      handleChangeImage(index, base64, file)
                    }
                    return false // Prevent automatic upload
                  }}
                  onRemove={(file) => handleRemove(file, index)}
                  customRequest={handleCustomRequestSuccess}
                >
                  {questionsArray[index] &&
                  questionsArray[index].imageUploadQuestion &&
                  questionsArray[index].imageUploadQuestion.length >=
                    1 ? null : (
                    <div>
                      <UploadOutlined />
                      <div style={{ marginTop: 8 }}>Upload</div>
                    </div>
                  )}
                </Upload>
                {questionsArray[index] &&
                  questionsArray[index].imageUploadQuestion && (
                    <Image
                      wrapperStyle={{
                        display: 'none',
                      }}
                      preview={{
                        visible: previewOpen,
                        onVisibleChange: (visible) => setPreviewOpen(visible),
                        afterOpenChange: (visible) =>
                          !visible && setPreviewImage(''),
                      }}
                      src={previewImage}
                    />
                  )}
              </Row>
              <Row className='flex-column'>
                <Row className='mt-3' hidden={questionType === 'enumeration'}>
                  <label htmlFor={`answer-${index + 1}`}>
                    Correct Answer:{' '}
                  </label>
                  <select
                    id={`answer-${index + 1}`}
                    className='ms-2 pe-2 ps-2 text-center'
                    value={
                      questionsArray[index]
                        ? questionsArray[index].correctAnswer
                        : ''
                    }
                    // value={questionsArray[index].answer}
                    // onChange={(e) => handleInputChange(e, index)}
                    onChange={(e) => handleCorrectAnswerChange(e, index)}
                  >
                    <option
                      disabled
                      value='empty'
                      label='Click here to select'
                      className='text-muted'
                    ></option>
                    <option value='A'>A</option>
                    <option value='B'>B</option>
                    {questionType !== 'trueOrFalse' && (
                      <>
                        <option value='C'>C</option>
                        <option value='D'>D</option>
                      </>
                    )}
                  </select>
                </Row>
              </Row>
              <label className='mt-4' hidden={questionType === 'enumeration'}>
                Answer Choices:{' '}
              </label>
              <Row
                justify='space-between'
                hidden={questionType === 'enumeration'}
              >
                <Col span={24} className='mt-3'>
                  <label className={'me-2'} htmlFor={`choices-1-${index + 1}`}>
                    <em>A.</em>
                  </label>
                  <TextArea
                    id={`choices-1-${index + 1}`}
                    onChange={(e) => handleAnswerChoicesChange(e, index, 1)}
                    value={
                      questionsArray[index] &&
                      questionsArray[index].choices &&
                      questionsArray[index].choices[0]
                        ? questionsArray[index].choices[0][
                            `choices-1-${index + 1}`
                          ]
                        : undefined
                    }
                    maxLength={512}
                    // onChange={(e) => handleInputChange(e, index)}
                  />
                </Col>
                <Col span={24} className='mt-2'>
                  <label className={'me-2'} htmlFor={`choices-2-${index + 1}`}>
                    <em>B.</em>
                  </label>
                  <TextArea
                    id={`choices-2-${index + 1}`}
                    onChange={(e) => handleAnswerChoicesChange(e, index, 2)}
                    value={
                      questionsArray[index] &&
                      questionsArray[index].choices &&
                      questionsArray[index].choices[1]
                        ? questionsArray[index].choices[1][
                            `choices-2-${index + 1}`
                          ]
                        : ''
                    }
                    maxLength={512}
                    // onChange={(e) => handleInputChange(e, index)}
                  />
                </Col>
                <Col
                  span={24}
                  className='mt-2'
                  hidden={questionType === 'trueOrFalse'}
                >
                  <label className={'me-2'} htmlFor={`choices-3-${index + 1}`}>
                    <em>C.</em>
                  </label>
                  <TextArea
                    id={`choices-3-${index + 1}`}
                    // defaultValue={questionType === "trueOrFalse" ? "n/a" : ""}
                    onChange={(e) => handleAnswerChoicesChange(e, index, 3)}
                    value={
                      questionsArray[index] &&
                      questionsArray[index].choices &&
                      questionsArray[index].choices[2]
                        ? questionsArray[index].choices[2][
                            `choices-3-${index + 1}`
                          ]
                        : ''
                    }
                    maxLength={512}
                    // onChange={(e) => handleInputChange(e, index)}
                  />
                </Col>
                <Col
                  span={24}
                  className='mt-2'
                  hidden={questionType === 'trueOrFalse'}
                >
                  <label className={'me-2'} htmlFor={`choices-4-${index + 1}`}>
                    <em>D.</em>
                  </label>
                  <TextArea
                    id={`choices-4-${index + 1}`}
                    // defaultValue={questionType === "trueOrFalse" ? "n/a" : ""}
                    value={
                      questionsArray[index] &&
                      questionsArray[index].choices &&
                      questionsArray[index].choices[3]
                        ? questionsArray[index].choices[3][
                            `choices-4-${index + 1}`
                          ]
                        : ''
                    }
                    onChange={(e) => handleAnswerChoicesChange(e, index, 4)}
                    maxLength={512}
                    // onChange={(e) => handleInputChange(e, index)}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
    }
  })

  const handleAddRow = () => {
    const updatedQuestions = [...newQuestion, ''] // Create a new array with the updated questions

    const index = updatedQuestions.length

    const newQuestionObject = {
      choices: [
        { [`choices-1-${index}`]: '' },
        { [`choices-2-${index}`]: '' },
        { [`choices-3-${index}`]: '' },
        { [`choices-4-${index}`]: '' },
      ],
      correctAnswer: 'empty',
      question: '',
      questionType: 'multipleChoice',
    }

    const newQuestionsArray = [...questionsArray, newQuestionObject]

    setNewQuestion(updatedQuestions)
    setQuestionsArray(newQuestionsArray)
  }

  const handleCreateQuestion = () => {
    if (questionsArray.length < 1) {
      return notification['info']({
        message: 'Info',
        description: 'No question to save.',
      })
    }

    let hasEmptyField = false

    questionsArray.forEach((question) => {
      if (question.question === '' || question.correctAnswer === 'empty') {
        hasEmptyField = true
        return notification['info']({
          message: 'Info',
          description: 'All fields must be filled.',
        })
      }

      if (
        question.choices.some((choiceObj) => Object.values(choiceObj)[0] === '')
      ) {
        hasEmptyField = true
        return notification['info']({
          message: 'Info',
          description: 'All fields must be filled.',
        })
      }
    })

    if (hasEmptyField) {
      return // Terminate the function
    }

    setOpenAssessmentPreview(true)
  }

  /* const createQuestions = () => {
    try {
      const questionsFound =
        document.querySelectorAll('[id*="question-"]').length >= 1;

      if (!questionsFound) {
        notification["info"]({
          message: "Info",
          description: "No question to save.",
        });
      } else {
        let holdErrors = [];
        const questionsInTextArea =
          document.querySelectorAll('[id*="question-"]');

        questionsInTextArea.forEach((elem) => {
          if (!elem.value.length) {
            holdErrors.push("");
          }
        });

        if (holdErrors.length) {
          notification["info"]({
            message: "Info",
            description: "All fields must be filled.",
          });
        } else {
          let newData = [];
          let holdBlankInputs = [];
          let missingAnswers = [];
          questionsInTextArea.forEach((elem, index) => {
            if (!newData[index]) {
              newData[index] = { question: "", choices: [], correctAnswer: "" };
            }

            const id = elem.id;
            const value = elem.value;

            if (id.includes("question")) {
              newData[index].question = value;
            }

            const inputElems =
              elem.parentElement.parentElement.querySelectorAll(
                '[id*="choices-"]'
              );

            inputElems.forEach((inputElement, inputIndex) => {
              let choice = {
                [`${inputElement.id}`]: inputElement.value,
              };

              if (inputElement.value.length) {
                newData[index].choices.push(choice);
              } else {
                holdBlankInputs.push("");
              }
            });

            const selectElems =
              elem.parentElement.parentElement.querySelectorAll(
                '[id*="answer-"]'
              );

            selectElems.forEach((selectElement) => {
              let answer = selectElement.value;

              if (selectElement.value && selectElement.value !== 'empty') {
                newData[index].correctAnswer = answer;
              } else {
                missingAnswers.push("");
              }
            });
          });

          if (holdBlankInputs.length) {
            notification["info"]({
              message: "Info",
              description: "All fields must be filled.",
            });
          } else {
            if (missingAnswers.length >= 1) {
              notification["info"]({
                message: "Info",
                description: "Missing correct answer/s.",
              });
            } else {
              setQuestionsArray(newData);
              setOpenAssessmentPreview(true);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }; */

  return (
    <>
      <Button ghost type='primary' onClick={handleOpenModal}>
        Create Pre-assessment & Post-assessment Questionnaire
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Create Assessment Questionnaire'
        visible={openModal}
        footer={null}
        onCancel={handleCancel}
        width={800}
      >
        {restart ? (
          <>
            <Row>
              <Col span={24}>
                <>{addRow}</>
              </Col>
            </Row>
            <Row justify='space-between' className={'mb-4'}>
              <Button
                onClick={handleAddRow}
                type='dashed'
                className={'d-flex align-items-center'}
              >
                <PlusOutlined />
                Add question
              </Button>
              <Button onClick={() => handleCreateQuestion()}>Save</Button>
              {openAssessmentPreview && (
                <AssessmentMakerPreview
                  userData={userData}
                  openAssessmentPreview={openAssessmentPreview}
                  setOpenAssessmentPreview={(e) => setOpenAssessmentPreview(e)}
                  // handleCreateQuestion={() => handleCreateQuestion()}
                  closeMotherModal={(e) => setOpenModal(e)}
                  setGetAssessmentID={(e) => setGetAssessmentID(e)}
                  questionsArray={questionsArray}
                  setQuestionsArray={(e) => setQuestionsArray(e)}
                  setNewQuestion={(e) => setNewQuestion(e)}
                />
              )}
            </Row>
          </>
        ) : null}
      </Modal>
    </>
  )
}
