/**
 * Title: activity2.js
 * Description: This is a file that contains activity 2
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/activity2.js
 **/

import { useState, useEffect, useRef } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  ActivityTwoAnswerByUserID,
  listTrainingVideos,
} from '../../api/queries'
import {
  createActivityTwoAnswer,
  deleteTrainingVideo,
  updateActivityTwoAnswer,
} from '../../api/mutations'

import moment from 'moment'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  PlayCircleFilled,
} from '@ant-design/icons'
import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  Row,
  Col,
} from 'antd'

export default function Activity2({
  setProgressActivity,
  setIsModalActivityOpen,
  viewUserAnswer,
  userData,
}) {
  const user = userData

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activityTwoAnswers, setActivityTwoAnswers] = useState({})
  const [activityOneID, setActivityOneID] = useState(null)
  const [isShowActivity, setIsShowActivity] = useState(false)
  const [countCheck, setCountCheck] = useState(0)

  useEffect(() => {
    if (viewUserAnswer.length > 0) {
      setActivityTwoAnswers(JSON.parse(viewUserAnswer[0].answer))
      setIsShowActivity(true)
    }
  }, [viewUserAnswer])

  const submitAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityTwoAnswer, {
          input: {
            id: activityOneID,
            answer: JSON.stringify(activityTwoAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: true,
          },
        })
      )

      notification['success']({
        message: 'Success',
        description: 'Your answer is saved as final answer.',
      })
      setIsModalActivityOpen(false)
      setProgressActivity('3')
      setTimeout(() => {
        setIsShowActivity(false)
        setIsModalVisible(false)
      }, 3000)
    } catch (err) {
      console.log(err)
    }
  }

  const updateAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityTwoAnswer, {
          input: {
            id: activityOneID,
            answer: JSON.stringify(activityTwoAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const createAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(createActivityTwoAnswer, {
          input: {
            userID: user.id,
            trainingAnnouncementID: 'cdb10950-01b5-428a-9959-0fc47237b528',
            answer: JSON.stringify(activityTwoAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: false,
          },
        })
      )

      setActivityOneID(submitTimeOnly.data.createActivityTwoAnswer.id)
      setIsShowActivity(true)
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityOneAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityTwoAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityTwoAnswerByUserID.items
      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setIsShowActivity(false)
        } else {
          setIsShowActivity(true)
          setActivityTwoAnswers(JSON.parse(itemsFetched[0].answer))
          if (itemsFetched[0].id !== activityOneID) {
            setActivityOneID(itemsFetched[0].id)
          }
        }
      } else {
        createAnswerItem()
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!viewUserAnswer.length) {
      if (isModalVisible) {
        if (user) {
          getActivityOneAnswerByUserID()
        }
      }
    }
  }, [isModalVisible])

  useEffect(() => {
    if (!viewUserAnswer.length) {
      if (countCheck > 0) {
        updateAnswerItem()
      }
    }
  }, [countCheck])

  useEffect(() => {
    if (!viewUserAnswer.length) {
      let timeoutId

      const startTimer = () => {
        timeoutId = setTimeout(() => {
          if (isShowActivity && activityOneID) {
            setCountCheck((prevCount) => prevCount + 10000)
          }

          startTimer() // Recursively call startTimer after 10 seconds
        }, 10000)
      }

      startTimer() // Start the initial timer

      return () => {
        clearTimeout(timeoutId) // Cleanup the timer on component unmount
      }
    }
  }, [isShowActivity, activityOneID])

  useEffect(() => {
    if (!viewUserAnswer.length) {
      const initialAnswers = {}

      for (let i = 1; i <= 10; i++) {
        initialAnswers[`Question${i}`] = ''
        initialAnswers[`Question${i}_rewrite`] = ''
      }

      setActivityTwoAnswers(initialAnswers)
    }
  }, [])

  const handleInputChange = (questionName, value) => {
    const newAnswers = { ...activityTwoAnswers }
    newAnswers[questionName] = value
    setActivityTwoAnswers(newAnswers)
  }

  const handleSubmitActivityAnswer = async () => {
    try {
      submitAnswerItem()
    } catch (err) {
      console.log(err)
    }
  }

  const questions = [
    {
      question:
        'When I get my salary, I will treat my officemates to fish ball and kwekkwek.',
      choices: [
        { value: 'A', label: 'A) Dependent Clause' },
        { value: 'B', label: 'B) Independent Clause' },
      ],
      rewriteLabel: 'Rewrite (if dependent clause):',
      questionName: 'Question1',
    },
    {
      question: 'Each time I feel lonely.',
      choices: [
        { value: 'A', label: 'A) Dependent Clause' },
        { value: 'B', label: 'B) Independent Clause' },
      ],
      rewriteLabel: 'Rewrite (if dependent clause):',
      questionName: 'Question2',
    },
    {
      question: 'Because when one feels the blues on rainy days.',
      choices: [
        { value: 'A', label: 'A) Dependent Clause' },
        { value: 'B', label: 'B) Independent Clause' },
      ],
      rewriteLabel: 'Rewrite (if dependent clause):',
      questionName: 'Question3',
    },
    {
      question:
        'I was so happy when the Grab driver finally arrived with the pancit I ordered.',
      choices: [
        { value: 'A', label: 'A) Dependent Clause' },
        { value: 'B', label: 'B) Independent Clause' },
      ],
      rewriteLabel: 'Rewrite (if dependent clause):',
      questionName: 'Question4',
    },
    {
      question:
        'As the Human Resources started to implement the new performance appraisal system.',
      choices: [
        { value: 'A', label: 'A) Dependent Clause' },
        { value: 'B', label: 'B) Independent Clause' },
      ],
      rewriteLabel: 'Rewrite (if dependent clause):',
      questionName: 'Question5',
    },
    {
      question: 'How sweet it is to be loved by you.',
      choices: [
        { value: 'A', label: 'A) Dependent Clause' },
        { value: 'B', label: 'B) Independent Clause' },
      ],
      rewriteLabel: 'Rewrite (if dependent clause):',
      questionName: 'Question6',
    },
    {
      question: 'Upon entering the gated campus of the Quezon City University.',
      choices: [
        { value: 'A', label: 'A) Dependent Clause' },
        { value: 'B', label: 'B) Independent Clause' },
      ],
      rewriteLabel: 'Rewrite (if dependent clause):',
      questionName: 'Question7',
    },
    {
      question:
        'As one lines up outside the elevator before going up the building.',
      choices: [
        { value: 'A', label: 'A) Dependent Clause' },
        { value: 'B', label: 'B) Independent Clause' },
      ],
      rewriteLabel: 'Rewrite (if dependent clause):',
      questionName: 'Question8',
    },
    {
      question:
        'The introduction of the 4-step process intended to make the processing of the permits faster.',
      choices: [
        { value: 'A', label: 'A) Dependent Clause' },
        { value: 'B', label: 'B) Independent Clause' },
      ],
      rewriteLabel: 'Rewrite (if dependent clause):',
      questionName: 'Question9',
    },
    {
      question:
        'The City Council expressed elation after the city ordinance meant to provide scholarships for deserving students was approved.',
      choices: [
        { value: 'A', label: 'A) Dependent Clause' },
        { value: 'B', label: 'B) Independent Clause' },
      ],
      rewriteLabel: 'Rewrite (if dependent clause):',
      questionName: 'Question10',
    },
  ]

  return (
    <>
      <Button
        className='bg-success text-white'
        onClick={() => setIsModalVisible(true)}
      >
        {!viewUserAnswer ? 'Go to activity' : 'View'}
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Activity'
        width={1000}
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            hidden={viewUserAnswer.length > 0}
            key='submit'
            type='primary'
            disabled={isShowActivity || viewUserAnswer.length ? false : true}
            // loading={loading}
            onClick={handleSubmitActivityAnswer}
          >
            Submit
          </Button>,
        ]}
      >
        <div>
          <h4>Exercise No. 2 (10 minutes)</h4>
        </div>
        {isShowActivity ? (
          <div>
            <div>
              <h4>
                Identify whether the group of words is a dependent or
                independent clause. If it is a dependent clause, rewrite it by
                adding words or another clause to make it a complete sentence.
              </h4>
            </div>

            {questions.map((questionObj, index) => (
              <div key={index} className='mt-3'>
                <p>{`${index + 1}. ${questionObj.question}`}</p>
                <div>
                  {questionObj.choices.map((choice, ind) => (
                    <div key={ind} className='d-flex align-items-center gap-2'>
                      <input
                        type='radio'
                        name={questionObj.questionName}
                        value={choice.value}
                        checked={
                          activityTwoAnswers[questionObj.questionName] ===
                          choice.value
                        }
                        onChange={() =>
                          handleInputChange(
                            questionObj.questionName,
                            choice.value
                          )
                        }
                      />{' '}
                      {choice.label}
                    </div>
                  ))}
                  {activityTwoAnswers[questionObj.questionName] === 'A' && (
                    <div>
                      <p className='p-0 m-0'>{questionObj.rewriteLabel}</p>
                      <textarea
                        className='w-100'
                        value={
                          activityTwoAnswers[
                            `${questionObj.questionName}_rewrite`
                          ] || ''
                        }
                        onChange={(e) =>
                          handleInputChange(
                            `${questionObj.questionName}_rewrite`,
                            e.target.value
                          )
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <>
            <Row className='mb-3 mt-5' justify='left'>
              <h3>Activity answer was already submitted as final.</h3>
            </Row>
          </>
        )}
      </Modal>
    </>
  )
}
