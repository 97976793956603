/**
 * Title: DataPrivacyPDS.js
 * Description: This is a file that contains the components for the Data Privacy Statement of the Personal Data Sheet.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/DataPrivacyPDS.js
 **/

import { useState } from 'react'

import { Row, Card, Button } from 'antd'

export default function DataPrivacyPDS({ setIsProceedPDS }) {
  const [isAgreePDS, setIsAgreePDS] = useState(false)

  const handlePDSAgree = (e) => {
    try {
      setIsAgreePDS(e.target.checked)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Card className='data-privacy-pds m-5'>
      <Row className='mb-3'>
        <span className='data-privacy-title'>Data Privacy Statement</span>
      </Row>
      <Row className='mb-5'>
        <span className='data-privacy-text'>
          By filling out this Personal Data Sheet, you consent to the
          collection, storage, and processing of your personal data by Quezon
          City Government for employment-related purposes only. Your data will
          be treated confidentially and used solely for legitimate business
          activities. You have the right to access, modify, or delete your
          personal data held by Quezon City Government. If you have any
          concerns, please contact the HR Department.
        </span>
      </Row>

      <Row className='mb-5'>
        <input
          onChange={(e) => {
            handlePDSAgree(e)
          }}
          name='pdsAgree'
          id='pdsAgree'
          type='checkbox'
          className='me-2'
        />
        <label htmlFor='pdsAgree'>
          <i>I agree to the data privacy terms stated above</i>
        </label>
      </Row>

      <Row justify='end'>
        <Button onClick={() => setIsProceedPDS(true)} disabled={!isAgreePDS}>
          Proceed
        </Button>
      </Row>
    </Card>
  )
}
