import React from 'react'

import { Button } from 'antd'
import PropTypes from 'prop-types'

/**
 * NextPageButton component renders a button that navigates to the next page.
 * The button is only visible if the `isVisible` prop is true.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.isVisible - Determines if the button should be visible.
 * @returns {JSX.Element|null} The rendered button component or null if not visible.
 */
const NextPageButton = ({ isVisible, nextPage, employeeToBeEncoded, user }) => {
  if (!isVisible) return null

  return (
    <Button
      type='primary'
      onClick={() => {
        employeeToBeEncoded(user)
        nextPage()
      }}
      style={{ marginTop: 16 }}
    >
      Next Page
    </Button>
  )
}

export default NextPageButton

NextPageButton.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  nextPage: PropTypes.func.isRequired,
  employeeToBeEncoded: PropTypes.func.isRequired,
  user: PropTypes.object,
}
