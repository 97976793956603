import { API, graphqlOperation } from 'aws-amplify'
import { ActivityThreeAnswerByUserID } from '../api/queries'
import moment from 'moment'

export const isDoneAllActivityBWBatch2 = async (currentUser) => {
  try {
    const dataFetched = await API.graphql(
      graphqlOperation(ActivityThreeAnswerByUserID, { userID: currentUser.id })
    )
    const itemsFetched = dataFetched.data.ActivityThreeAnswerByUserID.items
      .filter((item) => {
        if (
          item.trainingAnnouncementID == 'cdb10950-01b5-428a-9959-0fc47237b528'
        ) {
          return item
        }
      })
      .sort((a, b) => {
        const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
        const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

        // Sort in descending order
        return timeSubmittedB.diff(timeSubmittedA)
      })

    if (itemsFetched.length) {
      if (itemsFetched[0].isSubmittedByUser === true) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } catch (err) {
    console.log(err)
  }
}

export function stripHTML(input) {
  const doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.body.textContent || ''
}

export const isValidURL = (string) => {
  try {
    new URL(string)
    return true
  } catch (_) {
    return false
  }
}

export function toRoman(num) {
  const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
  const roman = [
    'M',
    'CM',
    'D',
    'CD',
    'C',
    'XC',
    'L',
    'XL',
    'X',
    'IX',
    'V',
    'IV',
    'I',
  ]

  let result = ''

  for (let i = 0; i < decimal.length; i++) {
    while (decimal[i] <= num) {
      result += roman[i]
      num -= decimal[i]
    }
  }

  return result
}
