/**
 * Title: online-training-instruction-promtmodal.js
 * Description: This is a file that contains lms view course modal
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/online-training-instruction-promtmodal.js
 * History:
 * - 09/23/2024 - John Bazil Valdez
 * - Change the message display
 **/

import { Button, Modal } from 'antd'
import React from 'react'

export default function OnlineTrainingInstructionPrompt({
  isInstructionModalVisible,
  setIsInstructionModalVisible,
  title,
}) {
  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      title='NOTICE'
      width={1000}
      visible={isInstructionModalVisible}
      onCancel={() => setIsInstructionModalVisible(false)}
      footer={[
        <Button key='back1' onClick={() => setIsInstructionModalVisible(false)}>
          Ok
        </Button>,
      ]}
    >
      <p>
        Welcome to <strong>{title}!</strong>
      </p>

      <p>
        Please go through all of the video modules for instructions, training
        content, and other announcements.
        <br /> Thank you very much and enjoy this learning experience!
        {/* Few instructions before you proceed:
        <ul>
             <li>
            Each video module has an activity which will only appear after
            completely viewing a video module.
          </li>
          <li>
            Per activity, your progress will be saved if you have not clicked
            the submit button yet.
          </li>
          <li>
            Submitting your activity entry will redirect you to the next video
            module. There is no backtracking to the previous video/s so please
            note-take. You may make use of the transcript available per video
            module.
          </li>
          
        </ul> */}
      </p>
      <p>
        If you encounter any technical issues, please email your concern/s to{' '}
        <strong>LD.HRMD@quezoncity.gov.ph.</strong> Thank you!
      </p>
    </Modal>
  )
}
