/**
 * Title: endorsementLetter.js
 * Description: This is a file that contains the endorsement letter component
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/endorsementLetter.js
 **/

import {
  createStepThreeRegistration,
  deleteStepThreeRegistration,
} from '../../api/mutations'
import { listStepThreeRegistrations } from '../../api/queries'
import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  TimePicker,
  Row,
  Col,
} from 'antd'
import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  CaretLeftFilled,
  CaretRightFilled,
  BoldOutlined,
  ItalicOutlined,
} from '@ant-design/icons'
import { useEffect, useRef, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import HtmlParser from './html-parser'

/* Initializing EndorsementLetter Module Variable */
const EndorsementLetter = ({ setEndorsementLetterID, userData }) => {
  /* Initializing variables using useState() */
  const [openCreateModal, setOpenCreateModal] = useState(false)
  const [openSelectModal, setOpenSelectModal] = useState(false)
  const [openCreateModalPreview, setOpenCreateModalPreview] = useState(false)
  const [textDisplay, setTextDisplay] = useState('')
  const [endorsementList, setEndorsementList] = useState([])
  const [listIndex, setListIndex] = useState(0)

  const fetchEndorsementLetterList = async () => {
    const response = await API.graphql(
      graphqlOperation(listStepThreeRegistrations)
    )
    setEndorsementList(response.data.listStepThreeRegistrations.items)
  }

  useEffect(() => {
    fetchEndorsementLetterList()
  }, [])

  /* 
    Handle the save button in Create Endorsement letter modal
    - use createStepThreeRegistration API to save the endorsement letter in database
  */
  const handleSaveCreate = async () => {
    try {
      if (!textDisplay) {
        return notification['info']({
          message: 'info',
          description: 'Please enter an endorsement letter',
        })
      }

      const response = await API.graphql(
        graphqlOperation(createStepThreeRegistration, {
          input: {
            textDisplay: textDisplay,
            userID: userData.id,
          },
        })
      )
      setEndorsementLetterID(response.data.createStepThreeRegistration.id)
      notification['success']({
        message: 'Success',
        description: 'Endorsement letter created.',
      })

      setOpenCreateModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancelCreate = () => {
    setOpenCreateModal(false)
    setTextDisplay('')
  }

  const handleSaveSelect = (endorsementID) => {
    try {
      setEndorsementLetterID(endorsementID)
      notification['success']({
        message: 'Success',
        description: 'Endorsement letter selected successfully!',
      })

      setOpenSelectModal(false)
    } catch (error) {
      console.log(error)
    }
  }

  /* Change the question preview to next question */
  const handleNextQuestion = () => {
    const nextIndex =
      listIndex === endorsementList.length - 1 ? 0 : listIndex + 1
    setListIndex(nextIndex)
  }

  /* Change the question preview to previous question */
  const handlePrevQuestion = () => {
    const prevIndex =
      listIndex === 0 ? endorsementList.length - 1 : listIndex - 1
    setListIndex(prevIndex)
  }

  /* Deleting the assessment question from database */
  const handleConfirmDelete = async (e, endorsementID) => {
    e.preventDefault()
    try {
      const status = await API.graphql(
        graphqlOperation(deleteStepThreeRegistration, {
          input: {
            id: endorsementID,
          },
        })
      )
      if (status) {
        notification['success']({
          message: 'Success',
          description: 'Successfully deleted.',
        })
      }
      fetchEndorsementLetterList()
    } catch (error) {
      console.log(error)
    }
  }

  const handleEndorsementLetterChange = (newContent) => {
    setTextDisplay(newContent)
  }

  return (
    <>
      <div className='p-3'>
        <Row justify='center'>
          <span className='loginInput-label text-center d-block mb-4'>
            Training Registration "Endorsement Letter" Step
          </span>
        </Row>
        <Row justify='center'>
          <small>
            <em>
              (Add / Edit text to appear in the Endorsement Letter Step in the
              Training Registration)
            </em>
          </small>
        </Row>

        <div className='d-flex justify-content-around'>
          <Button onClick={() => setOpenCreateModal(true)} ghost type='primary'>
            Create text
          </Button>
          <Button onClick={() => setOpenSelectModal(true)} ghost type='primary'>
            Select text
          </Button>
        </div>

        {/* Creating Endorsement Modal */}
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='Create endorsement letter'
          visible={openCreateModal}
          footer={null}
          onCancel={() => setOpenCreateModal(false)}
          width={800}
        >
          <div className='d-flex flex-column gap-3'>
            <span className='loginInput-label'>
              Enter the endorsement letter:
            </span>
            <ReactQuill
              value={textDisplay}
              onChange={handleEndorsementLetterChange}
              // className={hasError.includes("answer") ? "border border-danger" : ""}
              id='target-participants'
              name='target-participants'
              style={{ height: 400 }}
            />
            <div className='ms-auto mt-5'>
              <Button
                className='me-3 bg-secondary text-white'
                onClick={handleCancelCreate}
                type='default'
              >
                Cancel
              </Button>
              <Button
                onClick={() => setOpenCreateModalPreview(true)}
                type='primary'
              >
                Save
              </Button>
              <Modal
                maskClosable={false}
                destroyOnClose={true}
                title='Create Endorsement Letter Preview'
                visible={openCreateModalPreview}
                footer={null}
                onCancel={() => setOpenCreateModalPreview(false)}
                width={800}
              >
                {endorsementList && endorsementList.length > 0 && (
                  <>
                    <p
                      className='text-center'
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      <HtmlParser
                        content={textDisplay?.replace(/\n/g, '<br>')}
                      />
                    </p>
                    <p className='text-center'>
                      You may edit your response to upload your endorsement
                      letter or send a hardcopy to the HRMD.
                    </p>
                    <label
                      htmlFor='endorsementLetter'
                      className='loginInput-label'
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      Upload Here
                    </label>
                    <Input disabled type='file' />
                    <div className='d-flex justify-content-end mt-3'>
                      <Button
                        className='me-3'
                        onClick={() => setOpenCreateModalPreview(false)}
                        type='default'
                      >
                        Cancel
                      </Button>
                      <Button onClick={handleSaveCreate} type='primary'>
                        Confirm
                      </Button>
                    </div>
                  </>
                )}
              </Modal>
            </div>
          </div>
        </Modal>

        {/* Selecting Endorsement Modal */}
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='Select endorsement letter'
          visible={openSelectModal}
          footer={null}
          onCancel={() => setOpenSelectModal(false)}
          width={800}
        >
          {endorsementList && endorsementList.length > 0 && (
            <div className='me-md-0'>
              <div className='carousel-inner'>
                <div className='col-10 offset-2'>
                  <>
                    <div className='d-flex justify-content-between col-md-10'>
                      <h3 className='mb-5'>Endorsement Letter Preview</h3>
                      <Popconfirm
                        placement='top'
                        title={'Are you sure to delete this Question?'}
                        onConfirm={(e) =>
                          handleConfirmDelete(e, endorsementList[listIndex].id)
                        }
                        okText='Yes'
                        cancelText='No'
                      >
                        <Button className='bg-danger text-white d-flex align-items-center me-5 me-md-0'>
                          <DeleteFilled />
                          <span className='d-md-block d-none'>
                            Delete This Question
                          </span>
                        </Button>
                      </Popconfirm>
                    </div>
                    <div className='col-10'>
                      <div className='text-center'>
                        <p className='text-center'>
                          <HtmlParser
                            content={endorsementList[
                              listIndex
                            ]?.textDisplay?.replace(/\n/g, '<br>')}
                          />
                        </p>
                      </div>
                      <p className={'text-center'}>
                        You may edit your response to upload your endorsement
                        letter or send a hardcopy to the HRMD.
                      </p>
                      <label
                        htmlFor='endorsementLetter'
                        className='loginInput-label'
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        Upload Here
                      </label>
                      <Input disabled type='file' />
                    </div>
                  </>
                  {/* } */}
                </div>
                <button
                  onClick={handleNextQuestion}
                  className='carousel-control-prev text-black display-1 d-flex justify-content-start'
                >
                  <CaretLeftFilled />
                </button>
                <button
                  onClick={handlePrevQuestion}
                  className='carousel-control-next text-black display-1 d-flex justify-content-end'
                >
                  <CaretRightFilled />
                </button>
              </div>
              <div className='d-flex justify-content-end mt-5'>
                <Button
                  onClick={() => setOpenSelectModal(false)}
                  className='me-3'
                  type='default'
                >
                  Cancel
                </Button>
                <Button
                  onClick={() =>
                    handleSaveSelect(endorsementList[listIndex].id)
                  }
                  type='primary'
                >
                  Save
                </Button>
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  )
}

export default EndorsementLetter
