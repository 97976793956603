import React, { useState, useEffect, Suspense, useRef } from 'react'

import {
  Button,
  Modal,
  Col,
  Row,
  Table,
  notification,
  Tooltip,
  Space,
  Input,
} from 'antd'
import { DownloadOutlined, SearchOutlined } from '@ant-design/icons'

import Highlighter from 'react-highlight-words'

import { API, graphqlOperation } from 'aws-amplify'
import {
  registrationByTAID,
  OnlineActivitiesAnswerByTrainingID,
  listOnlineActivityAnswers,
} from '../../api/queries'

import { numToString } from '../../utilities/numToString'

import moment from 'moment'
import Activity1 from './activity1'
import OnlineTrainingActivityViewAnswer from './online-training-activity-viewanswer'
import { isValidURL } from '../../utilities/utils'

export default function OnlineTrainingActivityDownload({
  viewCourse,
  activityNumber,
  activityQuestion,
  userData,
}) {
  const currentUser = userData

  const [openTableModal, setOpenTableModal] = useState(false)
  const [disableAfter, setDisableAfter] = useState(false)
  const [allRegistrants, setAllRegistrants] = useState([])
  const [allActivityAnswers, setAllActivityAnswers] = useState([])
  const [dataDownload, setDataDownload] = useState([])

  const [isTableLoading, setIsTableLoading] = useState(true)

  function formatDate(dateString) {
    const date = new Date(dateString)

    const year = date.getFullYear()
    const month = (date.getMonth() + 1).toString().padStart(2, '0')
    const day = date.getDate().toString().padStart(2, '0')
    const hour = date.getHours().toString().padStart(2, '0')
    const minute = date.getMinutes().toString().padStart(2, '0')
    const second = date.getSeconds().toString().padStart(2, '0')
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM'

    const formattedDate = `${year}-${month}-${day} ${hour}:${minute}:${second} ${ampm}`

    return formattedDate
  }

  const filterWithAnswers = (a, b) => {
    // Get all unique keys from the answers table (excluding the unwanted ones)
    const answerKeys = new Set()
    b.forEach((item) => {
      Object.keys(item).forEach((questionKey) => {
        if (
          questionKey !== 'updatedAt' &&
          questionKey !== 'owner' &&
          questionKey !== 'createdAt' &&
          questionKey !== 'id' &&
          questionKey !== 'answers' &&
          questionKey !== 'onlineTrainingAnnoucementID' &&
          questionKey !== 'ownedBy' &&
          questionKey !== 'onlineTraining'
        ) {
          answerKeys.add(questionKey)
        }
      })
    })

    const answerKeysArray = Array.from(answerKeys)

    const newB = b.map((item) => {
      const dynamicData = {}
      Object.keys(item).forEach((questionKey) => {
        if (
          questionKey === 'updatedAt' ||
          questionKey === 'owner' ||
          questionKey === 'createdAt' ||
          questionKey === 'id' ||
          questionKey === 'answers' ||
          questionKey === 'onlineTrainingAnnoucementID' ||
          questionKey === 'ownedBy' ||
          questionKey === 'onlineTraining'
        )
          return

        dynamicData[`${questionKey}`] = item[questionKey]
      })

      return {
        ...dynamicData,
      }
    })

    setIsTableLoading(false)

    const combinedArray = a.map((item) => {
      const filterItem = newB.find(
        (filter) => filter.userID === item.registeredUser.id
      )

      // Create a base object with all answer keys and default values
      const baseData = answerKeysArray.reduce((acc, key) => {
        acc[key] = null // or any default value you prefer
        return acc
      }, {})

      return filterItem
        ? { ...item, ...baseData, ...filterItem }
        : { ...item, ...baseData }
    })

    setDataDownload(combinedArray)
  }

  const getAllActivityAnswers = async (a) => {
    try {
      const result = await API.graphql(
        graphqlOperation(OnlineActivitiesAnswerByTrainingID, {
          onlineTrainingAnnouncementID: viewCourse.id,
          limit: 100000,
        })
      )

      const onlineActivitiesAnswer =
        result.data.OnlineActivitiesAnswerByTrainingID.items

      const filterFinalAnswers = onlineActivitiesAnswer.map((item) => {
        const parsedAnswers = JSON.parse(item.answers)

        // Safely access the specific activity answers
        const activityKey = `activity${
          activityNumber[activityNumber.length - 1]
        }`
        const activityAnswers = parsedAnswers[activityKey]

        // Check if activityAnswers exists and is an object
        if (activityAnswers && typeof activityAnswers === 'object') {
          // Creating an object to hold dynamic answers
          let dynamicAnswers = {}
          Object.keys(activityAnswers).forEach((questionKey, index) => {
            dynamicAnswers[`answer${numToString(index)}`] =
              activityAnswers[questionKey]
          })

          return {
            ...item,
            ...dynamicAnswers,
            timeStart: item.createdAt,
            timeSubmitted: item.timeSubmitted,
          }
        } else {
          // Handle the case where activityAnswers is undefined or null
          console.warn(
            `Activity answers for ${activityKey} are missing or invalid.`
          )
          return {
            ...item,
            timeStart: item.createdAt,
            timeSubmitted: item.timeSubmitted,
          }
        }
      })

      filterWithAnswers(a, filterFinalAnswers)
      setAllActivityAnswers(filterFinalAnswers)
    } catch (error) {
      console.error('Error fetching activity answers:', error)
    }
  }

  const getAllRegistrants = async () => {
    try {
      const checkLength = await API.graphql(
        graphqlOperation(registrationByTAID, {
          trainingAnnouncementID: viewCourse.id,
          limit: 100000,
        })
      )
      if (checkLength.data.registrationByTAID.items.length) {
        const withCount = checkLength.data.registrationByTAID.items
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .map((obj, index) => {
            return {
              ...obj,
              count: index + 1,
            }
          })

        if (withCount.length) {
          setAllRegistrants(
            withCount.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          )
          getAllActivityAnswers(
            withCount.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          )
        } else {
          setOpenTableModal(false)
        }
      } else {
        notification['info']({
          message: 'Info',
          description: 'No registrants for this event.',
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const columns = [
    {
      title: '',
      dataIndex: 'count',
      key: 'count',
      width: '5%',
      render: (text, record) => (
        <span>
          <b>{record.count}</b>
        </span>
      ),
    },
    {
      title: 'FULL NAME',
      dataIndex: 'fullName',
      key: 'name',
      width: '20%',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      render: (text, record) =>
        record.fullName ? (
          <span>
            <b className='text-capitalize'>{record.fullName.toLowerCase()}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'NICKNAME',
      dataIndex: 'nickname',
      key: 'nickname',
      width: '10%',
      render: (text, record) =>
        record.nickname ? (
          <span>
            <b className='text-capitalize'>{record.nickname.toLowerCase()}</b>
          </span>
        ) : (
          'no nickname'
        ),
    },
    {
      title: 'EMAIL ADDRESS',
      width: '15%',
      render: (text, record) =>
        record.emailAddress ? (
          <span color='geekblue'>
            <b>{`${record.emailAddress}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE',
      width: '15%',
      render: (text, record) =>
        record.dept_office_unit_taskForce ? (
          <span>
            <b>{`${record.dept_office_unit_taskForce}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'DIVISION / SECTION',
      width: '10%',
      render: (text, record) =>
        record.division_section ? (
          <span>
            <b>{`${record.division_section}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'DESIGNATION/POSITION',
      width: '10%',
      render: (text, record) =>
        record.designation_position ? (
          <span>
            <b>{`${record.designation_position}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: `Activity ${activityNumber[activityNumber.length - 1]} Answer`,
      width: '10%',
      render: (text, record) => {
        const foundAcct = allActivityAnswers.filter((iden) => {
          if (record.registeredUser.id === iden.userID) {
            return JSON.parse(iden.answers)
          }
        })

        return (
          <>
            {foundAcct.length ? (
              <span>
                <OnlineTrainingActivityViewAnswer
                  viewUserAnswer={foundAcct}
                  activityQuestion={JSON.parse(activityQuestion)}
                  activityNumber={activityNumber}
                />
              </span>
            ) : (
              'no answer'
            )}
          </>
        )
      },
    },
  ]

  const handleOpen = () => {
    getAllRegistrants()
    setOpenTableModal(true)
  }

  const handleDisable = () => {
    if (allRegistrants.length) {
      if (disableAfter == false) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  const handleDownload = async () => {
    try {
      if (dataDownload.length) {
        const newData = dataDownload.map((item) => {
          let dynamicData = {}
          Object.keys(item).forEach((questionKey, index) => {
            if (
              questionKey === 'first_name' ||
              questionKey === 'last_name' ||
              questionKey === 'middle_initial' ||
              questionKey === 'createdAt' ||
              questionKey === 'isRegistered' ||
              questionKey === 'isSubmitEndorsementLetter' ||
              questionKey === 'isAttended' ||
              questionKey === 'isPostEvalDone' ||
              questionKey === 'nickname' ||
              questionKey === 'expected_outcomes' ||
              questionKey === 'isCourseOutputDone' ||
              questionKey === 'additionalRemarks' ||
              questionKey === 'cscProof' ||
              questionKey === 'isAdminRegistration' ||
              questionKey === 'updatedAt' ||
              questionKey === 'registeredUser' ||
              questionKey === 'training' ||
              questionKey === '__typename' ||
              questionKey === 'count' ||
              questionKey === 'onlineTrainingAnnouncementID' ||
              questionKey === 'timeStart' ||
              questionKey === 'isAgreeDataPrivacyConsent' ||
              questionKey === 'fullName' ||
              questionKey === 'emailAddress' ||
              questionKey === 'gender' ||
              questionKey === 'dept_office_unit_taskForce' ||
              questionKey === 'division_section' ||
              questionKey === 'designation_position' ||
              questionKey === 'endorsementLetter' ||
              questionKey === 'expectedOutcomes' ||
              questionKey === 'totalScore' ||
              questionKey === 'nickname' ||
              questionKey === 'timeStart' ||
              questionKey === 'timeSubmitted' ||
              questionKey === 'questionnaire' ||
              questionKey === 'onlineTraining'
            )
              return

            let value = item[questionKey] || ''
            if (isValidURL(value)) {
              value = encodeURI(value)

              value = value.replace(/#/g, '%23')
            }

            dynamicData[`${questionKey}`] = value
          })

          const {
            id,
            isAgreeDataPrivacyConsent,
            fullName,
            emailAddress,
            gender,
            contactNumber,
            dept_office_unit_taskForce,
            division_section,
            designation_position,
            endorsementLetter,
            nickname,
            expectedOutcomes,
            totalScore,
            timeStart,
            timeSubmitted,
          } = item

          return {
            'Data Privacy Consent': isAgreeDataPrivacyConsent || 'No',
            'FULL NAME': fullName || '',
            NICKNAME: nickname || '',
            'CONTACT NUMBER': contactNumber || '',
            'DEPARTMENT / OFFICE/ UNIT/ TASK FORCE':
              dept_office_unit_taskForce || '',
            'DESIGNATION / POSITION': designation_position || '',
            'DIVISION / SECTION': division_section || '',
            'EMAIL ADDRESS': emailAddress || '',
            SEX: gender || '',
            'Endorsement Letter': endorsementLetter || '',
            ...dynamicData,
            /*   'Total Score': totalScore, */
            'Time Started': timeStart || '',
            'Time Submitted': timeSubmitted || '',
          }
        })

        const csvHeader = Object.keys(newData[0])
        const csvValue = newData.map((item) => Object.values(item))
        const csvRows = csvValue.map((row) =>
          row
            .map((value) => `"${String(value).replace(/\n/g, '\r')}"`)
            .join(';')
        )
        const csvData = csvHeader.join(';') + '\n' + csvRows.join('\n')
        const afterDownload = function () {
          notification['success']({
            message: 'Success',
            description: 'Excel file downloaded.',
          })

          setTimeout(() => {
            setOpenTableModal(false)
            setDisableAfter(false)
            setAllRegistrants([])
            setAllActivityAnswers([])
            setDataDownload([])
            setIsTableLoading(true)
          }, 3000)
        }

        const downloadCSV = (csvData, fileName) => {
          const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
            csvData
          )}`
          const link = document.createElement('a')
          link.setAttribute('href', csvContent)
          link.setAttribute('download', fileName)
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          afterDownload()
        }

        downloadCSV(
          csvData,
          `${
            viewCourse.title ? viewCourse.title : ''
          } - ${activityNumber} Answers`
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleCancelModal = () => {
    setOpenTableModal(false)
    setDisableAfter(false)
    setIsTableLoading(true)
    setAllRegistrants([])
    setAllActivityAnswers([])
    setDataDownload([])
  }

  return (
    <>
      <Button onClick={handleOpen}>
        Download activity {activityNumber[activityNumber.length - 1]} answers
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={'Registrants'}
        centered
        visible={openTableModal}
        onOk={() => setOpenTableModal(false)}
        onCancel={handleCancelModal}
        width={'100%'}
        footer={null}
      >
        <Row>
          <Table
            loading={isTableLoading}
            bordered
            rowKey='id'
            // dataSource={selectArray()}
            dataSource={allRegistrants}
            columns={columns}
            showSizeChanger={false}
            pagination={{ pageSize: 50 }}
            className='w-100 table-responsive'
          />
        </Row>
        <Row>
          {allRegistrants.length ? (
            <>
              <Button
                disabled={handleDisable()}
                onClick={handleDownload}
                type='primary'
                icon={<DownloadOutlined />}
                size={'large'}
              >
                Download
              </Button>
            </>
          ) : null}
        </Row>
      </Modal>
    </>
  )
}

/* const getAllActivityOneAnswers = async (a) => {
    try {
      let items = []
      let nextToken = null
      const limit = 1000

      do {
        const variables = { limit, nextToken }
        const result = await API.graphql(
          graphqlOperation(listActivityOneAnswers, variables)
        )
        items.push(...result.data.listActivityOneAnswers.items)
        nextToken = result.data.listActivityOneAnswers.nextToken
      } while (nextToken)

      const filterByTrainingAnnouncementID = items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            'c822d184-b65a-4a25-8079-89e895685546'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted)
          const timeSubmittedB = moment(b.timeSubmitted)

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      const filterFinalAnswers = filterByTrainingAnnouncementID
        .filter((item) => {
          if (item.isSubmittedByUser == true) {
            return {
              ...item,
            }
          }
        })
        .map((item) => {
          return {
            ...item,
            answerOne: JSON.parse(item.answer).Question1,
            answerTwo: JSON.parse(item.answer).Question2,
            answerThree: JSON.parse(item.answer).Question3,
            answerFour: JSON.parse(item.answer).Question4,
            answerFive: JSON.parse(item.answer).Question5,
            answerSix: JSON.parse(item.answer).Question6,
            answerSeven: JSON.parse(item.answer).Question7,
            answerEight: JSON.parse(item.answer).Question8,
            answerNine: JSON.parse(item.answer).Question9,
            answerTen: JSON.parse(item.answer).Question10,
            answerEleven: JSON.parse(item.answer).Question11,
            answerTwelve: JSON.parse(item.answer).Question12,
            answerThirteen: JSON.parse(item.answer).Question13,
            answerFourteen: JSON.parse(item.answer).Question14,
            answerFifteen: JSON.parse(item.answer).Question15,
            timeStart: item.createdAt,
            timeSubmitted: item.timeSubmitted,
          }
        })
      filterWithAnswers(a, filterFinalAnswers)
      setAllActivityAnswers(filterFinalAnswers)
    } catch (err) {
      console.log(err)
    }
  } */
