/**
 * Title: MainForm.js
 * Description: This is a file that contains the components for the Personal Data Sheet form.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/MainForm.js
 **/

/*
 *Changes made:
 * - 2024.14.08  | Raymart Mojado | convert the National Capital Region into Metro Manila when selected on the list of permanent address
 * and residential address
 * - 2024.14.08  | Raymart Mojado | disabled the province , municipality , barangay, zip code if the checkbox same as residential address
 * is selected
 * - 2024.22.08 | Harry Lagunsad | fixed issue when selecting zip code that are shared with other places or barangays that after selection, a
 * different label will show but correct value is being put in the main zip code field
 * - 2024.02.09 | Raymart Mojado | For #22 Spouse, if marked as N/A .disable the input about the spouse and make it N/A,
 * - 2024.02.09 | Raymart Mojado | Automatic spouse details should be populated based on civil status (e.g., N/A if single)
 * - 2024.06.09 | Harry Lagunsad | Implement an auto save feature for the form, where the progress is saved every 5 seconds in the local storage
 * - 2024.06.09 | Harry Lagunsad | Added a function to load and decrypt saved data when the component mounts
 * - 2024.06.09 | Harry Lagunsad | Added a modal to appear that will advise the user about auto-save form feature but not to rely on it totally
     and use the save as draft or save as final button to save the form permanently
 * - 2024.06.09 | Harry Lagunsad | Fix sonarlint issues
 * - 2024.06.09 | Harry Lagunsad | Add prop types
 * - 2024.06.09 | Raymart Mojado | Add Upload PDS
 * - 2024.09.09 | Harry Lagunsad | Sort the list of barangays via labels before display.
 * - 2024.18.09 | Raymart Mojado | Change the notification error from  Please answer character reference fields to This is a required field. Please fill it out before proceeding.
 * - 2024.25.09 | Raymart Mojado | add validation on each field if its only input white-space show error / fix some fields that validations doesnt work
 * - 2024.10.10 | Harry Lagunsad | fix incorrect suffix value showing upon first time PDS form load
 * - 2024.10.10 | Harry Lagunsad | Enhanced handling of the Save as draft and Save as final buttons to avoid spamming.
 * - 2024.11.10 | Harry Lagunsad | Fixed function that handles control of number of data row for character reference from min of
 *   1 to max of 3 persons only
 */
import { useCallback, useEffect, useRef, useState } from 'react'

import {
  BackTop,
  Button,
  Card,
  Col,
  List,
  Modal,
  Row,
  Select,
  Typography,
  notification,
} from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import CryptoJS from 'crypto-js'
import moment from 'moment'
import PropTypes from 'prop-types'

import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'
import {
  GetSecretValueCommand,
  SecretsManagerClient,
} from '@aws-sdk/client-secrets-manager'
import { SESClient, SendEmailCommand } from '@aws-sdk/client-ses'
import { Form } from '@formio/react'

import { createPersonalDataSheet } from '../../api/mutations'
import { UserbyHomeDepartment, registrationByUserID } from '../../api/queries'
import PDSGuide from './PDSGuide'
import PDSVideo from './PDSVideo'
import UploadPds from './UploadPds'
import './pds.css'
import placeDataArray from './zipcodePH'

// Encryption key (in a real app, this should be securely stored)

const { Option } = Select
const { Text, Title } = Typography

export default function MainForm({ userDetails, afterSubmit, isProceedPDS }) {
  const [holdData, setHoldData] = useState({})
  const [dataReview, setDataReview] = useState({})
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [uploadedSignature, setUploadedSignature] = useState(null)
  const [uploadedThumbmark, setUploadedThumbmark] = useState(null)

  const [isModalOpenThumbmark, setIsModalOpenThumbmark] = useState(false)
  const [isModalOpenESign, setIsModalOpenESign] = useState(false)
  const [isModalOpenPassportPhoto, setIsModalOpenPassportPhoto] =
    useState(false)
  const [uploadedPassportPhoto, setUploadedPassportPhoto] = useState(null)
  const [removeAsterisk, setRemoveAsterisk] = useState(null)

  const [myTrainings, setMyTrainings] = useState([])
  const [isFinishedScan, setIsFinishedScan] = useState(false)

  const [isModalOpenProvince, setIsModalOpenProvince] = useState(false)
  const [regionGroupList, setRegionGroupList] = useState([])
  const [municipalityList, setMunicipalityList] = useState([])
  const [provinceData, setProvinceData] = useState({})
  const [isModalOpenCity, setIsModalOpenCity] = useState(false)
  const [allAreasData, setAllAreasData] = useState({})
  const [selectedCityData, setSelectedCityData] = useState({})
  const [isModalOpenBrgy, setIsModalOpenBrgy] = useState(false)
  const [barangayList, setBarangayList] = useState([])

  const [isProvincePerm, setIsProvincePerm] = useState(false)
  const [isBarangayPerm, setIsBarangayPerm] = useState(false)
  const [isCityPerm, setIsCityPerm] = useState(false)
  const [municipalityListPerm, setMunicipalityListPerm] = useState([])
  const [selectedCityDataPerm, setSelectedCityDataPerm] = useState({})
  const [allAreasDataPerm, setAllAreasDataPerm] = useState({})
  const [barangayListPerm, setBarangayListPerm] = useState([])
  const [provinceDataPerm, setProvinceDataPerm] = useState({})
  const [isModalOpenZipcode, setIsModalOpenZipcode] = useState(false)
  const [isZipcodePerm, setIsZipcodePerm] = useState(false)

  const [isError, setIsError] = useState(false)

  const [isLoaded, setIsLoaded] = useState(false)
  const [isOpenPDSCacheMessage, setIsOpenPDSCacheMessage] = useState(false)

  const [isDisabledAfterSave, setIsDisabledAfterSave] = useState(false)
  const [doneRender, setDoneRender] = useState(false)
  const fileInputRefThumb = useRef(null)
  const fileInputRefESign = useRef(null)
  const fileInputRefPassportPhoto = useRef(null)

  const placeData = placeDataArray()

  const fieldCheckerFunction = (obj, key) => {
    try {
      let value = obj[key]
      if (
        typeof value === 'undefined' ||
        value === null ||
        (typeof value === 'string' && value.trim() === '')
      ) {
        return {
          allRequiredValuesPresent: false,
          failedKey: key,
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getEncryptionKey = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken
      const secret_name = 'hrm-2023@secrets'
      const client = new SecretsManagerClient({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const responseSecret = await client.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )
      const foundSecret = JSON.parse(responseSecret.SecretString)[
        'ENCRYPTION_KEY_PDS_CACHE'
      ]

      return foundSecret
    } catch (err) {
      console.log(err)
    }
  }

  const loadPDSCache = useCallback(async () => {
    // Load and decrypt saved data when the component mounts
    const encryptedData = localStorage.getItem('encryptedFormData')

    if (!encryptedData) {
      console.warn('No encrypted data found in localStorage.')
      setIsLoaded(true)
      return
    }

    try {
      const encryptionKey = await getEncryptionKey()
      const decryptedData = CryptoJS.AES.decrypt(
        encryptedData,
        encryptionKey
      ).toString(CryptoJS.enc.Utf8)

      if (decryptedData) {
        setHoldData({
          ...JSON.parse(decryptedData),
          dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        })
        setDoneRender(true)
      } else {
        console.warn('Decrypted data is empty.')
      }
    } catch (error) {
      console.error('Failed to decrypt data:', error)
    } finally {
      setIsLoaded(true)
    }
  }, [])

  useEffect(() => {
    if (!isLoaded) return

    // Define an async function for auto-save
    const autoSave = async () => {
      try {
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(holdData),
          await getEncryptionKey()
        ).toString()
        localStorage.setItem('encryptedFormData', encryptedData)
      } catch (err) {
        console.log(err)
      }
    }

    // Set up auto-save interval
    const autoSaveInterval = setInterval(autoSave, 5000) // Auto-save every 5 seconds

    // Clean up interval on component unmount
    return () => clearInterval(autoSaveInterval)
  }, [holdData, isLoaded])

  useEffect(() => {
    fetch('https://www.sparksoft-demo.com/formio/pdsformmobilepage1', {
      method: 'HEAD',
      mode: 'cors',
    })
      .then((response) => {
        if (response.status === 503) {
          console.log('Server responded with error:', response.status)
          setIsError(true)
        }
      })
      .catch((error) => {
        console.log('Server is down or network request failed:', error)
        setIsError(true)
      })
  }, [])

  const getAllMyTrainings = async () => {
    try {
      let nextToken = null
      let allMyTrainings = []

      do {
        const { data } = await API.graphql(
          graphqlOperation(registrationByUserID, {
            userID: userDetails.id,
            nextToken,
          })
        )
        allMyTrainings = [...allMyTrainings, ...data.registrationByUserID.items]
        nextToken = data.registrationByUserID.nextToken
      } while (nextToken)

      return allMyTrainings.filter(
        (item) =>
          (!item?.training?.isPassPreAssessmentRequired ||
            item?.isPreAssessmentPassed) &&
          item?.isSubmitEndorsementLetter &&
          item?.isPostEvalDone &&
          item?.isAttended &&
          item?.isCourseOutputDone
      )
    } catch (err) {
      console.error(err)
      return []
    }
  }

  function transformGeoRegionData(geoRegionData) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return []
    }

    const dataArray = Object.values(geoRegionData)
    const regions = {}
    const allAreas = {} // This will store both provinces and regions without provinces

    dataArray.forEach((item) => {
      if (!item || typeof item !== 'object') return

      if (item.geographic_level === 'Reg') {
        regions[item.code] = {
          label: item.name,
          value: item.code,
          options: [],
        }
        // Add region to allAreas
        allAreas[item.code] = {
          name: item.name,
          code: item.code,
          geographic_level: 'Reg',
        }
      } else if (item.geographic_level === 'Prov') {
        const province = {
          ...item,
          label: item.name,
          value: item.code,
          regionCode: item.code.substring(0, 3) + '0000000',
        }
        allAreas[item.code] = province

        const regionEntry = regions[province.regionCode]
        if (regionEntry) {
          regionEntry.options.push(province)
        }
      }
    })

    const transformedRegions = Object.values(regions).map((region) => {
      if (region.options.length === 0) {
        // If a region has no provinces, return it as its own option
        return {
          label: region.label,
          value: region.value,
        }
      }
      return region
    })

    return {
      regionGroupList: transformedRegions,
      allAreas: allAreas,
    }
  }

  const loadAndTransformData = async () => {
    let combinedRegionGroupList = []
    let allAreasData = {}
    for (let i = 1; i <= 17; i++) {
      try {
        const geoRegionData = await import(`./../../pgsc/geo-reg-${i}.json`)
        const { regionGroupList, allAreas } = transformGeoRegionData(
          geoRegionData.default
        )
        combinedRegionGroupList =
          combinedRegionGroupList.concat(regionGroupList)
        Object.assign(allAreasData, allAreas)
      } catch (error) {
        console.error(`Error loading data for region ${i}:`, error)
      }
    }

    combinedRegionGroupList.forEach((region) => {
      if (region.label === 'Metro Manila') {
        region.label = 'National Capital Region (NCR)'
      }
    })

    setRegionGroupList(combinedRegionGroupList)
    setProvinceData(allAreasData) // This now includes both provinces and regions
    setProvinceDataPerm(allAreasData) // This now includes both provinces and regions
  }

  function transformMunicipalityData(geoRegionData, selectedProvinceCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { municipalityGroupList: [], allAreas: {} }
    }

    const municipalityList = []
    const allAreas = {}

    Object.entries(geoRegionData).forEach(([key, item]) => {
      if (!item || typeof item !== 'object') return

      if (
        (item.geographic_level === 'Mun' || item.geographic_level === 'City') &&
        item.code.startsWith(selectedProvinceCode.substring(0, 5))
      ) {
        const municipalityItem = {
          label: item.name,
          value: item.code,
          ...item,
        }
        municipalityList.push(municipalityItem)
        allAreas[item.code] = municipalityItem
      }
    })

    return {
      municipalityGroupList: municipalityList,
      allAreas: allAreas,
    }
  }

  const loadAndTransformMunicipalityData = async (provinceCode) => {
    let municipalityList = []
    let allAreasData = {}

    try {
      const regionNumber = provinceCode.substring(0, 2)
      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )

      const { municipalityGroupList, allAreas } = transformMunicipalityData(
        geoRegionData.default,
        provinceCode
      )

      municipalityList = municipalityGroupList
      allAreasData = allAreas
    } catch (error) {
      console.error(`Error loading data for province ${provinceCode}:`, error)
    }

    if (isProvincePerm) {
      setMunicipalityListPerm(municipalityList)
      setSelectedCityDataPerm(allAreasData)
    } else {
      setMunicipalityList(municipalityList)
      setSelectedCityData(allAreasData)
    }
  }

  const loadAndTransformCityData = async (regionCode) => {
    let cityList = []
    let allAreasData = {}

    try {
      const regionNumber = regionCode.substring(0, 2)

      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )

      const { cityGroupList, allAreas } = transformCityData(
        geoRegionData.default,
        regionCode
      )

      cityList = cityGroupList
      allAreasData = allAreas
    } catch (error) {
      console.error(`Error loading data for region ${regionCode}:`, error)
    }

    if (isProvincePerm) {
      setMunicipalityListPerm(cityList)
      setSelectedCityDataPerm(allAreasData)
    } else {
      setMunicipalityList(cityList)
      setSelectedCityData(allAreasData)
    }
  }

  function transformCityData(geoRegionData, selectedRegionCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { cityGroupList: [], allAreas: {} }
    }

    const cityList = []
    const allAreas = {}

    Object.entries(geoRegionData).forEach(([key, item]) => {
      if (!item || typeof item !== 'object') return

      if (
        (item.geographic_level === 'City' || item.geographic_level === 'Mun') &&
        item.code.startsWith(selectedRegionCode.substring(0, 2))
      ) {
        const cityItem = {
          label: item.name,
          value: item.code,
          ...item,
        }
        cityList.push(cityItem)
        allAreas[item.code] = cityItem
      }
    })

    return {
      cityGroupList: cityList,
      allAreas: allAreas,
    }
  }

  function transformBarangayData(geoRegionData, selectedMunicipalityCode) {
    if (!geoRegionData || typeof geoRegionData !== 'object') {
      console.error('Invalid geoRegionData:', geoRegionData)
      return { barangayGroupList: [], allAreas: {} }
    }
    const barangayList = []
    const allAreas = {}
    Object.entries(geoRegionData).forEach(([key, item]) => {
      if (!item || typeof item !== 'object') return
      if (
        item.geographic_level === 'Bgy' &&
        item.code.startsWith(selectedMunicipalityCode.substring(0, 7))
      ) {
        const barangayItem = {
          label: item.name,
          value: item.code,
          ...item,
        }
        barangayList.push(barangayItem)
        allAreas[item.code] = barangayItem
      }
    })
    return {
      barangayGroupList: barangayList,
      allAreas: allAreas,
    }
  }

  const loadAndTransformBarangayData = async (municipalityCode) => {
    let brgyList = []
    let brgyAreasData = {}
    try {
      const regionNumber = municipalityCode.substring(0, 2)
      const geoRegionData = await import(
        `./../../pgsc/geo-reg-${parseInt(regionNumber, 10)}.json`
      )
      const { barangayGroupList, allAreas } = transformBarangayData(
        geoRegionData.default,
        municipalityCode
      )
      brgyList = barangayGroupList
      brgyAreasData = allAreas
    } catch (error) {
      console.error(
        `Error loading data for municipality ${municipalityCode}:`,
        error
      )
    }

    const sortedArray = brgyList.sort((a, b) => a.label.localeCompare(b.label))

    if (isCityPerm) {
      setBarangayListPerm(sortedArray)
      setAllAreasDataPerm(brgyAreasData)
    } else {
      setBarangayList(sortedArray)
      setAllAreasData(brgyAreasData)
    }
  }

  useEffect(async () => {
    loadAndTransformData()

    const fetchTrainings = async () => {
      try {
        const foundTrainings = await getAllMyTrainings()
        if (foundTrainings.length) {
          setMyTrainings([...foundTrainings])
        }
      } catch (error) {
        console.log(error)
      } finally {
        setIsFinishedScan(true)
      }
    }

    fetchTrainings()
  }, [])

  useEffect(() => {
    if (doneRender) {
      const permanentHouseBlockLotNoRequiredElem = document.querySelector(
        'input[name="data[permanentHouseBlockLotNoRequired]"]'
      )

      const permanentStreetRequiredElem = document.querySelector(
        'input[name="data[permanentStreetRequired]"]'
      )
      const permanentSubdivisionVillageElem = document.querySelector(
        'input[name="data[permanentSubdivisionVillageRequired]"]'
      )
      const permanentBarangayRequiredElem = document.querySelector(
        'input[name="data[permanentBarangayRequired]"]'
      )
      const permanentCityMunicipalityRequiredElem = document.querySelector(
        'input[name="data[permanentCityMunicipalityRequired]"]'
      )
      const permanentProvinceRequiredElem = document.querySelector(
        'input[name="data[permanentProvinceRequired]"]'
      )
      const chooseProvinceButtonPermElem = document.querySelector(
        'button[name="data[chooseProvinceButtonPerm]"]'
      )
      const chooseCityButtonPermElem = document.querySelector(
        'button[name="data[chooseCityButtonPerm]"]'
      )
      const chooseBarangayButtonPermElem = document.querySelector(
        'button[name="data[chooseBarangayPerm]"]'
      )

      const chooseZipCodeButtonPermElem = document.querySelector(
        'button[name="data[choosezipcodePerm]"]'
      )

      const permanentZipcodeElem = document.querySelector(
        'input[name="data[permanentZipcodeNaAllowed]"]'
      )

      const firstCSERatingElem = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceRating]"]'
      )
      const firstCSEPOE = document.querySelector(
        'input[name*="data[well4Well7DataGrid][0][civilServiceExamDateIsNA]"]'
      )

      const firstCSEPlace = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceExamPlace]"]'
      )

      const firstOtherEligibility = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceAndOthersLicenseOthersEligibility]"]'
      )

      const firstCSELicense = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceLicenseNo]"]'
      )

      const firstCSEDOV = document.querySelector(
        'input[name*="data[well4Well7DataGrid][0][civilServiceLicenseValidityDateIsNA]"]'
      )

      const firstCSEExamDateInput = document.getElementsByClassName(
        'formio-component-civilServiceExamDate'
      )
      const firstCSELVD = document.getElementsByClassName(
        'formio-component-civilServiceLicenseValidityDate'
      )

      const firstCSEAddRowButton = document.querySelector(
        'button[ref="datagrid-well4Well7DataGrid-addRow"]'
      )

      const firstCSERemoveRowButton = document.querySelector(
        'button[ref="datagrid-well4Well7DataGrid-removeRow"]'
      )

      const spouseSurname = document.querySelector(
        'input[name="data[spouseSurnameNaAllowed]"]'
      )

      const spouseFirstName = document.querySelector(
        'input[name="data[spouseFirstnameNaAllowed]"]'
      )

      const spouseMiddleName = document.querySelector(
        'input[name="data[spouseMiddlenameNaAllowed]"]'
      )

      const spouseOccupation = document.querySelector(
        'input[name="data[spouseOccupationNaAllowed]"]'
      )

      const spouseEmployerName = document.querySelector(
        'input[name="data[spouseEmployerNameNaAllowed]"]'
      )

      const spouseEmployerAddress = document.querySelector(
        'input[name="data[spouseEmployerAddressNaAllowed]"]'
      )

      const spouseEmployerTelephoneno = document.querySelector(
        'input[name="data[spouseEmployerTelephonenoNaAllowed]"]'
      )

      const markSpouseAsNA = document.querySelector(
        'input[name="data[markSpouseAsNA]"]'
      )

      const spouseSuffixparentElement = document.querySelector(
        '.formio-component-spouseSuffixNaAllowed'
      )
      const dropdownList = spouseSuffixparentElement.querySelectorAll(
        '.choices__list.choices__list--dropdown'
      )
      const suffixDropDown = dropdownList[0]
      const allChoices = spouseSuffixparentElement.querySelectorAll(
        '.choices__item.choices__placeholder.choices__item--selectable'
      )
      const spouseSuffixSelect = allChoices[0]

      const timeoutForNA = setTimeout(() => {
        if (holdData.sameAsResidentialAddressBox === true) {
          chooseProvinceButtonPermElem.style.display = 'none'
          chooseProvinceButtonPermElem.style.display = 'none'
          chooseCityButtonPermElem.style.display = 'none'
          chooseBarangayButtonPermElem.style.display = 'none'
          chooseZipCodeButtonPermElem.style.display = 'none'
          permanentHouseBlockLotNoRequiredElem.disabled = true
          permanentStreetRequiredElem.disabled = true
          permanentSubdivisionVillageElem.disabled = true
          permanentBarangayRequiredElem.disabled = true
          permanentCityMunicipalityRequiredElem.disabled = true
          permanentProvinceRequiredElem.disabled = true
          permanentZipcodeElem.disabled = true
          spouseSurname.disabled = true
        }
        if (holdData.markSpouseAsNA === true) {
          spouseSurname.disabled = true
          spouseFirstName.disabled = true
          spouseMiddleName.disabled = true
          spouseOccupation.disabled = true
          spouseEmployerName.disabled = true
          spouseEmployerAddress.disabled = true
          spouseEmployerTelephoneno.disabled = true
          spouseSuffixSelect.disabled = true
          spouseSuffixSelect.style.display = 'none'
          spouseSuffixparentElement.style.display = 'none'
          spouseSuffixparentElement.style.visibility = 'hidden'
        }
      }, 500)
      return () => clearTimeout(timeoutForNA)
    }
  }, [doneRender])

  /**
   *@function handlePrefill
   *@description Prefills the form with the user's details.
   *@param {Object} none No parameter needed.
   *@returns {Object} none No return value.
   */
  const handlePrefill = () => {
    try {
      const encryptedData = localStorage.getItem('encryptedFormData')

      if (encryptedData) {
        loadPDSCache()
      } else {
        setIsLoaded(true)
        const prefillData = {
          ...holdData,
          surnameRequired: userDetails.lname.toUpperCase(),
          firstnameRequired: userDetails.name.toUpperCase(),
          middlenameRequired: userDetails.mname
            ? userDetails.mname.toUpperCase()
            : '',
          suffixNaAllowed: userDetails.suffix
            ? userDetails.suffix.toUpperCase()
            : '',
          sexRequired: userDetails.gender.toUpperCase(),
          mobileno: userDetails.contact.replace('+63', '0'),
          emailAddress: userDetails.username.toUpperCase(),
          dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        }

        if (myTrainings.length) {
          const well4Well11DataGrid = []
          for (const item of myTrainings) {
            // Sample array of dates
            const arrayOfDates = item.training.arrayOfDates

            function getLastDate(arrayOfDates) {
              if (arrayOfDates) {
                const datesArray = JSON.parse(arrayOfDates)

                if (datesArray && datesArray.length > 1) {
                  // Convert dates to moment objects
                  const momentDates = datesArray.map((date) => moment(date))
                  const latestDate = moment.max(momentDates)
                  return latestDate.format('YYYY-MM-DD')
                }
              }
            }

            const trainingDetails = {
              ldTrainingTitle: item.training.title.toUpperCase(),
              ldTrainingDateFrom: item.training.date,
              ldTrainingDateIsNA: 'selectDates',
            }

            if (arrayOfDates) {
              const datesArray = JSON.parse(arrayOfDates)
              if (datesArray && datesArray.length > 1) {
                trainingDetails.ldTrainingDateTo = getLastDate(arrayOfDates)
              }
            }

            well4Well11DataGrid.push(trainingDetails)
          }
          prefillData.well4Well11DataGrid = well4Well11DataGrid
        }

        setHoldData({
          ...prefillData,
          dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@description Sets the component's state to open a modal and updates the data to be displayed in the modal.
   *@function
   *@name showModal
   *@param {Object} e - An object containing the data and form properties to be displayed in the modal.
   *@param {Object} e.data - The data to be displayed in the modal.
   *@param {Object} e.form - The form to be displayed in the modal.
   *@return {void}
   **/
  const showModal = (e) => {
    setDataReview({
      data: {
        ...e.data,
        dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      },
      form: e.form,
    })
    setIsModalOpen(true)
  }

  /**
   *@function closeModal
   *@description Closes the modal.
   *@param {Object} none No parameter needed.
   *@returns {Object} none No return value.
   */
  const closeModal = () => {
    try {
      setIsModalOpen(false)
      afterSubmit('SubmittedPDSForms')
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function forUpload
   *@description Uploads the files to the S3 bucket.
   *@param {Object} none No parameter needed.
   *@returns {Object} An object containing the URLs of the uploaded files.
   */
  const forUpload = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })

      let url, urlThumb, urlPassport

      if (uploadedSignature) {
        const params = {
          Bucket: 's3etnahris133956-dev',
          Key: `${userDetails.id}/${moment().valueOf()}-${
            uploadedSignature.name
          }`,
          Body: uploadedSignature,
          ACL: 'public-read',
        }

        const command = new PutObjectCommand(params)
        await s3.send(command)
        url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`
      }

      if (uploadedThumbmark) {
        const paramsThumb = {
          Bucket: 's3etnahris133956-dev',
          Key: `${userDetails.id}/${moment().valueOf()}-${
            uploadedThumbmark.name
          }`,
          Body: uploadedThumbmark,
        }

        const commandThumb = new PutObjectCommand(paramsThumb)
        await s3.send(commandThumb)
        urlThumb = `https://${paramsThumb.Bucket}.s3.ap-southeast-1.amazonaws.com/${paramsThumb.Key}`
      }

      if (uploadedPassportPhoto) {
        const paramsPassport = {
          Bucket: 's3etnahris133956-dev',
          Key: `${userDetails.id}/${moment().valueOf()}-${
            uploadedPassportPhoto.name
          }`,
          Body: uploadedPassportPhoto,
        }

        const commandPassport = new PutObjectCommand(paramsPassport)
        await s3.send(commandPassport)
        urlPassport = `https://${paramsPassport.Bucket}.s3.ap-southeast-1.amazonaws.com/${paramsPassport.Key}`
      }

      return { url, urlThumb, urlPassport }
    } catch (err) {
      console.log(err)
    }
  }

  const handleExportData = async (data) => {
    try {
      setHoldData({
        ...data.data,
        dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      })
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function triggerSave
   *@description Saves the data to the database.
   *@param {Object} url - The URL of the uploaded e-Signature.
   *@param {Object} urlThumb - The URL of the uploaded thumbmark.
   *@param {Object} urlPassport - The URL of the uploaded passport photo.
   *@param {Object} isFinal - The status of the submission.
   *@returns {Object} The response from the API.
   */
  const triggerSave = async (url, urlThumb, urlPassport, isFinal) => {
    try {
      setHoldData({
        ...dataReview.data,
        dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
      })

      return await API.graphql(
        graphqlOperation(createPersonalDataSheet, {
          input: {
            data: JSON.stringify(dataReview),
            userID: userDetails.id,
            isFinal: isFinal,
            department: userDetails.department,
            division: userDetails.division,
            isApproved: 'Pending Approval',
            isHRApproved: 'Pending Approval',
            isAgreeDataPrivacyPDS: isProceedPDS,
            eSignatureLoc: url || '',
            thumbmarkSignLoc: urlThumb || '',
            passportPhotoLoc: urlPassport || '',
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleSave
   *@description Handles the saving of the data.
   *@param {Object} isFinal - The status of the submission.
   *@returns {Object} none No return value.
   */
  const handleSave = async (isFinal) => {
    try {
      setIsDisabledAfterSave(true)
      const { url, urlThumb, urlPassport } = await forUpload()

      if (removeAsterisk) {
        removeAsterisk.remove()
      }

      if (isFinal) {
        let errorSpans = document.getElementsByClassName('errorSpan')
        let errorSpansArray = Array.from(errorSpans)

        errorSpansArray.forEach(function (span) {
          span.parentNode.removeChild(span)
        })

        function checkRequiredValues(obj) {
          const heightFormatInputs = document.querySelectorAll(
            'input[name*="heightFormat"]'
          )

          const weightFormatInputs = document.querySelectorAll(
            'input[name*="weightFormat"]'
          )

          const childrenFormatInputs = document.querySelectorAll(
            'input[name*="ifChildren"]'
          )

          const workExperienceFormatinputs = document.querySelectorAll(
            'input[name*="isWorkExperience"]'
          )

          const voluntaryWorkFormInputs = document.querySelectorAll(
            'input[name*="data[isVoluntaryWork]"]'
          )

          const lAndDFormInputs = document.querySelectorAll(
            'input[name*="isLDAttended"]'
          )
          for (let key in obj) {
            if (
              key.includes('Required') ||
              key.includes('NaAllowed') ||
              key === 'heightFormat' ||
              key === 'weightFormat' ||
              key === 'birthOrNaturaliztion' ||
              key === 'indicateCountry' ||
              key === 'bloodtype' ||
              key === 'ifChildren' ||
              key === 'heightFt' ||
              key === 'heightIn' ||
              key === 'heightMeters' ||
              key === 'weightPounds' ||
              key === 'relatedExplain' ||
              key === 'offenseADetails' ||
              key === 'serviceSeparationADetails' ||
              key === 'candidateDetails' ||
              key === 'resignedDetails' ||
              key === 'offenseCDetails' ||
              key === 'offenseDateFiled' ||
              key === 'courtADetails' ||
              key === 'offenseCaseStatus' ||
              key === 'immigrantDetails' ||
              key === 'isIndigenousDetails' ||
              key === 'isPersonWDisabilityDetails' ||
              key === 'isSoloParentDetails'
            ) {
              if (key === 'referencesRequired') {
                let value = obj[key]

                if (!Array.isArray(value) || value.length === 0) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: key,
                    isGridData: true,
                  }
                }

                for (let i = 0; i < value.length; i++) {
                  let element = value[i]
                  if (typeof element !== 'object' || element === null) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}]`,
                      isGridData: true,
                    }
                  }

                  for (let elementKey in element) {
                    if (
                      element[elementKey] === undefined ||
                      element[elementKey] === null ||
                      element[elementKey] === ''
                    ) {
                      return {
                        allRequiredValuesPresent: false,
                        failedKey: `[${key}][${i}][${elementKey}]`,
                        dataKey: `${key}[${i}].[${elementKey}]`,
                        isGridData: true,
                      }
                    }
                  }
                }
              } else {
                let value = obj[key]
                if (
                  typeof value === 'undefined' ||
                  value === null ||
                  value === '' ||
                  (typeof value === 'string' && value.trim() === '')
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: key,
                  }
                }
              }
            } else if (
              weightFormatInputs[0].value === 'kilograms' &&
              key === 'weightKg'
            ) {
              let value = obj[key]
              if (
                typeof value === 'undefined' ||
                value === null ||
                value === '' ||
                value.trim() === ''
              ) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                }
              }
            } else if (
              childrenFormatInputs[0].value === 'YES' &&
              key === 'well3Well2DataGrid'
            ) {
              let value = obj[key]
              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridDataChildren: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataChildren: true,
                  }
                }
                for (let elementKey in element) {
                  if (
                    element[elementKey] === undefined ||
                    element[elementKey] === null ||
                    (typeof element[elementKey] === 'string' &&
                      element[elementKey].trim() === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${elementKey}]`,
                      dataKey: `${key}[${i}].[${elementKey}]`,
                      isGridDataChildren: true,
                    }
                  }
                }
              }
            } else if (
              weightFormatInputs[1].value === 'pounds' &&
              key === 'weightPounds'
            ) {
              fieldCheckerFunction(obj, key)
            } else if (
              heightFormatInputs[0].value === 'meters' &&
              key === 'heightMeters'
            ) {
              fieldCheckerFunction(obj, key)
            } else if (key === 'well4DataGrid2') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isElementaryGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isElementaryGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'elementaryNameOfSchoolNaAllowed',
                  'elementaryBasicEducationNaAllowed',
                  'elementaryDateFromNaAllowed',
                  'elementaryDateToNaAllowed',
                  'elementaryUnitsEarnedNaAllowed',
                  'elementaryYearGraduatedNaAllowed',
                  'elementaryScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isElementaryGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4DataGrid') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isSecondaryGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isSecondaryGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'secondaryNameOfSchoolNaAllowed',
                  'secondaryBasicEducation',
                  'secondaryDateFromNaAllowed',
                  'secondaryDateToNaAllowed',
                  'secondaryUnitsEarnedNaAllowed',
                  'secondaryYearGraduatedNaAllowed',
                  'secondaryScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isSecondaryGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4Levelvocationaltradecourse') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isVocationalGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isVocationalGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'vocationalNameOfSchoolNaAllowed',
                  'vocationalBasicEducationNaAllowed',
                  'vocationalDateFromNaAllowed',
                  'vocationalDateToNaAllowed',
                  'vocationalUnitsEarnedNaAllowed',
                  'vocationalYeargraduatedNaAllowed',
                  'vocationalScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isVocationalGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4DataGrid3') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isCollegeGrid: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isCollegeGrid: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'collegeNameOfSchoolNaAllowed',
                  'collegeCourseNaAllowed',
                  'collegeDateFromNaAllowed',
                  'collegeDateToNaAllowed',
                  'collegeUnitsEarnedNaAllowed',
                  'collegeYearGraduatedNaAllowed',
                  'collegeScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isCollegeGrid: true,
                    }
                  }
                }
              }
            } else if (key === 'well4Well7DataGrid') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isCivilServiceEligibility: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isCivilServiceEligibility: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'haveAnyEligibility',
                  'civilServiceAndOthersLicense',
                  'civilServiceRating',
                  'civilServiceExamDateIsNA',
                  'civilServiceExamPlace',
                  'civilServiceLicenseNo',
                  'civilServiceLicenseValidityDateIsNA',
                ]
                const civilServiceExamDateIsNAFormat =
                  element['civilServiceExamDateIsNA']
                if (civilServiceExamDateIsNAFormat === 'enterDate') {
                  fieldsToCheck.splice(4, 0, 'civilServiceExamDate')
                }
                const civilServiceLicenseValidityDateIsNAFormat =
                  element['civilServiceLicenseValidityDateIsNA']
                if (civilServiceLicenseValidityDateIsNAFormat === 'enterDate') {
                  fieldsToCheck.splice(9, 0, 'civilServiceLicenseValidityDate')
                }
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isCivilServiceEligibility: true,
                    }
                  }
                }
              }
            } else if (key === 'well4Well5Levelgraduatestudies') {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGraduateStudies: true,
                }
              }
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGraduateStudies: true,
                  }
                }
                // Specified fields to check in order
                const fieldsToCheck = [
                  'graduateNameOfSchoolNaAllowed',
                  'graduateCourseNaAllowed',
                  'graduateDateFromNaAllowed',
                  'graduateDateToNaAllowed',
                  'graduateUnitsEarnedNaAllowed',
                  'graduateYearGraduatedNaAllowed',
                  'graduateScholarshipNaAllowed',
                ]
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGraduateStudies: true,
                    }
                  }
                }
              }
            } else if (key === 'isWorkExperience') {
              fieldCheckerFunction(obj, key)
            } else if (
              workExperienceFormatinputs[0].value === 'YES' &&
              key === 'well4Well8DataGrid'
            ) {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridDataWorkExperience: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataWorkExperience: true,
                  }
                }
                const elementKeys = Object.keys(element)
                const fieldsToCheck = [
                  'workExperienceDateFrom',
                  'workExperiencePositionTitle',
                  'workExperienceCompany',
                  'workExperienceSalary',
                  'workPayGradeIsNA',
                  'workExperienceAppointmentStatus',
                  'workExperienceIsGovtService',
                ]
                if (elementKeys.includes('workExperienceDateTo')) {
                  fieldsToCheck.splice(1, 0, 'workExperienceDateTo')
                }
                if (elementKeys.includes('workExperiencePayGrade')) {
                  fieldsToCheck.splice(5, 0, 'workExperiencePayGrade')
                }
                if (elementKeys.includes('workExperienceStepIncrement')) {
                  fieldsToCheck.splice(6, 0, 'workExperienceStepIncrement')
                }
                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGridVoluntaryWork: true,
                    }
                  }
                }
              }
            } else if (
              voluntaryWorkFormInputs[0].value === 'YES' &&
              key === 'well4Well10DataGrid'
            ) {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridVoluntaryWork: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridVoluntaryWork: true,
                  }
                }
                const elementKeys = Object.keys(element)
                const fieldsToCheck = [
                  'voluntaryWorkOrg',
                  'voluntaryWorkDateIsNA',
                  'voluntaryWorkHoursNo',
                  'voluntaryWorkPosition',
                ]

                if (elementKeys.includes('voluntaryWorkDateFrom')) {
                  fieldsToCheck.splice(1, 0, 'voluntaryWorkDateFrom')
                }

                if (elementKeys.includes('voluntaryWorkDateTo')) {
                  fieldsToCheck.splice(2, 0, 'voluntaryWorkDateTo')
                }

                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGridVoluntaryWork: true,
                    }
                  }
                }
              }
            } else if (key === 'isVoluntaryWork') {
              fieldCheckerFunction(obj, key)
            } else if (key === 'isLDAttended') {
              fieldCheckerFunction(obj, key)
            } else if (
              lAndDFormInputs[0].value === 'YES' &&
              key === 'well4Well11DataGrid'
            ) {
              let value = obj[key]

              if (!Array.isArray(value) || value.length === 0) {
                return {
                  allRequiredValuesPresent: false,
                  failedKey: key,
                  isGridlNd: true,
                }
              }

              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                if (typeof element !== 'object' || element === null) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridlNd: true,
                  }
                }
                const elementKeys = Object.keys(element)
                const fieldsToCheck = [
                  'ldTrainingTitle',
                  'ldTrainingDateIsNA',
                  'ldTrainingHoursNo',
                  'ldType',
                  'ldSponsoredBy',
                ]
                if (elementKeys.includes('ldTrainingDateFrom')) {
                  fieldsToCheck.splice(1, 0, 'ldTrainingDateFrom')
                }
                if (elementKeys.includes('ldTrainingDateTo')) {
                  fieldsToCheck.splice(2, 0, 'ldTrainingDateTo')
                }

                for (let field of fieldsToCheck) {
                  if (
                    element[field] === undefined ||
                    element[field] === null ||
                    (typeof element[field] === 'string' &&
                      element[field].trim() === '') ||
                    (typeof element[field] !== 'string' &&
                      element[field] === '')
                  ) {
                    return {
                      allRequiredValuesPresent: false,
                      failedKey: `[${key}][${i}][${field}]`,
                      dataKey: `${key}[${i}].[${field}]`,
                      isGridlNd: true,
                    }
                  }
                }
              }
            } else if (key === 'skillsAndHobbiesGrid') {
              let value = obj[key]
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                // Check if element is not an object, is null, or is an empty string
                if (
                  element.skillsAndHobbies.length === 0 ||
                  (typeof element.skillsAndHobbies === 'string' &&
                    element.skillsAndHobbies.trim().length === 0)
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataHobbies: true,
                  }
                }
              }
            } else if (key === 'nonAcademicdistinctionsrecognitionGrid') {
              let value = obj[key]
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                // Check if the specific property is an empty string
                if (
                  element.nonAcademicdistinctionsrecognition.length === 0 ||
                  (typeof element.nonAcademicdistinctionsrecognition ===
                    'string' &&
                    element.nonAcademicdistinctionsrecognition.trim().length ===
                      0)
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDatanonAcademicdistinctionsrecognition: true,
                  }
                }
              }
            } else if (key === 'orgMembershipGrid') {
              let value = obj[key]
              for (let i = 0; i < value.length; i++) {
                let element = value[i]
                // Check if element is not an object, is null, or is an empty string
                if (
                  element.orgMembership.length === 0 ||
                  (typeof element.orgMembership === 'string' &&
                    element.orgMembership.trim().length === 0)
                ) {
                  return {
                    allRequiredValuesPresent: false,
                    failedKey: `[${key}][${i}]`,
                    isGridDataorgMembership: true,
                  }
                }
              }
            }
            // else if (key === 'serviceSeparationADetails') {
            //   let value = obj[key]
            //   if (
            //     typeof value === 'undefined' ||
            //     value === null ||
            //     value === ''
            //   ) {
            //     return {
            //       allRequiredValuesPresent: false,
            //       failedKey: key,
            //     }
            //   }
            // } else if (key === 'candidateDetails') {
            //   let value = obj[key]
            //   if (
            //     typeof value === 'undefined' ||
            //     value === null ||
            //     value === ''
            //   ) {
            //     return {
            //       allRequiredValuesPresent: false,
            //       failedKey: key,
            //     }
            //   }
            // } else if (key === 'resignedDetails') {
            //   let value = obj[key]
            //   if (
            //     typeof value === 'undefined' ||
            //     value === null ||
            //     value === ''
            //   ) {
            //     return {
            //       allRequiredValuesPresent: false,
            //       failedKey: key,
            //     }
            //   }
            // } else if (key === 'offenseCDetails') {
            //   let value = obj[key]
            //   if (
            //     typeof value === 'undefined' ||
            //     value === null ||
            //     value === ''
            //   ) {
            //     return {
            //       allRequiredValuesPresent: false,
            //       failedKey: key,
            //     }
            //   }
            // } else if (key === 'offenseDateFiled') {
            //   let value = obj[key]
            //   if (
            //     typeof value === 'undefined' ||
            //     value === null ||
            //     value === ''
            //   ) {
            //     return {
            //       allRequiredValuesPresent: false,
            //       failedKey: key,
            //     }
            //   }
            // }
          }
          return {
            allRequiredValuesPresent: true,
            failedKey: null,
          }
        }
        let result = checkRequiredValues(dataReview.data)
        const isGridData = result.isGridData
        const allRequiredValuesPresent = result.allRequiredValuesPresent
        const isGridDataChildren = result.isGridDataChildren
        const isGridDataElementary = result.isElementaryGrid
        const isGridDataSecondary = result.isSecondaryGrid
        const isGridDataVocational = result.isVocationalGrid
        const isGridDataCollege = result.isCollegeGrid
        const isGridDataGraduateStudies = result.isGraduateStudies
        const isGridDataWorkExperience = result.isGridDataWorkExperience
        const isGridDataVoluntaryWork = result.isGridVoluntaryWork
        const isGridDataLNDAtendee = result.isGridlNd
        const isGridDataHobbies = result.isGridDataHobbies
        const isGridDatanonAcademicdistinctionsrecognition =
          result.isGridDatanonAcademicdistinctionsrecognition
        const isGridDataorgMembership = result.isGridDataorgMembership
        const isCivilServiceEligibility = result.isCivilServiceEligibility
        function errorNonTextType(element, picture) {
          if (picture) {
            const errorSpan = document.createElement('span')

            errorSpan.textContent = '* upload here *'
            errorSpan.classList.add('errorSpan')

            element.insertAdjacentElement('afterend', errorSpan)

            notification.error({
              message: 'Error on a required field.',
              description: `Please upload your ${picture}.`,
            })

            setRemoveAsterisk(errorSpan)
          } else {
            const labelElement = element.querySelector('label')
            const errorSpan = document.createElement('span')

            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')

            labelElement.insertAdjacentElement('afterend', errorSpan)
            setRemoveAsterisk(errorSpan)
          }

          element.scrollIntoView({ behavior: 'smooth' })

          const elementRect = element.getBoundingClientRect()
          const offset = (window.innerHeight - elementRect.height) / 2
          const scrollOffset = elementRect.top - offset

          element.scrollIntoView({ behavior: 'smooth' })

          window.scrollBy(0, scrollOffset)

          setIsModalOpen(false)
          setIsDisabledAfterSave(false)
        }

        if (allRequiredValuesPresent === false) {
          const editData = { ...dataReview.data }
          if (isGridData) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            const labelElement =
              element.parentNode.parentNode.querySelector('label')
            const errorSpan = document.createElement('span')

            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')

            labelElement.insertAdjacentElement('afterend', errorSpan)
            setRemoveAsterisk(errorSpan)
            element.classList.add('is-invalid')
            element.scrollIntoView({ behavior: 'smooth' })

            const elementRect = element.getBoundingClientRect()
            const offset = (window.innerHeight - elementRect.height) / 2
            const scrollOffset = elementRect.top - offset

            element.scrollIntoView({ behavior: 'smooth' })

            window.scrollBy(0, scrollOffset)

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (
            isGridDataElementary ||
            isGridDataSecondary ||
            isGridDataVocational ||
            isGridDataCollege ||
            isGridDataGraduateStudies ||
            isGridDataLNDAtendee
          ) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            if (element) {
              const labelElement =
                element.parentNode.parentNode.querySelector('label')
              if (labelElement) {
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
              } else {
                const labelElement =
                  element.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
              }
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              isGridDataLNDAtendee &&
              result.failedKey.includes('[ldTrainingDateIsNA]')
            ) {
              const elementSelect = document.querySelectorAll(
                '.formio-component-wellContent3'
              )
              const parts = result.failedKey.split(']') // Split by ']'
              const indexPart = parts[1] // Get the part with the index, which will be "[1"
              const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
              const index = parseInt(indexStr, 10) // Convert to integer

              let elementSelectedWithIndex = elementSelect[index]

              if (elementSelectedWithIndex) {
                const labelElement =
                  elementSelectedWithIndex.parentNode.parentNode.parentNode.querySelector(
                    'span'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelectedWithIndex.classList.add('is-invalid')
                elementSelectedWithIndex.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )

              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            }
          } else if (isGridDataWorkExperience || isGridDataVoluntaryWork) {
            const element = document.querySelector(
              `input[name="data${result.failedKey}"]`
            )
            if (element) {
              if (
                result.failedKey.includes('[workExperienceDateFrom]') ||
                result.failedKey.includes('[workExperienceSalary]') ||
                result.failedKey.includes('[voluntaryWorkOrg]') ||
                result.failedKey.includes('[voluntaryWorkDateFrom]') ||
                result.failedKey.includes('[voluntaryWorkDateTo]')
              ) {
                const labelElement =
                  element.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else if (
                result.failedKey.includes('[workExperiencePositionTitle]') ||
                result.failedKey.includes('[voluntaryWorkHoursNo]') ||
                result.failedKey.includes('[voluntaryWorkPosition]') ||
                result.failedKey.includes('[workExperienceCompany]')
              ) {
                const labelElement =
                  element.parentNode.parentNode.querySelector('label')
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )
              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else {
                const elementSelect = document.querySelectorAll(
                  '.formio-component-voluntaryWorkDateIsNA'
                )
                const parts = result.failedKey.split(']')
                const indexPart = parts[1]
                const indexStr = indexPart.substring(1)
                const index = parseInt(indexStr, 10)

                let elementSelectedWithIndex = elementSelect[index]
                if (elementSelectedWithIndex) {
                  const labelElement =
                    elementSelectedWithIndex.parentNode.querySelector('span')
                  const errorSpan = document.createElement('span')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  labelElement.insertAdjacentElement('afterend', errorSpan)
                  errorSpan.textContent = '*'
                  errorSpan.classList.add('errorSpan')
                  setRemoveAsterisk(errorSpan)
                  elementSelectedWithIndex.classList.add('is-invalid')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
              }
            }
          } else if (isGridDataHobbies) {
            const element = document.querySelectorAll(
              '.formio-component-skillsAndHobbiesGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isGridDatanonAcademicdistinctionsrecognition) {
            const element = document.querySelectorAll(
              '.formio-component-nonAcademicdistinctionsrecognitionGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isGridDataorgMembership) {
            const element = document.querySelectorAll(
              '.formio-component-orgMembershipGrid'
            )
            const parts = result.failedKey.split(']') // Split by ']'
            const indexPart = parts[1] // Get the part with the index, which will be "[1"
            const indexStr = indexPart.substring(1) // Remove the '[' to get "1"
            const index = parseInt(indexStr, 10) // Convert to integer
            const rows = element[0].querySelectorAll('input')
            const selectedRow = rows[index]
            let elementSelectedWithIndex = element[0]
            if (elementSelectedWithIndex) {
              const labelElement =
                elementSelectedWithIndex.querySelector('label')

              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              selectedRow.classList.add('is-invalid')
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (isCivilServiceEligibility) {
            if (result.failedKey.includes('[haveAnyEligibility]')) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-haveAnyEligibility'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              result.failedKey.includes('[civilServiceExamDateIsNA]') ||
              result.failedKey.includes('[civilServiceExamDate]')
            ) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-civilServiceExamDateIsNA'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else if (
              result.failedKey.includes(
                '[civilServiceLicenseValidityDateIsNA]'
              ) ||
              result.failedKey.includes('[civilServiceLicenseValidityDate]')
            ) {
              const parts = result.failedKey.split(']')
              const indexStr = parts[1].substring(1)
              const index = parseInt(indexStr, 10)

              const element = document.querySelectorAll(
                '.formio-component-civilServiceLicenseValidityDateIsNA'
              )

              errorNonTextType(element[index])

              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            } else {
              const elementSelect = document.querySelector(
                `select[name="data${result.failedKey}"]`
              )
              if (elementSelect) {
                const labelElement =
                  elementSelect.parentNode.parentNode.parentNode.querySelector(
                    'label'
                  )
                const errorSpan = document.createElement('span')
                labelElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')
                setRemoveAsterisk(errorSpan)
                elementSelect.classList.add('is-invalid')
                elementSelect.scrollIntoView({
                  behavior: 'smooth',
                  block: 'center',
                })
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              } else {
                const elementSelect = document.querySelector(
                  `input[name="data${result.failedKey}"]`
                )
                if (elementSelect) {
                  const labelElement =
                    elementSelect.parentNode.parentNode.parentNode.querySelector(
                      'label'
                    )
                  const errorSpan = document.createElement('span')
                  labelElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  labelElement.insertAdjacentElement('afterend', errorSpan)
                  errorSpan.textContent = '*'
                  errorSpan.classList.add('errorSpan')
                  setRemoveAsterisk(errorSpan)
                  elementSelect.classList.add('is-invalid')
                  elementSelect.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                  notification.error({
                    message: 'Error on a required field.',
                    description:
                      'This is a required field. Please fill it out before proceeding.',
                  })
                }
              }
            }
          } else if (isGridDataChildren) {
            if (result.failedKey.includes('[childrenFullnameNaAllowed]')) {
              const element = document.querySelector(
                `input[name="data${result.failedKey}"]`
              )
              if (element) {
                const labelElement =
                  element.parentNode.parentNode.querySelector('label')
                const errorSpan = document.createElement('span')
                labelElement.insertAdjacentElement('afterend', errorSpan)
                errorSpan.textContent = '*'
                errorSpan.classList.add('errorSpan')

                setRemoveAsterisk(errorSpan)
                element.classList.add('is-invalid')
                element.scrollIntoView({ behavior: 'smooth' })

                const elementRect = element.getBoundingClientRect()
                const offset = (window.innerHeight - elementRect.height) / 2
                const scrollOffset = elementRect.top - offset

                element.scrollIntoView({ behavior: 'smooth' })

                window.scrollBy(0, scrollOffset)

                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
              // else {
              //   const allElements = document.querySelectorAll(
              //     `input[name^="data[well3Well2DataGrid]["][name$="][childrenFullnameNaAllowed]"]`
              //   );
              //   allElements.forEach(element => {
              //     const dataGridElements = document.querySelectorAll(`input[name^="data[well3Well2DataGrid]"]`);
              //     element.parentNode.parentNode.scrollIntoView({ behavior: 'smooth', block: 'center' });
              //     for (let element of dataGridElements) {
              //       if (!element.value) {
              //         element.style.border = '1px solid red';
              //         element.addEventListener('input', function () {
              //           this.style.border = '';
              //         });
              //         let label = element.parentNode.parentNode;
              //         let labelName = label.children[0]
              //         if (!labelName.querySelector('.errorSpan')) {
              //           const errorSpan = document.createElement('span');
              //           errorSpan.textContent = '*';
              //           errorSpan.classList.add('errorSpan');
              //           labelName.appendChild(errorSpan);
              //           setRemoveAsterisk(errorSpan);
              //         }
              //         break;
              //       }
              //     }
              //   });
              //   notification.error({
              //     message: 'Error on a required field.',
              //     description: 'This is a required field. Please fill it out before proceeding.',
              //   });
              // }
            } else if (result.failedKey.includes('[childrenDOBNaAllowed]')) {
              if (result.failedKey) {
                const element = document.querySelector(
                  `input[name="data${result.failedKey}"]`
                )
                if (element) {
                  // Use closest() to find the nearest ancestor which matches the selector
                  const labelElement = element
                    .closest('.form-group')
                    .querySelector('label')
                  if (labelElement) {
                    const errorSpan = document.createElement('span')
                    errorSpan.textContent = '*'
                    errorSpan.classList.add('errorSpan')
                    labelElement.appendChild(errorSpan)
                    setRemoveAsterisk(errorSpan)
                  }
                  element.classList.add('is-invalid')
                }
                const errorElement = document.querySelector('.errorSpan')
                if (errorElement) {
                  errorElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                  })
                }
                // Display notification error
                notification.error({
                  message: 'Error on a required field.',
                  description:
                    'This is a required field. Please fill it out before proceeding.',
                })
              }
            }
          } else if (
            result.failedKey === 'suffixNaAllowed' ||
            result.failedKey === 'spouseSuffixNaAllowed' ||
            result.failedKey === 'fatherSuffixNaAllowed'
          ) {
            const dataSuffix = document.querySelector(
              `.formio-component-${result.failedKey}`
            )
            errorNonTextType(dataSuffix)
            dataSuffix.parentNode.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            })

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (result.failedKey === 'serviceSeparationADetails') {
            const elementSelect = document.querySelector(
              '.formio-component-serviceSeparationADetails'
            )

            if (elementSelect) {
              const labelElement =
                elementSelect.parentNode.parentNode.parentNode.querySelector(
                  'label'
                )
              const errorSpan = document.createElement('span')
              labelElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              labelElement.insertAdjacentElement('afterend', errorSpan)
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              elementSelect.classList.add('is-invalid')
              elementSelect.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (result.failedKey === 'offenseDateFiled') {
            const elementSelect = document.querySelector(
              '.formio-component-offenseDateFiled'
            )

            if (elementSelect) {
              const labelElement = elementSelect.querySelector('label')
              const errorSpan = document.createElement('span')
              labelElement.insertAdjacentElement('afterend', errorSpan)
              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')
              setRemoveAsterisk(errorSpan)
              elementSelect.classList.add('is-invalid')
              elementSelect.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
              })
              window.scrollBy({
                top: 100,
                behavior: 'smooth',
              })
              notification.error({
                message: 'Error on a required field.',
                description:
                  'This is a required field. Please fill it out before proceeding.',
              })
            }
          } else if (
            result.failedKey !== 'sexRequired' &&
            result.failedKey !== 'dateofbirthRequired' &&
            result.failedKey !== 'civilStatusRequired' &&
            result.failedKey !== 'citizenshipQuestionRequired' &&
            result.failedKey !== 'birthOrNaturaliztion' &&
            result.failedKey !== 'indicateCountry' &&
            result.failedKey !== 'isThirdDegreeRequired' &&
            result.failedKey !== 'isFourthDegreeRequired' &&
            result.failedKey !== 'offenseARequired' &&
            result.failedKey !== 'offenceCRequired' &&
            result.failedKey !== 'courtARequired' &&
            result.failedKey !== 'serviceSeparationARequired' &&
            result.failedKey !== 'candidateRequired' &&
            result.failedKey !== 'resignedRequired' &&
            result.failedKey !== 'immigrantRequired' &&
            result.failedKey !== 'isIndigenousRequired' &&
            result.failedKey !== 'isPersonWDisabilityRequired' &&
            result.failedKey !== 'isSoloParentRequired' &&
            result.failedKey !== 'heightFormat' &&
            result.failedKey !== 'weightFormat' &&
            result.failedKey !== 'bloodtype' &&
            result.failedKey !== 'ifChildren' &&
            result.failedKey !== 'well3Well2DataGrid' &&
            result.failedKey !== 'isWorkExperience' &&
            result.failedKey !== 'isVoluntaryWork' &&
            result.failedKey !== 'isLDAttended' &&
            result.failedKey !== 'offenseDateFiled' &&
            !result.failedKey.includes('[workExperienceIsGovtService]') &&
            !result.failedKey.includes('[workPayGradeIsNA]') &&
            !result.failedKey.includes('[workInclusiveDateToFormat]') &&
            !result.failedKey.includes('[workExperienceDateTo]')
          ) {
            const element = document.querySelector(
              `input[name="data[${result.failedKey}]"]`
            )
            const labelElement =
              element.parentElement.parentNode.querySelector('label')

            const errorSpan = document.createElement('span')
            labelElement.insertAdjacentElement('afterend', errorSpan)
            errorSpan.textContent = '*'
            errorSpan.classList.add('errorSpan')
            setRemoveAsterisk(errorSpan)
            labelElement.classList.add('is-invalid')
            repeatingNotif()
          }

          setHoldData({
            ...editData,
            dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
          })

          const element = document.querySelector(
            `input[name="data[${result.failedKey}]"]`
          )

          function repeatingNotif() {
            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          }

          if (element) {
            if (result.failedKey === 'dateofbirthRequired') {
              const elementLabel = document.getElementsByClassName(
                'formio-component-dateofbirthRequired'
              )

              const labelElement = elementLabel[0].querySelector('label')
              const errorSpan = document.createElement('span')

              errorSpan.textContent = '*'
              errorSpan.classList.add('errorSpan')

              labelElement.insertAdjacentElement('afterend', errorSpan)
              setRemoveAsterisk(errorSpan)

              element.parentNode.scrollIntoView({ behavior: 'smooth' })

              const elementRect = element.parentNode.getBoundingClientRect()
              const offset = (window.innerHeight - elementRect.height) / 2
              const scrollOffset = elementRect.top - offset

              element.parentNode.scrollIntoView({ behavior: 'smooth' })

              window.scrollBy(0, scrollOffset)

              notification.error({
                message: 'Error',
                description: 'Please select your valid date of birth.',
              })
            } else {
              element.classList.add('is-invalid')
              element.scrollIntoView({ behavior: 'smooth' })

              const elementRect = element.getBoundingClientRect()
              const offset = (window.innerHeight - elementRect.height) / 2
              const scrollOffset = elementRect.top - offset

              element.scrollIntoView({ behavior: 'smooth' })

              window.scrollBy(0, scrollOffset)
            }
          } else if (result.failedKey === 'bloodtype') {
            const element = document.querySelector(
              '.formio-component-bloodtype'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your blood type.',
            })
          } else if (
            result.failedKey.includes('[workExperienceIsGovtService]')
          ) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workExperienceIsGovtService'
            )

            errorNonTextType(element[index])
          } else if (result.failedKey.includes('[workPayGradeIsNA]')) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workPayGradeIsNA'
            )

            errorNonTextType(element[index])

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (
            result.failedKey.includes('[workInclusiveDateToFormat]') ||
            result.failedKey.includes('[workExperienceDateTo]')
          ) {
            const parts = result.failedKey.split(']')
            const indexStr = parts[1].substring(1)
            const index = parseInt(indexStr, 10)

            const element = document.querySelectorAll(
              '.formio-component-workInclusiveDateToFormat'
            )

            errorNonTextType(element[index])

            notification.error({
              message: 'Error on a required field.',
              description:
                'This is a required field. Please fill it out before proceeding.',
            })
          } else if (result.failedKey === 'civilStatusRequired') {
            const element = document.querySelector(
              '.formio-component-civilStatusRequired'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your civil status.',
            })
          } else if (result.failedKey === 'citizenshipQuestionRequired') {
            const element = document.querySelector(
              '.formio-component-citizenshipQuestionRequired'
            )

            errorNonTextType(element)

            notification.error({
              message: 'Error on a required field.',
              description: 'Please select your citizenship.',
            })
          } else if (result.failedKey === 'birthOrNaturaliztion') {
            if (holdData.citizenshipQuestionRequired === 'DUAL CITIZENSHIP') {
              const element = document.querySelector(
                '.formio-component-birthOrNaturaliztion'
              )

              errorNonTextType(element)

              notification.error({
                message: 'Error on a required field.',
                description: `Please select between "by birth" or "by naturalization".`,
              })
            }
          } else if (result.failedKey === 'indicateCountry') {
            if (holdData.citizenshipQuestionRequired === 'DUAL CITIZENSHIP') {
              const element = document.querySelector(
                '.formio-component-indicateCountry'
              )

              errorNonTextType(element)

              notification.error({
                message: 'Error on a required field.',
                description:
                  'Please select if country if you have dual citizenship.',
              })
            }
          } else if (result.failedKey === 'isWorkExperience') {
            const element = document.querySelector(
              '.formio-component-isWorkExperience'
            )
            errorNonTextType(element)
            repeatingNotif()
          } else if (result.failedKey === 'isVoluntaryWork') {
            const element = document.querySelector(
              '.formio-component-isVoluntaryWork'
            )
            errorNonTextType(element)
            repeatingNotif()
          } else if (result.failedKey === 'isLDAttended') {
            const element = document.querySelector(
              '.formio-component-isLDAttended'
            )
            errorNonTextType(element)
            repeatingNotif()
          } else if (result.failedKey === 'isThirdDegreeRequired') {
            const element = document.querySelector(
              '.formio-component-isThirdDegreeRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isFourthDegreeRequired') {
            const element = document.querySelector(
              '.formio-component-isFourthDegreeRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'offenseARequired') {
            const element = document.querySelector(
              '.formio-component-offenseARequired'
            )

            errorNonTextType(element)
            repeatingNotif()
          } else if (result.failedKey === 'offenceCRequired') {
            const element = document.querySelector(
              '.formio-component-offenceCRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'courtARequired') {
            const element = document.querySelector(
              '.formio-component-courtARequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'serviceSeparationARequired') {
            const element = document.querySelector(
              '.formio-component-serviceSeparationARequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'candidateRequired') {
            const element = document.querySelector(
              '.formio-component-candidateRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'resignedRequired') {
            const element = document.querySelector(
              '.formio-component-resignedRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'immigrantRequired') {
            const element = document.querySelector(
              '.formio-component-immigrantRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isIndigenousRequired') {
            const element = document.querySelector(
              '.formio-component-isIndigenousRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isPersonWDisabilityRequired') {
            const element = document.querySelector(
              '.formio-component-isPersonWDisabilityRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'isSoloParentRequired') {
            const element = document.querySelector(
              '.formio-component-isSoloParentRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'sexRequired') {
            const element = document.querySelector(
              '.formio-component-sexRequired'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'heightFormat') {
            const element = document.querySelector(
              '.formio-component-heightFormat'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'weightFormat') {
            const element = document.querySelector(
              '.formio-component-weightFormat'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'ifChildren') {
            const element = document.querySelector(
              '.formio-component-ifChildren'
            )

            errorNonTextType(element)

            repeatingNotif()
          } else if (result.failedKey === 'well3Well2DataGrid') {
            const element = document.querySelector(
              '.formio-component-ifChildren'
            )
            errorNonTextType(element)
            repeatingNotif()
          }

          setIsModalOpen(false)
          setIsDisabledAfterSave(false)
        } else {
          if (uploadedSignature && uploadedThumbmark && uploadedPassportPhoto) {
            await triggerSave(url, urlThumb, urlPassport, isFinal)

            const getDeptApproverDetailsArray =
              await getDeptApproverDetailsArrayFunction()

            const credentials = await Auth.currentCredentials()
            const accessKeyId = credentials.accessKeyId
            const secretAccessKey = credentials.secretAccessKey
            const sessionToken = credentials.sessionToken

            const sesClient = new SESClient({
              region: 'ap-southeast-1',
              credentials: {
                accessKeyId: accessKeyId,
                secretAccessKey: secretAccessKey,
                sessionToken: sessionToken,
              },
            })

            async function sendEmail(usernameFound) {
              const emailBody = `Dear Department Admin,
    
    This is to inform you that a Personal Data Sheet has been submitted. Kindly log in to your account to review the submission.
    
    The following is the details of the PDS submission.
    
    First Name: ${userDetails.name}
    Last Name: ${userDetails.lname}
    Home Department: ${userDetails.department}
    Date and Time of Submission: ${moment().format('MMMM D, YYYY h:mm A')}

    Please follow the link to open the PDS document submitted: 

    https://pds.d3n8dycsr461s8.amplifyapp.com
    
    
    If you have any questions or require further assistance, don't hesitate to reach the HR department.
    
    Thank you for your attention to this matter.
    
    Best regards,
    System Administrator
    HRIS`

              const params = {
                Destination: {
                  ToAddresses: [usernameFound],
                },
                Message: {
                  Body: {
                    Text: {
                      Data: emailBody,
                      Charset: 'UTF-8',
                    },
                  },
                  Subject: {
                    Data: 'Submission of Personal Data Sheet', // replace with your email subject
                    Charset: 'UTF-8',
                  },
                },
                Source: 'hris.qc.gov@gmail.com',
              }

              await sesClient.send(new SendEmailCommand(params))
            }

            if (
              getDeptApproverDetailsArray?.length >= 1 &&
              !window.location.href.includes('localhost')
            ) {
              await sendEmail(getDeptApproverDetailsArray[0].username)

              notification.success({
                message: 'Success',
                description:
                  'You have submitted for review your Personal Data Sheet successfully.',
              })
            } else if (!getDeptApproverDetailsArray?.length >= 1) {
              notification.info({
                message: 'NOTICE',
                duration: 120000,
                description: `We didn't see any Department Approver for your PDS. Please reach out to HRM Department to inform them of your submitted PDS Form`,
              })
            }

            closeModal()
          } else if (!uploadedSignature) {
            let divElement = document.querySelector(
              '.formio-component-well4Well6ColumnsSignature'
            )

            let childDiv = divElement.querySelector('div')
            let spanElement = childDiv.querySelector('span')
            errorNonTextType(spanElement, 'e-Signature and thumbmark photo')
          } else if (!uploadedThumbmark) {
            let divElement = document.querySelector(
              '.formio-component-well4Well6Content'
            )

            let childDiv = divElement.querySelector('div')
            let spanElement = childDiv.querySelector('span')
            errorNonTextType(spanElement, 'thumbmark photo / e-Signature')
          } else if (!uploadedPassportPhoto) {
            let divElement = document.querySelector(
              '.formio-component-well4Well22ColumnsContent'
            )

            let spanElement = divElement.querySelector('span')
            errorNonTextType(spanElement, 'passport photo')
          }
        }
      } else {
        await triggerSave(url, urlThumb, urlPassport, isFinal)

        closeModal()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getDeptApproverDetailsArrayFunction = async () => {
    try {
      let getDeptApproverDetailsArray = []
      let token = null

      do {
        const getDeptApproverDetails = await API.graphql(
          graphqlOperation(UserbyHomeDepartment, {
            department: userDetails.department,
            filter: { pdsRole: { eq: 'deptApprover' } },
            nextToken: token,
          })
        )

        getDeptApproverDetailsArray.push(
          ...getDeptApproverDetails.data.UserbyHomeDepartment.items
        )

        token = getDeptApproverDetails.data.UserbyHomeDepartment.nextToken
      } while (token)

      return getDeptApproverDetailsArray
    } catch (err) {
      console.log(err)
      return []
    }
  }

  /**
   *@function handleSignType
   *@description Handles the event type of the button from Formio
   *@param {Object} type - The type of the event.
   *@returns {Object} none No return value.
   */
  const handleSignType = (type) => {
    try {
      if (type === 'customEventThumbSign') {
        setIsModalOpenThumbmark(true)
      }

      if (type === 'customEventESign') {
        setIsModalOpenESign(true)
      }

      if (type === 'customEventPassportPhoto') {
        setIsModalOpenPassportPhoto(true)
      }

      if (type === 'chooseProvince') {
        setIsModalOpenProvince(true)
        setIsProvincePerm(false)
      }

      if (type === 'chooseBarangay') {
        setIsModalOpenBrgy(true)
        setIsBarangayPerm(false)
      }

      if (type === 'chooseCity') {
        setIsModalOpenCity(true)
        setIsCityPerm(false)
      }

      if (type === 'chooseProvincePerm') {
        setIsModalOpenProvince(true)
        setIsProvincePerm(true)
      }

      if (type === 'chooseBarangayPerm') {
        setIsModalOpenBrgy(true)
        setIsBarangayPerm(true)
      }

      if (type === 'chooseCityPerm') {
        setIsModalOpenCity(true)
        setIsCityPerm(true)
      }

      if (type === 'choosezipcode') {
        setIsModalOpenZipcode(true)
        setIsZipcodePerm(false)
      }

      if (type === 'choosezipcodePerm') {
        setIsModalOpenZipcode(true)
        setIsZipcodePerm(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangePassportPhoto
   *@description Handles the event when a file is uploaded.
   *@param {Object} e - The event object.
   *@returns {Object} none No return value.
   */
  const handleFileChangePassportPhoto = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const passportPhoto =
          document.getElementsByClassName('passport-photo')[0]

        const imgElementDels = passportPhoto.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            passportPhoto.removeChild(imgElement)
          })
        }

        const reader = new FileReader()

        reader.onload = (event) => {
          const dataURL = event.target.result

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          passportPhoto.appendChild(imgElement)

          setIsModalOpenESign(false)
          setIsModalOpenThumbmark(false)
          setIsModalOpenPassportPhoto(false)
        }

        reader.readAsDataURL(file)
        setUploadedPassportPhoto(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangeESign
   *@description Handles the event when a file is uploaded.
   *@param {Object} e - The event object.
   *@returns {Object} none No return value.
   */
  const handleFileChangeESign = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const eSignDiv = document.getElementsByClassName(
          'electronic-signature'
        )[0]

        const imgElementDels = eSignDiv.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            eSignDiv.removeChild(imgElement)
          })
        }

        const reader = new FileReader()

        reader.onload = (event) => {
          const dataURL = event.target.result

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          eSignDiv.appendChild(imgElement)
          setIsModalOpenESign(false)
        }

        reader.readAsDataURL(file)
        setUploadedSignature(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *@function handleFileChangeThumb
   *@description Handles the event when a file is uploaded.
   *@param {Object} e - The event object.
   *@returns {Object} none No return value.
   */
  const handleFileChangeThumb = (e) => {
    try {
      const file = e.target.files[0]
      const maxSize = 5 * 1024 * 1024 // 5MB

      if (file && file.size <= maxSize && file.type.startsWith('image/')) {
        const eThumbDiv = document.getElementsByClassName(
          'electronic-thumbmark'
        )[0]

        const imgElementDels = eThumbDiv.querySelectorAll('img')

        if (imgElementDels.length) {
          imgElementDels.forEach((imgElement) => {
            eThumbDiv.removeChild(imgElement)
          })
        }

        const reader = new FileReader() // Create a FileReader instance

        reader.onload = (event) => {
          const dataURL = event.target.result // Get the data URL from the FileReader

          const imgElement = document.createElement('img')
          imgElement.src = dataURL

          eThumbDiv.appendChild(imgElement)
          setIsModalOpenThumbmark(false)
        }

        reader.readAsDataURL(file)
        setUploadedThumbmark(file)
      } else {
        let errorMessage = ''
        if (!file) {
          errorMessage = 'No file selected.'
        } else if (file.size > maxSize) {
          errorMessage = 'File size exceeds the limit of 5MB.'
        } else if (!file.type.startsWith('image/')) {
          errorMessage =
            'Invalid file type. Only image files (jpg, .jpeg, .png, .gif, .svg, .webp) are allowed.'
        }

        notification.error({
          message: 'Error',
          description: errorMessage,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const inputChangeHandler = (inputElement, val) => {
    try {
      if (inputElement) {
        if (inputElement.type === 'radio') {
          // For radio inputs, find the corresponding radio option and set its checked property
          const radioOption = document.querySelector(
            `input[name="${inputElement.name}"][value="${val}"]`
          )
          if (radioOption) {
            radioOption.checked = true

            const event = new Event('change', { bubbles: true })
            radioOption.dispatchEvent(event)
          }
        } else {
          // For other input types, set the value property
          const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
            window.HTMLInputElement.prototype,
            'value'
          ).set
          nativeInputValueSetter.call(inputElement, val)

          const event = new Event('input', { bubbles: true })
          inputElement.dispatchEvent(event)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function processTableRows
   * @description Process the rows of a table to hide remove buttons and add row buttons
   * @returns {undefined} No return value
   * @throws {Error} If an error occurs
   */
  function processTableRows() {
    try {
      // Find the tbody element with the specific data-key
      const tbody = document.querySelector(
        'tbody[data-key="datagrid-referencesRequired"]'
      )

      if (!tbody) {
        return
      }

      // Get all tr elements
      const rows = tbody.getElementsByTagName('tr')
      const rowCount = rows.length

      if (rowCount === 0) {
        return
      }

      // Function to hide the remove button in a row
      function hideRemoveButtonInRow(row, rowIndex) {
        const removeButton = row.querySelector(
          'button[ref="datagrid-referencesRequired-removeRow"]'
        )
        if (removeButton) {
          removeButton.parentNode.style.display = 'none'
        }
      }

      // Handle remove buttons
      if (rowCount === 1) {
        // If there's only one row, hide its remove button
        hideRemoveButtonInRow(rows[0], 0)
      } else {
        // If there are multiple rows, process each row except the last one
        for (let i = 0; i < rowCount - 1; i++) {
          hideRemoveButtonInRow(rows[i], i)
        }

        // Check if the last row has the remove button (but don't hide it)
        const lastRowRemoveButton = rows[rowCount - 1].querySelector(
          'button[ref="datagrid-referencesRequired-removeRow"]'
        )
      }

      // Handle add row button
      const addRowButton = document.querySelector(
        'button[ref="datagrid-referencesRequired-addRow"]'
      )
      if (addRowButton) {
        if (rowCount >= 3) {
          addRowButton.style.display = 'none'
        } else {
          addRowButton.style.display = '' // Reset to default display value
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  function handleClick(event) {
    event.preventDefault()
  }

  const handleChangeForm = (e) => {
    try {
      processTableRows()
      const permanentHouseBlockLotNoRequiredElem = document.querySelector(
        'input[name="data[permanentHouseBlockLotNoRequired]"]'
      )
      const permanentStreetRequiredElem = document.querySelector(
        'input[name="data[permanentStreetRequired]"]'
      )
      const permanentSubdivisionVillageElem = document.querySelector(
        'input[name="data[permanentSubdivisionVillageRequired]"]'
      )
      const permanentBarangayRequiredElem = document.querySelector(
        'input[name="data[permanentBarangayRequired]"]'
      )
      const permanentCityMunicipalityRequiredElem = document.querySelector(
        'input[name="data[permanentCityMunicipalityRequired]"]'
      )
      const permanentProvinceRequiredElem = document.querySelector(
        'input[name="data[permanentProvinceRequired]"]'
      )
      const chooseProvinceButtonPermElem = document.querySelector(
        'button[name="data[chooseProvinceButtonPerm]"]'
      )
      const chooseCityButtonPermElem = document.querySelector(
        'button[name="data[chooseCityButtonPerm]"]'
      )
      const chooseBarangayButtonPermElem = document.querySelector(
        'button[name="data[chooseBarangayPerm]"]'
      )

      const chooseZipCodeButtonPermElem = document.querySelector(
        'button[name="data[choosezipcodePerm]"]'
      )

      const permanentZipcodeElem = document.querySelector(
        'input[name="data[permanentZipcodeNaAllowed]"]'
      )

      const firstCSERatingElem = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceRating]"]'
      )
      const firstCSEPOE = document.querySelector(
        'input[name*="data[well4Well7DataGrid][0][civilServiceExamDateIsNA]"]'
      )

      const firstCSEPlace = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceExamPlace]"]'
      )

      const firstOtherEligibility = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceAndOthersLicenseOthersEligibility]"]'
      )

      const firstCSELicense = document.querySelector(
        'input[name="data[well4Well7DataGrid][0][civilServiceLicenseNo]"]'
      )

      const firstCSEDOV = document.querySelector(
        'input[name*="data[well4Well7DataGrid][0][civilServiceLicenseValidityDateIsNA]"]'
      )

      const firstCSEExamDateInput = document.getElementsByClassName(
        'formio-component-civilServiceExamDate'
      )
      const firstCSELVD = document.getElementsByClassName(
        'formio-component-civilServiceLicenseValidityDate'
      )

      const firstCSEAddRowButton = document.querySelector(
        'button[ref="datagrid-well4Well7DataGrid-addRow"]'
      )

      const firstCSERemoveRowButton = document.querySelector(
        'button[ref="datagrid-well4Well7DataGrid-removeRow"]'
      )

      const spouseSurname = document.querySelector(
        'input[name="data[spouseSurnameNaAllowed]"]'
      )

      const spouseFirstName = document.querySelector(
        'input[name="data[spouseFirstnameNaAllowed]"]'
      )

      const spouseMiddleName = document.querySelector(
        'input[name="data[spouseMiddlenameNaAllowed]"]'
      )

      const spouseOccupation = document.querySelector(
        'input[name="data[spouseOccupationNaAllowed]"]'
      )

      const spouseEmployerName = document.querySelector(
        'input[name="data[spouseEmployerNameNaAllowed]"]'
      )

      const spouseEmployerAddress = document.querySelector(
        'input[name="data[spouseEmployerAddressNaAllowed]"]'
      )

      const spouseEmployerTelephoneno = document.querySelector(
        'input[name="data[spouseEmployerTelephonenoNaAllowed]"]'
      )

      const markSpouseAsNA = document.querySelector(
        'input[name="data[markSpouseAsNA]"]'
      )

      const spouseSuffixparentElement = document.querySelector(
        '.formio-component-spouseSuffixNaAllowed'
      )
      const dropdownList = spouseSuffixparentElement.querySelectorAll(
        '.choices__list.choices__list--dropdown'
      )
      const suffixDropDown = dropdownList[0]
      const allChoices = spouseSuffixparentElement.querySelectorAll(
        '.choices__item.choices__placeholder.choices__item--selectable'
      )
      const spouseSuffixSelect = allChoices[0]

      if (
        e?.changed?.component?.key === 'sameAsResidentialAddressBox' &&
        e?.changed?.value === true
      ) {
        setHoldData({
          ...e.data,
          dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        })

        inputChangeHandler(
          permanentHouseBlockLotNoRequiredElem,
          e.data.residentialHouseBlockLotNoRequired
        )

        inputChangeHandler(
          permanentStreetRequiredElem,
          e.data.residentialStreetRequired
        )

        inputChangeHandler(
          permanentSubdivisionVillageElem,
          e.data.residentialSubdivisionVillageRequired
        )

        inputChangeHandler(
          permanentBarangayRequiredElem,
          e.data.residentialBarangayRequired
        )

        inputChangeHandler(
          permanentCityMunicipalityRequiredElem,
          e.data.residentialCityMunicipalityRequired
        )

        inputChangeHandler(
          permanentProvinceRequiredElem,
          e.data.residentialProvinceRequired
        )

        inputChangeHandler(
          permanentZipcodeElem,
          e.data.residentialZipcodeNaAllowed
        )

        setTimeout(() => {
          chooseProvinceButtonPermElem.style.display = 'none'
          chooseCityButtonPermElem.style.display = 'none'
          chooseBarangayButtonPermElem.style.display = 'none'
          chooseZipCodeButtonPermElem.style.display = 'none'
          permanentHouseBlockLotNoRequiredElem.disabled = true
          permanentStreetRequiredElem.disabled = true
          permanentSubdivisionVillageElem.disabled = true
          permanentBarangayRequiredElem.disabled = true
          permanentCityMunicipalityRequiredElem.disabled = true
          permanentProvinceRequiredElem.disabled = true
          permanentZipcodeElem.disabled = true
        }, 500)
      } else if (
        e?.changed?.component?.key === 'workExperienceAppointmentStatus' &&
        e?.isValid
      ) {
        for (const [index, element] of e.data.well4Well8DataGrid.entries()) {
          if (
            element.workExperienceAppointmentStatus === 'CONTRACT OF SERVICE' ||
            element.workExperienceAppointmentStatus === 'JOB ORDER'
          ) {
            element.workExperienceIsGovtService = 'NO'
            const workExperienceIsGovtService = document.querySelector(
              `input[name*="data[well4Well8DataGrid][${index}][workExperienceIsGovtService]"][value="NO"]`
            )

            if (workExperienceIsGovtService) {
              workExperienceIsGovtService.checked = true

              const event = new Event('change', { bubbles: true })
              workExperienceIsGovtService.dispatchEvent(event)
            }
          } else {
            element.workExperienceIsGovtService = 'YES'
            const workExperienceIsGovtService = document.querySelector(
              `input[name*="data[well4Well8DataGrid][${index}][workExperienceIsGovtService]"][value="YES"]`
            )

            if (workExperienceIsGovtService) {
              workExperienceIsGovtService.checked = true

              const event = new Event('change', { bubbles: true })
              workExperienceIsGovtService.dispatchEvent(event)
            }
          }
        }

        setHoldData({
          ...e.data,
          dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        })
      } else if (
        e?.changed?.component?.key === 'sameAsResidentialAddressBox' &&
        e?.changed?.value === false
      ) {
        setHoldData({
          ...e.data,
          dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        })
        chooseProvinceButtonPermElem.style.display = 'block'
        chooseCityButtonPermElem.style.display = 'block'
        chooseBarangayButtonPermElem.style.display = 'block'
        chooseZipCodeButtonPermElem.style.display = 'block'
        permanentHouseBlockLotNoRequiredElem.disabled = false
        permanentStreetRequiredElem.disabled = false
        permanentSubdivisionVillageElem.disabled = false
        permanentBarangayRequiredElem.disabled = false
        permanentCityMunicipalityRequiredElem.disabled = false
        permanentProvinceRequiredElem.disabled = false
        permanentZipcodeElem.disabled = false
      } else if (
        (e?.changed?.component?.key === 'residentialHouseBlockLotNoRequired' ||
          e?.changed?.component?.key === 'residentialStreetRequired' ||
          e?.changed?.component?.key ===
            'residentialSubdivisionVillageRequired' ||
          e?.changed?.component?.key === 'residentialBarangayRequired' ||
          e?.changed?.component?.key ===
            'residentialCityMunicipalityRequired' ||
          e?.changed?.component?.key === 'residentialProvinceRequired' ||
          e?.changed?.component?.key === 'residentialZipcodeNaAllowed') &&
        e.data.sameAsResidentialAddressBox === true
      ) {
        const sameAsResidentialAddressBox = document.querySelector(
          'input[name="data[sameAsResidentialAddressBox]"]'
        )
        sameAsResidentialAddressBox.checked = false

        const event = new Event('change', { bubbles: true })
        sameAsResidentialAddressBox.dispatchEvent(event)
        chooseProvinceButtonPermElem.style.display = 'block'
        chooseCityButtonPermElem.style.display = 'block'
        chooseBarangayButtonPermElem.style.display = 'block'
        chooseZipCodeButtonPermElem.style.display = 'block'
        permanentHouseBlockLotNoRequiredElem.disabled = false
        permanentStreetRequiredElem.disabled = false
        permanentSubdivisionVillageElem.disabled = false
        permanentBarangayRequiredElem.disabled = false
        permanentCityMunicipalityRequiredElem.disabled = false
        permanentProvinceRequiredElem.disabled = false
        permanentZipcodeElem.disabled = false

        inputChangeHandler(permanentHouseBlockLotNoRequiredElem, '')

        inputChangeHandler(permanentStreetRequiredElem, '')

        inputChangeHandler(permanentSubdivisionVillageElem, '')

        inputChangeHandler(permanentBarangayRequiredElem, '')

        inputChangeHandler(permanentCityMunicipalityRequiredElem, '')

        inputChangeHandler(permanentProvinceRequiredElem, '')

        inputChangeHandler(permanentZipcodeElem, '')

        setHoldData({
          ...e.data,
          sameAsResidentialAddressBox: false,
          [`${e?.changed?.component?.key}`]: e?.changed?.value,
          permanentHouseBlockLotNoRequired: '',
          permanentStreetRequired: '',
          permanentSubdivisionVillageRequired: '',
          permanentBarangayRequired: '',
          permanentCityMunicipalityRequired: '',
          permanentProvinceRequired: '',
          permanentZipcodeNaAllowed: '',
          dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
        })
      } else if (e?.changed?.component?.key === 'haveAnyEligibility') {
        if (e?.changed?.value === 'NO') {
          setHoldData({
            ...e.data,
            dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
          })
          inputChangeHandler(firstCSERatingElem, 'N/A')
          inputChangeHandler(firstCSEPOE, 'N/A')
          inputChangeHandler(firstCSEPlace, 'N/A')
          inputChangeHandler(firstCSELicense, 'N/A')
          inputChangeHandler(firstCSEDOV, 'N/A')

          firstCSEExamDateInput[0].classList.add('formio-hidden')
          firstCSEExamDateInput[0].innerHTML = ''

          if (firstOtherEligibility?.parentNode?.parentNode) {
            firstOtherEligibility.parentNode.parentNode.remove()
          }

          firstCSELVD[0].classList.add('formio-hidden')
          firstCSELVD[0].innerHTML = ''

          firstCSEAddRowButton.disabled = true
          firstCSERemoveRowButton.disabled = true

          document.querySelectorAll('.pds-answer-form select').forEach((i) => {
            if (
              i &&
              i.getAttribute('name') ===
                'data[well4Well7DataGrid][0][civilServiceAndOthersLicense]'
            ) {
              const labelElement =
                i.parentNode.parentNode.parentNode.querySelector('label')
              labelElement.addEventListener('click', handleClick)

              let inputElement = document.createElement('input')
              inputElement.value = 'N/A'
              inputElement.setAttribute('aria-required', 'false')
              inputElement.setAttribute('spellcheck', 'true')
              inputElement.setAttribute('lang', 'en')
              inputElement.setAttribute('class', 'form-control')
              inputElement.setAttribute(
                'id',
                'civilServiceAndOthersLicenseTempInput'
              )
              inputElement.setAttribute('type', 'text')
              inputElement.setAttribute('ref', 'input')
              inputElement.setAttribute('aria-invalid', 'false')
              inputElement.setAttribute('disabled', '')
              i.parentNode.insertAdjacentElement('afterend', inputElement)
              i.parentNode.style.display = 'none'
              const updatedData = {
                ...e.data,
                well4Well7DataGrid: [
                  {
                    ...e.data.well4Well7DataGrid[0],
                    civilServiceAndOthersLicense: 'N/A',
                  },
                ],
              }
              setHoldData({
                ...updatedData,
                dateAccomplishedRequired: moment().format(
                  'YYYY-MM-DDTHH:mm:ssZ'
                ),
              })
            }
          })
        } else {
          if (e?.isValid) {
            const updatedData = {
              ...e.data,
              well4Well7DataGrid: [
                {
                  ...e.data.well4Well7DataGrid[0],
                  civilServiceAndOthersLicense: '',
                },
                ...e.data.well4Well7DataGrid.slice(1),
              ],
            }

            setHoldData({
              ...updatedData,
              dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
            })
            document
              .querySelectorAll('.pds-answer-form select')
              .forEach((i) => {
                if (
                  i &&
                  i.getAttribute('name') ===
                    'data[well4Well7DataGrid][0][civilServiceAndOthersLicense]'
                ) {
                  const labelElement =
                    i.parentNode.parentNode.parentNode.querySelector('label')

                  labelElement.removeEventListener('click', handleClick)

                  let inputElement = document.getElementById(
                    'civilServiceAndOthersLicenseTempInput'
                  )
                  inputElement.remove()

                  i.parentNode.style.display = 'block'
                }
              })
            firstCSEAddRowButton.disabled = false
            firstCSERemoveRowButton.disabled = false

            firstCSEExamDateInput[0].classList.remove('formio-hidden')
            firstCSELVD[0].classList.remove('formio-hidden')

            inputChangeHandler(firstCSERatingElem, '')
            inputChangeHandler(firstCSEPOE, '')
            inputChangeHandler(firstCSEPlace, '')
            inputChangeHandler(firstCSELicense, '')
            inputChangeHandler(firstCSEDOV, '')
          }
        }
      } else if (
        e?.changed?.component?.key === 'civilStatusRequired' &&
        e?.changed.value === 'SINGLE'
      ) {
        inputChangeHandler(spouseSurname, 'N/A')
        inputChangeHandler(spouseFirstName, 'N/A')
        inputChangeHandler(spouseMiddleName, 'N/A')

        inputChangeHandler(spouseOccupation, 'N/A')

        inputChangeHandler(spouseEmployerName, 'N/A')

        inputChangeHandler(spouseEmployerAddress, 'N/A')

        inputChangeHandler(spouseEmployerTelephoneno, 'N/A')
        if (spouseSuffixSelect) {
          spouseSuffixSelect.setAttribute('data-value', 'N/A')
          spouseSuffixSelect.firstChild.textContent = 'N/A'
        }

        if (suffixDropDown) {
          suffixDropDown.style.display = 'none'
        }
        if (spouseSuffixparentElement) {
          // Select all child span elements within the parent
          const spanElements =
            spouseSuffixparentElement.querySelectorAll('span')
          const firstElement = spanElements[0]
          // Check if any span elements were found
          firstElement.textContent = 'N/A'
        }
        e.data.spouseSuffixNaAllowed = 'N/A'
        e.data.markSpouseAsNA = true
        markSpouseAsNA.checked = true
        spouseSurname.disabled = true
        spouseFirstName.disabled = true
        spouseMiddleName.disabled = true
        spouseOccupation.disabled = true
        spouseEmployerName.disabled = true
        spouseEmployerAddress.disabled = true
        spouseMiddleName.disabled = true
        spouseEmployerTelephoneno.disabled = true
        markSpouseAsNA.disabled = true
      } else if (
        e?.changed?.component?.key === 'civilStatusRequired' &&
        e?.changed.value !== 'SINGLE'
      ) {
        e.data.markSpouseAsNA = false
        markSpouseAsNA.checked = false
        spouseSurname.disabled = false
        spouseFirstName.disabled = false
        spouseMiddleName.disabled = false
        spouseOccupation.disabled = false
        spouseEmployerName.disabled = false
        spouseEmployerAddress.disabled = false
        spouseMiddleName.disabled = false
        spouseEmployerTelephoneno.disabled = false
        markSpouseAsNA.disabled = false
        if (suffixDropDown) {
          suffixDropDown.style.display = ''
        }
        if (spouseSuffixparentElement) {
          // Select all child span elements within the parent
          const spanElements =
            spouseSuffixparentElement.querySelectorAll('span')
          const firstElement = spanElements[0]
          // Check if any span elements were found
          firstElement.textContent = ''
        }
      } else if (
        e?.changed?.component?.key === 'markSpouseAsNA' &&
        e?.changed?.value === true
      ) {
        inputChangeHandler(spouseSurname, 'N/A')
        inputChangeHandler(spouseFirstName, 'N/A')
        inputChangeHandler(spouseMiddleName, 'N/A')

        inputChangeHandler(spouseOccupation, 'N/A')

        inputChangeHandler(spouseEmployerName, 'N/A')

        inputChangeHandler(spouseEmployerAddress, 'N/A')

        inputChangeHandler(spouseEmployerTelephoneno, 'N/A')

        if (suffixDropDown) {
          suffixDropDown.style.display = 'none'
        }

        if (spouseSuffixparentElement) {
          // Select all child span elements within the parent
          const spanElements =
            spouseSuffixparentElement.querySelectorAll('span')
          const firstElement = spanElements[0]
          // Check if any span elements were found

          firstElement.textContent = 'N/A'
        }

        if (spouseSuffixSelect) {
          spouseSuffixSelect.setAttribute('data-value', 'N/A')
          spouseSuffixSelect.firstChild.textContent = 'N/A'
        }
        e.data.spouseSuffixNaAllowed = 'N/A'
        spouseSurname.disabled = true
        spouseFirstName.disabled = true
        spouseMiddleName.disabled = true
        spouseOccupation.disabled = true
        spouseEmployerName.disabled = true
        spouseEmployerAddress.disabled = true
        spouseMiddleName.disabled = true
        spouseEmployerTelephoneno.disabled = true
      } else if (
        e?.changed?.component?.key === 'markSpouseAsNA' &&
        e?.changed?.value === false
      ) {
        inputChangeHandler(spouseSurname, '')
        inputChangeHandler(spouseFirstName, '')
        inputChangeHandler(spouseMiddleName, '')

        inputChangeHandler(spouseOccupation, '')

        inputChangeHandler(spouseEmployerName, '')

        inputChangeHandler(spouseEmployerAddress, '')

        inputChangeHandler(spouseEmployerTelephoneno, '')
        if (spouseSuffixSelect) {
          spouseSuffixSelect.setAttribute('data-value', 'N/A IF NOT APPLICABLE')
          spouseSuffixSelect.firstChild.textContent = 'N/A IF NOT APPLICABLE'
        }
        if (spouseSuffixparentElement) {
          // Select all child span elements within the parent
          const spanElements =
            spouseSuffixparentElement.querySelectorAll('span')
          const firstElement = spanElements[0]
          // Check if any span elements were found
          firstElement.textContent = ''
        }
        if (suffixDropDown) {
          suffixDropDown.style.display = ''
        }
        e.data.spouseSuffixNaAllowed = ''
        spouseSurname.disabled = false
        spouseFirstName.disabled = false
        spouseMiddleName.disabled = false
        spouseOccupation.disabled = false
        spouseEmployerName.disabled = false
        spouseEmployerAddress.disabled = false
        spouseMiddleName.disabled = false
        spouseEmployerTelephoneno.disabled = false
      } else {
        if (e?.isValid) {
          setHoldData({
            ...e.data,
            dateAccomplishedRequired: moment().format('YYYY-MM-DDTHH:mm:ssZ'),
          })
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleChangeProvinces = (value) => {
    const selectedArea = isProvincePerm
      ? provinceDataPerm[value]
      : provinceData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }

    if (isProvincePerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentProvinceRequired: selectedArea.name.toUpperCase(),
        permanentCityMunicipalityRequired: '',
        permanentBarangayRequired: '',
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialProvinceRequired: selectedArea.name.toUpperCase(),
        residentialCityMunicipalityRequired: '',
        residentialBarangayRequired: '',
      }))
    }

    setIsModalOpenProvince(false)

    // Check if the selected area is a region or a province
    if (selectedArea.geographic_level === 'Reg') {
      // If it's a region (like NCR), load cities directly
      loadAndTransformCityData(value)
    } else {
      // If it's a province, load municipalities and cities
      loadAndTransformMunicipalityData(value)
    }
  }

  const handleChangeCities = (value) => {
    const selectedArea = isCityPerm
      ? selectedCityDataPerm[value]
      : selectedCityData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }

    if (isCityPerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentCityMunicipalityRequired: selectedArea.name.toUpperCase(),
        permanentBarangayRequired: '',
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialCityMunicipalityRequired: selectedArea.name.toUpperCase(),
        residentialBarangayRequired: '',
      }))
    }
    setIsModalOpenCity(false)
    loadAndTransformBarangayData(value)
  }

  const handleChangeBarangay = (value) => {
    const selectedArea = isBarangayPerm
      ? allAreasDataPerm[value]
      : allAreasData[value]
    if (!selectedArea) {
      console.error(`No area found for value: ${value}`)
      return
    }
    if (isBarangayPerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentBarangayRequired: selectedArea.name.toUpperCase(),
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialBarangayRequired: selectedArea.name.toUpperCase(),
      }))
    }

    setIsModalOpenBrgy(false)
  }

  const handleChangeZipcode = (selectedOption) => {
    const { value } = JSON.parse(selectedOption.value)
    if (isZipcodePerm) {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        permanentZipcodeNaAllowed: value,
      }))
    } else {
      setHoldData((prevHoldData) => ({
        ...prevHoldData,
        residentialZipcodeNaAllowed: value,
      }))
    }
    setIsModalOpenZipcode(false)
  }

  const zipcodeList = placeData.map((place, index) => ({
    value: place.post_code,
    label: `${place.municipality}, ${place.location} (${place.post_code})`,
    key: `${place.post_code}-${place.municipality}-${index}`,
    uniqueId: `${place.post_code}-${index}`,
  }))

  return (
    <>
      {isError ? (
        <Row justify='center' align='middle' style={{ height: '100vh' }}>
          <Col>
            <p>
              <em>
                We're experiencing technical difficulties. Our team has been
                notified and is working on the issue. Please try again in a few
                minutes. We apologize for the inconvenience.
              </em>
            </p>
          </Col>
        </Row>
      ) : (
        <>
          <div className='pds-answer-form'>
            <PDSGuide className='mb-3' />
            <PDSVideo
              setIsOpenPDSCacheMessage={(e) => setIsOpenPDSCacheMessage(e)}
            />
            <Card>
              <UploadPds
                exportData={handleExportData}
                exportFrom={'mainForm'}
              />
              {isFinishedScan ? (
                <Form
                  onChange={(e) => handleChangeForm(e)}
                  onSubmit={(e) => showModal(e)}
                  submission={{ data: holdData }}
                  onRender={handlePrefill}
                  src='https://www.sparksoft-demo.com/formio/pdsformmobilepage1'
                  onCustomEvent={(e) => handleSignType(e.type)}
                />
              ) : null}
              <BackTop duration={200}>
                <div className='pds-backtop-div'>BACK TO TOP</div>
              </BackTop>
            </Card>
          </div>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet'
            visible={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={[]}
            destroyOnClose={true}
          >
            <div style={{ textAlign: 'center' }}>
              <Button
                className={'me-4'}
                size='large'
                style={{ backgroundColor: '#635380' }}
                key='draft'
                ghost
                onClick={() => handleSave(false)}
                disabled={isDisabledAfterSave}
              >
                Save as draft
              </Button>
              <Button
                size='large'
                style={{ backgroundColor: '#87C38F' }}
                key='final'
                ghost
                onClick={() => handleSave(true)}
                disabled={isDisabledAfterSave}
              >
                Save as final
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet (Right Thumbmark) Agreement'
            visible={isModalOpenThumbmark}
            onCancel={() => setIsModalOpenThumbmark(false)}
            footer={[]}
          >
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                ref={fileInputRefThumb}
                style={{ display: 'none' }}
                onChange={handleFileChangeThumb}
                accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
              />
              <Button
                onClick={() => fileInputRefThumb.current.click()}
                type='primary'
                size='large'
                key='final'
                ghost
              >
                Agree and Upload
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet (e-Signature) Agreement'
            visible={isModalOpenESign}
            onCancel={() => setIsModalOpenESign(false)}
            footer={[]}
          >
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                ref={fileInputRefESign}
                style={{ display: 'none' }}
                onChange={handleFileChangeESign}
                accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
              />
              <Button
                onClick={() => fileInputRefESign.current.click()}
                type='primary'
                size='large'
                key='final'
                ghost
              >
                Agree and Upload
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Personal Data Sheet (Passport Photo) Agreement'
            visible={isModalOpenPassportPhoto}
            onCancel={() => setIsModalOpenPassportPhoto(false)}
            footer={[]}
            destroyOnClose
          >
            <div style={{ textAlign: 'center' }}>
              <input
                type='file'
                ref={fileInputRefPassportPhoto}
                style={{ display: 'none' }}
                onChange={handleFileChangePassportPhoto}
                accept='.jpg, .jpeg, .png, .gif, .svg, .webp'
              />
              <Button
                onClick={() => fileInputRefPassportPhoto.current.click()}
                type='primary'
                size='large'
                key='final'
                ghost
              >
                Agree and Upload
              </Button>
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose Province'
            visible={isModalOpenProvince}
            onCancel={() => setIsModalOpenProvince(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a province'
                optionFilterProp='children'
                onChange={handleChangeProvinces}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={regionGroupList}
              />
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose City or Municipality'
            visible={isModalOpenCity}
            onCancel={() => setIsModalOpenCity(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a city or municipality'
                optionFilterProp='children'
                onChange={handleChangeCities}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={isCityPerm ? municipalityListPerm : municipalityList}
              />
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose Barangay'
            visible={isModalOpenBrgy}
            onCancel={() => setIsModalOpenBrgy(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a barangay'
                optionFilterProp='children'
                onChange={handleChangeBarangay}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={isBarangayPerm ? barangayListPerm : barangayList}
              />
            </div>
          </Modal>

          <Modal
            maskClosable={false}
            title='Choose Zip Code'
            visible={isModalOpenZipcode}
            onCancel={() => setIsModalOpenZipcode(false)}
            footer={[]}
            destroyOnClose
            width={900}
          >
            <div style={{ textAlign: 'center' }}>
              <Select
                style={{
                  width: 800,
                }}
                size='large'
                showSearch
                placeholder='Select a place'
                optionFilterProp='children'
                onChange={handleChangeZipcode}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                labelInValue
              >
                {zipcodeList.map((item) => (
                  <Option
                    key={item.key}
                    value={JSON.stringify({
                      value: item.value,
                      label: item.label,
                    })}
                    className='option-item'
                  >
                    {item.label}
                  </Option>
                ))}
              </Select>
            </div>
          </Modal>
          <Modal
            title={null}
            open={isOpenPDSCacheMessage}
            width={'50vw'}
            footer={null}
            onCancel={() => setIsOpenPDSCacheMessage(false)}
            destroyOnClose={true}
          >
            <Title level={4}>Important: Auto-Save Information</Title>
            <List
              size='small'
              dataSource={[
                <Text key='info01'>
                  Your progress is automatically saved every few seconds to
                  prevent data loss in case of unexpected interruptions.
                </Text>,
                <Text key='info02' strong>
                  This is only a temporary save and will be deleted when you log
                  out.
                </Text>,
                <Text key='info03'>
                  To permanently save your work, use the 'Save as draft' or
                  'Submit as Final' options.
                </Text>,
                <Text key='info04'>
                  Do not rely on auto-save for long-term storage of your
                  information.
                </Text>,
                <Text key='info05'>
                  Always save your work properly before logging out to avoid
                  losing your progress.
                </Text>,
              ]}
              renderItem={(item) => <List.Item>{item}</List.Item>}
            />
          </Modal>
        </>
      )}
    </>
  )
}

MainForm.propTypes = {
  userDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
    division: PropTypes.string.isRequired,
    lname: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mname: PropTypes.string,
    suffix: PropTypes.string,
    gender: PropTypes.string.isRequired,
    contact: PropTypes.string.isRequired,
    username: PropTypes.string.isRequired,
  }).isRequired,
  afterSubmit: PropTypes.func.isRequired,
  isProceedPDS: PropTypes.bool.isRequired,
}
