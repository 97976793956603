/**
 * Title: evaluationMaker.js
 * Description: This is a file that contains the evaluation maker component
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/evaluationMaker.js
 * Changes Made:
 * - 2024.08.09 | Zil Valdez | Added a max length on the input fields to avoid the user inputting inifinite characters that cause of failed of creation upon submitting
 **/

import React, { useState } from 'react'

import EvaluationMakerPreview from './evaluationMakerPreview.js'

import { notification, Button, Modal, Row, Col, Input, Radio } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

const { TextArea } = Input

export default function EvaluationMaker({ setGetEvalID, userData }) {
  const [restart, setRestart] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [newQuestion, setNewQuestion] = useState([])

  const [questionsArray, setQuestionsArray] = useState([])

  const [modalPreview, setModalPreview] = useState(false)

  const handleOpenModal = () => {
    setOpenModal(true)
    setTimeout(() => {
      setRestart(true)
    }, 500)
  }

  const handleCancel = () => {
    setOpenModal(false)
    setRestart(false)
    setNewQuestion([])
  }

  /* const handleInputChange = (e, index) => {
    const id = e.target.id
    const value = e.target.value

    let newData = [...questionsArray]
    if (!newData[index]) {
      newData[index] = { question: '', categories: [] }
    }
    if (id.includes('question')) {
      newData[index].question = value
    } else {
      let category = {}
      category[id] = value
      let categoryIndex = -1
      for (let i = 0; i < newData[index].categories.length; i++) {
        if (newData[index].categories[i].hasOwnProperty(id)) {
          categoryIndex = i
          break
        }
      }
      if (categoryIndex > -1) {
        newData[index].categories[categoryIndex] = category
      } else {
        newData[index].categories.push(category)
      }
    }
    setQuestionsArray(newData)
  }

  const handleEmptyQuestionFields = (e) => {
    try {
      e.target.parentNode.parentElement.parentElement.parentElement.remove()
    } catch (err) {
      console.log(err)
    }
  } */

  const removeQuestion = (index) => {
    try {
      const updatedQuestion2 = [...questionsArray]
      const updatedQuestion = [...newQuestion]

      updatedQuestion.splice(index, 1)
      updatedQuestion2.splice(index, 1)

      setNewQuestion(updatedQuestion)
      setQuestionsArray(updatedQuestion2)
    } catch (error) {
      console.log(error)
    }
  }

  const addRow = newQuestion.map((questionType, index) => {
    const handleRadioChange = async (e, index) => {
      setNewQuestion((prevNewQuestion) => {
        const updatedNewQuestion = [...prevNewQuestion]
        updatedNewQuestion[index] = e.target.value
        return updatedNewQuestion
      })
    }

    return (
      <React.Fragment key={index}>
        <Row className={'mb-4 elemToDelete border-bottom'}>
          <Col span={24}>
            <div className='d-flex justify-content-end'>
              <Button
                onClick={() => removeQuestion(index)}
                className='text-white bg-danger'
              >
                X
              </Button>
            </div>
            <p className='text-center'>
              <em>
                <strong>Question Type</strong>
              </em>
            </p>
            <Radio.Group
              onChange={(e) => handleRadioChange(e, index)}
              defaultValue='category'
              className='d-flex justify-content-center'
            >
              <Radio value='category'>Category</Radio>
              <Radio value='comment'>Comment</Radio>
            </Radio.Group>
            <div hidden={questionType == 'comment'}>
              <Row className={'mb-3'}>
                <label htmlFor={`description-${index + 1}`}>Description:</label>
              </Row>
              <Row className={'mb-3'}>
                {questionType == 'comment' ? (
                  <TextArea
                    id={`description-${index + 1}`}
                    style={{ width: '100%' }}
                    rows={2}
                    value='n/a'
                    maxLength={2000}
                    // onChange={(e) => handleInputChange(e, index)}
                  />
                ) : (
                  <TextArea
                    id={`description-${index + 1}`}
                    style={{ width: '100%' }}
                    rows={2}
                    maxLength={2000}
                    // onChange={(e) => handleInputChange(e, index)}
                  />
                )}
              </Row>
            </div>
            <Row className={'mb-3'}>
              <label htmlFor={`question-${index + 1}`}>Your question:</label>
              {/*<Button onClick={e => handleEmptyQuestionFields(e)} size="small" type="danger" ghost>Remove question field/s below</Button>*/}
            </Row>
            <Row className={'mb-3'}>
              <TextArea
                id={`question-${index + 1}`}
                style={{ width: '100%' }}
                rows={4}
                maxLength={2000}
                // onChange={(e) => handleInputChange(e, index)}
              />
            </Row>
            {questionType === 'category' && (
              <div className='mb-4'>
                <Row>
                  <label htmlFor=''>Categories: </label>
                </Row>
                <Row justify='space-between'>
                  <Col span={7}>
                    <label
                      className={'me-2'}
                      htmlFor={`category-1-${index + 1}`}
                    >
                      <em>(rated 1-5)</em>
                    </label>
                    <Input
                      id={`category-1-${index + 1}`}
                      type='text'
                      maxLength={1024}
                      // onChange={(e) => handleInputChange(e, index)}
                    />
                  </Col>
                  <Col span={7}>
                    <label
                      className={'me-2'}
                      htmlFor={`category-2-${index + 1}`}
                    >
                      <em>(rated 1-5)</em>
                    </label>
                    <Input
                      id={`category-2-${index + 1}`}
                      type='text'
                      maxLength={1024}
                      // onChange={(e) => handleInputChange(e, index)}
                    />
                  </Col>
                  <Col span={7}>
                    <label
                      className={'me-2'}
                      htmlFor={`category-3-${index + 1}`}
                    >
                      <em>(rated 1-5)</em>
                    </label>
                    <Input
                      id={`category-3-${index + 1}`}
                      type='text'
                      maxLength={1024}
                      // onChange={(e) => handleInputChange(e, index)}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Col>
        </Row>
      </React.Fragment>
    )
  })

  const handleAddRow = () => {
    setNewQuestion([...newQuestion, 'category'])
  }

  const regen = () => {
    try {
      const questionsFound =
        document.querySelectorAll('[id*="question-"]').length >= 1

      if (!questionsFound) {
        notification['info']({
          message: 'Info',
          description: 'No question to save.',
        })
      } else {
        let holdErrors = []
        const questionsInTextArea =
          document.querySelectorAll('[id*="question-"]')
        const descriptionInTextArea = document.querySelectorAll(
          '[id*="description-"]'
        )
        questionsInTextArea.forEach((elem) => {
          if (!elem.value.length) {
            holdErrors.push('')
          }
        })

        descriptionInTextArea.forEach((elem) => {
          if (!elem.value.length) {
            holdErrors.push('')
          }
        })

        if (holdErrors.length) {
          notification['info']({
            message: 'Info',
            description: 'All fields must be filled.',
          })
        } else {
          let newData = []
          let holdBlankInputs = []

          descriptionInTextArea.forEach((elem, index) => {
            if (!newData[index]) {
              newData[index] = { question: '', description: '', categories: [] }
              const id = elem.id
              const value = elem.value
              if (id.includes('description')) {
                newData[index].description = value
              }
            }
          })

          questionsInTextArea.forEach((elem, index) => {
            if (!newData[index]) {
              newData[index] = { question: '', description: '', categories: [] }
            }

            const id = elem.id
            const value = elem.value

            if (id.includes('question')) {
              newData[index].question = value
            }

            const inputElems =
              elem.parentElement.parentElement.querySelectorAll(
                '[id*="category-"]'
              )

            inputElems.forEach((inputElement, inputIndex) => {
              let category = {
                [`${inputElement.id}`]: inputElement.value,
              }
              if (inputElement.value.toLowerCase() === 'n/a') {
                return
              }

              if (inputElement.value.length) {
                newData[index].categories.push(category)
              } else {
                holdBlankInputs.push('')
              }
            })
          })

          if (holdBlankInputs.length) {
            notification['info']({
              message: 'Info',
              description: 'All fields must be filled.',
            })
          } else {
            setQuestionsArray(newData)
            setModalPreview(true)
          }
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Button ghost type='primary' onClick={handleOpenModal}>
        Create Post Evaluation Questionnaire
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Create Evaluation Questionnaire'
        visible={openModal}
        footer={null}
        onCancel={handleCancel}
        width={800}
      >
        {restart ? (
          <>
            <Row>
              <Col span={24}>
                <>{addRow}</>
              </Col>
            </Row>
            <Row justify='space-between' className={'mb-4'}>
              <Button
                onClick={handleAddRow}
                type='dashed'
                className={'d-flex align-items-center'}
              >
                <PlusOutlined />
                Add question
              </Button>

              <EvaluationMakerPreview
                userData={userData}
                regen={regen}
                modalPreview={modalPreview}
                setModalPreview={(e) => setModalPreview(e)}
                refresh={() => setQuestionsArray([])}
                closeMotherModal={(e) => setOpenModal(e)}
                setGetEvalID={(e) => setGetEvalID(e)}
                questionsArray={questionsArray}
              />
            </Row>
          </>
        ) : null}
      </Modal>
    </>
  )
}
