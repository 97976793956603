/**
 * Title: login.js
 * Description: This is a file that contains the components for the login page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Login/login.js
 *
 * Changes made:
 * - 11/29/24 | Harry Lagunsad | Improve login page in terms of grammar and make some ui/ux changes as well
 **/
import React from 'react'

import { Input, Typography, notification } from 'antd'
import { Auth } from 'aws-amplify'
import DOMPurify from 'dompurify'
import moment from 'moment'
import PropTypes from 'prop-types'

import './login.css'

const { Title, Text } = Typography

export default class Login extends React.Component {
  constructor() {
    super()
    this.state = {
      showPage: false,
      usernameError: '',
    }
  }

  // Sanitize input
  sanitizeInput = (input) => {
    return DOMPurify.sanitize(input)
  }

  // Validate password
  validatePassword = (password) => {
    if (password.length < 8) {
      return 'Password must be at least 8 characters long.'
    }
    if (password.length > 128) {
      return 'Password must not exceed 128 characters.'
    }
    // Example: You can add more validation rules here
    return ''
  }

  handlePasswordChange = (e) => {
    const sanitizedPassword = this.sanitizeInput(e.target.value)
    const error = this.validatePassword(sanitizedPassword)

    this.setState({
      password: sanitizedPassword,
      error,
    })
  }

  /**
   * @function checkIfLoggedin
   * @description Checks if a user is currently logged in by retrieving the current session.
   * If a session is found, it redirects the user to the homepage. If no session is found,
   * it clears local storage. If an error occurs, it clears local storage and sets the state to show the page.
   *
   * @returns {Promise<void>} A promise that resolves when the check is complete.
   *
   */
  checkIfLoggedin = async () => {
    try {
      const session = await Auth.currentSession()
      if (session) {
        window.location.pathname = '/'
      } else {
        localStorage.clear()
      }
    } catch (err) {
      if (err !== 'No current user') {
        notification['info']({
          message: 'Info',
          description: 'No valid session found. Please log in to continue.',
        })
      }

      localStorage.clear()
      this.setState({ showPage: true })
    }
  }

  /**
   * @function componentDidMount
   * @description Lifecycle method that is called after the component is mounted. It checks if the user is logged in, sets the current date and day in the state, and performs any other necessary setup.
   *
   * @returns {void}
   *
   */
  componentDidMount() {
    this.checkIfLoggedin()

    let date = moment().format('MMMM Do YYYY')
    let day = moment().format('dddd')
    this.setState({ date: date, day: day })
  }

  /**
   * @function handleSubmit
   * @description Handles the form submission by validating the input fields. If any errors are detected (e.g., blank username or invalid username format), it displays error notifications and sets error states. If no errors are found, it disables the form and triggers the `signIn` function passed via props.
   *
   * @param {Event} e - The event object from the form submission.
   *
   * @returns {Promise<void>} A promise that resolves when the form submission handling is complete.
   */
  handleSubmit = async (e) => {
    try {
      e.preventDefault()
      const d = this.state
      const validUsernameRegex =
        /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

      let hasError = false

      if (!d.username || d.username.trim() === '') {
        this.setState({ usernameError: 'Username cannot be blank' })
        notification['error']({
          message: 'Error',
          description: 'Username cannot be blank',
        })
        hasError = true
      } else if (!validUsernameRegex.test(d.username)) {
        this.setState({ usernameError: 'Please enter a valid username' })
        notification['error']({
          message: 'Error',
          description: 'Please enter a valid username',
        })
        hasError = true
      } else {
        this.setState({ usernameError: null }) // Clear the error if the username is valid
      }

      if (!d.password) {
        notification['error']({
          message: 'Error',
          description: 'Please complete the fields below to continue.',
        })
        hasError = true
      }

      if (!hasError) {
        this.setState({ disabled: true })
        this.props.signIn(this.state)
      } else {
        this.setState({ disabled: false })
      }
    } catch (err) {
      notification['error']({
        message: 'Login Failed',
        description:
          'An error occurred while trying to log in. Please contact the System Admin for help.',
      })
    }
  }

  /**
   * @function componentDidUpdate
   * @description Lifecycle method that is called after the component updates. It resets the `disabled` state if the props have changed.
   *
   * @param {Object} prevProps - The previous props of the component.
   *
   * @returns {void}
   */
  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ disabled: false })
    }
  }
  render() {
    return (
      <>
        {this.state.showPage === true ? (
          <div className='mainLogin-div'>
            <div className='mainHeader-login'>
              <div className='loginHead-title'></div>
              <div className='loginHead-date'>
                {`${this.state.day}, ${this.state.date}`}
              </div>
            </div>
            <div className='loginCard-div'>
              <div className='loginCard-right'>
                <img
                  src='https://quezoncity.gov.ph/wp-content/themes/qcproject/images/qclogo_main.png'
                  alt='QCLogo'
                />
                <div></div>
                <span>
                  Quezon City <br />
                  Human Resource Management Department (HRMD) Online Platform
                </span>
              </div>
              <form onSubmit={this.handleSubmit} className='loginCard-left'>
                <div className='loginTitle'>LOGIN</div>
                <span>
                  <label className='loginInput-label' htmlFor='uname'>
                    Username*
                  </label>
                  <Input
                    value={this.state.username}
                    disabled={this.state.disabled}
                    id='uname'
                    placeholder='Enter your username'
                    onFocus={() => this.setState({ usernameError: '' })}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\s/g, '') // Remove spaces
                      this.setState({
                        username: value.toLowerCase(),
                      })
                    }}
                    onKeyDown={(e) => {
                      if (e.key === ' ') {
                        e.preventDefault() // Prevent space from being typed
                      }
                    }}
                    className='loginInput'
                    type='email'
                    required
                    autoFocus
                    size='large'
                    maxLength={128}
                  />
                  {this.state.usernameError && (
                    <p
                      style={{ color: 'red', fontSize: '12px' }}
                      className='error-message'
                    >
                      {this.state.usernameError}
                    </p>
                  )}
                </span>

                <span>
                  <label className='loginInput-label' htmlFor='uname'>
                    Password *
                  </label>
                  <Input.Password
                    disabled={this.state.disabled}
                    onChange={(e) => this.handlePasswordChange(e)}
                    id='pwd'
                    placeholder='Enter your password'
                    className='loginInput'
                    required
                    size='large'
                    maxLength={128}
                  />
                  {this.state.error && (
                    <div
                      style={{ color: 'red', marginTop: '5px', fontSize: 14 }}
                    >
                      {this.state.error}
                    </div>
                  )}
                </span>
                <span>
                  <button
                    disabled={this.state.disabled}
                    className='btns btnLogin'
                  >
                    Sign-in
                  </button>
                </span>

                <div className='text-center mt-4'>
                  <Text type='secondary' className='d-block mb-2'>
                    Don't have an account?{' '}
                    <a href='/register'>Create account</a>
                  </Text>
                  <div>
                    <Text>
                      <a href='/verify' className='me-3'>
                        Verify account
                      </a>
                    </Text>
                    <Text>
                      <a href='/changePassword'>Forgot password?</a>
                    </Text>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : null}
      </>
    )
  }
}

Login.propTypes = {
  signIn: PropTypes.func.isRequired,
}
