/**
 * Title: registeredUsersByEvent.js
 * Description: This is a file that contains the components for the registered users by event
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/registeredUsersByEvent.js
 *
 * Changes made:
 * 10/17/2024 | Harry Lagunsad | Add the employment type column in the downloaded excel or csv file
 **/
import React, { Suspense, useRef, useState } from 'react'

import {
  Button,
  Input,
  Modal,
  Row,
  Space,
  Table,
  Tooltip,
  notification,
} from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import xlsx from 'json-as-xlsx'
import moment from 'moment'
import PropTypes from 'prop-types'
import Highlighter from 'react-highlight-words'

import {
  DeleteTwoTone,
  DownloadOutlined,
  FilePdfTwoTone,
  SearchOutlined,
} from '@ant-design/icons'

import { IDLPUploadByUserId, registrationByTAID } from '../../api/queries'
import { numToString } from '../../utilities/numToString'
import { isValidURL } from '../../utilities/utils.js'

const DeleteRegistrant = React.lazy(() => import('./deleteRegistrant.js'))

export default function RegisteredUsersByEvents({ viewCourse }) {
  const [openTableModal, setOpenTableModal] = useState(false)
  const [allRegistrants, setAllRegistrants] = useState([])
  const [disableAfter, setDisableAfter] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openDeleteModalIndex, setOpenDeleteModalIndex] = useState(-1)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')

  const [noFullName, setNoFullName] = useState(false)

  const searchInput = useRef(null)

  // Filter name
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  // Reset name filter
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  //Get the assessmentQuestion the correct answer per queistion is also included in the question
  let assesQuestionCorrectAns
  if (viewCourse.questionsAssessment !== null) {
    assesQuestionCorrectAns = JSON.parse(
      viewCourse.questionsAssessment.assessmentQuestionnaire
    )
  }

  const getAllRegistrants = async () => {
    try {
      const checkLength = await API.graphql(
        graphqlOperation(registrationByTAID, {
          trainingAnnouncementID: viewCourse.id,
          limit: 1000,
        })
      )
      if (checkLength.data.registrationByTAID.items.length) {
        const withCount = checkLength.data.registrationByTAID.items
          .filter((item) => {
            if (item?.isPreAssessmentPassed !== false) {
              return item
            }
          })
          .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
          .map((obj, index) => {
            return {
              ...obj,
              count: index + 1,
            }
          })

        if (withCount.length) {
          if (withCount[0]?.first_name) {
            setNoFullName(true)
          } else {
            setNoFullName(false)
          }

          setOpenTableModal(true)
          setAllRegistrants(
            withCount.sort(
              (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
            )
          )
        } else {
          setOpenTableModal(false)
        }
      } else {
        notification['info']({
          message: 'Info',
          description: 'No registrants for this event.',
        })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const refresh = () => {
    setAllRegistrants([])
    getAllRegistrants()
  }

  const handleOpen = () => {
    getAllRegistrants()
  }

  const handleDisable = () => {
    if (allRegistrants.length) {
      return disableAfter !== false
    } else {
      return true
    }
  }

  const employmentTypeValueGetter = (e) => {
    if (e === 'permanentOrRegular') {
      return 'Permanent or Regular'
    } else if (e === 'cosOrJoOrConsultant') {
      return 'COS or JO or Consultant'
    } else {
      return ''
    }
  }

  //Initial content of downloadable excel
  let dynamicColumns = []
  if (noFullName) {
    dynamicColumns = [
      { label: 'Data Privacy Consent', value: 'Data Privacy Consent' },
      { label: 'FIRST NAME', value: 'FIRST NAME' },
      { label: 'MIDDLE INITIAL', value: 'MIDDLE INITIAL' },
      { label: 'LAST NAME', value: 'LAST NAME' },
      { label: 'NICKNAME', value: 'NICKNAME' },
      { label: 'CONTACT NUMBER', value: 'CONTACT NUMBER' },
      { label: 'EMPLOYMENT TYPE', value: 'EMPLOYMENT TYPE' },
      {
        label: 'DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE',
        value: 'DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE',
      },
      { label: 'DESIGNATION/POSITION', value: 'DESIGNATION/POSITION' },
      { label: 'DIVISION/ SECTION', value: 'DIVISION/ SECTION' },
      { label: 'EMAIL ADDRESS', value: 'EMAIL ADDRESS' },
      { label: 'SEX', value: 'SEX' },
      { label: 'Endorsement Letter', value: 'Endorsement Letter' },
      { label: 'CSC Uploaded', value: 'CSC Uploaded' },
      { label: 'Date Answered', value: 'Date Answered' },
      { label: 'Expected Outcomes', value: 'Expected Outcomes' },
    ]
  } else {
    dynamicColumns = [
      { label: 'Data Privacy Consent', value: 'Data Privacy Consent' },
      { label: 'FULL NAME', value: 'FULL NAME' },
      { label: 'NICKNAME', value: 'NICKNAME' },
      { label: 'CONTACT NUMBER', value: 'CONTACT NUMBER' },
      { label: 'EMPLOYMENT TYPE', value: 'EMPLOYMENT TYPE' },
      {
        label: 'DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE',
        value: 'DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE',
      },
      { label: 'DESIGNATION/POSITION', value: 'DESIGNATION/POSITION' },
      { label: 'DIVISION/ SECTION', value: 'DIVISION/ SECTION' },
      { label: 'EMAIL ADDRESS', value: 'EMAIL ADDRESS' },
      { label: 'SEX', value: 'SEX' },
      { label: 'Endorsement Letter', value: 'Endorsement Letter' },
      { label: 'CSC Uploaded', value: 'CSC Uploaded' },
      { label: 'Date Answered', value: 'Date Answered' },
      { label: 'Expected Outcomes', value: 'Expected Outcomes' },
    ]
  }

  //Add the dynamic question into excel table
  if (assesQuestionCorrectAns) {
    dynamicColumns.splice(10, 0, {
      label: 'Pre-assessment Total',
      value: 'QTotal',
    })
    for (let i = 0; i < assesQuestionCorrectAns.length; i++) {
      const questionLabel = `Pre-assessment Q${i + 1}`
      // const questionValue = `[Q${i + 1}]`;
      // dynamicColumns.splice(10 + i, 0, { label: `${questionLabel}`, value: `${dynamicQs[questionValue]}` });
      const questionValue = `Q${i + 1}`
      const answeredPicked = `Q${i + 1}-picked`
      dynamicColumns.splice(10 + i, 0, {
        label: `${questionLabel}`,
        value: questionValue,
      })

      dynamicColumns.push({
        label: `${questionLabel} answer picked`,
        value: answeredPicked,
      })
    }
  }
  const handleDownload = async () => {
    setDisableAfter(true)
    try {
      const toDownload = allRegistrants.map((obj) => {
        const {
          isAgreeDataPrivacyConsent,
          contactNumber,
          employmentType,
          createdAt,
          dept_office_unit_taskForce,
          designation_position,
          division_section,
          emailAddress,
          fullName,
          first_name,
          middle_initial,
          last_name,
          gender,
          endorsementLetter,
          expectedOutcomes,
          cscProof,
          nickname,
        } = obj

        const q = JSON.parse(obj.questionnaire)

        //Create the dynamic excel key value pair depend on the question and answer of user
        let score = 0
        let dynamicQs = {}
        if (assesQuestionCorrectAns) {
          assesQuestionCorrectAns.forEach((elem, index) => {
            const numString = numToString(index)
            const question = 'question' + numString

            if (q[question]) {
              const itemAnswer = elem.correctAnswer
              let oldAnswers =
                q[question] == 'plantilla'
                  ? 'A'
                  : q[question] == 'jobOrder'
                    ? 'C'
                    : q[question] == 'contractOfService'
                      ? 'B'
                      : q[question] == 'consultant'
                        ? 'D'
                        : q[question][q[question].length - 1]
              const userAnswer =
                q[question].length > 1 ? oldAnswers : q[question]
              let isCorrect = itemAnswer == userAnswer

              dynamicQs[`Q${index + 1}-picked`] = q[question]
              dynamicQs[`Q${index + 1}`] = isCorrect ? '1' : '0'
              if (isCorrect) {
                score += 1
              }
            }
          })
        }

        //Value of downloadable excel
        let excelValue = {
          ['Data Privacy Consent']:
            isAgreeDataPrivacyConsent === true ? 'YES' : 'NO',
          ['FULL NAME']: fullName,
          ['NICKNAME']: nickname,
          ['CONTACT NUMBER']: contactNumber,
          ['EMPLOYMENT TYPE']: employmentTypeValueGetter(employmentType) || '',
          ['DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE']: dept_office_unit_taskForce,
          ['DESIGNATION/POSITION']: designation_position,
          ['DIVISION/ SECTION']: division_section,
          ['EMAIL ADDRESS']: emailAddress,
          ['SEX']: gender == 'notSpecified' ? 'Not specified' : gender,
          ['Endorsement Letter']: endorsementLetter,
          ...dynamicQs,
          ['QTotal']: score,
          ['Expected Outcomes']: expectedOutcomes,
          ['Date Answered']: moment(createdAt).format(
            'MMMM Do YYYY, h:mm:ss a'
          ),
          ['CSC Uploaded']: cscProof,
        }

        if (noFullName) {
          excelValue['FIRST NAME'] = first_name
          excelValue['MIDDLE INITIAL'] = middle_initial
          excelValue['LAST NAME'] = last_name
          delete excelValue['FULL NAME']
          return excelValue
        } else {
          return excelValue
        }
      })

      let data = [
        {
          sheet: 'Registrants',
          columns: dynamicColumns,
          content: toDownload.sort(
            (a, b) =>
              new Date(b['Date Answered']) - new Date(a['Date Answered'])
          ),
        },
      ]

      let settings = {
        fileName: `Registrants for ${
          viewCourse.title
            ? viewCourse.title +
              ' ' +
              moment(viewCourse.date).format('MMMM Do YYYY')
            : `Training on ${moment(viewCourse.date).format(
                'MMMM Do YYYY, h:mm:ss a'
              )}`
        }`, // Name of the resulting spreadsheet
        extraLength: 4, // A bigger number means that columns will be wider
        // writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
        // writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
        // RTL: true, // Display the columns from right-to-left (the default value is false)
      }

      let afterDownload = function () {
        notification['success']({
          message: 'Success',
          description: 'Excel file downloaded.',
        })
      }

      xlsx(data, settings, afterDownload)
    } catch (err) {
      console.log(err)
    }
  }

  const tooltipText = <span>Delete Registrant</span>

  const handleOpenDeleteModal = (index) => {
    setOpenDeleteModal(true)
    setOpenDeleteModalIndex(index)
  }

  /* it return filter components */
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            className='d-flex align-items-center justify-content-center'
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: '',
      dataIndex: 'count',
      key: 'count',
      width: '5%',
      render: (text, record) => (
        <span>
          <b>{record.count}</b>
        </span>
      ),
    },
    {
      title: 'FULL NAME',
      dataIndex: 'fullName',
      key: 'name',
      width: '20%',
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      ...getColumnSearchProps('fullName'),
      render: (text, record) =>
        record.fullName ? (
          <span>
            <b className='text-capitalize'>{record.fullName.toLowerCase()}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'NICKNAME',
      dataIndex: 'nickname',
      key: 'nickname',
      width: '10%',
      render: (text, record) =>
        record.nickname ? (
          <span>
            <b className='text-capitalize'>{record.nickname.toLowerCase()}</b>
          </span>
        ) : (
          'no nickname'
        ),
    },
    {
      title: 'EMAIL ADDRESS',
      width: '13%',
      ...getColumnSearchProps('emailAddress'),
      render: (text, record) =>
        record.emailAddress ? (
          <span color='geekblue'>
            <b>{`${record.emailAddress}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE',
      width: '14%',
      ...getColumnSearchProps('dept_office_unit_taskForce'),
      render: (text, record) =>
        record.dept_office_unit_taskForce ? (
          <span>
            <b>{`${record.dept_office_unit_taskForce}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'DIVISION / SECTION',
      width: '9%',
      render: (text, record) =>
        record.division_section ? (
          <span>
            <b>{`${record.division_section}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'DESIGNATION/POSITION',
      width: '9%',
      render: (text, record) =>
        record.designation_position ? (
          <span>
            <b>{`${record.designation_position}`}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'Registered by an ADMIN?',
      width: '5%',
      render: (text, record) =>
        record?.isSeminar ? (
          <span>
            <b>{`${record?.isSeminar}`}</b>
          </span>
        ) : (
          'No'
        ),
    },
    {
      title: 'SUBMITTED ENDORSEMENT LETTER?',
      width: '5%',
      render: (text, record) => {
        try {
          return record.userID ? (
            <span>
              {record?.endorsementLetter?.length ? (
                <>
                  {Array.isArray(JSON.parse(record.endorsementLetter)) ? (
                    <>
                      {JSON.parse(record.endorsementLetter).map((obj) => {
                        let url = obj
                        if (isValidURL(url)) {
                          encodeURI(url)
                          url = url.replace(/#/g, '%23')
                        }
                        return (
                          <Row key={url}>
                            <a
                              href={url}
                              target='_blank'
                              rel='noreferrer noopener'
                            >
                              <b>Open here</b>
                            </a>
                          </Row>
                        )
                      })}
                    </>
                  ) : (
                    (() => {
                      let url = record.endorsementLetter
                      if (isValidURL(url)) {
                        url = encodeURI(url).replace(/#/g, '%23')
                      }
                      return (
                        <a href={url} target='_blank' rel='noreferrer noopener'>
                          <b>Open here</b>
                        </a>
                      )
                    })()
                  )}
                </>
              ) : (
                <b style={{ color: 'red' }}>NO</b>
              )}
            </span>
          ) : (
            'record missing'
          )
        } catch (err) {
          let url = record.endorsementLetter
          if (isValidURL(url)) {
            encodeURI(url)
            url = url.replace(/#/g, '%23')
          }

          return (
            <a href={url} target='_blank' rel='noreferrer noopener'>
              <b>Open here</b>
            </a>
          )
        }
      },
    },
    {
      title: 'ACTION',
      width: '5%',
      render: (text, record, index) => (
        <>
          <Tooltip placement='left' title={tooltipText}>
            <Button
              type='dashed'
              className={'d-flex align-items-center'}
              onClick={() => handleOpenDeleteModal(index)}
            >
              <DeleteTwoTone twoToneColor='red' />
            </Button>
          </Tooltip>

          <Suspense fallback={null}>
            {openDeleteModal && openDeleteModalIndex === index && (
              <DeleteRegistrant
                openDeleteModal={openDeleteModal}
                record={record}
                refresh={refresh}
                onClose={() => setOpenDeleteModal(false)}
              />
            )}
          </Suspense>
        </>
      ),
    },
    {
      title: 'ILDP',
      width: '10%',
      render: (text, record, index) => (
        <Row justify='center'>
          <Tooltip placement='top' title={'Open File'}>
            <a href='#' type='button' onClick={() => handleViewILDP(record)}>
              <Button
                size='medium'
                style={{ marginTop: '3px', marginBottom: '6px' }}
              >
                <FilePdfTwoTone />
              </Button>
            </a>
          </Tooltip>
        </Row>
      ),
    },
  ]

  let newColumns = columns.filter((obj) => obj.title !== 'FULL NAME')
  newColumns.splice(
    1,
    0,
    {
      title: 'FIRST NAME',
      dataIndex: 'first_name',
      key: 'first_name',
      width: '8%',
      sorter: (a, b) => a.first_name.localeCompare(b.first_name),
      ...getColumnSearchProps('first_name'),
      render: (text, record) =>
        record.first_name ? (
          <span>
            <b className='text-capitalize'>{record.first_name.toLowerCase()}</b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'MIDDLE INITIAL',
      dataIndex: 'middle_initial',
      key: 'middle_initial',
      width: '4%',
      sorter: (a, b) => a.middle_initial.localeCompare(b.middle_initial),
      ...getColumnSearchProps('middle_initial'),
      render: (text, record) =>
        record.middle_initial ? (
          <span>
            <b className='text-capitalize'>
              {record.middle_initial.toLowerCase()}
            </b>
          </span>
        ) : (
          'record missing'
        ),
    },
    {
      title: 'LAST NAME',
      dataIndex: 'last_name',
      key: 'last_name',
      width: '8%',
      sorter: (a, b) => a.last_name.localeCompare(b.last_name),
      ...getColumnSearchProps('fullName'),
      render: (text, record) =>
        record.last_name ? (
          <span>
            <b className='text-capitalize'>{record.last_name.toLowerCase()}</b>
          </span>
        ) : (
          'record missing'
        ),
    }
  )

  const handleViewILDP = async (record) => {
    try {
      const downloadILDPUploaded = await API.graphql(
        graphqlOperation(IDLPUploadByUserId, {
          userID: record.registeredUser.id,
        })
      )
      const filterToCurrentYear =
        downloadILDPUploaded.data.IDLPUploadByUserId.items.filter((obj) => {
          const checkDate = moment('2023-07-01')
          if (moment(obj.updatedAt).isSameOrAfter(checkDate)) {
            return obj
          }
        })

      if (filterToCurrentYear.length) {
        filterToCurrentYear.forEach((obj) => {
          window.open(obj.file, '_blank')
        })
      } else {
        notification['info']({
          message: 'Info',
          description:
            'Employee has not submitted an ILDP yet for this period.',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Button onClick={handleOpen}>View Registrants</Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={'Registrants'}
        centered
        visible={openTableModal}
        onOk={() => setOpenTableModal(false)}
        onCancel={() => {
          setOpenTableModal(false)
          setDisableAfter(false)
        }}
        width={'100%'}
        footer={null}
      >
        <Row>
          {noFullName ? (
            <Table
              bordered
              rowKey='id'
              dataSource={allRegistrants}
              columns={newColumns}
              showSizeChanger={false}
              pagination={{ pageSize: 50 }}
              className='w-100 table-responsive'
            />
          ) : (
            <Table
              bordered
              rowKey='id'
              dataSource={allRegistrants}
              columns={columns}
              showSizeChanger={false}
              pagination={{ pageSize: 50 }}
              className='w-100 table-responsive'
            />
          )}
        </Row>
        <Row>
          {allRegistrants.length ? (
            <Button
              disabled={handleDisable()}
              onClick={handleDownload}
              type='primary'
              icon={<DownloadOutlined />}
              size={'large'}
            >
              Download
            </Button>
          ) : null}
        </Row>
      </Modal>
    </>
  )
}

RegisteredUsersByEvents.propTypes = {
  viewCourse: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    date: PropTypes.string,
    questionsAssessment: PropTypes.object,
  }),
}
