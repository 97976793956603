/**
 * Title: preAssessmentCustom.js
 * Description: This is a file that contains the components for creating a custom pre-assessment
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/preAssessmentCustom.js
 *
 *Changes made:
 * - 2024.14.08  | Harry Lagunsad | Add props validation.
 * - 2024.08.09  | Raymart Mojado | Fix sonarlint issues
 **/

/*
 *Changes made:
 *2024.08.09  | Raymart Mojado | show image on the multipleChoice if have image
 */

import React, { useEffect, useState } from 'react'
import { Row } from 'antd'
import { toRoman } from '../../utilities/toRoman'
import { Storage } from 'aws-amplify'
import { numToString } from '../../utilities/numToString'
import TextArea from 'antd/lib/input/TextArea'

import PropTypes from 'prop-types'

const PreAssessmentCustom = ({
  trainingItem,
  assessmentQuestions,
  assessmentQuestionsTwo,
  assessmentQuestionsThree,
  assessmentQuestionsFour,
  setAssessQuestionsSet,
  setAssessQuestionsSetTwo,
  setAssessQuestionsSetThree,
  setAssessQuestionsSetFour,
  identifier,
}) => {
  const [images, setImages] = useState({})
  useEffect(() => {
    assessmentQuestions.forEach((question, index) => {
      if (question.imageUploadQuestion) {
        Storage.get(question.imageUploadQuestion)
          .then((data) => {
            if (data) {
              setImages((prevImages) => ({
                ...prevImages,
                [index]: data,
              }))
            }
          })
          .catch((error) => {
            console.error(
              `Error retrieving image data for question ${index}:`,
              error
            )
          })
      }
    })
  }, [assessmentQuestions])
  const handleSetAnsAssessQuestion = (e) => {
    setAssessQuestionsSet((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSetAnsAssessQuestionTwo = (e) => {
    setAssessQuestionsSetTwo((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSetAnsAssessQuestionThree = (e) => {
    setAssessQuestionsSetThree((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleSetAnsAssessQuestionFour = (e) => {
    setAssessQuestionsSetFour((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  let showAssessmentQuestions,
    showAssessmentQuestionsTwo,
    showAssessmentQuestionsThree,
    showAssessmentQuestionsFour

  if (assessmentQuestions) {
    showAssessmentQuestions = assessmentQuestions.map((elem, index) => {
      const numString = numToString(index)
      return (
        <React.Fragment key={index}>
          {trainingItem.assessmentID ===
            '301d4b3c-4fbb-4636-9bec-0461ef7b7122' && index === 0 ? (
            <>
              <Row>
                <span>
                  The following are pre-assessment questions for the "Learning
                  Intermediate Excel" training program to be held on July 23,
                  2024. This test is composed of 10 questions and is designed to
                  measure your knowledge of Excel and to assess your current
                  proficiency level (Basic, Intermediate, Advanced, Expert).
                </span>
              </Row>
            </>
          ) : null}

          <Row className='mt-4'>
            <span style={{ fontSize: 16, textAlign: 'left' }}>
              <strong>
                {index + 1}. {elem.question}
              </strong>
            </span>
          </Row>
          {trainingItem.assessmentID ===
            '301d4b3c-4fbb-4636-9bec-0461ef7b7122' && index === 9 ? (
            <div className='mt-3 mb-3'>
              <img
                src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/excel+image.png'
                alt='contact admin if image is not showing'
              />
            </div>
          ) : null}

          <Row
            align='center'
            justify='center'
            className='flex-column ms-3 mt-2'
          >
            {images[index] ? (
              <img
                src={images[index]}
                alt={`Uploaded for question ${index}`}
                style={{
                  maxWidth: '450px',
                  maxHeight: '450px',
                }}
              />
            ) : null}
          </Row>
          <Row
            align='center'
            justify='center'
            className='flex-column ms-3 mt-2'
          >
            {elem.choices[0] &&
              elem.choices[1] &&
              elem.choices[2] &&
              elem.choices[3] && (
                <>
                  {elem.choices[0][`choices-1-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div
                      className='d-flex p-1'
                      hidden={
                        elem.choices[0][
                          `choices-1-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input
                        onChange={(e) => handleSetAnsAssessQuestion(e)}
                        value='A'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-A`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-A`}
                      >
                        <strong>
                          <em>A. </em>
                        </strong>
                        {elem.choices[0][`choices-1-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[1][`choices-2-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestion(e)}
                        value='B'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-B`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-B`}
                      >
                        <strong>
                          <em>B. </em>
                        </strong>
                        {elem.choices[1][`choices-2-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[2][`choices-3-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestion(e)}
                        value='C'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-C`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-C`}
                      >
                        <strong>
                          <em>C. </em>
                        </strong>
                        {elem.choices[2][`choices-3-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[3][`choices-4-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestion(e)}
                        value='D'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-D`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-D`}
                      >
                        <strong>
                          <em>D. </em>
                        </strong>
                        {elem.choices[3][`choices-4-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  <TextArea
                    hidden={elem.correctAnswer !== 'n/a'}
                    onChange={(e) => handleSetAnsAssessQuestion(e)}
                    name={'question' + numString}
                    className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                  />
                </>
              )}
          </Row>
        </React.Fragment>
      )
    })
  }
  if (assessmentQuestionsTwo) {
    showAssessmentQuestionsTwo = assessmentQuestionsTwo.map((elem, index) => {
      const numString = numToString(index)
      return (
        <React.Fragment key={index}>
          <Row className='mt-4'>
            <span style={{ fontSize: 16, textAlign: 'left' }}>
              <strong>
                {index + 1}. {elem.question}
              </strong>
            </span>
          </Row>
          <Row
            align='center'
            justify='center'
            className='flex-column ms-3 mt-2'
          >
            {elem.choices[0] &&
              elem.choices[1] &&
              elem.choices[2] &&
              elem.choices[3] && (
                <>
                  {elem.choices[0][`choices-1-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div
                      className='d-flex p-1'
                      hidden={
                        elem.choices[0][
                          `choices-1-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input
                        onChange={(e) => handleSetAnsAssessQuestionTwo(e)}
                        value='A'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-A`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-A`}
                      >
                        <strong>
                          <em>A. </em>
                        </strong>
                        {elem.choices[0][`choices-1-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[1][`choices-2-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestionTwo(e)}
                        value='B'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-B`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-B`}
                      >
                        <strong>
                          <em>B. </em>
                        </strong>
                        {elem.choices[1][`choices-2-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[2][`choices-3-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestionTwo(e)}
                        value='C'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-C`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-C`}
                      >
                        <strong>
                          <em>C. </em>
                        </strong>
                        {elem.choices[2][`choices-3-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[3][`choices-4-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestionTwo(e)}
                        value='D'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-D`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-D`}
                      >
                        <strong>
                          <em>D. </em>
                        </strong>
                        {elem.choices[3][`choices-4-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  <TextArea
                    hidden={elem.correctAnswer !== 'n/a'}
                    onChange={(e) => handleSetAnsAssessQuestionTwo(e)}
                    name={'question' + numString}
                    className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                  />
                </>
              )}
          </Row>
        </React.Fragment>
      )
    })
  }
  if (assessmentQuestionsThree) {
    showAssessmentQuestionsThree = assessmentQuestionsThree.map(
      (elem, index) => {
        const numString = numToString(index)
        return (
          <React.Fragment key={index}>
            <Row className='mt-4'>
              <span style={{ fontSize: 16, textAlign: 'left' }}>
                <strong>
                  {index + 1}. {elem.question}
                </strong>
              </span>
            </Row>
            <Row
              align='center'
              justify='center'
              className='flex-column ms-3 mt-2'
            >
              {elem.choices[0] &&
                elem.choices[1] &&
                elem.choices[2] &&
                elem.choices[3] && (
                  <>
                    {elem.choices[0][`choices-1-${index + 1}`].toLowerCase() !==
                      'n/a' && (
                      <div
                        className='d-flex p-1'
                        hidden={
                          elem.choices[0][
                            `choices-1-${index + 1}`
                          ].toLowerCase() === 'n/a'
                        }
                      >
                        <input
                          onChange={(e) => handleSetAnsAssessQuestionThree(e)}
                          value='A'
                          name={'question' + numString}
                          className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                          type='radio'
                          id={`question${numString}-choice-A`}
                        />
                        <label
                          className='m-0 ms-3 text-start'
                          htmlFor={`question${numString}-choice-A`}
                        >
                          <strong>
                            <em>A. </em>
                          </strong>
                          {elem.choices[0][`choices-1-${index + 1}`]}
                        </label>
                      </div>
                    )}
                    {elem.choices[1][`choices-2-${index + 1}`].toLowerCase() !==
                      'n/a' && (
                      <div className='d-flex p-1'>
                        <input
                          onChange={(e) => handleSetAnsAssessQuestionThree(e)}
                          value='B'
                          name={'question' + numString}
                          className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                          type='radio'
                          id={`question${numString}-choice-B`}
                        />
                        <label
                          className='m-0 ms-3 text-start'
                          htmlFor={`question${numString}-choice-B`}
                        >
                          <strong>
                            <em>B. </em>
                          </strong>
                          {elem.choices[1][`choices-2-${index + 1}`]}
                        </label>
                      </div>
                    )}
                    {elem.choices[2][`choices-3-${index + 1}`].toLowerCase() !==
                      'n/a' && (
                      <div className='d-flex p-1'>
                        <input
                          onChange={(e) => handleSetAnsAssessQuestionThree(e)}
                          value='C'
                          name={'question' + numString}
                          className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                          type='radio'
                          id={`question${numString}-choice-C`}
                        />
                        <label
                          className='m-0 ms-3 text-start'
                          htmlFor={`question${numString}-choice-C`}
                        >
                          <strong>
                            <em>C. </em>
                          </strong>
                          {elem.choices[2][`choices-3-${index + 1}`]}
                        </label>
                      </div>
                    )}
                    {elem.choices[3][`choices-4-${index + 1}`].toLowerCase() !==
                      'n/a' && (
                      <div className='d-flex p-1'>
                        <input
                          onChange={(e) => handleSetAnsAssessQuestionThree(e)}
                          value='D'
                          name={'question' + numString}
                          className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                          type='radio'
                          id={`question${numString}-choice-D`}
                        />
                        <label
                          className='m-0 ms-3 text-start'
                          htmlFor={`question${numString}-choice-D`}
                        >
                          <strong>
                            <em>D. </em>
                          </strong>
                          {elem.choices[3][`choices-4-${index + 1}`]}
                        </label>
                      </div>
                    )}
                    <TextArea
                      hidden={elem.correctAnswer !== 'n/a'}
                      onChange={(e) => handleSetAnsAssessQuestionThree(e)}
                      name={'question' + numString}
                      className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                    />
                  </>
                )}
            </Row>
          </React.Fragment>
        )
      }
    )
  }
  if (assessmentQuestionsFour) {
    showAssessmentQuestionsFour = assessmentQuestionsFour.map((elem, index) => {
      const numString = numToString(index)
      return (
        <React.Fragment key={index}>
          <Row className='mt-4'>
            <span style={{ fontSize: 16, textAlign: 'left' }}>
              <strong>
                {index + 1}. {elem.question}
              </strong>
            </span>
          </Row>
          <Row
            align='center'
            justify='center'
            className='flex-column ms-3 mt-2'
          >
            {elem.choices[0] &&
              elem.choices[1] &&
              elem.choices[2] &&
              elem.choices[3] && (
                <>
                  {elem.choices[0][`choices-1-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div
                      className='d-flex p-1'
                      hidden={
                        elem.choices[0][
                          `choices-1-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input
                        onChange={(e) => handleSetAnsAssessQuestionFour(e)}
                        value='A'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-A`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-A`}
                      >
                        <strong>
                          <em>A. </em>
                        </strong>
                        {elem.choices[0][`choices-1-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[1][`choices-2-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestionFour(e)}
                        value='B'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-B`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-B`}
                      >
                        <strong>
                          <em>B. </em>
                        </strong>
                        {elem.choices[1][`choices-2-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[2][`choices-3-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestionFour(e)}
                        value='C'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-C`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-C`}
                      >
                        <strong>
                          <em>C. </em>
                        </strong>
                        {elem.choices[2][`choices-3-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[3][`choices-4-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestionFour(e)}
                        value='D'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-D`}
                      />
                      <label
                        className='m-0 ms-3 text-start'
                        htmlFor={`question${numString}-choice-D`}
                      >
                        <strong>
                          <em>D. </em>
                        </strong>
                        {elem.choices[3][`choices-4-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  <TextArea
                    hidden={elem.correctAnswer !== 'n/a'}
                    onChange={(e) => handleSetAnsAssessQuestionFour(e)}
                    name={'question' + numString}
                    className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                  />
                </>
              )}
          </Row>
        </React.Fragment>
      )
    })
  }

  return (
    <>
      {identifier == 3
        ? showAssessmentQuestionsFour
        : identifier == 2
        ? showAssessmentQuestionsThree
        : identifier == 1
        ? showAssessmentQuestionsTwo
        : showAssessmentQuestions}
    </>
  )
}

PreAssessmentCustom.propTypes = {
  trainingItem: PropTypes.shape({
    assessmentID: PropTypes.string,
  }),
  assessmentQuestions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      choices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
      imageUploadQuestion: PropTypes.string,
    })
  ),
  assessmentQuestionsTwo: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      choices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    })
  ),
  assessmentQuestionsThree: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      choices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    })
  ),
  assessmentQuestionsFour: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      choices: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string)),
    })
  ),
  setAssessQuestionsSet: PropTypes.func,
  setAssessQuestionsSetTwo: PropTypes.func,
  setAssessQuestionsSetThree: PropTypes.func,
  setAssessQuestionsSetFour: PropTypes.func,
  identifier: PropTypes.number,
}

export default PreAssessmentCustom
