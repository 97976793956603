/**
 * Title: pendingUser.js
 * Description: This is a file that contains the components for the pending user
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Users/pendingUser.js
 **/

import {
  Button,
  Table,
  Modal,
  Popconfirm,
  Input,
  Tag,
  notification,
  Breadcrumb,
} from 'antd'
import React from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import { listUsers } from '../../api/queries'
import { updateUser } from '../../api/mutations'
import ViewUser from './viewUser'

export default class Users extends React.Component {
  constructor() {
    super()
    this.state = {
      data: null,
      open: false,
      selected: [],
      name: '',
      disabled: false,
      docs: [],
      openEdit: false,
    }
  }

  componentDidMount() {
    this.listUser()

    this.setState({ size: window.innerWidth })

    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions = () => {
    this.setState({ size: window.innerWidth })
  }

  listUser = async () => {
    let arr = []
    API.graphql(
      graphqlOperation(listUsers, {
        filter: {
          status: { contains: 'Pending' },
          and: { role: { notContains: 'admin' } },
        },
      })
    )
      .then((res) => {
        let filter = res.data.listUsers.items

        if (filter.length) {
          filter.forEach((item, i) => {
            arr.push({ ...item, key: i + 1 })
          })
        }
      })

      .then(() => {
        this.setState({ data: arr })
      })
  }

  handleOk = async (e) => {
    this.setState({ disabled: true })
    API.graphql(
      graphqlOperation(updateUser, {
        input: {
          id: e.id,
          status: 'Approved',
        },
      })
    ).then((res) => {
      notification['success']({
        message: 'Success',
        description: 'You just approved the user.',
      })
      this.setState({ disabled: false })
      this.listUser()
    })
  }

  render() {
    const columns = [
      {
        title: 'No.',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <span>
            <b>{`${record.name} ${record.mname} ${record.lname}`}</b>
          </span>
        ),
      },
      {
        title: 'Home Department',
        dataIndex: 'department',
        key: 'department',
        render: (text, record) => (
          <Tag color='geekblue'>{record.department}</Tag>
        ),
      },
      {
        title: 'Assigned Department',
        dataIndex: 'assignedDept',
        key: 'assignedDept',
        render: (text, record) => (
          <Tag color='geekblue'>{record.assignedDept}</Tag>
        ),
      },
      {
        title: 'Division',
        dataIndex: 'division',
        key: 'division',
        render: (text, record) => <Tag color='geekblue'>{record.division}</Tag>,
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (text, record) => (
          <span>
            <b style={{ textTransform: 'capitalize' }}>{`${record.role}`}</b>
          </span>
        ),
      },

      {
        title: 'Date created',
        dataIndex: 'date',
        key: 'locale',
      },

      {
        title: 'Action',
        key: 'action',
        render: (text, record) => (
          <span>
            <ViewUser data={record} />
            <Popconfirm
              title={
                <div>
                  By approving this account you were giving them access to
                  system. <div>Are you sure to continue?</div>
                </div>
              }
              onConfirm={(e) => this.handleOk(record)}
              placement='top'
              okText='Yes'
              cancelText='No'
            >
              <Button
                size='small'
                type='primary'
                style={{ marginRight: '10px' }}
                loading={this.state.disabled}
              >
                Approve
              </Button>
            </Popconfirm>
          </span>
        ),
      },
    ]

    return (
      <>
        <Breadcrumb>
          <Breadcrumb.Item>Home</Breadcrumb.Item>
          <Breadcrumb.Item>Pending user</Breadcrumb.Item>
        </Breadcrumb>
        <div className='compTable' style={{ marginTop: '30px' }}>
          <Table
            columns={columns}
            dataSource={this.state.data}
            pagination={false}
            loading={this.state.data === null}
            size={this.state.size <= 768 ? 'small' : 'middle'}
          />
        </div>

        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title={`Edit ${this.state.selected.name}`}
          visible={this.state.openEdit}
          onCancel={() => this.setState({ openEdit: false })}
          footer={[
            <Button
              onClick={() => this.setState({ openEdit: false })}
              type='danger'
              ghost
              key='danger'
            >
              Cancel
            </Button>,
            <Button
              loading={this.state.disabled}
              onClick={this.handleEdit}
              type='primary'
              key='primary'
            >
              Submit
            </Button>,
          ]}
        >
          <Input
            defaultValue={this.state.selected ? this.state.selected.name : ''}
            onChange={(e) => this.setState({ compName: e.target.value })}
          />
        </Modal>
      </>
    )
  }
}
