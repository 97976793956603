/**
 * Title: CSCOnlineTrainingJan21.js
 * Description: This is a file that contains the CSC Online Training for January 21, 2023
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/CSCOnlineTrainingJan21.js
 **/

import { useState, useEffect } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  ActivityOneAnswerByUserID,
  ActivityThreeAnswerByUserID,
  ActivityTwoAnswerByUserID,
  listTrainingVideos,
} from '../../api/queries.js'

import moment from 'moment'

import { PlayCircleFilled } from '@ant-design/icons'
import { Button, Modal, Input, Select } from 'antd'

import VideoPlayer from './videoPlayer.js'

import './strategicPerformanceManagementSystem.css'

import listOfDepartments from '../../utilities/dept.js'
import ActivityQuestionModalOneJan21 from './Jan21OnlineTraining/activityQuestionModalOneJan21.js'
import ActivityQuestionModalTwoJan21 from './Jan21OnlineTraining/activityQuestionModalTwoJan21.js'
import ActivityQuestionModalThreeJan21 from './Jan21OnlineTraining/activityQuestionModalThreeJan21.js'
import Jan21CorrectAnswerActivity1 from './Jan21OnlineTraining/Jan21CorrectAnswerActivity1.js'

const { Option } = Select

export default function CSCOnlineTrainingJan21({ userData }) {
  const user = userData
  const userInfo = userData
  const userFullName =
    userInfo?.name + ' ' + userInfo?.mname + ' ' + userInfo?.lname

  const [name, setName] = useState(userFullName)
  const [department, setDepartment] = useState(userInfo?.assignedDept)
  const [uploadedTrainingVideo, setUploadedTrainingVideo] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [videoKey, setVideoKey] = useState('')
  const [isAgree, setIsAgree] = useState(true)
  const [videoFinishWatching, setvideoFinishWatching] = useState([])
  const [isModalActivityOpen, setIsModalActivityOpen] = useState(false)
  const [progressActivity, setProgressActivity] = useState('1')
  const [isWatchedVideoOne, setIsWatchedVideoOne] = useState(false)
  const [isWatchedVideoTwo, setIsWatchedVideoTwo] = useState(false)
  const [isWatchedVideoFour, setIsWatchedVideoFour] = useState(false)
  const [transcriptActivity, setTranscriptActivity] = useState(
    'transcriptActivity1'
  )
  const [isInstructionModalVisible, setIsInstructionModalVisible] =
    useState(true)
  const [isCompleteMsgModalVisible, setIsCompleteMsgModalVisible] =
    useState(false)
  const [showCorrectAnswerModal, setShowCorrectAnswerModal] = useState(false)
  const [correctAnswer, setCorrectAnswer] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const getActivityOneAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityOneAnswerByUserID, { userID: user.id })
      )

      const itemsFetched = dataFetched.data.ActivityOneAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            '57f1487c-6a46-4d74-85dc-35f68798c28b'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('2')
        } else {
          setIsWatchedVideoOne(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityTwoAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityTwoAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityTwoAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            '57f1487c-6a46-4d74-85dc-35f68798c28b'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('3')
        } else {
          setIsWatchedVideoTwo(true)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityThreeAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityThreeAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityThreeAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            '57f1487c-6a46-4d74-85dc-35f68798c28b'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setProgressActivity('alldone')
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (progressActivity == 1) {
      setTranscriptActivity(transcriptActivity1)
    }
    if (progressActivity == 2) {
      setTranscriptActivity(transcriptActivity2)
    }
    if (progressActivity == 3) {
      setTranscriptActivity(transcriptActivity3)
    }
  }, [progressActivity])

  const fetchAllUserActivityProgress = async () => {
    setIsLoading(true)
    await getActivityOneAnswerByUserID()
    await getActivityTwoAnswerByUserID()
    await getActivityThreeAnswerByUserID()
    setIsLoading(false)
  }

  useEffect(() => {
    fetchAllUserActivityProgress()
  }, [])

  const englishVideos = [
    'Module 1 - Final',
    'Module 2 - Final',
    'Module 3 - Final',
  ]

  const dept = listOfDepartments()

  const transcriptActivity1 = () => {
    return (
      <>
        <p>
          Good morning, everyone. Mabuhay. I'm Azer Villola. I'm currently a
          highly technical consultant from the office of the city administrator
          and welcome to our session today. We will learn about complete staff
          work through this session, enhancing efficiency through complete staff
          work. So welcome again.
        </p>
        <p>
          And before we go into the session details, I hope everyone is ready.
          Balikan lang po natin, what was sent as a memorandum circular a couple
          of months ago. It was sent to all city departments offices
          implementing units and task force. This is a memo from the office of
          the city mayor with the subject of ensuring efficiency of the
          processing of documents requiring recommendations, assurances,
          authorizations and other approvals through the implementation by
          standard complete staff work or CSW.
        </p>
        <p>
          So this session is very relevant. This session is very important. This
          session is very timely. So I hope and pray that we, lahat po tayo;
          we'll work together and really strive to improve our documentation via
          standard CSW.
        </p>
        <p>
          Okay. Let's proceed. Some background there, you know, the scenario at
          the Office of the City Administrator. So our city government, more or
          less , meron po tayong 70 departments and approximately two divisions
          per department. Let's say 140 offices including divisions. If 60% of
          these offices seek approval per week. There will be about 84 documents
          for approval per week or 16 to 17 documents per day. Any document we
          sign, we are accountable. So that means this much volume of
          transactions, of volume of documents are reviewed, checked, approved,
          or disapproved each day. Sa office po ng city administrator. So you
          can just imagine how much documentation that everyone sends. And
          reviews and reviewed by the Office of the City Admin. This is just a
          brief backgrounder, k?
        </p>
        <p>
          So definition, ano ba ang CSW? What is CSW? So, Completed staff work
          is a situation where all pertinent information and documents as basis
          for decision making para po ito sa decision making, ah; and
          implementation of decisions are made available to the decision-maker.
          So ano po ‘to; iba-iba, there are memos, orders, circulars, project
          proposals, terms of preference, contracts, diba?; memorandum of
          agreements or memorandum of understanding.{' '}
        </p>
        <p>
          What is the objective of a CSW? Yan po, Number one, decisiveness in
          decision making. Number two, effective management of the issue or
          situation at hand. Number three, responsiveness to the public,
          clients, or key players affected by the issue or participants in the
          activity. In general, a CSW or completed staff work is designed to
          facilitate decision-making that is timely. Decision is immediately
          made and implemented as soon as the problem area or issue is
          identified and analyzed. Second, importante po sa decision maker that
          he or she is well informed. The decision is based on all available
          information, that is one, accurate and it is processed and evaluated
          and coordinated. So essentially the decisions in this context
          encompass all areas whether policy, program, projects or
          administrative decisions. It may be stressed that the important result
          of a CSW is that a decision is immediately made and not whether a
          decision is made for or against the proponents. Napaka importante po
          nito, sa atin ah; transactions sa ating ah; processes because if we
          don't give the right information, if we don't give the relevant
          information, if our information is incomplete, diba po? Mahahamper po,
          madedelay ang decision, diba po? Hindi po effectively mamanage ang
          situation or scenario and hindi kaagad makakapag respond kung sino man
          po ang tao responsible. or accountable person and office or department
          to address a certain or a specific situation.
        </p>
        <p>So dyan po very important ang CSW.</p>
      </>
    )
  }

  const transcriptActivity2 = () => {
    return (
      <>
        <p>
          What are the parameters of a completed staff work? So meron po siyang,
          iba’t ibang parameters, diba po? Katangian diba? Number one our CSW
          should be well researched. Data must be accurate and updated. All
          information should be well researched, validated. Napaka importante po
          that all the information are validated and up to date, especially
          whatever information or statistics that we give. The issue should be
          clearly defined and its effect are specified. So kailangan po talaga
          that the one preparing the CSW will do his or her own research. When
          you have the information already or the data on hand, it should be
          properly coordinated and validated. The positions of all entities
          concerned, especially when there are varying positions to an issue,
          must be considered and reported. This requires appropriate lateral and
          vertical coordination or consultation with all the concerned agencies
          or private organizations, client, public or kung saan po ang ah;
          concerned offices or departments.
        </p>
        <p>
          So number one, we have the right data, updated and complete data
          already. When we have it, when we do our own validation, dapat po ay
          ikinocoordinate kung tama up po ito ; or up to date diba, po? in
          proper offices, departments or concerned agencies. Then once it is
          well researched and properly coordinated and validated, we must be
          able to analyze the information on hand. Analysis must be
          comprehensive. All angles to an issue must be reviewed and reported in
          terms of its effects on existing programs and policies, as well as on
          the players concerned.
        </p>
        <p>
          So this is very important po. So well-researched, properly coordinated
          and validated yung information on hand, and there should be some
          analysis involved po.
        </p>
        <p>
          Okay, next. Once we have all of those information and analysis. We
          should be able to provide options and recommendations. The options and
          alternatives to resolve the issue should be provided on continuous
          issues or when agreements concurrence among affected entities cannot
          be generated. A recommended decision should always be provided. On
          continuous issues, a recommended action among the options all
          alternatives should be highlighted. The impact of the options and
          recommended decisions should be identified,diba po? So napaka
          importante po ito.
        </p>
        <p>
          So that's why the first three parameters of CSW are the information
          being well researched, properly coordinated and validated, and
          analyzed extensively. Napaka importante po niyan, for us to be able to
          provide a sound, updated, and timely recommendation. And we should
          also be providing proper action documents to implement decisions. For
          example, if there is already a recommended course of action, the
          proposed action to implement the recommendation must be provided,
          including the preparation of the corresponding action papers to be
          issued. For example po, meron tayong as a result of our
          recommendation, there is a memo, there is a notice that needs to be
          cascaded and shared to the concerned departments or offices. Aside
          from the communicating instrument or memo, the following documents are
          necessary para po maaprubahan rin nung kailangan magdecide, diba po?
          Action papers, directives, issuances, and orders to rationalized the
          decision. Remember the complete staff work, there is an action
          required diba po? and the action required is via a complete
          documentation also. It's via an updated memorandum also or an office
          order. So other relevant documents, copy of the directive which
          triggered the submission of the paper, other legal issuances and
          positions of the proponents relative to the issue.
        </p>
        <p>
          So kung meron tayong action item, kailangan po isama na natin sa CSW
          because that is where after the decision maker comes up or approves
          something, andun na rin po action document natin kung ito naman po ay
          susunod na mag-uutos na departamento or office or magbibigay po ng
          recommendation to do something. Otherwise, these are all just
          paperwork, walang magiging action.
        </p>
        <p>
          Next, communication is simple and well-written, diba po? The
          instrument of the memorandum must be easy to read and understand with
          minimal technical jargon, lalo na po sa mga IT, sa mga Engineer.
        </p>
        <p>
          Remember that not all decision makers are technical, not all are
          engineers. Let's not make the CSW too technical and flowery to the
          point that na mas mahirap siyang maintindihan. It must be very simple
          lang. It must be comprehensive, brief, concise, and direct to the
          point. without sacrificing vital information. Remember, as an example,
          sa backgrounder kanina sa initial slides, I gave an example for the
          office of the city admin. There's a lot of documents, there's a lot of
          requests which they need to review, approve or disapprove. So dapat
          direct to the point po ang ating wording.
        </p>
        <p>
          For long papers, there must be an accompanying executive summary of
          one to two pages. Isummarize na po natin according to key points.
          Ideas must be chronologically or logically sequenced. Let's strive to
          make use of very simple language and sentence structure. We must be
          devoid of grammatical and typographical errors. We must use and
          include correct action papers. Mahirap po kasi magbasa if the
          documentation has a lot of grammatical and typographical errors.
        </p>
        <p>
          So, sa mga word or other software that could check grammar and typo
          errors. Padaanin po natin dun para po yung magbabasa, magrereview at
          mag-aapprove ng ating CSW hindi po masyadong mahirapan at maging mas
          madali.
        </p>
        <p>
          Remember the decision will be timely at magkaron po tayo ng action
          based on our proposal or document that we submitted. The completed
          staff work does not end with the submission of recommendations and
          action of the decision maker. The concerned entities must also,
          especially when po approved or even disapproved, monitor and
          facilitate implementation of the decision and directives. Again, it
          doesn't stop on paperwork lalo na po pag approved. diba po?
        </p>
        <p>
          What we need to do is to operationalized whatever approved title or
          proposal the be sent. We must report to the decision maker the full
          substantial compliance and implementation of decisions. So again the
          CSW is just a start when the proposal or the memo gets approved. More
          importantly, kailangan po kung sino yung nag submit, ma monitor ,
          maimplement , maexecute yung laman ng CSW.
        </p>
        <p>
          Okay? Next po, the final test of completed staff work. When you have
          finished your CSW, pag nakagawa na po tayo documentation of CSW for
          submission, the final test or question is this. If you were the
          decision maker, kung ako po ang tatanggap or kayo ang tatanggap ng
          CSW, would you be willing to sign the paper you have prepared and
          stake your professional reputation on its being right? Kung tama ba po
          siya, kumpleto ba siya, relevant, updated, diba po?
        </p>
        <p>
          Willing ba 'ko? Kaya ko bang i-type yung aking professional
          reputation, 'pag yes, pag tama po at oo ang sagot natin, that's the
          time to be submitted. If your answer is negative, take it back, work
          it over. Because it's not yet a completed staff work, diba po?
        </p>
        <p>
          And operative work dito is complete. Kapag di kumpleto, kulang at
          hindi updated ang information. Kulang ang coordination sa concerned
          offices, diba po? Ibig sabihin hindi kumpleto. And the decision maker
          will not be able or will not have the right information para po maging
          tama ang decision niya. Okay? So ganyan po ang ating CSW. And the CSW
          is not just being practiced here in QCG,diba po? This is a actual,
          This document is being practiced and implemented wherever, whichever
          part po ng gobyerno natin, whether it be a national agency, an office
          or an LGU.
        </p>
        <p>
          What are the parameters of a completed staff work? diba po? Anong
          parameters po niyan? Number one, anong parts? Yan po; And later I'll
          show you an example of an examplevof CSW, gawa po ng IT department, ng
          ITDD for reference.
        </p>
        <p>
          Number one, there should be reference,diba po? Let's cite the
          directive, issue, letters that needs to be addressed. or the rational
          instructions relating to the issue on hand. Kasi po input to ng ating
          backgrounder, diba? We should be providing a brief backgrounder of
          antecedent factors relevant to issue or chronological narration of
          events, yung pagkakasunud-sunod which lead to the present situation
          and development,diba po? napakaimportante lalo na if the background is
          referencing to something, referencing to another memorandum, to an
          office order. The background also, lagay po natin we should be
          identifying the key players and their positions, if applicable. We
          should state directly the issue, diba po? We should define the
          parameters of the issue. We should have included in the background,
          the status of actions taken if applicable or meron or the most recent
          developments.
        </p>
        <p>
          Again, remember the scenario po sa office of the city admin. Let's not
          assume that everything, diba po? whoever will be reviewing that
          document, whether it be a legal person, an attorney, an assistant city
          admin, an administrative person or the city administrator itself do
          not assume that E is updated with the latest details of whatever we
          want to discuss, okay?
        </p>
        <p>
          Which goes into the number three, pagka tama na po ang ating
          references and background, let's put in the details, the discussion
          points and comments, diba po? Let's put there our analysis, what we
          have discussed earlier. The analysis of the information, the impact of
          the issue? Are there frameworks, priorities, policies, and laws which
          need to be considered? And what are the other options available?
          Because the discussion point, diba po? napakahalaga for us, for you,
          to create an action requested or recommendation,diba po?
        </p>
        <p>
          Yung recommendation natin will be based on what you have discussed.
          And what you have discussed is actually based on the backgrounder or
          reference documents. So napaka halaga po niyan, hindi po tayo derecho
          pupunta dun sa recommendation without us stating clearly, diba po?
        </p>
        <p>
          Ano po ang pinanggagalingan ng ating decision, diba? So based on all
          the comments made and all the options available, what is the sound
          recommendation? or suggested course of action to be taken. Ano po siya
          diba? Again, what are the measures and steps to operationalize a
          decision or recommended action? Lalagay po natin jan that what needs
          to be done, by who needs to do it, diba?; sinong gagawa, when it needs
          to be done. Lahat po yan.
        </p>
        <p>
          Yung pang lima po, attachments if there are other documents or
          information which would help operationalize the action item, diba po;
          such as memos, letters, directives, and orders of operationalization
          of decision. Let's attach it, let's include it, including all other
          pertinent documents. Ganyan po ka extensive ang completed staff work.
          Remember, babalik po tayo if you`ll allow me to go into the previous
          slide…
        </p>
        <p>
          All of these po are important because babalik po tayo dito, if you
          were the decision maker, if we will put our signature there on the
          document, if we will approve something even if it is as simple as a
          training session or a proposed simple training session of your
          department, are you willing to stake your professional reputation on
          the document being right? Okay, napakaimportante po niyan. Again, ayan
          po ang parameters of our completed staff work.{' '}
        </p>
      </>
    )
  }

  const transcriptActivity3 = () => {
    return (
      <>
        <p>
          This sample is a memo CSW addressed to Mr. Michael Alimurung, our city
          administrator from a, let's say from the IT department for a proposed
          information system strategic planning seminar for August or let's say
          for a future date.
        </p>
        <p>
          So first introduction, lagay po natin diba; the blank department, In
          extreme effort to fulfill its mandate and to effectively and
          efficiently contribute to the accentuated initiatives of the city
          government towards the attainment of the 14-point agenda of the city
          mayor, respectfully request for the conduct of an ISSP seminar.
        </p>
        <p>
          So nandito po. Let's give all the introduction diba; even if it aligns
          with the 14-point agenda, why we need an information systems planning,
          Because if it is a requirement from the DICT or from a national
          agency, ilagay po natin an introduction. Then let's put it in the
          background,diba..
        </p>
        <p>
          Prior to the assumption, this is an example, prior to the assumption
          of duties and responsibilities of the undersigned as head of whatever
          department or office, there have been attempts to undertake an ISSP
          seminar and workshop. But the pandemic got in the way and focus has
          been redirected on the immediate task and supporting the city
          government and its pandemic related efforts, diba po..
        </p>
        <p>
          Moreover, the department has no budget allocation for training. So ito
          po bibigyan natin ang backgrounders specific na for your office, diba?
          Ah, in relation to the proposed ISSP planning training or activity. So
          dito si-nite and mandate, diba? And ensuring the mandates are carried
          out efficiently, diba po? Ah nilagay yung details on the background,
          okay..
        </p>
        <p>
          And there are discussion points. The discussion points actually is
          what you want to really discuss in detail after you have given some of
          the reference and background there. So dito ano ba gusto natin
          mangyari? In relation to the backgrounder of the reference, diba po?
          So dito po ang gusto natin ma- achieve,diba, the proposed ISSP
          framework. Lagyan natin ng detalye and the department also intends to
          strengthen its effort on some of the areas identified. Then based on
          the right and complete reference, background and discussion points
          then the proponent of the proposal of the project will be able to make
          a more sound and complete recommendation, diba po?
        </p>
        <p>
          So even kahit na training session lang po ‘to, let's bear in mind that
          there is government money involved, there's cost, there's government
          resources to it, diba po? And dito sa recommendation, lalagay natin,
          lahat ng gagawin,diba? Ano ang gagawin? Sino yung mga resource speaker
          if needed, diba?
        </p>
        <p>
          And I'd like to highlight some of the annex or the other attachments
          that this department have included in this CSW. Makakatulong po kasi..
        </p>
        <p>
          Number one. And of course, there's a line on the signature of the
          approver. On Annex A, andito po nakalagay pati agenda, para hindi na
          hulaan,diba?
        </p>
        <p>
          So the decision maker will be more informed. Ano ba ang gagawin
          department na ito?
        </p>
        <p>
          Subplanning seminar, so there's a program of activities as an Annex
          A….
        </p>
        <p>
          For the second day, there's also a detailed program of activities.
          Likewise on the 3D and also on Annex B the list of participants per
          division with their roles, designation. Ayan po..
        </p>
        <p>
          So andiyan ang Annex C is actually the cost,diba po? Ano ang ating
          placeholder cost or proposed cost and budget para makadecide yung
          decision maker if this proposal is within budget, within policy and is
          aligned with the direction of the city. okay?
        </p>
        <p>
          We will leave a copy of this and the presentation materials with ah,
          HR for future reference also.{' '}
        </p>
        <p>
          o, things said that, that ends ah my presentation. Let’s be mindful of
          the importance of the CSW, not just because it is a mandate given the
          Memorandum Circular Number 9, Series of 2023, but it makes our
          transactions, it makes the approval process better, faster, more
          efficient, and we will be able to serve our citizens better with
          better and informed decision making.
        </p>
        <p>
          So kailangan po magutlong tayo dito both from the one proposing,
          tulungan din po natin yung mga decision makers for them to have better
          decision and faster decision and more efficient course of action.
          That's it and salamat po again everyone and ah God bless, thank you.
        </p>
      </>
    )
  }

  const fetchUploadedTrainingVideo = async () => {
    try {
      const uploadedTrainingVid = await API.graphql(
        graphqlOperation(listTrainingVideos)
      )
      const uploadedTrainingVidList =
        uploadedTrainingVid.data.listTrainingVideos.items
      setUploadedTrainingVideo(
        uploadedTrainingVidList.sort((a, b) => {
          return a.key.localeCompare(b.key)
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchUploadedTrainingVideo()
  }, [])

  const handlePlayButton = (key) => {
    setIsModalVisible(true)
    setVideoKey(key)
  }

  const handleSelect = (value) => {
    setDepartment(value)
  }

  const dateDisplay = `${
    new Date().getMonth() + 1
  }-${new Date().getDate()}-${new Date().getFullYear()}`

  /*  const handleButtonClick = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSdee0qZgz6rnrMW41J-LSV5_iH7CyRLau4MBrhIKHN50JWMMA/viewform',
      '_blank'
    )
  } */

  return (
    <>
      <div className='pb-5'>
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='NOTICE!'
          width={1000}
          visible={isInstructionModalVisible}
          onCancel={() => setIsInstructionModalVisible(false)}
          footer={[
            <Button
              key='back1'
              onClick={() => setIsInstructionModalVisible(false)}
            >
              Ok
            </Button>,
          ]}
        >
          <p>
            Welcome to{' '}
            <strong>Enhancing Efficiency through Complete Staff Work!</strong>
          </p>

          <p>
            Few instructions before you proceed:
            <ul>
              <li>
                Each video module has an activity which will only appear after
                completely viewing a video module.
              </li>
              <li>
                Per activity, your progress will be saved if you have not
                clicked the submit button yet.
              </li>
              <li>
                Submitting your activity entry will redirect you to the next
                video module. There is no backtracking to the previous video/s
                so please note-take. You may make use of the transcript
                available per video module.
              </li>
              <li>
                Kindly save your file document as PDF/word document as “
                <b>EECSW B2 - Course Output 3 - Name</b>” and upload in the
                submit button.
              </li>
            </ul>
          </p>
          <p>
            If you encounter any technical issues, please email your concern/s
            to <strong>LD.HRMD@quezoncity.gov.ph.</strong> Thank you!
          </p>
        </Modal>
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='Congratulations!'
          width={1000}
          visible={isCompleteMsgModalVisible}
          onCancel={() => setIsCompleteMsgModalVisible(false)}
          footer={[
            <Button
              key='back2'
              onClick={() => setIsCompleteMsgModalVisible(false)}
            >
              Ok
            </Button>,
          ]}
        >
          <p>You have reached the end of our training modules.</p>
          <p>
            To complete this training, please go back to{' '}
            <strong>
              <i>Training Programs</i> tab
            </strong>{' '}
            and answer <strong>the Post-Training.</strong>
          </p>
          <p>Thank you, and we hope you had a wonderful learning experience!</p>
        </Modal>
        <>
          <img
            className='col-12'
            src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/HEADER+-+extended.png'
          />
          <div className='d-flex gap-2 justify-content-center'>
            <img
              className='col-3'
              src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/LEFT+CSW+March.png'
            />
            {englishVideos.map((title, index) => {
              return uploadedTrainingVideo
                .filter((video) =>
                  video.key.toLowerCase().includes(title.toLowerCase())
                )
                .map((video) => {
                  let activityComponent
                  let inProgressVid
                  if (index === 0) {
                    activityComponent = (
                      <ActivityQuestionModalOneJan21
                        userData={userData}
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                        setShowCorrectAnswerModal={setShowCorrectAnswerModal}
                        setCorrectAnswer={setCorrectAnswer}
                      />
                    )
                    inProgressVid = isWatchedVideoOne
                  } else if (index === 1) {
                    activityComponent = (
                      <ActivityQuestionModalTwoJan21
                        userData={userData}
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                        setShowCorrectAnswerModal={setShowCorrectAnswerModal}
                        setCorrectAnswer={setCorrectAnswer}
                      />
                    )
                    inProgressVid = isWatchedVideoTwo
                  } else if (index === 2) {
                    activityComponent = (
                      <ActivityQuestionModalThreeJan21
                        userData={userData}
                        setProgressActivity={setProgressActivity}
                        setIsModalActivityOpen={setIsModalActivityOpen}
                      />
                    )
                    inProgressVid = isWatchedVideoFour
                  }
                  return (
                    <>
                      {progressActivity == index + 1 && !isLoading && (
                        <div
                          className='card shadow mt-3 col-md-6 col-12'
                          key={video.key}
                        >
                          <Modal
                            maskClosable={false}
                            footer={null}
                            width={1050}
                            title={`NOTICE!!!`}
                            visible={isModalActivityOpen}
                            onCancel={() => setIsModalActivityOpen(false)}
                            destroyOnClose={true}
                          >
                            <div className='d-flex justify-content-center flex-column align-items-center text-center'>
                              <h6>
                                PLEASE{' '}
                                <span className='text-danger'>
                                  CLICK THE BUTTON BELOW
                                </span>{' '}
                                TO PROCEED TO THIS MODULE'S ACTIVITY
                                <p className='mt-3'>
                                  Clicking <strong>"Submit"</strong> will record
                                  your answers as final.
                                </p>
                              </h6>
                              {activityComponent}
                            </div>
                          </Modal>
                          <div className='h-100 d-flex align-items-center '>
                            <img
                              src={`${process.env.PUBLIC_URL}/Module ${
                                index + 1
                              } - thumbnail.png`}
                              alt={video.key}
                              className='w-100'
                            />
                            <div
                              className='playbutton btn cursor-pointer mb-5'
                              onClick={() => handlePlayButton(video.key)}
                              style={{
                                position: 'absolute',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                width: '80px',
                                height: '80px',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                borderRadius: '50%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                top: '40%',
                              }}
                            >
                              <PlayCircleFilled
                                style={{ fontSize: '43px', color: '#fff' }}
                              />
                            </div>
                          </div>
                          {videoFinishWatching.includes(video.key) ||
                          inProgressVid
                            ? activityComponent
                            : null}
                        </div>
                      )}
                    </>
                  )
                })
            })}
            {progressActivity == 'alldone' && (
              <div className='col-md-6 col-12 flex-grow-1 d-flex flex-column align-items-center justify-content-center mt-5 bg-secondary text-white'>
                <p>
                  <h3>You have reached the end of our training modules.</h3>
                </p>
                <p>
                  <h3>
                    To complete this training, please go back to{' '}
                    <strong>Training Programs</strong> tab and{' '}
                    <strong>answer the Post-Training.</strong>
                  </h3>
                </p>
                <p>
                  <h3>
                    Thank you, and we hope you had a wonderful learning
                    experience!"
                  </h3>
                </p>
              </div>
            )}
            <img
              className='col-3'
              src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/RIGHT+-+extended.png'
            />
          </div>
        </>
        <Modal
          /* maskClosable={false}
          destroyOnClose={true} */
          title='Acknowledgment and Receipt'
          width={1000}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button key='back3' onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>,
            <VideoPlayer
              videoKey={videoKey}
              key={'videoplayer1'}
              CSCOnlineVids={true}
              name={name}
              department={department}
              setIsModalVisible={setIsModalVisible}
              isAgree={isAgree}
              setvideoFinishWatching={setvideoFinishWatching}
              videoFinishWatching={videoFinishWatching}
              setIsModalActivityOpen={setIsModalActivityOpen}
              transcriptActivity={transcriptActivity}
            />,
          ]}
        >
          <div>
            <p>STATEMENT OF CLAIMING VIEW</p>
            <p className='text-justify'>
              I hereby declare and affirm my commitment to the terms and
              conditions set forth in this statement regarding the review video
              material provided to me in this platform (www.qc-hrmd.org), and
              its entirety, by the Human Resource Management Department (HRMD)
              of the Quezon City Government. I acknowledge that the review video
              material is the exclusive property of HRMD, and as such, I fully
              understand and agree to abide by the following terms:
            </p>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>1.</p>
              <p>
                {' '}
                <strong>Ownership:</strong> I recognize that the training video
                material is protected by intellectual property laws and is the
                sole and exclusive property of HRMD. I acknowledge that I have
                no rights or ownership claims over the content contained within
                the training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>2.</p>
              <p>
                {' '}
                <strong>Non-Duplication:</strong> I solemnly affirm that I will
                not duplicate or reproduce, in whole or in part, the training
                video material provided to me by HRMD. I understand that
                duplication includes but is not limited to copying, recording,
                downloading, or distributing the material through any means or
                in any format without explicit written consent from HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>3.</p>
              <p>
                {' '}
                <strong>Confidentiality:</strong> I acknowledge and respect that
                the training video material may contain confidential and
                proprietary information belonging to HRMD. I commit to
                maintaining the strictest confidentiality regarding the content,
                concepts, techniques, and methodologies presented in the
                training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>4.</p>
              <p>
                {' '}
                <strong>Personal Use:</strong> I understand that the training
                video material is solely intended for my personal use, learning,
                and development. I agree not to share, lend, or otherwise make
                the training video material available to any third party,
                whether for commercial or non-commercial purposes, without the
                express written consent of HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>5.</p>
              <p>
                {' '}
                <strong>Legal Consequences:</strong> I acknowledge that any
                unauthorized duplication or reproduction of the training video
                material may result in legal consequences, including but not
                limited to civil lawsuits and monetary damages. I accept full
                responsibility for any such consequences resulting from my
                violation of this agreement.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>6.</p>
              <p>
                {' '}
                <strong>Compliance:</strong> I commit to cooperating with HRMD
                and its representatives in any investigation of suspected
                violation of this agreement. I understand that HRMD reserves the
                right to take appropriate legal action to enforce these terms
                and protect its rights.
              </p>
            </div>
            <p>
              By inputting my full name and department/office in this statement,
              I acknowledge that I have read and understood the terms outlined
              above and that I will comply with them strictly. I further
              acknowledge that any breach of this agreement may result in severe
              legal consequences.
            </p>
            <div className='d-flex'>
              <label className='d-flex items-align-center gap-2'>
                <input
                  type='checkbox'
                  checked={!isAgree}
                  onChange={(e) => setIsAgree(!isAgree)}
                />
                I agree
              </label>
            </div>
          </div>

          <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Enter your name'
                className='text-center'
              />
              <span className='text-center'>Employee's Name & Signature</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
              <span>{dateDisplay}</span>
              <span>Date</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
              <Select
                value={department}
                onChange={handleSelect}
                className='w-100 text-center'
              >
                <Option value='disabled' disabled>
                  Disabled
                </Option>
                {dept.sort().map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <span className='text-center'>Department</span>
            </div>
          </div>
        </Modal>
      </div>
      <Jan21CorrectAnswerActivity1
        showCorrectAnswerModal={showCorrectAnswerModal}
        setShowCorrectAnswerModal={setShowCorrectAnswerModal}
        correctAnswer={correctAnswer}
      />
    </>
  )
}
