/**
 * Title: internNewsLetter.js
 * Description: This is a file that contains the Intern Newsletter Page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/Internship/internNewsLetter.js
 **/

import { Row, Col } from 'antd'
import './internNewsletter.css'

export default function InternNewsletter() {
  return (
    <>
      <div className='flex-column'>
        <div className='newsletter-page main__inl'>
          <div className='item__inl'>
            <section className='section2__section2__inl'>
              <div className='section2__flexCol__inl'>
                <div className='section2__flexCol1__inl'>
                  <div className='px-grid__inl'>
                    <div className='section2__rect__inl'></div>
                  </div>
                  <span>
                    LOREM IPSUM DESCRIPTION FOR THE SUBTITLE OF THE MAIN PICTURE
                    HEADER
                  </span>
                  <div className='section2__flexCol2__inl'>
                    <div className='section2__flexCol1__inl'>
                      <div className='section2__flexRow__inl'>
                        <div className='item__inl'>
                          <px-grid
                            track-style='flex-grow: 1;'
                            x='0px 395fr 0px'
                            y='0px minmax(0px, 29fr) 0px'
                            xl-x='0px minmax(395px,395px) 0px'
                            xl-y='0px minmax(0px, 29fr) 0px'
                          >
                            <h2 className='section2__medium_title__inl'>
                              INSTITUTIONAL DEVELOPMENT
                            </h2>
                          </px-grid>
                        </div>
                        <div className='spacer__inl'></div>
                        <div className='item1__inl'>
                          <div className='section2__rect2__inl'></div>
                        </div>
                        <div className='spacer1__inl'></div>
                        <div className='item2__inl'>
                          <h2 className='section2__medium_title1__inl'>
                            EVENTS
                          </h2>
                        </div>
                      </div>
                      <h1 className='section2__big_title__inl'>
                        TITLE OF THE ESSAY THAT’S WRITTEN
                      </h1>
                      <px-grid
                        x='0px 957fr 0px'
                        y='15px minmax(0px, 20fr) 0px'
                        xxs-x='0px minmax(0px,350px) 0px'
                        xxs-y='15px minmax(0px, 20fr) 0px'
                      >
                        <h5 className='section2__highlights__inl'>
                          Lorem Ipsum for the Subtitle under the big ass tiotle
                          that’s given up top yeeeehaw
                        </h5>
                      </px-grid>
                      <px-grid
                        x='0px 957fr 0px'
                        y='79px minmax(0px, 20fr) 0px'
                        xxs-x='0px minmax(0px,300px) 0px'
                        xxs-y='79px minmax(0px, 20fr) 0px'
                      >
                        <h5 className=''>
                          <div className='section2__highlights1__inl'>
                            <span className='section2__highlights1_span0__inl'>
                              STORY AND PHOTOS BY{' '}
                            </span>
                            <span className='section2__highlights1_span1__inl'>
                              NAME HERE
                            </span>
                          </div>
                        </h5>
                      </px-grid>
                    </div>
                    <hr className='section2__line__inl' size='1' />
                    <px-grid
                      x='2px 955fr 0px'
                      y='78px minmax(640px,640fr) 0px'
                      lg-x='2px 955fr 0px'
                      lg-y='78px minmax(400px,640fr) 0px'
                      xxs-x='2px minmax(0px,340px) 0px'
                      xxs-y='78px minmax(400px,640fr) 0px'
                    >
                      <h5 className='section2__highlights__inl'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Vestibulum tincidunt vulputate ipsum vel egestas.
                        Curabitur et porta elit. Aenean sagittis dapibus quam,
                        eget tristique eros tempor et. Ut hendrerit leo in diam
                        accumsan, nec malesuada elit gravida. Vivamus suscipit
                        nisi at mattis blandit. Aenean vulputate lacus vehicula
                        turpis euismod consequat. Vestibulum varius aliquet
                        dolor id consectetur. Mauris aliquam gravida augue,
                        vitae malesuada erat pulvinar sed. Mauris efficitur
                        suscipit est eget eleifend. Suspendisse ullamcorper,
                        purus vitae mattis viverra, urna libero rutrum nunc, sit
                        amet feugiat neque lorem vel lectus. Nunc nec mollis
                        nunc.
                        <br />
                        <br />
                        <br />
                        Suspendisse placerat at massa sed laoreet. Suspendisse
                        euismod ut quam id imperdiet. Donec gravida neque augue,
                        vitae pharetra ipsum ornare quis. Integer ultricies
                        rhoncus quam, eget sodales metus bibendum ac. Duis nec
                        accumsan elit, quis consequat purus. Donec arcu ipsum,
                        eleifend non diam sit amet, hendrerit sodales ipsum.
                        Vestibulum pretium porta massa eu venenatis. Nulla eget
                        feugiat quam.
                        <br />
                        <br />
                        <br />
                        Fusce consectetur non sem a pretium. Pellentesque
                        fringilla iaculis turpis mollis pulvinar. Proin
                        porttitor ante ultrices ex feugiat accumsan. Donec a
                        augue varius, luctus quam ut, mollis urna. Curabitur
                        nulla risus, gravida eget feugiat sit amet, lacinia ac
                        tellus. Nam pharetra varius auctor. Nam quis convallis
                        libero. Maecenas molestie mauris lobortis mi gravida,
                        laoreet scelerisque felis gravida. Sed in lectus sed ex
                        maximus blandit vitae non sem.
                        <br />
                        Nam rutrum id augue finibus rutrum. Sed in facilisis
                        enim. Mauris eu tristique nisi. Curabitur nec purus
                        rutrum, vulputate leo a, condimentum nulla. Etiam
                        dignissim hendrerit leo id venenatis. Mauris cursus
                        pretium nisi eget elementum. Pellentesque habitant morbi
                        tristique senectus et netus et malesuada fames ac turpis
                        egestas. Nunc vel risus ac ex congue finibus.
                        Suspendisse vitae fringilla nisl. Aenean consequat magna
                        nec neque efficitur suscipit. Donec congue velit id enim
                        volutpat blandit.
                        <br />
                        <br />
                        <br />
                        Duis lacinia egestas blandit. Integer commodo hendrerit
                        velit. Quisque in tristique tellus. Cras faucibus
                        pharetra egestas. Fusce at nunc ac neque porta
                        facilisis. Vivamus ligula velit, scelerisque vitae
                        tempor in, pulvinar eget lectus. Aenean eget risus at
                        felis euismod efficitur.
                        <br />
                        <br />
                        <br />
                        <br />
                      </h5>
                    </px-grid>
                  </div>
                </div>
                <div className='section2__rect1__inl'></div>
                <h5 className='section2__highlights3__inl'>
                  Fusce velit eros, tincidunt nec nulla commodo, hendrerit
                  imperdiet urna. Mauris tellus odio, vehicula vitae sem id,
                  cursus feugiat arcu. Morbi quis mauris non magna sagittis
                  imperdiet a vitae turpis. Etiam sodales interdum dapibus.
                  Mauris ut urna facilisis, placerat mi quis, semper velit. Sed
                  eget metus at sapien dignissim venenatis at eget purus. Sed
                  volutpat malesuada tellus nec tincidunt. Vivamus vel volutpat
                  nisl. Aliquam finibus euismod varius.
                  <br />
                  Vestibulum a purus nunc. Aenean id erat non tellus bibendum
                  bibendum. Quisque felis metus, efficitur et porta at,
                  sollicitudin porta quam. Praesent convallis justo vel justo
                  condimentum laoreet. Quisque et malesuada erat, ut dapibus
                  neque. Interdum et malesuada fames ac ante ipsum primis in
                  faucibus. Vestibulum quis lobortis turpis. Nullam sagittis,
                  nisl vitae ornare egestas, eros purus dignissim nulla, sit
                  amet tempor diam dui non augue. Curabitur non purus dapibus,
                  varius odio eu, interdum urna. Curabitur euismod ligula neque,
                  non ullamcorper risus fringilla et. Vestibulum nec lorem
                  consequat, aliquet quam vel, tempor ipsum. Etiam magna leo,
                  tristique quis suscipit eu, tincidunt nec arcu.
                  <br />
                  <br />
                  Duis lacinia egestas blandit. Integer commodo hendrerit velit.
                  Quisque in tristique tellus. Cras faucibus pharetra egestas.
                  Fusce at nunc ac neque porta facilisis. Vivamus ligula velit,
                  scelerisque vitae tempor in, pulvinar eget lectus. Aenean eget
                  risus at felis euismod efficitur.
                  <br />
                  <br />
                </h5>
              </div>
            </section>
          </div>
        </div>
      </div>
    </>
  )
}
