/**
 * Title: uploadLetter.js
 * Description: This is a file that contains the components for uploading endorsement letter
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/uploadLetter.js
 **/

import { useState, useEffect, useRef } from 'react'

import { Button, Modal, Col, Row, Input, notification } from 'antd'

import { API, graphqlOperation } from 'aws-amplify'
import { updateTrainingRegistration } from '../../api/mutations'
import { Auth } from 'aws-amplify'
import {
  S3Client,
  PutObjectCommand,
  DeleteObjectCommand,
} from '@aws-sdk/client-s3'
import { sendEmail } from '../../utilities/sendEmail'
import moment from 'moment'

export default function UploadLetter({
  viewCourse,
  openViewLetter,
  setOpenViewLetter,
  toEdit,
  userData,
}) {
  const [letterUpload, setLetterUpload] = useState(null)
  const [disabledAfterSubmit, setDisabledAfterSubmit] = useState(false)

  const checkDisabled = () => {
    if (disabledAfterSubmit == false) {
      if (letterUpload) {
        return false
      } else {
        return true
      }
    } else {
      return true
    }
  }

  const handleUpload = async () => {
    setDisabledAfterSubmit(true)
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })

      // Retrieve the existing file URL from the database (assuming `toEdit.endorsementLetter` contains the URL)
      const oldFileUrl = toEdit.endorsementLetter

      if (oldFileUrl) {
        // Extract the S3 key from the old file URL
        const oldFileKey = oldFileUrl.split('.amazonaws.com/')[1]

        // Delete the old file from the S3 bucket
        const deleteParams = {
          Bucket: 's3etnahris133956-dev',
          Key: oldFileKey,
        }
        await s3.send(new DeleteObjectCommand(deleteParams))
      }

      // Upload the new file to the S3 bucket
      if (letterUpload) {
        const params = {
          Bucket: 's3etnahris133956-dev',
          Key: `${userData.id}/${moment().valueOf()}-${letterUpload.name}`,
          Body: letterUpload,
          ACL: 'public-read',
        }

        const command = new PutObjectCommand(params)
        const uploadedByAdmin = await s3.send(command)
        const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

        const adminUpload = await API.graphql(
          graphqlOperation(updateTrainingRegistration, {
            input: {
              id: toEdit.id,
              endorsementLetter: url,
            },
          })
        )

        if (adminUpload) {
          notification['success']({
            message: 'Success',
            description: 'Your Endorsement Letter was uploaded.',
          })

          //For sending email to notifify the admin that user are registered with uploaded endorsement letter
          const emailSubject = `Uploaded Endorsement Letter for ${viewCourse.title} training`
          const emailBody = `${userData.name} ${userData.lname} has uploaded a endorsement letter to ${viewCourse.title} training`
          sendEmail(emailSubject, emailBody)
        }
      }

      setOpenViewLetter(false)
      setLetterUpload(null)
      setDisabledAfterSubmit(false)
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description:
          'Uploading Endorsement Letter was not successful. Please contact administrator for help.',
      })
      setDisabledAfterSubmit(false)
    }
  }

  return (
    <>
      <Modal
        title={'Endorsement Letter'}
        centered
        visible={openViewLetter}
        onOk={() => setOpenViewLetter(false)}
        onCancel={() => setOpenViewLetter(false)}
        width={700}
        footer={null}
        destroyOnClose={true}
      >
        <Row justify='center' style={{ fontSize: 16 }}>
          <Col style={{ width: 800 }}>
            <Row justify='center'>
              <Col>
                <p className={'text-center'}>
                  Kindly submit the formal letter duly signed by your
                  Department/Office Head <b>allowing your attendance</b> in this
                  training <b>on official time</b>.{' '}
                  <em>
                    This letter shall merit the issuance of Certificate of
                    Completion.
                  </em>
                </p>
                <p className={'text-center'}>
                  You may edit your response to upload your endorsement letter.
                </p>
              </Col>
            </Row>
            <Row>
              <label
                htmlFor='endorsementLetter'
                className='loginInput-label'
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Upload Here
              </label>
              <Input
                id='endorsementLetter'
                name='endorsementLetter'
                type='file'
                accept='image/jpeg,image/gif,image/png,application/pdf'
                onChange={(e) => {
                  setLetterUpload(e.target.files[0])
                }}
              />
            </Row>
          </Col>
        </Row>
        <hr />
        <Row justify='space-between'>
          <Button onClick={() => setOpenViewLetter(false)}>Cancel</Button>
          <Button
            disabled={checkDisabled()}
            onClick={handleUpload}
            type='primary'
          >
            Submit
          </Button>
        </Row>
      </Modal>
    </>
  )
}
