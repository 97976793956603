import { Button, Modal } from 'antd'
import React, { useEffect, useState } from 'react'
import { getAssessmentQuestions } from '../../api/queries'
import { API, graphqlOperation } from 'aws-amplify'
import TextArea from 'antd/lib/input/TextArea'

export default function EditAssessmentQuestion({ assessmentID }) {
  const [assessmentQuestion, setAssessmentQuestion] = useState(null)
  const [openAssessmentModal, setOpenAssessmentModal] = useState(false)

  const getAssesnmentQuestion = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getAssessmentQuestions, {
          id: assessmentID,
        })
      )
      setAssessmentQuestion(response.data.getAssessmentQuestions)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getAssesnmentQuestion()
  }, [])

  const showOutput =
    assessmentQuestion &&
    JSON.parse(assessmentQuestion.assessmentQuestionnaire).map(
      (elem, index) => {
        const imageUrl =
          elem.imageUploadQuestion && elem.imageUploadQuestion[0]?.url
        return (
          <div key={index} className='mb-4'>
            <div>
              <p className={'fw-bold'}>
                {index + 1}. {elem.question}
              </p>
            </div>
            <div className='flex-column ms-3 mt-2'>
              {imageUrl && (
                <div>
                  <img
                    src={imageUrl}
                    alt={`imgQuestion ${index}`}
                    style={{ maxWidth: '450px', maxHeight: '450px' }}
                  />
                </div>
              )}
              {elem.choices[0] &&
                elem.choices[1] &&
                elem.choices[2] &&
                elem.choices[3] && (
                  <>
                    <div
                      className='flex align-items-center'
                      hidden={
                        elem.choices[0][
                          `choices-1-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>A. </em>
                        </strong>
                        {elem.choices[0][`choices-1-${index + 1}`]}
                      </span>
                    </div>
                    <div
                      hidden={
                        elem.choices[1][
                          `choices-2-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>B. </em>
                        </strong>
                        {elem.choices[1][`choices-2-${index + 1}`]}
                      </span>
                    </div>
                    <div
                      hidden={
                        elem.choices[2][
                          `choices-3-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>C. </em>
                        </strong>
                        {elem.choices[2][`choices-3-${index + 1}`]}
                      </span>
                    </div>
                    <div
                      hidden={
                        elem.choices[3][
                          `choices-4-${index + 1}`
                        ].toLowerCase() === 'n/a'
                      }
                    >
                      <input name='answer' className='mr' type='radio' />
                      <span className='m-0 ms-2'>
                        <strong>
                          <em>D. </em>
                        </strong>
                        {elem.choices[3][`choices-4-${index + 1}`]}
                      </span>
                    </div>
                    <TextArea hidden={elem.correctAnswer !== 'n/a'} />
                  </>
                )}
            </div>
            {/* <Row>
          <Row>
            <span className={"fw-bold"}>Multiple choice: Choose the correct asnwer</span>
          </Row>
        </Row> */}
          </div>
        )
      }
    )

  const handleCancelAssessment = () => {
    setOpenAssessmentModal(false)
  }

  return (
    <div>
      <Button type='primary' ghost onClick={() => setOpenAssessmentModal(true)}>
        View Assessment Question
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='View Assessment Question'
        visible={openAssessmentModal}
        footer={null}
        onCancel={() => setOpenAssessmentModal(false)}
        width={800}
      >
        <div className='d-flex flex-column gap-3'>
          {showOutput}
          <div className='ms-auto mt-5'>
            <Button
              className='me-3 bg-secondary text-white'
              onClick={handleCancelAssessment}
              type='default'
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
