/**
 * Title: PDSGuide.js
 * Description: This is a file that contains the components for the PDS guide PDF link.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/PDSGuide.js
 **/

import { Row, Button } from 'antd'

import { ArrowRightOutlined } from '@ant-design/icons'

export default function PDSGuide() {
  /**
   * Opens a new browser window or tab with a specified URL.
   *
   * This function attempts to open a PDF guide in a new browser window or tab.
   * If the operation fails, it logs the error to the console.
   *
   * @function openGuideLink
   * @returns {void}
   *
   * @example
   * openGuideLink();
   *
   * @throws {Error} Logs any error that occurs when trying to open the window.
   */
  const openGuideLink = () => {
    try {
      window.open(
        'https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/PDSFiles/GUIDE+TO+FILLING+OUT_THE+PDS.pdf',
        '_blank'
      )
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Row className='me-4 my-4' justify='end'>
        <Button
          type='primary'
          shape='round'
          size='large'
          onClick={openGuideLink}
        >
          <Row>
            <ArrowRightOutlined />
            <b className='ms-2'>
              <em>Guide on how to answer PDS Form</em>
            </b>
          </Row>
        </Button>
      </Row>
    </>
  )
}
