/**
 * Title: postTrainingModal.js
 * Description: This is a file that contains the components that displays the post training modal.
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/postTrainingModal.js
 * Changes made:
 * - 09/23/2024 | John Bazil Valdez | Added these file, modal to show the how many correct answer for Pre-Test and Post-Test
 * - 10/22/2024 | Harry Lagunsad | Add a props for show score modal to identify if the post training is for async training or not
 *   this isECert props is needed to adjust the score show modal message
 **/
import { Button, Modal } from 'antd'
import PropTypes from 'prop-types'

export default function PreAssessmentCorrectScore({
  preAssessmentCorrectScore,
  setPreAssessmentCorrectScore,
  isPreAssessmentScoreOpen,
  setIsPreAssessmentScoreOpen,
  isPostAssessment,
  trainingItem,
  isECert,
}) {
  const handleCancelModal = () => {
    setIsPreAssessmentScoreOpen(false)
    setPreAssessmentCorrectScore(0)
  }

  return (
    <Modal
      destroyOnClose={true}
      footer={null}
      width={'500px'}
      title={isPostAssessment ? 'Post-Test Score' : 'Pre-Test Score'}
      open={isPreAssessmentScoreOpen}
      onCancel={handleCancelModal}
    >
      <div className='flex flex-col items-center justify-center space-y-4 text-center'>
        {isPostAssessment &&
          (preAssessmentCorrectScore < trainingItem.postAssessmentScore ? (
            <p style={{ fontSize: '18px' }}>
              Unfortunately, you did not reach the passing score in the Post
              Test. You need 80%. Don&apos;t give up! Please try again.
            </p>
          ) : (
            <>
              {isECert ? (
                <p style={{ fontSize: '18px' }}>
                  Congratulations on completing this training! Kindly await the
                  release of your Certificate of Participation. You will be
                  emailed your e-Certificate as soon as possible. Thank you very
                  much!
                </p>
              ) : (
                <p style={{ fontSize: '18px' }}>
                  Congratulations on completing this training!
                </p>
              )}
            </>
          ))}
        <h4>Your {isPostAssessment ? 'Post-Test' : 'Pre-Test'} score is:</h4>
        <h2 className=' mt-4 fw-bold'>{preAssessmentCorrectScore}</h2>
      </div>

      <div className={'d-flex justify-content-end'} style={{ marginTop: 20 }}>
        <Button type='primary' onClick={handleCancelModal}>
          Ok
        </Button>
      </div>
    </Modal>
  )
}

PreAssessmentCorrectScore.propTypes = {
  preAssessmentCorrectScore: PropTypes.number,
  setPreAssessmentCorrectScore: PropTypes.func,
  isPreAssessmentScoreOpen: PropTypes.bool,
  setIsPreAssessmentScoreOpen: PropTypes.func,
  isPostAssessment: PropTypes.bool,
  trainingItem: PropTypes.object,
  isECert: PropTypes.bool,
}
