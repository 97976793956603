import React, { useEffect, useState } from 'react'
import { getEvaluationQuestions } from '../../api/queries'
import { API, graphqlOperation } from 'aws-amplify'
import { Button, Modal, notification, Row, Col, Input } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { toRoman } from '../../utilities/utils'

export default function EditEvaluationQuestion({ evalID }) {
  const [evaluationQuestion, setEvaluationQuestion] = useState(null)
  const [openAssessmentModal, setOpenAssessmentModal] = useState(false)

  const getEvaluationQuestion = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getEvaluationQuestions, {
          id: evalID,
        })
      )
      setEvaluationQuestion(response.data.getEvaluationQuestions)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getEvaluationQuestion()
  }, [])

  const showOutput =
    evaluationQuestion &&
    JSON.parse(evaluationQuestion.eval).map((elem, index) => {
      return (
        <React.Fragment key={index}>
          <span className={'fw-bold'}>
            {toRoman(index + 1)}. {elem.question}
          </span>
          {elem.categories.length > 0 ? (
            <>
              {elem.description && (
                <Row>
                  <span className={'text-muted py-2'}>{elem.description}</span>
                </Row>
              )}
              <Row className={'mb-3'}>
                <Col style={{ width: 550 }}>
                  <Row>
                    <Col span={9}></Col>
                    <Col span={15} className='d-flex justify-content-around'>
                      <span className='fw-bold'>1</span>
                      <span className='fw-bold'>2</span>
                      <span className='fw-bold'>3</span>
                      <span className='fw-bold'>4</span>
                      <span className='fw-bold'>5</span>
                    </Col>
                    <Col span={24}>
                      {/* <Row style={{ height: "22px" }}></Row> */}
                      {elem.categories.map((category, ci) => {
                        return (
                          <div className='d-flex align-items-center mt-1'>
                            <Col span={9} key={ci}>
                              {category[`category-${ci + 1}-${index + 1}`]}{' '}
                              <em className='asterisk'>**</em>
                            </Col>
                            <Col
                              span={15}
                              className='d-flex justify-content-around'
                            >
                              <Row style={{ height: '22px' }}>
                                <input
                                  value='1'
                                  name='setAQOne'
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  value='2'
                                  name='setAQOne'
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  value='2'
                                  name='setAQOne'
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  value='2'
                                  name='setAQOne'
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  value='2'
                                  name='setAQOne'
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                            </Col>
                          </div>
                        )
                      })}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <div style={{ width: '550px' }}>
              <Row className={'mb-3 mt-3'}>
                <Col span={24}>
                  <TextArea
                    /* onChange={(e) =>
                          handleTextareaChange(e, elem.question, index)
                        } */
                    id={`additionalComments${index}`}
                    style={{ fontSize: 16 }}
                    rows={5}
                  />
                </Col>
              </Row>
            </div>
          )}
        </React.Fragment>
      )
    })

  const handleCancelAssessment = () => {
    setOpenAssessmentModal(false)
  }

  return (
    <div>
      <Button type='primary' ghost onClick={() => setOpenAssessmentModal(true)}>
        View Evaluation Question
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='View Assessment Question'
        visible={openAssessmentModal}
        footer={null}
        onCancel={() => setOpenAssessmentModal(false)}
        width={800}
      >
        <div className='d-flex flex-column gap-3' style={{ maxWidth: 750 }}>
          <div className='d-flex flex-column gap-3 mx-auto'>{showOutput}</div>
          <div className='ms-auto mt-5'>
            <Button
              className='me-3 bg-secondary text-white'
              onClick={handleCancelAssessment}
              type='default'
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  )
}
