/**
 * Title: institutionalDev.js
 * Description: This is a file that contains the institutional development
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/Internship/institutionalDev.js
 **/

import { useState, useEffect } from 'react'

import { Carousel, Grid } from 'antd'

import './institutionalDev.css'

const { useBreakpoint } = Grid

export default function InstitutionalDev({ goto }) {
  const [index, setIndex] = useState(0)

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  const { md, lg, xl } = useBreakpoint()

  const handleNext = () => {
    goto('internNewsLetter')
  }

  useEffect(() => {
    if (document.getElementById('scrollHere')) {
      const scrollElement = document.getElementById('scrollHere')
      scrollElement.scrollIntoView()
    }
  })

  return (
    <div id='scrollHere' className='flex-column__inst'>
      <div className={'main-inst'}>
        <div className='item__inst'>
          {/*======= section1 ======= */}
          {/*<section className="section1__section1__inst"></section>*/}
          {/*======= End section1 =======*/}
        </div>

        <div className='item__inst'>
          {/*======= section2 =======*/}
          <section className='section2__section2__inst'>
            <div className='section2__wrapper__inst'>
              <div className={'px-grid'}>
                <div className='section2__flexRow__inst'>
                  <div className='item__inst'>
                    <div className='section2__rect__inst'></div>
                  </div>
                  <div className='spacer__inst'></div>
                  <div className='item1__inst'>
                    <h1 className='section2__hero_title__inst'>
                      INSTITUTIONAL DEVELOPMENT
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div className='section2__wrapper1__inst'>
              <div className='section2__flexCol__inst'>
                <div className='section2__flexCol__inst1'>
                  <div className='section2__list__inst'>
                    <div className='item__inst'>
                      <div className='card__inst'>
                        <div className='section2__rect2__inst'></div>
                      </div>
                    </div>
                    <div className='item1__inst'>
                      <div className='card__inst'>
                        <div className='section2__rect3__inst'></div>
                      </div>
                    </div>
                  </div>
                  <div className='section2__list__inst1'>
                    <div className='item__inst'>
                      <div className='card__inst'>
                        <div className='section2__rect31__inst'></div>
                      </div>
                    </div>
                    <div className='item__inst'>
                      <div className='card__inst'>
                        <div className='section2__rect31__inst'></div>
                      </div>
                    </div>
                    <div className='item__inst'>
                      <div className='card__inst'>
                        <div className='section2__rect32__inst'></div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  onClick={handleNext}
                  className={'btn__inst section3__btn__inst'}
                >
                  <span className='section3__btn__text__inst'>
                    MORE INFORMATION
                  </span>
                </button>
              </div>
            </div>
          </section>
          {/*======= End section2 =======*/}
        </div>
        <div className='item__inst'>
          {/*======= section3 =======*/}
          <section className='section3__section3__inst'>
            <div className='section3__wrapper3__inst'>
              <div className='section3__flexCol__inst'>
                <div className='section3__flexCol1__inst'>
                  <h1 className='section3__hero_title1__inst'>
                    UPCOMING EVENTS
                  </h1>
                  <div className='section3__flexCol2__inst'>
                    {xl ? (
                      <>
                        <div className='carousel__container'>
                          <Carousel dots='true' autoplaySpeed={3000} autoplay>
                            <div
                              className={
                                'd-flex flex-row justify-content-center'
                              }
                            >
                              <div
                                className={
                                  'contentStyle d-flex justify-content-center me-2'
                                }
                              >
                                <img
                                  src='https://via.placeholder.com/350x350'
                                  alt=''
                                />
                              </div>
                              <div
                                className={
                                  'contentStyle d-flex justify-content-center me-2'
                                }
                              >
                                <img
                                  src='https://via.placeholder.com/350x350'
                                  alt=''
                                />
                              </div>
                              <div
                                className={
                                  'contentStyle d-flex justify-content-center me-2'
                                }
                              >
                                <img
                                  src='https://via.placeholder.com/350x350'
                                  alt=''
                                />
                              </div>
                            </div>

                            <div
                              className={
                                'd-flex flex-row justify-content-center'
                              }
                            >
                              <div
                                className={
                                  'contentStyle d-flex justify-content-center me-2'
                                }
                              >
                                <img
                                  src='https://via.placeholder.com/350x350'
                                  alt=''
                                />
                              </div>
                              <div
                                className={
                                  'contentStyle d-flex justify-content-center me-2'
                                }
                              >
                                <img
                                  src='https://via.placeholder.com/350x350'
                                  alt=''
                                />
                              </div>
                              <div
                                className={
                                  'contentStyle d-flex justify-content-center me-2'
                                }
                              >
                                <img
                                  src='https://via.placeholder.com/350x350'
                                  alt=''
                                />
                              </div>
                            </div>

                            <div
                              className={
                                'd-flex flex-row justify-content-center'
                              }
                            >
                              <div
                                className={
                                  'contentStyle d-flex justify-content-center me-2'
                                }
                              >
                                <img
                                  src='https://via.placeholder.com/350x350'
                                  alt=''
                                />
                              </div>
                              <div
                                className={
                                  'contentStyle d-flex justify-content-center me-2'
                                }
                              >
                                <img
                                  src='https://via.placeholder.com/350x350'
                                  alt=''
                                />
                              </div>
                              <div
                                className={
                                  'contentStyle d-flex justify-content-center me-2'
                                }
                              >
                                <img
                                  src='https://via.placeholder.com/350x350'
                                  alt=''
                                />
                              </div>
                            </div>
                          </Carousel>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='carousel__container'>
                          <Carousel dots='true' autoplaySpeed={3000} autoplay>
                            <div
                              className={
                                'd-flex justify-content-center contentStyle'
                              }
                            >
                              <img
                                src='https://via.placeholder.com/350x350'
                                alt=''
                              />
                            </div>
                            <div
                              className={
                                'd-flex justify-content-center contentStyle'
                              }
                            >
                              <img
                                src='https://via.placeholder.com/350x350'
                                alt=''
                              />
                            </div>
                            <div
                              className={
                                'd-flex justify-content-center contentStyle'
                              }
                            >
                              <img
                                src='https://via.placeholder.com/350x350'
                                alt=''
                              />
                            </div>
                            <div
                              className={
                                'd-flex justify-content-center contentStyle'
                              }
                            >
                              <img
                                src='https://via.placeholder.com/350x350'
                                alt=''
                              />
                            </div>
                            <div
                              className={
                                'd-flex justify-content-center contentStyle'
                              }
                            >
                              <img
                                src='https://via.placeholder.com/350x350'
                                alt=''
                              />
                            </div>
                            <div
                              className={
                                'd-flex justify-content-center contentStyle'
                              }
                            >
                              <img
                                src='https://via.placeholder.com/350x350'
                                alt=''
                              />
                            </div>
                            <div
                              className={
                                'd-flex justify-content-center contentStyle'
                              }
                            >
                              <img
                                src='https://via.placeholder.com/350x350'
                                alt=''
                              />
                            </div>
                            <div
                              className={
                                'd-flex justify-content-center contentStyle'
                              }
                            >
                              <img
                                src='https://via.placeholder.com/350x350'
                                alt=''
                              />
                            </div>
                          </Carousel>
                        </div>
                      </>
                    )}
                  </div>
                  <button className={'section3__btn__inst btn__inst'}>
                    <span className={'section3__btn__text__inst'}>
                      MORE EVENTS
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </section>
          {/*======= End section3 =======*/}
        </div>
        <div className='item__inst'>
          {/*======= section4 =======*/}
          <section className='section4__section4__inst'>
            <div className='section4__flexCol__inst'>
              <div className='item__inst'>
                <h1 className='section4__hero_title1'>TESTIMONIES</h1>
              </div>

              <div className='item__inst'>
                <div className='section4__flexCol1__inst'>
                  <div className='item__inst'>
                    <div className='section4__list__inst'>
                      <div className='item__inst'>
                        <div className='section4__image3__inst'></div>
                      </div>
                      <div className='spacer__inst'></div>
                      <div className='item1__inst'>
                        <h5 className='section4__highlights__inst'>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Vestibulum vitae metus ut augue viverra
                          ullamcorper. Ut eleifend est id condimentum ultricies.
                          Ut tortor leo, laoreet posuere neque et, egestas
                          vulputate dolor. Pellentesque habitant morbi tristique
                          senectus et netus et malesuada fames ac turpis
                          egestas. Nulla facilisi. Duis a elementum justo, id
                          viverra ligula. Nunc malesuada vehicula arcu, non
                          luctus nulla tincidunt ac. Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit. Quisque a interdum felis.
                          Donec quis lorem molestie, feugiat magna faucibus,
                          finibus ligula. In quis convallis nunc, sit amet
                          semper magna.
                        </h5>
                      </div>
                    </div>
                  </div>

                  <div className='item__inst'>
                    <div className='section4__flexRow__inst'>
                      <div className='item__inst'>
                        <h5 className='section4__highlights1__inst'>
                          Lorem ipsum dolor sit amet, consectetur adipiscing
                          elit. Vestibulum vitae metus ut augue viverra
                          ullamcorper. Ut eleifend est id condimentum ultricies.
                          Ut tortor leo, laoreet posuere neque et, egestas
                          vulputate dolor. Pellentesque habitant morbi tristique
                          senectus et netus et malesuada fames ac turpis
                          egestas. Nulla facilisi. Duis a elementum justo, id
                          viverra ligula. Nunc malesuada vehicula arcu, non
                          luctus nulla tincidunt ac. Lorem ipsum dolor sit amet,
                          consectetur adipiscing elit. Quisque a interdum felis.
                          Donec quis lorem molestie, feugiat magna faucibus,
                          finibus ligula. In quis convallis nunc, sit amet
                          semper magna. <br />
                        </h5>
                      </div>
                      <div className='spacer__inst'></div>
                      <div className='item1__inst'>
                        <div className='section4__image31__inst'></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*======= End section4 =======*/}
        </div>
      </div>
    </div>
  )
}
