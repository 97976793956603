/**
 * Title: orientationVideos.js
 * Description: This is a file that contains the components that displays the onboarding videos and the script of the video
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/orientationVideos.js
 **/

/**
 * Changes Made:
 * - 2024.08.27 | Raymart Mojado | update the validation accept ' and - characters on name input
 */

import { useRef, useState } from 'react'

import {
  Button,
  Row,
  Col,
  Card,
  Table,
  notification,
  Modal,
  Input,
  Select,
} from 'antd'

import { API, graphqlOperation } from 'aws-amplify'

import { createOrientationVideoAcknowledgement } from '../../api/mutations'
import './home.css'
import './orientationVideos.css'
import listOfDepartments from '../../utilities/dept'

const { Option } = Select

/**
 *
 * @returns a component that displays the onboarding videos and the script of the video
 */

export default function OrientationVideos({ userData }) {
  const userInfo = userData
  const userFullName = userInfo
    ? userInfo?.name + ' ' + userInfo?.mname + ' ' + userInfo?.lname
    : ''

  const videoRef = useRef(null)
  const [name, setName] = useState(userFullName)
  const [department, setDepartment] = useState(userInfo?.department || '')
  const [showModal, setShowModal] = useState(false)
  const [videoPlayed, setVideoPlayed] = useState(false)
  const [nameError, setNameError] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [tabError, setTabError] = useState(false)
  const [departmentError, setDepartmentError] = useState(false)
  const [nameErrorBlank, setNameErrorBlank] = useState(false)
  const dept = listOfDepartments()

  const handleTimeChange = (startTime) => {
    videoRef.current.currentTime = startTime
  }

  const handlePlay = () => {
    if (!videoPlayed) {
      videoRef.current.pause() // Pause the video
      setShowModal(true)
    }
  }

  /**
   * @function showErrorNotification
   * @description Displays an error notification with the provided description.
   * @param {string} description - The description of the error to be displayed in the notification.
   */
  const showErrorNotification = (description) => {
    try {
      notification['error']({
        message: 'Error',
        description: description,
      })
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function handleSubmit
   * @description Handles the form submission for orientation videos. Validates the employee
   * name and department fields, and if valid, submits the data to the API.
   * @async
   * @returns {Promise<void>}
   */
  const handleSubmit = async () => {
    // Regex pattern to allow only alphabets (uppercase and lowercase) and spaces and selected special characters for name
    const regex = /^(?:[a-zA-ZñéáíóúÑÉÁÍÓÚ.\s-']+)$/
    if (!regex.test(name) || !department || !name || name.includes('\t')) {
      if (!regex.test(name) || name.includes('\t')) {
        showErrorNotification('The employee name has an invalid characters.')
        setNameError('The employee`s name has an invalid characters.')
      } else if (!name && !department) {
        setNameErrorBlank('Employee`s name cannot be blank.')
        setDepartmentError('Department name cannot be blank.')
        showErrorNotification('Please complete the fields below to continue.')
      } else if (!name) {
        showErrorNotification('Employee name cannot be blank.')
        setNameErrorBlank('Employee`s name cannot be blank.')
      } else if (!department) {
        notification['error']({
          message: 'Error',
          description: 'Department cannot be blank.',
        })
        setDepartmentError('Department name cannot be blank.')
      }
      // else if (!regex.test(name) && !department) {
      //   showErrorNotification('Please complete the fields below to continue.')
      // }
    } else {
      setDisabledButton(true)
      const input = {
        employeeName: name.trim().replace(/\s+/g, ' '),
        department,
        date: new Date(),
      }

      try {
        const acknowledgment = await API.graphql({
          query: createOrientationVideoAcknowledgement,
          variables: { input },
          authMode: 'API_KEY',
        })

        if (acknowledgment.data.createOrientationVideoAcknowledgement) {
          setTimeout(() => {
            setName(null)
            setDepartment(null)
            setShowModal(false)
            setVideoPlayed(true)
            videoRef.current.play()
          }, 2000)
        }
      } catch (err) {
        console.log(err)
        setDisabledButton(false)
      }
    }
  }

  const handleCancel = () => {
    setShowModal(false)
  }

  const handleDeptFocus = () => {
    try {
      setDepartmentError(false)
    } catch (err) {
      console.log(err)
    }
  }

  const handleNameFocus = () => {
    try {
      setNameErrorBlank(false)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function handleSelect
   * @description Handles the selection of a department from a dropdown or other selection input.
   * @param {string} value - The selected department value.
   */
  const handleSelect = (value) => {
    try {
      setDepartment(value)
    } catch (err) {
      console.log(err)
    }
  }

  const dateDisplay = `${
    new Date().getMonth() + 1
  }-${new Date().getDate()}-${new Date().getFullYear()}`

  /**
   * @function handleChange
   * @description Handles the change event for an input field. Sets the name
   * state if the input value does not start with a space or tab.
   * @param {Event} e - The change event object.
   */
  const handleChange = (e) => {
    const value = e.target.value
    const regex = /^(?:[a-zA-ZñéáíóúÑÉÁÍÓÚ.\s-']+)$/

    if (!/^(\s)/.test(value)) {
      if (!value.includes('\t') && regex.test(value) && value) {
        setName(value)
        setTabError(false)
        setNameError(false)
        setDisabledButton(false)
      }
      if (value && !regex.test(value)) {
        setNameError('The employee`s name has an invalid characters.')
        setName(value)
        setDisabledButton(true)
      }
      if (value.includes('\t')) {
        setTabError('Tab characters are not allowed.')
        setName(value)
        setDisabledButton(true)
      }
      if (value && value.length >= 256) {
        setNameError(
          'The employee`s name cannot be longer than 256 characters.'
        )
        setName(value)
        setDisabledButton(true)
      }
      if (!value) {
        setName(value)
        setDisabledButton(true)
        setTabError(false)
        setNameError(false)
      }
    }
  }

  return (
    <div className='container'>
      <div className='mx-auto font-Mont p-md-0 p-2'>
        <Row
          className='heading border-bottom border-dark'
          style={{ marginTop: 120 }}
          justify='left'
        >
          <h1 className='title fw-bolder font-Mont display-md-3 display-5'>
            ONBOARDING VIDEO{' '}
          </h1>
        </Row>
        <video
          className='img-fluid mb-5'
          ref={videoRef}
          style={{ marginTop: 50 }}
          width=''
          height='750'
          controls
          onPlay={handlePlay}
        >
          <source src='https://s3etnahris133956-dev.s3.amazonaws.com/uploadedByAdmin/Onboarding%20Video.mp4'></source>
        </video>

        <Row
          className='heading border-bottom border-dark'
          justify='left'
          hidden={!videoPlayed}
        >
          <h1 className='title fw-bolder font-Mont display-md-3 display-5'>
            TRANSCRIPT{' '}
          </h1>
        </Row>
        <Row className='mb-5' hidden={!videoPlayed}>
          <div className='transcript mt-3 text-start'>
            <p title='0:00-0:10' onClick={() => handleTimeChange(0)}>
              {' '}
              Welcome to the Quezon City Government! We are delighted to have an
              enthusiastic professional with a creative mind and compassionate
              heart among us.{' '}
            </p>

            <p title='0:10-0:19' onClick={() => handleTimeChange(10)}>
              Here with us today is Honorable Ma. Josefina G. Belmonte to give
              her welcoming remarks for you.Envisioned by the late President
              Manuel Luis Quezon as an ideal haven for diverse, well-planned
              urban development,
            </p>
            <p title='0:19-0:32' onClick={() => handleTimeChange(19)}>
              Quezon City today is both the largest and most populous city in
              the National Capital Region.With our growing population, we aim to
              sustain Quezon City as a Quality Community -{' '}
            </p>
            <p title='0:32-0:40' onClick={() => handleTimeChange(32)}>
              a progressive and peaceful, clean and orderly place, conducive and
              hospitable to living, employment, and business.
            </p>
            <p title='0:40- 54' onClick={() => handleTimeChange(40)}>
              We are dedicated to provide quality service, making Quezon City an
              ideal community where people live, work, and do business in a
              hospitable, progressive, and peaceful environment.{' '}
            </p>
            <p title='0:54-1:15' onClick={() => handleTimeChange(54)}>
              Guided by our core values, we believe that the success of the city
              lies upon having competent, committed, creative, caring, and
              trustworthy leadership, data and information-driven decision
              making, and an efficient and well-functioning government system
              that is worthy of the public trust.{' '}
            </p>
            <p title='1:15-1:30' onClick={() => handleTimeChange(75)}>
              Our way of governance is geared towards achieving Mayor Joy
              Belmonte's 14-point agenda that focuses on human and social
              services, economic development, environment, and climate change,
              infrastructure, and institutional development.{' '}
            </p>

            <p title='1:30-2:19' onClick={() => handleTimeChange(90)}>
              To better understand our growing family, we would like to
              introduce to you our departments! The departments and offices
              under the institutional development cluster serve towards becoming
              models of good governance, professionalizing and strengthening the
              Quezon City workforce, and listening to our citizens and
              understand what they need. This includes the:
            </p>
            <ul
              title='1:30-2:19'
              className='list-unstyled'
              onClick={() => handleTimeChange(108)}
            >
              <li className='text-decoration-none'>
                - Office of the City Mayor
              </li>
              <li>- Office of the Vice Mayor</li>
              <li>
                - Office of the Secretary to the Sangguniang Panlungsod (OSSP)
              </li>
              <li>- City Accounting Department</li>
              <li>- City Budget Department</li>
              <li>- Barangay Community Relations Department</li>
              <li>- Public Affairs and Information Services Department</li>
              <li>- City General Services Department</li>
              <li>-Human Resource Management Department</li>
              <li>- Information Technology Development Department</li>
              <li>- Quezon City Public Library</li>
              <li>- City Legal Department </li>
            </ul>
            <p title='2:19-2:23' onClick={() => handleTimeChange(139)}>
              Offices that ensure the city's SECURITY, PEACE, AND DISASTER
              PREPAREDNESS include the:
            </p>
            <ul
              title='2:23-2:42'
              className='list-unstyled'
              onClick={() => handleTimeChange(144)}
            >
              <li>-Department of Public Order and Safety</li>
              <li>-Quezon City Police District</li>
              <li>-Radio Communications Service Office</li>
              <li>-People's Law Enforcement Boards</li>
              <li>
                -Quezon City Disaster and Risk Reduction Management Office
              </li>
              <li>-Quezon City Fire District </li>
            </ul>
            <p title='2:42-3:10' onClick={() => handleTimeChange(162)}>
              Our Economic Development Cluster aims to make Quezon City the
              preferred business destination, create new jobs, and develop
              growth hubs through the
            </p>
            <ul
              title='2:42-3:10'
              className='list-unstyled'
              onClick={() => handleTimeChange(162)}
            >
              <li>-Business Permits and Licensing Department</li>
              <li>-City Assessor's Office</li>
              <li>-Market Development and Administration Department</li>
              <li>-City Treasurer's Office</li>
              <li>-Local Economic Investment Promotions Office</li>
              <li>-Investment Affairs Office</li>
              <li>-Quezon City Tourism Department</li>
            </ul>
            <p title='3:10-3:25' onClick={() => handleTimeChange(190)}>
              our INFRASTRUCTURE, URBAN PLANNING AND DEVELOPMENT CLUSTER
              includes the:
            </p>
            <ul
              title='3:10-3:25'
              className='list-unstyled'
              onClick={() => handleTimeChange(190)}
            >
              <li>-City Architect Department</li>
              <li>-Department of the Building Official</li>
              <li>-Quezon City Engineering Department</li>
              <li>-City Planning and Development Department </li>
            </ul>
            <p title='3:25-3:45' onClick={() => handleTimeChange(205)}>
              Our Poverty Reduction and Social Development CLUSTER is committed
              to deliver responsive, efficient, and cost-effective social
              services, build more homes, provide better healthcare, ensure high
              quality education, empower citizens of every gender and social
              className, and build a safer and more resilient city. This Cluster
              includes the:{' '}
            </p>
            <ul
              title='3:45-4:28'
              className='list-unstyled'
              onClick={() => handleTimeChange(225)}
            >
              <li>-City Civil Registry Department</li>
              <li>-Quezon City Health Department</li>
              <li>-City Veterinary Department</li>
              <li>
                -Housing Community Development and Resettlement Department
              </li>
              <li>-Novaliches District Hospital</li>
              <li>-Office for the Senior Citizen's Affairs</li>
              <li>-Persons with Disability Affairs Office</li>
              <li>-Social Service Development Department</li>
              <li>-Rosario Maclang Bautista General Hospital</li>
              <li>-Quezon City General Hospital</li>
              <li>-Public Employment Service Office</li>
              <li>
                -Small Business and Cooperatives Development and Promotions
                Office
              </li>
              <li>-Quezon City Youth Development Office </li>
              <li>-Quezon City Schools Division Office</li>
            </ul>
            <p title='4:28-4:55' onClick={() => handleTimeChange(268)}>
              Paving its way to a livable, green, and sustainable city, our
              Environment And Climate Change CLUSTER include the
            </p>
            <ul
              title='4:28-4:55'
              className='list-unstyled'
              onClick={() => handleTimeChange(268)}
            >
              <li>
                -Climate Change and Environmental Sustainability Department
              </li>
              <li>-Parks Development and Administration Department</li>
              <li>
                -Department of Sanitation and Cleanup Works of Quezon City
              </li>
            </ul>
            <p title='4:28-4:55' onClick={() => handleTimeChange(268)}>
              Special Bodies include the:
            </p>
            <ul
              title='4:28-4:55'
              onClick={() => handleTimeChange(268)}
              className='list-unstyled'
            >
              <li>-People's Law Enforcement Board</li>
              <li>-Board of Assessment Appeals</li>
            </ul>
            <p title='4:55- 5:19' onClick={() => handleTimeChange(295)}>
              To guide you further, You may access the Employee Handbook when
              you visit Human Resources @ Quezon City's website, under the
              Onboarding and Orientation section. This employee handbook
              presents your work environment, duties, responsibilities as well
              as your personal obligations as civil servants, and your rights
              and obligations as a government employee.
            </p>
            <p title='5:19-5:30' onClick={() => handleTimeChange(319)}>
              To know more about our local government services and for more
              information, visit: https://quezoncity.gov.ph/{' '}
            </p>
            <p title='5:30- 5:43' onClick={() => handleTimeChange(330)}>
              Thank you for choosing to serve Quezon City through our local
              government. Together, we can make a great team and strive to
              provide the best possible public service!
            </p>
          </div>
        </Row>
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title='Acknowledgment and Receipt'
          width={1000}
          visible={showModal}
          onOk={handleSubmit}
          onCancel={handleCancel}
          okButtonProps={{
            disabled: disabledButton,
          }}
        >
          <p className='text-center'>
            "I acknowledge and confirm that I will watch the 'Orientation
            video'."
          </p>

          <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
              <Input
                value={name}
                onChange={handleChange}
                onFocus={handleNameFocus}
                placeholder='Enter your name'
                className='text-center'
                maxLength={256}
              />
              {tabError && (
                <div style={{ color: 'red', fontSize: '12px' }}>{tabError}</div>
              )}
              {nameError && (
                <div style={{ color: 'red', fontSize: '12px' }}>
                  {nameError}
                </div>
              )}
              {nameErrorBlank && (
                <div style={{ color: 'red', fontSize: '12px' }}>
                  {nameErrorBlank}
                </div>
              )}
              <span className='text-center'>Employee's Name & Signature</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
              <span>{dateDisplay}</span>
              <span>Date</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
              <Select
                value={department}
                onChange={handleSelect}
                onFocus={handleDeptFocus}
                className='w-100 text-center'
              >
                <Option value='disabled' disabled>
                  Disabled
                </Option>
                {dept.sort().map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              {departmentError && (
                <div style={{ color: 'red', fontSize: '12px' }}>
                  {departmentError}
                </div>
              )}
              <span className='text-center'>Department</span>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  )
}
