/**
 * Title: manageFeedback.js
 * Description: This is a file that contains the components for managing the feedback
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Users/manageFeedback.js
 **/

import { useEffect, useState } from 'react'
import { Card, Avatar, Comment, Pagination, Spin } from 'antd'
import 'antd/dist/antd.css'
import { UserOutlined } from '@ant-design/icons'
import { API, graphqlOperation } from 'aws-amplify'
import { listSurveys } from '../../api/queries'
import '../Home/home.css'

export default function ManageFeedBack() {
  const [data, setData] = useState([])
  const [token, setToken] = useState(null)
  const [minValue, setMinValue] = useState(0)
  const [maxValue, setMaxValue] = useState(10)
  const [isLoading, setIsloading] = useState(true)

  useEffect(() => {
    handleLoadSurvey()
  }, [token])

  const handleLoadSurvey = async () => {
    try {
      let items = []
      let nextToken = null
      const limit = 1000

      do {
        const variables = { limit, nextToken }
        const result = await API.graphql(
          graphqlOperation(listSurveys, variables)
        )
        items.push(...result.data.listSurveys.items)
        nextToken = result.data.listSurveys.nextToken
        setIsloading(!!nextToken)
      } while (nextToken)

      setData(
        items
          .filter((e) => e.ownedBy)
          .filter((e) => e.surveyRecommendation.length)
      )
    } catch (err) {
      console.log(err)
    }
  }

  const handleChange = (value) => {
    if (value == 1) {
      setMinValue(0)
      setMaxValue(10)
    } else {
      setMinValue(value * 10 - 10)
      setMaxValue(value * 10)
    }
  }

  return (
    <div
      className='site-layout-background'
      style={{
        padding: 24,
      }}
    >
      <div>
        {/* <Card type="inner">
                    <Row gutter={24} justify="space-around">
                        <div className="mainDashboard">
                            <Col className="gutter-row" span={12}>
                                <iframe
                                src="https://ssdd.sparksoft-apps.com/d-solo/EQ9cbof7z/qc-enta-training-analysis?orgId=1&refresh=5s&from=1648716365317&to=1648737965317&panelId=26"
                                width="600"
                                title="Grafana Graph"
                                height="300"
                                frameBorder="0"
                                ></iframe>
                            </Col>
                        </div>
                    </Row>
                </Card> */}
        {isLoading ? (
          <>
            <Card
              type='inner'
              title='List of Employees Comments and Feedback For Evaluation form'
              style={{ marginTop: '10px' }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Spin size='large' />
              </div>
            </Card>
          </>
        ) : (
          <>
            <Card
              type='inner'
              title='List of Employees Comments and Feedback For Evaluation form'
              style={{ marginTop: '10px' }}
            >
              {data &&
                data.length > 0 &&
                data.slice(minValue, maxValue).map((item, i) => (
                  <Card key={item.id}>
                    <Comment
                      key={item}
                      author={
                        <a>
                          {item.ownedBy.name.toUpperCase() +
                            ' ' +
                            item.ownedBy.mname.toUpperCase() +
                            ' ' +
                            item.ownedBy.lname.toUpperCase()}
                        </a>
                      }
                      avatar={<Avatar size='large' icon={<UserOutlined />} />}
                      content={
                        // <div>
                        //   <p
                        //     style={{
                        //       border: "1px solid rgba(0, 0, 0, 0.05)",
                        //       padding: "20px",
                        //     }}
                        //   >
                        //     Survey Scores:
                        //     <br />
                        //     How satisfied are you in using the Learning &
                        //     Development platform?{" "}
                        //     <b>
                        //       {item.surveyScore
                        //         .replace(/[{}]/g, "")
                        //         .charAt(2)}
                        //     </b>
                        //     <br />
                        //     How would you rate the clarity (easy to
                        //     understand) of the content of the e-TNA (Training
                        //     Needs Analysis) form and ILDP (Individual Learning
                        //     and Development Plan)?{" "}
                        //     <b>
                        //       {item.surveyScore
                        //         .replace(/[{}]/g, "")
                        //         .charAt(7)}
                        //     </b>
                        //     <br />
                        //     How would you rate your overall experience in
                        //     filling up the e-TNA and ILDP form?{" "}
                        //     <b>
                        //       {item.surveyScore
                        //         .replace(/[{}]/g, "")
                        //         .charAt(12)}
                        //     </b>
                        //     <br />
                        //     <br />
                        //     Recommendation:
                        //     <br />
                        //     {item.surveyRecommendation}
                        //   </p>
                        // </div>
                        <p>
                          Recommendation:
                          <br />
                          {item.surveyRecommendation}
                        </p>
                      }
                    />
                  </Card>
                ))}
              <div className='d-flex flex-row-reverse'>
                <div className='p-2'></div>
                <div className='p-2'>
                  {data.length > 0 ? (
                    <Pagination
                      defaultCurrent={1}
                      pageSize={10}
                      onChange={(e) => handleChange(e)}
                      total={data.length}
                    />
                  ) : null}
                </div>
                <div className='p-2'></div>
              </div>
            </Card>
          </>
        )}
      </div>
    </div>
  )
}
