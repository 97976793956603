/**
 * Title: Jan21CorrectAnswerActivity1.js
 * Description: This is a file that contains the Correct Answer Activity 1
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/Jan21OnlineTraining/Jan21CorrectAnswerActivity1.js
 **/

import React from 'react'
import { Button, Modal, Row } from 'antd'

export default function Jan21CorrectAnswerActivity1({
  showCorrectAnswerModal,
  setShowCorrectAnswerModal,
  correctAnswer,
}) {
  return (
    <Modal
      title={`Activity Score`}
      width={800}
      /* visible={showCorrectAnswerModal} */
      open={showCorrectAnswerModal}
      onCancel={() => setShowCorrectAnswerModal(false)}
      footer={[
        <Button
          key='submit'
          type='primary'
          /* disabled={isShowActivity ? false : true} */
          // loading={loading}
          onClick={() => setShowCorrectAnswerModal(false)}
        >
          Ok
        </Button>,
      ]}
    >
      <Row className='mt-3' justify='center'>
        <h4>Your correct score is {correctAnswer}</h4>
      </Row>
    </Modal>
  )
}
