/**
 * Title: viewSubmittedPostTraining.js
 * Description: This is a file that contains the components for viewing the submitted post training
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/viewSubmittedPostTraining.js
 **/

import { useState } from 'react'

import { Button, Modal, Row, Col } from 'antd'

import PostAnswerOne from './postAnswersOne.js'
import PostAnswerTwo from './postAnswersTwo.js'
import PostAnswersCustom from './postAnswerCustom.js'

const ViewSubmittedPostTraining = ({ f, record }) => {
  const [open, setOpen] = useState(false)
  const [viewAnswer, setViewAnswer] = useState(false)

  const handleOpen = () => {
    setOpen(true)
    setTimeout(() => {
      setViewAnswer(true)
    }, 100)
  }

  const handleClose = () => {
    setOpen(false)
    setViewAnswer(false)
  }

  return (
    <>
      <Button onClick={handleOpen}>View</Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={`Post Training Answers of ${f.registeredUser.name} ${
          f.registeredUser.mname ? f.registeredUser.mname : ''
        } ${f.registeredUser.lname}`}
        centered
        visible={open}
        onOk={handleClose}
        onCancel={handleClose}
        width={1000}
        footer={null}
      >
        <>
          {viewAnswer == true ? (
            <>
              {f.training?.id == '4dc08fb5-0170-438b-b3cb-a6a46d89b82b' ? (
                <>
                  <PostAnswerTwo record={record} data={f} />
                </>
              ) : (
                <>
                  <PostAnswersCustom record={record} data={f} />
                </>
              )}
            </>
          ) : null}
        </>
      </Modal>
    </>
  )
}
export default ViewSubmittedPostTraining
