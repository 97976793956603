import React from 'react'
import DOMPurify from 'dompurify'
import parse from 'html-react-parser'

export default function HtmlParser({ content }) {
  const sanitizedHTML = DOMPurify.sanitize(content)
  const parsedHTML = parse(sanitizedHTML)

  return <div>{parsedHTML}</div>
}
