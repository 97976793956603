/**
 * Title: postTrainingModal.js
 * Description: This is a file that contains the components that displays the post training modal.
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/postTrainingModal.js
 * Changes made:
 * - 09/10/2024 | Harry Lagunsad | Add certain texts below the Training registration forms and
 *   Post Training Evaluation/Assessment question forms
 * - 09/11/2024 | Harry Lagunsad | Add props validation
 * - 09/11/2024 | Harry Lagunsad | Fix sonarlint issues
 * - 09/23/2024 | John Bazil Valdez | Add a popup modal to show how many correct answer for the Post-Test
 * - 10/22/2024 | Harry Lagunsad | Fix prop types and add a props for show score modal to identify
 *   if the post training is for async training or not
 * - 10/23/2024 | Harry Lagunsad | Fix post training modal message
 **/
import { useEffect, useState } from 'react'

import { Modal } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'
import PropTypes from 'prop-types'

import {
  evaluationByTAID,
  listOnlineActivityAnswers,
  registrationByTAID,
} from '../../api/queries'
import { numToString } from '../../utilities/numToString.js'
import { isDoneAllActivityBWBatch2 } from '../../utilities/utils.js'
import PostQuestionCustomMade from './postQuestionCustomMade'
import PostQuestionCustomMadeAnswer from './postQuestionCustomMadeAnswer'
import PostQuestionTwo from './postQuestionTwo.js'
import PostQuestionTwoAnswers from './postQuestionTwoAnswers.js'
import PreAssessmentCorrectScore from './pre-assessment-correct-score.js'

const PostTrainingModal = ({
  trainingItem,
  afterRegister,
  userData,
  setHidePostOnlineTraining,
  isECert,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpenAfter, setIsModalOpenAfter] = useState(false)

  const [showContent, setShowContent] = useState(false)
  const [showAnswer, setShowAnswer] = useState(false)

  const [disableRegister, setDisableRegister] = useState(false)

  const [hidePostTraining, setHidePostTraining] = useState(false)

  const [showByTime, setShowByTime] = useState(false)

  const [questionOneAfter, setQuestionOneAfter] = useState(null)
  const [questionTwoAfter, setQuestionTwoAfter] = useState(null)
  const [questionThreeAfter, setQuestionThreeAfter] = useState(null)
  const [questionFourAfter, setQuestionFourAfter] = useState(null)
  const [questionFiveAfter, setQuestionFiveAfter] = useState(null)

  const [evalQuestionsSet, setEvalQuestionsSet] = useState({})

  const [isCSCTraining, setIsCSCTraining] = useState(false)
  const [showCSCTraining, setShowCSCTraining] = useState(false)

  const [afterPostTraining, setAfterPostTraining] = useState(null)
  const [isPostTraining, setIsPostTraining] = useState(true)

  const [isDoneAllActivity, setIsDoneAllActivity] = useState(false)

  const [preAssessmentCorrectScore, setPreAssessmentCorrectScore] = useState(0)
  const [isPreAssessmentScoreOpen, setIsPreAssessmentScoreOpen] =
    useState(false)

  let questionsArray
  if (trainingItem?.evalQuestionnaire) {
    questionsArray = JSON.parse(trainingItem.evalQuestionnaire.eval)
  }

  let assessmentQuestions
  if (trainingItem?.questionsAssessment) {
    assessmentQuestions = JSON.parse(
      trainingItem.questionsAssessment.assessmentQuestionnaire
    )
  }

  const getUserActivity = () => {
    try {
      isDoneAllActivityBWBatch2(userData)
        .then((result) => {
          setIsDoneAllActivity(result)
        })
        .catch((err) => {
          console.error('Error:', err)
        })
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    getUserActivity()

    if (
      trainingItem.id === '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04' ||
      trainingItem.id === 'b4b26699-3535-452f-a370-cce7a4d2d71b' ||
      trainingItem.id === 'c822d184-b65a-4a25-8079-89e895685546'
    ) {
      setIsCSCTraining(true)
    }
  }, [])

  useEffect(() => {
    // Get the current date and time using Moment.js
    let now = moment()

    // Set the start and end dates for the date range
    let startDate = moment('2024-03-01 12:00:00')
    let endDate =
      trainingItem.id === 'b4b26699-3535-452f-a370-cce7a4d2d71b'
        ? moment('2024-03-15 23:59:59')
        : moment('2023-03-15 23:59:59')

    // Check if the current date is within the date range
    let isWithinRange = now.isBetween(startDate, endDate)

    // Return true if the current date is within the date range, otherwise false
    if (isWithinRange) {
      setShowCSCTraining(true)
    } else {
      setShowCSCTraining(false)
    }
  }, [])

  useEffect(() => {
    if (isModalOpen === true) {
      if (document.getElementsByClassName('postAssesmentQ').length) {
        const postAssesmentQ = document.querySelectorAll('.postAssesmentQ')
        postAssesmentQ.forEach((i) => {
          i.checked = false
        })
      }
    }
  }, [isModalOpen])

  useEffect(() => {
    const showPostraining = async () => {
      const fetchAllData = async () => {
        let allData = []
        let nextToken = null

        do {
          const result = await API.graphql({
            query: listOnlineActivityAnswers,
            variables: {
              nextToken,
              limit: 10000,
            },
          })

          const data = result.data.listOnlineActivityAnswers.items
          allData = allData.concat(data)

          nextToken = result.data.listOnlineActivityAnswers.nextToken
        } while (nextToken)

        return allData
      }

      const userAnswer = await fetchAllData()

      const filteredUserAnswer = userAnswer.filter((item) => {
        return (
          item.userID === userData?.id &&
          item.onlineTrainingAnnouncementID === trainingItem.id
        )
      })

      const numberOfAnswers = filteredUserAnswer[0]?.answers
        ? Object.keys(JSON.parse(filteredUserAnswer[0].answers)).length
        : 0

      if (JSON.parse(trainingItem?.sectionTitle)?.length !== numberOfAnswers) {
        setIsPostTraining(false)
      }
    }

    if (trainingItem.sectionTitle) {
      showPostraining()
    }
  }, [])

  const checkIfRegistered = async () => {
    try {
      if (userData) {
        let token = null
        let foundItems = []

        do {
          const result = await API.graphql(
            graphqlOperation(registrationByTAID, {
              trainingAnnouncementID: trainingItem.id,
              userID: { eq: userData.id },
              nextToken: token,
            })
          )

          const { items, nextToken } = result.data.registrationByTAID
          foundItems = [...foundItems, ...items]
          token = nextToken
        } while (token)

        if (foundItems.length) {
          setDisableRegister(true)
        } else {
          setDisableRegister(false)
        }
      }
    } catch (error) {
      console.log(error)
      if (error.toString().includes('Error: No current user')) {
        window.location.reload()
      }
    }
  }

  const handleTotalCorrectItem = (assessQuestionsSet) => {
    try {
      if (trainingItem && trainingItem.questionsAssessment) {
        let totalCorrectScore = 0
        const assessmentQuestionnaire = JSON.parse(
          trainingItem?.questionsAssessment?.assessmentQuestionnaire
        )

        // Iterate through the questionnaire and compare answers
        assessmentQuestionnaire.forEach((question, index) => {
          const questionKey = `question${numToString(index)}`
          const userAnswer = assessQuestionsSet[questionKey]
          const correctAnswer = question.correctAnswer

          if (userAnswer === correctAnswer) {
            totalCorrectScore += 1
          }
        })

        return totalCorrectScore
      }
    } catch (err) {
      console.log(err)
    }
  }

  const checkIfPostTrainingSubmitted = async () => {
    try {
      if (userData) {
        let token = null
        let foundItems = []

        do {
          const result = await API.graphql(
            graphqlOperation(evaluationByTAID, {
              trainingAnnouncementID: trainingItem.id,
              userID: { eq: userData.id },
              nextToken: token,
            })
          )

          const { items, nextToken } = result.data.evaluationByTAID
          foundItems = [...foundItems, ...items]
          token = nextToken
        } while (token)

        if (foundItems.length) {
          let totalCorrectScore = handleTotalCorrectItem(
            JSON.parse(foundItems[0].questionnaire)
          )

          if (
            trainingItem.isPassPostAssessmentRequired &&
            totalCorrectScore < trainingItem?.postAssessmentScore
          ) {
            return setHidePostTraining(false)
          }

          setHidePostTraining(true)
        } else {
          setHidePostTraining(false)
        }
      }
    } catch (error) {
      console.log(error)
      if (error.toString().includes('Error: No current user')) {
        window.location.reload()
      }
    }
  }

  useEffect(() => {
    let before = moment(new Date(), 'DD MM YYYY hh:mm:ss a').isAfter(
      trainingItem.postTrainOpen
    )
    let after = moment(new Date(), 'DD MM YYYY hh:mm:ss a').isBefore(
      trainingItem.postTrainClose
    )

    if (before && after) {
      setShowByTime(true)
    } else {
      setShowByTime(false)
    }
  }, [afterRegister])

  useEffect(() => {
    checkIfRegistered()
    checkIfPostTrainingSubmitted()
  }, [afterRegister, afterPostTraining])

  const handleOpenModal = () => {
    setIsModalOpen(true)
    setTimeout(() => {
      setShowContent(true)
    }, 500)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setShowContent(false)

    setQuestionOneAfter(null)
    setQuestionTwoAfter(null)
    setQuestionThreeAfter(null)
    setQuestionFourAfter(null)
    setQuestionFiveAfter(null)
  }

  const handleCloseModalAfter = () => {
    setHidePostTraining(true)

    setIsModalOpen(false)
    setIsModalOpenAfter(false)
    setShowContent(false)

    setQuestionOneAfter(null)
    setQuestionTwoAfter(null)
    setQuestionThreeAfter(null)
    setQuestionFourAfter(null)
    setQuestionFiveAfter(null)
  }

  return (
    <>
      {disableRegister && isPostTraining ? (
        <>
          {hidePostTraining ? (
            <span className={'text-monts'}>
              <b>
                <em>You have already answered this post-training.</em>
              </b>
            </span>
          ) : (
            <>
              {showByTime ? (
                <>
                  {/* This is only for Business Writing batch 2 online training only.
                  Only show the button when the user already answered all the activity*/}

                  {trainingItem.id === 'cdb10950-01b5-428a-9959-0fc47237b528' &&
                    isDoneAllActivity && (
                      <button
                        className={'m-container'}
                        onClick={handleOpenModal}
                      >
                        {trainingItem?.isSeminar
                          ? 'Post Evaluation'
                          : 'Post Training'}
                      </button>
                    )}

                  {trainingItem.id !==
                    'cdb10950-01b5-428a-9959-0fc47237b528' && (
                    <button className={'m-container'} onClick={handleOpenModal}>
                      {trainingItem?.isSeminar
                        ? 'Post Evaluation'
                        : 'Post Training'}
                    </button>
                  )}

                  <Modal
                    maskClosable={false}
                    title={trainingItem.title}
                    centered
                    open={isModalOpen}
                    onOk={handleCloseModal}
                    onCancel={handleCloseModal}
                    width={1100}
                    footer={null}
                    destroyOnClose={true}
                  >
                    {showContent ? (
                      <>
                        {(trainingItem.evalID &&
                          trainingItem.evalID !== 'ok') ||
                        trainingItem.assessmentID ? (
                          <PostQuestionCustomMade
                            userData={userData}
                            setAfterPostTraining={(e) =>
                              setAfterPostTraining(e)
                            }
                            setHidePostTraining={() =>
                              setHidePostTraining(true)
                            }
                            isCSCTraining={isCSCTraining}
                            evalQuestionsSet={evalQuestionsSet}
                            setEvalQuestionsSet={setEvalQuestionsSet}
                            questionsArray={questionsArray}
                            trainingItem={trainingItem}
                            assessmentQuestions={assessmentQuestions}
                            setIsModalOpenAfter={(e) => setIsModalOpenAfter(e)}
                            setQuestionOneAfter={(e) => setQuestionOneAfter(e)}
                            setQuestionTwoAfter={(e) => setQuestionTwoAfter(e)}
                            setQuestionThreeAfter={(e) =>
                              setQuestionThreeAfter(e)
                            }
                            setQuestionFourAfter={(e) =>
                              setQuestionFourAfter(e)
                            }
                            setQuestionFiveAfter={(e) =>
                              setQuestionFiveAfter(e)
                            }
                            setShowAnswer={(e) => setShowAnswer(e)}
                            handleCloseModal={handleCloseModal}
                            setHidePostOnlineTraining={() =>
                              setHidePostOnlineTraining?.()
                            }
                            preAssessmentCorrectScore={
                              preAssessmentCorrectScore
                            }
                            setPreAssessmentCorrectScore={
                              setPreAssessmentCorrectScore
                            }
                            isPreAssessmentScoreOpen={isPreAssessmentScoreOpen}
                            setIsPreAssessmentScoreOpen={
                              setIsPreAssessmentScoreOpen
                            }
                          />
                        ) : (
                          <PostQuestionTwo
                            userData={userData}
                            trainingItem={trainingItem}
                            setIsModalOpenAfter={(e) => setIsModalOpenAfter(e)}
                            setQuestionOneAfter={(e) => setQuestionOneAfter(e)}
                            setQuestionTwoAfter={(e) => setQuestionTwoAfter(e)}
                            setQuestionThreeAfter={(e) =>
                              setQuestionThreeAfter(e)
                            }
                            setQuestionFourAfter={(e) =>
                              setQuestionFourAfter(e)
                            }
                            setQuestionFiveAfter={(e) =>
                              setQuestionFiveAfter(e)
                            }
                            setShowAnswer={(e) => setShowAnswer(e)}
                            handleCloseModal={handleCloseModal}
                          />
                        )}
                      </>
                    ) : null}
                  </Modal>

                  <Modal
                    maskClosable={false}
                    destroyOnClose={true}
                    title={'Post Test Correct Answers'}
                    open={isModalOpenAfter}
                    onCancel={handleCloseModalAfter}
                    width={1000}
                    footer={null}
                  >
                    {showAnswer ? (
                      <>
                        {trainingItem.assessmentID ? (
                          <PostQuestionCustomMadeAnswer
                            evalQuestionsSet={evalQuestionsSet}
                            trainingItem={trainingItem}
                            assessmentQuestions={assessmentQuestions}
                          />
                        ) : (
                          <PostQuestionTwoAnswers
                            questionOneAfter={questionOneAfter}
                            questionTwoAfter={questionTwoAfter}
                            questionThreeAfter={questionThreeAfter}
                            questionFourAfter={questionFourAfter}
                            questionFiveAfter={questionFiveAfter}
                          />
                        )}
                      </>
                    ) : null}
                  </Modal>
                </>
              ) : (
                <>
                  {showCSCTraining ? (
                    <>
                      <button
                        className={'m-container'}
                        onClick={handleOpenModal}
                      >
                        {trainingItem?.isSeminar
                          ? 'Post Evaluation'
                          : 'Post Training'}
                      </button>
                      <Modal
                        maskClosable={false}
                        title={trainingItem.title}
                        centered
                        open={isModalOpen}
                        onOk={handleCloseModal}
                        onCancel={handleCloseModal}
                        width={1100}
                        footer={null}
                        destroyOnClose={true}
                      >
                        {showContent ? (
                          <>
                            {(trainingItem.evalID &&
                              trainingItem.evalID !== 'ok') ||
                            trainingItem.assessmentID ? (
                              <PostQuestionCustomMade
                                userData={userData}
                                setAfterPostTraining={(e) =>
                                  setAfterPostTraining(e)
                                }
                                setHidePostTraining={() =>
                                  setHidePostTraining(true)
                                }
                                isCSCTraining={isCSCTraining}
                                evalQuestionsSet={evalQuestionsSet}
                                setEvalQuestionsSet={setEvalQuestionsSet}
                                questionsArray={questionsArray}
                                trainingItem={trainingItem}
                                assessmentQuestions={assessmentQuestions}
                                setIsModalOpenAfter={(e) =>
                                  setIsModalOpenAfter(e)
                                }
                                setQuestionOneAfter={(e) =>
                                  setQuestionOneAfter(e)
                                }
                                setQuestionTwoAfter={(e) =>
                                  setQuestionTwoAfter(e)
                                }
                                setQuestionThreeAfter={(e) =>
                                  setQuestionThreeAfter(e)
                                }
                                setQuestionFourAfter={(e) =>
                                  setQuestionFourAfter(e)
                                }
                                setQuestionFiveAfter={(e) =>
                                  setQuestionFiveAfter(e)
                                }
                                setShowAnswer={(e) => setShowAnswer(e)}
                                handleCloseModal={handleCloseModal}
                              />
                            ) : (
                              <PostQuestionTwo
                                userData={userData}
                                trainingItem={trainingItem}
                                setIsModalOpenAfter={(e) =>
                                  setIsModalOpenAfter(e)
                                }
                                setQuestionOneAfter={(e) =>
                                  setQuestionOneAfter(e)
                                }
                                setQuestionTwoAfter={(e) =>
                                  setQuestionTwoAfter(e)
                                }
                                setQuestionThreeAfter={(e) =>
                                  setQuestionThreeAfter(e)
                                }
                                setQuestionFourAfter={(e) =>
                                  setQuestionFourAfter(e)
                                }
                                setQuestionFiveAfter={(e) =>
                                  setQuestionFiveAfter(e)
                                }
                                setShowAnswer={(e) => setShowAnswer(e)}
                                handleCloseModal={handleCloseModal}
                              />
                            )}
                          </>
                        ) : null}
                      </Modal>

                      <Modal
                        maskClosable={false}
                        destroyOnClose={true}
                        title={'Post Test Correct Answers'}
                        open={isModalOpenAfter}
                        onCancel={handleCloseModalAfter}
                        width={1000}
                        footer={null}
                      >
                        {showAnswer ? (
                          <>
                            {trainingItem.assessmentID ? (
                              <PostQuestionCustomMadeAnswer
                                evalQuestionsSet={evalQuestionsSet}
                                trainingItem={trainingItem}
                                assessmentQuestions={assessmentQuestions}
                              />
                            ) : (
                              <PostQuestionTwoAnswers
                                questionOneAfter={questionOneAfter}
                                questionTwoAfter={questionTwoAfter}
                                questionThreeAfter={questionThreeAfter}
                                questionFourAfter={questionFourAfter}
                                questionFiveAfter={questionFiveAfter}
                              />
                            )}
                          </>
                        ) : null}
                      </Modal>
                    </>
                  ) : null}
                </>
              )}
            </>
          )}
        </>
      ) : null}
      {/* Show total correct answer in pre assessment */}
      <PreAssessmentCorrectScore
        isECert={isECert}
        preAssessmentCorrectScore={preAssessmentCorrectScore}
        setPreAssessmentCorrectScore={setPreAssessmentCorrectScore}
        isPreAssessmentScoreOpen={isPreAssessmentScoreOpen}
        setIsPreAssessmentScoreOpen={setIsPreAssessmentScoreOpen}
        isPostAssessment={true}
        trainingItem={trainingItem}
      />
    </>
  )
}

PostTrainingModal.propTypes = {
  trainingItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    sectionTitle: PropTypes.string,
    evalQuestionnaire: PropTypes.shape({
      eval: PropTypes.string,
    }),
    questionsAssessment: PropTypes.shape({
      assessmentQuestionnaire: PropTypes.string,
    }),
    postTrainOpen: PropTypes.string,
    postTrainClose: PropTypes.string,
    isSeminar: PropTypes.bool,
    title: PropTypes.string,
    evalID: PropTypes.string,
    assessmentID: PropTypes.string,
    isPassPostAssessmentRequired: PropTypes.bool,
    postAssessmentScore: PropTypes.number,
  }).isRequired,
  afterRegister: PropTypes.object,
  userData: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
  setHidePostOnlineTraining: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.oneOf([null]),
  ]),
  isECert: PropTypes.bool,
}

export default PostTrainingModal
