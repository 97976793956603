import React from 'react'
import PropTypes from 'prop-types'

import { Typography } from 'antd'

import DOMPurify from 'dompurify'

import './pds.css'

const { Text } = Typography

const remarksIsArrayChecker = (remarks) => {
  const mappedRemarks = remarks.map((remark, index) => (
    <div key={`${remark.input}-${index}`} className='pds-remarks-remark'>
      <div className='pds-remarks-remark-content'>
        <Text className='pds-remarks-label'>
          {DOMPurify.sanitize(remark.input)}:
        </Text>
        <Text className='pds-remarks-value'>
          {DOMPurify.sanitize(remark.textArea)}
        </Text>
      </div>
    </div>
  ))
  return mappedRemarks
}

const remarksIsObjectChecker = (remarks) => {
  return (
    <div className='pds-remarks-remark'>
      <div className='pds-remarks-remark-content'>
        <Text className='pds-remarks-label'>
          {DOMPurify.sanitize(remarks.input)}:
        </Text>
        <Text className='pds-remarks-value'>
          {DOMPurify.sanitize(remarks.textArea)}
        </Text>
      </div>
    </div>
  )
}

const remarksIsStringChecker = (remarks) => {
  return (
    <div className='pds-remarks-remark'>
      <Text className='pds-remarks-value'>{DOMPurify.sanitize(remarks)}</Text>
    </div>
  )
}

/**
 * Component to render remarks content.
 *
 * @param {Object} props - The component props.
 * @param {Array|Object|string} props.remarks - The remarks to display. Can be an array of remark objects, a single remark object, or a string.
 * @returns {JSX.Element|null} The rendered remarks content or null if no remarks are provided.
 */
const RemarksContent = ({ remarks }) => {
  try {
    if (!remarks) {
      return null
    }

    if (Array.isArray(remarks)) {
      if (!remarks.length) return null

      return remarksIsArrayChecker(remarks)
    }

    if (typeof remarks === 'object' && remarks !== null) {
      return remarksIsObjectChecker(remarks)
    }

    if (typeof remarks === 'string') {
      return remarksIsStringChecker(remarks)
    }

    return null // Add explicit return for unhandled cases
  } catch (error) {
    console.log('RemarksContent: Render failed', error)
    return <div className='pds-remarks-error'>Unable to display remarks</div>
  }
}

RemarksContent.propTypes = {
  remarks: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({
        input: PropTypes.string.isRequired,
        textArea: PropTypes.string.isRequired,
      })
    ),
    PropTypes.shape({
      input: PropTypes.string.isRequired,
      textArea: PropTypes.string.isRequired,
    }),
  ]),
}

export default RemarksContent
