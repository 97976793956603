/**
 * Title: postQuestionCustomMade.js
 * Description: This is a file that contains the components that displays the Post Question Custom Made
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/postQuestionCustomMade.js
 **/

import React, { useEffect, useState } from "react";
import { numToString } from "../../utilities/numToString";
import { convertNumToChar } from "../../utilities/convertNumToChar";
import { toRoman } from "../../utilities/toRoman";
import { notification, Button, Modal, Row, Col, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";

export default function PostQuestionCustomMadeAnswer({
  evalQuestionsSet,
  trainingItem,
  assessmentQuestions,
}) {
  const [score, setScore] = useState(0);

  // This useEffect is to check the the user total score by comparing the correct answer and the answer input by the user
  useEffect(() => {
    let tempScore = 0;
    if (assessmentQuestions) {
      assessmentQuestions.forEach((elem, index) => {
        const numString = numToString(index);
        const question = "question" + numString;
        if (
          assessmentQuestions[index].correctAnswer == evalQuestionsSet[question]
        ) {
          tempScore++;
        }
      });
    }
    setScore(tempScore);
  }, []);

  // This is to create the assessment questions component by mapping the "assessmentQuestions"
  let showAssessmentQuestions;
  if (assessmentQuestions) {
    showAssessmentQuestions = assessmentQuestions.map((elem, index) => {
      const numString = numToString(index);
      const question = "question" + numString;

      return (
        <React.Fragment key={index}>
          <Row className="mt-4">
            <span style={{ fontSize: 16, textAlign: "left" }}>
              <strong>
                {toRoman(index + 1)}. {elem.question}
              </strong>
            </span>
          </Row>
          <Row
            align="center"
            justify="center"
            className="flex-column ms-3 mt-2"
          >
            {elem.choices[0] &&
              elem.choices[1] &&
              elem.choices[2] &&
              elem.choices[3] && (
                <>
                  {elem.choices[0][`choices-1-${index + 1}`].toLowerCase() !== "n/a" && (
                    <Row className="p-1">
                      <input
                        // onChange={(e) => handleSetAnsAssessQuestion(e)}
                        defaultChecked={
                          evalQuestionsSet[question] == "A" ? true : false
                        }
                        value="A"
                        name={"question" + numString}
                        type="radio"
                      />
                      <span
                        className={
                          elem.correctAnswer == "A"
                            ? "fw-bold m-0 ms-3"
                            : "m-0 ms-3"
                        }
                      >
                        <em>A. </em>
                        {elem.choices[0][`choices-1-${index + 1}`]}
                      </span>
                    </Row>
                  )}
                  {elem.choices[1][`choices-2-${index + 1}`].toLowerCase() !== "n/a" && (
                    <Row className="p-1">
                      <input
                        // onChange={(e) => handleSetAnsAssessQuestion(e)}
                        defaultChecked={
                          evalQuestionsSet[question] == "B" ? true : false
                        }
                        value="B"
                        name={"question" + numString}
                        type="radio"
                      />
                      <span
                        className={
                          elem.correctAnswer == "B"
                            ? "fw-bold m-0 ms-3"
                            : "m-0 ms-3"
                        }
                      >
                        <em>B. </em>
                        {elem.choices[1][`choices-2-${index + 1}`]}
                      </span>
                    </Row>
                  )}
                  {elem.choices[2][`choices-3-${index + 1}`].toLowerCase() !== "n/a" && (
                    <Row className="p-1" hidden={elem.choices[2][`choices-3-${index + 1}`].toLowerCase() === "n/a"}>
                      <input
                        // onChange={(e) => handleSetAnsAssessQuestion(e)}
                        defaultChecked={
                          evalQuestionsSet[question] == "C" ? true : false
                        }
                        value="C"
                        name={"question" + numString}
                        type="radio"
                      />
                      <span
                        className={
                          elem.correctAnswer == "C"
                            ? "fw-bold m-0 ms-3"
                            : "m-0 ms-3"
                        }
                      >
                        <em>C. </em>
                        {elem.choices[2][`choices-3-${index + 1}`]}
                      </span>
                    </Row>
                  )}
                  {elem.choices[3][`choices-4-${index + 1}`].toLowerCase() !== "n/a" && (
                    <Row className="p-1 font-weight-bold" hidden={elem.choices[3][`choices-4-${index + 1}`].toLowerCase() === "n/a"}>
                      <input
                        // onChange={(e) => handleSetAnsAssessQuestion(e)}
                        defaultChecked={
                          evalQuestionsSet[question] == "D" ? true : false
                        }
                        value="D"
                        name={"question" + numString}
                        type="radio"
                      />
                      <span
                        className={
                          elem.correctAnswer == "D"
                            ? "fw-bold m-0 ms-3"
                            : "font-weight-bold m-0 ms-3"
                        }
                      >
                        <em>D. </em>
                        {elem.choices[3][`choices-4-${index + 1}`]}
                      </span>
                    </Row>
                  )}
                  <TextArea
                    hidden={elem.correctAnswer !== "n/a"}
                    name={"question" + numString}
                    className="mr align-self-start mt-md-0 mt-1 align-self-md-center"
                    value={evalQuestionsSet[question]}
                  />
                </>
              )}
          </Row>
        </React.Fragment>
      );
    });
  }

  return (
    <>
      <h5 style={{ fontSize: 16, color: 'blue' }} className='mb-3 d-flex flex-column gap-3'>
        <em>Note: item in bold is the correct answer.</em>
        <em>Note: Essay question is not included in the correct answer </em>
      </h5>
      <span className="fw-bold">
        <em>Total score: {score}</em>
      </span>
      {showAssessmentQuestions}
    </>
  );
}