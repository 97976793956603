/**
 * Title: uploadedILDP.js
 * Description: This is a file that contains the uploaded ILDP
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Form/uploadedILDP.js
 **/

/*
 * Changes made:
 * - 2024.14.10 | Harry Lagunsad | fix sonarlint issues and add prop types
 */

import { Button, Table, Tag, notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import React from 'react'

import { IDLPUploadByUserId } from '../../api/queries'

import PropTypes from 'prop-types'

export default class UploadedILDP extends React.Component {
  constructor() {
    super()
    this.state = {}
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    try {
      const user = this.props.userData

      const response = await API.graphql(
        graphqlOperation(IDLPUploadByUserId, { userID: user.id })
      )

      this.setState((prevState) => {
        const newData = response.data.IDLPUploadByUserId.items.map((row) => ({
          ...row,
          key: row.id,
        }))
        return { data: newData }
      })
    } catch (error) {
      console.log('Error in fetchData:', error)

      notification.error({
        message: 'Error',
        description:
          'An error occurred while loading data. Please contact System Admin for assistance.',
      })
    }
  }

  render() {
    const columns = [
      {
        title: 'FILE',
        dataIndex: 'file',
        key: 'id',
        render: (text, record) => <Tag color='geekblue'>{text}</Tag>,
      },

      {
        title: 'DATE',
        dataIndex: 'date',
        key: 'date',
      },
      {
        title: 'ACTION',
        key: 'action',
        render: (text, record) => (
          <span>
            <Button
              size='small'
              type='primary'
              onClick={() => {
                window.open(`${record.file}`, '_blank')
              }}
            >
              View
            </Button>
          </span>
        ),
      },
    ]

    return <Table bordered dataSource={this.state.newData} columns={columns} />
  }
}

UploadedILDP.propTypes = {
  userData: PropTypes.object,
}
