import { Modal } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import PropTypes from 'prop-types'

/**
 * PDSRemarksSubmit component renders a modal dialog for submitting remarks on a PDS (Personal Data Sheet).
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines if the modal is visible.
 * @param {function} props.onCancel - Callback function to handle the cancel action.
 * @param {function} props.onConfirm - Callback function to handle the confirm action.
 * @param {string} [props.title='Return PDS to Employee'] - The title of the modal.
 *
 * @returns {JSX.Element} The rendered PDSRemarksSubmit component.
 */
export default function PDSRemarksSubmit({
  open,
  onCancel,
  onConfirm,
  title = 'Return PDS to Employee',
}) {
  return (
    <Modal
      title={
        <div className='pds-remarks-delete-row-div'>
          <ExclamationCircleOutlined style={{ color: '#faad14' }} />
          {title}
        </div>
      }
      open={open}
      onCancel={onCancel}
      okText='Submit'
      cancelText='Cancel'
      okButtonProps={{
        danger: true,
        style: {
          backgroundColor: '#ff4d4f',
          borderColor: '#ff4d4f',
        },
      }}
      onOk={onConfirm}
    >
      <p style={{ fontSize: '16px', marginBottom: '16px' }}>
        Are you sure you want to return this PDS to the employee? This action
        cannot be undone.
      </p>
    </Modal>
  )
}

PDSRemarksSubmit.propTypes = {
  open: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
}
