import React, { useState } from 'react'

import EvaluationMakerPreview from './evaluationMakerPreview.js'
import AssessmentMakerPreview from './assessmentMakerPreview'

import {
  notification,
  Button,
  Modal,
  Row,
  Col,
  Input,
  Select,
  Radio,
} from 'antd'

import { PlusOutlined } from '@ant-design/icons'
import AcitivityQuestionMakerPreview from './ActivityQuestionMakerPreview.js'

const { TextArea } = Input

export default function AssessmentMaker({ setActivityQuestions, index }) {
  const [restart, setRestart] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [newQuestion, setNewQuestion] = useState([])

  const [questionsArray, setQuestionsArray] = useState([])

  const [openAssessmentPreview, setOpenAssessmentPreview] = useState(false)

  const handleOpenModal = () => {
    try {
      setOpenModal(true)
      setTimeout(() => {
        setRestart(true)
      }, 500)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCancel = () => {
    try {
      setOpenModal(false)
      setRestart(false)
      setNewQuestion([])
      setQuestionsArray([])
    } catch (error) {
      console.log(error)
    }
  }

  const removeQuestion = (index) => {
    try {
      const updatedQuestion2 = [...questionsArray]
      const updatedQuestion = [...newQuestion]
      updatedQuestion.splice(index, 1)
      updatedQuestion2.splice(index, 1)
      setNewQuestion(updatedQuestion)
      setQuestionsArray(updatedQuestion2)
    } catch (error) {
      console.log(error)
    }
  }

  const handleCorrectAnswerChange = (e, index) => {
    try {
      const updatedQuestionArray = [...questionsArray]
      const questionObjectToUpdate = { ...updatedQuestionArray[index] }
      questionObjectToUpdate.correctAnswer = e.target.value
      updatedQuestionArray[index] = questionObjectToUpdate
      setQuestionsArray(updatedQuestionArray)
    } catch (error) {
      console.log(error)
    }
  }

  const handleQuestionChange = (e, index) => {
    try {
      const updatedQuestionArray = [...questionsArray]
      const questionObjectToUpdate = { ...updatedQuestionArray[index] }
      questionObjectToUpdate.question = e.target.value
      updatedQuestionArray[index] = questionObjectToUpdate
      setQuestionsArray(updatedQuestionArray)
    } catch (error) {
      console.log(error)
    }
  }

  const handleAnswerChoicesChange = (e, index, choicesNumber) => {
    try {
      const choice = `choices-${choicesNumber}-${index + 1}`

      const updatedQuestionArray = [...questionsArray]
      const questionObjectToUpdate = { ...updatedQuestionArray[index] }
      questionObjectToUpdate.choices[choicesNumber - 1][choice] = e.target.value
      updatedQuestionArray[index] = questionObjectToUpdate
      setQuestionsArray(updatedQuestionArray)
    } catch (error) {
      console.log(error)
    }
  }

  const handleTitleChange = (scenarioIndex, event) => {
    const newQuestionsArray = [...questionsArray]
    newQuestionsArray[index].scenario[scenarioIndex].title = event.target.value
    setQuestionsArray(newQuestionsArray)
  }

  const handleDescriptionChange = (scenarioIndex, event) => {
    const newQuestionsArray = [...questionsArray]
    newQuestionsArray[index].scenario[scenarioIndex].description =
      event.target.value
    setQuestionsArray(newQuestionsArray)
  }

  const addScenario = () => {
    const newQuestionsArray = [...questionsArray]
    newQuestionsArray[index].scenario.push({ title: '', description: '' })
    setQuestionsArray(newQuestionsArray)
  }

  const addRow = newQuestion.map((questionType, index) => {
    try {
      const handleRadioChange = async (e, index) => {
        try {
          const { value } = e.target
          const updatedQuestions = [...newQuestion]

          if (value == 'trueOrFalse') {
            updatedQuestions[index] = 'trueOrFalse'
            setNewQuestion(updatedQuestions)
            const updatedQuestionArray = [...questionsArray]
            const questionObjectToUpdate = { ...updatedQuestionArray[index] }

            questionObjectToUpdate.choices[0][`choices-1-${index + 1}`] = ''
            questionObjectToUpdate.choices[1][`choices-2-${index + 1}`] = ''
            questionObjectToUpdate.choices[2][`choices-3-${index + 1}`] = 'n/a'
            questionObjectToUpdate.choices[3][`choices-4-${index + 1}`] = 'n/a'
            updatedQuestionArray[index] = questionObjectToUpdate
            questionObjectToUpdate.questionType = 'trueOrFalse'
            setQuestionsArray(updatedQuestionArray)
          }

          if (value == 'enumeration') {
            updatedQuestions[index] = 'enumeration'
            setNewQuestion(updatedQuestions)
            const updatedQuestionArray = [...questionsArray]
            const questionObjectToUpdate = { ...updatedQuestionArray[index] }

            questionObjectToUpdate.choices[0][`choices-1-${index + 1}`] = 'n/a'
            questionObjectToUpdate.choices[1][`choices-2-${index + 1}`] = 'n/a'
            questionObjectToUpdate.choices[2][`choices-3-${index + 1}`] = 'n/a'
            questionObjectToUpdate.choices[3][`choices-4-${index + 1}`] = 'n/a'
            updatedQuestionArray[index] = questionObjectToUpdate
            questionObjectToUpdate.correctAnswer = 'n/a'
            questionObjectToUpdate.questionType = 'enumeration'
            setQuestionsArray(updatedQuestionArray)
          }

          if (value === 'multipleChoice') {
            updatedQuestions[index] = 'multipleChoice'
            const updatedQuestionArray = [...questionsArray]
            const questionObjectToUpdate = { ...updatedQuestionArray[index] }

            questionObjectToUpdate.choices[0][`choices-1-${index + 1}`] = ''
            questionObjectToUpdate.choices[1][`choices-2-${index + 1}`] = ''
            questionObjectToUpdate.choices[2][`choices-3-${index + 1}`] = ''
            questionObjectToUpdate.choices[3][`choices-4-${index + 1}`] = ''
            questionObjectToUpdate.correctAnswer = 'empty'
            questionObjectToUpdate.questionType = 'multipleChoice'
            updatedQuestionArray[index] = questionObjectToUpdate
            setQuestionsArray(updatedQuestionArray)
            setNewQuestion(updatedQuestions)
          }

          if (value === 'caseStudy') {
            // delete the other queestionarray only left the first index of the array which is the case study if the user selects case study
            updatedQuestions[index] = 'caseStudy'
            const updatedQuestionArray = [questionsArray[0]]
            const questionObjectToUpdate = { ...updatedQuestionArray[index] }
            questionObjectToUpdate.scenario = [{ title: '', description: '' }]
            questionObjectToUpdate.correctAnswer = 'n/a'
            questionObjectToUpdate.questionType = 'caseStudy'
            updatedQuestionArray[index] = questionObjectToUpdate
            setQuestionsArray(updatedQuestionArray)
            setNewQuestion(updatedQuestions)
          }
        } catch (error) {
          console.log(error)
        }
      }

      return (
        <React.Fragment key={index}>
          <hr hidden={index === 0} />
          <Row className={'mb-4'}>
            <Col span={24}>
              <p className='text-center'>
                <em>
                  <strong>Question Type</strong>
                </em>
              </p>
              <Radio.Group
                onChange={(e) => handleRadioChange(e, index)}
                defaultValue='multipleChoice'
                className='d-flex justify-content-center'
              >
                <Radio value='multipleChoice'>Multiple Choice</Radio>
                <Radio value='trueOrFalse'>True or False</Radio>
                <Radio value='enumeration'>Essay</Radio>

                {index === 0 && <Radio value='caseStudy'>Case Study</Radio>}
                {/* <Radio value='caseStudy'>Case Study</Radio> */}
              </Radio.Group>
              {questionType !== 'caseStudy' && (
                <div>
                  <Row
                    className={
                      'mb-3 d-flex justify-content-between align-items-center'
                    }
                  >
                    <label htmlFor={`question-${index + 1}`}>
                      <strong>Question #{index + 1}:</strong>
                    </label>
                    <Button
                      onClick={() => removeQuestion(index)}
                      className='text-white bg-danger'
                    >
                      X
                    </Button>
                  </Row>
                  <Row className={'mb-3'}>
                    <TextArea
                      id={`question-${index + 1}`}
                      style={{ width: '100%' }}
                      rows={4}
                      value={questionsArray[index].question}
                      onChange={(e) => handleQuestionChange(e, index)}
                      maxLength={500}
                      // onChange={(e) => handleInputChange(e, index)}
                    />
                  </Row>
                  <Row className='flex-column'>
                    <Row
                      className='mt-3'
                      hidden={questionType === 'enumeration'}
                    >
                      <label htmlFor={`answer-${index + 1}`}>
                        Correct Answer:{' '}
                      </label>
                      <select
                        id={`answer-${index + 1}`}
                        className='ms-2 pe-2 ps-2 text-center'
                        value={questionsArray[index].correctAnswer}
                        // value={questionsArray[index].answer}
                        // onChange={(e) => handleInputChange(e, index)}
                        onChange={(e) => handleCorrectAnswerChange(e, index)}
                      >
                        <option
                          disabled
                          value='empty'
                          label='Click here to select'
                          className='text-muted'
                        ></option>
                        <option value='A'>A</option>
                        <option value='B'>B</option>
                        {questionType !== 'trueOrFalse' && (
                          <>
                            <option value='C'>C</option>
                            <option value='D'>D</option>
                          </>
                        )}
                      </select>
                    </Row>
                  </Row>
                  <label
                    className='mt-4'
                    hidden={questionType === 'enumeration'}
                  >
                    Answer Choices:{' '}
                  </label>
                  <Row
                    justify='space-between'
                    hidden={questionType === 'enumeration'}
                  >
                    <Col span={24} className='mt-3'>
                      <label
                        className={'me-2'}
                        htmlFor={`choices-1-${index + 1}`}
                      >
                        <em>A.</em>
                      </label>
                      <TextArea
                        id={`choices-1-${index + 1}`}
                        onChange={(e) => handleAnswerChoicesChange(e, index, 1)}
                        value={
                          questionsArray[index].choices[0][
                            `choices-1-${index + 1}`
                          ]
                        }
                        maxLength={500}
                        // onChange={(e) => handleInputChange(e, index)}
                      />
                    </Col>
                    <Col span={24} className='mt-2'>
                      <label
                        className={'me-2'}
                        htmlFor={`choices-2-${index + 1}`}
                      >
                        <em>B.</em>
                      </label>
                      <TextArea
                        id={`choices-2-${index + 1}`}
                        onChange={(e) => handleAnswerChoicesChange(e, index, 2)}
                        value={
                          questionsArray[index].choices[1][
                            `choices-2-${index + 1}`
                          ]
                        }
                        maxLength={500}
                        // onChange={(e) => handleInputChange(e, index)}
                      />
                    </Col>
                    <Col
                      span={24}
                      className='mt-2'
                      hidden={questionType === 'trueOrFalse'}
                    >
                      <label
                        className={'me-2'}
                        htmlFor={`choices-3-${index + 1}`}
                      >
                        <em>C.</em>
                      </label>
                      <TextArea
                        id={`choices-3-${index + 1}`}
                        // defaultValue={questionType === "trueOrFalse" ? "n/a" : ""}
                        onChange={(e) => handleAnswerChoicesChange(e, index, 3)}
                        value={
                          questionsArray[index].choices[2][
                            `choices-3-${index + 1}`
                          ]
                        }
                        maxLength={500}
                        // onChange={(e) => handleInputChange(e, index)}
                      />
                    </Col>
                    <Col
                      span={24}
                      className='mt-2'
                      hidden={questionType === 'trueOrFalse'}
                    >
                      <label
                        className={'me-2'}
                        htmlFor={`choices-4-${index + 1}`}
                      >
                        <em>D.</em>
                      </label>
                      <TextArea
                        id={`choices-4-${index + 1}`}
                        // defaultValue={questionType === "trueOrFalse" ? "n/a" : ""}
                        value={
                          questionsArray[index].choices[3][
                            `choices-4-${index + 1}`
                          ]
                        }
                        onChange={(e) => handleAnswerChoicesChange(e, index, 4)}
                        maxLength={500}
                        // onChange={(e) => handleInputChange(e, index)}
                      />
                    </Col>
                  </Row>
                </div>
              )}
              {questionType === 'caseStudy' && (
                <>
                  <Row
                    className={
                      'mb-3 d-flex justify-content-between align-items-center'
                    }
                  >
                    <label htmlFor={`question-${index + 1}`}>
                      <strong>Instruction</strong>
                    </label>
                    <Button
                      onClick={() => removeQuestion(index)}
                      className='text-white bg-danger'
                    >
                      X
                    </Button>
                  </Row>
                  <Row className={'mb-3'}>
                    <TextArea
                      id={`question-${index + 1}`}
                      style={{ width: '100%' }}
                      rows={4}
                      value={questionsArray[index].question}
                      onChange={(e) => handleQuestionChange(e, index)}
                      maxLength={500}
                    />
                  </Row>

                  {questionsArray[index].scenario.map(
                    (scenario, scenarioIndex) => (
                      <div key={scenarioIndex} className='my-4'>
                        <div>
                          <h4>Case Scenario #{scenarioIndex + 1}</h4>
                        </div>
                        <div className='ms-3 mt-2'>
                          <label htmlFor={`scenarioTitle${scenarioIndex + 1}`}>
                            Title
                          </label>
                          <Input
                            value={scenario.title}
                            id={`scenarioTitle${scenarioIndex + 1}`}
                            onChange={(e) =>
                              handleTitleChange(scenarioIndex, e)
                            }
                            maxLength={500}
                          />
                          <label
                            className='mt-2'
                            htmlFor={`scenarioTitle${scenarioIndex + 1}`}
                          >
                            Description
                          </label>
                          <TextArea
                            value={scenario.description}
                            id={`scenarioTitle${scenarioIndex + 1}`}
                            onChange={(e) =>
                              handleDescriptionChange(scenarioIndex, e)
                            }
                            maxLength={500}
                          />
                        </div>
                      </div>
                    )
                  )}
                  <div className='w-100 d-flex justify-content-center'>
                    <Button onClick={addScenario} className='mx-auto'>
                      Add Scenario
                    </Button>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </React.Fragment>
      )
    } catch (error) {
      console.log(error)
    }
  })

  const handleAddRow = () => {
    const updatedQuestions = [...newQuestion, ''] // Create a new array with the updated questions

    const index = updatedQuestions.length

    const newQuestionObject = {
      choices: [
        { [`choices-1-${index}`]: '' },
        { [`choices-2-${index}`]: '' },
        { [`choices-3-${index}`]: '' },
        { [`choices-4-${index}`]: '' },
      ],
      correctAnswer: 'empty',
      question: '',
      questionType: 'multipleChoice',
    }

    const newQuestionsArray = [...questionsArray, newQuestionObject]

    setNewQuestion(updatedQuestions)
    setQuestionsArray(newQuestionsArray)
  }

  const handleCreateQuestion = () => {
    if (questionsArray.length < 1) {
      return notification['info']({
        message: 'Info',
        description: 'No question to save.',
      })
    }

    let hasEmptyField = false

    questionsArray.forEach((question) => {
      if (question.question === '' || question.correctAnswer === 'empty') {
        hasEmptyField = true
        return notification['info']({
          message: 'Info',
          description: 'All fields must be filled.',
        })
      }

      if (
        question.questionType !== 'caseStudy' &&
        question.choices.some((choiceObj) => Object.values(choiceObj)[0] === '')
      ) {
        hasEmptyField = true
        return notification['info']({
          message: 'Info',
          description: 'All fields must be filled.',
        })
      }

      if (
        question.questionType == 'caseStudy' &&
        question.scenario.some(
          (scenario) => scenario.title === '' || scenario.description === ''
        )
      ) {
        hasEmptyField = true
        return notification['info']({
          message: 'Info',
          description: 'All fields must be filled.',
        })
      }
    })

    if (hasEmptyField) {
      return // Terminate the function
    }

    setOpenAssessmentPreview(true)
  }

  /* const createQuestions = () => {
    try {
      const questionsFound =
        document.querySelectorAll('[id*="question-"]').length >= 1;

      if (!questionsFound) {
        notification["info"]({
          message: "Info",
          description: "No question to save.",
        });
      } else {
        let holdErrors = [];
        const questionsInTextArea =
          document.querySelectorAll('[id*="question-"]');

        questionsInTextArea.forEach((elem) => {
          if (!elem.value.length) {
            holdErrors.push("");
          }
        });

        if (holdErrors.length) {
          notification["info"]({
            message: "Info",
            description: "All fields must be filled.",
          });
        } else {
          let newData = [];
          let holdBlankInputs = [];
          let missingAnswers = [];
          questionsInTextArea.forEach((elem, index) => {
            if (!newData[index]) {
              newData[index] = { question: "", choices: [], correctAnswer: "" };
            }

            const id = elem.id;
            const value = elem.value;

            if (id.includes("question")) {
              newData[index].question = value;
            }

            const inputElems =
              elem.parentElement.parentElement.querySelectorAll(
                '[id*="choices-"]'
              );

            inputElems.forEach((inputElement, inputIndex) => {
              let choice = {
                [`${inputElement.id}`]: inputElement.value,
              };

              if (inputElement.value.length) {
                newData[index].choices.push(choice);
              } else {
                holdBlankInputs.push("");
              }
            });

            const selectElems =
              elem.parentElement.parentElement.querySelectorAll(
                '[id*="answer-"]'
              );

            selectElems.forEach((selectElement) => {
              let answer = selectElement.value;

              if (selectElement.value && selectElement.value !== 'empty') {
                newData[index].correctAnswer = answer;
              } else {
                missingAnswers.push("");
              }
            });
          });

          if (holdBlankInputs.length) {
            notification["info"]({
              message: "Info",
              description: "All fields must be filled.",
            });
          } else {
            if (missingAnswers.length >= 1) {
              notification["info"]({
                message: "Info",
                description: "Missing correct answer/s.",
              });
            } else {
              setQuestionsArray(newData);
              setOpenAssessmentPreview(true);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }; */

  return (
    <>
      <Button ghost type='primary' onClick={handleOpenModal}>
        Create Activity Questionnaire
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Create Assessment Questionnaire'
        visible={openModal}
        footer={null}
        onCancel={handleCancel}
        width={800}
      >
        {restart ? (
          <>
            <Row>
              <Col span={24}>
                <>{addRow}</>
              </Col>
            </Row>
            <Row
              className={`mb-4 ${
                (questionsArray[0] &&
                  questionsArray[0].questionType !== 'casestudy') ||
                !questionsArray.length
                  ? 'justify-content-between'
                  : 'justify-content-end'
              }`}
            >
              {/* Hide the "add question" button if the first question is case study */}
              {((questionsArray[0] &&
                questionsArray[0].questionType !== 'caseStudy') ||
                !questionsArray.length) && (
                <Button
                  onClick={handleAddRow}
                  type='dashed'
                  className={'d-flex align-items-center'}
                >
                  <PlusOutlined />
                  Add question
                </Button>
              )}

              <Button onClick={() => handleCreateQuestion()}>Save</Button>
              {openAssessmentPreview && (
                <AcitivityQuestionMakerPreview
                  openAssessmentPreview={openAssessmentPreview}
                  setOpenAssessmentPreview={(e) => setOpenAssessmentPreview(e)}
                  // handleCreateQuestion={() => handleCreateQuestion()}
                  closeMotherModal={(e) => setOpenModal(e)}
                  setActivityQuestions={(e) => setActivityQuestions(e)}
                  questionsArray={questionsArray}
                  index={index}
                />
              )}
            </Row>
          </>
        ) : null}
      </Modal>
    </>
  )
}
