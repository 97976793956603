/**
 * Title: manageTrainingNumberOfParticipants.js
 * Description: This is a file that contains the component of managing the number of participants in a training
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/manageTrainingNumberOfParticipants.js
 *
 *Changes made:
 * - 2024.26.09  | Raymart Mojado | fix the data retain even not saving the data / add checkbox that only the selected department can be participate
 **/

import React, { useState, useEffect } from 'react'

import 'antd/dist/antd.css'

import {
  notification,
  Button,
  Modal,
  Select,
  InputNumber,
  Checkbox,
} from 'antd'
import { DeleteFilled } from '@ant-design/icons'

const ManageTrainingsNumberOfParticipants = ({
  departmentOptions,
  departments,
  setDepartments,
  listOfDepartmentsOrignal,
  departmentsLimitValue,
  setDepartmentsLimitValue,
  manageParticipantsModal,
  setManageParticipantsModal,
  participantLimit,
  setParticipantLimit,
}) => {
  /* Declaring state variables */
  const [listOfDepartmentsToHaveLimit, setListOfDepartmentsToHaveLimit] =
    useState([])

  const [theRestOfDeptLimit, setTheRestOfDeptLimit] = useState(0)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [participantLimitsData, setParticipantLimitsData] = useState(0)
  /* Will add new department to limit*/

  const handleAddDeptToLimit = () => {
    try {
      const arrOfDeptToLimit = [...listOfDepartmentsToHaveLimit]
      arrOfDeptToLimit.push({ '': '' })
      setListOfDepartmentsToHaveLimit(arrOfDeptToLimit)
    } catch (error) {
      console.log(error)
    }
  }

  const handleDeleteDeptToLimit = (index) => {
    const updatedDepartments = [...listOfDepartmentsToHaveLimit]
    updatedDepartments.splice(index, 1)
    setListOfDepartmentsToHaveLimit(updatedDepartments)
  }

  function isNestedArray(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        return true
      }
    }
    return false
  }

  useEffect(() => {
    if (listOfDepartmentsToHaveLimit.length === 0) {
      setIsCheckboxChecked(false)
    }
  }, [listOfDepartmentsToHaveLimit])

  const handleSave = () => {
    let deptObj = {}
    let hasInvalidValue = false

    if (
      (!isCheckboxChecked && theRestOfDeptLimit < 1) ||
      theRestOfDeptLimit === ''
    ) {
      return notification['info']({
        message: 'Info',
        description: 'Please enter limit of the rest of departments',
      })
    }

    if (participantLimitsData < 1) {
      return notification['info']({
        message: 'Info',
        description: 'Please enter max number of attendee',
      })
    }

    listOfDepartmentsToHaveLimit.forEach((dept) => {
      const key = Object.keys(dept)[0] // Get the key of the department
      const value = Object.values(dept)[0] // Get the value of the department
      if (dept === '') {
        hasInvalidValue = true
        return
      }
      if (value < 1) {
        hasInvalidValue = true
        return
      }
      deptObj[key] = value // Assign the value to the corresponding key in deptObj
    })

    departments.forEach((dept) => {
      if (!Object.hasOwn(deptObj, dept)) {
        deptObj[dept] = theRestOfDeptLimit
      }
    })

    if (hasInvalidValue) {
      return notification['info']({
        message: 'Info',
        description: 'All fields required',
      }) // Exit the handleSave function if an invalid value is found
    }
    setParticipantLimit(participantLimitsData)

    const combinedDepartments = isNestedArray(deptObj)
      ? deptObj.flat()
      : deptObj
    const updatedDepartmentsLimitValue = {
      allDepartments: combinedDepartments,
      selectedDepartments: listOfDepartmentsToHaveLimit,
      specificDepartmentAllowed: isCheckboxChecked,
      restDepartmentLimit: theRestOfDeptLimit,
    }
    setDepartmentsLimitValue(updatedDepartmentsLimitValue)
    setManageParticipantsModal(false)
    notification['success']({
      message: 'Success',
      description: 'Departments limit added',
    })
  }

  const handleSelectDeptChange = (value, index) => {
    const updatedListOfDepartmentsToHaveLimit = [
      ...listOfDepartmentsToHaveLimit,
    ]
    const department = { [value]: '' } // Create an object with the selected value as the key
    updatedListOfDepartmentsToHaveLimit[index] = department

    // remove selected dept in list. it will prevent duplicate dept to have limit
    const updatedDepartmentOpt = listOfDepartmentsOrignal.filter((dept) => {
      const isDeptSelected = updatedListOfDepartmentsToHaveLimit.some(
        (selDept) => {
          const selectedDeptName = Object.keys(selDept)[0] // Get the name of the selected department
          return selectedDeptName === dept
        }
      )
      return !isDeptSelected
    })

    setDepartments(updatedDepartmentOpt)
    setListOfDepartmentsToHaveLimit(updatedListOfDepartmentsToHaveLimit)
  }

  const handleLimitDeptChange = (value, index) => {
    const updatedListOfDepartmentsToHaveLimit = [
      ...listOfDepartmentsToHaveLimit,
    ]
    const department = updatedListOfDepartmentsToHaveLimit[index]
    const firstKey = Object.keys(department)[0]
    department[firstKey] = value
    setListOfDepartmentsToHaveLimit(updatedListOfDepartmentsToHaveLimit)
  }

  const onCancelModal = () => {
    setManageParticipantsModal(false)
    setParticipantLimitsData('')

    setListOfDepartmentsToHaveLimit([])
  }

  // Function to calculate the mode (most common value) from an array of numbers
  const calculateMode = (numbers) => {
    const numFrequency = {}
    let maxFrequency = 0
    let mode

    numbers.forEach((num) => {
      numFrequency[num] = (numFrequency[num] || 0) + 1
      if (numFrequency[num] > maxFrequency) {
        maxFrequency = numFrequency[num]
        mode = num
      }
    })

    return mode
  }

  // const getInitialDeptValues = () => {
  //   try {
  //     // Get an array of department limit values
  //     const departmentLimits = departmentsLimitValue.map((dept) => {
  //       const value = Object.values(dept.allDepartments)[0]
  //       return value || 0 // If the value is empty, consider it as 0
  //     })
  //     console.log(departmentLimits, 'departmentLimits')
  //     // Calculate the mode (most common value) of department limits
  //     const modeLimit = calculateMode(departmentLimits)

  //     setTheRestOfDeptLimit(modeLimit)
  //   } catch (err) {
  //     console.log(err)
  //   }
  // }

  useEffect(() => {
    if (participantLimit) {
      setParticipantLimitsData(participantLimit)
    }
    if (departmentsLimitValue?.restDepartmentLimit) {
      setTheRestOfDeptLimit(departmentsLimitValue.restDepartmentLimit)
    } else {
      setTheRestOfDeptLimit(0)
    }
    if (departmentsLimitValue?.selectedDepartments?.length > 0) {
      setListOfDepartmentsToHaveLimit(
        departmentsLimitValue?.selectedDepartments
      )
    } else {
      setListOfDepartmentsToHaveLimit([])
    }
    if (departmentsLimitValue?.specificDepartmentAllowed) {
      setIsCheckboxChecked(true)
    }
  }, [manageParticipantsModal])

  const handleCheckboxChange = (e) => {
    setIsCheckboxChecked(e.target.checked)
    if (e.target.checked) {
      setTheRestOfDeptLimit(0)
    }
  }

  return (
    <>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Manage Participants Limit'
        visible={manageParticipantsModal}
        footer={null}
        onCancel={onCancelModal}
        width={800}
      >
        <div>
          <div className='d-flex w-100'>
            <div className='d-flex flex-column gap-2 w-100'>
              <label hidden={listOfDepartmentsToHaveLimit.length === 0}>
                Specific department limit:
              </label>
              {listOfDepartmentsToHaveLimit.map((dept, index) => {
                const department = listOfDepartmentsToHaveLimit[index]
                const firstKey = Object.keys(department)[0]

                return (
                  <React.Fragment key={index}>
                    <div className='d-flex gap-2' id={index}>
                      <Select
                        id={`dept-${index}`}
                        className='w-100'
                        value={firstKey}
                        showSearch
                        placeholder='Select a department'
                        optionFilterProp='children'
                        onChange={(value) =>
                          handleSelectDeptChange(value, index)
                        }
                        filterOption={(input, option) =>
                          (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={departmentOptions}
                      />
                      <InputNumber
                        // className='col-2'
                        value={department[firstKey]}
                        disabled={listOfDepartmentsToHaveLimit[index] === ''}
                        onChange={(value) =>
                          handleLimitDeptChange(value, index)
                        }
                        id={`limit-${index}`}
                        placeholder='0'
                        min='1'
                      />
                      <Button
                        className='d-flex justify-content-center align-items-center'
                        onClick={() => handleDeleteDeptToLimit(index)}
                      >
                        <DeleteFilled className='text-danger' />
                      </Button>
                    </div>
                  </React.Fragment>
                )
              })}
            </div>
          </div>
          <div className='mt-5'>
            {listOfDepartmentsToHaveLimit.length === 0 ? (
              <Button onClick={handleAddDeptToLimit}>
                Add department to limit
              </Button>
            ) : (
              <Button onClick={handleAddDeptToLimit}>
                Add another department to limit
              </Button>
            )}
          </div>
          {listOfDepartmentsToHaveLimit.length !== 0 && (
            <div className='d-flex gap-3 mt-5'>
              <Checkbox
                onChange={handleCheckboxChange}
                checked={isCheckboxChecked}
                className='d-flex justify-content-center'
              >
                Only the specific department is allowed.
              </Checkbox>
            </div>
          )}
          {!isCheckboxChecked && (
            <div className='d-flex gap-3 mt-5'>
              <label className='align-self-end'>
                The rest of department limit:
              </label>
              <InputNumber
                disabled={isCheckboxChecked}
                value={theRestOfDeptLimit}
                onChange={(value) => setTheRestOfDeptLimit(value)}
                className='d-flex justify-content-center'
                placeholder='0'
                min='0'
              />
            </div>
          )}
          <div className='d-flex gap-3 mt-5'>
            <label className='align-self-end'>Max number of attendee:</label>
            <InputNumber
              value={participantLimitsData}
              onChange={(value) => setParticipantLimitsData(value)}
              className='d-flex justify-content-center'
              placeholder='0'
              min='1'
            />
          </div>
          <div className='d-flex justify-content-end mt-4'>
            <Button onClick={handleSave} className='bg-primary text-white'>
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ManageTrainingsNumberOfParticipants
