/**
 * Title: grafanaDashboard.js
 * Description: This is a file that contains the Grafana Dashboard
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/grafanaDashboard.js
 **/

import { useState } from 'react'

import { Col, Row, Tabs, Card, Select, DatePicker, Button } from 'antd'

import TotalUsers from './totalUsers.js'
import listOfDepartments from '../../utilities/dept.js'

import FormCounter from './formCounter.js'

import moment from 'moment'

const { TabPane } = Tabs
const { Option } = Select

const dept = listOfDepartments()
dept.push('All Departments')

export default function GrafanaDashboard({
  departmentD,
  handleChangeDepartment,
  assignedDepartment,
  getFourPanels,
  showCurrentPeriod,
  showDataByPeriod,
  handleShowCurrentPeriodInDemographicData,
  userData,
}) {
  const role = userData.role

  const [dateFrom, setDateFrom] = useState('1688169600')
  const [dateTo, setDateTo] = useState('1701307018')
  const [holdDateFrom, setHoldDateFrom] = useState(null)
  const [holdDateTo, setHoldDateTo] = useState(null)

  const [applyButton, setApplyButton] = useState(true)

  const [currentKey, setCurrentKey] = useState('1')

  /**
   * @description function that changes the date from the date picker
   * @function onChangeDateFrom
   * @param {object} date - the date object
   * @param {string} dateString - the date string
   * @returns {void} - no return value
   */
  const onChangeDateFrom = (date, dateString) => {
    setHoldDateFrom(moment(dateString).unix())
    if (holdDateTo && dateString) {
      setApplyButton(false)
    } else {
      setApplyButton(true)
    }
  }

  /**
   * @description function that changes the date from the date picker
   * @function onChangeDateTo
   * @param {object} date - the date object
   * @param {string} dateString - the date string
   * @returns {void} - no return value
   */
  const onChangeDateTo = (date, dateString) => {
    setHoldDateTo(moment(dateString).unix())
    if (holdDateFrom && dateString) {
      setApplyButton(false)
    } else {
      setApplyButton(true)
    }
  }

  // generate JSDOC comment or documentation for this function with @description tag
  /**
   * @description function that applies the date range to the grafana panels
   * @function handleApplyDates
   * @returns {void} - no return value
   * @param {void} - no parameter
   * @returns {Promise<void>} - Promise<void>
   */
  const handleApplyDates = () => {
    setDateFrom(holdDateFrom)
    setDateTo(holdDateTo)
    setApplyButton(true)
  }

  const onKeyChange = (e) => {
    setCurrentKey(e.toString())
  }

  return (
    <>
      <Tabs onChange={onKeyChange} defaultActiveKey='1' centered>
        <TabPane tab='Demographic Data and e-TNA Result' key='1'>
          {currentKey === '1' ? (
            <div>
              <Card type='inner' title='Demographic Data'>
                <Row
                  gutter={24}
                  justify='center'
                  style={{
                    maxWidth: '100%',
                    marginLeft: 10,
                    marginRight: 10,
                    marginBottom: 15,
                  }}
                >
                  <Col className='gutter-row' span={12}>
                    <TotalUsers />
                  </Col>
                </Row>

                <Row justify='center'>
                  <Col span={10}>
                    {role === 'admin' ? (
                      <div className='d-flex flex-row-reverse justify-content-center'>
                        <Select
                          value={departmentD}
                          showSearch
                          style={{ width: '85%', marginBottom: 50 }}
                          placeholder='Search to Department'
                          optionFilterProp='children'
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          filterSort={(optionA, optionB) =>
                            optionA.children
                              .toLowerCase()
                              .localeCompare(optionB.children.toLowerCase())
                          }
                          onChange={(e) => handleChangeDepartment(e)}
                        >
                          {dept.map((obj, index) => (
                            <Option key={index} value={obj}>
                              {obj}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    ) : null}
                  </Col>
                  <Col span={5}>
                    <label className='me-2'>Date Start:</label>
                    <DatePicker onChange={onChangeDateFrom} />
                  </Col>
                  <Col span={5}>
                    <label className='me-2'>Date End:</label>
                    <DatePicker onChange={onChangeDateTo} />
                  </Col>

                  <Col span={4}>
                    <Button
                      onClick={handleApplyDates}
                      disabled={applyButton}
                      type='primary'
                    >
                      Apply
                    </Button>
                  </Col>
                </Row>

                {/*number of responses grafana panel*/}
                <Row>
                  <Col span={24}>
                    <Row justify='center'>
                      <iframe
                        className='w-100 '
                        src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Barangay%20and%20Community%20Relations%20Department&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=84`}
                        title='Grafana Graph'
                        height={500}
                        frameBorder={0}
                      />
                    </Row>
                  </Col>
                </Row>

                <Row>
                  {/*number of age range grafana panel*/}
                  <Col span={12}>
                    {departmentD === 'All Departments' ? (
                      <>
                        <iframe
                          className='w-100'
                          src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=46`}
                          title='Grafana Graph'
                          height={300}
                          frameBorder={0}
                        />
                      </>
                    ) : (
                      <>
                        <iframe
                          className='w-100 d-md-flex flex-column'
                          src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                            role === 'admin'
                              ? departmentD.replace(/\s/g, '%20')
                              : assignedDepartment.replace(/\s/g, '%20')
                          }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=63`}
                          title='Grafana Graph'
                          height={300}
                          frameBorder={0}
                        />
                      </>
                    )}
                  </Col>

                  {/*number of gender grafana panel*/}
                  <Col span={12}>
                    {departmentD === 'All Departments' ? (
                      <>
                        <iframe
                          className='w-100'
                          src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=50`}
                          title='Grafana Graph'
                          height={300}
                          frameBorder={0}
                        />
                      </>
                    ) : (
                      <>
                        <iframe
                          className='w-100 d-md-flex flex-column'
                          src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                            role === 'admin'
                              ? departmentD.replace(/\s/g, '%20')
                              : assignedDepartment.replace(/\s/g, '%20')
                          }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=30`}
                          title='Grafana Graph'
                          height={300}
                          frameBorder={0}
                        />
                      </>
                    )}
                  </Col>
                </Row>

                <>
                  <Row>
                    {/*number of employees per employee type grafana panel*/}
                    <Col className='gutter-row' span={12}>
                      <Row justify='center'>
                        {departmentD === 'All Departments' ? (
                          <Col span={24}>
                            <iframe
                              src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=71`}
                              width={600}
                              title='Grafana Graph'
                              height={300}
                              frameBorder={0}
                            ></iframe>
                          </Col>
                        ) : (
                          <>
                            <iframe
                              src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                                role === 'admin'
                                  ? departmentD.replace(/\s/g, '%20')
                                  : assignedDepartment.replace(/\s/g, '%20')
                              }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=72`}
                              width={600}
                              title='Grafana Graph'
                              height={300}
                              frameBorder={0}
                            ></iframe>
                          </>
                        )}
                      </Row>
                    </Col>

                    {/*number of employees highest educational attainment*/}
                    <Col className='gutter-row' span={12}>
                      <Row justify='center'>
                        {departmentD === 'All Departments' ? (
                          <Col span={24}>
                            <iframe
                              src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=73`}
                              width={600}
                              title='Grafana Graph'
                              height={300}
                              frameBorder={0}
                            ></iframe>
                          </Col>
                        ) : (
                          <>
                            <iframe
                              src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                                role === 'admin'
                                  ? departmentD.replace(/\s/g, '%20')
                                  : assignedDepartment.replace(/\s/g, '%20')
                              }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=74`}
                              width={600}
                              title='Grafana Graph'
                              height={300}
                              frameBorder={0}
                            ></iframe>
                          </>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      maxWidth: '100%',
                      marginLeft: 10,
                      marginRight: 10,
                      marginTop: 10,
                    }}
                    justify='center'
                    gutter={24}
                    className='mt-5'
                  >
                    {/*method of training or intervention grafana panels*/}
                    <Col span={12}>
                      {departmentD === 'All Departments' ? (
                        <>
                          <iframe
                            className='w-100'
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=65`}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </>
                      ) : (
                        <>
                          <iframe
                            className='w-100'
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                              role === 'admin'
                                ? departmentD.replace(/\s/g, '%20')
                                : assignedDepartment.replace(/\s/g, '%20')
                            }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=67`}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </>
                      )}
                    </Col>

                    {/*influence to register by a training program grafana panel*/}
                    <Col span={12}>
                      {departmentD === 'All Departments' ? (
                        <>
                          <iframe
                            className='w-100'
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=77`}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </>
                      ) : (
                        <>
                          <>
                            <iframe
                              className='w-100'
                              src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                                role === 'admin'
                                  ? departmentD.replace(/\s/g, '%20')
                                  : assignedDepartment.replace(/\s/g, '%20')
                              }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=78`}
                              title='Grafana Graph'
                              height={300}
                              frameBorder={0}
                            ></iframe>
                          </>
                        </>
                      )}
                    </Col>
                  </Row>
                  <Row
                    style={{
                      maxWidth: '100%',
                      marginLeft: 10,
                      marginRight: 10,
                      marginTop: 10,
                    }}
                    justify='center'
                    gutter={24}
                    className='mt-5'
                  >
                    {/*computer and technical training programs grafana panel*/}
                    <Col span={12}>
                      {departmentD === 'All Departments' ? (
                        <>
                          <iframe
                            className='w-100'
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=79`}
                            title='Grafana Graph'
                            height={500}
                            frameBorder={0}
                          ></iframe>
                        </>
                      ) : (
                        <>
                          <iframe
                            className='w-100'
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                              role === 'admin'
                                ? departmentD.replace(/\s/g, '%20')
                                : assignedDepartment.replace(/\s/g, '%20')
                            }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=80`}
                            title='Grafana Graph'
                            height={500}
                            frameBorder={0}
                          ></iframe>
                        </>
                      )}
                    </Col>

                    {/*government specific training programs grafana panels*/}
                    <Col span={12}>
                      {departmentD === 'All Departments' ? (
                        <>
                          <iframe
                            className='w-100'
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=81`}
                            title='Grafana Graph'
                            height={500}
                            frameBorder={0}
                          ></iframe>
                        </>
                      ) : (
                        <>
                          <iframe
                            className='w-100'
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                              role === 'admin'
                                ? departmentD.replace(/\s/g, '%20')
                                : assignedDepartment.replace(/\s/g, '%20')
                            }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=82`}
                            title='Grafana Graph'
                            height={500}
                            frameBorder={0}
                          ></iframe>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              </Card>

              <Card type='inner' title='Competency Result' className='mt-5'>
                <Row
                  gutter={24}
                  className='mt-5'
                  justify='center'
                  style={{
                    maxWidth: '100%',
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <Col className='gutter-row' span={16}>
                    <Row justify='center'>
                      {departmentD === 'All Departments' ? (
                        <Col span={24}>
                          <iframe
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=64`}
                            width={600}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </Col>
                      ) : (
                        <>
                          <iframe
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                              role === 'admin'
                                ? departmentD.replace(/\s/g, '%20')
                                : assignedDepartment.replace(/\s/g, '%20')
                            }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=32`}
                            width={600}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row
                  gutter={24}
                  className='mt-5'
                  justify='center'
                  style={{
                    maxWidth: '100%',
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <Col className='gutter-row' span={12}>
                    <Row justify='center'>
                      {departmentD === 'All Departments' ? (
                        <Col span={20}>
                          <iframe
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=75`}
                            width={600}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </Col>
                      ) : (
                        <>
                          <iframe
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                              role === 'admin'
                                ? departmentD.replace(/\s/g, '%20')
                                : assignedDepartment.replace(/\s/g, '%20')
                            }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=76`}
                            width={600}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </>
                      )}
                    </Row>
                  </Col>
                  <Col className='gutter-row' span={12}>
                    <Row justify='center'>
                      {departmentD === 'All Departments' ? (
                        <Col span={20}>
                          <iframe
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=58`}
                            width={600}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </Col>
                      ) : (
                        <>
                          <iframe
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                              role === 'admin'
                                ? departmentD.replace(/\s/g, '%20')
                                : assignedDepartment.replace(/\s/g, '%20')
                            }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=68`}
                            width={600}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
                <Row
                  gutter={24}
                  className='mt-5'
                  justify='center'
                  style={{
                    maxWidth: '100%',
                    marginLeft: 10,
                    marginRight: 10,
                    marginTop: 10,
                  }}
                >
                  <Col className='gutter-row' span={12}>
                    <Row justify='center'>
                      {departmentD === 'All Departments' ? (
                        <Col span={20}>
                          <iframe
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Office%20of%20the%20City%20Administrator&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=69`}
                            width={600}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </Col>
                      ) : (
                        <>
                          <iframe
                            src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                              role === 'admin'
                                ? departmentD.replace(/\s/g, '%20')
                                : assignedDepartment.replace(/\s/g, '%20')
                            }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=70`}
                            width={600}
                            title='Grafana Graph'
                            height={300}
                            frameBorder={0}
                          ></iframe>
                        </>
                      )}
                    </Row>
                  </Col>
                </Row>
              </Card>
            </div>
          ) : null}
        </TabPane>
        <TabPane
          className={'d-flex justify-content-center'}
          tab='Evaluation Form Result'
          key='2'
        >
          {currentKey === '2' ? (
            <Card
              className='col-10'
              type='inner'
              style={{ marginTop: '10px' }}
              title='Evaluation Form Result'
            >
              <Row justify='center'>
                <Col span={10}>
                  {role === 'admin' ? (
                    <div className='d-flex flex-row-reverse justify-content-center'>
                      <Select
                        value={departmentD}
                        showSearch
                        style={{ width: '85%', marginBottom: 50 }}
                        placeholder='Search to Department'
                        optionFilterProp='children'
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(e) => handleChangeDepartment(e)}
                      >
                        {dept.map((obj, index) => (
                          <Option key={index} value={obj}>
                            {obj}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  ) : null}
                </Col>
                <Col span={5}>
                  <label className='me-2'>Date Start:</label>
                  <DatePicker onChange={onChangeDateFrom} />
                </Col>
                <Col span={5}>
                  <label className='me-2'>Date End:</label>
                  <DatePicker onChange={onChangeDateTo} />
                </Col>

                <Col span={4}>
                  <Button
                    onClick={handleApplyDates}
                    disabled={applyButton}
                    type='primary'
                  >
                    Apply
                  </Button>
                </Col>
              </Row>
              <Row>
                {departmentD === 'All Departments' ? (
                  <iframe
                    className='w-100'
                    src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&var-Department=Barangay%20and%20Community%20Relations%20Department&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=85`}
                    title='Grafana Graph'
                    height={300}
                    frameBorder={0}
                  ></iframe>
                ) : (
                  <iframe
                    className='w-100'
                    src={`https://ssdd.sparksoft-apps.com/d-solo/FSOO2E8Vz/qcetna-ver-2-copy?orgId=1&refresh=5m&var-Department=${
                      role === 'admin'
                        ? departmentD.replace(/\s/g, '%20')
                        : assignedDepartment.replace(/\s/g, '%20')
                    }&var-dateRangeFrom=${dateFrom}&var-dateRangeTo=${dateTo}&theme=light&panelId=40`}
                    title='Grafana Graph'
                    height={300}
                    frameBorder={0}
                  ></iframe>
                )}
              </Row>
            </Card>
          ) : null}
        </TabPane>
        <TabPane
          className={'d-flex justify-content-center'}
          tab='e-TNA and ILDP forms submitted or uploaded'
          key='3'
        >
          {currentKey === '3' ? (
            <Card
              className='col-10'
              type='inner'
              style={{ marginTop: '10px' }}
              title='Totals'
            >
              <FormCounter />
            </Card>
          ) : null}
        </TabPane>
      </Tabs>
    </>
  )
}
