import React, { useState } from 'react'
import { numToString } from '../../utilities/numToString'
import { Button, Modal, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'

export default function OnlineTrainingActivityViewAnswer({
  viewUserAnswer,
  activityQuestion,
  activityNumber,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false)

  return (
    <div className='mb-4'>
      <Button
        className='bg-success text-white'
        onClick={() => setIsModalVisible(true)}
      >
        View
      </Button>
      {isModalVisible && (
        <Modal
          maskClosable={false}
          destroyOnClose={true}
          title={`Activity ${activityNumber[activityNumber.length - 1]}`}
          width={1000}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button key='back' onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>,
          ]}
        >
          {activityQuestion &&
            activityQuestion.map((elem, index) => {
              const numString = numToString(index)
              return (
                <div key={index} className='mb-4'>
                  {elem.questionType !== 'caseStudy' && (
                    <>
                      <Row className='mt-4'>
                        <span
                          style={{
                            fontSize: 16,
                            textAlign: 'left',
                          }}
                        >
                          <strong>
                            {index + 1}. {elem.question}
                          </strong>
                        </span>
                      </Row>
                      <Row
                        align='center'
                        justify='center'
                        className='flex-column ms-3 mt-2'
                      >
                        {elem.choices[0] &&
                          elem.choices[1] &&
                          elem.choices[2] &&
                          elem.choices[3] && (
                            <>
                              {elem.choices[0][
                                `choices-1-${index + 1}`
                              ].toLowerCase() !== 'n/a' && (
                                <div
                                  className='d-flex p-1'
                                  hidden={
                                    elem.choices[0][
                                      `choices-1-${index + 1}`
                                    ].toLowerCase() === 'n/a'
                                  }
                                >
                                  <input
                                    checked={
                                      viewUserAnswer[0][
                                        `answer${numString}`
                                      ] === 'A'
                                    }
                                    value='A'
                                    name={'question' + numString}
                                    className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                    type='radio'
                                    id={`question${numString}-choice-A`}
                                    readOnly
                                  />
                                  <label
                                    className='m-0 ms-3 text-start'
                                    htmlFor={`question${numString}-choice-A`}
                                  >
                                    <strong>
                                      <em>A. </em>
                                    </strong>
                                    {elem.choices[0][`choices-1-${index + 1}`]}
                                  </label>
                                </div>
                              )}
                              {elem.choices[1][
                                `choices-2-${index + 1}`
                              ].toLowerCase() !== 'n/a' && (
                                <div className='d-flex p-1'>
                                  <input
                                    checked={
                                      viewUserAnswer[0][
                                        `answer${numString}`
                                      ] === 'B'
                                    }
                                    value='B'
                                    name={'question' + numString}
                                    className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                    type='radio'
                                    id={`question${numString}-choice-B`}
                                    readOnly
                                  />
                                  <label
                                    className='m-0 ms-3 text-start'
                                    htmlFor={`question${numString}-choice-B`}
                                  >
                                    <strong>
                                      <em>B. </em>
                                    </strong>
                                    {elem.choices[1][`choices-2-${index + 1}`]}
                                  </label>
                                </div>
                              )}
                              {elem.choices[2][
                                `choices-3-${index + 1}`
                              ].toLowerCase() !== 'n/a' && (
                                <div className='d-flex p-1'>
                                  <input
                                    checked={
                                      viewUserAnswer[0][
                                        `answer${numString}`
                                      ] === 'C'
                                    }
                                    value='C'
                                    name={'question' + numString}
                                    className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                    type='radio'
                                    id={`question${numString}-choice-C`}
                                    readOnly
                                  />
                                  <label
                                    className='m-0 ms-3 text-start'
                                    htmlFor={`question${numString}-choice-C`}
                                  >
                                    <strong>
                                      <em>C. </em>
                                    </strong>
                                    {elem.choices[2][`choices-3-${index + 1}`]}
                                  </label>
                                </div>
                              )}
                              {elem.choices[3][
                                `choices-4-${index + 1}`
                              ].toLowerCase() !== 'n/a' && (
                                <div className='d-flex p-1'>
                                  <input
                                    checked={
                                      viewUserAnswer[0][
                                        `answer${numString}`
                                      ] === 'D'
                                    }
                                    value='D'
                                    name={'question' + numString}
                                    className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                                    type='radio'
                                    id={`question${numString}-choice-D`}
                                    readOnly
                                  />
                                  <label
                                    className='m-0 ms-3 text-start'
                                    htmlFor={`question${numString}-choice-D`}
                                  >
                                    <strong>
                                      <em>D. </em>
                                    </strong>
                                    {elem.choices[3][`choices-4-${index + 1}`]}
                                  </label>
                                </div>
                              )}
                              <TextArea
                                readOnly
                                hidden={elem.correctAnswer !== 'n/a'}
                                value={viewUserAnswer[0][`answer${numString}`]}
                                name={'question' + numString}
                                className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                              />
                            </>
                          )}
                      </Row>
                    </>
                  )}

                  {elem.questionType === 'caseStudy' && (
                    <>
                      <Row
                        className={
                          'mb-3 d-flex justify-content-between align-items-center'
                        }
                      >
                        <label htmlFor={`question-${index + 1}`}>
                          <strong>Instructions:</strong>
                        </label>
                      </Row>
                      <Row className={'mb-3'}>
                        <p>{elem?.question}</p>
                      </Row>

                      {elem?.scenario.map((scenario, scenarioIndex) => (
                        <div key={scenarioIndex} className='my-4'>
                          <div>
                            <h4>
                              Case Scenario #{scenarioIndex + 1}:{' '}
                              {scenario.title}{' '}
                            </h4>
                            <p>{scenario.description}</p>
                          </div>
                        </div>
                      ))}
                      <div>
                        <p>Select a case scenario before uploading:</p>
                        <div className='d-flex gap-3'>
                          {elem?.scenario.map((scenario, scenarioIndex) => (
                            <div key={scenarioIndex} className='fw-bold'>
                              <input
                                /*  defaultChecked={
                                  caseScenario ===
                                  `caseScenario-${scenarioIndex}`
                                } */
                                type='radio'
                                name='caseScenario'
                                id={`caseScenario-${scenarioIndex}`}
                              />{' '}
                              Case # {scenarioIndex + 1}
                            </div>
                          ))}
                        </div>
                      </div>
                      <input className='mt-4' type='file' readOnly />
                    </>
                  )}
                </div>
              )
            })}
        </Modal>
      )}
      {/* <div className='d-flex justify-content-center'>
        <Button
          disabled={
            selectedActivity &&
            selectedActivity[0]?.questionType === 'caseStudy' &&
            !uploadedFile
          }
          hidden={!selectedActivity}
          onClick={handleSubmitActivity}
          size='large'
          className=''
        >
          Submit
        </Button>
      </div>
      {!selectedActivity && (
        <div className='p-5 text-center'>
          <h4>
            This has no activity. You can proceed to the next video. by clicking
            the "Next" button.
          </h4>
        </div>
      )} */}
    </div>
  )
}
