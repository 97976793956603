import {
  parseISO,
  format,
  isToday,
  isThisWeek,
  formatDistanceToNow,
  isValid,
} from 'date-fns'

export const dateFormatter = (timestamp) => {
  // Check if timestamp is valid
  if (!timestamp) {
    console.error('Invalid timestamp:', timestamp)
    return 'Invalid date'
  }

  // Parse the ISO date
  const date = parseISO(timestamp)

  // Check if the parsed date is valid
  if (!isValid(date)) {
    console.error('Invalid date value:', timestamp)
    return 'Invalid date'
  }

  const today = isToday(date)
  const thisWeek = isThisWeek(date)

  let formattedDate

  if (today) {
    // Within the last 24 hours, display time in a human-readable format
    formattedDate = formatDistanceToNow(date, { addSuffix: true })
  } else if (thisWeek) {
    // Within the current week, display the day of the week (e.g., "Mon")
    formattedDate = format(date, 'eee')
  } else {
    // Exceeds the current week, display the date in the format MM/dd/yyyy
    formattedDate = format(date, 'MM/dd/yy')
  }

  return formattedDate
}
