/**
 * Title: createForm2.js
 * Description: This is a file that contains the create2 form for the employee
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Form/createForm2.js
 **/

/*
 * Changes made:
 * - 2024.09.08 | Harry Lagunsad | added an input pre-sanitation for the name fields pre-fill, for excessive white spaces
 * - 2024.09.08 | Harry Lagunsad | added try catch the functions
 * - 2024.09.08 | Harry Lagunsad | made some refactoring as per sonarlint suggestions
 * - 2024.14.08 | Harry Lagunsad | refactor Forms components to avoid multiple render when changing tabs and answering
 *   the form fields
 * - 2024.14.10 | Harry Lagunsad | fix sonarlint issues and add prop types
 * - 2024.06.11 | Harry Lagunsad | add suffix validation function for name field to add only real suffix
 * - 2024.06.11 | Harry Lagunsad | add notification messages to catch block errors
 */
import React from 'react'

import { Breadcrumb, Card, Tabs, notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import moment from 'moment'
import PropTypes from 'prop-types'

import { Form } from '@formio/react'

import { createForm2, updateUser } from '../../api/mutations'
import {
  FormByUserId,
  listUsers,
  registrationByUserID,
} from '../../api/queries'
import './createForm2.css'

const { TabPane } = Tabs

let remarks1 = ''
let remarks2 = ''
let remarks3 = ''
let remarks4 = ''
let remarks5 = ''
let remarks6 = ''
let remarks7 = ''
let remarks8 = ''
let remarks9 = ''
let remarks10 = ''

let remarks11 = ''
let remarks12 = ''
let remarks13 = ''
let remarks14 = ''
let remarks15 = ''
let remarks16 = ''
let remarks17 = ''
let remarks18 = ''
let remarks19 = ''
let remarks20 = ''

let remarks21 = ''
let remarks22 = ''
let remarks23 = ''
let remarks24 = ''
let remarks25 = ''
let remarks26 = ''
let remarks27 = ''
let remarks28 = ''
let remarks29 = ''
let remarks30 = ''

let remarks31 = ''
let remarks32 = ''
let remarks33 = ''
let remarks34 = ''
let remarks35 = ''
let remarks36 = ''
let remarks37 = ''
let remarks38 = ''
let remarks39 = ''

// english
let panel3Columns3DateTime = ''
let panel3Columns4DateTime = ''
let panel3Columns4DateTime2 = ''
let panel3Columns4DateTime3 = ''
let panel3Columns4DateTime4 = ''
let panel3Columns4DateTime26 = ''
let panel3Columns4DateTime25 = ''
let panel3Columns4DateTime20 = ''
let panel3Columns4DateTime18 = ''
let panel3Columns4DateTime30 = ''
let panel3Columns4DateTime29 = ''
let panel3Columns4DateTime28 = ''
let panel3Columns4DateTime27 = ''
let panel3Columns4DateTime33 = ''
let panel3Columns4DateTime32 = ''
let panel3Columns4DateTime31 = ''
let panel3Columns3DateTime2 = ''
let panel3Columns3DateTime3 = ''
let panel3Columns3DateTime5 = ''
let panel3Columns3DateTime16 = ''
let panel3Columns3DateTime6 = ''
let panel3Columns3DateTime4 = ''
let panel3Columns3DateTime13 = ''
let panel3Columns3DateTime9 = ''
let panel3Columns4DateTime19 = ''
let panel3Columns3DateTime17 = ''
let panel3Columns4DateTime5 = ''
let panel3Columns4DateTime34 = ''
let panel3Columns3DateTime14 = ''
let panel3Columns3DateTime18 = ''
let panel3Columns3DateTime15 = ''

// filipino
let panel4Columns5DateTime = ''
let panel4Columns5DateTime4 = ''
let panel4Columns5DateTime3 = ''
let panel4Columns5DateTime2 = ''
let panel4Columns18DateTime = ''
let panel4Columns18DateTime2 = ''
let panel4Columns18DateTime3 = ''
let panel4Columns18DateTime4 = ''
let panel4Columns18DateTime6 = ''
let panel4Columns18DateTime7 = ''
let panel4Columns18DateTime8 = ''
let panel4Columns18DateTime9 = ''
let panel4Columns18DateTime12 = ''
let panel4Columns18DateTime11 = ''
let panel4Columns18DateTime13 = ''
let panel4Columns18DateTime10 = ''
let panel4Columns28DateTime = ''
let panel4Columns28DateTime4 = ''
let panel4Columns28DateTime3 = ''
let panel4Columns28DateTime2 = ''
let panel4Columns28DateTime5 = ''
let panel4Columns28DateTime6 = ''
let panel4Columns28DateTime7 = ''
let panel4Columns28DateTime8 = ''
let panel4Columns18DateTime5 = ''
let panel4Columns28DateTime9 = ''
let panel4Columns28DateTime10 = ''
let panel4Columns28DateTime11 = ''
let panel4Columns28DateTime12 = ''
let panel4Columns28DateTime13 = ''
let panel4Columns28DateTime14 = ''

export default class CreateForm2 extends React.Component {
  constructor() {
    super()
    this.state = {
      noShow: true,
      tabKey: 1,
      arrayInsertRemarks: [],
    }
  }

  uuidv4() {
    try {
      const crypto = window.crypto || window.msCrypto
      const array = new Uint32Array(5)
      crypto.getRandomValues(array)

      return (
        array[0].toString(16).padStart(8, '0') +
        '-' +
        array[1].toString(16).padStart(4, '0') +
        '-4' +
        array[2].toString(12).padStart(3, '0') +
        '-y' +
        array[3].toString(12).padStart(3, '0') +
        '-' +
        array[4].toString(16).padStart(12, '0')
      )
    } catch (error) {
      console.log(error)
    }
  }
  getSupervisor = async (d) => {
    try {
      const foundSupervisor = await API.graphql(
        graphqlOperation(listUsers, {
          filter: {
            division: { contains: d.division },
            and: {
              role: { contains: 'supervisor' },
            },
          },
        })
      )
      let f = foundSupervisor.data.listUsers.items
      if (f.length) {
        this.setState({
          supervisor: `${f[0].name} ${f[0].mname} ${f[0].lname}`,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  componentDidMount() {
    const d = this.props.user

    this.getSupervisor(d)
    this.getMyEtna(d)
    this.fetchTrainingDates()
  }

  insertRemarks = () => {
    try {
      this.state.arrayInsertRemarks.forEach((i) => {
        if (i.training) {
          const insertHere = i.training.courseSubCategory

          let el = '\n-Endorsement Letter'
          let ta = '\n-Training Attendance'
          let co = '\n-Course Output'
          let pe = '\n-Post-evaluation'
          let additionalRemarks = ''

          if (
            insertHere ==
            'Public Service Ethics & Accountability Orientation & Work Ethics'
          ) {
            if (document.getElementsByName('data[remarks1]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }
              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks1 = incompleteMessage
              if (document.getElementsByName('data[remarks1]')[0]) {
                document.getElementsByName('data[remarks1]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks1]')[1]) {
                document.getElementsByName('data[remarks1]')[1].value =
                  incompleteMessage
              }
            }

            try {
              if (
                document.getElementsByName('data[panel3Columns3DateTime]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns3DateTime]')[0]
                ) {
                  panel3Columns3DateTime = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              if (
                document.getElementsByName('data[panel4Columns5DateTime]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel4Columns5DateTime]')[0]
                ) {
                  panel4Columns5DateTime = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns5DateTime]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns5DateTime]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns5DateTime]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns5DateTime]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (
            insertHere ==
            'Administrative Discipline: Orientation of Values & Ethics in the Workplace'
          ) {
            if (document.getElementsByName('data[remarks2]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks2 = incompleteMessage
              if (document.getElementsByName('data[remarks2]')[0]) {
                document.getElementsByName('data[remarks2]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks2]')[1]) {
                document.getElementsByName('data[remarks2]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns4DateTime]')[0]
                ) {
                  panel3Columns4DateTime = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns5DateTime4]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel4Columns5DateTime4]')[0]
                ) {
                  panel4Columns5DateTime4 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns5DateTime4]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns5DateTime4]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns5DateTime4]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns5DateTime4]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Social Media Responsibility') {
            if (document.getElementsByName('data[remarks3]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks3 = incompleteMessage
              if (document.getElementsByName('data[remarks3]')[0]) {
                document.getElementsByName('data[remarks3]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks3]')[1]) {
                document.getElementsByName('data[remarks3]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime2]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns4DateTime2]')[0]
                ) {
                  panel3Columns4DateTime2 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime2]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime2]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime2]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime2]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns5DateTime3]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel4Columns5DateTime3]')[0]
                ) {
                  panel4Columns5DateTime3 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns5DateTime3]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns5DateTime3]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns5DateTime3]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns5DateTime3]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Customer Service Satisfaction') {
            if (document.getElementsByName('data[remarks4]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks4 = incompleteMessage
              if (document.getElementsByName('data[remarks4]')[0]) {
                document.getElementsByName('data[remarks4]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks4]')[1]) {
                document.getElementsByName('data[remarks4]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime3]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns4DateTime3]')[0]
                ) {
                  panel3Columns4DateTime3 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime3]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime3]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime3]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime3]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns5DateTime2]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel4Columns5DateTime2]')[0]
                ) {
                  panel4Columns5DateTime2 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns5DateTime2]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns5DateTime2]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns5DateTime2]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns5DateTime2]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Leadership & Management Program') {
            if (document.getElementsByName('data[remarks5]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks5 = incompleteMessage
              if (document.getElementsByName('data[remarks5]')[0]) {
                document.getElementsByName('data[remarks5]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks5]')[1]) {
                document.getElementsByName('data[remarks5]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime4]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns4DateTime4]')[0]
                ) {
                  panel3Columns4DateTime4 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime4]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime4]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime4]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime4]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel4Columns18DateTime]')[0]
                ) {
                  panel4Columns18DateTime = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Conflict Management') {
            if (document.getElementsByName('data[remarks6]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks6 = incompleteMessage
              if (document.getElementsByName('data[remarks6]')[0]) {
                document.getElementsByName('data[remarks6]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks6]')[1]) {
                document.getElementsByName('data[remarks6]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime26]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime26]'
                  )[0]
                ) {
                  panel3Columns4DateTime26 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime26]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime26]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime26]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime26]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime2]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime2]'
                  )[0]
                ) {
                  panel4Columns18DateTime2 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime2]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime2]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime2]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime2]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Effective Management & Supervision') {
            if (document.getElementsByName('data[remarks7]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks7 = incompleteMessage
              if (document.getElementsByName('data[remarks7]')[0]) {
                document.getElementsByName('data[remarks7]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks7]')[1]) {
                document.getElementsByName('data[remarks7]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime25]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime25]'
                  )[0]
                ) {
                  panel3Columns4DateTime25 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime25]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime25]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime25]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime25]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime3]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime3]'
                  )[0]
                ) {
                  panel4Columns18DateTime3 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime3]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime3]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime3]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime3]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Total Quality Management') {
            if (document.getElementsByName('data[remarks8]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks8 = incompleteMessage
              if (document.getElementsByName('data[remarks8]')[0]) {
                document.getElementsByName('data[remarks8]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks8]')[1]) {
                document.getElementsByName('data[remarks8]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime20]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime20]'
                  )[0]
                ) {
                  panel3Columns4DateTime20 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime20]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime20]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime20]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime20]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime4]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime4]'
                  )[0]
                ) {
                  panel4Columns18DateTime4 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime4]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime4]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime4]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime4]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Culture Building') {
            if (document.getElementsByName('data[remarks10]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks10 = incompleteMessage
              if (document.getElementsByName('data[remarks10]')[0]) {
                document.getElementsByName('data[remarks10]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks10]')[1]) {
                document.getElementsByName('data[remarks10]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime18]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime18]'
                  )[0]
                ) {
                  panel3Columns4DateTime18 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime18]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime18]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime18]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime18]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime6]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime6]'
                  )[0]
                ) {
                  panel4Columns18DateTime6 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime6]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime6]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime6]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime6]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Coaching & Mentoring') {
            if (document.getElementsByName('data[remarks11]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks11 = incompleteMessage
              if (document.getElementsByName('data[remarks11]')[0]) {
                document.getElementsByName('data[remarks11]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks11]')[1]) {
                document.getElementsByName('data[remarks11]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime30]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime30]'
                  )[0]
                ) {
                  panel3Columns4DateTime30 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime30]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime30]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime30]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime30]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime7]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime7]'
                  )[0]
                ) {
                  panel4Columns18DateTime7 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime7]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime7]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime7]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime7]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Managing Teams & People') {
            if (document.getElementsByName('data[remarks12]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks12 = incompleteMessage
              if (document.getElementsByName('data[remarks12]')[0]) {
                document.getElementsByName('data[remarks12]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks12]')[1]) {
                document.getElementsByName('data[remarks12]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime29]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime29]'
                  )[0]
                ) {
                  panel3Columns4DateTime29 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime29]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime29]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime29]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime29]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime8]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime8]'
                  )[0]
                ) {
                  panel4Columns18DateTime8 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime8]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime8]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime8]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime8]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Expanded Team Building') {
            if (document.getElementsByName('data[remarks13]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks13 = incompleteMessage
              if (document.getElementsByName('data[remarks13]')[0]) {
                document.getElementsByName('data[remarks13]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks13]')[1]) {
                document.getElementsByName('data[remarks13]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime28]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime28]'
                  )[0]
                ) {
                  panel3Columns4DateTime28 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime28]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime28]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime28]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime28]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime9]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime9]'
                  )[0]
                ) {
                  panel4Columns18DateTime9 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime9]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime9]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime9]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime9]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Strategic Management Planning') {
            if (document.getElementsByName('data[remarks14]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks14 = incompleteMessage
              if (document.getElementsByName('data[remarks14]')[0]) {
                document.getElementsByName('data[remarks14]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks14]')[1]) {
                document.getElementsByName('data[remarks14]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime27]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime27]'
                  )[0]
                ) {
                  panel3Columns4DateTime27 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime27]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime27]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime27]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime27]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime12]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime12]'
                  )[0]
                ) {
                  panel4Columns18DateTime12 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime12]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime12]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime12]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime12]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Goal and Metrics Setting') {
            if (document.getElementsByName('data[remarks15]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks15 = incompleteMessage
              if (document.getElementsByName('data[remarks15]')[0]) {
                document.getElementsByName('data[remarks15]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks15]')[1]) {
                document.getElementsByName('data[remarks15]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime33]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime33]'
                  )[0]
                ) {
                  panel3Columns4DateTime33 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime33]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime33]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime33]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime33]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime11]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime11]'
                  )[0]
                ) {
                  panel4Columns18DateTime11 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime11]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime11]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime11]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime11]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Supervisory Development Course') {
            if (document.getElementsByName('data[remarks16]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks16 = incompleteMessage
              if (document.getElementsByName('data[remarks16]')[0]) {
                document.getElementsByName('data[remarks16]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks16]')[1]) {
                document.getElementsByName('data[remarks16]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime32]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime32]'
                  )[0]
                ) {
                  panel3Columns4DateTime32 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime32]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime32]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime32]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime32]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime13]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime13]'
                  )[0]
                ) {
                  panel4Columns18DateTime13 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime13]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime13]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime13]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime13]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Change Management') {
            if (document.getElementsByName('data[remarks17]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks17 = incompleteMessage
              if (document.getElementsByName('data[remarks17]')[0]) {
                document.getElementsByName('data[remarks17]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks17]')[1]) {
                document.getElementsByName('data[remarks17]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime31]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime31]'
                  )[0]
                ) {
                  panel3Columns4DateTime31 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime31]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime31]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime31]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime31]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns18DateTime10]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns18DateTime10]'
                  )[0]
                ) {
                  panel4Columns18DateTime10 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime10]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns18DateTime10]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns18DateTime10]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns18DateTime10]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Business/Technical Writing Skills') {
            if (document.getElementsByName('data[remarks18]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks18 = incompleteMessage
              if (document.getElementsByName('data[remarks18]')[0]) {
                document.getElementsByName('data[remarks18]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks18]')[1]) {
                document.getElementsByName('data[remarks18]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime2]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns3DateTime2]')[0]
                ) {
                  panel3Columns3DateTime2 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime2]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime2]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime2]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime2]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel4Columns28DateTime]')[0]
                ) {
                  panel4Columns28DateTime = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (
            insertHere == 'English Proficiency & Effective Communication'
          ) {
            if (document.getElementsByName('data[remarks19]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks19 = incompleteMessage
              if (document.getElementsByName('data[remarks19]')[0]) {
                document.getElementsByName('data[remarks19]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks19]')[1]) {
                document.getElementsByName('data[remarks19]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime3]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns3DateTime3]')[0]
                ) {
                  panel3Columns3DateTime3 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime3]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime3]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime3]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime3]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime4]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime4]'
                  )[0]
                ) {
                  panel4Columns28DateTime4 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime4]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime4]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime4]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime4]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Effective Presentation') {
            if (document.getElementsByName('data[remarks20]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks20 = incompleteMessage
              if (document.getElementsByName('data[remarks20]')[0]) {
                document.getElementsByName('data[remarks20]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks20]')[1]) {
                document.getElementsByName('data[remarks20]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime5]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns3DateTime5]')[0]
                ) {
                  panel3Columns3DateTime5 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime5]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime5]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime5]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime5]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime3]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime3]'
                  )[0]
                ) {
                  panel4Columns28DateTime3 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime3]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime3]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime3]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime3]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Public Speaking') {
            if (document.getElementsByName('data[remarks21]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks21 = incompleteMessage
              if (document.getElementsByName('data[remarks21]')[0]) {
                document.getElementsByName('data[remarks21]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks21]')[1]) {
                document.getElementsByName('data[remarks21]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime16]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns3DateTime16]'
                  )[0]
                ) {
                  panel3Columns3DateTime16 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime16]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime16]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime16]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime16]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime2]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime2]'
                  )[0]
                ) {
                  panel4Columns28DateTime2 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime2]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime2]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime2]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime2]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (
            insertHere == 'Personality Development in Local Government'
          ) {
            if (document.getElementsByName('data[remarks22]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks22 = incompleteMessage
              if (document.getElementsByName('data[remarks22]')[0]) {
                document.getElementsByName('data[remarks22]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks22]')[1]) {
                document.getElementsByName('data[remarks22]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime6]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns3DateTime6]')[0]
                ) {
                  panel3Columns3DateTime6 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime6]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime6]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime6]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime6]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime5]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime5]'
                  )[0]
                ) {
                  panel3Columns3DateTime6 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime5]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime5]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime5]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime5]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Time Management') {
            if (document.getElementsByName('data[remarks23]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks23 = incompleteMessage
              if (document.getElementsByName('data[remarks23]')[0]) {
                document.getElementsByName('data[remarks23]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks23]')[1]) {
                document.getElementsByName('data[remarks23]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime4]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns3DateTime4]')[0]
                ) {
                  panel3Columns3DateTime4 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime4]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime4]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime4]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime4]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime6]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime6]'
                  )[0]
                ) {
                  panel4Columns28DateTime6 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime6]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime6]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime6]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime6]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (
            insertHere == 'Obtaining and Providing Positive Work Attitude'
          ) {
            if (document.getElementsByName('data[remarks24]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks24 = incompleteMessage
              if (document.getElementsByName('data[remarks24]')[0]) {
                document.getElementsByName('data[remarks24]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks24]')[1]) {
                document.getElementsByName('data[remarks24]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime13]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns3DateTime13]'
                  )[0]
                ) {
                  panel3Columns3DateTime13 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime13]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime13]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime13]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime13]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime7]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime7]'
                  )[0]
                ) {
                  panel4Columns28DateTime7 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime7]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime7]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime7]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime7]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (
            insertHere ==
            'Strategic HRM: Providing Assistance to Planning and Goal Setting'
          ) {
            if (document.getElementsByName('data[remarks25]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks25 = incompleteMessage
              if (document.getElementsByName('data[remarks25]')[0]) {
                document.getElementsByName('data[remarks25]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks25]')[1]) {
                document.getElementsByName('data[remarks25]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime9]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns3DateTime9]')[0]
                ) {
                  panel3Columns3DateTime9 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime9]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime9]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime9]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime9]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime8]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime8]'
                  )[0]
                ) {
                  panel4Columns28DateTime8 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime8]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime8]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime8]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime8]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Project Management') {
            if (i.training.courseCategory == 'LEADERSHIP COMPETENCIES') {
              if (document.getElementsByName('data[remarks9]')?.length) {
                if (i.additionalRemarks?.length >= 1) {
                  additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
                }

                if (i.isSubmitEndorsementLetter !== false) {
                  el = ''
                }

                if (i.isAttended !== false) {
                  ta = ''
                }

                if (i.isCourseOutputDone !== false) {
                  co = ''
                }

                if (i.isPostEvalDone !== false) {
                  pe = ''
                }

                let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
                remarks9 = incompleteMessage
                document.getElementsByName('data[remarks9]')[0].value =
                  incompleteMessage
                if (document.getElementsByName('data[remarks9]')[0]) {
                  document.getElementsByName('data[remarks9]')[0].value =
                    incompleteMessage
                }
                if (document.getElementsByName('data[remarks9]')[1]) {
                  document.getElementsByName('data[remarks9]')[1].value =
                    incompleteMessage
                }
              }

              try {
                // english
                if (
                  document.getElementsByName('data[panel3Columns4DateTime19]')
                    ?.length
                ) {
                  if (
                    document.getElementsByName(
                      'data[panel3Columns4DateTime19]'
                    )[0]
                  ) {
                    panel3Columns4DateTime19 = `${moment(
                      i.training.date
                    ).format('YYYY-MM-DDTHH:mm:ss')}.000Z`
                    document.getElementsByName(
                      'data[panel3Columns4DateTime19]'
                    )[0].value = `${moment(i.training.date).format(
                      'YYYY-MM-DDTHH:mm:ss'
                    )}`
                    document.getElementsByName(
                      'data[panel3Columns4DateTime19]'
                    )[0].nextSibling.value = moment(i.training.date).format(
                      'YYYY-MM-DD HH:mm A'
                    )
                    setTimeout(() => {
                      if (
                        document.getElementsByName(
                          'data[panel3Columns4DateTime19]'
                        )[0].nextSibling.nodeName == 'INPUT'
                      ) {
                        document
                          .getElementsByName(
                            'data[panel3Columns4DateTime19]'
                          )[0]
                          .nextSibling.setAttribute('disabled', '')
                      }
                    }, 1000)
                  }
                }

                // filipino
                if (
                  document.getElementsByName('data[panel4Columns18DateTime5]')
                    ?.length
                ) {
                  if (
                    document.getElementsByName(
                      'data[panel4Columns18DateTime5]'
                    )[0]
                  ) {
                    panel4Columns18DateTime5 = `${moment(
                      i.training.date
                    ).format('YYYY-MM-DDTHH:mm:ss')}.000Z`
                    document.getElementsByName(
                      'data[panel4Columns18DateTime5]'
                    )[0].value = `${moment(i.training.date).format(
                      'YYYY-MM-DDTHH:mm:ss'
                    )}`
                    document.getElementsByName(
                      'data[panel4Columns18DateTime5]'
                    )[0].nextSibling.value = moment(i.training.date).format(
                      'YYYY-MM-DD HH:mm A'
                    )
                    setTimeout(() => {
                      if (
                        document.getElementsByName(
                          'data[panel4Columns18DateTime5]'
                        )[0].nextSibling.nodeName == 'INPUT'
                      ) {
                        document
                          .getElementsByName(
                            'data[panel4Columns18DateTime5]'
                          )[0]
                          .nextSibling.setAttribute('disabled', '')
                      }
                    }, 1000)
                  }
                }
              } catch (error) {
                console.log(error)
              }
            } else if (
              i.training.courseCategory == 'ORGANIZATIONAL COMPETENCIES'
            ) {
              if (document.getElementsByName('data[remarks26]')?.length) {
                if (i.additionalRemarks?.length >= 1) {
                  additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
                }

                if (i.isSubmitEndorsementLetter !== false) {
                  el = ''
                }

                if (i.isAttended !== false) {
                  ta = ''
                }

                if (i.isCourseOutputDone !== false) {
                  co = ''
                }

                if (i.isPostEvalDone !== false) {
                  pe = ''
                }

                let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
                remarks26 = incompleteMessage
                if (document.getElementsByName('data[remarks26]')[0]) {
                  document.getElementsByName('data[remarks26]')[0].value =
                    incompleteMessage
                }
                if (document.getElementsByName('data[remarks26]')[1]) {
                  document.getElementsByName('data[remarks26]')[1].value =
                    incompleteMessage
                }
              }

              try {
                // english
                if (
                  document.getElementsByName('data[panel3Columns3DateTime17]')
                    ?.length
                ) {
                  if (
                    document.getElementsByName(
                      'data[panel3Columns3DateTime17]'
                    )[0]
                  ) {
                    panel3Columns3DateTime17 = `${moment(
                      i.training.date
                    ).format('YYYY-MM-DDTHH:mm:ss')}.000Z`
                    document.getElementsByName(
                      'data[panel3Columns3DateTime17]'
                    )[0].value = `${moment(i.training.date).format(
                      'YYYY-MM-DDTHH:mm:ss'
                    )}`
                    document.getElementsByName(
                      'data[panel3Columns3DateTime17]'
                    )[0].nextSibling.value = moment(i.training.date).format(
                      'YYYY-MM-DD HH:mm A'
                    )
                    setTimeout(() => {
                      if (
                        document.getElementsByName(
                          'data[panel3Columns3DateTime17]'
                        )[0].nextSibling.nodeName == 'INPUT'
                      ) {
                        document
                          .getElementsByName(
                            'data[panel3Columns3DateTime17]'
                          )[0]
                          .nextSibling.setAttribute('disabled', '')
                      }
                    }, 1000)
                  }
                }

                // filipino
                if (
                  document.getElementsByName('data[panel4Columns28DateTime9]')
                    ?.length
                ) {
                  if (
                    document.getElementsByName(
                      'data[panel4Columns28DateTime9]'
                    )[0]
                  ) {
                    panel4Columns28DateTime9 = `${moment(
                      i.training.date
                    ).format('YYYY-MM-DDTHH:mm:ss')}.000Z`
                    document.getElementsByName(
                      'data[panel4Columns28DateTime9]'
                    )[0].value = `${moment(i.training.date).format(
                      'YYYY-MM-DDTHH:mm:ss'
                    )}`
                    document.getElementsByName(
                      'data[panel4Columns28DateTime9]'
                    )[0].nextSibling.value = moment(i.training.date).format(
                      'YYYY-MM-DD HH:mm A'
                    )
                    setTimeout(() => {
                      if (
                        document.getElementsByName(
                          'data[panel4Columns28DateTime9]'
                        )[0].nextSibling.nodeName == 'INPUT'
                      ) {
                        document
                          .getElementsByName(
                            'data[panel4Columns28DateTime9]'
                          )[0]
                          .nextSibling.setAttribute('disabled', '')
                      }
                    }, 1000)
                  }
                }
              } catch (error) {
                console.log(error)
              }
            }
          } else if (insertHere == 'Data Management & Analysis/Chart') {
            if (document.getElementsByName('data[remarks27]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks27 = incompleteMessage
              if (document.getElementsByName('data[remarks27]')[0]) {
                document.getElementsByName('data[remarks27]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks27]')[1]) {
                document.getElementsByName('data[remarks27]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime5]')
                  ?.length
              ) {
                if (
                  document.getElementsByName('data[panel3Columns4DateTime5]')[0]
                ) {
                  panel3Columns4DateTime5 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime5]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime5]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime5]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime5]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime10]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime10]'
                  )[0]
                ) {
                  panel4Columns28DateTime10 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime10]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime10]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime10]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime10]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Data Privacy') {
            if (document.getElementsByName('data[remarks28]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks28 = incompleteMessage
              if (document.getElementsByName('data[remarks28]')[0]) {
                document.getElementsByName('data[remarks28]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks28]')[1]) {
                document.getElementsByName('data[remarks28]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns4DateTime34]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns4DateTime34]'
                  )[0]
                ) {
                  panel3Columns4DateTime34 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime34]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns4DateTime34]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns4DateTime34]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns4DateTime34]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime11]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime11]'
                  )[0]
                ) {
                  panel4Columns28DateTime11 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime11]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime11]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime11]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime11]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Critical Thinking') {
            if (document.getElementsByName('data[remarks29]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks29 = incompleteMessage
              if (document.getElementsByName('data[remarks29]')[0]) {
                document.getElementsByName('data[remarks29]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks29]')[1]) {
                document.getElementsByName('data[remarks29]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime14]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns3DateTime14]'
                  )[0]
                ) {
                  panel3Columns3DateTime14 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime14]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime14]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime14]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime14]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime12]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime12]'
                  )[0]
                ) {
                  panel4Columns28DateTime12 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime12]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime12]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime12]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime12]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Innovative Thinking') {
            if (document.getElementsByName('data[remarks39]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks30 = incompleteMessage
              if (document.getElementsByName('data[remarks30]')[0]) {
                document.getElementsByName('data[remarks30]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks30]')[1]) {
                document.getElementsByName('data[remarks30]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime18]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns3DateTime18]'
                  )[0]
                ) {
                  panel3Columns3DateTime18 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime18]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime18]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime18]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime18]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime13]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime13]'
                  )[0]
                ) {
                  panel4Columns28DateTime13 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime13]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime13]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime13]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime13]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          } else if (insertHere == 'Design Thinking') {
            if (document.getElementsByName('data[remarks30]')?.length) {
              if (i.additionalRemarks?.length >= 1) {
                additionalRemarks = `\nOther remarks: \n${i.additionalRemarks}`
              }

              if (i.isSubmitEndorsementLetter !== false) {
                el = ''
              }

              if (i.isAttended !== false) {
                ta = ''
              }

              if (i.isCourseOutputDone !== false) {
                co = ''
              }

              if (i.isPostEvalDone !== false) {
                pe = ''
              }

              let incompleteMessage = `Incomplete Requirements ${el} ${ta} ${co} ${pe} ${additionalRemarks}`
              remarks31 = incompleteMessage
              if (document.getElementsByName('data[remarks31]')[0]) {
                document.getElementsByName('data[remarks31]')[0].value =
                  incompleteMessage
              }
              if (document.getElementsByName('data[remarks31]')[1]) {
                document.getElementsByName('data[remarks31]')[1].value =
                  incompleteMessage
              }
            }

            try {
              // english
              if (
                document.getElementsByName('data[panel3Columns3DateTime15]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel3Columns3DateTime15]'
                  )[0]
                ) {
                  panel3Columns3DateTime15 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime15]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel3Columns3DateTime15]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel3Columns3DateTime15]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel3Columns3DateTime15]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }

              // filipino
              if (
                document.getElementsByName('data[panel4Columns28DateTime14]')
                  ?.length
              ) {
                if (
                  document.getElementsByName(
                    'data[panel4Columns28DateTime14]'
                  )[0]
                ) {
                  panel4Columns28DateTime14 = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}.000Z`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime14]'
                  )[0].value = `${moment(i.training.date).format(
                    'YYYY-MM-DDTHH:mm:ss'
                  )}`
                  document.getElementsByName(
                    'data[panel4Columns28DateTime14]'
                  )[0].nextSibling.value = moment(i.training.date).format(
                    'YYYY-MM-DD HH:mm A'
                  )
                  setTimeout(() => {
                    if (
                      document.getElementsByName(
                        'data[panel4Columns28DateTime14]'
                      )[0].nextSibling.nodeName == 'INPUT'
                    ) {
                      document
                        .getElementsByName('data[panel4Columns28DateTime14]')[0]
                        .nextSibling.setAttribute('disabled', '')
                    }
                  }, 1000)
                }
              }
            } catch (error) {
              console.log(error)
            }
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  }

  // get date training for user by their registration
  fetchTrainingDates = async () => {
    const user = this.props.user
    try {
      const listTrainingDates = await API.graphql(
        graphqlOperation(registrationByUserID, { userID: user.id })
      )

      const arrayInsertRemarks =
        listTrainingDates.data.registrationByUserID.items
      this.setState({ arrayInsertRemarks })
    } catch (err) {
      console.log(err)
    }
  }

  componentDidUpdate() {
    try {
      if (document.getElementsByName('data[print]')?.length) {
        const b = document.getElementsByName('data[print]')
        b.forEach((bb) => (bb.style.display = 'none'))
      }

      if (document.getElementsByName('data[columnsDownloadform]')?.length) {
        const b = document.getElementsByName('data[columnsDownloadform]')
        b.forEach((bb) => (bb.style.display = 'none'))
      }
    } catch (error) {
      console.log(error)
    }
  }

  getMyEtna = async (data) => {
    try {
      const myEtna = await API.graphql(
        graphqlOperation(FormByUserId, { userID: data.id })
      )
      const myEtnaArr = myEtna.data.FormByUserId.items.filter(
        (obj) => obj.isFinal == 'YES'
      )
      const newMyEtnaArr = myEtnaArr.sort((a, b) => {
        return (
          moment(b.finalSubmitDate).unix() - moment(a.finalSubmitDate).unix()
        )
      })

      if (newMyEtnaArr) {
        if (newMyEtnaArr.length) {
          this.setState({
            etnaId: newMyEtnaArr[0].finalSubmitDate,
            noShow: false,
          })

          // english
          if (newMyEtnaArr[0].isLanguage === '1') {
            const o = JSON.parse(myEtnaArr[0].form)
            this.setState({
              forSubmissionData: {
                panelColumns13Score14: o.well5TableColumns2ColumnsNumber,
                panelColumns13Score12: o.well5TableColumns2ColumnsNumber2,
                panelColumns13Score13: o.well5TableColumns2ColumnsNumber3,

                panelColumns13Score: o.well5TableColumns5ColumnsNumber,
                panelColumns13Score2: o.well5TableColumns6ColumnsNumber,
                panelColumns13Score3: o.well5TableColumns7ColumnsNumber,
                panelColumns13Score4: o.well5TableColumns8ColumnsNumber,
                panelColumns13Score5: o.well5TableColumns9ColumnsNumber,
                panelColumns13Score6: o.well5TableColumns10ColumnsNumber,

                panelColumns13Score7: o.well5TableColumns12ColumnsNumber,
                panelColumns13Score8: o.well5TableColumns13ColumnsNumber,
                panelColumns13Score9: o.well5TableColumns14ColumnsNumber,
                panelColumns13Score10: o.well5TableColumns15ColumnsNumber,
                panelColumns13Score11: o.well5TableColumns16ColumnsNumber,
                panelColumns3Datetaken: new Date(),
              },
            })
          } else {
            const o = JSON.parse(newMyEtnaArr[0].form)
            this.setState({
              forSubmissionData: {
                panelColumns13Score14: o.panelWellTableColumns2ColumnsNumber,
                panelColumns13Score12: o.panelWellTableColumns3ColumnsNumber,
                panelColumns13Score13: o.panelWellTableColumns4ColumnsNumber,

                panelColumns13Score: o.panelWellTableColumns6ColumnsNumber,
                panelColumns13Score2: o.panelWellTableColumns7ColumnsNumber,
                panelColumns13Score3: o.panelWellTableColumns8ColumnsNumber,
                panelColumns13Score4: o.panelWellTableColumns9ColumnsNumber,
                panelColumns13Score5: o.panelWellTableColumns10ColumnsNumber,
                panelColumns13Score6: o.panelWellTableColumns11ColumnsNumber,

                panelColumns13Score7: o.panelWellTableColumns13ColumnsNumber,
                panelColumns13Score8: o.panelWellTableColumns14ColumnsNumber,
                panelColumns13Score9: o.panelWellTableColumns15ColumnsNumber,
                panelColumns13Score10: o.panelWellTableColumns16ColumnsNumber,
                panelColumns13Score11: o.panelWellTableColumns17ColumnsNumber,
                panel2Columns3Petsangpagkakuha: new Date(),
              },
            })
          }
        } else {
          notification['error']({
            message: 'Error',
            description:
              "You didn't submit an e-TNA form yet. Please go back to Step 1.",
          })
          this.setState({ noShow: true })
        }
      } else {
        notification['error']({
          message: 'Error',
          description:
            "You don't have submitted yet an e-TNA form. Please go back to Step 1.",
        })
        this.setState({ noShow: true })
      }
    } catch (err) {
      console.log(err)
    }
  }

  handleSubmit = async (e, f) => {
    try {
      const newData = {
        ...e.data,
      }
      const user = this.props.user
      const input = {
        dept: user.department,
        assignedDept: user.assignedDept,
        division: user.division,
        position: user.position,
        userID: user.id,
        etnaId: this.state.etnaId,
        filipino: f,
        form: JSON.stringify(newData),
        isApproved: '0',
      }

      await API.graphql(graphqlOperation(createForm2, { input }))

      await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: user.id,
            form2: '0',
            isTakeSurvey: '0',
          },
        })
      )

      this.props.refresh(this.uuidv4())
      this.props.afterSubmit('viewForm')

      notification['success']({
        message: 'Success',
        description: 'Your form has been submitted. Thank you!',
      })
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description: "There's an error in submitting form.",
      })
    }
  }

  handleSubmitFili = async (e, f) => {
    try {
      const newData = {
        ...e.data,
      }

      newData.remarks1 = remarks1
      newData.remarks2 = remarks2
      newData.remarks3 = remarks3
      newData.remarks4 = remarks4
      newData.remarks5 = remarks5
      newData.remarks6 = remarks6
      newData.remarks7 = remarks7
      newData.remarks8 = remarks8
      newData.remarks9 = remarks9
      newData.remarks10 = remarks10
      newData.remarks11 = remarks11
      newData.remarks12 = remarks12
      newData.remarks13 = remarks13
      newData.remarks14 = remarks14
      newData.remarks15 = remarks15
      newData.remarks16 = remarks16
      newData.remarks17 = remarks17
      newData.remarks18 = remarks18
      newData.remarks19 = remarks19
      newData.remarks20 = remarks20
      newData.remarks21 = remarks21
      newData.remarks22 = remarks22
      newData.remarks23 = remarks23
      newData.remarks24 = remarks24
      newData.remarks25 = remarks25
      newData.remarks26 = remarks26
      newData.remarks27 = remarks27
      newData.remarks28 = remarks28
      newData.remarks29 = remarks29
      newData.remarks30 = remarks30
      newData.remarks31 = remarks31
      newData.remarks32 = remarks32
      newData.remarks33 = remarks33
      newData.remarks34 = remarks34
      newData.remarks35 = remarks35
      newData.remarks36 = remarks36
      newData.remarks37 = remarks37
      newData.remarks38 = remarks38
      newData.remarks39 = remarks39

      newData.panel4Columns5DateTime = panel4Columns5DateTime
      newData.panel4Columns5DateTime4 = panel4Columns5DateTime4
      newData.panel4Columns5DateTime3 = panel4Columns5DateTime3
      newData.panel4Columns5DateTime2 = panel4Columns5DateTime2
      newData.panel4Columns18DateTime = panel4Columns18DateTime
      newData.panel4Columns18DateTime2 = panel4Columns18DateTime2
      newData.panel4Columns18DateTime3 = panel4Columns18DateTime3
      newData.panel4Columns18DateTime4 = panel4Columns18DateTime4
      newData.panel4Columns18DateTime6 = panel4Columns18DateTime6
      newData.panel4Columns18DateTime7 = panel4Columns18DateTime7
      newData.panel4Columns18DateTime8 = panel4Columns18DateTime8
      newData.panel4Columns18DateTime9 = panel4Columns18DateTime9
      newData.panel4Columns18DateTime12 = panel4Columns18DateTime12
      newData.panel4Columns18DateTime11 = panel4Columns18DateTime11
      newData.panel4Columns18DateTime13 = panel4Columns18DateTime13
      newData.panel4Columns18DateTime10 = panel4Columns18DateTime10
      newData.panel4Columns28DateTime = panel4Columns28DateTime
      newData.panel4Columns28DateTime4 = panel4Columns28DateTime4
      newData.panel4Columns28DateTime3 = panel4Columns28DateTime3
      newData.panel4Columns28DateTime2 = panel4Columns28DateTime2
      newData.panel4Columns28DateTime5 = panel4Columns28DateTime5
      newData.panel4Columns28DateTime6 = panel4Columns28DateTime6
      newData.panel4Columns28DateTime7 = panel4Columns28DateTime7
      newData.panel4Columns28DateTime8 = panel4Columns28DateTime8
      newData.panel4Columns18DateTime5 = panel4Columns18DateTime5
      newData.panel4Columns28DateTime9 = panel4Columns28DateTime9
      newData.panel4Columns28DateTime10 = panel4Columns28DateTime10
      newData.panel4Columns28DateTime11 = panel4Columns28DateTime11
      newData.panel4Columns28DateTime12 = panel4Columns28DateTime12
      newData.panel4Columns28DateTime13 = panel4Columns28DateTime13
      newData.panel4Columns28DateTime14 = panel4Columns28DateTime14

      const user = this.props.user
      const input = {
        dept: user.department,
        assignedDept: user.assignedDept,
        division: user.division,
        position: user.position,
        userID: user.id,
        etnaId: this.state.etnaId,
        filipino: f,
        form: JSON.stringify(newData),
        isApproved: '0',
      }

      await API.graphql(graphqlOperation(createForm2, { input }))
      await API.graphql(
        graphqlOperation(updateUser, {
          input: {
            id: user.id,
            form2: '0',
            isTakeSurvey: '0',
          },
        })
      )

      this.props.refresh(this.uuidv4())
      this.props.afterSubmit('viewForm')
      notification['success']({
        message: 'Success',
        description: 'Your form has been submitted. Thank you!',
      })
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description: "There's an error in submitting form.",
      })
    }
  }

  cleanUpString(inputString) {
    // Remove leading and trailing whitespaces
    let trimmedString = inputString.trim()

    // Replace multiple whitespaces with a single space
    let cleanedString = trimmedString.replace(/\s\s+/g, ' ')

    return cleanedString
  }

  /**
   * Validate suffix input
   * @param {string} value - The suffix value to be validated
   * @returns {string} - Returns a space followed by the suffix if it is valid, otherwise an empty string
   * @example
   * validateSuffix('JR.') // returns ' JR.'
   * validateSuffix('VII') // returns ' VII'
   *
   **/
  validateSuffix = (value) => {
    const validSuffixes = [
      'JR.',
      'SR.',
      'I',
      'II',
      'III',
      'IV',
      'V',
      'VI',
      'VII',
      'VIII',
      'IX',
      'X',
    ]

    // Trim the input and convert to uppercase for consistent comparison
    const normalized = value?.trim().toUpperCase()

    // Return empty string if value does NOT match any suffix, otherwise return original value
    return validSuffixes.includes(normalized) ? ' ' + value : ''
  }

  handleLoad = async (e) => {
    const { user } = this.props

    try {
      if (!user) return

      const fullName = this.cleanUpString(
        `${user.name}${user.mname ? ' ' + user.mname : ''} ${
          user.lname
        }${this.validateSuffix(user.suffix)}`
      )

      const supervisorOrDepartmentHead =
        user.role === 'supervisor' ? 'Department Head' : this.state.supervisor

      this.setState(
        (prevState) => {
          const submissionData = {
            ...prevState.forSubmissionData,
            panel2Columns2Pangalan5: user.position,
            panel2Columns2Division: user.division,
            panel2Columns2Division4: user.assignedDept,
            panel2Columns2Division3: supervisorOrDepartmentHead,
            panelColumns2Office: user.assignedDept,
            panelColumns2Division: user.division,
            panelColumns2Currentposition: user.position,
            panel3Columns31EmployeeNameandSignature3: fullName,
            panelColumns2Name: fullName,
            panelColumns2Supervisorsname: supervisorOrDepartmentHead,
          }

          return { submissionData }
        },
        () => {
          this.handleForm(this.state.forSubmissionData)
        }
      )
    } catch (err) {
      console.log('Error in handleLoad:', err)
      notification.error({
        message: 'Error',
        description:
          'An error occurred while loading data. Please contact System Admin for assistance.',
      })
    }
  }

  handleLoadFil = async () => {
    const { user } = this.props

    if (!user) {
      console.error('User data is not available')
      return
    }

    try {
      const fullName = this.cleanUpString(
        `${user.name}${user.mname ? ' ' + user.mname : ''} ${
          user.lname
        }${this.validateSuffix(user.suffix)}`
      )

      this.setState(
        (prevState) => ({
          submissionDataFilipino: {
            ...prevState.submissionDataFilipino,
            panel2Columns3Petsangpagkakuha: new Date(),
            panel5Columns3PangalanatPirmangEmpleyado: fullName,
            panel2Columns2Pangalan: fullName,
            panel2Columns2Pangalan5: user.position,
            panel2Columns2Division: user.division,
            panel2Columns2Division4: user.assignedDept,
            panel2Columns2Division3:
              user.role === 'supervisor'
                ? 'Department Head'
                : prevState.supervisor,
            panelColumns2Office: user.assignedDept,
            panelColumns2Division: user.division,
            panelColumns2Currentposition: user.position,
            panelColumns2Supervisorsname:
              user.role === 'supervisor'
                ? 'Department Head'
                : prevState.supervisor,
          },
        }),
        () => {
          this.handleFormFil(this.state.submissionDataFilipino)
        }
      )
    } catch (err) {
      console.error('Error in handleLoadFil:', err)
      notification.error({
        message: 'Error',
        description:
          'An error occurred while loading data. Please contact System Admin for assistance.',
      })
    }
  }

  handleForm = async (e) => {
    let d = this.state.formComponents
    let arr = []
    try {
      await d.components.forEach(async (item, i) => {
        if (item.key === 'panel') {
          let comp = item.components
          let arr2 = []

          comp.forEach(async (it, i) => {
            let arr3 = []
            if (it.columns?.length === 2) {
              let w = it

              w.columns.forEach((col, ii) => {
                if (ii === 0) {
                  arr3.push(col)
                } else if (col.components[0].label !== 'Score') {
                  arr3.push(col)
                } else {
                  let x = col
                  let sc = e[x.components[0].key]
                  let y = {
                    ...x.components[0],
                    content: `<div class='${
                      +sc <= 3.5 ? 'scoreBelow' : 'scoreAbove'
                    }'><span>Score: </span>${sc || 'No score'}</div>`,
                  }

                  let z = { ...x, components: [y] }

                  arr3.push(z)
                }
              })

              let ww = { ...w, columns: arr3 }
              arr2.push(ww)
            } else {
              arr2.push(it)
            }
          })
          let newItem = { ...item, components: arr2 }
          arr.push(newItem)
        } else {
          arr.push(item)
        }
        this.setState({ comp: arr })
      })
    } catch (err) {
      console.log(err)
      notification.error({
        message: 'Error',
        description:
          'An error occurred while loading data. Please contact System Admin for assistance.',
      })
    }
  }

  handleFormFil = async (e) => {
    try {
      let d = this.state.formComponentsFil
      let arr = []
      await d.components.forEach(async (item, i) => {
        if (item.key === 'panel2') {
          let comp = item.components
          let arr2 = []

          comp.forEach(async (it, i) => {
            let arr3 = []
            if (it.columns?.length === 2) {
              let w = it

              w.columns.forEach((col, ii) => {
                if (ii === 0) {
                  arr3.push(col)
                } else if (col.components[0].label !== 'Score') {
                  arr3.push(col)
                } else {
                  let x = col
                  let sc = e[x.components[0].key]
                  let y = {
                    ...x.components[0],
                    content: `<div class='${
                      +sc <= 3.5 ? 'scoreBelow' : 'scoreAbove'
                    }'><span>Score: </span>${sc || 'No score'}</div>`,
                  }

                  let z = { ...x, components: [y] }
                  arr3.push(z)
                }
              })

              let ww = { ...w, columns: arr3 }
              arr2.push(ww)
            } else {
              arr2.push(it)
            }
          })
          let newItem = { ...item, components: arr2 }
          arr.push(newItem)
        } else {
          arr.push(item)
        }
        this.setState({ compFil: arr })
      })
    } catch (err) {
      console.log(err)
      notification.error({
        message: 'Error',
        description:
          'An error occurred while loading data. Please contact System Admin for assistance.',
      })
    }
  }

  render() {
    setTimeout(() => {
      this.insertRemarks()
      if (document.querySelectorAll('.remarks-from-hr').length) {
        const remarksInput = document.querySelectorAll('.remarks-from-hr')
        remarksInput.forEach((elem) => {
          if (elem.children[1]) {
            elem.children[1].children[0].disabled = true
          } else {
            elem.children[0].children[0].disabled = true
          }
        })
      }
    }, 300)
    setTimeout(() => {
      this.insertRemarks()
      if (document.querySelectorAll('.remarks-from-hr').length) {
        const remarksInput = document.querySelectorAll('.remarks-from-hr')
        remarksInput.forEach((elem) => {
          if (elem.children[1]) {
            elem.children[1].children[0].disabled = true
          } else {
            elem.children[0].children[0].disabled = true
          }
        })
      }
    }, 600)
    setTimeout(() => {
      this.insertRemarks()
      if (document.querySelectorAll('.remarks-from-hr').length) {
        const remarksInput = document.querySelectorAll('.remarks-from-hr')
        remarksInput.forEach((elem) => {
          if (elem.children[1]) {
            elem.children[1].children[0].disabled = true
          } else {
            elem.children[0].children[0].disabled = true
          }
        })
      }
    }, 1000)

    const user = this.props.user
    const b = document.getElementsByTagName('button')
    try {
      if (b['data[print]']) {
        b['data[print]'].remove()
      }
    } catch (err) {
      console.log(err)
      notification.error({
        message: 'Error',
        description:
          'An error occurred while loading data. Please contact System Admin for assistance.',
      })
    }

    return (
      <div
        className={'site-layout-background p-edit-margin'}
        style={{
          padding: 50,
        }}
      >
        <Card>
          {user && this.state.noShow !== true ? (
            <>
              <div className='titleILDP'>
                INDIVIDUAL LEARNING & DEVELOPMENT PLAN (ILDP)
              </div>
              <Tabs
                onChange={(e) => this.setState({ tabKey: +e })}
                defaultActiveKey='1'
                centered
              >
                <TabPane tab={<b>ILDP (English Version)</b>} key='1'>
                  <Breadcrumb style={{ marginBottom: 20 }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Fill-out Form</Breadcrumb.Item>
                  </Breadcrumb>
                  <div style={{ display: 'none' }} className='printMe'></div>
                  {this.state.comp ? (
                    <Form
                      form={{
                        components: this.state.comp,
                      }}
                      onSubmit={(e, f) => this.handleSubmit(e, 'NO')}
                      submission={{ data: this.state.submissionData }}
                    />
                  ) : (
                    <Form
                      src='https://www.sparksoft-demo.com/formio/ildp'
                      onSubmit={(e, f) => this.handleSubmit(e, 'NO')}
                      onFormLoad={(e) => {
                        this.setState({ formComponents: e }, () =>
                          this.handleLoad()
                        )
                      }}
                      submission={{ data: this.state.submissionData }}
                    />
                  )}
                </TabPane>

                <TabPane tab={<b>ILDP (Filipino Version)</b>} key='2'>
                  <Breadcrumb style={{ marginBottom: 20 }}>
                    <Breadcrumb.Item>Home</Breadcrumb.Item>
                    <Breadcrumb.Item>Fill-out Form</Breadcrumb.Item>
                  </Breadcrumb>
                  <div style={{ display: 'none' }} className='printMe'></div>
                  {this.state.compFil ? (
                    <Form
                      form={{
                        components: this.state.compFil,
                      }}
                      onSubmit={(e, f) => this.handleSubmitFili(e, 'YES')}
                      submission={{ data: this.state.submissionDataFilipino }}
                    />
                  ) : (
                    <Form
                      src='https://www.sparksoft-demo.com/formio/ildp-fili'
                      onSubmit={(e, f) => this.handleSubmitFili(e, 'YES')}
                      onFormLoad={(e) => {
                        this.setState({ formComponentsFil: e }, () =>
                          this.handleLoadFil()
                        )
                      }}
                      submission={{ data: this.state.submissionDataFilipino }}
                    />
                  )}
                </TabPane>
              </Tabs>
            </>
          ) : null}
        </Card>
      </div>
    )
  }
}

// proptypes
CreateForm2.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    mname: PropTypes.string,
    lname: PropTypes.string.isRequired,
    suffix: PropTypes.string,
    role: PropTypes.string.isRequired,
    department: PropTypes.string,
    assignedDept: PropTypes.string,
    division: PropTypes.string,
    position: PropTypes.string.isRequired,
  }).isRequired,
  refresh: PropTypes.func,
  afterSubmit: PropTypes.func,
}
