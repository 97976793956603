/**
 * Title: upcomingTrainingCardEdit.js
 * Description: This is a file that contains the components for the upcoming training card edit
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/upcomingTrainingCardEdit.js
 **/

import { useState, useEffect } from 'react'

import { Button, Modal, Input, Row, Col, notification } from 'antd'

import { API, graphqlOperation } from 'aws-amplify'

import { updateHomeUpcomingTrainingCard } from '../../api/mutations'
import { getHomeUpcomingTrainingCard } from '../../api/queries'

const { TextArea } = Input

const UpcomingTrainingCardEdit = ({
  setUTCardEdited,
  setUTEditOpen,
  userData,
}) => {
  const currentUser = userData

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [editText, setEditText] = useState('')

  const [afterSave, setAfterSave] = useState(null)

  const getUTCardText = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(getHomeUpcomingTrainingCard, {
          id: '30cb7bc3-55de-4386-ae72-69f6cfe0501a',
        })
      )
      setEditText(result.data.getHomeUpcomingTrainingCard.textDisplay)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getUTCardText()
  }, [afterSave])

  const showModal = () => {
    setIsModalOpen(true)
  }
  const handleOk = () => {
    setIsModalOpen(false)
  }
  const handleCancel = () => {
    setIsModalOpen(false)
  }

  const handleEditText = (e) => {
    setEditText(e.target.value)
  }

  const handleSave = async () => {
    try {
      const sendEditText = await API.graphql(
        graphqlOperation(updateHomeUpcomingTrainingCard, {
          input: {
            id: '30cb7bc3-55de-4386-ae72-69f6cfe0501a',
            textDisplay: editText,
            userID: currentUser.id,
          },
        })
      )
      if (sendEditText) {
        notification['success']({
          message: 'Success',
          description: 'Upcoming Training Card is edited!',
        })

        setUTCardEdited(sendEditText)
        setAfterSave(sendEditText)

        setTimeout(() => {
          setIsModalOpen(false)
          setUTEditOpen(false)
        }, 2500)
      } else {
        notification['error']({
          message: 'error',
          description: 'Nothing was edited! Contact System Admin.',
        })
      }
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'error',
        description: 'Nothing was edited! Contact System Admin.',
      })
    }
  }

  return (
    <>
      <Button onClick={showModal}>Edit Upcoming Training Card</Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        footer={null}
        title='Edit Upcoming Training Card'
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Row className={'mb-4'}>
          <Col span={24}>
            <TextArea
              defaultValue={editText}
              onChange={handleEditText}
              id='expectedOutcome'
              style={{ fontSize: 16 }}
              rows={4}
            />
          </Col>
        </Row>
        <Row justify='end'>
          <Button onClick={handleSave} type='primary'>
            Save
          </Button>
        </Row>
      </Modal>
    </>
  )
}
export default UpcomingTrainingCardEdit
