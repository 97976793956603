/**
 * Title: onlineTrainingRegistrationModal.js
 * Description: This is a file that contains the components that handle the online training registration modal
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/onlineTrainingRegistrationModal.js
 * History:
 * - 08/07/2024 - John Bazil Valdez
 * - Implement input validation to prevent the use of special characters and numbers in name fields, and enforce character limits to prevent excessive input.
 * - 09/23/2024 | John Bazil Valdez | Add a popup modal to show how many correct answer for the pre-test
 * - 10/16/2024 | Harry Lagunsad | Added the employment type field
 * - 10/22/2024 | Harry Lagunsad | Add a props for show score modal to identify if the post training is for async training or not
 **/
import React, { useEffect, useState } from 'react'

import {
  Button,
  Col,
  Input,
  Modal,
  Row,
  Select,
  Skeleton,
  Steps,
  notification,
} from 'antd'
import { API, Auth, graphqlOperation } from 'aws-amplify'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import { PutObjectCommand, S3Client } from '@aws-sdk/client-s3'

import { createTrainingRegistration } from '../../api/mutations'
import {
  Form2ByUserId,
  FormByUserId,
  IDLPUploadByUserId,
  getTrainingAnnouncement,
  listTrainingAnnouncements,
  registrationByTAID,
} from '../../api/queries'
import { numToString } from '../../utilities/numToString'
import HtmlParser from './html-parser'
import OnlineTrainingProgramsView from './onlineTrainingProgramsView'
import PreAssessmentCorrectScore from './pre-assessment-correct-score.js'
import PreAssessmentCustom from './preAssessmentCustom'

const { Step } = Steps
const { TextArea } = Input

/**
 * Represents a component for online training registration modal.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.trainingItem - The training item object.
 * @param {Function} props.setAfterRegister - The function to set after register.
 * @param {Function} props.gotoForms - The function to navigate to forms.
 * @param {Function} props.setRefresh - The function to set refresh.
 * @param {boolean} props.refresh - The refresh flag.
 * @returns {JSX.Element} The JSX element representing the online training registration modal.
 */
const OnlineTrainingRegistrationModal = ({
  trainingItem,
  setAfterRegister,
  gotoForms,
  setRefresh,
  refresh,
  afterRegister,
  userData,
}) => {
  const currentUser = userData

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [current, setCurrent] = useState(0)

  const [isAgree, setIsAgree] = useState('no')

  const [first_name, setFirst_name] = useState(null)
  const [middle_initial, setMiddle_initial] = useState(null)
  const [last_name, setLast_name] = useState(null)
  const [full_name, setFull_name] = useState(null)
  const [email_address, setEmail_address] = useState(null)
  const [user_sex, setUser_sex] = useState(null)
  const [contact_number, setContact_number] = useState(null)
  const [user_dept, setUser_dept] = useState(null)
  const [div_section, setDiv_section] = useState(null)
  const [user_designation, setUser_designation] = useState(null)
  const [nickname, setNickname] = useState('')

  const [letterUpload, setLetterUpload] = useState(null)

  const [expected_outcome, setExpected_outcome] = useState('')

  const [disableRegister, setDisableRegister] = useState(false)

  const [limitReached, setLimitReached] = useState(false)

  const [isPastToday, setIsPastToday] = useState(false)

  const [assessQuestionsSet, setAssessQuestionsSet] = useState([])

  const [isSubmitted, setIsSubmitted] = useState(false)

  const [hasNewPeriodForms, setHasNewPeriodForms] = useState(false)

  const [submittedEndorseLetter, setSubmittedEndorseLetter] = useState(false)
  const [reachLimitText, setReachLimitText] = useState('')

  const [childrenTrains, setChildrenTrains] = useState([])
  const [customRegistration, setCustomRegistration] = useState([])
  const [assessQuestionsSetTwo, setAssessQuestionsSetTwo] = useState([])
  const [assessQuestionsSetThree, setAssessQuestionsSetThree] = useState([])
  const [assessQuestionsSetFour, setAssessQuestionsSetFour] = useState([])

  const [expected_outcomeTwo, setExpected_outcomeTwo] = useState('')
  const [expected_outcomeThree, setExpected_outcomeThree] = useState('')
  const [expected_outcomeFour, setExpected_outcomeFour] = useState('')

  const [parentTrainingName, setParentTrainingName] = useState(null)

  const [isFinishedChecking, setIsFinishedChecking] = useState(false)

  const [assessmentQuestions, setAssessmentQuestions] = useState(null)
  const [validationError, setValidationError] = useState({})

  const [preAssessmentCorrectScore, setPreAssessmentCorrectScore] = useState(0)
  const [isPreAssessmentScoreOpen, setIsPreAssessmentScoreOpen] =
    useState(false)

  const [employmentType, setEmploymentType] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    if (trainingItem?.questionsAssessment) {
      setAssessmentQuestions(
        JSON.parse(trainingItem.questionsAssessment.assessmentQuestionnaire)
      )
    }
  }, [])

  /**
   * Fetches the parent training information.
   * @returns {Promise<void>} A promise that resolves when the parent training information is fetched.
   */
  const fetchParentTraining = async () => {
    try {
      const getParentName = await API.graphql(
        graphqlOperation(getTrainingAnnouncement, {
          id: trainingItem.parentTrainingID,
        })
      )

      setParentTrainingName(getParentName.data.getTrainingAnnouncement.title)
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Fetches training announcements from the API and updates the state with the fetched data.
   * @returns {Promise<void>} A promise that resolves when the fetch operation is complete.
   */
  const fetchTrainings = async () => {
    try {
      const result = await API.graphql({
        query: listTrainingAnnouncements,
        authMode: 'API_KEY',
        variables: {
          limit: 1000,
        },
      })

      if (trainingItem.linkedTrainingAnnouncementIDs) {
        const childrenIDs = JSON.parse(
          trainingItem.linkedTrainingAnnouncementIDs
        )
        const filteredArray =
          result.data.listTrainingAnnouncements.items.filter((num) =>
            childrenIDs.includes(num.id)
          )
        setChildrenTrains(filteredArray)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchTrainings()

    if (trainingItem.parentTrainingID) {
      fetchParentTraining()
    }
  }, [])

  useEffect(() => {
    if (refresh) {
      checkIfRegistered()
    }
  }, [refresh])

  /**
   * Checks if the current user is registered for a training.
   * If the user is registered, it sets the disableRegister flag to true.
   * If the user has submitted an endorsement letter, it sets the submittedEndorseLetter flag to true.
   * If the user is not registered, it checks the participant limit and department limit to determine if the user can register.
   * If the participant limit or department limit is reached, it sets the limitReached flag and the reachLimitText accordingly.
   * @returns {Promise<void>} A promise that resolves when the registration check is complete.
   */
  const checkIfRegistered = async () => {
    try {
      if (currentUser) {
        const result = await API.graphql(
          graphqlOperation(registrationByTAID, {
            trainingAnnouncementID: trainingItem.id,
            userID: { eq: currentUser.id },
            limit: 1000,
          })
        )

        if (result.data.registrationByTAID.items.length) {
          setDisableRegister(true)
          if (result.data.registrationByTAID.items[0].endorsementLetter) {
            setSubmittedEndorseLetter(true)
          }
        } else {
          const participantLimit =
            trainingItem.participantLimit === null ||
            trainingItem.participantLimit === 0 ||
            trainingItem.participantLimit === undefined
              ? 40
              : trainingItem.participantLimit

          const checkLength = await API.graphql(
            graphqlOperation(registrationByTAID, {
              trainingAnnouncementID: trainingItem.id,
              limit: 1000,
            })
          )

          if (trainingItem.limitPerDept !== null) {
            const userDept = currentUser.assignedDept
            const usersRegistered = checkLength.data.registrationByTAID.items
            const registeredUsersInDept = usersRegistered.filter(
              (user) => user.dept_office_unit_taskForce == userDept
            )
            const registeredCount = registeredUsersInDept.length
            const departmentLimit = JSON.parse(trainingItem.limitPerDept)[0][
              userDept
            ]

            if (registeredCount >= departmentLimit) {
              setLimitReached(true)
              setReachLimitText(
                'Training event already reached max limit of your department.'
              )
            } else if (usersRegistered.length >= participantLimit) {
              setLimitReached(true)
              setReachLimitText(
                'Training event already reached max limit of attendees.'
              )
            } else {
              setDisableRegister(false)
              setLimitReached(false)
            }
            return
          }

          if (
            checkLength.data.registrationByTAID.items.length >= participantLimit
          ) {
            setLimitReached(true)
          } else {
            setDisableRegister(false)
            setLimitReached(false)
          }
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // This will initialize the assessment dynamic variables where we gonna contain the asnwer on the assessment
    // questions depends on the length of the assestmentQuestion.
    let assessQuestions = {}
    if (assessmentQuestions) {
      for (let i = 0; i < assessmentQuestions.length; i++) {
        let numString = numToString(i)

        let question = 'question' + numString
        assessQuestions[question] = null
      }
      setAssessQuestionsSet(assessQuestions)
    }
  }, [assessmentQuestions])

  /**
   * Checks if the ILDP upload is in a new period.
   * @returns {Promise<void>} A promise that resolves when the check is complete.
   */
  const checkIfILDPUploadIsNewPeriod = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(IDLPUploadByUserId, {
          userID: currentUser.id,
          limit: 1000,
        })
      )
      const arrayToCheck = result.data.IDLPUploadByUserId.items

      if (arrayToCheck.length) {
        const foundIDLP = arrayToCheck.filter((item) => {
          const finalSubmitDate = moment(new Date(item.updatedAt))
          const finalSubmitDateCheckAgain = moment(new Date(item.etnaId))
          const currentPeriodDate = 'July 1, 2024 12:00:00 AM'

          if (
            finalSubmitDate.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            ) ||
            finalSubmitDateCheckAgain.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            )
          ) {
            if (item.isApproved == 1) {
              return item
            }
          }
        })

        if (foundIDLP.length) {
          checkIfRegistered()
          setIsFinishedChecking(true)
          setHasNewPeriodForms(true)
        } else {
          setHasNewPeriodForms(false)
          setIsFinishedChecking(true)
        }
      } else {
        setHasNewPeriodForms(false)
        setIsFinishedChecking(true)
      }
    } catch (err) {
      console.log(err)

      if (currentUser) {
        notification['error']({
          message: 'Error',
          duration: 500000,
          description:
            'Failed to get your list of uploaded ILDP forms. You may need to logout and login back to the website to try again. If this error remains, please report to system administrator.',
        })
      }
    }
  }

  /**
   * Checks if the ILDP form is in a new period.
   * @async
   * @function checkIfILDPFormIsNewPeriod
   * @returns {Promise<void>} - A promise that resolves when the check is complete.
   */
  const checkIfILDPFormIsNewPeriod = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(Form2ByUserId, {
          userID: currentUser.id,
          limit: 1000,
        })
      )
      const arrayToCheck = result.data.Form2ByUserId.items
      if (arrayToCheck.length) {
        const foundForm2 = arrayToCheck.filter((item) => {
          const finalSubmitDate = moment(new Date(item.updatedAt))
          const finalSubmitDateCheckAgain = moment(new Date(item.etnaId))
          const currentPeriodDate = 'July 1, 2023 12:00:00 AM'

          if (
            finalSubmitDate.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            ) ||
            finalSubmitDateCheckAgain.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            )
          ) {
            if (item.isApproved == 1 || item.isApproved == '1') {
              return item
            }
          }
        })

        if (foundForm2.length) {
          checkIfILDPUploadIsNewPeriod()
        } else {
          setHasNewPeriodForms(false)
          setIsFinishedChecking(true)
        }
      } else {
        setHasNewPeriodForms(false)
        setIsFinishedChecking(true)
      }
    } catch (err) {
      console.log(err)

      if (currentUser) {
        notification['error']({
          message: 'Error',
          duration: 500000,
          description:
            'Failed to get your list of ILDP forms answered. You may need to logout and login back to the website to try again. If this error remains, please report to system administrator.',
        })
      }
    }
  }

  /**
   *@description This function checks if eTNA foem is in a new period by querying the API for forms
   *submitted by the current user. It filters the forms based on specific criteria and
   *determines if there are any new period forms. If there are new period forms, it performs
   *further operations. If there are no forms or no new period forms, it updates the state
   *accordingly.
   *@async
   *@function
   *@name checkIfEtnaIsNewPeriod
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   **/
  const checkIfEtnaIsNewPeriod = async () => {
    try {
      const result = await API.graphql(
        graphqlOperation(FormByUserId, {
          userID: currentUser.id,
          limit: 1000,
        })
      )

      const arrayToCheck = result.data.FormByUserId.items

      if (arrayToCheck.length) {
        const foundForm1 = arrayToCheck.filter((item) => {
          const finalSubmitDate = moment(new Date(item.finalSubmitDate))
          const finalSubmitDateCheckAgain = moment(new Date(item.updatedAt))
          const currentPeriodDate = 'July 1, 2023 12:00:00 AM'

          if (
            finalSubmitDate.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            ) ||
            finalSubmitDateCheckAgain.isSameOrAfter(
              currentPeriodDate,
              'MMMM D, YYYY h:mm:ss A'
            )
          ) {
            if (item.isFinal === 'YES') {
              return item
            }
          }
        })

        if (foundForm1.length) {
          checkIfILDPFormIsNewPeriod()
        } else {
          setHasNewPeriodForms(false)
          setIsFinishedChecking(true)
        }
      } else {
        setHasNewPeriodForms(false)
        setIsFinishedChecking(true)
      }
    } catch (err) {
      console.log(err)

      if (currentUser) {
        // this could show multiple times as many as active trainings
        notification['error']({
          message: 'Error',
          duration: 500000,
          description:
            'Failed to get your list of eTNA. You may need to logout and login back to the website to try again. If this error remains, please report to system administrator.',
        })
      }
    }
  }

  /**
   * Checks if the training is before a specific period date.
   */
  const handleCheckIfTrainingIsBeforePeriod = () => {
    try {
      const beforeCurrentPeriodDate = 'June 30, 2023 12:59:59 PM'
      const checkIfTrainingCanBeOpened = moment(
        trainingItem.date
      ).isSameOrBefore(beforeCurrentPeriodDate, 'MMMM D, YYYY h:mm:ss A')

      if (checkIfTrainingCanBeOpened) {
        setHasNewPeriodForms(true)
        checkIfRegistered()

        setIsFinishedChecking(true)
      } else {
        checkIfEtnaIsNewPeriod()
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (currentUser) {
      handleCheckIfTrainingIsBeforePeriod()
    } else {
      setIsFinishedChecking(true)
    }

    // if (currentUser) {
    //   if (currentUser.role === 'admin') {
    //     setHasNewPeriodForms(true)
    //   } else {
    //     handleCheckIfTrainingIsBeforePeriod()
    //   }
    // }
  }, [])

  useEffect(() => {
    if (currentUser) {
      setFull_name(
        `${currentUser.name} ${currentUser.mname ? currentUser.mname : ''} ${
          currentUser.lname
        }`
      )
      setFirst_name(currentUser.name)
      setLast_name(currentUser.lname)
      setEmail_address(currentUser.username)
      setUser_dept(currentUser.assignedDept)
      setDiv_section(currentUser.division)
      setContact_number(currentUser.contact)
      setUser_designation(currentUser.position)
      setUser_sex(currentUser.gender)
    }
  })

  const handleEmploymentType = (e) => {
    setEmploymentType(e)
  }

  const handleMiddleInitial = (e) => {
    // Regex pattern for middle initial, accepts letters only
    const regex = /^(N\/A|[a-zA-ZñéáíóúÑÉÁÍÓÚ.]+)$/

    const value = e.target.value
    const maxLength = e.target.maxLength

    //Input a validation for middle initial using the namePattern

    if (!regex.test(value) && value !== '') {
      // Invalid input detected
      setMiddle_initial(value)
      setValidationError((prev) => ({
        ...prev,
        middleInitial: 'Enter a valid middle initial or N/A',
      }))
    } else if (value.length > maxLength) {
      // Input exceeds max length
      setMiddle_initial(value)
      setValidationError((prev) => ({
        ...prev,
        middleInitial: `Maximum ${maxLength} characters allowed`,
      }))
    } else {
      // Input is valid, update state
      setMiddle_initial(value)
      setValidationError((prev) => ({
        ...prev,
        middleInitial: '', // Clear validation error if valid
      }))
    }
  }

  /**
   * Handles the change event of the radio button.
   * @param {Object} e - The event object.
   */
  const handleRadio = (e) => {
    setIsAgree(e.target.value)
  }

  /**
   * Handles the change event for the nickname input field.
   * @param {Object} e - The event object.
   */
  const handleNickname = (e) => {
    // Regex pattern for nickname, accepts letters, spaces, hyphens, and apostrophes
    const regex = /^(?:[a-zA-ZñéáíóúÑÉÁÍÓÚ.\s-']+)$/

    const value = e.target.value
    const maxLength = e.target.maxLength

    if (!regex.test(value) && value !== '') {
      // Invalid input detected
      setNickname(value)
      setValidationError((prev) => ({
        ...prev,
        nickname: 'Enter a valid nickname',
      }))
    } else if (value.length > maxLength) {
      // Input exceeds max length
      setNickname(value)
      setValidationError((prev) => ({
        ...prev,
        nickname: `Maximum ${maxLength} characters allowed`,
      }))
    } else {
      // Input is valid, update state
      setNickname(value)
      setValidationError((prev) => ({
        ...prev,
        nickname: '', // Clear validation error if valid
      }))
    }
  }

  /**
   * Handles the change event for the email address input field.
   * @param {Object} e - The event object.
   */
  const handleEmailAddress = (e) => {
    setEmail_address(e.target.value)
  }

  /**
   * Handles the change event of the sex radio buttons.
   * @param {Object} e - The event object.
   */
  const handleSexRadio = (e) => {
    setUser_sex(e.target.value)
  }

  /**
   * Handles the change event of the contact number input field.
   * @param {Object} e - The event object.
   */
  const handleContactNumber = (e) => {
    setContact_number(e.target.value)
  }

  /**
   * Handles the selection of a department.
   * @param {Event} e - The event object.
   */
  const handleDepartment = (e) => {
    setUser_dept(e.target.value)
  }

  /**
   * Handles the division section change event.
   * @param {Event} e - The event object.
   */
  const handleDivisionSection = (e) => {
    setDiv_section(e.target.value)
  }

  /**
   * Handles the change event for the designation input field.
   * @param {Event} e - The event object.
   */
  const handleDesignation = (e) => {
    setUser_designation(e.target.value)
  }

  /**
   * Handles the expected outcome of the training registration.
   * @param {Event} e - The event object.
   */
  const handleExpectedOutcome = (e) => {
    setExpected_outcome(e.target.value)
  }

  /**
   * Sets the value of expected_outcomeTwo based on the target value of the event.
   * @param {Event} e - The event object.
   */
  const handleExpectedOutcomeTwo = (e) => {
    setExpected_outcomeTwo(e.target.value)
  }

  /**
   * Sets the value of expected_outcomeThree state based on the target value of the event.
   * @param {Event} e - The event object.
   */
  const handleExpectedOutcomeThree = (e) => {
    setExpected_outcomeThree(e.target.value)
  }

  /**
   * Updates the expected outcome for the fourth option.
   * @param {Event} e - The event object.
   */
  const handleExpectedOutcomeFour = (e) => {
    setExpected_outcomeFour(e.target.value)
  }

  const handleNextButton = () => {
    switch (current) {
      case 0:
        return isAgree == 'no'
      case 1:
        return !(
          full_name &&
          email_address &&
          user_sex &&
          contact_number &&
          user_dept &&
          div_section &&
          user_designation &&
          nickname &&
          employmentType?.length &&
          !validationError.middleInitial &&
          !validationError.nickname
        )
      case 2:
        if (trainingItem.isEndorsementRequired == 'yes') {
          return !(letterUpload?.length >= 1 || letterUpload != null)
        } else {
          return current != 2
        }
    }
  }

  /**
   * Determines whether the next button should be enabled or disabled based on the current step and form inputs.
   * @returns {boolean} True if the next button should be disabled, false if it should be enabled.
   */
  const handleNextButtonWithAssessment = () => {
    switch (current) {
      case 0:
        return isAgree == 'no'
      case 1:
        return !(
          full_name &&
          email_address &&
          user_sex &&
          contact_number &&
          user_dept &&
          div_section &&
          user_designation &&
          nickname &&
          employmentType?.length &&
          !validationError.middleInitial &&
          !validationError.nickname
        )
      case 2:
        if (trainingItem.isEndorsementRequired == 'yes') {
          return !(letterUpload?.length >= 1 || letterUpload != null)
        } else {
          return current != 2
        }
      case 3:
        if (assessQuestionsSet) {
          let holdAnswers = []
          for (let key in assessQuestionsSet) {
            if (assessQuestionsSet[key] === null) {
              holdAnswers.push('')
            }
          }
          return holdAnswers.length >= 1
        } else {
          return false
        }

      case 4:
        if (trainingItem.linkedTrainingAnnouncementIDs) {
          if (assessQuestionsSetTwo) {
            let holdAnswers = []
            for (let key in assessQuestionsSetTwo) {
              if (assessQuestionsSetTwo[key] === null) {
                holdAnswers.push('')
              }
            }
            return holdAnswers.length >= 1
          } else {
            return false
          }
        }
        break

      case 5:
        if (trainingItem.linkedTrainingAnnouncementIDs) {
          if (assessQuestionsSetThree) {
            let holdAnswers = []
            for (let key in assessQuestionsSetThree) {
              if (assessQuestionsSetThree[key] === null) {
                holdAnswers.push('')
              }
            }
            return holdAnswers.length >= 1
          } else {
            return false
          }
        }
        break

      case 6:
        if (trainingItem.linkedTrainingAnnouncementIDs) {
          let holdAnswers = []
          if (assessQuestionsSetFour) {
            for (let key in assessQuestionsSetFour) {
              if (assessQuestionsSetFour[key] === null) {
                holdAnswers.push('')
              }
            }
          }

          return holdAnswers.length >= 1
        }
    }
  }

  /**
   * Checks if the participant limit for a training event has been reached.
   * This function queries an API endpoint to retrieve the number of participants registered
   * for a specific training event. If the number of registered participants is equal to or greater
   * than the participant limit for the event, the function returns `false`. Otherwise, it returns `true`.
   * If an error occurs while querying the API, the function logs the error to the console and returns `false`.
   * @async
   * @function
   * @returns {boolean} - Returns `true` if the participant limit has not been reached and `false` if it has.
   */
  const checkIfLimitReached = async (a) => {
    setIsSubmitted(true)
    try {
      const checkLength = await API.graphql(
        graphqlOperation(registrationByTAID, {
          trainingAnnouncementID: trainingItem.id,
          limit: 1000,
        })
      )

      // Determine the participant limit for the training event
      const participantLimit =
        trainingItem.participantLimit === null ||
        trainingItem.participantLimit === 0 ||
        trainingItem.participantLimit === undefined
          ? 40
          : trainingItem.participantLimit

      // Check if the number of registered participants is equal to or greater than the participant limit
      if (
        checkLength.data.registrationByTAID.items.length >= participantLimit
      ) {
        // If the limit has been reached, display a notification to the user and return `false`
        notification['info']({
          message: 'Message',
          description:
            'Sorry, the training event you are trying to register for has already reached its maximum number of participants',
        })
        setIsModalOpen(false)
        handleCheckIfTrainingIsBeforePeriod()

        return
      }

      // If the limit has not been reached, call function
      if (a === 'without') {
        handleRegistration()
      }
    } catch (err) {
      // If an error occurs while querying the API, log the error to the console and return `false`
      console.log(err)
      notification['error']({
        message: 'Error',
        description:
          'An unexpected error occurred. Please contact your administrator for assistance.',
      })
    }
  }

  const handleTotalCorrectItem = () => {
    try {
      if (trainingItem && trainingItem.questionsAssessment) {
        let totalCorrectScore = 0
        const assessmentQuestionnaire = JSON.parse(
          trainingItem?.questionsAssessment?.assessmentQuestionnaire
        )

        // Iterate through the questionnaire and compare answers
        assessmentQuestionnaire.forEach((question, index) => {
          const questionKey = `question${numToString(index)}`
          const userAnswer = assessQuestionsSet[questionKey]
          const correctAnswer = question.correctAnswer

          if (userAnswer === correctAnswer) {
            totalCorrectScore += 1
          }
        })

        setPreAssessmentCorrectScore(totalCorrectScore)
        setIsPreAssessmentScoreOpen(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   *Handles the registration process for a training/event.
   *@async
   *@function handleRegistration
   *@returns {Promise<void>} - Resolves once the registration is completed.
   **/
  const handleRegistration = async () => {
    const linkedTrainings = JSON.parse(
      trainingItem.linkedTrainingAnnouncementIDs
    )

    if (expected_outcome.length >= 1) {
      if (trainingItem.isOpenRegistration === true) {
        try {
          const credentials = await Auth.currentCredentials()
          const accessKeyId = credentials.accessKeyId
          const secretAccessKey = credentials.secretAccessKey
          const sessionToken = credentials.sessionToken

          const s3 = new S3Client({
            region: 'ap-southeast-1',
            credentials: {
              accessKeyId: accessKeyId,
              secretAccessKey: secretAccessKey,
              sessionToken: sessionToken,
            },
          })

          const questionnaireValue = assessQuestionsSet

          if (letterUpload) {
            if (letterUpload.length >= 1) {
              async function uploadFiles(files) {
                const promises = files.map(async (file) => {
                  const params = {
                    Bucket: 's3etnahris133956-dev',
                    Key: `uploadedByAdmin/${file.name}`,
                    Body: file,
                    ACL: 'public-read',
                  }

                  const command = new PutObjectCommand(params)
                  await s3.send(command)
                  const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`
                  return url
                })

                return Promise.all(promises)
              }
              const uploadsDone = await uploadFiles(letterUpload)
              if (uploadsDone) {
                const adminUpload = await API.graphql(
                  graphqlOperation(createTrainingRegistration, {
                    input: {
                      userID: currentUser.id,
                      trainingAnnouncementID: trainingItem.id,
                      isAgreeDataPrivacyConsent:
                        isAgree == 'yes' || isAgree === true,
                      fullName: full_name,
                      first_name: first_name,
                      middle_initial: middle_initial,
                      last_name: last_name,
                      emailAddress: email_address,
                      gender: user_sex,
                      contactNumber: contact_number,
                      dept_office_unit_taskForce: user_dept,
                      division_section: div_section,
                      designation_position: user_designation,
                      endorsementLetter: JSON.stringify(uploadsDone),
                      questionnaire: JSON.stringify(questionnaireValue),
                      expectedOutcomes: expected_outcome,
                      isRegistered: true,
                      isSubmitEndorsementLetter: false,
                      isAttended: false,
                      isPostEvalDone: false,
                      isCourseOutputDone: false,
                      additionalRemarks: '',
                      nickname,
                      employmentType,
                    },
                  })
                )
                if (adminUpload) {
                  if (linkedTrainings?.length > 0) {
                    const promisesChildTrains = linkedTrainings.map(
                      async (id, count) => {
                        const adminUploadNext = API.graphql(
                          graphqlOperation(createTrainingRegistration, {
                            input: {
                              userID: currentUser.id,
                              trainingAnnouncementID: id,
                              isAgreeDataPrivacyConsent:
                                isAgree == 'yes' || isAgree === true,
                              fullName: full_name,
                              emailAddress: email_address,
                              gender: user_sex,
                              contactNumber: contact_number,
                              dept_office_unit_taskForce: user_dept,
                              division_section: div_section,
                              designation_position: user_designation,
                              endorsementLetter: JSON.stringify(uploadsDone),
                              questionnaire:
                                count === 0
                                  ? JSON.stringify(assessQuestionsSetTwo)
                                  : count == 1
                                    ? JSON.stringify(assessQuestionsSetThree)
                                    : JSON.stringify(assessQuestionsSetFour),
                              expectedOutcomes:
                                count == 0
                                  ? expected_outcomeTwo
                                  : count == 1
                                    ? expected_outcomeThree
                                    : expected_outcomeFour,
                              isRegistered: true,
                              isSubmitEndorsementLetter: false,
                              isAttended: false,
                              isPostEvalDone: false,
                              isCourseOutputDone: false,
                              additionalRemarks: '',
                              nickname,
                              employmentType,
                            },
                          })
                        )
                        return adminUploadNext
                      }
                    )

                    const finishedChildTrainsRegistrations =
                      await Promise.all(promisesChildTrains)

                    if (finishedChildTrainsRegistrations) {
                      notification['success']({
                        message: 'Success',
                        description:
                          "You're registered now to the Training/Event. You're also registered with other training's that is linked in this training ",
                      })

                      setAfterRegister(finishedChildTrainsRegistrations)
                      setRefresh(finishedChildTrainsRegistrations)
                      setIsAgree('no')
                      setFull_name(null)
                      setFirst_name(null)
                      setMiddle_initial(null)
                      setLast_name(null)
                      setEmail_address(null)
                      setUser_sex(null)
                      setContact_number(null)
                      setUser_dept(null)
                      setDiv_section(null)
                      setUser_designation(null)
                      setLetterUpload(null)
                      setExpected_outcome('')
                      setCurrent(0)
                      setNickname('')
                      setEmploymentType('')
                      setDisableRegister(true)
                      handleCheckIfTrainingIsBeforePeriod()
                      setTimeout(() => {
                        setIsModalOpen(false)
                      }, 3000)
                    }
                  } else {
                    notification['success']({
                      message: 'Success',
                      description:
                        "You're registered now to the Training/Event.",
                    })

                    setAfterRegister(adminUpload)
                    handleTotalCorrectItem()
                    setIsAgree('no')
                    setFull_name(null)
                    setFirst_name(null)
                    setMiddle_initial(null)
                    setLast_name(null)
                    setEmail_address(null)
                    setUser_sex(null)
                    setContact_number(null)
                    setUser_dept(null)
                    setDiv_section(null)
                    setUser_designation(null)
                    setLetterUpload(null)
                    setExpected_outcome('')
                    setCurrent(0)
                    setNickname('')
                    setEmploymentType('')
                    setDisableRegister(true)
                    handleCheckIfTrainingIsBeforePeriod()
                    setTimeout(() => {
                      setIsModalOpen(false)
                    }, 3000)
                  }
                }
              }
            } else {
              const params = {
                Bucket: 's3etnahris133956-dev',
                Key: `uploadedByAdmin/${letterUpload.name}`,
                Body: letterUpload,
                ACL: 'public-read',
              }
              const command = new PutObjectCommand(params)
              await s3.send(command)
              const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

              const adminUpload = await API.graphql(
                graphqlOperation(createTrainingRegistration, {
                  input: {
                    userID: currentUser.id,
                    trainingAnnouncementID: trainingItem.id,
                    isAgreeDataPrivacyConsent:
                      isAgree == 'yes' || isAgree === true,
                    fullName: full_name,
                    emailAddress: email_address,
                    gender: user_sex,
                    contactNumber: contact_number,
                    dept_office_unit_taskForce: user_dept,
                    division_section: div_section,
                    designation_position: user_designation,
                    endorsementLetter: url,
                    questionnaire: JSON.stringify(questionnaireValue),
                    expectedOutcomes: expected_outcome,
                    isRegistered: true,
                    isSubmitEndorsementLetter: false,
                    isAttended: false,
                    isPostEvalDone: false,
                    isCourseOutputDone: false,
                    additionalRemarks: '',
                    nickname,
                    employmentType,
                  },
                })
              )
              if (adminUpload) {
                if (linkedTrainings?.length > 0) {
                  const promisesChildTrains = linkedTrainings.map(
                    async (id, count) => {
                      const adminUploadNext = API.graphql(
                        graphqlOperation(createTrainingRegistration, {
                          input: {
                            userID: currentUser.id,
                            trainingAnnouncementID: id,
                            isAgreeDataPrivacyConsent:
                              isAgree == 'yes' || isAgree === true,
                            fullName: full_name,
                            emailAddress: email_address,
                            gender: user_sex,
                            contactNumber: contact_number,
                            dept_office_unit_taskForce: user_dept,
                            division_section: div_section,
                            designation_position: user_designation,
                            endorsementLetter: url,
                            questionnaire:
                              count === 0
                                ? JSON.stringify(assessQuestionsSetTwo)
                                : count == 1
                                  ? JSON.stringify(assessQuestionsSetThree)
                                  : JSON.stringify(assessQuestionsSetFour),
                            expectedOutcomes:
                              count == 0
                                ? expected_outcomeTwo
                                : count == 1
                                  ? expected_outcomeThree
                                  : expected_outcomeFour,
                            isRegistered: true,
                            isSubmitEndorsementLetter: false,
                            isAttended: false,
                            isPostEvalDone: false,
                            isCourseOutputDone: false,
                            additionalRemarks: '',
                            nickname,
                            employmentType,
                          },
                        })
                      )
                      return adminUploadNext
                    }
                  )

                  const finishedChildTrainsRegistrations =
                    await Promise.all(promisesChildTrains)

                  if (finishedChildTrainsRegistrations) {
                    notification['success']({
                      message: 'Success',
                      description:
                        "You're registered now to the Training/Event. You're also registered with other training's that is linked in this training ",
                    })

                    setAfterRegister(finishedChildTrainsRegistrations)
                    setRefresh(finishedChildTrainsRegistrations)
                    setIsAgree('no')
                    setFull_name(null)
                    setFirst_name(null)
                    setMiddle_initial(null)
                    setLast_name(null)
                    setEmail_address(null)
                    setUser_sex(null)
                    setContact_number(null)
                    setUser_dept(null)
                    setDiv_section(null)
                    setUser_designation(null)
                    setLetterUpload(null)
                    setExpected_outcome('')
                    setCurrent(0)
                    setNickname('')
                    setEmploymentType('')
                    setDisableRegister(true)
                    handleCheckIfTrainingIsBeforePeriod()
                    setTimeout(() => {
                      setIsModalOpen(false)
                    }, 3000)
                  }
                } else {
                  notification['success']({
                    message: 'Success',
                    description: "You're registered now to the Training/Event.",
                  })

                  handleTotalCorrectItem()
                  setAfterRegister(adminUpload)
                  setIsAgree('no')
                  setFull_name(null)
                  setEmail_address(null)
                  setUser_sex(null)
                  setContact_number(null)
                  setUser_dept(null)
                  setDiv_section(null)
                  setUser_designation(null)
                  setLetterUpload(null)
                  setExpected_outcome('')
                  setCurrent(0)
                  setNickname('')
                  setEmploymentType('')
                  setDisableRegister(true)
                  handleCheckIfTrainingIsBeforePeriod()
                  setTimeout(() => {
                    setIsModalOpen(false)
                  }, 3000)
                }
              }
            }
          } else {
            const adminUpload = await API.graphql(
              graphqlOperation(createTrainingRegistration, {
                input: {
                  userID: currentUser.id,
                  trainingAnnouncementID: trainingItem.id,
                  isAgreeDataPrivacyConsent:
                    isAgree == 'yes' || isAgree === true,
                  fullName: full_name,
                  emailAddress: email_address,
                  gender: user_sex,
                  contactNumber: contact_number,
                  dept_office_unit_taskForce: user_dept,
                  division_section: div_section,
                  designation_position: user_designation,
                  endorsementLetter: '',
                  questionnaire: JSON.stringify(questionnaireValue),
                  expectedOutcomes: expected_outcome,
                  isRegistered: true,
                  isSubmitEndorsementLetter: false,
                  isAttended: false,
                  isPostEvalDone: false,
                  isCourseOutputDone: false,
                  additionalRemarks: '',
                  nickname,
                  employmentType,
                },
              })
            )
            if (adminUpload) {
              if (linkedTrainings?.length > 0) {
                const promisesChildTrains = linkedTrainings.map(
                  async (id, count) => {
                    const adminUploadNext = API.graphql(
                      graphqlOperation(createTrainingRegistration, {
                        input: {
                          userID: currentUser.id,
                          trainingAnnouncementID: id,
                          isAgreeDataPrivacyConsent:
                            isAgree == 'yes' || isAgree === true,
                          fullName: full_name,
                          emailAddress: email_address,
                          gender: user_sex,
                          contactNumber: contact_number,
                          dept_office_unit_taskForce: user_dept,
                          division_section: div_section,
                          designation_position: user_designation,
                          endorsementLetter: '',
                          questionnaire:
                            count === 0
                              ? JSON.stringify(assessQuestionsSetTwo)
                              : count == 1
                                ? JSON.stringify(assessQuestionsSetThree)
                                : JSON.stringify(assessQuestionsSetFour),
                          expectedOutcomes:
                            count == 0
                              ? expected_outcomeTwo
                              : count == 1
                                ? expected_outcomeThree
                                : expected_outcomeFour,
                          isRegistered: true,
                          isSubmitEndorsementLetter: false,
                          isAttended: false,
                          isPostEvalDone: false,
                          isCourseOutputDone: false,
                          additionalRemarks: '',
                          nickname,
                          employmentType,
                        },
                      })
                    )
                    return adminUploadNext
                  }
                )

                const finishedChildTrainsRegistrations =
                  await Promise.all(promisesChildTrains)

                if (finishedChildTrainsRegistrations) {
                  notification['success']({
                    message: 'Success',
                    description:
                      "You're registered now to the Training/Event. You're also registered with other training's that is linked in this training ",
                  })

                  setAfterRegister(finishedChildTrainsRegistrations)
                  setRefresh(finishedChildTrainsRegistrations)
                  setIsAgree('no')
                  setFull_name(null)
                  setEmail_address(null)
                  setUser_sex(null)
                  setContact_number(null)
                  setUser_dept(null)
                  setDiv_section(null)
                  setUser_designation(null)
                  setLetterUpload(null)
                  setExpected_outcome('')
                  setCurrent(0)
                  setNickname('')
                  setEmploymentType('')
                  setDisableRegister(true)
                  handleCheckIfTrainingIsBeforePeriod()
                  setTimeout(() => {
                    setIsModalOpen(false)
                  }, 3000)
                }
              } else {
                notification['success']({
                  message: 'Success',
                  description: "You're registered now to the Training/Event.",
                })

                handleTotalCorrectItem()
                setAfterRegister(adminUpload)
                setIsAgree('no')
                setFull_name(null)
                setEmail_address(null)
                setUser_sex(null)
                setContact_number(null)
                setUser_dept(null)
                setDiv_section(null)
                setUser_designation(null)
                setLetterUpload(null)
                setExpected_outcome('')
                setCurrent(0)
                setNickname('')
                setEmploymentType('')
                setDisableRegister(true)
                handleCheckIfTrainingIsBeforePeriod()

                setTimeout(() => {
                  setIsModalOpen(false)
                }, 3000)
              }
            }
          }
        } catch (error) {
          console.log(error)
          notification['error']({
            message: 'Error',
            description: 'Please try again later to register.',
          })
        }
      } else {
        notification['error']({
          message: 'Error',
          description:
            'Registration to this event is already closed at this time.',
        })
      }
    } else {
      setIsSubmitted(false)
      notification['error']({
        message: 'Error',
        description:
          'Please answer this question: What are your expected outcome/s and/or learnings after the training?',
      })
    }
  }

  /**
   * Array of steps with assessment.
   * Each step contains a title and content.
   * The content is a JSX element representing the form for each step.
   * @type {Array<{title: string, content: JSX.Element}>}
   */
  const stepsWithAssessment = [
    {
      title: 'Data Privacy Consent Form',
      content: (
        <>
          <Row justify='center' style={{ fontSize: 16 }}>
            <Col className='d-flex flex-column justify-content-center align-items-center'>
              <div className='d-flex justify-content-center'>
                <Row className='col-md-5 col-12'>
                  <span>
                    <strong>
                      I hereby authorize and give the Strategic Human Resource
                      Unit a consent that all personal information indicated
                      herein will be kept confidential for training purposes
                      only. I further understand that this form is being
                      protected by the Republic Act 10173 or the "Data Privacy
                      Act".
                    </strong>
                  </span>
                </Row>
              </div>
              <Row
                style={{ marginTop: 24 }}
                justify='space-evenly'
                onChange={handleRadio}
                className='col-md-7 col-12'
              >
                <Col>
                  <input
                    style={{ marginRight: 8 }}
                    defaultChecked={isAgree == 'no'}
                    value='no'
                    name='dpcf'
                    id='dpcfNo'
                    type='radio'
                  />
                  <label htmlFor='dpcfNo'>Disagree</label>
                </Col>
                <Col>
                  <input
                    style={{ marginRight: 8 }}
                    defaultChecked={isAgree == 'yes'}
                    value='yes'
                    name='dpcf'
                    id='dpcfYes'
                    type='radio'
                  />
                  <label htmlFor='dpcfYes'>Agree</label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.02</b>
            </span>
          </Row>
        </>
      ),
    },
    {
      title: 'Demographic Profile of Participants',
      content: (
        <>
          <Row className={'mb-3'} justify='center'>
            <Col span={24}>
              <span style={{ fontSize: '20px' }}>
                <b>
                  Before proceeding to the next part, please review your details
                  in the Demographic Profile of Participants Form.
                </b>
              </span>
            </Col>
          </Row>
          <Row justify='center'>
            <Col>
              <Row justify='center'>
                <Col style={{ width: 830 }}>
                  <div className='d-flex justify-content-between gap-2'>
                    <Row
                      className='w-100'
                      style={{
                        marginBottom: 12,
                        fontSize: 16,
                        height: '50px',
                      }}
                    >
                      <label
                        style={{ fontSize: 16 }}
                        className='loginInput-label'
                        htmlFor='firstName'
                      >
                        First name <em className='asterisk'>**</em>
                      </label>
                      <Input
                        disabled
                        defaultValue={first_name}
                        className={'text-center w-100'}
                        type='text'
                        id='firstName'
                      />
                    </Row>
                    <Row
                      className='w-50'
                      style={{ marginBottom: 12, fontSize: 16 }}
                    >
                      <label
                        style={{ fontSize: 16 }}
                        className='loginInput-label'
                        htmlFor='middleInitial'
                      >
                        Middle initial
                      </label>
                      <Input
                        defaultValue={middle_initial}
                        onChange={handleMiddleInitial}
                        className={`text-center ${
                          validationError.middleInitial && 'border-danger'
                        }`}
                        type='text'
                        id='middleInitial'
                        maxLength={3}
                      />
                      {validationError.middleInitial && (
                        <span className='text-danger'>
                          {validationError.middleInitial}
                        </span>
                      )}
                    </Row>
                    <Row
                      className='w-100'
                      style={{ marginBottom: 12, fontSize: 16, height: '50px' }}
                    >
                      <label
                        style={{ fontSize: 16 }}
                        className='loginInput-label'
                        htmlFor='lastName'
                      >
                        Last name <em className='asterisk'>**</em>
                      </label>
                      <Input
                        disabled
                        defaultValue={last_name}
                        className={'text-center w-100'}
                        type='text'
                        id='lastName'
                      />
                    </Row>
                  </div>

                  <Row>
                    <label
                      style={{ fontSize: 16 }}
                      className='loginInput-label'
                      htmlFor='nickname'
                    >
                      Nickname <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row style={{ marginBottom: 12, fontSize: 16 }}>
                    <Input
                      defaultValue={nickname}
                      onChange={handleNickname}
                      placeholder={'enter your nickname here'}
                      className={`text-center ${
                        validationError.nickname && 'border-danger'
                      }`}
                      type='text'
                      id='nickname'
                      maxLength={50}
                    />
                    {validationError.nickname && (
                      <span className='text-danger'>
                        {validationError.nickname}
                      </span>
                    )}
                  </Row>
                  <Row>
                    <label
                      className='loginInput-label'
                      style={{ fontSize: 16 }}
                      htmlFor='employmentType'
                    >
                      Type of Employment <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row>
                    <Select
                      value={employmentType}
                      placeholder={'select your employment type'}
                      onChange={handleEmploymentType}
                      style={{
                        width: '50%',
                      }}
                      options={[
                        {
                          value: 'permanentOrRegular',
                          label: 'Permanent/Regular',
                        },
                        {
                          value: 'cosOrJoOrConsultant',
                          label: 'COS/JO/Consultant',
                        },
                      ]}
                      id='employmentType'
                    />
                  </Row>
                  <Row gutter={6}>
                    <Col className='col-md-6 col-12' style={{ fontSize: 16 }}>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='emailAddress'
                        >
                          EMAIL ADDRESS <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={email_address}
                          onChange={handleEmailAddress}
                          style={{ fontSize: 16 }}
                          type='email'
                          id='emailAddress'
                        />
                      </Row>
                      <Row>
                        <span
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                        >
                          SEX <em className='asterisk'>**</em>
                        </span>
                      </Row>

                      <Row
                        className='ms-md-0 ms-4'
                        onChange={handleSexRadio}
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col className='col-md-auto col-12'>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'male'}
                              value='male'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexMale'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexMale'
                            >
                              Male
                            </label>
                          </Row>
                        </Col>
                        <Col className='col-md-auto col-12'>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'female'}
                              value='female'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexFemale'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexFemale'
                            >
                              Female
                            </label>
                          </Row>
                        </Col>
                        <Col className='col-md-auto col-12'>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'notSpecified'}
                              value='notSpecified'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexNotMentioned'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexNotMentioned'
                            >
                              Prefer not to mention
                            </label>
                          </Row>
                        </Col>
                      </Row>

                      <Row className='mt-md-0 mt-5'>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='contactNumber'
                        >
                          CONTACT NUMBER <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={contact_number}
                          onChange={handleContactNumber}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='contactNumber'
                        />
                      </Row>
                    </Col>
                    <Col className='col-md-6 col-12'>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='department'
                        >
                          DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={user_dept}
                          onChange={handleDepartment}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='department'
                        />
                      </Row>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='divisionSection'
                        >
                          DIVISION / SECTION{' '}
                          <small>(If not applicable, type N/A)</small>{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={div_section}
                          onChange={handleDivisionSection}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='divisionSection'
                        />
                      </Row>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='designation'
                        >
                          DESIGNATION/POSITION <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={user_designation}
                          onChange={handleDesignation}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='designation'
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className='d-md-flex justify-content-center mt-md-3'>
            <div className='col-md-5'>
              <Row className='d-md-flex justify-content-start'>
                <em className='requiredFields'>** All fields are required</em>
              </Row>
            </div>
          </div>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.04</b>
            </span>
          </Row>
        </>
      ),
    },
    {
      title: 'Endorsement Letter',
      content: (
        <Row justify='center' style={{ fontSize: 16 }}>
          <Col style={{ width: 800 }}>
            <Row justify='center'>
              <Col>
                {trainingItem?.stepThreeID ? (
                  <>
                    <p
                      className='text-center'
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      <HtmlParser
                        content={trainingItem?.stepThreeText?.textDisplay?.replace(
                          /\n/g,
                          '<br>'
                        )}
                      />
                    </p>
                    <p className={'text-center'}>
                      You may edit your response to upload your endorsement
                      letter or send a hardcopy to the HRMD.
                    </p>
                  </>
                ) : (
                  <>
                    <p className={'text-center'}>
                      Kindly submit the formal letter duly signed by your
                      Department/Office Head allowing your attendance in this
                      training on official time.{' '}
                    </p>
                    <p className={'text-center'}>
                      You may edit your response to upload your endorsement
                      letter or send a hardcopy to the HRMD.
                    </p>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <label
                htmlFor='endorsementLetter'
                className='loginInput-label'
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Upload Here{' '}
              </label>
              <Input
                multiple
                id='endorsementLetter'
                name='endorsementLetter'
                type='file'
                accept='image/jpeg,image/gif,image/png,application/pdf'
                onChange={(e) => {
                  setLetterUpload(Array.from(e.target.files))
                }}
              />
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: `${childrenTrains.length ? 'Pre-Test 1' : 'Pre-Test'}`,
      content: (
        <>
          <div className='col-md-8 offset-md-2 p-md-0 p-2'>
            {childrenTrains.length ? (
              <Row justify='center'>
                <h3>
                  {trainingItem.title} <em>(pre-assessment)</em>
                </h3>
              </Row>
            ) : null}
            <PreAssessmentCustom
              identifier={0}
              trainingItem={trainingItem}
              assessmentQuestions={assessmentQuestions}
              assessmentQuestionsTwo={null}
              assessmentQuestionsThree={null}
              assessmentQuestionsFour={null}
              setAssessQuestionsSet={setAssessQuestionsSet}
              setAssessQuestionsSetTwo={null}
              setAssessQuestionsSetThree={null}
              setAssessQuestionsSetFour={null}
            />
          </div>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.10</b>
            </span>
          </Row>
        </>
      ),
    },
    {
      title: 'Expected Outcomes',
      content: (
        <>
          <Row justify='center'>
            <Col style={{ width: 800 }}>
              <Row>
                <label
                  style={{ fontSize: 16 }}
                  className='loginInput-label'
                  htmlFor='expectedOutcome'
                >
                  What are your expected outcome/s and/or learnings after the
                  training? <span className='asterisk'>**</span>
                </label>
              </Row>
              <Row>
                <TextArea
                  onChange={handleExpectedOutcome}
                  id='expectedOutcome'
                  style={{ fontSize: 16 }}
                  rows={4}
                  maxLength={512}
                />
              </Row>
            </Col>
          </Row>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.06</b>
            </span>
          </Row>
        </>
      ),
    },
  ]

  useEffect(() => {
    if (trainingItem.linkedTrainingAnnouncementIDs) {
      childrenTrains.forEach((obj, index) => {
        if (obj.questionsAssessment !== null) {
          let customAssessmentQuestions
          try {
            customAssessmentQuestions = JSON.parse(
              obj.questionsAssessment.assessmentQuestionnaire
            )
          } catch (error) {
            console.log('Error parsing assessmentQuestionnaire:', error)
            notification['error']({
              message: 'Error',
              description:
                'There was an error parsing the assessment questionnaire. Please try again later.',
            })
            return
          }

          if (customAssessmentQuestions) {
            let assessQuestions = {}
            for (let i = 0; i < assessmentQuestions.length; i++) {
              let numString = numToString(i)
              let question = 'question' + numString
              assessQuestions[question] = null
            }

            switch (index) {
              case 0:
                setAssessQuestionsSetTwo(assessQuestions)
                break
              case 1:
                setAssessQuestionsSetThree(assessQuestions)
                break
              case 2:
                setAssessQuestionsSetFour(assessQuestions)
                break
            }
          }
        }
      })
    }
  }, [assessmentQuestions, childrenTrains])

  useEffect(() => {
    if (trainingItem.linkedTrainingAnnouncementIDs) {
      const newObject = childrenTrains.map((obj, index) => {
        if (obj.questionsAssessment !== null) {
          let customAssessmentQuestions = JSON.parse(
            obj.questionsAssessment.assessmentQuestionnaire
          )

          return {
            title: `Pre-Test ${index + 2}`,
            content: (
              <>
                {current > 3 ? (
                  <div className='col-md-8 offset-md-2 p-md-0 p-2'>
                    <Row justify='center'>
                      <h3>
                        {obj.title} <em>(pre-assessment)</em>
                      </h3>
                    </Row>
                    <PreAssessmentCustom
                      identifier={index + 1}
                      trainingItem={obj}
                      assessmentQuestions={null}
                      assessmentQuestionsTwo={
                        index === 0 ? customAssessmentQuestions : null
                      }
                      assessmentQuestionsThree={
                        index === 1 ? customAssessmentQuestions : null
                      }
                      assessmentQuestionsFour={
                        index === 2 ? customAssessmentQuestions : null
                      }
                      setAssessQuestionsSet={setAssessQuestionsSet}
                      setAssessQuestionsSetTwo={
                        index === 0 ? setAssessQuestionsSetTwo : null
                      }
                      setAssessQuestionsSetThree={
                        index === 1 ? setAssessQuestionsSetThree : null
                      }
                      setAssessQuestionsSetFour={
                        index === 2 ? setAssessQuestionsSetFour : null
                      }
                    />
                  </div>
                ) : null}
              </>
            ),
          }
        }
      })

      let objToUpdate = stepsWithAssessment.find(
        (obj) => obj.title === 'Expected Outcomes'
      )
      if (objToUpdate) {
        objToUpdate.content = (
          <>
            {childrenTrains.length ? (
              <>
                <Row justify='center' className='mb-4'>
                  <Col style={{ width: 800 }}>
                    <Row>
                      <label
                        style={{ fontSize: 16 }}
                        className='loginInput-label'
                        htmlFor='expectedOutcome'
                      >
                        What are your expected outcome/s and/or learnings after
                        the training <em>{`(${trainingItem.title})?`} </em>
                        <span className='asterisk'>**</span>
                      </label>
                    </Row>
                    <Row>
                      <TextArea
                        onChange={handleExpectedOutcome}
                        id='expectedOutcome'
                        style={{ fontSize: 16 }}
                        rows={4}
                        maxLength={512}
                      />
                    </Row>
                  </Col>
                </Row>
                {childrenTrains.map((obj, count) => {
                  return (
                    <Row key={obj.id} justify='center' className='mb-4'>
                      <Col style={{ width: 800 }}>
                        <Row>
                          <label
                            style={{ fontSize: 16 }}
                            className='loginInput-label'
                            htmlFor={`expectedOutcome${
                              count == 0 ? 'Two' : count == 1 ? 'Three' : 'Four'
                            }`}
                          >
                            What are your expected outcome/s and/or learnings
                            after the training <em>{`(${obj.title})?`} </em>
                            <span className='asterisk'>**</span>
                          </label>
                        </Row>
                        <Row>
                          <TextArea
                            onChange={
                              count == 0
                                ? handleExpectedOutcomeTwo
                                : count == 1
                                  ? handleExpectedOutcomeThree
                                  : handleExpectedOutcomeFour
                            }
                            id={`expectedOutcome${
                              count == 0 ? 'Two' : count == 1 ? 'Three' : 'Four'
                            }`}
                            style={{ fontSize: 16 }}
                            rows={4}
                            maxLength={512}
                          />
                        </Row>
                      </Col>
                    </Row>
                  )
                })}
              </>
            ) : (
              <Row justify='center'>
                <Col style={{ width: 800 }}>
                  <Row>
                    <label
                      style={{ fontSize: 16 }}
                      className='loginInput-label'
                      htmlFor='expectedOutcome'
                    >
                      What are your expected outcome/s and/or learnings after
                      the training? <span className='asterisk'>**</span>
                    </label>
                  </Row>
                  <Row>
                    <TextArea
                      onChange={handleExpectedOutcome}
                      id='expectedOutcome'
                      style={{ fontSize: 16 }}
                      rows={4}
                      maxLength={512}
                    />
                  </Row>
                </Col>
              </Row>
            )}
          </>
        )
      }

      const index = stepsWithAssessment.findIndex(
        (obj) => obj.title === 'Expected Outcomes'
      )

      let newStepsWithAssessments = stepsWithAssessment
      if (index !== -1) {
        newStepsWithAssessments.splice(index, 0, ...newObject)
        setCustomRegistration([...newStepsWithAssessments])
      }
    }
  }, [childrenTrains, current])

  /**
   * Array of steps without assessment.
   * Each step contains a title and content.
   * The content is a JSX element representing the step's content.
   * @type {Array<{title: string, content: JSX.Element}>}
   */
  const stepsWithoutAssessment = [
    {
      title: 'Data Privacy Consent Form',
      content: (
        <>
          <Row justify='center' style={{ fontSize: 16 }}>
            <Col>
              <Row style={{ width: 800 }}>
                <span>
                  <strong>
                    I hereby authorize and give the Strategic Human Resource
                    Unit a consent that all personal information indicated
                    herein will be kept confidential for training purposes only.
                    I further understand that this form is being protected by
                    the Republic Act 10173 or the "Data Privacy Act".
                  </strong>
                </span>
              </Row>
              <Row
                style={{ marginTop: 24 }}
                justify='space-evenly'
                onChange={handleRadio}
              >
                <Col span={12}>
                  <input
                    style={{ marginRight: 8 }}
                    defaultChecked={isAgree == 'no'}
                    value='no'
                    name='dpcf'
                    id='dpcfNo'
                    type='radio'
                  />
                  <label htmlFor='dpcfNo'>Disagree</label>
                </Col>
                <Col span={12}>
                  <input
                    style={{ marginRight: 8 }}
                    defaultChecked={isAgree == 'yes'}
                    value='yes'
                    name='dpcf'
                    id='dpcfYes'
                    type='radio'
                  />
                  <label htmlFor='dpcfYes'>Agree</label>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.02</b>
            </span>
          </Row>
        </>
      ),
    },
    {
      title: 'Demographic Profile of Participants',
      content: (
        <>
          <Row className={'mb-3'} justify='center'>
            <Col span={24}>
              <span style={{ fontSize: '20px' }}>
                <b>
                  Before proceeding to the next part, please review your details
                  in the Demographic Profile of Participants Form.
                </b>
              </span>
            </Col>
          </Row>
          <Row justify='center'>
            <Col>
              <Row justify='center'>
                <Col style={{ width: 830 }}>
                  <div className='d-flex justify-content-between gap-2'>
                    <Row
                      className='w-100'
                      style={{
                        marginBottom: 12,
                        fontSize: 16,
                        height: '50px',
                      }}
                    >
                      <label
                        style={{ fontSize: 16 }}
                        className='loginInput-label'
                        htmlFor='firstName'
                      >
                        First name <em className='asterisk'>**</em>
                      </label>
                      <Input
                        disabled
                        defaultValue={first_name}
                        className={'text-center w-100'}
                        type='text'
                        id='firstName'
                      />
                    </Row>
                    <Row
                      className='w-50'
                      style={{ marginBottom: 12, fontSize: 16 }}
                    >
                      <label
                        style={{ fontSize: 16 }}
                        className='loginInput-label'
                        htmlFor='middleInitial'
                      >
                        Middle initial
                      </label>
                      <Input
                        defaultValue={middle_initial}
                        onChange={handleMiddleInitial}
                        className={`text-center ${
                          validationError.middleInitial && 'border-danger'
                        }`}
                        type='text'
                        id='middleInitial'
                        maxLength={3}
                      />
                      {validationError.middleInitial && (
                        <span className='text-danger'>
                          {validationError.middleInitial}
                        </span>
                      )}
                    </Row>
                    <Row
                      className='w-100'
                      style={{ marginBottom: 12, fontSize: 16, height: '50px' }}
                    >
                      <label
                        style={{ fontSize: 16 }}
                        className='loginInput-label'
                        htmlFor='lastName'
                      >
                        Last name <em className='asterisk'>**</em>
                      </label>
                      <Input
                        disabled
                        defaultValue={last_name}
                        className={'text-center w-100'}
                        type='text'
                        id='lastName'
                      />
                    </Row>
                  </div>

                  <Row>
                    <label
                      style={{ fontSize: 16 }}
                      className='loginInput-label'
                      htmlFor='nickname'
                    >
                      Nickname <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row style={{ marginBottom: 12, fontSize: 16 }}>
                    <Input
                      defaultValue={nickname}
                      onChange={handleNickname}
                      placeholder={'enter your nickname here'}
                      className={`text-center ${
                        validationError.nickname && 'border-danger'
                      }`}
                      type='text'
                      id='nickname'
                      maxLength={50}
                    />
                    {validationError.nickname && (
                      <span className='text-danger'>
                        {validationError.nickname}
                      </span>
                    )}
                  </Row>
                  <Row>
                    <label
                      className='loginInput-label'
                      style={{ fontSize: 16 }}
                      htmlFor='employmentType'
                    >
                      Type of Employment <em className='asterisk'>**</em>
                    </label>
                  </Row>
                  <Row>
                    <Select
                      placeholder={'select your employment type'}
                      onChange={handleEmploymentType}
                      style={{
                        width: '50%',
                      }}
                      options={[
                        {
                          value: 'permanentOrRegular',
                          label: 'Permanent/Regular',
                        },
                        {
                          value: 'cosOrJoOrConsultant',
                          label: 'COS/JO/Consultant',
                        },
                      ]}
                      id='employmentType'
                    />
                  </Row>
                  <Row gutter={6}>
                    <Col style={{ fontSize: 16 }} span={12}>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='emailAddress'
                        >
                          EMAIL ADDRESS <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={email_address}
                          onChange={handleEmailAddress}
                          style={{ fontSize: 16 }}
                          type='email'
                          id='emailAddress'
                        />
                      </Row>
                      <Row>
                        <span
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                        >
                          SEX <em className='asterisk'>**</em>
                        </span>
                      </Row>

                      <Row
                        onChange={handleSexRadio}
                        align='middle'
                        style={{ height: 35, marginBottom: 12 }}
                        justify='space-between'
                      >
                        <Col>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'male'}
                              value='male'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexMale'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexMale'
                            >
                              Male
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'female'}
                              value='female'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexFemale'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexFemale'
                            >
                              Female
                            </label>
                          </Row>
                        </Col>
                        <Col>
                          <Row>
                            <input
                              disabled
                              defaultChecked={user_sex == 'notSpecified'}
                              value='notSpecified'
                              style={{ fontSize: 16 }}
                              name='sex'
                              type='radio'
                              id='sexNotMentioned'
                            />
                            <label
                              style={{ fontSize: 16 }}
                              className='loginInput-label'
                              htmlFor='sexNotMentioned'
                            >
                              Prefer not to mention
                            </label>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='contactNumber'
                        >
                          CONTACT NUMBER <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={contact_number}
                          onChange={handleContactNumber}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='contactNumber'
                        />
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='department'
                        >
                          DEPARTMENT/ OFFICE/ UNIT/ TASK FORCE{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={user_dept}
                          onChange={handleDepartment}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='department'
                        />
                      </Row>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='divisionSection'
                        >
                          DIVISION / SECTION{' '}
                          <small>(If not applicable, type N/A)</small>{' '}
                          <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={div_section}
                          onChange={handleDivisionSection}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='divisionSection'
                        />
                      </Row>
                      <Row>
                        <label
                          style={{ fontSize: 16 }}
                          className='loginInput-label'
                          htmlFor='designation'
                        >
                          DESIGNATION/POSITION <em className='asterisk'>**</em>
                        </label>
                      </Row>
                      <Row style={{ marginBottom: 12 }}>
                        <Input
                          disabled
                          defaultValue={user_designation}
                          onChange={handleDesignation}
                          style={{ fontSize: 16 }}
                          type='text'
                          id='designation'
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <em className='requiredFields'>** All fields are required</em>
          </Row>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.04</b>
            </span>
          </Row>
        </>
      ),
    },
    {
      title: 'Endorsement Letter',
      content: (
        <Row justify='center' style={{ fontSize: 16 }}>
          <Col style={{ width: 800 }}>
            <Row justify='center'>
              <Col>
                {trainingItem?.stepThreeID ? (
                  <>
                    <p
                      className='text-center'
                      style={{ whiteSpace: 'pre-wrap' }}
                    >
                      <HtmlParser
                        content={trainingItem?.stepThreeText?.textDisplay?.replace(
                          /\n/g,
                          '<br>'
                        )}
                      />
                    </p>
                    <p className={'text-center'}>
                      You may edit your response to upload your endorsement
                      letter or send a hardcopy to the HRMD.
                    </p>
                  </>
                ) : (
                  <>
                    <p className={'text-center'}>
                      Kindly submit the formal letter duly signed by your
                      Department/Office Head allowing your attendance in this
                      training on official time.{' '}
                    </p>
                    <p className={'text-center'}>
                      You may edit your response to upload your endorsement
                      letter or send a hardcopy to the HRMD.
                    </p>
                  </>
                )}
              </Col>
            </Row>
            <Row>
              <label
                htmlFor='endorsementLetter'
                className='loginInput-label'
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Upload Here{' '}
              </label>
              <Input
                multiple
                id='endorsementLetter'
                name='endorsementLetter'
                type='file'
                accept='image/jpeg,image/gif,image/png,application/pdf'
                onChange={(e) => {
                  setLetterUpload(Array.from(e.target.files))
                }}
              />
            </Row>
          </Col>
        </Row>
      ),
    },
    {
      title: 'Expected Outcomes',
      content: (
        <>
          <Row justify='center'>
            <Col style={{ width: 800 }}>
              <Row>
                <label
                  style={{ fontSize: 16 }}
                  className='loginInput-label'
                  htmlFor='expectedOutcome'
                >
                  What are your expected outcome/s and/or learnings after the
                  training? <span className='asterisk'>**</span>
                </label>
              </Row>
              <Row>
                <TextArea
                  onChange={handleExpectedOutcome}
                  id='expectedOutcome'
                  style={{ fontSize: 16 }}
                  rows={4}
                  maxLength={512}
                />
              </Row>
            </Col>
          </Row>
          <Row justify='end'>
            <span className='m-2'>
              <b>QCG.HRMD.HRDASD.F.06</b>
            </span>
          </Row>
        </>
      ),
    },
  ]

  /**
   *Shows the registration modal if the user is logged in,
   *otherwise displays a notification that they must log in to register.
   **/
  const showModal = () => {
    if (userData) {
      setIsModalOpen(true)
    } else {
      notification['info']({
        message: 'Info',
        description: 'You must login to register.',
      })
    }
  }

  /**
   * Handles the cancellation of the online training registration modal.
   */
  const handleCancel = () => {
    setIsModalOpen(false)
    setIsAgree('no')
    setFull_name(null)
    setFirst_name(null)
    setMiddle_initial(null)
    setLast_name(null)
    setEmail_address(null)
    setUser_sex(null)
    setContact_number(null)
    setUser_dept(null)
    setDiv_section(null)
    setUser_designation(null)
    setLetterUpload(null)
    setExpected_outcome('')
    setCurrent(0)
    setNickname('')
    setEmploymentType('')
  }
  const next = () => {
    setCurrent(current + 1)
  }
  const prev = () => {
    setCurrent(current - 1)
  }

  const checkIfTodayIncluded = () => {
    try {
      if (trainingItem?.arrayOfDates) {
        const arrayOfDates = JSON.parse(trainingItem.arrayOfDates)
        const today = moment().format('YYYY-MM-DD')

        // Check if today's date is included in arrayOfDates
        const isTodayIncluded = arrayOfDates.some((date) => {
          return moment(date).isSame(today, 'day')
        })

        setIsPastToday(isTodayIncluded)
      } else {
        const today = moment().format('YYYY-MM-DD')
        const isTodayIncluded = moment(trainingItem.date).isSame(today, 'day')

        setIsPastToday(isTodayIncluded)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    checkIfTodayIncluded()
  }, [])

  /**
   * Handles the action of going to the forms.
   * @param {Event} e - The event object.
   * @returns {void}
   */
  const handleGoToForms = (e) => {
    e.preventDefault()
    if (currentUser) {
      gotoForms()
    } else {
      notification['info']({
        message: 'Info',
        description: 'You must sign-in to register.',
      })
    }
  }

  /**
   * Handles the event when the user clicks on the "Go to Login" button.
   * Navigates to the login page.
   *
   * @param {Event} e - The click event object.
   * @returns {void}
   */
  const handleGoToLogin = (e) => {
    e.preventDefault()
    navigate('/login')
  }

  return (
    <>
      {isFinishedChecking ? (
        <>
          {hasNewPeriodForms || currentUser?.role === 'admin' ? (
            <>
              {limitReached === false ? (
                <>
                  {disableRegister === false ? (
                    <>
                      {trainingItem.isOpenRegistration === true ? (
                        <>
                          {
                            // if training day is past today, it will show close otherwise it shows the register button
                            isPastToday ? (
                              <>
                                {!trainingItem.parentTrainingID ? (
                                  <button
                                    className='m-container'
                                    onClick={() => showModal()}
                                  >
                                    Register Here
                                  </button>
                                ) : (
                                  <Row className='mt-2'>
                                    <div
                                      className='alert alert-info ms-2'
                                      role='alert'
                                    >
                                      Register to {`${parentTrainingName}`}
                                    </div>
                                  </Row>
                                )}
                              </>
                            ) : (
                              <Row justify='start' className={'mt-3'}>
                                <span className={'p-2'}>
                                  <i>
                                    <b>CLOSED</b>
                                  </i>
                                </span>
                              </Row>
                            )
                          }
                        </>
                      ) : (
                        <Row justify='start' className={'mt-3'}>
                          <span className={'p-2'}>
                            <i>
                              <b>CLOSED</b>
                            </i>
                          </span>
                        </Row>
                      )}

                      <Modal
                        maskClosable={false}
                        footer={null}
                        width={'100vw'}
                        title='REGISTRATION FORM'
                        visible={isModalOpen}
                        onCancel={handleCancel}
                        destroyOnClose={true}
                      >
                        {trainingItem.hasAssessment == 'true' ? (
                          <>
                            {customRegistration.length ? (
                              <>
                                <Steps current={current}>
                                  {customRegistration.map((item) => (
                                    <Step key={item.title} title={item.title} />
                                  ))}
                                </Steps>
                                <div className='steps-content'>
                                  {customRegistration[current].content}
                                </div>
                                <div
                                  className={
                                    'steps-action d-flex justify-content-end'
                                  }
                                >
                                  {current > 0 && (
                                    <Button
                                      style={{
                                        margin: '0 8px',
                                      }}
                                      onClick={() => prev()}
                                    >
                                      Previous
                                    </Button>
                                  )}
                                  {current ===
                                    customRegistration.length - 1 && (
                                    <Button
                                      disabled={isSubmitted}
                                      type='primary'
                                      onClick={() =>
                                        checkIfLimitReached('without')
                                      }
                                    >
                                      Done
                                    </Button>
                                  )}
                                  {current < customRegistration.length - 1 && (
                                    <Button
                                      disabled={handleNextButtonWithAssessment()}
                                      type='primary'
                                      onClick={() => next()}
                                    >
                                      Next
                                    </Button>
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                <Steps current={current}>
                                  {stepsWithAssessment.map((item) => (
                                    <Step key={item.title} title={item.title} />
                                  ))}
                                </Steps>
                                <div className='steps-content'>
                                  {stepsWithAssessment[current].content}
                                </div>
                                <div
                                  className={
                                    'steps-action d-flex justify-content-end'
                                  }
                                >
                                  {current > 0 && (
                                    <Button
                                      style={{
                                        margin: '0 8px',
                                      }}
                                      onClick={() => prev()}
                                    >
                                      Previous
                                    </Button>
                                  )}
                                  {current ===
                                    stepsWithAssessment.length - 1 && (
                                    <Button
                                      disabled={isSubmitted}
                                      type='primary'
                                      onClick={() =>
                                        checkIfLimitReached('without')
                                      }
                                    >
                                      Done
                                    </Button>
                                  )}
                                  {current < stepsWithAssessment.length - 1 && (
                                    <Button
                                      disabled={handleNextButtonWithAssessment()}
                                      type='primary'
                                      onClick={() => next()}
                                    >
                                      Next
                                    </Button>
                                  )}
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <Steps current={current}>
                              {stepsWithoutAssessment.map((item) => (
                                <Step key={item.title} title={item.title} />
                              ))}
                            </Steps>
                            <div className='steps-content'>
                              {stepsWithoutAssessment[current].content}
                            </div>
                            <div
                              className={
                                'steps-action d-flex justify-content-end'
                              }
                            >
                              {current > 0 && (
                                <Button
                                  style={{
                                    margin: '0 8px',
                                  }}
                                  onClick={() => prev()}
                                >
                                  Previous
                                </Button>
                              )}
                              {current ===
                                stepsWithoutAssessment.length - 1 && (
                                <Button
                                  disabled={isSubmitted}
                                  type='primary'
                                  onClick={() => checkIfLimitReached('without')}
                                >
                                  Done
                                </Button>
                              )}
                              {current < stepsWithoutAssessment.length - 1 && (
                                <Button
                                  disabled={handleNextButton()}
                                  type='primary'
                                  onClick={() => next()}
                                >
                                  Next
                                </Button>
                              )}
                            </div>
                          </>
                        )}
                      </Modal>
                    </>
                  ) : (
                    <span className={'text-monts mt-3'}>
                      <b>
                        {submittedEndorseLetter ? (
                          <em>You are already registered.</em>
                        ) : (
                          <em>
                            You are already registered. Please check your
                            Calendar found under the "Learning and Development
                            Tab" to be able to submit your duly signed
                            Endorsement letter.
                          </em>
                        )}
                      </b>
                      <OnlineTrainingProgramsView
                        training={trainingItem}
                        afterRegister={afterRegister}
                        userData={userData}
                      />
                    </span>
                  )}
                </>
              ) : (
                <span className={'text-monts mt-3'}>
                  <b>
                    <em>{reachLimitText}</em>
                  </b>
                </span>
              )}
            </>
          ) : (
            <>
              <Row>
                <span className={'text-monts mt-3'}>
                  <b>
                    <em>
                      Please accomplish the e-Training Needs Analysis and
                      Individual Learning Development Plan forms for this
                      current period (2023) to be able to register to a
                      Training.
                    </em>
                  </b>
                </span>
              </Row>
              {currentUser ? (
                <Row>
                  <a
                    onClick={handleGoToForms}
                    className={'text-monts mt-3'}
                    href='#'
                  >
                    Answer e-TNA or ILDP here
                  </a>
                </Row>
              ) : (
                <Row>
                  <a
                    onClick={handleGoToLogin}
                    className={'text-monts mt-3'}
                    href='#'
                  >
                    Sign in to register
                  </a>
                </Row>
              )}
            </>
          )}
        </>
      ) : (
        <Skeleton active />
      )}

      {/* Show total correct answer in pre assessment */}
      <PreAssessmentCorrectScore
        isECert={true}
        preAssessmentCorrectScore={preAssessmentCorrectScore}
        setPreAssessmentCorrectScore={setPreAssessmentCorrectScore}
        isPreAssessmentScoreOpen={isPreAssessmentScoreOpen}
        setIsPreAssessmentScoreOpen={setIsPreAssessmentScoreOpen}
      />
    </>
  )
}
export default OnlineTrainingRegistrationModal

// props validation
OnlineTrainingRegistrationModal.propTypes = {
  trainingItem: PropTypes.object,
  userData: PropTypes.object,
  afterRegister: PropTypes.func,
  gotoForms: PropTypes.func,
  setRefresh: PropTypes.func,
  setAfterRegister: PropTypes.func,
  refresh: PropTypes.bool,
}
