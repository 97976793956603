/**
 * Title: CSCOnlineVideos.js
 * Description: This is a file that contains the CSC online videos
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/CSCOnlineVideos.js
 **/

import { useState, useEffect, useRef } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import { listTrainingVideos } from '../../api/queries'
import { deleteTrainingVideo } from '../../api/mutations'

import moment from 'moment'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  PlayCircleFilled,
} from '@ant-design/icons'
import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  Row,
  Col,
} from 'antd'

import VideoPlayer from './videoPlayer'

import './strategicPerformanceManagementSystem.css'
import listOfDepartments from '../../utilities/dept'

import CSCOnlineMaterials from './CSCOnlineMaterials'
import CSCOnlineMaterialsA from './CSCOnlineMaterialsA'
import CSCOnlineMaterialsPartThree from './CSCOnlineMaterialsPartThree'
import CSCOnlineMaterialsPartFour from './CSCOnlineMaterialsPartFour'
import CSCOnlineMaterialsPartTwo from './CSCOnlineMaterialsPartTwo'
import CSCOnlineMaterialsPartTwoA from './CSCOnlineMaterialsPartTwoA'

const { Option } = Select

export default function CSCOnlineVideos({ userData }) {
  const userInfo = userData
  const userFullName =
    userInfo?.name + ' ' + userInfo?.mname + ' ' + userInfo?.lname

  const [name, setName] = useState(userFullName)
  const [department, setDepartment] = useState(userInfo?.assignedDept)
  const [uploadedTrainingVideo, setUploadedTrainingVideo] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [videoKey, setVideoKey] = useState('')
  const [isAgree, setIsAgree] = useState(true)
  const [pageState, setPageState] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [
    isAdditionalMaterialModalVisible,
    setIsAdditionalMaterialModalVisible,
  ] = useState(false)

  const filipinoVideos = ['Talasalitaan', 'Mga kawikaang Pilipino', 'Pagtukoy']
  const englishVideos = [
    'Idiomatic Expressions Part 1',
    'Idiomatic Expression Part 2',
    'Spelling Part 1',
    'Spelling Part 2',
    'Reading Comprehension',
    'Vocabulary Part 1',
    'Vocabulary Part 2',
    'English Grammar and Identifying Errors Part 1',
    'English Grammar and Identifying Errors Part 2',
    'English Grammar and Identifying Errors Part 3',
  ]
  const numericalReasoning = [
    'Numerical Reasoning (Part 1)',
    'Numerical Reasoning (Part 2)',
    'Numerical Reasoning (Part 3), Numerical Reasoning (Part 4)',
  ]
  const generalInfo = [
    'Current Events Part 1',
    'Current Events Part 2',
    'Philippine Constitution Part 1',
    'Philippine Constitution Part 2',
    'R.A 6713 Part 1',
    'R.A 6713 Part 2',
  ]
  const clearicalOperations = [
    'Alphabetizing Part 1',
    'Alphabetizing Part 2',
    'Finding Errors',
  ]
  const analogyAndLogicalReasoning = [
    'Lesson 1 - Deductive Reasoning',
    'Lesson 2 - Inductive Reasoning',
    'Lesson 3 - Diagrammatic Reasoning',
    'Lesson 4 - Critical Thinking _ Abstract Reasoning Test',
    'Lesson 5 - Anatomy of Logical Reasoning Question _ Tips',
    'Logical Reasoning (Patterns _ Diagrams Part 1)',
    'Logical Reasoning (Patterns _ Diagrams Part 2)',
    'Logical Reasoning (Patterns _ Diagrams Part 3)',
  ]

  const dept = listOfDepartments()

  const fetchUploadedTrainingVideo = async () => {
    try {
      const uploadedTrainingVid = await API.graphql(
        graphqlOperation(listTrainingVideos)
      )
      const uploadedTrainingVidList =
        uploadedTrainingVid.data.listTrainingVideos.items
      setUploadedTrainingVideo(
        uploadedTrainingVidList.sort((a, b) => {
          return a.key.localeCompare(b.key)
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchUploadedTrainingVideo()
  }, [])

  const handlePlayButton = (key) => {
    setIsModalVisible(true)
    setVideoKey(key)
  }

  const handleSelect = (value) => {
    setDepartment(value)
  }

  const dateDisplay = `${
    new Date().getMonth() + 1
  }-${new Date().getDate()}-${new Date().getFullYear()}`

  const handleButtonClick = () => {
    window.open(
      'https://docs.google.com/forms/d/e/1FAIpQLSdee0qZgz6rnrMW41J-LSV5_iH7CyRLau4MBrhIKHN50JWMMA/viewform',
      '_blank',
      'noopener'
    )
  }

  /**
   *@function handlePreTest
   *@description if triggered, will redirect to appropriate training google form.
   *@returns {Promise<void>} A promise that resolves when the check is complete.
   */
  const handlePreTest = () => {
    try {
      let formUrl = ''

      if (pageState === 'Filipino' || pageState === 'English') {
        formUrl =
          'https://docs.google.com/forms/d/e/1FAIpQLSeWuYO_vzjRc5pDTBAIDiPFaX-QrFkIQ9eYZN1yi9gUh7Ox8w/viewform'
      } else if (
        pageState === 'Numerical Reasoning' ||
        pageState === 'General Information'
      ) {
        formUrl =
          'https://docs.google.com/forms/d/e/1FAIpQLSem0NcPieuU0JAqHdTDH9T-q9msdksGW9_qqN4Vx91lFBKRdw/viewform'
      } else if (
        pageState === 'Clerical Operations' ||
        pageState === 'Analogy and Logical Reasoning'
      ) {
        formUrl =
          'https://docs.google.com/forms/d/e/1FAIpQLSd0-mQuFtWGYleGNtPlWfIRFZJSL1dlA-nXezSTE2lhT30RSA/viewform'
      }

      window.open(formUrl, '_blank', 'noopener,noreferrer')
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='NOTICE!!!'
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        width={800}
        footer={[
          <Button key='back' onClick={() => setOpenModal(false)}>
            Close
          </Button>,
        ]}
      >
        <div className='text-center'>
          <h6>Please answer the PRE-TEST before you proceed watching!</h6>
          <Button
            className='mt-2'
            type='primary'
            onClick={handlePreTest}
            target='_blank'
            rel='noopener noreferrer'
          >
            Take Pre-Test
          </Button>
        </div>
      </Modal>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={`Additional materials for ${pageState}`}
        width={1300}
        open={isAdditionalMaterialModalVisible}
        onCancel={() => setIsAdditionalMaterialModalVisible(false)}
        footer={[
          <Button onClick={() => setIsAdditionalMaterialModalVisible(false)}>
            Close
          </Button>,
        ]}
      >
        {pageState === 'Filipino' && <CSCOnlineMaterials userData={userData} />}
        {pageState === 'English' && <CSCOnlineMaterialsA userData={userData} />}
        {pageState === 'Numerical Reasoning' && (
          <CSCOnlineMaterialsPartThree userData={userData} />
        )}
        {pageState === 'General Information' && (
          <CSCOnlineMaterialsPartFour userData={userData} />
        )}
        {pageState === 'Clerical Operations' && (
          <CSCOnlineMaterialsPartTwo userData={userData} />
        )}
        {pageState === 'Analogy and Logical Reasoning' && (
          <CSCOnlineMaterialsPartTwoA />
        )}
      </Modal>
      <div className='py-5'>
        <h1>CSC Online Trainings</h1>

        {!pageState && (
          <div className='container mt-4'>
            <div className='card border-0 shadow py-4'>
              <div className='card-body d-flex flex-wrap justify-content-center gap-3 py-0'>
                <button
                  className='m-container bg-primary rounded-pill'
                  onClick={() => {
                    setPageState('Filipino')
                    setOpenModal(true)
                  }}
                >
                  Filipino
                </button>
                <button
                  className='m-container bg-primary rounded-pill'
                  onClick={() => {
                    setPageState('English')
                    setOpenModal(true)
                  }}
                >
                  English
                </button>
              </div>
              <div className='card-body d-flex flex-wrap justify-content-center gap-3 py-0'>
                <button
                  className='m-container bg-primary rounded-pill'
                  onClick={() => {
                    setPageState('Numerical Reasoning')
                    setOpenModal(true)
                  }}
                >
                  Numerical Reasoning
                </button>
                <button
                  className='m-container bg-primary rounded-pill'
                  onClick={() => {
                    setPageState('General Information')
                    setOpenModal(true)
                  }}
                >
                  General Information and Philippine Constitution
                </button>
              </div>
              <div className='card-body d-flex flex-wrap justify-content-center gap-3 py-0'>
                <button
                  className='m-container bg-primary rounded-pill'
                  onClick={() => {
                    setPageState('Clerical Operations')
                    setOpenModal(true)
                  }}
                >
                  Clerical Operations
                </button>
                <button
                  className='m-container bg-primary rounded-pill'
                  onClick={() => {
                    setPageState('Analogy and Logical Reasoning')
                    setOpenModal(true)
                  }}
                >
                  Analogy and Logical Reasoning
                </button>
              </div>
              <div className='card-body d-flex flex-wrap justify-content-center gap-3 py-0'>
                <button
                  className='m-container bg-primary rounded-pill'
                  onClick={() => {
                    let formUrl =
                      'https://docs.google.com/forms/d/e/1FAIpQLSflKuvZshty_OP5xrlTpyuvdMuEFW36-lkDU0mBFaN3hYGpjg/viewform'
                    window.open(formUrl, '_blank', 'noopener,noreferrer')
                  }}
                >
                  Mock Exam
                </button>
              </div>
            </div>
          </div>
        )}

        {pageState === 'Filipino' && (
          <>
            <Button className='mt-3' onClick={() => setPageState(null)}>
              Go back
            </Button>
            <h1 className='p-5 pb-3 font-Mont d-none d-md-block'>Filipino</h1>
            <h3 className='p-5 pb-3 font-Mont d-block d-md-none'>Filipino</h3>
            <Row className='pt-3'>
              <button className={'m-container mx-auto'} onClick={handlePreTest}>
                <span style={{ fontSize: 14 }} className='text-monts '>
                  PRE-TEST
                </span>
              </button>
            </Row>
            <Row className='pb-3'>
              <button
                className={'m-container mx-auto'}
                onClick={() => setIsAdditionalMaterialModalVisible(true)}
              >
                <span style={{ fontSize: 14 }} className='text-monts '>
                  ADDITIONAL MATERIALS
                </span>
              </button>
            </Row>
            <div className='d-flex flex-wrap gap-5 justify-content-center container'>
              {filipinoVideos.map((title) => {
                return uploadedTrainingVideo
                  .filter((video) =>
                    video.key.toLowerCase().includes(title.toLowerCase())
                  )
                  .map((video) => {
                    const videoName = video.key.slice(0, -4) // remove the last 4 characters (i.e. ".mp4") from the file name
                    return (
                      <div
                        className='card shadow mt-3 col-md-7 col-12'
                        key={video.key}
                      >
                        <div>
                          <img
                            src={`${process.env.PUBLIC_URL}/EnglishFilipino.png`}
                            alt={video.key}
                            className='w-100'
                          />
                          <div
                            className='playbutton btn cursor-pointer mb-5'
                            onClick={() => handlePlayButton(video.key)}
                            style={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '80px',
                              height: '80px',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              top: '40%',
                            }}
                          >
                            <PlayCircleFilled
                              style={{ fontSize: '43px', color: '#fff' }}
                            />
                          </div>
                        </div>
                        <div className='card-body'>
                          <h4 className='card-title'>{videoName}</h4>
                          {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        </div>
                      </div>
                    )
                  })
              })}
            </div>
          </>
        )}

        {pageState === 'English' && (
          <>
            <Button className='mt-3' onClick={() => setPageState(null)}>
              Go back
            </Button>
            <h1 className='p-5 pb-3 font-Mont d-none d-md-block'>English</h1>
            <h3 className='p-5 pb-3 font-Mont d-block d-md-none'>English</h3>
            <Row>
              <button className={'m-container mx-auto'} onClick={handlePreTest}>
                <span style={{ fontSize: 14 }} className='text-monts '>
                  PRE-TEST
                </span>
              </button>
            </Row>
            <Row className='pb-3'>
              <button
                className={'m-container mx-auto'}
                onClick={() => setIsAdditionalMaterialModalVisible(true)}
              >
                <span style={{ fontSize: 14 }} className='text-monts '>
                  ADDITIONAL MATERIALS
                </span>
              </button>
            </Row>
            <div className='d-flex flex-wrap gap-5 justify-content-center container'>
              {englishVideos.map((title) => {
                return uploadedTrainingVideo
                  .filter((video) =>
                    video.key.toLowerCase().includes(title.toLowerCase())
                  )
                  .map((video) => {
                    const videoName = video.key.slice(0, -4) // remove the last 4 characters (i.e. ".mp4") from the file name
                    return (
                      <div
                        className='card shadow mt-3 col-md-7 col-12'
                        key={video.key}
                      >
                        <div>
                          <img
                            src={`${process.env.PUBLIC_URL}/EnglishFilipino.png`}
                            alt={video.key}
                            className='w-100'
                          />
                          <div
                            className='playbutton btn cursor-pointer mb-5'
                            onClick={() => handlePlayButton(video.key)}
                            style={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '80px',
                              height: '80px',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              top: '40%',
                            }}
                          >
                            <PlayCircleFilled
                              style={{ fontSize: '43px', color: '#fff' }}
                            />
                          </div>
                        </div>
                        <div className='card-body'>
                          <h4 className='card-title'>{videoName}</h4>
                          {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        </div>
                      </div>
                    )
                  })
              })}
            </div>
          </>
        )}

        {pageState === 'Numerical Reasoning' && (
          <>
            <Button className='mt-3' onClick={() => setPageState(null)}>
              Go back
            </Button>
            <h1 className='p-5 pb-3 font-Mont d-none d-md-block'>
              Numerical Reasoning
            </h1>
            <h3 className='p-5 pb-3 font-Mont d-block d-md-none'>
              Numerical Reasoning
            </h3>
            <Row>
              <button className={'m-container mx-auto'} onClick={handlePreTest}>
                <span style={{ fontSize: 14 }} className='text-monts '>
                  PRE-TEST
                </span>
              </button>
            </Row>
            <Row className='pb-3'>
              <button
                className={'m-container mx-auto'}
                onClick={() => setIsAdditionalMaterialModalVisible(true)}
              >
                <span style={{ fontSize: 14 }} className='text-monts '>
                  ADDITIONAL MATERIALS
                </span>
              </button>
            </Row>
            <div className='d-flex flex-wrap gap-5 justify-content-center container'>
              {numericalReasoning.map((title) => {
                return uploadedTrainingVideo
                  .filter((video) =>
                    video.key.toLowerCase().includes(title.toLowerCase())
                  )
                  .map((video) => {
                    const videoName = video.key.slice(0, -4) // remove the last 4 characters (i.e. ".mp4") from the file name
                    return (
                      <div
                        className='card shadow mt-3 col-md-7 col-12'
                        key={video.key}
                      >
                        <div>
                          <img
                            src={`${process.env.PUBLIC_URL}/EnglishFilipino.png`}
                            alt={video.key}
                            className='w-100'
                          />
                          <div
                            className='playbutton btn cursor-pointer mb-5'
                            onClick={() => handlePlayButton(video.key)}
                            style={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '80px',
                              height: '80px',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              top: '40%',
                            }}
                          >
                            <PlayCircleFilled
                              style={{ fontSize: '43px', color: '#fff' }}
                            />
                          </div>
                        </div>
                        <div className='card-body'>
                          <h4 className='card-title'>{videoName}</h4>
                          {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        </div>
                      </div>
                    )
                  })
              })}
            </div>
          </>
        )}

        {pageState === 'General Information' && (
          <>
            <Button className='mt-3' onClick={() => setPageState(null)}>
              Go back
            </Button>
            <h1 className='p-5 pb-3 font-Mont d-none d-md-block'>
              General Information and Philippine Constitution
            </h1>
            <h3 className='p-5 pb-3 font-Mont d-block d-md-none'>
              General Information and Philippine Constitution
            </h3>
            <Row>
              <button className={'m-container mx-auto'} onClick={handlePreTest}>
                <span style={{ fontSize: 14 }} className='text-monts '>
                  PRE-TEST
                </span>
              </button>
            </Row>
            <Row className='pb-3'>
              <button
                className={'m-container mx-auto'}
                onClick={() => setIsAdditionalMaterialModalVisible(true)}
              >
                <span style={{ fontSize: 14 }} className='text-monts '>
                  ADDITIONAL MATERIALS
                </span>
              </button>
            </Row>
            <div className='d-flex flex-wrap gap-5 justify-content-center container'>
              {generalInfo.map((title) => {
                return uploadedTrainingVideo
                  .filter((video) =>
                    video.key.toLowerCase().includes(title.toLowerCase())
                  )
                  .map((video) => {
                    const videoName = video.key.slice(0, -4) // remove the last 4 characters (i.e. ".mp4") from the file name
                    return (
                      <div
                        className='card shadow mt-3 col-md-7 col-12'
                        key={video.key}
                      >
                        <div>
                          <img
                            src={`${process.env.PUBLIC_URL}/Csc-Week2.png`}
                            alt={video.key}
                            className='w-100'
                          />
                          <div
                            className='playbutton btn cursor-pointer mb-5'
                            onClick={() => handlePlayButton(video.key)}
                            style={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '80px',
                              height: '80px',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              top: '40%',
                            }}
                          >
                            <PlayCircleFilled
                              style={{ fontSize: '43px', color: '#fff' }}
                            />
                          </div>
                        </div>
                        <div className='card-body'>
                          <h4 className='card-title'>{videoName}</h4>
                          {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        </div>
                      </div>
                    )
                  })
              })}
            </div>
          </>
        )}

        {pageState === 'Clerical Operations' && (
          <>
            <Button className='mt-3' onClick={() => setPageState(null)}>
              Go back
            </Button>
            <h1 className='p-5 pb-3 font-Mont d-none d-md-block'>
              Clerical Operations
            </h1>
            <h3 className='p-5 pb-3 font-Mont d-block d-md-none'>
              Clerical Operations
            </h3>
            <Row>
              <button className={'m-container mx-auto'} onClick={handlePreTest}>
                <span style={{ fontSize: 14 }} className='text-monts '>
                  PRE-TEST
                </span>
              </button>
            </Row>
            <Row className='pb-3'>
              <button
                className={'m-container mx-auto'}
                onClick={() => setIsAdditionalMaterialModalVisible(true)}
              >
                <span style={{ fontSize: 14 }} className='text-monts '>
                  ADDITIONAL MATERIALS
                </span>
              </button>
            </Row>
            <div className='d-flex flex-wrap gap-5 justify-content-center container'>
              {clearicalOperations.map((title) => {
                return uploadedTrainingVideo
                  .filter((video) =>
                    video.key.toLowerCase().includes(title.toLowerCase())
                  )
                  .map((video) => {
                    const videoName = video.key.slice(0, -4) // remove the last 4 characters (i.e. ".mp4") from the file name
                    return (
                      <div
                        className='card shadow mt-3 col-md-7 col-12'
                        key={video.key}
                      >
                        <div>
                          <img
                            src={`${process.env.PUBLIC_URL}/Csc-Week3.png`}
                            alt={video.key}
                            className='w-100'
                          />
                          <div
                            className='playbutton btn cursor-pointer mb-5'
                            onClick={() => handlePlayButton(video.key)}
                            style={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '80px',
                              height: '80px',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              top: '40%',
                            }}
                          >
                            <PlayCircleFilled
                              style={{ fontSize: '43px', color: '#fff' }}
                            />
                          </div>
                        </div>
                        <div className='card-body'>
                          <h4 className='card-title'>{videoName}</h4>
                          {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        </div>
                      </div>
                    )
                  })
              })}
            </div>
          </>
        )}

        {pageState === 'Analogy and Logical Reasoning' && (
          <>
            <Button className='mt-3' onClick={() => setPageState(null)}>
              Go back
            </Button>
            <h1 className='p-5 pb-3 font-Mont d-none d-md-block'>
              Analogy and Logical Reasoning
            </h1>
            <h3 className='p-5 pb-3 font-Mont d-block d-md-none'>
              Analogy and Logical Reasoning
            </h3>
            <Row>
              <button className={'m-container mx-auto'} onClick={handlePreTest}>
                <span style={{ fontSize: 14 }} className='text-monts '>
                  PRE-TEST
                </span>
              </button>
            </Row>
            <Row className='pb-3'>
              <button
                className={'m-container mx-auto'}
                onClick={() => setIsAdditionalMaterialModalVisible(true)}
              >
                <span style={{ fontSize: 14 }} className='text-monts '>
                  ADDITIONAL MATERIALS
                </span>
              </button>
            </Row>
            <div className='d-flex flex-wrap gap-5 justify-content-center container'>
              {analogyAndLogicalReasoning.map((title) => {
                return uploadedTrainingVideo
                  .filter((video) =>
                    video.key.toLowerCase().includes(title.toLowerCase())
                  )
                  .map((video) => {
                    const videoName = video.key.slice(0, -4) // remove the last 4 characters (i.e. ".mp4") from the file name
                    return (
                      <div
                        className='card shadow mt-3 col-md-7 col-12'
                        key={video.key}
                      >
                        <div>
                          <img
                            src={`${process.env.PUBLIC_URL}/Csc-Week3.png`}
                            alt={video.key}
                            className='w-100'
                          />
                          <div
                            className='playbutton btn cursor-pointer mb-5'
                            onClick={() => handlePlayButton(video.key)}
                            style={{
                              position: 'absolute',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              width: '80px',
                              height: '80px',
                              backgroundColor: 'rgba(0, 0, 0, 0.5)',
                              borderRadius: '50%',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              top: '40%',
                            }}
                          >
                            <PlayCircleFilled
                              style={{ fontSize: '43px', color: '#fff' }}
                            />
                          </div>
                        </div>
                        <div className='card-body'>
                          <h4 className='card-title'>{videoName}</h4>
                          {/* <p className="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p> */}
                        </div>
                      </div>
                    )
                  })
              })}
            </div>
          </>
        )}
        <Modal
          title='Acknowledgment and Receipt'
          width={1000}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button key='back' onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>,
            <VideoPlayer
              videoKey={videoKey}
              CSCOnlineVids={true}
              name={name}
              department={department}
              setIsModalVisible={setIsModalVisible}
              isAgree={isAgree}
            />,
          ]}
        >
          <div>
            <p>STATEMENT OF CLAIMING VIEW</p>
            <p className='text-justify'>
              I hereby declare and affirm my commitment to the terms and
              conditions set forth in this statement regarding the review video
              material provided to me in this platform (www.qc-hrmd.org), and
              its entirety, by the Human Resource Management Department (HRMD)
              of the Quezon City Government. I acknowledge that the review video
              material is the exclusive property of HRMD, and as such, I fully
              understand and agree to abide by the following terms:
            </p>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>1.</p>
              <p>
                {' '}
                <strong>Ownership:</strong> I recognize that the training video
                material is protected by intellectual property laws and is the
                sole and exclusive property of HRMD. I acknowledge that I have
                no rights or ownership claims over the content contained within
                the training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>2.</p>
              <p>
                {' '}
                <strong>Non-Duplication:</strong> I solemnly affirm that I will
                not duplicate or reproduce, in whole or in part, the training
                video material provided to me by HRMD. I understand that
                duplication includes but is not limited to copying, recording,
                downloading, or distributing the material through any means or
                in any format without explicit written consent from HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>3.</p>
              <p>
                {' '}
                <strong>Confidentiality:</strong> I acknowledge and respect that
                the training video material may contain confidential and
                proprietary information belonging to HRMD. I commit to
                maintaining the strictest confidentiality regarding the content,
                concepts, techniques, and methodologies presented in the
                training video material.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>4.</p>
              <p>
                {' '}
                <strong>Personal Use:</strong> I understand that the training
                video material is solely intended for my personal use, learning,
                and development. I agree not to share, lend, or otherwise make
                the training video material available to any third party,
                whether for commercial or non-commercial purposes, without the
                express written consent of HRMD.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>5.</p>
              <p>
                {' '}
                <strong>Legal Consequences:</strong> I acknowledge that any
                unauthorized duplication or reproduction of the training video
                material may result in legal consequences, including but not
                limited to civil lawsuits and monetary damages. I accept full
                responsibility for any such consequences resulting from my
                violation of this agreement.
              </p>
            </div>
            <div className='d-flex'>
              <p className='ms-2 ms-md-5 me-1'>6.</p>
              <p>
                {' '}
                <strong>Compliance:</strong> I commit to cooperating with HRMD
                and its representatives in any investigation of suspected
                violation of this agreement. I understand that HRMD reserves the
                right to take appropriate legal action to enforce these terms
                and protect its rights.
              </p>
            </div>
            <p>
              By inputting my full name and department/office in this statement,
              I acknowledge that I have read and understood the terms outlined
              above and that I will comply with them strictly. I further
              acknowledge that any breach of this agreement may result in severe
              legal consequences.
            </p>
            <div className='d-flex'>
              <label className='d-flex items-align-center gap-2'>
                <input
                  type='checkbox'
                  checked={!isAgree}
                  onChange={(e) => setIsAgree(!isAgree)}
                />
                I agree
              </label>
            </div>
          </div>

          <div className='d-flex flex-wrap gap-5 gap-md-0 mt-4'>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-2 order-md-2'>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder='Enter your name'
                className='text-center'
              />
              <span className='text-center'>Employee's Name & Signature</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-1 order-md-2'>
              <span>{dateDisplay}</span>
              <span>Date</span>
            </div>
            <div className='col-12 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center order-3 order-md-3'>
              <Select
                value={department}
                onChange={handleSelect}
                className='w-100 text-center'
              >
                <Option value='disabled' disabled>
                  Disabled
                </Option>
                {dept.sort().map((item) => (
                  <Option key={item} value={item}>
                    {item}
                  </Option>
                ))}
              </Select>
              <span className='text-center'>Department</span>
            </div>
          </div>
        </Modal>
      </div>
    </>
  )
}
