/**
 * Title: editOnlineTraining.js
 * Description: This react component is used for editing online training announcements.
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * - Raymart Mojado [marty.mojado@gmail.com] [@Github: @RaymartMojado]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/editOnlineTraining.js
 * History:
 * - 08/06/2024 - John Bazil Valdez
 * - Add all fields for editing online training announcements.
 * - 09/11/2024 - Raymart Mojado
 * - fix the resource speaker not showing on edit online training
 * - covert the uploaded resourceSpeaker image from blob to base64
 **/

import { useState, useEffect } from 'react'

import DatePicker from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'

import {
  Button,
  Modal,
  Col,
  Row,
  notification,
  DatePicker as AntdDatePicker,
  Select,
  TimePicker,
  Input,
} from 'antd'

import { API, graphqlOperation, Auth } from 'aws-amplify'
import {
  updateOnline,
  updateOnlineTrainingAnnouncement,
} from '../../api/mutations'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

import moment from 'moment'
import AssessmentMaker from './assessmentMaker'
import SelectAssessmentQuestion from './selectAssessmentQuestion'
import EvaluationMaker from './evaluationMaker'
import SelectEvaluationQuestion from './selectEvaluationQuestion'
import ManageTrainingsNumberOfParticipants from './manageTrainingsNumberOfParticipants'
import { listTrainingAnnouncements } from '../../api/queries'
import ReactQuill from 'react-quill'
import listOfDepartments from '../../utilities/dept'
import SelectActivityQuestions from './SelectActivityQuestions'
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons'
import UploadOnlineeTrainingsPreview from './uploadOnlnieTrainingsPreview'
import ActivityQuestionMaker from './ActivityQuestionMaker.js'
import EndorsementLetter from './endorsementLetter.js'

const { Option } = Select
const { TextArea } = Input

export default function EditOnlineTraining({
  refresh,
  viewCourse,
  setOpenView,
  uploadedTrainingVideo,
  otherFiles,
  userData,
}) {
  const [openViewEditModal, setOpenViewViewEditModal] = useState(false)

  const [announceUpload, setAnnounceUpload] = useState([])
  const [announceDate, setAnnounceDate] = useState(null)
  const [annouceArrayOfDate, setAnnouceArrayOfDate] = useState(null)
  const [announceStartTime, setAnnouceStartTime] = useState(null)
  const [announceEndTime, setAnnouceEndTime] = useState(null)
  const [tpData, setTpData] = useState(null)
  const [cdData, setCdData] = useState(null)
  const [courseTitle, setCourseTitle] = useState(null)
  const [courseCategory, setCourseCategory] = useState([])
  const [optionSubCategory, setOptionSubCategory] = useState(null)
  const [courseSubCategory, setCourseSubCategory] = useState(null)
  const [showPostAssessment, setShowPostAssessment] = useState()
  const [participantLimit, setParticipantLimit] = useState(0)
  const [pTDateOpen, setPTDateOpen] = useState(null)
  const [pTTimeOpen, setPTTimeOpen] = useState(null)
  const [pTDateClose, setPTDateClose] = useState(null)
  const [pTTimeClose, setPTTimeClose] = useState(null)
  const [evalID, setEvalID] = useState(null)
  const [assessmentID, setAssessmentID] = useState(null)
  const [manageParticipantsModal, setManageParticipantsModal] = useState(false)
  const [departments, setDepartments] = useState([])
  const [listOfDepartmentsOrignal, setListOfDepartmentsOriginal] = useState([])
  const [departmentsLimitValue, setDepartmentsLimitValue] = useState({})
  const [textDisplayBeforeTrainingOpen, setTextDisplayBeforeTrainingOpen] =
    useState(null)
  const [initialSubcateg, setInitialSubcateg] = useState(
    viewCourse.courseSubCategory
  )
  const [endorsementLetterID, setEndorsementLetterID] = useState(null)

  const [isSeminar, setIsSeminar] = useState(false)

  const [chosenFiles, setChosenFiles] = useState([])
  const [chosenTrainingVideos, setChosenTrainingVideos] = useState(null)
  const [sectionLength, setSectionLength] = useState([])
  const [sectionTitle, setSectionTitle] = useState([])
  const [activityQuestions, setActivityQuestions] = useState(null)
  const [videoTranscript, setVideoTranscript] = useState(null)
  const [isEndorsementLetterRequired, setIsEndorsementLetterRequired] =
    useState()
  const [isTestEvent, setIsTestEvent] = useState(false)
  const [resourceSpeakerDetails, setResourceSpeesourceSpeakerDetails] =
    useState('')
  const [trainingCreatedDate, setTrainingCreatedDate] = useState(null)

  function isNestedArray(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        return true
      }
    }
    return false
  }

  /* List of departments */
  useEffect(() => {
    const departments = listOfDepartments()
    setDepartments(departments)
    setListOfDepartmentsOriginal(departments)
  }, [])

  const departmentOptions = [
    {
      value: '',
      label: 'Select department',
      disabled: true,
    },
    ...departments.map((dept) => ({
      value: dept,
      label: dept,
    })),
  ]

  const subCategCore = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Public Service Ethics & Accountability Orientation & Work Ethics',
      label: 'Public Service Ethics & Accountability Orientation & Work Ethics',
    },
    {
      value:
        'Administrative Discipline: Orientation of Values & Ethics in the Workplace',
      label:
        'Administrative Discipline: Orientation of Values & Ethics in the Workplace',
    },
    {
      value: 'Social Media Responsibility',
      label: 'Social Media Responsibility',
    },
    {
      value: 'Customer Service Satisfaction',
      label: 'Customer Service Satisfaction',
    },
  ]

  const subCategLeader = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Leadership & Management Program',
      label: 'Leadership & Management Program',
    },
    {
      value: 'Conflict Management',
      label: 'Conflict Management',
    },
    {
      value: 'Effective Management & Supervision',
      label: 'Effective Management & Supervision',
    },
    {
      value: 'Total Quality Management',
      label: 'Total Quality Management',
    },
    {
      value: 'Project Management',
      label: 'Project Management',
    },
    {
      value: 'Culture Building',
      label: 'Culture Building',
    },
    {
      value: 'Coaching & Mentoring',
      label: 'Coaching & Mentoring',
    },
    {
      value: 'Managing Teams & People',
      label: 'Managing Teams & People',
    },
    {
      value: 'Expanded Team Building',
      label: 'Expanded Team Building',
    },
    {
      value: 'Strategic Management Planning',
      label: 'Strategic Management Planning',
    },
    {
      value: 'Goal and Metrics Setting',
      label: 'Goal and Metrics Setting',
    },
    {
      value: 'Supervisory Development Course',
      label: 'Supervisory Development Course',
    },
    {
      value: 'Change Management',
      label: 'Change Management',
    },
  ]

  const subCategOrg = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Business/Technical Writing Skills',
      label: 'Business/Technical Writing Skills',
    },
    {
      value: 'English Proficiency & Effective Communication',
      label: 'English Proficiency & Effective Communication',
    },
    {
      value: 'Effective Presentation',
      label: 'Effective Presentation',
    },
    {
      value: 'Public Speaking',
      label: 'Public Speaking',
    },
    {
      value: 'Personality Development in Local Government',
      label: 'Personality Development in Local Government',
    },
    {
      value: 'Time Management',
      label: 'Time Management',
    },
    {
      value: 'Obtaining and Providing Positive Work Attitude',
      label: 'Obtaining and Providing Positive Work Attitude',
    },
    {
      value: 'Strategic HRM: Providing Assistance to Planning and Goal Setting',
      label: 'Strategic HRM: Providing Assistance to Planning and Goal Setting',
    },
    {
      value: 'Project Management',
      label: 'Project Management',
    },
    {
      value: 'Data Management & Analysis/Chart',
      label: 'Data Management & Analysis/Chart',
    },
    {
      value: 'Data Privacy',
      label: 'Data Privacy',
    },
    {
      value: 'Critical Thinking',
      label: 'Critical Thinking',
    },
    {
      value: 'Innovative Thinking',
      label: 'Innovative Thinking',
    },
    {
      value: 'Design Thinking',
      label: 'Design Thinking',
    },
  ]

  const subCategFunctional = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Program One',
      label: 'Program One',
    },
    {
      value: 'Program Two',
      label: 'Program Two',
    },
    {
      value: 'Program Three',
      label: 'Program Three',
    },
    {
      value: 'Program Four',
      label: 'Program Four',
    },
  ]

  const subCategAdditional = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Program One',
      label: 'Program One',
    },
    {
      value: 'Program Two',
      label: 'Program Two',
    },
    {
      value: 'Program Three',
      label: 'Program Three',
    },
    {
      value: 'Program Four',
      label: 'Program Four',
    },
  ]

  useEffect(() => {
    if (courseCategory == 'CORE COMPETENCIES') {
      setOptionSubCategory(subCategCore)
      setCourseSubCategory(null)
    } else if (courseCategory == 'ORGANIZATIONAL COMPETENCIES') {
      setOptionSubCategory(subCategOrg)
      setCourseSubCategory(null)
    } else if (courseCategory == 'LEADERSHIP COMPETENCIES') {
      setOptionSubCategory(subCategLeader)
      setCourseSubCategory(null)
    } else if (courseCategory == 'FUNCTIONAL/TECHNICAL') {
      setOptionSubCategory(subCategFunctional)
      setCourseSubCategory(null)
    } else if (courseCategory == 'Additional Training Programs') {
      setOptionSubCategory(subCategAdditional)
      setCourseSubCategory(null)
    } else {
      setOptionSubCategory([])
    }
  }, [courseCategory])

  const handleDate = (selectedDates) => {
    const formattedDates = selectedDates.map((date) =>
      date.format('YYYY-MM-DD')
    )

    setAnnounceDate(formattedDates)
  }

  const handleTrainingStartTime = (time, timeString) => {
    setAnnouceStartTime(timeString)
  }

  const handleTrainingEndTime = (time, timeString) => {
    setAnnouceEndTime(timeString)
  }

  const handleCourseTitle = (e) => {
    setCourseTitle(e.target.value)
  }

  const handleCourseCategory = (e) => {
    setCourseCategory(e)
    setCourseSubCategory(null)
    setInitialSubcateg(null)
  }
  const handleCourseSubCategory = (e) => {
    setCourseSubCategory(e)
  }

  const handleTargetParticipants = (e) => {
    setTpData(e)
  }

  const handleAdditionalText = (content) => {
    setTextDisplayBeforeTrainingOpen(content)
  }

  const handleCourseDesc = (e) => {
    setCdData(e)
  }

  const handleHasAssessment = (e) => {
    setShowPostAssessment(e.target.value)
    if (e.target.value == 'false') {
      setAssessmentID(null)
    }
  }

  const handlePostTrainOpenDate = (date, dateString) => {
    setPTDateOpen(dateString)
  }

  const handlePostTrainOpenTime = (time, timeString) => {
    setPTTimeOpen(timeString)
  }

  const handlePostTrainCloseDate = (date, dateString) => {
    setPTDateClose(dateString)
  }

  const handlePostTrainCloseTime = (time, timeString) => {
    setPTTimeClose(timeString)
  }

  const showUploadNames = announceUpload.map((o, index) => {
    return (
      <span className={'fw-bold'} key={index}>
        {o.name}
      </span>
    )
  })

  const closeUploadPubMat = () => {
    setAnnounceUpload([])
    setAnnounceDate(null)
    setTpData(null)
    setCdData(null)
    setCourseTitle(null)
    setOpenViewViewEditModal(false)
  }

  const handleSubmitTraining = async (e) => {
    e.preventDefault()
    try {
      if (announceDate) {
        if (courseTitle) {
          if (courseCategory) {
            if (courseSubCategory) {
              if (tpData) {
                if (cdData) {
                  if (participantLimit) {
                    if (pTDateOpen) {
                      if (pTTimeOpen) {
                        if (pTDateClose) {
                          if (pTTimeClose) {
                            if (
                              moment(
                                `${pTDateOpen} ${pTTimeOpen}`
                              ).isValid() === true &&
                              moment(
                                `${pTDateClose} ${pTTimeClose}`
                              ).isValid() === true
                            ) {
                              if (announceUpload.length >= 1) {
                                const credentials =
                                  await Auth.currentCredentials()
                                const accessKeyId = credentials.accessKeyId
                                const secretAccessKey =
                                  credentials.secretAccessKey
                                const sessionToken = credentials.sessionToken

                                const s3 = new S3Client({
                                  region: 'ap-southeast-1',
                                  credentials: {
                                    accessKeyId: accessKeyId,
                                    secretAccessKey: secretAccessKey,
                                    sessionToken: sessionToken,
                                  },
                                })

                                Promise.all(
                                  announceUpload.map((value) => {
                                    return new Promise(function (
                                      resolve,
                                      reject
                                    ) {
                                      const params = {
                                        Bucket: 's3etnahris133956-dev',
                                        Key: `uploadedByAdmin/${value.name}`,
                                        Body: value,
                                      }

                                      const command = new PutObjectCommand(
                                        params
                                      )
                                      s3.send(command)
                                      const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

                                      resolve(url)
                                    })
                                  })
                                ).then(async (res) => {
                                  const locations = res.map((item) => {
                                    const oldLoc = item
                                    return oldLoc
                                  })
                                  const input = {
                                    id: viewCourse.id,
                                    location: JSON.stringify(locations),
                                    date: Array.isArray(announceDate)
                                      ? announceDate[0]
                                      : announceDate,
                                    arrayOfDates: Array.isArray(announceDate)
                                      ? JSON.stringify(announceDate)
                                      : annouceArrayOfDate || null,
                                    trainingTimeStart: announceStartTime,
                                    trainingTimeEnd: announceEndTime,
                                    title: courseTitle,
                                    courseDescription: cdData,
                                    targetParticipants: tpData,
                                    postTrainOpen: moment(
                                      `${pTDateOpen} ${pTTimeOpen}`
                                    ).format('ddd MMM D YYYY hh:mm:ss a'),
                                    postTrainClose: moment(
                                      `${pTDateClose} ${pTTimeClose}`
                                    ).format('ddd MMM D YYYY hh:mm:ss a'),
                                    courseCategory,
                                    courseSubCategory,
                                    hasAssessment: showPostAssessment,
                                    participantLimit,
                                    evalID: evalID,
                                    assessmentID: assessmentID,
                                    limitPerDept: JSON.stringify([
                                      departmentsLimitValue,
                                    ]),
                                    additionalTexts:
                                      textDisplayBeforeTrainingOpen,
                                    sectionTitle: JSON.stringify(sectionTitle),
                                    additionalFiles:
                                      JSON.stringify(chosenFiles),
                                    sectionVideos:
                                      JSON.stringify(chosenTrainingVideos),
                                    sectionQuestions:
                                      JSON.stringify(activityQuestions),
                                    videoTranscripts:
                                      JSON.stringify(videoTranscript),
                                    isSeminar,
                                    isEndorsementRequired:
                                      isEndorsementLetterRequired,
                                    isTestEvent,
                                    stepThreeID: endorsementLetterID,
                                  }

                                  const adminUpload = await API.graphql(
                                    graphqlOperation(
                                      updateOnlineTrainingAnnouncement,
                                      { input }
                                    )
                                  )

                                  if (
                                    adminUpload.data
                                      .updateOnlineTrainingAnnouncement
                                  ) {
                                    notification['success']({
                                      message: 'Success',
                                      description:
                                        'Online Training announcement edited.',
                                    })

                                    setOpenViewViewEditModal(false)
                                    refresh()
                                    setOpenView(false)
                                  }
                                })
                              } else {
                                notification['info']({
                                  message: 'Info',
                                  description:
                                    'You will be uploading the edited Online Training Announcement using the same poster.',
                                })

                                setTimeout(async () => {
                                  const input = {
                                    id: viewCourse.id,
                                    date: Array.isArray(announceDate)
                                      ? announceDate[0]
                                      : announceDate,
                                    arrayOfDates: Array.isArray(announceDate)
                                      ? JSON.stringify(announceDate)
                                      : annouceArrayOfDate || null,
                                    trainingTimeStart: announceStartTime,
                                    trainingTimeEnd: announceEndTime,
                                    title: courseTitle,
                                    courseDescription: cdData,
                                    targetParticipants: tpData,
                                    postTrainOpen: moment(
                                      `${pTDateOpen} ${pTTimeOpen}`
                                    ).format('ddd MMM D YYYY hh:mm:ss a'),
                                    postTrainClose: moment(
                                      `${pTDateClose} ${pTTimeClose}`
                                    ).format('ddd MMM D YYYY hh:mm:ss a'),
                                    courseCategory,
                                    courseSubCategory,
                                    hasAssessment: showPostAssessment,
                                    participantLimit,
                                    evalID: evalID,
                                    assessmentID: assessmentID,
                                    limitPerDept: JSON.stringify([
                                      departmentsLimitValue,
                                    ]),
                                    additionalTexts:
                                      textDisplayBeforeTrainingOpen,
                                    sectionTitle: JSON.stringify(sectionTitle),
                                    additionalFiles:
                                      JSON.stringify(chosenFiles),
                                    sectionVideos:
                                      JSON.stringify(chosenTrainingVideos),
                                    sectionQuestions:
                                      JSON.stringify(activityQuestions),
                                    videoTranscripts:
                                      JSON.stringify(videoTranscript),
                                    isSeminar,
                                    isEndorsementRequired:
                                      isEndorsementLetterRequired,
                                    isTestEvent,
                                    stepThreeID: endorsementLetterID,
                                  }

                                  const adminUpload = await API.graphql(
                                    graphqlOperation(
                                      updateOnlineTrainingAnnouncement,
                                      { input }
                                    )
                                  )
                                  if (
                                    adminUpload.data
                                      .updateOnlineTrainingAnnouncement
                                  ) {
                                    notification['success']({
                                      message: 'Success',
                                      description:
                                        'Online Training announcement edited.',
                                    })

                                    setOpenViewViewEditModal(false)
                                    refresh()
                                    setOpenView(false)
                                  }
                                }, 1000)
                              }
                            } else {
                              notification['error']({
                                message: 'Error',
                                description:
                                  "There's something wrong with the Post Training Open/Close Dates and/or Times. Please re-enter each accordingly.",
                              })
                            }
                          } else {
                            notification['error']({
                              message: 'Error',
                              description:
                                'Missing Post Training Time of closing.',
                            })
                          }
                        } else {
                          notification['error']({
                            message: 'Error',
                            description:
                              'Missing Post Training Date of closing.',
                          })
                        }
                      } else {
                        notification['error']({
                          message: 'Error',
                          description: 'Missing Post Training Time of opening.',
                        })
                      }
                    } else {
                      notification['error']({
                        message: 'Error',
                        description: 'Missing Post Training Date of opening.',
                      })
                    }
                  } else {
                    notification['error']({
                      message: 'Error',
                      description:
                        'Missing Participant Limit for the Training Announcement.',
                    })
                  }
                } else {
                  notification['error']({
                    message: 'Error',
                    description:
                      'Missing Course Description for the Training Announcement.',
                  })
                }
              } else {
                notification['error']({
                  message: 'Error',
                  description:
                    'Missing Target Participants for the Training Announcement.',
                })
              }
            } else {
              notification['error']({
                message: 'Error',
                description:
                  'Missing Sub-category for the Training Announcement.',
              })
            }
          } else {
            notification['error']({
              message: 'Error',
              description: 'Missing Category for the Training Announcement.',
            })
          }
        } else {
          notification['error']({
            message: 'Error',
            description: 'Missing Title for the Training Announcement.',
          })
        }
      } else {
        notification['error']({
          message: 'Error',
          description: 'Missing date of Training Announcement.',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleOpenView = () => {
    const {
      courseCategory,
      courseDescription,
      courseSubCategory,
      date,
      arrayOfDates,
      trainingTimeStart,
      trainingTimeEnd,
      hasAssessment,
      participantLimit,
      postTrainClose,
      postTrainOpen,
      targetParticipants,
      title,
      assessmentID,
      evalID,
      limitPerDept,
      additionalTexts,
      isSeminar,
      additionalFiles,
      sectionTitle,
      sectionVideos,
      sectionQuestions,
      videoTranscripts,
      isEndorsementRequired,
      isTestEvent,
      stepThreeID,
      resourceSpeakerDetails,
      createdAt,
    } = viewCourse

    const oldValue = isNestedArray(JSON.parse(limitPerDept))
      ? JSON.parse(viewCourse.limitPerDept).flat()
      : JSON.parse(viewCourse.limitPerDept)

    try {
      setAnnounceDate(date)
      setAnnouceArrayOfDate(arrayOfDates)
      setAnnouceStartTime(trainingTimeStart)
      setAnnouceEndTime(trainingTimeEnd)
      setTpData(targetParticipants)
      setCdData(courseDescription)
      setCourseTitle(title)
      setCourseCategory(courseCategory)
      setTextDisplayBeforeTrainingOpen(additionalTexts)
      setParticipantLimit(participantLimit)
      setPTDateOpen(moment(postTrainOpen).format('YYYY-MM-DD'))
      setPTTimeOpen(moment(postTrainOpen).format('h:mm:ss A'))
      setPTDateClose(moment(postTrainClose).format('YYYY-MM-DD'))
      setPTTimeClose(moment(postTrainClose).format('h:mm:ss A'))
      setAnnounceUpload([])
      setResourceSpeesourceSpeakerDetails(resourceSpeakerDetails)
      setTrainingCreatedDate(createdAt)
      setShowPostAssessment(hasAssessment)
      setEvalID(evalID)
      setAssessmentID(assessmentID)
      setDepartmentsLimitValue(oldValue ? oldValue[0] : [])

      setIsSeminar(isSeminar ? true : false)
      setChosenFiles(JSON.parse(additionalFiles))
      setSectionTitle(JSON.parse(sectionTitle))
      setChosenTrainingVideos(JSON.parse(sectionVideos))
      setActivityQuestions(JSON.parse(sectionQuestions))
      setVideoTranscript(JSON.parse(videoTranscripts))
      setIsEndorsementLetterRequired(isEndorsementRequired)
      setIsTestEvent(isTestEvent)
      setEndorsementLetterID(stepThreeID)

      JSON.parse(sectionTitle).forEach((_, index) => {
        setSectionLength((prevElements) => [
          ...prevElements,
          activityQuestions?.[`activityQuestion${index + 1}`] ? true : false,
        ])
      })

      setTimeout(() => {
        setOpenViewViewEditModal(true)
        setCourseSubCategory(courseSubCategory)
      }, 1500)
    } catch (err) {
      console.log(err)
    }
  }

  const handleIsSeminarInput = (e) => {
    setIsSeminar(e.target.value)
  }

  const handleSectionTitle = (e, index) => {
    setSectionTitle((prev) => {
      const updatedTitles = [...prev]
      updatedTitles[index] = e.target.value
      return updatedTitles
    })
  }

  const handleChooseTrainingVideos = (e, f) => {
    const key = e.key
    setChosenTrainingVideos((prev) => ({
      ...prev,
      [f]: key,
    }))
  }

  const handleAddTranscript = (e, f) => {
    const value = e.target.value

    setVideoTranscript((prev) => ({
      ...prev,
      [f]: value,
    }))
  }

  const handleChooseFiles = (value) => {
    setChosenFiles(value)
  }

  const handleSectionHasActivity = (value, index) => {
    if (value === 'false') {
      const questionToDelete = `activityQuestion${index + 1}`
      if (activityQuestions && activityQuestions[questionToDelete]) {
        const updatedQuestions = { ...activityQuestions }
        delete updatedQuestions[questionToDelete]
        setActivityQuestions(updatedQuestions)
      }
    }

    const updatedSectionLength = [...sectionLength]
    updatedSectionLength[index] = value === 'true'
    setSectionLength(updatedSectionLength)
  }

  const handleDeleteSection = (e, g, h) => {
    const myObjectTitles = sectionTitle
    const myObjectVideos = chosenTrainingVideos
    const myObjectQuestions = activityQuestions

    if (myObjectTitles) {
      myObjectTitles.splice(e, 1)
      setSectionTitle(myObjectTitles)
    }
    if (myObjectVideos) {
      delete myObjectVideos[g]
      setChosenTrainingVideos(myObjectVideos)
    }
    if (myObjectQuestions) {
      delete myObjectQuestions[h]
      setActivityQuestions(myObjectQuestions)
    }
    setSectionLength((prevElements) => prevElements.filter((_, i) => i !== e))
  }

  /**
   * Adds a new section to the section length array.
   */
  const handleAddSection = () => {
    const secArray = []
    secArray.push(...sectionLength)
    secArray.push(false)
    setSectionLength(secArray)
  }

  /**
   * Object representing the preview input for uploading online trainings.
   * @typedef {Object} PreviewInput
   * @property {string} title - The title of the course.
   * @property {string} arrayOfDates - The array of announce dates in JSON string format.
   * @property {string} trainingTimeStart - The start time of the training.
   * @property {string} trainingTimeEnd - The end time of the training.
   * @property {string} courseDescription - The description of the course.
   * @property {string} targetParticipants - The target participants of the course.
   * @property {boolean} isOpenRegistration - Indicates if the registration is open.
   * @property {string} postTrainOpen - The opening date and time of the post-training.
   * @property {string} postTrainClose - The closing date and time of the post-training.
   * @property {string} courseCategory - The category of the course.
   * @property {string} courseSubCategory - The sub-category of the course.
   * @property {boolean} hasAssessment - Indicates if the course has an assessment.
   * @property {number} participantLimit - The limit of participants for the course.
   * @property {string} evalID - The evaluation ID for the course.
   * @property {string} assessmentID - The assessment ID for the course.
   * @property {string} stepThreeID - The ID for the endorsement letter in step three.
   * @property {number[]} limitPerDept - The limit per department for the course.
   * @property {string} parentTrainingID - The ID of the parent training.
   * @property {string} isEndorsementRequired - Indicates if an endorsement letter is required.
   * @property {string} sectionTitle - The title of the section.
   * @property {string[]} additionalFiles - The additional files for the section.
   * @property {string[]} sectionVideos - The videos for the section.
   * @property {string[]} sectionQuestions - The questions for the section.
   */
  const previewInput = {
    title: courseTitle,
    arrayOfDates: JSON.stringify(announceDate),
    trainingTimeStart: announceStartTime,
    trainingTimeEnd: announceEndTime,
    courseDescription: cdData,
    targetParticipants: tpData,
    isOpenRegistration: true,
    postTrainOpen: moment(`${pTDateOpen} ${pTTimeOpen}`).format(
      'ddd MMM D YYYY hh:mm:ss a'
    ),
    postTrainClose: moment(`${pTDateClose} ${pTTimeClose}`).format(
      'ddd MMM D YYYY hh:mm:ss a'
    ),
    courseCategory: courseCategory,
    courseSubCategory: courseSubCategory,
    hasAssessment: showPostAssessment,
    participantLimit,
    evalID: evalID,
    assessmentID: assessmentID,
    /* stepThreeID: endorsementLetterID, */
    limitPerDept: [departmentsLimitValue],
    /*  parentTrainingID: selectedTraining, */
    /* isEndorsementRequired: isEndorsementLetterRequired
      ? isEndorsementLetterRequired
      : 'no', */
    sectionTitle: sectionTitle,
    additionalFiles: chosenFiles,
    sectionVideos: chosenTrainingVideos,
    videoTranscript: videoTranscript,
    sectionQuestions: activityQuestions,
    resourceSpeakerDetails: resourceSpeakerDetails,
    createdAt: trainingCreatedDate,
  }

  const handleTestEventInput = (e) => {
    setIsTestEvent(e.target.value)
  }

  return (
    <>
      <Button onClick={handleOpenView}>Edit Online Training</Button>

      <Modal
        maskClosable={false}
        title={'Edit details of the Course / Training'}
        centered
        visible={openViewEditModal}
        onOk={() => setOpenViewViewEditModal(false)}
        onCancel={() => setOpenViewViewEditModal(false)}
        width={1100}
        footer={null}
        destroyOnClose={true}
      >
        <form onSubmit={handleSubmitTraining}>
          <div className={'border mb-3'}>
            <Row justify='center'>
              <span>
                This part is for creating a test training announcements
              </span>
            </Row>
            <Row justify='center'>
              <div className='reg1-row'>
                <span className='loginInput-label'>
                  Are you uploading a Test Event?
                </span>
                <Row justify='center'>
                  <em>For Testing purposes only</em>
                </Row>
              </div>
            </Row>
            <Row onChange={handleTestEventInput} justify='center'>
              <div className={'d-flex align-items-center me-3'}>
                <label
                  htmlFor='test-event-yes'
                  className={'loginInput-label m-0'}
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  YES
                </label>
                <input
                  value={true}
                  defaultChecked={isTestEvent}
                  className={'ms-2 test-event'}
                  name='test-event'
                  id='test-event-yes'
                  type='radio'
                />
              </div>

              <div className={'d-flex align-items-center ms-3'}>
                <label
                  htmlFor='test-event-no'
                  className={'loginInput-label m-0'}
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  NO
                </label>
                <input
                  defaultChecked={!isTestEvent}
                  value={false}
                  className={'ms-2 test-event'}
                  name='test-event'
                  id='test-event-no'
                  type='radio'
                />
              </div>
            </Row>

            <Row justify='center'>
              <span>
                This part is for creating a test training announcements
              </span>
            </Row>
          </div>

          <div className='d-flex flex-column align-items-center justify-content-center mt-3'>
            <span className='loginInput-label'>
              Do you want this required the Endorsement Letter of this training?
            </span>
            <div className='d-flex gap-2'>
              <div className={'d-flex align-items-center me-3'}>
                <label
                  className={'loginInput-label m-0'}
                  style={{ marginBottom: 10, marginRight: 10 }}
                  htmlFor='isEndorsementLetterRequiredYes'
                >
                  YES
                </label>
                <input
                  value={isEndorsementLetterRequired}
                  defaultChecked={isEndorsementLetterRequired == 'yes'}
                  onChange={() => setIsEndorsementLetterRequired('yes')}
                  type='radio'
                  id='isEndorsementLetterRequiredYes'
                  name='isEndorsementLetterRequired'
                  className={'ms-2 test-event'}
                />
              </div>
              <div className={'d-flex align-items-center ms-3'}>
                <label
                  className={'loginInput-label m-0'}
                  style={{ marginBottom: 10, marginRight: 10 }}
                  htmlFor='isEndorsementLetterRequiredNo'
                >
                  NO
                </label>
                <input
                  value={isEndorsementLetterRequired}
                  defaultChecked={isEndorsementLetterRequired == null}
                  onChange={() => setIsEndorsementLetterRequired(null)}
                  type='radio'
                  id='isEndorsementLetterRequiredNo'
                  name='isEndorsementLetterRequired'
                  className={'ms-2 test-event'}
                />
              </div>
            </div>
          </div>

          <Row className='mt-3' justify='center'>
            <div className='reg1-row'>
              <span className='loginInput-label'>
                Are you uploading a Seminar?
              </span>
            </div>
          </Row>

          <Row
            className='mb-3'
            onChange={handleIsSeminarInput}
            justify='center'
          >
            <div className={'d-flex align-items-center me-3'}>
              <label
                htmlFor='isSeminarYes'
                className={'loginInput-label m-0'}
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                YES
              </label>
              <input
                defaultChecked={isSeminar === true ? true : false}
                value={true}
                className={'ms-2 test-event'}
                name='seminar'
                id='isSeminarYes'
                type='radio'
              />
            </div>

            <div className={'d-flex align-items-center ms-3'}>
              <label
                htmlFor='isSeminarYes'
                className={'loginInput-label m-0'}
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                NO
              </label>
              <input
                defaultChecked={isSeminar === true ? false : true}
                value={false}
                className={'ms-2 test-event'}
                name='seminar'
                id='isSeminarYes'
                type='radio'
              />
            </div>
          </Row>
          <div className='reg1-row'>
            <span>
              <label
                htmlFor='date-event'
                className='loginInput-label'
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                Select Date of Event:
              </label>
              <DatePicker
                onChange={handleDate}
                sort
                multiple
                format={'YYYY/MM/DD'}
                plugins={[<DatePanel />]}
                style={{ height: '30px', width: '400px' }}
              />
            </span>
          </div>
          <div className='reg1-row mt-3'>
            <span>
              <label
                htmlFor='Train-openTime'
                className='loginInput-label col-md-3 col-12'
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                Select Start Time of Event:
              </label>

              <TimePicker
                defaultValue={moment(viewCourse.trainingTimeStart, 'h:mm A')}
                id='Train-openTime'
                name='Train-openTime'
                use12Hours
                format='h:mm A'
                onChange={handleTrainingStartTime}
              />
            </span>
          </div>
          <div className='reg1-row mt-3'>
            <span>
              <label
                htmlFor='Train-endTime'
                className='loginInput-label col-md-3 col-12'
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                Select End Time of Event:
              </label>

              <TimePicker
                defaultValue={moment(viewCourse.trainingTimeEnd, 'h:mm A')}
                id='Train-endTime'
                name='Train-endTime'
                use12Hours
                format='h:mm A'
                onChange={handleTrainingEndTime}
              />
            </span>
          </div>

          <Row>
            <label
              htmlFor='course-title'
              className='loginInput-label'
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Online Training Title
            </label>
            <Input
              onChange={handleCourseTitle}
              id='course-title'
              name='course-title'
              defaultValue={viewCourse.title}
            />
          </Row>

          <Row>
            <label
              htmlFor='course-category'
              className='loginInput-label'
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Online Training Category
            </label>
            <Col span={24}>
              <Select
                style={{
                  width: '100%',
                }}
                id='course-category'
                name='course-category'
                showSearch
                placeholder='Select a category'
                optionFilterProp='children'
                onChange={handleCourseCategory}
                // onSearch={onSearchCategory}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: '',
                    label: '',
                    disabled: true,
                  },
                  {
                    value: 'CORE COMPETENCIES',
                    label: 'CORE COMPETENCIES',
                  },
                  {
                    value: 'LEADERSHIP COMPETENCIES',
                    label: 'LEADERSHIP COMPETENCIES',
                  },
                  {
                    value: 'ORGANIZATIONAL COMPETENCIES',
                    label: 'ORGANIZATIONAL COMPETENCIES',
                  },
                  {
                    value: 'FUNCTIONAL/TECHNICAL',
                    label: 'FUNCTIONAL/TECHNICAL',
                  },
                  {
                    value: 'Additional Training Programs',
                    label: 'Additional Training Programs',
                  },
                ]}
                defaultValue={viewCourse.courseCategory}
              />
            </Col>
          </Row>
          <Row>
            <label
              htmlFor='course-category'
              className='loginInput-label'
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Online Training Sub-category
            </label>
            <Col span={24}>
              <Select
                style={{
                  width: '100%',
                }}
                id='course-sub-category'
                name='course-sub-category'
                showSearch
                placeholder='Select a sub-category'
                optionFilterProp='children'
                onChange={handleCourseSubCategory}
                // onSearch={onSearchCategory}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={optionSubCategory}
                defaultValue={initialSubcateg}
                value={courseSubCategory}
                required
              />
            </Col>
          </Row>

          <Row>
            <label
              htmlFor='otherFilesSelect'
              className='loginInput-label'
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Online Training Additional Files
            </label>
            <Col span={24}>
              <Select
                value={chosenFiles}
                mode='multiple'
                className='w-100'
                id='otherFilesSelect'
                name='otherFilesSelect'
                showSearch
                allowClear
                placeholder='Select additional material/s for the training'
                optionFilterProp='children'
                onChange={handleChooseFiles}
                filterOption={(input, option) =>
                  (option?.filename ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={otherFiles}
              />
            </Col>
          </Row>

          {sectionLength &&
            sectionLength?.map((section, index) => {
              return (
                <Row key={index} id={`section-length-${index}`}>
                  <Col span={22}>
                    <Row className='mt-4'>
                      <h4
                        htmlFor='createSection'
                        /*  className='loginInput-label' */
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        Section {index + 1}
                      </h4>
                    </Row>
                    <div className='ms-4'>
                      <Row className='mt-3'>
                        <label
                          htmlFor='createSection'
                          className='loginInput-label'
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          Topic / Category
                        </label>
                        <Input
                          value={sectionTitle[index]}
                          placeholder='Topic / Category title'
                          onChange={(e) => handleSectionTitle(e, index)}
                          id={`sectionTitle-${index + 1}`}
                          name='createSection'
                        />
                      </Row>
                      <Row>
                        <label
                          htmlFor='course-title'
                          className='loginInput-label'
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          Select training video
                        </label>
                        <Select
                          value={
                            chosenTrainingVideos?.[
                              `trainingVideosID${index + 1}`
                            ]
                          }
                          className='w-100'
                          id={`trainingVideosID-${index + 1}`}
                          name='trainingVideosID'
                          showSearch
                          placeholder='Select training video/s for the section'
                          optionFilterProp='children'
                          onChange={(input, option) =>
                            handleChooseTrainingVideos(
                              option,
                              `trainingVideosID${index + 1}`
                            )
                          }
                          // onSearch={onSearchCategory}
                          filterOption={(input, option) =>
                            (option?.key ?? '')
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={uploadedTrainingVideo}
                        />
                      </Row>
                      <Row>
                        <label
                          htmlFor='course-title'
                          className='loginInput-label'
                          style={{ marginTop: 10, marginBottom: 10 }}
                        >
                          Video Transcript
                          <span className='text-secondary'> (optional)</span>
                        </label>
                        <TextArea
                          value={
                            videoTranscript?.[`videoTranscriptID${index + 1}`]
                          }
                          rows={4}
                          placeholder='Enter video transcript'
                          onChange={(e) =>
                            handleAddTranscript(
                              e,
                              `videoTranscriptID${index + 1}`
                            )
                          }
                          id='course-video-transcript'
                          name='course-video-transcript'
                        />
                      </Row>
                    </div>
                    {activityQuestions &&
                    activityQuestions[`activityQuestion${index + 1}`] ? (
                      <div className='ms-4 d-flex gap-2 align-items-center mt-3'>
                        <span
                          className='d-flex items-center gap-1'
                          style={{
                            fontSize: '16px',
                          }}
                        >
                          Activity question{' '}
                          <CheckOutlined
                            className='text-success'
                            style={{
                              fontSize: '24px',
                            }}
                          />
                        </span>
                        <Button
                          onClick={(e) =>
                            handleSectionHasActivity('false', index)
                          }
                          value={'false'}
                          type='danger'
                        >
                          Delete
                        </Button>
                        {/* <Button type='primary'>View</Button> */}
                      </div>
                    ) : (
                      <div className='reg1-row mt-3 mx-auto mb-5'>
                        <Row justify='center'>
                          <span className='loginInput-label'>
                            Will it have a activity question?
                          </span>
                        </Row>
                        <Row
                          /* onChange={handleHasAssessment} */
                          className='mt-3'
                          justify='space-around'
                        >
                          <Col>
                            <input
                              defaultChecked={false}
                              value={true}
                              id={`activityQuestion${index + 1}`}
                              name={`activityQuestion${index + 1}`}
                              className='activityQuestion'
                              type='radio'
                              onChange={(e) =>
                                handleSectionHasActivity(e.target.value, index)
                              }
                            />
                            <label
                              className='loginInput-label'
                              htmlFor='activityQuestionYes'
                            >
                              YES
                            </label>
                          </Col>
                          <Col>
                            <input
                              defaultChecked={true}
                              value={false}
                              id={`activityQuestionNo${index + 1}`}
                              name={`activityQuestion${index + 1}`}
                              className='activityQuestion'
                              type='radio'
                              onChange={(e) =>
                                handleSectionHasActivity(e.target.value, index)
                              }
                            />
                            <label
                              className='loginInput-label'
                              htmlFor='activityQuestionNo'
                            >
                              NO
                            </label>
                          </Col>
                        </Row>
                        {section ? (
                          <>
                            <Row className='mt-3 w-100 gap-5' justify='center'>
                              <ActivityQuestionMaker
                                setActivityQuestions={setActivityQuestions}
                                index={index}
                              />
                              <SelectActivityQuestions
                                setActivityQuestions={setActivityQuestions}
                                index={index}
                              />
                            </Row>
                          </>
                        ) : null}
                      </div>
                    )}
                  </Col>
                  <Col
                    span={2}
                    className='align-items-center d-flex flex-column'
                  >
                    {index == 0 || index + 1 < sectionLength.length ? null : (
                      <Row justify='end' className='mt-3 mb-3'>
                        <Button
                          onClick={(e) =>
                            handleDeleteSection(
                              index,
                              `trainingVideosID${index + 1}`,
                              `activityQuestion${index + 1}`
                            )
                          }
                          ghost
                          type='danger'
                          className='d-flex justify-content-center align-items-center'
                        >
                          <CloseOutlined />
                        </Button>
                      </Row>
                    )}
                  </Col>
                </Row>
              )
            })}

          <Row className='mt-4'>
            <Col span={24}>
              <Button
                onClick={handleAddSection}
                type='primary'
                ghost
                block
                className='d-flex justify-content-center align-items-center'
              >
                Add Section <PlusOutlined />
              </Button>
            </Col>
          </Row>

          <div className='reg1-row'>
            <span>
              <label
                htmlFor='pubmat'
                className='loginInput-label'
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                PUBMAT Upload Here
              </label>
              <Input
                id='pubmat'
                name='pubmat'
                multiple
                type='file'
                accept='image/jpeg,image/gif,image/png,application/pdf,video/mp4,video/x-m4v,video/*'
                onChange={(e) => {
                  setAnnounceUpload([...e.target.files])
                }}
              />
              {announceUpload.length > 0 ? (
                <div className={'d-flex flex-column'}>{showUploadNames}</div>
              ) : null}
              <Row className='flex-column'>
                <label
                  htmlFor='course-description'
                  className='loginInput-label'
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  Course Description
                </label>
                <ReactQuill
                  onChange={handleCourseDesc}
                  id='course-description'
                  name='course-description'
                  className='mb-5 mt-1'
                  style={{ height: 130 }}
                  defaultValue={viewCourse.courseDescription}
                />
              </Row>
              <Row className='flex-column'>
                <label
                  htmlFor='target-participants'
                  className='loginInput-label'
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  Target Participants
                </label>
                <ReactQuill
                  onChange={handleTargetParticipants}
                  id='target-participants'
                  name='target-participants'
                  className='mb-5 mt-1'
                  style={{ height: 130 }}
                  defaultValue={viewCourse.targetParticipants}
                />
              </Row>
              <Row className='flex-column'>
                <label
                  htmlFor='additonal-text'
                  className='loginInput-label'
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  Text to display before training open
                </label>
                <ReactQuill
                  onChange={handleAdditionalText}
                  value={textDisplayBeforeTrainingOpen}
                  id='additonal-text'
                  name='additonal-text'
                  className='mb-5 mt-1'
                  style={{ height: 130 }}
                />
              </Row>
              <Row className='my-3'>
                <Col span={15}>
                  <label
                    htmlFor='limit-participants'
                    className='loginInput-label'
                    style={{ marginTop: 10, marginBottom: 10 }}
                  >
                    Number of Participants{' '}
                    <small style={{ fontSize: 10 }}>
                      <em>(limit the number of who can attend)</em>
                    </small>
                  </label>
                </Col>
                <Col span={9}>
                  <Input
                    onClick={() => setManageParticipantsModal(true)}
                    id='limit-participants'
                    name='limit-participants'
                    type='number'
                    value={participantLimit}
                    min={1}
                    defaultValue={viewCourse.participantLimit}
                  />
                </Col>
              </Row>
            </span>
          </div>

          <ManageTrainingsNumberOfParticipants
            departmentOptions={departmentOptions}
            departments={departments}
            setDepartments={setDepartments}
            listOfDepartmentsOrignal={listOfDepartmentsOrignal}
            setDepartmentsLimitValue={setDepartmentsLimitValue}
            manageParticipantsModal={manageParticipantsModal}
            setManageParticipantsModal={setManageParticipantsModal}
            setParticipantLimit={setParticipantLimit}
            participantLimit={participantLimit}
            departmentsLimitValue={departmentsLimitValue}
          />

          <div className='reg1-row mt-3'>
            {/*<Row justify="center">
              <span className="loginInput-label">
                Will it have a Pre-assessment and Post-assessment?
              </span>
            </Row>*/}

            {/*<Row
              onChange={handleHasAssessment}
              className="mt-3"
              justify="space-around"
            >
              <Col>
                <input
                  defaultChecked={showPostAssessment == true? true : false}
                  value={true}
                  id="hasAssessmentYes"
                  name="hasAssessment"
                  className="hasAssessment"
                  type="radio"
                />
                <label
                  className="loginInput-label"
                  htmlFor="hasAssessmentYes"
                >
                  YES
                </label>
              </Col>
              <Col>
                <input
                  defaultChecked={showPostAssessment == true? false : true}
                  value={false}
                  id="hasAssessmentNo"
                  name="hasAssessment"
                  className="hasAssessment"
                  type="radio"
                />
                <label
                  className="loginInput-label"
                  htmlFor="hasAssessmentNo"
                >
                  NO
                </label>
              </Col>
            </Row>*/}
          </div>

          <div>
            <EndorsementLetter
              userData={userData}
              setEndorsementLetterID={setEndorsementLetterID}
            />
            {endorsementLetterID && (
              <Row className='mt-3'>
                <span className='loginInput-label'>
                  Text ID: <em className='asterisk'>{endorsementLetterID}</em>
                </span>
              </Row>
            )}
          </div>

          <div className='reg1-row mt-3'>
            <Row justify='center'>
              <span className='loginInput-label'>
                Will it have a Pre-assessment and Post-assessment?
              </span>
            </Row>
            <Row
              onChange={handleHasAssessment}
              className='mt-3'
              justify='space-around'
            >
              <Col>
                <input
                  defaultChecked={
                    viewCourse.hasAssessment == 'true' ? true : false
                  }
                  value={true}
                  id='hasAssessmentYes'
                  name='hasAssessment'
                  className='hasAssessment'
                  type='radio'
                />
                <label className='loginInput-label' htmlFor='hasAssessmentYes'>
                  YES
                </label>
              </Col>
              <Col>
                <input
                  defaultChecked={
                    viewCourse.hasAssessment == 'false' ? true : false
                  }
                  value={false}
                  id='hasAssessmentNo'
                  name='hasAssessment'
                  className='hasAssessment'
                  type='radio'
                />
                <label className='loginInput-label' htmlFor='hasAssessmentNo'>
                  NO
                </label>
              </Col>
            </Row>
          </div>

          {showPostAssessment != 'false' && (
            <>
              <Row className='mt-3 w-100' justify='center'>
                <AssessmentMaker
                  userData={userData}
                  setGetAssessmentID={(e) => setAssessmentID(e)}
                />
              </Row>
              <Row className='mt-3 w-100' justify='center'>
                <SelectAssessmentQuestion
                  setGetAssessmentID={setAssessmentID}
                />
              </Row>
            </>
          )}

          <Row className='mt-3' justify='space-around'>
            <EvaluationMaker setGetEvalID={(e) => setEvalID(e)} />
            <SelectEvaluationQuestion setGetEvalID={setEvalID} />
          </Row>

          {showPostAssessment != 'false' && (
            <Row className='mt-3'>
              <span className='loginInput-label'>
                Assessment Questionnaire ID:{' '}
                <em className='asterisk'>{assessmentID || 'none'}</em>
              </span>
            </Row>
          )}

          {viewCourse.evalID && (
            <Row className='mt-3'>
              <span className='loginInput-label'>
                Evaluation Questionnaire ID:{' '}
                <em className='asterisk'>{evalID}</em>
              </span>
            </Row>
          )}

          <>
            <div className='reg1-row mt-3'>
              <span>
                <label
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  POST TRAINING OPEN
                </label>
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-open'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Date Post Training will open:
                </label>
                <AntdDatePicker
                  id='postTrain-open'
                  name='postTrain-open'
                  onChange={handlePostTrainOpenDate}
                  defaultValue={moment(viewCourse.postTrainOpen)}
                />
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-openTime'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Time Post Training will open:
                </label>

                <TimePicker
                  id='postTrain-openTime'
                  name='postTrain-openTime'
                  use12Hours
                  format='h:mm:ss A'
                  onChange={handlePostTrainOpenTime}
                  defaultValue={moment(viewCourse.postTrainOpen)}
                />
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  POST TRAINING CLOSE
                </label>
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-close'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Date Post Training will close:
                </label>
                <AntdDatePicker
                  id='postTrain-close'
                  name='postTrain-close'
                  onChange={handlePostTrainCloseDate}
                  defaultValue={moment(viewCourse.postTrainClose)}
                />
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-closeTime'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Time Post Training will close:
                </label>

                <TimePicker
                  id='postTrain-closeTime'
                  name='postTrain-closeTime'
                  use12Hours
                  format='h:mm:ss A'
                  onChange={handlePostTrainCloseTime}
                  defaultValue={moment(viewCourse.postTrainClose)}
                />
              </span>
            </div>
          </>

          <div
            className={'d-flex justify-content-end'}
            style={{ marginTop: 20 }}
          >
            <Button style={{ marginRight: 20 }} onClick={closeUploadPubMat}>
              Cancel
            </Button>
            <UploadOnlineeTrainingsPreview
              sectionLength={sectionLength}
              announceUpload={announceUpload}
              handleSubmitTraining={handleSubmitTraining}
              training={previewInput}
              isEditTraining={true}
            />
          </div>
        </form>
      </Modal>
    </>
  )
}
