/**
 * Title: selectEvaluationQuestion.js
 * Description: This is a file that contains the components for the select evaluation question
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/selectEvaluationQuestion.js
 **/

import { API, graphqlOperation } from 'aws-amplify'
import React, { useEffect, useRef, useState } from 'react'
import { listEvaluationQuestions } from '../../api/queries'
import { deleteEvaluationQuestions } from '../../api/mutations'

import { notification, Button, Modal, Row, Col, Input, Popconfirm } from 'antd'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  CaretLeftFilled,
  CaretRightFilled,
} from '@ant-design/icons'
import { async } from 'q'

const SelectEvaluationQuestion = ({ setGetEvalID }) => {
  /* Declaring variables */
  const [openModal, setOpenModal] = useState(false)
  const [evalQuestionsList, setEvalQuestionsList] = useState([])
  const [selectedEvalQuestion, setSelectedEvalQuestion] = useState('')
  const [qIndex, setqIndex] = useState(0)
  // const selectRef = useRef(null);

  /* Convert number to roman */
  function toRoman(num) {
    const decimal = [1000, 900, 500, 400, 100, 90, 50, 40, 10, 9, 5, 4, 1]
    const roman = [
      'M',
      'CM',
      'D',
      'CD',
      'C',
      'XC',
      'L',
      'XL',
      'X',
      'IX',
      'V',
      'IV',
      'I',
    ]
    let result = ''
    for (let i = 0; i < decimal.length; i++) {
      while (decimal[i] <= num) {
        result += roman[i]
        num -= decimal[i]
      }
    }
    return result
  }

  /* Get the list of Eval Questions */
  const fetchEvalQuestionList = async () => {
    const evalQuestionList = await API.graphql({
      query: listEvaluationQuestions,
    })
    const questionList = evalQuestionList.data.listEvaluationQuestions.items
    const initialSelectedQuestion = questionList[qIndex]
    setEvalQuestionsList(questionList)
    setSelectedEvalQuestion(initialSelectedQuestion)
  }

  /* Change the question preview to next question */
  const handleNextQuestion = () => {
    const nextIndex = qIndex === evalQuestionsList.length - 1 ? 0 : qIndex + 1
    setqIndex(nextIndex)
  }

  /* Change the question preview to previous question */
  const handlePrevQuestion = () => {
    const prevIndex = qIndex === 0 ? evalQuestionsList.length - 1 : qIndex - 1
    setqIndex(prevIndex)
  }

  //Handle Selected Question
  // const handleSelectedQuestion = (e) => {
  //   const questionId = e.target.value;
  //   if (questionId) {
  //     const selectedQuestion = evalQuestionsList.filter(question => {
  //       return question.id == questionId;
  //     })
  //     console.log(JSON.parse(selectedQuestion[0].eval))
  //     setSelectedEvalQuestion(JSON.parse(selectedQuestion[0].eval));
  //   }

  //   if (!questionId) {
  //     setSelectedEvalQuestion("")
  //   }
  // }

  /* Saving to bind the selected evaluation question to the training that will be created */
  const handleSave = () => {
    setGetEvalID(evalQuestionsList[qIndex].id)

    setTimeout(() => {
      setqIndex(0)
      setSelectedEvalQuestion(evalQuestionsList[qIndex])
      setOpenModal(false)
    }, 1000)
  }

  /* Closing the select question modal */
  const handleCloseModal = () => {
    setOpenModal(false)
    setqIndex(0)
    setSelectedEvalQuestion(evalQuestionsList[qIndex])
  }

  /* Deleting the evaluation question from database */
  const handleConfirmDelete = async (e, questionId) => {
    e.preventDefault()
    try {
      const status = await API.graphql(
        graphqlOperation(deleteEvaluationQuestions, {
          input: {
            id: questionId,
          },
        })
      )
      if (status) {
        notification['success']({
          message: 'Success',
          description: 'Successfully deleted.',
        })
      }
      fetchEvalQuestionList()
      // selectRef.current.value = "";
    } catch (error) {
      console.log(error)
    }
  }

  /* Initializing the default value of selected question */
  useEffect(() => {
    if (evalQuestionsList && evalQuestionsList[qIndex]) {
      setSelectedEvalQuestion(evalQuestionsList[qIndex])
    }
  }, [qIndex])

  /* Fetch evaluation question list */
  useEffect(() => {
    fetchEvalQuestionList()
  }, [])

  /* Mapping selectedEvalQuestion for Questions Preview */
  const showOutput =
    selectedEvalQuestion &&
    JSON.parse(selectedEvalQuestion.eval).map((elem, index) => {
      return (
        <React.Fragment key={index}>
          <Row>
            <span className={'fw-bold'}>
              {toRoman(index + 1)}. {elem.question}
            </span>
          </Row>
          {elem.categories.length > 0 ? (
            <>
              {elem.description && (
                <Row>
                  <span className={'text-muted py-2'}>{elem.description}</span>
                </Row>
              )}
              <Row className={'mb-3'}>
                <Col style={{ width: 550 }}>
                  <Row>
                    <Col span={9}></Col>
                    <Col span={15} className='d-flex justify-content-around'>
                      <span className='fw-bold'>1</span>
                      <span className='fw-bold'>2</span>
                      <span className='fw-bold'>3</span>
                      <span className='fw-bold'>4</span>
                      <span className='fw-bold'>5</span>
                    </Col>
                    <Col span={24}>
                      {/* <Row style={{ height: "22px" }}></Row> */}
                      {elem.categories.map((category, ci) => {
                        return (
                          <div
                            key={ci}
                            className='d-flex align-items-center mt-1'
                          >
                            <Col span={9} key={ci}>
                              {category[`category-${ci + 1}-${index + 1}`]}{' '}
                              <em className='asterisk'>**</em>
                            </Col>
                            <Col
                              span={15}
                              className='d-flex justify-content-around'
                            >
                              <Row style={{ height: '22px' }}>
                                <input
                                  value='1'
                                  name='setAQOne'
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  value='2'
                                  name='setAQOne'
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  value='2'
                                  name='setAQOne'
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  value='2'
                                  name='setAQOne'
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                              <Row style={{ height: '22px' }}>
                                <input
                                  value='2'
                                  name='setAQOne'
                                  className={'postAssesmentQ'}
                                  type='radio'
                                />
                              </Row>
                            </Col>
                          </div>
                        )
                      })}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          ) : (
            <div style={{ width: '550px' }}>
              <Row className={'mb-3 mt-3'}>
                <Col span={24}>
                  <Row justify='start' className='mb-2'>
                    <label
                      style={{ fontSize: 16 }}
                      className='loginInput-label'
                      htmlFor={`additionalComments${index}`}
                    >
                      Additional Comments/Feedback{' '}
                      <span className='asterisk'>**</span>
                    </label>
                  </Row>
                  <Input.TextArea
                    /* onChange={(e) =>
                      handleTextareaChange(e, elem.question, index)
                    } */
                    id={`additionalComments${index}`}
                    style={{ fontSize: 16 }}
                    rows={5}
                  />
                </Col>
              </Row>
            </div>
          )}
        </React.Fragment>
      )
    })

  return (
    <>
      <Button
        ghost
        type='primary'
        onClick={() => setOpenModal(true)}
        className='mt-2 mt-md-0'
      >
        Select Post Evaluation Questionnaire
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Select Evaluation Questionnaire'
        visible={openModal}
        footer={null}
        onCancel={handleCloseModal}
        width={800}
      >
        {/* <div className="p-5 pt-2 ps-0 pe-0 d-flex flex-md-row flex-column justify-content-center align-items-md-center">
          <span className="fw-bold">Select Evaluation Question: </span>
          <select ref={selectRef} onChange={(e) => handleSelectedQuestion(e)} className="p-1 ms-md-2">
            <option value="">Select Question</option>
            {evalQuestionsList.map((question) => {
              return (
                <option key={question.id} value={`${question.id}`}>
                  {question.id}
                </option>
              )
            })}
          </select>
        </div> */}

        <div className='me-md-0'>
          <div className='carousel-inner'>
            <div className='col-10 offset-2'>
              {showOutput && (
                <>
                  <div className='d-flex justify-content-between col-md-10'>
                    <h3 className='mb-5'>Evaluation Question Preview</h3>
                    <Popconfirm
                      placement='top'
                      title={'Are you sure to delete this Question?'}
                      onConfirm={(e) =>
                        handleConfirmDelete(e, selectedEvalQuestion.id)
                      }
                      okText='Yes'
                      cancelText='No'
                    >
                      <Button className='bg-danger text-white d-flex align-items-center me-5 me-md-0'>
                        <DeleteFilled />
                        <span className='d-md-block d-none '>
                          Delete This Question
                        </span>
                      </Button>
                    </Popconfirm>
                  </div>
                  <div className='col-10 col-md-12'>{showOutput}</div>
                </>
              )}
            </div>
            <div>
              <button
                onClick={handlePrevQuestion}
                className='carousel-control-prev text-black display-1 d-flex justify-content-start'
              >
                <CaretLeftFilled />
              </button>
              <button
                onClick={handleNextQuestion}
                className='carousel-control-next text-black display-1 d-flex justify-content-end'
              >
                <CaretRightFilled />
              </button>
            </div>
          </div>
          <div className='d-flex justify-content-end gap-3 mt-5'>
            <Button onClick={handleCloseModal} className='bg-danger text-white'>
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              className='align-self-end bg-primary text-white ps-4 pe-4'
            >
              Save
            </Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default SelectEvaluationQuestion
