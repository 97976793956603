/**
 * Title: manageUser.js
 * Description: This is a file that contains the components for managing user accounts.
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Users/manageUser.js
 **/

import React, { useEffect, useState } from 'react'

import {
  CognitoIdentityProviderClient,
  AdminDisableUserCommand,
  AdminEnableUserCommand,
} from '@aws-sdk/client-cognito-identity-provider'
import {
  SecretsManagerClient,
  GetSecretValueCommand,
} from '@aws-sdk/client-secrets-manager'
import { API, graphqlOperation, Auth } from 'aws-amplify'
import { listUsers } from '../../api/queries'

import {
  Table,
  Tag,
  Button,
  Popconfirm,
  notification,
  Select,
  Input,
  Card,
} from 'antd'
import 'antd/dist/antd.css'

import ViewUser from './viewUser'

import { updateUser } from '../../api/mutations'
import listOfDepartments from '../../utilities/dept'

import moment from 'moment'
import useTableSearch from '../Home/use-table-search'

const { Search } = Input

const { Option } = Select

const departmentX = listOfDepartments()

const secret_name = 'hrm-2023@secrets'

/**
 * @async
 * @function ManageUser
 * @description Component for managing user accounts.
 * @param {Object} assignedDepartment - The assigned department for the user.
 * @returns {JSX.Element} - The rendered component.
 * @throws {Error} - If there is an error while fetching or updating user data.
 */
const ManageUser = ({ assignedDepartment }) => {
  const [employeeList, setEmployeeList] = useState([])
  const [filteredByStatusList, setFilteredByStatusList] = useState([])
  const [searchedDataList, setSearchedDataList] = useState(null)
  const [disabled, setDisabled] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const [loadingTable, setIsLoadingTable] = useState(true)

  const { getColumnSearchProps } = useTableSearch()

  /**
   * @async
   * @function getCredentials
   * @description Retrieves AWS credentials using Auth.currentCredentials() method.
   * @throws {Error} If there is an error retrieving the credentials.
   * @returns {Promise<Object>} A promise that resolves to an object containing the AWS configuration settings.
   */
  const getCredentials = async () => {
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken

      const configSet = {
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      }

      return configSet
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @async
   * @function
   * @description Handles the search users functionality.
   * @throws {Error} If an error occurs during the search process.
   * @param {string} e - The search query entered by the user.
   * @returns {Promise<void>} - A promise that resolves when the search is completed.
   */
  const handleSearchUser = (e) => {
    try {
      if (filteredByStatusList.length > 0) {
        let filterSearch = filteredByStatusList.filter((o) => {
          return Object.keys(o).some((k) => {
            if (
              k == 'name' ||
              k == 'lname' ||
              k == 'department' ||
              k == 'assignedDept' ||
              k == 'username' || // Add check for 'username' property
              (k == 'mname' && o[k] !== null) // Add check for 'mname' property
            ) {
              const searchData = o[k].toString()
              return searchData.toLowerCase().includes(e.toLowerCase())
            }
          })
        })

        setSearchedDataList(filterSearch)
      } else {
        let filterSearch = employeeList.filter((o) => {
          return Object.keys(o).some((k) => {
            if (
              k == 'name' ||
              k == 'lname' ||
              k == 'department' ||
              k == 'assignedDept' ||
              k == 'username' || // Add check for 'username' property
              (k == 'mname' && o[k] !== null) // Add check for 'mname' property
            ) {
              const searchData = o[k].toString()
              return searchData.toLowerCase().includes(e.toLowerCase())
            }
          })
        })
        setSearchedDataList(filterSearch)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @async
   * @function handleUserStatus
   * @description Handles the user status based on the provided parameter.
   * @throws {Error} If an error occurs during the process.
   * @param {string} e - The user status value.
   * @returns {void}
   */
  const handleUserStatus = (e) => {
    setSearchedDataList(null)
    setSearchValue('')
    try {
      if (e === 'All') {
        setFilteredByStatusList([])
      } else {
        let tryFilter = employeeList.filter((obj) => {
          if (obj.status === e) {
            return obj
          }
        })
        setFilteredByStatusList(tryFilter)
      }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * Fetches employee users from the server.
   *
   * @async
   * @function fetchEmployeeUser
   * @description This function retrieves a list of employee users from the server using GraphQL.
   * @throws {Error} If an error occurs during the API call.
   * @returns {Promise<void>} A promise that resolves when the employee list is updated.
   */
  const fetchEmployeeUser = async () => {
    try {
      let token = null
      let foundItems = []

      do {
        const result = await API.graphql(
          graphqlOperation(listUsers, {
            limit: 1000,
            nextToken: token,
          })
        )

        const { items, nextToken } = result.data.listUsers
        foundItems = [...foundItems, ...items]
        token = nextToken
        if (nextToken === null) {
          setIsLoadingTable(false)
        }
      } while (token)
      setEmployeeList(
        foundItems.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      )
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchEmployeeUser()
  }, [])

  /**
   * @async
   * @function handleOk
   * @description Handles the logic for updating the user account status.
   * @param {Object} e - The event object.
   * @param {string} c - The new status of the user account.
   * @throws {Error} - If an error occurs during the update process.
   * @returns {Promise<void>} - A promise that resolves when the update is successful.
   */
  const handleOk = async (e, c) => {
    setDisabled(true)

    try {
      const config = await getCredentials()
      const client = new CognitoIdentityProviderClient(config)
      const secretsClient = new SecretsManagerClient(config)
      const response = await secretsClient.send(
        new GetSecretValueCommand({
          SecretId: secret_name,
          VersionStage: 'AWSCURRENT', // VersionStage defaults to AWSCURRENT if unspecified
        })
      )

      const cognitoID = JSON.parse(response.SecretString)[
        'REACT_APP_COGNITO_ID'
      ]

      const result = await API.graphql(
        graphqlOperation(updateUser, { input: { id: e.id, status: c } })
      )

      if (result.data.updateUser) {
        if (c === 'Disabled') {
          const input = {
            UserPoolId: cognitoID,
            Username: e.username,
          }

          const command = new AdminDisableUserCommand(input)
          const response = await client.send(command)

          if (response) {
            const newRecord = result.data.updateUser

            const filteredByStatusListindex = filteredByStatusList.findIndex(
              (item) => item.id === newRecord.id
            )
            const employeeListindex = employeeList.findIndex(
              (item) => item.id === newRecord.id
            )
            const searchedDataListindex = searchedDataList.findIndex(
              (item) => item.id === newRecord.id
            )

            if (filteredByStatusListindex >= 0) {
              let filteredByStatusListNew = filteredByStatusList
              filteredByStatusListNew[filteredByStatusListindex] = newRecord
              setFilteredByStatusList(filteredByStatusListNew)
            }

            if (employeeListindex >= 0) {
              let employeeListNew = employeeList
              employeeListNew[employeeListindex] = newRecord
              setEmployeeList(employeeListNew)
            }

            if (searchedDataListindex >= 0) {
              let searchedDataListNew = [...searchedDataList]
              searchedDataListNew[searchedDataListindex] = newRecord
              setSearchedDataList(searchedDataListNew)
            }

            notification['success']({
              message: 'Success',
              description: 'You just disabled the user account.',
            })

            setDisabled(false)
          }
        } else {
          const input = {
            UserPoolId: cognitoID,
            Username: e.username,
          }

          const command = new AdminEnableUserCommand(input)
          const response = await client.send(command)

          if (response) {
            const newRecord = result.data.updateUser

            const filteredByStatusListindex = filteredByStatusList.findIndex(
              (item) => item.id === newRecord.id
            )
            const employeeListindex = employeeList.findIndex(
              (item) => item.id === newRecord.id
            )
            const searchedDataListindex = searchedDataList.findIndex(
              (item) => item.id === newRecord.id
            )

            if (filteredByStatusListindex >= 0) {
              let filteredByStatusListNew = filteredByStatusList
              filteredByStatusListNew[filteredByStatusListindex] = newRecord
              setFilteredByStatusList(filteredByStatusListNew)
            }

            if (employeeListindex >= 0) {
              let employeeListNew = employeeList
              employeeListNew[employeeListindex] = newRecord
              setEmployeeList(employeeListNew)
            }

            if (searchedDataListindex >= 0) {
              let searchedDataListNew = [...searchedDataList]
              searchedDataListNew[searchedDataListindex] = newRecord
              setSearchedDataList(searchedDataListNew)
            }

            notification['success']({
              message: 'Success',
              description: 'You just enabled the user account.',
            })

            setDisabled(false)
          }
        }
      }
    } catch (err) {
      notification['error']({
        message: 'Error',
        description:
          "Something happened when updating the employee status. Please seek system admin's help.",
      })
      console.log(err)
    }
  }

  /**
   * @description Represents an array of columns for managing users.
   * @returns {Array} An array of column objects.
   */
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <span>
          <b>{`${record.name} ${record.mname !== null ? record.mname : ''} ${
            record.lname
          }`}</b>
        </span>
      ),
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      render: (text, record) => (
        <span>
          <b>{record.username}</b>
        </span>
      ),
      sorter: (a, b) => a.username.localeCompare(b.username),
      ...getColumnSearchProps('username'),
    },
    {
      title: 'Home Department',
      dataIndex: 'department',
      key: 'department',

      filters: departmentX.sort().map((obj) => ({
        text: obj,
        value: obj,
      })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record) {
          if ('department' in record) {
            return record.department.indexOf(value) === 0
          }
        }
      },

      render: (text, record) => (
        <span color='geekblue'>
          <b>{record.department}</b>
        </span>
      ),
    },
    {
      title: 'Assigned Department',
      dataIndex: 'assignedDept',
      key: 'assignedDept',

      filters: departmentX.sort().map((obj) => ({
        text: obj,
        value: obj,
      })),
      filterMultiple: true,
      onFilter: (value, record) => {
        if (record) {
          if ('assignedDept' in record) {
            return record.assignedDept.indexOf(value) === 0
          }
        }
      },

      render: (text, record) => (
        <span color='geekblue'>
          <b>{record.assignedDept}</b>
        </span>
      ),
    },
    {
      title: 'Date created',
      dataIndex: 'date',
      key: 'locale',
      render: (text, record) => (
        <Tag color='magenta'>
          {moment(record.createdAt).format('MMMM D, YYYY h:mm:ss A')}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: '5%',
      render: (text, record) => (
        <span>
          <ViewUser data={record} />

          <Popconfirm
            title={
              record.status === 'Approved' ? (
                <div>
                  By disabling this account you were revoking their access to
                  the system. <div>Are you sure to continue?</div>
                </div>
              ) : (
                <div>
                  By enabling this account you were giving them access to the
                  system. <div>Are you sure to continue?</div>
                </div>
              )
            }
            onConfirm={(e) =>
              handleOk(
                record,
                record.status === 'Approved' ? 'Disabled' : 'Approved'
              )
            }
            placement='top'
            okText='Yes'
            cancelText='No'
          >
            <Button
              size='small'
              type={record.status === 'Approved' ? 'danger' : 'primary'}
              style={{ marginRight: '10px', marginTop: '3px' }}
              loading={disabled}
            >
              {record.status === 'Approved' ? 'Disable' : 'Approve'}
            </Button>
          </Popconfirm>
        </span>
      ),
    },
  ]

  return (
    <>
      <div className='site-layout-background' style={{ padding: 24 }}>
        <Card title='List of Employees'>
          <div
            style={{
              borderStyle: 'none',
              display: 'flex',
              justifyContent: 'end',
              marginBottom: 20,
            }}
          >
            <div className='userHeader'>
              <Select
                style={{ width: 250, marginRight: 20 }}
                onChange={(e) => handleUserStatus(e)}
                defaultValue='All'
              >
                <Option value='All'>All Users</Option>
                <Option value='Approved'>Active Users</Option>
                <Option value='Disabled'>Disabled Users</Option>
              </Select>
              <Search
                value={searchValue}
                icon='search'
                style={{ width: 250 }}
                placeholder='Search...'
                allowClear
                onSearch={(e) => handleSearchUser(e)}
                onChange={(e) => setSearchValue(e.target.value)}
              />
            </div>
          </div>
          {
            <Table
              bordered
              rowKey='id'
              dataSource={
                searchedDataList === null
                  ? filteredByStatusList.length > 0
                    ? filteredByStatusList
                    : employeeList
                  : searchedDataList
              }
              columns={columns}
              loading={loadingTable}
              pagination={{ pageSize: 50 }}
            />
          }
        </Card>
      </div>
    </>
  )
}

export default ManageUser
