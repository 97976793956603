/**
 * Title: strategicPerformanceManagementSystemAcknowledgement.js
 * Description: This is a file that contains the components for the strategic performance management system acknowledgement
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/strategicPerformanceManagementSystemAcknowledgement.js
 **/

import React, { useEffect, useState, useRef } from 'react'

import { Table, Tag, Button, Space, Input, Card } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import 'antd/dist/antd.css'

import { API, graphqlOperation } from 'aws-amplify'
import { listSpmsVideoAcknowledgements } from '../../api/queries'

import Highlighter from 'react-highlight-words'

import moment from 'moment'

export default function StrategicPerformanceManagementSystemAcknowledgement() {
  const [aknowledgementList, setAknowledgementList] = useState([])
  const [filteredAknowledgement, setFilteredAknowledgement] = useState([])
  const [selectedAknowledgementPart, setSelectedAknowledgementPart] =
    useState('all')
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const searchInput = useRef(null)

  const [tableIsLoading, setTableIsLoading] = useState(true)

  const uniqueVideoNames = [
    ...new Set(aknowledgementList.map((item) => item.videoName)),
  ]

  const fetchSpmsVideoList = async () => {
    try {
      let nextToken = null
      let allResults = []

      do {
        const result = await API.graphql(
          graphqlOperation(listSpmsVideoAcknowledgements, { nextToken })
        )

        allResults = allResults.concat(
          result.data.listSpmsVideoAcknowledgements.items
        )
        nextToken = result.data.listSpmsVideoAcknowledgements.nextToken
      } while (nextToken)

      const spmsVideosList = allResults.filter((item) => {
        if (item.videoName.includes('SPMS Part')) {
          return item
        }
      })

      spmsVideosList.sort((a, b) =>
        moment(a.updatedAt).diff(moment(b.updatedAt))
      )

      setAknowledgementList(spmsVideosList)
      setFilteredAknowledgement(spmsVideosList)
      setTableIsLoading(false)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    fetchSpmsVideoList()
  }, [])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm()
    setSearchText(selectedKeys[0])
    setSearchedColumn(dataIndex)
  }

  // Reset name filter
  const handleReset = (clearFilters) => {
    clearFilters()
    setSearchText('')
  }

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            className='d-flex align-items-center justify-content-center'
            type='primary'
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              confirm({
                closeDropdown: false,
              })
              setSearchText(selectedKeys[0])
              setSearchedColumn(dataIndex)
            }}
          >
            Filter
          </Button>
          <Button
            type='link'
            size='small'
            onClick={() => {
              close()
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100)
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  })

  const columns = [
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      key: 'employeeName',
      className: 'col-3',
      sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
      ...getColumnSearchProps('employeeName'),
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
      className: 'col-5',
      sorter: (a, b) => a.department.localeCompare(b.department),
    },
    {
      title: "Date of SPMS Video's Access",
      dataIndex: 'date',
      key: 'locale',
      className: 'col-3',
      sorter: (a, b) => new Date(a.date) - new Date(b.date),
      render: (text, record) => (
        <Tag color='geekblue'>
          {new Date(record.createdAt).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          })}{' '}
          {new Date(record.createdAt).toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
          })}
        </Tag>
      ),
    },
  ]

  if (selectedAknowledgementPart === 'all') {
    columns.splice(2, 0, {
      title: 'SPMS Video',
      dataIndex: 'videoName',
      key: 'videoName',
      className: 'col-2',
      sorter: (a, b) => a.videoName.localeCompare(b.videoName),
    })
  }

  const handleFilterAknowledgement = () => {
    if (selectedAknowledgementPart === 'all') {
      setFilteredAknowledgement(aknowledgementList)
    }
    if (selectedAknowledgementPart === 'SPMS Part 1.mp4') {
      const part1 = aknowledgementList.filter((item) => {
        return item.videoName === 'SPMS Part 1.mp4'
      })

      setFilteredAknowledgement(part1)
    }
    if (selectedAknowledgementPart === 'SPMS Part 2.mp4') {
      const part1 = aknowledgementList.filter((item) => {
        return item.videoName === 'SPMS Part 2.mp4'
      })

      setFilteredAknowledgement(part1)
    }
    if (selectedAknowledgementPart === 'SPMS Part 3.mp4') {
      const part1 = aknowledgementList.filter((item) => {
        return item.videoName === 'SPMS Part 3.mp4'
      })

      setFilteredAknowledgement(part1)
    }
    if (selectedAknowledgementPart === 'SPMS Part 4.mp4') {
      const part1 = aknowledgementList.filter((item) => {
        return item.videoName === 'SPMS Part 4.mp4'
      })

      setFilteredAknowledgement(part1)
    }
    if (selectedAknowledgementPart === 'SPMS Part 5.mp4') {
      const part1 = aknowledgementList.filter((item) => {
        return item.videoName === 'SPMS Part 5.mp4'
      })

      setFilteredAknowledgement(part1)
    }
  }

  useEffect(() => {
    handleFilterAknowledgement()
  }, [selectedAknowledgementPart])

  return (
    <div className='site-layout-background' style={{ padding: 24 }}>
      <Card title='List of Employees Access Strategic Performance Management System Videos'>
        <div className='d-flex justify-content-center flex-wrap mb-4'>
          <Button
            type={selectedAknowledgementPart === 'all' ? 'primary' : 'default'}
            style={{ marginRight: 10 }}
            onClick={() => setSelectedAknowledgementPart('all')}
          >
            All
          </Button>
          {uniqueVideoNames.sort().map((part) => (
            <Button
              key={part}
              type={part === selectedAknowledgementPart ? 'primary' : 'default'}
              style={{ marginRight: 10 }}
              onClick={() => setSelectedAknowledgementPart(part)}
              className='mb-3 mb-md-0'
            >
              {part.replace('.mp4', '')}
            </Button>
          ))}
        </div>

        <Table
          loading={tableIsLoading}
          rowKey={'id'}
          bordered
          dataSource={filteredAknowledgement}
          columns={columns}
          pagination={{
            pageSize: 10,
            className: 'd-flex justify-content-center',
          }}
        />
      </Card>
    </div>
  )
}
