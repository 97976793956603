/**
 * Title: siteFeedback.js
 * Description: This is a file that contains the components for the site feedback
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/siteFeedback.js
 **/

import React, { useState } from 'react'

import ReCAPTCHA from 'react-google-recaptcha'

import { Row, Col, Button, Input, notification } from 'antd'
import { PhoneOutlined, InboxOutlined, GlobalOutlined } from '@ant-design/icons'

const { TextArea } = Input

const {
  REACT_APP_SEND_HR_EMAIL,
  REACT_APP_RECAPTCHA_KEY,
  REACT_APP_TOKEN_GENERATOR_LINK,
  REACT_APP_TOKEN_GENERATOR_KEY,
  REACT_APP_SEND_EMAIL,
} = process.env

export default function ContactUs() {
  const [username, setUsername] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [message, setMessage] = useState('')
  const [captcha, setCaptcha] = useState('')

  const handleUsername = (value) => {
    setUsername(value)
  }

  const handleUserEmail = (value) => {
    setUserEmail(value)
  }

  const handleMessage = (value) => {
    setMessage(value)
  }

  const handleRecaptcha = (value) => {
    setCaptcha(value)
  }

  const grecaptchaObject = window.grecaptcha

  const getToken = async () => {
    try {
      const result = await fetch(REACT_APP_TOKEN_GENERATOR_LINK, {
        method: 'POST',
        headers: {
          security_token: REACT_APP_TOKEN_GENERATOR_KEY,
        },
      })

      result
        .json()
        .then(async (resOne) => {
          if (captcha) {
            if (
              captcha.length &&
              username.length &&
              userEmail.length &&
              message.length
            ) {
              const bodyOfEmail = `Sender name: ${username}, Sender Email address: ${userEmail}, Sender Message: ${message}`

              const apiSend = {
                access_token: resOne.access_token,
                subject: 'Site Feedback, Comments and Suggestions',
                body: JSON.stringify(bodyOfEmail),
                to: REACT_APP_SEND_HR_EMAIL,
              }

              try {
                const dataAPI = await fetch(REACT_APP_SEND_EMAIL, {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  method: 'POST',
                  body: JSON.stringify(apiSend),
                })

                const newData = await dataAPI.json()

                if (newData.status_code == 'GC0') {
                  notification['success']({
                    message: 'Success',
                    description: 'Message sent. Thank you!',
                  })
                  setUsername('')
                  setUserEmail('')
                  setMessage('')
                  setCaptcha('')
                  grecaptchaObject.reset()
                } else {
                  notification['error']({
                    message: 'Error',
                    description:
                      'Error sending your message. Please contact system administrator.',
                  })
                }
              } catch (err) {
                console.log(err)
                notification['error']({
                  message: 'Error',
                  description:
                    'Error sending your message. Please contact system administrator.',
                })
              }
            } else {
              notification['error']({
                message: 'Error',
                description: 'Please check the fields that cannot be empty.',
              })
            }
          }
        })
        .catch((err) => {
          console.log(err)
        })
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description:
          'Error sending your message. Please contact the system administrator.',
      })
    }
  }

  return (
    <>
      <Row>
        <Col span={2}></Col>
        <Col span={10}>
          <Row className='contact-us-title'>
            <p>We would like to hear your feedback!</p>
          </Row>
          <div className='dividerLine'></div>
          <Row className='contact-us-row'>
            <Col className='contact-us-col' span={24}>
              <label htmlFor='user_name'>Your Name (required)</label>
              <br />
              <Input
                value={username}
                onChange={(e) => handleUsername(e.target.value)}
                required
                type='text'
                name='user_name'
              />
            </Col>
            <Col className='contact-us-col' span={24}>
              <label htmlFor='user_email'>Your Email (required)</label>
              <br />
              <Input
                value={userEmail}
                onChange={(e) => handleUserEmail(e.target.value)}
                type='email'
                name='user_email'
              />
            </Col>
            <Col className='contact-us-col' span={24}>
              <label>Message</label>
              <br />
              <TextArea
                value={message}
                onChange={(e) => handleMessage(e.target.value)}
                autoSize={{ minRows: 4, maxRows: 4 }}
                name='message'
              />
            </Col>
            <Col className='contact-us-col' span={24}>
              <Button
                disabled={captcha && captcha.length ? false : true}
                onClick={getToken}
                type='primary'
                block
              >
                SEND
              </Button>
            </Col>
            <Col className='contact-us-col' span={24}>
              <ReCAPTCHA
                sitekey={REACT_APP_RECAPTCHA_KEY}
                onChange={handleRecaptcha}
                grecaptcha={grecaptchaObject}
              />
            </Col>
          </Row>
        </Col>
        <Col className='contact-us-details' span={10}>
          <div className='contact-us-blank'></div>
          <Row>
            <a className='contact-us-anchors' href='tel:+63 2 8988-4242'>
              <PhoneOutlined
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '8px',
                }}
              />{' '}
              {' +63 2 8988-4242'}
            </a>
          </Row>
          <Row>
            <a
              className='contact-us-anchors'
              href='mailto:shru@quezoncity.gov.ph'
            >
              <InboxOutlined
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '8px',
                }}
              />{' '}
              {'shru@quezoncity.gov.ph'}
            </a>
          </Row>
          <Row>
            <a className='contact-us-anchors' href='https://quezoncity.gov.ph/'>
              <GlobalOutlined
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '8px',
                }}
              />{' '}
              {' www.quezoncity.gov.ph'}
            </a>
          </Row>
        </Col>
        <Col span={2}></Col>
      </Row>

      {/*<span>FAQs Here</span>*/}
    </>
  )
}
