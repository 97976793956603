/**
 * Title: uploadTrainingVideo.js
 * Description: This is a file that contains the components that shows the uploaded training videos
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/uploadTrainingVideo.js
 **/

import { useState, useEffect, useRef } from 'react'

import { API, graphqlOperation, Auth } from 'aws-amplify'
import { listTrainingVideos } from '../../api/queries'
import { deleteTrainingVideo } from '../../api/mutations'

import { S3Client, DeleteObjectCommand } from '@aws-sdk/client-s3'

import moment from 'moment'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
} from '@ant-design/icons'

import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  Row,
  Col,
} from 'antd'
import VideoPlayer from './videoPlayer'

const UploadedTrainingVideo = ({ refresh }) => {
  const [uploadedTrainingVideo, setUploadedTrainingVideo] = useState([])

  useEffect(() => {
    fetchUploadedTrainingVideo()
  }, [refresh])

  const fetchUploadedTrainingVideo = async () => {
    try {
      const uploadedTrainingVid = await API.graphql(
        graphqlOperation(listTrainingVideos)
      )

      const uploadedTrainingVidList =
        uploadedTrainingVid.data.listTrainingVideos.items
      setUploadedTrainingVideo(
        uploadedTrainingVidList.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt)
        })
      )
    } catch (error) {
      console.log(error)
    }
  }

  const handleConfirmDelete = async (e, rId, key) => {
    e.preventDefault()
    try {
      const credentials = await Auth.currentCredentials()
      const accessKeyId = credentials.accessKeyId
      const secretAccessKey = credentials.secretAccessKey
      const sessionToken = credentials.sessionToken
      const s3 = new S3Client({
        region: 'ap-southeast-1',
        credentials: {
          accessKeyId: accessKeyId,
          secretAccessKey: secretAccessKey,
          sessionToken: sessionToken,
        },
      })
      const params = {
        Bucket: 'hrmd-training-videos',
        Key: key,
      }
      const command = new DeleteObjectCommand(params)
      const response = await s3.send(command)
      if (response) {
        const status = await API.graphql(
          graphqlOperation(deleteTrainingVideo, {
            input: {
              id: rId,
            },
          })
        )
        if (status) {
          notification['success']({
            message: 'Success',
            description: 'Your File is successfully deleted.',
          })
          fetchUploadedTrainingVideo()
        }
      }
    } catch (error) {
      console.log(error)

      notification['error']({
        message: 'Error',
        description:
          'Delete Video failed. Please reach out to System Admin for assistance.',
      })
    }
  }

  const columns = [
    {
      title: 'Date Uploaded',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (date) => moment(date).format('YYYY/MM/DD'),
    },
    {
      title: 'File Name',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: '',
      key: 'action',
      width: '5%',
      render: (text, record) => (
        <span>
          <div className='d-flex flex-row-reverse'>
            <div className='p-2'>
              <Popconfirm
                placement='top'
                title={'Are you sure to delete this task?'}
                onConfirm={(e) => handleConfirmDelete(e, record.id, record.key)}
                okText='Yes'
                cancelText='No'
              >
                <Button
                  size='medium'
                  style={{ marginLeft: '0px', marginTop: '3px' }}
                >
                  <DeleteFilled />
                </Button>
              </Popconfirm>
            </div>
            <div className='p-2'>
              <VideoPlayer videoKey={record.key} uploadedVid={true} />
            </div>
          </div>
        </span>
      ),
    },
  ]

  return (
    <Card title='List of Uploaded Training Videos'>
      <Table
        bordered
        columns={columns}
        dataSource={uploadedTrainingVideo}
        rowKey='id'
        className='table-responsive'
        pagination={{
          position: ['bottomCenter'],
          style: { textAlign: 'center' },
        }}
      />
    </Card>
  )
}

export default UploadedTrainingVideo
