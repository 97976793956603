/**
 * Title: uploadOnlineTrainings.js
 * Description: This is a file that contains the components for uploading online trainings
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/uploadOnlineTrainings.js
 * Changes Made:
 * - 2024.09.08 | Zil Valdez | Added a specific max character length to inputs
 * - 2024.14.08 | Zil Valdez | Fix sonarqube security review issue related to HTML text sanitation
 * - 2024.09.23 | Zil Valdez | Add a field to set if passing score for the post-test
 **/

import React, { useState, useEffect, useRef } from 'react'

import {
  Modal,
  Row,
  Col,
  notification,
  Input,
  Select,
  TimePicker,
  DatePicker as AntdDatePicker,
  Button,
} from 'antd'
import { PlusOutlined, CloseOutlined, CheckOutlined } from '@ant-design/icons'

import { Auth, API, graphqlOperation } from 'aws-amplify'
import {
  createOnlineTrainingAnnouncement,
  updateOnlineTrainingAnnouncement,
} from '../../api/mutations'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import tippy from 'tippy.js'
import 'tippy.js/dist/tippy.css'

import moment from 'moment'

import ManageTrainingsNumberOfParticipants from './manageTrainingsNumberOfParticipants'
import EvaluationMaker from './evaluationMaker.js'
import AssessmentMaker from './assessmentMaker'
import EndorsementLetter from './endorsementLetter'
import SelectEvaluationQuestion from './selectEvaluationQuestion'
import SelectAssessmentQuestion from './selectAssessmentQuestion'

import DatePicker from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'
import ActivityQuestionMaker from './ActivityQuestionMaker.js'
import SelectActivityQuestions from './SelectActivityQuestions.js'
import UploadOnlineeTrainingsPreview from './uploadOnlnieTrainingsPreview.js'
import TextArea from 'antd/lib/input/TextArea.js'
import { stripHTML } from '../../utilities/utils.js'

/**
 * Component for uploading online trainings.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.visibleAnnouncementOnline - Flag indicating if the announcement is visible.
 * @param {function} props.setVisibleAnnouncementOnline - Function to set the visibility of the announcement.
 * @param {function} props.fetchTrainings - Function to fetch trainings.
 * @param {function} props.getAllRegistrations - Function to get all registrations.
 * @param {function} props.getAllAnnouncements - Function to get all announcements.
 * @param {Array} props.otherFiles - Array of other files.
 * @param {Array} props.departments - Array of departments.
 * @param {function} props.setListOfDepartments - Function to set the list of departments.
 * @param {Object} props.uploadedTrainingVideo - Uploaded training video.
 * @returns {JSX.Element} - The rendered component.
 */
export default function UploadOnlineTrainings({
  visibleAnnouncementOnline,
  setVisibleAnnouncementOnline,
  fetchTrainings,
  getAllRegistrations,
  getAllAnnouncements,
  otherFiles,
  departments,
  setDepartments,
  setListOfDepartments,
  uploadedTrainingVideo,
  userData,
  listOfDepartmentsOrignal,
}) {
  const [announceUpload, setAnnounceUpload] = useState([])
  const [speakerUpload, setSpeakerUpload] = useState([])
  const [announceDate, setAnnounceDate] = useState(null)
  const [announceStartTime, setAnnounceStartTime] = useState(null)
  const [announceEndTime, setAnnouceEndTime] = useState(null)
  const [tpData, setTpData] = useState(null)
  const [cdData, setCdData] = useState(null)
  const [courseTitle, setCourseTitle] = useState(null)
  const [courseCategory, setCourseCategory] = useState([])
  const [optionSubCategory, setOptionSubCategory] = useState(null)
  const [courseSubCategory, setCourseSubCategory] = useState(null)

  const [allAnnouncements, setAllAnnouncements] = useState([])
  const [allRegistrations, setAllRegistrations] = useState([])

  const [courses, setCourses] = useState([])
  const [viewCourse, setViewCourse] = useState(null)
  const [openView, setOpenView] = useState(false)
  const [isOpenRegistration, setIsOpenRegistration] = useState(false)

  const [pTDateOpen, setPTDateOpen] = useState(null)
  const [pTTimeOpen, setPTTimeOpen] = useState(null)
  const [pTDateClose, setPTDateClose] = useState(null)
  const [pTTimeClose, setPTTimeClose] = useState(null)

  const [showPostAssessment, setShowPostAssessment] = useState(false)

  const [participantLimit, setParticipantLimit] = useState('')

  const [getEvalID, setGetEvalID] = useState(
    'd184c688-31d9-4c77-92a5-e0abae9c4e49'
  )

  const [isTestEvent, setIsTestEvent] = useState(false)

  const [getAssessmentID, setGetAssessmentID] = useState(null)

  const [endorsementLetterID, setEndorsementLetterID] = useState(null)

  const [registrationLimitPerDept, setRegistrationLimitPerDept] = useState({})

  const [departmentsLimitValue, setDepartmentsLimitValue] = useState({})

  const [textDisplayBeforeTrainingOpen, setTextDisplayBeforeTrainingOpen] =
    useState(null)

  const [manageParticipantsModal, setManageParticipantsModal] = useState(false)
  const [trainings, setTrainings] = useState([])
  const [isLink, setIsLink] = useState(false)
  const [selectedTraining, setSelectedTraining] = useState(null)
  const [isEndorsementLetterRequired, setIsEndorsementLetterRequired] =
    useState(null)
  const [isSeminar, setIsSeminar] = useState(false)

  /**
   * Options for selecting a department.
   * @type {Array<{ value: string, label: string, disabled: boolean }>}
   */
  const departmentOptions = [
    {
      value: '',
      label: 'Select department',
      disabled: true,
    },
    ...(departments || []).map((dept) => ({
      value: dept,
      label: dept,
    })),
  ]

  const [listOfTrainings, setListOfTrainings] = useState([
    {
      value: '',
      date: '',
      label: 'Select training',
      title: 'Select training',
      disabled: true,
    },
  ])

  const [chosenFiles, setChosenFiles] = useState([])
  const [chosenTrainingVideos, setChosenTrainingVideos] = useState(null)
  const [sectionLength, setSectionLength] = useState([false])
  const [sectionTitle, setSectionTitle] = useState([])
  const [activityQuestions, setActivityQuestions] = useState(null)
  const [videoTranscript, setVideoTranscript] = useState(null)

  const [isPassPostAssessmentRequired, setIsPassPostAssessmentRequired] =
    useState(false)
  const [postAssessmentScore, setPreAssessmentScore] = useState(0)

  /**
   * Array of sub-categories for online trainings.
   * @type {Array<{value: string, label: string, disabled?: boolean}>}
   */
  const subCategCore = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Public Service Ethics & Accountability Orientation & Work Ethics',
      label: 'Public Service Ethics & Accountability Orientation & Work Ethics',
    },
    {
      value:
        'Administrative Discipline: Orientation of Values & Ethics in the Workplace',
      label:
        'Administrative Discipline: Orientation of Values & Ethics in the Workplace',
    },
    {
      value: 'Social Media Responsibility',
      label: 'Social Media Responsibility',
    },
    {
      value: 'Customer Service Satisfaction',
      label: 'Customer Service Satisfaction',
    },
  ]

  /**
   * Array of sub-categories for leadership training programs.
   * @type {Array<{value: string, label: string, disabled?: boolean}>}
   */
  const subCategLeader = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Leadership & Management Program',
      label: 'Leadership & Management Program',
    },
    {
      value: 'Conflict Management',
      label: 'Conflict Management',
    },
    {
      value: 'Effective Management & Supervision',
      label: 'Effective Management & Supervision',
    },
    {
      value: 'Total Quality Management',
      label: 'Total Quality Management',
    },
    {
      value: 'Project Management',
      label: 'Project Management',
    },
    {
      value: 'Culture Building',
      label: 'Culture Building',
    },
    {
      value: 'Coaching & Mentoring',
      label: 'Coaching & Mentoring',
    },
    {
      value: 'Managing Teams & People',
      label: 'Managing Teams & People',
    },
    {
      value: 'Expanded Team Building',
      label: 'Expanded Team Building',
    },
    {
      value: 'Strategic Management Planning',
      label: 'Strategic Management Planning',
    },
    {
      value: 'Goal and Metrics Setting',
      label: 'Goal and Metrics Setting',
    },
    {
      value: 'Supervisory Development Course',
      label: 'Supervisory Development Course',
    },
    {
      value: 'Change Management',
      label: 'Change Management',
    },
  ]

  /**
   * Array of subcategories for online trainings.
   * @type {Array<{value: string, label: string, disabled?: boolean}>}
   */
  const subCategOrg = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Business/Technical Writing Skills',
      label: 'Business/Technical Writing Skills',
    },
    {
      value: 'English Proficiency & Effective Communication',
      label: 'English Proficiency & Effective Communication',
    },
    {
      value: 'Effective Presentation',
      label: 'Effective Presentation',
    },
    {
      value: 'Public Speaking',
      label: 'Public Speaking',
    },
    {
      value: 'Personality Development in Local Government',
      label: 'Personality Development in Local Government',
    },
    {
      value: 'Time Management',
      label: 'Time Management',
    },
    {
      value: 'Obtaining and Providing Positive Work Attitude',
      label: 'Obtaining and Providing Positive Work Attitude',
    },
    {
      value: 'Strategic HRM: Providing Assistance to Planning and Goal Setting',
      label: 'Strategic HRM: Providing Assistance to Planning and Goal Setting',
    },
    {
      value: 'Project Management',
      label: 'Project Management',
    },
    {
      value: 'Data Management & Analysis/Chart',
      label: 'Data Management & Analysis/Chart',
    },
    {
      value: 'Data Privacy',
      label: 'Data Privacy',
    },
    {
      value: 'Critical Thinking',
      label: 'Critical Thinking',
    },
    {
      value: 'Innovative Thinking',
      label: 'Innovative Thinking',
    },
    {
      value: 'Design Thinking',
      label: 'Design Thinking',
    },
  ]

  /**
   * Array of subcategories for functional programs.
   * @type {Array<{value: string, label: string, disabled?: boolean}>}
   */
  const subCategFunctional = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Program One',
      label: 'Program One',
    },
    {
      value: 'Program Two',
      label: 'Program Two',
    },
    {
      value: 'Program Three',
      label: 'Program Three',
    },
    {
      value: 'Program Four',
      label: 'Program Four',
    },
  ]

  /**
   * Additional subcategories for online trainings.
   * @type {Array<{value: string, label: string, disabled?: boolean}>}
   */
  const subCategAdditional = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Program One',
      label: 'Program One',
    },
    {
      value: 'Program Two',
      label: 'Program Two',
    },
    {
      value: 'Program Three',
      label: 'Program Three',
    },
    {
      value: 'Program Four',
      label: 'Program Four',
    },
  ]

  useEffect(() => {
    if (courseCategory == 'CORE COMPETENCIES') {
      setOptionSubCategory(subCategCore)
      setCourseSubCategory(null)
    } else if (courseCategory == 'ORGANIZATIONAL COMPETENCIES') {
      setOptionSubCategory(subCategOrg)
      setCourseSubCategory(null)
    } else if (courseCategory == 'LEADERSHIP COMPETENCIES') {
      setOptionSubCategory(subCategLeader)
      setCourseSubCategory(null)
    } else if (courseCategory == 'FUNCTIONAL/TECHNICAL') {
      setOptionSubCategory(subCategFunctional)
      setCourseSubCategory(null)
    } else if (courseCategory == 'Additional Training Programs') {
      setOptionSubCategory(subCategAdditional)
      setCourseSubCategory(null)
    } else {
      setOptionSubCategory([])
    }
  }, [courseCategory])

  const showUploadNames = announceUpload.map((o, index) => {
    return (
      <span className={'fw-bold'} key={index}>
        {o.name}
      </span>
    )
  })

  const showUploadspeaker = speakerUpload.map((o, index) => {
    return (
      <span className={'fw-bold'} key={index}>
        {o.name}
      </span>
    )
  })

  /**
   * Closes the upload public materials modal and resets the state variables.
   */
  const closeUploadPubMat = () => {
    setVisibleAnnouncementOnline(false)
    setAnnounceUpload([])
    setSpeakerUpload([])
    setAnnounceDate(null)
    setTpData(null)
    setCdData(null)
    setCourseTitle(null)
    setParticipantLimit('')
    setPTDateOpen(null)
    setPTTimeOpen(null)
    setPTDateClose(null)
    setPTTimeClose(null)
    setActivityQuestions(null)
  }

  function compareOptions(optionA, optionB) {
    const dateA = new Date(optionA.date)
    const dateB = new Date(optionB.date)
    if (dateA > dateB) {
      return -1
    }
    if (dateA < dateB) {
      return 1
    }
    return 0
  }
  /**
   * Handles the cancellation of the upload process.
   */
  const handleCancel = () => {
    setVisibleAnnouncementOnline(false)
    setAnnounceUpload([])
    setSpeakerUpload([])
    setAnnounceDate(null)
    setTpData(null)
    setCdData(null)
    setCourseTitle(null)
    setShowPostAssessment(false)
    setIsPassPostAssessmentRequired(false)
    setPreAssessmentScore(0)
    setSelectedTraining(null)
    setIsLink(false)
    setIsEndorsementLetterRequired(null)
    setListOfTrainings([
      {
        value: '',
        date: '',
        label: 'Select training',
        title: 'Select training',
        disabled: true,
      },
    ])
    setChosenFiles([])
    setChosenTrainingVideos(null)
    setSectionLength([false])
    setSectionTitle(null)
    setParticipantLimit('')
    setEndorsementLetterID(null)
    setDepartmentsLimitValue({})
    setPTDateOpen(null)
    setPTTimeOpen(null)
    setPTDateClose(null)
    setPTTimeClose(null)
    setActivityQuestions(null)
  }

  /**
   * Checks if two time values are valid based on a specified time format.
   * @param {string} a - The first time value to check.
   * @param {string} b - The second time value to check.
   * @returns {boolean} - True if both time values are valid, false otherwise.
   */
  const checkTimeIfValid = (a, b) => {
    const timeFormat = 'h:mm A'
    const parsedTimeA = moment(a, timeFormat)
    const parsedTimeB = moment(b, timeFormat)
    const isValidTimeA = parsedTimeA.isValid() // returns true if valid, false if not
    const isValidTimeB = parsedTimeB.isValid() // returns true if valid, false if not

    if (isValidTimeA && isValidTimeB) {
      return true
    } else {
      return false
    }
  }

  /**
   * Handles the submission of the online training form.
   * @param {Event} e - The form submission event.
   * @returns {Promise<void>} - A promise that resolves when the submission is successful.
   */
  const handleSubmitTraining = async (e) => {
    e.preventDefault()

    if (courseSubCategory && courseCategory) {
      if (
        (showPostAssessment && getAssessmentID) ||
        !showPostAssessment ||
        showPostAssessment === 'false'
      ) {
        if (
          announceDate &&
          checkTimeIfValid(announceStartTime, announceEndTime)
        ) {
          if (
            moment(`${pTDateOpen} ${pTTimeOpen}`).isValid() == true &&
            moment(`${pTDateClose} ${pTTimeClose}`).isValid() == true
          ) {
            if (participantLimit >= 1) {
              if (cdData && tpData) {
                try {
                  const credentials = await Auth.currentCredentials()
                  const accessKeyId = credentials.accessKeyId
                  const secretAccessKey = credentials.secretAccessKey
                  const sessionToken = credentials.sessionToken

                  const s3 = new S3Client({
                    region: 'ap-southeast-1',
                    credentials: {
                      accessKeyId: accessKeyId,
                      secretAccessKey: secretAccessKey,
                      sessionToken: sessionToken,
                    },
                  })

                  const announceUploadPromises = announceUpload.map((value) => {
                    return new Promise(function (resolve, reject) {
                      const params = {
                        Bucket: 's3etnahris133956-dev',
                        Key: `uploadedByAdmin/${value.name}`,
                        Body: value,
                      }

                      const command = new PutObjectCommand(params)
                      const uploadedByAdmin = s3.send(command)
                      const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`
                      resolve(url)
                    })
                  })

                  const speakerUploadPromises = speakerUpload.map((value) => {
                    return new Promise(function (resolve, reject) {
                      const params = {
                        Bucket: 's3etnahris133956-dev',
                        Key: `uploadedByAdmin/${value.name}`,
                        Body: value,
                      }

                      const command = new PutObjectCommand(params)
                      const uploadedByAdmin = s3.send(command)
                      const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`
                      resolve(url)
                    })
                  })

                  Promise.all([
                    ...announceUploadPromises,
                    ...speakerUploadPromises,
                  ])
                    .then(async (res) => {
                      const locations = res[0]
                      const speakerLocations = res[1]

                      const input = {
                        title: courseTitle,
                        date: announceDate[0],
                        arrayOfDates: JSON.stringify(announceDate),
                        trainingTimeStart: announceStartTime,
                        trainingTimeEnd: announceEndTime,
                        location: JSON.stringify([locations]),
                        resourceSpeakerDetails: JSON.stringify([
                          speakerLocations,
                        ]),
                        courseDescription: cdData,
                        targetParticipants: tpData,
                        isOpenRegistration: true,
                        postTrainOpen: moment(
                          `${pTDateOpen} ${pTTimeOpen}`
                        ).format('ddd MMM D YYYY hh:mm:ss a'),
                        postTrainClose: moment(
                          `${pTDateClose} ${pTTimeClose}`
                        ).format('ddd MMM D YYYY hh:mm:ss a'),
                        courseCategory: courseCategory,
                        courseSubCategory: courseSubCategory,
                        hasAssessment: showPostAssessment,
                        participantLimit,
                        evalID: getEvalID,
                        isTestEvent: isTestEvent,
                        assessmentID: getAssessmentID,
                        stepThreeID: endorsementLetterID,
                        limitPerDept: JSON.stringify([departmentsLimitValue]),
                        parentTrainingID: selectedTraining,
                        isEndorsementRequired: isEndorsementLetterRequired
                          ? isEndorsementLetterRequired
                          : 'no',
                        sectionTitle: JSON.stringify(sectionTitle),
                        additionalFiles: JSON.stringify(chosenFiles),
                        sectionVideos: JSON.stringify(chosenTrainingVideos),
                        sectionQuestions: JSON.stringify(activityQuestions),
                        additionalTexts: textDisplayBeforeTrainingOpen,
                        videoTranscripts: JSON.stringify(videoTranscript),
                        isSeminar,
                        isPassPostAssessmentRequired:
                          isPassPostAssessmentRequired,
                        postAssessmentScore: postAssessmentScore,
                      }

                      const adminUpload = await API.graphql(
                        graphqlOperation(createOnlineTrainingAnnouncement, {
                          input,
                        })
                      )

                      if (adminUpload) {
                        if (selectedTraining) {
                          const newTrainingId =
                            adminUpload.data.createOnlineTrainingAnnouncement.id
                          let existingTrainingLinked = trainings
                            .filter((x) => x.id === selectedTraining)
                            .map((x) =>
                              JSON.parse(x.linkedTrainingAnnouncementIDs)
                            )[0]

                          existingTrainingLinked =
                            existingTrainingLinked != null
                              ? existingTrainingLinked
                              : []

                          await API.graphql(
                            graphqlOperation(updateOnlineTrainingAnnouncement, {
                              input: {
                                id: selectedTraining,
                                linkedTrainingAnnouncementIDs: JSON.stringify([
                                  ...existingTrainingLinked,
                                  newTrainingId,
                                ]),
                              },
                            })
                          )
                        }

                        notification['success']({
                          message: 'Success',
                          description: 'Training announcement created.',
                        })
                        setVisibleAnnouncementOnline(false)
                        setAnnounceUpload([])
                        setSpeakerUpload([])
                        setAnnounceDate(null)
                        setTpData(null)
                        setCdData(null)
                        setCourseTitle(null)

                        setChosenFiles([])
                        setChosenTrainingVideos(null)
                        setSectionLength([false])
                        setSectionTitle(null)
                        setParticipantLimit('')
                        getAllAnnouncements()
                        fetchTrainings()
                        setEndorsementLetterID(null)
                        setDepartmentsLimitValue({})
                        setPTDateOpen(null)
                        setPTTimeOpen(null)
                        setPTDateClose(null)
                        setPTTimeClose(null)
                        setActivityQuestions(null)
                      }
                    })
                    .catch((err) => console.log(err))
                } catch (error) {
                  console.log(error)
                }
              } else {
                notification['error']({
                  message: 'Error',
                  description:
                    'Online Training Description and/or Target Participants cnnot be empty.',
                })
              }
            } else {
              notification['error']({
                message: 'Error',
                description: 'Participant Limit cannot be Zero.',
              })
            }
          } else {
            notification['error']({
              message: 'Error',
              description:
                'Please enter correctly the Post Training Opening and Closing Dates/Times.',
            })
          }
        } else {
          notification['error']({
            message: 'Error',
            description:
              'Please enter correctly the Training Date/s and Times.',
          })
        }
      } else {
        notification['error']({
          message: 'Error',
          description: 'Please select or create assesstment.',
        })
      }
    } else {
      notification['error']({
        message: 'Error',
        description:
          'Both Online Training Category and Online Training Sub-category are required.',
      })
    }
  }

  /**
   * Handles the selected dates and formats them to YYYY-MM-DD format.
   * @param {Array<Date>} selectedDates - The selected dates.
   */
  const handleDate = (selectedDates) => {
    const formattedDates = selectedDates.map((date) =>
      date.format('YYYY-MM-DD')
    )

    setAnnounceDate(formattedDates)
  }

  /**
   * Sets the text display before training open.
   * @param {string} content - The content to be set as the text display.
   */
  const handleAdditionalText = (content) => {
    setTextDisplayBeforeTrainingOpen(content)
  }

  /**
   * Sets the announce start time for the training.
   * @param {Date} time - The selected time.
   * @param {string} timeString - The selected time as a string.
   */
  const handleTrainingStartTime = (time, timeString) => {
    setAnnounceStartTime(timeString)
  }

  /**
   * Sets the announcement end time for a training.
   * @param {Date} time - The selected time.
   * @param {string} timeString - The formatted time string.
   */
  const handleTrainingEndTime = (time, timeString) => {
    setAnnouceEndTime(timeString)
  }

  /**
   * Sets the post train open date.
   * @param {Date} date - The selected date.
   * @param {string} dateString - The selected date as a string.
   */
  const handlePostTrainOpenDate = (date, dateString) => {
    setPTDateOpen(dateString)
  }

  /**
   * Sets the PTTimeOpen state with the provided time string.
   *
   * @param {Date} time - The selected time.
   * @param {string} timeString - The formatted time string.
   */
  const handlePostTrainOpenTime = (time, timeString) => {
    setPTTimeOpen(timeString)
  }

  /**
   * Sets the post train close date.
   * @param {Date} date - The selected date.
   * @param {string} dateString - The selected date as a string.
   */
  const handlePostTrainCloseDate = (date, dateString) => {
    setPTDateClose(dateString)
  }

  /**
   * Sets the post train close time.
   *
   * @param {Date} time - The selected time.
   * @param {string} timeString - The formatted time string.
   */
  const handlePostTrainCloseTime = (time, timeString) => {
    setPTTimeClose(timeString)
  }

  /**
   * Sets the target participants for online trainings.
   * @param {any} content - The content to set as target participants.
   */
  const handleTargetParticipants = (content) => {
    setTpData(content)
  }

  /**
   * Sets the course description data.
   * @param {string} content - The content of the course description.
   */
  const handleCourseDesc = (content) => {
    setCdData(content)
  }

  /**
   * Handles the change event for the course title input field.
   * @param {Object} e - The event object.
   */
  const handleCourseTitle = (e) => {
    setCourseTitle(e.target.value)
  }

  /**
   * Handles the selection of a course category.
   *
   * @param {string} e - The selected course category.
   */
  const handleCourseCategory = (e) => {
    setCourseCategory(e)
  }
  /**
   * Handles the selection of a course subcategory.
   *
   * @param {Event} e - The event object.
   */
  const handleCourseSubCategory = (e) => {
    setCourseSubCategory(e)
  }

  const eventClick = (e) => {
    const publicId = e.event._def.publicId
    const courseFound = courses.filter((c) => {
      return c.id === publicId
    })

    setViewCourse(courseFound[0])
    setIsOpenRegistration(courseFound[0].isOpenRegistration)
    setOpenView(true)
  }

  const handleCourseModal = (e) => {
    setOpenView(e)
    setViewCourse(null)
  }

  const handleTrigger = () => {
    getAllAnnouncements()
  }

  const handleHover = (arg) => {
    try {
      tippy(arg.el, {
        content: arg.event._def.title,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const handleRefreshCalendar = () => {
    getAllAnnouncements()
  }

  /**
   * Handles the change event for the "has assessment" input.
   * @param {Object} e - The event object.
   */
  const handleHasAssessment = (e) => {
    if (e.target.value === false || e.target.value === 'false') {
      setGetAssessmentID(null)
    }
    setShowPostAssessment(e.target.value)
  }

  const handleLimitParticipants = (e) => {
    setParticipantLimit(e.target.value)
  }

  /**
   * Handles the input event for the test event.
   * @param {Object} e - The event object.
   */
  const handleTestEventInput = (e) => {
    setIsTestEvent(e.target.value)
  }

  /**
   * Handles the selection of a training to link.
   *
   * @param {any} value - The value of the selected training.
   */
  const handleSelectTrainingToLink = (value) => {
    setSelectedTraining(value)
  }

  /**
   * Sets the chosen files.
   *
   * @param {any} value - The value to set as the chosen files.
   */
  const handleChooseFiles = (value) => {
    setChosenFiles(value)
  }

  /**
   * Handles the selection of training videos.
   * @param {Array} e - The selected videos.
   * @param {string} f - The identifier for the selected videos.
   */
  const handleChooseTrainingVideos = (e, f) => {
    const key = e.key
    setChosenTrainingVideos((prev) => ({
      ...prev,
      [f]: key,
    }))
  }

  const handleAddTranscript = (e, f) => {
    const value = e.target.value

    setVideoTranscript((prev) => ({
      ...prev,
      [f]: value,
    }))
  }

  const handleActivityQuestion = (e) => {
    const myObject = sectionTitle

    if (myObject) {
      if (myObject.hasOwnProperty(e.target.id)) {
        myObject[`${e.target.id}`] = e.target.value
        setActivityQuestions({ ...myObject })
      } else {
        setActivityQuestions({
          ...myObject,
          [e.target.id]: e.target.value,
        })
      }
    } else {
      setActivityQuestions({
        ...myObject,
        [e.target.id]: e.target.value,
      })
    }
  }

  /**
   * Updates the section title at the specified index.
   * @param {Event} e - The event object.
   * @param {number} index - The index of the section title to update.
   */
  const handleSectionTitle = (e, index) => {
    setSectionTitle((prev) => {
      const updatedTitles = [...(prev || [])]
      updatedTitles[index] = e.target.value
      return updatedTitles
    })
  }

  /**
   * Adds a new section to the section length array.
   */
  const handleAddSection = () => {
    const secArray = []
    secArray.push(...sectionLength)
    secArray.push(false)
    setSectionLength(secArray)
  }

  /**
   * Deletes a section, training video, and activity question based on the provided indices.
   *
   * @param {number} e - The index of the section to be deleted.
   * @param {number} g - The index of the training video to be deleted.
   * @param {number} h - The index of the activity question to be deleted.
   */
  const handleDeleteSection = (e, g, h) => {
    const myObjectTitles = sectionTitle
    const myObjectVideos = chosenTrainingVideos
    const myObjectQuestions = activityQuestions

    if (myObjectTitles) {
      myObjectTitles.splice(e, 1)
      setSectionTitle(myObjectTitles)
    }
    if (myObjectVideos) {
      delete myObjectVideos[g]
      setChosenTrainingVideos(myObjectVideos)
    }
    if (myObjectQuestions) {
      delete myObjectQuestions[h]
      setActivityQuestions(myObjectQuestions)
    }
    setSectionLength((prevElements) => prevElements.filter((_, i) => i !== e))
  }

  /**
   * Handles the change event for section activity.
   * @param {Event} e - The event object.
   * @param {number} index - The index of the section.
   */
  const handleSectionHasActivity = (value, index) => {
    if (value === 'false') {
      const questionToDelete = `activityQuestion${index + 1}`
      if (activityQuestions && activityQuestions[questionToDelete]) {
        const updatedQuestions = { ...activityQuestions }
        delete updatedQuestions[questionToDelete]
        setActivityQuestions(updatedQuestions)
      }
    }

    const updatedSectionLength = [...sectionLength]
    updatedSectionLength[index] = value === 'true'
    setSectionLength(updatedSectionLength)
  }

  /**
   * Object representing the preview input for uploading online trainings.
   * @typedef {Object} PreviewInput
   * @property {string} title - The title of the course.
   * @property {string} arrayOfDates - The array of announce dates in JSON string format.
   * @property {string} trainingTimeStart - The start time of the training.
   * @property {string} trainingTimeEnd - The end time of the training.
   * @property {string} courseDescription - The description of the course.
   * @property {string} targetParticipants - The target participants of the course.
   * @property {boolean} isOpenRegistration - Indicates if the registration is open.
   * @property {string} postTrainOpen - The opening date and time of the post-training.
   * @property {string} postTrainClose - The closing date and time of the post-training.
   * @property {string} courseCategory - The category of the course.
   * @property {string} courseSubCategory - The sub-category of the course.
   * @property {boolean} hasAssessment - Indicates if the course has an assessment.
   * @property {number} participantLimit - The limit of participants for the course.
   * @property {string} evalID - The evaluation ID for the course.
   * @property {boolean} isTestEvent - Indicates if the course is a test event.
   * @property {string} assessmentID - The assessment ID for the course.
   * @property {string} stepThreeID - The ID for the endorsement letter in step three.
   * @property {number[]} limitPerDept - The limit per department for the course.
   * @property {string} parentTrainingID - The ID of the parent training.
   * @property {string} isEndorsementRequired - Indicates if an endorsement letter is required.
   * @property {string} sectionTitle - The title of the section.
   * @property {string[]} additionalFiles - The additional files for the section.
   * @property {string[]} sectionVideos - The videos for the section.
   * @property {string[]} sectionQuestions - The questions for the section.
   */
  const previewInput = {
    title: courseTitle,
    arrayOfDates: JSON.stringify(announceDate),
    trainingTimeStart: announceStartTime,
    trainingTimeEnd: announceEndTime,
    courseDescription: cdData,
    targetParticipants: tpData,
    isOpenRegistration: true,
    postTrainOpen: moment(`${pTDateOpen} ${pTTimeOpen}`).format(
      'ddd MMM D YYYY hh:mm:ss a'
    ),
    postTrainClose: moment(`${pTDateClose} ${pTTimeClose}`).format(
      'ddd MMM D YYYY hh:mm:ss a'
    ),
    courseCategory: courseCategory,
    courseSubCategory: courseSubCategory,
    hasAssessment: showPostAssessment,
    participantLimit,
    evalID: getEvalID,
    isTestEvent: isTestEvent,
    assessmentID: getAssessmentID,
    stepThreeID: endorsementLetterID,
    limitPerDept: [departmentsLimitValue],
    parentTrainingID: selectedTraining,
    isEndorsementRequired: isEndorsementLetterRequired
      ? isEndorsementLetterRequired
      : 'no',
    sectionTitle: sectionTitle,
    additionalFiles: chosenFiles,
    sectionVideos: chosenTrainingVideos,
    videoTranscript: videoTranscript,
    sectionQuestions: activityQuestions,
    announceUpload,
  }

  const handleIsSeminarInput = (e) => {
    setIsSeminar(e.target.value)
  }

  const checkCharacterCount = (e) => {
    const data = e.currentTarget.id

    let plainText

    if (data === 'course-description') {
      plainText = cdData ? stripHTML(cdData) : ''
    }

    if (data === 'target-participants') {
      plainText = tpData ? stripHTML(tpData) : ''
    }

    if (data === 'additonal-text') {
      plainText = textDisplayBeforeTrainingOpen
        ? stripHTML(textDisplayBeforeTrainingOpen)
        : ''
    }

    // Check if the plain text length exceeds 3000 characters
    if (plainText.length >= 3000 && e.key !== 'Backspace') {
      e.preventDefault()
    }
  }

  const handleIsPassPreAssessmentRequiredInput = (e) => {
    setIsPassPostAssessmentRequired(e.target.value)
    setShowPostAssessment(e.target.value)
  }

  const handlePreAssessmentScore = (e) => {
    setPreAssessmentScore(e.target.value)
  }

  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      title='Create Online Training'
      visible={visibleAnnouncementOnline}
      footer={null}
      onCancel={handleCancel}
      width={1200}
    >
      <div>
        <div className={'border mb-3'}>
          <Row justify='center'>
            <span>
              This part is for creating a test online training announcements
            </span>
          </Row>
          <Row justify='center'>
            <div className='reg1-row'>
              <span className='loginInput-label'>
                Are you uploading a Test Online Training?
              </span>
              <Row justify='center'>
                <em>For Testing purposes only</em>
              </Row>
            </div>
          </Row>
          <Row onChange={handleTestEventInput} justify='center'>
            <div className={'d-flex align-items-center me-3'}>
              <label
                htmlFor='test-event-yes'
                className={'loginInput-label m-0'}
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                YES
              </label>
              <input
                value={true}
                className={'ms-2 test-event'}
                name='test-event'
                id='test-event-yes'
                type='radio'
              />
            </div>

            <div className={'d-flex align-items-center ms-3'}>
              <label
                htmlFor='test-event-no'
                className={'loginInput-label m-0'}
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                NO
              </label>
              <input
                defaultChecked={true}
                value={false}
                className={'ms-2 test-event'}
                name='test-event'
                id='test-event-no'
                type='radio'
              />
            </div>
          </Row>
          <Row justify='center'>
            <span>
              This part is for creating a test online training announcements
            </span>
          </Row>
        </div>

        <Row className='mt-3' justify='center'>
          <div className='reg1-row'>
            <span className='loginInput-label'>
              Are you uploading a Seminar?
            </span>
          </div>
        </Row>

        <Row className='mb-3' onChange={handleIsSeminarInput} justify='center'>
          <div className={'d-flex align-items-center me-3'}>
            <label
              htmlFor='isSeminarYes'
              className={'loginInput-label m-0'}
              style={{ marginBottom: 10, marginRight: 10 }}
            >
              YES
            </label>
            <input
              value={true}
              className={'ms-2 test-event'}
              name='seminar'
              id='isSeminarYes'
              type='radio'
            />
          </div>

          <div className={'d-flex align-items-center ms-3'}>
            <label
              htmlFor='isSeminarYes'
              className={'loginInput-label m-0'}
              style={{ marginBottom: 10, marginRight: 10 }}
            >
              NO
            </label>
            <input
              defaultChecked={true}
              value={false}
              className={'ms-2 test-event'}
              name='seminar'
              id='isSeminarYes'
              type='radio'
            />
          </div>
        </Row>

        <div className='d-flex flex-column align-items-center justify-content-center mb-3'>
          <span>
            Do you want this required the Endorsement Letter of this training?
          </span>
          <div className='d-flex gap-2'>
            <label className='fw-bold' htmlFor='yes'>
              YES
            </label>
            <input
              value={isEndorsementLetterRequired}
              onChange={() => setIsEndorsementLetterRequired('yes')}
              type='radio'
              id='yes'
              name='isEndorsementLetterRequired'
            />

            <label className='ms-3 fw-bold' htmlFor='no'>
              NO
            </label>
            <input
              value={isEndorsementLetterRequired}
              defaultChecked={isEndorsementLetterRequired == null}
              onChange={() => setIsEndorsementLetterRequired(null)}
              type='radio'
              id='no'
              name='isEndorsementLetterRequired'
            />
          </div>
        </div>

        <div className='d-flex flex-column align-items-center justify-content-center mb-3'>
          <span>Do you want to link this training?</span>
          <div className='d-flex gap-2'>
            <label className='fw-bold' htmlFor='yes'>
              YES
            </label>
            <input
              value={isLink}
              onChange={() => setIsLink(true)}
              type='radio'
              id='yes'
              name='linkTraining'
            />

            <label className='ms-3 fw-bold' htmlFor='no'>
              NO
            </label>
            <input
              value={isLink}
              defaultChecked={isLink == false}
              onChange={() => setIsLink(false)}
              type='radio'
              id='no'
              name='linkTraining'
            />
          </div>
          {isLink && (
            <div className='w-100'>
              <label htmlFor='selectTraining'>Select training to be link</label>
              <Select
                id='selectTraining'
                className='w-100'
                value={selectedTraining}
                showSearch
                placeholder='Select a training'
                optionFilterProp='children'
                onChange={handleSelectTrainingToLink}
                filterSort={compareOptions}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={listOfTrainings}
              />
            </div>
          )}
        </div>

        <Row className='mt-3' justify='center'>
          <div className='reg1-row'>
            <span className='loginInput-label'>
              Do participants need to pass the post-assessment
            </span>
          </div>
        </Row>
        <Row
          className='mb-3'
          onChange={handleIsPassPreAssessmentRequiredInput}
          justify='center'
        >
          <div className={'d-flex align-items-center me-3'}>
            <label
              htmlFor='isPassPostAssessmentRequired'
              className={'loginInput-label m-0'}
              style={{ marginBottom: 10, marginRight: 10 }}
            >
              YES
            </label>
            <input
              value={true}
              className={'ms-2 test-event'}
              name='passPostAssessment'
              id='isPassPostAssessmentRequired'
              type='radio'
            />
          </div>

          <div className={'d-flex align-items-center ms-3'}>
            <label
              htmlFor='isPassPreAssessmentRequiredNo'
              className={'loginInput-label m-0'}
              style={{ marginBottom: 10, marginRight: 10 }}
            >
              NO
            </label>
            <input
              defaultChecked={true}
              value={false}
              className={'ms-2 test-event'}
              name='passPostAssessment'
              id='isPassPreAssessmentRequiredNo'
              type='radio'
            />
          </div>
        </Row>

        {isPassPostAssessmentRequired === 'true' ? (
          <Row justify='center mb-3'>
            <Col span={12}>
              <Row justify='center'>
                <label
                  htmlFor='postAssessmentScore'
                  className='loginInput-label text-center me-3 mb-2 mt-2'
                >
                  Post-assessment score required:
                </label>
                <Input
                  value={postAssessmentScore}
                  type='number'
                  min={1}
                  style={{ width: 70 }}
                  onChange={handlePreAssessmentScore}
                  id='postAssessmentScore'
                  name='postAssessmentScore'
                />
              </Row>
            </Col>
          </Row>
        ) : null}

        <div className='mb-4'>
          <label
            htmlFor='date-event'
            className='loginInput-label col-md-3 col-12'
            style={{ marginBottom: 10 }}
          >
            Select Date of Event:
          </label>

          <DatePicker
            onChange={handleDate}
            sort
            multiple
            format={'YYYY/MM/DD'}
            plugins={[<DatePanel />]}
            style={{ height: '30px', width: '400px', marginRight: '10px' }}
          />
        </div>

        <div className='mb-4'>
          <Row gutter={16}>
            <Col span={12}>
              <Row>
                <Col span={12}>
                  <label
                    htmlFor='Train-openTime'
                    className='loginInput-label'
                    style={{ marginBottom: 10 }}
                  >
                    Select Start Time of Event:
                  </label>
                </Col>
                <Col span={12}>
                  <TimePicker
                    id='Train-openTime'
                    name='Train-openTime'
                    use12Hours
                    format='h:mm A'
                    className='w-100'
                    onChange={handleTrainingStartTime}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={12}>
              <Row>
                <Col span={12}>
                  <label
                    htmlFor='Train-endTime'
                    className='loginInput-label'
                    style={{ marginBottom: 10 }}
                  >
                    Select End Time of Event:
                  </label>
                </Col>
                <Col span={12}>
                  <TimePicker
                    id='Train-endTime'
                    name='Train-endTime'
                    use12Hours
                    format='h:mm A'
                    className='w-100'
                    onChange={handleTrainingEndTime}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Row>
          <label
            htmlFor='course-title'
            className='loginInput-label'
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Online Training Title
          </label>
          <Input
            onChange={handleCourseTitle}
            id='course-title'
            name='course-title'
            maxLength={256}
          />
        </Row>

        <Row>
          <label
            htmlFor='course-category'
            className='loginInput-label'
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Online Training Category
          </label>
          <Col span={24}>
            <Select
              style={{
                width: '100%',
              }}
              id='course-category'
              name='course-category'
              showSearch
              placeholder='Select a category'
              optionFilterProp='children'
              onChange={handleCourseCategory}
              // onSearch={onSearchCategory}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={[
                {
                  value: '',
                  label: '',
                  disabled: true,
                },
                {
                  value: 'CORE COMPETENCIES',
                  label: 'CORE COMPETENCIES',
                },
                {
                  value: 'LEADERSHIP COMPETENCIES',
                  label: 'LEADERSHIP COMPETENCIES',
                },
                {
                  value: 'ORGANIZATIONAL COMPETENCIES',
                  label: 'ORGANIZATIONAL COMPETENCIES',
                },
                {
                  value: 'FUNCTIONAL/TECHNICAL',
                  label: 'FUNCTIONAL/TECHNICAL',
                },
                {
                  value: 'Additional Training Programs',
                  label: 'Additional Training Programs',
                },
              ]}
            />
          </Col>
        </Row>
        <Row className='mb-3'>
          <label
            htmlFor='course-category'
            className='loginInput-label'
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Online Training Sub-category
          </label>
          <Col span={24}>
            <Select
              style={{
                width: '100%',
              }}
              id='course-sub-category'
              name='course-sub-category'
              showSearch
              placeholder='Select a sub-category'
              optionFilterProp='children'
              onChange={handleCourseSubCategory}
              // onSearch={onSearchCategory}
              filterOption={(input, option) =>
                (option?.label ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={optionSubCategory}
            />
          </Col>
        </Row>

        {/*insert online training videos and materials selection here*/}

        <Row>
          <label
            htmlFor='otherFilesSelect'
            className='loginInput-label'
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Online Training Additional Files
          </label>
          <Col span={24}>
            <Select
              mode='multiple'
              className='w-100'
              id='otherFilesSelect'
              name='otherFilesSelect'
              showSearch
              allowClear
              placeholder='Select additional material/s for the training'
              optionFilterProp='children'
              onChange={handleChooseFiles}
              // onSearch={onSearchCategory}
              filterOption={(input, option) =>
                (option?.filename ?? '')
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={otherFiles}
            />
          </Col>
        </Row>

        {/* <Row className='mt-4'>
          <label
            htmlFor='createSection'
            className='loginInput-label'
            style={{ marginTop: 10, marginBottom: 10 }}
          >
            Section {sectionLength.length + 1}
          </label>
        </Row> */}

        {sectionLength &&
          sectionLength.map((section, index) => {
            return (
              <Row key={index} id={`section-length-${index}`}>
                <Col span={22}>
                  <Row className='mt-4'>
                    <h4
                      htmlFor='createSection'
                      /*  className='loginInput-label' */
                      style={{ marginTop: 10, marginBottom: 10 }}
                    >
                      Section {index + 1}
                    </h4>
                  </Row>
                  <div className='ms-4'>
                    <Row className='mt-3'>
                      <label
                        htmlFor='createSection'
                        className='loginInput-label'
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        Topic / Category
                      </label>
                      <Input
                        placeholder='Enter Section / Topic / Category title'
                        onChange={(e) => handleSectionTitle(e, index)}
                        id={`sectionTitle-${index + 1}`}
                        name='createSection'
                        maxLength={256}
                      />
                    </Row>
                    <Row>
                      <label
                        htmlFor='course-title'
                        className='loginInput-label'
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        Select training video
                      </label>
                      <Select
                        className='w-100'
                        id={`trainingVideosID-${index + 1}`}
                        name='trainingVideosID'
                        showSearch
                        placeholder='Select training video/s for the section'
                        optionFilterProp='children'
                        onChange={(input, option) =>
                          handleChooseTrainingVideos(
                            option,
                            `trainingVideosID${index + 1}`
                          )
                        }
                        // onSearch={onSearchCategory}
                        filterOption={(input, option) =>
                          (option?.key ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={uploadedTrainingVideo}
                        /*  mode='multiple' */
                      />
                    </Row>
                    <Row>
                      <label
                        htmlFor='course-title'
                        className='loginInput-label'
                        style={{ marginTop: 10, marginBottom: 10 }}
                      >
                        Video Transcript
                        <span className='text-secondary'> (optional)</span>
                      </label>
                      <TextArea
                        rows={4}
                        placeholder='Enter video transcript'
                        onChange={(e) =>
                          handleAddTranscript(
                            e,
                            `videoTranscriptID${index + 1}`
                          )
                        }
                        id='course-video-transcript'
                        name='course-video-transcript'
                        maxLength={40000}
                      />
                    </Row>
                  </div>
                  {activityQuestions &&
                  activityQuestions[`activityQuestion${index + 1}`] ? (
                    <div className='ms-4 d-flex gap-2 align-items-center mt-3'>
                      <span
                        className='d-flex items-center gap-1'
                        style={{
                          fontSize: '16px',
                        }}
                      >
                        Activity question{' '}
                        <CheckOutlined
                          className='text-success'
                          style={{
                            fontSize: '24px',
                          }}
                        />
                      </span>
                      <Button
                        onClick={(e) =>
                          handleSectionHasActivity('false', index)
                        }
                        type='danger'
                      >
                        Delete
                      </Button>
                      {/* <Button type='primary'>View</Button> */}
                    </div>
                  ) : (
                    <div className='reg1-row mt-3 mx-auto mb-5'>
                      <Row justify='center'>
                        <span className='loginInput-label'>
                          Will it have a activity question?
                        </span>
                      </Row>
                      <Row
                        /* onChange={handleHasAssessment} */
                        className='mt-3'
                        justify='space-around'
                      >
                        <Col>
                          <input
                            defaultChecked={false}
                            value={true}
                            id={`hasAssessmentYes${index + 1}`}
                            name={`hasAssessment${index + 1}`}
                            className='hasAssessment'
                            type='radio'
                            onChange={(e) =>
                              handleSectionHasActivity(e.target.value, index)
                            }
                          />
                          <label
                            className='loginInput-label'
                            htmlFor='activityQuestionYes'
                          >
                            YES
                          </label>
                        </Col>
                        <Col>
                          <input
                            defaultChecked={true}
                            value={false}
                            id={`hasAssessmentNo${index + 1}`}
                            name={`hasAssessment${index + 1}`}
                            className='hasAssessment'
                            type='radio'
                            onChange={(e) =>
                              handleSectionHasActivity(e.target.value, index)
                            }
                          />
                          <label
                            className='loginInput-label'
                            htmlFor='activityQuestionNo'
                          >
                            NO
                          </label>
                        </Col>
                      </Row>
                      {section ? (
                        <>
                          <Row className='mt-3 w-100 gap-5' justify='center'>
                            <ActivityQuestionMaker
                              setActivityQuestions={setActivityQuestions}
                              index={index}
                            />

                            <SelectActivityQuestions
                              setActivityQuestions={setActivityQuestions}
                              index={index}
                            />
                          </Row>
                        </>
                      ) : null}
                    </div>
                  )}
                </Col>
                <Col span={2} className='align-items-center d-flex flex-column'>
                  {index == 0 || index + 1 < sectionLength.length ? null : (
                    <Row justify='end' className='mt-3 mb-3'>
                      <Button
                        onClick={(e) =>
                          handleDeleteSection(
                            index,
                            `trainingVideosID${index + 1}`,
                            `activityQuestion${index + 1}`
                          )
                        }
                        ghost
                        type='danger'
                        className='d-flex justify-content-center align-items-center'
                      >
                        <CloseOutlined />
                      </Button>
                    </Row>
                  )}
                </Col>
              </Row>
            )
          })}

        <Row className='mt-4'>
          <Col span={24}>
            <Button
              onClick={handleAddSection}
              type='primary'
              ghost
              block
              className='d-flex justify-content-center align-items-center'
            >
              Add Section <PlusOutlined />
            </Button>
          </Col>
        </Row>

        <div className='reg1-row'>
          <span>
            <label
              htmlFor='pubmat'
              className='loginInput-label'
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Poster Upload Here
            </label>
            <Input
              id='pubmat'
              name='pubmat'
              type='file'
              accept='image/jpeg,image/gif,image/png,application/pdf,video/mp4,video/x-m4v,video/*'
              onChange={(e) => {
                setAnnounceUpload([...e.target.files])
              }}
            />
            {announceUpload.length > 0 ? (
              <div className={'d-flex flex-column ms-3'}>{showUploadNames}</div>
            ) : null}
            <label
              htmlFor='speaker'
              className='loginInput-label'
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Resource speaker poster upload here
            </label>
            <Input
              id='speaker'
              name='speaker'
              type='file'
              accept='image/jpeg,image/gif,image/png,application/pdf,video/mp4,video/x-m4v,video/*'
              onChange={(e) => {
                setSpeakerUpload([...e.target.files])
              }}
            />
            {speakerUpload.length > 0 ? (
              <div className={'d-flex flex-column ms-3'}>
                {showUploadspeaker}
              </div>
            ) : null}
            <Row className='flex-column'>
              <label
                htmlFor='course-description'
                className='loginInput-label'
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Online Training Description
              </label>
              <ReactQuill
                onKeyDown={checkCharacterCount}
                onChange={handleCourseDesc}
                id='course-description'
                name='course-description'
                className='mb-5 mt-1'
                style={{ height: 130 }}
              />
            </Row>
            <Row className='flex-column'>
              <label
                htmlFor='target-participants'
                className='loginInput-label'
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Target Participants
              </label>
              <ReactQuill
                onKeyDown={checkCharacterCount}
                onChange={handleTargetParticipants}
                id='target-participants'
                name='target-participants'
                className='mb-5 mt-1'
                style={{ height: 130 }}
              />
            </Row>
            <Row className='flex-column'>
              <label
                htmlFor='additonal-text'
                className='loginInput-label'
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                Text to display before training open
              </label>
              <ReactQuill
                onKeyDown={checkCharacterCount}
                onChange={handleAdditionalText}
                id='additonal-text'
                name='additonal-text'
                className='mb-5 mt-1'
                style={{ height: 130 }}
              />
            </Row>
            <Row className='my-3'>
              <Col span={15}>
                <label
                  htmlFor='limit-participants'
                  className='loginInput-label'
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  Number of Participants{' '}
                  <small style={{ fontSize: 10 }}>
                    <em>(limit the number of who can attend)</em>
                  </small>
                </label>
              </Col>
              <Col span={9}>
                <Input
                  // onChange={handleLimitParticipants}
                  onClick={() => setManageParticipantsModal(true)}
                  id='limit-participants'
                  name='limit-participants'
                  type='number'
                  value={participantLimit}
                  min={1}
                />
              </Col>
            </Row>
          </span>
        </div>

        <ManageTrainingsNumberOfParticipants
          departmentOptions={departmentOptions}
          departments={departments}
          setDepartments={setDepartments}
          setListOfDepartments={setListOfDepartments}
          listOfDepartmentsOrignal={listOfDepartmentsOrignal}
          setDepartmentsLimitValue={setDepartmentsLimitValue}
          departmentsLimitValue={departmentsLimitValue}
          manageParticipantsModal={manageParticipantsModal}
          setManageParticipantsModal={setManageParticipantsModal}
          participantLimit={participantLimit}
          setParticipantLimit={setParticipantLimit}
        />

        <div>
          <EndorsementLetter
            userData={userData}
            setEndorsementLetterID={setEndorsementLetterID}
          />
          {endorsementLetterID && (
            <Row className='mt-3'>
              <span className='loginInput-label'>
                Text ID: <em className='asterisk'>{endorsementLetterID}</em>
              </span>
            </Row>
          )}
        </div>

        <div className='reg1-row mt-3'>
          <Row justify='center'>
            <span className='loginInput-label'>
              Will it have a Pre-assessment and Post-assessment?
            </span>
          </Row>
          <Row
            onChange={handleHasAssessment}
            className='mt-3'
            justify='space-around'
          >
            <Col>
              <input
                checked={showPostAssessment === 'true'}
                value={true}
                id='hasAssessmentYes'
                name='hasAssessment'
                className='hasAssessment'
                type='radio'
              />
              <label className='loginInput-label' htmlFor='hasAssessmentYes'>
                YES
              </label>
            </Col>
            <Col>
              <input
                checked={showPostAssessment === 'false'}
                value={false}
                id='hasAssessmentNo'
                name='hasAssessment'
                className='hasAssessment'
                type='radio'
              />
              <label className='loginInput-label' htmlFor='hasAssessmentNo'>
                NO
              </label>
            </Col>
          </Row>
        </div>

        {showPostAssessment == 'true' ? (
          <>
            <Row className='mt-3 w-100' justify='center'>
              <AssessmentMaker
                userData={userData}
                setGetAssessmentID={(e) => setGetAssessmentID(e)}
              />
            </Row>
            <Row className='mt-3 w-100' justify='center'>
              <SelectAssessmentQuestion
                setGetAssessmentID={setGetAssessmentID}
              />
            </Row>
          </>
        ) : null}

        <Row className='mt-3' justify='space-around'>
          <EvaluationMaker
            userData={userData}
            setGetEvalID={(e) => setGetEvalID(e)}
          />
          <SelectEvaluationQuestion setGetEvalID={setGetEvalID} />
        </Row>

        {showPostAssessment == 'true' ? (
          <Row className='mt-3'>
            <span className='loginInput-label'>
              Assessment Questionnaire ID:{' '}
              <em className='asterisk'>
                {getAssessmentID ? getAssessmentID : 'none'}
              </em>
            </span>
          </Row>
        ) : null}

        {getEvalID ? (
          <>
            <Row className='mt-3'>
              <span className='loginInput-label'>
                Evaluation Questionnaire ID:{' '}
                <em className='asterisk'>{getEvalID}</em>
              </span>
            </Row>
          </>
        ) : (
          <>
            <Row className='mt-3'>
              <span className='loginInput-label'>
                Evaluation Questionnaire ID:{' '}
                <em className='asterisk'>
                  Select your questionnaire or create a new one.
                </em>
              </span>
            </Row>
          </>
        )}
        <Row className='mb-4'>
          <Col span={12}>
            <div className='reg1-row mt-3'>
              <span>
                <label
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  POST TRAINING OPEN
                </label>
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-open'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Date Post Training will open:
                </label>
                <AntdDatePicker
                  id='postTrain-open'
                  name='postTrain-open'
                  onChange={handlePostTrainOpenDate}
                />
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-openTime'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Time Post Training will open:
                </label>

                <TimePicker
                  id='postTrain-openTime'
                  name='postTrain-openTime'
                  use12Hours
                  format='h:mm:ss A'
                  onChange={handlePostTrainOpenTime}
                />
              </span>
            </div>
          </Col>

          <Col span={12}>
            <div className='reg1-row mt-3'>
              <span>
                <label
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  POST TRAINING CLOSE
                </label>
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-close'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Date Post Training will close:
                </label>
                <AntdDatePicker
                  id='postTrain-close'
                  name='postTrain-close'
                  onChange={handlePostTrainCloseDate}
                />
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-closeTime'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Time Post Training will close:
                </label>

                <TimePicker
                  id='postTrain-closeTime'
                  name='postTrain-closeTime'
                  use12Hours
                  format='h:mm:ss A'
                  onChange={handlePostTrainCloseTime}
                />
              </span>
            </div>
          </Col>
        </Row>

        <div className={'d-flex justify-content-end'} style={{ marginTop: 20 }}>
          <Button style={{ marginRight: 20 }} onClick={closeUploadPubMat}>
            Cancel
          </Button>
          <UploadOnlineeTrainingsPreview
            sectionLength={sectionLength}
            speakerUpload={speakerUpload}
            handleSubmitTraining={handleSubmitTraining}
            training={previewInput}
          />
        </div>
      </div>
    </Modal>
  )
}
