/**
 * Title: LmsViewCourseModal.js
 * Description: This is a file that contains lms view course modal
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/LmsViewCourseModal.js
 * History:
 * - 09/23/2024 - John Bazil Valdez
 * - Implement logic to update online training or normal training
 **/

import React, { useState, Suspense } from 'react'

import { Button, Modal, Col, Row, notification, Tooltip } from 'antd'

import { API, graphqlOperation } from 'aws-amplify'
import {
  updateOnlineTrainingAnnouncement,
  updateTrainingAnnouncement,
} from '../../api/mutations'

import moment from 'moment'

import RegisteredUsersByEvents from './registeredUsersByEvent.js'
import UsersWithPostTraining from './usersWithPostTraining.js'
import DeleteTraining from './deleteTraining.js'
import EditTraining from './editTraining.js'

import ActivityFourDownload from './activityFourDownload.js'
import ActivityThreeDownload from './activityThreeDownload.js'
import ActivityOneDownload from './activityOneDownload'
import ActivityTwoDownload from './activityTwoDownload'
import Sept15ActivityOneDownload from './Sept15OnlineTrainingDownloads/sept15ActivityOneDownload'
import Sept15ActivityTwoDownload from './Sept15OnlineTrainingDownloads/sept15ActivityTwoDownload'
import Sept15ActivityThreeDownload from './Sept15OnlineTrainingDownloads/sept15ActivityThreeDownload'
import Nov20ActivityDownload from './Nov20ActivityDownload.js'
import EditOnlineTraining from './ediOnlineTraining.js'
import DeleteOnlineTraining from './deleteOnlineTraining.js'
import OnlineTrainingActivityDownload from './online-training-activity-download.js'
import HtmlParser from './html-parser.js'
import AdminCommentModalView from './admin-comment-modal-view.js'

const ManualRegister = React.lazy(() => import('./manualRegister.js'))

export default function LmsViewCourseModal({
  viewCourse,
  openView,
  setOpenView,
  isOpenRegistration,
  setIsOpenRegistration,
  triggerFetch,
  refresh,
  uploadedTrainingVideo,
  otherFiles,
  userData,
}) {
  const [openRegisterModal, setOpenRegisterModal] = useState(false)

  const handleCloseRegistration = async () => {
    try {
      const closeRegistration = await API.graphql(
        graphqlOperation(
          viewCourse.sectionTitle
            ? updateOnlineTrainingAnnouncement
            : updateTrainingAnnouncement,
          {
            input: { id: viewCourse.id, isOpenRegistration: false },
          }
        )
      )

      if (closeRegistration) {
        triggerFetch()
        setIsOpenRegistration(false)
        notification['success']({
          message: 'Success',
          description: 'Event Registration is now closed.',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleOpenRegistration = async () => {
    try {
      const openRegistration = await API.graphql(
        graphqlOperation(
          viewCourse.sectionTitle
            ? updateOnlineTrainingAnnouncement
            : updateTrainingAnnouncement,
          {
            input: { id: viewCourse.id, isOpenRegistration: true },
          }
        )
      )

      if (openRegistration) {
        triggerFetch()
        setIsOpenRegistration(true)
        notification['success']({
          message: 'Success',
          description: 'Event Registration is now opened.',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const tooltipText = <span>Register an employee to a Training event</span>

  const handleOpenRegisterModal = () => {
    setOpenRegisterModal(true)
  }

  /*  console.log(JSON.parse(viewCourse.sectionQuestions)) */

  return (
    <Modal
      maskClosable={false}
      destroyOnClose={true}
      title={'Course / Training'}
      centered
      visible={openView}
      onOk={() => setOpenView(false)}
      onCancel={() => setOpenView(false)}
      width={850}
      footer={null}
    >
      {viewCourse ? (
        <>
          <Row>
            <Col className='col-md-6 col-12 order-2 order-md-1 mt-md-0 mt-5'>
              {viewCourse?.title ? (
                <Row>
                  <span>
                    <b>Course Title: </b>
                    <br />
                    <p>{`${viewCourse?.title}`}</p>
                  </span>
                </Row>
              ) : null}
              <Row className={'mb-3'}>
                <span>
                  <b>Course Description: </b>
                  <br />
                  {viewCourse?.courseDescription ? (
                    <p>
                      <HtmlParser
                        content={viewCourse?.courseDescription?.replace(
                          /\n/g,
                          '<br>'
                        )}
                      />
                    </p>
                  ) : null}
                </span>
              </Row>
              <Row className={'mb-3'}>
                <span>
                  <b>Target Participants: </b>
                  <br />
                  {viewCourse?.targetParticipants ? (
                    <p>
                      <HtmlParser
                        content={viewCourse?.targetParticipants?.replace(
                          /\n/g,
                          '<br>'
                        )}
                      />
                    </p>
                  ) : null}
                </span>
              </Row>
              <Row className={'mb-3'}>
                <span>
                  <b>Course / Training Date: </b>
                  <br />
                  {`${moment(viewCourse.date).format('MMMM DD, YYYY')}`}
                </span>
              </Row>

              <Row className={'mb-3'}>
                <span>
                  <b>Start time: </b>
                  <br />
                  {viewCourse?.trainingTimeStart
                    ? viewCourse.trainingTimeStart
                    : null}
                </span>
              </Row>

              <Row className={'mb-3'}>
                <span>
                  <b>Post training will open: </b>
                  <br />
                  {viewCourse?.postTrainOpen ? viewCourse.postTrainOpen : null}
                </span>
              </Row>
              <Row className={'mb-3'}>
                <span>
                  <b>Post training will close: </b>
                  <br />
                  {viewCourse?.postTrainClose
                    ? viewCourse.postTrainClose
                    : null}
                </span>
              </Row>
            </Col>
            <Col className='col-md-6 col-12 order-1 order-md-2'>
              <Row justify='center'>
                <img
                  style={{ maxWidth: '250px' }}
                  src={JSON.parse(viewCourse.location).sort()[0]}
                  alt='Course image.'
                />
              </Row>
              <Row style={{ marginTop: 12 }} justify='center'>
                <RegisteredUsersByEvents viewCourse={viewCourse} />
              </Row>
              <Row style={{ marginTop: 12 }} justify='center'>
                <UsersWithPostTraining viewCourse={viewCourse} />
              </Row>
              <Row style={{ marginTop: 12 }} justify='center'>
                <>
                  <Tooltip placement='left' title={tooltipText}>
                    <Button
                      type='default'
                      className={'d-flex align-items-center'}
                      onClick={handleOpenRegisterModal}
                    >
                      Admin-initiated Registration
                    </Button>
                  </Tooltip>
                  <Suspense fallback={null}>
                    {openRegisterModal && (
                      <ManualRegister
                        openRegisterModal={openRegisterModal}
                        viewCourse={viewCourse}
                        refresh={refresh}
                        onClose={() => setOpenRegisterModal(false)}
                      />
                    )}
                  </Suspense>
                </>

                {/*<ManualRegister />*/}
              </Row>
              <Row style={{ marginTop: 12 }} justify='center'>
                {viewCourse.sectionTitle ? (
                  <div className='d-flex justify-content-center align-items-center flex-column'>
                    <EditOnlineTraining
                      refresh={() => refresh()}
                      viewCourse={viewCourse}
                      setOpenView={() => setOpenView()}
                      uploadedTrainingVideo={uploadedTrainingVideo}
                      otherFiles={otherFiles}
                      userData={userData}
                    />

                    <AdminCommentModalView
                      training={viewCourse}
                      userData={userData}
                    />
                  </div>
                ) : (
                  <EditTraining
                    refresh={() => refresh()}
                    viewCourse={viewCourse}
                    setOpenView={() => setOpenView()}
                    userData={userData}
                  />
                )}
              </Row>

              {viewCourse.sectionQuestions &&
                Object.entries(JSON.parse(viewCourse.sectionQuestions)).map(
                  ([key, value], index) => (
                    <Row key={index} style={{ marginTop: 12 }} justify='center'>
                      <OnlineTrainingActivityDownload
                        viewCourse={viewCourse}
                        activityNumber={key}
                        activityQuestion={value}
                        userData={userData}
                      />
                    </Row>
                  )
                )}

              {viewCourse.id == 'c822d184-b65a-4a25-8079-89e895685546' && (
                <>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <ActivityOneDownload viewCourse={viewCourse} />
                  </Row>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <ActivityTwoDownload viewCourse={viewCourse} />
                  </Row>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <ActivityThreeDownload viewCourse={viewCourse} />
                  </Row>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <ActivityFourDownload viewCourse={viewCourse} />
                  </Row>
                </>
              )}

              {viewCourse.id == 'c822d184-b65a-4a25-8079-89e895685546' && (
                <>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <ActivityOneDownload
                      userData={userData}
                      viewCourse={viewCourse}
                    />
                  </Row>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <ActivityTwoDownload
                      userData={userData}
                      viewCourse={viewCourse}
                    />
                  </Row>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <ActivityThreeDownload
                      userData={userData}
                      viewCourse={viewCourse}
                    />
                  </Row>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <ActivityFourDownload
                      userData={userData}
                      viewCourse={viewCourse}
                    />
                  </Row>
                </>
              )}

              {viewCourse.id == '7a8a0240-06d2-4a2e-9fc4-a7041f7c5a04' && (
                <>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <Nov20ActivityDownload viewCourse={viewCourse} />
                  </Row>
                </>
              )}

              {viewCourse.id == '57f1487c-6a46-4d74-85dc-35f68798c28b' && (
                <>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <Sept15ActivityOneDownload viewCourse={viewCourse} />
                  </Row>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <Sept15ActivityTwoDownload viewCourse={viewCourse} />
                  </Row>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <Sept15ActivityThreeDownload viewCourse={viewCourse} />
                  </Row>
                </>
              )}

              {viewCourse.id == 'acb7d867-7114-49a5-81f0-8ee28eb2828d' && (
                <>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <Sept15ActivityOneDownload viewCourse={viewCourse} />
                  </Row>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <Sept15ActivityTwoDownload viewCourse={viewCourse} />
                  </Row>
                  <Row style={{ marginTop: 12 }} justify='center'>
                    <Sept15ActivityThreeDownload viewCourse={viewCourse} />
                  </Row>
                </>
              )}
            </Col>
          </Row>
          <hr />
          <Row justify='space-between'>
            {isOpenRegistration ? (
              <>
                <Button onClick={handleCloseRegistration} type='danger'>
                  Close Registration
                </Button>
              </>
            ) : (
              <>
                <Button onClick={handleOpenRegistration} type='primary'>
                  Open Registration
                </Button>
              </>
            )}
            {viewCourse.sectionTitle ? (
              <DeleteOnlineTraining
                viewCourse={viewCourse}
                refresh={() => refresh()}
                setOpenView={(e) => setOpenView(e)}
              />
            ) : (
              <DeleteTraining
                viewCourse={viewCourse}
                refresh={() => refresh()}
                setOpenView={(e) => setOpenView(e)}
              />
            )}
          </Row>
        </>
      ) : null}
    </Modal>
  )
}
