/**
 * Title: faq.js
 * Description: This is a file that contains the FAQs component
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/faq.js
 **/

import { useState, useEffect, useRef } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { listFaqTables } from '../../api/queries'
import { deleteFaqTable } from '../../api/mutations'
import AddFAQ from './AddFAQ'
import { Row, Input, Button, notification, Breadcrumb, Popconfirm } from 'antd'
import { DeleteOutlined, BackwardFilled } from '@ant-design/icons'
import HtmlParser from './html-parser'

/**
 * The FAQs function displays a list of frequently asked questions and their answers, with the ability
 * for an admin user to add or delete items.
 * @returns The `FAQs` component is being returned, which renders a list of frequently asked questions
 * and their answers. The component fetches the FAQs using GraphQL and sets them to a state variable.
 * It also allows the user to search for specific FAQs and click on them to expand and view their
 * answers. If the user has an "admin" role, they can also add and delete FAQs.
 */
export default function FAQs({ role }) {
  const [FAQsList, setFAQsList] = useState([])
  const [activeFAQ, setActiveFAQ] = useState(null)
  const [filteredSearch, setFilteredSearch] = useState(null)
  const search = useRef()

  /**
   * The function sets the active FAQ when clicked.
   * @param faq - The parameter `faq` is likely an object or a string that represents a frequently asked
   * question (FAQ) that the user has clicked on. The function `handleFAQClick` sets the `faq` as the
   * active FAQ, which could mean that it is highlighted or expanded to show the answer. The
   */
  const handleFAQClick = (faq) => {
    setActiveFAQ(faq)
    setFilteredSearch(null)
  }

  /**
   * This function fetches a list of FAQ items using GraphQL and sets them to a state variable.
   */
  const fetchFAQs = async () => {
    const result = await API.graphql({
      query: listFaqTables,
      authMode: 'API_KEY',
    })
    setFAQsList(result.data.listFaqTables.items)
  }

  /* `useEffect(() => { fetchFAQs(); }, [])` is a React hook that is used to fetch the list of FAQ items
	using GraphQL and set them to a state variable. It is called only once when the component mounts,
	as the second argument `[]` is an empty array, which means that there are no dependencies and the
	effect will only run once. */
  useEffect(() => {
    fetchFAQs()
  }, [])

  /* `const groupedFAQs` is a new variable that is created using the `reduce` method on the `FAQsList`
	array. The `reduce` method is used to transform an array into a single value, in this case, an
	object. */
  const groupedFAQs = FAQsList.reduce((acc, curr) => {
    const { category } = curr
    if (!acc[category]) {
      acc[category] = []
    }
    acc[category].push(curr)
    return acc
  }, {})

  /**
   * This function handles the deletion of a FAQ table entry and displays a success or error
   * notification.
   * @param id - The id parameter is the unique identifier of the FAQ table entry that needs to be
   * deleted.
   */
  const handleDelete = async (id) => {
    try {
      const deleteEvent = await API.graphql(
        graphqlOperation(deleteFaqTable, {
          input: {
            id,
          },
        })
      )

      if (deleteEvent) {
        notification['success']({
          message: 'Success',
          description: 'Training Announcement was deleted.',
        })
        setActiveFAQ(null)
        fetchFAQs()
      } else {
        notification['error']({
          message: 'Error',
          description: 'Something happened. Please report to System Admin',
        })
      }
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description: 'Something happened. Please report to System Admin',
      })
    }
  }

  const handleSearch = () => {
    const searchValue = search.current.input.value
    if (searchValue === '') {
      return setFilteredSearch([])
    }
    const filteredFAQsList = FAQsList.filter((faq) =>
      faq.questionText.toLowerCase().includes(searchValue.toLowerCase())
    )
    setFilteredSearch(filteredFAQsList)
  }

  const backToFAQList = () => {
    setActiveFAQ(null)
    setFilteredSearch(null)
  }

  return (
    <>
      <div className='container min-vh-100 font-Mont'>
        <Row className='welcome' justify='center'>
          <h1 className='display-3 fw-bold'>
            Frequently
            <br />
            Asked Questions
          </h1>
          <Input
            ref={search}
            placeholder='Search here'
            allowClear
            onPressEnter={() => handleSearch()}
            style={{ width: 1000, fontSize: 25 }}
            prefix={
              <button className='btn btn-link' onClick={() => handleSearch()}>
                <i className='fa fa-search fa-lg pe-2'></i>
              </button>
            }
            className='form-control-lg mt-4'
          />
        </Row>
        {role.toLowerCase() === 'admin' && !filteredSearch && !activeFAQ ? (
          <div className='p-5'>
            <AddFAQ fetchFAQs={fetchFAQs} groupedFAQs={groupedFAQs} />
          </div>
        ) : (
          <div className='p-5'></div>
        )}
        {filteredSearch ? (
          <div className='d-flex flex-column flex-wrap text-start ps-3 pe-3 pe-md-0 ps-md-0'>
            <div>
              <Button
                onClick={backToFAQList}
                className='mb-5 d-flex align-items-center ps-0 pe-4 border-0 fs-5'
              >
                <BackwardFilled />
                Previous
              </Button>
            </div>
            <div>
              <h1 className='fw-bold'>Search results</h1>
              <span className='fs-5'>
                <em>
                  {filteredSearch.length} results for "
                  {search.current.input.value}"
                </em>
              </span>
              <hr className='mt-5' />
              <div className='d-flex flex-column gap-4'>
                {filteredSearch.map((faq) => (
                  <div key={faq.id}>
                    <button
                      onClick={() => handleFAQClick(faq)}
                      className='p-0 border-0'
                    >
                      <h2 className='faqs-link text-primary'>
                        {faq.questionText}
                      </h2>
                    </button>
                    <Breadcrumb separator='>'>
                      <Breadcrumb.Item>
                        <button
                          className='text-decoration-none font-italic border-0 p-0 bg-transparent'
                          onClick={backToFAQList}
                        >
                          <em>FAQ's List</em>
                        </button>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item>
                        <em className='font-italic text-capitalize'>
                          {faq.category}
                        </em>
                      </Breadcrumb.Item>
                      <Breadcrumb.Item className='font-italic'>
                        <em className='font-italic text-capitalize'>
                          {'question'}
                        </em>
                      </Breadcrumb.Item>
                    </Breadcrumb>
                    <hr />
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : activeFAQ ? (
          <div className='d-flex flex-column gap-3 align-items-start ps-md-0 pe-md-0 ps-3 pe-3 justify-content-center align-items-center '>
            <div className='col-md-9 col-12 d-flex flex-column gap-3 align-items-start ps-md-0 pe-md-0 ps-3 pe-3'>
              {role.toLowerCase() === 'admin' && (
                <div className='w-100 d-flex justify-content-center '>
                  <Popconfirm
                    placement='top'
                    title={'Are you sure to delete this FAQ?'}
                    onConfirm={() => handleDelete(activeFAQ.id)}
                    okText='Yes'
                    cancelText='No'
                  >
                    <Button
                      type='text'
                      // icon={<DeleteOutlined style={{ color: 'red', fontSize: 30 }} />}
                      // onClick={() => handleDelete(activeFAQ.id)}
                      className='bg-danger text-white d-flex align-items-center'
                    >
                      <DeleteOutlined /> Delete This FAQ
                    </Button>
                  </Popconfirm>
                </div>
              )}
              <Button
                onClick={backToFAQList}
                className='mb-4 ghost d-flex align-items-center ps-0 pe-4 border-0 fs-5'
              >
                <BackwardFilled />
                Previous
              </Button>
              <div className='display-6 fw-bold d-flex align-items-center w-100 gap-3 text-primary text-start'>
                {activeFAQ.questionText}
              </div>
              <Breadcrumb separator='>'>
                <Breadcrumb.Item>
                  <button
                    className='text-decoration-none font-italic border-0 p-0 bg-transparent'
                    onClick={backToFAQList}
                  >
                    <em>FAQ's List</em>
                  </button>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <em className='font-italic text-capitalize'>
                    {activeFAQ.category}
                  </em>
                </Breadcrumb.Item>
                <Breadcrumb.Item className='font-italic'>
                  <em className='font-italic text-capitalize'>{'question'}</em>
                </Breadcrumb.Item>
              </Breadcrumb>

              <p className='text-start line-height-28'>
                <HtmlParser
                  content={activeFAQ?.answerText?.replace(/\n/g, '<br>')}
                />
              </p>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-wrap text-start ps-3 pe-3 pe-md-0 ps-md-0'>
            {Object.entries(groupedFAQs).map(([category]) => (
              <div key={category} className='col-12 col-md-6 mb-5'>
                <h2 className='mb-4 font-Mont text-primary fw-bold' level={3}>
                  {category.charAt(0).toUpperCase() +
                    category.slice(1).toLowerCase()}
                </h2>
                <ul className='list-unstyled d-flex flex-column gap-2'>
                  {FAQsList.map((item) => {
                    if (
                      category.toLowerCase() === item.category.toLowerCase()
                    ) {
                      return (
                        <li key={item.id}>
                          <a
                            onClick={() => handleFAQClick(item)}
                            className='faqs-link font-Mont text-dark text-capitalize'
                          >
                            {item.questionText}
                          </a>
                        </li>
                      )
                    }
                  })}
                </ul>
              </div>
            ))}
          </div>
        )}
      </div>
      {/*<span>FAQs Here</span>*/}

      {/* <h1 className="mt-5 pt-5 display-2 font-Mont fw-bold">Coming Soon</h1> */}
    </>
  )
}
