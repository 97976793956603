/**
 * Title: activityQuestionModalTwoSept15.js
 * Description: This is a file that contains activity question modal two for sept 15 training
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/activityQuestionModalTwoSept15.js
 **/

import { useState, useEffect, useRef } from 'react'

import { API, graphqlOperation } from 'aws-amplify'
import {
  ActivityTwoAnswerByUserID,
  listTrainingVideos,
} from '../../api/queries'
import {
  createActivityTwoAnswer,
  deleteTrainingVideo,
  updateActivit,
  updateActivityTwoAnswer,
} from '../../api/mutations'

import moment from 'moment'

import {
  UploadOutlined,
  FundViewOutlined,
  DeleteFilled,
  PlayCircleFilled,
} from '@ant-design/icons'
import {
  notification,
  Card,
  Table,
  Button,
  Modal,
  Input,
  Popconfirm,
  Select,
  Tooltip,
  DatePicker,
  Row,
  Col,
} from 'antd'
import TextArea from 'antd/lib/input/TextArea'

export default function ActivityQuestionModalTwoSept15({
  setProgressActivity,
  setIsModalActivityOpen,
  setIsUserWatchedCurrentVid,
  viewUserAnswer,
  userData,
}) {
  const user = userData

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [activityTwoAnswers, setActivityTwoAnswers] = useState({})
  const [activityTwoID, setActivityTwoID] = useState(null)
  const [isShowActivity, setIsShowActivity] = useState(false)
  const [countCheck, setCountCheck] = useState(0)

  const [isModalVisibleConfirm, setIsModalVisibleConfirm] = useState(false)

  useEffect(() => {
    if (viewUserAnswer.length > 0) {
      setActivityTwoAnswers(JSON.parse(viewUserAnswer[0].answer))
      setIsShowActivity(true)
    }
  }, [viewUserAnswer])

  const submitAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityTwoAnswer, {
          input: {
            id: activityTwoID,
            answer: JSON.stringify(activityTwoAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: true,
          },
        })
      )

      notification['success']({
        message: 'Success',
        description: 'Your answer is saved as final answer.',
      })
      setIsModalActivityOpen(false)
      setProgressActivity('3')
      setTimeout(() => {
        setIsShowActivity(false)
        console.log('false')
        setIsModalVisible(false)
      }, 3000)
    } catch (err) {
      console.log(err)
    }
  }

  const updateAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(updateActivityTwoAnswer, {
          input: {
            id: activityTwoID,
            answer: JSON.stringify(activityTwoAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
          },
        })
      )
    } catch (err) {
      console.log(err)
    }
  }

  const createAnswerItem = async () => {
    try {
      const submitTimeOnly = await API.graphql(
        graphqlOperation(createActivityTwoAnswer, {
          input: {
            userID: user.id,
            trainingAnnouncementID: 'acb7d867-7114-49a5-81f0-8ee28eb2828d',
            answer: JSON.stringify(activityTwoAnswers),
            timeSubmitted: moment(new Date()).format('YYYY-MM-DD HH:mm:ss A'),
            isSubmittedByUser: false,
          },
        })
      )

      setActivityTwoID(submitTimeOnly.data.createActivityTwoAnswer.id)
      setIsShowActivity(true)
    } catch (err) {
      console.log(err)
    }
  }

  const getActivityTwoAnswerByUserID = async () => {
    try {
      const dataFetched = await API.graphql(
        graphqlOperation(ActivityTwoAnswerByUserID, { userID: user.id })
      )
      const itemsFetched = dataFetched.data.ActivityTwoAnswerByUserID.items
        .filter((item) => {
          if (
            item.trainingAnnouncementID ==
            'acb7d867-7114-49a5-81f0-8ee28eb2828d'
          ) {
            return item
          }
        })
        .sort((a, b) => {
          const timeSubmittedA = moment(a.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')
          const timeSubmittedB = moment(b.timeSubmitted, 'YYYY-MM-DD HH:mm:ss')

          // Sort in descending order
          return timeSubmittedB.diff(timeSubmittedA)
        })

      if (itemsFetched.length) {
        if (itemsFetched[0].isSubmittedByUser === true) {
          setIsShowActivity(false)
          console.log('false')
        } else {
          setIsShowActivity(true)
          const initialAnswers = {}

          for (let i = 1; i <= questions.length; i++) {
            initialAnswers[`Question${i}`] = {
              Answer1: '',
              Answer2: '',
              Answer3: '',
              Answer4: '',
              Answer5: '',
            }
          }

          if (Object.keys(JSON.parse(itemsFetched[0].answer)).length > 0) {
            setActivityTwoAnswers(JSON.parse(itemsFetched[0].answer))
            console.log('qwe')
          } else {
            setActivityTwoAnswers(initialAnswers)
            console.log('ewq')
          }

          if (itemsFetched[0].id !== activityTwoID) {
            setActivityTwoID(itemsFetched[0].id)
          }
        }
      } else {
        createAnswerItem()
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (!viewUserAnswer.length) {
      console.log('test1')
      if (isModalVisible) {
        if (user) {
          getActivityTwoAnswerByUserID()
        }
      }
    }
  }, [isModalVisible])

  useEffect(() => {
    if (!viewUserAnswer.length) {
      console.log('test1')
      if (countCheck > 0) {
        updateAnswerItem()
        console.log('test1')
      }
    }
  }, [countCheck])

  useEffect(() => {
    if (!viewUserAnswer.length) {
      console.log('test1')
      let timeoutId

      const startTimer = () => {
        timeoutId = setTimeout(() => {
          if (isShowActivity && activityTwoID) {
            setCountCheck((prevCount) => prevCount + 10000)
          }

          startTimer() // Recursively call startTimer after 10 seconds
        }, 10000)
      }

      startTimer() // Start the initial timer

      return () => {
        clearTimeout(timeoutId) // Cleanup the timer on component unmount
      }
    }
  }, [isShowActivity, activityTwoID])

  useEffect(() => {
    if (!viewUserAnswer.length) {
      console.log('test1')
      if (isModalVisible) {
        const initialAnswers = {}

        for (let i = 1; i <= questions.length; i++) {
          initialAnswers[`Question${i}`] = {
            Answer1: '',
            Answer2: '',
            Answer3: '',
            Answer4: '',
            Answer5: '',
          }
        }

        setActivityTwoAnswers(initialAnswers)
      }
    }
  }, [isModalVisible])

  const handleChoiceChange = (questionName, choiceValue, index) => {
    // Validate that choiceValue is a single character between '1' and '5'
    if (/^[1-5]?$/.test(choiceValue)) {
      const ans = `Answer${index + 1}`
      const newAnswers = { ...activityTwoAnswers }
      newAnswers[questionName][ans] = choiceValue
      setActivityTwoAnswers(newAnswers)
    } else {
      // Handle invalid input (e.g., show an error message)
      console.error(
        'Invalid input. Please enter a single character between 1 and 5.'
      )
      // You can also show an error message to the user or take other actions.
    }
  }

  const handleSubmitActivityAnswer = async () => {
    try {
      function hasNoLengthInObject(obj) {
        const question1Obj = obj['Question1']
        const question2Obj = obj['Question2']

        if (checkDuplicates(question1Obj)) {
          return true
        }

        if (checkDuplicates(question2Obj)) {
          return true
        }

        return false
      }

      function checkDuplicates(obj) {
        for (const questionKey in obj) {
          if (obj.hasOwnProperty(questionKey)) {
            const currentAnswerObj = obj[questionKey]

            for (const answerKey in currentAnswerObj) {
              if (currentAnswerObj.hasOwnProperty(answerKey)) {
                const currentValue = currentAnswerObj[answerKey]
                if (
                  currentValue === null ||
                  currentValue === undefined ||
                  currentValue === ''
                ) {
                  return true
                }

                for (const otherQuestionKey in obj) {
                  if (
                    otherQuestionKey !== questionKey &&
                    obj.hasOwnProperty(otherQuestionKey)
                  ) {
                    const otherAnswerObj = obj[otherQuestionKey]

                    for (const otherAnswerKey in otherAnswerObj) {
                      if (otherAnswerObj.hasOwnProperty(otherAnswerKey)) {
                        const otherValue = otherAnswerObj[otherAnswerKey]
                        if (currentValue === otherValue) {
                          return true
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }

        return false
      }

      const checkObject = hasNoLengthInObject(activityTwoAnswers)

      if (checkObject) {
        setIsModalVisibleConfirm(true)
      } else {
        submitAnswerItem()
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleSubmitActivityAnswerConfirm = async () => {
    submitAnswerItem()
  }

  const questions = [
    {
      question:
        '1. Jam was told to submit a document for a new project that must be completed by the end of the month. Following the parameters of a Complete Staff Work (CSW), what does she need to do?',
      choices: [
        'Then she talks to Jan and Jane of finance to collaborate on the project.',
        'Then they now gather the necessary documents to write and submit a CSW.',
        'Both Jan and Jane give their feedback and opinion.',
        'She conducted extensive research and talked to experts in the field.',
        'Then they set a meeting to discuss the data that Jam has collected.',
      ],
      answerKey: [2, 5, 4, 1, 3],
    },
    {
      question:
        '2. Miguel is from the Office of the City Assessor’s and he was tasked to write a memorandum for the Office of the City Administrator that needed funding for a training to be approved. It’s his first time to write a memo. What steps should he take in order to accomplish this task?',
      choices: [
        'Write a training proposition in support of the points made.',
        'Understand what is being proposed and write what the training is about.',
        'Provide a background of the training and its contribution to the department.',
        'Enumerate the contents and key results of the training.',
        'List the attachments that will further understand what is being proposed.',
      ],
      answerKey: [4, 1, 2, 3, 5],
    },
  ]

  return (
    <>
      <Button
        className='bg-success text-white'
        onClick={() => setIsModalVisible(true)}
      >
        {!viewUserAnswer ? 'Go to activity' : 'View'}
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title='Activity'
        width={1000}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisible(false)}>
            Cancel
          </Button>,
          <Button
            hidden={viewUserAnswer.length > 0}
            key='submit'
            type='primary'
            disabled={isShowActivity ? false : true}
            onClick={handleSubmitActivityAnswer}
          >
            Submit
          </Button>,
        ]}
      >
        <div className='font-Mont'>
          <Row className='mb-3' justify='center'>
            <h3 className='text-center'>ACTIVITY 2 for MODULE 2</h3>
          </Row>
          <Row className='mb-3' justify='left'>
            <h3>Sequencing the events from 1 to 5 (2 items, 10 pts): </h3>
          </Row>
        </div>
        {isShowActivity ? (
          <div className='font-Mont'>
            {questions.map((questionObj, index1) => (
              <div key={index1}>
                {activityTwoAnswers ? (
                  <div className='mt-3'>
                    <p className='p-0 m-0'>{questionObj.question}</p>
                    {questionObj.choices.map((choice, index) => {
                      const ans = `Answer${index + 1}`
                      const questionName = `Question${index1 + 1}`
                      return (
                        <div className='d-flex p-1' key={index}>
                          <input
                            value={activityTwoAnswers[questionName][ans]}
                            onChange={(e) =>
                              handleChoiceChange(
                                `Question${index1 + 1}`,
                                e.target.value,
                                index
                              )
                            }
                            name={`Question${index + 1}`}
                            className='mr align-self-start mt-md-0 mt-1 align-self-md-center col-1 border-top-0 border-start-0 border-end-0 border-1 text-center'
                            id={`question${`Question${index + 1}`}-choice-${
                              choice.value
                            }`}
                          />
                          <label
                            className='m-0 ms-3'
                            htmlFor={`question${`Question${
                              index + 1
                            }`}-choice-${choice}`}
                          >
                            {choice}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                ) : /* <MultipleChoiceQuestion
                    question={questionObj.question}
                    choices={questionObj.choices}
                    questionName={`Question${index + 1}`}
                  /> */

                null}
              </div>
            ))}
          </div>
        ) : (
          <>
            <Row className='mb-3 mt-5' justify='left'>
              <h3>Activity answer was already submitted as final.</h3>
            </Row>
          </>
        )}
      </Modal>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        width={800}
        visible={isModalVisibleConfirm}
        onCancel={() => setIsModalVisibleConfirm(false)}
        footer={[
          <Button key='back' onClick={() => setIsModalVisibleConfirm(false)}>
            Cancel
          </Button>,
          <Button
            key='submit'
            type='primary'
            disabled={isShowActivity || viewUserAnswer.length ? false : true}
            // loading={loading}
            onClick={handleSubmitActivityAnswerConfirm}
          >
            Submit
          </Button>,
        ]}
      >
        <Row className='mt-3' justify='center'>
          <h4>Please check your answer</h4>
        </Row>
        <Row className='mt-3' justify='center'>
          <h4>
            Either you have repeated a number in item question, or have a blank
            answer on it.
          </h4>
        </Row>
        <Row justify='center'>
          <h4>Click Submit to proceed</h4>
        </Row>
      </Modal>
    </>
  )
}
