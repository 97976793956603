/**
 * Title: editTraining.js
 * Description: This is a file that contains the edit training component
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/editTraining.js
 * History:
 * - 08/06/2024 - John Bazil Valdez
 * - Add all fields for editing training announcements.
 **/

import { useState, useEffect } from 'react'

import DatePicker from 'react-multi-date-picker'
import DatePanel from 'react-multi-date-picker/plugins/date_panel'

import {
  Button,
  Modal,
  Col,
  Row,
  notification,
  DatePicker as AntdDatePicker,
  Select,
  TimePicker,
  Input,
} from 'antd'

import { API, graphqlOperation, Auth } from 'aws-amplify'
import { updateTrainingAnnouncement } from '../../api/mutations'
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3'

import moment from 'moment'
import AssessmentMaker from './assessmentMaker'
import SelectAssessmentQuestion from './selectAssessmentQuestion'
import EvaluationMaker from './evaluationMaker'
import SelectEvaluationQuestion from './selectEvaluationQuestion'
import ManageTrainingsNumberOfParticipants from './manageTrainingsNumberOfParticipants'
import { listTrainingAnnouncements } from '../../api/queries'
import ReactQuill from 'react-quill'
import listOfDepartments from '../../utilities/dept'
import EndorsementLetter from './endorsementLetter'
import EditEndorsementLetterText from './editEndorsementLetterText'
import EditAssessmentQuestion from './editAssessmentQuestion'
import EditEvaluationQuestion from './editEvaluationQuestion'

const { Option } = Select
const { TextArea } = Input

export default function EditTraining({
  refresh,
  viewCourse,
  setOpenView,
  userData,
}) {
  const [openViewEditModal, setOpenViewViewEditModal] = useState(false)

  const [announceUpload, setAnnounceUpload] = useState([])
  const [announceDate, setAnnounceDate] = useState(null)
  const [annouceArrayOfDate, setAnnouceArrayOfDate] = useState(null)
  const [announceStartTime, setAnnouceStartTime] = useState(null)
  const [announceEndTime, setAnnouceEndTime] = useState(null)
  const [tpData, setTpData] = useState(null)
  const [cdData, setCdData] = useState(null)
  const [courseTitle, setCourseTitle] = useState(null)
  const [courseCategory, setCourseCategory] = useState([])
  const [optionSubCategory, setOptionSubCategory] = useState(null)
  const [courseSubCategory, setCourseSubCategory] = useState(null)
  const [showPostAssessment, setShowPostAssessment] = useState()
  const [participantLimit, setParticipantLimit] = useState(0)
  const [pTDateOpen, setPTDateOpen] = useState(null)
  const [pTTimeOpen, setPTTimeOpen] = useState(null)
  const [pTDateClose, setPTDateClose] = useState(null)
  const [pTTimeClose, setPTTimeClose] = useState(null)
  const [evalID, setEvalID] = useState(null)
  const [assessmentID, setAssessmentID] = useState(null)
  const [manageParticipantsModal, setManageParticipantsModal] = useState(false)
  const [departments, setDepartments] = useState([])
  const [listOfDepartmentsOrignal, setListOfDepartmentsOriginal] = useState([])
  const [departmentsLimitValue, setDepartmentsLimitValue] = useState({})
  const [textDisplayBeforeTrainingOpen, setTextDisplayBeforeTrainingOpen] =
    useState(null)
  const [initialSubcateg, setInitialSubcateg] = useState(
    viewCourse.courseSubCategory
  )
  const [endorsementLetterID, setEndorsementLetterID] = useState(
    viewCourse.stepThreeID
  )
  const [isLink, setIsLink] = useState(false)
  const [isEndorsementLetterRequired, setIsEndorsementLetterRequired] =
    useState()
  const [isPassPreAssessmentRequired, setIsPassPreAssessmentRequired] =
    useState(false)
  const [isSeminar, setIsSeminar] = useState(false)
  const [selectedTraining, setSelectedTraining] = useState(null)
  const [isTestEvent, setIsTestEvent] = useState(false)
  const [trainings, setTrainings] = useState([])
  const [listOfTrainings, setListOfTrainings] = useState([
    {
      value: '',
      date: '',
      label: 'Select training',
      title: 'Select training',
      disabled: true,
    },
  ])
  const fetchTrainings = async () => {
    try {
      let token = null // Initialize the token variable
      let foundData = []
      do {
        const result = await API.graphql({
          query: listTrainingAnnouncements,
          authMode: 'API_KEY',
          variables: {
            limit: 1000,
            nextToken: token, // Pass the token variable as the nextToken value
          },
        })

        foundData = [
          ...foundData,
          ...result.data.listTrainingAnnouncements.items,
        ]

        token = result.data.listTrainingAnnouncements.nextToken // Update the token with the nextToken from the result
      } while (token)

      const forListTrainings = foundData.map((x) => ({
        date: x.date,
        value: x.id,
        title: x.title,
        label: (
          <span>
            <strong>{x.date}</strong> - {x.title}
          </span>
        ),
      }))
      setListOfTrainings([...listOfTrainings, ...forListTrainings])
      setTrainings(foundData)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchTrainings()
  }, [])

  function isNestedArray(arr) {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i])) {
        return true
      }
    }
    return false
  }

  function compareOptions(optionA, optionB) {
    const dateA = new Date(optionA.date)
    const dateB = new Date(optionB.date)
    if (dateA > dateB) {
      return -1
    }
    if (dateA < dateB) {
      return 1
    }
    return 0
  }

  /* List of departments */
  useEffect(() => {
    const departments = listOfDepartments()
    setDepartments(departments)
    setListOfDepartmentsOriginal(departments)
  }, [])

  const departmentOptions = [
    {
      value: '',
      label: 'Select department',
      disabled: true,
    },
    ...departments.map((dept) => ({
      value: dept,
      label: dept,
    })),
  ]

  const subCategCore = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Public Service Ethics & Accountability Orientation & Work Ethics',
      label: 'Public Service Ethics & Accountability Orientation & Work Ethics',
    },
    {
      value:
        'Administrative Discipline: Orientation of Values & Ethics in the Workplace',
      label:
        'Administrative Discipline: Orientation of Values & Ethics in the Workplace',
    },
    {
      value: 'Social Media Responsibility',
      label: 'Social Media Responsibility',
    },
    {
      value: 'Customer Service Satisfaction',
      label: 'Customer Service Satisfaction',
    },
  ]

  const subCategLeader = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Leadership & Management Program',
      label: 'Leadership & Management Program',
    },
    {
      value: 'Conflict Management',
      label: 'Conflict Management',
    },
    {
      value: 'Effective Management & Supervision',
      label: 'Effective Management & Supervision',
    },
    {
      value: 'Total Quality Management',
      label: 'Total Quality Management',
    },
    {
      value: 'Project Management',
      label: 'Project Management',
    },
    {
      value: 'Culture Building',
      label: 'Culture Building',
    },
    {
      value: 'Coaching & Mentoring',
      label: 'Coaching & Mentoring',
    },
    {
      value: 'Managing Teams & People',
      label: 'Managing Teams & People',
    },
    {
      value: 'Expanded Team Building',
      label: 'Expanded Team Building',
    },
    {
      value: 'Strategic Management Planning',
      label: 'Strategic Management Planning',
    },
    {
      value: 'Goal and Metrics Setting',
      label: 'Goal and Metrics Setting',
    },
    {
      value: 'Supervisory Development Course',
      label: 'Supervisory Development Course',
    },
    {
      value: 'Change Management',
      label: 'Change Management',
    },
  ]

  const subCategOrg = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Business/Technical Writing Skills',
      label: 'Business/Technical Writing Skills',
    },
    {
      value: 'English Proficiency & Effective Communication',
      label: 'English Proficiency & Effective Communication',
    },
    {
      value: 'Effective Presentation',
      label: 'Effective Presentation',
    },
    {
      value: 'Public Speaking',
      label: 'Public Speaking',
    },
    {
      value: 'Personality Development in Local Government',
      label: 'Personality Development in Local Government',
    },
    {
      value: 'Time Management',
      label: 'Time Management',
    },
    {
      value: 'Obtaining and Providing Positive Work Attitude',
      label: 'Obtaining and Providing Positive Work Attitude',
    },
    {
      value: 'Strategic HRM: Providing Assistance to Planning and Goal Setting',
      label: 'Strategic HRM: Providing Assistance to Planning and Goal Setting',
    },
    {
      value: 'Project Management',
      label: 'Project Management',
    },
    {
      value: 'Data Management & Analysis/Chart',
      label: 'Data Management & Analysis/Chart',
    },
    {
      value: 'Data Privacy',
      label: 'Data Privacy',
    },
    {
      value: 'Critical Thinking',
      label: 'Critical Thinking',
    },
    {
      value: 'Innovative Thinking',
      label: 'Innovative Thinking',
    },
    {
      value: 'Design Thinking',
      label: 'Design Thinking',
    },
  ]

  const subCategFunctional = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Program One',
      label: 'Program One',
    },
    {
      value: 'Program Two',
      label: 'Program Two',
    },
    {
      value: 'Program Three',
      label: 'Program Three',
    },
    {
      value: 'Program Four',
      label: 'Program Four',
    },
  ]

  const subCategAdditional = [
    {
      value: '',
      label: '',
      disabled: true,
    },
    {
      value: 'Program One',
      label: 'Program One',
    },
    {
      value: 'Program Two',
      label: 'Program Two',
    },
    {
      value: 'Program Three',
      label: 'Program Three',
    },
    {
      value: 'Program Four',
      label: 'Program Four',
    },
  ]

  useEffect(() => {
    if (courseCategory == 'CORE COMPETENCIES') {
      setOptionSubCategory(subCategCore)
      setCourseSubCategory(null)
    } else if (courseCategory == 'ORGANIZATIONAL COMPETENCIES') {
      setOptionSubCategory(subCategOrg)
      setCourseSubCategory(null)
    } else if (courseCategory == 'LEADERSHIP COMPETENCIES') {
      setOptionSubCategory(subCategLeader)
      setCourseSubCategory(null)
    } else if (courseCategory == 'FUNCTIONAL/TECHNICAL') {
      setOptionSubCategory(subCategFunctional)
      setCourseSubCategory(null)
    } else if (courseCategory == 'Additional Training Programs') {
      setOptionSubCategory(subCategAdditional)
      setCourseSubCategory(null)
    } else {
      setOptionSubCategory([])
    }
  }, [courseCategory])

  const handleDate = (selectedDates) => {
    const formattedDates = selectedDates.map((date) =>
      date.format('YYYY-MM-DD')
    )

    setAnnounceDate(formattedDates)
  }

  const handleTrainingStartTime = (time, timeString) => {
    setAnnouceStartTime(timeString)
  }

  const handleTrainingEndTime = (time, timeString) => {
    setAnnouceEndTime(timeString)
  }

  const handleCourseTitle = (e) => {
    setCourseTitle(e.target.value)
  }

  const handleCourseCategory = (e) => {
    setCourseCategory(e)
    setCourseSubCategory(null)
    setInitialSubcateg(null)
  }
  const handleCourseSubCategory = (e) => {
    setCourseSubCategory(e)
  }

  const handleTargetParticipants = (e) => {
    setTpData(e)
  }

  const handleAdditionalText = (content) => {
    setTextDisplayBeforeTrainingOpen(content)
  }

  const handleCourseDesc = (e) => {
    setCdData(e)
  }

  const handleHasAssessment = (e) => {
    setShowPostAssessment(e.target.value)
  }

  const handlePostTrainOpenDate = (date, dateString) => {
    setPTDateOpen(dateString)
  }

  const handlePostTrainOpenTime = (time, timeString) => {
    setPTTimeOpen(timeString)
  }

  const handlePostTrainCloseDate = (date, dateString) => {
    setPTDateClose(dateString)
  }

  const handlePostTrainCloseTime = (time, timeString) => {
    setPTTimeClose(timeString)
  }

  const handleIsPassPreAssessmentRequiredInput = (e) => {
    setIsPassPreAssessmentRequired(e.target.value)
    setShowPostAssessment(e.target.value === 'true' ? true : false)
  }

  const handleSelectTrainingToLink = (value) => {
    setSelectedTraining(value)
  }

  const handleUnlinkTraining = () => {
    setIsLink(false)
    setSelectedTraining(null)
  }

  const showUploadNames = announceUpload.map((o, index) => {
    return (
      <span className={'fw-bold'} key={index}>
        {o.name}
      </span>
    )
  })

  const closeUploadPubMat = () => {
    setOpenViewViewEditModal(false)
    setAnnounceUpload([])
    setAnnounceDate(null)
    setTpData(null)
    setCdData(null)
    setCourseTitle(null)
  }

  const handleSubmitTraining = async (e) => {
    e.preventDefault()
    try {
      if (announceDate) {
        if (courseTitle) {
          if (courseCategory) {
            if (courseSubCategory) {
              if (tpData) {
                if (cdData) {
                  if (participantLimit) {
                    if (pTDateOpen) {
                      if (pTTimeOpen) {
                        if (pTDateClose) {
                          if (pTTimeClose) {
                            if (
                              moment(
                                `${pTDateOpen} ${pTTimeOpen}`
                              ).isValid() === true &&
                              moment(
                                `${pTDateClose} ${pTTimeClose}`
                              ).isValid() === true
                            ) {
                              if (announceUpload.length >= 1) {
                                const credentials =
                                  await Auth.currentCredentials()
                                const accessKeyId = credentials.accessKeyId
                                const secretAccessKey =
                                  credentials.secretAccessKey
                                const sessionToken = credentials.sessionToken

                                const s3 = new S3Client({
                                  region: 'ap-southeast-1',
                                  credentials: {
                                    accessKeyId: accessKeyId,
                                    secretAccessKey: secretAccessKey,
                                    sessionToken: sessionToken,
                                  },
                                })

                                Promise.all(
                                  announceUpload.map((value) => {
                                    return new Promise(function (
                                      resolve,
                                      reject
                                    ) {
                                      const params = {
                                        Bucket: 's3etnahris133956-dev',
                                        Key: `uploadedByAdmin/${value.name}`,
                                        Body: value,
                                      }

                                      const command = new PutObjectCommand(
                                        params
                                      )
                                      s3.send(command)
                                      const url = `https://${params.Bucket}.s3.ap-southeast-1.amazonaws.com/${params.Key}`

                                      resolve(url)
                                    })
                                  })
                                ).then(async (res) => {
                                  const locations = res.map((item) => {
                                    const oldLoc = item
                                    return oldLoc
                                  })
                                  const input = {
                                    id: viewCourse.id,
                                    location: JSON.stringify(locations),
                                    date: Array.isArray(announceDate)
                                      ? announceDate[0]
                                      : announceDate,
                                    arrayOfDates: Array.isArray(announceDate)
                                      ? JSON.stringify(announceDate)
                                      : annouceArrayOfDate || null,
                                    trainingTimeStart: announceStartTime,
                                    trainingTimeEnd: announceEndTime,
                                    title: courseTitle,
                                    courseDescription: cdData,
                                    targetParticipants: tpData,
                                    postTrainOpen: moment(
                                      `${pTDateOpen} ${pTTimeOpen}`
                                    ).format('ddd MMM D YYYY hh:mm:ss a'),
                                    postTrainClose: moment(
                                      `${pTDateClose} ${pTTimeClose}`
                                    ).format('ddd MMM D YYYY hh:mm:ss a'),
                                    courseCategory,
                                    courseSubCategory,
                                    hasAssessment: showPostAssessment,
                                    participantLimit,
                                    evalID: evalID,
                                    assessmentID: assessmentID,
                                    limitPerDept: JSON.stringify([
                                      departmentsLimitValue,
                                    ]),
                                    additionalTexts:
                                      textDisplayBeforeTrainingOpen,
                                    isSeminar,
                                    stepThreeID: endorsementLetterID,
                                    isEndorsementRequired:
                                      isEndorsementLetterRequired,
                                    isPassPreAssessmentRequired,
                                    parentTrainingID: selectedTraining,
                                    isTestEvent,
                                  }

                                  const adminUpload = await API.graphql(
                                    graphqlOperation(
                                      updateTrainingAnnouncement,
                                      { input }
                                    )
                                  )

                                  if (
                                    adminUpload.data.updateTrainingAnnouncement
                                  ) {
                                    notification['success']({
                                      message: 'Success',
                                      description:
                                        'Training announcement edited.',
                                    })

                                    setOpenViewViewEditModal(false)
                                    refresh()
                                    setOpenView(false)
                                  }
                                })
                              } else {
                                notification['info']({
                                  message: 'Info',
                                  description:
                                    'You will be uploading the edited Training Announcement using the same poster.',
                                })

                                setTimeout(async () => {
                                  const input = {
                                    id: viewCourse.id,
                                    date: Array.isArray(announceDate)
                                      ? announceDate[0]
                                      : announceDate,
                                    arrayOfDates: Array.isArray(announceDate)
                                      ? JSON.stringify(announceDate)
                                      : annouceArrayOfDate || null,
                                    trainingTimeStart: announceStartTime,
                                    trainingTimeEnd: announceEndTime,
                                    title: courseTitle,
                                    courseDescription: cdData,
                                    targetParticipants: tpData,
                                    postTrainOpen: moment(
                                      `${pTDateOpen} ${pTTimeOpen}`
                                    ).format('ddd MMM D YYYY hh:mm:ss a'),
                                    postTrainClose: moment(
                                      `${pTDateClose} ${pTTimeClose}`
                                    ).format('ddd MMM D YYYY hh:mm:ss a'),
                                    courseCategory,
                                    courseSubCategory,
                                    hasAssessment: showPostAssessment,
                                    participantLimit,
                                    evalID: evalID,
                                    assessmentID: assessmentID,
                                    limitPerDept: JSON.stringify([
                                      departmentsLimitValue,
                                    ]),
                                    additionalTexts:
                                      textDisplayBeforeTrainingOpen,
                                    isSeminar,
                                    stepThreeID: endorsementLetterID,
                                    isEndorsementRequired:
                                      isEndorsementLetterRequired,
                                    isPassPreAssessmentRequired,
                                    parentTrainingID: selectedTraining,
                                    isTestEvent,
                                  }

                                  const adminUpload = await API.graphql(
                                    graphqlOperation(
                                      updateTrainingAnnouncement,
                                      { input }
                                    )
                                  )
                                  if (
                                    adminUpload.data.updateTrainingAnnouncement
                                  ) {
                                    const updateLinkedTrainings = async () => {
                                      const updateTraining = async (
                                        trainingID,
                                        linkedIDs
                                      ) => {
                                        await API.graphql(
                                          graphqlOperation(
                                            updateTrainingAnnouncement,
                                            {
                                              input: {
                                                id: trainingID,
                                                linkedTrainingAnnouncementIDs:
                                                  JSON.stringify(linkedIDs),
                                              },
                                            }
                                          )
                                        )
                                      }

                                      const getLinkedTrainingIDs = (
                                        trainingID
                                      ) => {
                                        let linkedIDs = trainings
                                          .filter((x) => x.id === trainingID)
                                          .map((x) =>
                                            JSON.parse(
                                              x.linkedTrainingAnnouncementIDs
                                            )
                                          )[0]
                                        return linkedIDs ? linkedIDs : []
                                      }

                                      if (
                                        viewCourse.parentTrainingID &&
                                        !isLink
                                      ) {
                                        // Unlink the training
                                        let existingTrainingLinked =
                                          getLinkedTrainingIDs(
                                            viewCourse.parentTrainingID
                                          ).filter((id) => id !== viewCourse.id)

                                        await updateTraining(
                                          viewCourse.parentTrainingID,
                                          existingTrainingLinked
                                        )
                                      } else if (
                                        !viewCourse.parentTrainingID &&
                                        isLink
                                      ) {
                                        // Link the training
                                        let existingTrainingLinked =
                                          getLinkedTrainingIDs(selectedTraining)
                                        existingTrainingLinked.push(
                                          viewCourse.id
                                        )

                                        await updateTraining(
                                          selectedTraining,
                                          existingTrainingLinked
                                        )
                                      } else if (
                                        viewCourse.parentTrainingID !==
                                          selectedTraining &&
                                        selectedTraining &&
                                        isLink
                                      ) {
                                        // Change the linked training
                                        let oldTrainingLinked =
                                          getLinkedTrainingIDs(
                                            viewCourse.parentTrainingID
                                          ).filter((id) => id !== viewCourse.id)

                                        await updateTraining(
                                          viewCourse.parentTrainingID,
                                          oldTrainingLinked
                                        )

                                        let newTrainingLinked =
                                          getLinkedTrainingIDs(selectedTraining)
                                        newTrainingLinked.push(viewCourse.id)

                                        await updateTraining(
                                          selectedTraining,
                                          newTrainingLinked
                                        )
                                      }
                                    }

                                    updateLinkedTrainings()

                                    notification['success']({
                                      message: 'Success',
                                      description:
                                        'Training announcement edited.',
                                    })

                                    setOpenViewViewEditModal(false)
                                    refresh()
                                    setOpenView(false)
                                  }
                                }, 1000)
                              }
                            } else {
                              notification['error']({
                                message: 'Error',
                                description:
                                  "There's something wrong with the Post Training Open/Close Dates and/or Times. Please re-enter each accordingly.",
                              })
                            }
                          } else {
                            notification['error']({
                              message: 'Error',
                              description:
                                'Missing Post Training Time of closing.',
                            })
                          }
                        } else {
                          notification['error']({
                            message: 'Error',
                            description:
                              'Missing Post Training Date of closing.',
                          })
                        }
                      } else {
                        notification['error']({
                          message: 'Error',
                          description: 'Missing Post Training Time of opening.',
                        })
                      }
                    } else {
                      notification['error']({
                        message: 'Error',
                        description: 'Missing Post Training Date of opening.',
                      })
                    }
                  } else {
                    notification['error']({
                      message: 'Error',
                      description:
                        'Missing Participant Limit for the Training Announcement.',
                    })
                  }
                } else {
                  notification['error']({
                    message: 'Error',
                    description:
                      'Missing Course Description for the Training Announcement.',
                  })
                }
              } else {
                notification['error']({
                  message: 'Error',
                  description:
                    'Missing Target Participants for the Training Announcement.',
                })
              }
            } else {
              notification['error']({
                message: 'Error',
                description:
                  'Missing Sub-category for the Training Announcement.',
              })
            }
          } else {
            notification['error']({
              message: 'Error',
              description: 'Missing Category for the Training Announcement.',
            })
          }
        } else {
          notification['error']({
            message: 'Error',
            description: 'Missing Title for the Training Announcement.',
          })
        }
      } else {
        notification['error']({
          message: 'Error',
          description: 'Missing date of Training Announcement.',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleOpenView = () => {
    const {
      courseCategory,
      courseDescription,
      courseSubCategory,
      date,
      arrayOfDates,
      trainingTimeStart,
      trainingTimeEnd,
      hasAssessment,
      participantLimit,
      postTrainClose,
      postTrainOpen,
      targetParticipants,
      title,
      assessmentID,
      evalID,
      limitPerDept,
      additionalTexts,
      isSeminar,
      isEndorsementRequired,
      isPassPreAssessmentRequired,
      parentTrainingID,
      isTestEvent,
    } = viewCourse

    const oldValue = isNestedArray(JSON.parse(limitPerDept))
      ? JSON.parse(viewCourse.limitPerDept).flat()
      : JSON.parse(viewCourse.limitPerDept)

    try {
      setAnnounceDate(date)
      setAnnouceArrayOfDate(arrayOfDates)
      setAnnouceStartTime(trainingTimeStart)
      setAnnouceEndTime(trainingTimeEnd)
      setTpData(targetParticipants)
      setCdData(courseDescription)
      setCourseTitle(title)
      setCourseCategory(courseCategory)
      setTextDisplayBeforeTrainingOpen(additionalTexts)
      setParticipantLimit(participantLimit)
      setPTDateOpen(moment(postTrainOpen).format('YYYY-MM-DD'))
      setPTTimeOpen(moment(postTrainOpen).format('h:mm:ss A'))
      setPTDateClose(moment(postTrainClose).format('YYYY-MM-DD'))
      setPTTimeClose(moment(postTrainClose).format('h:mm:ss A'))
      setAnnounceUpload([])
      setIsLink(parentTrainingID ? true : false)
      setSelectedTraining(parentTrainingID)
      setIsTestEvent(isTestEvent)

      setShowPostAssessment(hasAssessment)
      setEvalID(evalID)
      setAssessmentID(assessmentID)
      setDepartmentsLimitValue(oldValue ? oldValue[0] : [])

      setIsSeminar(isSeminar ? true : false)
      setIsEndorsementLetterRequired(isEndorsementRequired)
      setIsPassPreAssessmentRequired(isPassPreAssessmentRequired)
      setTimeout(() => {
        setOpenViewViewEditModal(true)
        setCourseSubCategory(courseSubCategory)
      }, 1500)
    } catch (err) {
      console.log(err)
    }
  }

  const handleIsSeminarInput = (e) => {
    setIsSeminar(e.target.value)
  }

  const handleTestEventInput = (e) => {
    setIsTestEvent(e.target.value)
  }

  return (
    <>
      <Button onClick={handleOpenView}>Edit Training</Button>

      <Modal
        maskClosable={false}
        title={'Edit details of the Course / Training'}
        centered
        visible={openViewEditModal}
        onOk={() => setOpenViewViewEditModal(false)}
        onCancel={() => setOpenViewViewEditModal(false)}
        width={700}
        footer={null}
        destroyOnClose={true}
      >
        <form onSubmit={handleSubmitTraining}>
          <div className={'border mb-3'}>
            <Row justify='center'>
              <span>
                This part is for creating a test training announcements
              </span>
            </Row>
            <Row justify='center'>
              <div className='reg1-row'>
                <span className='loginInput-label'>
                  Are you uploading a Test Event?
                </span>
                <Row justify='center'>
                  <em>For Testing purposes only</em>
                </Row>
              </div>
            </Row>
            <Row onChange={handleTestEventInput} justify='center'>
              <div className={'d-flex align-items-center me-3'}>
                <label
                  htmlFor='test-event-yes'
                  className={'loginInput-label m-0'}
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  YES
                </label>
                <input
                  value={true}
                  defaultChecked={isTestEvent}
                  className={'ms-2 test-event'}
                  name='test-event'
                  id='test-event-yes'
                  type='radio'
                />
              </div>

              <div className={'d-flex align-items-center ms-3'}>
                <label
                  htmlFor='test-event-no'
                  className={'loginInput-label m-0'}
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  NO
                </label>
                <input
                  defaultChecked={!isTestEvent}
                  value={false}
                  className={'ms-2 test-event'}
                  name='test-event'
                  id='test-event-no'
                  type='radio'
                />
              </div>
            </Row>

            <Row justify='center'>
              <span>
                This part is for creating a test training announcements
              </span>
            </Row>
          </div>
          <Row className='mt-3' justify='center'>
            <div className='reg1-row'>
              <span className='loginInput-label'>
                Are you uploading a Seminar?
              </span>
            </div>
          </Row>

          <Row
            className='mb-3'
            onChange={handleIsSeminarInput}
            justify='center'
          >
            <div className={'d-flex align-items-center me-3'}>
              <label
                htmlFor='isSeminarYes'
                className={'loginInput-label m-0'}
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                YES
              </label>
              <input
                defaultChecked={isSeminar === true ? true : false}
                value={true}
                className={'ms-2 test-event'}
                name='seminar'
                id='isSeminarYes'
                type='radio'
              />
            </div>

            <div className={'d-flex align-items-center ms-3'}>
              <label
                htmlFor='isSeminarYes'
                className={'loginInput-label m-0'}
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                NO
              </label>
              <input
                defaultChecked={isSeminar === true ? false : true}
                value={false}
                className={'ms-2 test-event'}
                name='seminar'
                id='isSeminarYes'
                type='radio'
              />
            </div>
          </Row>

          <div className='d-flex flex-column align-items-center justify-content-center mt-3'>
            <span className='loginInput-label'>
              Do you want this required the Endorsement Letter of this training?
            </span>
            <div className='d-flex gap-4'>
              <div className='d-flex gap-2'>
                <label
                  className={'loginInput-label m-0'}
                  style={{ marginBottom: 10, marginRight: 10 }}
                  htmlFor='isEndorsementLetterRequiredYes'
                >
                  YES
                </label>
                <input
                  value={isEndorsementLetterRequired}
                  defaultChecked={isEndorsementLetterRequired == 'yes'}
                  onChange={() => setIsEndorsementLetterRequired('yes')}
                  type='radio'
                  id='isEndorsementLetterRequiredYes'
                  name='isEndorsementLetterRequired'
                />
              </div>
              <div className='d-flex gap-2'>
                <label
                  className={'loginInput-label m-0'}
                  style={{ marginBottom: 10, marginRight: 10 }}
                  htmlFor='isEndorsementLetterRequiredNo'
                >
                  NO
                </label>
                <input
                  value={isEndorsementLetterRequired}
                  defaultChecked={isEndorsementLetterRequired == null}
                  onChange={() => setIsEndorsementLetterRequired(null)}
                  type='radio'
                  id='isEndorsementLetterRequiredNo'
                  name='isEndorsementLetterRequired'
                />
              </div>
            </div>
          </div>

          <div className='d-flex flex-column align-items-center justify-content-center my-3'>
            <span className='loginInput-label'>
              Do you want to link this training?
            </span>
            <div className='d-flex gap-2'>
              <label
                className={'loginInput-label m-0'}
                htmlFor='linkTrainingYes'
              >
                YES
              </label>
              <input
                value={isLink}
                defaultChecked={isLink}
                onChange={() => setIsLink(true)}
                type='radio'
                id='linkTrainingYes'
                name='linkTraining'
              />

              <label
                className={'loginInput-label ms-3'}
                htmlFor='linkTrainingNo'
              >
                NO
              </label>
              <input
                value={isLink}
                defaultChecked={!isLink}
                onChange={handleUnlinkTraining}
                type='radio'
                id='linkTrainingNo'
                name='linkTraining'
              />
            </div>
            {isLink && (
              <div className='w-100'>
                <label htmlFor='selectTraining'>
                  Select training to be link
                </label>
                <Select
                  id='selectTraining'
                  className='w-100'
                  value={selectedTraining}
                  showSearch
                  placeholder='Select a training'
                  optionFilterProp='children'
                  onChange={handleSelectTrainingToLink}
                  filterSort={compareOptions}
                  filterOption={(input, option) =>
                    (option?.label ?? '')
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={listOfTrainings}
                />
              </div>
            )}
          </div>

          <Row className='mt-3' justify='center'>
            <div className='reg1-row'>
              <span className='loginInput-label'>
                Do participants need to pass the pre-assessment test before
                registering for the training?
              </span>
            </div>
          </Row>
          <Row
            className='mb-3'
            justify='center'
            onChange={handleIsPassPreAssessmentRequiredInput}
          >
            <div className={'d-flex align-items-center me-3'}>
              <label
                htmlFor='isPassPreAssessmentRequired'
                className={'loginInput-label m-0'}
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                YES
              </label>
              <input
                defaultChecked={isPassPreAssessmentRequired}
                value={true}
                className={'ms-2 test-event'}
                name='passPreAssessment'
                id='isPassPreAssessmentRequired'
                type='radio'
              />
            </div>

            <div className={'d-flex align-items-center ms-3'}>
              <label
                htmlFor='isPassPreAssessmentRequiredNo'
                className={'loginInput-label m-0'}
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                NO
              </label>
              <input
                defaultChecked={!isPassPreAssessmentRequired}
                value={false}
                className={'ms-2 test-event'}
                name='passPreAssessment'
                id='isPassPreAssessmentRequiredNo'
                type='radio'
              />
            </div>
          </Row>

          <div className='reg1-row mt-4'>
            <span>
              <label
                htmlFor='date-event'
                className='loginInput-label'
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                Select Date of Event:
              </label>
              <DatePicker
                onChange={handleDate}
                sort
                multiple
                format={'YYYY/MM/DD'}
                plugins={[<DatePanel />]}
                style={{ height: '30px', width: '400px' }}
              />
            </span>
          </div>
          <div className='reg1-row mt-3'>
            <span>
              <label
                htmlFor='Train-openTime'
                className='loginInput-label col-md-3 col-12'
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                Select Start Time of Event:
              </label>

              <TimePicker
                defaultValue={moment(viewCourse.trainingTimeStart, 'h:mm A')}
                id='Train-openTime'
                name='Train-openTime'
                use12Hours
                format='h:mm A'
                onChange={handleTrainingStartTime}
              />
            </span>
          </div>
          <div className='reg1-row mt-3'>
            <span>
              <label
                htmlFor='Train-endTime'
                className='loginInput-label col-md-3 col-12'
                style={{ marginBottom: 10, marginRight: 10 }}
              >
                Select End Time of Event:
              </label>

              <TimePicker
                defaultValue={moment(viewCourse.trainingTimeEnd, 'h:mm A')}
                id='Train-endTime'
                name='Train-endTime'
                use12Hours
                format='h:mm A'
                onChange={handleTrainingEndTime}
              />
            </span>
          </div>

          <Row>
            <label
              htmlFor='course-title'
              className='loginInput-label'
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Course Title
            </label>
            <Input
              onChange={handleCourseTitle}
              id='course-title'
              name='course-title'
              defaultValue={viewCourse.title}
            />
          </Row>

          <Row>
            <label
              htmlFor='course-category'
              className='loginInput-label'
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Course Category
            </label>
            <Col span={24}>
              <Select
                style={{
                  width: '100%',
                }}
                id='course-category'
                name='course-category'
                showSearch
                placeholder='Select a category'
                optionFilterProp='children'
                onChange={handleCourseCategory}
                // onSearch={onSearchCategory}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={[
                  {
                    value: '',
                    label: '',
                    disabled: true,
                  },
                  {
                    value: 'CORE COMPETENCIES',
                    label: 'CORE COMPETENCIES',
                  },
                  {
                    value: 'LEADERSHIP COMPETENCIES',
                    label: 'LEADERSHIP COMPETENCIES',
                  },
                  {
                    value: 'ORGANIZATIONAL COMPETENCIES',
                    label: 'ORGANIZATIONAL COMPETENCIES',
                  },
                  {
                    value: 'FUNCTIONAL/TECHNICAL',
                    label: 'FUNCTIONAL/TECHNICAL',
                  },
                  {
                    value: 'Additional Training Programs',
                    label: 'Additional Training Programs',
                  },
                ]}
                defaultValue={viewCourse.courseCategory}
              />
            </Col>
          </Row>
          <Row>
            <label
              htmlFor='course-category'
              className='loginInput-label'
              style={{ marginTop: 10, marginBottom: 10 }}
            >
              Course Sub-category
            </label>
            <Col span={24}>
              <Select
                style={{
                  width: '100%',
                }}
                id='course-sub-category'
                name='course-sub-category'
                showSearch
                placeholder='Select a sub-category'
                optionFilterProp='children'
                onChange={handleCourseSubCategory}
                // onSearch={onSearchCategory}
                filterOption={(input, option) =>
                  (option?.label ?? '')
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={optionSubCategory}
                defaultValue={initialSubcateg}
                value={courseSubCategory}
                required
              />
            </Col>
          </Row>

          <div className='reg1-row'>
            <span>
              <label
                htmlFor='pubmat'
                className='loginInput-label'
                style={{ marginTop: 10, marginBottom: 10 }}
              >
                PUBMAT Upload Here
              </label>
              <Input
                id='pubmat'
                name='pubmat'
                multiple
                type='file'
                accept='image/jpeg,image/gif,image/png,application/pdf,video/mp4,video/x-m4v,video/*'
                onChange={(e) => {
                  setAnnounceUpload([...e.target.files])
                }}
              />
              {announceUpload.length > 0 ? (
                <div className={'d-flex flex-column'}>{showUploadNames}</div>
              ) : null}
              <Row className='flex-column'>
                <label
                  htmlFor='course-description'
                  className='loginInput-label'
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  Course Description
                </label>
                <ReactQuill
                  onChange={handleCourseDesc}
                  id='course-description'
                  name='course-description'
                  className='mb-5 mt-1'
                  style={{ height: 130 }}
                  defaultValue={viewCourse.courseDescription}
                />
              </Row>
              <Row className='flex-column'>
                <label
                  htmlFor='target-participants'
                  className='loginInput-label'
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  Target Participants
                </label>
                <ReactQuill
                  onChange={handleTargetParticipants}
                  id='target-participants'
                  name='target-participants'
                  className='mb-5 mt-1'
                  style={{ height: 130 }}
                  defaultValue={viewCourse.targetParticipants}
                />
              </Row>
              <Row className='flex-column'>
                <label
                  htmlFor='additonal-text'
                  className='loginInput-label'
                  style={{ marginTop: 10, marginBottom: 10 }}
                >
                  Text to display before training open
                </label>
                <ReactQuill
                  onChange={handleAdditionalText}
                  value={textDisplayBeforeTrainingOpen}
                  id='additonal-text'
                  name='additonal-text'
                  className='mb-5 mt-1'
                  style={{ height: 130 }}
                />
              </Row>
              <Row className='my-3'>
                <Col span={15}>
                  <label
                    htmlFor='limit-participants'
                    className='loginInput-label'
                    style={{ marginTop: 10, marginBottom: 10 }}
                  >
                    Number of Participants{' '}
                    <small style={{ fontSize: 10 }}>
                      <em>(limit the number of who can attend)</em>
                    </small>
                  </label>
                </Col>
                <Col span={9}>
                  <Input
                    onClick={() => setManageParticipantsModal(true)}
                    id='limit-participants'
                    name='limit-participants'
                    type='number'
                    value={participantLimit}
                    min={1}
                    defaultValue={viewCourse.participantLimit}
                  />
                </Col>
              </Row>
            </span>
          </div>

          <ManageTrainingsNumberOfParticipants
            departmentOptions={departmentOptions}
            departments={departments}
            setDepartments={setDepartments}
            listOfDepartmentsOrignal={listOfDepartmentsOrignal}
            setDepartmentsLimitValue={setDepartmentsLimitValue}
            manageParticipantsModal={manageParticipantsModal}
            setManageParticipantsModal={setManageParticipantsModal}
            setParticipantLimit={setParticipantLimit}
            participantLimit={participantLimit}
            departmentsLimitValue={departmentsLimitValue}
          />

          <div className='reg1-row mt-3'>
            {/*<Row justify="center">
              <span className="loginInput-label">
                Will it have a Pre-assessment and Post-assessment?
              </span>
            </Row>*/}

            {/*<Row
              onChange={handleHasAssessment}
              className="mt-3"
              justify="space-around"
            >
              <Col>
                <input
                  defaultChecked={showPostAssessment == true? true : false}
                  value={true}
                  id="hasAssessmentYes"
                  name="hasAssessment"
                  className="hasAssessment"
                  type="radio"
                />
                <label
                  className="loginInput-label"
                  htmlFor="hasAssessmentYes"
                >
                  YES
                </label>
              </Col>
              <Col>
                <input
                  defaultChecked={showPostAssessment == true? false : true}
                  value={false}
                  id="hasAssessmentNo"
                  name="hasAssessment"
                  className="hasAssessment"
                  type="radio"
                />
                <label
                  className="loginInput-label"
                  htmlFor="hasAssessmentNo"
                >
                  NO
                </label>
              </Col>
            </Row>*/}
          </div>

          {endorsementLetterID ? (
            <div className='mt-3 flex-col'>
              <Row justify='center'>
                <span className='loginInput-label text-center d-block mb-4'>
                  Training Registration "Endorsement Letter" Step
                </span>
              </Row>

              <div className='d-flex justify-content-around'>
                <EditEndorsementLetterText
                  endorsementId={endorsementLetterID}
                  userData={userData}
                  setEndorsementLetterID={setEndorsementLetterID}
                />
              </div>
            </div>
          ) : (
            <div>
              <EndorsementLetter
                userData={userData}
                setEndorsementLetterID={setEndorsementLetterID}
              />
            </div>
          )}

          {showPostAssessment != 'false' && (
            <div className='mt-3 flex-col'>
              <Row justify='center'>
                <span className='loginInput-label text-center d-block mb-4'>
                  Pre-assessment and Post-assessment?
                </span>
              </Row>
              <div className='d-flex justify-content-around'>
                <EditAssessmentQuestion assessmentID={assessmentID} />
              </div>
            </div>
          )}

          {(showPostAssessment == 'false' || !showPostAssessment) && (
            <>
              <Row className='mt-3 w-100' justify='center'>
                <AssessmentMaker
                  userData={userData}
                  setGetAssessmentID={(e) => setAssessmentID(e)}
                  setShowPostAssessment={setShowPostAssessment}
                />
              </Row>
              <Row className='mt-3 w-100' justify='center'>
                <SelectAssessmentQuestion
                  setGetAssessmentID={setAssessmentID}
                  setShowPostAssessment={setShowPostAssessment}
                />
              </Row>
            </>
          )}

          <div className='reg1-row mt-3'>
            <Row justify='center'>
              <span className='loginInput-label'>Post Evaluation Question</span>
            </Row>
          </div>

          {evalID && (
            <div className='mt-3 flex-col'>
              <div className='d-flex justify-content-around'>
                <EditEvaluationQuestion evalID={evalID} />
              </div>
            </div>
          )}

          {!evalID && (
            <>
              <Row className='mt-3' justify='space-around'>
                <EvaluationMaker
                  userData={userData}
                  setGetEvalID={(e) => setEvalID(e)}
                />
                <SelectEvaluationQuestion setGetEvalID={setEvalID} />
              </Row>
            </>
          )}

          <>
            <div className='reg1-row mt-3'>
              <span>
                <label
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  POST TRAINING OPEN
                </label>
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-open'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Date Post Training will open:
                </label>
                <AntdDatePicker
                  id='postTrain-open'
                  name='postTrain-open'
                  onChange={handlePostTrainOpenDate}
                  defaultValue={moment(viewCourse.postTrainOpen)}
                />
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-openTime'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Time Post Training will open:
                </label>

                <TimePicker
                  id='postTrain-openTime'
                  name='postTrain-openTime'
                  use12Hours
                  format='h:mm:ss A'
                  onChange={handlePostTrainOpenTime}
                  defaultValue={moment(viewCourse.postTrainOpen)}
                />
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  POST TRAINING CLOSE
                </label>
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-close'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Date Post Training will close:
                </label>
                <AntdDatePicker
                  id='postTrain-close'
                  name='postTrain-close'
                  onChange={handlePostTrainCloseDate}
                  defaultValue={moment(viewCourse.postTrainClose)}
                />
              </span>
            </div>

            <div className='reg1-row mt-3'>
              <span>
                <label
                  htmlFor='postTrain-closeTime'
                  className='loginInput-label'
                  style={{ marginBottom: 10, marginRight: 10 }}
                >
                  Select Time Post Training will close:
                </label>

                <TimePicker
                  id='postTrain-closeTime'
                  name='postTrain-closeTime'
                  use12Hours
                  format='h:mm:ss A'
                  onChange={handlePostTrainCloseTime}
                  defaultValue={moment(viewCourse.postTrainClose)}
                />
              </span>
            </div>
          </>

          <div
            className={'d-flex justify-content-end'}
            style={{ marginTop: 20 }}
          >
            <Button style={{ marginRight: 20 }} onClick={closeUploadPubMat}>
              Cancel
            </Button>
            <Button key='submit' type='primary' htmlType='submit'>
              Submit
            </Button>
          </div>
        </form>
      </Modal>
    </>
  )
}
