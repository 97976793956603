/**
 * Title: aboutUs.js
 * Description: This is a file that contains the About Us Page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/About%20Us/aboutUs.js
 **/

import { Row, Col } from 'antd'

export default function AboutUs() {
  // return(
  // 	<>
  // 		<Row className="noMargin">
  // 			<Col span={4} ></Col>
  // 			<Col span={16} className="aboutUs" >
  // 				<h4>Vision</h4>
  // 				<p>
  // 					To sustain Quezon City as Quality Community - a progressive and peaceful, clean and orderly place, conducive and hospitable to living, employment, and business.
  // 				</p>
  // 				<h4>Mission</h4>
  // 				<p>
  // 					To provide quality service, making Quezon City an ideal community where people live, work and do business in a hospitable, progressive, and peaceful environment.
  // 				</p>
  // 				<h4>Core Values</h4>
  // 				<Row justify="center" >
  // 					<Col>
  // 						<ul>
  // 							<li className="coreValues">Competence</li>
  // 							<li className="coreValues">Commitment</li>
  // 							<li className="coreValues">Creativity</li>
  // 							<li className="coreValues">Integrity</li>
  // 							<li className="coreValues">Political Will</li>
  // 							<li className="coreValues">Cooperation</li>
  // 						</ul>
  // 					</Col>
  // 				</Row>

  // 				{/*<h3>
  // 					About Strategic Human Resource Unit (SHRU) <img className="qclogo" src="./Quezon_City.svg" alt="QC LGU Seal" />
  // 				</h3>
  // 				<h5 class="chrp">
  // 					MS. MILFLORES VICTORIA TANTOCO - DE LEON, RPSY, CHRP
  // 				</h5>
  // 				<h5>
  // 					Action Officer
  // 				</h5>
  // 				<p>
  // 					Strategic Human Resources Affairs Unit (SHRU) was established under the Executive Order No. 4, Series of 2022. Under the 14-Point Agenda of Hon. Mayor Josefina Belmonte, particularly Item No. 13, there is a need to professionalize and strengthen the Quezon City workforce. Thus, this is the vision and mission that the SHRU embodies.
  // 				</p>
  // 				<p>
  // 					The goal and mission of this unit is to develop and implement training programs to all existing Departments/Offices/Units (DOU). This vision is for the long-term.
  // 				</p>
  // 				<h2 className="partners">
  // 					Our Partner
  // 				</h2>
  // 				<h5 className="hrmd">
  // 					Quezon City Human Resources Management Department (HRMD)
  // 				</h5>
  // 				<h5 className="oic">
  // 					MR. RONALD TAN
  // 				</h5>
  // 				<h5>
  // 					Officer-in-Charge
  // 				</h5>
  // 				<h3 className="hrmdAbout">
  // 					About The Department
  // 				</h3>
  // 				<p >
  // 					The Human Resource Management Department is tasked to keep Quezon City Government effective and efficient in the delivery of basic public services and ensure their maintenance and continuous improvement through a highly motivated workforce. It takes charge of all employee concerns from the time he enters service up to the time he exits therefrom.
  // 				</p>
  // 				<p>
  // 					More specifically, the HRMD provides services on the following:
  // 				</p>

  // 				<Col className="hrmd-p">
  // 					<Row justify="center">
  // 						<Col>
  // 							<p>
  // 								Recruitment, Selection and Placement,
  // 							</p>
  // 							<p>
  // 								Performance Evaluation,
  // 							</p>
  // 							<p>
  // 								Training and Development Interventions,
  // 							</p>
  // 							<p>
  // 								Compensation and Health Benefits,
  // 							</p>
  // 							<p>
  // 								Employer-Employee Relations,
  // 							</p>
  // 							<p>
  // 								HR Policy Implementation and Dissemination,
  // 							</p>
  // 							<p>
  // 								Opinion and Replies to HR Related Queries,
  // 							</p>
  // 							<p>
  // 								Personnel Records Management,
  // 							</p>
  // 							<p>
  // 								Rewards and Recognition, and
  // 							</p>
  // 							<p>
  // 								Position Paper on HR Related Issues.
  // 							</p>
  // 						</Col>
  // 					</Row>
  // 				</Col>*/}
  // 			</Col>
  // 			<Col span={4} ></Col>
  // 		</Row>
  // 		<Row justify="end">
  // 			<button onClick={() => window.open('https://quezoncity.gov.ph/about-the-city-government/', "_blank")} className="learnAnchors" >
  //               <span className="learnmore" >To learn more, please visit <img src="./qcbanner.png" alt="QC Website Logo" /></span>
  //             </button>
  // 		</Row>
  // 	</>
  // )

  return (
    <>
      <Row justify='center'>
        <video style={{ minWidth: '100%' }} muted autoPlay loop>
          <source src='https://s3etnahris133956-dev.s3.ap-southeast-1.amazonaws.com/uploadedByAdmin/About+Us-LAYOUT.mp4' />
        </video>
      </Row>
    </>
  )
}
