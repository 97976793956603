import { useEffect, useState } from 'react'

import { Button, Modal, Row, notification } from 'antd'

import { API, graphqlOperation } from 'aws-amplify'
import {
  deleteOnlineTrainingAnnouncement,
  deleteTrainingRegistration,
} from '../../api/mutations'
import {
  listOnlineTrainingAnnouncements,
  registrationByTAID,
} from '../../api/queries'

export default function DeleteOnlineTraining({
  viewCourse,
  refresh,
  setOpenView,
}) {
  const [openModal, setOpenModal] = useState(false)
  const [listOfTrainings, setListOfTrainings] = useState([])

  const fetchTrainings = async () => {
    const result = await API.graphql({
      query: listOnlineTrainingAnnouncements,
      authMode: 'API_KEY',
      variables: {
        limit: 1000,
      },
    })
    setListOfTrainings(result.data.listOnlineTrainingAnnouncements.items)
  }

  useEffect(() => {
    fetchTrainings()
  }, [])

  const handleOpen = () => {
    setOpenModal(true)
  }

  /**
   * Deletes a training announcement and its associated registrations, if any.
   *
   * @async
   * @function handleDeleteEvent
   * @description This function first retrieves all the registrations associated with the training announcement
   *       specified by `viewCourse.id`. If there are any registrations, it deletes them using the
   *       `deleteTrainingRegistration` operation, and then deletes the training announcement itself using
   *       the `deleteOnlineTrainingAnnouncement` operation. If there are no registrations, it simply deletes the
   *       training announcement. After the delete operation is complete, this function displays a success
   *       or error message, calls the `refresh` function, and closes the modal window.
   *
   * @throws {Error} If an error occurs while deleting the training announcement or its associated registrations.
   *
   * @returns {Promise<void>} A Promise that resolves once the delete operation is complete.
   */
  const handleDeleteEvent = async () => {
    try {
      const childTraining = JSON.parse(viewCourse.linkedTrainingAnnouncementIDs)
      let deleteChildPromises = []
      if (childTraining != null) {
        deleteChildPromises = childTraining.map(async (trainingId) => {
          if (listOfTrainings.some((x) => x.id === trainingId)) {
            const findRegistrations = await API.graphql(
              graphqlOperation(registrationByTAID, {
                trainingAnnouncementID: trainingId,
              })
            )
            if (findRegistrations.data.registrationByTAID.items.length > 0) {
              const deletePromises =
                findRegistrations.data.registrationByTAID.items.map((item) => {
                  return API.graphql(
                    graphqlOperation(deleteTrainingRegistration, {
                      input: {
                        id: item.id,
                      },
                    })
                  )
                })

              await Promise.all(deletePromises)
            }

            const deleteEvent = await API.graphql(
              graphqlOperation(deleteOnlineTrainingAnnouncement, {
                input: {
                  id: trainingId,
                },
              })
            )

            if (deleteEvent) {
              notification['success']({
                message: 'Success',
                description: 'Training Announcement was deleted.',
              })
              refresh()
              setOpenModal(false)
              setOpenView(false)
            } else {
              notification['error']({
                message: 'Error',
                description:
                  'Something happened. Please report it to the System Admin.',
              })
            }
          }
        })
      }

      await Promise.all(deleteChildPromises)

      const findRegistrations = await API.graphql(
        graphqlOperation(registrationByTAID, {
          trainingAnnouncementID: viewCourse.id,
        })
      )
      if (findRegistrations.data.registrationByTAID.items.length > 0) {
        const deletePromises =
          findRegistrations.data.registrationByTAID.items.map((item) => {
            return API.graphql(
              graphqlOperation(deleteTrainingRegistration, {
                input: {
                  id: item.id,
                },
              })
            )
          })

        await Promise.all(deletePromises)
        const deleteEvent = await API.graphql(
          graphqlOperation(deleteOnlineTrainingAnnouncement, {
            input: {
              id: viewCourse.id,
            },
          })
        )

        if (deleteEvent) {
          notification['success']({
            message: 'Success',
            description: 'Training Announcement was deleted.',
          })
          refresh()
          setOpenModal(false)
          setOpenView(false)
        } else {
          notification['error']({
            message: 'Error',
            description: 'Something happened. Please report to System Admin',
          })
        }
      } else {
        const deleteEvent = await API.graphql(
          graphqlOperation(deleteOnlineTrainingAnnouncement, {
            input: {
              id: viewCourse.id,
            },
          })
        )

        if (deleteEvent) {
          notification['success']({
            message: 'Success',
            description: 'Training Announcement was deleted.',
          })
          refresh()
          setOpenModal(false)
          setOpenView(false)
        } else {
          notification['error']({
            message: 'Error',
            description: 'Something happened. Please report to System Admin',
          })
        }
      }
    } catch (err) {
      console.log(err)
      notification['error']({
        message: 'Error',
        description: 'Something happened. Please report to System Admin',
      })
    }
  }
  return (
    <>
      <Button type='danger' onClick={handleOpen}>
        Delete Event?
      </Button>
      <Modal
        maskClosable={false}
        destroyOnClose={true}
        title={'Delete Training Announcement?'}
        centered
        visible={openModal}
        onOk={() => setOpenModal(false)}
        onCancel={() => setOpenModal(false)}
        width={300}
        footer={null}
      >
        <Row justify='space-between'>
          <Button onClick={() => setOpenModal(false)} type='default'>
            Cancel
          </Button>
          <Button onClick={handleDeleteEvent} type='danger' ghost>
            Delete
          </Button>
        </Row>
      </Modal>
    </>
  )
}
