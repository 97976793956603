import { createContext, useState, useEffect, useMemo } from 'react'
import { Auth, API, graphqlOperation } from 'aws-amplify'
import { UserbyUsername } from '../api/queries'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [userData, setUserData] = useState(null)
  const [loading, setLoading] = useState(true)
  const [updated, setUpdated] = useState('')

  const checkUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser()
      const userDataFound = await API.graphql(
        graphqlOperation(UserbyUsername, { username: user.username })
      )
      setUserData(userDataFound.data.UserbyUsername.items[0])
      setLoading(false)
    } catch (error) {
      console.log(error)
      setUserData(null)
      setLoading(false)
    }
  }

  useEffect(() => {
    checkUser()
  }, [updated])
  const value = useMemo(() => ({ userData, updated, setUpdated }), [userData])
  return (
    <AuthContext.Provider value={value}>
      {loading ? null : children}
    </AuthContext.Provider>
  )
}

export default AuthContext
