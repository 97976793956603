/**
 * Title: verify.js
 * Description: This is a file that contains the components for the verification page
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Verify/verify.js
 **/

import React from 'react'

import { Input, notification, Modal } from 'antd'

import { Auth } from 'aws-amplify'

import moment from 'moment'

export default class Verify extends React.Component {
  constructor() {
    super()
    this.state = {
      email: localStorage.getItem('email'),
      timeleft: 60,
      noCounter: true,
      setIsModalVisible: false,
      showPage: false,
    }
  }

  /**
 * @function checkIfLoggedin
 * @description Checks if the user is currently logged in by verifying the session.
 *  If a session exists, redirects to the home page. If not, clears local storage and updates the state to show the page.
 * @returns {Promise<void>}
 */

  checkIfLoggedin = async () => {
    try {
      const session = await Auth.currentSession()
      if (session) {
        window.location.pathname = '/'
      }
    } catch (err) {
      console.log(err)
      localStorage.clear()
      this.setState({ showPage: true })
    }
  }

  componentDidMount() {
    this.checkIfLoggedin()

    var date = moment().format('MMMM Do YYYY')
    var day = moment().format('dddd')
    this.setState({ date: date, day: day })

    notification['info']({
      message: 'Information',
      description:
        "If you didn't receive your verification email, please click the link below for more instructions.",
      duration: 120000,
    })
  }

  showModal = () => {
    this.setState({ setIsModalVisible: true })
  }

  handleOk = () => {
    this.setState({ setIsModalVisible: false })
  }

  handleCancel = () => {
    this.setState({ setIsModalVisible: false })
  }

  /**
 * @function handleSubmit
 * @description Handles the form submission for verifying the email. 
 * Validates the email and triggers the verification process.
 * @param {Event} e - The form submission event.
 * @returns {Promise<void>}
 */

  handleSubmit = async (e) => {
    try {
      e.preventDefault()

      const { email } = this.state;
      // Regular expression for valid email characters
      const validEmailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (email === '') {

        this.setState({ emailError: 'username cannot be blank' });
        notification['error']({
          message: 'Error',
          description: 'username cannot be blank',
        })
      } else if (!validEmailRegex.test(email)) {
        this.setState({ emailError: 'Please enter a valid username.' });
        notification['error']({
          message: 'Error',
          description: 'Please enter a valid username.',
        })
      } else {
        this.props.verify(this.state)
        this.setState({ disabled: true })
      }
      // if (!this.state.code) {
      //   notification['error']({
      //     message: 'Error',
      //     description: 'Please input your code below to continue.',
      //   })
      // } else {
      //   if (this.state.emailError) {
      //     notification['error']({
      //       message: 'Error',
      //       description: this.state.emailError,
      //     })
      //   }
      // }
    } catch (err) {
      console.log(err)
    }
  }

  /**
   * @function resendCode
   * @description Handles the process of resending the verification code to the user's email. 
   * Validates the email and triggers the resend process.
   * @returns {Promise<void>}
   */

  resendCode = async () => {
    try {
      const { email } = this.state;
      // Regular expression for valid email characters
      const validEmailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

      if (email === '') {

        this.setState({ emailError: 'username cannot be blank' });
        notification['error']({
          message: 'Error',
          description: 'username cannot be blank',
        })
      } else if (!validEmailRegex.test(email)) {
        this.setState({ emailError: 'Please enter a valid username.' });
        notification['error']({
          message: 'Error',
          description: 'Please enter a valid username.',
        })
      } else if (email.length > 256) {
        this.setState({ emailError: 'Email cannot be longer than 256 characters.' });
        notification['error']({
          message: 'Error',
          description: 'Email cannot be longer than 256 characters.',
        })
      } else {
        this.setState({ timeleft: 60, noCounter: false }, () => {
          if (this.state.noCounter === false) {
            let intervalId = setInterval(() => {
              if (this.state.timeleft > 1) {
                this.setState({ timeleft: this.state.timeleft - 1 })
              } else {
                this.setState({ timeleft: 1, noCounter: true })
                clearInterval(intervalId)
              }
            }, 1000)
          }
        })
        const resendSignUp = await Auth.resendSignUp(this.state.email)

        console.log(resendSignUp)

        notification['success']({
          message: 'Success',
          description: 'A new verification code was sent to your email.',
        })
      }
    } catch (err) {
      notification['error']({
        message: 'Error',
        description: err.message,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.setState({ disabled: false })
    }
  }

  /**
 * @function handleEmailChange
 * @description Handles the change event for the email input field. Converts
 *  the input to lowercase and updates the state.
 * @param {Event} e - The change event triggered by the email input field.
 */
  handleEmailChange = (e) => {
    try {
      const emailValue = e.target.value.toLowerCase();
      // Update the state with the user's input without validating
      this.setState({ email: emailValue });
      this.setState({ disabled: false });
    } catch (err) {
      console.log(err);
    }
  };

  handleInputFocus = () => {
    this.setState({ emailError: '' });
  };

  handleCodeFocus = () => {
    this.setState({ disabled: false });
  };

  render() {
    return (
      <>
        {this.state.showPage == true ? (
          <>
            <Modal
              maskClosable={false}
              destroyOnClose={true}
              title="Why I didn't receive my Verification Code in my email?"
              visible={this.state.setIsModalVisible}
              onOk={this.handleOk}
              onCancel={this.handleCancel}
            >
              <p>
                <b>Possible reasons: </b>
              </p>
              <ul>
                <li>
                  The Verification email is sent to your "Junk" folder or "Spam"
                  folder.{' '}
                </li>
                <li>
                  There could be a delay in sending the email.
                  <p>
                    <em>Delay could be:</em>
                  </p>
                  <ul>
                    <li>due to internet connection, or speed</li>
                    <li>your internet provider</li>
                    <li>your email provider</li>
                    <li>your device speed or memory</li>
                  </ul>
                  <p></p>
                  <p>
                    <em>What to do:</em>
                  </p>
                  <ul>
                    <li>
                      please give it at least 2-3 minutes to receive the email
                    </li>
                    <li>
                      occasionally you may need to manually <em>refresh</em>{' '}
                      your browser to check for new emails in your inbox.
                    </li>
                    <li>
                      if you didn't receive it after that time, resend{' '}
                      <em>code</em> again
                    </li>
                    <li>
                      when all else fails, please register again using a{' '}
                      <em>different</em> email address
                    </li>
                  </ul>
                </li>
              </ul>
              <p></p>
              <p>
                <b>
                  If you've tried the steps above and still haven't received a
                  verification email, please contact our Support Team.
                </b>
              </p>
            </Modal>
            <div className='mainLogin-div'>
              <div className='mainHeader-login'>
                <div className='loginHead-title'>
                  {/* <img
                      src="https://quezoncity.gov.ph/wp-content/themes/qcproject/images/qclogo_main.png"
                      alt="QCLogo"
                    />
                    <span>Quezon City</span> */}
                </div>
                <div className='loginHead-date'>
                  {`${this.state.day}, ${this.state.date}`}
                </div>
              </div>
              <div className='loginCard-div'>
                <div className='loginCard-right'>
                  <img
                    src='https://quezoncity.gov.ph/wp-content/themes/qcproject/images/qclogo_main.png'
                    alt='QCLogo'
                  />
                  <div></div>
                  <span>
                    Quezon City Human Resource Management Department (HRMD)
                    Online Platform
                  </span>
                </div>
                <form onSubmit={this.handleSubmit} className='loginCard-left'>
                  <div className='loginTitle'>VERIFY</div>
                  <span>
                    <label className='loginInput-label' htmlFor='uname'>
                      Username*
                    </label>
                    <Input
                      onChange={this.handleEmailChange}
                      id='uname'
                      // onBlur={this.validateEmail} // Validate on blur
                      onFocus={this.handleInputFocus} // Clear error on focus
                      value={this.state.email}
                      placeholder='Enter your username'
                      className='loginInput'
                      type='email'
                      maxLength={256}
                      required
                    />
                    {this.state.emailError && <div style={{ color: 'red', fontSize: '12px' }}>{this.state.emailError}</div>}
                  </span>{' '}
                  <span>
                    <label className='loginInput-label' htmlFor='uname'>
                      Code *
                    </label>
                    <Input
                      // disabled={this.state.disabled}
                      // prefix={}
                      onChange={(e) => this.setState({ code: e.target.value, disabled: false })}
                      onFocus={this.handleCodeFocus} // Clear error on focus
                      id='pwd'
                      placeholder='Enter your verification code'
                      className='loginInput'
                      required
                      autoFocus
                    />
                  </span>
                  <span>
                    <button
                      disabled={this.state.disabled}
                      className='btns btnLogin'
                    >
                      Verify
                    </button>
                  </span>
                  <div className='regFoot'>
                    <span>Don't have yet an account?</span>{' '}
                    <span
                      onClick={() => (window.location.pathname = '/register')}
                    >
                      Register here
                    </span>
                  </div>
                  <div className='regFoot'>
                    <span>Need to resend code?</span>{' '}
                    {this.state.noCounter === true ? (
                      <>
                        <span onClick={() => this.resendCode()}>
                          Click here
                        </span>
                      </>
                    ) : (
                      <>
                        <span></span>
                        <span>Try again after {this.state.timeleft}</span>
                      </>
                    )}
                  </div>
                  <div className='regFoot'>
                    <span>
                      <em>
                        <b>I didn't receive my Verification Email.</b>
                      </em>
                    </span>{' '}
                    <span onClick={() => this.showModal()}>
                      Instructions here
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </>
        ) : null}
      </>
    )
  }
}
