/**
 * Title: postQuestionCustomMade.js
 * Description: This is a file that contains the components that displays the Post Question Custom Made
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/postQuestionCustomMade.js
 *
 *Changes made:
 * - 08/14/2024 | Harry Lagunsad | Add props validation
 * - 08/14/2024 | Harry Lagunsad | Fix sonarlint issues
 * - 09/23/2024 | John Bazil Valdez | Add a logic when user already submitted post training it will just update the existing.
 * - 10/23/2024 | Harry Lagunsad | Fix sonarlint issues and fix post training modal message
 **/
import React, { useEffect, useState } from 'react'

import { Button, Col, Input, Row, notification } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'
import PropTypes from 'prop-types'

import {
  createTrainingPostEvaluation,
  updateTrainingPostEvaluation,
} from '../../api/mutations'
import { evaluationByTAID } from '../../api/queries'
import { convertNumToChar } from '../../utilities/convertNumToChar'
import { numToString } from '../../utilities/numToString'
import { toRoman } from '../../utilities/toRoman'

const { TextArea } = Input

export default function PostQuestionCustomMade({
  evalQuestionsSet,
  setEvalQuestionsSet,
  assessmentQuestions,
  questionsArray,
  trainingItem,
  setQuestionOneAfter,
  setQuestionTwoAfter,
  setQuestionThreeAfter,
  setQuestionFourAfter,
  setQuestionFiveAfter,
  setIsModalOpenAfter,
  setIsModalOpen,
  setShowAnswer,
  handleCloseModal,
  isCSCTraining,
  setHidePostTraining,
  setAfterPostTraining,
  userData,
  setHidePostOnlineTraining,
  preAssessmentCorrectScore,
  setPreAssessmentCorrectScore,
  isPreAssessmentScoreOpen,
  setIsPreAssessmentScoreOpen,
}) {
  const currentUser = userData

  const [questionsSet, setQuestionsSet] = useState([])

  const [isAgree, setIsAgree] = useState(false)

  const EVALUATION_INSTRUCTIONS =
    'Your experience and feedback in regards to the training program will help us improve our logistics and content, thus we would highly appreciate your comments and thoughts by filling out this quick survey.'

  //It will disable the submit button when one of the questions doesn't have answer
  const handleDisable = () => {
    if (trainingItem.hasAssessment == 'false') {
      return Object.values(questionsSet).some((question) => !question)
    } else {
      return (
        Object.values(evalQuestionsSet).some((val) => !val) ||
        Object.values(questionsSet).some((val) => !val)
      )
    }
  }

  const handleTotalCorrectItem = () => {
    try {
      if (trainingItem && trainingItem.questionsAssessment) {
        let totalCorrectScore = 0
        const assessmentQuestionnaire = JSON.parse(
          trainingItem?.questionsAssessment?.assessmentQuestionnaire
        )

        // Iterate through the questionnaire and compare answers
        assessmentQuestionnaire.forEach((question, index) => {
          const questionKey = `question${numToString(index)}`
          const userAnswer = evalQuestionsSet[questionKey]
          const correctAnswer = question.correctAnswer

          if (userAnswer === correctAnswer) {
            totalCorrectScore += 1
          }
        })

        setPreAssessmentCorrectScore(totalCorrectScore)
        setIsPreAssessmentScoreOpen(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  const checkIfPostTrainingSubmitted = async () => {
    try {
      if (userData) {
        let token = null
        let foundItems = []

        do {
          const result = await API.graphql(
            graphqlOperation(evaluationByTAID, {
              trainingAnnouncementID: trainingItem.id,
              userID: { eq: userData.id },
              nextToken: token,
            })
          )

          const { items, nextToken } = result.data.evaluationByTAID
          foundItems = [...foundItems, ...items]
          token = nextToken
        } while (token)

        if (foundItems.length) return foundItems[0]?.id

        return null
      }
    } catch (error) {
      console.log(error)
      if (error.toString().includes('Error: No current user')) {
        window.location.reload()
      }
    }
  }

  // It will save the user input answer to the questions to the database when the submit button is click
  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      if (handleDisable() === false) {
        const questionnaireValue = evalQuestionsSet

        const postEvalQuestionsValue = questionsSet

        let adminUpload

        const submittedPostTrainingId = await checkIfPostTrainingSubmitted()

        if (submittedPostTrainingId) {
          adminUpload = await API.graphql(
            graphqlOperation(updateTrainingPostEvaluation, {
              input: {
                id: submittedPostTrainingId,
                postEvalQuestions: JSON.stringify(postEvalQuestionsValue),
                questionnaire: JSON.stringify(questionnaireValue),
                additionalComments: '',
                cscComments: null,
              },
            })
          )
        } else {
          adminUpload = await API.graphql(
            graphqlOperation(createTrainingPostEvaluation, {
              input: {
                userID: currentUser.id,
                trainingAnnouncementID: trainingItem.id,
                postEvalQuestions: JSON.stringify(postEvalQuestionsValue),
                questionnaire: JSON.stringify(questionnaireValue),
                additionalComments: '',
                cscComments: null,
              },
            })
          )
        }

        if (adminUpload.data) {
          handleTotalCorrectItem()
          setAfterPostTraining(adminUpload)

          /*  const answer = JSON.parse(
            adminUpload.data.createTrainingPostEvaluation.questionnaire
          ) */
          notification['success']({
            message: 'Success',
            description:
              'Thank you for answering the Post-Training Evaluation. Your response has been recorded.',
          })

          // setIsSubmit(true);
          // setIsModalOpen(false)
          handleCloseModal()
          setHidePostTraining()
          if (typeof setHidePostOnlineTraining === 'function') {
            setHidePostOnlineTraining()
          }
        }
      } else {
        notification['error']({
          message: 'Error',
          description: 'Please answer all questions.',
        })
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handlePrivacy = (e) => {
    setIsAgree(true)
  }

  useEffect(() => {
    // This will initialize the evaluation dynamic variables where we gonna contain the asnwer on the post question evaluation depends on the length of the questionArray.
    let questionsAndSets = {}
    if (questionsArray) {
      for (let i = 0; i < questionsArray.length; i++) {
        let letter = convertNumToChar(i)

        if (questionsArray[i].categories.length == 0) {
          let answerComment = 'set' + letter + 'Comment'
          questionsAndSets[answerComment] = null
        }

        if (questionsArray[i].categories.length >= 1) {
          let answerOne = 'set' + letter + 'QOne'
          questionsAndSets[answerOne] = null
        }

        if (questionsArray[i].categories.length >= 2) {
          let answerTwo = 'set' + letter + 'QTwo'
          questionsAndSets[answerTwo] = null
        }

        if (questionsArray[i].categories.length === 3) {
          let answerThree = 'set' + letter + 'QThree'
          questionsAndSets[answerThree] = null
        }
      }
      setQuestionsSet(questionsAndSets)
    }

    // This will initialize the assessment dynamic variables where we gonna contain the asnwer on the assessment questions depends on the length of the assestmentQuestion.
    let assessQuestions = {}
    if (assessmentQuestions) {
      for (let i = 0; i < assessmentQuestions.length; i++) {
        let numString = numToString(i)

        let question = 'question' + numString
        assessQuestions[question] = null
      }
      setEvalQuestionsSet(assessQuestions)
    }
  }, [])

  // This is to update the value of the variable that we initialize on the useEffect above. This will contain the users answer to specific variable depends on the set of question
  const handleSetAnswerQuestion = (i, e) => {
    const newquestionsAndSets = {
      ...questionsSet,
      [e.target.name]: e.target.value,
    }
    setQuestionsSet(newquestionsAndSets)
  }

  const handleSetAnsAssessQuestion = (e) => {
    setEvalQuestionsSet((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  // This is to create the evaluation questions component by mapping the "questionsArray"
  let showEvalQuestions
  if (questionsArray) {
    showEvalQuestions = questionsArray.map((elem, index) => {
      const numberStrings = ['One', 'Two', 'Three', 'Four', 'Five', 'Six']
      const letter = convertNumToChar(index)
      return (
        <React.Fragment key={index}>
          <Row>
            <span className={'fw-bold'}>
              {toRoman(index + 1)}. {elem.question}
            </span>
          </Row>
          {elem.categories.length > 0 ? (
            <>
              {elem.description && (
                <Row>
                  <span className={'text-muted py-2'}>{elem.description}</span>
                </Row>
              )}
              {trainingItem.id === 'b4b26699-3535-452f-a370-cce7a4d2d71b' ? (
                <Row className={'mb-3'}>
                  <Col style={{ width: 730 }}>
                    <Row>
                      <Col span={13}></Col>
                      <Col
                        span={11}
                        className='d-flex justify-content-around mt-3'
                      >
                        <span className='fw-bold'>1</span>
                        <span className='fw-bold'>2</span>
                        <span className='fw-bold'>3</span>
                        <span className='fw-bold'>4</span>
                        <span className='fw-bold'>5</span>
                        <span className='fw-bold'>n/a</span>
                      </Col>
                      <Col span={24}>
                        <Row style={{ height: '10px' }}></Row>
                        {elem.categories.map((category, ci) => {
                          return (
                            <div
                              key={ci}
                              className='d-flex align-items-center mt-3'
                            >
                              <Col span={13} className='text-end'>
                                {category[`category-${ci + 1}-${index + 1}`]}{' '}
                                <em className='asterisk'>**</em>
                              </Col>
                              <Col
                                span={11}
                                className='d-flex justify-content-around'
                              >
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ] == '1'
                                    }
                                    value='1'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ]
                                    }
                                    value='2'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ]
                                    }
                                    value='3'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ]
                                    }
                                    value='4'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ]
                                    }
                                    value='5'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ]
                                    }
                                    value='n/a'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                              </Col>
                            </div>
                          )
                        })}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <Row className={'mb-3'}>
                  <Col style={{ width: 730 }}>
                    <Row>
                      <Col span={13}></Col>
                      <Col
                        span={11}
                        className='d-flex justify-content-around mt-3'
                      >
                        <span className='fw-bold'>1</span>
                        <span className='fw-bold'>2</span>
                        <span className='fw-bold'>3</span>
                        <span className='fw-bold'>4</span>
                        <span className='fw-bold'>5</span>
                      </Col>
                      <Col span={24}>
                        <Row style={{ height: '10px' }}></Row>
                        {elem.categories.map((category, ci) => {
                          return (
                            <div
                              key={ci}
                              className='d-flex align-items-center mt-3
                      '
                            >
                              <Col span={13} className='text-end'>
                                {category[`category-${ci + 1}-${index + 1}`]}{' '}
                                <em className='asterisk'>**</em>
                              </Col>
                              <Col
                                span={11}
                                className='d-flex justify-content-around'
                              >
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ] == '1'
                                    }
                                    value='1'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ]
                                    }
                                    value='2'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ]
                                    }
                                    value='3'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ]
                                    }
                                    value='4'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                                <Row style={{ height: '22px' }}>
                                  <input
                                    onChange={(e) =>
                                      handleSetAnswerQuestion(index, e)
                                    }
                                    defaultChecked={
                                      questionsSet[
                                        'set' + letter + 'Q' + numberStrings[ci]
                                      ]
                                    }
                                    value='5'
                                    name={
                                      'set' + letter + 'Q' + numberStrings[ci]
                                    }
                                    className={'postAssesmentQ'}
                                    type='radio'
                                    id={index + 1}
                                  />
                                </Row>
                              </Col>
                            </div>
                          )
                        })}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <div>
              <Row className={'mb-3 mt-3'}>
                <Col span={24}>
                  <TextArea
                    onChange={(e) => handleSetAnswerQuestion(index, e)}
                    name={'set' + letter + 'Comment'}
                    style={{ fontSize: 16 }}
                    rows={5}
                    id={'set' + letter + 'Comment'}
                  />
                </Col>
              </Row>
            </div>
          )}
        </React.Fragment>
      )
    })
  }

  // This is to create the assessment questions component by mapping the "assessmentQuestions"
  let showAssessmentQuestions
  if (assessmentQuestions) {
    showAssessmentQuestions = assessmentQuestions.map((elem, index) => {
      const numString = numToString(index)
      return (
        <React.Fragment key={index}>
          <Row className='mt-4 col-11'>
            <strong>
              {index + 1}. {elem.question}
            </strong>
          </Row>
          <Row
            align='center'
            justify='center'
            className='flex-column ms-3 mt-2'
          >
            {elem.choices[0] &&
              elem.choices[1] &&
              elem.choices[2] &&
              elem.choices[3] && (
                <>
                  {elem.choices[0][`choices-1-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestion(e)}
                        value='A'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-A`}
                      />
                      <label
                        className='m-0 ms-3'
                        htmlFor={`question${numString}-choice-A`}
                      >
                        <strong>
                          <em>A. </em>
                        </strong>
                        {elem.choices[0][`choices-1-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[1][`choices-2-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestion(e)}
                        value='B'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-B`}
                      />
                      <label
                        className='m-0 ms-3'
                        htmlFor={`question${numString}-choice-B`}
                      >
                        <strong>
                          <em>B. </em>
                        </strong>
                        {elem.choices[1][`choices-2-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[2][`choices-3-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestion(e)}
                        value='C'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-C`}
                      />
                      <label
                        className='m-0 ms-3'
                        htmlFor={`question${numString}-choice-C`}
                      >
                        <strong>
                          <em>C. </em>
                        </strong>
                        {elem.choices[2][`choices-3-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  {elem.choices[3][`choices-4-${index + 1}`].toLowerCase() !==
                    'n/a' && (
                    <div className='d-flex p-1'>
                      <input
                        onChange={(e) => handleSetAnsAssessQuestion(e)}
                        value='D'
                        name={'question' + numString}
                        className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                        type='radio'
                        id={`question${numString}-choice-D`}
                      />
                      <label
                        className='m-0 ms-3'
                        htmlFor={`question${numString}-choice-D`}
                      >
                        <strong>
                          <em>D. </em>
                        </strong>
                        {elem.choices[3][`choices-4-${index + 1}`]}
                      </label>
                    </div>
                  )}
                  <TextArea
                    hidden={elem.correctAnswer !== 'n/a'}
                    onChange={(e) => handleSetAnsAssessQuestion(e)}
                    name={'question' + numString}
                    className='mr align-self-start mt-md-0 mt-1 align-self-md-center'
                  />
                </>
              )}
          </Row>
        </React.Fragment>
      )
    })
  }

  return (
    <>
      {/* <Button ghost type="primary" onClick={handleOpenModal}>Save</Button>
      <Modal
        title={
          <span><em><b>Please review questionnaire before saving</b></em></span>
        }
        visible={openModal}
        footer={null}
        onCancel={handleCancel}
        width={800}
        destroyOnClose={true}
      > */}

      <>
        <Row className={'font-Mont mb-3'}>
          <Col>
            <Row className={'mt-2'}>
              <Col span={24}>
                <Row>
                  <span>{EVALUATION_INSTRUCTIONS}</span>
                  <br />
                </Row>

                <Row>
                  <span>Thank you!</span>
                  <br />
                  <br />
                </Row>
                <Row>
                  <span>1 = Poor</span>
                </Row>
                <Row>
                  <span>2 = Fair</span>
                </Row>
                <Row>
                  <span>3 = Average</span>
                </Row>
                <Row>
                  <span>4 = Good</span>
                </Row>
                <Row>
                  <span>5 = Excellent</span>
                  <br />
                  <br />
                </Row>
              </Col>
            </Row>

            <Row className={'mb-3'}>
              <h4>I. Data Privacy Consent Form</h4>
            </Row>
            <Row>
              <span>Republic Act 10173 or the "Data Privacy Act"</span>
              <br />
              <br />
            </Row>
            <Row>
              <span>
                <strong>
                  I hereby authorize and give the Human Resource Management
                  Department a consent that all personal information indicated
                  herein will be kept confidential for training purposes only. I
                  further understand that this form is being protected by the
                  Republic Act 10173 or the "Data Privacy Act".
                </strong>
              </span>
              <br />
            </Row>
            <Row onChange={(e) => handlePrivacy(e)}>
              <input value={true} id='dpa22' name='dpa22' type='radio' />
              <label className={'ms-2'} htmlFor='dpa22'>
                <b>I agree *</b>
              </label>
            </Row>

            {isAgree === true ? (
              <>
                {/* // It will show only if the training has a evaluation */}
                {trainingItem.evalID && (
                  <>
                    <Row className={'mb-3 mt-3'}>
                      <h4>II. Post Evaluation</h4>
                    </Row>
                    <Row>
                      <span style={{ fontSize: 16 }}>
                        {EVALUATION_INSTRUCTIONS}
                      </span>
                      <br />
                      <br />
                    </Row>
                    <Row>
                      <span style={{ fontSize: 16 }}>Thank you!</span>
                      <br />
                      <br />
                    </Row>
                    <Row>
                      <span style={{ fontSize: 16 }}>1 = Poor</span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: 16 }}>2 = Fair</span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: 16 }}>3 = Average</span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: 16 }}>4 = Good</span>
                    </Row>
                    <Row>
                      <span style={{ fontSize: 16 }}>5 = Excellent</span>
                    </Row>
                    <Row className={'mb-4'}></Row>
                    <Row style={{ fontSize: 16 }} justify='center'>
                      <Col>
                        <Row className='mb-4'>
                          <span className={'fw-bold'}>
                            1 = Poor, 5 = Excellent
                          </span>
                        </Row>
                        {
                          // show here the custom evaluation questionnaire
                          showEvalQuestions
                        }
                      </Col>
                    </Row>
                    <Row justify='end'>
                      <span className='m-2'>
                        <b>QCG.HRMD.HRDASD.F.09</b>
                      </span>
                    </Row>
                  </>
                )}
                {
                  // It will show only if the training has a assessment
                  trainingItem.assessmentID && (
                    <>
                      <hr />
                      <Row className='mt-4'>
                        <h4>III. Post Test</h4>
                      </Row>
                      <Row className='mt-3'>
                        <span style={{ fontSize: 16, textAlign: 'left' }}>
                          <b>Instructions: </b>Please answer all the questions
                          that follow to have a complete picture of your updated
                          knowledge after attending the Onboarding and
                          Orientation Program. Please read carefully and select
                          the best answer.
                        </span>
                      </Row>
                      {isCSCTraining ? (
                        <Row className='mt-4'>
                          {trainingItem.id ===
                          'acb7d867-7114-49a5-81f0-8ee28eb2828d' ? (
                            <span className='fw-bold'>
                              Choose the best answer.
                            </span>
                          ) : (
                            <span className='fw-bold'>
                              Which among the choices made the sentence
                              incorrect?
                            </span>
                          )}
                        </Row>
                      ) : null}
                      <div className='container ms-md-5 ms-1 mt-3 col-md-11'>
                        {showAssessmentQuestions}
                      </div>
                      <Row justify='end'>
                        <span className='m-2'>
                          <b>QCG.HRMD.HRDASD.F.08</b>
                        </span>
                      </Row>
                    </>
                  )
                }
              </>
            ) : null}
          </Col>
        </Row>

        <Row justify='end'>
          <Button
            disabled={handleDisable()}
            onClick={handleSubmit}
            type='primary'
          >
            Submit
          </Button>
        </Row>
      </>
    </>
  )
}

PostQuestionCustomMade.propTypes = {
  evalQuestionsSet: PropTypes.object,
  setEvalQuestionsSet: PropTypes.func,
  assessmentQuestions: PropTypes.array,
  questionsArray: PropTypes.array,
  trainingItem: PropTypes.shape({
    hasAssessment: PropTypes.string,
    evalID: PropTypes.string,
    assessmentID: PropTypes.string,
    id: PropTypes.string.isRequired,
    questionsAssessment: PropTypes.object,
  }),
  setQuestionOneAfter: PropTypes.func,
  setQuestionTwoAfter: PropTypes.func,
  setQuestionThreeAfter: PropTypes.func,
  setQuestionFourAfter: PropTypes.func,
  setQuestionFiveAfter: PropTypes.func,
  setIsModalOpenAfter: PropTypes.func,
  setIsModalOpen: PropTypes.func,
  setShowAnswer: PropTypes.func,
  handleCloseModal: PropTypes.func,
  isCSCTraining: PropTypes.bool,
  setHidePostTraining: PropTypes.func,
  setAfterPostTraining: PropTypes.func,
  userData: PropTypes.object.isRequired,
  setHidePostOnlineTraining: PropTypes.func,
  preAssessmentCorrectScore: PropTypes.number,
  setPreAssessmentCorrectScore: PropTypes.func,
  isPreAssessmentScoreOpen: PropTypes.bool,
  setIsPreAssessmentScoreOpen: PropTypes.func,
}
