/**
 * Title: volunteersCorner.js
 * Description: This is a file that contains the components for the Volunteers Corner
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/volunteersCorner.js
 **/

import { Row, Col } from 'antd'

export default function VolunteersCorner() {
  return (
    <Row className='welcome' justify='center'>
      <p>Coming Soon</p>
    </Row>
  )
}
