import React from 'react'

import { Card, Empty, Row } from 'antd'
import PropTypes from 'prop-types'

/**
 * Displays the results for a given user.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.user - The user object.
 * @param {string} props.user.username - The username of the user.
 * @param {string} props.user.name - The first name of the user.
 * @param {string} props.user.lname - The last name of the user.
 * @param {string} props.user.department - The home department of the user.
 * @returns {JSX.Element} The rendered component.
 */
const ResultsDisplay = ({ user }) => {
  if (!user) {
    return (
      <Row justify='center'>
        <Card className='pds-encoder-results'>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description='No user found'
          />
        </Card>
      </Row>
    )
  }

  return (
    <Row justify='center'>
      <Card title='User Found' className='pds-encoder-results'>
        <p>
          <strong>Username: </strong> {user.username}
        </p>
        <p>
          <strong>Name: </strong> {`${user.name} ${user.lname}`}
        </p>
        <p>
          <strong>Home Department: </strong> {user.department}
        </p>
      </Card>
    </Row>
  )
}

export default ResultsDisplay

ResultsDisplay.propTypes = {
  user: PropTypes.shape({
    username: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    lname: PropTypes.string.isRequired,
    department: PropTypes.string.isRequired,
  }),
}
