/**
 * Title: reacaptchaRegister.js
 * Description: This is a file that contains the components for the reCAPTCHA for registration
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Register/reacaptchaRegister.js
 **/

import { useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import { API, graphqlOperation } from 'aws-amplify'
import { listForms, getForm, getPublicKeys } from '../../api/queries'

// window.grecaptcha.reset();
export default function RecaptchaRegister({ setCaptcha, showButton }) {
  const [show, setShow] = useState(false)
  const [captchaKey, setCaptchaKey] = useState(null)

  const grecaptchaObject = window.grecaptcha

  const getKey = async () => {
    try {
      const idKey = '2258d874-9b69-42bb-a764-6d18b6692e3e'
      const result = await API.graphql({
        query: getPublicKeys,
        authMode: 'API_KEY',
        variables: {
          id: idKey,
        },
      })

      setCaptchaKey(
        JSON.parse(result.data.getPublicKeys.publicKey).recaptchaKey
      )
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getKey()
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
      showButton(true)
    }, 1500)
  }, [])

  const handleRecaptcha = async (value) => {
    setCaptcha(value)
  }

  return (
    <>
      {captchaKey && show ? (
        <>
          <ReCAPTCHA
            sitekey={captchaKey}
            onChange={handleRecaptcha}
            grecaptcha={grecaptchaObject}
          />
        </>
      ) : null}
    </>
  )
}
