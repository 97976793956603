/**
 * Title: PersonalDataSheet.js
 * Description: This is a file that contains the components for the Personal Data Sheet page.
 * Authors:
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/PDS/PersonalDataSheet.js
 **/
import { useEffect, useState } from 'react'

import { Auth, API, graphqlOperation } from 'aws-amplify'
import { UserbyUsername } from './../../api/queries'

import PageLoading from './PageLoading'
import MainForm from './MainForm'

import DataPrivacyPDS from './DataPrivacyPDS'

import 'bootstrap-icons/font/bootstrap-icons.css'
// import './pds.css'

export default function PersonalDataSheet({ afterSubmit }) {
  const [userFound, setUserFound] = useState(false)
  const [switchMsg, setSwitchMsg] = useState(false)
  const [userDetails, setUserDetails] = useState(null)

  const [isProceedPDS, setIsProceedPDS] = useState(false)

  /**
   *@function checkSessionLogin
   *@description Handles the session of the user.
   *@param {Object} none No parameter needed.
   *@returns {Object} none No return value.
   */
  const checkSessionLogin = async () => {
    try {
      await Auth.currentSession()
      const user = await Auth.currentAuthenticatedUser()
      const { username } = user
      const userDetails = await API.graphql(
        graphqlOperation(UserbyUsername, { username })
      )

      setUserDetails(userDetails.data.UserbyUsername.items[0])

      setUserFound(true)
    } catch (err) {
      console.log(err)
      setSwitchMsg(true)
    }
  }

  useEffect(() => {
    checkSessionLogin()
  }, [])

  return (
    <div>
      {userFound ? (
        <>
          {isProceedPDS ? (
            <MainForm
              isProceedPDS={isProceedPDS}
              userDetails={userDetails}
              afterSubmit={afterSubmit}
            />
          ) : (
            <DataPrivacyPDS setIsProceedPDS={(e) => setIsProceedPDS(e)} />
          )}
        </>
      ) : (
        <PageLoading switchMsg={switchMsg} message={'Redirecting...'} />
      )}
    </div>
  )
}
