/**
 * Title: onlineTrainingAccordionView.js
 * Description: This is a file that contains lms view course modal
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/onlineTrainingAccordionView.js
 * History:
 * - 09/23/2024 - John Bazil Valdez
 * - Add a logic to only render the post training when the user finish all the video modules
 **/
import React, { useEffect, useState } from 'react'

import { Popconfirm } from 'antd'
import { API, graphqlOperation } from 'aws-amplify'

import { evaluationByTAID, registrationByTAID } from '../../api/queries'
import PostTrainingModal from './postTrainingModal'

/**
 * Renders the online training accordion view.
 *
 * @param {Object} props - The component props.
 * @param {string} props.selectedView - The selected view.
 * @param {function} props.setSelectedView - The function to set the selected view.
 * @param {function} props.setSelectedVideoKey - The function to set the selected video key.
 * @param {Object} props.training - The training object.
 * @param {function} props.setSelectedActivity - The function to set the selected activity.
 * @param {function} props.setSelectedActivityIndex - The function to set the selected activity index.
 * @param {number} props.numberOfAnsweredActivity - The number of answered activities.
 * @param {number} props.selectedAccordion - The selected accordion index.
 * @param {function} props.setSelectedAccordion - The function to set the selected accordion index.
 * @param {function} props.handleSubmitActivity - The function to handle submit activity.
 * @returns {JSX.Element} The online training accordion view component.
 */

export default function OnlineTrainingAccordionView({
  selectedView,
  setSelectedView,
  setSelectedVideoKey,
  training,
  setSelectedActivity,
  setSelectedActivityIndex,
  numberOfAnsweredActivity,
  selectedAccordion,
  setSelectedAccordion,
  handleSubmitActivity,
  isVideoWatched,
  handleClickDone,
  setSelectedTranscript,
  afterRegister,
  userData,
}) {
  const [hidePostOnlineTraining, setHidePostOnlineTraining] = useState(true)
  const [submittedEndorseLetter, setSubmittedEndorseLetter] = useState(false)
  /**
   * Handles the click event on the accordion.
   * Toggles the selected accordion based on the index.
   *
   * @param {number} index - The index of the clicked accordion.
   * @returns {void}
   */
  const handleAccordionClick = (index) => {
    setSelectedAccordion(index === selectedAccordion ? -1 : index)
  }

  const checkIfPostTrainingSubmitted = async () => {
    try {
      if (userData) {
        let token = null
        let foundItems = []

        do {
          const result = await API.graphql(
            graphqlOperation(evaluationByTAID, {
              trainingAnnouncementID: training.id,
              userID: { eq: userData.id },
              nextToken: token,
            })
          )

          const { items, nextToken } = result.data.evaluationByTAID
          foundItems = [...foundItems, ...items]
          token = nextToken
        } while (token)
        if (foundItems.length) {
          setHidePostOnlineTraining(true)
        } else {
          setHidePostOnlineTraining(false)
        }
      }
    } catch (error) {
      console.log(error)
      if (error.toString().includes('Error: No current user')) {
        window.location.reload()
      }
    }
  }

  const isUserSubmittedEndorseLetter = async () => {
    if (userData) {
      let token = null
      let foundItems = []

      do {
        const result = await API.graphql(
          graphqlOperation(registrationByTAID, {
            trainingAnnouncementID: training.id,
            userID: { eq: userData.id },
            nextToken: token,
          })
        )

        const { items, nextToken } = result.data.registrationByTAID
        foundItems = [...foundItems, ...items]
        token = nextToken
      } while (token)
      if (foundItems.length && foundItems[0].endorsementLetter) {
        setSubmittedEndorseLetter(true)
      }
    }
  }

  useEffect(() => {
    checkIfPostTrainingSubmitted()
    isUserSubmittedEndorseLetter()
  }, [])

  return (
    <>
      <div className='accordion' id='accordionExample'>
        <div className='accordion-item'>
          <h2 className='accordion-header'>
            <button disabled className={`accordion-button py-4 collapsed`}>
              <input
                className='form-check-input fs-5 me-3'
                type='checkbox'
                disabled
                checked
              />
              Pre-Test
            </button>
          </h2>
        </div>
      </div>
      <div className='accordion' id='accordionExample'>
        <div className='accordion-item'>
          <h2 className='accordion-header'>
            <button disabled className={`accordion-button py-4 collapsed`}>
              <input
                className='form-check-input fs-5 me-3'
                type='checkbox'
                disabled
                checked={submittedEndorseLetter}
              />
              Endorsement letter
            </button>
          </h2>
        </div>
      </div>
      {training?.sectionTitle &&
        JSON.parse(training?.sectionTitle).map((title, index) => (
          <div className='accordion' id='accordionExample' key={index}>
            <div className='accordion-item'>
              <h2 className='accordion-header'>
                <button
                  disabled={numberOfAnsweredActivity != index}
                  onClick={() => {
                    handleAccordionClick(index)
                    setSelectedActivityIndex(index)
                    setSelectedVideoKey(
                      JSON.parse(training.sectionVideos)[
                        `trainingVideosID${index + 1}`
                      ]
                    )
                    if (training.sectionQuestions) {
                      setSelectedActivity(
                        JSON.parse(
                          JSON.parse(training?.sectionQuestions || null)[
                            `activityQuestion${index + 1}`
                          ] || null
                        ) || null
                      )
                    }
                  }}
                  className={`accordion-button py-4 ${
                    selectedAccordion === index ? '' : 'collapsed'
                  }`}
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target={`#collapse${index + 1}`}
                  aria-expanded={`${
                    selectedAccordion === index ? 'true' : 'false'
                  }`}
                >
                  <input
                    className='form-check-input fs-5 me-3'
                    type='checkbox'
                    disabled
                    checked={numberOfAnsweredActivity > index}
                  />
                  {index + 1}. {title}
                </button>
              </h2>

              <div
                id={`collapse${index + 1}`}
                className={`accordion-collapse collapse ${
                  selectedAccordion === index &&
                  numberOfAnsweredActivity <= index
                    ? 'show'
                    : ''
                }`}
                aria-labelledby={`heading${index + 1}`}
                data-bs-parent='#accordionExample'
              >
                <div className='accordion-body bg-light p-3 rounded'>
                  <button
                    hidden={isVideoWatched}
                    onClick={() => setSelectedView('video')}
                    className={`btn btn-outline-secondary w-100 mb-2 ${
                      selectedView === 'video' && 'active'
                    }`}
                  >
                    Video
                  </button>

                  <button
                    hidden={
                      (!training.videoTranscript &&
                        !JSON.parse(training?.videoTranscripts)?.[
                          `videoTranscriptID${index + 1}`
                        ]) ||
                      isVideoWatched ||
                      false
                    }
                    onClick={() =>
                      setSelectedTranscript(
                        JSON.parse(training?.videoTranscripts)[
                          `videoTranscriptID${index + 1}`
                        ]
                      )
                    }
                    className={`btn btn-outline-secondary w-100 mb-2 `}
                  >
                    Transcript
                  </button>

                  {JSON.parse(
                    JSON.parse(training?.sectionQuestions || null)?.[
                      `activityQuestion${index + 1}`
                    ] || null
                  ) !== null ? (
                    <button
                      hidden={!isVideoWatched}
                      onClick={() => setSelectedView('activity')}
                      className={`btn btn-outline-secondary w-100 ${
                        selectedView !== 'video' && 'active'
                      }`}
                    >
                      Activity
                    </button>
                  ) : (
                    <Popconfirm
                      placement='top'
                      title={
                        'Are you sure you are done with this video? This action cannot be undone. you cannot return to this video.'
                      }
                      onConfirm={() => handleSubmitActivity(true)}
                      okText='Yes'
                      cancelText='No'
                      overlayStyle={{ maxWidth: '500px' }}
                    >
                      <button
                        hidden={!isVideoWatched}
                        onClick={() => handleClickDone()}
                        className={`btn text-white bg-primary btn-outline-secondary w-100 mb-2`}
                      >
                        Next
                      </button>
                    </Popconfirm>
                  )}
                </div>
              </div>
            </div>
          </div>
        ))}
      <div className='accordion' id='accordionExample'>
        <div className='accordion-item'>
          <h2 className='accordion-header'>
            <button
              disabled={true}
              className={`accordion-button py-4 ${
                selectedAccordion ===
                JSON.parse(training?.sectionTitle).length + 1
                  ? ''
                  : 'collapsed'
              }`}
              type='button'
              data-bs-toggle='collapse'
              data-bs-target={`#collapse${
                JSON.parse(training?.sectionTitle).length + 1
              }`}
              aria-expanded={`${
                selectedAccordion ===
                JSON.parse(training?.sectionTitle).length + 1
                  ? 'true'
                  : 'false'
              }`}
            >
              <input
                className='form-check-input fs-5 me-3'
                type='checkbox'
                disabled
                checked={
                  numberOfAnsweredActivity >
                    JSON.parse(training?.sectionTitle).length ||
                  hidePostOnlineTraining
                }
              />
              {JSON.parse(training?.sectionTitle).length + 1}. Post Training
            </button>
          </h2>

          <div
            id={`collapse${JSON.parse(training?.sectionTitle).length + 1}`}
            className={`accordion-collapse collapse ${
              selectedAccordion === JSON.parse(training?.sectionTitle).length
                ? 'show'
                : ''
            }`}
            aria-labelledby={`heading${
              JSON.parse(training?.sectionTitle).length + 1
            }`}
            data-bs-parent='#accordionExample'
          >
            {selectedAccordion ===
              JSON.parse(training?.sectionTitle).length && (
              <div className='accordion-body bg-light p-3 rounded d-flex justify-content-center'>
                <PostTrainingModal
                  afterRegister={afterRegister}
                  trainingItem={training}
                  setHidePostOnlineTraining={setHidePostOnlineTraining}
                  userData={userData}
                  isECert={true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}
