/**
 * Title: onlineTrainingPrograms.js
 * Description: This is a file that contains the components that shows the online training programs
 * Authors:
 * - John Bazil Valdez [bazilvaldez30@gmail.com] [Github: @bazilvaldez30]
 * - Harry Lagunsad [hlagunsad@sparksoft.com.ph] [@Github: @hlagunsadxSparksoft]
 * Repository: https://github.com/SparkSoftDevs/ldsystem
 * Version Link: https://github.com/SparkSoftDevs/ldsystem/blob/master/src/components/Home/onlineTrainingPrograms.js
 **/
import React, { useEffect, useState } from 'react'

import { Button, Card, Col, Row } from 'antd'
import { API } from 'aws-amplify'
import moment from 'moment'

import {
  listOnlineActivityAnswers,
  listOnlineTrainingAnnouncements,
} from '../../api/queries'
import HtmlParser from './html-parser.js'
import OnlineTrainingRegistrationModal from './onlineTrainingRegistrationModal.js'
import PostTrainingModal from './postTrainingModal.js'

/**
 * Renders the online training programs component.
 *
 * @param {function} triggerScroll - Function to trigger scrolling.
 * @param {function} gotoForms - Function to navigate to forms.
 * @returns {JSX.Element} The rendered online training programs component.
 */
export default function OnlineTrainingPrograms({
  triggerScroll,
  gotoForms,
  userData,
}) {
  const [upcomingPubmat, setUpcomingPubmat] = useState([])
  const [oldPubmat, setOldPubmat] = useState([])

  const [afterRegister, setAfterRegister] = useState(null)

  const [refresh, setRefresh] = useState(null)

  const [currentPage, setCurrentPage] = useState(0)
  const [currentShows, setCurrentShows] = useState([])

  const endIndex = currentPage * 5
  const startIndex = endIndex - 5

  /**
   * Retrieves a sorted and sliced array of old pubmat items.
   *
   * @param {Array} oldPubmat - The array of old pubmat items.
   * @param {number} startIndex - The index to start slicing from.
   * @param {number} endIndex - The index to end slicing at.
   * @returns {Array} - The sorted and sliced array of old pubmat items.
   */
  useEffect(() => {
    const holdShow = oldPubmat
      .sort((a, b) => new Date(b.date) - new Date(a.date))
      .slice(startIndex, endIndex)
    setCurrentShows([...currentShows, ...holdShow])
  }, [currentPage])

  /**
   * Retrieves all online training announcements.
   * @returns {Promise<void>} A promise that resolves when the retrieval is complete.
   */
  const getAllPubmats = async () => {
    try {
      const result = await API.graphql({
        query: listOnlineTrainingAnnouncements,
        authMode: 'API_KEY',
        variables: {
          limit: 1000,
        },
      })

      if (result) {
        let holdOld = []
        let holdNew = []

        const data = result.data.listOnlineTrainingAnnouncements.items

        // filter out training announcements that has no post training dates then push to holdOld
        // they are considered as old trainings as post training function came later
        data.forEach((itemData) => {
          if (!itemData.postTrainClose) {
            holdOld.push(itemData)
          }
        })

        // sort by date then filter out trainings that has its post training past today date
        // items post training date is past today are pushed to Past Trainings
        const newData = data.filter((itemData) => {
          if (itemData.postTrainClose) {
            return itemData
          }
        })

        newData
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .forEach((item) => {
            if (moment(new Date(item.postTrainClose)).isAfter(new Date())) {
              holdNew.push(item)
            } else {
              if (
                moment(new Date(item.postTrainClose)).isSame(new Date(), 'day')
              ) {
                holdNew.push(item)
              } else {
                // check if CSC Training by id
                if (item.id === '844efb56-51b0-4ef9-930c-9942e8a8c8f6') {
                  holdNew.push(item)
                } else {
                  holdOld.push(item)
                }
              }
            }
          })

        // remove test events in the Upcoming Training
        // use isTestEvent field to filter
        const removeTestEvents = holdNew.filter((h) => {
          let checkEvent

          if (h.isTestEvent === null) {
            checkEvent = false
          } else if (h.isTestEvent === true) {
            checkEvent = true
          } else {
            checkEvent = false
          }

          if (checkEvent === false) {
            return h
          }
        })
        setUpcomingPubmat(removeTestEvents)
        setOldPubmat(
          holdOld.sort((a, b) => new Date(b.date) - new Date(a.date))
        )
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllPubmats()
  }, [])

  /**
   * Maps the upcomingPubmat array to create a new array of JSX elements.
   * Each element represents a training program with its corresponding details and components.
   *
   * @param {Array} upcomingPubmat - The array of upcoming training programs.
   * @returns {Array} - The array of JSX elements representing the training programs.
   */
  const setNewPubmat = upcomingPubmat.map((item) => {
    const sortByName = JSON.parse(item.location)

    return (
      <Col className={'next-card-margin'} key={item.id} span={24}>
        <Row justify='center'>
          <Col className='col-md-9 col-11'>
            <div className={'tPage-box-shadow p-3'}>
              <Col span={24}>
                <div className='d-md-flex'>
                  <Col className='p-0 d-md-none d-block'>
                    <Row
                      style={{ height: '100%', width: '100%' }}
                      justify='end'
                      align='center'
                    >
                      <div className={'d-flex align-items-center'}>
                        <img
                          style={{ maxWidth: '100%' }}
                          src={sortByName.sort()[0]}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col className='mt-md-0 mt-3 col-md-8'>
                    <div className={'me-3'}>
                      {item.title.includes('Wave 2') ? (
                        <>
                          <Row
                            id='wave-2'
                            className={'text-monts tPage-header'}
                            justify='start'
                          >
                            {item.title}
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row
                            className={'text-monts tPage-header'}
                            justify='start'
                          >
                            {item.title}
                          </Row>
                        </>
                      )}

                      <Row className={'text-monts tPage-categ'} justify='start'>
                        {'Description'}
                      </Row>

                      <Row className={'text-monts'} justify='start'>
                        <Col>
                          <HtmlParser
                            content={item?.courseDescription?.replace(
                              /\n/g,
                              '<br>'
                            )}
                          />
                        </Col>
                      </Row>

                      <Row className={'text-monts tPage-categ'} justify='start'>
                        {'Target Participants'}
                      </Row>

                      <Row className={'text-monts'} justify='start'>
                        <Col>
                          <HtmlParser
                            content={item?.targetParticipants?.replace(
                              /\n/g,
                              '<br>'
                            )}
                          />
                        </Col>
                      </Row>

                      <Row justify='start'>
                        <OnlineTrainingRegistrationModal
                          userData={userData}
                          refresh={refresh}
                          setRefresh={(e) => setRefresh(e)}
                          gotoForms={() => gotoForms()}
                          setAfterRegister={(e) => setAfterRegister(e)}
                          trainingItem={item}
                          afterRegister={afterRegister}
                        />
                      </Row>
                      <Row>
                        <PostTrainingModal
                          afterRegister={afterRegister}
                          trainingItem={item}
                          userData={userData}
                          isECert={true}
                        />
                      </Row>
                    </div>
                  </Col>
                  <Col className='p-0 d-md-block d-none' span={8}>
                    <Row
                      style={{ height: '100%', width: '100%' }}
                      justify='end'
                      align='center'
                    >
                      <div className={'d-flex align-items-center'}>
                        <img
                          style={{ maxWidth: '100%' }}
                          src={sortByName.sort()[0]}
                        />
                      </div>
                    </Row>
                  </Col>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Col>
    )
  })

  /**
   * Generates a list of JSX elements representing old pubmat divs.
   *
   * @param {Array} currentShows - The array of current shows.
   * @returns {Array} - The list of JSX elements representing old pubmat divs.
   */
  const setOldPubmatDiv = currentShows.map((item) => {
    let courseDescription = ''
    if (item.courseDescription?.length) {
      courseDescription = item.courseDescription
        .split(/\r?\n|\r|\n/g)
        .map((line, index) => {
          return (
            <React.Fragment key={index}>
              <span>{`${line}`}</span>
              <br />
            </React.Fragment>
          )
        })
    }

    let targetParticipants = ''
    if (item.targetParticipants?.length) {
      targetParticipants = item.targetParticipants
        .split(/\r?\n|\r|\n/g)
        .map((line, ind) => {
          return (
            <React.Fragment key={ind}>
              <span>{`${line}`}</span>
              <br />
            </React.Fragment>
          )
        })
    }

    const sortByName = JSON.parse(item.location)
    return (
      <Col className={'next-card-margin'} key={item.id} span={24}>
        <Row justify='center'>
          <Col className='col-md-9 col-11'>
            <div className={'tPage-box-shadow p-3'}>
              <Col span={24}>
                <div className='d-md-flex'>
                  <Col className='d-block d-md-none'>
                    <Row
                      style={{ height: '100%', width: '100%' }}
                      justify='end'
                      align='center'
                    >
                      <div className={'d-flex align-items-center'}>
                        <img
                          style={{ maxWidth: '100%' }}
                          src={sortByName.sort()[0]}
                        />
                      </div>
                    </Row>
                  </Col>
                  <Col className='mt-md-0 mt-3'>
                    <div className={'me-3'}>
                      <Row
                        className={'text-monts tPage-header'}
                        justify='start'
                      >
                        {item.title}
                      </Row>

                      <Row className={'text-monts tPage-categ'} justify='start'>
                        {'Description'}
                      </Row>

                      <Row className={'text-monts'} justify='start'>
                        <Col>{courseDescription}</Col>
                      </Row>

                      {item.targetParticipants ? (
                        <>
                          <Row
                            className={'text-monts tPage-categ'}
                            justify='start'
                          >
                            {'Target Participants'}
                          </Row>
                          <Row className={'text-monts'} justify='start'>
                            <Col>{targetParticipants}</Col>
                          </Row>
                        </>
                      ) : null}

                      <Row justify='start' className={'mt-3'}>
                        <span className={'p-2'}>
                          <i>
                            <b>CLOSED</b>
                          </i>
                        </span>
                      </Row>
                    </div>
                  </Col>
                  <Col className='p-0 d-md-block d-none' span={8}>
                    <Row
                      style={{ height: '100%', width: '100%' }}
                      justify='end'
                      align='center'
                    >
                      <div className={'d-flex align-items-center'}>
                        <img
                          style={{ maxWidth: '100%' }}
                          src={sortByName.sort()[0]}
                        />
                      </div>
                    </Row>
                  </Col>
                </div>
              </Col>
            </div>
          </Col>
        </Row>
      </Col>
    )
  })

  const handleLoadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  return (
    <>
      <div className='d-flex justify-content-center'>
        <h3 className='font-Mont p-4'>Online Trainings and Programs</h3>
      </div>
      <Row
        style={{ paddingTop: '16px', paddingBottom: '16px' }}
        justify='center'
      >
        <div style={{ width: 900 }}>
          <h4 className={'font-Mont'}>Upcoming Online Trainings</h4>
        </div>
      </Row>
      <Row
        style={{ paddingTop: '16px', paddingBottom: '16px' }}
        justify='center'
      >
        {upcomingPubmat.length ? (
          <>{setNewPubmat}</>
        ) : (
          <>
            <Card
              size='small'
              title='TBA'
              style={{
                width: 900,
              }}
            ></Card>
          </>
        )}
      </Row>
      <Row
        style={{ paddingTop: '40px', paddingBottom: '16px' }}
        justify='center'
      >
        <div style={{ width: 900 }}>
          <h4 className={'font-Mont'}>Past Online Trainings</h4>
        </div>
      </Row>
      <Row
        style={{ paddingTop: '16px', paddingBottom: '16px' }}
        justify='center'
      >
        {oldPubmat.length ? <>{setOldPubmatDiv}</> : null}
      </Row>

      {currentShows.length < oldPubmat.length && (
        <Row justify='center' className='mb-4'>
          <Button onClick={handleLoadMore}>Load More</Button>
        </Row>
      )}
    </>
  )
}
